import React, { useRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

import { ThemeProvider } from "@material-ui/styles";
import PropTypes from "prop-types";
import { createMuiTheme, NativeSelect } from "@material-ui/core";
import theme from "./materialTheme";

// import "../../styles/main.css";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
export function BaseSelect2(props) {
  const classes = useStyles();
  const objects = props.object;
  const styles = {
    formControl: {
      margin: 8,
      minWidth: 120,
      height: 66,
      width: props.width,
      margin: 8,
      border: "#D3D8DA",
    },
    selectEmpty: {
      marginTop: 16,
    },
  };
  return (
    <div>
      <ThemeProvider theme={theme}>
        <FormControl
          required={props.required}
          variant={props.variant}
          style={props.style}
          className={classes.formControl}
        >
          <InputLabel id="demo-simple-select-label" className="label">
            {props.label}
          </InputLabel>
          <NativeSelect
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            error={props.error}
            className="options"
            labelId="demo-simple-select-label"
            id={props.uniqueid}
            autoWidth
            color={props.color}
            value={props.value}
            abbr={'abbr'}
            onChange={props.onChange}
          >
            <option
            defaultChecked
            disabled
            hidden
                // className="options"
              >
              </option>
            {objects.map((object) => (
              <option
                key={object.value}
                value={props.convertToJSON ? JSON.stringify(object) : object.value}
                // className="options"
              >
                {object.label}
              </option>
            ))}
          </NativeSelect>
          {props.helperText && (
            <span
              style={{
                color: props.error ? "#f44336" : "#D3D8DA",
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2,
                fontSize: 12,
                letterSpacing: "0.4px",
                fontFamily: "roboto",
              }}
            >
              {props.helperText}{" "}
            </span>
          )}
        </FormControl>
      </ThemeProvider>
    </div>
  );
}

BaseSelect2.propTypes = {
  label: PropTypes.string,
  autoWidth: PropTypes.bool,
  required: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
  error: PropTypes.bool,
};

BaseSelect2.defaultProps = {
  label: "Label",
  autoWidth: false,
  required: false,
  variant: "standard",
  color: "primary",
  error: false,
};

export default BaseSelect2;
