import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { SimpleTable, MasterLayoutMiddle, FooterInner, SimpleAccordian, EmptyTable } from "@dclab/dc-react-ui-kit";
import Handle from "../../../assets/img/more_vert-24px.svg";
import { getOrganizationPlan } from "../../UserProfile/Apis/index"


function CommitteesListing(props) {
  const [orders, setorders] = useState({})

  useEffect(() => {
    const getDetails = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const orgId = localStorage.getItem("orgID");
        const orgOrders = await getOrganizationPlan(orgId, token);
        console.log(orgOrders?.stripeData, "orgOrders")
        orgOrders && setorders(orgOrders)
      }
      catch (error) {
        console.log(error)
      }
    }
    getDetails()
  }, [])

  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { id: 'drag', label: ' ', width: '15px' }, //its only add when parentTableDrag is true
    { type: "link", name: "invoice_number", label: "Name", width: "15%" },
    { type: "string", name: "name", label: "# of members", width: "15%" },
    { type: "string", name: "name", label: "", width: "70%" },
    { type: "action", name: "action", label: "", width: "15%" },
  ];
  let rows = orders?.stripeData?.length ? orders.stripeData.map((el, i) => {
    return [
      { type: 'string', name: 'date', value: "SFSFS" },
      { type: 'string', name: 'date', value: "12" },
      { type: 'string', name: 'string', value: "" },
      { type: "action", status: "success", value: "see details" },
    ]
  }) : [];
  // ===========Table End ===========//

  const style = {
    width: '100%',
  };


  // =========== Accordion Start ===========//
  const [selectedZone, setselectedZone] = React.useState(0);

  const saveChanges = (accordian) => {
    console.log("data", accordian)
    setselectedZone(0)
  }
  const onAccordianClick = (panel) => {
    console.log("onAccordianClick", panel)
    if (panel === selectedZone) {
      setselectedZone(0)
    } else {
      setselectedZone(panel)
    }
  }

  const AccordionDetails = {
    top: "0px"
    //top:"-46px"
  };

  // =========== Accordion End ===========//

  return (
    <MasterLayoutMiddle
      content={
        <div>

          <Grid container spacing={3} >

            <Grid item md={12} sm={12} xs={12} >

              <div className="table_box DragTable">
                <SimpleTable
                  showHeader={true}
                  title="Committees"
                  width="100%"
                  rows={rows}
                  columns={col}
                  colSpan={16}
                  actionSendButton={true}
                  parentTableDrag={true}
                  DragIcon={Handle}
                  classProps={changeClassProps()}
                  donationsCount={rows && rows.length > 0 ? 1 : 0}
                  noTitle="No Orders added"
                  noPara="Added Reminders will be displayed and managed here"
                  noBtnText=""
                  onInviteClick={() =>
                    props.history.push("/organization/committees-details")
                  }
                  actionLink={(app) => props.history.push({ pathname: "/organization/committees-details", state: { order: app[0].data } })}
                  showIconCol={false}
                  actionSendButton={true}
                  deleteAction={(e) => console.log(e)}
                  showFooter={true}
                  showAdd={true}
                  addText="ADD NEW"
                  onAddClick={() => this.props.history.push("/organization/add-committee")
                  }
                  iconTextSize={16}
                />

                <EmptyTable
                  title="Committees"
                  noTitle="No committees added"
                  noPara="Added committees will be displayed and managed here"
                  onAddClick={() => this.props.history.push("/organization/add-committee")}
                  addLabel="ADD NEW"
                  noBtnText="ADD NEW"
                />

              </div>

            </Grid>


            <Grid item md={12} sm={12} xs={12} >

              <SimpleAccordian
                Title="MEMBERS"
                text="0 Members"
                color="#2D2D2D"
                fontSize="14px"
                fontWeight="400"
                tooltip="Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups."
                TootltipStatus={false}
                TitleButtonLabel="MANAGE"
                AccordionDetails={AccordionDetails}
                panel={2}
                id="2"
                open={selectedZone === 2 ? false : false}
                onAccordianClick={onAccordianClick}
              />

              <SimpleAccordian
                Title="DESIGNATIONS"
                text="6 Designations"
                color="#2D2D2D"
                fontSize="14px"
                fontWeight="400"
                tooltip="Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups."
                TootltipStatus={false}
                TitleButtonLabel="MANAGE"
                AccordionDetails={AccordionDetails}
                panel={2}
                id="2"
                open={selectedZone === 2 ? false : false}
                onAccordianClick={onAccordianClick}
              />


            </Grid>


          </Grid>

        </div>
      }
      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  );
}
export default CommitteesListing;