import React, { Component } from 'react';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
import { BasePhoneInput } from "@dclab/dc-react-ui-kit";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {
  SimpleTable,
  BasePaper,
  BaseTextField,
  AlertAction,
  DrawerMain,
} from '@dclab/dc-react-ui-kit';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import '../../pages/Organization/style.css';
import '../../App.css';
import { editOrganization, getOrganizationData, } from '../../pages/Organization/api';
import Edit from '../../assets/img/create-24px.svg';
import Delete from '../../assets/img/delete_forever-24px.svg';
import Grid from '@material-ui/core/Grid';
import { saveAsDraft } from '../DonationForm/Apis';
export default class ContactInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primary_phone: props?.orgData?.phoneFax?.primaryPhone ?? "",
      primary_phoneCopy: props?.orgData?.phoneFax?.primaryPhone ?? "",
      secondary_phone: props?.orgData?.phoneFax?.secondaryPhone ?? "",
      secondary_phoneCopy: props?.orgData?.phoneFax?.secondaryPhone ?? "",
      primary_phone2: '',
      secondary_phone2: '',
      email: props?.orgData?.orgEmail ?? "",
      emailCopy: props?.orgData?.orgEmail ?? "",
      inquiryName: '',
      editInquiryId: '',
      inquiryMembers: [],
      inquiryMembersEdit: [],
      activeInquiry: [],
      inquiries: [],
      inquiryLoading: false,
      openInquiryAlert: false,
      deleteInquiryId: '',
      emailError: false,
      primaryPhoneError: false,
      secondaryPhoneError: false,
      modalLoading: false,
      showAddDiv: false,
      showEditDiv: false,
      contactSuccess: false,
      contactError: false,
      orgData: props.orgData,
      loading: false,
      showAlert: false,
      error: false,
      addInquiryError: false,
      editInquiryError: false,
      primaryPhoneText: '',
      secondaryPhoneText: '',
      emailText: '',
      teamMembers: {
        id: 1,
        primary: "Abdul Al Razi",
        secondary: "Administrator",
        src:
          "https://media.gettyimages.com/photos/jama-masjid-mosque-in-delhi-picture-id505757382?s=612x612",
        Checkboxchecked: false,
      },
    };
    this.addInquiryType = this.addInquiryType.bind(this);
    this.addInquiryMember = this.addInquiryMember.bind(this);
    this.addInquiryMemberEdit = this.addInquiryMemberEdit.bind(this);
    this.editInquiryAction = this.editInquiryAction.bind(this);
    this.deleteInquiryAction = this.deleteInquiryAction.bind(this);
    this.editInquiry = this.editInquiry.bind(this);
    this.updateContact = this.updateContact.bind(this);
    this.handleInquiryConfirm = this.handleInquiryConfirm.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.addNew = this.addNew.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }
  getOrgData = async () => {
    let orgId = localStorage.getItem('orgID');
    const res2 = await getOrganizationData(orgId);
    this.setState({
      orgData: res2.data.body.data,
    });
  }
  componentDidMount() {
    this.getOrgData()
    const teamMembers = this.props.teamMembers.map((text, index) => {
      let nameArr = [text.name.firstName, text.name.lastName]
      let initials;
      if (nameArr.length > 1) {
        initials = nameArr[0][0] + nameArr[1][0];
      } else {
        initials = nameArr[0][0];
      }
      return ({
        id: index + 1,
        primary: nameArr.join(' '),
        fullName: nameArr.join(' '),
        secondary: text.role.map((r, i) => {
          return (
            r.roles[0].toUpperCase() + r.roles.slice(1) + `${text.role.length === i + 1 ? '' : ', '}`
          );
        }),
        src: initials.toUpperCase()
      })
    });
    this.setState({ teamMembers: teamMembers })
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  // // do things with nextProps.someProp and prevState.cachedSomeProp
  // return {
  //   primary_phone: nextProps.primary_phone,
  //   primary_phoneCopy: nextProps.primary_phone,
  //   secondary_phone: nextProps.secondary_phone,
  //   secondary_phoneCopy: nextProps.secondary_phone,
  //   email: nextProps.email,
  //   emailCopy: nextProps.email,
  // };
  // }
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  addNew = () => {
    this.setState({
      inquiryName: '',
      editInquiryId: '',
      error: false,
      addInquiryError: false,
      showAddDiv: true,
    });
  };
  validateEmail = (e) => {
    const emailRegex = /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/;
    if (emailRegex.test(e.target.value)) {
      this.setState({ email: e.target.value, emailError: false });
    } else {
      this.setState({ email: e.target.value, emailError: false });
    }
  };
  checkEmail = () => {
    const emailRegex = /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/;
    if (emailRegex.test(this.state.email)) {
      this.setState({ emailError: false });
    } else {
      this.setState({ emailError: true });
    }
  }
  updateContact = async () => {
    const {
      primary_phone,
      secondary_phone,
      orgData,
      email,
      primaryPhoneError,
      secondaryPhoneError,
      emailError,
      contactError,
      primary_phoneCopy,
      secondary_phoneCopy,
      emailCopy,
    } = this.state;

    let orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    this.setState({
      loading: true,
      primaryPhoneText: '',
      secondaryPhoneText: '',
      emailText: '',
      contactError: false,
    });
    if (!primary_phone || !secondary_phone || !email) {
      this.setState({ contactError: true, loading: false });
    } else if ((secondary_phone.length < 14) && (primary_phone.length < 14)) {
      this.setState({ secondaryPhoneError: true, primaryPhoneError: true, loading: false })
    } else if (primary_phone.length < 14) {
      this.setState({ primaryPhoneError: true, loading: false })
    } else if (secondary_phone.length < 14) {
      this.setState({ secondaryPhoneError: true, loading: false })
    } else if (primaryPhoneError || secondaryPhoneError) {
      this.setState({ contactError: true, loading: false });
    } else if (emailError) {
      this.setState({ contactError: true, loading: false });
    }
    // else if (contactError === false) {
    //   this.setState({ contactError: true, loading: false });
    // } 
    else if (orgData.contactInfo.length === 0) {
      const obj = {
        orgEmail: email,
        phoneFax: {
          primaryPhone: primary_phone,
          secondaryPhone: secondary_phone,
        },
        contactInfo: [
          {
            contactUs: {
              phone: primary_phone,
              email,
            },
          },
        ],
      };
      if (primary_phone !== primary_phoneCopy) {
        this.setState({ primaryPhoneText: 'Primary Phone Number' });
      }
      if (secondary_phone !== secondary_phoneCopy) {
        this.setState({ secondaryPhoneText: 'Secondary Phone Number' });
      }
      if (email !== emailCopy) {
        this.setState({ emailText: 'Info Email' });
      }
      try {
        const res = await editOrganization(obj, orgId, token);
        const res2 = await getOrganizationData(orgId);
        this.setState({
          orgData: res.data.body.data,
          inquiryName: '',
          showAlert: true,
          contactSuccess: true,
          primary_phoneCopy: primary_phone,
          secondary_phoneCopy: secondary_phone,
          emailCopy: email,
          loading: false,
        });
        // this.props.getOrganizationData(res2.data.body.data);
        this.hideAlert();
      } catch (error) {
        this.setState({
          error: error && error.response && error.response.data.message,
          showAlert: true,
          contactError: error && error.response && error.response.data.message,
          loading: false,
        });
        this.hideAlert();
        console.log(error, "error2")
      }
    } else {
      const obj2 = {
        orgEmail: email,
        phoneFax: {
          primaryPhone: primary_phone,
          secondaryPhone: secondary_phone,
        },
        contactInfo: [
          {
            contactUs: {
              phone: primary_phone,
              email,
            },
            inquiryTypes: orgData.contactInfo[0].inquiryTypes,
          },
        ],
      };
      if (primary_phone !== primary_phoneCopy) {
        this.setState({ primaryPhoneText: 'Primary Phone Number' });
      }
      if (secondary_phone !== secondary_phoneCopy) {
        this.setState({ secondaryPhoneText: 'Secondary Phone Number' });
      }
      if (email !== emailCopy) {
        this.setState({ emailText: 'Info Email' });
      }
      try {
        const res = await editOrganization(obj2, orgId, token);
        try {
          const res2 = await getOrganizationData(orgId);
          // this.props.getOrganizationData(res2.data.body.data);
        } catch (err) {
          console.log(err)
        }
        this.setState({
          orgData: res.data.body.data,
          contactSuccess: true,
          primary_phoneCopy: primary_phone,
          secondary_phoneCopy: secondary_phone,
          emailCopy: email,
          showAlert: true,
          loading: false,
        });
        this.hideAlert();
      } catch (error) {
        this.setState({
          error: error && error.response && error.response.data.message,
          showAlert: true,
          contactError: error && error.response && error.response.data.message,
          loading: false,
        });
        this.hideAlert();
      }
    }
  };
  editInquiryAction = (e) => {
    const { orgData } = this.state;
    this.setState({
      showEditDiv: true,
      inquiryName: e[1].value,
      editInquiryId: e[0].label,
      activeInquiry: orgData.contactInfo[0].inquiryTypes.filter(
        (it) => it._id === e[0].label
      ),
      editInquiryError: false,
    });
  };
  handleInquiryConfirm = async () => {
    console.log("Inquiry Member added...")
    this.setState({ modalLoading: true });
    const { orgData, deleteInquiryId } = this.state;
    let orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    let arr = orgData.contactInfo[0].inquiryTypes.filter(
      (inqT) => inqT._id !== deleteInquiryId
    );
    const obj2 = {
      contactInfo: [
        {
          inquiryTypes: arr,
        },
      ],
    };
    try {
      await editOrganization(obj2, orgId, token);
      const res2 = await getOrganizationData(orgId);
      this.setState({
        orgData: res2.data.body.data,
        openInquiryAlert: false,
        modalLoading: false,
      });
      // this.props.getOrganizationData(res2.data.body.data);
    } catch (error) {
      this.setState({
        error: error && error.response && error.response.data.message,
        openInquiryAlert: false,
        modalLoading: false,
      });
    }
  };
  deleteInquiryAction = (e) => {
    this.setState({ openInquiryAlert: true, deleteInquiryId: e[0].label });
  };
  handleClose = () => {
    this.setState({
      openAlert: false,
      deleteTeamMemberId: '',
      openInquiryAlert: false,
      deleteInquiryId: '',
    });
  };
  addInquiryMember = (e) => {
    console.log("Member added...")
    let { inquiryMembers } = this.state;
    if (!inquiryMembers.find((element) => element._id === e._id)) {
      const evcIdArray = inquiryMembers.concat(e);
      this.setState({
        inquiryMembers: evcIdArray,
      });
    } else {
      this.setState({
        inquiryMembers: inquiryMembers.filter(
          (element) => element._id !== e._id
        ),
      });
    }
  };
  addInquiryMemberEdit = (e) => {
    let { activeInquiry } = this.state;
    if (!activeInquiry[0].members.find((element) => element._id === e._id)) {
      const evcIdArray = activeInquiry[0].members.concat(e);
      this.setState({
        inquiryMembersEdit: evcIdArray,
        activeInquiry: [
          {
            _id: activeInquiry[0]._id,
            inquiry_name: activeInquiry[0].inquiry_name,
            members: evcIdArray,
          },
        ],
      });
    } else {
      activeInquiry[0].members.filter((element) => element._id !== e._id);
      this.setState({
        inquiryMembersEdit: activeInquiry[0].members.filter(
          (element) => element._id !== e._id
        ),
        activeInquiry: [
          {
            _id: activeInquiry[0]._id,
            inquiry_name: activeInquiry[0].inquiry_name,
            members: activeInquiry[0].members.filter(
              (element) => element._id !== e._id
            ),
          },
        ],
      });
    }
  };
  editInquiry = async () => {
    console.log("New Member added in edit...")
    const {
      inquiryName,
      inquiryMembersEdit,
      orgData,
      editInquiryId,
      activeInquiry,
    } = this.state;
    this.setState({ inquiryLoading: true });
    let orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    if (!inquiryName || activeInquiry[0].members.length === 0) {
      this.setState({ editInquiryError: true, inquiryLoading: false });
    } else {
      let arr = orgData.contactInfo[0].inquiryTypes.map((inqT) => {
        if (inqT._id === editInquiryId) {
          let obj = {
            ...inqT,
            inquiryName: inquiryName,
            members:
              inquiryMembersEdit.length > 0
                ? inquiryMembersEdit
                : activeInquiry[0].members
          }
          return obj;
        } else {
          return inqT;
        }
      });

      const obj2 = {
        contactInfo: [
          {
            contact_us: orgData.contactInfo[0].contactUs,
            inquiryTypes: arr,
          },
        ],
      };
      try {
        
        await editOrganization(obj2, orgId, token);
        const res2 = await getOrganizationData(orgId);

        
        res2.status === 200 && this.setState({
          orgData: res2.data.body.data,
          inquiryName: '',
          inquiryMembersEdit: [],
          inquiryLoading: false,
          showEditDiv: false,
          editInquiryError: false,
        });
        // res2.status === 200 && this.props.getOrganizationData(res2.data.body.data);
      } catch (error) {
        this.setState({
          editInquiryError: error,
          inquiryLoading: false,
          showEditDiv: false,
        });
      }
    }
  };
  addInquiryType = async () => {
    const { inquiryName, inquiryMembers, orgData } = this.state;
    let orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    this.setState({ inquiryLoading: true });
    if (!inquiryName || inquiryMembers.length === 0) {
      this.setState({ addInquiryError: true, inquiryLoading: false });
    } else if (
      orgData.contactInfo.length === 0 ||
      orgData.contactInfo[0].inquiryTypes.length === 0
    ) {
      const obj = {
        contactInfo: [
          {
            inquiryTypes: [
              {
                inquiryName: inquiryName,
                members: inquiryMembers.map(el => el._id),
              },
            ],
          },
        ],
      };
      try {
        await editOrganization(obj, orgId, token);
        await saveAsDraft(orgId,obj, token)
        const res2 = await getOrganizationData(orgId);
        this.setState({
          orgData: res2.data.body.data,
          showAddDiv: false,
          inquiryName: '',
          inquiryLoading: false,
          inquiryMembers: [],
          addInquiryError: false,
        });
        // this.props.getOrganizationData(res2.data.body.data);
      } catch (error) {
        this.setState({ error, inquiryLoading: false });
      }
    } else {
      const obj2 = {
        contactInfo: [
          {
            contact_us: orgData.contactInfo[0].contactUs,
            inquiryTypes: [
              ...orgData.contactInfo[0].inquiryTypes,
              {
                inquiryName: inquiryName,
                members: inquiryMembers,
              },
            ],
          },
        ],
      };
      try {
        await editOrganization(obj2, orgId, token);
        const res2 = await getOrganizationData(orgId);
        this.setState({
          orgData: res2.data.body.data,
          showAddDiv: false,
          inquiryName: '',
          inquiryLoading: false,
          inquiryMembers: [],
          addInquiryError: false,
        });

        localStorage.setItem("inquiryAdded")
        // this.props.getOrganizationData(res2.data.body.data);
      } catch (error) {
        this.setState({ addInquiryError: error, inquiryLoading: false });
      }
    }
  };
  render() {
    const {
      primary_phone,
      primaryPhoneText,
      secondaryPhoneText,
      emailText,
      secondary_phone,
      primaryPhoneError,
      secondaryPhoneError,
      email,
      emailError,
      showAddDiv,
      showEditDiv,
      contactError,
      contactSuccess,
      openInquiryAlert,
      inquiryName,
      inquiryLoading,
      showAlert,
      inquiryMembers,
      addInquiryError,
      editInquiryError,
      orgData,
      modalLoading,
      activeInquiry,
    } = this.state;
    // console.log("state", this.state)
    // console.log("props", this.props)

    let phoneRegex = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{5})$/g;
    let alertInquiryModal = (
      <Dialog
        open={openInquiryAlert}
        onClose={() => this.handleClose()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Do you want to delete this inquiry? This can't be undone.
          </DialogContentText>
        </DialogContent>
        {modalLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 50,
            }}
          >
            <CircularProgress
              color='primary'
              style={{ width: 24, height: 24 }}
            />
          </div>
        ) : (
          <DialogActions>
            <Button onClick={() => this.handleClose()} color='primary'>
              Cancel
            </Button>
            <Button onClick={() => this.handleInquiryConfirm()} color='primary'>
              Confirm
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
    let inquiryCol = [
      { id: 'NO.', label: 'NO.', width: 'auto' },
      { id: 'INQUIRY_NAME', label: 'INQUIRY NAME', width: '50%' },
      {
        type: 'array',
        name: 'multi_images',
        label: 'EMAIL TO',
        minWidth: '40%',
      },
      { id: 'ACTION', label: 'ACTION', minWidth: '15%' },
    ];
    let inquiryRows =
      orgData.contactInfo &&
      orgData.contactInfo.length > 0 &&
      orgData.contactInfo[0].inquiryTypes.length > 0 &&
      orgData.contactInfo[0].inquiryTypes.map((inType, i) => {
        return [
          { type: 'id', value: i + 1, label: inType._id },
          {
            type: 'string',
            name: 'name',
            value: inType.inquiryName,
          },
          {
            type: 'array',
            name: 'multi_images',
            value: inType.members.map((mem, index) => {
              let nameArr = [mem.name.firstName, mem.name.lastName];
              let initials;
              if (nameArr.length > 1) {
                initials = nameArr[0][0] + (nameArr[1] === '' ? '' : nameArr[1][0]);
              } else {
                initials = nameArr[0][0];
              }
              return {
                name: 'img1',
                type: 'url',
                initials: !mem?.imageDetails?.image ? initials.toUpperCase() : '',
                src: mem?.imageDetails?.image ? mem.imageDetails.image : '',
                listStyle: {
                  position: 'relative',
                  left: `${index === 0 ? 0 : 1 * (-index + 0.25)}rem`,
                  border: '3px solid white',
                  borderRadius: '50px',
                  zIndex: 100 - index,
                },
              };
            }),
          },
        ];
      });
    let InquiryclassProps = () => {
      const tempArray = [];
      inquiryCol.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const InquirychangeClassProps = () => {
      const tempArray = InquiryclassProps();
      tempArray[0] = 'class15';
      return tempArray;
    };
    const styles = () => ({
      root: {
        '&$checked': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
      checked: {},
    });
    const contact = (
      <>
        <div style={{ border: '1px solid #E8E9E9' }}>
          <p className='orgTitled'>Contact Info</p>
        </div>
        <div className='contact-us' style={{ borderBottom: 'none' }}>
          <h5 className='contact'>Contact Us</h5>
          <p className='contact-desc'>
            This contact details will be visible to public
          </p>

          <Grid container spacing={3}>

            <Grid item md={4} sm={4} xs={12}>
              <BasePhoneInput
                phoneInputVariant={"underlineWithFlag"}
                countryCodeEditable={false}
                specialLabel={'Primary Phone Number'}
                placeholder='Phone Number'
                country={'us'}
                value={primary_phone}
                onChange={(e, f, g, h) => {
                  if (!phoneRegex.test(e)) {
                    this.setState({
                      primaryPhoneError: false,
                      primary_phone: h,
                    });
                  } else {
                    this.setState({
                      primaryPhoneError: false,
                      primary_phone: h,
                    });
                  }
                }}
                errorText={contactError ? 'Please enter phone number' : primaryPhoneError ? 'Enter valid phone number' : ' '}
                error={contactError ? true : primaryPhoneError ? true : false}
              />
            </Grid>

            <Grid item md={4} sm={4} xs={12}>
              <BasePhoneInput
                phoneInputVariant={"underlineWithFlag"}
                countryCodeEditable={false}
                specialLabel={'Secondary Phone Number'}
                placeholder='Phone Number'
                country={'us'}
                value={secondary_phone}
                onChange={(e, f, g, h) => {
                  if (!phoneRegex.test(e)) {
                    this.setState({
                      secondary_phone: h,
                      secondaryPhoneError: false
                    });
                  } else {
                    this.setState({
                      secondary_phone: h,
                      secondaryPhoneError: false
                    });
                  }
                }}
                errorText={contactError ? 'Please enter phone number' : secondaryPhoneError ? 'Enter valid phone number' : ' '}
                error={contactError ? true : secondaryPhoneError ? true : false}
              />
            </Grid>

            <Grid item xs={8}> 
              <div className="field_box">
                  <BaseTextField
                    label='Info Email'
                    helperText={emailError && 'Invalid format'}
                    style={{ width: '103%', marginLeft: 0 }}
                    value={email}
                    onChange={(e) => this.validateEmail(e)}
                    onBlur={() => this.checkEmail()}
                    error={emailError}
                    placeholder=' '
                  />
              </div>
            </Grid>

            <Grid item xs={12}> 
             
            </Grid>

          </Grid>


          
        </div>
        {this.state.loading ? (
          <div
            style={{
              border: '1px solid #E8E9E9',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: -21,
              height: 30,
              paddingTop: 10,
            }}
          >
            <CircularProgress color='primary' size={20} />
          </div>
        ) : (
          <div
            style={{
              borderTop: '1px solid #E8E9E9',
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: -21,
            }}
          >
            <p className='orgSave' onClick={() => this.updateContact()}>
              Save Changes
            </p>
          </div>
        )}
        <br />
      </>
    );
    const inquiryTable = (
      <div className='dc-inq-table'>
        <SimpleTable
          showHeader={true}
          title='Inquiry Types'
          rows={inquiryRows && inquiryRows.length > 0 ? inquiryRows : []}
          columns={inquiryCol}
          colSpan={16}
          actionSendButton={true}
          classProps={InquirychangeClassProps()}
          donationsCount={inquiryRows && inquiryRows.length > 0 ? 1 : 0}
          noTitle='No Inquiry Types Added'
          noPara='Add new inquiry types'
          noBtnText='Add Inquiry Types'
          onInviteClick={() => this.addNew()}
          showIconCol={true}
          editIcon={Edit}
          deleteIcon={Delete}
          showFooter={true}
          showAdd={true}
          onAddClick={() => this.addNew()}
          editAction={(e) => this.editInquiryAction(e)}
          deleteAction={(e) => this.deleteInquiryAction(e)}
          iconTextSize={16}
          addText='ADD NEW'
        />
      </div>
    );
    return (
      <>
        {showAlert && !contactError && (
          <div
            style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35 }}
          >
            <AlertAction
              alert={`Contact info successfully updated`}
              alertType={'success'}
              onClick={() => this.setState({ showAlert: false })}
            />
          </div>
        )}
        {showAlert && contactError && (
          <div
            style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35 }}
          >
            <AlertAction
              alert={contactError}
              alertType={'error'}
              onClick={() => this.setState({ showAlert: false })}
            />
          </div>
        )}
        <div>
          <div>
            <div
              style={{
                border: '1px solid rgba(0, 0, 0, 0.12)',
              }}
            >
              <BasePaper
                context={contact}
                square={false}
                paperStyle={styles.paperStyleNext}
              />
            </div>
            <div
              style={{ boxShadow: '0px 2px 4px #0000001A', marginTop: 29 }}
              className='inquiry-table'
            >
              <BasePaper
                context={inquiryTable}
                square={false}
                paperStyle={styles.paperStyleNext}
              />
            </div>
          </div>
        </div>
        <DrawerMain
          open={showAddDiv}
          openHandler={() => this.setState({ showAddDiv: false })}
          title='Add Inquiry Type'
          onCancel={() =>
            this.setState({
              showAddDiv: false,
              error: false,
              addInquiryError: false,
            })
          }
          onSave={() => this.addInquiryType()}
          loading={inquiryLoading}
          sideDrawerContentClass='inquiry-contact'
        >
          <div style={{ height: 75 }}>
            <BaseTextField
              label='Inquiry Name'
              style={{ width: 305 }}
              value={inquiryName}
              onChange={(e) => this.setState({ inquiryName: e.target.value })}
              error={this.state.addInquiryError && !this.state.inquiryName}
              helperText={
                this.state.addInquiryError &&
                !this.state.inquiryName &&
                'Please enter inquiry name'
              }
              placeholder=' '
            />
          </div>
          <div style={{ width: 305 }} className='event_Image'>
            <p>Email to</p>
            <List>
              {this.props.teamMembers.map((mem) => {
                let nameArr = [mem.name.firstName, mem.name.lastName];
                let initials;
                if (nameArr.length > 0) {
                  initials = nameArr[0][0] + (nameArr[1] === '' ? '' : nameArr[1][0]);
                } else {
                  initials = nameArr[0][0];
                }
                return (
                  <>
                    <ListItem
                      alignItems='flex-start'
                    // style={{ marginLeft: -15 }}
                    >
                      <div
                        style={{
                          width: 40,
                          height: 40,
                          background: '#7266FB 0% 0% no-repeat padding-box',
                          borderRadius: 50,
                          marginTop: 5,
                          marginRight: 10,
                        }}
                      >
                        {mem.imageDetails?.image ?
                          <img style = {{height: '40px', width: '40px', borderRadius: '40px', marginRight: '3px'}} src={mem.imageDetails.image} />
                          :
                        <p
                          style={{
                            textAlign: 'center',
                            fontSize: 16,
                            letterSpacing: 0.51,
                            color: '#FFFFFF',
                            verticalAlign: 'middle',
                            marginTop: 10,
                          }}
                        >
                          {initials.toUpperCase()}
                        </p>
                      }
                      </div>
                      <ListItemText
                        primary={nameArr.join(' ')}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component='span'
                              variant='body2'
                              color='textPrimary'
                            ></Typography>
                            {mem.role[0].roles[0].toUpperCase() +
                              mem.role[0].roles.slice(1)}
                          </React.Fragment>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge='end'
                          onChange={() => this.addInquiryMember(mem)}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider component='li' className='divider-inq' />
                  </>
                );
              })}
            </List>
            {addInquiryError && inquiryMembers.length === 0 && (
              <p style={{ color: '#f44336 ', fontSize: '0.75rem' }}>
                Select atleast one member
              </p>
            )}
          </div>
        </DrawerMain>
        <DrawerMain
          open={showEditDiv}
          openHandler={() => this.setState({ showEditDiv: false })}
          title='Edit Inquiry Type'
          onCancel={() =>
            this.setState({
              showEditDiv: false,
              editInquiryError: false,
            })
          }
          onSave={() => this.editInquiry()}
          loading={inquiryLoading}
          sideDrawerContentClass='inquiry-contact'
        >
          <div style={{ height: 75 }}>
            <BaseTextField
              label='Inquiry Name'
              style={{ width: 305 }}
              value={inquiryName}
              onChange={(e) => this.setState({ inquiryName: e.target.value })}
              error={this.state.editInquiryError && !this.state.inquiryName}
              helperText={
                this.state.editInquiryError &&
                !this.state.inquiryName &&
                'Please enter inquiry name'
              }
              placeholder=' '
            />
          </div>
          <div style={{ width: 305 }}>
            <p>Email to</p>
            <List>
              {this.props.teamMembers.map((mem) => {
                const lastNameCheck = mem.name.lastName === '' ? true : false;
                let nameArr = lastNameCheck ? [mem.name.firstName] : [mem.name.firstName, mem.name.lastName];
                let initials;
                if (nameArr.length > 1) {
                  initials = nameArr[0][0] + nameArr[1][0];
                } else {
                  initials = nameArr[0][0];
                }
                return (
                  <>
                    <ListItem
                      alignItems='flex-start'
                      style={{ marginLeft: 0 }}
                    >
                      <div
                        style={{
                          width: 40,
                          height: 40,
                          background: '#7266FB 0% 0% no-repeat padding-box',
                          borderRadius: 50,
                          marginTop: 5,
                          marginRight: 10,
                        }}
                      >
                        {mem.imageDetails?.image ?
                          <img style = {{height: '40px', width: '40px', borderRadius: '40px', marginRight: '3px'}} src={mem.imageDetails.image} />
                          :
                        <p
                          style={{
                            textAlign: 'center',
                            fontSize: 16,
                            letterSpacing: 0.51,
                            color: '#FFFFFF',
                            verticalAlign: 'middle',
                            marginTop: 10,
                          }}
                        >
                          {initials.toUpperCase()}
                          {/* {mem.imageDetails?.image ?  : initials?.toUpperCase() */}
                        
                        </p>
                          }
                      </div>
                      <ListItemText
                        primary={nameArr.join(' ')}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component='span'
                              variant='body2'
                              color='textPrimary'
                            ></Typography>
                            {mem.role.map((r, i) => {
                              return (
                                r.roles[0].toUpperCase() + r.roles.slice(1) + `${mem.role.length === i + 1 ? '' : ', '}`
                              );
                            })}
                          </React.Fragment>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge='end'
                          onChange={() => this.addInquiryMemberEdit(mem)}
                          checked={
                            this.state.activeInquiry.length > 0 &&
                            this.state.activeInquiry[0].members.some(
                              (fac) => fac._id === mem._id
                            )
                          }
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider component='li' className='divider-inq' />
                  </>
                );
              })}
            </List>
            {editInquiryError && activeInquiry[0].members.length === 0 && (
              <p style={{ color: '#f44336 ', fontSize: '0.75rem' }}>
                Select atleast one member
              </p>
            )}
          </div>
        </DrawerMain>
        {openInquiryAlert && alertInquiryModal}
        <div className='contact-scroll'></div>
      </>
    );
  }
}
