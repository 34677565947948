import React, { useState, useEffect } from 'react';
import {
    MasterLayoutMiddle, FooterInner, DrawerMain,
    BaseCheckBox, BaseTextField, BaseImgChip, TextEditor,BaseButton
}
    from "@dclab/dc-react-ui-kit";

import Grid from '@material-ui/core/Grid';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';








function AddNewJumah(props) {

    const style = {
        width: '100%',
    };

    const [open, setOpen] = React.useState(false);
    const [values, setValues] = React.useState({ age: "", });
    const closeDrawer = () => { setOpen(!open); };
    const onCancel = () => { setOpen(false); };

    const onSave = () => {
        console.log("save");
    };
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };





    let chipData = [
        {
            key: 0,
            label: "Image Chip",
            src:
                "https://audioengineusa.com/wp-content/uploads/2019/01/a2.jpg",
            alt: "",
        },

    ];

    const onInputClick = () => {
        console.log("input clicked");
    };

    const onChipInputClick = () => {
        console.log("input chip clicked");
    };

    const deleteChip = () => {
        console.log('chip deleted')
    }


    const [value, setValue] = useState([{ type: 'paragraph', children: [{ text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." }] }]);

    const onChange = (e) => {
        setValue(e)
    };

    const styles = {
        border: '1px solid black',
        color: 'gray'
    };

    const [checked, setChecked] = useState(true);

    useEffect(() => {
        console.log(`Your ${checked} function here`);
    }, [checked]);


    return (

        <MasterLayoutMiddle

            header={
                <div style={{ width: '26%', alignSelf: 'center' }}>
                    <div
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                        className='backBtn'
                    >
                        <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
                        <p style={{ alignSelf: 'center' }}>Back</p>{' '}
                    </div>
                </div>
            }


            isForm={true}
            formData={
                <>
                    <div>
                        <h1 className='title_top'>Add New Jumu'ah</h1>
                        <div className="dasheddBorder"></div>
                    </div>


                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <div className="field_box" >
                                <BaseTextField
                                    label="Date & Time"
                                    placeholder=""
                                    helperText=""
                                    onClick={() => setOpen(true)}
                                    InputProps={true}
                                />
                            </div>
                        </Grid>


                        <Grid item xs={12}>
                            <div className="field_box" >
                                <BaseImgChip
                                    chipData={chipData}
                                    onInputClick={onInputClick}
                                    onChipInputClick={onChipInputClick}
                                    deleteChip={deleteChip}
                                    helperText=''
                                    label="Select Khateeb"
                                // textStyle={styles}
                                // arrowClick={arrowClick}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box" >
                                <BaseImgChip
                                    chipData={chipData}
                                    onInputClick={onInputClick}
                                    onChipInputClick={onChipInputClick}
                                    deleteChip={deleteChip}
                                    helperText=''
                                    label="Invitation Method"
                                // textStyle={styles}
                                // arrowClick={arrowClick}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="TextArea">
                                <TextEditor
                                    onChange={(e) => onChange(e)}
                                    disabled={false}
                                    value={value}
                                    style={styles}
                                    label={"Khutbah Topic (Optional)"}
                                    helperText={"Helper Text"}
                                    maxCharCount={200}
                                    placeholder="placeholder"
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="TextArea">
                                <TextEditor
                                    onChange={(e) => onChange(e)}
                                    disabled={false}
                                    value={value}
                                    style={styles}
                                    label={"Notes (Optional)"}
                                    helperText={"Helper Text"}
                                    maxCharCount={200}
                                    placeholder="placeholder"
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box" >
                                <BaseTextField
                                    label="Reminder 1"
                                    onClick={() => setOpen(true)}
                                    InputProps={true}
                                    value="None"
                                    helperText="We'll send reminder to imam's preferred communication method"
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="addButtonContainerTwo"><AddCircleRoundedIcon /> ADD ANOTHER REMINDER </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box" >
                                <BaseCheckBox
                                    label="Send message to {PreferredContact} after clicking on ADD NOW"
                                    checked={checked}
                                    color="secondary"
                                    showHelperText={true}
                                    onChange={(e) => setChecked(e.target.checked)}
                                />
                            </div>
                        </Grid>


                        <Grid item xs={12}>
                            <div className="form_button">
                                <p
                                    className='cancel'
                                    onClick={() => this.onCancelOrgDetail()
                                    }
                                >
                                    CANCEL
                                </p>
                                <BaseButton text='ADD NOW' onClick={() => this.updateOrg()} />
                            </div>

                        </Grid>


                    </Grid>








                    <DrawerMain
                        open={open}
                        openHandler={closeDrawer}
                        title="Select Assignee"
                        onCancel={onCancel}
                        loading={false}
                        saveDisabled={false}
                        onSave={onSave}
                    >
                        <div className="cardDrawer">
                            sdsfsf
                        </div>
                    </DrawerMain>





                </>
            }

            isFooter={true}
            footer={
                <FooterInner style={style} />
            }

            isWhiteBackground={true}

        />

    );
}


export default AddNewJumah;