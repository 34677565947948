import React, { Component } from "react";
import {
  BaseTextField,
  BaseButton,
  BaseStepper,
  FormPublishUnpublish,
  DrawerMain,
  BaseRadio,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import MomentTime from "moment-timezone";
import Typography from "@material-ui/core/Typography";
import componentImg from "../../assets/img/component.png";
import "./style.css";
import { getAllSalah, UpdateSalah } from "../Donations/deen-kioks-website/Apis";
import { CircularProgress } from "@material-ui/core";
import Moment from "moment";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import { connect } from "react-redux";
import messages_content from '../../utils/messages.json';
import { getOrganization } from "../Home/api";
import {  getOrganizationData, saveSalah } from "../Organization/api";
import { getSalahsWithAppId } from "./api";
const {token_expired, publish_date_before_unpublish, success_message_salah} = messages_content;
const styles = {
  formBody: {
    background: "#fff",
  },
  formTitle: {
    fontSize: 22,
    color: "#5A5A5A",
  },
  contents: {
    padding: 24,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: "76%",
    marginTop: 70,
    marginLeft: "20%",
  },
  formTitleSub: {
    fontSize: 14,
    color: "#818E94",
  },
  dottedBorder: {
    border: "#1px dashed #D3D8DA",
  },
  pageTitle: {
    color: "#5A5A5A",
    fontSize: "28px",
    paddingBottom: "30px",
    textAlign: "center",
  },
};
class AddSalah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postTimeDate: "",
      postTime: "",
      postDate: "",
      checked: false,
      publishDate: "Immediately",
      publishTime: "",
      unPublishDate: "Never",
      unPublishTime: "",
      unPublishTimestamp: 0,
      activeStep: 0,
      visibility: 'Public',
      publish: "Immediately",
      unPublish: "Never",
      openDrawerSalah: false,
      UTCDate: '',
      UTCTime: '',
      UTCDateUnPublish: '',
      UTCTimeUnPublish: '',
      validUnPublish: true,
      validPublish: true,
      publishedDateAndTime: '',
      unPublishedDateAndTime: '',
      obj: [],
      selectedSalah: [],
      Loading: false,
      waqt: '',
      errorSalah: false,
      error: false,
      dateError: false,
      integrateData: {},
      ExistingSalah: {},
      disablePublish: false
    };
  }
  async componentDidMount() {
    this.setState({ Loading: true })
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    let SalahList = []
    try{
      const getSalahRes = await getSalahsWithAppId(token, orgId, 'deenweb')
      // console.log("Salah Res with App ID")
      // console.log(getSalahRes)

      SalahList = getSalahRes.data.body.data
      console.log("SalahList: ", SalahList)
    }catch(err) {
      console.log(err)
      this.setState({ error: err.response && err.response.data.message })
      this.setState({ Loading: false })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
    try {
      this.setState({ Loading: true })
      
      const response = await getAllSalah(orgId, token);
      const resOrg = await getOrganizationData(orgId);
      const orgIntegate = resOrg.data?.body?.data?.enabledIntegrations;

      //console.log("orgIntegate")
      //console.log(orgIntegate)

      const selSalahs = orgIntegate?.deenweb?.salahs?.selectedSalahs.length ? orgIntegate?.deenweb?.salahs?.selectedSalahs : []
      console.log("selSalahs: ", selSalahs)

      const orgSalahs = response.data.body.data;

      console.log("orgSalahs: ", orgSalahs)
      const getActiveSalah = orgSalahs.filter(salah => salah.status === 'true')
      console.log("getActiveSalah: ", getActiveSalah)
      
      const selectedSalahIDs = selSalahs.length ? selSalahs.map(t => t.salah) : [];
      console.log("selectedSalahIDs: ", selectedSalahIDs)

      const filter = getActiveSalah.filter(text => !selectedSalahIDs?.includes(text._id) )
      console.log("Active Salah", filter)
      
      const salahList = filter.map((text, index) => {
        let string = text.salahName.fullName.split(' ');
        return (
          {
            ...text,
            label: string[0] + ' ' + string[1],
            select: false,
            detail: string[2] + ' ' + string[3],
          }
        )
      })

      console.log("salahList") 
      console.log(salahList)

      this.setState({ obj: salahList, Loading: false });
    } catch (err) {
      this.setState({ error: err.response && err.response.data.message })
      this.setState({ Loading: false })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  }
  handleChange = (label, index) => {
    console.log("index: ", index)
    let radios = this.state.obj;
    let array = radios.map((text, ind) => {
      if (index === ind) {
        return (
          {
            ...text,
            label: text.label,
            helperText: text.helperText,
            select: !text.select,
            detail: text.detail
          }
        )
      } else {
        return (
          {
            ...text,
            label: text.label,
            helperText: text.helperText,
            select: false,
            detail: text.detail
          }
        )
      }
    })
    //console.log("array: ",array )
    this.setState({ obj: array });
  }
  handleNext = () => {
    if (this.state.selectedSalah.length === 0) {
      this.setState({ errorSalah: true })
    } else {
      this.setState({ activeStep: this.state.activeStep + 1 })
    }
  };
  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 })
  };
  render() {
    const onBack = () => {
      this.setState({ activeStep: 0 })
    }
    const onVisibilitySave = () => {
      console.log("visibility saved");
    };
    const onVisibilityCancel = () => {
      console.log("visibility cancelled");
    };
    const onPublishSave = () => {
      let Time = this.state.publishTime.split(' ');
      let dated = MomentTime(new Date(this.state.publishDate)).format('YYYY-MM-DD');
      let split = Time[0].split(':');
      let Hours = ''
      let H;
      if (Time[1] === 'AM') {
        H = parseInt(split[0]);
        Hours = H + ':' + split[1]
      } else {
        H = parseInt(split[0]) + 12;
        Hours = H + ':' + split[1]
      }
      let date = `${dated + 'T' + Hours}:00.00Z`

      console.log("date: ", date)
      this.setState({ publishedDateAndTime: date });
    }
    const onPublishCancel = () => {
      console.log("onPublishCancel")
    };
    const onUnPublishSave = () => {
      let Time = this.state.unPublishTime.split(' ');
      let dated = MomentTime(new Date(this.state.unPublishDate)).format('YYYY-MM-DD');
      let split = Time[0].split(':');
      let Hours = ''
      let H;
      if (Time[1] === 'AM') {
        H = parseInt(split[0]);
        Hours = H + ':' + split[1]
      } else {
        H = parseInt(split[0]) + 12;
        Hours = H + ':' + split[1]
      }
      let date = `${dated + 'T' + Hours}:00.00Z`
      this.setState({ unPublishedDateAndTime: date })
    };
    const onUnPublishCancel = () => {
      console.log("onUnPublishCancel")
    };
    const getPublishDate = (value, boolean) => {
      console.log("value: ", value)
      
      this.setState({
        publishedDateAndTime: '',
        UTCDate: '',
        publish: 'Immediately',
        publishDate: 'Immediately',

      });

      // //Not applicable since schedule Salah is removed
      // if(value === "Immediately") {
      //   this.setState({
      //     publishedDateAndTime: '',
      //     UTCDate: '',
      //     publish: 'Immediately',
      //     publishDate: 'Immediately',

      //   });
      // } else {
      //   this.setState({
      //     publishDate: `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`,
      //     validPublish: false
      //   })
      //   this.setState({ postDate: Moment(value).format("YYYY-MM-DD"), UTCDate: Moment(value).format('YYYY-MM-DD') });
      // }
    };
    const getPublishTime = (value, boolean) => {
      console.log("publishTime: ", value)
      this.setState({ publishTime: Moment(value).format("h:mm A") });
      this.setState({ postTime: `${Moment(value).format("HH:mm:ss")}.000Z`, UTCTime: Moment(value).format("HH:mm:ss") })
    };
    const getUnPublishDate = (value, boolean) => {
      console.log("UnPublishTime: ", value)
      this.setState({
        unPublishedDateAndTime: '',
        UTCDateUnPublish: '',
        unPublish: 'Never',
        unPublishDate: 'Never'
      });


      //Not functional as UnPublish Scheduling is not available
      // if(value == "Never") {
      //   this.setState({
      //     unPublishedDateAndTime: '',
      //     UTCDateUnPublish: '',
      //     unPublish: 'Never',
      //     unPublishDate: 'Never'
      //   });
      // } else {
      //   this.setState({ UnPublishTimestamp: value, validUnPublish: false })
      //   this.setState({ unPublishDate: `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`, UTCDateUnPublish: Moment(value).format('YYYY-MM-DD') })
      // }
    };
    const getUnPublishTime = (value, boolean) => {
      console.log("value: ", value)
      this.setState({ unPublishTime: Moment(value).format("h:mm A"), UTCTimeUnPublish: Moment(value).utcOffset(0).format("HH:mm:ss") });
    };
    const {
      activeStep,
      visibility,
      publish,
      unPublish,
      openDrawerSalah,
      obj,
      publishDate,
      selectedSalah,
      unPublishDate,
      Loading,
      waqt,
      UTCDate,
      UTCTime,
      UTCDateUnPublish,
      UTCTimeUnPublish,
      validPublish,
      validUnPublish,
      publishedDateAndTime,
      dateError,
      unPublishedDateAndTime
    } = this.state;
    const closeDrawer = () => {
      this.setState({ openDrawerSalah: false });
    };

    const onCancel = () => {
      this.setState({ openDrawerSalah: false });

    };
    const onSaveDrawer = () => {
      let data = obj.filter(text => {
        return text.select === true
      })
      
      let time = data[0].label.split('-')
      console.log("time: ", time)
      
      this.setState({ selectedSalah: data, openDrawerSalah: false, waqt: time[1] })
      
      
    }
    const convertUTC = (date) => {
      return date.toISOString()
    }
    const onSave = async () => {
      const {ExistingSalah, obj, integrateData, disablePublish} = this.state;
      if(disablePublish) {
        return
      }
      this.setState({disablePublish: true})
      const token = localStorage.getItem("authToken");
      const orgId = localStorage.getItem('orgID');
      const data =  UTCDate === '' ? new Date() : new Date(`${UTCDate + ' ' + UTCTime}`)
      const dataU = convertUTC(data);
      const dataE = UTCDateUnPublish === '' ? new Date() : new Date(`${UTCDateUnPublish + ' ' + UTCTimeUnPublish}`)
      const UnpublishTime = convertUTC(dataE);
      console.log("UnpublishTime: ", UnpublishTime)
      console.log("visibility: ", visibility)
      // const Array = ExistingSalah?.salahs?.selectedSalahs.length ? ExistingSalah.salahs.selectedSalahs : []
      // const FilteredArray = Array.filter(text => text._id !== obj[0]._id)
      const Check = {
        website: {
          status: visibility,
          publishFrom: publishedDateAndTime === '' ? publish : dataU,
          publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime
        }
      }
      const dataObj = {
          "addSelectedArrayData": {
              "enabledIntegrations.deenweb.salahs.selectedSalahs": [{
                  salah: selectedSalah[0]._id,
                  publishFrom: publishedDateAndTime === '' ? publish : dataU,
                  publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime, // DATE OBJECT
                  status: visibility,
                  featured: false
              }]
          },
          "enabledIntegrations.deenweb.salahs.subscriptionType": "free"
        }

         console.log("Data Obj")
          console.log(dataObj)
        
      const previousUnpublish = Check.website.publishFrom !== 'Immediately' ? Check.website.publishTo > Check.website.publishFrom : true
      if (Check.website.publishTo === 'Never' || previousUnpublish) {
        try {
          console.log("API CALLED....")
          const response = await saveSalah(dataObj, orgId, token);
          // UpdateSalah(obj, token, selectedSalah[0]._id)
          this.props.alertShow(success_message_salah)
          this.props.history.push("/website/manage-salah");
        } catch (err) {
          this.setState({ error: err.response && err.response.data.message })
          setTimeout(() => {
            this.setState({ error: false })
          }, 8000);
        }
      } else {
        this.setState({ dateError: true })
        setTimeout(() => {
          this.setState({ dateError: false })
        }, 8000)
      }
    };
    const getSteps = () => {
      return [
        { number: 1, label: "Select Salah" },
        { number: 2, label: "Publish" },
      ];
    }
    const style = {
      width: '100%',
    };
    return (
      <MasterLayoutMiddle
        header={
          <>
            {
              dateError &&
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={publish_date_before_unpublish}
                  alertType={"error"}
                  onClick={() => this.setState({ dateError: false })}
                />
              </div>
            }
            {
              this.state.error && this.state.error === "Invalid token" && (
                <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                  <AlertAction
                    alert={token_expired}
                    alertType={"error"}
                    onClick={() => this.setState({ error: false })}
                  />
                </div>
              )
            }
          </>
        }
        isForm={true}
        formData={
          Loading ?
            <div
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </div>
            :
            <>
              <BaseStepper
                title="Add Salah"
                getSteps={getSteps}
                alternativeLabel={true}
                handleBackPreviousPage={() => this.props.history.push("/website/manage-salah")}
                handleBack={() => this.handleBack()}
                handleNext={() => this.handleNext()}
                nonLinear={true}
                orientation={"horizontal"}
                btnStyles={styles.stepper}
                activeStep={activeStep}
              />
              {activeStep === 0 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Select Salah</h2>
                    <Typography style={styles.formTitleSub}>
                      Add “New Salah” from Home screen &gt; Manage &gt; Salah
                  </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={selectedSalah.length > 0 ? selectedSalah[0].salahName.fullName : ''}
                        InputProps={true}
                        helperText={obj.length ? "" : <p style={{ display: 'flex' }}>Salah not found. For create new salah click here: <p onClick={() => this.props.history.push('/salah')} className='addLinkTag' style={{ margin: 0, paddingLeft: '5px' }}>{' '} Add Salah </p></p>}
                        label="Select Salah"
                        placeholder=""
                        style={{ width: '100%' }}
                        onClick={() => {
                          this.setState({ openDrawerSalah: true, isSalahDrawer: true })
                          this.setState({ errorSalah: false })
                        }}
                        error={this.state.errorSalah || obj.length > 0 ? false : true}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      !selectedSalah.length ?
                        null
                        :
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "flex-start",
                          }}
                        >
                          {
                            <div className="wakt_feild">
                              <div>
                                <img
                                  src={componentImg}
                                  alt="componentmg"
                                  style={{ width: 24, height: 24 }}
                                />
                              </div>
                              <div>
                                <p>{`${waqt ? waqt : 'fajr'} waqt starts at ${selectedSalah.length > 0 ? Moment(selectedSalah[0]?.salahStart?.waktTime).utcOffset(0).format('hh:mm A') : ''}`}</p>
                                <p>
                                  <span>
                                    Method - {selectedSalah.length > 0 ? selectedSalah[0]?.settings?.salahMethod?.waqtMethod : ''}
                                  </span>{" "}
                                </p>
                              </div>
                            </div>
                          }
                          <div className="time_zone_field">
                            <img
                              src={componentImg}
                              alt="componentmg"
                              style={{ width: 24, height: 24 }}
                            />
                            <p>
                              {selectedSalah.length > 0 ? selectedSalah[0].settings.salahMethod.timezone : ''}
                            </p>
                          </div>
                        </div>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <div className="dasheddBorder_form"></div>
                  </Grid>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Location</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={selectedSalah.length > 0 ? selectedSalah[0].address.locationName : ''}
                        InputProps={true}
                        label="Location"
                        placeholder=""
                        disabled={true}
                       // helperText="Helper text"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="dasheddBorder_form"></div>
                  </Grid>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Date and Time</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={`${selectedSalah.length > 0 && selectedSalah[0].salahStart.waktTime ? 
                          Moment(selectedSalah[0].salahStart.waktTime).format("MMM DD, YYYY") : ''}${selectedSalah.length > 0 ? 
                            Moment(selectedSalah[0].salahStart.iqamahTime).format("HH:mm:ss") && selectedSalah[0].salahStart.iqamahTime ? ', ' : '' : ''}${selectedSalah.length > 0 ? 
                              Moment(selectedSalah[0].salahStart.iqamahTime).utcOffset(0).format("h:mm A") : ''}`}
                        label="Start Date and Time"
                        placeholder=""
                        disabled={true}
                        style={{ width: '100%' }}
                       // helperText="Helper text"
                        onClick={() =>
                          this.setState({ openDrawer: true, isStartDate: true })
                        }
                        InputProps={true}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={`${selectedSalah.length > 0 && selectedSalah[0].salahEnd ? 
                          Moment(selectedSalah[0].salahEnd).format('MMM DD, YYYY') : ''}${selectedSalah.length > 0 ? 
                            Moment(selectedSalah[0].salahEnd).format("HH:mm:ss") && selectedSalah[0].salahEnd ? ', ' : '' : ''}${selectedSalah.length > 0 ? 
                              Moment(selectedSalah[0].salahEnd).utcOffset(0).format('h:mm A') : ''}`}
                       // helperText="Helper text"
                        label="End Date and Time"
                        placeholder=""
                        disabled={true}
                        style={{ width: '100%' }}
                        InputProps={true}
                        onClick={() =>
                          this.setState({ openDrawer: true, isEndDate: true })
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={`${selectedSalah.length > 0 ? selectedSalah[0].repeat.category : ''}`}
                        label="Repeat"
                        placeholder=""
                        style={{ width: '100%' }}
                        disabled={true}
                        InputProps={true}
                       // helperText="Helper text"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      className="addSalah"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div className="form_button">
                        <p
                          className="cancel"
                          onClick={() => {
                            this.props.history.push("/website/manage-salah");
                          }}
                        >
                          CANCEL
                        </p>
                        <BaseButton
                          text="CONTINUE"
                          onClick={() => this.handleNext()}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
              {activeStep === 1 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className="field_box ManageSalahField">
                      <FormPublishUnpublish
                        visibility={visibility}
                        setVisibility={(e) => this.setState({ visibility: e })}
                        publish={this.state.publish}
                        unPublish={this.state.unPublish}
                        publishDate={this.state.publishDate}
                        publishTime={this.state.publishTime}
                        setPublishDate={(e) => this.setState({ setPublishDate: e })}
                        setPublishTime={(e) => this.setState({ setPublishTime: e })}
                        getPublishDate={getPublishDate}
                        getPublishTime={getPublishTime}
                        unPublishDate={unPublishDate}
                        unPublishTime={this.state.unPublishTime}
                        setUnPublishDate={(e) => this.setState({ setUnPublishDate: e })}
                        setUnPublishTime={(e) => this.setState({ setUnPublishTime: e })}
                        getUnPublishDate={getUnPublishDate}
                        getUnPublishTime={getUnPublishTime}
                        onVisibilitySave={onVisibilitySave}
                        onVisibilityCancel={onVisibilityCancel}
                        onPublishSave={onPublishSave}
                        onPublishCancel={onPublishCancel}
                        onUnPublishSave={onUnPublishSave}
                        onUnPublishCancel={onUnPublishCancel}
                        onSave={onSave}
                        onBack={onBack}
                        unPublishTimestamp={this.state.unPublishTimestamp}
                        showFeatured={false}
                        featuredText="Feature this on homepage"
                        checked={this.state.checked}
                        onSetChecked={() => this.setState({ SetChecked: !this.state.checked })}
                        minDateUnpublish={publishDate}
                        maxDatepublish={unPublishDate}
                        disabledSave={validUnPublish}
                        disabledSaveP={validPublish}
                       // helperText="Helper text"
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
              <DrawerMain
                open={openDrawerSalah}
                openHandler={closeDrawer}
                title="Salah Name"
                onCancel={onCancel}
                onSave={onSaveDrawer}
                loading={false}
              >
                {
                  obj.sort((a, b) => a.order - b.order).map((text, index) => {
                    console.log("text: ", text)
                    return (
                      <>
                        <BaseRadio
                          label={text.label}
                          checked={text.select}
                          onChange={() => this.handleChange(text.label, index)}
                          subDetails={text.detail}
                        />
                        <div className="dasheddBorder" style={{ margin: 0 }}></div>
                      </>
                    )
                  })
                }
              </DrawerMain>
            </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={true}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
  alertHide: (res) => dispatch(hideAlert(res)),

});

export default connect(mapStateToProps, mapDispatchToProps)(AddSalah);
