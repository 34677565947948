import React, { useRef, useEffect, useState } from "react";
import {
  BaseTextField,
  PageHeaderWithTabs,
  FormSwitch,
  BaseCheckBox,
  SimpleTable,
  GridContainer, GridItem, AlertDialogue, EmptyTable,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction,
  AlertDialogueAdvance,
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Moment from "moment";
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import Handle from "../../assets/img/more_vert-24px.svg";
import "./style.css";
import { getAllServices, getOrganizationData } from "../Organization/api";
import { DeleteServicesWebsite, deleteServicesWithAppId, getServicesWithAppId, sortServicesWebsite } from "./api";
import { saveAsDraft } from "../../components/DonationForm/Apis";
import { connect } from "react-redux";
import { PagesAction } from "../../redux/actions/pages";
import { CircularProgress } from "@material-ui/core";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
const {
  token_expired,
  Your_changes_has_been_published,
  Your_changes_has_been_saved_as_draft,
  quick_access_limit_axeeded,
  remove_service_message
} = messages_content;
function Services(props) {
  const history = props.history;
  const [Title, setTitle] = React.useState('')
  const [ErrorFound, setErrorFound] = useState(false)
  const [Loader, setLoader] = React.useState(true)
  const [rowData, setRowData] = React.useState([])
  const [value, setValue] = React.useState(0);
  const [servicesList, setServicesList] = React.useState([]);
  const [specificRow, setSpecificRow] = React.useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState(false);
  const [getNewData, setNewData] = useState(false);
  const [dischard_changes, setdischard_changes] = useState(0);
  const [homepageButton, setHomePageButton] = useState(false);
  const [checked, setChecked] = useState(true);
  const [organizationData, setOrganizationData] = useState([])
  const [QuickLimit, setQuickLimit] = useState(false);
  const [changes, setCheckChanges] = useState(false);
  const [pagesAll, setPagesAll] = useState({})
  const unblockHandle = useRef();
  const [integrateData, setIntegrateData] = useState({});
  const [saved_route, setRoute] = useState('');
  const [open, setAlertModal] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      props.alertHide()
    }, 8000)
  }, [props.alert.alertValue])
  useEffect(() => {
    unblockHandle.current = history.block((route) => {
      setRoute(route.pathname)
      // changes && visibleModal()
      setAlertModal(false)
      setCheckChanges(false)
      return changes === true ? false : true
    });
    return () => {
      unblockHandle.current = false
      !window.location.pathname.includes('service') && localStorage.removeItem('services')
    }
  }, [changes])
  function visibleModal() {
    setAlertModal(false)
  }
  // console.log("changes", changes, open)
  useEffect(() => {

    getWebsiteServices()
  }, [])
  useEffect(() => {
    const page_no = localStorage.getItem('services')
    setValue(page_no ? +page_no : 0);
    const token = localStorage.getItem('authToken')
    const orgId = localStorage.getItem('orgID')
    async function getorganization() {
      try {
        const getOraganization = await getOrganizationData(orgId, token);
        const orgIntegrate = getOraganization?.data?.body?.data?.enabledIntegrations;
        const data = orgIntegrate?.deenweb?.pages;
        const oraganization_data = data?.services;
        setIntegrateData(orgIntegrate);
        setPagesAll(data);
        setOrganizationData(oraganization_data);
        setTitle(oraganization_data.title);
        setHomePageButton(oraganization_data.enablePage)
        setChecked(oraganization_data.quickAccess)
        setNewData(false)
        setLoader(false)
      }
      catch (error) {
        setLoader(false)
        seterror(error.response && error.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    }
    getorganization()
  }, [getNewData])
  const handleChange = (event, newValue) => {
    changes && (Title === '' ? setErrorFound(true) : setAlertModal(true))
    !changes && setValue(newValue);
    !changes && localStorage.setItem('services', newValue)
    setdischard_changes(newValue)
  };
  const discardChanges = () => {
    setCheckChanges(false)
    setAlertModal(false)
    setValue(dischard_changes)
    setChecked(organizationData.quickAccess);
    setHomePageButton(organizationData.enablePage)
    setTitle(organizationData.title)
    if (unblockHandle) {
      unblockHandle.current()
    }
    if (saved_route) {
      props.history.push(saved_route)
    }
  };
  async function getWebsiteServices() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const response = await getServicesWithAppId(token, orgId, 'deenweb')
      // const response1 = await getAllServices(token);
      // const response = await getOrganizationData(orgId);
      console.log(response)
      const dataget = response.data.body.data;
      const sort = dataget.sort((a, b) => {
        return new Date(b.service.order) - new Date(a.service.order)
      })
      // let filterData = sort.filter(text => text.website);
      setRowData(sort);
      let data = [];
      sort.map((text, i) => {
        const dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("h:mm A") + ' (EST)'];
        const dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("h:mm A") + ' (EST)'];
        const UnpublishDate = new Date(dateEnd).getFullYear()
        const status = text.status
        const statusChangeStart = new Date(dateStart) <= new Date();
        const statusChangeEnd = new Date(dateEnd) <= new Date();
        let array = [];
        array = [
          { type: "id", value: `${i <= 8 ? '0' + (i + 1) : i + 1}`, _id: text._id, serviceId: text.service._id },
          {
            type: "url",
            name: "single_pic",
            src: text.service.imageMetaData.imageUrl,
          },
          {
            type: "string",
            name: "name",
            value: text.service.name,
          },
          {
            type: "string",
            name: "vertical",
            value: dateStart,
          },
          {
            type: "string",
            name: UnpublishDate === 2099 ? "frequency" : "vertical",
            value: UnpublishDate === 2099 ? "Never" : dateEnd
          },
          {
            type: "status",
            status: status === 'Hidden' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'pending' : 'schedule' : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',
            value: status === 'Hidden' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Unpublished' : 'Scheduled'
              : statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled'
          },
        ]
        data.push(array)
      })
      setServicesList(data);
      setLoader(false)
    } catch (error) {
      setLoader(false)
      seterror(error.response && error.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const checkChanges = (boolean) => {
    setCheckChanges(boolean)
  }
  const object = [
    {
      label: "Contents",
      item: "Contents",
    },
    {
      label: "Page setup",
      item: "Page setup",
    },

  ];
  const Alertstyle = {
    width: 470,
    marginLeft: '35%'
  };
  const saveChanges = async () => {
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    if (Title === '') {
      setErrorFound(true)
    } else {
      // const data = {
      //   "addSelectedArrayData": {
      //       "enabledIntegrations.deenweb.pages.services": {
      //         title: Title,
      //         status: homepageButton ? 'active' : 'inactive',
      //         enablePage: homepageButton,
      //         quickAccess: checked,
      //         lastEdited: new Date(),
      //       }
      //     },
      //   }
      const data = {
        enabledIntegrations: {
          ...integrateData,
          deenweb: {
            ...integrateData.deenweb,
            pages: {
              ...pagesAll,
              services: {
                title: Title,
                status: homepageButton ? 'active' : 'inactive',
                enablePage: homepageButton,
                quickAccess: checked,
                lastEdited: new Date(),
                pageName: 'About Us',
              }
            }
          }
        }
      }
      try {
        await saveAsDraft(orgId, data, token);
        setshowAlert(true);
        setsuccess(true)
        setValue(dischard_changes);
        setAlertModal(false)
        setCheckChanges(false)
        if (saved_route) {
          props.history.push(saved_route)
        }
      } catch (err) {
        setAlertModal(false)
        seterror(err.response && err.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    }
  };

  const publish = async () => {
    let pages = props?.pages?.pages
    let lengthOfPages = pages.filter(text => text.quickAccess === true);
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    if (Title === '') {
      setErrorFound(true)
    } else {
      const data = {
        enabledIntegrations: {
          ...integrateData,
          deenweb: {
            ...integrateData.deenweb,
            pages: {
              ...pagesAll,
              services: {
                title: Title,
                status: homepageButton ? 'active' : 'inactive',
                enablePage: homepageButton,
                quickAccess: checked,
                lastEdited: new Date(),
                pageName: 'About Us',
              }
            }
          }
        }
      }
      if (!checked || organizationData.quickAccess || lengthOfPages.length < 6) {
        try {
          await saveAsDraft(orgId, data, token);
          setshowAlert(true);
          setsuccess('Publish');
          setCheckChanges(false)
          let Quick = pages.filter(text => text.name[0] !== "services")
          Quick.push({
            name: ['services'],
            quickAccess: checked
          })
          props.dispatchPagesAction(Quick)
        } catch (err) {
          seterror(err.response && err.response.data.message)
          setTimeout(() => {
            seterror(false)
          }, 8000);
        }
      } else {
        setQuickLimit(true)
        setTimeout(() => {
          setQuickLimit(false)
        }, 8000)
      }
    }
  };
  const saveStyles = {
    backgroundColor: "#2A82F6",
  };
  const publishStyles = {
    backgroundColor: "#2A82F6",
    opacity: 0.6,
  };
  const paperStyles = {};
  const styles = {
    BaseTextField: {
      width: "100%",
    },
    contents: {
      paddingTop: 20,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "100%",
      marginTop: 70,
      marginLeft: 280,
    },
  };
  const DeleteAlertFunc = (e) => {
    setSpecificRow(e);
    setOpenAlert(true);
  }
  const handleCancel = () => {
    setOpenAlert(false);
  }
  const handleClose = () => {
    setOpenAlert(false);
  };
  const deleteServiceAction = async (e) => {
    setOpenAlert(false);
    setLoader(true)
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");

    try {
      await deleteServicesWithAppId(token, orgId, 'deenweb', e[0].serviceId)
      props.alertShow(remove_service_message)
      const stateNews = servicesList
      const NewsIndex = servicesList.indexOf(e)
      stateNews.splice(NewsIndex, 1)
      setLoader(false)
    } catch (err) {
      setLoader(false)
      seterror(error.response && error.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { id: "drag", label: " ", width: '5%' },
    { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
    { type: "link", name: "invoice_number", label: "Image", width: 'auto' },
    { type: "string", name: "name", label: "Service name", width: '50%' },
    { type: "string", name: "date/time", label: "Publish Date", width: '15%' },
    {
      type: "string",
      name: "date/time",
      label: "UnPublish Date",
      width: '15%',
    },
    { type: "string", name: "date", label: "STATUS", width: '15%' },
    { type: "action", name: "action", label: "ACTION", width: '15%' },
  ];
  const style = {
    width: 470,
    marginLeft: 800
  };
  const handleCloseDraft = () => {
    setAlertModal(false)
  };
  const sortData = async (data) => {
    const token = localStorage.getItem("authToken");
    let OBJ = {
      sortedList: data.map(text => {
        return (
          { _id: text._id, order: text.order }
        )
      })
    }
    try {
      await sortServicesWebsite(token, OBJ)
    } catch (err) {
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
    const sortService = data.sort((a, b) => a.order - b.order)
    let dataN = [];
    sortService.map((text, i) => {
      const dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("h:mm A")];
      const dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("h:mm A")];
      const UnpublishDate = new Date(dateEnd).getFullYear()
      const status = text.status
      const statusChangeStart = new Date(dateStart) <= new Date();
      const statusChangeEnd = new Date(dateEnd) <= new Date();
      let array = [];
      array = [
        { type: "id", value: `${i <= 8 ? '0' + (i + 1) : i + 1}`, _id: text._id, serviceId: text.service._id },
        {
          type: "url",
          name: "single_pic",
          src: text.service.imageMetaData.imageUrl,
        },
        {
          type: "string",
          name: "name",
          value: text.service.name,
        },
        {
          type: "string",
          name: "vertical",
          value: dateStart,
        },
        {
          type: "string",
          name: UnpublishDate === 2099 ? "frequency" : "vertical",
          value: UnpublishDate === 2099 ? "Never" : dateEnd
        },
        {
          type: "status", status: status === 'Hidden' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'pending' : 'schedule'
            : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',
          value: status === 'Hidden' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Unpublished' : 'Scheduled'
            : statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled'
        },
      ]
      dataN.push(array)
    })
    setServicesList(dataN)
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          <PageHeaderWithTabs
            back={() => props.history.push('/website/pages')}
            value={value}
            title="Services"
            object={object}
            disabled={changes ? false : true}
            indicatorColor="primary"
            style={{ color: "#2A82F6", border: "1px solid black" }}
            handleChange={(x, y) => handleChange(x, y)}
            indicatorColor="#2A82F6"
            saveStyles={saveStyles}
            publishStyles={publishStyles}
            hiddenDraft={true}
            paperStyles={paperStyles}
            saveChanges={() => saveChanges()}
            publish={() => publish()}
          />
          {
            showAlert && success && success === 'Publish' ? (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={Your_changes_has_been_published}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            ) : showAlert && success && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={Your_changes_has_been_saved_as_draft}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            )
          }
          {
            props.alert.alertValue &&
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
              <AlertAction
                alert={props.alert.alertValue}
                alertType={"success"}
                onClick={() => props.alertHide()}
              />
            </div>
          }
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            QuickLimit && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={quick_access_limit_axeeded}
                  alertType={"error"}
                  onClick={() => setQuickLimit(false)}
                />
              </div>
            )
          }
        </>
      }
      content={
        Loader ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <>
            <div>
              <div>
                <AlertDialogueAdvance
                  title="You have unsaved changes!"
                  description='There are unsaved changes. If you would like to save changes, press the "SAVE AS DRAFT" button.'
                  cancelText="Discard Changes"
                  confirmText="SAVE AS DRAFT"
                  open={open}
                  handleCancel={() => saveChanges()}
                  handleClose={() => handleCloseDraft()}
                  handleConfirm={() => discardChanges()}
                  styles={Alertstyle}
                />
              </div>
              {value === 1 &&
                <div className="page_body">
                  <Typography className="page_form_title">Page Settings</Typography>
                  <Grid className="form_body">
                    <Grid container item xs={6} spacing={3} >
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={Title}
                            onChange={(e) => {
                              setTitle(e.target.value); checkChanges(true); setErrorFound(false)
                            }}
                            InputProps={false}
                            phone={false}
                            search={false}
                            count={20}
                            error={ErrorFound}
                            style={styles.BaseTextField}
                            color="primary"
                            disabled={false}
                            //  helperText="Helper text"
                            label="Page Title"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="page_switch">
                          <FormSwitch
                            style={styles.BaseTextField}
                            headline="Enable Page"
                            body="Disabled pages can’t be accessed by your site visitors."
                            values={homepageButton}
                            handleSwitchChange={() => { setHomePageButton(!homepageButton); checkChanges(true) }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="SolidBorder"></div>
                        <div className="checkBox">
                          <BaseCheckBox
                            label="Quick Access"
                            checked={checked}
                            color="secondary"
                            showHelperText={true}
                            helperText="Add to left navigation under QUICK ACCESS for quickly accessing the page"
                            onChange={(e) => { setChecked(e.target.checked); checkChanges(true) }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              }
              {value === 0 && (
                servicesList.length > 0 ?
                  <GridContainer>
                    <GridItem itemStyle={{ marginTop: 0 }} xs={12} itemClass="web-salah">
                      <SimpleTable
                        showHeader={true}
                        title="Services"
                        rows={servicesList}
                        parentTableDrag={true}
                        DragIcon={Handle}
                        columns={col}
                        colSpan={16}
                        actionSendButton={true}
                        classProps={changeClassProps()}
                        donationsCount={servicesList?.length > 0 ? 1 : 0}
                        noTitle="No Services added"
                        noPara="Added Services will be displayed and managed here"
                        noBtnText="Add New Services"
                        onInviteClick={() =>
                          props.history.push("/website/add-service")
                        }
                        showIconCol={true}
                        editIcon={Edit}
                        deleteIcon={Delete}
                        editAction={(e) =>
                          props.history.push(`/website/edit-service/${e[0]._id}`)
                        }
                        deleteAction={(e) => DeleteAlertFunc(e)}
                        showFooter={true}
                        showAdd={true}
                        rowsPerPage={5}
                        addText="ADD NEW"
                        onAddClick={() => props.history.push("/website/add-services")}
                        iconTextSize={16}
                        sortData={(e) => sortData(e)}
                        data={rowData}
                        sortColumnIndex={[4]}
                      />
                    </GridItem>
                    <GridItem xs={12} itemStyle={{ paddingTop: 0 }}>
                      <div style={{ display: "flex", marginTop: 0 }}>
                        <p className="cat-lower" style={{ marginTop: 0 }}>
                          Change the order of service to display to users. Click on{" "}
                        </p>
                        <img src={Handle} alt="" style={{ display: "flex", marginTop: -10 }} />
                        <p className="cat-lower" style={{ marginTop: 0 }}>to drag and drop within the table.</p>
                      </div>
                    </GridItem>
                  </GridContainer>
                  :
                  <EmptyTable
                    title="Services"
                    onAddClick={() => props.history.push("/website/add-services")}
                    addLabel="ADD NEW"
                    noBtnText="ADD NEW"
                  />
              )}
              <div>
                <AlertDialogue
                  title="Delete Service?"
                  description="Are you sure you want to delete this Service?"
                  description2="Deleting will permanently remove from Website."
                  cancelText="Cancel"
                  confirmText="Confirm"
                  open={openAlert}
                  handleCancel={() => handleCancel()}
                  handleClose={() => handleClose()}
                  handleConfirm={() => deleteServiceAction(specificRow)}
                  styles={style}
                />
              </div>
            </div>
          </>
      }
      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  );
}
const mapStateToProps = (state) => {
  return {
    pages: state.pages,
    alert: state.alert,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatchPagesAction: (data) => dispatch(PagesAction(data)),
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Services);
