import React, { useState, useEffect } from 'react';
import { BaseSelect, DropdownMultiSelect, BaseTextField } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';


function Publish(props) {
    const history = useHistory()
    const [values, setValues] = React.useState({
        age: "",
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const object = [
        {
            value: "10",
            label: "Ten",
        },
        {
            value: "20",
            label: "Twenty",
        },
        {
            value: "30",
            label: "Thirty",
        },
    ];






    const [valuesCoupon, setValuesCoupon] = React.useState({
        name: "hello",
    });

    const handleChangeCoupon = (prop) => (event) => {
        setValuesCoupon({

        });
    };

    const viewChange = (newValue) => {
        console.log("Coming from View Change: ", newValue);
    };

    const Listdata = [
        {
            groupContacts: [],
            title: "NEWENROLLED25 (25% off)"
        },
        {
            groupContacts: [],
            title: "NEWENROLLED25 (20% off)"
        },
        {
            groupContacts: [],
            title: "NEWENROLLED25 (50% off)"
        },

    ];



    const styles = {
        select: {
            margin: 8,
            minWidth: "100%",
            height: 66,
            width: 120,
            margin: 0,
            border: "#D3D8DA",
            width: "100%",
        }
    };






    return (

        <div className='StepperFormCtn'>

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <h2 className="sub_title">Course Status</h2>
                </Grid>

                <Grid item xs={12}>
                    <div className="field_box" >
                        <BaseTextField
                            label="Price"
                            placeholder=""
                            helperText=""
                            InputProps={true}
                            onClick={() => history.push("/education/course-details")}
                        />
                    </div>
                </Grid>




            </Grid>


        </div>

    );
}


export default Publish;