import React  from "react";
import Moment from 'react-moment';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ReactPlayer from 'react-player'
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';

import YouTubeIcon from '@material-ui/icons/YouTube';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

import './style.css';


const useStyles = makeStyles((theme) => ({

  CardContainer: {
    width: "119px",
    height: "119px",
    display: "inline-block",
    borderRadius: "0px",
    padding: "0",
    overflow: "hidden",
    margin: "2px !important",
    float: "left",
  },


  PackageCardContainer: {
    margin: "0px -5px",

    "& .MuiToggleButtonGroup-root": {
      display: "block",
      borderRadius: "0px",
    },

    "& .MuiToggleButton-root": {
      border: "#E4E8EC 1px solid",
    },

    "& .Packagebadge": {
      backgroundColor: "transparent",
      borderRadius: "100px",
      width: "12px",
      height: "12px",
      position: "absolute",
      right: "10px",
      top: "10px",
      padding: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "999",
    },

    "& .BadgeIcon": {
      fontSize: "14px",
      color: "#f5f7f9",
      display: "none",
    },

    "& .MedialibImg": {
      width: "100%",
      objectFit: "cover",
      height: "100% !important",
      position: "absolute",
      left: 0,
      top: 0,
    },

    "& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
      marginLeft: "2px",
    },

    "& .MuiToggleButton-root.Mui-selected + .MuiToggleButton-root.Mui-selected": {
      marginLeft: "2px",
      borderLeft: "#2A82F6 1px solid",
    },




    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: "transparent",
      border: "#2A82F6 1px solid",

      "& .Packagebadge": {
        backgroundColor: "#2A82F6",
      },

      "& .BadgeIcon": {
        color: "#fff",
        display: "block",
      },

      "& .MedialibImg": {
        opacity: 0.6,
      },


    },

  },



}));

export function RenderLoadingIcon(props){
  // console.log( props.showloading );
  if( props.showloading ){
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 100,
        }}
      >
        <CircularProgress color="primary" />
      </div>
    );
  }else{
    return (
      ''
    );
  }
  
}



function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};





export function SingleMedia(props){  
  const [imageprogress, setImageprogress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setImageprogress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 1));
    }, 1200);
    return () => {
      clearInterval(timer);
    };
  }, []);
    
    if( props.object.isVideo ){
      return ( 
        <div>
          <div className="Packagebadge"><CheckRoundedIcon className="BadgeIcon" /></div>
            <div src={props.object.metaData.videoUrl} mediatitle={props.object.title} onClick={props.handler} isvideo="1" ikey={props.index} objnr={props.index} mediaid={props.object._id}  objnr={props.index}  className="youtubeVideoBox">
            <YouTubeIcon className="iconYoutube" />
            <p src={props.object.metaData.videoUrl} ikey={props.index} objnr={props.index} mediatitle={props.object.title} isvideo="1"  >{props.object.title}</p>
          </div>
        </div>
      ) ;
    }else{
      return (
        <div>
          { props.object._id == '-1'? (
            <div className="ImgUploadingPrograss"><LinearProgressWithLabel value={imageprogress} /></div>
          ): (
            <div>
              <div className="Packagebadge"><CheckRoundedIcon className="BadgeIcon" /></div>
              <img isvideo="0" onClick={props.handler} mediatitle={props.object.title}  mediaid={props.object._id}   objnr={props.index} ikey={props.index} className="MedialibImg" src={props.object.mediaURL} alt="media" />
            </div>
          ) }
        </div>
      ) ;
    }      
    
  };
  
  export  function MediaGroup(props){    
    const classes = useStyles();   
    const [alignment , setAlignment ]   = React.useState();  
    
    const handleAlignment = async (e , nv )=>{
      // console.log("handleAlignment", e, nv)
      
    }
    const mediaClickEvent = async (e ) => {
      props.handler( e );
      let newEvent = parseInt( e.target.getAttribute('ikey') )  ;
      newEvent = alignment === newEvent ? null : newEvent;
      // console.log( "new", newEvent );
      setAlignment( newEvent );
    } ;
    
    return (
      <div ref={props.refitem} className={classes.PackageCardContainer}>
        <ToggleButtonGroup
            value={alignment} exclusive                 
            onChange={handleAlignment}> 
        {props.mediaList.map( (object , index ) =>(                    
            <ToggleButton aria-label="left aligned" key={index} value={index} className={classes.CardContainer} selected={alignment === index ? true : false}>                
                <SingleMedia object={object} handler={mediaClickEvent}  index={index} />
          </ToggleButton>           
          
        ))}
        </ToggleButtonGroup>
      </div>
    );
  }

  

  export function TabPanel(props) {

    const { children, value, index } = props;
    return (
      <div>
        {value === index && (
          <>{children}</>
        )}
      </div>
    );
  }

  export function MediaDetails(props){
    if( props.object.isVideo ){
      return (
        <div>
          <li><h3>Title:</h3> <p>{ text_truncate(props.object.title, 40 ) }</p></li>
          <li><h3>File type:</h3> <p>{props.object.imageMetaData.fileType}</p></li>
          <li><h3>Uploaded on:</h3> <p><Moment format="YYYY/MM/DD HH:mm A" date={props.object.createdAt} /> </p></li>
          <li><h3>Duration:</h3> <p>{props.object.metaData.duration}</p></li>          
        </div>
      );
    }else{
      return (
        <div>
          <li><h3>File name:</h3> <p>{props.object?.imageMetaData?.imageName ? props.object.imageMetaData.imageName : '' }</p></li>
          <li><h3>File type:</h3> <p>{props.object?.imageMetaData?.fileType ? props.object.imageMetaData.fileType: '' }</p></li>
          <li><h3>Uploaded on:</h3> <p><Moment format="YYYY/MM/DD HH:mm A" date={props.object.createdAt} /> </p></li>
          <li><h3>File size:</h3> <p>{props.object?.imageMetaData?.imageSize ? props.object.imageMetaData.imageSize: '' }</p> kb</li>
          {/* <li><h3>Dimension:</h3> <p>{props.object.imageMetaData.dimension}</p></li> */}
        </div>
      );
    }

    
  }

  export function DisplayRightDrawerMedia(props){
    if(props.isVideo){
      return (
      <div className="FeatureImgContainer">
          <ReactPlayer width="250px" height="150px" url={props.detailsMedia.metaData.videoUrl} />
      </div>
      ); 
    }else{
      return (
        <div className="FeatureImgContainer">
          <img src={props.detailsMedia.mediaURL} />
        </div>
      );
    }
    
  }

  export function MediaStoreTotal(props){
    
    if( props.objects.percentage < 75 ){
      return (
        <div className="StorageDetails">
          <div className="BootmInfodetails">
              <li>
                <p>Storage</p>
                <p>{props.objects.percentage.toFixed(2)}% used</p>
              </li>
              <h4>{props.objects.totalSize.toFixed(2)} MB of {props.objects.maxLimit}MB</h4>          
          
          </div>
  
          <div className="BootmInfodetails">
            <li>
              <p>Total Media Item</p>
            </li>
            <h4>{props.objects.totalItems}</h4>
          </div>
        </div>
      )
    }else{
      return (
        <div className="StorageDetails">
          <div className="BootmInfodetails">
              <li>
                <p>Storage</p>
                <p>{props.objects.percentage.toFixed(2)}% used</p>
              </li>
              <h4>{props.objects.totalSize.toFixed(2)} MB of {props.objects.maxLimit}MB</h4>
              <div className="ProgressbarCol">
                <LinearProgress
                  value={props.objects.percentage}
                  variant="buffer"  
                  color="secondary" 
                  valueBuffer={20}
                />
  
                <p>Storage is almost full. Upgrade to get more space.</p>
              </div>
              <Button  className="upgradeNowButton">Upgrade now</Button>
              
          
          </div>
  
          <div className="BootmInfodetails">
            <li>
              <p>Total Media Item</p>
            </li>
            <h4>{props.objects.totalItems}</h4>
          </div>
        </div>
      );
    }
    
  }

  function text_truncate (str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  }
  export default SingleMedia ; 

  