import React, { Component } from 'react'
import { SimpleTable } from "@dclab/dc-react-ui-kit";
import Edit from "../../../assets/img/create-24px.svg";
import Delete from "../../../assets/img/delete_forever-24px.svg";
import '../../../App.css'
export default class Announcements extends Component {
  render() {
    const classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    const col = [
      { type: "number", name: "serial_number", label: "NO.", width: "auto" },
      { type: "link", name: "invoice_number", label: "Title", },
      { type: "string", name: "name", label: "Description", },
      { type: "string", name: "date", label: "Publish Date", },
      { type: "string", name: "date/time", label: "UnPublish Date", },
      { type: "string", name: "date", label: "STATUS", },
      { type: "action", name: "action", label: "ACTION", },
    ];
    const rows = [
      [
        { type: "id", value: "01" },
        { type: "string", name: "name", value: "Ramadan is on our doorstep.", },
        { type: "string", name: "frequency", value: "It is essential to be in the best of forms, both physically..", },
        { type: "string", name: "date", value: "Jan 23, 2020 8:00 AM (EST)" },
        { type: "string", name: "date/time", value: "Jul 23, 2020 9:00 AM (EST)" },
        { type: "status", status: "success", value: "Published" },
      ],
      [
        { type: "id", value: "02" },
        { type: "string", name: "name", value: "Ramadan is on our doorstep.", },
        { type: "string", name: "frequency", value: "It is essential to be in the best of forms, both physically..", },
        { type: "string", name: "date", value: "Jan 23, 2020 8:00 AM (EST)" },
        { type: "string", name: "date/time", value: "Jul 23, 2020 9:00 AM (EST)" },
        { type: "status", status: "success", value: "Published" },
      ],
      [
        { type: "id", value: "03" },
        { type: "string", name: "name", value: "Ramadan is on our doorstep.", },
        { type: "string", name: "frequency", value: "It is essential to be in the best of forms, both physically..", },
        { type: "string", name: "date", value: "Jan 23, 2020 8:00 AM (EST)" },
        { type: "string", name: "date/time", value: "Jul 23, 2020 9:00 AM (EST)" },
        { type: "status", status: "success", value: "Published" },
      ],
    ];
    return (
      <div>
        <SimpleTable
          showHeader={true}
          title="Announcements"
          width="100%"
          rows={rows}
          columns={col}
          colSpan={16}
          actionSendButton={true}
          classProps={changeClassProps()}
          donationsCount={rows && rows.length > 0 ? 1 : 0}
          noTitle="No Announcements added"
          noPara="Added Announcements will be displayed and managed here"
          noBtnText="Add New Announcements"
          onInviteClick={() =>
            this.props.history.push(
              "/organization/services/add-new-service"
            )
          }
          actionLink={(name) => this.details(name)}
          showIconCol={true}
          editIcon={Edit}
          deleteIcon={Delete}
          editAction={(e) => this.editService(e)}
          deleteAction={(e) => this.deleteServiceAction(e)}
          showFooter={true}
          showAdd={true}
          addText="ADD"
          onAddClick={() =>
            this.props.history.push(
              "/organization/services/add-new-service"
            )
          }
          iconTextSize={16}
        />
      </div>
    )
  }
}
