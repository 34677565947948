import React, { Component } from "react";
import { connect } from "react-redux";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { emptyAnnoucement } from "../../../redux/actions/annoucementAction";
import {
  BaseTextField,
  BaseButton,
  BaseSwitch,
  BaseCheckBox,
  AlertAction,
  BaseFileSelect,
  MasterLayoutMiddle,
  FooterInner,
  TextEditor,
  FormSwitch
} from "@dclab/dc-react-ui-kit";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./style.css";
import "../../Organization/style.css";
import { updateAnnoucement } from "./Apis/index";
import Grid from '@material-ui/core/Grid';
import { showAlert, emptyAlert } from '../../../redux/actions/alertActions';
import messages_content from '../../../utils/messages.json';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
const { 
    token_expired,
    success_message_on_update_announcement,
    announcement_title_already_exist
  } = messages_content;
class EditAnnoucement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAlert: false,
      originalPublished: false,
      annoucementTitle: "",
      annoucementTitleCount: 0,
      annoucementDescription: [{ type: "paragraph", children: [{ text: "" }] }],
      featured: false,
      descriptionCount: 0,
      showAlert: false,
      success: false,
      error: false,
      openDrawer: false,
      reminderImage: [],
      image: [],
      loading: false,
      getAnnoucements: [],
      seError: false,
      isActionBtn: false,
      btnLabel: "",
      btnLabelCount: 0,
      link: "",
      annoucement_id: "",
    };
    this.onDescriptionStateChange = this.onDescriptionStateChange.bind(this);
    this.addAnnoucement = this.addAnnoucement.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }
  async componentDidMount() {
    const { editAnnoucements } = this.props;
    console.log(editAnnoucements, 'loop')
    if (
      typeof editAnnoucements !== "undefined" &&
      Boolean(Object.keys(editAnnoucements)[0])
    ) {
      const description = JSON.parse(editAnnoucements.description);
      let sum = 0;
      description.map((st) => {
        if (st.type === "bulleted-list" || st.type === "numbered-list") {
          st.children.map((e) => {
            e.children.map((ch) => {
              sum += ch.text.length;
            });
          });
        } else {
          st.children.map((e) => {
            sum += e.text.length;
          });
        }
      });
      this.setState({
        annoucementTitle: editAnnoucements.title,
        annoucementDescription: description,
        featured: editAnnoucements.status === 'true',
        originalPublished: editAnnoucements.status === 'true',
        annoucementTitleCount: editAnnoucements.title.length,
        descriptionCount: sum,
        annoucement_id: editAnnoucements._id,
        isActionBtn: editAnnoucements.button.actionButton ? true : false,
        btnLabel: editAnnoucements.button.buttonLabel,
        btnLabelCount: editAnnoucements.button.buttonLabel.length,
        link: editAnnoucements.button.url,
      });
    } else {
      this.props.history.push("/annoucements");
    }
  }
  changeHandler = (name, val) => {
    const { btnLabelCount } = this.state;
    if (name === "name") {
      if (val.length < 36) {
        if (/^(?![\s.]+$)[a-zA-Z\s\-.]*$/.test(val)) {
          this.setState({
            annoucementTitle: val,
            annoucementTitleCount: val.length,
          });
        } else {
          return false;
        }
      }
    }
    if (name === "btnLabel") {
      if (btnLabelCount < 14) {
        if (/^(?![\s.]+$)[a-zA-Z\s.]*$/.test(val)) {
          this.setState({ btnLabel: val, btnLabelCount: val.length });
        } else {
          return false;
        }
      } else {
        this.setState({ btnLabelCount: val.length });
      }
    }
  };
  onDescriptionStateChange = (editorState) => {
    let sum = 0;
    editorState.map((st) => {
      if (st.type === "bulleted-list" || st.type === "numbered-list") {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    this.setState({
      annoucementDescription: editorState,
      descriptionCount: sum,
    });
  };
  addAnnoucement = async () => {
    this.setState({ loading: true });
    const URLregEx = /(https?:\/\/+)/g;
    const {
      annoucementDescription,
      btnLabel,
      link,
      annoucementTitle,
      featured,
      descriptionCount,
      getAnnoucements,
      annoucement_id,
      isActionBtn,
    } = this.state;
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    const obj = {
      title: annoucementTitle,
      description: JSON.stringify(annoucementDescription),
      status: featured,
      button: {
        actionButton: isActionBtn,
        buttonLabel: isActionBtn ? btnLabel : "",
        url: isActionBtn ? link : "",
      },
      orgId,
    };
    if (!annoucementTitle /* || descriptionCount === 0 */) {
      this.setState({
        error: true,
        loading: false,
        errorMessage: "Please enter url",
      });
    } else if ((isActionBtn && !btnLabel) || (isActionBtn && !link)) {
      this.setState({
        error: true,
        loading: false,
        errorMessage: "Please enter url",
      });
    } else if (link.length > 0 && URLregEx.test(link) === false) {
      this.setState({
        errorMessage: "Invalid URL",
        error: true,
        loading: false,
      });
    } else {
      let allAnnoucements = getAnnoucements.filter(
        (item) => item.title === annoucementTitle
      );
      if (allAnnoucements.length > 0) {
        this.setState({
          error: announcement_title_already_exist,
          loading: false,
          showAlert: true,
        });
      } else {
        try {
          await updateAnnoucement(obj, token, annoucement_id);
          this.setState({
            success: true,
            showAlert: true,
            featured: false,
            loading: false,
          });
          this.props.showAlert('editAnnoucement')
          this.props.history.push("/annoucements");
        } catch (error) {
          this.setState({
            error: error && error.response && error.response.data.message,
            showAlert: true,
            loading: false,
          });
        }
      }
    }
  };
  handleClose = () => {
    this.setState({ openAlert: false });
  };

  handleDeleteConfirm = () => {
    const {featured} = this.state;
    this.setState({ featured: !featured });
    this.setState({openAlert: false});
  }

  handleSwitch = () => {
    const {featured, originalPublished} = this.state;
    if(originalPublished && !featured === false) {
      this.setState({openAlert: true});
    } else {
      this.setState({ featured: !featured });
    }
  };
  render() {
    const {
      annoucementTitle,
      annoucementDescription,
      featured,
      descriptionCount,
      showAlert,
      success,
      error,
      openDrawer,
      image,
      reminderImage,
      loading,
      annoucementTitleCount,
      isActionBtn,
      btnLabelCount,
      btnLabel,
      link,
      errorMessage,
      openAlert,
    } = this.state;
    const addDiv = (
      <>
        <div className="overlay2"></div>
        <div className="add2">
          <div style={{ marginLeft: 30, marginTop: 20 }}>
            <h3>Select Image</h3>
            <div style={{ marginBottom: 20 }}>
              <BaseFileSelect selectedFiles={(file) => this.upload(file)} />
            </div>
            <div>
              <img
                src={image.length > 0 && image[0].src}
                style={{
                  width: image.length > 0 ? 260 : 0,
                  height: image.length > 0 ? 170 : 0,
                }}
              />
            </div>
            <div className="lowerDiv" style={{ marginTop: 20 }}>
              <div
                style={{
                  marginTop: 30,
                  display: "flex",
                  justifyContent: "flex-end",
                  width: 260,
                }}
              >
                <p
                  className="cancel"
                  onClick={() =>
                    this.setState({
                      openDrawer: false,
                    })
                  }
                >
                  CANCEL
                </p>
                <BaseButton text="Save" onClick={() => this.addLogo()} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
    const style = {
      width: "100%",
    };
    const textEditorStyle = {
      border: "1px solid black",
      color: "gray",
    };

    const alertEventModal = (
      <Dialog
        open={openAlert}
        onClose={() => this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Change status to inactive?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          This content is published in other applications/sites. <br></br>
          Changing to “Inactive” will remove from everywhere.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()} color="primary">
          CANCEL
          </Button>
          <Button onClick={() => this.handleDeleteConfirm()} color="primary">
          YES
          </Button>
        </DialogActions>
      </Dialog>
    );

    return (
      <MasterLayoutMiddle
        header={
          <>
            <div
              onClick={() => this.props.history.push("/annoucements")}
              className="backBtn"
            >
              <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
              <p style={{ alignSelf: "center" }}>Back</p>{" "}
            </div>
            {error && error === "Invalid token" && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => this.setState({ error: false })}
                />
              </div>
            )}
            {showAlert && error && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={
                    error === "Invalid token"
                      ? token_expired
                      : error
                  }
                  alertType={"error"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {showAlert && success && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={success_message_on_update_announcement}
                  alertType={"success"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {openAlert && alertEventModal}
          </>
        }
        isForm={true}
        formData={
          <>
            <div>
              <h1 className="title_top">Edit Announcement</h1>
              <div className="dasheddBorder"></div>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12}>  <h2 className="sub_title">Announcement Details</h2> </Grid>
              <Grid item xs={12}>
                <div className="field_box">
                  <BaseTextField
                    label="Annoucement Title"
                    style={{ width: 570 }}
                    helperText=" "
                    value={annoucementTitle}
                    onChange={(e) => this.changeHandler("name", e.target.value)}
                    error={!success && error && !annoucementTitle}
                    helperText={
                      !success &&
                      error &&
                      !annoucementTitle &&
                      "Please enter annoucement title"
                    }
                    placeholder=" "
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end", }} >
                    <span className="char-count"> CHAR {annoucementTitleCount}/35 </span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
              <div className="TextArea">
                <TextEditor
                  onChange={(e) => this.onDescriptionStateChange(e)}
                  disabled={false}
                  value={annoucementDescription}
                  style={textEditorStyle}
                  label={"Description (Optional)"}
                  placeholder="Add description here.."
                  // helperText={
                  //   error &&
                  //   !descriptionCount &&
                  //   "Please add announcement description"
                  // }
                  // error={error && !descriptionCount}
                  maxCharCount={100}
                />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="field_box border_top_bottm bottom_border_none">
                  <FormSwitch
                    headline='Add Action Button'
                    body="Add a button for user to click and navigate to a specific page/website"
                    values={isActionBtn}
                    handleSwitchChange={() => this.setState({ isActionBtn: !isActionBtn })}
                  />
                </div>

                {isActionBtn && (
                  <div
                    style={{
                      width: 570,
                      marginBottom: 20,
                      paddingBottom: 10,
                    }}
                  >
                    <BaseTextField
                      label="Button Label"
                      style={{ width: 570 }}
                      helperText=" "
                      value={btnLabel}
                      onChange={(e) =>
                        this.changeHandler("btnLabel", e.target.value)
                      }
                      error={!success && error && !btnLabel}
                      helperText={
                        isActionBtn &&
                        !success &&
                        error &&
                        !btnLabel &&
                        "Please enter button label"
                      }
                      placeholder=" "
                    />
                    <div
                      style={{
                        width: 570,
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: 10,
                      }}
                    >
                      <span className="char-count">CHAR {btnLabelCount}/15</span>
                    </div>
                    <BaseTextField
                      label="Add Link"
                      style={{ width: 570 }}
                      helperText=" "
                      value={link}
                      onChange={(e) => {
                        this.setState({ link: e.target.value });
                        if (
                          isActionBtn &&
                          !success &&
                          error &&
                          link &&
                          errorMessage === "Invalid URL"
                        ) {
                          this.setState({ errorMessage: "" });
                        }
                      }}
                      error={
                        (isActionBtn &&
                          !success &&
                          error &&
                          !link &&
                          errorMessage === "Please enter url") ||
                        (isActionBtn &&
                          !success &&
                          error &&
                          link &&
                          errorMessage === "Invalid URL")
                      }
                      helperText={
                        (isActionBtn &&
                          !success &&
                          error &&
                          !link &&
                          errorMessage === "Please enter url" &&
                          errorMessage) ||
                        (isActionBtn &&
                          !success &&
                          error &&
                          link &&
                          errorMessage === "Invalid URL" &&
                          errorMessage)
                      }
                      placeholder=" "
                    />
                  </div>
                )}
              </Grid>
              <Grid item xs={12} className="top_space_none">
                <div className="field_box border_top_bottm">
                  <FormSwitch
                    headline='Annoucement Status'
                    body="Enabling would make the announcement available for publishing"
                    values={featured}
                    handleSwitchChange={() => this.handleSwitch()}
                    
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <div
                    style={{
                      marginTop: 30,
                      display: "flex",
                      justifyContent: "center",
                      width: 500,
                    }}
                  >
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                    <div className="form_button">
                      <p className="cancel" onClick={() => this.props.history.push("/annoucements")} >CANCEL</p>
                      <BaseButton text="SAVE NOW" onClick={() => this.addAnnoucement()} />
                    </div>
                  )}
              </Grid>
            </Grid>
            {openDrawer && addDiv}
          </>
        }
        isFooter={true}
        footer={<FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />}
        isWhiteBackground={true}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    editAnnoucements: state.annoucements.editAnnoucements,
    edit: state.reminders.edit,
  };
};
const mapDispatchToProps = (dispatch) => ({
  emptyAnnoucement: (res) => dispatch(emptyAnnoucement(res)),
  showAlert: (res) => dispatch(showAlert(res)),

});
export default connect(mapStateToProps, mapDispatchToProps)(EditAnnoucement);
