import React, { useState } from "react";
import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Grid from '@material-ui/core/Grid';
import { DrawerMain, MasterLayoutMiddle, FooterInner, BaseImgChip, BaseSelect, BaseButton, arrowClick, DrawerListCheckbox } from "@dclab/dc-react-ui-kit";
import './style.css'



import { } from "@dclab/dc-react-ui-kit";

let chipData = [
  {
    key: 0,
    label: "Image Chip",
    src:
      "https://audioengineusa.com/wp-content/uploads/2019/01/a2.jpg",
    alt: "",
  },

];

const style = {
  width: '100%',
};

const onInputClick = () => {
  console.log("input clicked");
};

const onChipInputClick = () => {
  console.log("input chip clicked");
};

const deleteChip = () => {
  console.log('chip deleted')
}


const object = [
  {
    value: "In-progress",
    label: "In-progress",
  },

];


const data3 = [
  {
    id: 1,
    primary: "Abdul Al Razi",
    secondary: "Khatib",
    src:
      "https://media.gettyimages.com/photos/jama-masjid-mosque-in-delhi-picture-id505757382?s=612x612",
  },
  {
    id: 2,
    primary: "Abdul Al Razi",
    secondary: "Khatib",
    src:
      "https://media.gettyimages.com/photos/jama-masjid-mosque-in-delhi-picture-id505757382?s=612x612",
  },
];

const selectedData = [
  {
    id: 2,
    primary: "Abdul Al Razi",
    secondary: "Khatib",
    src:
      "https://media.gettyimages.com/photos/jama-masjid-mosque-in-delhi-picture-id505757382?s=612x612",
  },
];








function ManageOrder(props) {
  const [values, setValues] = React.useState({
    age: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };


  const [value, setValue] = useState([{ type: 'paragraph', children: [{ text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." }] }]);

  const onChange = (e) => {
    setValue(e)
  };

  const styles = {
    border: '1px solid black',
    color: 'gray'
  };


  const [open, setOpen] = React.useState(false);


  const closeDrawer = () => {
    setOpen(!open);
  };

  const onCancel = () => {
    setOpen(false);
  };


  const object = [
    {
      value: "10",
      label: "Ten",
    },
    {
      value: "20",
      label: "Twenty",
    },
    {
      value: "30",
      label: "Thirty",
    },
  ];

  const getData = (values) => {
    console.log(values);
  }

  return (

    <MasterLayoutMiddle
      header={
        <div className="backBtn">
          <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
          <Link
            to={{ pathname: "/admin-profile", state: 1, }}
            style={{ alignSelf: "center", color: "black", textDecoration: "none", }} > Back </Link>{" "}
        </div>
      }

      isForm={true}
      formData={
        <div>
          <Grid container spacing={3}>

            <Grid item md={12}>
              <h1 className='title_top'>Manage Order</h1>
              <div className="dasheddBorder"></div>
            </Grid>


            <Grid item xs={12}>
              <button onClick={() => setOpen(true)}>Open Drawer</button>
              <div className="field_box">
                <BaseImgChip
                  chipData={chipData}
                  onInputClick={onInputClick}
                  onChipInputClick={onChipInputClick}
                  deleteChip={deleteChip}
                  helperText=''
                  label="Assignee"
                // textStyle={styles}
                //arrowClick={arrowClick}

                />
              </div>
            </Grid>


            <Grid item xs={12}>
              <div className="field_box">
                <BaseSelect
                  value={values.age}
                  onChange={handleChange("age")}
                  object={object}
                  style={styles.select}
                 // helperText="Helper text"
                  label="Status"
                />
              </div>
            </Grid>

            <Grid item md={12} className="buttons">
              <span className="btnCencel" onClick={() => props.history.push("/admin-profile")}><BaseButton text="CANCEL" /></span>
              <BaseButton text="Save" />
            </Grid>

          </Grid>

          <DrawerMain
            open={open}
            openHandler={closeDrawer}
            title="Select Member"
            onCancel={onCancel}
            //onSave={onSave}
            loading={false}
            //img={Logo}
            saveDisabled={true}
          >

        <div className="cardDrawer">
        <DrawerListCheckbox objects={data3}  getData={getData} selectedItems={selectedData}/>
        </div>

           
          </DrawerMain>
        </div>
      }



      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}

    />
  );
}
export default ManageOrder;