import React, { Component } from 'react';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
import { connect } from 'react-redux';
import { getStates } from 'country-state-picker';
import {
  BaseSelect,
  BaseTextField,
  BaseButton,
  BaseFileSelect,
  AlertAction,
  DrawerMain, BaseImgChip, MasterLayoutMiddle,
  FooterInner,
  AlertDialogueAdvance,
} from '@dclab/dc-react-ui-kit';
import MediaUpload from '../../components/MediaUpload/MediaUpload';
import { BasePhoneInput } from "@dclab/dc-react-ui-kit";
import InputAdornment from '@material-ui/core/InputAdornment';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { countries } from '../../utils/countries';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactCrop from 'react-image-crop';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Avatar from '@material-ui/core/Avatar';
import 'react-image-crop/dist/ReactCrop.css';
import Grid from '@material-ui/core/Grid';
import { getOrgData } from '../../redux/actions/newOrgAction';
import {
  getOrganizationData,
  editOrganization,
} from './api';
import {
  image64toCanvasRef,
  extractImageFileExtensionFromBase64,
  base64StringtoFile,
} from '../../utils/crop';
import './style.css';
import '../../App.css';
import { stateFullAbbreviation } from '../../utils/statesAbbreviations';
import countries_states from '../../utils/countries+states.json';
import { hideAlert, showAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
import { TextField } from '@material-ui/core';
const {
  token_expired,
  success_message_update_org
} = messages_content;

class EditOrganization extends Component {
  constructor(props) {
    super(props);
    this.imagePreviewCanvasRef = React.createRef();
    this.state = {
      crop: {
        aspect: 1 / 1,
      },
      statesArr: [],
      countriesStatesArr: [],
      orgName: '',
      orgType: '',
      orgShortName: '',
      orgStreetAdress: '',
      enabledIntegrations: { deenfund: {}, deenweb: {}},
      orgCity: '',
      orgState: '',
      orgZipCode: '',
      orgCountry: '',
      orgPrimaryPhoneNumber: '',
      orgPrimaryPhoneNumber2: '',
      orgSecondaryPhoneNumber: '',
      orgSecondaryPhoneNumber2: '',
      orgEmail: '',
      orgWebsite: '',
      orgLine2: '',
      orgData: [],
      orgLogo: [],
      orgIcon: [],
      orgIconForm: [],
      orgLogoForm: [],
      error: '',
      openLogoDrawer: false,
      openIconDrawer: false,
      logo: [],
      logoName: '',
      logoSize: 0,
      icon: [],
      iconName: '',
      iconSize: '',
      showAlert: false,
      success: false,
      loading: false,
      webError: false,
      emailError: false,
      zipCodeError: false,
      primaryPhoneError: false,
      secondaryPhoneError: false,
      orgNameCount: 0,
      orgShortNameCount: 0,
      orgShortNameError: false,
      formLoading: true,
      showLogoDrag: true,
      showIconDrag: true,
      countryCode: [],
      changesFound: false,
      openAlert: false,
      onChanges: false,
    };
    this.updateOrg = this.updateOrg.bind(this);
    this.logoUpload = this.logoUpload.bind(this);
    this.iconUpload = this.iconUpload.bind(this);
    this.addLogo = this.addLogo.bind(this);
    this.addIcon = this.addIcon.bind(this);
    this.handleLogoDeleteChip = this.handleLogoDeleteChip.bind(this);
    this.handleIconDeleteChip = this.handleIconDeleteChip.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleLogoOnCrop = this.handleLogoOnCrop.bind(this);
    this.handleLogoOnCropCompleted = this.handleLogoOnCropCompleted.bind(this);
    this.handleIconOnCrop = this.handleIconOnCrop.bind(this);
    this.handleIconOnCropCompleted = this.handleIconOnCropCompleted.bind(this);
    this.handleLogoRemove = this.handleLogoRemove.bind(this);
    this.handleIconRemove = this.handleIconRemove.bind(this);
    this.onCancelOrgDetail = this.onCancelOrgDetail.bind(this);
  }
  async componentDidMount() {
    const orgId = localStorage.getItem('orgID');
    try {
      const res = await getOrganizationData(orgId);
      this.setState({
        orgData: res.data.body.data,
      });
      this.props.getOrganizationData(res.data.body.data);
      const states = getStates('us');
      let {
        orgEmail,
        orgType,
        orgName,
        orgAddress = {},
        phoneFax,
        orgShortName,
        enabledIntegrations,
        orgIcon,
        orgLogo,
        orgWebsite,
      } = res.data.body.data;
      orgWebsite = orgWebsite ? orgWebsite?.replace("https://", "") : ""
      let countryName;
      countryName =
      orgAddress?.country?.length > 2 ? orgAddress.country : 'United States';
      const countryCode = countries.filter((text) => text.name === countryName)
      const country_states = countries_states.filter(
        (country) => country.name === countryName
      );
      this.setState({
        countryCode: countryCode,
        enabledIntegrations: enabledIntegrations,
        statesArr: states,
        countriesStatesArr: country_states?.[0]?.states,
        orgWebsite: orgWebsite,
        orgEmail: orgEmail,
        orgType: orgType,
        orgName: orgName ?? "",
        orgStreetAdress: orgAddress.line1 || '',
        orgLine2: orgAddress.line2 || '',
        orgCity: orgAddress.county || orgAddress.city || '',
        orgState: orgAddress.state
          ? stateFullAbbreviation[orgAddress.state]
            ? stateFullAbbreviation[orgAddress.state]
            : orgAddress.state
          : orgAddress.state,
        orgZipCode: orgAddress.postalCode,
        orgCountry: orgAddress.country,
        orgPrimaryPhoneNumber:
        phoneFax && phoneFax?.primaryPhone?.length > 3
            ? phoneFax.primaryPhone
            : '',
        orgSecondaryPhoneNumber: phoneFax && phoneFax.secondaryPhone,
        orgShortName: orgShortName ? orgShortName : '',
        orgIcon:
        orgIcon && orgIcon?.iconThumbnail?.length > 0
            ? [{ label: orgIcon.iconName, name: orgIcon.iconName, size: orgIcon.iconSize, src: orgIcon.iconThumbnail, source: orgIcon.iconThumbnail }]
            : [],
        orgIconForm:
        orgIcon && orgIcon?.iconUrl?.length > 0
            ? [{ label: orgIcon.iconName, name: orgIcon.iconName, size: orgIcon.iconSize, source: orgIcon.iconUrl }]
            : [],
        icon:
        orgIcon && orgIcon?.iconThumbnail?.length > 0
            ? [{ name: orgIcon.iconName, size: orgIcon.iconSize, src: orgIcon.iconThumbnail }]
            : [],
        orgLogo:
        orgLogo && orgLogo?.logoUrl?.length > 0
            ? [{ label: orgLogo.logoName, name: orgLogo.logoName, size: orgLogo.logoSize, src: orgLogo.logoUrl, source: orgLogo.logoUrl }]
            : [],
        orgLogoForm:
        orgLogo && orgLogo?.logoThumbnail?.length > 0
            ? [{ label: orgLogo.logoName, name: orgLogo.logoName, size: orgLogo.logoSize, source: orgLogo.logoThumbnail }]
            : [],
        logo:
        orgLogo && orgLogo?.logoThumbnail?.length > 0
            ? [{ name: orgLogo.logoName, size: orgLogo.logoSize, src: orgLogo.logoThumbnail }]
            : [],

        orgNameCount: orgName && orgName?.length ? orgName.length : 0,
        orgShortNameCount:
        orgShortName && orgShortName?.length ? orgShortName.length : 0,
        formLoading: false,
      });
    } catch (error) {
      console.log("error", error)
      this.setState({ error, formLoading: false });
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  }
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  changeHandler = (name, val) => {
    const { orgNameCount, orgShortNameCount } = this.state;
    if (name === 'orgName') {
      if (orgNameCount < 50) {
        this.setState({ orgName: val, orgNameCount: val?.length, onChanges: true });
      } else {
        this.setState({ orgNameCount: val?.length, onChanges: true });
      }
    } else if (name === 'orgShortName') {
      if (orgShortNameCount < 20) {
        this.setState({ orgShortName: val, orgShortNameCount: val?.length, onChanges: true });
      } else {
        this.setState({ orgShortNameCount: val?.length, onChanges: true });
      }
    }
  };
  handleLogoDeleteChip = () => {
    this.setState({
      orgLogo: [],
      logo: [],
      orgLogoForm: []
    });
  };
  handleIconDeleteChip = () => {
    this.setState({
      orgIcon: [],
      icon: [],
      orgIconForm: []
    });
  };
  updateOrg = async () => {
    const token = localStorage.getItem('authToken');
    const orgId = localStorage.getItem('orgID');
    this.setState({
      loading: true,
    });
    const zipCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    const webRegexCheck = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.?\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[?6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1?,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00?a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u?00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
    const emailRegex = /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i;
    const {
      orgName,
      orgType,
      orgShortName,
      orgCity,
      orgStreetAdress,
      orgLine2,
      orgState,
      orgZipCode,
      orgCountry,
      orgPrimaryPhoneNumber,
      orgSecondaryPhoneNumber,
      orgWebsite,
      orgEmail,
      orgLogo,
      orgIcon,
      primaryPhoneError,
      countriesStatesArr,
      enabledIntegrations,
      emailError,
      webError,
    } = this.state;
    console.log("orgPrimaryPhoneNumber: ", orgPrimaryPhoneNumber, orgPrimaryPhoneNumber.length);
    const obj = {
      orgName: orgName,
      orgType: orgType,
      orgShortName: orgShortName,
      orgWebsite: orgWebsite ? "https://" + orgWebsite : "",
      enabledIntegrations: enabledIntegrations,
      orgEmail: orgEmail,
      orgAddress: {
        line1: orgStreetAdress,
        line2: orgLine2 ? orgLine2 : '',
        city: orgCity,
        state: orgState == " " ? countriesStatesArr[0].value : orgState,
        postalCode: orgZipCode,
        country: orgCountry,
      },
      phoneFax: {
        primaryPhone: orgPrimaryPhoneNumber,
        secondaryPhone: orgSecondaryPhoneNumber ? orgSecondaryPhoneNumber : '',
      },
      orgLogo: {
        logoName: '',
        logoSize: 0,
        // logoThumbnail: '',
        logoUrl: ''
      },
      orgIcon: {
        iconName: '',
        iconSize: 0,
        // iconThumbnail: '',
        iconUrl: ''
      },
    };
    console.log("State orgLogo: ", this.state.orgLogo);
    console.log("Not state orgLogo: ", orgLogo)
    if (orgLogo?.length > 0 && orgLogo[0]?.source) {
      console.log("In update Logo function");
      // obj.orgLogo.logoThumbnail = orgLogo.length > 0 && orgLogo[0].source;
      obj.orgLogo.logoUrl = orgLogo[0].source;
      obj.orgLogo.logoName = orgLogo[0].name;
      obj.orgLogo.logoSize = orgLogo[0].size;
    } 
    console.log("orgIcon",orgIcon)

    /* if (orgIcon?.length > 0 && orgIcon[0].source.slice(0, 5) !== 'https') {
      // obj.orgIcon.iconThumbnail = orgIcon.length > 0 && orgIcon[0].source;
      obj.orgIcon.iconUrl = orgIcon.length > 0 && orgIcon[0].source;
      obj.orgIcon.iconName = orgIcon.length > 0 && orgIcon[0].name;
      obj.orgIcon.iconSize = orgIcon.length > 0 && orgIcon[0].size;
    } else */ if (orgIcon?.length > 0 && orgIcon[0].source) {
      obj.orgIcon.iconUrl = orgIcon[0].source;
      obj.orgIcon.iconName = orgIcon[0].name;
      obj.orgIcon.iconSize = 0;
      obj.orgIcon.iconThumbnail = orgIcon[0].source;
    } /* else {
      delete obj.orgIcon
    } */
    if (
      !orgName ||
      !orgType ||
      orgLogo?.[0]?.source?.length === 0 ||
      !orgPrimaryPhoneNumber ||
      !orgEmail ||
      !orgStreetAdress > 3 ||
      !orgState ||
      !orgCity ||
      !orgZipCode
    ) {
      this.setState({
        error: true,
        loading: false,
      });
    } else if (orgShortName === '') {
      this.setState({ orgShortNameError: true, loading: false })
    } else if (!emailRegex.test(orgEmail)) {
      this.setState({ emailError: true, loading: false })
    } else if (orgWebsite && !webRegexCheck.test("https://" + orgWebsite)) {
      this.setState({ webError: true, loading: false })
    } else if (orgStreetAdress?.length < 4) {
      this.setState({
        error: true,
        loading: false
      })
    } else if (!zipCodeRegex.test(orgZipCode)) {
      this.setState({
        zipCodeError: true,
        loading: false,
      });
    } else if (emailError) {
      this.setState({
        emailError: true,
        loading: false,
      });
    } else if (primaryPhoneError) {
      this.setState({ primaryPhoneError: true, loading: false });
    }
    else if (webError) {
      this.setState({ webError: true, loading: false });
    } else {
      try {
        console.log("APi data: ", obj);
        const res = await editOrganization(obj, orgId, token);
        console.log("ORg Api update response: ", res);
        localStorage.setItem('orgaddress', JSON.stringify(obj.orgAddress));
        this.props.getOrganizationData(res.data);
        this.setState({ showAlert: true, success: true, loading: false });
        this.props.alertShow(success_message_update_org)

        if (this.props?.location?.state?.prevPath === "overview") {
          this.props.history.push("/organization/overview");
        } else {
          this.props.history.push({pathname: '/dashboard', state: { showAlert: true}});
        } 

      } catch (error) {
        console.log("Error while updating org: ", error);
        this.setState({
          error: error && error.response && error.response.data.message,
          showAlert: true,
          loading: false,
        });
        setTimeout(() => {
          this.setState({ error: false })
        }, 8000);
        this.hideAlert();
      }
    }
  };
  logoUpload = async (files) => {
    if (files?.length > 0) {
      this.setState({
        logo: files,
        showLogoDrag: files?.length === 0 ? true : false,
      });
    }
  };
  iconUpload = async (files) => {
    if (files?.length > 0) {
      this.setState({
        icon: files,
        showIconDrag: files?.length === 0 ? true : false,
      });
    }
  };
  addLogo = async (obj) => {
    console.log("Add Logo Object: ", obj)
    /* const { logo } = this.state; */
    if (obj.src) {
      /* this.setState({ onChanges: true })
      const canvasRef = this.imagePreviewCanvasRef.current;
      const imgSrc = obj.src;
      const fileExtension = extractImageFileExtensionFromBase64(imgSrc);
      const imageDatato64 = canvasRef.toDataURL('image/' + fileExtension);
      const myFileName = obj.title + fileExtension;
      if (imageDatato64.split(',')[1] !== '') {
        const newCroppedFile = base64StringtoFile(imageDatato64, myFileName);
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            this.setState({
              orgLogo: [
                {
                  preview: URL.createObjectURL(newCroppedFile),
                  source: reader.result,
                  src: reader.result,
                  name: obj.title,
                  label: obj.title,
                  size: "",
                },
              ],
              orgLogoForm: [
                {
                  preview: URL.createObjectURL(newCroppedFile),
                  source: reader.result,
                  name: obj.title,
                  label: obj.title,
                  size: "",
                },
              ],
              logo: [
                {
                  preview: URL.createObjectURL(newCroppedFile),
                  src: reader.result,
                  name: obj.title,
                  label: obj.title,
                  size: obj.title,
                },
              ],
              openLogoDrawer: false,
              showLogo: false,
              showLogoDrag: obj?.src && false,
            });
          },
          false
        );
        reader.readAsDataURL(newCroppedFile);
      } else { */
        this.setState({
          orgLogo: [
            {
              label: obj.title,
              name: obj.title,
              source: obj.src,
              src: obj.src,
              size: "",
            },
          ],
          orgLogoForm: [
            {
              label: obj.title,
              name: obj.title,
              source: obj.src,
              size: "",
            },
          ],
          logo: [
            {
              label: obj.title,
              name: obj.title,
              src: obj.src,
              size: "",
            },
          ],
          openLogoDrawer: false,
          showLogo: false,
          showLogoDrag: obj.src && false,
        });
      /* } */
    } else {
      this.setState({ openLogoDrawer: false, showLogo: false });
    }
  };
  addIcon = (obj) => {
    
/*     const { icon } = this.state;
 */    if (obj.src) {
      /* this.setState({ onChanges: true })
      const canvasRef = this.imagePreviewCanvasRef.current;
      const imgSrc = obj.src;
      const fileExtension = extractImageFileExtensionFromBase64(imgSrc);
      const imageDatato64 = canvasRef.toDataURL('image/' + fileExtension);
      const myFileName = obj.title + fileExtension;
      if (imageDatato64.split(',')[1] !== '') {
        const newCroppedFile = base64StringtoFile(imageDatato64, myFileName);
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            this.setState({
              orgIconForm: [
                {
                  label: obj.title,
                  name: obj.title,
                  source: obj.src,
                  size: "",
                },
              ],
              orgIcon: [
                {
                  preview: URL.createObjectURL(newCroppedFile),
                  source: reader.result,
                  src: reader.result,
                  name: obj.title,
                  label: obj.title,
                  size: "",
                },
              ],
              icon: [
                {
                  preview: URL.createObjectURL(newCroppedFile),
                  src: reader.result,
                  name: obj.title,
                  label: obj.title,
                  size: "",
                },
              ],
              openIconDrawer: false,
              showIcon: false,
              showIconDrag: obj.src && false,
            });
          },
          false
        );
        reader.readAsDataURL(newCroppedFile);
      } else { */
        this.setState({
          orgIconForm: [
            {
              label: obj.title,
              name: obj.title,
              source: obj.src,
              size: "",
            },
          ],
          orgIcon: [
            {
              label: obj.title,
              name: obj.title,
              source: obj.src,
              src: obj.src,
              size: "",
            },
          ],
          icon: [
            {
              label: obj.title,
              name: obj.title,
              src: obj.src,
              size: "",
            },
          ],
          openIconDrawer: false,
          showIcon: false,
          showIconDrag: obj.src && false,
        });
      /* } */
    } else {
      this.setState({ openIconDrawer: false, showIcon: false });
    }
  };

  deleteIcon = () => {
    this.setState({
      orgIconForm: [
        {
          label: '',
          name: '',
          source: '',
          size: "",
        },
      ],
      orgIcon: [
        {
          label: '',
          name: '',
          source: '',
          src: '',
          size: "",
        },
      ],
      icon: [
        {
          label: '',
          name: '',
          src: '',
          size: "",
        },
      ],
    });
  }

  deleteLogo = () => {
    this.setState({
      orgLogo: [
        {
          label: '',
          name: '',
          source: '',
          src: '',
          size: "",
        },
      ],
      orgLogoForm: [
        {
          label: '',
          name: '',
          source: '',
          size: "",
        },
      ],
      logo: [
        {
          label: '',
          name: '',
          src: '',
          size: "",
        },
      ],
    
    });
  }
  handleLogoOnCrop = (crop) => {
    this.setState({ crop });
    this.setState({ cropped: true });
  };
  handleLogoOnCropCompleted = (crop, pixelCrop) => {
    const { logo } = this.state;
    const canvasRef = this.imagePreviewCanvasRef.current;
    const imgSrc = logo?.length > 0 && logo[0].src;
    image64toCanvasRef(canvasRef, imgSrc, crop);
  };
  handleIconOnCrop = (crop) => {
    this.setState({ crop });
    this.setState({ cropped: true });
  };
  handleIconOnCropCompleted = (crop, pixelCrop) => {
    const { icon } = this.state;
    const canvasRef = this.imagePreviewCanvasRef.current;
    const imgSrc = icon?.length > 0 && icon[0].src;
    image64toCanvasRef(canvasRef, imgSrc, crop);
  };

  handleLogoRemove = () => {
    //this.setState({ logo: [], showLogoDrag: true, orgLogo: [], orgLogoForm: [] });
  };
  handleIconRemove = () => {
    this.setState({ icon: [], showIconDrag: true, orgIcon: [], orgIconForm: [] });
  };
  onCancelOrgDetail = () => {
    if (this.state.onChanges) {
      this.setState({ openAlert: true })
    } else {
      this.props.history.push('/organization/overview')
    }
  }
  render() {
    const {
      countriesStatesArr,
      orgName,
      orgType,
      orgStreetAdress,
      orgCity,
      orgState,
      orgZipCode,
      orgEmail,
      orgPrimaryPhoneNumber,
      orgShortNameError,
      orgSecondaryPhoneNumber,
      orgLogoForm,
      orgIconForm,
      orgShortName,
      orgWebsite,
      orgLine2,
      openLogoDrawer,
      openIconDrawer,
      orgIcon,
      orgLogo,
      logo,
      icon,
      showAlert,
      success,
      error,
      emailError,
      webError,
      zipCodeError,
      primaryPhoneError,
      secondaryPhoneError,
      countryCode,
      formLoading,
      showIconDrag,
      showLogoDrag,
      openAlert,
      crop,
    } = this.state;
    // console.log("orgPrimaryPhoneNumber", orgPrimaryPhoneNumber)
    console.log("props", this.props)
    const onInputClick = () => {
      this.setState({
        openLogoDrawer: true,
        showLogo: true,
        showLogoDrag:
          (logo?.length > 0 || orgLogo?.length > 0) &&
            logo[0].src.slice(0, 5) === 'data:'
            ? false
            : true,
      })
    };
    const onInputClickIcon = () => {
      this.setState({
        openIconDrawer: true,
        showIcon: true,
        showIconDrag:
          (icon?.length > 0 || orgIcon?.length > 0) &&
            icon[0].src.slice(0, 5) === 'data:'
            ? false
            : true,
      })
    };
    const onChipInputClick = () => {
      this.setState({
        openLogoDrawer: true,
        showLogo: true,
        showLogoDrag:
          (logo?.length > 0 || orgLogo?.length > 0) &&
            logo[0].src.slice(0, 5) === 'data:'
            ? false
            : true,
      })
    };
    const onChipInputClickIcon = () => {
      this.setState({
        openIconDrawer: true,
        showIcon: true,
        showIconDrag:
          (icon?.length > 0 || orgIcon?.length > 0) &&
            icon[0].src.slice(0, 5) === 'data:'
            ? false
            : true,
      })
    };
    function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    const states = countriesStatesArr.map((state) => {
      return {
        label: state.name,
        value: state.name,
      };
    });
    const object = [
      {
        value: 'Academic/Educational Institutions',
        label: 'Academic/Educational Institutions',
      },
      {
        value: 'Religious Organization',
        label: 'Religious Organization',
      },
      {
        value: 'Charitable Funds',
        label: 'Charitable Funds',
      },
      {
        value: 'Community/Youth Center',
        label: 'Community/Youth Center',
      },
      {
        value: 'Education/Career Development',
        label: 'Education/Career Development',
      },
      {
        value: 'Entrepreneurship & Ideas',
        label: 'Entrepreneurship & Ideas',
      },
      {
        value: 'Funeral/Cemetery Services',
        label: 'Funeral/Cemetery Services',
      },
      {
        value: 'Masjid/Islamic Center',
        label: 'Masjid/Islamic Center',
      },
      {
        value: 'Not-for-Profit Organization',
        label: 'Not-for-Profit Organization',
      },
      {
        value: 'Private Foundation',
        label: 'Private Foundation',
      },
      {
        value: 'Public Affairs/Politics',
        label: 'Public Affairs/Politics',
      },
      {
        value: 'Scientific Research',
        label: 'Scientific Research',
      },
      {
        value: 'Social/Recreational Clubs',
        label: 'Social/Recreational Clubs',
      },
      {
        value: 'Veterans',
        label: 'Veterans',
      },
      {
        value: 'Voluntary Association',
        label: 'Voluntary Association',
      },
    
    ];
    const style = {
      width: '100%',
    };
    const handleClose = () => {
      this.setState({ openAlert: false })
      this.props.history.push('/organization/overview')
    };
    const handleCancel = () => {
      this.setState({ openAlert: false })
    };
    const handleConfirm = () => {
      this.updateOrg()
      this.setState({ openAlert: false })
    };
    const emailRegex = /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i;
    const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    const characterRegex = /^[a-zA-Z\s]*$/;
    const webRegex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.?\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[?6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1?,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00?a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u?00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
    const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    const suiteRegex = /^[a-zA-Z0-9/-]*$/g;

    console.log("State Orglogo: ", this.state.orgLogo)
    return (
      
      <MasterLayoutMiddle
        header={
          <>
            {showAlert && error && (
              <div
                style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={
                    error === 'Invalid token'
                      ? token_expired
                      : error
                  }
                  alertType={'error'}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {/* {showAlert && success && (
              <div
                style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={success_message_update_org}
                  alertType={'success'}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )} */}
            <div style={{ width: '26%', alignSelf: 'center' }}>
              <div
                onClick={() => {
                  this.props.history.goBack();
                }}
                className='backBtn'
              >
                <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
                <p style={{ alignSelf: 'center' }}>Back</p>{' '}
              </div>
            </div>
          </>
        }
        isForm={true}
        formData={
          <>
            {formLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 30,
                }}
              >
                <CircularProgress color='primary' />
              </div>
            ) : (
                <div>
                  <div>
                    <h1 className='title_top'>Organization Details</h1>
                    <div className="dasheddBorder"></div>
                  </div>
                  <div>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            label={<p style={{ display: 'flex', margin: 0 }}>Organization Name {orgName ? '' : <p style={{ color: 'red', margin: 0, paddingLeft: '5px' }}>*</p>}</p>}
                            placeholder=''
                            helperText={
                              error && !orgName ? 'Organization Name is required' : ''
                            }
                            style={{ width: '100%', }}
                            value={orgName ?? ""}
                            onChange={(e) => {
                              if (characterRegex.test(e.target.value)) {
                                this.changeHandler('orgName', e.target.value)
                              }
                            }
                            }
                            error={error && !orgName}
                            count={50}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseSelect
                            value={orgType}
                            onChange={(e) => this.setState({ orgType: e.target.value, onChanges: true })}
                            object={object}
                            style={{
                              width: '100%',
                              margin: 0,
                              border: '#707070',
                            }}
                            label={<p style={{ display: 'flex', margin: 0 }}>Organization Type {orgType ? '' : <p style={{ color: 'red', margin: 0, paddingLeft: '5px' }}>*</p>}</p>
                            }
                            helperText={
                              error && !orgType && 'Please select organization type'
                            }
                            error={error && !orgType}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            label={<p style={{ display: 'flex', margin: 0 }}>Organization Short Name {orgShortName ? '' : <p style={{ color: 'red', margin: 0, paddingLeft: '5px' }}>*</p>}</p>
                            }
                            placeholder=''
                            helperText={orgShortNameError ? 'Organization short name is required.' : 'Some organizations are known by their shorter name'}
                            style={{ width: '100%', }}
                            value={orgShortName}
                            onChange={(e) => {
                              if (characterRegex.test(e.target.value)) {
                                this.changeHandler('orgShortName', e.target.value)
                              }
                            }
                            }
                            error={error && !orgShortName || orgShortNameError}
                            count={20}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                      <MediaUpload
                            addMediaItem = {this.addLogo}
                            onDelete = {this.deleteLogo}
                            label = " Logo"
                            buttonName = "ADD LOGO"
                            typeOfMedia='/images'
                            selectedMedia = {{logoUrl: this.state.orgLogo?.[0]?.src ?? "", logoName: this.state.orgLogo?.[0]?.name ?? ""}} 
                      /> 
                      {error && this.state.orgLogo?.[0]?.source.length === 0 ? 
                        <p style={{ color: '#f44336', marginTop: '3px', paddingLeft: '5px' , fontFamily:'Roboto,sans-serif !important', fontSize: '12px'}}>Logo is required</p>
                      :null }
                        {/* <div className="field_box mouse-hover" onClick={onChipInputClick}>
                          <BaseImgChip
                            chipData={orgLogo?.length ? orgLogoForm : []}
                            style={{ width: '100%', }}
                            label={<p style={{ display: 'flex', margin: 0 }}>Logo {orgLogoForm?.length !== 0 ? '' : <p style={{ color: 'red', margin: 0, paddingLeft: '5px' }}>*</p>}</p>
                            }
                            onInputClick={onInputClick}
                            onChipInputClick={onChipInputClick}
                            helperText={error && !orgLogo?.length ? 'Logo is required' : ''}
                            deleteChip={() => this.handleLogoDeleteChip()}
                            error={error && !orgLogo?.length}
                          />
                        </div>  */}
                      </Grid>
                      <Grid item xs={12}>
                      <MediaUpload
                            addMediaItem = {this.addIcon}
                            onDelete = {this.deleteIcon}
                            label = " Logo Icon"
                            buttonName = "ADD LOGO ICON"
                            typeOfMedia='/images' 
                            selectedMedia = {{logoUrl: this.state.orgIcon?.[0]?.src, logoName: this.state.orgIcon?.[0]?.name}} 

                          /> 
                        {/* <div className="field_box mouse-hover" onClick={onChipInputClickIcon}>
                          <BaseImgChip
                            chipData={orgIconForm}
                            style={{ width: '100%', }}
                            label='Icon'
                            onInputClick={onInputClickIcon}
                            onChipInputClick={onChipInputClickIcon}
                            helperText={''}
                            deleteChip={() => this.handleIconDeleteChip()}
                            error={false}
                          />
                        </div> */}
                      </Grid>
                      <Grid item xs={8}>
                        <div className="field_box">
                          <BaseTextField
                            label={<p style={{ display: 'flex', margin: 0 }}>Street Address {orgStreetAdress ? '' : <p style={{ color: 'red', margin: 0, paddingLeft: '5px' }}>*</p>}</p>
                            }
                            placeholder=''
                            helperText={
                              error && orgStreetAdress?.length < 4
                                ? 'Street Adress is required'
                                : ''
                            }
                            style={{ width: '100%', }}
                            value={orgStreetAdress}
                            onChange={(e) =>
                              this.setState({ orgStreetAdress: e.target.value, onChanges: true })
                            }
                            error={error && orgStreetAdress?.length < 4}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="field_box">
                          <BaseTextField
                            label='FL/Suite Number'
                            helperText=' '
                            value={orgLine2}
                            onChange={(e) => {
                              if (suiteRegex.test(e.target.value)) {
                                this.setState({ orgLine2: e.target.value, onChanges: true })
                              }
                            }
                            }
                            style={{ width: '100%', }}
                            placeholder=' '
                            error={false}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="field_box">
                          <BaseTextField
                            label={<p style={{ display: 'flex', margin: 0 }}>City {orgCity ? '' : <p style={{ color: 'red', margin: 0, paddingLeft: '5px' }}>*</p>}</p>
                            }
                            placeholder=' '
                            helperText={error && !orgCity ? 'City is required' : ''}
                            style={{ width: '100%', }}
                            value={orgCity}
                            onChange={(e) => this.setState({ orgCity: e.target.value, onChanges: true })}
                            error={error && !orgCity}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="field_box">
                          {states?.length > 0 && (
                            <BaseSelect
                              object={states}
                              style={{ width: '100%', }}
                              label={<p style={{ display: 'flex', margin: 0 }}>State {orgState ? '' : <p style={{ color: 'red', margin: 0, paddingLeft: '5px' }}>*</p>}</p>
                              }
                              value={orgState}
                              onChange={(e) =>
                                this.setState({ orgState: e.target.value, onChanges: true })
                              }
                              error={error && !orgState}
                              helperText={error && !orgState ? 'State is required' : ''}
                            />
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="field_box">
                          <BaseTextField
                            label={<p style={{ display: 'flex', margin: 0 }}>Zip Code {orgZipCode ? '' : <p style={{ color: 'red', margin: 0, paddingLeft: '5px' }}>*</p>}</p>
                            }
                            placeholder=' '
                            helperText={
                              (error && !orgZipCode && 'Zip code is required') ||
                              (zipCodeError && 'Invalid Zip code')
                            }
                            style={{
                              width: '100%',
                            }}
                            value={orgZipCode}
                            onChange={(e) => {
                              if (!zipRegex.test(e.target.value)) {
                                this.setState({
                                  zipCodeError: false,
                                  orgZipCode: e.target.value,
                                  onChanges: true,
                                });
                              } else {
                                this.setState({
                                  zipCodeError: false,
                                  orgZipCode: e.target.value,
                                  onChanges: true,
                                });
                              }
                            }}
                            error={(error && !orgZipCode) || zipCodeError}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6} style={{}}>
                        <div className="field_box telInputcontainer">
                          <div
                            // className={
                            //   primaryPhoneError
                            //     ? 'edit-org-primary-error'
                            //     : orgPrimaryPhoneNumber ? 'edit-org-primary' : 'edit-org-primary-required'
                            // }
                          >
                            <BasePhoneInput
                              phoneInputVariant={"underlineWithFlag"}
                              countryCodeEditable={false}
                              specialLabel={'Primary Phone Number'}
                              placeholder='Phone Number'
                              country={'us'}
                              inputStyle={{
                                marginLeft: "0px",
                                height: "35px",
                                width: "100%",
                                fontFamily: "Roboto-Regular",
                                fontSize: "16px",
                              }}
                              value={orgPrimaryPhoneNumber}
                              onChange={(e, f, g, h) => {
                                console.log("onChange", e, h)
                                if (!phoneRegex.test(e)) {
                                  this.setState({
                                    primaryPhoneError: true,
                                    onChanges: true,
                                    orgPrimaryPhoneNumber: h,
                                  });
                                } else {
                                  this.setState({
                                    primaryPhoneError: false,
                                    orgPrimaryPhoneNumber: h,
                                    onChanges: true
                                  });
                                }
                              }}
                              errorText={error && !orgPrimaryPhoneNumber ? 'Please enter phone number' : primaryPhoneError ? 'Enter valid phone number' : ' '}
                              error={error && !orgPrimaryPhoneNumber ? true : primaryPhoneError ? true : false}
                            />
                          </div>
                          {/* <div >
                            <span style={{ color: '#f44336', fontSize: '0.75rem' }}>
                              {error && !orgPrimaryPhoneNumber
                                ? 'Please enter phone number'
                                : ' '}
                              {primaryPhoneError ? 'Enter valid phone number' : ' '}
                            </span>
                          </div> */}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="field_box telInputcontainer">
                          <div
                            // className={
                            //   secondaryPhoneError
                            //     ? 'edit-org-secondary-error'
                            //     : 'edit-org-secondary'
                            // }
                          >
                            <BasePhoneInput
                              countryCodeEditable={false}
                              specialLabel={'Secondary Phone Number'}
                              placeholder='Phone Number'
                              phoneInputVariant={"underlineWithFlag"}
                              country={'us'}
                              inputStyle={{
                                marginLeft: "0px",
                                height: "34px",
                                width: "100%",
                                fontFamily: "Roboto-Regular",
                                fontSize: "16px",
                              }}
                              value={orgSecondaryPhoneNumber}
                              onChange={(e, f, g, h) => {
                                if (!phoneRegex.test(e)) {
                                  this.setState({
                                    secondaryPhoneError: false,
                                    orgSecondaryPhoneNumber: h,
                                    onChanges: true
                                  });
                                } else {
                                  this.setState({
                                    secondaryPhoneError: false,
                                    orgSecondaryPhoneNumber: h,
                                    onChanges: true
                                  });
                                }
                              }}
                            />
                          </div>
                          <div style={{ width: '95%' }}>
                            {/* <span style={{ color: '#f44336', fontSize: '0.75rem' }}>
                              {error && !orgSecondaryPhoneNumber
                                ? 'Please enter phone number'
                                : ' '}
                              {secondaryPhoneError ? 'Enter valid phone number' : ' '}
                            </span> */}
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="field_box">
                          <BaseTextField
                            placeholder=' '
                            label={<p style={{ display: 'flex', margin: 0 }}>Organization Email {orgEmail ? '' : <p style={{ color: 'red', margin: 0, paddingLeft: '5px' }}>*</p>}</p>
                            }
                            helperText={
                              (error && !orgEmail && 'Email is required') ||
                              (emailError && 'Invalid email address')
                            }
                            style={{ width: '95%', marginLeft: 10, height: 48 }}
                            value={orgEmail}
                            onChange={(e) => {
                              if (!emailRegex.test(e.target.value)) {
                                this.setState({
                                  emailError: false,
                                  orgEmail: e.target.value,
                                  onChanges: true
                                });
                              } else {
                                this.setState({
                                  emailError: false,
                                  orgEmail: e.target.value,
                                  onChanges: true
                                });
                              }
                            }}
                            error={(error && !orgEmail) || emailError}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="field_box">
                          <TextField
                            placeholder=' '
                            label='Organization Website'
                            helperText={webError && 'Invalid Url'}
                            style={{ width: '100%', }}
                            value={orgWebsite}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">https://</InputAdornment>,
                            }}
                            onChange={(e) => {
                              if (!webRegex.test("https://" + e.target.value)) {
                                this.setState({
                                  webError: false,
                                  orgWebsite: e.target.value,
                                  onChanges: true
                                });
                              } else {
                                this.setState({
                                  webError: false,
                                  onChanges: true,
                                  orgWebsite: e.target.value,
                                });
                              }
                            }}
                            error={webError}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        {this.state.loading ? (
                          <div
                            style={{
                              marginLeft: 20,
                              marginTop: 30,
                              display: 'flex',
                              justifyContent: 'center',
                              width: 500,
                            }}
                          >
                            <CircularProgress color='primary' size={20} />
                          </div>
                        ) : (
                            <div className="form_button">
                              <p
                                className='cancel'
                                onClick={() => this.onCancelOrgDetail()
                                }
                              >
                                CANCEL
                              </p>
                              <BaseButton text='SAVE' onClick={() => this.updateOrg()} />
                            </div>
                          )}
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
            <DrawerMain
              open={openLogoDrawer}
              openHandler={() =>
                this.setState({
                  openLogoDrawer: false,
                })
              }
              title='Select Image'
              onCancel={() =>
                this.setState({
                  openLogoDrawer: false,
                })
              }
              onSave={() => this.addLogo()}
            >
              <List className='img-details' style={{ paddingLeft: 0 }}>
                {logo.map((file, index) => (
                  <ListItem key={file.name} style={{ paddingLeft: 0 }}>
                    <ListItemAvatar>
                      <Avatar
                        variant='square'
                        alt={'preview'}
                        src={
                          logo?.length > 0
                            ? logo[0].preview || logo[0].src
                            : file.preview
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        (file.name && file.name.slice(0, 20)) ||
                        file.src.slice(0, 20)
                      }
                      secondary={file.size && formatBytes(file.size)}
                    />
                    <ListItemSecondaryAction style={{ paddingLeft: 20 }}>
                      <IconButton
                        aria-label='delete'
                        size='medium'
                        style={{ padding: '0px' }}
                        onClick={() => this.handleLogoRemove(index)}
                      >
                        <DeleteForeverIcon fontSize='inherit' />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              {showLogoDrag && (
                <>
                  <div style={{ marginBottom: 20 }} className='img-uploader'>
                    <BaseFileSelect
                      selectedFiles={(file) => this.logoUpload(file)}
                    />
                  </div>
                </>
              )}
              {logo?.length > 0 && logo[0].src.slice(0, 5) !== 'https' && (
                <ReactCrop
                  src={logo?.length > 0 && logo[0].src}
                  crop={crop}
                  onChange={this.handleLogoOnCrop}
                  onComplete={this.handleLogoOnCropCompleted}
                />
              )}
              {(logo?.length > 0 || orgLogo?.length > 0) && (
                <p style={{ color: '#818E94', fontSize: 12 }}>
                  You can also crop the uploaded images
                </p>
              )}
              <br />
              <div style={{ display: 'none' }}>
                <canvas ref={this.imagePreviewCanvasRef}></canvas>
              </div>
            </DrawerMain>
            <DrawerMain
              open={openIconDrawer}
              openHandler={() =>
                this.setState({
                  openIconDrawer: false,
                })
              }
              title='Select Image'
              onCancel={() =>
                this.setState({
                  openIconDrawer: false,
                })
              }
              onSave={() => this.addIcon()}
            >
              <List className='img-details' style={{ paddingLeft: 0 }}>
                {icon.map((file, index) => (
                  <ListItem key={file.name} style={{ paddingLeft: 0 }}>
                    <ListItemAvatar>
                      <Avatar
                        variant='square'
                        alt={'preview'}
                        src={
                          icon?.length > 0
                            ? icon[0].preview || icon[0].src
                            : file.preview
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        (file.name && file.name.slice(0, 20)) ||
                        file.src.slice(0, 20)
                      }
                      secondary={file.size && formatBytes(file.size)}
                    />
                    <ListItemSecondaryAction style={{ paddingLeft: 20 }}>
                      <IconButton
                        aria-label='delete'
                        size='medium'
                        style={{ padding: '0px' }}
                        onClick={() => this.handleIconRemove(index)}
                      >
                        <DeleteForeverIcon fontSize='inherit' />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              {showIconDrag && (
                <>
                  <div style={{ marginBottom: 20 }} className='img-uploader'>
                    <BaseFileSelect
                      selectedFiles={(file) => this.iconUpload(file)}
                    />
                  </div>
                </>
              )}
              {icon?.length > 0 && icon[0].src.slice(0, 5) !== 'https' && (
                <ReactCrop
                  src={icon?.length > 0 && icon[0].src}
                  crop={crop}
                  onChange={this.handleIconOnCrop}
                  onComplete={this.handleIconOnCropCompleted}
                />
              )}
              {(icon?.length > 0 || orgIcon?.length > 0) && (
                <p style={{ color: '#818E94', fontSize: 12 }}>
                  You can also crop the uploaded images
                </p>
              )}
              <br />
              <div style={{ display: 'none' }}>
                <canvas ref={this.imagePreviewCanvasRef}></canvas>
              </div>
            </DrawerMain>
            <div>
              <AlertDialogueAdvance
                title="You have unsaved changes!"
                description='Do you want to save changes?'
                cancelText="Discard Changes"
                confirmText="SAVE CHANGES"
                open={openAlert}
                handleCancel={() => handleConfirm()}
                handleClose={() => handleCancel()}
                handleConfirm={() => handleClose()}
                styles={style}
              />
            </div>
            <div className='edit-footer'></div>
          </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={true}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    orgData: state.addOrg.orgData,
    alert: state.alert,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getOrganizationData: (res) => dispatch(getOrgData(res)),
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditOrganization);