import React, { useEffect } from "react";
import {
  BaseButton,
  BaseSelect,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { EditNewsAndArticlesWebsite, GetNewsAndArticlesWebsiteFeaturedNot, getNewsWithAppId, updateNewsWithAppId } from '../../../pages/Website/api';
import "../../../pages/Website/style.css";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { hideAlert, showAlert } from "../../../redux/actions/alertActions";
import messages_content from '../../../utils/messages.json';
import { editOrganization } from "../../../pages/Organization/api";
const { 
    token_expired,
    success_message_add_news,
    maximum_limit_exeed_of_featured_news
  } = messages_content;
function AddNewsArticles(props) {
  const [selection, setSelection] = React.useState('');
  const [News, setNews] = React.useState([]);
  const [error, seterror] = React.useState(false)
  const [limitError, setLimitError] = React.useState(false);
  const [featuredList, setFeaturedList] = React.useState([])
  const [Objecy, setObject] = React.useState([])
  const [Loading, setLoading] = React.useState(true);
  useEffect(() => {
    getNewsArticles()
  }, [])
  async function getNewsArticles() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    try {
      const resNews = await getNewsWithAppId(token, orgId, 'deenweb')
      const data = resNews.data.body.data;
      const featured = data.filter(text => text.featured === true)
      setFeaturedList(featured)
      const unFeatured = data.filter(text => text.featured === false)
      setNews(data);
      const array = unFeatured.map((text, index) => {
        return ({
          value: text.article.title,
          label: text.article.title,
        })
      })
      setObject(array)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }
  const handleChange = (e) => {
    setSelection(e.target.value);
  }
  const OnFeatured = async () => {
    const data = News.filter(text => text.article.title === selection);
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    const updateOnOrg = {
      article: data[0].article._id,
      featured: true,
      publishFrom: data[0].publishFrom,  // DATE OBJECT
      publishTo: data[0].publishTo,
      status: data[0].status,
  }
    if (featuredList.length < 3) {
      try {
        await updateNewsWithAppId(token, orgId, 'deenweb', data[0].article._id, updateOnOrg )
        props.alertShow(success_message_add_news)
        props.history.push({ pathname: '/website/home-page', state: '1' })
      } catch (err) {
        console.log(err)
        seterror(err.response && err.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    } else {
      setLimitError(true)
      setTimeout(() => {
        setLimitError(false)
      }, 8000);
    }
  }
  const styles = {
    formBody: {
      background: "#fff",
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A",
    },
    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
    },
    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
    select: {
      margin: "14px 0",
      minWidth: 120,
      width: 120,
      border: "#D3D8DA",
      width: "100%",
    },
  };
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          <div
            onClick={() => props.history.push({ pathname: '/website/home-page', state: '1' })}
            className="backBtn"
            style={{ marginTop: 0 }}
          >
            <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
            <p style={{ alignSelf: "center" }}>Back</p>{" "}
          </div>
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            limitError && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={maximum_limit_exeed_of_featured_news}
                  alertType={"error"}
                  onClick={() => setLimitError(false)}
                />
              </div>
            )
          }
        </>
      }
      isForm={true}
      formData={
        Loading ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <div>
            <div>
              <h1 className="title_top">Add Featured Reads/Views</h1>
              <div className="dasheddBorder"></div>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h2 className="sub_title">Select Reads/Views to Feature</h2>
                <Typography className="sub_decrition">Selected content will be featured on the website homepage</Typography>
              </Grid>
              <Grid item md={12} xl={12}>
                <div className="field_box">
                  <BaseSelect
                    value={selection}
                    onChange={(e) => handleChange(e)}
                    object={Objecy}
                    style={styles.select}
                    helperText={Objecy.length ? "" : <p>Reads/Views not found. For create new click here:
                      <span onClick={() => props.history.push('/website/add-news-articles')} className='addLinkTag'> Add Reads/Views </span></p>}
                    label="Select Reads/Views"
                    error={Objecy.length ? false : true}
                  />
                </div>
              </Grid>
              <Grid item md={12} xl={12}>
                <div className="form_button">
                  <span className="btnCencel">
                    <BaseButton
                      text="CANCEL"
                      onClick={() => props.history.push({ pathname: '/website/home-page', state: '1' })}
                    />
                  </span>
                  <BaseButton text="ADD NOW" onClick={() => OnFeatured()} />
                </div>
              </Grid>
            </Grid>
          </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
const mapStateToProps = (state) => ({
  alert: state.addOrg.alert,
});
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
  alertHide: (res) => dispatch(hideAlert(res)),

});
export default connect(mapStateToProps, mapDispatchToProps)(AddNewsArticles);
