import React from "react"
import { useHistory } from "react-router-dom"
import {
  GridContainer,
  GridItem,
  SimpleTable,
  MasterLayoutMiddle,
  FooterInner,
} from "@dclab/dc-react-ui-kit";
import icon from '../../assets/img/playlist.png'
function Playlists() {
  const history = useHistory()
  const style = {
    width: '100%',
  };
  const columns = [
    { type: 'string', name: 'name', label: 'NAME', minWidth: 900 },
    { type: 'status', name: 'status', label: 'STATUS', minWidth: 110 },
  ];
  const rows = [
    [{
      name: "textImg",
      type: "string",
      primaryText: 'Home Screen Playlist',
      secondaryText: "",
      src: icon,
    },
    { type: 'status', status: 'success', value: 'Active' }],
  ]
  return (
    <MasterLayoutMiddle
      content={
        <>
          <GridContainer>
            <GridItem>
              <SimpleTable
                showHeader={true}
                title="Playlists"
                parentTableDrag={false}
                rows={rows}
                columns={columns}
                colSpan={16}
                actionSendButton={true}
                donationsCount={rows && rows.length > 0 ? 1 : 0}
                noTitle="No playlists added yet "
                noPara="Add and manage your first playlists"
                noBtnText="Add Playlist"
                showIconCol={false}
                showFooter={true}
                showAdd={false}
                onAddClick={() => history.push("/deenkiosk/newplaylists")}
                iconTextSize={16}
                manageDevice={(e) => history.push('/deenkiosk/devices/manage-devices')}
              />
            </GridItem>
          </GridContainer>
          <p style={{ fontSize: "14px", color: "#818E94" }}>"Active" playlists are available to publish and schedule within devices</p>
        </>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  )
}
export default Playlists