import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { SimpleTable,  MasterLayoutMiddle, FooterInner, CardData  } from "@dclab/dc-react-ui-kit";
import './style.css';
import { getOrganizationPlan } from "../UserProfile/Apis/index"
import Moment from 'moment';

function Order(props) {
  const [orders, setorders] = useState({})

  useEffect(() => {
  const getDetails = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const orgId = localStorage.getItem("orgID");
      const orgOrders = await getOrganizationPlan(orgId, token);
      orgOrders && setorders(orgOrders)
    }
    catch (error) {
      console.log(error)
    }
  }
    getDetails()
  }, [])
  
  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { id: 'NO', label: 'NO.', width: 'auto' },
    { type: "link", name: "invoice_number", label: "Order no.", width: "15%" },
    { type: "string", name: "name", label: "Order date", width: "15%" }, 
    { type: "string", name: "name", label: "Amount", width: "55%" },
    { type: "string", name: "status", label: "Status", width: '15%' },
    { type: "action", name: "action", label: "ACTION", width: "15%" },
  ];
  let rows = orders?.stripeData?.length ? orders.stripeData.map((el, i) => { 
    return [
      { type: 'id', value: i + 1, data: { ...el, email: orders?.email, orgName: orders?.orgName } },
      { type: 'link', name: 'invoice_number', value: el.receipt_number?.replace("#", ""), url: el.receipt_url },
      { type: 'string', name: 'date', value: el.created ? Moment(el.created * 1000).format("LL") : "" },
      { type: 'string', name: 'amount', value: el.amount ? "$" + (el.amount / 100)?.toFixed(2) : "" },
      { type: 'status', status: 'success', value: 'New Order' },
      { type: "action", status: "success", value: "see details" },
    ]
  }) : [];
  // ============================== Table End ======================================//
  
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      content={
        <div>
          
          
            <Grid container spacing={3} >

            <Grid item md={12} sm={12} xs={12} >
            {/* <CardData
                data={[
                  {
                    title: "New - Feb 2021",
                    donation: "$2540.76",
                    fromLastMonth: 34,
                    increased: true,
                  },
                  {
                    title: "In Progress",
                    donation: "20",
                    fromLastMonth: 34,
                    increased: true,
                  },
                  {
                    title: "Total Confirmed",
                    donation: "$640",
                    fromLastMonth: 34,
                    increased: false,
                  },
                  {
                    title: "Total Balance",
                    donation: "$640",
                    fromLastMonth: 34,
                    increased: false,
                  },
                  {
                    title: "Total Paid",
                    donation: "$640",
                    fromLastMonth: 34,
                    increased: false,
                  },
                ]}
              /> */}
            </Grid>
              
            <Grid item md={12} sm={12} xs={12} >
              
                <div className="table_box">
                  <SimpleTable
                    showHeader={true}
                    title="Orders"
                    width="100%"
                    rows={rows}
                    columns={col}
                    colSpan={16}
                    actionSendButton={true}
                    classProps={changeClassProps()}
                    donationsCount={rows && rows.length > 0 ? 1 : 0}
                    noTitle="No Orders added"
                    noPara="Added Reminders will be displayed and managed here"
                    noBtnText=""
                    onInviteClick={() =>
                      props.history.push("/organization/order-details")
                    }
                    actionLink={(app) => props.history.push({pathname: "/organization/order-details", state: { order: app[0].data }})}
                    showIconCol={false}
                    actionSendButton={true}
                    deleteAction={(e) => console.log(e)}
                    showFooter={true}
                    showAdd={false}
                    addText="ADD NEW"
                    onAddClick={() =>
                      this.props.history.push(
                        "/order-details"
                      )
                    }
                    iconTextSize={16}
                    sortColumnIndex={[4]}

                  /> 
                </div>
              </Grid>
            </Grid>
        
         
            
     
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
export default Order;