import React, { Component } from 'react';
import {
  BaseTextField,
  BaseButton,
  AlertAction,
  BaseFileSelect,
  DrawerMain,
  MasterLayoutMiddle,
  FooterInner,
  FormSwitch,
  BasePhoneInput,
  TextEditor
  // MediaUploader 
} from '@dclab/dc-react-ui-kit';
// import MediaUpload from '../../components/MediaUpload/MediaUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
// import PhoneInput from 'react-phone-input-2';
// import {
//   BasePhoneInput
// } from 'master-react-lib';
import ReactCrop from 'react-image-crop';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Avatar from '@material-ui/core/Avatar';
import 'react-image-crop/dist/ReactCrop.css';
import Grid from '@material-ui/core/Grid';
import { getSpeakers, addSpeakers } from './api';
import {
  image64toCanvasRef,
  extractImageFileExtensionFromBase64,
  base64StringtoFile,
} from '../../utils/crop';
import './style.css';
import { connect } from 'react-redux';
import { showAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
import MediaUpload from '../../components/MediaUpload/MediaUpload';


const {
  token_expired,
  success_message_speaker,
  speaker_title_already_exist
} = messages_content;

class AddSpeaker extends Component {
  constructor(props) {
    super(props);
    this.imagePreviewCanvasRef = React.createRef();
    this.state = {
      speakerName: '',
      fName: '',
      lName: '',
      fnameCount: 0,
      lnameCount: 0,
      companyName: '',
      nameCount: 0,
      companyNameCount: 0,
      email: '',
      notes: '',
      notesCount: 0,
      designation: '',
      designationCount: 0,
      phoneNo: '',
      phoneNo2: '',
      phoneNoCount: 0,
      available: false,
      speakerImage: [],
      image: [],
      openDrawer: false,
      showAlert: false,
      error: false,
      success: false,
      emailError: false,
      phoneError: false,
      allSpeakers: [],
      showDrag: true,
      shortBio: [{ type: 'paragraph', children: [{ text: "" }] }],
      shortBioCount: 0,
      crop: {
        aspect: 1 / 1,
      },
      cropped: false,
    };
    this.add = this.add.bind(this);
    this.upload = this.upload.bind(this);
    this.addLogo = this.addLogo.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleOnCrop = this.handleOnCrop.bind(this);
    this.handleOnCropCompleted = this.handleOnCropCompleted.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.selectMediaItem = this.selectMediaItem.bind(this);
  }
  async selectMediaItem(object) {

    this.setState({
      speakerImage: [
        {
          label: object.title,
          name: object.title,
          src: object.src,
          size: 55,
        },
      ],
      image: [
        {
          label: object.title,
          name: object.title,
          src: object.src,
          size: 55,
        },
      ],

    });

    // console.log(object.src);

  }
  async componentDidMount() {
    const token = localStorage.getItem('authToken');
    const orgId = localStorage.getItem('orgID');
    try {
      const res = await getSpeakers(orgId, token);
      this.setState({
        allSpeakers: res.data.body.data,
      });
    } catch (error) {
      this.setState({
        spError: error.response && error.response.data.message,
      });
      setTimeout(() => {
        this.setState({ spError: false })
      }, 8000);
    }
  }
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  changeHandler = (name, val) => {
    const { nameCount, designationCount, notesCount, companyNameCount, shortBioCount, fnameCount, lnameCount } = this.state;
    if (name === 'fname') {
      if (fnameCount < 50) {
        this.setState({ fName: val, fnameCount: val.length });
      } else {
        this.setState({ fnameCount: val.length });
      }
    } else if (name === 'lname') {
      if (lnameCount < 50) {
        this.setState({ lName: val, lnameCount: val.length });
      } else {
        this.setState({ lnameCount: val.length });
      }
    } else if (name === 'companyName') {
      if (companyNameCount < 50) {
        this.setState({ companyName: val, companyNameCount: val.length });
      } else {
        this.setState({ companyNameCount: val.length });
      }
    } else if (name === 'designation') {
      if (designationCount < 50) {
        this.setState({ designation: val, designationCount: val.length });
      } else {
        this.setState({ designationCount: val.length });
      }
    } else if (name === 'notes') {
      if (notesCount < 100) {
        this.setState({ notes: val, notesCount: val.length });
      } else {
        this.setState({ notesCount: val.length });
      }
    } else if (name === 'bio') {
      // if (shortBioCount < 100) {
        this.setState({ shortBio: val, shortBioCount: val[0].children[0].text.length });
      // } else {
      //   this.setState({ shortBioCount: val.length });
      // }
    }

  };
  upload = async (files) => {
    this.setState({
      image: files,
      showDrag: files.length === 0 ? true : false,
      speakerImage: [],
    });
  };
  addLogo = async () => {
    const { image, cropped } = this.state;
    if (image.length > 0) {
      const canvasRef = this.imagePreviewCanvasRef.current;
      const imgSrc = image[0].src;
      const fileExtension = extractImageFileExtensionFromBase64(imgSrc);
      const imageDatato64 = canvasRef.toDataURL('image/' + fileExtension);
      const myFileName = image[0].name + fileExtension;
      if (imageDatato64.split(',')[1] !== '') {
        const newCroppedFile = base64StringtoFile(imageDatato64, myFileName);
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            this.setState({
              speakerImage: [
                {
                  preview: URL.createObjectURL(newCroppedFile),
                  src: reader.result,
                  name: image[0].name.slice(0, 20),
                  label: image[0].name.slice(0, 20),
                  size: image[0].size,
                },
              ],
              image: [
                {
                  preview: URL.createObjectURL(newCroppedFile),
                  src: reader.result,
                  name: image[0].name.slice(0, 20),
                  label: image[0].name.slice(0, 20),
                  size: image[0].size,
                },
              ],
              openDrawer: false,
              showDrag: image.length > 0 && false,
            });
          },
          false
        );
        reader.readAsDataURL(newCroppedFile);
      } else {
        this.setState({
          speakerImage: [
            {
              label: image[0].name.slice(0, 20),
              name: image[0].name.slice(0, 20),
              src: image[0].src,
              size: image[0].size,
            },
          ],
          image: [
            {
              label: image[0].name.slice(0, 20),
              name: image[0].name.slice(0, 20),
              src: image[0].src,
              size: image[0].size,
            },
          ],
          openDrawer: false,
          showDrag: image.length > 0 && false,
        });
      }
    } else {
      this.setState({ openDrawer: false });
    }
  };
  handleDeleteChip = () => {
    this.setState({
      speakerImage: [],
    });
  };
  add = async () => {
    const emailRegex = /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i;
    const {
      speakerName,
      fName,
      lName,
      companyName,
      email,
      designation,
      phoneNo,
      available,
      speakerImage,
      notes,
      phoneError,
      allSpeakers,
      shortBio,
      shortBioCount
    } = this.state;
    const token = localStorage.getItem('authToken');
    const orgId = localStorage.getItem('orgID');
    // const name = speakerName.split(/(?<=^\S+)\s/);
    const name = speakerName.split(/ (.*)/s);
    // console.log( name );
    const obj = {
      name: {
        firstName: fName,
        lastName: lName,
      },
      imageMetaData: {
        imageUrl: speakerImage.length > 0 && speakerImage[0].src,
        imageName: speakerImage.length > 0 && speakerImage[0].name,
        imageThumbnail: speakerImage.length > 0 && speakerImage[0].src,
        imageSize: speakerImage.length > 0 && speakerImage[0].size,
      },
      designation: {
        title: designation,
      },
      status: available ? 'Available' : 'Unavailable',
      phone: phoneNo,
      orgId,
      emailAddress: email,
      notes: notes.length > 0 ? notes : '  ',
      companyName: companyName,
      bio: shortBio[0].children[0].text
    };

    // console.log( obj );

    this.setState({ loading: true, emailError: false, phoneError: false });

    if (
      !fName ||
      !lName ||
      !companyName ||
      !designation ||
      !phoneNo ||
      !shortBio ||
      shortBioCount.length === 0 ||
      speakerImage.length === 0
    ) {
      this.setState({ error: true, loading: false });
      console.log('there is an error ')
    } else if (phoneError) {
      this.setState({ error: true, phoneError: true, loading: false });
    }
    // else if (email) {
    //   if(!emailRegex.test(email)) {
    //     this.setState({
    //       emailError: true,
    //       loading: false,
    //     });
    //   }
    // } 
    else {
      this.setState({ phoneError: false, emailError: false });
      try {
        console.log('checking email of speaker');
        const sp = allSpeakers.filter((s) => s.email === email);
        if (sp.length > 0) {
          this.setState({
            error: speaker_title_already_exist,
            loading: false,
            showAlert: true,
          });
        } else {
          // console.log('inserting data');

          await addSpeakers(obj, token);
          this.setState({
            success: true,
            showAlert: true,
            loading: false,
          });
          this.props.showAlert(success_message_speaker)
          this.props.history.push('/organization/guests');
        }
      } catch (error) {
        this.setState({
          error: error && error.response && error.response.data.message,
          showAlert: true,
          loading: false,
        });
        this.hideAlert();
      }
    }
  };
  handleOnCrop = (crop) => {
    this.setState({ crop });
    this.setState({ cropped: true });
  };
  handleOnCropCompleted = (crop) => {
    const { image } = this.state;
    const canvasRef = this.imagePreviewCanvasRef.current;
    const imgSrc = image[0].src;
    image64toCanvasRef(canvasRef, imgSrc, crop);
  };
  handleRemove = () => {
    this.setState({ speakerImage: [], image: [], showDrag: true });
  };
  render() {
    const {
      phoneError,
      emailError,
      error,
      speakerName,
      companyName,
      email,
      designation,
      phoneNo,
      available,
      success,
      showAlert,
      openDrawer,
      image,
      speakerImage,
      loading,
      notes,
      showDrag,
      crop,
      shortBio,
      shortBioCount,
      fName,
      lName,
    } = this.state;
    function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    const style = {
      width: '100%',
    };
    const emailRegex2 = /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i;
    const phoneRegex = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{5})$/g;
    return (
      <MasterLayoutMiddle
        header={
          <>
            <div
              onClick={() =>
                this.props.history.push('/organization/guests')
              }
              className='backBtn'>
              <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
              <p style={{ alignSelf: 'center' }}>Back</p>{' '}
            </div>
            {(error && error === 'Invalid token') ||
              (error && error === 'No token Provided' && (
                <div
                  style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                >
                  <AlertAction
                    alert={token_expired}
                    alertType={'error'}
                    onClick={() => this.setState({ error: false })}
                  />
                </div>
              ))}
            {showAlert && error && (
              <div
                style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={
                    error === 'Invalid token'
                      ? token_expired
                      : error
                  }
                  alertType={'error'}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {showAlert && success && (
              <div
                style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={success_message_speaker}
                  alertType={'success'}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
          </>
        }
        isForm={true}
        formData={
          <>
            <>
              {' '}
              <div>
                <h1 className='title_top'>Add Guests</h1>
                <div className="dasheddBorder"></div>
              </div>
              <div>
                <Grid container spacing={3}>

                  <Grid item xs={6}>
                    <div className="field_box">
                      <BaseTextField
                        label='First Name'
                        placeholder=''
                        helperText={
                          error && !fName && 'Please enter first name'
                        }
                        value={fName}
                        onChange={(e) => this.changeHandler('fname', e.target.value)}
                        error={error && !fName}

                      />
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    <div className="field_box">
                      <BaseTextField
                        label='Last Name'
                        placeholder=''
                        helperText={
                          error && !lName && 'Please enter last name'
                        }
                        value={lName}
                        onChange={(e) => this.changeHandler('lname', e.target.value)}
                        error={error && !lName}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>



                    <div className="field_box">
                      <BaseTextField
                        label='Org/Institution/Company Name'
                        placeholder=''
                        helperText={
                          error && !companyName && 'Please enter Org/Institution/Company Name'
                        }
                        value={companyName}
                        onChange={(e) => this.changeHandler('companyName', e.target.value)}
                        error={error && !companyName}
                        count={50}
                      />
                    </div>

                    <div className="field_box">
                      <BaseTextField
                        label='Designation'
                        placeholder=''
                        helperText={error && !designation && 'Please enter designation'}
                        value={designation}
                        onChange={(e) =>
                          this.changeHandler('designation', e.target.value)
                        }
                        error={error && !designation}
                        count={50}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className="field_box" >
                      <div className="media-library-image" >
                        <MediaUpload
                          addMediaItem={this.selectMediaItem}
                          label="Guests Image"
                          buttonName="ADD IMAGE"
                          typeOfMedia='/images'
                          selectedMedia={{ logoUrl: speakerImage[0]?.src ?? "", logoName: speakerImage[0]?.name ?? "" }}
                        />
                        {/* <MediaUploader typeOfMedia='/images' label="Speaker Image" buttonName = "ADD IMAGE" addMediaItem={this.selectMediaItem}  /> */}
                      </div>
                      <div>
                        <span style={{ color: '#f44336', fontSize: '0.75rem' }}>
                          {error &&
                            speakerImage.length === 0 &&
                            'Please provide Guests image'}
                        </span>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                  <BasePhoneInput
                        phoneInputVariant={"underlineWithFlag"}
                        countryCodeEditable={false}
                        specialLabel={'Phone Number'}
                        country={'us'}
                        value={phoneNo}
                        onChange={(e, f, g, h) => {
                          if (!phoneRegex.test(e)) {
                            this.setState({ phoneError: false, phoneNo: h });
                          } else {
                            this.setState({ phoneError: false, phoneNo: h });
                          }
                        }}
                        onBlur={() => {
                          if (phoneNo.length < 14) {
                            this.setState({ phoneError: true });
                          } else {
                            this.setState({ phoneError: false });
                          }
                        }
                        }
                        errorText={error && !phoneNo ? 'Please enter phone number' : phoneError ? 'Enter valid phone number' : ' '}
                        error={error && !phoneNo ? true : phoneError ? true : false}
                      />
                    <div
                      className={
                        (error && !phoneNo) || phoneError
                          ? 'guests-secondary error'
                          : 'guests-secondary'
                      }
                    >
                      
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        label='Email Address'
                        // helperText={
                        //   (emailError && 'Enter valid email')
                        // }
                        helperText="We will send invitation to this email address"
                        value={email}
                        onChange={(e) => {
                          if (emailError) {
                            if (!emailRegex2.test(e.target.value)) {
                              this.setState({
                                emailError: false,
                                email: e.target.value,
                              });
                            } else {
                              this.setState({
                                emailError: false,
                                email: e.target.value,
                              });
                            }
                          } else {
                            if (!emailRegex2.test(e.target.value)) {
                              this.setState({
                                emailError: false,
                                email: e.target.value,
                              });
                            } else {
                              this.setState({
                                emailError: false,
                                email: e.target.value,
                              });
                            }
                          }
                        }}
                        onBlur={() => {
                          if (email) {
                            if (!emailRegex2.test(email)) {
                              this.setState({
                                emailError: true,
                              });
                            }
                          } else {
                            this.setState({
                              emailError: false,
                            });
                          }
                        }}
                        error={emailError}
                        placeholder=' '
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className="TextArea">
                      <TextEditor
                        label='Short bio'
                        // helperText=' '
                        value={shortBio}
                        onChange={(e) => this.changeHandler('bio', e)}
                        placeholder=' '
                        // count={600}
                        maxCharCount={600}
                        helperText={error && shortBioCount === 0 ? 'Please provides guest bio' : 'Short bio about guest'}
                        error = {error && shortBioCount === 0}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className="field_box border_top_bottm">
                      <FormSwitch
                        headline='Available'
                        body="Guest is available for events"
                        values={available}
                        handleSwitchChange={(e) => this.setState({ available: !available })}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    {loading ? (
                      <div
                        style={{
                          marginTop: 50,
                          display: 'flex',
                          justifyContent: 'center',
                          width: 570,
                        }}
                      >
                        <CircularProgress color='primary' />
                      </div>
                    ) : (
                      <div className="form_button">
                        <p className='cancel' onClick={() => this.props.history.push('/organization/guests')} >CANCEL</p>
                        <BaseButton text='ADD NOW' onClick={() => this.add()} />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </div>
            </>
            <DrawerMain
              open={openDrawer}
              openHandler={() => this.setState({ openDrawer: false })}
              title='Select Image'
              onCancel={() => this.setState({ openDrawer: false })}
              onSave={() => this.addLogo()}
            >
              <List className='img-details' style={{ paddingLeft: 0 }}>
                {image.map((file, index) => (
                  <ListItem key={file.name} style={{ paddingLeft: 0 }}>
                    <ListItemAvatar>
                      <Avatar
                        variant='square'
                        alt={'preview'}
                        src={
                          speakerImage.length > 0
                            ? speakerImage[0].src
                            : file.preview
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={file.name.slice(0, 20)}
                      secondary={formatBytes(file.size)}
                    />
                    <ListItemSecondaryAction style={{ paddingLeft: 20 }}>
                      <IconButton
                        aria-label='delete'
                        size='medium'
                        style={{ padding: '0px' }}
                        onClick={() => this.handleRemove(index)}
                      >
                        <DeleteForeverIcon fontSize='inherit' />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              {showDrag && (
                <>
                  <div style={{ marginBottom: 20 }} className='img-uploader'>
                    <BaseFileSelect selectedFiles={(file) => this.upload(file)} showMediaText={true} getFromMedia={() => console.log('clicked')} />
                  </div>
                </>
              )}
              <ReactCrop
                src={image.length > 0 && image[0].src}
                crop={crop}
                onChange={this.handleOnCrop}
                onComplete={this.handleOnCropCompleted}
              />
              {(image.length > 0 || speakerImage.length > 0) && (
                <p style={{ color: '#818E94', fontSize: 12 }}>
                  You can also crop the uploaded images
                </p>
              )}
              <br />
              <p></p>
              <div style={{ display: 'none' }}>
                <canvas ref={this.imagePreviewCanvasRef}></canvas>
              </div>
            </DrawerMain>
          </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={true}
      />
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showAlert: (res) => dispatch(showAlert(res)),
  };
};
export default connect(0, mapDispatchToProps)(AddSpeaker);