import axios from "axios";
const baserUrl =
  "https://cors-anywhere.herokuapp.com/http://18.211.207.245:3000";
const env = {
  prod: process.env.REACT_APP_PAYMENT_PROD_API,
  dev: process.env.REACT_APP_PAYMENT_DEV_API
};
const orgEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
};
let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")
) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}
const orgBaseUrl = orgEnv[currentEnv];
export function getCategories(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${orgBaseUrl}/donation-category?orgId=${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  export function sortCategories(obj, token) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${orgBaseUrl}/donation-category/sortCategoriesList`, obj, {
          headers: {
            "Content-Type": "application/json",
            token
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  export function addFaq(id,faqs,token) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${orgBaseUrl}/organizations/${id}`,{
          embed_form_draft: [
            {
              setup: {
                faqs: faqs,
              },
            }
          ]}, {
          headers: {
            "Content-Type": "application/json",
            token
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  export function saveAsDraft(id,embedData,token) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${orgBaseUrl}/organizations/${id}`,embedData,
          {
          headers: {
            "Content-Type": "application/json",
            token
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  export function getEmbedForm(id,token) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${orgBaseUrl}/organizations/${id}`,{
          headers: {
            "Content-Type": "application/json",
            token
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  export function checkWebsiteName(name,token) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${orgBaseUrl}/organizations/checkWebsiteExist?websiteName=${name}`,{
          headers: {
            "Content-Type": "application/json",
            token
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  export function getOrganizationData(id,token){
    return new Promise((resolve, reject) => {
      axios
        .get(`${orgBaseUrl}/organizations/${id}`,{
          headers:{
            token: token
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }