const CryptoJS = require("crypto-js");

export function enrypt (text)  {
 
    let ciphertext = CryptoJS.AES.encrypt(text, process.env.REACT_APP_CRYPTO_KEY).toString();
    
    return ciphertext;
} 

export function decrypt (hash) {
    let bytes = CryptoJS.AES.decrypt(hash, process.env.REACT_APP_CRYPTO_KEY);
    let decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
}

