import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { MasterLayoutMiddle, FooterInner, TableStats, Activities } from "@dclab/dc-react-ui-kit";
import { getPlanSubscriptionsStats, getOrgOrdersCount, getOrgEventsCount } from './apis/index.js'
import { getDonors, getDonorsCardData,getInvitedDonorsData, getDonationsDashboard } from "../pages/Donations/api";
import {editOrganization, getOrganizationData} from "../pages/Organization/api/index.js";
import { makeStyles } from '@material-ui/core/styles';
// import { getOrganizationPlan } from "../pages/UserProfile/Apis/index"
// import {getAllCustomerInvoices} from "../pages/Organization/api/index.js"
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

import FilterIcon from "../assets/img/filterIcon.svg";
import { withRouter } from "react-router-dom";
import {decrypt} from '../utils/crypto';

const orgId = localStorage.getItem("orgID");
const authToken = localStorage.getItem("authToken");

function DashboardStats(props) {
  const [planSubsCount, setplanSubsCount] = useState([])
  const [ordersCount, setordersCount] = useState({
    newOrders: 0,
    completedOrders: 0,
    pendingOrders: 0,
    ordersTotal: 0
  })
  const [eventsCount, seteventsCount] = useState({
    totalEvents: 0,
    publicEvents: 0,
    privateEvents: 0,
    completedEvents: 0
  })
  const [donorsCount, setdonorsCount] = useState({})
  const [recentDonorsCount, setrecentDonorsCount] = useState([])
  const [recentDonorsData, setrecentDonorsData] = useState([])
  const [invoicesData, setinvoicesData] = useState([])
  const [inviteDonorData, setInviteDonorData] = useState([])
  const [loading, setloading] = useState(false)
  const [dashboardManageFilter, setDashboardManageFilter] = useState([])
  

  const validateUser = () => {
    let verify = false;
    let accountRevoked = false
    const token = localStorage.getItem("authToken");
    let access = localStorage.getItem("userAccess") ;
    if(access) {
      access = decrypt(access);
    }
    if(token) {
      verify = true;
    } 
    if(access && access?.toLowerCase() !== "active") {
      accountRevoked = true;
    }
    if(!verify || accountRevoked) {
      props.history.push(accountRevoked ? "/access-revoked" : "/signin");
    }
  }


  useEffect(() => {
    validateUser();
    const orgId = localStorage.getItem("orgID");
    const authToken = localStorage.getItem("authToken");
    const getData = async () => {
      setloading(true)
      const orgData = await getOrganizationData(orgId);
      setDashboardManageFilter(orgData?.data?.body?.data?.dashboardManageFilter);
      const promises = [
        await getOrgOrdersCount(authToken, orgId, '28'),
        await getOrgEventsCount(authToken, orgId),
        await getDonationsDashboard(orgId, "28", authToken),
        await getDonors(orgId, authToken),
        //await getInvitedDonorsData(orgId, authToken),
        
      ];

      Promise.all(promises).then(([orgOrderRes, eventsRes, donorsRes, recentDonors, invitedDonors]) => {
        orgOrderRes?.data?.body?.data && setordersCount(orgOrderRes?.data?.body?.data)
        eventsRes?.data?.body?.data && seteventsCount(eventsRes?.data?.body?.data)
        donorsRes?.data?.body?.data && setdonorsCount(donorsRes?.data?.body?.data)
        //invitedDonors?.data?.body?.data && setInviteDonorData(invitedDonors?.data?.body?.data)

        let recentDonorsRes = recentDonors?.data?.body?.data;
        if (recentDonorsRes?.length) {
          const dateFrom = moment().subtract(28, 'd').format('YYYY-MM-DD');
          console.log(recentDonorsRes, "recentDonorsRes")
          setrecentDonorsData(recentDonorsRes.filter((el) => moment(el.createdAt).format('YYYY-MM-DD') > dateFrom))
          setrecentDonorsCount(recentDonorsRes)
        }

        setloading(false)

      }).catch((err) => {
        setloading(false)
        console.log(err)
      });
    }
    getData()
  }, [])

  useEffect(() => {
    const getData = async () => {
      if (props?.planSubscriptionId) {
        const orgId = localStorage.getItem("orgID");
        const authToken = localStorage.getItem("authToken");
        const planStatsRes = await getPlanSubscriptionsStats(authToken, props.planSubscriptionId, orgId);
        if (planStatsRes?.data?.body?.data?.length) {
          let res = planStatsRes?.data?.body?.data;
          let data = res.map(el => {
            let localData = localStorage.getItem("planSubsCount");
            localData = localData ? JSON.parse(localStorage.getItem("planSubsCount")) : [];
            let find = localData.find((text) => text.name === el.name);
            if(dashboardManageFilter?.length > 0 && localData?.length === 0 &&
              dashboardManageFilter?.includes(el.name)) {
              return {
                ...el, checked: true
              }
            } else {
              return {
                ...el, checked: find?.checked ?? false
              }
            }
          });

          data?.length && localStorage.setItem("planSubsCount", JSON.stringify(data))
          setplanSubsCount(data)
        }
        console.log("planStatsRes", planStatsRes?.data?.body?.data)
      }
    };
    getData()
  }, [props.planSubscriptionId]);

// const setactiveManagerFilter = (data) => {
//     let tempPlanCount = data?.dashboardManageFilter?.map(plan => {
//       return {name: plan,checked: true}
//     });
//     localStorage.setItem("planSubsCount", JSON.stringify(tempPlanCount));
//   }
// }

  // ============================== Table Start ======================================//
  const Manage = planSubsCount?.length ? planSubsCount?.filter((el) => el.checked).map((plan) => {
    return { name: plan.name, amount: plan.number }
  }) : [];

  const Orders = [
    {
      name: "New orders",
      amount: ordersCount.newOrders,
      statusColor: "statusgreen"
    },

    {
      name: "Completed orders",
      amount: ordersCount.completedOrders,
      statusColor: "statusgreen"
    },

    {
      name: "Pending orders",
      amount: ordersCount.pendingOrders,
      statusColor: "statusgreen"
    },

    {
      name: "Total order amount",
      amount: "$" + ordersCount.ordersTotal,
      statusColor: "statusgreen"
    },

  ];

  const Events = [
    {
      name: "Total Events",
      amount: eventsCount.totalEvents,
      statusColor: "statusgreen"
    },

    {
      name: "Public Events",
      amount: eventsCount.publicEvents,
      statusColor: "statusgreen"
    },

    {
      name: "Private Events",
      amount: eventsCount.privateEvents,
      statusColor: "statusgreen"
    },

    {
      name: "Completed Events",
      amount: eventsCount.completedEvents,
      statusColor: "statusgreen"
    },

  ];

  const Donations = [
    {
      name: "Donations",
      amount: donorsCount?.donationAmount ? "$" + (donorsCount?.donationAmount / 100).toFixed(2) : "$0",
    },

    {
      name: "New donors",
      amount: donorsCount?.donors ?? 0,
    },

    {
      name: "Recurring donors",
      amount: donorsCount?.recurringDonors ?? 0,
    },

    {
      name: "Invited donors",
      amount: donorsCount?.invitedDonors ?? 0,
    },

  ];

  // console.log("recentDonorsData", recentDonorsData)

  const Recent_Donors = recentDonorsData?.length ? recentDonorsData?.slice(0, 4).map((donor) => {
    return {
      name: donor.fullName,
      amount: donor?.amount ? "$" + (donor?.amount / 100).toFixed(2) : "N/A",
      statusColor: "statusgreen"
    }
  }) : [];

  const Invoice = invoicesData?.length ? invoicesData?.sort((a ,b) => (b.created * 1000) - (a.created * 1000)).slice(0, 4).map((payment) => {
    return {
      name: payment.number,
      date: payment?.created ? moment(payment.created * 1000).format("ll") : "",
      amount: payment?.total ? "$" + (payment.total / 100).toFixed(2) : "N/A",
      statusColor: "statusgreen",
      url: payment?.hosted_invoice_url ? payment?.hosted_invoice_url : ""
    }
  }) : [];

  useEffect(() => {
    if (props.payments?.length) {
      // console.log(props.payments, "props.payments")
      const dateFrom = moment().subtract(90, 'd').format('YYYY-MM-DD');
      setinvoicesData(props.payments?.length ? props.payments.filter((el) => {
        return moment(el.created * 1000).format('YYYY-MM-DD') > dateFrom
      }) : [])
    }
  }, [props.payments])


  const selectdata = [
    { value: 1, select: "Today" },
    { value: 7, select: "Last 7 days" },
    { value: 28, select: "Last 4 weeks" },
    { value: 90, select: "Last 3 months" },
    { value: 365, select: "Last 12 months" },
    { value: 730, select: "Last year" },
  ];
  // ================ Table End =====================//

  // =============== Activities Start ==============//
  const ActivitiesSelect = [
    { value: 1, select: "Today" },
    { value: 7, select: "Last 7 days" }
  ];

  const ActivitiesData = [

    {
      org: "Darul Salaam",
      text: "submitted tax exemption request.",
      Link: "http://daruljannah.org/",
      Date: "Feb 14, 2021, 10:00 AM"

    },

    {
      org: "Darul Salaam",
      text: "submitted tax exemption request.",
      Link: "http://daruljannah.org/",
      Date: "Feb 14, 2021, 10:00 AM"

    },

    {
      org: "Darul Salaam",
      text: "submitted tax exemption request.",
      Link: "http://daruljannah.org/",
      Date: "Feb 14, 2021, 10:00 AM"

    },
  ];

  // ============== Activities End ================//

  const handleFilterChange = async (val, card) => {
    const orgId = localStorage.getItem("orgID");
    const authToken = localStorage.getItem("authToken");

    if (card === "orgOrders") {
      let orgOrderRes = await getOrgOrdersCount(authToken, orgId, val);
      orgOrderRes?.data?.body?.data && setordersCount(orgOrderRes?.data?.body?.data)

    } else if (card === "invoices") {
      if (val === 1) {
        const dateFrom = moment().startOf('day').format('YYYY-MM-DD');
        setinvoicesData(props.payments?.length ? props.payments.filter((el) => {
          return moment(el.created * 1000).format('YYYY-MM-DD') == dateFrom
        }) : [])
      } else if (val) {
        const dateFrom = moment().subtract(val, 'd').format('YYYY-MM-DD');
        setinvoicesData(props.payments?.length ? props.payments.filter((el) => {
          return moment(el.created * 1000).format('YYYY-MM-DD') > dateFrom
        }) : [])
      }
    } else if (card === "donors") {
      if (val === 1) {
        const dateFrom = moment().startOf('day').format('YYYY-MM-DD');
        setrecentDonorsData(recentDonorsCount?.length ? recentDonorsCount.filter((el) => {
          return moment(el.createdAt).format('YYYY-MM-DD') == dateFrom
        }) : [])
      } else if (val) {
        const dateFrom = moment().subtract(val, 'd').format('YYYY-MM-DD');
        setrecentDonorsData(recentDonorsCount?.length ? recentDonorsCount.filter((el) => {
          return moment(el.createdAt).format('YYYY-MM-DD') > dateFrom
        }) : [])
      } else {
        setrecentDonorsData(recentDonorsCount)
      }
    } else if (card === "donations") {
        let donorsRes = await getDonationsDashboard(orgId, val, authToken);
        donorsRes?.data?.body?.data && setdonorsCount(donorsRes?.data?.body?.data)
    }

  }

  const handleCheckboxFilterChange = async (value) => {
    const isLimit = value?.length && value?.filter((el) => el.checked).length > 5 ? false : true;
    if (isLimit) {
      localStorage.setItem("planSubsCount", JSON.stringify(value));
      setplanSubsCount(value);
      try {
        const token = localStorage.getItem("authToken");
        const orgId = localStorage.getItem("orgID");
        let checkout = value?.filter(el => el.checked).map(selected => selected.name);
        await editOrganization({dashboardManageFilter: checkout}, orgId, token );
      } catch {
        console.log("Something went wrong")
      }
    }

  };

  const style = {
    width: '100%',
  };


  const availableSubscriptions = (name) => {
    // console.log("subscriptions", subscriptions)
    let subscriptions = localStorage.getItem("subsData");
    if (name?.length && subscriptions?.length) {
      const arr = name.filter(value => subscriptions.includes(value));
      return arr?.length ? true : false
    }
  }


  const classes = useStyles();
  return (
   
        <div className={classes.Container}>
          {loading ?
            <div style={{ position: 'absolute', top: "50%", left: "50%" }}>
              <CircularProgress color="primary" />
            </div> : null}
          <Grid container spacing={1} >
            <Grid item md={12} sm={12} xs={12}>
              <Grid container spacing={1} >
                <Grid item md={6} sm={6} xs={12} >
                  <TableStats
                    Title="Manage"
                    tabledata={Manage}
                    selectdata={selectdata}
                    seemore=""
                    showFilter={false}
                    onSeeMoreBtnClick={() => console.log("onSeeMoreBtnClick")}
                    showCheckboxFilter={true}
                    checkboxFilterData={planSubsCount}
                    handleCheckboxFilterChange={handleCheckboxFilterChange}
                    FilterIcon={FilterIcon}
                  />
                </Grid>

                {availableSubscriptions(["orders"]) && <Grid item md={6} sm={6} xs={12} >
                  <TableStats
                    Title="Orders"
                    seemore="See More"
                    tabledata={Orders}
                    selectdata={selectdata}
                    showFilter={true}
                    handleFilterChange={(e) => handleFilterChange(e.target.value, "orgOrders")}
                    onSeeMoreBtnClick={() => props.history.push("/orders")}
                    selectDefaultValue={28}
                  />
                </Grid>}

                {availableSubscriptions(["deenfund"]) && <Grid item md={6} sm={6} xs={12} >
                  <TableStats
                    Title="Donations"
                    seemore="See More"
                    tabledata={Donations}
                    selectdata={selectdata}
                    showFilter={true}
                    handleFilterChange={(e) =>  handleFilterChange(e.target.value, "donations")}
                    onSeeMoreBtnClick={() => props.history.push("/deenfund/donations")}
                    selectDefaultValue={28}
                  />
                </Grid>}

                {availableSubscriptions(["deenfund"]) && <Grid item md={6} sm={6} xs={12} >
                  <TableStats
                    Title="Recent Donors"
                    seemore="See More"
                    tabledata={Recent_Donors}
                    selectdata={selectdata}
                    Status={true}
                    showFilter={true}
                    handleFilterChange={(e) => handleFilterChange(e.target.value, "donors")}
                    onSeeMoreBtnClick={() => props.history.push("/deenfund/manage-donors")}
                    selectDefaultValue={28}
                  />
                </Grid>}


                <Grid item md={6} sm={6} xs={12} >
                  <TableStats
                    Title="Invoice"
                    seemore="See More"
                    tabledata={Invoice}
                    selectdata={selectdata}
                    Status={true}
                    showFilter={true}
                    handleFilterChange={(e) => handleFilterChange(e.target.value, "invoices")}
                    nameType={"link"}
                    onNameClick={(data) => data.url ? window.open(data.url) : null}
                    onSeeMoreBtnClick={() => props.history.push("/organization/payment-history")}
                    selectDefaultValue={90}
                  />
                </Grid>


                {availableSubscriptions(["events"]) && <Grid item md={6} sm={6} xs={12} >
                  <TableStats
                    Title="Events"
                    seemore="See More"
                    tabledata={Events}
                    selectdata={selectdata}
                    showFilter={false}
                    selectDefaultValue={1}
                    onSeeMoreBtnClick={() => props.history.push("/events")}
                  />
                </Grid>}
              </Grid>
            </Grid>

             {/* <Grid item md={3} sm={3} xs={12}>
                <Activities  
                    Title="Activities"
                    ActivitiesData={ActivitiesData}
                    selectdata={ActivitiesSelect}
                    Boxheight="100%"
                    selectDefaultValue={1}
               />
            </Grid>  */}
          </Grid>

        </div>
    
      
   
  );
}

export default withRouter(React.memo(DashboardStats));



const useStyles = makeStyles((theme) => ({

  
}));