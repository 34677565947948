import * as constants from "../constants";

function getOrgCategories(data) {
  return {
    type: constants.GET_CATEGORIES,
    payload: data
  };
}


export { getOrgCategories };
