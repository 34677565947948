import React, { useState, useEffect } from "react";
import { SimpleTooltip } from "@dclab/dc-react-ui-kit";

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import TextField from '@material-ui/core/TextField';
import replyIcon from '../../assets/img/replyIcon.svg'
import FileIcon from '../../assets/img/upload_file_black_24dp.svg'
import axios from "axios";
import './style.css';


import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ErrorIcon from '@material-ui/icons/Error';

import { useDropzone } from 'react-dropzone'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


const orgEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")
) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}

const orgBaseUrl = orgEnv[currentEnv];

async function upload(files) {
  // this.setState({
  //   image: files,
  //   showDrag: files.length === 0 ? true : false,
  //   serviceImage: [],
  // });
}

export default function YoutubeModal(props) {
  const [open, setOpen] = React.useState(false);
  const [serviceImage, setServiceImage] = React.useState([]);


  //start dropzone 
  const [files, setFiles] = useState([]);
  const [imgSrc, setImgSrc] = useState("");
  const [error, setError] = useState("");
  const [imageUploaded, setImageuploaded] = useState(false);
  const [uploadButton, setUploadButton] = useState('importUrlButton');

  useEffect(() => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const { getRootProps, getInputProps, isDragReject } = useDropzone({
    multiple: false,
    accept: "image/*",

    onDrop: (acceptedFiles) => {
      const currentFile = acceptedFiles[0];
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          const currentFileSize = parseInt(currentFile.size / 1024);
          const maxFileUploadLimit = parseInt(props.maxLimit * 1024);
          console.log( maxFileUploadLimit + ' '+ currentFileSize );

          if (maxFileUploadLimit > currentFileSize) {
            setFiles(
              acceptedFiles.map((file) =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                  src: reader.result,
                })
              )
            );
            setImgSrc(reader.result);
            setError('');
            setImageuploaded(true);
            setUploadButton('');
          } else {
            setError('Limit exceeded');
          }

        },
        false
      );
      if (acceptedFiles.length > 0) {
        reader.readAsDataURL(currentFile);
      }
    },
    onDropRejected: (rejectedFile) => {
      if (rejectedFile.length > 0 && rejectedFile[0].errors.length > 0) {
        setError('This file type is not accepted')
      }
    },
    onDropAccepted: (e) => {
      // console.log(e);
    },
  });



  const handleRemove = (e) => {

    const newFiles = [];
    setFiles(newFiles);
    setImageuploaded(false);
    setUploadButton('importUrlButton');
  };

  //end dropzone 


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFiles([]);
    setError('');
  };

  const upload = async (files) => {
    // console.log(files);
    setServiceImage(files[0]);

  }

  const saveImage = async () => {
    const uploadedImage = files[0];

    const token = localStorage.getItem("authToken");

    const imageMetaData = {
      imageUrl: uploadedImage.src,
      imageName: uploadedImage.path,
      imageThumbnail: uploadedImage.src,
      fileType: uploadedImage.type,
      imageSize: (parseInt(uploadedImage.size) / 1024).toFixed(2),
    };

    setOpen(false);

    props.loadingIcon();



    const postData = {
      imageMetaData: imageMetaData,
      isVideo: false,
      title: uploadedImage.path,
      mediaURL: uploadedImage.src,
    }



    return new Promise((resolve, reject) => {
      axios
        .post(`${orgBaseUrl}/medias/image`, postData, {
          headers: {
            "Content-Type": "application/json",
            token
          }
        })
        .then((res) => {
          resolve(res);
          setImageuploaded(false);
          setUploadButton('importUrlButton');

          setFiles([]);

          //image uploading is done, now time to change the below area
          props.onChange(res);
          // console.log('hi');

        })
        .catch((error) => {
          reject(error);
        });
    });

  }

  const saveVideo = () => {
    let videoUrl = document.getElementById('youtube-video-id').value;

    if (videoUrl.includes('youtu.be')) {
      videoUrl = videoUrl.replace('https://youtu.be/', '');
    }

    const token = localStorage.getItem("authToken");

    props.loadingIcon();

    const postData = {
      title: videoUrl
    }

    return new Promise((resolve, reject) => {
      axios
        .post(`${orgBaseUrl}/medias/video`, postData, {
          headers: {
            "Content-Type": "application/json",
            token
          }
        })
        .then((res) => {
          resolve(res);
          setOpen(false);
          props.onChange(0);

        })
        .catch((error) => {
          reject(error);
        });
    });

  };

  const [show, setShow] = React.useState(false);


  const onClick = () => {
    console.log('onClock');
    setShow(!show);
  }
  const TooltipClick = () => {
    console.log('Tooltip');
  }


  const [value, setValue] = React.useState(parseInt(props.initialtab));


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="ModalContainer">
      <button className={props.className} type="button" onClick={handleOpen}><AddRoundedIcon /> {props.labelName}</button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="YoutubeModaSummary">

            <div className="ModalCloseIcon"><CloseRoundedIcon onClick={handleClose} /></div>
            <div className="ModalLeftCol ModaltabsContainer">
              <Tabs value={value} onChange={handleChange}>
                { props.hideImage? (''): (
                  <Tab label="Choose file" icon={<img className="fileIcon" src={FileIcon} />} />
                ) }
                {props.hideVideo? ('') : (
                  <Tab label="YouTube video" icon={<YouTubeIcon className="TitleIcon iconYoutube" />} />
                ) }
                
              </Tabs>
            </div>
            <div className="ModalRighttCol">
              <TabPanel value={value} index={0}>

                {error.length > 1 ? (
                  <div className="MediaImgListing">
                    <div className="AddMoerStorageContainer">
                      <ErrorIcon className="warningIcon" />
                      <h1 className="dragTitle">Storage full</h1>
                      <p className="dragSubText">Please free up some space or Upgrade to <a className="LinkText" href="#">upload</a> new image</p>
                      <button className={"SelectButton " + uploadButton} type="button"> Add more storage <ArrowRightAltIcon className="ImportBtnArrowIcon" /></button>
                    </div>
                  </div>
                ) : (
                  <div {...getRootProps()}>
                    <input {...getInputProps({ className: 'dropzone disabled' })} />
                    <div className="ImportFormContainer borderBox">
                      <h1 className="dragTitle">Drag file here </h1>
                      <p className="OrTitle">or</p>
                      <button className={"SelectButton " + uploadButton} type="button"> Select a file <ArrowRightAltIcon className="ImportBtnArrowIcon" /></button>

                    </div>
                  </div>
                )}

                <div className="MediaImgListing">
                  {files.map((file, index) => (
                    <div key={index} className="mediaImagePreview">
                      <div className="MediaImgListingLeft">
                        <div className="imgBox">
                          <div className="checkIcon"><CheckRoundedIcon /></div>
                          <img src={file.src} className="MediaImgListingLeftImg" />
                        </div>
                        <div className="MediaImgListingLeftInfo">
                          <p>{file.name}</p>
                          <span className="ImgSize">{(parseInt(file.size) / 1024).toFixed(1)} KB</span>
                          {/* <span className="failing">Reason for failing</span>                             */}
                        </div>
                      </div>
                      <button onClick={handleRemove} index={index} className="iconDelete" > <DeleteForeverRoundedIcon /> </button>
                    </div>
                  ))}

                </div>
                {imageUploaded ? (
                  <div className="DoneButtonContainer">
                    <button onClick={saveImage} className="DoneButton" type="button">Done <ArrowRightAltIcon className="ImportBtnArrowIcon" /></button>
                  </div>
                ) : (

                  <div></div>
                )}
              </TabPanel>

              <TabPanel value={value} index={1}>
                <div className="ImportFormContainer">

                  <li><YouTubeIcon className="largeIcon iconYoutube" />Video URL</li>
                  <TextField className="urlField " id="youtube-video-id" variant="outlined" placeholder="Enter YouTube video URL" />

                  <button onClick={saveVideo} className="importUrlButton" type="button"> Import from YouTube <ArrowRightAltIcon className="ImportBtnArrowIcon" /></button>
                </div>

                <div className="ModalinfoListContainer">
                  <li className="Listinfo">
                    <SimpleTooltip
                      title={'More Action'} aria-label="add"
                      onClick={() => onClick()}
                      open={show}
                      onTooltipClick={() => TooltipClick()}
                    >
                      <InfoRoundedIcon />
                    </SimpleTooltip>
                    Where can you find a video URL?
                  </li>
                  <li>1. Start watching a video on <a className="BlueLink" target="_blank" href="https://youtube.com">youtube.com <LaunchRoundedIcon /></a></li>
                  <li>2. Under the video, click <a className="DarkLink" href="#">Share <img src={replyIcon} /></a></li>
                  <li>3. A panel will appear, presenting different sharing options. Click the <a className="CopyUrlBtn">Copy</a> button to copy a link to the video that you can paste in the field above.</li>
                </div>
              </TabPanel>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (<div>
    {
      value === index && (
        <h1>{children}</h1>
      )
    }

  </div>)

}





