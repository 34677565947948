import React,  {useState, useEffect} from "react";
import { MasterLayoutMiddle, FooterInner, AppDetails , BaseButton, ModalHardwareDetails, BaseCartButton, AlertAction } from "@dclab/dc-react-ui-kit";
import { PricingModal } from "master-react-lib";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import DeenLabelImg from '../../assets/img/img/deen-label.png';
import CheckCircle from '../../assets/img/check-circle.png';
import info from '../../assets/img/info.png';
import HardwareSlide1 from '../../assets/img/hard-slide1.png';
import Player from '../../assets/img/player.png';
import hardwareUps from "../../assets/img/hardwareUps.png"
import hardwareLcd from "../../assets/img/hardwareLcd.png"
import { withRouter } from "react-router-dom";
import { updatePlanSubscriptions } from "./api";
import {cancelPlan} from "../Organization/api";
import { connect } from "react-redux";
import { showAlert } from "../../redux/actions/alertActions";



import './style.css';

function MoreAppDetails(props) {
    const [details, setdetails] = useState({});
    const [plan, setplan] = useState({})
    const [plans, setplans] = useState({})
    const [cartItems, setcartItems] = useState([])
    const [selectedPlan, setselectedPlan] = React.useState("monthly"); 
    const [openModal, setModal] = React.useState(false); 
    const [showSuccessAlert, setshowSuccessAlert] = useState(false)
    const [planSubscription, setplanSubscription] = useState();
    
    // const [planDuration, setplanDuration] = React.useState("monthly"); 

    const features = details?.features?.length ? details.features.map((feature) => {
      return {title: feature.title, description: feature.description}
    }) : [];

    useEffect(() => {
      if(props?.location?.state?.details) {
        
        if(props?.location?.state?.plans) {
          setplans(props.location.state.plans);
          let plan = props.location.state.plans?.stripeData?.length ? props.location.state.plans.stripeData.find((plan, i) => plan?.subscription_plan?.toLowerCase() === details?.name?.toLowerCase()) : null;
          plan = { ...plan, orgId: props.location.state.plans?.orgId, planSubscriptionId: props.location.state.plans?.planSubscriptionId };
          setplan(plan);

        }

          setdetails(props.location.state.details);
          props?.location?.state?.planSubscription && setplanSubscription(props.location.state.planSubscription);
          console.log("details", props.location.state.details);
          console.log("plan", props.location.state.plan);
      }
    }, [props?.location?.state?.details])

    useEffect(() => {
      let cartData = JSON.parse(localStorage.getItem("appCartItem")) ? JSON.parse(localStorage.getItem("appCartItem")) : [];
      setcartItems(cartData)
    }, [])
  
    const handleSelectedPlan = (e) => {
        setselectedPlan(e.target.checked ? "annually" : "monthly");
    }; 
  
    const handleSelectedPlanData = async (planData) => {
      if(planData[Object.keys(planData)[0]].plan.toLowerCase() === 'free') {
        const token = localStorage.getItem("authToken");
        let currentPlan = plans?.stripeData?.length ? plans.stripeData.find((plan, i) => plan?.subscription_plan?.toLowerCase() === details?.name?.toLowerCase()) : null;
        currentPlan = { ...currentPlan, orgId: plans?.orgId, planSubscriptionId: plans?.planSubscriptionId };
        
        if(currentPlan) {
          let price = currentPlan && currentPlan?.monthlyBilling?.price ? currentPlan.monthlyBilling.price : 0;
          const formData = {
            planId: currentPlan?.planId,
            subscriptionId: currentPlan?.subscriptionId,
            orgId: currentPlan?.orgId,
            customerId: currentPlan?.customerId,
            productId: currentPlan?.productId,
            planSubscriptionId: currentPlan?.planSubscriptionId,
            subscriptionPlan: details?.name,
            subscriptionPlanType: details?.appType,
            subscriptionPaymentFrequency: currentPlan?.frequency,
            subscriptionMonthlyPrice: price,
            removeSubscription: true,
          };
          
          await cancelPlan(formData, token);
          
        }
        // const data = {
        //   ... planData,
        //   appType: 'application',
        //   displayName: details?.displayName ?? '',
        // }
        planData[Object.keys(planData)[0]]['displayName'] = details?.displayName ?? '';
        await updatePlanSubscriptions(planSubscription._id,planData , token);
        props.alertShow("Free Application added successfully!");
        props.history.push('/more-apps');

      
      } else {
          console.log("plan", Object.keys(planData)[0], planData[Object.keys(planData)[0]].plan)
          let appName = Object.keys(planData)[0];
          let paymentFrequency = selectedPlan;
          
          let items = cartItems;
          items = items.length ? items.filter((item) => Object.keys(item)[0] !== appName) : [];

          if(details?.addedPlan && details?.addedPlan?.total && (parseFloat(details?.addedPlan?.total) > 0)) {
            const planMonthPrice = details?.plans?.find((el) => el.name?.toLowerCase() === details?.addedPlan?.plan);
            console.log("planMonthPrice", planMonthPrice, planMonthPrice.monthlyBilling.price)
            items.push({[appName]: {
              ...planData[appName], 
              updatedPlan: details?.addedPlan?.plan?.toLowerCase() === "free" ? false : true, 
              paymentFrequency,
              subscriptionId: plan?.subscriptionId,
              customerId: plan?.customerId,
              subscriptionMonthlyPrice: planMonthPrice?.monthlyBilling?.price ?? 0,
              createdAt: new Date(),
              displayName: details?.displayName ?? '',
              productImage: details?.imageMetaData?.imageUrl ?? ''
            }});
          } else {
            items.push({[appName]: {
              ...planData[appName], 
              paymentFrequency,
              displayName: details?.displayName ?? '',
              productImage: details?.imageMetaData?.imageUrl ?? ''
            }});
          }
          
          localStorage.setItem("appCartItem", JSON.stringify(items));      
          setcartItems(items)
          setshowSuccessAlert(true)
          setTimeout(() => {
            setshowSuccessAlert(false)
          }, 8000);
      }
    };

    const capitalizeFLetter = (val) => {  
      return val ? `${val[0].toUpperCase() + val.slice(1)}` : ""; 
    } 

    let sum = 0;
    const CartItems = cartItems?.length ? cartItems.map((item) => {
      let appName = Object.keys(item)[0];
      let planName = item[appName].plan;
      let displayName = item[appName]?.displayName ?? false;
      sum = sum + parseFloat(item[appName].total)
      return {
        name: `${displayName ? displayName.toUpperCase() : appName.toUpperCase() ?? ""} - ${planName?.toUpperCase() ?? ""}`,
        desc: `$${item[appName].total}/${capitalizeFLetter(item[appName].paymentFrequency)}`,
        id: appName
      }
    }) : [];  

    
    const cartCheckOut =() => {
      console.log('checkOut')
    }
    const removeCartItem =(data, index) => {
      console.log(data, index, 'lopppp')
      
      let items = cartItems;
      items = items.length ? items.filter((item) => data.id !== Object.keys(item)[0]) : [];
      
      console.log("items", items);
      localStorage.setItem("appCartItem", JSON.stringify(items));
      setcartItems(items)
    }
    
    const styles = {
        buttonStyle: {
            backgroundColor:'#2A82F6',
            color:'#fff'
        }
    };

  const style = {
    width:'100%',
  };

  const viewWebsiteClick = () => {
    if(details?.name?.toLowerCase() === "deenfund") {
      if(process.env.REACT_APP_Environment === 'dev') {
        window.open('https://shapla.deenfund.com', '_blank');
      } else {
        window.open('https://deenfund.com', '_blank');
      }
    } else {
      if(process.env.REACT_APP_Environment === 'dev') {
        window.open('https://shapla.deenconnect.io', '_blank');
      } else {
        window.open('https://deenconnect.io', '_blank');
      }
    }
  };

	const onSeePricingClick = () => {
    setModal(true)
  };

  const [integrationPaymentFrequency, setintegrationPaymentFrequency] = React.useState("monthly"); 

  const handleIntegrationDuration = (e) => {
      setintegrationPaymentFrequency(e.target.checked ? "annually" : "monthly");
  }; 

  const onIntegrationBtnClick = () => {
    let appName = details?.name?.toLowerCase();
    
    let items = cartItems;
    items = items.length ? items.filter((item) => Object.keys(item)[0] !== appName) : [];
    items.push({[appName]: {
      paymentFrequency: integrationPaymentFrequency,
      price: integrationPaymentFrequency === "monthly" ? (details?.pricing?.monthlyBilling?.price ?? 0) : (details?.pricing?.annuallyBilling?.price ?? 0),
      total: integrationPaymentFrequency === "monthly" ? (details?.pricing?.monthlyBilling?.price ?? 0) : (details?.pricing?.annuallyBilling?.price ?? 0),
      compatible: details?.compatibleWith?.length ? details?.compatibleWith?.map((el) => el.applicationName).join(", ") : "",
      type: "integration",
      plan: "Integration",
      integrationApi: details?.integrationApi ?? "",
      stripePlanId: integrationPaymentFrequency === "monthly" ? details?.pricing?.monthlyBilling?.stripePlanId : details?.pricing?.annuallyBilling?.stripePlanId,
      createdAt: new Date(),
      productImage: details?.imageMetaData?.imageUrl ?? ''
    }});
    
    console.log("items", items);
    localStorage.setItem("appCartItem", JSON.stringify(items));      
    setcartItems(items)
    setshowSuccessAlert(true)
    setTimeout(() => {
      setshowSuccessAlert(false)
    }, 8000);
  };

  const checkStatus = () => {
    if(details?.status?.toLowerCase() === 'coming soon') {
      return "coming soon"
    } else if (details?.addedPlan) {
      return  "added"
    } else if (details?.appType === "Application") {
      return  details?.name?.toLowerCase() === "free" ? "free" : "paid"
    } else if (details?.appType === "Integration") {
      return details?.pricing?.pricingType?.toLowerCase() === "free" ? "free" : "paid"
    } else {
      return ""
    }
  }

  const addOrManageFunc = async (text) => {
    const token = localStorage.getItem("authToken");

    if(text.toLowerCase() === 'add now'){
      let selectedIntegrations = planSubscription?.extraIntegrations?.selectedIntegrations?.length ? planSubscription.extraIntegrations.selectedIntegrations : [];
      selectedIntegrations.push({
        name: details?.name ?? "",
        price: 0,
        paymentFrequency: "",
        compatible: details?.compatibleWith?.length ? details?.compatibleWith?.map((el) => el.applicationName).join(", ") : "",
        integrationApi: details?.integrationApi ?? "",
        createdAt: new Date()
      });

      const formData = {
        extraIntegrations : {
          selectedIntegrations
        }
      };

      try {
  
        let res = await updatePlanSubscriptions(planSubscription._id, formData, token);
        if(!res?.data?.error) {
          //setshowSuccessAlert(true)
          props.history.push(`/more-app`);
          props.history.push({pathname: `/more-apps`, state: { showSuccessAlert: true }});
          // setTimeout(() => {
          //   setshowSuccessAlert(false)
          // }, 8000);
        } else {
          console.log("type", planSubscription._id, formData, res)
        }

      } catch (error) {
        console.log(error)
      }


    } else if(text.toLowerCase() === 'manage') {
      let plan = plans?.stripeData?.length ? plans.stripeData.find((plan, i) => plan?.subscription_plan?.toLowerCase() === details?.name?.toLowerCase()) : null;
      // let id = plan?._id;
      plan = { ...plan, orgId: plans?.orgId, planSubscriptionId: plans?.planSubscriptionId };

      if(details?.appType === "Application") {
        if(plan) {
          props.history.push({pathname: `/organization/plan-details`, state: { details: details, plan: plan } });
        } else {

        } 
      } else if (details?.appType === "Integration") {
         if(plan) {
            props.history.push({pathname: `/organization/plan-details`, state: { details: details, plan: plan } });
         } else {
            props.history.push({pathname: `/organization/plan-details`, state: { details: details, plan: plan } });
         }
      }
    }
  }

        // HARDWARE MODAL
        const hardwareSlideImages = [
          HardwareSlide1, HardwareSlide1, HardwareSlide1, HardwareSlide1, HardwareSlide1
        ];
      
        const includeInPackage = [
          { title: "DEENKIOSK Player", image: Player}, 
          { title: "Touch Display", image: Player}, 
        ];
      
        const hardwareFeatures = ["24 inch LED", "VESA Mount", "Enable/disable specific Salah", "Search and follow Masajid", "Add your own Salah", "Enable/disable specific Salah"];

  return (
    <div>
        <MasterLayoutMiddle
            header={  
              <div className="PageHeaderContainer BaseCartButtonContainer">
                  {(showSuccessAlert && (
                    <div
                      style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px', Left: '70px' }}
                    >
                      <AlertAction
                        alert={"Added to cart successfully!"}
                        alertType={"success"}
                        onClick={() => setshowSuccessAlert(false)}
                      />
                    </div>
                  ))}
                <div
                  onClick={() => props.history.push("/more-apps")}
                  className="backBtn">
                  <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                  <p style={{ alignSelf: "center" }}>Back</p>{" "}
                </div>
                
               
                <BaseCartButton
                  addToCart={true}
                  CartItems={CartItems}
                  cartCheckOut={cartItems?.length ? () => props.history.push("/cart-details") : null}
                  removeCartItem={removeCartItem}
                  cartSubTotal={sum ? sum.toFixed(2) : 0}
                  cartQuantity={cartItems?.length ?? 0}
                  styles={styles.buttonStyle}
                />
                

              </div>
            }

        content={			
          <div> 

           <div className="AppDetails_container">
            <AppDetails 
                checkCircleIcon={CheckCircle}
                deenLabelImg={details?.appType === "Application" ? details?.iconMetaData?.iconUrl ?? "" : details?.imageMetaData?.imageUrl ?? ""}
                backLink={true}
                backLinkText={"Back to Apps & Integrations"}
                compatibleWith={details?.compatibleWith?.length ? details?.compatibleWith.map((el) => el?.applicationName?.toUpperCase()) : []}
                title={details.displayName ? details.displayName : details?.name ?? ""}
                PricingButton={details?.status?.toLowerCase() === 'coming soon' ? false : details?.appType === "Application" ? true : false}
                subTitle={details?.appType}
                FeauterTitle={true}
                features={features}
                paragraphText={details?.shortDescription}
                viewWebsiteClick={viewWebsiteClick}
                onSeePricingClick={onSeePricingClick}
                //PRICING MODAL PROPS
                checkCircleIcon={CheckCircle}
                // planDuration={planDuration}
                // handlePlanDuration={handlePlanDuration}
                // planSelected={planSelected}
                modalButtonText={details?.addedPlan ? "CHANGE PLAN" : "SEE PRICING"}
                modalButtonStyles={{}}
                infoIcon={info}
                starterPlanDeenkiosk={8}
                premiumPlanDeenkiosk={12}
                showDeviceFooter={true} 
                peragraph={false}
                Hardware={false}
                status={checkStatus()}
                hardwareUps={hardwareUps}
                hardwareLcd={hardwareLcd}
                externalModal={true}
                viewWebsitebutton={details?.appType === "Application" ? true : false}
                viewWebsiteButtonText="View On Website"
                priceAmount={details?.pricing?.pricingType?.toLowerCase() === "free" ? "Free" : integrationPaymentFrequency === "monthly" ? "$" + details?.pricing?.monthlyBilling?.price : "$" + details?.pricing?.annuallyBilling?.price}
                AmountStatus=" /month, paid annually"
                Pricing={details?.status?.toLowerCase() === 'coming soon' ? false : details?.appType === "Integration" && !details?.addedPlan?.name && details?.pricing?.pricingType?.toLowerCase() !== "free" ? true : false}
                pricingButtonLabel={details?.appType === "Integration" && !details?.addedPlan?.name && details?.pricing?.pricingType?.toLowerCase() !== "free" ? "Add To Cart" : "Continue"}
                SwitchSection={details?.appType === "Integration" ? true : false}

                integrationPaymentFrequency={integrationPaymentFrequency}
                setintegrationPaymentFrequency={setintegrationPaymentFrequency}
                integrationDiscount={details?.pricing?.annuallyBilling?.discountPercentage ?? "0"}
                onIntegrationBtnClick={onIntegrationBtnClick}
                handleIntegrationDuration={handleIntegrationDuration}
                integrationDetails={{}}
                viewAddOrManage = {details?.pricing?.pricingType?.toLowerCase() === "free"? true : false}
                addOrManageText = {details?.status?.toLowerCase() === 'coming soon' ? "Coming Soon"  : details?.addedPlan ? "MANAGE" : "ADD NOW"}
                addOrManageFunc = {addOrManageFunc}
              />
              </div>
              
              <PricingModal 
                  openModal={openModal} 
                  setModal={setModal} 
                  selectedPlan={selectedPlan} 
                  handleSelectedPlan={handleSelectedPlan} 
                  handleSelectedPlanData={handleSelectedPlanData} 
                  applicationInfo={details}
                  checkCircleIcon={CheckCircle}
                  modalButtonText={details?.addedPlan ? "CHANGE PLAN" : "SEE PRICING"}
                  infoIcon={info}
                  buttonStyle={{
                    display: "none"
                  }}

                currentPlanName={details?.addedPlan?.plan?.toLowerCase() ?? ""}
                planDiscount={"20"}
                  // HARDWARE MODAL
                deviceTitle={"DEENKIOSK Player"}
                devicePrice={details?.hardware?.[0]?.setupCost}
                hardwareSlideImages={hardwareSlideImages}
                includeInPackage={includeInPackage}
                hardwareFeatures={hardwareFeatures}
                modalButtonText={"SEE DETAILS"}
                modalButtonStyles={{color: "white"}}
                />

              {/* <ModalHardwareDetails 
                numOfDevices={numOfDevices}
                setnumOfDevices={setnumOfDevices}
                deviceTitle={"DEENKIOSK Player"}
                devicePrice={10}
                hardwareSlideImages={hardwareSlideImages}
                includeInPackage={includeInPackage}
                hardwareFeatures={hardwareFeatures}
                onSelectClick={onSelectClick}
                modalButtonText={"SEE DETAILS"}
                modalButtonStyles={{background: "grey", color: "white"}}
              /> */}
                                        
          </div>
        }


        isFooter={true}
        footer={
        <FooterInner 
          style={style}
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
        }
        isWhiteBackground={true}

        />
        
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    alertValue: state.alert.alertValue

  };
};

const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MoreAppDetails));

    // const handlePlanDuration = (e) => {
    //     console.log(e.target.checked)
    //     setplanDuration(e.target.checked ? "annually" : "monthly");
    // }; 
  
    // const planSelected = (planName, planPrice, numOfDevices) => {
    //   console.log("plan name: ", planName)
    //   console.log("plan price: ", planPrice)
    //   console.log("num of devices: ", numOfDevices)
    // };


    // const [numOfDevices, setnumOfDevices] = React.useState(0); 

    // const onSelectClick = () => {
    //   console.log("numOfDevices", numOfDevices)
    // };
    
    // const hardwareSlideImages = [
    //   HardwareSlide1, HardwareSlide1, HardwareSlide1, HardwareSlide1, HardwareSlide1
    // ];
  
    // const includeInPackage = [
    //   { title: "DEENKIOSK Player", image: Player}, 
    //   { title: "Touch Display", image: Player}, 
    // ];
  
    // const hardwareFeatures = ["24 inch LED", "VESA Mount", "Enable/disable specific Salah", "Search and follow Masajid", "Add your own Salah", "Enable/disable specific Salah"];