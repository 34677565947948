import React, { useEffect } from "react";
import {
  BaseTextField,
  BaseButton,
  TextEditor,
  AlertAction,
  MasterLayoutMiddle,
  FooterInner,
} from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { useState } from "react";
import "./style.css";
import { EditFAQWebsite } from "./api";
import { showAlert } from "../../redux/actions/alertActions";
import { connect } from "react-redux";
import messages_content from '../../utils/messages.json';
const { token_expired, success_message_edit_faq } = messages_content;
function EditFaq(props) {
  const [value, setValue] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);
  const [title, setTitle] = useState('');
  const [errorName, setErrorName] = useState(false);
  const [error, seterror] = useState(false);
  const [errorDesc, setErrorDesc] = useState(false);
  useEffect(() => {
    let paramData = props.location.state.detail;
    let para = JSON.parse(paramData[0].answer);
    setValue(para);
    setTitle(paramData[0].question)
  }, [])
  const onChange = (e) => {
    setValue(e);
  };
  const AddNow = async () => {
    const token = localStorage.getItem("authToken");
    let orgId = localStorage.getItem('orgID');
    let obj = {
      question: title,
      answer: JSON.stringify(value),
      orgId: orgId
    }
    if (title === '' || value[0].children[0].text === '') {
      if (title === '') {
        setErrorName(true)
      }
      if (value[0].children[0].text === '') {
        setErrorDesc(true)
      }
    } else {
      try {
        let result = await EditFAQWebsite(obj, token, props.match.params.id);
        props.alertShow(success_message_edit_faq)
        if (result.status === 200) {
          props.history.push("/website/faq");
        }
      } catch (err) {
        seterror(err.response && err.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    }
  };

  const handleChange = (event) => {
    setTitle(event.target.value);
  }
  const styles = {
    formBody: {
      background: "#fff",
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A",
    },
    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
    },
    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
  };
  const style = {
    width: '100%',
  };


  return (
    <MasterLayoutMiddle
      header={
        <>
          <div
            onClick={() => props.history.push("/website/faq")}
            className="backBtn"
            style={{ marginTop: 0 }}
          >
            <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
            <p style={{ alignSelf: "center" }}>Back</p>{" "}
          </div>
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
        </>
      }
      isForm={true}
      formData={
        <div>
          <div>
            <h1 className="title_top">Edit Frequently Asked Questions</h1>
            <div className="dasheddBorder"></div>
          </div>
          <Grid container spacing={3}>
            <Grid item md={12} xl={12}>
              <div className="field_box">
                <BaseTextField
                  value={title}
                  InputProps={false}
                  onChange={event => handleChange(event)}
                  count={100}
                  style={{ width: '100%' }}
                  color="primary"
                  //helperText="Enter a title for the slider"
                  label="Faq Title"
                  error={errorName}
                />
              </div>
            </Grid>
            <Grid item md={12} xl={12} style={{ margin: "auto" }}>
              <TextEditor
                label={"Description"}
                onChange={(e) => onChange(e)}
                value={value}
                style={styles.editor}
                error={errorDesc}
                maxCharCount={500}
              />
            </Grid>
            <Grid item md={12} xl={12}>
              <div className="form_button">
                <span className="btnCencel">
                  <BaseButton text="CANCEL" onClick={() => props.history.push("/website/faq")} />
                </span>
                <BaseButton text="UPDATE" onClick={() => AddNow()} />
              </div>
            </Grid>
          </Grid>
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(0, mapDispatchToProps)(EditFaq);
