import React, { Component } from "react";
import {
  BaseTextField,
  BaseButton,
  BaseStepper,
  BaseStepperExtended,
  FormPublishUnpublish,
  DrawerMain,
  BaseRadio,
  MasterLayoutMiddle,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import MomentTime from "moment-timezone";
import Typography from "@material-ui/core/Typography";
import componentImg from "../../assets/img/component.png";
import "./style.css";
import { getAllSalah, getSalah, UpdateSalah } from "../Donations/deen-kioks-website/Apis";
import { CircularProgress } from "@material-ui/core";
import Moment from "moment";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import { connect } from "react-redux";
import messages_content from '../../utils/messages.json';
import { editOrganization, getOrganizationData } from "../Organization/api";
import { getSalahsWithAppId, updateSalahsWithAppId } from "./api";
const { token_expired, publish_date_before_unpublish, success_message_on_update_salah } = messages_content;
const styles = {
  formBody: {
    background: "#fff",
  },
  formTitle: {
    fontSize: 22,
    color: "#5A5A5A",
  },
  contents: {
    padding: 24,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: "76%",
    marginTop: 70,
    marginLeft: "20%",
  },
  formTitleSub: {
    fontSize: 14,
    color: "#818E94",
  },
  dottedBorder: {
    border: "#1px dashed #D3D8DA",
  },
  pageTitle: {
    color: "#5A5A5A",
    fontSize: "28px",
    paddingBottom: "30px",
    textAlign: "center",
  },
};
class EditSalah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postTimeDate: "",
      postTime: "",
      postDate: "",
      checked: false,
      publishDate: "Immediately",
      publishTime: "",
      unPublishDate: "Never",
      unPublishTime: "",
      unPublishTimestamp: 0,
      activeStep: 0,
      visibility: 'Public',
      publish: "Immediately",
      unPublish: "Never",
      openDrawerSalah: false,
      UTCDate: '',
      UTCTime: '',
      UTCDateUnPublish: '',
      UTCTimeUnPublish: '',
      validUnPublish: true,
      validPublish: true,
      publishedDateAndTime: '',
      unPublishedDateAndTime: '',
      obj: [
        { label: 'Salāt al-Fajr', helperText: 'Dawn Prayer', select: false, detail: 'Dawn Prayer' },
        { label: 'Salāt al-Fajr', helperText: 'Dawn Prayer', select: false, detail: 'Noon Prayer' },
        { label: 'Salāt al-Fajr', helperText: 'Dawn Prayer', select: false, detail: 'Afternoon Prayer' },
        { label: 'Salāt al-Fajr', helperText: 'Dawn Prayer', select: false, detail: 'Sunset Prayer' },
        { label: 'Salāt al-Fajr', helperText: 'Dawn Prayer', select: false, detail: 'Night Prayer' },
        { label: 'Salāt al-Fajr', helperText: 'Dawn Prayer', select: false, detail: 'Friday Prayer' },
        { label: 'Salāt al-Fajr', helperText: 'Dawn Prayer', select: false, detail: 'Ramadan Night Prayer' },
      ],
      selectedSalah: [],
      Loading: false,
      waqt: '',
      error: false,
      dateError: false,
      orgIntegrateData: {},
      ExistingSalah: []
    };
  }
  async componentDidMount() {
    this.setState({ Loading: true })
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      let SalahId = this.props.match.params.id;
      const getSalahs = await getSalahsWithAppId(token, orgId, 'deenweb')
      const SalahsData = getSalahs.data.body.data
      // const getOraganization = await getOrganizationData(orgId, token);
      // const orgIntegateData = getOraganization?.data?.body?.data?.enabledIntegrations;
      // const SalahList = orgIntegateData.deenweb?.salahs?.selectedSalahs
      // this.setState({orgIntegrateData: orgIntegateData, ExistingSalah: SalahList})
      const GetSalahID = SalahsData.filter(text => text._id === SalahId)
      //console.log("GetSalahID[0].publishTo: ", GetSalahID[0].publishTo)
      // let result = await getSalah(SalahId, token)
      // let GetSalahID = result.data.body.data;

      const resOrg = await getOrganizationData(orgId);
      const orgIntegate = resOrg.data?.body?.data?.enabledIntegrations;

      // console.log("orgIntegate")
      // console.log(orgIntegate)

      const salah = orgIntegate?.deenweb?.salahs?.selectedSalahs
      console.log("salah with App ID: ", salah)
      console.log("SalahId: ", SalahId)

      // const resData = salah.filter(({status, publishTo, publishFrom}) => status === "Public"); // && (new Date(publishTo) > new Date()) && (new Date(publishFrom) < new Date())
      // console.log("salah with App ID: ", resData)

      const selectedSettings = salah.filter(text => text.salah === SalahId)


      console.log("selectedSettings[0]: ", selectedSettings)

      const ObjectS = GetSalahID
      this.setState({
        selectedSalah: ObjectS,
        publishDate: selectedSettings[0].publishFrom === "Immediately" ? selectedSettings[0].publishFrom : `${Moment(selectedSettings[0].publishFrom).format("ddd")}, ${Moment(selectedSettings[0].publishFrom).format("MMM D, YYYY")}`,
        validPublish: false,
        postDate: selectedSettings[0].publishFrom === "Immediately" ? selectedSettings[0].publishFrom : Moment(selectedSettings[0].publishFrom).format("YYYY-MM-DD"),
        UTCDate: Moment(selectedSettings[0].publishFrom).format('YYYY-MM-DD'),
        publishTime: Moment(selectedSettings[0].publishFrom).format("h:mm A"),
        postTime: `${Moment(selectedSettings[0].publishFrom).format("HH:mm:ss")}.000Z`,
        UTCTime: Moment(selectedSettings[0].publishFrom).format("HH:mm:ss"),
        visibility: selectedSettings[0].status, //Moment(selectedSettings[0].publishTo) !== Moment() ? 'Hidden' : selectedSettings[0].status,
        unPublishedDateAndTime: selectedSettings[0].publishTo,
        publishedDateAndTime: selectedSettings[0].publishFrom,
      })
      const year = new Date(selectedSettings[0].publishTo).getFullYear();
      console.log("YEADAS : ", this.state.publishTime)

      this.setState({
        UnPublishTimestamp: selectedSettings[0].publishTo,
        UTCDate: selectedSettings[0].publishFrom === "Immediately" ? selectedSettings[0].publishFrom : Moment(selectedSettings[0].publishFrom).format('YYYY-MM-DD'),
        UTCTime: Moment(selectedSettings[0].publishFrom).format("HH:mm:ss"),
        validUnPublish: false,
        unPublishDate: year === 2099 ? "Never" : `${Moment(selectedSettings[0].publishTo).format("ddd")}, ${Moment(selectedSettings[0].publishTo).format("MMM D, YYYY")}`,
        UTCDateUnPublish: Moment(selectedSettings[0].publishTo).format('YYYY-MM-DD'),
        unPublishTime: Moment(selectedSettings[0].publishTo).format("h:mm A"),
        UTCTimeUnPublish: Moment(selectedSettings[0].publishTo).format("HH:mm:ss")
      })


      const response = await getAllSalah(orgId, token);
      const data = response.data.body.data;
      const filter = data.filter(text => !text.website && text.active === true)
      const salahList = filter.map((text, index) => {
        let string = text.salahName.split(' ');
        return (
          {
            ...text,
            label: string[0] + ' ' + string[1],
            select: false,
            detail: string[2] + ' ' + string[3],
          }
        )
      })
      this.setState({ obj: salahList, Loading: false });
    } catch (err) {
      this.setState({ error: err.response && err.response.data.message })
      this.setState({ Loading: false })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  }
  handleChange = (label, index) => {
    let radios = this.state.obj;
    let array = radios.map((text, ind) => {
      if (index === ind) {
        return (
          {
            ...text,
            label: text.label,
            helperText: text.helperText,
            select: !text.select,
            detail: text.detail
          }
        )
      } else {
        return (
          {
            ...text,
            label: text.label,
            helperText: text.helperText,
            select: false,
            detail: text.detail
          }
        )
      }
    })
    this.setState({ obj: array });


  }
  handleNext = () => {
    if (this.state.selectedSalah.length === 0) {
      alert("Select Salah First...");
    } else {
      this.setState({ activeStep: this.state.activeStep + 1 })
      this.props.history.push(`/website/edit-salah/${this.state.selectedSalah[0]._id}`)
    }
  };
  handleBack = () => {
    this.props.history.push("/website/manage-salah");
    // this.setState({ activeStep: this.state.activeStep - 1 })
  };
  render() {
    const onBack = () => {
      this.props.history.push("/website/manage-salah");
      // this.setState({ activeStep: 0 });
    }
    const onVisibilitySave = () => {
      console.log("visibility saved");
    };
    const onVisibilityCancel = () => {
      console.log("visibility cancelled");
    };
    const onPublishSave = () => {
      let Time = this.state.publishTime.split(' ');
      let dated = MomentTime(new Date(this.state.publishDate)).format('YYYY-MM-DD');
      let split = Time[0].split(':');
      let Hours = ''
      let H;
      if (Time[1] === 'AM') {
        H = parseInt(split[0]);
        Hours = H + ':' + split[1]
      } else {
        H = parseInt(split[0]) + 12;
        Hours = H + ':' + split[1]
      }
      let date = `${dated + 'T' + Hours}:00.00Z`
      this.setState({ publishedDateAndTime: date });
    }
    const onPublishCancel = () => {
      console.log("onPublishCancel")
    };
    const onUnPublishSave = () => {
      let Time = this.state.unPublishTime.split(' ');
      let dated = MomentTime(new Date(this.state.unPublishDate)).format('YYYY-MM-DD');
      let split = Time[0].split(':');
      let Hours = ''
      let H;
      if (Time[1] === 'AM') {
        H = parseInt(split[0]);
        Hours = H + ':' + split[1]
      } else {
        H = parseInt(split[0]) + 12;
        Hours = H + ':' + split[1]
      }
      let date = `${dated + 'T' + Hours}:00.00Z`
      this.setState({ unPublishedDateAndTime: date })
    };
    const onUnPublishCancel = () => {
      console.log("onUnPublishCancel")
    };
    const getPublishDate = (value) => {
      if (value === "Immediately") {
        this.setState({
          publishedDateAndTime: '',
          UTCDate: '',
          publish: 'Immediately',
          publishDate: 'Immediately',

        });
      } else {
        this.setState({
          publishDate: `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`,
          validPublish: false
        })
        this.setState({ postDate: Moment(value).format("YYYY-MM-DD"), UTCDate: Moment(value).format('YYYY-MM-DD') })
      }
    };
    const getPublishTime = (value) => {
      this.setState({ publishTime: Moment(value).format("h:mm A") });
      this.setState({ postTime: `${Moment(value).format("HH:mm:ss")}.000Z`, UTCTime: Moment(value).format("HH:mm:ss") })
    };
    const getUnPublishDate = (value) => {
      if (value == "Never") {
        this.setState({
          unPublishedDateAndTime: '',
          UTCDateUnPublish: '',
          unPublish: 'Never',
          unPublishDate: 'Never'
        });
      } else {
        this.setState({ UnPublishTimestamp: value, validUnPublish: false })
        this.setState({ unPublishDate: `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`, UTCDateUnPublish: Moment(value).format('YYYY-MM-DD') })
      }
    };
    const getUnPublishTime = (value) => {
      this.setState({ unPublishTime: Moment(value).format("h:mm A"), UTCTimeUnPublish: Moment(value).format("HH:mm:ss") });
    };
    const {
      activeStep,
      visibility,
      publish,
      unPublish,
      openDrawerSalah,
      obj,
      publishDate,
      selectedSalah,
      unPublishDate,
      Loading,
      waqt,
      UTCDate,
      UTCTime,
      UTCDateUnPublish,
      UTCTimeUnPublish,
      validPublish,
      validUnPublish,
      publishedDateAndTime,
      unPublishedDateAndTime,
      dateError
    } = this.state;
    const closeDrawer = () => {
      this.setState({ openDrawerSalah: false });
    };
    const onCancel = () => {
      this.setState({ openDrawerSalah: false });
    };
    const onSaveDrawer = () => {
      let data = obj.filter(text => {
        return text.select === true
      })
      let time = data[0].label.split('-')
      this.setState({ selectedSalah: data, openDrawerSalah: false, waqt: time[1] })
    }

    const convertUTC = (date) => {
      return date.toISOString()
    }
    const onSave = async () => {
      const { orgIntegrateData, ExistingSalah, selectedSalah } = this.state;
      const token = localStorage.getItem("authToken");
      const orgId = localStorage.getItem('orgID');
      const data = UTCDate === 'Immediately' ? UTCDate : UTCDate === '' ? new Date() : new Date(`${UTCDate + ' ' + UTCTime}`)
      const dataU = data === 'Immediately' ? data : convertUTC(data);
      const dataE = UTCDateUnPublish === '' ? new Date() : new Date(`${UTCDateUnPublish + ' ' + UTCTimeUnPublish}`)
      const UnpublishTime = UTCDateUnPublish === 'Never' ? UTCDateUnPublish : convertUTC(dataE);
      // const Array = ExistingSalah.length ? ExistingSalah : []
      // const FilteredArray = Array.filter(text => text._id !== selectedSalah[0]._id)


      console.log("selectedSalah: ", selectedSalah[0])
      const Check = {
        website: {
          status: visibility,
          publishFrom: publishDate === 'Immediately' ? publish : dataU,
          publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime
        }
      }
      const objData = {

        salah: selectedSalah[0]._id,
        publishFrom: publishedDateAndTime === '' ? publish : dataU,  // DATE OBJECT
        publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime,
        status: visibility,
        featured: false
      }
      const previousUnpublish = Check.website.publishFrom !== 'Immediately' ? Check.website.publishTo > Check.website.publishFrom : true;
      if (Check.website.publishTo === 'Never' || previousUnpublish) {
        try {
          await updateSalahsWithAppId(token, orgId, 'deenweb', selectedSalah[0]._id, objData)
          this.props.alertShow(success_message_on_update_salah)
          this.props.history.push("/website/manage-salah");
        } catch (err) {
          this.setState({ error: err.response && err.response.data.message })
          setTimeout(() => {
            this.setState({ error: false })
          }, 8000);
        }
      } else {
        this.setState({ dateError: true })
        setTimeout(() => {
          this.setState({ dateError: false })
        }, 8000)
      }
    };
    const getSteps = () => {
      return [
        { number: 1, label: "Select Salah" },
        { number: 2, label: "Publish" },
      ];
    }
    return (
      <MasterLayoutMiddle
        header={
          <>
            {
              dateError &&
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={publish_date_before_unpublish}
                  alertType={"error"}
                  onClick={() => this.setState({ dateError: false })}
                />
              </div>
            }
            {
              this.state.error && this.state.error === "Invalid token" && (
                <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                  <AlertAction
                    alert={token_expired}
                    alertType={"error"}
                    onClick={() => this.setState({ error: false })}
                  />
                </div>
              )
            }
          </>
        }
        isForm={true}
        isWhiteBackground={true}
        formData={
          Loading ?
            <div
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </div>
            :
            <>
              <BaseStepperExtended
                title="Edit Salah"
                getSteps={getSteps}
                alternativeLabel={true}
                handleBack={() => this.handleBack()}
                handleNext={() => this.handleNext()}
                handleBackPreviousPage={() => this.props.history.push('/website/manage-salah')}
                nonLinear={true}
                orientation={"horizontal"}
                btnStyles={styles.stepper}
                onStepClick={(obj) => this.setState({ activeStep: obj - 1 })}
                activeStep={activeStep}
              />
              {
                this.state.error && this.state.error === "Invalid token" && (
                  <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                    <AlertAction
                      alert={token_expired}
                      alertType={"error"}
                      onClick={() => this.setState({ error: false })}
                    />
                  </div>
                )
              }
              {activeStep === 0 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Select Salah</h2>
                    <Typography style={styles.formTitleSub}>
                      Add “New Salah” from Home screen &gt; Manage &gt; Salah
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={selectedSalah.length > 0 ? selectedSalah?.[0]?.salahName.fullName : ''}
                        InputProps={true}
                        label="Select Salah"
                        placeholder=""
                        disabled={true}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      {
                        <div className="wakt_feild">
                          <div>
                            <img
                              src={componentImg}
                              alt="componentmg"
                              style={{ width: 24, height: 24 }}
                            />
                          </div>
                          <div>
                            <p>{`${waqt ? waqt : 'fajr'} waqt starts at ${selectedSalah.length > 0 ? Moment(selectedSalah[0]?.salahStart.waktTime).utcOffset(0).format('h:mm A') : ''}`}</p>
                            <p>
                              <span>
                                Method - {selectedSalah?.[0]?.settings.waktMethodString ? selectedSalah[0]?.settings.waktMethodString : ''}
                              </span>{" "}
                            </p>
                          </div>
                        </div>
                      }
                      <div className="time_zone_field">
                        <img
                          src={componentImg}
                          alt="componentmg"
                          style={{ width: 24, height: 24 }}
                        />
                        <p>
                          {selectedSalah.length > 0 ? selectedSalah[0]?.settings?.salahMethod?.timezone : ''}
                        </p>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="dasheddBorder_form"></div>
                  </Grid>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Location</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <BaseTextField
                      value={selectedSalah.length > 0 ? selectedSalah?.[0]?.address?.locationName : ''}
                      InputProps={true}
                      label="Location"
                      placeholder=""
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div className="dasheddBorder_form"></div>
                  </Grid>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Date and Time</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={`${selectedSalah?.length > 0 && selectedSalah?.[0]?.salahStart?.waktTime ? Moment(selectedSalah?.[0]?.salahStart?.waktTime).format("MMM DD, YYYY") : ''}${selectedSalah?.length > 0 ? Moment(selectedSalah?.[0]?.salahStart?.iqamahTime).utcOffset(0).format("HH:mm:ss") && selectedSalah?.[0]?.salahStart?.waktTime ? ', ' : '' : ''}${selectedSalah?.length > 0 ? Moment(selectedSalah?.[0]?.salahStart?.iqamahTime).utcOffset(0).format("h:mm A") : ''}`}
                        label="Start Date and Time"
                        placeholder=""
                        disabled={true}
                        style={{ width: '100%' }}
                        onClick={() =>
                          this.setState({ openDrawer: true, isStartDate: true })
                        }
                        InputProps={true}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={`${selectedSalah?.length > 0 && selectedSalah?.[0]?.salahEnd?.endTime ? Moment(selectedSalah?.[0]?.salahEnd?.endTime).format("MMM DD, YYYY") : ''}${selectedSalah?.length > 0 ? Moment(selectedSalah?.[0]?.salahEnd?.endTime).format("HH:mm:ss") && selectedSalah?.[0]?.salahEnd?.endTime ? ', ' : '' : ''}${selectedSalah?.length > 0 ? Moment(selectedSalah?.[0]?.salahEnd?.endTime).utcOffset(0).format("h:mm A") : ''}`}
                        label="End Date and Time"
                        placeholder=""
                        disabled={true}
                        style={{ width: '100%' }}
                        InputProps={true}
                        onClick={() =>
                          this.setState({ openDrawer: true, isEndDate: true })
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={`${selectedSalah?.length > 0 ? selectedSalah?.[0]?.salah?.repeat?.category : ''}`}
                        label="Repeat"
                        placeholder=""
                        style={{ width: '100%' }}
                        disabled={true}
                        InputProps={true}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      className="addSalah"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div className="form_button">
                        <p
                          className="cancel"
                          onClick={() => {
                            this.props.history.push("/website/manage-salah");
                          }}
                        >
                          CANCEL
                        </p>
                        <BaseButton
                          text="CONTINUE"
                          onClick={() => this.handleNext()
                          }
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
              {activeStep === 1 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className="field_box ManageSalahField">
                      <FormPublishUnpublish
                        visibility={visibility}
                        setVisibility={(e) => this.setState({ visibility: e })}
                        publish={this.state.publish}
                        unPublish={this.state.unPublish}
                        publishDate={this.state.publishDate}
                        publishTime={this.state.publishTime}
                        setPublishDate={(e) => this.setState({ publishDate: e })}
                        setPublishTime={(e) => this.setState({ publishTime: e })}
                        getPublishDate={getPublishDate}
                        getPublishTime={getPublishTime}
                        unPublishDate={unPublishDate}
                        unPublishTime={this.state.unPublishTime}
                        setUnPublishDate={(e) => this.setState({ unPublishDate: e })}
                        setUnPublishTime={(e) => this.setState({ unPublishTime: e })}
                        getUnPublishDate={getUnPublishDate}
                        getUnPublishTime={getUnPublishTime}
                        onVisibilitySave={onVisibilitySave}
                        onVisibilityCancel={onVisibilityCancel}
                        onPublishSave={onPublishSave}
                        onPublishCancel={onPublishCancel}
                        onUnPublishSave={onUnPublishSave}
                        onUnPublishCancel={onUnPublishCancel}
                        onSave={onSave}
                        onBack={onBack}
                        unPublishTimestamp={this.state.unPublishTimestamp}
                        showFeatured={false}
                        featuredText="Feature this on homepage"
                        checked={this.state.checked}
                        onSetChecked={() => this.setState({ SetChecked: !this.state.checked })}
                        minDateUnpublish={publishDate}
                        maxDatepublish={unPublishDate}
                        disabledSave={validUnPublish}
                        disabledSaveP={validPublish}
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
              <DrawerMain
                open={openDrawerSalah}
                openHandler={closeDrawer}
                title="Side Drawer"
                onCancel={onCancel}
                onSave={onSaveDrawer}
                loading={false}
              >
                {
                  obj.map((text, index) => {
                    return (
                      <>
                        <BaseRadio
                          label={text.label}
                          checked={text.select}
                          onChange={() => this.handleChange(text.label, index)}
                          subDetails={text.detail}
                        />
                        <div className="dasheddBorder" style={{ margin: 0 }}></div>
                      </>
                    )
                  })
                }
              </DrawerMain>
            </>
        }
      />
    );
  }
}
const mapStateToProps = (state) => ({
  alert: state.alert,
});
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
  alertHide: (res) => dispatch(hideAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditSalah);