import * as constants from "./../constants";

export const registerUser = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    method: "POST",
    url: "/auth/register",
    data,
    success: (response) => setRegUserInfo(data),
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});

export const loginUser = (data, onSuccess, onError) => ({
  type: constants.API,
  payload: {
    method: "POST",
    url: "/auth/authenticate",
    data,
    success: (response) => setUserInfo(response),
    postProcessSuccess: onSuccess,
    postProcessError: onError,
  },
});

export const logoutUser = () => {
  localStorage.removeItem("USER_INFO");
  return { type: constants.RESET_USER_INFO };
};

const setUserInfo = (response) => {
  //const parseToken = JSON.parse(atob(data.data.token.split(".")[1]));
  const userInfo = {
    token: response.body.data.token,
    isLoggedIn: true,
  };
  localStorage.setItem("USER_INFO", JSON.stringify(userInfo));
  return { type: constants.SET_USER_INFO, payload: userInfo };
};

const setRegUserInfo = (localData) => {
  // const parseToken = JSON.parse(atob(data.data.token.split(".")[1]));
  const userInfo = {
    name: {
      firstName: localData.firstName,
      lastName : localData.lastName
    },
    userName: localData.userName,
    token: localData.token,
    password: localData.password,
    isLoggedIn: false,
  };
  // localStorage.setItem("USER_INFO", JSON.stringify(userInfo));
  return { type: constants.SET_REG_USER_INFO, payload: userInfo };
};
