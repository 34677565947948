import React, { Component } from "react";
import { connect } from "react-redux";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {
  BaseTextField,
  BaseButton,
  AlertAction,
  MasterLayoutMiddle,
  FooterInner,
  TextEditor,
  FormSwitch
} from "@dclab/dc-react-ui-kit";
import {CircularProgress, TextField} from "@material-ui/core";
import "./style.css";
import "../../Organization/style.css";
import { getReminders, addReminder } from "./Apis/index";
import Grid from '@material-ui/core/Grid';
import { showAlert } from '../../../redux/actions/alertActions';
import messages_content from '../../../utils/messages.json';
const { 
    token_expired,
    reminder_title_already_exist
  } = messages_content;
class AddReminders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reminderTitle: "",
      reminderTitleCount: 0,
      reminderDescription: [{ type: "paragraph", children: [{ text: "" }] }],
      featured: true,
      descriptionCount: 0,
      showAlert: false,
      success: false,
      error: false,
      openDrawer: false,
      reminderImage: [],
      image: [],
      loading: false,
      getReminders: [],
      seError: false,
    };
    this.onDescriptionStateChange = this.onDescriptionStateChange.bind(this);
    this.addService = this.addService.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }
  async componentDidMount() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const res = await getReminders(orgId, token);
      this.setState({
        getReminders: res.data.body.data,
      });
    } catch (error) {
      this.setState({
        seError: error && error.response && error.response.data.message,
      });
    }
  }
  changeHandler = (name, val) => {
    if (name === "name") {
      // if (val.length < 36) {
        // if (/^(?![\s.]+$)[a-zA-Z\s.]*$/.test(val)) {
          this.setState({ reminderTitle: val, reminderTitleCount: val.length });
        // } else {
        //   return false;
        // }
      // }
    }
  };
  onDescriptionStateChange = (editorState) => {
    let sum = 0;
    editorState.map((st) => {
      if (st.type === "bulleted-list" || st.type === "numbered-list") {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    this.setState({
      reminderDescription: editorState,
      descriptionCount: sum,
    });
  };
  addService = async () => {
    this.setState({ loading: true });
    const {
      reminderDescription,
      reminderTitle,
      featured,
      descriptionCount,
      getReminders,
    } = this.state;
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    const obj = {
      title: reminderTitle,
      description: JSON.stringify(reminderDescription),
      status: featured,
      orgId,
      frequency: "daily",
    };
    if (!reminderTitle || descriptionCount === 0) {
      this.setState({ error: true, loading: false });
    } else {
      let allReminders = getReminders.filter(
        (item) => item.title === reminderTitle
      );
      if (allReminders.length > 0) {
        this.setState({
          error: reminder_title_already_exist,
          loading: false,
          showAlert: true,
        });
      } else {
        try {
          await addReminder(obj, token);
          this.setState({
            success: true,
            showAlert: true,
            reminderTitle: "",
            featured: false,
            loading: false,
          });
          this.props.showAlert('addReminder')
          this.props.history.push("/reminders");
        } catch (error) {
          this.setState({
            error: error && error.response && error.response.data.message,
            showAlert: true,
            loading: false,
          });
        }
      }
    }
  };
  render() {
    const {
      reminderTitle,
      reminderDescription,
      featured,
      descriptionCount,
      showAlert,
      success,
      error,
      openDrawer,
      image,
      reminderImage,
      loading,
      reminderTitleCount,
    } = this.state;

    const style = {
      width: "100%",
    };

    const textEditorStyle = {
      border: "1px solid black",
      color: "gray",
    };
    return (
      <MasterLayoutMiddle
        header={
          <>
            <div
              onClick={() => this.props.history.push("/reminders")}
              className="backBtn"
            >
              <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
              <p style={{ alignSelf: "center" }}>Back</p>{" "}
            </div>
            {error && error === "Invalid token" && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => this.setState({ error: false })}
                />
              </div>
            )}
            {showAlert && error && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={
                    error === "Invalid token"
                      ? token_expired
                      : error
                  }
                  alertType={"error"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
          </>
        }
        isForm={true}
        formData={
          <>
            <div>
              <h1 className="title_top">Add New Reminder</h1>
              <div className="dasheddBorder"></div>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12}>  <h2 className="sub_title">Reminder Details</h2> </Grid>
              <Grid item xs={12}>
                <div className="field_box">
                  <TextField
                    label="Reminder Title"
                    style={{ width: "100%" }}
                    helperText=" "
                    value={reminderTitle}
                    onChange={(e) => this.changeHandler("name", e.target.value)}
                    error={!success && error && !reminderTitle}
                    helperText={
                      !success &&
                      error &&
                      !reminderTitle &&
                      "Please enter reminder title"
                    }
                    placeholder=" "
                    inputProps={{
                      maxLength: 35
                    }}
                  />
                  <div style={{ display: "flex", justifyContent: "flex-end", }}>
                    <span className="char-count">CHAR {reminderTitleCount}/35</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextEditor
                  onChange={(e) => this.onDescriptionStateChange(e)}
                  disabled={false}
                  value={reminderDescription}
                  style={textEditorStyle}
                  label={"Description"}
                  helperText={
                    error &&
                    !descriptionCount &&
                    "Please add reminder description"
                  }
                  error={error && !descriptionCount}
                  maxCharCount={200}
                />
              </Grid>
              <Grid item xs={12}>
                <div className="field_box border_top_bottm">
                  <FormSwitch
                    headline='Reminder Status'
                    body="Enabling would make the reminder available for publishing"
                    values={featured}
                    handleSwitchChange={() => this.setState({ featured: !featured })}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <div
                    style={{
                      marginTop: 30,
                      display: "flex",
                      justifyContent: "center",
                      width: 500,
                    }}
                  >
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                    <div className="form_button">
                      <p className="cancel" onClick={() => this.props.history.push("/reminders")} >CANCEL</p>
                      <BaseButton text="ADD NOW" onClick={() => this.addService()} />
                    </div>
                  )}
              </Grid>

            </Grid>
          </>
        }
        isFooter={true}
        footer={<FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />}
        isWhiteBackground={true}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alertValue: state.alert.alertValue
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    showAlert: (res) => dispatch(showAlert(res)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddReminders);
