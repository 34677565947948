import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { MasterLayoutMiddle, FooterInner, FormPublishUnpublish, DrawerRepeatDays, DrawerMain, BaseTextField, DrawerListRadioButton, AlertAction } from "@dclab/dc-react-ui-kit";
import Moment from "moment";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { Link } from "react-router-dom";
import { getHomeScreens, addScheduleToDevice } from "./Apis/index";
import Layout1 from "../../assets/images/DK_Layout_1.png"
import Layout2 from "../../assets/images/DK_Layout_2.png"
import Layout3 from "../../assets/images/DK_Layout_3.png"
import Layout4 from "../../assets/images/DK_Layout_4.png"
import Layout5 from "../../assets/images/DK_Layout_5.png"

const orgId = localStorage.getItem("orgID")
const token = localStorage.getItem("authToken")

function AddSchedule(props) {
  // ================ Bublish form Start ====================//
  const [visibility, setVisibility] = React.useState("Public");
  const [publish, setPublish] = React.useState("Immediately");
  const [unPublish, setUnPublish] = React.useState("Never");
  const [publishDate, setPublishDate] = React.useState("Immediately");
  const [publishTime, setPublishTime] = React.useState("");
  const [unPublishDate, setUnPublishDate] = React.useState("Never");
  const [unPublishTime, setUnPublishTime] = React.useState("");
  const [unPublishTimestamp, setUnPublishTimestamp] = React.useState(0);
  const [validPublish, setValidPublish] = React.useState(true);
  const [validUnPublish, setValidUnPublish] = React.useState(true);
  const [homeScreens, sethomeScreens] = useState([])
  const [selectedHomeScreen, setselectedHomeScreen] = useState({
    id: "", name: ""
  })
  const [schedules, setschedules] = useState([])
  const [overlapAlert, setoverlapAlert] = useState(false)
  const [homeScreenError, sethomeScreenError] = useState("")
  const [weekdayError, setweekdayError] = useState("")

  useEffect(() => {
    const getData = async () => {
      let res = await getHomeScreens(orgId, token);
      if(res?.data?.body?.data) {
        sethomeScreens(res?.data?.body?.data)
      }
    };
    getData();

    if(props.location?.state?.schedules) {
      props.location.state.schedules.length && setschedules(props.location.state.schedules)
    }
  }, [])

  const getTemplateImage = (name) => {
    name = name?.toLowerCase() ?? ""
    switch (name) {
      case 'template one':
        return Layout1;
      case 'template two':
        return Layout2;
      case 'template three':
        return Layout3;
      case 'template four':
        return Layout4;
      case 'template five':
        return Layout5;
    
      default:
        return Layout1;
    }
  }



  const [weekDays, setWeekDays] = useState({
    everyDay: false,
    days: []
  })
  const [selectedDays, setselectedDays] = React.useState({ 
    Sun: true, Mon: true, Tue: true, Wed: true, Thu: true, Fri: true, Sat: true,
  });

  const [everyDay, seteveryDay] = useState(false)

  const getDays = (days) => {
      const allSelect = Object.values(days).every(item => item)
      seteveryDay(allSelect)
      setselectedDays(days)
      console.log(days, allSelect);
      setweekdayError("")
  };

  const onDaysDrawerSave = () => {
    const days = Object.keys(selectedDays).filter(k => selectedDays[k])
    if(days?.length) {
      const weekDaysObj = {
        everyDay: everyDay,
        days: days
      }
      setContent(weekDaysObj.everyDay ? "Everyday" : weekDaysObj?.days?.length ? weekDaysObj.days.map((day) => day).join(", ") : "")
      setWeekDays(weekDaysObj)
      setOpen(false)
      console.log('onDaysDrawerSave', weekDaysObj)
    }
  }

  const onHomeScreenDrawerSave = () => {
    sethomeScreen(selectedHomeScreen?.name)
    setOpen2(false)
    console.log("onHomeScreenDrawerSave")
  }

  useEffect(() => {
    if (unPublishDate < Moment(new Date()).format("MMM D, YYYY")) {
      setVisibility('Hidden')
    }
  }, [unPublishDate])
  // console.log(unPublishDate, publishDate, 'll')
  const getPublishDate = (value, boolean) => {
    if(value === "Immediately") {
      setPublishDate('Immediately')
    } else {
      setValidPublish(false)
      setPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
    }
  };
  const getPublishTime = (value, boolean) => {
    console.log(value, 'pp')
    setPublishTime(Moment(value).format("h:mm A"));
  };

  const getUnPublishDate = (value, boolean) => {
    if(value == "Never") {
      setUnPublishDate("Never")
    }else {
      setUnPublishTimestamp(value)
    setValidUnPublish(false)

    setUnPublishDate(
      `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
    );
    }
    
  };
  const getUnPublishTime = (value, boolean) => {
    console.log(value)
    setUnPublishTime(Moment(value).format("h:mm A"));
  };

  const onVisibilitySave = () => {
     console.log("visibility saved");
   };

   const onVisibilityCancel = () => {
     console.log("visibility cancelled");
   };

   const onPublishSave = () => {
     console.log("publish date saved");
    };

    const onPublishCancel = () => {
     console.log("publish date cancel");
   };

   const onUnPublishSave = () => {
     
     console.log("unpublish date save");

   };

   const onUnPublishCancel = () => {
     console.log("unpublish date cancel");
   };

   const convertUTC = (date) => date.toISOString();

   const onSave = async () => {
     
    const publishFrom2 = publishDate === 'Immediately' ? convertUTC(new Date()) : convertUTC(new Date(`${publishDate + ' ' + publishTime}`));
    const publishTo2 = unPublishDate === 'Never' ? convertUTC(new Date(2099, 1, 1)) : convertUTC(new Date(`${unPublishDate + ' ' + unPublishTime}`))


    let scheduleScreen = schedules.find((el) => {
    //  console.log("publishFrom", publishFrom,publishTo, publishFrom <= convertUTC(new Date(el.publishTo)), convertUTC(new Date(el.publishFrom)) <= publishTo)

     if((publishFrom2 <= convertUTC(new Date(el.publishTo))) && (convertUTC(new Date(el.publishFrom)) <= publishTo2)) {
       console.log("overlap error", el)
       return el
     }
     })

     if(scheduleScreen) {
        setoverlapAlert("Schedule date overlap with another schedule")
        setTimeout(() => {
          setoverlapAlert("")
        }, 8000);
     }else {
        setoverlapAlert("")
     }
     if (!weekDays?.days?.length) {
        setweekdayError("Select weekdays")
     } else {
        setweekdayError("")
     }
     if (!selectedHomeScreen?.id) {
        sethomeScreenError("Select homescreen")
      }else {
        sethomeScreenError("")
      }


      const publishFrom = publishDate === 'Immediately' ? 'Immediately' : convertUTC(new Date(`${publishDate + ' ' + publishTime}`));
      const publishTo = unPublishDate === 'Never' ? 'Never' : convertUTC(new Date(`${unPublishDate + ' ' + unPublishTime}`))

      const obj = {
        deviceId: localStorage.getItem("deviceId"),
        schedule: {
          weekDays,
          visibility,
          publishFrom, 
          publishTo,
          homeScreen: selectedHomeScreen?.id
        }
      }

      try {

        if(weekDays?.days?.length && selectedHomeScreen?.id && !scheduleScreen) {
          console.log('save', obj);

          const res = await addScheduleToDevice(obj, token)
          if(res) {
            props.history.goBack()
          }
          console.log(res)
        }
      } catch (error) {
        console.log(error)
      }

   }

   const onBack = () => {
    props.history.push("/deenkiosk/devices/manage-devices")
  }
  // ================ Table End =====================//

  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [content, setContent] = React.useState('');
  const [homeScreen, sethomeScreen] = React.useState('');

  
  // ============== End ================//

  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle

      header={
        <div>
          <div className="backBtn">
            <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
            <Link
              to={{ pathname: "/deenkiosk/devices/manage-devices", state: 1, }}
              style={{ alignSelf: "center", color: "black", textDecoration: "none", }} > Back </Link>{" "}
          </div>
          {overlapAlert && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={overlapAlert}
                  alertType={"error"}
                  onClick={() => setoverlapAlert(false)}
                />
              </div>
            )}
        </div>
      }

      isForm={true}
      formData={
        <div>

          <div>
            <h1 className='title_top'>Add schedule to{" "}
              <span className='roboto-medium' style={{ fontWeight: 500, color: "#5A5A5A" }}>
                {localStorage.getItem("deviceName") ? localStorage.getItem("deviceName") : ""}
              </span></h1>
            <div className="dasheddBorder"></div>
          </div>

          <Grid container spacing={3}>

            <Grid item xs={12}>
              <BaseTextField
                value={homeScreen}
                // onChange={handleChange("name")}
                InputProps={true}
                style={{ width: '100%' }}
                onClick={() => setOpen2(true)}
                disabled={false}
                onArrowClick={() => setOpen2(true)}
                label="Select Homescreen"
                error={homeScreenError ? true : false}
                helperText={homeScreenError}
              />
            </Grid>

            <Grid item xs={12}>
              <BaseTextField
                value={content}
                // onChange={handleChange("name")}
                InputProps={true}
                style={{ width: '100%' }}
                onClick={() => setOpen(true)}
                disabled={false}
                onArrowClick={() => setOpen(true)}
                label="Select Weekdays"
                error={weekdayError ? true : false}
                helperText={weekdayError}
              />
            </Grid>

            <Grid item xs={12}>
              <FormPublishUnpublish
                visibility={visibility}
                setVisibility={setVisibility}
                publish={publish}
                unPublish={unPublish}
                publishDate={publishDate}
                publishTime={publishTime}
                setPublishDate={setPublishDate}
                setPublishTime={setPublishTime}
                getPublishDate={getPublishDate}
                getPublishTime={getPublishTime}
                unPublishDate={unPublishDate}
                unPublishTime={unPublishTime}
                setUnPublishDate={setUnPublishDate}
                setUnPublishTime={setUnPublishTime}
                getUnPublishDate={getUnPublishDate}
                getUnPublishTime={getUnPublishTime}
                onVisibilitySave={onVisibilitySave}
                onVisibilityCancel={onVisibilityCancel}
                onPublishSave={onPublishSave}
                onPublishCancel={onPublishCancel}
                onUnPublishSave={onUnPublishSave}
                onUnPublishCancel={onUnPublishCancel}
                onSave={onSave}
                onBack={onBack}
                unPublishTimestamp={unPublishTimestamp}
                //showFeatured={true}
                //featuredText="Feature this Service on homepage"
                //checked={checked}
                //onSetChecked={() => setChecked(!checked)}
                maxDatepublish={unPublishDate}
                minDateUnpublish={publishDate}
                disabledSave={validUnPublish}
                disabledSaveP={validPublish}
              />
            </Grid>

          </Grid>


          <DrawerMain
            open={open}
            openHandler={() => setOpen(!open)}
            title="Schedule"
            onCancel={() => setOpen(false)}
            onSave={() => onDaysDrawerSave()}
            loading={false}
          >

            <div className="noBorder">
            <DrawerRepeatDays 
              title={"Select Weekdays"} 
              showSwitch={true} 
              switchLabel={"EveryData"} 
              getDays={getDays} 
              days={selectedDays} 
            />
            </div>
          </DrawerMain>

          <DrawerMain
            open={open2}
            openHandler={() => setOpen2(!open2)}
            title="Select Homescreen"
            onCancel={() => setOpen2(false)}
            onSave={() => onHomeScreenDrawerSave()}
            loading={false}
            saveDisabled={selectedHomeScreen?.id ? false : true}
          >
              <DrawerListRadioButton 
                objects={homeScreens?.length ? homeScreens?.filter((el) => el.status === "active").map((el) => {
                    return {
                      id: el._id,
                      primary: el.name,
                      image:  getTemplateImage(el.template?.name)
                    }
                }) : []} 
                getData={(value, secondary) => {
                  console.log(value)
                  let fData = homeScreens?.length ? homeScreens.find((el) => el.name === value) : null;
                  fData && setselectedHomeScreen({
                    id: fData._id, name: value
                  })
                  sethomeScreenError("")
                }} 
                selectedData={{
                  id: selectedHomeScreen?.id, primary: selectedHomeScreen?.name
                }} 
                listWithImage={true}
              />
          </DrawerMain>

        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}

export default AddSchedule;