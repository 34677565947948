import { AlertAction, CardDetailsTable, EventLogs, MasterLayoutMiddle } from "@dclab/dc-react-ui-kit";
import React from "react";
import Check from "../../assets/img/check_circle-24px.svg";
import { GetContacts } from "./api";
import Moment from "moment";
import { CircularProgress } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import messages_content from '../../utils/messages.json';
import { getaddressFromPostalCode, testAddressFromPostalCode } from "../DeenFund/apis/index.js";
import Grid from '@material-ui/core/Grid';

const {
    token_expired
} = messages_content;

function ContactUs(props) {
    const [contacts, setContacts] = React.useState([])
    const [loader, setLoader] = React.useState(true)
    const [error, seterror] = React.useState(false);

    React.useEffect(() => {
        getContacts()
    }, [])

    async function getContacts() {
        const token = localStorage.getItem("authToken");
        const orgId = localStorage.getItem('orgID')
        const contactId = props.location.state
        try {
            const response = await GetContacts(token, orgId);
            const contactArray = response?.data?.body?.data ?? [];
            const filterData = contactArray.find(text => text._id === contactId);

            if (filterData?.postalCode) {
                console.log("postal_code", filterData?.postalCode);
                const post_code = await testAddressFromPostalCode(filterData.postalCode, "");
                console.log("address", post_code?.data?.body?.data?.address);
            }
            setContacts(filterData)
            setLoader(false)
        } catch (error) {
            setLoader(false);
            seterror(error.response && error.response.data.message)
            setTimeout(() => {
                seterror(false)
            }, 8000);
        }
    }
    const data = [
        {
            id: 1, icon: Check, status: 'In Progress', date: 'June 03, 2020, 12:00 PM',
            details: [
                { title: 'Reviewd by', message: 'Hassan khan' },
                {
                    title: 'Note', message: ' We just want to send you a quick thank you note for your awesome contribution',
                }]
        },
        {
            id: 2, icon: Check, status: 'In Progress', date: 'June 03, 2020, 12:00 PM',
            details: [
                { title: 'Reviewd by', message: 'Hassan khan' },
                {
                    title: 'Message', message: ' We just want to send you a quick thank you note for your awesome contribution'
                }]
        },
    ];

    const CardDetails = [
        {
            field: "Date",
            value: contacts && Moment(contacts.updatedAt).format('lll')
        },
        {
            field: "Name",
            value: contacts && contacts?.name?.firstName + (contacts?.name?.lastName ? ' ' + contacts?.name?.lastName : '')
        },
        {
            field: "Email",
            value: contacts?.email ? contacts.email : ""
        },
        {
            field: "Phone number",
            value: contacts?.phone ? contacts.phone : ""
        },
        {
            field: "App stream",
            value: contacts?.stream ? contacts.stream : ""  
        },

    ];
    const CardDetails2 = [
        {
            field: "Subject",
            value: contacts?.subject ? "Inquiry/Contact Us" : ""
        },
        {
            field: "Inquiry type",
            value: contacts?.inquiryType ?? ""
        },
        {
            field: "Status",
            value: contacts?.status ?? "New"
        },
        {
            field: "Message",
            value: contacts?.message ?? ""
        },
        {
            field: "Location",
            value: ""
        },
    ];
    const headerCard = {
        tableName: `Details`,
    };
    const footerLinkName = "ACTION";
    const onClick = () => {
        console.log('edit')
    }
    return (
        <MasterLayoutMiddle
            header={
                <>
                    <div className='contact-us-backbutton'>
                        <div
                            onClick={() => props.history.push("/messages")}
                            className="backBtn"
                            style={{ marginTop: 0 }}
                        >
                            <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                            <p style={{ alignSelf: "center" }}>Back</p>{" "}
                        </div>
                    </div>
                    {
                        error && error === "Invalid token" && (
                            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                                <AlertAction
                                    alert={token_expired}
                                    alertType={"error"}
                                    onClick={() => seterror(false)}
                                />
                            </div>
                        )
                    }
                </>
            }
            content={
                loader ?
                    <div
                        style={{
                            marginTop: 30,
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress color="primary" />
                    </div>
                    :
                    <>

                    

                    <Grid container spacing={3}>	      

                    <Grid item xs={12}> 
                    <div style={{ marginTop: '65px' }}>
                        <CardDetailsTable
                            data={CardDetails}
                            data2={CardDetails2}
                            header={headerCard}
                            twoColumns
                            footerLinkName={footerLinkName}
                            onClick={() => onClick()}
                        />
                        </div>
                    </Grid>

                    <Grid item xs={12}> 
                    <div className='events-logs-contact-us'>
                            <EventLogs
                                data={data}
                                headTitle="TIMELINE"
                                schedule={true}
                                editSchedule={(e) => console.log('editSchedule', e)}
                                deleteSchedule={(e) => console.log('deleteSchedule', e)}
                            />
                        </div>
                    </Grid>

                    </Grid>
                        
                        
                    </>
            }
        />
    )
}
export default ContactUs;