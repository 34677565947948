import * as constants from "../constants";



const INITIAL_STATE = {
 donors:[]

};

export default function donorsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case constants.SET_ALL_DONORS:
      return {
        ...state,
        donors:action.payload
      };
    case constants.RESET_USER_INFO:
      return [];
    default:
      return state;
  }
}
