import React, { useState, useEffect, Fragment } from 'react';
import { FooterInner, MasterLayoutMiddle, TableWithoutColName,AlertAction } from '@dclab/dc-react-ui-kit';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorOutline from '../assets/img/error_outline.svg';
import GetApp from '../assets/img/get_app-24px.svg';
import StripeLogo from '../assets/img/Stripe logo - white.svg';
import Upgrade from '../assets/img/upgrade-24px.svg';
import Warning from '../assets/img/warning-24px.svg';
import Launch from '../assets/img/launch-24px.svg';
import Cross from '../assets/img/clear.svg';
import '../App.css';
import {editOrganization, getAllCustomerInvoices, getOrganizationData} from "../pages/Organization/api/index.js"
import {decrypt} from '../utils/crypto';
import {withRouter} from 'react-router-dom';
import { getCurrentUserDetail, getOrganizationPlan, refreshCognitoToken, getPlanSubscriptionsByOrgId } from "../pages/UserProfile/Apis/index"

import messages_content from '../utils/messages.json';
import DashboardStats from './dashboardStats';
const {
  success_message_update_org, success_message_taxEIN
} = messages_content;

const env = {
  prod: process.env.REACT_APP_STRIPE_PROD_KEY,
  dev: process.env.REACT_APP_STRIPE_DEV_KEY
};
let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")
) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}
const envStripeKey = env[currentEnv];

function Dashboard(props) {
  const {taxStatusRequired, detailsRequired, stripeRequired, taxIdEinRequired} = props;

  const [userPlanDetails, setuserPlanDetails] = useState({
    amount: 0, planSubscriptionId: "", orgPlan: "", stripeData: []
  })
  // console.log("taxStatus", taxStatusRequired, detailsRequired, stripeRequired)
  let rowsData = [
    [
      {
        name: 'textImg',
        type: 'string',
        primaryText: 'Organization details',
        src: ErrorOutline,
        required: props && props.detailsRequired,
        id: 1,
      },
      { type: 'action', status: 'success', value: 'Update Info' },
      { type: 'action-cross', cancel: false },
    ],
    [
      {
        name: 'textImg',
        type: 'string',
        primaryText: 'Complete Checkout/Payment',
        src: ErrorOutline,
        required: props && props.detailsRequired,
        id: 2,
      },
      { type: 'action', status: 'success', value: 'CONTINUE TO CHECKOUT' },
      { type: 'action-cross', cancel: false },
    ],
    [
      {
        name: 'textImg',
        type: 'string',
        primaryText: 'Connect to Stripe',
        src: StripeLogo,
        required: userPlanDetails?.amount && userPlanDetails?.planSubscriptionData?.deenfund?.plan && props.stripeRequired ? true : false,
        id: 3,
      },
      {
        type: 'action',
        status: 'discontinued',
        value: props && props.stripeRequired ? 'Connect' : 'Connected',
        icon: Launch,
        cancel: true,
      },
      { type: 'action-cross', cancel: false },
    ],
    // [
    //   {
    //     name: 'textImg',
    //     type: 'string',
    //     primaryText: 'Enter EIN/Tax ID for Tax Exemption verification',
    //     src: ErrorOutline,
    //     required: true,
    //     id: 4,
    //   },
    //   { type: 'action', status: 'success', value: 'ADD EIN/TAX ID' },
    //   { type: 'action-cross', cancel: false },
    // ],

    [
      {
        name: 'textImg',
        type: 'string',
        primaryText: 'Verify Tax Exemption status',
        src: ErrorOutline,
        required: true,
        id: 4,
      },
      { type: 'action', status: 'success', value: 'VERIFY' },
      { type: 'action-cross', cancel: false },
    ],

    [
      {
        name: 'textImg',
        type: 'string',
        primaryText: 'Add Tax ID/EIN',
        src: ErrorOutline,
        required: true,
        id: 5,
      },
      { type: 'action', status: 'success', value: 'ADD NOW' },
      { type: 'action-cross', cancel: false },
    ],

    [
      {
        name: 'textImg',
        type: 'string',
        primaryText: 'Add apps & integrations to your console',
        src: GetApp,
        id: 6,
      },
      { type: 'action', status: 'discontinued', value: 'Explore Apps' },
      { type: 'action-cross', cancel: true },
    ],
    // [
    //   {
    //     name: 'textImg',
    //     type: 'string',
    //     primaryText: 'Current Plan : Free',
    //     secondaryText:
    //       "Use your DC console at it's fullest by upgrading your plan",
    //     src: Upgrade,
    //     id: 6,
    //   },
    //   { type: 'action', status: 'discontinued', value: 'Upgrade' },
    //   { type: 'action-cross', cancel: true },
    // ],
    // [
    //   {
    //     name: 'textImg',
    //     type: 'string',
    //     primaryText: 'Customize your admin console',
    //     src: Warning,
    //     id: 7,
    //   },
    //   { type: 'action', status: 'discontinued', value: 'checkout' },
    //   { type: 'action-cross', cancel: true },
    // ],
  ];
  
  const [rows, setRows] = useState(rowsData);
  
  const [payments, setpayments] = useState([])
  const [showAlert, setshowAlert] = useState(false);
  const [dashboardSteps, setDashboardSteps] = useState({});


  useEffect(() => {
    const getData = async () => {
      if(userPlanDetails.stripeData?.length) {
        const customerIds = userPlanDetails.stripeData?.map((el) => el.customerId);
        if(customerIds?.length) {
          const invoices = await getAllCustomerInvoices([...new Set(customerIds)]);
          console.log("invoices?.data?.body?.data: ", invoices?.data?.body?.data)
          invoices?.data?.body?.data?.length && localStorage.setItem("payments", JSON.stringify(invoices?.data?.body?.data ?? []))
          invoices?.data?.body?.data?.length && setpayments(invoices?.data?.body?.data ?? [])
        }
      }
    }
    getData();
  }, [userPlanDetails.stripeData])

  useEffect(() => {
    let newRows = rowsData.filter((value, index) => {
      switch(value[0].id) {
        case 1:
          return detailsRequired;
        
        case 2:
          return userPlanDetails?.amount && !userPlanDetails?.orgPlan;

        case 3:
          return stripeRequired && userPlanDetails?.planSubscriptionData?.deenfund?.plan;

        case 4:
          return taxStatusRequired && taxIdEinRequired ;

        case 5:
          return !taxStatusRequired && taxIdEinRequired;

        default:
          return true;

      }
    });
    // if (!detailsRequired && !stripeRequired && !taxStatusRequired) {
    //     newRows = rowsData.filter((el) => el[0].id !== 1 && el[0].id !== 3 && el[0].id !== 4)
    // } else if (!detailsRequired && !stripeRequired && taxStatusRequired) {
    //     newRows = rowsData.filter((el) => el[0].id !== 1 && el[0].id !== 3)
    // } else if (!detailsRequired && stripeRequired && !taxStatusRequired) {
    //     newRows = rowsData.filter((el) => el[0].id !== 1 && el[0].id !== 4)
    // } else if (detailsRequired && !stripeRequired && !taxStatusRequired) {
    //     newRows = rowsData.filter((el) => el[0].id !== 4 && el[0].id !== 3)
    // } else if (detailsRequired && stripeRequired && !taxStatusRequired) {
    //     newRows = rowsData.filter((el) => el[0].id !== 4)
    // } else if (detailsRequired && !stripeRequired && taxStatusRequired) {
    //    newRows = rowsData.filter((el) => el[0].id !== 3)
    // } else if (!detailsRequired && stripeRequired && taxStatusRequired) {
    //    newRows = rowsData.filter((el) => el[0].id !== 1)
    // } else {
    //    newRows = rowsData;
    // }

    // userPlanDetails?.amount && !userPlanDetails?.orgPlan && newRows.unshift([
    //   {
    //     name: 'textImg',
    //     type: 'string',
    //     primaryText: 'Complete Checkout/Payment',
    //     src: ErrorOutline,
    //     required: true,
    //     id: 2,
    //   },
    //   { type: 'action', status: 'discontinued', value: 'continue to checkout' },
    //   { type: 'action-cross', cancel: false },
    // ]);
    newRows = newRows.filter(el => !dashboardSteps[`id${el[0].id}`]);
    setRows(newRows)

  }, [props, userPlanDetails]);

  // console.log("userPlanDetails", userPlanDetails)

  const style = {
    width: '100%',
  };
  const actionHandler = (data) => {
  
    if (data[0].primaryText === 'Organization details') {
      localStorage.setItem('pageTitle', 'Organization');
      props.history.push('/organization/overview/edit-details');
    } else if (data[0].primaryText === 'Connect to Stripe') {
      let returnUrl;
      if(window.location.href.indexOf("shapla") !== -1) {
        returnUrl = 'https://shapla.admin.deenconnect.io/connect-stripe?dashboard=true'
      } else {
        returnUrl = 'https://admin.deenconnect.io/connect-stripe?dashboard=true'
      }
      window.location.href =
        `https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${envStripeKey}&scope=read_write&redirect_uri=${returnUrl}`;
    } else if (data[0].primaryText === 'Verify Tax Exemption status') {
      props.history.push('organization/overview/taxein');
    } else if (data[0].primaryText === 'Add Tax ID/EIN') {
      props.history.push('organization/overview/taxein');
    } else if (data[0].primaryText === 'Complete Checkout/Payment') {
      
      let tempData = {... userPlanDetails};
      !tempData?.planSubscriptionData?.deenfund.plan && delete tempData.planSubscriptionData.deenfund
      !tempData?.planSubscriptionData?.deenkiosk.plan && delete tempData.planSubscriptionData.deenkiosk
      !tempData?.planSubscriptionData?.deenweb.plan && delete tempData.planSubscriptionData.deenweb
      !tempData?.planSubscriptionData?.support.plan && delete tempData.planSubscriptionData.support
      
      props.history.push({pathname: `/checkout`, state: { userPlanDetails: tempData }});
    } else if (data[0].primaryText === 'Add apps & integrations to your console') {
      props.history.push({pathname: `/more-apps` });
    } else if (data[0].primaryText === 'Current Plan : Free') {
      props.history.push({pathname: `/more-apps`, state: { tab: 1 } });
    }
  };
  const dataDeleteHandler = async (data) => {
    if(data.length && data[0].id) {
      try {
        const token = localStorage.getItem("authToken");
        const orgId = localStorage.getItem("orgID");
      
        const dashboardStepsLocal = dashboardSteps;
        dashboardStepsLocal[`id${data[0].id}`] = true;
        await editOrganization({dashboardSteps: dashboardStepsLocal}, orgId, token )

        setRows(rows.filter((row) => row[0].id !== data[0].id));
      } catch {
        console.log("Error occurred")
      }
      
    }
  };
  const userName = localStorage.getItem('name');
  const firstName = userName?.split(' ')[0];

  const validateUser = () => {
    let verify = false;
    let accountRevoked = false
    const token = localStorage.getItem("authToken");
    let access = localStorage.getItem("userAccess") ;
    if(access) {
      access = decrypt(access);
    }
    if(token) {
      verify = true;
    } 
    if(access && access?.toLowerCase() !== "active") {
      accountRevoked = true;
    }
    if(!verify || accountRevoked) {
      props.history.push(accountRevoked ? "/access-revoked" : "/signin");
    }
  }

  useEffect(() => {
    validateUser();
    if(props?.location?.state?.showAlert) {
      setshowAlert(success_message_update_org)
      setTimeout(() => {
        setshowAlert(false)
      }, 8000);
    } else if (props?.location?.state?.showTaxAlert) {
      setshowAlert(success_message_taxEIN)
      setTimeout(() => {
        setshowAlert(false)
      }, 8000);
    } else {
      setshowAlert(false)
    }
    const getDetails = async () => {
        try {
          const token = localStorage.getItem("authToken");
          const orgId = localStorage.getItem("orgID");
          const res = await getPlanSubscriptionsByOrgId(orgId, token);
          const orgRes = await getOrganizationPlan(orgId, token);
          const organizationData = await getOrganizationData(orgId);
          if(organizationData?.data?.body?.data?.dashboardSteps) {
            setDashboardSteps((organizationData.data.body.data.dashboardSteps));
          }
          // PlansubscriptionOrgId
          if(res?._id) {
            setuserPlanDetails({
                planSubscriptionId: res?._id,
                planSubscriptionData: res,
                amount: res?.planTotal ?? 0, 
                orgPlan: orgRes?.orgId ?? "",
                stripeData: orgRes?.stripeData ?? []
            });
          } 
        }
        catch (error) {
          console.log(error)
        }
      }
      getDetails()
  }, [])


  return props.loading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 250,
      }}
    >
      <CircularProgress color='primary' />
    </div>
  ) : (
    <Fragment>
      {showAlert && (
        <div
          style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
        >
          <AlertAction
            alert={showAlert}
            alertType={'success'}
            onClick={() => setshowAlert(false)}
          />
        </div>
      )}
   
        <MasterLayoutMiddle
          content={
            <>
            {(rows.length > 0) ?
            <TableWithoutColName
              title={`Welcome, ${firstName} - follow these steps to get started`}
              subTitle=''
              rows={rows}
              colSpan={6}
              actionSendButton={true}
              iconTextSize={14}
              actionLink={actionHandler}
              crossIcon={Cross}
              delete={dataDeleteHandler}
            /> : null}
            <DashboardStats payments={payments} planSubscriptionId={userPlanDetails.planSubscriptionId} />
            </>
          }
          isFooter={true}
          footer={
            <FooterInner 
              style={style}
              termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
              privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
            />
          }
        />
      
    </Fragment>
  )
}

export default withRouter(Dashboard);