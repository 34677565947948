import axios from "axios";

const env = {
  prod: process.env.REACT_APP_PAYMENT_PROD_API,
  dev: process.env.REACT_APP_PAYMENT_DEV_API
};
const orgEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
};
let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")

) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}
const orgBaseUrl = orgEnv[currentEnv];

export function getOrgAnnoucements(token, id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/announcements?orgId=${id}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        resolve({});
      });
  });
}

export function getOrgReminders(token, id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/reminders?orgId=${id}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        resolve({});
      });
  });
}

export function getOrgSalahs(token, id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/salah?orgId=${id}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        resolve({});
      });
  });
}

export function getOrgMedias(token, id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/medias/all-type?orgId=${id}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        resolve({});
      });
  });
}

export function getPlanSubscriptionsStats(token, id, orgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/planSubscription/getPlanSubscriptionsStats/${id}?orgId=${orgId}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
        // console.log("planSubscription", res)
      })
      .catch((error) => {
        resolve({});
      });
  });
}



export function getOrgOrdersCount(token, id, val) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/orgOrder/getOrgOrdersCount/${id}?days=${val}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        resolve({});
      });
  });
}

export function getOrgEventsCount(token, id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/events/getOrgEventsCount?orgId=${id}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        resolve({});
      });
  });
}