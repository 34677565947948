import React from 'react'
import {ComponentOtpLockedCard} from '@dclab/dc-react-ui-kit'
import Logo from "../../assets/img/DC_Logo.png";
import './style.css'
function SignUpLock(props) {
    return (
        <div className="container-success" style={{position: "relative"}}>
            <div style={{position: "absolute", width: '100%', zIndex: -1}} >
                <div className='Background-Image' style={{ width: '100%', position: 'fixed', height: '100vh' }}>

                </div>
            </div>
            <div style={{paddingTop: 100}}>
                <ComponentOtpLockedCard 
                src={Logo}
                LogoLink="https://shapla.deenconnect.io/" 
                tooAttempts={true}
                />
            </div>
        </div>
    )
}
export default SignUpLock
