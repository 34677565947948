import React, { useState, useEffect } from 'react';
import Edit from '../../../assets/img/create-24px.svg';
import Delete from '../../../assets/img/delete_forever-24px.svg';

import {
  AlertAction,
  GridContainer,
  GridItem,
  MasterLayoutMiddle,
  FooterInner, CardDetailsTable,
  SimpleTable,
} from "@dclab/dc-react-ui-kit";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import "../style.css";
import "../../../App.css";
import "../../style.css";
import moment from 'moment';
import { getCurrentUserDetail } from "../../UserProfile/Apis";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';


export default function CommitteesDetails(props) {
  console.log(props?.location?.state?.order)
  const [order, setorder] = useState({})
  const [showSuccessAlert, setshowSuccessAlert] = useState(true)
  const [loading, setloading] = useState(false)

  useEffect(() => {
    if (props?.location?.state?.order) {
      props?.location?.state?.order && setorder(props.location.state.order)
    }
  }, [props?.location?.state?.order])


  let header = { avatar: "", tableName: `Social Services`, icon: "", status: [] };

  let data = [
    {
      field: "Name",
      value: "Social Services"
    },

    {
      field: "Secretary",
      value: "Hassan Ahmed"
    },




  ];

  let data2 = [
    {
      field: "# of members",
      value: "12"
    },

  ];

  let footerLinkName = "Edit";
  let footerDeleteButton = "Delete";

  const onClick = () => {
    console.log('EDIT')
    props.history.push({ pathname: `/organization/edit-committee`, state: { order: order } });
  }

  const onCancelClick = () => {
    console.log('DELETE');
  };

  const style = {
    width: '100%',
  };

  // TABLE
  let col = [
    { type: "string", name: "name", label: "NAME", width: '23%' },
    { type: "string", name: "name", label: "PHONE", width: '23%' },
    { type: "string", name: "name", label: "MAIL", width: '23%' },
    { type: "string", name: "name", label: "DESIGNATION", width: '27%' },
    { type: "string", name: "name", label: "ACTION", width: '15%' },

  ];

  const selected_categories = data.sort((a, b) => a.order - b.order)
  let rows = selected_categories.map((text, index) => {
    let i = index + 1
    return (
      [
        { type: 'link', name: 'invoice_number', value: 'Ahmad Noor', url: '/organization/committees/member-details',},
        { type: 'string', name: 'amount', value: '+1 (123) 123-1234' },
        { type: 'string', name: 'category', value: 'hassanahmed@gmail.com' },
        { type: 'string', name: 'frequency', value: 'Secretary' },
      ]
      
    )
  })

  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const actionLinkHandler = (data) => {
    // console.log("id is: ", data[0]?.plan, details);
    // props.history.push({pathname: `/organization/plan-details`, state: { plan: data[0].plan, details: details }});
  }


  return (
    <MasterLayoutMiddle
      header={
        <>
          <div
            onClick={() =>
              props.history.goBack()
            }
            className='backBtn'>
            <ChevronLeft style={{ alignSelf: 'center', marginLeft: 0 }} />
            <p style={{ alignSelf: 'center' }}>Back</p>{' '}
          </div>

          {(showSuccessAlert && (
            <div
              style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
            >
              <AlertAction
                alert={"Committee updated successfully!"}
                alertType={"success"}
                onClick={() => setshowSuccessAlert(false)}
              />
            </div>
          ))}
        </>
      }
      content={
        <>
          <div style={{ marginTop: '60px' }}>
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <CardDetailsTable
                  data={data}
                  data2={data2}
                  twoColumns
                  header={header}
                  footerLinkName={footerLinkName}
                  footerDeleteButton={footerDeleteButton}
                  onClick={() => onClick()}
                  onDeleteClick={() => onCancelClick()}
                />
              </Grid>

              <Grid item xs={12}>
                <div className="speaker-simple-table">
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 30,
                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                    <div className="MemberTable">
                      <SimpleTable
                        showHeader={true}
                        showCol={true}
                        title="Members"
                        rows={rows}
                        columns={col}
                        colSpan={16}
                        actionSendButton={true}
                        classProps={changeClassProps()}
                        donationsCount={rows && rows.length > 0 ? 1 : 0}
                        noTitle="No members added"
                        //noPara="Added Items will be displayed and managed here"
                        noBtnText=""
                        actionLink={(data) => actionLinkHandler(data)}
                        showIconCol={true}
                        editIcon={Edit}
                        deleteIcon={Delete}
                        editAction={(e) =>
                          this.props.history.replace(
                            `/organization/services/edit-service/${e[0].label}`
                          )
                        }
                        deleteAction={(e) => this.deleteServiceAction(e)}
                        showFooter={true}
                        showAdd={true}
                        addText='Add'
                        iconTextSize={16}
                        rowsPerPage={5}
                        showFooter={rows?.length > 5 ? true : false}
                      />
                    </div>
                  )}
                </div>
              </Grid>

            </Grid>
          </div>

        </>
      }
      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  )
}
