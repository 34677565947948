import React from "react";
import { SimpleTable, MasterLayoutMiddle, FooterInner, Accordian } from "@dclab/dc-react-ui-kit";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Grid from '@material-ui/core/Grid';
import './style.css'

function TestimonialListing() {

  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { type: "number", name: "serial_number", label: "NO.", width: "auto" },
    { type: "string", name: "name", label: "Summary", width: "85%" },
    { type: "action", name: "action", label: "ACTION", width: "15%" },
  ];

  let rows = [
    [
      { type: "id", value: "01" },
      { type: "string", name: "frequency", value: "New 9,700 SF rooftop space with 5,700 rooftop field for community programs", },
      { type: "action", status: "success", value: "see details" },

    ],

    [
      { type: "id", value: "02" },
      { type: "string", name: "frequency", value: "New 9,700 SF rooftop space with 5,700 rooftop field for community programs", },
      { type: "action", status: "success", value: "see details" },

    ],

    [
      { type: "id", value: "03" },
      { type: "string", name: "frequency", value: "New 9,700 SF rooftop space with 5,700 rooftop field for community programs", },
      { type: "action", status: "success", value: "see details" },

    ],

  ];
  // ============================== Table End ======================================//



  


  const style = {
    width: '100%',
  };


  return (
    <div>

      <MasterLayoutMiddle

        header={
          <div>

            <div
              onClick={() =>
                this.props.history.push('')
              }
              className='backBtn'>
              <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
              <p style={{ alignSelf: 'center' }}>Back</p>{' '}
            </div>
          </div>
        }

        content={


          <div className="ListingTableContainer">
          <Grid container spacing={3}>

            <Grid item xs={12}>
              
                <SimpleTable
                  showHeader={true}
                  title="Project Highlights"
                  width="100%"
                  rows={rows}
                  columns={col}
                  colSpan={16}
                  actionSendButton={true}
                  classProps={changeClassProps()}
                  donationsCount={rows?.length ? 1 : 0}
                  noTitle="No Project Highlights added"
                  noPara="Added Project Highlights will be displayed and managed here"
                  noBtnText="Add New Project Highlight"
                  onInviteClick={() =>
                    this.props.history.push(
                      ""
                    )
                  }
                  actionLink={(name) => this.details(name)}
                  showIconCol={false}
                  actionSendButton={true}
                  deleteAction={(e) => this.deleteServiceAction(e)}
                  showFooter={true}
                  showAdd={true}
                  addText="ADD NEW"
                  onAddClick={() => this.props.history.push("")
                  }
                  iconTextSize={16}
                />
              
            </Grid>

            

          </Grid>
          </div>


        }



        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }



      />


    </div>
  )
}


export default TestimonialListing
