import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Verify, AlertAction } from '@dclab/dc-react-ui-kit';
import Logo from '../../assets/img/DC_Logo.png';
import { forgotPasswordHandler } from './apis';
import { forgotPasswordAction } from '../../redux/actions/forgotPasswordAction';
import './style.css';
import messages_content from '../../utils/messages.json';
const { 
  email_verfy_message
  } = messages_content;
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      code: '',
      emailError: false,
      error: false,
      showAlert: false,
      enterCode: false,
      isLoading: false,
      resendShowAlert: false,
      showValidEmailAlert: false,
    };
    this.setEmail = this.setEmail.bind(this);
    this.setCode = this.setCode.bind(this);
    this.click = this.click.bind(this);
    this.resend = this.resend.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }
  setEmail = (e) => {
    const emailRegex = /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i;
    if (emailRegex.test(e)) {
      this.setState({ email: e, emailError: false });
    } else {
      this.setState({ email: e, emailError: false });
    }
  };
  setCode = (e) => {
    this.setState({
      code: e,
    });
  };
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false, resendShowAlert: false });
    }, 8000);
  };
  componentDidMount(){
    if( this.props.location.state && this.props.location.state.errorServer){
      let Email = localStorage.getItem('email');
      this.setState({enterCode: true, error: "Code didn't match, please try again",email: Email });
    }
  }
  resend = async () => {
    const { email } = this.state;
    const obj = {
      userName: email,
    };
    try {
      const res = await forgotPasswordHandler(obj);
      this.setState({ resendShowAlert: true, error: false });
      this.hideAlert();
    } catch (error) {
      this.setState({
        error: error && error.response && error.response.data.message,
        isLoading: false,
      });
    }
  };
  click = async (name) => {
    const { email, code, showValidEmailAlert, emailError } = this.state;
    let Email = localStorage.getItem('email');
    this.setState({
      error: false,
      showAlert: false,
      isLoading: true,
    });
    if (name === 'verifyEmail') {
      if (emailError) {
        this.setState({emailError: true, isLoading: false});
      } else {
        try {
          const obj = {
            userName: email,
          };
          localStorage.setItem('email', email);
          const res = await forgotPasswordHandler(obj);
          console.log("Res", res)
          this.setState({
            showValidEmailAlert: true,
            enterCode: true,
            isLoading: false,
          });
        } catch (error) {
          this.setState({
            error: error.response.data.message,
            isLoading: false,
            showAlert: true,
          });
          this.hideAlert();
        }
        this.setState({ enterCode: true });
      }
    } else {
      const obj = {
        userName: email,
        code,
      };
      try {
        this.props.forgotPasswordHandler(email, code);
        this.props.history.replace('/reset-password');
      } catch (error) {
        this.setState({
          error: error.response.data.message,
          isLoading: false,
          showAlert: true,
        });
        this.hideAlert();
      }
    }
  };

  disableEmail = (email) => {
    const emailRegex = /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i;
    return !emailRegex.test(email);
  }
  render() {
    const {
      code,
      error,
      showAlert,
      email,
      enterCode,
      emailError,
      isLoading,
      resendShowAlert,
      showValidEmailAlert,
    } = this.state;
    const onBlurAction = () => {
      const emailRegex = /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i;
      if (emailRegex.test(email)) {
        this.setState({emailError: false, });
      } else {
        this.setState({emailError: true });
      }
    }
    return (
      <div
        style={{ paddingBottom: '40px', height: 'inherit' }}
        className='Background-Image'
      >
        {resendShowAlert && (
          <div
            style={{
              zIndex: 99,
              width: '100%',
              position: 'fixed',
              marginTop: 20,
              marginLeft: 200,
            }}
          >
            <AlertAction
              alert={email_verfy_message}
              alertType={'success'}
              onClick={() => this.setState({ resendShowAlert: false })}
            />
          </div>
        )}
        {showAlert && error && (
          <div
            style={{
              zIndex: 99,
              width: '100%',
              position: 'fixed',
              marginTop: 20,
              marginLeft: 200,
            }}
          >
            <AlertAction
              alert={error}
              alertType={'error'}
              onClick={() => this.setState({ showAlert: false })}
            />
          </div>
        )}
        <div
          style={{ width: '65%', margin: '0 auto', }}
          className='signin-container'
        >
          <Verify
          
            isLoading={isLoading}
            name={enterCode ? 'verifyCode' : 'verifyEmail'}
            title='Forgot Password?'
            metaTitle={
              enterCode
                ? <p className="forgotSubText"  style={{display: 'content',}}>We sent a code to <span style={{margin: 0, color: '#2A82F6',}}>{email}</span> if it’s recognized account. Please enter the code below.</p>
                : "Don't worry! Just enter the email address you've used to register with us"
            }
            btnText={enterCode ? 'VERIFY' : 'CONTINUE'}
            forVerify={enterCode}
            src={Logo}
            LogoLink="https://shapla.deenconnect.io/"
            onChangeText={(e) => this.setEmail(e.target.value)}
            onChangeCode={(e) => this.setCode(e)}
            onClickVerify={() => {
              enterCode ? this.click('verifyCode') : this.click('verifyEmail');
            }}
            onClickResend={() => this.resend()}
            hasErrored={error}
            errorStyle={{
              border: '1px solid #B00020',
            }}
            error={!enterCode && emailError}
            onBlur={() => onBlurAction()}
            label='Email Address'
            textfieldvalue={email}
            value={enterCode ? code : email}
            disabled={enterCode ? code.length !== 6 && true : this.disableEmail(email)} 
            placeholder={''}
            helperText={
              (!enterCode && !email && 'Email Address is required') ||
              (emailError && 'This Email Address is not associated with  any account')
            }
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPasswordHandler: (email, code) =>
      dispatch(forgotPasswordAction(email, code)),
  };
};
export default connect(null, mapDispatchToProps)(ForgotPassword);
