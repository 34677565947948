import React from "react";
import {ComponentOtpSuccessCard} from "@dclab/dc-react-ui-kit";


import './style.css'

function PaymentSuccessful(props) {

 
  const click = () => {
    console.log("");
  };

  return (
    <div className="paymentSuccessContainer">
      <ComponentOtpSuccessCard 
        AppLogo={false}
        heading="Payment Successful"
        description="But I must explain to you how all this mistaken idea of event posting"
        onClick={() => props.history.push("/more-apps")}
        btnText="BACK TO APPS"
      />
    </div>
  )
}


export default PaymentSuccessful
