import React from 'react';
import { CardDetailsTable, MasterLayoutMiddle, FooterInner, PageHeaderWithButton, SimpleAccordian, } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';
import GroomInfo from './GroomInfo';
import BrideInfo from './BrideInfo';
import WitnessOne from './WitnessOne';
import WitnessTwo from './WitnessTwo';
import WitnessThree from './WitnessThree';
import VenueDetails from './VenueDetails';






function NikahDetails(props) {


    const [selectedZone, setselectedZone] = React.useState(0);

    const saveChanges = (accordian) => {
        console.log("data", accordian)
        setselectedZone(0)
    }
    const onAccordianClick = (panel) => {
        console.log("onAccordianClick", panel)
        if (panel === selectedZone) {
            setselectedZone(0)
        } else {
            setselectedZone(panel)
        }
    }

    const AccordionDetails = {
        top: "0px"
        //top:"-46px"
    };



    let header = {
        tableName: `Nikah Details`,
    };

    let data = [
        {
            field: "Coordinator",
            value: "Hassan Ahmed"
        },

        {
            field: "Requested Date",
            value: "Apr 25, 2021"
        },

        {
            field: "Start Time",
            value: "10:00 AM EST"
        },

        {
            field: "End Time",
            value: "1:00 PM EST"
        },

        {
            field: "Contact Number",
            value: "+1 (123) 123-1234"
        },
        {
            field: "Email",
            value: "shayanmahmud@gmail.com"
        },
        {
            field: "Make Announcement",
            value: "Yes"
        },
    ];

    let data2 = [
        {
            field: "Note",
            value: "When Muslims talk about God, they automatically think of Allah. Allah is the Arabic word for “The One True God”. He is the Almighty, the Creator who created the universe, mankind and all other of life."
        },
        {
            field: "Qadhi Name",
            value: `<div>Abdullah al-Razi</div>`,
        },

        {
            field: "Qadhi Phone No.",
            value: "+1 (123) 123-1234",
        },

    ];

    let footerLinkName = "EDIT DETAILS";
    let footerDeleteButton = "DELETE";

    // HANDLE FOOTER LINK
    const onClick = () => {
        console.log('/edit-detail')
    }

    const onDeleteClick = () => {
        console.log('cancel-membership')
    }


    const handleChange = (event, value) => {
        console.log(event.target.value, value);
    };

    const style = {
        width: '100%',
    };



    const back = () => {
        console.log("back");
    }

    const Statustdata = [
        { value: 1, select: "Assigned" },
        { value: 2, select: "Unassigned" },
        { value: 3, select: "Completed" },
        { value: 4, select: "On hold" },
        { value: 5, select: "Canceled" },
    ];

    const Statustdata2 = [
        { value: 1, statuscolor: "#7266FB", select: "New" },
        { value: 2, statuscolor: "#FF9800", select: "In-progress" },
        { value: 3, statuscolor: "#3CC480", select: "Completed" },
        { value: 4, statuscolor: "#818E94", select: "On hold" },
        { value: 5, statuscolor: "#C44058", select: "Canceled" },
    ];



    const eData = [
        {   
            id:1,
            title: "GROOM'S INFO",
            subtext:"Hassan Ahmed",
            Edit:"EDIT",
            save:"save",
            body: <GroomInfo />,
        },

        {   
            id:2,
            title: "BRIDE'S INFO",
            subtext:"Aisha Mahmud",
            Edit:"EDIT",
            save:"save",
            body: <BrideInfo />,
        },

        {   
            id:3,
            title: "GUARDIAN/WITNESS 1",
            subtext:"Aabidah Saba",
            Edit:"EDIT",
            save:"save",
            body: <WitnessOne />,
        },

        {   
            id:4,
            title: "GUARDIAN/WITNESS 2",
            subtext:"Aabidah Saba",
            Edit:"EDIT",
            save:"save",
            body: <WitnessTwo />,
        },

        {   
            id:5,
            title: "GUARDIAN/WITNESS 3",
            subtext:"Witness must be sane, adult and Muslim",
            Edit:"EDIT",
            save:"save",
            body: <WitnessThree />,
            color:"#818E94",
            size:"12px",
        },

        {   
            id:6,
            title: "VENUE DETAILS",
            subtext:"Guests attending",
            Edit:"EDIT",
            save:"save",
            body: <VenueDetails />,
        },

      
    ];


    return (

        <>
            <MasterLayoutMiddle

                header={
                    <div>
                        <PageHeaderWithButton
                            title="Hassan Ahmed"
                            indicatorColor="primary"
                            style={{ color: "#fff", border: "1px solid " }}
                            handleChange={(x, y) => handleChange(x, y)}
                            headerColor={'#000'}
                            disabled={true}
                            back={back}
                            showBackBtn={true}
                            hiddenDraft={true}
                            hiddenPublish={true}
                        />
                    </div>
                }

                content={
                    <div>

                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <CardDetailsTable
                                    data={data}
                                    data2={data2}
                                    twoColumns
                                    header={header}
                                    footerLinkName={footerLinkName}
                                    footerDeleteButton={footerDeleteButton}
                                    onClick={() => onClick()}
                                    onDeleteClick={() => onDeleteClick()}

                                    Statustdata={Statustdata}
                                    handleChange="handleChange"
                                    value="2"
                                    Statustdata2={Statustdata2}
                                    StatusDropdown={true}
                                    AssigneeDropdown={true}

                                    HeaderStatus={false}

                                />
                            </Grid>
                            
                            <Grid item xs={12}>
                            {eData.map((item, i) => (
                                <SimpleAccordian
                                    Title={item.title}
                                    text= {item.subtext}
                                    TitleButtonLabel={item.Edit}
                                    AccordionDetails={AccordionDetails}
                                    panel={item.id}
                                    id={item.id}
                                    areacontrol={item.id}
                                    open={selectedZone === item.id ? true : false}
                                    onAccordianClick={onAccordianClick}
                                    accordianName="headerCard"
                                    saveChanges={item.save}
                                    Cencel="CENCEL"
                                    Save="SAVE NOW"
                                    color={item.color}
                                    fontSize={item.size}
                                    content={
                                        <div className='AccformBox'>
                                            {item.body}
                                        </div>
                                    }
                                />
                                ))}
                            </Grid>

                        </Grid>

                    </div>
                }

                isForm={true}
                formData={
                    <div>
                    </div>
                }

                isFooter={true}
                footer={
                    <FooterInner style={style} />
                }

                isWhiteBackground={true}

            />
        </>



    );
}


export default NikahDetails;