import React from "react";
import { connect } from "react-redux";
import {
  SimpleAppBar,
  BasePaper,
  NavDrawer,
  BaseIcon,
  MasterLayout,
} from "@dclab/dc-react-ui-kit";
import { Switch, Route } from "react-router-dom";
import ManageDevice from './manageDevice'
import Devices from './Devices'
import Onboarding from './onBoarding'
import Addplaylist from './Addplaylist'
import EditPlaylist from './editPlaylist'
import Playlists from './Playlists'
import { ReactComponent as MoreApps } from "../../assets/nav_icons/moreapps.svg";
import { ReactComponent as Support } from "../../assets/nav_icons/support.svg";
import { ReactComponent as Launch } from "../../assets/nav_icons/launch-24px.svg";
import MainLogo from "../../assets/img/DC_Logo.png";
import { ChevronRight } from "@material-ui/icons";

import AddSchedule from './AddSchedule'
import EditSchedule from './EditSchedule'

import HomeScreenListing from './HomeScreens/HomeScreenListing'
import EditHomeScreen from './HomeScreens/EditHomeScreen'
import SessionExpiredModal from "../../components/SessionExpiredModal";
import PrivateRoute from "../../components/PrivateRoute";
const headerItems = [
  {
    title: "MANAGE",
    child: [],
    disabled: true,
  },
  {
    title: "Devices",
    child: [],
    path: "/deenkiosk/devices",
  },
  {
    title: "Home Screen",
    child: [],
    path: "/deenkiosk/home-screen",
  },
];

const bodyItems = {
};
const footerItems = [
  {
    title: "More Apps",
    icon: <BaseIcon icon={MoreApps} />,
    path: "/more-apps",
    child: [
    ],
    secondaryIcon: <BaseIcon icon={ChevronRight} />,
  },
  {
    title: "Support",
    icon: <BaseIcon icon={Support} />,
    path: "http://shapla.deenconnect.io/support",
    child: [],
    secondaryIcon: <BaseIcon icon={Launch} />,
  },
];
const HomePage = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname])
  function onClick() {
    props.history.replace("/donations");
  }
  const logout = () => {
    localStorage.setItem("user", false);
    localStorage.setItem("authToken", "");
    props.history.replace("/signin");
    localStorage.clear();
  };
  const userProfile = () => {
    props.history.push("/admin-profile")
  };
  const navigate = (x, y) => {
    if (x === 'Support') {
      window.location.href = (y)
    } else {
      props.history.push(y)
    }
  }
  const getInitials = () => {
    let nameArr = localStorage.getItem("name") || (props?.signup?.fullName ?? '') ;
    nameArr = nameArr.length > 0 ? nameArr?.trim()?.split(" ") : [];
    let initials;
    if (nameArr?.length > 1) {
      initials = nameArr[0][0] + nameArr[1][0];
    } else {
      initials = nameArr?.[0]?.[0] + nameArr[0]?.[1] ?? '';
    } 
    return initials;
  }
  let image = localStorage.getItem("image");
  const content = (
    <React.Fragment>
      <MasterLayout
        header={
          <SimpleAppBar
            src={MainLogo}
            name={localStorage.getItem("name") || props.signup.fullName}
            showImg={image && image !== "undefined" ? true : false}
            img={image && image !== "undefined" ? image : ""}
            role={localStorage.getItem("role")}
            initials = {getInitials()}
            onClickLogout={() => logout()}
            onClickProfile={() => userProfile()}

          />
        }
        sideBar={
          <>
          <NavDrawer
            headerNavItems={headerItems}
            bodyNavItems={bodyItems}
            footerNavItems={footerItems}
            onBackBtnClick={() => onClick()}
            onClick={() => console.log('')}
            buttonShow={true}
            showTitle={true}
            navigate={(x, y) => navigate(x, y)}
            selectedTitle={localStorage.getItem("pageTitle")}
            distance={360}
          />
          <SessionExpiredModal />
          </>
        }
        headerFixed={true}
        middleContent={
          <Switch>
            <PrivateRoute exact path="/deenkiosk/devices" component={Devices} />
            <PrivateRoute exact path="/deenkiosk/devices/addplaylist" component={Addplaylist} />
            <PrivateRoute exact path="/deenkiosk/devices/editplaylist/:id" component={EditPlaylist} />
            <PrivateRoute exact path="/deenkiosk/devices/manage-devices" component={ManageDevice} />
            <PrivateRoute exact path="/deenkiosk/onboarding" component={Onboarding} />
            <PrivateRoute exact path="/deenkiosk/playlists" component={Playlists} />
              {/* <Playlists />
            </Route> */}
            <Route exact path="/deenkiosk/newplaylists"  >
              <newPlaylists />
            </Route>

            <PrivateRoute exact path="/deenkiosk/add-schedule" component={AddSchedule} />
            <PrivateRoute exact path="/deenkiosk/edit-schedule" component={EditSchedule} />

            <PrivateRoute exact path="/deenkiosk/home-screen" component={HomeScreenListing} />
            <PrivateRoute exact path="/deenkiosk/edit-home-screen" component={EditHomeScreen} /> 


          </Switch>
        }
      />
    </React.Fragment>
  );
  return (
    <>
      <BasePaper elevation={0} context={content} />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    signup: state.signup,
  };
};
export default connect(mapStateToProps, {})(HomePage);
