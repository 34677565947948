import React, { useState } from "react";
import {
  BaseTextField,
  BaseButton,
  TextEditor,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import "./style.css";
import { AddFAQWebsite } from "./api";
import { connect } from "react-redux";
import { showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
import { getOrgData } from "../../redux/actions/organizationData";
import { editOrganization } from "../Organization/api";
const { token_expired, success_message_faq } = messages_content;
function AddFaq(props) {
  const [value, setValue] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);
  const [name, setName] = useState('');
  const [errorName, setErrorName] = useState(false);
  const [errorDesc, setErrorDesc] = useState(false);
  const [error, seterror] = useState(false);
  const [orgData, setOrgData] = useState({})
  const onChange = (e) => {
    setValue(e);
  };
  const handleChange = (prop) => (event) => {
    if(prop === 'name'){
      setName(event.target.value);
      setErrorName(false)
    }
  }
  React.useEffect(() => {
    setOrgData(props.organization.orgData)
  }, [props.organization])
  const AddNow = async () => {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    const obj = {
      question: name,
      answer: JSON.stringify(value),
      orgId: orgId
    }
    if(name === '' || value[0].children[0].text === ''){
      if(name === ''){
        setErrorName(true)
      }
      if(value[0].children[0].text === ''){
        setErrorDesc(true)
      }
    }else{
      try{
        const result = await AddFAQWebsite(obj, token);
        const addedFaq = result.data.body.data._id
        const updateOnOrg = {
          "addSelectedArrayData": {
            "enabledIntegrations.deenweb.faqs.selectedFaqs": [{
              _id: addedFaq,
            }]
          },
        }
        await editOrganization(updateOnOrg, orgId, token);
        props.alertShow(success_message_faq)
        if(result.status === 201){
          props.history.push("/website/faq");
        }
      }catch(err){
        seterror(err.response && err.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    }
  }
  const styles = {
    formBody: {
      background: "#fff",
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A",
    },
    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
    },
    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
  };
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
        <div
          onClick={() => props.history.push("/website/faq")}
          className="backBtn"
          style={{ marginTop: 0 }}
        >
          <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
          <p style={{ alignSelf: "center" }}>Back</p>{" "}
        </div>
        {
          error && error === "Invalid token" && (
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
              <AlertAction
                alert={token_expired}
                alertType={"error"}
                onClick={() => seterror(false)}
              />
            </div>
          )
        }
        </>
      }
      isForm={true}
      formData={
        <>
        <div>
            <div>
              <h1 className="title_top">Add Frequently Asked Questions</h1>
              <div className="dasheddBorder"></div>
            </div>
            <Grid container spacing={3}>
            <Grid item md={12} xl={12}>
              <div className="field_box">
                  <BaseTextField
                    value={name}
                    onChange={handleChange("name")}
                    InputProps={false}
                    count={100}
                    style={{ width: '100%' }}
                    color="primary"
                    //helperText="Enter a title for the FaQ"
                    label="Faq Title"
                    error={errorName}
                  />
                </div>
            </Grid>
            <Grid item md={12} xl={12}>
                <TextEditor
                  label={"Description"}
                  onChange={(e) => onChange(e)}
                  value={value}
                  error={errorDesc}
                  style={styles.editor}
                  maxCharCount={500}
                />
            </Grid>
            <Grid item  md={12} xl={12}>
            <div className="form_button">
              <span className="btnCencel">
                <BaseButton text="CANCEL" onClick={() => props.history.push("/website/faq")} />
              </span>
              <BaseButton text="ADD NOW" onClick={() => AddNow()} />
              </div>
            </Grid>
          </Grid>
        </div>
        </>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style}
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"} 
        />
      }
      isWhiteBackground={true}
    />
  );
}
const mapStateToProps = (state) => {
  return {
    organization: state.orgData
  };
};
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
  dispatchGetOrgData: (data) => dispatch(getOrgData(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddFaq);
