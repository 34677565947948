import React from 'react';
import { SimpleTable } from '@dclab/dc-react-ui-kit';
import UsFlag from '../../assets/img/united-states.svg';

function Agreement(props) {


    // ============================== Table Start ======================================//
    let col = [

        { type: 'link', name: 'invoice_number', label: 'NAME', width: '30%' },
        { type: 'string', name: 'name', label: 'STATUS', width: '15%' },
        { type: 'string', name: 'date', label: 'DATE', width: '30%' },
        { type: 'string', name: 'date', label: 'IP', width: '20%' },


    ];

    const selected_categories = col.sort((a, b) => a.order - b.order)
    let rows = selected_categories.map((text, index) => {
        let i = index + 1
        return (
            [
                { type: 'string', name: 'name', value: 'Liability Waiver and Release of Information' },
                { type: 'string', name: 'category', value: 'Signed' },
                { type: 'string', name: 'category', value: 'Sep 12, 2020, 12:05:01 AM' },
                { name: 'textImg', type: 'string', primaryText: '23.125.122.121', src: UsFlag, },

            ]
        )
    })


    return (

        <div>

            <div className="table_box Innertable">
                <SimpleTable
                    showHeader={false}
                    title="Volunteer Requestes"
                    width="100%"
                    rows={rows}
                    columns={col}
                    colSpan={16}
                    actionSendButton={true}
                    donationsCount={rows && rows.length > 0 ? 1 : 0}
                    noTitle="No inquiry submitted yet"
                    noPara="Inquiries submitted by visitors will be visible and managed here."
                    noBtnText=""
                    onInviteClick={() =>
                        props.history.push(
                            "/volunteer/volunteer-details"
                        )
                    }
                    actionLink={(name) => props.history.push({ pathname: "/volunteer/volunteer-details", state: { contact: name[0].data } })}
                    showIconCol={false}
                    deleteAction={(e) => console.log(e)}
                    showFooter={true}
                    showFilter={true}
                    filterText='Filter'
                    addText="FILTER"
                    rowsPerPage={5}
                    //onAddClick={() => props.history.push("/nikah/nikah-details")}
                    iconTextSize={16}
                    sortColumnIndex={[4]}
                />
            </div>
        </div>

    );
}


export default Agreement;