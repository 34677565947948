import * as constants from "../constants";

function addServiceAction(data) {
  return {
    type: constants.ADD_SERVICE,
    payload: data
  };
}

function editServiceAction(data) {
    return {
      type: constants.EDIT_SERVICE,
      payload: data
    };
  }

  function emptyServiceAction() {
    return {
      type: constants.EMPTY_SERVICE
    };
  }
  

export { addServiceAction,editServiceAction,emptyServiceAction };
