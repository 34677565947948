import React, { Component } from "react";
import { connect } from "react-redux";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import componentImg from "../../../assets/img/component.png";
import { countries } from "../../../utils/countries";
import { states } from "../../../utils/states";
import { stateAbbreviations } from '../../../utils/statesAbbreviations';
import {getaddressFromPostalCode} from '../../Organization/api/index' 

import {
  BaseTextField,
  AlertAction,
  BaseButton,
  DrawerListRadioButton,
  BaseSwitch,
  DrawerDateTime,
  FormSwitch,
  BaseSelect,
  DrawerMain,
  MasterLayoutMiddle,
  FooterInner,
  Repeat
} from "@dclab/dc-react-ui-kit";
import CircularProgress from "@material-ui/core/CircularProgress";
import Moment from "moment-timezone";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import { showAlert } from '../../../redux/actions/alertActions';
import {
  addServiceAction,
  emptyServiceAction,
} from "../../../redux/actions/servicesAction";
import "./style.css";
import { addSalah, getWakt, organizations } from "./Apis/index";
import InfoIcon from '@material-ui/icons/Info';
import messages_content from '../../../utils/messages.json';
import Tooltip from '@material-ui/core/Tooltip';


const { 
    token_expired,
    provide_valid_zip_code,
    repeat_date_between_start_end,
    Organization_postal_code_not_correct
  } = messages_content;
const styles = {
  btn: {
    width: "100%",
    border: "#D3D8DA",
  },
};
class AddSALAH extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef()
    this.state = {
      salahPrimary: "",
      salahSecondary: "",
      loading: false,
      openDrawer: false,
      isSalahDrawer: false,
      isLocationDrawer: false,
      isStartDate: false,
      isEndDate: false,
      isRepeatDays: false,
      submitError: false,
      orgLocation: true,
      organizationAddress: "",
      ISOstartDateandTime: "",
      repeatdays: [],
      startDate: "",
      iqamahTime: "",
      startDateandTime: "",
      EndDateandTime: "",
      daysString: "",
      days: {
        Sun: false,
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
      },
      salahStatus: false,
      fullLocationAddress: "",
      LocationAddress: {
        LocationName: "",
        StreetAddress: "",
        Suit_Number: "",
        City: "",
        State: "",
        Zip_Code: "",
      },
      fullStartDateandTime: "",
      fullEndDateandTime: "",
      fullRepeatDays: "",
      LocationState: {
        LocationName: "",
        StreetAddress: "",
        Suit_Number: "",
        City: "",
        State: "",
        Zip_Code: "",
      },
      endTime_Timestamp: "",
      endDate_Timestamp: "",
      iqamah_ISOString: "",
      startDate_Timestamp: "",
      isError: "",
      errorText: "",
      timezonestring: "",
      timezone: "",
      method: "",
      checkRepeats: "One-time salah",
      checkDaily: "Never",
      NumOccurrence: 1,
      repeat_start_date: "",
      isWaktFeildToShow: false,
      iswaktField: false,
      waktSalahName: "",
      waktSalahValue: "",
      waktSalah: "",
      waktTime: "",
      waktFormat: "",
      waktMethodString: "",
      IsOrgAddress: false,
      endDateError: false,
      startCheckBox: false,
      endCheckBox: false,
      dateError: false,
      timeError: false,
      states: [],
      stateAbbr: "",
      locationError: false,
      endboolcheck: false,
      startboolcheck: false,
      Repeat_beforeError: false,
      data1: [],
      selectedSalah: {},


      //Added by Toseef Ahmed
      //Adding variables that I think are required for Date and Time related information.
      c_salahName: '',
      c_salahOrder: '',
      c_salahWaktName: '',
      c_salahStartDate: '',
      c_salahEndDate: '',
      c_salahStartTime: '',
      c_salahEndTime: '',
      c_organizationAddress: '',
      c_repeatStatus: '',
      c_salahMethod: '',
      c_timeZone: '',
      c_startTime24: '',
      c_endTime24: '',
      c_IqamahTime: '',
      c_dynamicIqamahDetails: {
        check: false,
        mins: ''
      }
    };
  }

  getTimeZoneName = (value) => {
    switch(value){
      case -4:
        return "Atlantic Standard Time (AST)";
      case -5:
        return "Eastern Standard Time (EST)";
      case -6:
        return "Central Standard Time (CST)";
      case -7:
        return "Mountain Standard Time (MST)";
      case -9:
        return "Alaskan Standard Time (AKST)";
      case -10:
        return "Hawaii Standard Time (HST)";
      case 0:
        return "Greenwich Mean Time (GMT)";
      case -8:
        return "Pacific Standard Time (PST)";
      default:
        return "Eastern Standard Time (EST)";
    }
  }
  getFullSalahMethodName = (abbr) => {
   
    switch(abbr) {
      case 'MWL':
        return 'Muslim World League (MWL)'
      case 'ISNA':
        return 'Islamic Society of North America (ISNA)'
      case 'Egypt':
        return 'Egyptian General Authority of Survey'
      case 'Makkah':
        return 'Umm al-Qura University, Makkah'
      case 'Karachi':
        return '	University of Islamic Sciences, Karachi'
      case 'Tehran':
        return 'Institute of Geophysics, University of Tehran'
      case 'Jafari':
        return 'Shia Ithna Ashari (Ja`fari)'
      default:
        return 'Muslim World League (MWL)'
    }
  }

  async componentDidMount() {
    const data1 = [
      {
        id: 1,
        primary: "Salat al-Fajr",
        secondary: "Dawn Prayer",
      },
      {
        id: 2,
        primary: "Salat al-Zuhr",
        secondary: "Noon Prayer",
      },
      {
        id: 3,
        primary: "Salat al-‘Asr",
        secondary: "Afternoon Prayer",
      },
      {
        id: 4,
        primary: "Salat al-Maghrib",
        secondary: "Sunset Prayer",
      },
      {
        id: 5,
        primary: "Salat al-‘Isha",
        secondary: "Night Prayer",
      },
      {
        id: 6,
        primary: "Salat al-Jumu‘ah",
        secondary: "Friday Prayer",
      },

      {
        id: 7,
        primary: "Salat at-Tahajjud",
        secondary: "Late Night Prayer",
      },

      {
        id: 8,
        primary: "Salat al-Janazah",
        secondary: "Funeral Prayer",
      },
      {
        id: 9,
        primary: "Salat al-Eid al-Fitr",
        secondary: "Prayer of Fast-breaking",
      },
      {
        id: 10,
        primary: "Salat al-Eid al-Adha",
        secondary: "Prayer of Sacrifice",
      },
      {
        id: 11,
        primary: "Salat al-Tarawih",
        secondary: "Ramadan Night Prayer",
      },
      {
        id: 12,
        primary: "Salat al-Khawf",
        secondary: "Prayer of Fear",
      },
      {
        id: 13,
        primary: "Salat ad-Duha",
        secondary: "Pre-noon Prayer",
      },
      {
        id: 14,
        primary: "Salat al-Istikharah",
        secondary: "Istikhārah Prayer",
      },
      {
        id: 15,
        primary: "Salat al-'Istisqa",
        secondary: "Prayer for Rain",
      },
    ];
    

    const token = localStorage.getItem('authToken');
    const orgId = localStorage.getItem('orgID');
    try {
        if (token && orgId) {
            const orgres = await organizations(orgId, token);
            
            console.log("Salah Settings: ", orgres.data.body.data[0].salahSetting)
            
            const juristicMethod = orgres.data.body.data[0].salahSetting && orgres.data.body.data[0].salahSetting.juristicMethod //localStorage.getItem('juristicMethod')
           
            let daytimeSaving = orgres.data.body.data[0].salahSetting && orgres.data.body.data[0].salahSetting.daytimeSaving;
            let waqtmethod = orgres.data.body.data[0].salahSetting && orgres.data.body.data[0].salahSetting.waqtMethod;
            let timezoneAbbr = orgres.data.body.data[0].salahSetting && orgres.data.body.data[0].salahSetting.timeZone;
            const timezone = this.getTimeZoneName(timezoneAbbr);
            const waqtMethodString = this.getFullSalahMethodName(waqtmethod)
            const methodString = waqtMethodString
            
            if(juristicMethod === undefined || juristicMethod === null) {
              juristicMethod = 'Standard'
            }

            this.setState({
                waqtmethod: waqtmethod,
                timezone: timezoneAbbr,
                timezonestring: timezone,
                waktMethodString: waqtMethodString,
                daytimeSaving: daytimeSaving,
                juristicMethod: juristicMethod
            })
           
          }
    }
    catch(e) {
        console.log("error: ", e);
    }
    console.log('timeZone Code: '+this.props?.location?.state?.timeZone)
    // const waqtmethod = this.props?.location?.state?.waqtMethod ?? localStorage.getItem("waqtmethod");
    // const waqtMethodString = this.props?.location?.state?.waqtMethod ? this.getFullSalahMethodName(this.props.location.state.waqtMethod) : localStorage.getItem("waqtMethodString");
    // const timezone = this.props?.location?.state?.timeZone ?? localStorage.getItem("timezone");
    // const daytimeSaving = this.props?.location?.state?.daytimeSaving ?? localStorage.getItem("daytimeSaving");
    //const juristicMethod = this.props?.location?.state?.juristicMethod && this.props?.location?.state?.juristicMethod != 'Shafi/Maliki/Hanbali' ? 'Hanafi':  'Standard';
    //const timezonestring = this.props?.location?.state?.timeZone ? this.getTimeZoneName(this.props.location.state.timeZone) : localStorage.getItem("timezonestring");
    const orgAddress = JSON.parse(localStorage.getItem("orgaddress"));
    
    let salahPrimary = "";
    let isWaktFeildToShow = "";
    let Locationaddress = "";
    let orgLocation = true;
    let LocationAddress = {};
    let iqamahTime = "";
    let iqamah_ISOString = "";
    let repeat_start_date = "";
    let startDate = "";
    let startDateandTime = "";
    let EndDateandTime = "";
    let endDate = "";
    let endTime = "";
    let repeat_content = "";
    let repeat_object = "";
    let startTimeToFormat = "";
    let startDateToFormat = "";
    let endDateToFormat = "";
    let endTimeToFormat = "";
    let endbool = true;
    let startbool = true;

    if (JSON.parse(localStorage.getItem("salahObj")) !== null) {
      const salah = JSON.parse(localStorage.getItem("salahObj"));
      salahPrimary = salah.salahPrimary;
      isWaktFeildToShow = salah.isWaktFeildToShow;
    }
    if (JSON.parse(localStorage.getItem("LocationState")) !== null) {
      const Location = JSON.parse(localStorage.getItem("LocationState"));
      Locationaddress = Location.fullLocationAddress;
      orgLocation = Location.orgLocation;
      LocationAddress = {
        City: Location.City,
        LocationName: Location.LocationName,
        State: Location.State,
        StreetAddress: Location.StreetAddress,
        Suit_Number: Location.Suit_Number,
        Zip_Code: Location.Zip_Code,
      };
    }
    if (JSON.parse(localStorage.getItem("StartDate")) !== null) {
      const start_date = JSON.parse(localStorage.getItem("StartDate"));
      iqamahTime = start_date.iqamahTime;
      iqamah_ISOString = start_date.iqamah_ISOString;
      repeat_start_date = start_date.repeat_start_date;
      startDate = startDate.start_date;
      startDateandTime = start_date.startDateandTime;
      startTimeToFormat = start_date.startTimeToFormat;
      startDateToFormat = start_date.startDateToFormat;
      startbool = startDateToFormat ? false : true
      console.log("startbool: ", startbool);
    }
    
    if (JSON.parse(localStorage.getItem("EndDate")) !== null) {
      const endStart = JSON.parse(localStorage.getItem("EndDate"));
      EndDateandTime = endStart.EndDateandTime;
      endDate = endStart.endDate;
      endTime = endStart.endTime;
      endDateToFormat = endStart.endDateToFormat;
      endTimeToFormat = endStart.endTimeToFormat;
      endbool = endDateToFormat ? false : true
    }
    if (JSON.parse(localStorage.getItem("repeats")) !== null) {
      const repeat = JSON.parse(localStorage.getItem("repeats"));
      repeat_content = repeat.repeat_content;
      repeat_object = repeat.repeat_object;
    }
    const org_address = JSON.parse(localStorage.getItem("orgaddress"));
    const country_name = org_address?.country;
    const zip_code = org_address?.postalCode;
    
    const country_code = countries.find((item) => item.name === country_name);
    const new_states = states
      .filter((item) => item.country_code === country_code?.iso2)
      .map((item) => {
        return {
          label: item.name,
          value: item.name,
          name: item.state_code,
          stateAbbr: item.state_code,
        };
      });
    const default_state = states.filter(
      (item) => item.name.toLowerCase() === org_address?.state.toLowerCase()
    );
    this.setState({
      states: new_states,
      stateAbbr: default_state[0]?.state_code,
      // method: waqtmethod,
      // timezone: timezone,
      // timezonestring: timezonestring,
      // waktMethodString: waqtMethodString,
      Salah_Name: salahPrimary,
      iswaktField: isWaktFeildToShow,
      fullLocationAddress: Locationaddress,
      orgLocation,
      LocationAddress,
      iqamahTime,
      iqamah_ISOString,
      repeat_start_date,
      startDate,
      fullStartDateandTime: startDateandTime,
      fullEndDateandTime: EndDateandTime,
      endDate,
      endTime,
      repeat_content,
      repeat_object,
      startTimeToFormat,
      startDateToFormat,
      endDateToFormat,
      endTimeToFormat,
      startboolcheck: startbool,
      endboolcheck: endbool,
      startCheckBox: startbool,
      endCheckBox: endbool,
      data1,

    });
    
    // console.log('Location Changed.....')

    // const obj  = {postal_code: 11218, country:country_name, ipCountry: country_code }
    // //console.log('Posal Code: ' + postal_code)
    // console.log('country: ' + country_code)
    // console.log(obj)
    
    const loc = await getaddressFromPostalCode({postal_code: zip_code, country:country_name });
    const LatLong = loc.data.body.data.location
  
    localStorage.setItem("location", JSON.stringify(LatLong));
    

    const location =
    localStorage.getItem("location") != "undefined"
      ? JSON.parse(localStorage.getItem("location"))
      : localStorage.getItem("location");

    if (location !== "undefined" && JSON.stringify(location) !== "{}") {
      const timezone = this.props?.location?.state?.timeZone ?? localStorage.getItem("timezone");
      const lat = location.lat;
      const long = location.long;

      // console.log(location)
      // console.log('Latitude after local storage'+ location.lat)
      // console.log('Longitude after local storage'+location.long)

      let value = "";
      let newValue = "";
      let waktSalahValue = "";
      try {
        console.log('timezone:')
        console.log(timezone)
        console.log("this.state.waqtmethod: ", this.state.waqtmethod)
        console.log("this.state.timezone: ", this.state.timezone)
        console.log("this.state.juristicMethod: ", this.state.juristicMethod)

        const waktApiResponse = await getWakt(lat, long, this.state.waqtmethod, this.state.timezone, this.state.daytimeSaving, this.state.juristicMethod);
        console.log('Wakt API '+ waktApiResponse)
        const wakt_res = waktApiResponse.data.body.data;
        console.log("wakt_res", wakt_res)

        if (
          JSON.parse(localStorage.getItem("salahObj")) !== null &&
          JSON.parse(localStorage.getItem("salahObj")).isWaktFeildToShow
        ) {
          const waktSalah = JSON.parse(localStorage.getItem("salahObj"));
          value = waktSalah.salahPrimary;
          newValue = value.split(" (")[0];
          Object.keys(wakt_res[0] && wakt_res[0]).forEach((item) => {
            //console.log('item')
            //console.log(item)
            const newItem = item === "dhuhr" ? item.replace(item, "zuhr") : item;
            if (newValue.toLowerCase().includes(newItem)) {
              waktSalahValue = wakt_res[0][item];
            }
          });
        }
        const salahTime12Hrs = waktSalahValue && this.formatAMPM(waktSalahValue);
        this.state.c_salahStartTime = salahTime12Hrs;
        //console.log("formatAMPM", salahTime12Hrs, waktSalahValue, this.formatAMPM(waktSalahValue))

       

        this.setState({
          waktResponse: wakt_res,
          waktSalah: newValue.substring(newValue.lastIndexOf("-") + 1),
          waktTime: salahTime12Hrs,
          waktFormat: new Date(`${wakt_res[0].date}T${waktSalahValue}:00`),
        });
      } catch (error) {
        this.setState({
          error: error && error.response && error.response.data.message,
          showAlert: true,
        });
      }
    } else {
      //console.log('Location has issues: ')
      this.setState({
        error: provide_valid_zip_code,
        IsOrgAddress: true,
        locationError: true,
      });
    }

    this.locationAddress();
  }
  
  locationAddress = () => {
    const { LocationState, orgLocation, stateAbbr } = this.state;
    if (orgLocation) {
      const orgAddress = JSON.parse(localStorage.getItem("orgaddress"));
      const orgName = localStorage.getItem("organame");
      const fullLocationAddress = `${orgAddress.line1 ? orgAddress.line1 + ". " : ""
        }${orgAddress.line2 ? orgAddress.line2 + " " : ""}${orgAddress.city ? orgAddress.city + ", " : ""
        }${stateAbbr} ${orgAddress.postalCode}`;
      
        this.setState({
        fullLocationAddress,
        openDrawer: false,
        isLocationDrawer: false,
        locationPlaceholder: orgName,
        isError: false,
        LocationAddress: {
          LocationName: orgName,
          StreetAddress: orgAddress.line1,
          Suit_Number: orgAddress.line2 || "",
          City: orgAddress.city,
          State: orgAddress.state,
          Zip_Code: orgAddress.postalCode,
        },
        LocationState: {
          LocationName: "",
          StreetAddress: "",
          Suit_Number: "",
          City: "",
          State: "",
          Zip_Code: "",
        },
      });
      localStorage.setItem(
        "LocationState",
        JSON.stringify({
          LocationName: orgName,
          StreetAddress: orgAddress.line1,
          Suit_Number: orgAddress.line2 || "",
          City: orgAddress.city,
          State: orgAddress.state,
          Zip_Code: orgAddress.postalCode,
          fullLocationAddress,
          orgLocation,
        })
      );
    } else {
      const LocationName = LocationState.LocationName;
      let StreetAddress = LocationState.StreetAddress;
      let Suit_Number = LocationState.Suit_Number;
      let City = LocationState.City;
      const State = LocationState.State;
      const Zip_Code = LocationState.Zip_Code;
      const state_abbr = states.filter(
        (item) => item.name.toLowerCase() === State.toLowerCase()
      );
      if (LocationName && StreetAddress && City && State && Zip_Code) {
        let fullLocationAddress = `${StreetAddress ? StreetAddress + ". " : ""
          }${Suit_Number ? Suit_Number + " " : ""}${City ? City + ", " : ""}${state_abbr[0].state_code
          } ${Zip_Code}`;
        this.setState({
          fullLocationAddress,
          openDrawer: false,
          isLocationDrawer: false,
          orgLocation: false,
          isError: false,
          locationPlaceholder: LocationName,
          LocationAddress: LocationState,
        });
        localStorage.setItem(
          "LocationState",
          JSON.stringify({
            LocationName: LocationState.LocationName,
            StreetAddress: LocationState.StreetAddress,
            Suit_Number: LocationState.Suit_Number,
            City: LocationState.City,
            State: LocationState.State,
            Zip_Code: LocationState.Zip_Code,
            fullLocationAddress,
            orgLocation: false,
          })
        );
      } else {
        this.setState({ isError: true });
      }
    }
  };
  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
    
  };

  handleSwitchChange = (val) => (event) => {
    this.setState({ [val]: !this.state[val] });
   
  };

  //Get Data calls wakt
  getData = (value, secondary) => {
    const selectedSalah1 = this.state.data1.filter((el, index) => value == el.primary)?.[0];
    selectedSalah1 && this.setState({selectedSalah : selectedSalah1})
    
    console.log("selected Salah: ")
    console.log(selectedSalah1)
   
    const { waktResponse, timezone } = this.state;
    const waktArr = waktResponse && waktResponse[0];

    const salahPrimaryUponValue =
      value + (secondary ? " (" + secondary + ")" : "");
    
    
    let salahID;
    this.state.c_salahName = salahPrimaryUponValue;
    if(selectedSalah1) {
      this.state.c_salahOrder = selectedSalah1.id
    }
    console.log('Selected Salah ID: ' + this.state.c_salahOrder)
    console.log('c_salahName: ' + this.state.c_salahName)
    
    // this.setState({
    //   c_salahName: salahPrimaryUponValue
    // })
    
    
    let isWaktFeildToShow = false;
    let waktSalahValue = "";
    if (waktArr && "fajr" in waktArr) {
      Object.keys(waktArr && waktArr).forEach((item) => {
        let newItem = item === "dhuhr" ? item.replace(item, "zuhr") : item;
        value = value === 'Salat al-Jumu‘ah' ? 'Salat al-Zuhr' : value;
        
        if (value.toLowerCase().includes(newItem)) {
          isWaktFeildToShow = true;
          waktSalahValue = waktArr[item];
          let waktSalahName =  value.substring(value.lastIndexOf("-") + 1)
          if(waktSalahName.toLowerCase().includes("asr") || waktSalahName.toLowerCase().includes("isha"))
          {
            waktSalahName = waktSalahName.slice(1, waktSalahName.length)
          }
         this.state.c_salahWaktName = waktSalahName;
          this.state.c_startTime24 = waktSalahValue;
         
          //console.log( this.state.c_startTime24)
        }
      });
      const salahTime12Hrs = waktSalahValue && this.formatAMPM(waktSalahValue);
      

      // this.setState({
      //   c_salahStartTime: salahTime12Hrs
      // })


     
     
      // console.log('salahTime12Hrs')
      // console.log(salahTime12Hrs)

      this.state.c_salahStartTime = salahTime12Hrs;
      console.log('c_salahStartTime')
      console.log(this.state.c_salahStartTime)

      this.setState({
        ...(value && { salahPrimary: salahPrimaryUponValue }),
        isWaktFeildToShow: isWaktFeildToShow,
        waktSalahName: value.substring(value.lastIndexOf("-") + 1),
        waktSalahValue: salahTime12Hrs,
        waktFormat: new Date(`${waktArr.date}T${waktSalahValue}:00`),
        selectedSalah: selectedSalah1
      });
     
    }

    

    this.setState({salahPrimary: salahPrimaryUponValue })

    const salahPrimary = salahPrimaryUponValue;
    localStorage.removeItem("salahObj")
    localStorage.setItem(
      "salahObj",
      JSON.stringify({ salahPrimary, isWaktFeildToShow })
    );
    
  };
  getDateTime = (value, boolean) => {
    
    console.log("Date check called....")
    let startDate = "";
    const { iqamah_ISOString, iqamahTime, startTimeToFormat } = this.state;


    //console.log('Start Date: '+ iqamah_ISOString)
    if (!boolean) {
      startDate = Moment(value).format("ddd, MMM DD, yyyy");
      
      //console.log('startDate')
      //console.log(startDate)
      
    }

    this.state.c_salahStartDate = startDate;

    //this.convertUTC(this.state.c_salahStartDate, 'start_time');

    // this.setState({
    //   c_salahStartDate: startDate
    // })

    
    this.setState({
      startDateandTime: (startDate ? startDate + ", " : "") + iqamahTime,
      iqamah_ISOString,
      startDate,
      repeat_start_date: startDate.slice(0, 3),
      startDateToFormat: value,
      temp_startDate: value,
      dateError: false,
    });

    localStorage.setItem(
      "StartDate",
      JSON.stringify({
        startDate: startDate,
        iqamahTime: iqamahTime,
        iqamah_ISOString: iqamah_ISOString,
        repeat_start_date: startDate.slice(0, 3),
        startDateandTime: (startDate ? startDate + ", " : "") + iqamahTime,
        startDateToFormat: value,
        startTimeToFormat: startTimeToFormat,

      })
    );
  };
  getCheck = (value) => {

    if(!value) {
      console.log("Check Disabled...")
      this.setState({c_salahStartDate : ""});
    }
    this.setState({ startCheckBox: value, startDateToFormat: "" });
  };

  getTimeCheck = (value) => {
    // this.setState(prevState => ({ 
    //     c_dynamicIqamahDetails: {...prevState.c_dynamicIqamahDetails, check: value}
    // }));
    // console.log('Iqamah Time check')
    // console.log(this.c_dynamicIqamahDetails)
  }

 
  dynamicIqamahTime = (value) => {

    const { startDate, startDateToFormat, c_salahStartDate, c_salahStartTime } = this.state;
    let tempDateObj;
    if(c_salahStartDate) { // ||c_salahStartDate==="" || c_salahStartDate===undefined
      tempDateObj = c_salahStartDate
      console.log("c_SalahStart valid")
    }
    else {
      console.log("c_SalahStart Invalid")
      let tempDate = new Date();
      tempDateObj = Moment(tempDate).format("YYYY-MM-DD");
    }

    const { waktTime } = this.state;
    //console.log("waktTime")
    //console.log(waktTime)
    
    let h = c_salahStartTime.split(":")[0];
    let m = c_salahStartTime.split(":")[1];
    let format = c_salahStartTime.split(" ")[1];
    
    let mins = parseInt(m) + parseInt(value)
   
    const startTime24 = this.convertTime12to24(c_salahStartTime)
    // console.log("dynamic hrs" , h)
    // console.log("dynamic mins" , m)
    // console.log("dynamic mins iqamah" , mins)

    var d = new Date(`${tempDateObj} ${startTime24}`);
    
    d.setMinutes(mins);

    //console.log("Start Date: ", d)
    const iqamahTime = Moment(d).format("hh:mm A");
    //console.log("iqamahTime: ", iqamahTime)
    this.state.c_IqamahTime = iqamahTime
    console.log("Dynamic Iqamah Time: ", this.state.c_IqamahTime)
   // this.state.c_IqamahTime = this.state.c_salahStartTime
    this.state.c_dynamicIqamahDetails.mins = value;
    this.state.c_dynamicIqamahDetails.check = true;
    //console.log("iqamahTime Toseef", this.state.c_dynamicIqamahDetails.mins)

    let dateUTC = this.convertUTC(tempDateObj, "start_time")//Moment(this.state.c_salahEndDate).format("YYYY-MM-DD")

    //This code will be removed
    const Iqamah_date_utc = Moment(d).format("YYYY-MM-DD");
    const Iqamah_time_utc = Moment(d).format("hh:mm") + " " +  format;
    const iqamah_ISOString = new Date(`${Iqamah_date_utc} ${Iqamah_time_utc}`);
    this.setState(prevState => ({
      startDateandTime: (startDate ? startDate + ", " : "") + iqamahTime,
      iqamah_ISOString,
      startTimeToFormat: iqamah_ISOString,
      timeError: false,
      temp_startTime: d,
      iqamahTime: iqamahTime,
      c_dynamicIqamahDetails: {...prevState.c_dynamicIqamahDetails, mins: value}
    }));

    // console.log('Iqamah Details')
    // console.log(this.c_dynamicIqamahDetails)
    // const ms = Moment(d).format("hh:mm")
    
  }

  getEndCheck = (value) => {
    if(!value) {
      //this.setState({ c_salahEndDate : ""});
      this.state.c_salahEndDate = ""
      console.log("Check disbled: ", this.state.c_salahEndDate)
    }
    this.setState({ endCheckBox: value, endDateToFormat: "" });
  };


  //getISODate(date, time)
  getDefaultDateObj = (date, time) => {

    console.log("date in default obj: ", date)
    let tempDateObj;
    let dateObj;
    if(!date || date==="undefined" || date==="") {
      if(time==="start_time") {
         dateObj = new Date();
         console.log("default date: ", dateObj)
      }
      else if(time==="end_time") {
        dateObj = new Date('December 31, 2098 00:00:00');
        console.log("default date: ", dateObj)
      }

      tempDateObj = Moment(dateObj).format("YYYY-MM-DD")
    }
    else {
      tempDateObj = date
    }

    return tempDateObj;
  }

  convertUTC = (date, time) => {
    console.log("date value: ", date);
    
    let tempDateObj;
    let dateObj;
    let time24;

    if(!date || date==="undefined" || date==="") {
      if(time==="start_time") {
         dateObj = new Date();
         console.log("default date: ", dateObj)
      }
      else if(time==="end_time") {
        dateObj = new Date('December 31, 2099 00:00:00');
        console.log("default date: ", dateObj)
      }

      tempDateObj = Moment(dateObj).format("YYYY-MM-DD")
    }
    else {
      console.log("date is not invalid: ", dateObj)
      tempDateObj = date
    }

    console.log("tempDateObj: ", tempDateObj)
    if(time === "iqamah_time") {
      time24 = this.convertTime12to24(this.state.c_IqamahTime)
    }
    else if(time==="start_time") {
      time24 = this.state.c_startTime24
    }
    else if(time==="end_time") {
      time24 = this.state.c_endTime24
    }

    let UpdatedDate = new Date(`${tempDateObj} ${time24}`)
    console.log("UpdatedDate: ", UpdatedDate)

    const hrs = UpdatedDate.getHours();

    UpdatedDate.setUTCHours(hrs)
    const timeFinal = Moment(UpdatedDate).format();

    console.log( "Final Time: ", timeFinal );
    
    const  utcDateTime = isNaN( new Date(timeFinal) ) ? new Date() : new Date(timeFinal).toISOString();
    console.log("utcDateTime: ", utcDateTime)

    // const utcOffset = UpdatedDate.getTimezoneOffset();
    // const momentISO = Moment(UpdatedDate).utcOffset(-1*utcOffset).format("YYYY-MM-DDTHH:mm:00:000Z")
    // console.log("momentISO: ", momentISO)

    // let DateUTC = Moment(date).format("YYYY-MM-DDTHH:mm")


    // //console.log("DateUTC: ", DateUTC)
    // let h, m
    // h = time24.split(":")[0];
    // m = time24.split(":")[1];

    // //console.log('time24: ', time24)

    // //console.log('Hours Obtained: ', h)
    // //console.log('Minutes Obtained: ', m)
    // let utcDateTime = Moment(DateUTC).add( h, 'hours').add( m, 'minutes').format("YYYY-MM-DDTHH:mm")
    

    // //console.log("utcDateTime: ", utcDateTime)
    // let newDateISO = new Date(utcDateTime).toISOString()
    // console.log('startDateUTC after adding hours')
    // console.log(newDateISO)

    return utcDateTime;

  };

  startTime = (value) => {
    const { startDate, startDateToFormat } = this.state;

    const iqamahTime = Moment(value).format("hh:mm A");
    
    this.state.c_IqamahTime = iqamahTime
    this.state.c_salahStartTime = iqamahTime

    console.log('iqamahTime Testing');
    console.log(iqamahTime);


    //This code will be removed
    const Iqamah_date_utc = Moment(value).format("YYYY-MM-DD");
    const Iqamah_time_utc = Moment(value).format("hh:mm A");
    const iqamah_ISOString = new Date(`${Iqamah_date_utc} ${Iqamah_time_utc}`);
    
    console.log('iqamah_ISOString to test.....')
    console.log(iqamah_ISOString)
    this.setState({
      startDateandTime: (startDate ? startDate + ", " : "") + iqamahTime,
      iqamah_ISOString,
      startTimeToFormat: iqamah_ISOString,
      timeError: false,
      temp_startTime: value,
      iqamahTime: iqamahTime,
    });


    localStorage.setItem(
      "StartDate",
      JSON.stringify({
        startDate: startDate,
        iqamahTime: iqamahTime,
        iqamah_ISOString: iqamah_ISOString,
        repeat_start_date: startDate && startDate.slice(0, 3),
        startDateandTime: (startDate ? startDate + ", " : "") + iqamahTime,
        startDateToFormat: startDateToFormat,
        startTimeToFormat: value,
      })
    );
  };

  getEndDateTime = (value, boolean) => {
    let endDate = "";
    const { endTime, endTimeToFormat } = this.state;

    if (!boolean) {
      endDate = Moment(value).format("ddd, MMM DD, yyyy");
    }

    this.state.c_salahEndDate = Moment(value).format("ddd, MMM DD, yyyy");

    console.log(this.state.c_salahEndDate)

    
    this.setState({
      EndDateandTime: (endDate ? endDate + ", " : "") + endTime,
      endDate,
      endTime,
      temp_endDate: value,
      dateError: false,
      endDateToFormat: value,
    });
    localStorage.setItem(
      "EndDate",
      JSON.stringify({
        endDate: endDate,
        endTime: endTime,
        EndDateandTime: (endDate ? endDate + ", " : "") + endTime,
        endDateToFormat: value,
        endTimeToFormat: endTimeToFormat,

      })
    );
  };

  endTime = (value) => {
    let endTime = Moment(value).format("hh:mm A");

    this.state.c_salahEndTime = endTime;

    this.state.c_endTime24 = this.convertTime12to24(this.state.c_salahEndTime)

    console.log('End Time Added Now')
    console.log(this.state.c_endTime24)
    

   //let dateUTC = this.convertUTC(this.state.c_salahEndDate, "end_time")//Moment(this.state.c_salahEndDate).format("YYYY-MM-DD")

    //This Code will be removed
    const { endDate, endDateToFormat } = this.state;
    this.setState({
      EndDateandTime: (endDate ? endDate + ", " : "") + endTime,
      endDate: endDate,
      endTime: endTime,
      endTimeToFormat: value,
      timeError: false,
      temp_endTime: value,
    });
    localStorage.setItem(
      "EndDate",
      JSON.stringify({
        endDate: endDate,
        endTime: endTime,
        EndDateandTime: (endDate ? endDate + ", " : "") + endTime,
        endDateToFormat: endDateToFormat,
        endTimeToFormat: value,
      })
    );
  };

  handleRepeatdaysDelete = (data) => {
    const { repeatdays } = this.state;
    const newrepeatsdays = repeatdays.filter((chip) => chip.item != data);
    const days = {
      Sun: false,
      Mon: false,
      Tue: false,
      Wed: false,
      Thu: false,
      Fri: false,
      Sat: false,
    };
    if (newrepeatsdays.length > 0) {
      newrepeatsdays.forEach((val) => {

        if (Object.keys(days).includes(val.item)) {
          days[val.item] = true;
        }
      });
    }
    this.setState({ repeatdays: newrepeatsdays, days });
  };
  repeatsalahs = (days) => {
    let newObj = {};
    Object.keys({
      sunday: true,
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: true,
    }).forEach((item, index) => {
      const key = (item[0].toUpperCase() + item.slice(1).toLowerCase()).slice(
        0,
        3
      );
      const objectKeys = Object.keys(days);
      if (item.includes(objectKeys[index].toLowerCase())) {
        newObj[item] = days[key];
      }
    });
    return newObj;
  };
  saveRepeats = (value) => {
    this.setState({
      repeat_object: value,
      repeat_content: value.repeat_content,
      openDrawer: false,
      isRepeatDays: false,
    });
    localStorage.setItem(
      "repeats",
      JSON.stringify({
        repeat_object: value,
        repeat_content: value.repeat_content,
      })
    );
  };
  CANCELRepeats = () => {
    this.setState({
      repeat_object: "",
      repeat_content: "",
      openDrawer: false,
      isRepeatDays: false,
    });
  };
  handlerepeats = (event, props) => {
    this.setState({ [props]: event.target.value });
  };
  formatAMPM = (timeString) => {
    const H = +timeString.substr(0, 2);
    const h = H % 12 || 12;
    const ampm = H < 12 || H === 24 ? "AM" : "PM";
    return (timeString = `${h}${timeString.substr(2, 3)} ${ampm}`);
  };
  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }
  addSalah = async () => {

    console.log('Salah Added...')
    this.setState({ loading: true });
    const {
      method,
      timezone,
      timezonestring,
      waktMethodString,
      waktFormat,
      repeat_object,
      repeat_content,
      Salah_Name,
      fullStartDateandTime,
      fullEndDateandTime,
      fullLocationAddress,
      salahStatus,
      endDate,
      endTime,
      startDate,
      iqamah_ISOString,
      LocationAddress,
      c_dynamicIqamahDetails
    } = this.state;
    if (
      !fullStartDateandTime ||
      !fullLocationAddress ||
      !Salah_Name ||
      !fullEndDateandTime ||
      !repeat_content
    ) {
      this.setState({ submitError: true, loading: false });
    } 
    else if (
      new Date(`${this.state.c_salahEndDate} ${this.state.c_salahEndTime}`).getTime() <=
      new Date(
        `${this.state.c_salahStartDate} ${this.state.c_IqamahTime}`).getTime()
    ) {
      this.state.endDateError = true;
      this.state.loading = false;
      //this.setState({ endDateError: true, loading: false });
    } 
    else if (new Date(repeat_content) <= new Date(
      `${this.state.c_salahStartDate} ${this.state.c_IqamahTime}`
    )) {
      this.setState({ Repeat_beforeError: repeat_date_between_start_end, loading: false });
    } else {
      const token = localStorage.getItem("authToken");
      const orgId = localStorage.getItem("orgID");
      
      // Api object
      // const data = {
      //   salahName: Salah_Name,
      //   salahStart: {
      //     startDate: '',
      //     iqamahTime: ''
      //   },
      //   salahEnd: {
      //     endDate: '',
      //     endTime: ''
      //   },
      //   waqt: {
      //     waqtDate: waktFormat,
      //     waktMethodString: waktMethodString,
      //     timezoneFormat: timezone,
      //   },
      //   settings: {
      //     salahMethod: {
      //       waqtMethod: method,
      //       timezone: timezonestring,
      //     },
      //     otherSettings: {}
      //   },
      //   address: {
      //     line1: LocationAddress.StreetAddress,
      //     line2: LocationAddress.Suit_Number,
      //     city: LocationAddress.City,
      //     state: LocationAddress.State,
      //     postalCode: LocationAddress.Zip_Code,
      //     locationName: LocationAddress.LocationName,
      //   },
      //   orgId: orgId,
      //   status: salahStatus,
      //   c_dynamicIqamahDetails
      // };

     
      const data = {
        salahName: {
          fullName: Salah_Name,
          waktName: this.state.c_salahWaktName
        },
        salahStart: {
              waktTime: this.convertUTC(this.state.c_salahStartDate, "start_time"),
              iqamahTime: this.convertUTC(this.state.c_salahStartDate, "iqamah_time")
            },
        salahEnd: this.convertUTC(this.state.c_salahEndDate, "end_time"),
        settings: {
          salahMethod: {
            waqtMethod: method,
            timezone: timezonestring,
          },
          otherSettings: {}
        },
        address: {
          line1: LocationAddress.StreetAddress,
          line2: LocationAddress.Suit_Number,
          city: LocationAddress.City,
          state: LocationAddress.State,
          postalCode: LocationAddress.Zip_Code,
          locationName: LocationAddress.LocationName,
        },
        orgId: orgId,
        status: salahStatus,
        dynamicIqamahDetails: this.state.c_dynamicIqamahDetails,
        order: this.state.c_salahOrder
      };

      if (repeat_content) {
        data.repeat = {
          category: repeat_object.repeat_category,
          repeatEvery: repeat_object.repeat_every,
          frequency: repeat_object.frequency,
          repeatOn: repeat_object.repeat_on,
          repeatEnds: {
            type: repeat_object.repeats_Object.type,
            value: repeat_object.repeats_Object.value,
          },
        };
      }

      // if (this.state.c_salahStartDate && this.state.c_IqamahTime) {
      //   data.salahStart.waktTime = this.convertUTC(this.state.c_salahStartDate, "start_time")
      //   data.salahStart.iqamahTime = this.convertUTC(this.state.c_salahStartDate, "iqamah_time")
      // }
      // if (this.state.c_salahEndDate &&this.state.c_salahEndTime) {
      //   data.salahEnd = this.convertUTC(this.state.c_salahEndDate, "end_time")
      // }
     
      try {
        console.log('Salah Added Message')
        console.log("'Salah To Add: ",data)
        
        const res = await addSalah(data, token);
        localStorage.removeItem("salahObj");
        localStorage.removeItem("LocationState");
        localStorage.removeItem("StartDate");
        localStorage.removeItem("EndDate");
        localStorage.removeItem("repeats");
        localStorage.removeItem("newMethod");
        this.setState({
          success: true,
          showAlert: true,
          loading: false,
        });
        this.props.showAlert('addSalah')
        this.props.history.push("/salah");
      } catch (error) {
       
        this.setState({
          error: error && error.response && error.response.data.message,
          showAlert: true,
          loading: false,
        });
      }
    }
  };
  render() {
    const {
      endboolcheck,
      startboolcheck,
      showAlert,
      error,
      openDrawer,
      loading,
      isLocationDrawer,
      isSalahDrawer,
      LocationState,
      isStartDate,
      isEndDate,
      Repeat_beforeError,
      isRepeatDays,
      Salah_Name,
      salahPrimary,
      iqamahTime,
      startDateandTime,
      EndDateandTime,
      fullStartDateandTime,
      fullLocationAddress,
      fullEndDateandTime,
      isError,
      locationPlaceholder,
      submitError,
      timezonestring,
      repeat_content,
      isWaktFeildToShow,
      iswaktField,
      waktSalahName,
      waktSalahValue,
      waktSalah,
      waktTime,
      waktMethodString,
      IsOrgAddress,
      dateError,
      timeError,
      states,
      locationError,
      endDateError,
      endTimeToFormat,
      endDateToFormat,
      startDateToFormat,
      startTimeToFormat,
      repeat_object,
      data1,
      selectedSalah
    } = this.state;
    // const data1 = [
    //   {
    //     id: 1,
    //     primary: "Salat al-Fajr",
    //     secondary: "Dawn Prayer",
    //   },
    //   {
    //     id: 2,
    //     primary: "Salat al-Zuhr",
    //     secondary: "Noon Prayer",
    //   },
    //   {
    //     id: 3,
    //     primary: "Salat al-‘Asr",
    //     secondary: "Afternoon Prayer",
    //   },
    //   {
    //     id: 4,
    //     primary: "Salat al-Maghrib",
    //     secondary: "Sunset Prayer",
    //   },
    //   {
    //     id: 5,
    //     primary: "Salat al-‘Isha",
    //     secondary: "Night Prayer",
    //   },
    //   {
    //     id: 6,
    //     primary: "Salat al-Jumu‘ah",
    //     secondary: "Friday Prayer",
    //   },

    //   {
    //     id: 7,
    //     primary: "Salat at-Tahajjud",
    //     secondary: "Late Night Prayer",
    //   },

    //   {
    //     id: 8,
    //     primary: "Salat al-Janazah",
    //     secondary: "Funeral Prayer",
    //   },
    //   {
    //     id: 9,
    //     primary: "Salat al-Eid al-Fitr",
    //     secondary: "Prayer of Fast-breaking",
    //   },
    //   {
    //     id: 10,
    //     primary: "Salat al-Eid al-Adha",
    //     secondary: "Prayer of Sacrifice",
    //   },
    //   {
    //     id: 11,
    //     primary: "Salat al-Tarawih",
    //     secondary: "Ramadan Night Prayer",
    //   },
    //   {
    //     id: 12,
    //     primary: "Salat al-Khawf",
    //     secondary: "Prayer of Fear",
    //   },
    //   {
    //     id: 13,
    //     primary: "Salat ad-Duha",
    //     secondary: "Pre-noon Prayer",
    //   },
    //   {
    //     id: 14,
    //     primary: "Salat al-Istikharah",
    //     secondary: "Istikhārah Prayer",
    //   },
    //   {
    //     id: 15,
    //     primary: "Salat al-'Istisqa",
    //     secondary: "Prayer for Rain",
    //   },
    // ];
    const LocationArray = [
      "Location Name",
      "Street Address",
      "Suite/FL Number",
      "City",
      "State",
      "Zip code",
    ];

    const LocationArrayNames = [
      "LocationName",
      "StreetAddress",
      "Suit_Number",
      "City",
      "State",
      "Zip_Code",
    ];
    const stringRegex = /^[a-zA-Z\s\.]*$/;
    const steetAddress = /^\s*\S+(?:\s+\S+){2}/;
    const flatReg = /^[a-zA-Z0-9\s,.'-]*$/;
    const state = /[A-Z][a-z]+/g;
    const zipReg = /^[0-9]*$/;
    const orgAddress = JSON.parse(localStorage.getItem("orgaddress"));
    const orgName = localStorage.getItem("organame");

    const style = {
      width: "100%",
    };

    

    return (
      <MasterLayoutMiddle
        header={
          <>
            <div
              onClick={() => {
                this.props.history.push("/salah");
                localStorage.removeItem("salahObj");
                localStorage.removeItem("LocationState");
                localStorage.removeItem("StartDate");
                localStorage.removeItem("EndDate");
                localStorage.removeItem("repeats");
                localStorage.removeItem("newMethod");
              }}
              className="backBtn"
            >
              <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
              <p style={{ alignSelf: "center" }}>Back</p>{" "}
            </div>
            {error && error === "Invalid token" && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => this.setState({ error: false })}
                />
              </div>
            )}
            {locationError && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={
                    provide_valid_zip_code
                  }
                  alertType={"error"}
                  onClick={() => this.setState({ locationError: false })}
                />
              </div>
            )}

            {showAlert && error && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={
                    error === "Invalid token"
                      ? token_expired
                      : error
                  }
                  alertType={"error"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {showAlert && IsOrgAddress && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                }}
              >
                <AlertAction
                  alert={Organization_postal_code_not_correct}
                  alertType={"error"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
          </>
        }
        isForm={true}
        formData={
          <>
            <div>
              <h1 className="title_top">Add Salah</h1>
              <div className="dasheddBorder"></div>
            </div>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                {" "}
                <h2 className="sub_title">Basic Information</h2>{" "}
              </Grid>

              <Grid item xs={12}>
                <BaseTextField
                  value={Salah_Name ? Salah_Name : ""}
                  onChange={this.handleChange("salah")}
                  InputProps={false}
                  phone={false}
                  search={false}
                  count={false}
                  label="Salah Name"
                  placeholder=""
                  style={styles.btn}
                  onClick={() =>
                    this.setState({ openDrawer: true, isSalahDrawer: true })
                  }
                  disabled={isSalahDrawer}
                  error={submitError && !Salah_Name}
                  helperText={submitError && "Salah required"}
                  InputProps={true}
                />

                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    {iswaktField && fullLocationAddress && (
                      <div className="wakt_feild">
                        <div>
                          <img
                            src={componentImg}
                            alt="componentmg"
                            style={{ width: 24, height: 24 }}
                          />
                        </div>
                        <div>
                          <p>{`${waktSalah} waqt starts at ${waktTime}`}</p>
                          <p>
                            <span>{`Method - ${waktMethodString}`}</span>{" "}
                            <span
                              className="change"
                              onClick={() =>
                                this.props.history.push("salahsettings")
                              }
                            >
                              Change
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="time_zone_field">
                      <img
                        src={componentImg}
                        alt="componentmg"
                        style={{ width: 24, height: 24 }}
                      />
                      <p>
                        {`${timezonestring}`}{" "}
                        <span
                          className="change"
                          onClick={() =>
                            this.props.history.push("salahsettings")
                          }
                        >
                          Change
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <div className="dasheddBorder_form"></div>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <h2 className="sub_title">Select Location</h2>{" "}
              </Grid>

              <Grid item xs={12}>
                <div className="field_box">
                  <BaseTextField
                    value={fullLocationAddress}
                    onChange={this.handleChange("Location")}
                    InputProps={false}
                    phone={false}
                    search={false}
                    count={false}
                    disabled={isLocationDrawer}
                    label={
                      locationPlaceholder ? locationPlaceholder : "Location"
                    }
                    placeholder=""
                    style={styles.btn}
                    error={submitError && !fullLocationAddress}
                    helperText={submitError && "Location required"}
                    onClick={() =>
                      this.setState({
                        openDrawer: true,
                        isLocationDrawer: true,
                      })
                    }
                    InputProps={true}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <div className="dasheddBorder_form"></div>
              </Grid>
              <Grid item xs={12}>
                {" "}
                <h2 className="sub_title">Date and Time</h2>{" "}
              </Grid>

              <Grid item xs={12}>
                <div className="field_box">
                  <BaseTextField
                    value={this.state.c_salahStartDate && this.state.c_IqamahTime //If Both Start Date and Start Time are available, show both
                      ? this.state.c_salahStartDate + ' , ' +  this.state.c_IqamahTime 
                      : !this.state.c_salahStartDate && this.state.c_IqamahTime ? this.state.c_IqamahTime  //If only Iqamah Time is available, show only Time
                      : ""}
                    InputProps={false}
                    phone={false}
                    search={false}
                    count={false}
                    label="Start Date and Time"
                    placeholder=""
                    style={styles.btn}
                    error={submitError && !fullStartDateandTime}
                    helperText={submitError && "Start date and time required"}
                    onClick={() =>
                      this.setState({ openDrawer: true, isStartDate: true })
                    }
                    disabled={isStartDate}
                    InputProps={true}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="field_box">
                  <BaseTextField
                    value={(this.state.c_salahEndDate && this.state.c_salahEndTime) //If Both End Date and End Time are available, show both
                      ? this.state.c_salahEndDate + ' , ' +  this.state.c_salahEndTime 
                      : (!this.state.c_salahEndDate && this.state.c_salahEndTime) ? this.state.c_salahEndTime //If only End Time is available, show only Time
                      : ""}
                    InputProps={false}
                    phone={false}
                    search={false}
                    count={false}
                    label="End Date and Time"
                    placeholder=""
                    style={styles.btn}
                    error={(submitError && !(this.state.c_salahEndDate && this.state.c_salahEndTime)) || endDateError}
                    helperText={
                      (submitError &&
                        !(this.state.c_salahEndTime && this.state.c_salahEndTime) &&
                        "End date and time is required") ||
                      (endDateError &&
                        "End date must be greater than start date")
                    }
                    onClick={() =>
                      this.setState({ openDrawer: true, isEndDate: true })
                    }
                    disabled={isEndDate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          onClick={() =>
                            this.setState({
                              openDrawer: true,
                              isRepeatDays: true,
                            })
                          }
                          position="end"
                          style={{
                            marginBottom: 15,
                            cursor: "pointer",
                          }}
                        >
                          <ChevronRight />{" "}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="field_box">
                  <BaseTextField
                    value={repeat_content || ""}
                    InputProps={false}
                    phone={false}
                    search={false}
                    count={false}
                    label="Repeat"
                    placeholder=""
                    style={styles.btn}
                    onClick={() =>
                      this.setState({ openDrawer: true, isRepeatDays: true })
                    }
                    disabled={isRepeatDays}
                    error={submitError && !repeat_content || Repeat_beforeError}
                    helperText={submitError && !Repeat_beforeError ? "Repeats required" : repeat_date_between_start_end}
                    InputProps={true}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="border_top_bottm">
                  <FormSwitch
                    headline="Salah Status"
                    body="Enabling would make the salah available for publishing"
                    values={this.state.salahStatus}
                    handleSwitchChange={this.handleSwitchChange("salahStatus")}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <div
                    style={{
                      marginTop: 30,
                      display: "flex",
                      justifyContent: "center",
                      width: 500,
                    }}
                  >
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                    <div
                      className="addSalah"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div>
                        <div className="form_button">
                          <p
                            className="cancel"
                            onClick={() => {
                              localStorage.removeItem("salahObj");
                              localStorage.removeItem("LocationState");
                              localStorage.removeItem("StartDate");
                              localStorage.removeItem("EndDate");
                              localStorage.removeItem("repeats");
                              localStorage.removeItem("newMethod");
                              this.props.history.push("/salah");
                            }}
                          >
                            CANCEL
                        </p>
                          <BaseButton
                            text="ADD NOW"
                            onClick={() => this.addSalah()}
                          />
                        </div>
                      </div>
                    </div>
                  )}
              </Grid>
            </Grid>
            <DrawerMain
              open={openDrawer}
              openHandler={() =>
                this.setState({
                  openDrawer: false,
                  isStartDate: false,
                  isEndDate: false,
                  isLocationDrawer: false,
                  isSalahDrawer: false,
                  isRepeatDays: false,
                })
              }
              title={
                (isSalahDrawer && "Salah Name") ||
                (isLocationDrawer && "Salah Location") ||
                (isStartDate && "Start Date and Time") ||
                (isEndDate && "End Date and Time") ||
                (isRepeatDays && "Repeats")
              }
              onCancel={() => {
                if (isSalahDrawer) {
                  this.setState((prevState) => ({
                    openDrawer: false,
                    isSalahDrawer: false,
                    Salah_Name: this.state.Salah_Name
                      ? this.state.Salah_Name
                      : "",
                  }));
                } else if (isLocationDrawer) {
                  this.setState({
                    openDrawer: false,
                    isLocationDrawer: false,
                    isError: false,
                    orgLocation: true,
                    LocationState: {
                      StreetAddress: "",
                      Suit_Number: "",
                      City: "",
                      State: "",
                      Zip_Code: "",
                      LocationName: "",
                    },
                  });
                } else if (isEndDate) {
                  this.setState({
                    openDrawer: false,
                    isEndDate: false,
                    endCheckBox: this.state.endDateToFormat ? false : true,
                    fullEndDateandTime: fullEndDateandTime
                      ? fullEndDateandTime
                      : "",
                    EndDateandTime: "",
                    temp_endDate: "",
                    temp_endTime: "",
                    dateError: false,
                    timeError: false,
                    endCheckBox: true,
                  });
                  localStorage.removeItem("EndDate");
                } else if (isStartDate) {
                  this.setState({
                    openDrawer: false,
                    startCheckBox: this.state.startDateToFormat ? false : true,
                    isStartDate: false,
                    fullStartDateandTime: fullStartDateandTime
                      ? fullStartDateandTime
                      : "",
                    startDateandTime: "",
                    temp_startDate: "",
                    temp_startTime: "",
                    dateError: false,
                    timeError: false,
                    startCheckBox: true,
                  });
                  localStorage.removeItem("StartDate");
                } else if (isRepeatDays) {
                  this.child.cancle();
                }
              }}


              onSave={() => {
                if (isSalahDrawer) {
                  
                  console.log(this.state.selectedSalah)
                  localStorage.setItem(
                    "salahObj",
                    JSON.stringify({ salahPrimary, isWaktFeildToShow })
                  );


                  this.setState({
                    openDrawer: false,
                    isSalahDrawer: false,
                    Salah_Name: salahPrimary,
                    iswaktField: isWaktFeildToShow,
                    waktSalah: waktSalahName,
                    waktTime: waktSalahValue,
                  });
                } else if (isLocationDrawer) {
                  this.locationAddress();
                } else if (isStartDate) {
                  const {
                    startCheckBox,
                    startDateToFormat,
                    iqamahTime,
                    temp_startTime,
                  } = this.state;
                  
                  if (startCheckBox && !startDateToFormat && !iqamahTime) {
                    this.setState({ dateError: true, timeError: true });
                  } else if (startCheckBox && iqamahTime && !startDateToFormat) {
                    this.setState({ dateError: true, timeError: false });
                  } else if (startCheckBox && startDateToFormat && !iqamahTime) {
                    this.setState({ dateError: false, timeError: true });
                  } else if (!startCheckBox && !iqamahTime) {
                    this.setState({ dateError: false, timeError: true });
                  } else if (!startCheckBox && iqamahTime && !startDateToFormat) {
                    this.setState({
                      openDrawer: false,
                      isStartDate: false,
                      fullStartDateandTime: iqamahTime,
                      timeError: false,
                      dateError: false,
                      repeat_start_date: "",
                      startDate: "",
                      // startDateToFormat: "",
                      startDateandTime: iqamahTime,
                      // startCheckBox: true,
                      startboolcheck: startCheckBox
                    });
                    let startObj = JSON.parse(localStorage.StartDate);
                    startObj.repeat_start_date = "";
                    startObj.startDate = "";
                    startObj.startDateToFormat = "";
                    startObj.startDateandTime = iqamahTime;
                    localStorage.setItem("StartDate", JSON.stringify(startObj));
                  } else {
                    this.setState({
                      openDrawer: false,
                      isStartDate: false,
                      fullStartDateandTime: startDateandTime
                        ? startDateandTime
                        : fullStartDateandTime,
                      timeError: false,
                      dateError: false,
                      // startCheckBox: true,
                      startboolcheck: false

                    });
                  }
                } else if (isEndDate) {
                  const {
                    endCheckBox,
                    endDateToFormat,
                    temp_endTime,
                    endTime,
                  } = this.state;
                  if (endCheckBox && !endDateToFormat && !endTime) {
                    this.setState({ dateError: true, timeError: true });
                  } else if (endCheckBox && endTime && !endDateToFormat) {
                    this.setState({ dateError: true, timeError: false });
                  } else if (endCheckBox && endDateToFormat && !endTime) {
                    this.setState({ dateError: false, timeError: true });
                  } else if (!endCheckBox && !endTime) {
                    this.setState({ dateError: false, timeError: true });
                  } else if (!endCheckBox && endTime && !endDateToFormat) {
                    this.setState({
                      openDrawer: false,
                      isEndDate: false,
                      fullEndDateandTime: endTime,
                      timeError: false,
                      dateError: false,
                      // endCheckBox: true,
                      endDate: "",
                      // endDateToFormat: "",
                      EndDateandTime: endTime,
                      endboolcheck: endCheckBox
                    });
                    let endObj = JSON.parse(localStorage.EndDate);
                    endObj.endDate = "";
                    endObj.endDateToFormat = "";
                    endObj.EndDateandTime = endTime;
                    localStorage.setItem("EndDate", JSON.stringify(endObj));
                  } else {
                    this.setState({
                      openDrawer: false,
                      isEndDate: false,
                      fullEndDateandTime: EndDateandTime
                        ? EndDateandTime
                        : fullEndDateandTime,
                      timeError: false,
                      dateError: false,
                      // endCheckBox: true,
                      endDateError: false,
                      endboolcheck: endCheckBox
                    });
                  }
                } else if (isRepeatDays) {
                  this.child.save();
                }
              }}
            >
              
              <div>
                {isSalahDrawer && (
                  <div className="salahDrawer">
                    <DrawerListRadioButton
                      objects={data1}
                      getData={this.getData}
                      selectedData = {selectedSalah}
                    />
                  </div>
                )}
                {isLocationDrawer && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <div className="salahStatus">
                        <h2>Use org address</h2>
                      </div>
                      <div>
                        <BaseSwitch
                          checked={this.state.orgLocation}
                          onChange={this.handleSwitchChange("orgLocation")}
                        />
                      </div>
                    </div>
                    {this.state.orgLocation ? (
                      <div
                        className="orgName"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          marginTop: "10px",
                        }}
                      >
                        {orgName && <h2>{orgName}</h2>}
                        {orgAddress && (<>
                          <p>{`${orgAddress.line1}`} </p>
                          <p> {`${orgAddress.city}, ${stateAbbreviations[orgAddress.state] ? stateAbbreviations[orgAddress.state]
                              : orgAddress.state} ${orgAddress.postalCode
                            }`}</p></>
                        )}
                      </div>
                    ) : (
                        <div className="drawer_form">
                          {LocationArray.map((item, index) => {
                            return (
                              <>
                                {item !== "State" && (
                                  <BaseTextField
                                    key={index}
                                    value={
                                      LocationState[LocationArrayNames[index]] ||
                                      ""
                                    }
                                    onChange={(e) => {
                                      e.persist();
                                      if (
                                        (item === "Location Name" &&
                                          stringRegex.test(e.target.value)) ||
                                        (item === "Suite/FL Number" &&
                                          e.target.value.length <= 10 && flatReg.test(e.target.value)) ||
                                        (item === "State" &&
                                          stringRegex.test(e.target.value)) ||
                                        (item === "Street Address" &&
                                          flatReg.test(e.target.value)) ||
                                        (item === "Zip code" &&
                                          e.target.value.length <= 10 &&
                                          zipReg.test(e.target.value)) ||
                                        (item === "City" &&
                                          stringRegex.test(e.target.value))
                                      ) {
                                        this.setState((prevState) => ({
                                          LocationState: {
                                            ...prevState.LocationState,
                                            [LocationArrayNames[index]]:
                                              e.target.value,
                                          },
                                        }));
                                      }
                                    }}
                                    InputProps={false}
                                    phone={false}
                                    search={false}
                                    count={false}
                                    label={item}
                                    placeholder=""
                                    style={styles.btn}
                                    helperText={
                                      isError &&
                                      item !== "Suite/Fl Number" &&
                                      !LocationState[LocationArrayNames[index]] &&
                                      item + " " + "required"
                                    }
                                    error={
                                      isError &&
                                      item !== "Suite/Fl Number" &&
                                      !LocationState[LocationArrayNames[index]]
                                    }
                                  />
                                  
                                )}
                                
                                {item === "State" && (
                                  <BaseSelect
                                    object={states}
                                    label="State"
                                    style={{
                                      width: "100%",
                                      margin: 0,
                                      marginBottom: 26,
                                      minHeight: "48px",
                                    }}
                                    value={
                                      LocationState[LocationArrayNames[index]] ||
                                      ""
                                    }
                                    onChange={(e) => {
                                      e.persist();
                                      this.setState((prevState) => ({
                                        LocationState: {
                                          ...prevState.LocationState,
                                          [LocationArrayNames[index]]:
                                            e.target.value,
                                        },
                                      }));
                                    }}
                                    helperText={
                                      isError &&
                                      item !== "Suite/Fl Number" &&
                                      !LocationState[LocationArrayNames[index]] &&
                                      item + " " + "required"
                                    }
                                    error={
                                      isError &&
                                      item !== "Suite/Fl Number" &&
                                      !LocationState[LocationArrayNames[index]]
                                    }
                                  />
                                )}
                              </>
                            );
                          })}
                        </div>
                      )}
                  </>
                )}
                {isStartDate && (
                  
                  <div className="isStartDate">
                    <DrawerDateTime
                      title={""}
                      check={startboolcheck}
                      checkValue = {true}
                      defaultDate={startDateToFormat}
                      defaultTime={startTimeToFormat}
                      dateLabel={"Start Date"}
                      timeLabel={"Iqamah Time"}
                      switchLabel={"Start Date"}
                      getDateTime={this.getDateTime}
                      getTime={this.startTime}
                      getCheck={this.getCheck}
                      getTimeCheck={this.getTimeCheck}
                      dateError={dateError && !startDateToFormat}
                      useAsPublish={false}
                      dateHelperText={
                        dateError && !startDateToFormat && "Start date required"
                      }
                      timeError={timeError && !iqamahTime}
                      timeHelperText={
                        timeError && !iqamahTime && "Start time required"
                      }
                      
                      dynamicIqamahSelect={this.dynamicIqamahTime}
                      timeSwitchLabel={<>Dynamic Iqamah Time <span style={{position: "relative", top: "4px"}}><Tooltip title="Enabling this option will allow to add time for Iqamah to Waqt." placement="top"><InfoIcon fontSize="small" /></Tooltip></span></>} 
                      showTimeSwitch={true}
                    />
                  </div>
                  
                )}
                
                {isEndDate && (    
                  <div className="isEndDate">
                    <DrawerDateTime
                      title={""}
                      check={endboolcheck}
                      checkValue = {true}
                      defaultDate={endDateToFormat}
                      defaultTime={endTimeToFormat}
                      dateLabel={"End Date"}
                      timeLabel={"End Time"}
                      switchLabel={"End Date"}
                      getDateTime={this.getEndDateTime}
                      getTime={this.endTime}
                      getCheck={this.getEndCheck}
                      dateError={dateError && !endDateToFormat}
                      useAsPublish={false}
                      dateHelperText={
                        dateError && !endDateToFormat && "End date required"
                      }
                      timeError={timeError && !this.state.endTime}
                      timeHelperText={
                        timeError && !this.state.endTime && "End time required"
                      }
                    />
                  </div>
                )}
                
                {isRepeatDays && (
                  <>
                    <Repeat
                      callbackFromParent={this.saveRepeats}
                      canclecallbackFromParent={this.CANCELRepeats}
                      ref={ref => (this.child = ref)}
                      maxDate={endDateToFormat}
                      initialEndDate={startDateToFormat || new Date()}
                      category={repeat_object.repeat_category}
                      repeats_object={repeat_object.repeats_Object}
                      frequency={repeat_object.frequency}
                      repeat_every={repeat_object.repeat_every}
                      repeat_on={repeat_object.repeat_on}
                      oneTimeTitle={'salah'}
                    />
                  </>
                )}
              </div>
            </DrawerMain>
          </>
        }
        isFooter={true}
        footer={<FooterInner 
            style={style}
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"} 
          />}
        isWhiteBackground={true}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    editableSalah: state.salahs.editableSalah,
    edit: state.services.edit,
    alertValue: state.alert.alertValue

  };
};
const mapDispatchToProps = (dispatch) => ({
  addServiceHandler: (res) => dispatch(addServiceAction(res)),
  emptyServiceHandler: () => dispatch(emptyServiceAction()),
  showAlert: (res) => dispatch(showAlert(res)),

});
export default connect(mapStateToProps, mapDispatchToProps)(AddSALAH);
