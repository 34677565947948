import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Grid from '@material-ui/core/Grid';
import { DrawerMain, MasterLayoutMiddle, FooterInner, BaseImgChip, BaseSelect, BaseButton, TextEditor, DrawerListCheckbox } from "@dclab/dc-react-ui-kit";
// import './style.css'
import { UpdateContact } from "../../Messages/api";
import { connect } from "react-redux";
import { hideAlert, showAlert } from "../../../redux/actions/alertActions";
import {
  getTeamMembers,
} from '../api';
import messages_content from '../../../utils/messages.json';
import CircularProgress from '@material-ui/core/CircularProgress';
const { inquiry_update_success_message } = messages_content;


function ContactUsRespond(props) {
  const [open, setOpen] = React.useState(false);
  const [contact, setcontact] = useState({
      status: "", assignee: [], id: ""
  });
  const [Contact, setContact] = useState();
  const [showCircular, setshowCircular] = useState(true);
  const [chipData, setchipData] = useState([])
  const [values, setvalues] = useState([])
  const [selectedData, setselectedData] = useState([])

  const [value, setValue] = useState([{ type: "paragraph", children: [{ text: "" }] }]);
  const [descriptionCount, setdescriptionCount] = useState('')
  const [error, setError] = useState(false)
  const [teamMembers, setteamMembers] = useState([])

  function onDescriptionStateChange(editorState) {
    let sum = 0;
    editorState.map((st) => {
      if (st.type === "bulleted-list" || st.type === "numbered-list") {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    setValue(editorState)
    setdescriptionCount(sum)
  };

  useEffect(() => {
    if(props?.location?.state?.contact) {
        const contactData = props?.location?.state?.contact;
        setContact(contactData);
        // console.log(contactData)
        setcontact({
            id: contactData?._id,
            status: contactData?.status ?? "New Order",
            assignee: contactData?.assignee ?? [],
        })
        contactData?.assignee?.length && setchipData(contactData?.assignee?.map((el, i) => {
            return { key: i, label: el.name, src: el.imageUrl, alt: el.name,
          }
        }))

        contactData?.assignee?.length && setselectedData(contactData?.assignee?.map((el, i) => {
            return { id: i + 1, primary: el.name, src: el.imageUrl, secondary: el.role,
        }
        }))

        try {
          const token = localStorage.getItem("authToken")
          const getData = async () => {

            const res2 = await getTeamMembers(token);
            setteamMembers(res2?.data?.body?.data)
            const member = res2?.data?.body?.data ?? [];
            if(member[0]) {
              
              let chipMember;
              let i;
              for(i = 0; i < member.length; i++) {
                const memberRole = member[i]?.role.filter(el => el.roles?.toLowerCase() === 'owner');
                if(memberRole.length > 0) {
                  chipMember =  member[i];
                  break;
                }
              }

              let nameArr = chipMember ? [chipMember?.name?.firstName, chipMember?.name?.lastName] : [member[0]?.name?.firstName, member[0]?.name?.lastName]
              let initials;
              if (nameArr?.length > 1) {
                initials = nameArr[0][0] + nameArr[1][0];
              } else {
                initials = nameArr[0][0];
              }

              if(chipMember) {
                setchipData([{ key: chipMember._id, label: nameArr.join(' '), src: chipMember?.imageDetails?.image ?? "", alt: nameArr.join(' ')}])
                // setselectedData([{
                //    id: i + 1, primary: nameArr.join(' '), src: chipMember.imageUrl, secondary: chipMember.role,
                // }])
              } else {
                member?.length && setchipData([{ key: member[0]._id, label: nameArr.join(' '), src: member[0]?.imageDetails?.image ?? "", alt: nameArr.join(' ')}])
              }
              setcontact({ ...contact, id: contactData?._id, status: contactData?.status ?? "New Order", assignee: [{name: nameArr.join(' '), imageUrl: member[0]?.imageDetails?.image ?? "", role: member[0]?.role?.[0]?.roles}] });
            }
          }
          getData();
        } catch (error) {
          console.log(error)
        }
        setshowCircular(false);
    }
}, [props?.location?.state?.contact])

  const handleChange = (prop) => (event) => {
    setcontact({ ...contact, [prop]: event.target.value });
  };

  const styles = {
    border: '1px solid black',
    color: 'gray'
  };


  const closeDrawer = () => {
    setOpen(!open);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onSave = async () => {
    setOpen(false);
    setcontact({ ...contact, assignee: values?.map((el, i) => {
        return { name: el.primary, imageUrl: el.src, role: el.secondary[0] }
    })
    });
    setchipData(values?.map((el, i) => {
        return { key: i, label: el.primary, src: el.src, alt: "",
      }
    }));

    // setselectedData(values?.map((el, i) => {
    //   return {id: i , primary: nameArr.join(' '), src: chipMember.imageUrl, secondary: chipMember.role,}
    // }));

  }

  const onSubmit = async () => {
    setshowCircular(true);
      const formData = {
        status: contact.status,
        responseTimeline: {
          status: contact.status,
          assignee: contact.assignee,
          note: value[0].children[0].text,
          createdAt: new Date(),
          reviewedBy: ""
        }
      }

      const token = localStorage.getItem("authToken")

      try {
          let res = await UpdateContact(token, contact.id, formData) 
          
          props.alertShow(inquiry_update_success_message)
          props.history.push({pathname: "/organization/contact-us-details", state: {contact: res.data.body.data}});
      } catch (error) {
        setshowCircular(false)
          console.log("error", error)
      }
  }

  const getData = (values) => {
    if(!values?.length) return
    setvalues(values)
  }
  
  const style = {
    width: '100%',
  };
  
  const onInputClick = () => {
    console.log("input clicked");
  };
  
  const onChipInputClick = () => {
    console.log("input chip clicked");
    setOpen(true)
  };
  
  const deleteChip = (data) => {
    console.log('chip deleted', data)
    setcontact({ ...contact, assignee: [] });
    setchipData([])
  }
  
  
  const data3 = teamMembers.map((text, index) => {
    let nameArr = [text.name.firstName, text.name.lastName]
    let initials;
    if (nameArr.length > 1) {
      initials = nameArr[0][0] + nameArr[1][0];
    } else {
      initials = nameArr[0][0];
    }
    return ({
      id: index + 1,
      primary: nameArr.join(' '),
      fullName: nameArr.join(' '),
      secondary: text.role.map((r, i) => {
        return (
          r.roles[0].toUpperCase() + r.roles.slice(1) + `${text.role.length === i + 1 ? '' : ', '}`
        );
      }),
      initials: !text?.imageDetails?.image ? initials : '',
      src: text?.imageDetails?.image ? text.imageDetails.image : '' /* initials.toUpperCase() */
    })
  }); 
  
//   const selectedData = [
    // {
    //     id: 1,
    //     primary: "Abdul Al Razi",
    //     secondary: "Admin",
    //     src: "https://media.gettyimages.com/photos/jama-masjid-mosque-in-delhi-picture-id505757382?s=612x612",
    //   },
//   ];
  

  return (

    <MasterLayoutMiddle
      header={
        <div className="backBtn">
          <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
          <Link
            to={{pathname: "/organization/contact-us-details", state: {contact: Contact}}}
            style={{ alignSelf: "center", color: "black", textDecoration: "none", }} > Back </Link>{" "}
        </div>
      }

      isForm={true}
      formData={
        <>
        {showCircular ? <div
            style={{
            marginTop: 30,
            display: "flex",
            justifyContent: "center",
            }}
        >
          <CircularProgress color="primary" />
        </div>
         :
        <div>
          <Grid container spacing={3}>

            <Grid item md={12}>
              <h1 className='title_top'>Assign</h1>
              <div className="dasheddBorder"></div>
            </Grid>

            <Grid item xs={12}>
              <div className="field_box" style={{marginBottom: "10px"}}>
                <BaseSelect
                  value={contact.status}
                  onChange={handleChange("status")}
                  object={[
                    { value: "New", label: "New" },
                    { value: "In-progress", label: "In-progress" },
                    { value: "Closed", label: "Closed" },
                    { value: "Done", label: "Done" },
                  ]}
                  style={styles.select}
                  label="Status"
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="field_box" onClick={() => setOpen(true)}>
                <BaseImgChip
                  chipData={chipData}
                  onInputClick={onInputClick}
                  onChipInputClick={onChipInputClick}
                  deleteChip={deleteChip}
                  helperText=''
                  label="Assignee"
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="TextArea">
                <TextEditor
                    onChange={(e) => onDescriptionStateChange(e)}
                    disabled={false}
                    value={value}
                    // style={textEditorStyle}
                    label={"Note"}
                    maxCharCount={100}
                    helperText={
                      error &&
                      !descriptionCount &&
                      "description is required"
                    }
                    //error={error && !descriptionCount}
                />
              </div>
            </Grid>


            

            <Grid item md={12} className="buttons">
              <span className="btnCencel" onClick={() => props.history.goBack()}><BaseButton text="CANCEL" /></span>
              <BaseButton text="Save" onClick={() => onSubmit()} />
            </Grid>

          </Grid>

          <DrawerMain
            open={open}
            openHandler={closeDrawer}
            title="Select Assignee"
            onCancel={onCancel}
            loading={false}
            saveDisabled={false}
            onSave={onSave}
          >
            <div className="cardDrawer">
                <DrawerListCheckbox objects={data3}  getData={getData} selectedItems={selectedData}/>
            </div>
          </DrawerMain>
        </div>
      }
        </>
      }

      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}

    />
  );
}

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
  };
};
const mapDispatchToProps = (dispatch) => ({
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsRespond);

const styles = {
  btn: {
    height: 66,
    width: "100%",
    margin: 8,
    border: "#D3D8DA",
  },
};