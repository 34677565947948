import React, { useRef, useImperativeHandle, forwardRef, useState, useEffect } from "react";
import {
  BaseSwitch, DrawerMain, BaseSelect, BaseTextField, AlertAction
} from "@dclab/dc-react-ui-kit";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Tooltip from '@material-ui/core/Tooltip';
import { TwitterPicker } from "react-color";
import { saveAsDraft } from './Apis'
import { getOrganizationData } from './Apis/index'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import messages_content from '../../utils/messages.json';
const { 
    token_expired,
    Your_changes_has_been_saved_as_draft,
    Your_changes_has_been_published
  } = messages_content;
const Embed = forwardRef((props, ref) => {
  const textAreaRef = useRef(null);
  const [customize, setCustomize] = React.useState(false);
  const [text, setText] = React.useState('DONATE NOW');
  const [color, setColor] = React.useState('FFFFFF');
  const [colorBtn, setBtnColor] = React.useState('#2A82F6');
  const [btnSize, setBtnSize] = React.useState('170 x 45 px');
  const [btnWidth, setBtnWidth] = React.useState('170')
  const [btnHeight, setBtnHeight] = React.useState('40')
  const [btnRadius, setBtnRadius] = React.useState('100');
  const [saveBtnStyling, setSaveBtnStyling] = React.useState({})
  const [Embed, setEmbed] = React.useState(false);
  const [error, seterror] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [success, setsuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [btnLink, setbtnLink] = useState("");
  const [copyTooltip, setcopyTooltip] = React.useState(false);
  const [embedform_draft, setembedform_draft] = useState("");
  const setTwitterColorArrow = (color) => {
    const title = document.getElementsByClassName('twitter-picker')[0]?.getElementsByTagName('span')
    if (!title) return
    const length = title?.length
    for (let i = 0; i < length; i++) {
      if (title?.[i]?.children?.[0]?.title?.toLowerCase() === color?.toLowerCase()) {
        title[i].children[0].id = 'checkbox';
        title[i].children[0].innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><defs><style>.a{fill:none;}.b{fill:#fff;}</style></defs><path class="a" d="M0,0H18V18H0Z"/><path class="b" d="M7.7,13.652,5.077,11.027a.742.742,0,1,0-1.05,1.05l3.142,3.142a.747.747,0,0,0,1.057,0l7.95-7.943a.742.742,0,0,0-1.05-1.05Z" transform="translate(-0.952 -1.502)"/></svg>'
      }
      else {
        title[i].children[0].id = '';
        title[i].children[0].innerHTML = ''
      }
    }
  }
  useEffect(() => {
    async function getEmbedForm() {
      try {
        setLoading(true)
        const orgId = localStorage.getItem('orgID')
        const token = localStorage.getItem('authToken')
        const getOraganization = await getOrganizationData(orgId, token)
        const embedform_draft = getOraganization?.data?.body?.data?.[0]?.enabledIntegrations?.deenfund;
        const btnLink = embedform_draft?.embedFormDraft?.[0]?.formLink
        const embed_button = embedform_draft?.embedFormDraft?.[0]?.embedButton
        const btn_color = embed_button?.color
        const btn_text = embed_button?.text
        const background = embed_button?.background 
        const radius = embed_button?.radius
        const btnsource = embed_button?.source
        const height = embed_button?.height
        const width = embed_button?.width
        btn_color && setColor(btn_color)
        btn_text && setText(btn_text)
        background && setBtnColor(background)
        radius && setBtnRadius(radius)
        height && setBtnHeight(height)
        width && setBtnWidth(width)
        btnsource && setEmbed(true)
        btnLink && setbtnLink(btnLink)
        embedform_draft && setembedform_draft(embedform_draft)
        btnsource && setSaveBtnStyling({
          width: width,
          height: height,
          radius: radius,
          btnColor: background,
          btnText: btn_text,
          btnTextColor: btn_color
        })
        setLoading(false)
      }
      catch {
        seterror(error && error.response && error.response.data.message)
        setLoading(false)
        setTimeout(() => {
          seterror(false)
        }, 8000)
      }
    }
    getEmbedForm()
  }, [])
  useEffect(() => {
    setTwitterColorArrow(colorBtn)
  }, [loading])
  function onSave() {
    setCustomize(false)
    let stylingData = {
      width: btnWidth,
      height: btnHeight,
      radius: btnRadius,
      btnColor: colorBtn,
      btnText: text,
      btnTextColor: color
    }
    setSaveBtnStyling(stylingData);
    props.checkChanges(true)
  }
  const handleChange = (prop) => (event) => {
    if (prop === 'text') {
      setText(event.target.value);
    } else if (prop.name === 'colorText') {
      setColor(event.target.value);
    } else if (prop.name === 'buttonColor') {
      setBtnColor(event.target.value);
    } else if (prop === 'size') {
      let array = event.target.value.split(' ');
      setBtnWidth(array[0])
      setBtnHeight(array[2])
      setBtnSize(event.target.value);
    } else if (prop === 'style') {
      setBtnRadius(event.target.value);
    }
  };
  useImperativeHandle(ref, () => ({
    EmbedDraft(param) {
      const orgId = localStorage.getItem('orgID')
      const token = localStorage.getItem('authToken')
      const formdata = {
        enabledIntegrations: formData(param),
      }
      param === 'publish' ? publish(orgId, formdata, token) : draftChanges(orgId, formdata, token)
    }
  }));
  function formData(param) {
    if(param === 'publish'){
      return [{
                donationFormType: embedform_draft?.embedFormDraft?.[0]?.donationFormType,
                preselectAmounts: embedform_draft?.embedFormDraft?.[0]?.preselectAmounts,
                donationMinimumAmount: embedform_draft?.embedFormDraft?.[0]?.donationMinimumAmount,
                donationMinimumAmountEnabled: embedform_draft?.embedFormDraft?.[0]?.donationMinimumAmountEnabled,
                processingFeeEnabled: embedform_draft?.embedFormDraft?.[0]?.processingFeeEnabled,
                paymentMethod: embedform_draft?.embedFormDraft?.[0]?.paymentMethod,//release 2 will have two donations values

                donation_categories: embedform_draft?.embedFormDraft?.[0]?.donation_categories,
                formTitle: embedform_draft?.embedFormDraft?.[0]?.formTitle,
                formThemeColor: embedform_draft?.embedFormDraft?.[0]?.formThemeColor,
                formLink: embedform_draft?.embedFormDraft?.[0]?.formLink,
                formHeaderButton: embedform_draft?.embedFormDraft?.[0]?.formHeaderButton,
                setup: {
                  footer: embedform_draft?.embedFormDraft?.[0]?.setup?.footer,
                  rightSide: embedform_draft?.embedFormDraft?.[0]?.setup?.rightSide,
                  imageOrVideo: embedform_draft?.embedFormDraft?.[0]?.setup?.imageOrVideo,
                  content: embedform_draft?.embedFormDraft?.[0]?.setup?.content,
                  faqs: embedform_draft?.embedFormDraft?.[0]?.setup?.faqs
                },
                embedButton: {
                  source: `<div><a style='display : inline-block; text-decoration : none; color : #${color};
                  background : ${colorBtn}; border-radius : ${btnRadius}px; width : ${btnWidth}px;
                  height: ${btnHeight}px; line-height: ${btnHeight}px; text-align : center' href=${btnLink} target=_blank> ${text}</a></div>`,
                  height: btnHeight,
                  width: btnWidth,
                  color: color,
                  background: colorBtn,
                  text: text,
                  radius: btnRadius
                },
                websiteName: embedform_draft?.embedFormDraft?.[0]?.websiteName,
      }]
    } else{
      return [{
        donationFormType: embedform_draft?.embedFormDraft?.[0]?.donationFormType,
        preselectAmounts: embedform_draft?.embedFormDraft?.[0]?.preselectAmounts,
        donationMinimumAmount: embedform_draft?.embedFormDraft?.[0]?.donationMinimumAmount,
        donationMinimumAmountEnabled: embedform_draft?.embedFormDraft?.[0]?.donationMinimumAmountEnabled,
        processingFeeEnabled: embedform_draft?.embedFormDraft?.[0]?.processingFeeEnabled,
        paymentMethod: embedform_draft?.embedFormDraft?.[0]?.paymentMethod,//release 2 will have two donations values

        donation_categories: embedform_draft?.embedFormDraft?.[0]?.donation_categories,
        formTitle: embedform_draft?.embedFormDraft?.[0]?.formTitle,
        formThemeColor: embedform_draft?.embedFormDraft?.[0]?.formThemeColor,
        formLink: embedform_draft?.embedFormDraft?.[0]?.formLink,
        formHeaderButton: embedform_draft?.embedFormDraft?.[0]?.formHeaderButton,
        setup: {
          footer: embedform_draft?.embedFormDraft?.[0]?.setup?.footer,
          rightSide: embedform_draft?.embedFormDraft?.[0]?.setup?.rightSide,
          imageOrVideo: embedform_draft?.embedFormDraft?.[0]?.setup?.imageOrVideo,
          content: embedform_draft?.embedFormDraft?.[0]?.setup?.content,
          faqs: embedform_draft?.embedFormDraft?.[0]?.setup?.faqs
        },
        embedButton: {
          source: `<div><a style='display : inline-block; text-decoration : none; color : #${color};
          background : ${colorBtn}; border-radius : ${btnRadius}px; width : ${btnWidth}px;
          height: ${btnHeight}px; line-height: ${btnHeight}px; text-align : center' href=${btnLink} target=_blank> ${text}</a></div>`,
          height: btnHeight,
          width: btnWidth,
          color: color,
          background: colorBtn,
          text: text,
          radius: btnRadius
        },
        websiteName: embedform_draft?.embedFormDraft?.[0]?.websiteName,
        changes: true
}]
    }
  }
  async function publish(orgId, formdata, token) {
    // formdata.embedFormDraft[0].changes = false

    let formData2 = {
      'enabledIntegrations.deenfund.embedForm': formdata.enabledIntegrations,
      'enabledIntegrations.deenfund.embedFormDraft': formdata.enabledIntegrations
    }

    // formdata.embedForm = formData()
    // formdata.embedFormDraft[0].changes = false
    props.publishChanges(false)
    try {
      console.log("formData", formData2)
      await saveAsDraft(orgId, formData2, token)
      setshowAlert(true)
      setsuccess('Publish')
      props.hideAlert()
      props.checkChanges(false)
      props.getnewdata(true)
      setTimeout(() => {
        setshowAlert(false)
        setsuccess(false)
      }, 8000)
    }
    catch (error) {
      seterror(error.response && error.response.data.message)
      props.hideAlert()
      setTimeout(() => {
        seterror(false)
      }, 8000)
    }
  }

  async function draftChanges(orgId, formdata, token) {
    let formData2 = {
      'enabledIntegrations.deenfund.embedForm': formdata.enabledIntegrations,
      'enabledIntegrations.deenfund.embedFormDraft': formdata.enabledIntegrations
    }

    try {
      await saveAsDraft(orgId, formData2, token)
      setshowAlert(true)
      setsuccess(true)
      props.hideAlert()
      props.checkChanges(false)
      setTimeout(() => {
        setshowAlert(false)
        setsuccess(false)
      }, 8000)
    }
    catch (error) {
      seterror(error.response && error.response.data.message)
      props.hideAlert()
      setTimeout(() => {
        seterror(false)
      }, 8000)
    }
  }
  function copyToClipboard(e) {
    let Text = document.getElementById('text')
    navigator.clipboard.writeText(Text.textContent);
    setcopyTooltip(true)
    setTimeout(() => {
      setcopyTooltip(false)
    }, 5000);
  };
  const styles = {
    btn: {
      height: 66,
      width: "100%",
      margin: 0,
      border: "#D3D8DA",
    },
    select: {
      margin: 0,
      minWidth: 120,
      height: 66,
      width: 120,
      border: "#D3D8DA",
      width: "100%",
    }
  };
  function colorChangeHandler(event) {
    console.log(event.hex)
    setBtnColor(event.hex)
    setTwitterColorArrow(event.hex)
  }
  const object = [
    {
      value: '170 x 45 px',
      label: "170 x 45 px",
    },
    {
      value: '200 x 60 px',
      label: "200 x 60 px",
    },
  ];
  const objectStyle = [
    {
      value: '100',
      label: "Filled Rounded",
    },
    {
      value: '1',
      label: "Filled Square",
    },
    {
      value: '5',
      label: "Filled Rounded Rectangle",
    },
  ];
  return (
    <>
      {
        error && error === "Invalid token" && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={token_expired}
              alertType={"error"}
              onClick={() => seterror(false)}
            />
          </div>
        )
      }
      {
        showAlert && error && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={
                error === "Invalid token"
                  ? token_expired
                  : error
              }
              alertType={"error"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        )
      }
      {
        showAlert && success && success === 'Publish' ? (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={Your_changes_has_been_published}
              alertType={"success"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        ) : showAlert && success && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={Your_changes_has_been_saved_as_draft}
              alertType={"success"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        )
      }
      <div className="main-form-container">
        {loading && <div className='skeletons'>
          <SkeletonTheme color="#f7f8fa">
            <Skeleton height={162} width={570} />
            <Skeleton height={90} width={570} />
            <Skeleton height={90} width={570} />
            <Skeleton height={90} width={570} />
            <Skeleton height={90} width={570} />
          </SkeletonTheme>
        </div>}
        {!loading && <section>
          <div className="emb_card">
            <div className="df-homepage-btn">
              <div className="card_title">
                <h1 className="df-form-title">Embed Button</h1>
                <p className="df-form-para">Add a sub-title</p>
              </div>
              <div className="df-switch">
                <BaseSwitch
                  checked={Embed}
                  onChange={() => setEmbed(!Embed)}
                />
              </div>
            </div>
            {
              !Embed ?
                null
                :
                <div style={{ paddingBottom: '20px' }}>
                  <div style={{ cursor: 'pointer' }} onClick={() => {
                        setCustomize(true)
                        setTimeout(() => {
                          setTwitterColorArrow(colorBtn)
                        }, 100);
                  }}>
                  <div className="df-homepage-btn" >
                    <h1 className="df-form-link-2">Customize Button</h1>
                    <div className="df-switch">
                      <ChevronRight style={{ color: "#818E94" }} />
                    </div>
                  </div>
                  <p className="df-form-subtitle-para">
                    Change the button type and color from here
                  </p>
                  </div>
                  <div className="df-preview-btn">
                    <div className="card_title">
                      <h1 className="df-form-link-3">Preview</h1>
                    </div>
                    <div className='df-previewButton' style={{
                      backgroundColor: saveBtnStyling !== {} ? `${saveBtnStyling.btnColor}` : '',
                      width: `${saveBtnStyling !== {} ? saveBtnStyling.width : btnWidth}px`,
                      height: `${saveBtnStyling !== {} ? saveBtnStyling.height : btnHeight}px`,
                      borderRadius: saveBtnStyling !== {} ? `${saveBtnStyling.radius}px` : '5rem'
                    }} >
                      <label style={{ color: saveBtnStyling.btnTextColor ? `#${saveBtnStyling.btnTextColor}` : '#fff' }}>
                        {saveBtnStyling.btnText ? saveBtnStyling.btnText : 'DONATE NOW'}
                      </label>
                    </div>
                  </div>
                  <h1 className="df-form-link-2">
                    Copy and paste the code below into your website or blog
                  </h1>
                  <div className="df-code-box">
                    <div className="df-html-code" ref={textAreaRef}>
                      <p id='text'>
                        &lt;div&gt;&lt;a style='display : inline-block; text-decoration : none; color : #{color};
                        background : {colorBtn}; border-radius : {btnRadius}px; width : {btnWidth}px;
                        height: {btnHeight}px; line-height: {btnHeight}px; text-align : center' href={btnLink} target="_blank"&gt; {text} &lt;/a&gt;&lt;/div&gt;
                    </p>
                    </div>
                    <div className="df-copy-div">
                        <Tooltip title="Copied!" arrow placement="top"
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={() => setcopyTooltip(false)}
                          open={copyTooltip}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                        >
                      <p className="df-copy-text" onClick={() => copyToClipboard()} >Copy Code</p>
                      </Tooltip>
                    </div>
                  </div>
                </div>
            }
          </div>
          <div className="emb_card">
            <div className="card_title">
              <h1 className="df-form-title">EMBED FORM</h1>
              <p className="df-form-para">Paste this Donation form into any website or webpage.</p>
            </div>
            <div className="df-upgrade-box">
              <p className="upgrade-text">
                Upgrade to the Team plan and paste this Donation form into any
                website or webpage.
              </p>
              <p className="df-upgrade-now">Comming Soon</p>
            </div>
          </div>
          <DrawerMain
            open={customize}
            openHandler={() => setCustomize(false)}
            title="Customize Button"
            onCancel={() => setCustomize(false)}
            onSave={() => onSave()}
            loading={false}
          >
            <BaseTextField
              value={text}
              onChange={handleChange("text")}
              // InputProps={true}
              phone={false}
              search={false}
              label='Text'
              style={styles.btn}
              color="primary"
              disabled={false}
            />
            <BaseSelect
              value={btnRadius}
              onChange={handleChange("style")}
              object={objectStyle}
              label='Style'
              style={styles.select}
            />
            <div>
              <BaseSelect
                value={btnSize}
                label='Size'
                onChange={handleChange("size")}
                object={object}
                style={styles.select}
              />
            </div>
            {!loading && <div>
              <div className="card_title" style={{paddingBottom: "5px"}}>
                <h1 className="df-form-title">Button Color</h1>
              </div>
              <TwitterPicker
                className='df-embed-colorpicker hitInput draw_btn_color'
                colors={['#2A82F6', '#3CC480', '#7266FB', '#00ADBE', '#E57D33', '#A7CF84']}
                color={colorBtn}
                onChange={(event) => colorChangeHandler(event)}
              />
            </div> }
            <div>
              <div className="card_title">
                <h1 className="df-form-title">Text Color</h1>
              </div>
              <div className="df-custom-color" style={{ width: '260px' }}>
                <div className="df-hash">
                  <p className="df-hash-sign">#</p>
                </div>
                <div style={{ width: 20, height: 35 }}></div>
                <input type="text" value={color} className="df-custom-color-input" onChange={handleChange({ name: 'colorText' })} />
              </div>
            </div>
            <div style={{ marginTop: 25 }}>
              <div className="card_title">
                <h1 className="df-form-title">Preview</h1>
              </div>
              <div className='df-previewButton' style={{
                backgroundColor: colorBtn !== '' ? `${colorBtn}` : '',
                width: `${btnWidth}px`,
                height: `${btnHeight}px`,
                borderRadius: btnRadius !== '' ? `${btnRadius}px` : '5rem'
              }} >
                <label style={{ color: color !== '' ? `#${color}` : '#fff' }}>
                  {text !== '' ? text : 'DONATE NOW'}
                </label>
              </div>
            </div>
          </DrawerMain>
        </section>}
      </div>
    </>
  );
})

export default Embed;
