import React, { Component } from "react";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {
  BaseButton,
  BaseCheckBox,
  BaseFileSelect,
  DrawerMain,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import InputAdornment from "@material-ui/core/InputAdornment";
import ChipInput from "material-ui-chip-input";
import Grid from '@material-ui/core/Grid';
import "./style.css";
import "../../App.css";
import { editOrganization, getOrganizationData } from "./api";
import { connect } from "react-redux";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import { CircularProgress } from "@material-ui/core";
import messages_content from '../../utils/messages.json';
const { 
    token_expired,
    success_message_edit_taxExemt
  } = messages_content;
class TaxExemption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taxExempted: false,
      disabledButton: true,
      openDrawer: false,
      document: [],
      uploadDoc: [],
      error: '',
      showAlert: false,
      taxStatus: {},
      Loading: true
    };
    this.upload = this.upload.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
    this.addDocument = this.addDocument.bind(this);
    this.onSaveTaxExemption = this.onSaveTaxExemption.bind(this);
  }
  upload = (file) => {
    this.setState({
      document: file,
    });
  };
  async componentDidMount() {
    const orgId = localStorage.getItem('orgID');

    try {
      const res = await getOrganizationData(orgId);
      let result = res.data.body.data
      this.setState({ taxStatus: result.taxIdStatus, taxExempted: result.taxIdStatus.taxExempt, Loading: false })
    } catch (err) {
      this.setState({ Loading: false, error: err })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000)
    }
  }
  async onSaveTaxExemption() {
    const { taxExempted, taxStatus } = this.state;
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    const data = {
      taxIdStatus: {
        taxIdEin: taxStatus.taxIdEin ? taxStatus.taxIdEin : '',
        events: taxStatus.events.length ? taxStatus.events : [],
        taxExempt: taxExempted
      },
    }
    try {
      await editOrganization(data, orgId, token)
      this.props.alertShow(success_message_edit_taxExemt)
      this.setState({ showAlert: false })
      this.props.history.push('/organization/overview')
    } catch (err) {
      this.setState({
        error: err
      });
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000)
    }
  }
  handleDeleteChip = (chip, index) => {
    this.setState({
      uploadDoc: [],
    });
  };
  addDocument = async () => {
    const { document } = this.state;
    this.setState({
      uploadDoc: [{ name: document[0].name, src: document[0].src }],
      openDrawer: false,
    });
  };
  render() {
    const { taxExempted, openDrawer, error, showAlert, Loading, taxStatus } = this.state;
    const style = {
      width: '100%',
    };
    return (
      <MasterLayoutMiddle
        header={
          <>
            {
              showAlert &&
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                <AlertAction
                  alert={success_message_edit_taxExemt}
                  alertType={"success"}
                  onClick={() => this.state.showAlert(false)}
                />
              </div>
            }
            {error &&
              ((error.response &&
                error.response.data.message === 'Invalid token') ||
                error === 'Invalid token' ||
                error === 'No token Provided') && (
                <div
                  style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35 }}
                >
                  <AlertAction
                    alert={token_expired}
                    alertType={'error'}
                    onClick={() => this.setState({ error: false })}
                  />
                </div>
              )}
            <div
              onClick={() => this.props.history.push("/organization/overview")}
              className="backBtn">
              <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
              <p style={{ alignSelf: "center" }}>Back</p>{" "}
            </div>
          </>
        }
        isForm={true}
        formData={
          Loading ?
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 50,
              }}
            >
              <CircularProgress
                color='primary'
                style={{ width: 24, height: 24 }}
              />
            </div>
            :
            <div>
              <h1 className="title_top">Tax Exemption</h1>
              <div className="dasheddBorder"></div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseCheckBox
                      checked={taxExempted}
                      label="We are 501(c)(3) tax exempted not-for-profit organization."
                      color="secondary"
                      showHelperText={true}
                      helperText="This will be displayed to potential visitors/donors as VERIFIED - 501(c)(3)"
                      onChange={(e) => this.setState({ taxExempted: e.target.checked })}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <>
                      <div className="form_button">
                        <p
                          className="cancel"
                          onClick={() =>
                            this.props.history.push("/organization/overview")
                          }
                        >
                          CANCEL
                      </p>
                        <BaseButton text="SUBMIT"
                          disabled={taxStatus.taxExempt === taxExempted}
                          onClick={() => this.onSaveTaxExemption()} />
                      </div>
                    </>
                  </div>
                </Grid>
              </Grid>
              <DrawerMain
                open={openDrawer}
                openHandler={() => this.setState({ openDrawer: false })}
                title="Upload Document"
                onCancel={() => this.setState({ openDrawer: false })}
                onSave={() => this.addDocument()}
              >
                <div style={{ marginBottom: 20 }}>
                  <BaseFileSelect
                    selectedFiles={(file) => this.upload(file)}
                    acceptedTypes="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/rtf"
                  />
                </div>
              </DrawerMain>
            </div>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={true}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  alert: state.alert,
});
const mapDispatchToProps = (dispatch) => ({
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TaxExemption);
