import React, { Component, useState, useEffect } from 'react'
import { MasterLayoutMiddle, FooterInner, BaseTextField, BasePhoneInput, BaseButton  } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import Button from '@material-ui/core/Button';
import {inviteDonor, getInviteDonor, editInviteDonor} from '../apis/index';
import { connect } from "react-redux";
import { showAlert } from "../../../redux/actions/alertActions";
import { CircularProgress } from "@material-ui/core";

function EditInviteDonor(props) {
  const emailRegex = /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/;
  const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email : "",
  });
  const [error, setError] = useState(false);
  const [phone, setPhone] = React.useState("");
  const [donorId, setdonorId] = useState(null);
  const [loading, setloading] = useState(false);

  const getDonorData = async () => {
      const token = localStorage.getItem("authToken");
      let pathName = window.location.href;
      let id = pathName.slice(pathName.lastIndexOf("/") + 1);

      const response = await getInviteDonor(id, token);
      const donorData = response?.data?.body?.data;
      
      if(Object.keys(donorData)?.length > 0) {
          setdonorId(id);
          setValues({
              firstName: donorData?.firstName,
              lastName: donorData?.lastName,
              email: donorData?.email
          });
          setPhone(donorData?.phoneNo);
        }
  };

  useEffect(() => {
    getDonorData();
  }, []);

  const classes = useStyles();
  const style = {
    width: '100%',
  };

  


  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };


  const editInvite = () => {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    setloading(true);

    const {firstName, lastName, email} = values;
    if(firstName.length === 0 || lastName.length === 0
       || email.length === 0 || !emailRegex.test(email)
       || phone.length === 0 || !phoneRegex.test(phone.replace(/\D/g, ''))
       ) {
        setError(true);
        setloading(false);
        return ;
    } else {
      setError(false);
    }

    const OBJ = {
      phoneNo: phone,
      firstName: firstName,
      lastName: lastName,
      email: email,
      orgId: orgId
    };
    
    editInviteDonor(donorId, token, OBJ)
    .then(res => {
      props.alertShow("Invitation resent successfully");
      setloading(false);
      props.history.push("/deenfund/manage-donors")
    })
    .catch(err => {
      setloading(false);
      console.log("Error: ", err);
    })
  }

  return (
    <div>

      <MasterLayoutMiddle

        header={
          <div
            onClick={() =>
              props.history.push('/deenfund/manage-donors')
            }
            className='backBtn'>
            <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
            <p style={{ alignSelf: 'center' }}>Back</p>{' '}
          </div>
        }
        isForm={true}
        formData={
          loading ?
            <div
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </div>
            :
          <div className={classes.Container}>

            <div>
              <h1 className="title_top">Edit Invite Donor</h1>
              <div className="dasheddBorder"></div>
            </div>

            <Grid container spacing={3}>

              <Grid item xs={6} >
                <BaseTextField
                  label="First Name"
                  value={values?.firstName}
                  onChange={handleChange("firstName")}
                  InputProps={false}
                  phone={false}
                  search={false}
                  color="primary"
                  onClick={() => console.log("onCLick")}
                  onBlur={() => console.log("onBlur")}
                  error = {error}
                  helperText={
                    error ?
                      values?.firstName?.length === 0 ?
                      "First Name is required"
                      :''
                    :''
                  }
                  multiline={true}
                  rowsMax={4}

                />
              </Grid>

              <Grid item xs={6} >
                <BaseTextField
                  label="Last Name"
                  value={values?.lastName}
                  onChange={handleChange("lastName")}
                  InputProps={false}
                  phone={false}
                  search={false}
                  color="primary"
                  onClick={() => console.log("onCLick")}
                  onBlur={() => console.log("onBlur")}
                  error = {error}
                  helperText={
                    error 
                    ? values?.lastName?.length === 0
                      ? 'Last Name is required'
                      :''
                    :''
                  }
                  multiline={true}
                  rowsMax={4}

                />
              </Grid>

              <Grid item xs={6}>
                <BaseTextField
                  label="Email"
                  value={values?.email}
                  onChange={handleChange("email")}
                  InputProps={false}
                  phone={false}
                  search={false}
                  color="primary"
                  onClick={() => console.log("onCLick")}
                  onBlur={() => console.log("onBlur")}
                  multiline={true}
                  rowsMax={4}
                  error={error}
                  helperText={
                    error
                    ? values?.email?.length === 0
                      ? "Email is required"
                      :values?.email?.length > 0 && !emailRegex.test(values?.email) 
                        ? "Email is invalid"
                        : ""
                    : "We'll send an invitation link to this email"
                  }

                />
              </Grid>

              <Grid item xs={6} className={classes.phoneInput}>
              <div className="field_box">
                <BasePhoneInput
                  phoneInputVariant={"underlineWithFlag"} // default, materialui, underlineInput, underlineWithFlag
                  country={'us'}
                  value={phone}
                  countryCodeEditable={false}
                  onChange={(e, f, g, h) => {
                    setPhone(h)
                  }}
                  onBlur={() => console.log("onBlur")}
                  onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                  variant="outlined"
                  onlyCountries={["us", "ca", "gb", "my"]}
                  containerStyle={{}}
                  buttonStyle={{}}
                  inputStyle={{
                    marginLeft: "0px",
                    height: "35px",
                    width: "100%",
                    fontFamily: "Roboto-Regular",
                    fontSize: "16px",
                  }}
                  containerClass={{}}
                  errorText={error
                    ? phone?.length === 0
                      ? "Phone Number is required"
                      :phone?.length && !phoneRegex.test(phone.replace(/\D/g, '')) 
                        ? "Phone Number is invalid"
                        : ""
                    : ""}
                  error={error}
                  errorStyles={{ marginBottom: "5px" }}
                  autoFormat={true}
                  disableDropdown={false}
                  disabled={false}
                  disableCountryCode={false}
                  enableSearch={false}
                  disableSearchIcon={false}
                  placeholder={""}
                  inputProps={{}}
                  specialLabel={'Phone Number'}
                // preferredCountries={[]}
                // excludeCountries={[]}
                />
                </div>
              </Grid>

              <Grid item xs={12} >
                <div className="form_button">
                    <p className="cancel" onClick={() => props.history.push("/deenfund/manage-donors")} >CANCEL</p>
                    {(values.firstName && values.lastName && values.email && phone?.length > 2 ) 
                    ? 
                      <BaseButton text="RESEND INVITE" onClick={editInvite}/>
                    :
                      <BaseButton text="RESEND INVITE" onClick={() => {}} disabled/>
                    }
                  
                  </div>
                  {/* <Button onClick = {editInvite} > Resend Invite  </Button> */}
              </Grid>

            </Grid>

          </div>
        }


        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }

        isWhiteBackground={true}


      />




    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    alertValue: state.alert.alertValue

  };
};

const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditInviteDonor);


const useStyles = makeStyles((theme) => ({

  Container: {
    marginTop: "0px",
  },

  Label:{
    fontSize:"12px",
    color:"#818E94",
  },

  addInviteBtn:{
    color:"#2490F9",
    padding:"0",
    textTransform:"uppercase",

    '& svg':{
      marginRight:"8px",
    },

    '&:hover':{
      backgroundColor:"transparent",
    }
  },

  phoneInput:{
    '& .form-control':{
      height:"29px !important",
    }
  }

}));