import React from "react";
import { useEffect, useState } from 'react';
import { BaseTextField, TableModal, MasterLayoutMiddle, FooterInner, BaseSelect, BaseButton, TextEditor, AlertAction  } from "@dclab/dc-react-ui-kit";

import Grid from "@material-ui/core/Grid";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

import { saveTemplate , updateTemplate , getItemDetails } from './Functions';

import { hideAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
const {
    add_com_template_success
} = messages_content;

const orgEnv = {
    prod: process.env.REACT_APP_BASEURL_PROD_API,
    dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
    window.location.host.includes("amazon") ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("shapla")
) {
    currentEnv = "dev";
} else {
    currentEnv = "prod";
}

const orgBaseUrl = orgEnv[currentEnv];


function AddTemplate(props) {

    const [success , setSuccess] = useState(false); 

    const style = {
        width: '100%',
    };

    const styles = {
        btn: {
            height: 66,
            width: "100%",
            margin: 8,
            border: "#D3D8DA",
        },
    };

    const MessageStyles = {
        border:'1px solid black',
        color: 'gray'
    };

    const [isTypeEmail, setIstypeemail] = React.useState( false );

    const [values, setValues] = React.useState({
        templateName: "",
        templateBody: "",
        templateType: "SMS", 
        emailSubject: '', 
        emailHeader: ''
    });

    const handleChange = (prop) => (event) => {
        setValues({
            ...values,
            [prop]: event.target.value,
        });

        if( prop =='templateType' && event.target.value =='EMAIL' ){
            setIstypeemail( true );
            setEditorClass('');
            setMessageSize( 500 );
        }else if(prop =='templateType' && event.target.value =='SMS'){
            setIstypeemail( false );
            setEditorClass('TextArea');
        }

        checkVerification();
    };

    

    
    const [editedItem , setEditItem ] = React.useState( false );
    const [ savetext , setSavetext ] = React.useState( 'ADD NOW');
    const [editorClass, setEditorClass] = React.useState('TextArea');
    const [messageSize , setMessageSize] = React.useState( 160)
    

    useEffect(() => {

        const templateId = props.match.params.id ; 
        let checked = true ;
        if( templateId ){
            setEditItem( true );
            settemplatePageTitle('Edit Template');
            setSavetext('SAVE');
            getItemDetails( templateId.toString() , 'templates').then(response =>{
                if( checked ){
                    if( response && response.data.body.data ){ 
                        
                        // console.log( response.data.body.data );

                        setButtonDisabled( '');
    
                        setValues( response.data.body.data );

                        if( response.data.body.data.templateType =='SMS'){
                            setIstypeemail( false );
                        }else{
                            setIstypeemail( true );
                            setEditorClass('');
                            setMessageSize( 500 );

                        }

                        setValue( [{ type: 'paragraph', children: [{ text: response.data.body.data.templateBody }] }]); 
                        
                    }
    
                }
            });
        }
        return () => checked = false  ;

        
    }, []);

    async function handleSave(e){
        //save the template data 
        let template = {};
        if( editedItem ){
            template = await updateTemplate( values , props.match.params.id.toString() );
        }else{
            template = await saveTemplate( values );
        }
        
        

        props.history.push('/communication/templates/success'  );
    }

    const object = [
        {
            value: "SMS",
            label: "SMS",
        },
        {
            value: "EMAIL",
            label: "Email",
        },

    ];

    const colData = [
        {value: "First name of recipient",  parameter: "{FirstName}",},
        {value: "Last name of recipient",  parameter: "{LastName}",}
    ]

    const [buttonDisabled , setButtonDisabled] = React.useState( 'disabled' );

    function checkVerification(){

        if( values.templateType == 'SMS'){
            if(  values.templateName.length>0 && values.templateBody.length>0 ){
                setButtonDisabled( '');
            }else{
                setButtonDisabled( 'disabled');
            }
        }else{
            if(  values.templateName.length>0 && values.templateBody.length>0 && values.emailSubject.length>0  ){
                setButtonDisabled( '');
            }else{
                setButtonDisabled( 'disabled');
            }
        }
        
    }

    const [templatePageTitle, settemplatePageTitle] = useState('Add Template');

    const [openModal , setOpenModal] = useState( false );

    const [value, setValue] = useState([{ type: 'paragraph', children: [{ text: values.templateBody }] }]);

    const onChange = (e) => {
        setValue(e);
        setValues({
            ...values,
            ["templateBody"]: e[0].children[0].text ,
        });

        checkVerification(); 
    };

    const handleClose = (e) =>{
        setOpenModal( false );
    }

    const To = [
        {
            value: "Search contacts/groups",
            label: "Search contacts/groups",
        },

    ];

    const rowTitle = [
        {
            value: "VALUE",
            parameter: "PARAMETER",
        },

    ];

    const Template = [
        {
            value: "Custom",
            label: "Custom",
        },

    ];

    const handleCopied = (e) => {
        console.log(e);
      };

    return (
        <MasterLayoutMiddle
            header={
                <div>
                    <div
                        onClick={() => props.history.replace("/communication/templates")}
                        className="backBtn"
                    >
                        <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                        <p style={{ alignSelf: "center" }}>Back</p>{" "}
                    </div>

                    {success && (
                        <div
                            style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                        >
                            <AlertAction
                            alert={add_com_template_success}
                            alertType={"success"}
                            onClick={() => setSuccess( false )}
                            />
                        </div>
                    )}
                </div>
            }
            isForm={true}
            formData={
                <div>

                    <div>
                        <h1 className="title_top">{templatePageTitle}</h1>
                        <div className="dasheddBorder"></div>
                    </div>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseSelect
                                    label="Template Type"
                                    value={values.templateType}
                                    onChange={handleChange("templateType")}
                                    object={object}
                                    style={styles.select}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Template Name"
                                    value={values.templateName}
                                    onChange={handleChange("templateName")}
                                    InputProps={false}
                                    phone={false}
                                    search={false}
                                    count={30}
                                    style={styles.btn}
                                    color="primary"
                                    onClick={() => console.log("onCLick")}
                                    onBlur={() => console.log("onBlur")}
                                    // disabled={true}
                                    //helperText="Helper text"
                                    multiline={true}
                                    rowsMax={4}
                                //type="search"
                                />
                            </div>
                        </Grid>

                        

                        {isTypeEmail && (

                            <Grid item xs={12}>
                                <div className="field_box">
                                
                                    <BaseTextField
                                        label="Email Subject"
                                        value={values.emailSubject}
                                        onChange={handleChange("emailSubject")}
                                        InputProps={false}
                                        phone={false}
                                        search={false}
                                        count={50}
                                        style={styles.btn}
                                        color="primary"
                                        onClick={() => console.log("onCLick")}
                                        onBlur={() => console.log("onBlur")}
                                        // disabled={true}
                                        //helperText="Helper text"
                                        multiline={true}
                                        rowsMax={4}
                                    //type="search"
                                    />
                                </div>
                            </Grid>
                        )}                     

                        

                        <Grid item xs={12}> 
                        <div className="field_box">
                        {/* <button className='AddParametersbutton' onClick={() =>setOpenModal(true) }>Add Parameters <AddCircleRoundedIcon /></button>                            */}
                            <div className={editorClass}>
                            
                                <TextEditor
                                    onChange={(e) => onChange(e)}
                                    disabled={false}
                                    value={value}
                                    style={styles}
                                    label={"Message"}
                                    maxCharCount={messageSize}
                                />
                            </div>
                            </div>                             
                        </Grid>

                        <TableModal
                            ModalTitle="Add parameters"
                            handleClose={() => handleClose()}
                            handleClose={handleClose} 
                            rowTitle={rowTitle}
                            colData={colData}
                            open={openModal}
                            LabeClose="CLOSE"
                            LabeConfirm="CONFIRM"
                            width="570px"
                            Description="Add personalized customer's name in your email. You can copy the parameter from below and paste it in your email, or you can type the parameter manually."
                            handleCopied={(e) => handleCopied(e)}
                        />

                        <Grid item xs={12}>

                            <div className="form_button">
                                <p
                                    className='cancel'
                                    onClick={() => props.history.push('/communication/templates')            }
                                >
                                    CANCEL
                                </p>
                                <BaseButton disabled={buttonDisabled} text={savetext} onClick={handleSave} />
                            </div>

                        </Grid>

                    </Grid>

                </div>
            }
            isFooter={true}
            footer={<FooterInner 
                    style={style}
                    termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
                    privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
               />}
            isWhiteBackground={true}
        />
    );
}

export default AddTemplate;
