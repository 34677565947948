import React from "react";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { makeStyles } from '@material-ui/core/styles'; 
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import Layout1 from "../../../assets/images/DK_Layout_1.png"
import Layout2 from "../../../assets/images/DK_Layout_2.png"
import Layout3 from "../../../assets/images/DK_Layout_3.png"
import Layout4 from "../../../assets/images/DK_Layout_4.png"
import Layout5 from "../../../assets/images/DK_Layout_5.png"

// const APP_URL = `http://localhost:3000`; // https://shapla.console.deenconnect.io

export default function CreateNewHomeScreenModal(props) {
  const {templateModalOpen, settemplateModalOpen, addHomeScreen, setaddHomeScreen, setnameModalOpen} = props;
  const classes = useStyles();

 //==================Img selecter ======================//
 const [alignment, setAlignment] = React.useState();
 const [template, settemplate] = React.useState({})

 const handleAlignment = (event, newAlignment) => {
   setAlignment(newAlignment);
 };

 const TemplateData = [
  { Img: Layout1, Label: "Template One", value:"1" },
  { Img: Layout2, Label: "Template Two", value:"2" },
  { Img: Layout3, Label: "Template Three", value:"3" },
  { Img: Layout4, Label: "Template Four", value:"4" },
  { Img: Layout5, Label: "Template Five", value:"5" },
];

 //==================Img selecter ======================//

 const editSubmit = () => {
  settemplateModalOpen(false)
  template?.name && setaddHomeScreen({ ...addHomeScreen, template})
  props.setchanges(true)
 }

  return (
    <div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={templateModalOpen}
        onClose={() => settemplateModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={templateModalOpen}>
          <div className={classes.MediaModalContainer}>

            <div className={classes.Header}>
              <h1 className={classes.Title}>Choose template</h1>
              <div className="ModalCloseIcon"><CloseRoundedIcon onClick={() => settemplateModalOpen(false)} /></div>
            </div>


            <div className={classes.PackageCardContainer}>
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                >

                  {TemplateData.map((Temp) => (   
                  <ToggleButton value={Temp.value} className={classes.CardContainer} onClick={() => props.edit ? settemplate({ imageUrl: "", name: Temp.Label }) : setaddHomeScreen({ ...addHomeScreen, template: { imageUrl: "", name: Temp.Label }})}>

                    <img className="MedialibImg" src={Temp.Img} alt="media-lib" />
                    <div className={classes.CardTitleBox}>
                      <h2 className={classes.CardTitle}>{Temp.Label}</h2>
                      <div className="Packagebadge"><CheckRoundedIcon className="BadgeIcon" /></div>
                    </div>
                  </ToggleButton>
                  ))}

                </ToggleButtonGroup>
              </div>

            <div className={classes.ModalFooter}>
              <button className="ButtonCancel" type="button" onClick={() => {
                  settemplateModalOpen(false)
                  setnameModalOpen(true)
              }}> BACK</button>
              <button disabled={addHomeScreen?.template?.name?.length ? false : true} className={`ButtonContinue ${addHomeScreen?.template?.name?.length  ? "" : "disableContinue"}`} type="button" onClick={() => props.edit ? editSubmit() : props.submitForm()}>{props.edit ? "CHANGE TEMPLATE" : "CREATE HOMEPAGE"} </button>
            </div>

          </div>
        </Fade>
      </Modal>
    </div>
  );
}



const useStyles = makeStyles((theme) => ({

  MediaModalContainer: {
    background: "#fff",
    maxWidth: "1030px",
    width: "100%",
    height: "750px",
    position: "relative",
    top: "80px",
    borderRadius: "4px",
    margin:" 0 auto",
  },

  CardContainer: {
    width: "224px",
    height: "182px",
    display: "flex",
    borderRadius: "0px",
    padding: "16px",
    overflow: "hidden",
    margin: "9px !important",
    borderRadius: "4px",
    alignItems: "baseline",
    float:"left",
  },

  Header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "17px 30px",
    background: "#F7F8FA",
    borderRadius: "4px 4px 0 0",
  },

  Title: {
    color: "#5a5a5a",
    fontSize: "24px",
    fontFamily: "Poppins",
    fontWeight: "300",
    margin: "0",
  },

  ModalFooter: {
    background: "#fff",
    boxShadow: "0px -1px 4px #0000000F",
    padding: "17px 0px",
    position: "absolute",
    bottom: "0",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderRadius:"0 0 4px 4px",

    "& .ButtonCancel": {
      color: "#818E94",
      backgroundColor: "transparent",
      borderRadius: "100px",
      margin: " 0 30px",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "400",
      height: "40px",
      outline: "none",
      border: "none",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "uppercase",
      letterSpacing: "1.25px",
    },

    "& .ButtonContinue": {
      color: "#fff",
      backgroundColor: "#2a82f6",
      borderRadius: "100px",
      padding: " 0 30px",
      margin: " 0 30px",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "400",
      height: "40px",
      outline: "none",
      border: "none",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "uppercase",
      letterSpacing: "1.25px",
    },

    "& .disableContinue": {
      color: "#fff",
      backgroundColor: "darkGray",
      cursor: "default",
    },
  },

  PackageCardContainer: {
    margin: "0px",
    padding:"30px",

    "& .MuiToggleButtonGroup-root": {
      display: "block",
      borderRadius: "0px",
    },

    "& .MuiToggleButton-root": {
      border: "#E4E8EC 1px solid",
    },

    "& .Packagebadge": {
      backgroundColor: "transparent",
      borderRadius: "100px",
      width: "12px",
      height: "12px",
      padding: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "999",
      marginRight:"16px",
    },

    "& .BadgeIcon": {
      fontSize: "14px",
      color: "#f5f7f9",
      display: "none",
    },

    "& .MedialibImg": {
      width: "100%",
      height: "100% !important",
    },

    "& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
      marginLeft: "2px",
    },

    "& .MuiToggleButton-root.Mui-selected + .MuiToggleButton-root.Mui-selected": {
      marginLeft: "2px",
      borderLeft: "#2A82F6 1px solid",
    },




    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: "transparent",
      border: "#2A82F6 1px solid",

      "& .Packagebadge": {
        backgroundColor: "#2A82F6",
      },

      "& .BadgeIcon": {
        color: "#fff",
        display: "block",
      },

      "& .MedialibImg": {
        opacity: 0.6,
      },


    },

  },

  CardTitleBox:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    borderTop:"#E8E9E9 1px solid",
    padding:"11px 0px",
    marginTop:"6px",
    position:" absolute",
    left:"0",
    bottom:"0",
    width:"100%",
  },

  CardTitle:{
      textTransform:"initial",
      fontSize: "12px",
      fontWeight: "600",
      color:"#4D4D4D",
      margin:"0 16px",

  }




}), {index: 1});