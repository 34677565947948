import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  BaseTextField,
  BaseButton,
  AlertAction,
  BaseFileSelect,
  DrawerMain,
  BaseImgChip, FormSwitch, MasterLayoutMiddle, FooterInner, TextEditor
} from '@dclab/dc-react-ui-kit';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactCrop from 'react-image-crop';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Avatar from '@material-ui/core/Avatar';
import 'react-image-crop/dist/ReactCrop.css';
import Grid from '@material-ui/core/Grid';
import { getService, UpdateService } from './api';
import {
  image64toCanvasRef,
  extractImageFileExtensionFromBase64,
  base64StringtoFile,
} from '../../utils/crop';
import {
  addServiceAction,
  emptyServiceAction,
} from '../../redux/actions/servicesAction';
import './style.css';
import '../../App.css';
import '../style.css';
import { showAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
import MediaUpload from '../../components/MediaUpload/MediaUpload';
const {
  token_expired,
  success_message_edit_services
} = messages_content;
class EditService extends Component {
  constructor(props) {
    super(props);
    this.imagePreviewCanvasRef = React.createRef();
    this.state = {
      id: '',
      serviceName: '',
      serviceDescription: [{ type: 'paragraph', children: [{ text: '' }] }],
      featured: false,
      descriptionCount: 0,
      showAlert: false,
      success: false,
      error: false,
      openDrawer: false,
      serviceImage: [],
      image: [],
      loading: false,
      serviceNameCount: 0,
      seLoading: true,
      showDrag: true,
      crop: {
        aspect: 1 / 1,
      },
    };
    this.onDescriptionStateChange = this.onDescriptionStateChange.bind(this);
    this.editService = this.editService.bind(this);
    this.upload = this.upload.bind(this);
    this.addLogo = this.addLogo.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleOnCrop = this.handleOnCrop.bind(this);
    this.handleOnCropCompleted = this.handleOnCropCompleted.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }
  async componentDidMount() {
    const pathName = this.props.history.location.pathname;
    const id = pathName.slice(pathName.lastIndexOf('/') + 1);
    const token = localStorage.getItem('authToken');
    try {
      const res = await getService(id, token);
      const {
        description,
        imageMetaData,
        image,
        name,
        featured,
        _id,

      } = res.data.body.data;
      let descSum = 0;
      JSON.parse(description).map((st) => {
        if (st.type === 'bulleted-list' || st.type === 'numbered-list') {
          st.children.map((e) => {
            e.children.map((ch) => {
              descSum += ch.text.length;
            });
          });
        } else {
          st.children.map((e) => {
            descSum += e.text.length;
          });
        }
      });
      this.setState({
        serviceName: name,
        serviceNameCount: name.length,
        serviceDescription: JSON.parse(description),
        featured,
        descriptionCount: descSum,
        serviceImage: [{ label: imageMetaData.imageName, name: imageMetaData.imageName, src: imageMetaData.imageUrl, size: imageMetaData.imageSize }],
        image: [{ name: imageMetaData.imageName, src: imageMetaData.imageUrl ? imageMetaData.imageUrl : '', size: imageMetaData.imageSize }],
        id: _id,
        seLoading: false,
      });
    } catch (error) {
      this.setState({ error, seLoading: false });
    }
  }
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  changeHandler = (name, val) => {
    const { serviceNameCount } = this.state;
    if (name === 'name') {
      if (serviceNameCount < 30) {
        this.setState({ serviceName: val, serviceNameCount: val.length });
      } else {
        this.setState({ serviceNameCount: val.length });
      }
    }
  };
  handleDeleteChip = () => {
    this.setState({
      serviceImage: [],
      image: [],
    });
  };
  upload = async (files) => {
    if (files.length > 0) {
      this.setState({
        image: files,
        showDrag: files.length === 0 ? true : false,
        serviceImage: files,
      });
    }
  };
  selectedMedia = (obj) => {
    this.setState({serviceImage: [
      {
        label: obj.title,
        name: obj.title,
        src: obj.src,
        size: 0,
      },
    ]});
  }
  addLogo = async () => {
    const { image } = this.state;
    if (image.length > 0) {
      const canvasRef = this.imagePreviewCanvasRef.current;
      const imgSrc = image[0].src;
      const type = imgSrc.slice(0, 5);
      let fileExtension;
      if (type === 'data:') {
        fileExtension = extractImageFileExtensionFromBase64(imgSrc);
      }
      const imageDatato64 = canvasRef.toDataURL('image/' + fileExtension);
      const myFileName = image[0].name + fileExtension;
      if (imageDatato64.split(',')[1] !== '') {
        const newCroppedFile = base64StringtoFile(imageDatato64, myFileName);
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            this.setState({
              serviceImage: [
                {
                  preview: URL.createObjectURL(newCroppedFile),
                  src: reader.result,
                  name: image[0].name.slice(0, 20),
                  label: image[0].name.slice(0, 20),
                  size: image[0].size,
                },
              ],
              image: [
                {
                  preview: URL.createObjectURL(newCroppedFile),
                  src: reader.result,
                  name: image[0].name.slice(0, 20),
                  label: image[0].name.slice(0, 20),
                  size: image[0].size,
                },
              ],
              openDrawer: false,
              showDrag: image.length > 0 && false,
            });
          },
          false
        );
        reader.readAsDataURL(newCroppedFile);
      } else {
        this.setState({
          serviceImage: [
            {
              label: image[0].name.slice(0, 20),
              name: image[0].name.slice(0, 20),
              src: image[0].src,
              size: image[0].size,
            },
          ],
          image: [
            {
              label: image[0].name.slice(0, 20),
              name: image[0].name.slice(0, 20),
              src: image[0].src,
              size: image[0].size,
            },
          ],
          openDrawer: false,
          showDrag: image.length > 0 && false,
        });
      }
    } else {
      this.setState({ openDrawer: false });
    }
  };
  handleOnCrop = (crop) => {
    this.setState({ crop });
    this.setState({ cropped: true });
  };
  handleOnCropCompleted = (crop, pixelCrop) => {
    const { image } = this.state;
    const canvasRef = this.imagePreviewCanvasRef.current;
    const imgSrc = image[0].src;
    image64toCanvasRef(canvasRef, imgSrc, crop);
  };
  onDescriptionStateChange = (editorState) => {
    let sum = 0;
    editorState.map((st) => {
      if (st.type === 'bulleted-list' || st.type === 'numbered-list') {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    this.setState({
      serviceDescription: editorState,
      descriptionCount: sum,
    });
  };
  editService = async () => {
    const token = localStorage.getItem('authToken');
    const {
      serviceName,
      serviceDescription,
      featured,
      serviceImage,
      id,
      descriptionCount,
    } = this.state;
    this.setState({ loading: true });
    if (!serviceName || serviceImage.length === 0 || descriptionCount === 0) {
      this.setState({ error: true, loading: false });
    } else {
      let obj;
      if (
        serviceImage.length > 0 
      ) {
        obj = {
          name: serviceName,
          description: JSON.stringify(serviceDescription),
          featured,
          imageMetaData: {
            imageUrl: serviceImage.length > 0 ? serviceImage[0].src : [],
            imageThumbnail: serviceImage.length > 0 ? serviceImage[0].src : [],
            imageName: serviceImage.length > 0 ? serviceImage[0].name : '',
            imageSize: serviceImage.length > 0 ? serviceImage[0].size : 0,
          }
        };
      }else{
        obj = {
          name: serviceName,
          description: JSON.stringify(serviceDescription),
          featured,
         
        };
      }
      try {
        await UpdateService(obj, token, id);
        this.setState({
          success: true,
          showAlert: true,
          serviceName: '',
          serviceImage: [],
          featured: false,
          loading: false,
        });
        this.props.showAlert(success_message_edit_services)
        this.props.history.push('/organization/services');
      } catch (error) {
        this.setState({
          error: error && error.response && error.response.data.message,
          showAlert: true,
          loading: false,
        });
        this.hideAlert();
        setTimeout(() => {
          this.setState({ error: false })
        }, 8000);
      }
    }
  };
  handleRemove = () => {
    this.setState({ image: [], showDrag: true, serviceImage: [] });
  };
  render() {
    const {
      serviceName,
      serviceDescription,
      featured,
      descriptionCount,
      showAlert,
      success,
      error,
      openDrawer,
      image,
      serviceImage,
      loading,
      seLoading,
      showDrag,
      crop,
    } = this.state;
    function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    const style = {
      width: '100%',
    };
    return (
      <>
        <MasterLayoutMiddle
          header={
            <div>
              <div
                onClick={() =>
                  this.props.history.push('/organization/services')
                }
                className='backBtn' >
                <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
                <p style={{ alignSelf: 'center' }}>Back</p>{' '}
              </div>
              {error && error === 'Invalid token' && (
                <div
                  style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                >
                  <AlertAction
                    alert={token_expired}
                    alertType={'error'}
                    onClick={() => this.setState({ error: false })}
                  />
                </div>
              )}
              {showAlert && error && (
                <div
                  style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                >
                  <AlertAction
                    alert={
                      error === 'Invalid token'
                        ? token_expired
                        : error
                    }
                    alertType={'error'}
                    onClick={() => this.setState({ showAlert: false })}
                  />
                </div>
              )}
              {showAlert && success && (
                <div
                  style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                >
                  <AlertAction
                    alert={success_message_edit_services}
                    alertType={'success'}
                    onClick={() => this.setState({ showAlert: false })}
                  />
                </div>
              )}
            </div>
          }
          isForm={true}
          formData={
            seLoading ?
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress color='primary' />
              </div>
              :
              <div>
                <div>
                  <h1 className='title_top'>Edit Service</h1>
                  <div className="dasheddBorder"></div>
                </div>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Service Details</h2>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box services_helpertext">
                      <BaseTextField
                        label='Service Name'
                        style={{ width: 570 }}
                        value={serviceName}
                        onChange={(e) => this.changeHandler('name', e.target.value)}
                        error={!success && error && !serviceName}
                        helperText={
                          !success &&
                          error &&
                          !serviceName &&
                          'Please enter service name'
                        }
                        placeholder=' '
                        count={30}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                  <div className="field_box services_helpertext">
                    <TextEditor
                      onChange={(e) => this.onDescriptionStateChange(e)}
                      disabled={false}
                      value={serviceDescription}
                      label={"Description"}
                      helperText={
                        error &&
                        !descriptionCount &&
                        "Please add service description"
                      }
                      error={error && !descriptionCount}
                      maxCharCount={1000}
                    />
                    </div>
                  </Grid>
                  {console.log("state is: ", this.state)}
                  <Grid item xs={12}>
                    <div className="field_box" >
                    <MediaUpload
                      addMediaItem = {this.selectedMedia}
                      label = "Service Image"
                      buttonName = "ADD IMAGE"
                      typeOfMedia='/images'
                      selectedMedia={{ logoUrl: this.state?.serviceImage[0]?.src ?? "", logoName: this.state?.serviceImage[0]?.name ?? "" }}
                     />
                    </div>
                    {/* <div className="field_box"
                      onClick={() =>
                        this.setState({
                          openDrawer: true,
                          showDrag:
                            (image.length > 0 || serviceImage.length > 0) &&
                              image[0].src.slice(0, 5) === 'data:'
                              ? false
                              : true,
                        })
                      }
                    >
                      <div>
                        <div
                          className={
                            error && serviceImage.length === 0
                              ? 'img-chip error-chip'
                              : 'img-chip'
                          }
                        >
                          <BaseImgChip
                            chipData={serviceImage}
                            label='Service Image'
                            onInputClick={() =>
                              this.setState({
                                openDrawer: true,
                                showDrag:
                                  (image.length > 0 || serviceImage.length > 0) &&
                                    image[0].src.slice(0, 5) === 'data:'
                                    ? false
                                    : true,
                              })
                            }
                            onChipInputClick={() => {
                              this.setState({
                                openDrawer: true,
                                showDrag:
                                  (image.length > 0 || serviceImage.length > 0) &&
                                    image[0].src.slice(0, 5) === 'data:'
                                    ? false
                                    : true,
                              });
                            }}
                            helperText=''
                            textStyle={{ width: '570px' }}
                            deleteChip={() =>
                              this.setState({ serviceImage: [], image: [] })
                            }
                          />
                        </div>
                        <div style={{ marginTop: -1 }}>
                          <span style={{ color: '#f44336', fontSize: '0.75rem' }}>
                            {error &&
                              serviceImage.length === 0 &&
                              'Please provide service image'}
                          </span>
                        </div>
                      </div>
                    </div> */}
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box border_top_bottm">
                      <FormSwitch
                        headline='Service Status'
                        body=" Enabling this would make the service available for
                      publishing from application"
                        values={featured}
                        handleSwitchChange={(e) => this.setState({ featured: !featured })}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    {loading ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <CircularProgress color='primary' />
                      </div>
                    ) : (
                        <>
                          <div className="form_button">
                            <p
                              className='cancel'
                              onClick={() =>
                                this.props.history.push('/organization/services')
                              }
                            >
                              CANCEL
                            </p>
                            <BaseButton
                              text='SAVE'
                              onClick={() => this.editService()}
                            />
                          </div>
                        </>
                      )}
                  </Grid>
                </Grid>
              </div>
          }
          isFooter={true}
          footer={
            <FooterInner 
              style={style} 
              termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
              privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
            />
          }
          isWhiteBackground={true}
        />
        <DrawerMain
          open={openDrawer}
          openHandler={() => this.setState({ openDrawer: false })}
          title='Select Image'
          onCancel={() => this.setState({ openDrawer: false })}
          onSave={() => this.addLogo()}
        >
          <List className='img-details' style={{ paddingLeft: 0 }}>
            {image.map((file, index) => (
              <ListItem key={file.name} style={{ paddingLeft: 0 }}>
                <ListItemAvatar>
                  <Avatar
                    variant='square'
                    alt={'preview'}
                    src={
                      image.length > 0
                        ? image[0].preview || image[0].src
                        : file.preview
                    }
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={file.name.slice(0, 20)}
                  secondary={formatBytes(file.size)}
                />
                <ListItemSecondaryAction style={{ paddingLeft: 20 }}>
                  <IconButton
                    aria-label='delete'
                    size='medium'
                    style={{ padding: '0px' }}
                    onClick={() => this.handleRemove(index)}
                  >
                    <DeleteForeverIcon fontSize='inherit' />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {showDrag && (
            <>
              <div style={{ marginBottom: 20 }} className='img-uploader'>
                <BaseFileSelect selectedFiles={(file) => this.upload(file)} />
              </div>
            </>
          )}
          {image.length > 0 && image[0].src.slice(0, 5) !== 'https' && (
            <ReactCrop
              src={image.length > 0 && image[0].src}
              crop={crop}
              onChange={this.handleOnCrop}
              onComplete={this.handleOnCropCompleted}
            />
          )}
          {(image.length > 0 || serviceImage.length > 0) && (
            <p style={{ color: '#818E94', fontSize: 12 }}>
              You can also crop the uploaded images
            </p>
          )}
          <br />
          <div style={{ display: 'none' }}>
            <canvas ref={this.imagePreviewCanvasRef}></canvas>
          </div>
        </DrawerMain>
        <div className='add-service-separator'></div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    services: state.services.editableService,
    edit: state.services.edit,
  };
};
const mapDispatchToProps = (dispatch) => ({
  addServiceHandler: (res) => dispatch(addServiceAction(res)),
  emptyServiceHandler: () => dispatch(emptyServiceAction()),
  showAlert: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditService);
