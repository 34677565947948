import React, { useEffect, useState } from "react";
import { TextInput, TextInputFixedLabel } from "master-react-lib";
import { BaseButton } from "@dclab/dc-react-ui-kit";
import QDCLogo from './QDCLogo.png'
import DCLogo from './dc_logo.png'
import {getSubscriptionDetail, unSubscribeFromNewsLetter} from './api';

import './style.css'

function UnsubscribeEnterEmail(props) {
    const [subscriptionDetail, setSubscriptionDetail] = useState({});
    const [hiddenEmail, sethiddenEmail] = useState('');
    const [enterEmail, setEnterEmail] = useState('')
    const [error, setError] = useState(false);
    const [value, setvalue] = useState('');
    const emailRegex = /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/;

    const getData = async() => {
        if(props.match.params.id) {
            const subscriptionDetail = await getSubscriptionDetail(props.match.params.id);
            if(subscriptionDetail.data._id){
                setSubscriptionDetail(subscriptionDetail.data);
                const email = subscriptionDetail.data.email.split("@");
                let encryptedEmail = email[0];
                let encryptedEmailLength = encryptedEmail.length;
                encryptedEmail = encryptedEmail[0] + '*'.repeat(encryptedEmailLength - 2) + encryptedEmail[encryptedEmailLength - 1] + '@' + email[1];
                sethiddenEmail(encryptedEmail);

            }


        }

    }

    useEffect(() => {
        getData();
    }, []);

    const click = async () => {
        if(enterEmail === subscriptionDetail?.email) {
            unSubscribeFromNewsLetter(subscriptionDetail?._id, 'Website')
            .then(res => {
                props.history.push({pathname: '/unsubscribe-confirmation', state: {id: subscriptionDetail?._id, orgDetail: {... subscriptionDetail?.orgId}}})
            })
            .catch(err => {
                console.log("An Erro Occured: ", err);
            })
        } else {
            setError(true);
        }
      };


    return (

        <div className="SubsFormContainer">
            <div className="QDCLogo"><img src={subscriptionDetail?.orgId?.orgLogo?.logoUrl} /></div>

            <div className="SubsFormBody">
                <h1 className="SubsFormTitle">Unsubscribe from this list.</h1>
                <h3>Email: {hiddenEmail}</h3>
                <p>We're sorry to see you go!<br/> You can unsubscribe from future emails like this by entering your email address and clicking the “Unsubscribe from this list” button. Please allow 5 days for processing.</p>

                <div className="SubsFormField">
                <TextInputFixedLabel 
                    //label="Field label"
                    labelStatus={true}
                    //helpertext="Helper text"
                    HelpertextStatus={true}
                    placeholder="Placeholder"
                    Errormessage="Error message"
                    errortextStatus={false}
                    count={100}
                    //countStatus={true}
                    //tooltipStatus={true}
                    onChange={(e) => setEnterEmail(e.target.value)}
                    value={enterEmail}
                />
                </div>

                <div className="SubsFormButton">
                    <BaseButton onClick={click} disabled = {!emailRegex.test(enterEmail)} text="UNSUBSCRIBE FROM THIS LIST"/>
                </div>

            </div>
            <div className="QDCFooterLogo"><span>POWERED BY</span> <a href={process.env.REACT_APP_Environment === 'dev' ? 'https://shapla.deenconnect.io' : 'https://deenconnect.io' } target="blank"><img src={DCLogo} /></a></div>
        </div>
    );
}

export default UnsubscribeEnterEmail;

