import * as constants from '../constants'

const INITIAL_STATE = {
    services:[],
    editableService:[],
    edit:false
  };
  
  export default (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
      case constants.ADD_SERVICE:
        return {
          ...state,
         services:[...state.services,action.payload]
        };
        case constants.EDIT_SERVICE:
        return {
          ...state,
         editableService:action.payload,
         edit:true
        };
        case constants.EMPTY_SERVICE:
            return {
              ...state,
             editableService:[],
             edit:false
            };
      default:
        return state;
    }
  };
  