import React, { useEffect } from "react";
import { AlertAction, AlertDialogue, EmptyTable, GridContainer, GridItem, SimpleTable } from "@dclab/dc-react-ui-kit";
import Moment from "moment";
import Handle from "../../../assets/img/more_vert-24px.svg";
import { GetServicesWebsiteFeatured, EditServicesWebsite, sortServicesWebsite, getServicesWithAppId, updateServicesWithAppId } from '../../../pages/Website/api';
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { hideAlert, showAlert } from "../../../redux/actions/alertActions";
import messages_content from '../../../utils/messages.json';
const {
  token_expired,
  service_unfeatured_successfully
} = messages_content;
function Services(props) {
  const [DataTable, setDataTable] = React.useState([]);
  const [Loader, setLoader] = React.useState(true);
  const [specificRow, setSpecificRow] = React.useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [error, seterror] = React.useState(false)
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  useEffect(() => {
    getServicesFeatured()
  }, [])
  async function getServicesFeatured() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    try {
      const resService = await getServicesWithAppId(token, orgId, 'deenweb')
      const data = resService.data.body.data;
      const featuredServices = data.filter(text => text.featured)
      const sort = featuredServices.sort((a, b) => {
        return a.service.order - b.service.order
      })
      setData(sort)
      let dataTable = [];
      sort.map((text, index) => {
        const dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("h:mm A") + ' (EST)'];
        const dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("h:mm A") + ' (EST)'];
        const UnpublishDate = new Date(dateEnd).getFullYear()
        console.log(UnpublishDate, 'unpublish')
        let array = [
          { type: "id", value: `${index <= 8 ? '0' + (index + 1) : index + 1}`, _id: text._id, serviceId: text.service._id },
          {
            type: "url",
            name: "single_pic",
            src: text.service.imageMetaData ? text.service.imageMetaData.imageUrl : '',
          },
          {
            type: "string",
            name: "name",
            value: text.service.name,
          },
          {
            type: "string",
            name: "vertical",
            value: dateStart,
          },
          {
            type: "string",
            name: UnpublishDate === 2099 ? "frequency" : "vertical",
            value: UnpublishDate === 2099 ? "Never" : dateEnd
          },
          { type: 'action', status: 'details', value: 'REMOVE' },
        ]
        dataTable.push(array)
      })
      setDataTable(dataTable);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { id: "drag", label: " ", width: 'auto' },
    { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
    { type: "link", name: "invoice_number", label: "Image", width: 'auto' },
    { type: "string", name: "name", label: "Service Name", width: '60%' },
    { type: "string", name: "date/time", label: "Publish Date", width: '15%' },
    {
      type: "string",
      name: "date/time",
      label: "UnPublish Date",
      width: '15%',
    },
    { type: "action", name: "action", label: "ACTION", width: '10%' },
  ];
  const DeleteAlertFunc = (e) => {
    setSpecificRow(e);
    setOpenAlert(true);
  }
  const handleCancel = () => {
    setOpenAlert(false);
  }
  const handleClose = () => {
    setOpenAlert(false);
  };
  const RemoveRow = async (e) => {
    setLoader(true);
    setOpenAlert(false);
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    const updateOnOrg = {
      service: data[0].service._id,
      featured: false,
      publishFrom: data[0].publishFrom,  // DATE OBJECT
      publishTo: data[0].publishTo,
      status: data[0].status,
    }
    try {
      await updateServicesWithAppId(token, orgId, 'deenweb', data[0].service._id, updateOnOrg)
      props.alertShow(service_unfeatured_successfully)
      const stateNews = DataTable
      const NewsIndex = DataTable.indexOf(e)
      stateNews.splice(NewsIndex, 1)
      setLoader(false);
    } catch (err) {
      setLoader(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const style = {
    width: 470,
    marginLeft: 800
  };
  const sortData = async (data) => {
    props.setCheckChanges(true)
    const token = localStorage.getItem("authToken");
    let OBJ = {
      sortedList: data.map(text => {
        return (
          { _id: text.service._id, order: text.order }
        )
      })
    }
    try {
      await sortServicesWebsite(token, OBJ)
    } catch (err) {
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
    const sortData = data.sort((a, b) => a.order - b.order)
    let arr = [];
    sortData.map((text, index) => {
      const dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("h:mm A") + ' (EST)'];
      const dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("h:mm A") + ' (EST)'];
      const UnpublishDate = new Date(dateEnd).getFullYear()
      let array = [
        { type: "id", value: `${index <= 8 ? '0' + (index + 1) : index + 1}`, _id: text._id, serviceId: text.service._id },
        {
          type: "url",
          name: "single_pic",
          src: text.service.imageMetaData ? text.service.imageMetaData.imageUrl : '',
        },
        {
          type: "string",
          name: "name",
          value: text.service.name,
        },
        {
          type: "string",
          name: "vertical",
          value: dateStart,
        },
        {
          type: "string",
          name: UnpublishDate === 2099 ? "Frequency" : "vertical",
          value: UnpublishDate === 2099 ? "Never" : dateEnd
        },
        { type: 'action', status: 'details', value: 'REMOVE' },
      ]
      arr.push(array)
    })
    setDataTable(arr);
  }
  return (
    <GridContainer containerClass="web-announcements ">
      {
        Loader ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          DataTable.length > 0 ?
            <>
              {
                error && error === "Invalid token" && (
                  <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '12px' }}>
                    <AlertAction
                      alert={token_expired}
                      alertType={"error"}
                      onClick={() => seterror(false)}
                    />
                  </div>
                )
              }
              <GridItem xs={12} itemClass="web-salah">
                <SimpleTable
                  showHeader={true}
                  title="Featured Services"
                  parentTableDrag={true}
                  DragIcon={Handle}
                  rows={DataTable}
                  columns={col}
                  colSpan={16}
                  actionSendButton={true}
                  classProps={changeClassProps()}
                  actionLink={(e) => DeleteAlertFunc(e)}
                  donationsCount={DataTable?.length ? 1 : 0}
                  noTitle="No Service added"
                  noPara="Added Services will be displayed and managed here"
                  noBtnText="Add New Services"
                  onInviteClick={() =>
                    props.history.push("/website/homepage/add-new-service")
                  }
                  showIconCol={false}
                  showFooter={false}
                  showAdd={true}
                  addText="ADD"
                  onAddClick={() =>
                    props.history.push("/website/homepage/add-new-service")
                  }
                  iconTextSize={16}
                  sortData={(e) => sortData(e)}
                  sortColumnIndex={[4]}
                  data={data}
                />
                <div style={{ display: "flex", marginTop: 20 }}>
                  <p className="cat-lower" style={{ marginTop: 0 }}>
                    Change the order of Services to display to users. Click on{" "}
                  </p>
                  <img src={Handle} alt="" style={{ display: "flex", marginTop: -10 }} />
                  <p className="cat-lower" style={{ marginTop: 0 }}> to drag and drop within the table.</p>
                </div>
              </GridItem>
            </>
            :
            <EmptyTable
              title="Featured Services"
              onAddClick={() => props.history.push("/website/homepage/add-new-service")}
              addLabel="Add"
              noBtnText="Add"
            />
      }
      <div>
        <AlertDialogue
          title="Remove Service?"
          description="Are you sure you want to remove this service?"
          description2="It will be removed from homepage only."
          cancelText="Cancel"
          confirmText="Confirm"
          open={openAlert}
          handleCancel={() => handleCancel()}
          handleClose={() => handleClose()}
          handleConfirm={() => RemoveRow(specificRow)}
          styles={style}
        />
      </div>
    </GridContainer>
  );
}
const mapStateToProps = (state) => ({
  alert: state.addOrg.alert,
});
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
  alertHide: (res) => dispatch(hideAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Services);
