import React, { Component } from "react";
import {
  SimpleTable,
  BasePaper,
  CardData,
  BaseTabs,
  AlertAction,
  GridContainer,
  GridItem,
  MasterLayoutMiddle,
  FooterInner
} from "@dclab/dc-react-ui-kit";
import Edit from "../../../assets/img/create-24px.svg";
import Delete from "../../../assets/img/delete_forever-24px.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { setAllDonors } from "../../../redux/actions/donorsActionCreators";
import { getDonors, getDonorsCardData } from "../../Donations/api";
import { stateAbbreviations } from '../../../utils/statesAbbreviations';
import "../../../App.css";
import '../style.css';
import moment from 'moment';
import messages_content from '../../../utils/messages.json';
import { getInviteDonors, deleteInviteDonor } from '../apis';
import { hideAlert } from "../../../redux/actions/alertActions";
const {
  token_expired
} = messages_content;
class ManageDonors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      activeTab: 0,
      donors: props.donors,
      donorsCardData: {},
      inviteDonorList: [],
      error: "",
      loading: true,
      invitedDonorDeleted: false,
    };
    this.handleTabChange = this.handleTabChange.bind(this);
    this.actionLink = this.actionLink.bind(this);
  }
  async componentDidMount() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const res = await getDonors(orgId, token);
      const res2 = await getDonorsCardData(orgId, "", token);
      const inviteDonors = await getInviteDonors(orgId, token);
      const groupByOutput = res?.data?.body?.data?.reduce((accumulator, cur) => {
        let date = cur.phoneNo;
        let found = accumulator.find(elem => elem.phoneNo === date)
        if (found) found.amount += cur.amount;
        else accumulator.push(cur);
        return accumulator;
      }, []);

      this.props.setDonors(res.data.body.data);
      this.setState({
        donors: res.data.body.data,
        donorsCardData: res2.data.body.data,
        inviteDonorList: inviteDonors.data.body.data,
        tabIndex: this.props?.alertValue === "Donor Invited" ? 1 : 0,
        activeTab: this.props?.alertValue === "Donor Invited" ? 1 : 0,
        loading: false,
        groupByOutput: groupByOutput,
      });
    } catch (error) {
      this.setState({
        error: error && error.response && error.response.data.message,
        loading: false,
      });
    }
    // setTimeout(() => {
    //   this.props.hideAlert();
    // }, 8000);
  }
  capitalizeFLetter = (val) => {
    return val ? `${val[0].toUpperCase() + val.slice(1)}` : "";
  }

  editInviteDonot = (donor) => {
    if (donor[0]?.label?.length) {
      this.props.history.push(`/deenfund/edit-invite-donor/${donor[0].label}`);
    }
  }

  deleteInviteDonor = async (donor) => {
    this.setState({ loading: true });
    const donorId = donor[0]?.label;

    if (donorId.length) {
      const token = localStorage.getItem("authToken");
      await deleteInviteDonor(donorId, token)
        .then(res => {
          const { inviteDonorList } = this.state;
          const updatedInviteDonors = inviteDonorList.filter(donor => donor._id !== donorId);
          this.setState({
            inviteDonorList: updatedInviteDonors,
            invitedDonorDeleted: true,
            loading: false
          });
          setTimeout(() => {
            this.setState({ invitedDonorDeleted: false });
            this.props.hideAlert();
          }, 8000);

        })
        .catch(err => {
          this.setState({ loading: false })
          console.log("Error ", err);
        });


    }
  }

  actionLink = (data) => {
    console.log(data[0].donorDetails, this.state.donors)
    // this.props.history.push({pathname: "/donors-details", state: { donorDetails: data[0].donorDetails, donors: this.state.donors } });
  };
  actionLink2 = (data) => {
    const { donors } = this.state;
    const d = donors?.length ? donors.filter((el) => el.phoneNo === data[0].donorDetails?.phoneNo) : [];
    // console.log(data[0].donorDetails, d)

    if (d.length > 1) {
      let amount = 0;
      let amountToBeMinus = d.map((donor, index) => {
        if (index === 0) return;
        amount += donor.amount;
      });
      d[0].amount = d[0].amount - amount;
    }

    this.props.history.push({ pathname: "/deenfund/donors-details", state: { donorDetails: data[0].donorDetails, donors: d } });
  };
  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };
  render() {
    let freqRows = [];
    let byCategoryRows = [];
    this.onClick = (x, y) => {
      this.setState({
        tabIndex: y,
      });
    };
    const { donors, tabIndex, inviteDonorList, groupByOutput } = this.state;
    console.log("byCategoryRows", byCategoryRows)
    if (Array.isArray(groupByOutput)) {

      // const groupByOutput = donors.reduce((accumulator, cur) => {
      //   let date = cur.phoneNo;
      //   let found = accumulator.find(elem => elem.phoneNo === date)
      //   if (found) found.amount += cur.amount;
      //   else accumulator.push(cur);
      //   return accumulator;
      // }, []);


      byCategoryRows = groupByOutput.map((freqmonthly, i) => {
        const nameArr = freqmonthly?.fullName?.split(" ");
        let initials;
        if (nameArr?.length > 1) {
          initials = nameArr[0][0] + nameArr[1][0];
        } else if (nameArr?.length) {
          initials = nameArr[0][0];
        } else {
          initials = "";
        }
        let address = freqmonthly?.billing_details?.address ?? {};
        return [
          { type: "id", value: i + 1, docId: freqmonthly._id, donorDetails: { ...freqmonthly } },
          {
            type: 'string',
            name: 'textImg',
            primaryText: freqmonthly.fullName,
            initials: initials?.toUpperCase(),
          },

          {
            type: "string",
            name: "category",
            value: `${address?.city ? this.capitalizeFLetter(address?.city) + "," : ""} ${address?.state
              ? stateAbbreviations[address?.state.replace(/\b\w/g, c => c.toUpperCase())] ? stateAbbreviations[address?.state.replace(/\b\w/g, c => c.toUpperCase())] : this.capitalizeFLetter(address?.state) ?? '' : address?.state ?? ''} ${address?.postal_code ?? ""}` || "N/A",
          },
          {
            type: "string",
            name: "name",
            value: freqmonthly.amount ? "$" + (freqmonthly.amount / 100).toFixed(2) : "" || "N/A",
          },
          { type: "action", status: "details", value: "View details" },
        ];
      });
    }
    if (Array.isArray(inviteDonorList)) {
      inviteDonorList.map((freq, i) => {
        return freqRows.push([
          { type: "id", value: i + 1, label: freq._id },

          {
            type: "string",
            name: "name",
            value: freq?.firstName + " " + freq.lastName,
            // stream: freq.stream,
          },

          {
            type: "string",
            name: "category",
            value: freq.email

          },

          {
            type: "string",
            name: "category",
            value: freq.phoneNo ?? ''
          },

          {
            type: "status",
            status: freq?.status?.toLowerCase() === "accepted" ? "success" : "active",
            //freq?.status?.toLowerCase() === "succeeded" ? "success" : "failed",
            value: freq?.status ?? 'N/A'

            // freq?.status?.toLowerCase() === "succeeded"
            //   ? "Success"
            //   : freq?.status?.[0]?.toUpperCase() + freq?.status?.slice(1),
          },
          //{ type: "action", status: "details", value: "see details" },
        ]);
      });

    }
    const classProps = () => {
      const tempArray = [];
      columns.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    const obj = [
      {
        label: "Donors",
        item: "",
      },
      {
        label: "Invited donors",
        item: "",
      },
    ];
    const columns = [
      { id: "NO.", label: "No.", width: "0" },
      { id: "DONOR NAME", label: "DONOR NAME", width: "25%" },
      { id: "CATEGORY", label: "EMAIL", width: "25%" },
      { id: "CATEGORY", label: "Phone", width: "25%" },
      { id: "STATUS", label: "STATUS", width: "25%" },
      { id: "ACTION", label: "ACTION", width: "15%" },
    ];
    const byCategoryColumns = [
      { id: "NO.", label: "No.", width: "auto", },
      { id: "CATEGORY", label: "DONOR NAME", width: "40%" },
      { id: "CATEGORY", label: "Location", width: "25%" },
      { id: "CATEGORY", label: "Total donation", width: "25%" },
      { id: "STATUS", label: "", width: "15%" },
    ];
    const contentNext = (
      <>
        <SimpleTable
          showHeader={true}
          title="Donors"
          rows={byCategoryRows}
          columns={byCategoryColumns}
          colSpan={16}
          actionSendButton={true}
          classProps={changeClassProps()}
          donationsCount={byCategoryRows.length ? 1 : 0}
          noTitle="No recent donations"
          noPara="Add donation categories so that people can donate for a specific cause."
          noBtnText="Invite Donors"
          showFooter={true}
          actionLink={(data) => this.actionLink2(data)}
        />
      </>
    );
    const contentNextOneTime = (
      <>

        <SimpleTable
          showHeader={true}
          title="Invited Donors"
          rows={freqRows}
          columns={columns}
          colSpan={16}
          actionSendButton={true}
          classProps={changeClassProps()}
          donationsCount={freqRows && freqRows.length > 0 ? 1 : 0}
          noTitle="No Donor has been invited"
          noPara="Invited Donors will be displayed and managed here"
          noBtnText="Invite Donor"
          onInviteClick={() => this.props.history.push("/deenfund/invite-donor")}
          actionLink={(name) => this.details(name)}
          showIconCol={true}
          editIcon={Edit}
          deleteIcon={Delete}
          editAction={(e) => this.editInviteDonot(e)}
          deleteAction={(e) => this.deleteInviteDonor(e)}
          showFooter={true}
          showAdd={true}
          addText="INVITE DONOR"
          onAddClick={() => this.props.history.push("/deenfund/invite-donor")}
          iconTextSize={16}
        />
        {/* <SimpleTable
          showHeader={true}
          showAdd={true}
          addText='Invite Donor'
          title="Donations"
          rows={freqRows}
          columns={columns}
          colSpan={16}
          actionSendButton={true}
          classProps={changeClassProps()}
          donationsCount={freqRows.length ? 1 : 0}
          noTitle="No recent donations"
          noPara="Add donation categories so that people can donate for a specific cause."
          noBtnText="Invite Donors"
          showFooter={true}
          editIcon={Edit}
          deleteIcon={Delete}
          editAction={(e) => console.log("Edit Clicked: ", e)}
          deleteAction={(e) => console.log("Delete Clicked: ", e)}
          onInviteClick={() =>
            this.props.history.push("/deenfund/invite-donor")
          }
          onAddClick={() =>
            this.props.history.push("/deenfund/invite-donor")
          }
          actionLink={(data) => this.actionLink(data)}
          iconTextSize={16}
        /> */}
        <div className="footer-saprator-3"></div>
      </>
    );

    const style = {
      width: '100%',
    };
    console.log("this.state.donors", this.state.donors)

    const { error, activeTab, loading, donorsCardData, invitedDonorDeleted } = this.state;
    return (
      <React.Fragment>
        <MasterLayoutMiddle
          header={
            <div></div>
          }
          content={
            <div>

              <div>
                {error && error === "Token is invalid or expired" && (
                  <div
                    style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                  >
                    <AlertAction
                      alert={token_expired}
                      alertType={"error"}
                      onClick={() => this.setState({ error: false })}
                    />
                  </div>
                )}

                {invitedDonorDeleted && (
                  <div
                    style={{
                      zIndex: 9999,
                      width: "60%",
                      position: "fixed",
                      top: 35,
                    }}
                  >
                    <AlertAction
                      alert={"Invited Donor has been deleted Successfully!"}
                      alertType={"success"}
                      onClick={() => this.props.hideAlert()}
                    />
                  </div>
                )}

                {this.props?.alertValue && (this.props?.alertValue === "Invitation sent successfully!" || this.props?.alertValue === "Invitation resent successfully") && (
                  <div
                    style={{
                      zIndex: 9999,
                      width: "60%",
                      position: "fixed",
                      top: 35,
                    }}
                  >
                    <AlertAction
                      alert={this.props?.alertValue}
                      alertType={"success"}
                      onClick={() => this.props.hideAlert()}
                    />
                  </div>
                )}
              </div>


              <div className="card_section">
                <CardData
                  data={[
                    {
                      title: `Donations - ${moment().format("MMM YYYY")}`,
                      donation: donorsCardData && donorsCardData.card1 && donorsCardData.card1.data ? "$" + (donorsCardData.card1.data / 100).toFixed(2) : 0,
                      fromLastMonth: donorsCardData && donorsCardData.card1 && donorsCardData.card1.percentage ? (donorsCardData.card1.percentage).toFixed(2) : 0,
                      increased: donorsCardData && donorsCardData.card1 && donorsCardData.card1.percentage > 0 ? true : false,
                    },
                    {
                      title: `New Donors - ${moment().format("MMM YYYY")}`,
                      donation: donorsCardData && donorsCardData.card2 && donorsCardData.card2.data ? donorsCardData.card2.data : 0,
                      fromLastMonth: donorsCardData && donorsCardData.card2 && donorsCardData.card2.percentage ? (donorsCardData.card2.percentage).toFixed(2) : 0,
                      increased: donorsCardData && donorsCardData.card2 && donorsCardData.card2.percentage > 0 ? true : false,
                    },
                    {
                      title: "Total Recurring Donors",
                      donation: donorsCardData && donorsCardData.card3 && donorsCardData.card3.data ? donorsCardData.card3.data : 0,
                      fromLastMonth: donorsCardData && donorsCardData.card3 && donorsCardData.card3.percentage ? (donorsCardData.card3.percentage).toFixed(2) : 0,
                      increased: donorsCardData && donorsCardData.card3 && donorsCardData.card3.percentage > 0 ? true : false,
                    },
                    {
                      title: `Total Donations - ${moment().format("YYYY")}`,
                      donation: donorsCardData && donorsCardData.card4 && donorsCardData.card4.data ? "$" + (donorsCardData.card4.data / 100).toFixed(2) : 0,
                      fromLastMonth: donorsCardData && donorsCardData.card4 && donorsCardData.card4.percentage ? (donorsCardData.card4.percentage).toFixed(2) : 0,
                      increased: donorsCardData && donorsCardData.card4 && donorsCardData.card4.percentage > 0 ? true : false,
                    },
                  ]}
                />

              </div>
              <GridContainer spacing="0">
                <GridItem>
                  <BaseTabs
                    title=""
                    object={obj}
                    onClick={(x, y) => this.onClick(x, y)}
                    value={activeTab}
                    onChange={(event, newValue) =>
                      this.handleTabChange(event, newValue)
                    }
                  />
                </GridItem>
                {tabIndex === 0 &&
                  (loading ? (
                    <div
                      style={{
                        marginTop: 60,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                    <GridItem itemClass="grid-item">
                      <BasePaper
                        context={contentNext}
                        square={false}
                        paperStyle={styles.paperStyleNext}
                      />
                    </GridItem>
                  ))}
                {tabIndex === 1 &&
                  (loading ? (
                    <div
                      style={{
                        marginTop: 60,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                    <GridItem itemClass="grid-item">
                      <BasePaper
                        context={contentNextOneTime}
                        square={false}
                        paperStyle={styles.paperStyleNext}
                      />
                    </GridItem>
                  ))}

              </GridContainer>
            </div>
          }
          isFooter={true}
          footer={
            <FooterInner 
              style={style} 
              termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
              privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
            />
          }
          isWhiteBackground={false}
        />
      </React.Fragment>
    );
  }
}
const styles = {
  paperStyle: {},
  paperStyleNext: {
    border: "none",
  },
  contents: {
    padding: '24 0',
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: '76%',
    marginTop: 70,
    marginLeft: '20%'
  },
};
const mapStateToProps = (state) => ({
  donors: state.donors,
  alertValue: state.alert.alertValue,
});
const mapDispatchToProps = (dispatch) => ({
  setDonors: (res) => dispatch(setAllDonors(res)),
  hideAlert: (res) => dispatch(hideAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ManageDonors);
