import React from 'react';
import { SelectFieldOne, TextInputFixedLabel, PhoneInputField, } from "master-react-lib";
import { FormSwitch } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';


function AgeGroup(props) {

    const handleChangeGender = (event, value) => {
        console.log(event.target.value, value);
    };


    const Age = [
        { value: 1, select: "05-09 years" },
        { value: 2, select: "10-14 years" },
        { value: 3, select: "15-19 years" },
        { value: 4, select: "20-24 years" },
        { value: 5, select: "25-29 years" },
        { value: 6, select: "30-34 years" },
        { value: 7, select: "35-39 years" },
        { value: 8, select: "40-44 years" },
        { value: 9, select: "45-49 years" },
        { value: 10, select: "50-54 years" },
        { value: 11, select: "55-59 years" },
        { value: 12, select: "60-64 years" },
        { value: 13, select: "65-69 years" },
        { value: 14, select: "70-74 years" },
        { value: 15, select: "75-79 years" },
        { value: 16, select: "80-84 years" },
        { value: 17, select: "85-89 years" },
        { value: 18, select: "90 years" },
    ];



    const [values, setValues] = React.useState(false);

    const handleSwitchChange = (event) => {
        setValues(event)
        console.log(event, 'swuitch')
        // setValues();
    };



    return (

        <div className="field_boxAcord">

            <Grid container spacing={0}>

                <Grid item xs={12} md={12}>
                    <FormSwitch
                        headline='All age groups'
                        body=""
                        values={values}
                        handleSwitchChange={(e) => handleSwitchChange(e)}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <SelectFieldOne
                        label="Select Age Groups"
                        selectdata={Age}
                        defaultValue={1}
                        handleChange={handleChangeGender}
                    />
                </Grid>

            </Grid>

        </div>

    );
}


export default AgeGroup;