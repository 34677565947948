import React, { useState } from "react";
import { BaseTextField, MasterLayoutMiddle, FooterInner, TextEditor, BaseButton } from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import MediaUpload from "../../components/MediaUpload/MediaUpload";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
import { addPhotoGallery } from "./api";
const {success_message_add_photo_gallery} = messages_content;

function AddPhotoGallery(props) {
  const [logo, setLogo] = useState({
    logoUrl: '',
    logoName: ''
  });
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState([{ type: 'paragraph', children: [{ text: "" }] }]);
  
  const style = {
    width: '100%',
  };

  const setlogo = (obj) => {
    if(obj?.title?.length) {
      setLogo({
        logoName: obj.title,
        logoUrl: obj.src 
      });
    }
    
  }
  

  const handleChange = (event)  => {
    setTitle(event.target.value);
  };

  const onChange = (e) => {
    setDescription(e)
  };

  const handleNext = () => {
    setLoading(true);
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    const obj = {
      title: title,
      description: description?.[0]?.children?.[0]?.text,
      orgId: orgId,
      imageMetaData: {
        imageUrl: logo?.logoUrl,
        imageName: logo?.logoName
      }
    };
    addPhotoGallery(obj, token)
    .then(res => {  
      setTimeout(() => {
        props.alertShow(success_message_add_photo_gallery)
        props.history.push("/website/photo-gallery");
      }, 5000)
    })
    .catch(error => {
      setLoading(true);
      console.log("Error is: ", error)
    })
    // setTimeout(() => {
    //     props.alertShow(success_message_add_photo_gallery)
    //     props.history.push("/website/photo-gallery");
    // }, 5000)
  };

  const styles = {
    btn: {
      height: 66,
      width: "100%",
      margin: 8,
      border: "#D3D8DA",
    },
  };

  const disableButton = logo?.logoUrl?.length > 0  ? false : true;

  return (
    <MasterLayoutMiddle
      header={
        <div>
        <div
          onClick={() => props.history.push("/website/photo-gallery")}
          className="backBtn"
          style={{ marginTop: 0 }}
        >
          <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
          <p style={{ alignSelf: "center" }}>Back</p>{" "}
        </div>
        </div>
      }
      isForm={true}
      formData={
        loading ?
        <div
          style={{
            marginTop: 30,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="primary" />
        </div>
        :
        <Grid container spacing={3}>

          <Grid item xs={12}>
            <h1 className="title_top">Add Photo</h1>
            <div className="dasheddBorder"></div>
          </Grid>

          <Grid item md={12} xl={12}>
            <MediaUpload
              addMediaItem={(obj) => setlogo(obj)}
              label="Photo"
              buttonName="ADD PHOTO"
              typeOfMedia='/images'
            />
          </Grid>

          <Grid item md={12} xl={12}>
            <div className="field_box">
              <BaseTextField
                label={"Caption Title"}
                value={title}
                onChange={(e) => handleChange(e)}
                InputProps={false}
                //phone={false}
                //search={false}
                count={50}
                style={styles.btn}
                color="primary"
                onClick={() => console.log("onCLick")}
                onBlur={() => console.log("onBlur")}
                // disabled={true}
                //helperText="Helper text"
                multiline={true}
                rowsMax={4}
              //type="search"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="TextArea">
              <TextEditor
                onChange={(e) => onChange(e)}
                disabled={false}
                value={description}
                style={styles}
                label={"Description"}
                //helperText={"Helper Text"}
                maxCharCount={200}
                placeholder="Description here"
              />
            </div>
          </Grid>

          <Grid item md={12} xl={12}>
            <div className="form_button">
              <span className="btnCencel">
                <BaseButton
                  text="CANCEL"
                  onClick={() => props.history.push("/website/photo-gallery")}
                />
              </span>
              <BaseButton text="ADD NOW" disabled = {disableButton} onClick={() => handleNext()} />
            </div>
          </Grid>

        </Grid>
      }
      isFooter={true}
      footer={<FooterInner 
        style={style} 
        termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />}
      isWhiteBackground={true}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    alert: state.alert,
  };
};
const mapDispatchToProps = (dispatch) => ({
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddPhotoGallery);
