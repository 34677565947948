import * as constants from "../constants";

const INITIAL_STATE = {
  application: "",
  role: "",
  roles: [],
  editTeamMember: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.EMPTY_ROLES:
      return {
        ...state,
        roles: [],
      };
    case constants.EDIT_TEAM_MEMBER:
      return {
        ...state,
        editTeamMember: action.payload,
      };
    case constants.ADD_ROLE:
      return {
        ...state,
        application: action.payload.application,
        role: action.payload.role,
        roles: [
          ...state.roles,
          {
            id: action.payload.id,
            application: action.payload.application,
            roles: action.payload.role,
          },
        ],
      };

    case constants.EDIT_ROLE:
      return {
        ...state,
        application: action.payload.application,
        role: action.payload.role,
        roles: state.roles.map((item) => {
          if (item.id === action.payload.id) {
            return {
              id: action.payload.id,
              application: action.payload.application,
              roles: action.payload.role,
            };
          }
          return item;
        }),
      };

    case constants.DELETE_ROLE:
      return {
        ...state,
        roles: state.roles.filter((item) => {
          return item.id !== action.payload.id;
        }),
      };

    case constants.ADD_TEAM_MEMBER_ROLE:
      state.editTeamMember.role = [
        ...state.editTeamMember.role,
        {
          id: action.payload.id,
          application: action.payload.application,
          roles: action.payload.role,
        },
      ];
      return {
        ...state,
        editTeamMember: state.editTeamMember,
      };

    case constants.EDIT_TEAM_MEMBER_ROLE:
      let arr2 = state.editTeamMember.role.map((item) => {
        if (item._id === action.payload.id) {
          return {
            id: action.payload.id,
            application: action.payload.application,
            roles: action.payload.role,
          };
        }
        return item;
      });
      state.editTeamMember.role = arr2;
      return {
        ...state,
        editTeamMember: state.editTeamMember,
      };
    case constants.DELETE_TEAM_MEMBER_ROLE:
      let arr = state.editTeamMember.role.filter((item) => {
        return item._id !== action.payload.id;
      });
      state.editTeamMember.role = arr;
      return {
        ...state,
        editTeamMember: state.editTeamMember,
      };
      case constants.EMPTY_TEAM_MEMBER:
        return{
          ...state,
          editTeamMember:[]
        }
    default:
      return state;
  }
};
