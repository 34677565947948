import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  SimpleAppBar,
  BasePaper,
  PageLoaderNavbar,
  Spinner,
} from "@dclab/dc-react-ui-kit";
import MainLogo from "../../assets/img/DC_Logo.png";
import Footer from "../../components/Footer/Footer";
import "../../App.css";
import "./Loading.css";
const LoadingPage = (props) => {
  useEffect(() => {
    setTimeout(() => {
      props.history.replace('/organization/overview/edit-details')
    }, 8000)
  }, [props.history])
  const logout = () => {
    localStorage.setItem("user", false);
    localStorage.setItem("authToken", "");
    props.history.replace("/signin");
    localStorage.clear();
  };
  const content = (
    <React.Fragment>
      <header style={styles.header} className="app-bar">
        <SimpleAppBar
          src={MainLogo}
          name={localStorage.getItem("name") || props.signup.fullName}
          role={localStorage.getItem("role")}
          onClickLogout={() => logout()}
          style={{ marginTop: 80, marginLeft: -10 }}
        />
      </header>
      <section style={styles.conatiner}>
        <aside style={styles.navBar} className="deenfund-nav">
          <PageLoaderNavbar />
        </aside>
        <section style={styles.contents}>
          <Spinner />
        </section>
      </section>
    </React.Fragment>
  );
  return (
    <>
      <BasePaper
        elevation={0}
        context={content}
        paperStyle={styles.paperStyle}
      />
      <div style={{ height: "62%", position: "relative" }}>
        <Footer />
      </div>
    </>
  );
};
const styles = {
  conatiner: {
    display: "flex",
    background: "#FFF",
  },
  navBar: {
    backgroundImage: "linear-gradient(#2490F9, #7266FB)",
    color: "white",
    height: "91vh",
    marginTop: 75,
  },
  contents: {
    paddingBottom: 0,
    background: "#FFF",
    width: "76%",
    marginTop: 70,
    marginLeft: "15%",
    height: "90vh",
  },
  paperStyle: {
    width: "100%",
  },
  header: {
    boxShadow: "0px 1px 4px #0000001A",
    borderBottom: "1px solid #0000001A",
    position: "fixed",
    width: "100%",
    zIndex: 999,
  },
};
const mapStateToProps = (state) => {
  return {
    signup: state.signup,
  };
};
export default connect(mapStateToProps, {})(LoadingPage);
