import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import {
   DropDown, TextInputFixedLabel, MediaUploader, ColorPickerButton
} from "@dclab/dc-react-ui-kit";
import { makeStyles } from '@material-ui/core/styles';
import MediaLibraryModal from '../../../MediaLibrary/MediaLibraryModal';
import MediaUpload from "../../../../components/MediaUpload/MediaUpload";


export default function SettingsTab(props) {
    const {editHomeScreen, seteditHomeScreen} = props;
    console.log("Edit Home Screen is: ", editHomeScreen);
    let editBg = editHomeScreen?.background ?? {}
    // editBg = editBg?.imageUrl ? {...editBg} : { ...editBg, imageUrl: "https://shapla.console.deenconnect.io/dk-default-bg.png"}

    // const [name, setname] = useState("");
    // const [status, setstatus] = useState("");
    // const [background, setbackground] = useState({
    //   backgroundType: "",
    //   imageUrl: "",
    //   themeColor: "",
    // })

    const selectdata = [
        { value: "active", select: "Active" },
        { value: "inactive", select: "Inactive" },
    ];
    
    const Background = [
    { value: "image", select: "Image" },
    { value: "solidColor", select: "Solid Color" },
    ];
      // ========Color Picker Start =======//
  const getColor = (color) => {
    console.log("get color", color)
    seteditHomeScreen({...editHomeScreen, background: {...editBg, themeColor: color}})
    props.setchanges(true)
  }

  const palette = {
    red: '#ff0000',
    blue: '#0000ff',
    green: '#00ff00',
    yellow: 'yellow',
    cyan: 'cyan',
    lime: 'lime',
    gray: 'gray',
    orange: 'orange',
    purple: 'purple',
    black: 'black',
    white: 'white',
    pink: 'pink',
    darkblue: 'darkblue',
  };
  // ========Color Picker end =======//

    const classes = useStyles();


    return (
        <Grid container spacing={3}>

        <Grid item xs={12}>
          <TextInputFixedLabel
            label="Name"
            labelStatus={true}
            helpertext="Helper text"
            HelpertextStatus={false}
            placeholder=""
            Errormessage="Error message"
            errortextStatus={false}
            count={100}
            countStatus={false}
            tooltipStatus={false}
            onChange={(e) => {
              props.setchanges(true)
              seteditHomeScreen({...editHomeScreen, name: e.target.value})
            }}
            value={editHomeScreen?.name ?? ""}
          />
        </Grid>

        <Grid item xs={12}>
          <DropDown
            label="Status"
            labelStatus={true}
            selectdata={selectdata}
            defaultValue={editHomeScreen?.status ?? ""}
            handleChange={(e) => {
              seteditHomeScreen({...editHomeScreen, status: e.target.value})
              props.setchanges(true)            
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.DashLine}></div>
        </Grid>

        <Grid item xs={12}>
          <DropDown
            label="Background Style"
            labelStatus={true}
            selectdata={Background}
            defaultValue={editBg?.backgroundType ?? "image"}
            handleChange={(e) => {
              seteditHomeScreen({...editHomeScreen, background: {...editBg, backgroundType: e.target.value}})
              props.setchanges(true)            
            }}
          />
        </Grid>


        <Grid item xs={12}>
        {
          editHomeScreen?.background?.backgroundType === "solidColor" ? <ColorPickerButton
          label="Background Color"
          getColor={getColor}
          defaultColor={editBg?.themeColor ?? "#000000"}
          style={{ width: '100%' }}
          palette={palette}
        /> : <MediaUpload 
              typeOfMedia='/images' 
              label="Upload Image" 
              buttonName = "UPLOAD IMAGE" 
              addMediaItem={(obj) => {
                seteditHomeScreen({...editHomeScreen, background: {...editBg, imageUrl: obj.src}})
                props.setchanges(true)            
              }}
              selectedMedia = {{logoUrl: editHomeScreen?.background?.imageUrl ?? "", logoName: editHomeScreen?.background?.backgroundType ?? ""}}
              /> }
        </Grid>

      </Grid>
    )
}


const useStyles = makeStyles((theme) => ({

    DashLine: {
      border: " 1px dashed #D3D8DA",
    },
  
    text: {
      letterSpacing: " 0.25px",
      color: '#818E94',
      fontSize: "14px",
      lineHeight: "19px",
    },
  
    BorderLine: {
      borderBottom: " 1px solid #E4E8EC",
    }
  
}));