import React, { useState, useEffect } from 'react';
import {
    AlertAction,
    GridContainer,
    GridItem,
    OfferCard,
    BaseButton,
    BaseStepperExtended,
    BaseCheckBox,
} from "@dclab/dc-react-ui-kit";

import ChevronLeft from '@material-ui/icons/ChevronLeft';
import "../style.css";
import "../../../App.css";
import "../../style.css";
import moment from 'moment';
import { Grid, TextField } from '@material-ui/core';
import { cancelPlan } from "../api";
import CircularProgress from '@material-ui/core/CircularProgress';

import { makeStyles } from '@material-ui/core/styles';

import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import Icon from '../../../assets/img/DWFavicon.png';

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import Link from '@material-ui/core/Link';

function FeedbackStep(props) {
    const { reason, setreason, checkboxesData, setcheckboxesData } = props;

    const checkboxHandler = (e, i) => {
        setcheckboxesData((prevState) => [
            ...prevState.slice(0, i),
            {
                ...prevState[i],
                checked: !prevState[i].checked,
            },
            ...prevState.slice(i + 1)
        ]
        );
    }

    const onContinueClick = () => {
        props.handleNext();
    }

    return (
        <div className="feedback-step" style={{ maxWidth: "980px", margin: "0 auto", paddingBottom: "100px", }}>
            <h3 className="mainHeading">We're sorry to see you go.</h3>
            <Grid container spacing={3}>
                <Grid item xs={7}>
                    <h3 className="subHeading">WHY DO YOU WANT TO CANEL?</h3>
                    {checkboxesData.map((el, i) => {
                        return <div key={i} className="cancel-reason-checkbox"><BaseCheckBox
                            label={el.label}
                            checked={checkboxesData[i].checked}
                            color="secondary"
                            onChange={(e) => checkboxHandler(e.target.checked, i)}
                        /></div>
                    })}
                </Grid>
                <Grid item xs={5}>
                    <div className="subHeading">COMMENTS (OPTIONAL)</div>
                    <div className="cancel-reason" style={{width:"470px"}}>
                        <TextField
                            id="outlined-multiline-static"
                            label=""
                            value={reason}
                            multiline
                            rows={6}
                            defaultValue=""
                            placeholder="Please tell us more so we can improve our offering."
                            variant="outlined"
                            onChange={(e) => e.target.value?.length <= 300 ? setreason(e.target.value) : null}
                        />
                        <div className="reason-helpertext">CHAR {reason?.length}/300</div>
                    </div>
                </Grid>

                <div className="form_button DSHBorderTop">
                    <p className="cancel" onClick={() => props.history.goBack()}>CANCEL</p>
                    <BaseButton text="CONTINUE" onClick={() => onContinueClick()} />
                </div>
            </Grid>
        </div>
    )
}

function OffersStep(props) {

    return (
        <div className="feedback-step" style={{ maxWidth: "980px", margin: "0 auto", paddingBottom: "100px", }}>
            <h3 className="mainHeading">Before you decide to cancel, consider these offers:</h3>
            <div className="OfferContainerBody">
                <Grid container spacing={3}>

                    <Grid item xs={4}>
                        <OfferCard
                            themeColor="#F6853C"
                            Icon={
                                <img src={Icon} />
                            }

                            Title="Keep the same plan on a special price"
                            Description={
                                <p>Regular</p>
                            }

                            Amount="$5/mo"
                            DiscountText="Discount for your first year"
                            DiscountAmount="$4/mo"
                            buttonLabel="ACCEPT OFFER"
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <OfferCard
                            themeColor="#2A82F6"
                            Icon={
                                <QuestionAnswerOutlinedIcon />
                            }

                            Title="Let's talk: check for a custom deal."
                            Description={
                                <p>We can help you find the right plan, answer any questions, <span>or maybe give you a special offer.</span></p>
                            }
                            buttonLabel="START CHAT"
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <OfferCard
                            themeColor="#F6853C"
                            Icon={
                                <img src={Icon} />
                            }

                            Title="Get your next six months free and save $30"
                            Description={
                                <p>Regular</p>
                            }

                            Amount="$5/mo"
                            DiscountText="Discount for your first year"
                            DiscountAmount="$4/mo"
                            buttonLabel="ACCEPT OFFER"
                        />
                    </Grid>




                </Grid>

                </div>

                <div className="form_button DSHBorderTop">
                    <p className="cancel" onClick={() => props.handleBack()}>CANCEL</p>
                    <BaseButton text="NO THANKS" onClick={() => props.handleNext()} />
                </div>

            





            {/* <div className="feedback-step" style={{ maxWidth: "980px", margin: "0 auto", paddingBottom: "100px", }}>
                <h3 className="mainHeading">We're sorry to see you go.</h3>
                <Grid container spacing={3}>
                    <Grid item xs={7}>
                        <div className="subHeading">CANCELLATION DETAILS INCLUDE</div>

                        <div className="bodyText">
                            <MonetizationOnIcon />
                            <div>
                                <h2 className="bodyHeadingText">You'll receive a refund of $18</h2>
                                <h3 className="FDBdescription">This amount is adjusted for your DEENFUND-Standard plan subscription use within this cycle,
                                    which you'll receive within 5 business days after billing cycle ends.</h3>
                            </div>
                        </div>

                        <div className="bodyText">
                            <CalendarTodayIcon />
                            <div>
                                <h2 className="bodyHeadingText">Plan Date</h2>
                                <h3 className="FDBdescription">Access to your plan will end at the end of the running billing cycle <span>Friday, September 3, 2021</span></h3>
                            </div>
                        </div>

                        <div className="bodyText">
                            <CloudOffIcon />
                            <div>
                                <h2 className="bodyHeadingText">Contents</h2>
                                <h3 className="FDBdescription">You contents will be deleted when plan date ends. You can reactivate in this period to save your data.</h3>
                            </div>
                        </div>

                        <div className="bodyText">
                            <DesktopMacIcon />
                            <div>
                                <h2 className="bodyHeadingText">Hardware</h2>
                                <h3 className="FDBdescription">Placeholder for hardware return instructions or purchasing options of hardware</h3>
                            </div>
                        </div>

                    </Grid>

                    <Grid item xs={12}>
                        <div className="steper_border"></div>
                    </Grid>

                    <div className="form_button DSHBorderTop">
                        <p className="cancel" onClick={() => props.handleBack()}>CANCEL</p>
                        <BaseButton text="CONFIRM" onClick={() => props.handleNext()} />
                    </div>
                </Grid>
            </div> */}



            {/* <div className="CalcelConfirmationContainer">
                <h2>You're still part of the DEENCONNECT community.</h2>
                <p>You can <Link to=''>return to this plan</Link>at any point. <br />In the meantime, enjoy access to all the free applications/integrations we have to offer.</p>

                <BaseButton text="EXPLORE MORE APPS" onClick={() => props.handleNext()} />
            </div> */}





        </div>






    )
}


function ReviewStep(props) {
    return (
        <div className="feedback-step" style={{ maxWidth: "980px", margin: "0 auto", paddingBottom: "100px", }}>
                <h3 className="mainHeading">We're sorry to see you go.</h3>
                <Grid container spacing={3}>
                    <Grid item xs={7}>
                        <div className="subHeading">CANCELLATION DETAILS INCLUDE</div>

                        <div className="bodyText">
                            <MonetizationOnIcon />
                            <div>
                                <h2 className="bodyHeadingText">You'll receive a refund of $18</h2>
                                <h3 className="FDBdescription">This amount is adjusted for your DEENFUND-Standard plan subscription use within this cycle,
                                    which you'll receive within 5 business days after billing cycle ends.</h3>
                            </div>
                        </div>

                        <div className="bodyText">
                            <CalendarTodayIcon />
                            <div>
                                <h2 className="bodyHeadingText">Plan Date</h2>
                                <h3 className="FDBdescription">Access to your plan will end at the end of the running billing cycle <span>Friday, September 3, 2021</span></h3>
                            </div>
                        </div>

                        <div className="bodyText">
                            <CloudOffIcon />
                            <div>
                                <h2 className="bodyHeadingText">Contents</h2>
                                <h3 className="FDBdescription">You contents will be deleted when plan date ends. You can reactivate in this period to save your data.</h3>
                            </div>
                        </div>

                        <div className="bodyText">
                            <DesktopMacIcon />
                            <div>
                                <h2 className="bodyHeadingText">Hardware</h2>
                                <h3 className="FDBdescription">Placeholder for hardware return instructions or purchasing options of hardware</h3>
                            </div>
                        </div>

                    </Grid>

                    <Grid item xs={12}>
                        <div className="steper_border"></div>
                    </Grid>

                    <div className="form_button DSHBorderTop">
                        <p className="cancel" onClick={() => props.handleBack()}>CANCEL</p>
                        <BaseButton text="CONFIRM" onClick={() => props.handleNext()} />
                    </div>
                </Grid>
            </div>
        // <div className="feedback-step" style={{ maxWidth: "980px", margin: "0 auto", paddingBottom: "100px", }}>

        // </div>
    )
}





export default function CancelPlan(props) {
    const classes = useStyles();
    const [plan, setplan] = useState({})
    const [reason, setreason] = useState("")
    const [details, setdetails] = useState({})
    const [planCancelled, setplanCancelled] = useState(false);
    const [checkboxesData, setcheckboxesData] = useState([
        { label: "I'm looking to change my plan.", checked: false },
        { label: "Technical issue", checked: false },
        { label: "Too expensive", checked: false },
        { label: "I'm not using the product(s) often enough.", checked: false },
        { label: "Product(s) and features are too complicated for me.", checked: false },
        { label: "I'm not using the product(s) often enough.", checked: false },
        { label: "Other (Please specify).", checked: false },
    ]);
    const [error, seterror] = useState(false)
    const [loading, setloading] = useState(false)

    useEffect(() => {
        if (props?.location?.state?.plan) {
            setplan(props.location.state.plan)
            props?.location?.state?.details && setdetails(props.location.state.details)
            console.log("route states", props.location.state.plan, props?.location?.state?.details);
        }
    }, [props?.location?.state?.plan])


    const [activeStep, setActiveStep] = React.useState(0);
    const getSteps = () => {
        return [
            {
                number: 1, label: "Feedback",
                value: <FeedbackStep handleNext={handleNext} history={props.history} reason={reason} setreason={setreason} setcheckboxesData={setcheckboxesData} checkboxesData={checkboxesData} />
            },

            { number: 2, label: "Offers", value: <OffersStep handleBack={handleBack} handleNext={handleNext} /> },

            { number: 3, label: "Review", value: <ReviewStep handleBack={handleBack} handleNext={handleNext} /> },
        ];
    };
    const handleNext = async () => {
        if (activeStep < 2) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if(activeStep === 2) {
            let price;
            if (details?.appType?.toLowerCase() === "integration") {
                price = details?.pricing?.monthlyBilling?.price ?? 0;
            } else {
                let plan = details?.plans?.find((plan) => plan?.name?.toLowerCase() === details?.addedPlan?.plan?.toLowerCase())
                price = plan && plan?.monthlyBilling?.price ? plan.monthlyBilling.price : 0;
            }
            const formData = {
                reasons: checkboxesData.filter((el) => el.checked === true).map((el) => el.label),
                message: reason,
                planId: plan?.planId,
                subscriptionId: plan?.subscriptionId,
                orgId: plan?.orgId,
                customerId: plan?.customerId,
                productId: plan?.productId,
                planSubscriptionId: plan?.planSubscriptionId,
                subscriptionPlan: details?.name,
                subscriptionPlanType: details?.appType,
                subscriptionPaymentFrequency: plan?.frequency,
                subscriptionMonthlyPrice: price
            };
            console.log("submit", formData)
            
            try {
                const token = localStorage.getItem("authToken");
                let res = await cancelPlan(formData, token);
                if (res?.data?.error) {
                    console.log("error", res?.data?.message)
                    seterror(res?.data?.message || "Something went wrong");
                    setloading(false)
                } else {
                    setplanCancelled(true);
                    setTimeout(() => {
                        setplanCancelled(false);
                    }, 4000);
                    setActiveStep(activeStep + 1);

                    // props.history.push("/more-apps")
                }
                console.log(res, "planRes")
            } catch (error) {
                console.log(error)
                setloading(false)
                seterror(error?.message || "Something went wrong");
            }
        }
    };

    const handleBack = () => {
        if (activeStep === 1) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };
    const handleBackPreviousPage = () => {
        console.log('BackButton');
    }

    const styles = {
        stepper: {
            marginTop: 120,
            padding: "8px 20px",
            borderRadius: "30px",
            backgroundColor: "#ccc",
        },
    };


    return (
        <>
        { activeStep === 3 ? 
        <>
            {planCancelled && (
                <div
                    style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                >
                    <AlertAction
                        alert={"Your plan was schedule to cancel. Check your email for more details"}
                        alertType={'success'}
                        onClick={() => setplanCancelled(false)}
                    />
                </div>
            )}
            <div className="CalcelConfirmationContainer">
                
            <h2>You're still part of the DEENCONNECT community.</h2>
            <p>You can <Link to=''>return to this plan</Link> at  any point. <br />In the meantime, enjoy access to all the free applications/integrations we have to offer.</p>

            <BaseButton text="EXPLORE MORE APPS" onClick={() => props.history.push("/more-apps")} />
            </div>
            </>
        :

        <div >

            <>

            <div
                    style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                >
                    <AlertAction
                        alert={"Your plan was schedule to cancel. Check your email for more details"}
                        alertType={'success'}
                        onClick={() => setplanCancelled(false)}
                    />
                </div>
                <div style={{ marginLeft: "100px" }}
                    onClick={() =>
                        props.history.goBack()
                    }
                    className='backBtn'>
                    <ChevronLeft style={{ alignSelf: 'center', marginLeft: 0 }} />
                    <p style={{ alignSelf: 'center' }}>Back</p>{' '}
                </div>

                {(error && (
                    <div
                        style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                    >
                        <AlertAction
                            alert={error}
                            alertType={'error'}
                            onClick={() => seterror(false)}
                        />
                    </div>
                ))}
            </>



            <>
                <GridContainer spacing={0} containerClass="grid-main">
                    <GridItem itemStyle={{ marginTop: 0 }} itemClass="grid-item">
                        {loading && <div
                            style={{
                                position: 'absolute',
                                top: "50%",
                                left: "50%",
                                zIndex: 1000
                            }}
                        >
                            <CircularProgress color='primary' />
                        </div>}

                        <div className={classes.StapperContainer}>
                            <BaseStepperExtended
                                title="Cancel Plan"
                                getSteps={getSteps}
                                alternativeLabel={true}
                                handleBack={() => handleBack()}
                                handleNext={() => handleNext()}
                                nonLinear={true}
                                bottomLine={true}
                                backButton={false}
                                backgroundColor={'transparent'}
                                orientation={"horizontal"}
                                btnStyles={styles.stepper}
                                activeStep={activeStep}
                                backgroundColor="#F7F8FA"
                            />
                        </div>
                    </GridItem>
                </GridContainer>
            </>


        </div>
        }

    
</>
    )
}


const useStyles = makeStyles((theme) => ({

    StapperContainer: {

        '& .steper_border': {
            display: "none",
        },


    },

}));