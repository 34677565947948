import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import {
  BasePaper,
  AlertAction,
  GridContainer,
  GridItem,
  MasterLayoutMiddle,
  FooterInner,
  SimpleTable,
  EmptyTable
} from "@dclab/dc-react-ui-kit";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { getCategories, deleteCategory, sortCategories } from "./apis";
import "../../App.css";
import "./style.css";
import Handle from "../../assets/img/more_vert-24px.svg";
import messages_content from '../../utils/messages.json';
const {
  token_expired
} = messages_content;
class Categories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      error: false,
      showAlert: false,
      success: false,
      loading: false,
      openAlert: false,
      deleteId: "",
      catLoading: true,
      modalLoading: false,
    };
    this.deleteCategory = this.deleteCategory.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCategoryConfirm = this.handleCategoryConfirm.bind(this);
    this.updateCategories = this.updateCategories.bind(this);
  }
  async componentDidMount() {
    const id = localStorage.getItem("orgID");
    try {

      const res = await getCategories(id);
      if (res.data.body.data.length > 0) {
        const sortArr = res.data.body.data.sort((a, b) => a.order - b.order);
        this.setState({
          categories: sortArr,
          catLoading: false,
        });
      } else {
        this.setState({
          catLoading: false,
        });
      }
    } catch (err) {
      this.setState({
        catLoading: false,
      });
      console.log(err)
    }
  }
  handleClose = () => {
    this.setState({ openAlert: false });
  };
  handleCategoryConfirm = async () => {
    this.setState({ modalLoading: true });
    const { categories, deleteId } = this.state;
    const token = localStorage.getItem("authToken");
    try {
      const res = await deleteCategory(deleteId, token);
      const newArr = categories.filter((cat) => cat._id !== deleteId);
      this.setState({
        categories: newArr,
        modalLoading: false,
        openAlert: false,
      });
    } catch (error) {
      this.setState({
        error: error.response.data.message,
        showAlert: true,
        openAlert: false,
        modalLoading: false,
      });
    }
  };
  deleteCategory = async (e) => {
    this.setState({
      openAlert: true,
      deleteId: e[1].label,
    });
  };
  updateCategories = (data) => {
    this.setState({
      categories: data.sort((a, b) => a.order - b.order),
    });
  };
  render() {
    const {
      categories,
      error,
      showAlert,
      success,
      openAlert,
      catLoading,
      modalLoading,
    } = this.state;
    let alertCategoryModal = (
      <Dialog
        open={openAlert}
        onClose={() => this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete this category? This can't be undone.
          </DialogContentText>
        </DialogContent>
        {modalLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 50,
            }}
          >
            <CircularProgress
              color="primary"
              style={{ width: 24, height: 24 }}
            />
          </div>
        ) : (
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => this.handleCategoryConfirm()}
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
    let rows = categories.map((cat, i) => {
      return [
        { type: "id", value: i + 1 },
        {
          type: "string",
          name: "name",
          value: cat.name,
          label: cat._id,
        },
        {
          type: "status",
          status:
            cat.status && cat.status.toLowerCase() === "active"
              ? "success"
              : "failed",
          value: cat.status,
        },
      ];
    });
    const byCategoryColumns = [
      { id: "drag", label: "", width: "auto" },
      { id: "NO.", label: "No.", width: "auto", },
      { id: "CATEGORY", label: "CATEGORY NAME", width: "80%", },
      { id: "STATUS", label: "STATUS", width: "20%", },
      { type: "action", name: "action", label: "action", width: '20%' },
    ];
    const classProps = () => {
      const tempArray = [];
      byCategoryColumns.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };

    const sortData = async (data) => {
      console.log("data", data)
      const selected_categories = data.sort((a, b) => a.order - b.order)
      this.setState({
        categories: selected_categories,
      });

      const id = localStorage.getItem("orgID");
      const token = localStorage.getItem("authToken");
      if (data && data.length) {
        let obj = {
          orgId: id,
          sortedList: data.map((el) => {
            return { _id: el._id, order: el.order }
          })
        }
        const res = await sortCategories(obj, token);
        console.log("sortRes", res)
      }
    }

    const contentNext = (
      <>
        {
          rows.length > 0 ?
            <SimpleTable
              showCol={false}
              showHeader={true}
              title="Donation Categories"
              parentTableDrag={true}
              DragIcon={Handle}
              rows={rows}
              columns={byCategoryColumns}
              colSpan={16}
              actionSendButton={true}
              classProps={changeClassProps()}
              donationsCount={rows.length > 0 ? rows.length : 0}
              data={categories}
              noTitle="No Categories added"
              noPara="Added Categories will be displayed and managed here"
              noBtnText="Add New Categories"
              showAdd={true}
              onAddClick={() =>
                this.props.history.replace("/deenfund/categories/add-new-category")
              }
              onInviteClick={() =>
                this.props.history.replace("/deenfund/categories/add-new-category")
              }
              editIcon={Edit}
              showiconCol={true}
              deleteIcon={Delete}
              editAction={(e) =>
                this.props.history.replace(
                  `/deenfund/categories/edit-category/${e[1].label}`
                )
              }
              deleteAction={(e) => this.deleteCategory(e)}
              iconTextSize={16}
              addText="ADD NEW"
              updateCategories={this.updateCategories}
              showEditColIcon={false}
              hideEditIcon={false}
              showIconCol={true}
              sortData={(e) => sortData(e)}
              rowsPerPage={10}
              showFooter={rows?.length > 10 ? true : false}
            />
            :
            <EmptyTable
              title="Donation Categories"
              onAddClick={() => this.props.history.replace("/deenfund/categories/add-new-category")}
              addLabel="ADD NEW"
              noBtnText="ADD NEW"
            />
        }
      </>
    );
    const style = {
      width: '100%',
    };
    return (
      <React.Fragment>
        <MasterLayoutMiddle
          content={
            <div>
              {showAlert && error && (
                <div
                  style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                >
                  <AlertAction
                    alert={
                      error === "Invalid token"
                        ? token_expired
                        : error
                    }
                    alertType={"error"}
                    onClick={() => this.setState({ showAlert: false })}
                  />
                </div>
              )}
              {showAlert && success && (
                <div
                  style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                >
                  <AlertAction
                    alert={"Success"}
                    alertType={"success"}
                    onClick={() => this.setState({ showAlert: false })}
                  />
                </div>
              )}
              <GridContainer spacing={0} containerClass="grid-main">
                {catLoading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 350,
                    }}
                  >
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                  <>
                    <GridItem>
                      <BasePaper
                        context={contentNext}
                        square={false}
                        paperStyle={styles.paperStyleNext}
                      />
                      <div style={{ display: "flex", }}>
                        <p className="cat-lower">
                          Change the order of Categories to display to users. Click on{" "}
                        </p>
                        <img src={Handle} alt="" />
                        <p className="cat-lower">
                          to drag and drop within the table.
                        </p>
                      </div>
                    </GridItem>
                  </>
                )}
              </GridContainer>
              {openAlert && alertCategoryModal}
            </div>
          }
          isFooter={true}
          footer={
            <FooterInner
              style={style}
              termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
              privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
            />
          }
          isWhiteBackground={false}
        />
      </React.Fragment>
    );
  }
}
const styles = {
  paperStyle: {},
  paperStyleNext: {},
  contents: {
    padding: '24 0',
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: '76%',
    marginTop: 70,
    marginLeft: '20%'

  },
};
const mapStateToProps = (state) => ({
  addOrg: state.addOrg.orgId,
});
export default connect(mapStateToProps, {})(Categories);
