import React, { useEffect } from "react";
import {
  BaseTextField,
  BaseButton,
  TextEditor,
  AlertAction,
  MasterLayoutMiddle, FooterInner
} from "@dclab/dc-react-ui-kit";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { useState } from "react";
import "../../Website/style.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { addFaq, getEmbedForm } from '../apis/index'
import { markdowntoreact } from "../../../utils/markdowntoreact";
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../redux/actions/alertActions';
import Grid from '@material-ui/core/Grid';
import messages_content from '../../../utils/messages.json';
const { 
    token_expired
  } = messages_content;
function EditFaq(props) {
  const dispatch = useDispatch()
  const [value, setValue] = useState([{ type: "paragraph", children: [{ text: "" }] }]);
  const [title, setTitle] = useState('')
  const [descriptionCount, setdescriptionCount] = useState('')
  const [error, setError] = useState(false)
  const [showalert, setshowalert] = useState(false)
  const [loading, setloading] = useState(false)
  const [pageloading, setpageloading] = useState(true)
  function onDescriptionStateChange(editorState) {
    let sum = 0;
    editorState.map((st) => {
      if (st.type === "bulleted-list" || st.type === "numbered-list") {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    setValue(editorState)
    setdescriptionCount(sum)
  };
  useEffect(() => {
    async function get_faqs() {
      try {
        const orgId = localStorage.getItem('orgID')
        const token = localStorage.getItem('authToken')
        const _id = window.location.pathname.split("/").pop()
        const get_faq = await getEmbedForm(orgId, token)
        console.log("get_faq", get_faq?.data?.body?.data[0]?.enabledIntegrations?.deenfund?.embedFormDraft[0]?.setup?.faqs)
        const faqs = get_faq?.data?.body?.data[0]?.enabledIntegrations?.deenfund?.embedFormDraft[0]?.setup?.faqs
        const filtered_faq = faqs.filter(item => item._id === _id)
        setTitle(filtered_faq?.[0]?.question)
        setValue([{ type: "paragraph", children: [{ text: filtered_faq?.[0]?.answer }] }])
        setdescriptionCount(filtered_faq?.[0]?.answer.length)
        setpageloading(false)
      }
      catch (e) {
        setpageloading(false)
        setError(error && error.response && error.response.data.message)
      }
    }
    get_faqs()
  }, [])
  
  const add = async () => {
    setloading(true)
    if (!title || !descriptionCount) {
      console.log("222")
      setError(true)
      setloading(false)
    }
    else {
      const orgId = localStorage.getItem('orgID')
      const token = localStorage.getItem('authToken')
      const faqObject = {
        question: title,
        answer: markdowntoreact(value)[0].props.children[0]
      }
      try {
        const getfaqs = await getEmbedForm(orgId, token)
        // const embedform_draft = get_faq?.data?.body?.data[0]?.enabledIntegrations?.deenfund?.embedFormDraft[0]
        let embedform_draft = getfaqs?.data?.body?.data[0]?.enabledIntegrations?.deenfund?.embedFormDraft?.[0]
        let embedform = getfaqs?.data?.body?.data[0]?.enabledIntegrations?.deenfund?.embedForm?.[0]

        let faqs = getfaqs?.data?.body?.data[0]?.enabledIntegrations?.deenfund?.embedFormDraft[0]?.setup?.faqs

        const _id = window.location.pathname.split("/").pop()
        let res = faqs.map(obj => obj._id === _id ? {...faqObject, _id: _id} : obj);

        faqs = faqs && faqs.length ? faqs : [];
        faqs.push(res)

        const formdata = {
          embedFormDraft: [
            {
              ...embedform_draft,
              setup: {
                footer: embedform_draft?.setup?.footer,
                rightSide: embedform_draft?.setup?.rightSide,
                imageOrVideo: embedform_draft?.setup?.imageOrVideo,
                content: embedform_draft?.setup?.content,
                faqs: res
              },
            },
          ],
          embedForm: [
            {
              ...embedform,
              setup: {
                footer: embedform?.setup?.footer,
                rightSide: embedform?.setup?.rightSide,
                imageOrVideo: embedform?.setup?.imageOrVideo,
                content: embedform?.setup?.content,
                faqs: res
              },
            },
          ]
        }

        let formData2 = {
          'enabledIntegrations.deenfund.embedFormDraft': formdata.embedFormDraft,
          'enabledIntegrations.deenfund.embedForm': formdata.embedForm
        };

        await addFaq(orgId, formData2, token)
        setshowalert(true)
        setloading(false)
        dispatch(showAlert('editfaq'))
         props.history.goBack()
      }
      catch (error) {
        console.log("error", error)
        setloading(false)
        setError(error && error.response && error.response.data.message)
      }
    }
  }
  const style = {
    width: '100%',
  };
  const textEditorStyle = {
    border: "1px solid black",
    color: "gray",
  };
  return (
      <MasterLayoutMiddle
        header={
          <div>
            {error && error === "Token invalid or expired" && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 70 }}
              >
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => setError(false)}
                />
              </div>
            )}
            {showalert && error && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 70 }}
              >
                <AlertAction
                  alert={
                    error === "Token invalid or expired"
                      ? token_expired
                      : error
                  }
                  alertType={"error"}
                  onClick={() => setshowalert(false)}
                />
              </div>
            )}
            <div
              onClick={() => props.history.goBack()}
              className="backBtn"
              style={{ marginTop: 0 }}
            >
              <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
              <p style={{ alignSelf: "center" }}>Back</p>{" "}
            </div>
          </div>
        }
        isForm={true}
        formData={
          pageloading ? <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: 'center',
              height: '90vh'
            }}
          >
            <CircularProgress color="primary" />
          </div> :
            <div>
              <div>
                <h1 className='title_top'>Edit Frequently Asked Questions</h1>
                <div className="dasheddBorder"></div>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseTextField
                      value={title}
                      InputProps={false}
                      count={100}
                      style={{ width: '570px' }}
                      color="primary"
                      onChange={(e) => setTitle(e.target.value)}
                      helperText={error && !title && "Enter a title for the slider"}
                      error={error && !title && "Enter a title for the slider"}
                      label="Faq Title"
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <TextEditor
                    onChange={(e) => onDescriptionStateChange(e)}
                    disabled={false}
                    value={value}
                    style={textEditorStyle}
                    label={"Description"}
                    maxCharCount={1000}
                    helperText={
                      error &&
                      !descriptionCount &&
                      "description is required"
                    }
                    error={error && !descriptionCount}
                  />
                </Grid>
                <Grid item xs={12}>
                  {loading ? (
                    <div
                      style={{
                        marginTop: 30,
                        display: "flex",
                        justifyContent: "center",
                        width: 500,
                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                      <div className="form_button">
                        <span className="btnCencel">
                          <BaseButton
                            text="CANCEL"
                            onClick={() => props.history.push("/deenfund/donation-form")}
                          />
                        </span>
                        <BaseButton text="SAVE NOW" onClick={() => add()} />
                      </div>)}
                </Grid>
                <Grid item xs={12}>
                  <div className="field_box">
                  </div>
                </Grid>
              </Grid>
            </div>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={true}
      />
  );
}
export default EditFaq;
