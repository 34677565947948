import React from "react";
import { VerificationSuccess } from "@dclab/dc-react-ui-kit";
import Logo from "../../assets/img/DC_Logo.png";
import Done from '../../assets/img/Done.svg'
import './style.css'
import { signinHandler } from '../Signin/apis';
import { organizations } from '../Donations/deen-kioks-website/Apis';
function Success(props) {
  const onClick = async () => {
    let userInfo = {
      userName: props.history.location.state.email,
      password: props.history.location.state.password
    }
    try {
      const res = await signinHandler(userInfo);
      let token = res.data.body.data.token;
      const userName = res.data.body.data.userName;
      let refreshToken = res.data.body.data.refreshToken;
      let name = res.data.body.data.name.firstName + (res.data.body.data.name.lastName ? ' ' + res.data.body.data.name.lastName : '')
      let orgId = res.data.body.data.defaultOrg;
      localStorage.setItem('authToken', token);
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('orgID', orgId);
      localStorage.setItem('userName', userName);
      localStorage.setItem('name', name);
      localStorage.setItem(
        'role',
        res.data.body.data.role[0].roles[0].toUpperCase() +
        res.data.body.data.role[0].roles.slice(1)
      );
      if (token && orgId) {
        const orgres = await organizations(orgId, token);
        let orgAddress = orgres.data.body.data.orgAddress;
        let orgName = orgres.data.body.data.orgName;
        let daytimeSaving = orgres?.data?.body?.data?.salahSetting ? orgres?.data?.body?.data?.salahSetting?.daytimeSaving : ''
        let waqtMethod = orgres?.data?.body?.data?.salahSetting ? orgres?.data?.body?.data?.salahSetting?.waqtMethod : ''
        localStorage.setItem('orgaddress', JSON.stringify(orgAddress));

        console.log("Salah Setting: ", orgres?.data?.body?.data?.salahSetting)
        //localStorage.setItem('daytimeSaving', daytimeSaving);
        
        localStorage.setItem('organame', orgName);
        if (localStorage.getItem('timezone') === null) {
          localStorage.setItem('timezone', 'EST');
          
          localStorage.setItem(
            'timezonestring',
            'Eastern Standard Time (EST)'
          );
          localStorage.setItem('waqtmethod', 'MWL');
          localStorage.setItem(
            'waqtMethodString',
            'Muslim World League (MWL)'
          );
        }
      }
      localStorage.setItem('user', true);
      if(props.history.location.state.teamMember) {
        props.history.replace('/dashboard')
      } else {
        props.history.replace('/onboarding')
      }
      
    } catch (error) {
      console.log(error)
      // setError(
      //   error && error.response && error.response.data.message
      // )
    }
  }

  return (
    <div style={{ paddingBottom: '40px', posiiton: 'relative' }}>
      <div style={{ position: "absolute", width: '100%', zIndex: -1 }} >
        <div className='Background-Image' style={{ width: '100%', position: 'fixed', height: '100vh' }}>
        </div>
      </div>
      <div className="signup-container div_backgrndimg-pattern" style={{ paddingTop: 40 }}>
        <VerificationSuccess
          src={Logo}
          name="verifySuccess"
          title="Verification Successful"
          para={["We are getting a few things ready for you."]}
          btnText="GO TO DASHBOARD"
          btn={false}
          loader={true}
          showBox={true}
          showfooter={true}
          onLoadingComplete={() => onClick()}
          img={Done}

        />
      </div>
    </div>
  );
}

export default Success;
