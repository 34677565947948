import axios from "axios";

const baserUrl =
  "https://cors-anywhere.herokuapp.com/http://18.211.207.245:3000";
const env = {
  prod: process.env.REACT_APP_PAYMENT_PROD_API,
  dev: process.env.REACT_APP_PAYMENT_DEV_API
};
const orgEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")
) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}
const orgBaseUrl = orgEnv[currentEnv];

export function getSubscriptionDetail(id){
    return new Promise((resolve, reject) => {
      axios
        .get(`${orgBaseUrl}/newsletter/getById/${id}`,{
          headers:{
            
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function unSubscribeFromNewsLetter(id, source) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${orgBaseUrl}/newsletter/unsubscribe/${id}`, {source},{
          headers:{
            
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function unSubscribeFromNewsLetterReason(id, reason) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${orgBaseUrl}/newsletter/unsubscribe-reason/${id}`, {reason: reason},{
          headers:{
            
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function reSubscribeFromNewsLetterReason(id) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`${orgBaseUrl}/newsletter/resubscribe/${id}`,{
          headers:{
            
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
}