import React from "react";
import { connect } from 'react-redux'
import {
  SimpleAppBar,
  BasePaper,
  NavDrawer,
  BaseIcon,
  MasterLayout,
} from "@dclab/dc-react-ui-kit";
import { Switch, Route } from "react-router-dom";
import { ReactComponent as MoreApps } from "../../assets/nav_icons/moreapps.svg";
import { ReactComponent as Support } from "../../assets/nav_icons/support.svg";
import { ReactComponent as Launch } from '../../assets/nav_icons/launch-24px.svg'
import MainLogo from '../../assets/img/DC_Logo.png';
import { ChevronRight } from '@material-ui/icons';
import PrivateRoute from "../../components/PrivateRoute";


import Members from './Members';
import Pages from './Pages';
import EditDetail from './EditDetail';
import EditEmergency from './EditEmergency';
import AddDonation from './AddDonation';
import CancelMembership from './CancelMembership';


const headerItems = [
  {
    title: "Members",
    path: "/membership/members",
  },
  {
    title: "Customize Form",
    path: "/deenfund/manage-donors",
  },
];

const footerItems = [
  {
    title: "More Apps",
    icon: <BaseIcon icon={MoreApps} />,
    path: "/more-apps",
    child: [
    ],
    secondaryIcon: <BaseIcon icon={ChevronRight} />,
  },
  {
    title: "Support",
    icon: <BaseIcon icon={Support} />,
    path: "http://shapla.deenconnect.io/support",
    child: [],
    secondaryIcon: <BaseIcon icon={Launch} />
  },
];
const MembershipPage = (props) => {
  function onClick() {
    props.history.push('/dashboard')
  }

  const returnActiveTab = () => {
    switch (props.match.path) {
      case '/deenfund/donations/details':
          return "Donations";
      default:
        return false;
    }
  };
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname])
  const logout = () => {
    localStorage.setItem('user', false)
    localStorage.setItem('authToken', '')
    props.history.replace('/signin')
    localStorage.clear();
  }
  const userProfile = () => {
    props.history.push("/admin-profile")
  };
  const navigate = (x, y) => {
    if (x === 'Support') {
      window.location.href = (y)
    } else {
      props.history.push(y)
    }
  }
  const getInitials = () => {
    let nameArr = localStorage.getItem("name") || (props?.signup?.fullName ?? '') ;
    nameArr = nameArr.length > 0 ? nameArr?.trim()?.split(" ") : [];
    let initials;
    if (nameArr?.length > 1) {
      initials = nameArr[0][0] + nameArr[1][0];
    } else {
      initials = nameArr?.[0]?.[0] + nameArr[0]?.[1] ?? '';
    } 
    return initials;
  }
  let image = localStorage.getItem("image");
  console.log("userImage", image)
  const content = (
    <React.Fragment>
      <MasterLayout
        header={
          <SimpleAppBar
            src={MainLogo}
            name={localStorage.getItem("name") || props.signup.fullName}
            role={localStorage.getItem("role")}
            showImg={image && image !== "undefined" ? true : false}
            img={image && image !== "undefined" ? image : ""}
            onClickLogout={() => logout()}
            onClickProfile={() => userProfile()}
            initials = {getInitials()}
            style={{ marginTop: 80, marginLeft: -10 }}
          />
        }
        sideBar={
          <NavDrawer
            headerNavItems={headerItems}
            footerNavItems={footerItems}
            onBackBtnClick={() => onClick()}
            onClick={() => console.log('')}
            buttonShow={true}
            showTitle={true}
            showBodyItem={true}
            activeTab = {returnActiveTab()}
            navigate={(x, y) => navigate(x, y)}
            selectedTitle={'Membership'}
            footerLower="footerLower"
          />
        }
        headerFixed={true}
        middleContent={
          <Switch>
            <PrivateRoute exact path="/membership/members" component={Members} />
            <PrivateRoute exact path="/membership-details" component={Pages} />
            <PrivateRoute exact path="/edit-detail" component={EditDetail} /> 
            <PrivateRoute exact path="/edit-emergency" component={EditEmergency} />
            <PrivateRoute exact path="/add-donation" component={AddDonation} />
            <PrivateRoute exact path="/cancel-membership" component={CancelMembership } />

             
          </Switch>
        }
      />
    </React.Fragment>
  );
  return (
    <>
      <BasePaper elevation={0} context={content} />
    </>
  );
};
const mapStateToProps = state => {
  return {
    signup: state.signup
  }
}
export default connect(mapStateToProps, {})(MembershipPage);
