import * as constants from '../constants'

const INITIAL_STATE = {
    editableEvent: [],
    edit: false,
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case constants.EDIT_EVENT:
            return {
                ...state,
                editableEvent: action.payload,
                edit: true
            };
        case constants.EMPTY_EVENT:
            return {
                ...state,
                editableEvent: [],
                edit: false
            };
        default:
            return state;
    }
};
