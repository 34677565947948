import React, { useEffect, useState } from "react";
import {
  SimpleTable,
  AlertAction,
  GridContainer,
  GridItem,
  MasterLayoutMiddle,
  FooterInner
} from "@dclab/dc-react-ui-kit";
import { getIntegrations, getApps } from "../../ManageApps/api";
import { getCurrentUserDetail, getPlanSubscriptionsByOrgId } from "../../UserProfile/Apis";
import { getPlans } from "../api";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../style.css";
import "../../../App.css";
import "../../style.css";
import moment from 'moment';


export default function ManagePlans(props) {
  const [plans, setplans] = useState([])
  const [apps, setapps] = useState([])
  const [planSubscription, setplanSubscription] = useState([])
  const [integrations, setintegrations] = useState([])
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState("")

  useEffect(() => {
    const getDetails = async () => {
      const token = localStorage.getItem("authToken");
      let orgId = localStorage.getItem("orgID");
      try {
        const plansRes = await getPlans(orgId, token);
        const appsRes = await getApps(token);
        const integrationsRes = await getIntegrations(token);
        console.log("plansRes", plansRes)
        setplans(plansRes?.data?.body?.data)
        setapps(appsRes?.data?.body?.data)
        setintegrations(integrationsRes?.data?.body?.data)
        const userRes = await getPlanSubscriptionsByOrgId(orgId, token);

        if (userRes?._id) {
          let planSubscription = userRes;
          const arr = Object.keys(planSubscription)
            .filter(v => {
              return planSubscription[v] != null && typeof planSubscription[v] === 'object'
            }).map(key => ({ ...planSubscription[key], planName: [key][0] }))
          setplanSubscription(arr)
          console.log("arrs", arr)
        }
        setloading(false)
      } catch (error) {
        seterror(error?.response && error?.response?.data?.message)
        setloading(false)
      }
    }
    getDetails();
  }, [])
  const capitalizeFLetter = (val) => {
    return val ? `${val[0].toUpperCase() + val.slice(1)}` : "";
  }

  let col = [
    // { id: "NO", label: "NO.", width: "auto" },
    { type: "url", name: "single_pic", label: "", width: '30%' },
    { type: "string", name: "name", label: "PLAN", width: '15%' },
    { type: "string", name: "category", label: "PRICE", width: '10%' },
    { type: "string", name: "category", label: "BILLING CYCLE", width: '10%' },
    { type: "string", name: "category", label: "STARTED", width: '25%' },
    { id: "STATUS", label: "STATUS", width: '10%' },
    { type: "action", name: "action", label: "", width: "10%" },
  ];

  let rows = [];
  planSubscription?.length && planSubscription.forEach((plan, i) => {
    if (plan.planName?.toLowerCase() === "extraintegrations") {
      plan.selectedIntegrations?.length && plan.selectedIntegrations.forEach(int => {

        let planPayment = plans?.stripeData?.length ? plans?.stripeData?.find((el) => el?.subscription_plan?.toLowerCase() === int?.name?.toLowerCase()) : {};
        let integration = integrations?.length ? integrations?.find((el) => el?.integrationApi?.toLowerCase() === int?.integrationApi?.toLowerCase()) : {};
        rows.push([
          { type: "string", name: "textImg", src: integration?.imageMetaData?.imageUrl ? integration.imageMetaData.imageUrl : "https://i.ibb.co/mhTNNDX/DEENCONNECT-Integration-Icon.png", primaryText: integration?.name, initials: "", plan: { ...planPayment, orgId: plans?.orgId, planSubscriptionId: plans?.planSubscriptionId, planName: integration?.name, planType: "integration" } },
          { type: "string", name: "name", value: capitalizeFLetter(integration?.pricing?.pricingType) },
          { type: "string", name: "category", value: int.paymentFrequency === "monthly" ? `$${int.price}/mo` : int.price ? `$${(parseFloat(int.price) / 12).toFixed(2)}/mo` : "$0/mo" },
          { type: "string", name: "category", value: capitalizeFLetter(int.paymentFrequency) ?? "N/A" },
          { type: "string", name: "category", value: int?.createdAt ? moment(int?.createdAt).format('MMM DD, YYYY') : planPayment?.created ? moment(planPayment.created * 1000).format('MMM DD, YYYY') : "N/A" },
          {
            type: "status",
            status: "success",
            value: "Active",
          },
          { type: "action", status: "details", value: "Manage Plan" }
        ])
      });
    } else if (plan.plan) {
      let planPayment = plans?.stripeData?.length ? plans?.stripeData?.find((el) => el?.subscription_plan?.toLowerCase() === plan?.planName?.toLowerCase()) : {};

      let app = apps?.length ? apps?.find((el) => el?.name?.toLowerCase() === plan?.planName?.toLowerCase()) : {};
      rows.push([
        { type: "string", name: "textImg", src: app?.iconMetaData?.iconUrl ? app.iconMetaData.iconUrl : "https://i.ibb.co/mhTNNDX/DEENCONNECT-Integration-Icon.png", primaryText: app?.displayName ?? app?.name, initials: "", plan: { ...planPayment, orgId: plans?.orgId, planSubscriptionId: plans?.planSubscriptionId, planName: plan?.planName, planType: "application" } },
        { type: "string", name: "name", value: plan.plan ? capitalizeFLetter(plan.plan) : "" },
        { type: "string", name: "category", value: plan.paymentFrequency === "monthly" ? `$${plan.total}/mo` : plan.total ? `$${(parseFloat(plan.total) / 12).toFixed(2)}/mo` : "$0/mo" },
        { type: "string", name: "category", value: capitalizeFLetter(plan.paymentFrequency) ?? "N/A" },
        { type: "string", name: "category", value: plan?.createdAt ? moment(plan?.createdAt).format('MMM DD, YYYY') : planPayment?.created ? moment(planPayment.created * 1000).format('MMM DD, YYYY') : "N/A" },
        {
          type: "status",
          status: "success",
          value: "Active",
        },
        { type: "action", status: "details", value: "Manage Plan" }
      ])
    }
  });

  // planSubscription?.length && planSubscription.forEach((plan, i) => {
  //   if(plan.planName?.toLowerCase() === "extraintegrations") {
  //     plan.selectedIntegrations?.length && plan.selectedIntegrations.forEach(int => {

  //       let planPayment = plans?.stripeData?.length ? plans?.stripeData?.find((el) => el?.subscription_plan?.toLowerCase() === int?.name?.toLowerCase()) : {};

  //       let integration = integrations?.length ? integrations?.find((el) => el?.name?.toLowerCase() === int?.name?.toLowerCase()) : {};


  //       rows.push([
  //         { type: "string", name: "textImg", src: integration?.iconMetaData?.iconUrl ? integration.iconMetaData.iconUrl : "https://i.ibb.co/mhTNNDX/DEENCONNECT-Integration-Icon.png", primaryText: int?.name, initials: "", plan: {...planPayment, orgId: plans?.orgId, planSubscriptionId: plans?.planSubscriptionId, planName: int?.name, planType: "integration"} },
  //         { type: "string", name: "name", value: capitalizeFLetter(integration?.pricing?.pricingType) },
  //         { type: "string", name: "category", value: int.paymentFrequency === "monthly" ? `$${int.price}/mo` : int.price ? `$${(parseFloat(int.price) / 12).toFixed(2)}/mo` :  `$0/mo`  },
  //         { type: "string", name: "category", value: int.paymentFrequency ?? "" },
  //         { type: "string", name: "category", value: int?.createdAt ? moment(int?.createdAt).format('lll') : planPayment?.created ? moment(planPayment.created * 1000).format('lll') : "N/A" },
  //         {
  //           type: "status",
  //           status: "success",
  //           value: "Active",
  //         },
  //         { type: "action", status: "details", value: "Manage Plans" }
  //       ])
  //     });
  //   } else if(plan.plan) {
  //     let planPayment = plans?.stripeData?.length ? plans?.stripeData?.find((el) => el?.subscription_plan?.toLowerCase() === plan?.planName?.toLowerCase()) : {};

  //     let app = apps?.length ? apps?.find((el) => el?.name?.toLowerCase() === plan?.planName?.toLowerCase()) : {};
  //     rows.push([
  //       { type: "string", name: "textImg", src: app?.iconMetaData?.iconUrl ? app.iconMetaData.iconUrl : "https://i.ibb.co/mhTNNDX/DEENCONNECT-Integration-Icon.png", primaryText: app?.name, initials: "", plan: {...planPayment, orgId: plans?.orgId, planSubscriptionId: plans?.planSubscriptionId, planName: app?.name, planType: "application"} },
  //       { type: "string", name: "name", value: plan.plan ? capitalizeFLetter(plan.plan) : "" },
  //       { type: "string", name: "category", value: plan.paymentFrequency === "monthly" ? `$${plan.total}/mo` : plan.total ? `$${(parseFloat(plan.total) / 12).toFixed(2)}/mo` : `$0/mo` },
  //       { type: "string", name: "category", value: plan.paymentFrequency ?? "" },
  //       { type: "string", name: "category", value: plan?.createdAt ? moment(plan?.createdAt).format('MMM DD, YYYY, hh:mm A') : planPayment?.created ? moment(planPayment.created * 1000).format('MMM DD, YYYY, hh:mm A') : "N/A" },
  //       {
  //         type: "status",
  //         status: "success",
  //         value: "Active",
  //       },
  //       { type: "action", status: "details", value: "Manage Plan" }
  //     ])
  //   }
  // });

  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const style = {
    width: '100%',
  };

  const actionLinkHandler = (data) => {
    let details;
    if (data[0]?.plan?.planType === "application") {
      details = apps?.find((el) => el?.name?.toLowerCase() === data[0]?.plan?.planName)
      details = { ...details, appType: "application" }
    } else {
      details = integrations?.find((el) => el?.name?.toLowerCase() === data[0]?.plan?.planName?.toLowerCase());
      details = { ...details, appType: "integration" }
    }

    console.log("id is: ", data[0]?.plan, details);
    props.history.push({ pathname: `/organization/plan-details`, state: { plan: data[0].plan, details: details } });
  }



  return (
    <MasterLayoutMiddle
      header={
        <>
        </>
      }
      content={
        <>
          <GridContainer spacing={0} containerClass="grid-main">
            <GridItem itemStyle={{ marginTop: 0 }} itemClass="grid-item">
              <div className="speaker-simple-table">
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 30,
                    }}
                  >
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                  <div className="speaker-simple-table2">
                    <SimpleTable
                      showHeader={true}
                      showCol={false}
                      title="Manage Plans"
                      rows={rows}
                      columns={col}
                      colSpan={16}
                      actionSendButton={true}
                      classProps={changeClassProps()}
                      noTitle="No Plans found"
                      noPara="Add new plan."
                      noBtnText="Add Pln"
                      actionLink={(data) => actionLinkHandler(data)}
                      showIconCol={false}
                      showFooter={true}
                      showAdd={false}
                      iconTextSize={16}
                    />
                  </div>
                )}
              </div>
            </GridItem>
          </GridContainer>
        </>
      }
      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  );
}


