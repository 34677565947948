import React, { Component } from "react";
import {
  SimpleTable,
  AlertAction,
  GridContainer,
  GridItem,
  MasterLayoutMiddle,
  FooterInner
} from "@dclab/dc-react-ui-kit";
import Moment from "moment";
import Edit from "../../../assets/img/create-24px.svg";
import Delete from "../../../assets/img/delete_forever-24px.svg";
import {
  getAllSalah,
  getSalah,
  Deletesalah,
  organizations,
} from "./Apis/index";
import "../../../App.css";
import "../../style.css";
import { SettingsOpener } from "@dclab/dc-react-ui-kit";
import { editSalahAction } from "../../../redux/actions/salahAction";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { hideAlert } from '../../../redux/actions/alertActions';
import messages_content from '../../../utils/messages.json';
const { 
    token_expired,
    success_message_salah,
    success_message_on_update_salah,
    remove_salah_message
  } = messages_content;
class Managesalah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      salahresponse: [],
      error: "",
      deletesalahId: "",
      modalLoading: "",
      openAlert: "",
      isloading: false,
      successAlert: false,
      orgSalahSetting: {}
    };
  }

  getTimeStamp = (time) => {
    let timestamp = Moment(time).format("x");
    return timestamp
  }
  async componentDidMount() {
    this.setState({ isloading: true });
    localStorage.removeItem("salahObj");
    localStorage.removeItem("LocationState");
    localStorage.removeItem("StartDate");
    localStorage.removeItem("EndDate");
    localStorage.removeItem("repeats");
    localStorage.removeItem("newMethod");
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const res = await getAllSalah(orgId, token);
      console.log("Salah Info")
      console.log(res.data.body.data)
      const orgres = await organizations(orgId, token);
      const orgAddress = orgres.data.body.data[0].orgAddress;
      const orgName = orgres.data.body.data[0].orgName;
      localStorage.setItem("orgaddress", JSON.stringify(orgAddress));
      localStorage.setItem("organame", orgName);
      this.state.orgSalahSetting = orgres.data.body.data[0].salahSetting
      
      let salahData = res.data.body.data.reverse();

      console.log("salahData: ", salahData)
      // let sortedSalah = salahData?.length > 100 ? salahData?.filter((el) => el?.order).sort((a, b) => {
      //   return this.getTimeStamp(a.salahStart.iqamahTime) - this.getTimeStamp(b.salahStart.iqamahTime)
      // }) : salahData

      let sortedSalah = salahData ; 
      
      console.log("sortedSalah: ",sortedSalah)
      this.setState({
        salahresponse: sortedSalah,
        isloading: false,
        orgSalahSetting: orgres.data.body.data[0].salahSetting
      });
    } catch (error) {
      this.setState({
        error: error && error.response && error.response.data.message,
        isloading: false,
      });
      setTimeout(() => {
        this.setState({error: false})
      }, 8000);
    }
    if(this.props?.location?.successAlert) {
      this.setState({successAlert: true})
      setTimeout(() => {
        this.setState({successAlert: false})
      }, 8000);
    }
    setTimeout(() => {
      this.props.hideAlert()
    }, 8000)
  }
  
  editSalah = async (e) => {
    try {

      console.log('this.state.orgSalahSetting')
      console.log(this.state.orgSalahSetting)
      
      const token = localStorage.getItem("authToken");
      const res = await getSalah(e[0].label, token);
      this.props.editSalahAction(res.data.body.data);
      this.props.history.push({pathname: "/editsalah", state: this.state.orgSalahSetting});
    } catch (e) {
      this.setState({
        error: e.response && e.response.data.message,
        showAlert: true,
      });
      setTimeout(() => {
        this.setState({error: false})
      }, 8000);
    }
  };
  handleClose = () => {
    this.setState({ openAlert: false });
  };
  handleServiceConfirm = async () => {
    const { salahresponse, deletesalahId } = this.state;
    this.setState({ modalLoading: true });
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      await Deletesalah(deletesalahId, token,orgId, "");
      const newSalah = salahresponse.filter(
        (item) => item._id !== deletesalahId
      );
      this.setState({
        salahresponse: newSalah,
        success: true,
        openAlert: false,
        modalLoading: false,
      });
      setTimeout(() => {
        this.setState({success: false})
      }, 8000);
    } catch (error) {
      this.setState({
        error: error && error.response && error.response.data.message,
        showAlert: true,
        openAlert: false,
        modalLoading: false,
      });
      setTimeout(() => {
        this.setState({error: false})
      }, 8000);
    }
  };
  onClick = () => {
    this.props.history.push("salahsettings");
  };
  deleteSalah = async (e) => {
    this.setState({
      openAlert: true,
      deletesalahId: e,
    });
  };
  render() {
    const {
      error,
      salahresponse,
      showAlert,
      openAlert,
      modalLoading,
      isloading,
      success
    } = this.state;
    const { alertValue } = this.props
    let classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };

    

    const formatAMPM = (timeString) => {
      const H = +timeString.substr(0, 2);
      const h = H % 12 || 12;
      const ampm = H < 12 || H === 24 ? "AM" : "PM";
      return (timeString = `${h}${timeString.substr(2, 3)} ${ampm}`);
    };
    const col = [
      { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
      { type: "link", name: "salahName", label: "Salah Name", width: '58%'},
      // { type: "string", name: "waqt/date", label: "WAQT", width: '15%' },
      {
        type: "string",
        name: "iqamah/date",
        label: "IQAMAH TIME",
        width: '15%'
      },
      // { type: "string", name: "repeat", label: "REPEAT", width: '15%' },
      { type: "string", name: "status", label: "STATUS", width: '20%'},
      { type: "action", name: "action", label: "ACTION", width: '20%' },
    ];
    let rows = salahresponse.map((item, i) => {
      const salahName = item.salahName;
      console.log("Wakt Time: ", item.salahStart.waktTime);
      var tempDateObj = new Date(item.salahStart.waktTime);
      var utcOffset = tempDateObj.getTimezoneOffset();
      
      console.log("Wakt Offset: ", utcOffset/60);
      const getSalah = salahName ? salahName.fullName.split(" (")[0] : '';
      const getSalahDesc = salahName ? salahName.fullName.replace(getSalah + " ", "") : '';
     
      //Only these two lines should be added to add Iqamah Time in Salah Time. Then convert it to EST or any other TimeZone
      // const tempIqamahOffset = item.dynamicIqamahDetails.mins;
      // const dynamicIqamahTime = Moment(item.waqt.waqtDate).add(tempIqamahOffset, 'minutes').utcOffset('+0000').format('hh:mm A')
      
      // console.log('Iqamah')
      // console.log(dynamicIqamahTime)

      return [
        { type: "id", value: "0" + (i + 1), label: item._id },
        { type: "string", name: "siblings", value: [getSalah, getSalahDesc] },
        // {
        //   type: "string",
        //   name: "date",
        //   value: item?.salahStart?.waktTime ? Moment(item.salahStart.waktTime).utcOffset(0).format('hh:mm A') : 'No Waqt',
        // },
        // { type: "string", 
        // name: "date", 
        // value:  item?.salahStart?.iqamahTime ? Moment(item.salahStart.iqamahTime).utcOffset(0).format('hh:mm A'): ''},
        {
          type: "string",
          name: "date",
          value: item?.repeat?.category?.split(" ")[0],
        },
        {
          type: "status",
          status: item.status === 'true' ? "success" : "failed",
          value: item.status === 'true' ? "Active" : "Inactive",
        },
      ];
    });
    const alertServiceModal = (
      <Dialog
        open={openAlert}
        onClose={() => this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Salah?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this salah? <br/> Deleting will permanently remove from everywhere.
          </DialogContentText>
        </DialogContent>
        {modalLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 50,
            }}
          >
            <CircularProgress
              color="primary"
              style={{ width: 24, height: 24 }}
            />
          </div>
        ) : (
            <DialogActions>
              <Button onClick={() => this.handleClose()} color="primary">
                Cancel
            </Button>
              <Button onClick={() => this.handleServiceConfirm()} color="primary">
                Confirm
            </Button>
            </DialogActions>
          )}
      </Dialog>
    );
  const style = {
      width: '100%',
    };
    return (
      <MasterLayoutMiddle
        content={
          <>
            {this.state.successAlert && (
                <div
                  style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                >
                  <AlertAction
                    alert={"Salah settings saved successfully!"}
                    alertType={'success'}
                    onClick={() => this.setState({successAlert: false})}
                  />
                </div>
              )}
            {error && error === "Invalid token" && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => this.setState({ error: false })}
                />
              </div>
            )}
            {success && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={remove_salah_message}
                  alertType={"success"}
                  onClick={() => this.setState({ error: false })}
                />
              </div>
            )}
            {showAlert && error && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={
                    error === "Invalid token"
                      ? token_expired
                      : error
                  }
                  alertType={"error"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {!error && alertValue && alertValue === 'addSalah' && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                }}
              >
                <AlertAction
                  alert={success_message_salah}
                  alertType={"success"}
                  onClick={() => this.props.hideAlert()}
                />
              </div>
            )}
            {!error && alertValue && alertValue === 'editSalah' && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                }}
              >
                <AlertAction
                  alert={success_message_on_update_salah}
                  alertType={"success"}
                  onClick={() => this.props.hideAlert()}
                />
              </div>
            )}
            {isloading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                <CircularProgress
                  color="primary"
                  style={{ width: 24, height: 24 }}
                />
              </div>
            ) : (
                <GridContainer>
                  <GridItem>
                    <SimpleTable
                      showHeader={true}
                      title="Salah"
                      rows={rows}
                      columns={col}
                      colSpan={16}
                      actionSendButton={true}
                      classProps={changeClassProps()}
                      donationsCount={rows && rows.length > 0 ? 1 : 0}
                      noTitle="No Salah added"
                      noPara="Added Salah will be displayed and managed here"
                      noBtnText="Add New Salah"
                      onInviteClick={() => this.props.history.push({pathname: "/addsalah", state: this.state.orgSalahSetting})}
                      actionLink={(name) => this.details(name)}
                      showIconCol={true}
                      editIcon={Edit}
                      deleteIcon={Delete}
                      editAction={(e) => this.editSalah(e)}
                      deleteAction={(e) => this.deleteSalah(e[0].label)}
                      showFooter={true}
                      showAdd={true}
                      addText="ADD NEW"
                      onAddClick={() => this.props.history.push({pathname:"/addsalah", state: this.state.orgSalahSetting})}
                      iconTextSize={16}
                    />
                    {salahresponse && salahresponse.length > 0 && (
                      <div>
                        <p className="service-helper-text">
                          "Active" salah are available with publishing date and time
                          from applications
                            </p>
                      </div>
                    )}
                  <div className="salahsettingContainer" onClick={() => this.onClick()}>
                      <SettingsOpener
                        title="Salah"
                        primaryPara="Change and save salah related settings from here."
                        onClick={() => this.onClick()}
                      />
                    </div>
                  </GridItem>
                  {openAlert && alertServiceModal}
                </GridContainer>
              )}
          </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style}
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={false}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    services: state.services.services,
    alertValue: state.alert.alertValue
  };
};

const mapDispatchToProps = (dispatch) => ({
  editSalahAction: (res) => dispatch(editSalahAction(res)),
  hideAlert: (res) => dispatch(hideAlert(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Managesalah);
