import React, { useEffect } from "react";
import Moment from "moment";
import MomentTime from "moment-timezone";
import {
  BaseTextField,
  BaseButton,
  BaseStepper,
  BaseSelect,
  TextEditor,
  FormPublishUnpublish,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import Grid from '@material-ui/core/Grid';
import { useState } from "react";
import "./style.css";
import { getAnnoucements, updateAnnoucement } from "../Donations/deen-kioks-website/Apis";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
import { editOrganization, getOrganizationData } from "../Organization/api";
import { getAnnoucementsWithAppId } from "./api";
const {token_expired, success_message_announcement, publish_date_before_unpublish} = messages_content;
function AddAnnouncements(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [Announcement, setAnnouncement] = React.useState([]);
  const [AnnouncementRes, setAnnouncementRes] = React.useState([])
  const [announcementName, setannouncementName] = React.useState('')
  const [urlLink, setUrlLink] = React.useState('');
  const [buttonLabel, setButtonLabel] = React.useState('')
  const [checked, setChecked] = React.useState(false);
  const [error, seterror] = useState(false);
  const [visibility, setVisibility] = React.useState("Public");
  const [publish, setPublish] = React.useState("Immediately");
  const [unPublish, setUnPublish] = React.useState("Never");
  const [publishDate, setPublishDate] = React.useState("Immediately");
  const [publishTime, setPublishTime] = React.useState("");
  const [unPublishDate, setUnPublishDate] = React.useState("Never");
  const [unPublishTime, setUnPublishTime] = React.useState("");
  const [Loading, setLoading] = React.useState(true);
  const [UTCDate, setUTCDate] = React.useState('');
  const [UTCTime, setUTCTime] = React.useState('');
  const [UTCDateUnPublish, setUTCDateUnPublish] = React.useState('');
  const [UTCTimeUnPublish, setUTCTimeUnPublish] = React.useState('');
  const [unPublishTimestamp, setUnPublishTimestamp] = React.useState(0);
  const [onSelectAnnouncement, setOnSelectAnnouncement] = React.useState([]);
  const [publishedDateAndTime, setPublishedDateAndTime] = React.useState('');
  const [unPublishedDateAndTime, setUnPublishedDateAndTime] = React.useState('');
  const [validUnPublish, setValidUnPublish] = React.useState(true);
  const [validPublish, setValidPublish] = React.useState(true);
  const [errorAnnouncement, setErrorAnnouncement] = React.useState(false);
  const [existingAnnouncement, setExistingAnnouncement] = React.useState({});
  const [integration, setIntegration] = React.useState({});
  const [dateError, setDateError] = React.useState(false);
  const [disablePublish, setdisablePublish] = useState(false);

  const [Desc, setDesc] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);
  useEffect(() => {
    getAnnouncement();
  }, [])
  async function getAnnouncement(params) {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    let AnnouncementList = [];
    try{
      const getResAnnoucements = await getAnnoucementsWithAppId(token, orgId, 'deenweb')
      AnnouncementList = getResAnnoucements.data.body.data;
    }catch(err){
      setLoading(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
    try {
      setLoading(true);
      const response = await getAnnoucements(orgId, token);
      const res = response.data.body.data
      const getActiveAnnouncement = res.filter(text => text.status === 'true')
      const Ids = AnnouncementList.length ? AnnouncementList.map(t => t.announcement._id) : [];
      const filter = getActiveAnnouncement.filter(text => !Ids.includes(text._id))
      setAnnouncementRes(getActiveAnnouncement);
      let arr = []
      filter.map(tex => {
        arr.push({ value: tex.title, label: tex.title })
      })
      setAnnouncement(arr);
      setLoading(false);
    } catch (err) {
      console.log(err)
      setLoading(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const getPublishDate = (value, boolean) => {
    if(value === "Immediately") {
      setUTCDate('');
      setPublishedDateAndTime('');
      setPublish("Immediately");
      setPublishDate("Immediately")
    } else {
      setValidPublish(false);
      setPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDate(Moment(value).format('YYYY-MM-DD'));
    }
  };
  const getPublishTime = (value, boolean) => {
    setPublishTime(Moment(value).format("h:mm A"));
    setUTCTime(Moment(value).format("HH:mm:ss"));
  };

  const getUnPublishDate = (value, boolean) => {
    if(value == "Never") {
      setUTCDateUnPublish('');
      setUnPublishedDateAndTime('')
      setUnPublish('Never');
      setUnPublishDate('Never');
    } else {
      setValidUnPublish(false);
      setUnPublishTimestamp(value);
      setUnPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDateUnPublish(Moment(value).format('YYYY-MM-DD'));
    }
  };

  const getUnPublishTime = (value, boolean) => {
    console.log("getUnPublishTime", value)

    setUnPublishTime(Moment(value).format("h:mm A"));
    setUTCTimeUnPublish(Moment(value).format("HH:mm:ss"));
  };
  const onVisibilitySave = (e) => {
    console.log(e, "visibility saved");
  };
  const onVisibilityCancel = () => {
    console.log("visibility cancelled");
  };
  const onPublishSave = () => {
    if (publishDate === '' || publishDate === 'Immediately') {
      setValidPublish(false);
    }
    let Time = publishTime.split(' ');
    let dated = MomentTime(new Date(publishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = `${dated + 'T' + Hours}:00.00Z`
    setPublishedDateAndTime(date);
  };
  const onPublishCancel = () => {
    console.log("publish date cancel");
  };
  const onUnPublishSave = () => {
    if (unPublishDate === '' || unPublishDate === 'Never') {
      setValidUnPublish(false);
    }
    let Time = unPublishTime.split(' ');
    let dated = MomentTime(new Date(unPublishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = `${dated + 'T' + Hours}:00.00Z`
    setUnPublishedDateAndTime(date);
  };
  const onUnPublishCancel = () => {
  };
  const convertUTC = (date) => {
    return date.toISOString()
  }
  const onSave = async () => {
    if(disablePublish) {
      return
    }
    setdisablePublish(true)
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    const data = UTCDate === '' ? new Date() : new Date(`${UTCDate + ' ' + UTCTime}`)
    const dataU = convertUTC(data);
    const dataE = UTCDateUnPublish === '' ? new Date() : new Date(`${UTCDateUnPublish + ' ' + UTCTimeUnPublish}`)
    const UnpublishTime = convertUTC(dataE);
    const Check = {
      website: {
        status: visibility,
        publishFrom: publishedDateAndTime === '' ? publish : dataU,
        publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime
      }
    }
    const dataObj = {
      "addSelectedArrayData": {
          "enabledIntegrations.deenweb.announcements.selectedAnnouncements": [{
            announcement: onSelectAnnouncement[0]._id,
            publishFrom: publishedDateAndTime === '' ? publish : dataU,
            publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime, // DATE OBJECT
            status: visibility,
	          featured: false
          }]
        },
        "enabledIntegrations.deenweb.announcements.subscriptionType": "free"
      }
    const previousUnpublish = Check.website.publishFrom !== 'Immediately' ? Check.website.publishTo > Check.website.publishFrom : true
    if (Check.website.publishTo === 'Never' || previousUnpublish) {
      try {

        await editOrganization(dataObj, orgId, token);
        props.alertShow(success_message_announcement);
        props.history.push("/website/announcements");
      } catch (err) {
        seterror(err.response && err.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    } else {
      setDateError(true)
      setTimeout(() => {
        setDateError(false)
      }, 8000)
    }
  };
  const onBack = () => {
    setActiveStep(0)
  };
  const handleNext = () => {
    if (announcementName === '') {
      setErrorAnnouncement(true)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleChange = (prop) => (event) => {
    if (prop === 'Announcement') {
      let AnnouncementList = AnnouncementRes;
      setannouncementName(event.target.value)
      let select = AnnouncementList.filter(tex => { return tex.title === event.target.value })
      let desc = JSON.parse(select[0].description)
      setDesc(desc);
      setOnSelectAnnouncement(select)
      setButtonLabel(select[0].button.buttonLabel)
      setUrlLink(select[0].button.url)
    }
  };
  const styles = {
    stepper: {
      borderRadius: "30px",
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    select: {
      margin: "14px 0px 0px 0px",
      minWidth: 120,
      height: 66,
      width: 120,
      border: "#D3D8DA",
      width: "100%",
    },

    BaseTextField: {
      width: "100%",
    },
    editor: {
      padding: 16,
    },
    formBody: {
      background: "#fff",
    },

    formTitle: {
      fontSize: 22,
      color: "#5A5A5A"
    },

    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
      marginTop: 7,
    },

    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },

    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
  };

  const getSteps = () => {
    return [
      { number: 1, label: "Select Announcement" },
      { number: 2, label: "Publish" },
    ];
  }

  const style = {
    width: '100%',
  };

  return (
    <MasterLayoutMiddle
      header={
        <>
          {
            dateError &&
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
              <AlertAction
                alert={publish_date_before_unpublish}
                alertType={"error"}
                onClick={() => setDateError(false)}
              />
            </div>
          }
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
        </>
      }
      isForm={true}
      formData={
        Loading ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <>
            <BaseStepper
              title="Add Announcement"
              getSteps={getSteps}
              alternativeLabel={true}
              handleBackPreviousPage={() => props.history.push('/website/announcements')}
              handleBack={() => handleBack()}
              handleNext={() => handleNext()}
              nonLinear={true}
              orientation={"horizontal"}
              btnStyles={styles.stepper}
              activeStep={activeStep}
            />
            <div>
              {activeStep === 0 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Announcement</h2>
                    <Typography style={styles.formTitleSub}>
                      Add “New Announcement” from Home screen &gt; Manage &gt; Announcement.
                    </Typography>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseSelect
                        value={announcementName}
                        onChange={handleChange("Announcement")}
                        object={Announcement}
                        style={styles.select}
                        helperText={Announcement.length ? "Announcements are managed at home screen" : <p>Announcement not found. For create new announcement click here: <span onClick={() => props.history.push('/annoucements')} className='addLinkTag'> Add Announcement </span></p>}
                        label="Select Announcement"
                        error={errorAnnouncement || Announcement.length ? false : true}
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <TextEditor
                      label={"Description"}
                      disabled={true}
                      value={Desc}
                     // helperText={"Helper Text"}
                      style={styles} />
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="SolidBorder"></div>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={buttonLabel}
                        InputProps={false}
                        phone={false}
                        search={false}
                        style={styles.BaseTextField}
                        color="primary"
                        disabled={true}
                       // helperText="Helper text"
                        label="Button Label"
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={urlLink}
                        InputProps={false}
                        phone={false}
                        search={false}
                        style={styles.BaseTextField}
                        color="primary"
                        disabled={true}
                      //  helperText="Helper text"
                        label="Add Link"
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="form_button">
                      <span className="btnCencel">
                        <BaseButton text="CANCEL" onClick={() => props.history.push('/website/announcements')} />
                      </span>
                      <BaseButton text="CONTINUE" onClick={() => handleNext()} />
                    </div>
                  </Grid>
                </Grid>
              )}
              {activeStep === 1 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <FormPublishUnpublish
                        visibility={visibility}
                        setVisibility={setVisibility}
                        publish={publish}
                        unPublish={unPublish}
                        publishDate={publishDate}
                        publishTime={publishTime}
                        setPublishDate={setPublishDate}
                        setPublishTime={setPublishTime}
                        getPublishDate={getPublishDate}
                        getPublishTime={getPublishTime}
                        unPublishDate={unPublishDate}
                        unPublishTime={unPublishTime}
                        setUnPublishDate={setUnPublishDate}
                        setUnPublishTime={setUnPublishTime}
                        getUnPublishDate={getUnPublishDate}
                        getUnPublishTime={getUnPublishTime}
                        onVisibilitySave={onVisibilitySave}
                        onVisibilityCancel={onVisibilityCancel}
                        onPublishSave={onPublishSave}
                        onPublishCancel={onPublishCancel}
                        onUnPublishSave={onUnPublishSave}
                        onUnPublishCancel={onUnPublishCancel}
                        onSave={onSave}
                        onBack={onBack}
                        setChecked={() => setChecked(!checked)}
                        unPublishTimestamp={unPublishTimestamp}
                        minDateUnpublish={publishDate}
                        maxDatepublish={unPublishDate}
                        disabledSave={validUnPublish}
                        disabledSaveP={validPublish}
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
          </>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
  alertHide: (res) => dispatch(hideAlert(res)),

});
export default connect(0, mapDispatchToProps)(AddAnnouncements);
