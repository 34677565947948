import React from 'react';
import {
    SimpleTable,
} from '@dclab/dc-react-ui-kit';

function MembershipFees(props) {

    const style = {
        width: '100%',
    };



    // ============================== Table Start ======================================//
    let col = [
        { type: 'number', name: 'serial_number', label: 'NO.', width: 'auto' },
        { type: 'link', name: 'invoice_number', label: 'RECEIPT', width: '20%' },
        { type: 'string', name: 'name', label: 'DATE', width: '20%' },
        { type: 'string', name: 'name', label: 'AMOUNT', width: '20%' },
        { type: 'string', name: 'date', label: 'PAYMENT METHOD', width: '20%' },
        { type: 'action', label: 'ACTION', width: '10%' }

    ];

    const selected_categories = col.sort((a, b) => a.order - b.order)
    let rows = selected_categories.map((text, index) => {
        let i = index + 1
        return (
            [
                { type: 'id' },
                {
                    type: 'link',
                    name: 'invoice_number',
                    value: '1848-5131',
                    url: 'https://www.google.com',
                    linkType: "external",
                    display: "inline-block",
                },
                { type: 'string', name: 'date', value: 'May 20, 2021' },
                { type: 'string', name: 'frequency', value: 'General Membership' },
                { type: 'string', name: 'frequency', value: 'Apr 20, 2021' },
                { type: 'action', status: 'success', value: 'See Details' },

            ]
        )
    })


    // ============================== Table End ======================================//

    return (

        <div className="table_box">
            <SimpleTable
                showHeader={true}
                title="Membership Fee"
                width="100%"
                rows={rows}
                columns={col}
                colSpan={16}
                actionSendButton={true}
                donationsCount={rows && rows.length > 0 ? 1 : 0}
                noTitle="No inquiry submitted yet"
                noPara="Inquiries submitted by visitors will be visible and managed here."
                noBtnText=""
                onInviteClick={() =>
                    props.history.push(
                        "/add-donation"
                    )
                }
                actionLink={(name) => props.history.push({ pathname: "/add-donation", state: { contact: name[0].data } })}
                showIconCol={false}
                deleteAction={(e) => console.log(e)}
                showFooter={true}
                showAdd={true}
                addText="ADD DONATION"
                onAddClick={() =>
                    props.history.push(
                        "/add-donation"
                    )
                }
                iconTextSize={16}
            />
        </div>

    );
}


export default MembershipFees;