import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { markdowntoPlainHtml } from '../../utils/markdowntoreact';
import {
    BaseTabs,
    SimpleTable,
    GridContainer,
    GridItem,
    MasterLayoutMiddle,
    AlertAction, 
    FooterInner
} from '@dclab/dc-react-ui-kit';
import {
    getServices,
    deleteService,
    editOrganization,
    getService,
    getOrganizationData,
} from '../Organization/api';
import { editServiceAction } from '../../redux/actions/servicesAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../App.css';
import '../style.css';
import { hideAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
import Verifiedicon from '../../assets/img/VerifiedIcon.svg';


const orgEnv = {
    prod: process.env.REACT_APP_BASEURL_PROD_API,
    dev: process.env.REACT_APP_BASEURL_DEV_API
  };
  
  let currentEnv;
  if (
    window.location.host.includes("amazon") ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("shapla")
  ) {
    currentEnv = "dev";
  } else {
    currentEnv = "prod";
  }
  
  const orgBaseUrl = orgEnv[currentEnv];
  
  const {
    add_com_contact_success,
      remove_com_contact_message,
      remove_com_group_message,
  } = messages_content;

class ComuContactsListing extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            tabInd: 0,
            activeTab: 0,
            items: [],
            groups: [],
            error: '',
            showAlert: false,
            success: false,
            deleteSuccess: false, 
            addContactSuccess: false ,
            deleteSuccessGroup: false ,
            successMessage: add_com_contact_success , 
            facilitiesArr: [],
            orgFacilities: [],
            openAlert: false,
            deleteServiceId: '',
            loading: true,
            modalLoading: false,
            facilitiesLoading: false,
            removeFacility: false,
        };
        this.onClick = this.onClick.bind(this);
        this.editService = this.editService.bind(this);
        this.deleteServiceAction = this.deleteServiceAction.bind(this);
        this.getCommunicationsData = this.getCommunicationsData.bind(this);
        this.saveFacilities = this.saveFacilities.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleServiceConfirm = this.handleServiceConfirm.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
    }

    async componentDidMount() {
        const token = localStorage.getItem('authToken');
        let orgId = localStorage.getItem('orgID');
        const alertStatus = this.props.match.params.status ; 

        

        if( alertStatus ){

           

            if( alertStatus == 'deleteSuccesGroup' ){

                this.setState({
                    deleteSuccessGroup: true, 
                    activeTab: 1 ,
                    tabInd: 1 
                });

            }else if( alertStatus == 'deleteSuccesContact'){
                this.setState({
                    deleteSuccess: true, 
                    tabInd: 0 
                });
            }else if( alertStatus == 'groupListView' ){
                this.setState({
                   activeTab: 1 ,
                    tabInd: 1
                });
            }else if( alertStatus == 'contactAddSuccess'){
                this.setState({
                    addContactSuccess: true, 
                    tabInd: 0 
                });
            }
 
            
            
            
        }
        
        try {
            
            const res = await this.getCommunicationsData('contacts' , orgId, token);
            
            if( true ){
                this.setState({
                    items: res.data.body.data,   
                                 
                    loading: false,
                });
            }
            

            const groups = await this.getCommunicationsData('groups' , orgId, token);

            if( true ){
                this.setState({
               
                    groups: groups.data.body.data,                
                    loading: false,
                });
            }
            

        } catch (error) {
            console.log( error );
            this.setState({
                error: error.response && error.response.data.message,
                loading: false,
            });
        }
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state,callback)=>{
            return;
        };
    }


    async getCommunicationsData ( type , orgId , token ){
        
        return new Promise((resolve, reject) => {
            axios
              .get(`${orgBaseUrl}/communications/findAll/${type}/${orgId}`,{
                headers:{
                  token
                }
              })
              .then((res) => {
                resolve(res);
              })
              .catch((error) => {
                reject(error);
              });
          });
    }

    details = ( row ) =>{
        const itemId = ( row[5].name );

        this.props.history.push(
            '/communication/contact-details/'+itemId
        );
    }

    groupDetails = ( row ) =>{
        const itemId = ( row[2].name );

        // console.log( row )

        this.props.history.push(
            '/communication/groups-details/'+itemId
        );
    }

    hideAlert = () => {
        setTimeout(() => {
            this.setState({ showAlert: false });
        }, 8000);
    };
    
    saveFacilities = async () => {
        this.setState({ facilitiesLoading: true });
        let orgId = localStorage.getItem('orgID');
        const token = localStorage.getItem('authToken');
        const { orgFacilities } = this.state;
        const obj = {
            orgDetails: {
                facilities: orgFacilities,
            }
        };
        try {
            const res = await editOrganization(obj, orgId, token);
            this.setState({
                success: true,
                showAlert: true,
                facilitiesLoading: false,
            });
            this.hideAlert();
        } catch (error) {
            this.setState({
                error: error.response && error.response.data.message,
                showAlert: true,
                facilitiesLoading: false,
            });
            this.hideAlert();
        }
    };
    editService = async (e) => {
        try {
            const token = localStorage.getItem('authToken');
            const res = await getService(e[0].label, token);
            this.props.editServiceHandler(res.data.body.data);
            this.props.history.push('/communication/add-contact');
        } catch (e) {
            this.setState({
                error: e.response && e.response.data.message,
            });
        }
    };
    handleClose = () => {
        this.setState({ openAlert: false });
    };
    handleServiceConfirm = async () => {
        this.setState({ modalLoading: true });
        const { services, deleteServiceId } = this.state;
        const token = localStorage.getItem('authToken');
        try {
            const res = await deleteService(deleteServiceId, token);
            const newServiceArr = services.filter(
                (ser) => ser._id !== deleteServiceId
            );
            this.setState({
                services: newServiceArr,
                success: true,
                openAlert: false,
                modalLoading: false,
            });
        } catch (error) {
            this.setState({
                error: error.response && error.response.data.message,
                showAlert: true,
                openAlert: false,
                modalLoading: false,
            });
        }
    };
    deleteServiceAction = async (e) => {
        this.setState({
            openAlert: true,
            deleteServiceId: e[0].label,
        });
    };
    onClick = (x, y) => {
        this.setState({
            tabInd: y,
        });
    };
    handleTabChange = (event, newValue) => {
        this.setState({ activeTab: newValue });
    };
    render() {
        const {
            tabInd,
            items,
            groups, 
            activeTab,
            deleteSuccess,
            deleteSuccessGroup,
            addContactSuccess,
            loading,
        } = this.state;
        if (this.props.alert.alertValue) {
            setTimeout(() => {
                this.props.alertHide()
            }, 8000);
        }

        const obj = [
            {
                label: 'LIST',
                item: '',
            },
            {
                label: 'GROUPS',
                item: '',
            },
        ];
        let col = [
            { type: 'string', name: 'single_pic', label: 'FIRST NAME', width: '21%' },
            { type: 'string', name: 'single_pic', label: 'LAST NAME', width: '21%' },
            { type: 'string', name: 'single_pic', label: 'PHONE', width: '21%' },
            { type: 'string', name: 'email', label: 'EMAIL ADDRESS', width: '21%' , verifyIcon: "true",},
            { type: 'string', name: 'single_pic', label: 'SOURCE', width: '10%' },
            { id: 'ACTION', label: 'ACTION', width: '13%' },
        ];

        let rows = items.map((singleItem, i) => { 
            let isEmailVerified = singleItem.emailVerified?'email':'amount' ;            
            return [
                { type: 'string', name: 'amount', value: singleItem.firstName },
                { type: 'string', name: 'amount', value: singleItem.lastName },
                { type: 'string', name: 'amount', value: '+' + singleItem.phoneNumber },
                { type: 'string', name: isEmailVerified, value: singleItem.email, icon: Verifiedicon ,  verifyText:"Email verified"},
                { type: "string", name: "chip", value: singleItem.source },
                { type: 'action', name: singleItem._id ,  status: 'success', value: 'SEE DETAILS' },               
            ];
        });

        let group_col = [
            { type: 'string', name: 'single_pic', label: 'GROUP NAME', width: '50.%' },
            { type: 'string', name: 'single_pic', label: '# OF CONTACTS', width: '50%' },
            { id: 'ACTION', label: 'ACTION', width: '15%' },
        ];

        let group_rows = groups.map((singleItem, i) => {
            
            // console.log( singleItem.groupName );

            return [
                { type: 'string', name: 'amount', value: singleItem.groupName.toString() },
                { type: 'string', name: 'amount', value: ( singleItem.numberOfContacts? singleItem.numberOfContacts : 0 )  },
                { type: 'action', name: singleItem._id ,  status: 'success', value: 'SEE DETAILS' },
                // { type: 'action', ,  status: 'success', value: 'SEE DETAILS' },   
            ];
        });



        let classProps = () => {
            const tempArray = [];
            col.forEach((column, index) => {
                tempArray.push(`class${index}`);
            });
            return tempArray;
        };
        const changeClassProps = () => {
            const tempArray = classProps();
            tempArray[0] = 'class15';
            return tempArray;
        };



        const style = {
            width: '100%',
        };
        return (
            <MasterLayoutMiddle
                header={
                    <>

                    </>
                }
                content={
                    <>       
                    {deleteSuccess && (
                            <div
                                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                            >
                                <AlertAction
                                    alert={remove_com_contact_message}
                                    alertType={"success"}
                                    onClick={() => this.setState({ deleteSuccess: false })}
                                />
                            </div>
                        )}     

                    {deleteSuccessGroup && (
                            <div
                                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                            >
                                <AlertAction
                                    alert={remove_com_group_message}
                                    alertType={"success"}
                                    onClick={() => this.setState({ deleteSuccessGroup: false })}
                                />
                            </div>
                        )}     
                    {addContactSuccess && (
                        <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }} >
                            <AlertAction
                                alert={add_com_contact_success}
                                alertType={"success"}
                                onClick={() => this.setState({ addContactSuccess: false })}
                            />
                        </div>
                    )}   

                        <GridContainer spacing="0">
                            <GridItem>
                                <BaseTabs
                                    title=''
                                    object={obj}
                                    onClick={(x, y) => this.onClick(x, y)}
                                    value={activeTab}
                                    onChange={(event, newValue) =>
                                        this.handleTabChange(event, newValue)
                                    }
                                />
                            </GridItem>
                            {tabInd === 0 && (
                                <>
                                    {loading ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: 30,
                                            }}
                                        >
                                            <CircularProgress color='primary' />
                                        </div>
                                    ) : (
                                        <GridItem itemStyle={{ marginTop: 0 }} itemClass='grid-item'>
                                            <div className='services-table'>
                                                <SimpleTable
                                                    showHeader={true}
                                                    title='Contact List'
                                                    width='98%'
                                                    rows={rows}
                                                    columns={col}
                                                    colSpan={16}
                                                    actionSendButton={true}
                                                    classProps={changeClassProps()}
                                                    donationsCount={rows && rows.length > 0 ? 1 : 0}
                                                    noTitle="No contacts added"
                                                    noPara="Added Contacts will be displayed and managed here"
                                                    noBtnText="Add New Contact"
                                                    onInviteClick={() => {
                                                        this.props.editServiceHandler();
                                                        this.props.history.push(
                                                            '/communication/add-contact'
                                                        );
                                                    }}
                                                    actionLink={(name) => this.details(name)}
                                                    showFooter={true}
                                                    showAdd={true}
                                                    onAddClick={() => {
                                                        this.props.editServiceHandler();
                                                        this.props.history.push(
                                                            '/communication/add-contact'
                                                        );
                                                    }}
                                                    iconTextSize={16}
                                                    addText='ADD NEW'
                                                    verifyIcon="true"
                                                    rowsPerPage={10}
                                                />
                                            </div>

                                        </GridItem>
                                    )}
                                </>
                            )}
                            {tabInd === 1 && (
                                <GridItem itemStyle={{ marginTop: 0 }} itemClass='grid-item'>
                                    <div className='services-table'>
                                                <SimpleTable
                                                    showHeader={true}
                                                    title='Contact Groups'
                                                    width='98%'
                                                    rows={group_rows}
                                                    columns={group_col}
                                                    colSpan={16}
                                                    actionSendButton={true}
                                                    classProps={changeClassProps()}
                                                    donationsCount={rows && rows.length > 0 ? 1 : 0}
                                                    noTitle="No Groups added"
                                                    noPara="Added Groups will be displayed and managed here"
                                                    noBtnText="Add New Groups"
                                                    onInviteClick={() => {
                                                        this.props.editServiceHandler();
                                                        this.props.history.push(
                                                            '/communication/create-new-group'
                                                        );
                                                    }}
                                                    actionLink={(name) => this.groupDetails(name)}
                                                    showFooter={true}
                                                    showAdd={true}
                                                    onAddClick={() => {
                                                        this.props.editServiceHandler();
                                                        this.props.history.push(
                                                            '/communication/create-new-group'
                                                        );
                                                    }}
                                                    iconTextSize={16}
                                                    addText='CREATE NEW GROUP'
                                                    verifyIcon="true"
                                                    rowsPerPage={10}
                                                />
                                            </div>
                                </GridItem>
                            )}
                        </GridContainer>

                    </>
                }
                isFooter={true}
                footer={
                    <FooterInner 
                        style={style}
                        termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
                        privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                    />
                }
                isWhiteBackground={false}
            />
        );
    }
}
const styles = {
    paperStyle: {
    },
    paperStyleNext: {
    },
};
const mapStateToProps = (state) => {
    return {
        services: state.services.services,
        alert: state.alert,
    };
};
const mapDispatchToProps = (dispatch) => ({
    editServiceHandler: (res) => dispatch(editServiceAction(res)),
    alertHide: (res) => dispatch(hideAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ComuContactsListing);
