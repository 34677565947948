import React, { Component } from "react";
import { connect } from "react-redux";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {
  BaseTextField,
  BaseButton,
  AlertAction,
  BaseFileSelect,
  MasterLayoutMiddle,
  FooterInner,
  TextEditor,
  FormSwitch
} from "@dclab/dc-react-ui-kit";
import CircularProgress from "@material-ui/core/CircularProgress";
import { emptyReminder } from "../../../redux/actions/reminderAction";
import "./style.css";
import "../../Organization/style.css";
import { getReminders, updateReminder } from "./Apis/index";
import Grid from '@material-ui/core/Grid';
import { showAlert } from '../../../redux/actions/alertActions';
import messages_content from '../../../utils/messages.json';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
const {
  token_expired,
  success_message_on_update_reminder,
  reminder_title_already_exist
} = messages_content;
class EditReminder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAlert: false,
      originalPublished: false,
      reminderTitle: "",
      reminderTitleCount: 0,
      reminderDescription: [{ type: "paragraph", children: [{ text: "" }] }],
      featured: false,
      descriptionCount: 0,
      showAlert: false,
      success: false,
      error: false,
      openDrawer: false,
      reminderImage: [],
      image: [],
      loading: false,
      getReminders: [],
      seError: false,
      reminderId: "",
    };
    this.onDescriptionStateChange = this.onDescriptionStateChange.bind(this);
    this._updateReminder = this._updateReminder.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
  }
  async componentDidMount() {
    const { editReminder } = this.props;
    if (
      typeof editReminder !== "undefined" &&
      Boolean(Object.keys(editReminder)[0])
    ) {
      const description = JSON.parse(editReminder.description);
      let sum = 0;
      description.map((st) => {
        if (st.type === "bulleted-list" || st.type === "numbered-list") {
          st.children.map((e) => {
            e.children.map((ch) => {
              sum += ch.text.length;
            });
          });
        } else {
          st.children.map((e) => {
            sum += e.text.length;
          });
        }
      });
      this.setState({
        reminderTitle: editReminder.title,
        reminderDescription: description,
        featured: editReminder.status === 'true' ? true : false,
        originalPublished: editReminder.status === 'true' ? true : false,
        reminderTitleCount: editReminder.title.length,
        descriptionCount: sum,
        reminderId: editReminder._id,
      });
    } else {
      this.props.history.push("/reminders");
    }
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const res = await getReminders(orgId, token);
      this.setState({
        getReminders: res.data.body.data,
      });
    } catch (error) {
      this.setState({
        seError: error && error.response && error.response.data.message,
      });
    }
  }
  changeHandler = (name, val) => {
    if (name === "name") {
      if (val.length < 36) {
        if (/^(?![\s.]+$)[a-zA-Z\s.]*$/.test(val)) {
          this.setState({ reminderTitle: val, reminderTitleCount: val.length });
        } else {
          return false;
        }
      }
    }
  };
  onDescriptionStateChange = (editorState) => {
    let sum = 0;
    editorState.map((st) => {
      if (st.type === "bulleted-list" || st.type === "numbered-list") {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    this.setState({
      reminderDescription: editorState,
      descriptionCount: sum,
    });
  };

  _updateReminder = async () => {
    const { getReminders } = this.state;
    this.setState({ loading: true });
    const {
      reminderDescription,
      reminderTitle,
      featured,
      descriptionCount,
      reminderId,
    } = this.state;

    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    const obj = {
      title: reminderTitle,
      description: JSON.stringify(reminderDescription),
      status: featured,
      orgId,
      frequency: "daily",
    };
    if (!reminderTitle || descriptionCount === 0) {
      this.setState({ error: true, loading: false });
    } else {
      let allReminders = getReminders.filter(
        (item) => item.title === reminderTitle
      );
      try {
        console.log("originalPublished: ", this.state.originalPublished)
        await updateReminder(obj, token, reminderId);
        this.setState({
          success: true,
          showAlert: true,
          loading: false,
        });
        this.props.showAlert('editReminder')
        this.props.history.push("/reminders");
      } catch (error) {
        this.setState({
          error: error && error.response && error.response.data.message,
          showAlert: true,
          loading: false,
        });
      }
    }
  };

  handleClose = () => {
    this.setState({ openAlert: false });
  };

  handleDeleteConfirm = () => {
    const {featured} = this.state;
    this.setState({ featured: !featured });
    this.setState({openAlert: false});
  }

  handleSwitch = () => {
    const {featured, originalPublished} = this.state;
    if(originalPublished && !featured === false) {
      this.setState({openAlert: true});
    } else {
      this.setState({ featured: !featured });
    }
  };


  render() {
    const {
      reminderTitle,
      reminderDescription,
      featured,
      descriptionCount,
      showAlert,
      success,
      error,
      openDrawer,
      image,
      loading,
      reminderTitleCount,
      openAlert
    } = this.state;
    const addDiv = (
      <>
        <div className="overlay2"></div>
        <div className="add2">
          <div style={{ marginLeft: 30, marginTop: 20 }}>
            <h3>Select Image</h3>
            <div style={{ marginBottom: 20 }}>
              <BaseFileSelect selectedFiles={(file) => this.upload(file)} />
            </div>
            <div>
              <img
                src={image.length > 0 && image[0].src}
                style={{
                  width: image.length > 0 ? 260 : 0,
                  height: image.length > 0 ? 170 : 0,
                }}
              />
            </div>
            <div className="lowerDiv" style={{ marginTop: 20 }}>
              <div
                style={{
                  marginTop: 30,
                  display: "flex",
                  justifyContent: "flex-end",
                  width: 260,
                }}
              >
                <p
                  className="cancel"
                  onClick={() =>
                    this.setState({
                      openDrawer: false,
                    })
                  }
                >
                  CANCEL
                </p>
                <BaseButton text="Save" onClick={() => this.addLogo()} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
    const style = {
      width: "100%",
    };
    const textEditorStyle = {
      border: "1px solid black",
      color: "gray",
    };

    const alertEventModal = (
      <Dialog
        open={openAlert}
        onClose={() => this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Change status to inactive?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          This content is published in other applications/sites. <br></br>
          Changing to “Inactive” will remove from everywhere.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()} color="primary">
          CANCEL
          </Button>
          <Button onClick={() => this.handleDeleteConfirm()} color="primary">
          YES
          </Button>
        </DialogActions>
      </Dialog>
    );


    return (
      <MasterLayoutMiddle
        header={
          <>
            <div
              onClick={() => this.props.history.push("/reminders")}
              className="backBtn"
            >
              <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
              <p style={{ alignSelf: "center" }}>Back</p>{" "}
            </div>
            {error && error === "Invalid token" && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => this.setState({ error: false })}
                />
              </div>
            )}
            {showAlert && error && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={
                    error === "Invalid token"
                      ? token_expired
                      : error
                  }
                  alertType={"error"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {showAlert && success && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={success_message_on_update_reminder}
                  alertType={"success"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {openAlert && alertEventModal}
          </>
        }
        isForm={true}
        formData={
          <>
            <div>
              <h1 className="title_top">Edit Reminder</h1>
              <div className="dasheddBorder"></div>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12}>  <h2 className="sub_title">Reminder Details</h2> </Grid>
              <Grid item xs={12}>
                <div className="field_box">
                  <BaseTextField
                    label="Reminder Title"
                    helperText=" "
                    value={reminderTitle}
                    onChange={(e) => this.changeHandler("name", e.target.value)}
                    error={!success && error && !reminderTitle}
                    helperText={
                      !success &&
                      error &&
                      !reminderTitle &&
                      "Please enter reminder title"
                    }
                    placeholder=" "
                  />
                  <div
                    style={{ display: "flex", justifyContent: "flex-end", }} >
                    <span className="char-count">CHAR {reminderTitleCount}/35</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextEditor
                  onChange={(e) => this.onDescriptionStateChange(e)}
                  disabled={false}
                  value={reminderDescription}
                  style={textEditorStyle}
                  label={"Description"}
                  helperText={
                    error &&
                    !descriptionCount &&
                    "Please add reminder description"
                  }
                  error={error && !descriptionCount}
                  maxCharCount={200}
                />
              </Grid>
              <Grid item xs={12}>
                <div className="field_box border_top_bottm">
                  <FormSwitch
                    headline='Reminder Status'
                    body="Enabling would make the reminder available for publishing"
                    values={featured}
                    handleSwitchChange={() => this.handleSwitch()}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <div
                    style={{
                      marginTop: 30,
                      display: "flex",
                      justifyContent: "center",
                      width: 500,
                    }}
                  >
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                    <div className="form_button">
                      <p className="cancel" onClick={() => this.props.history.push("/reminders")} >CANCEL</p>
                      <BaseButton text="SAVE" onClick={() => this._updateReminder()} />
                    </div>
                  )}
              </Grid>
            </Grid>
            {openDrawer && addDiv}
          </>
        }
        isFooter={true}
        footer={<FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />}
        isWhiteBackground={true}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    editReminder: state.reminders.editReminders,
    edit: state.reminders.edit,
    alertValue: state.alert.alertValue
  };
};
const mapDispatchToProps = (dispatch) => ({
  emptyReminder: (res) => dispatch(emptyReminder(res)),
  showAlert: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditReminder);
