import React from 'react';
import { FormSwitch, FormSelectChip } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';



function GradeLevel(props) {


    const [values, setValues] = React.useState(false);

    const handleSwitchChange = (event) => {
        setValues(event)
        console.log(event, 'swuitch')
        // setValues();
    };


    const viewChange = (newValue) => {
        console.log(newValue);
    };

    const dataset = [
        { title: "1st grade" },
        { title: "2nd grade" },
        { title: "3rd grade" },
    ];



    return (

        <div className="field_boxAcord">

            <Grid container spacing={0}>

                <Grid item xs={12} md={12}>
                    <FormSwitch
                        headline='Grade applicable'
                        body=""
                        values={values}
                        handleSwitchChange={(e) => handleSwitchChange(e)}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <FormSelectChip
                        dataset={dataset}
                        viewChange={viewChange}
                        helpertext=""
                    />
                </Grid>

            </Grid>

        </div>

    );
}


export default GradeLevel;