import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Grid from '@material-ui/core/Grid';
import { DrawerMain, MasterLayoutMiddle, FooterInner, BaseTextField , BaseSelect, BaseButton, arrowClick, DrawerListCheckbox } from "@dclab/dc-react-ui-kit";
// import './style.css'
import { getOrders, updateOrgOrder } from "../api";



function EditCommittee(props) {
  const [open, setOpen] = React.useState(false);
  const [order, setorder] = useState({
      status: "", assignee: [], id: ""
  })
  const [chipData, setchipData] = useState([])
  const [values, setvalues] = useState([])
  const [selectedData, setselectedData] = useState([])

  useEffect(() => {
    if(props?.location?.state?.order) {
        const orderData = props?.location?.state?.order;
        setorder({
            id: orderData?._id,
            status: orderData?.status ?? "New Order",
            assignee: orderData?.assignee ?? [],
        })

        orderData?.assignee?.length && setchipData(orderData?.assignee?.map((el, i) => {
            return { key: i, label: el.name, src: el.imageUrl, alt: el.primary,
          }
        }))

        orderData?.assignee?.length && setselectedData(orderData?.assignee?.map((el, i) => {
            return { id: i + 1, primary: el.name, src: el.imageUrl, secondary: el.role,
          }
        }))
    }
}, [props?.location?.state?.order])

  const handleChange = (prop) => (event) => {
    setorder({ ...order, [prop]: event.target.value });
  };

  const styles = {
    border: '1px solid black',
    color: 'gray'
  };


  const closeDrawer = () => {
    setOpen(!open);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onSave = async () => {
    setOpen(false);
    setorder({ ...order, assignee: values?.map((el, i) => {
        return { name: el.primary, imageUrl: el.src, role: el.secondary }
    })
    });
    setchipData(values?.map((el, i) => {
        return { key: i, label: el.primary, src: el.src, alt: el.primary,
      }
    }))

  }

  const onSubmit = async () => {
      console.log(order)
    const token = localStorage.getItem("authToken")

    try {
        let res = await updateOrgOrder(order.id, order, token) 
        props.history.push("/orders");
    } catch (error) {
        console.log("error", error)
    }
  }

  const getData = (values) => {
    if(!values?.length) return
    setvalues(values)
  }
  
  const style = {
    width: '100%',
  };
  
  const onInputClick = () => {
    console.log("input clicked");
  };
  
  const onChipInputClick = () => {
    console.log("input chip clicked");
    setOpen(true)
  };
  
  const deleteChip = (data) => {
    console.log('chip deleted', data)
    setorder({ ...order, assignee: [] });
    setchipData([])
  }
  
  
  const data3 = [
    {
      id: 1,
      primary: "Abdul Al Razi",
      secondary: "Admin",
      src: "https://media.gettyimages.com/photos/jama-masjid-mosque-in-delhi-picture-id505757382?s=612x612",
    },
    {
      id: 2,
      primary: "Hossain Miraz",
      secondary: "Project Lead",
      src:
        "https://media.gettyimages.com/photos/jama-masjid-mosque-in-delhi-picture-id505757382?s=612x612",
    },
  ];
  
//   const selectedData = [
    // {
    //     id: 1,
    //     primary: "Abdul Al Razi",
    //     secondary: "Admin",
    //     src: "https://media.gettyimages.com/photos/jama-masjid-mosque-in-delhi-picture-id505757382?s=612x612",
    //   },
//   ];
  

  return (

    <MasterLayoutMiddle
      header={
        <div className="backBtn">
          <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
          <Link
            to={{ pathname: "/organization/committees-details", state: 1, }}
            style={{ alignSelf: "center", color: "black", textDecoration: "none", }} > Back </Link>{" "}
        </div>
      }

      isForm={true}
      formData={
        <div>
          <Grid container spacing={3}>

            <Grid item md={12}>
              <h1 className='title_top'>Edit Committee</h1>
              <div className="dasheddBorder"></div>
            </Grid>


            <Grid item xs={12}>
              <div className="field_box">
              <BaseTextField
                label="Committee Name"
                value="Sports & Recreation"
                style={styles.btn}
                color= "primary"
                count={30}
              />
              </div>
            </Grid>


            <Grid item xs={12}>
              <div className="field_box">
                <BaseSelect
                  value="Hassan Ahmed"
                  onChange={handleChange("status")}
                  object={[
                    { value: "Hassan Ahmed", label: "Hassan Ahmed" },
                    { value: "Zeeshan Gujjar", label: "Zeeshan Gujar" },
                    { value: "Nafseen Al bani", label: "Nafseen Al bani" },
                    { value: "Waqas Ahmad", label: "Waqas Ahmad" },
                  ]}
                  style={styles.select}
                  label="Select Chair"
                />
              </div>
            </Grid>

            <Grid item md={12} className="buttons">
              <span className="btnCencel" onClick={() => props.history.goBack()}><BaseButton text="CANCEL" /></span>
              <BaseButton text="ADD Now" onClick={() => onSubmit()} />
            </Grid>

          </Grid>

          
        </div>
      }

      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}

    />
  );
}
export default EditCommittee;