import React from 'react';
import { SimpleAccordian } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import Branding from './Branding';


function BasicDetails(props) {


    const [selectedZone, setselectedZone] = React.useState(0);

    const saveChanges = (accordian) => {
        console.log("data", accordian)
        setselectedZone(0)
    }
    const onAccordianClick = (panel) => {
        console.log("onAccordianClick", panel)
        if (panel === selectedZone) {
            setselectedZone(0)
        } else {
            setselectedZone(panel)
        }
    }

    const AccordionDetails = {
        top: "0px"
        //top:"-46px"
    };


    const eData = [
        {   
            id:1,
            title: "BRANDING",
            subtext:"Hassan Ahmed",
            Edit:"EDIT",
            save:"save",
            body: <Branding />,
        },

        {   
            id:2,
            title: "FORM LINK",
            subtext:"https://nikah.deenconnect.io/bicny",
            Edit:"EDIT",
            save:"save",
            color:"#A4A7B0",
            size:"14px",
            body: <Branding />,
        },

        {   
            id:3,
            title: "VIDEOS",
            subtext:<div><RemoveCircleRoundedIcon /> Hidden</div>,
            Edit:"ADD NOW",
            save:"save",
            color:"#A4A7B0",
            size:"14px",
            body: <Branding />,
        },
      
    ];


    return (

        <>
            <Grid item xs={12}>
                {eData.map((item, i) => (
                    <SimpleAccordian
                        Title={item.title}
                        text={item.subtext}
                        TitleButtonLabel={item.Edit}
                        AccordionDetails={AccordionDetails}
                        panel={item.id}
                        id={item.id}
                        areacontrol={item.id}
                        open={selectedZone === item.id ? true : false}
                        onAccordianClick={onAccordianClick}
                        accordianName="headerCard"
                        saveChanges={item.save}
                        Cencel="CENCEL"
                        Save="SAVE CHANGES"
                        color={item.color}
                        fontSize={item.size}
                        content={
                            <div className='AccformBox'>
                                {item.body}
                            </div>
                        }
                    />
                ))}
            </Grid>
        </>

    );
}


export default BasicDetails;