import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { MasterLayoutMiddle, FooterInner, CardDetailsTable, EventLogs } from "@dclab/dc-react-ui-kit";
import '../style.css';
import Moment from "moment";
import { DeleteContact, GetNewsLetterById } from "../Messages/api";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Check from "./check_circle-24px.svg";
import Cancel from "./cancel-24px.svg";
import { serialize2 } from "../../utils/markdowntoreact";
import { AlertDialogue } from "@dclab/dc-react-ui-kit";
import unitedStatesFlag from '../../assets/img/united-states.svg'
import { stateAbbreviations } from "../../utils/statesAbbreviations";
import { getOrgData } from "../../redux/actions/organizationData";
import { CircularProgress } from "@material-ui/core";

function ManageSubscriptionsDetails(props) {
  const [contact, setcontact] = useState({})
  const [open, setOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [loading, setloading] = useState(true);
  
  
  useEffect(() => {
    const getData = async() => {
      if(props?.match?.params?.id) {
        const token = localStorage.getItem("authToken");
        const newsLetter = await GetNewsLetterById(token, props.match.params.id);
        if(newsLetter.status === 200) {
          const Contact = newsLetter.data;

        
        let subObj = {};
        let unsubObj = {};

        if(Contact?.timeline?.unsubscribed) {
          let city =  Contact?.timeline?.unsubscribed?.location?.city ? Contact.timeline.unsubscribed.location.city :  Contact?.timeline?.unsubscribed?.location;
          if(Contact?.timeline?.unsubscribed?.location?.region) {
            const regionShort = stateAbbreviations[uppercaseWords(Contact.timeline.unsubscribed.location.region?.toLowerCase())] ?? Contact.timeline.unsubscribed.location.region;
            city = city + ', ' + regionShort  ;
          }
          unsubObj = {
              id: 1, 
              icon: Cancel, 
              status: 'Unsubscribed', 
              date: Moment(Contact?.timeline?.unsubscribed?.created).format('lll'), 
              details: [
                { title: 'Reason', message: Contact?.timeline?.unsubscribed?.reason ??  "No data"},
                // { title: 'IP Address', IP: Contact?.timeline?.unsubscribed?.ip, flag: unitedStatesFlag, },
                { title: 'Location', message: city ?? 'N/A'},
          
              ]
          }
        }

        if(Contact?.timeline?.subscribed) {
          let city =  Contact?.timeline?.subscribed?.location?.city ? Contact.timeline.subscribed.location.city :  Contact?.timeline?.subscribed?.location;
          if(Contact?.timeline?.subscribed?.location?.region) {
            const regionShort = stateAbbreviations[uppercaseWords(Contact.timeline.subscribed.location.region?.toLowerCase())] ?? Contact.timeline.subscribed.location.region;
            city = city + ', ' + regionShort  ;
          }
          subObj = {
            id: Contact?.timeline?.unsubscribed ? 2 : 1, 
            icon: Check, 
            status: 'Subscribed', 
            date: Moment(Contact?.timeline?.subscribed?.created).format('lll'), 
            details: [
              { title: 'Source', message: Contact?.timeline?.subscribed?.source },
              // { title: 'IP Address', IP: Contact?.timeline?.subscribed?.ip, flag: unitedStatesFlag, },
              { title: 'Location', message: city ?? 'N/A'   },
        
            ]
          }
        }

        const temp = [];
        if(unsubObj.id) {
          temp.push(unsubObj)
        }

        if(subObj.id) {
          temp.push(subObj)
        }
        setDetails(temp);
        setcontact(Contact);
      }
      setloading(false);
    }

    }

    getData();
  }, [])


  // ======= Table Start ========//
  let header = {
    tableName: contact && contact?.name?.firstName + (contact?.name?.lastName ? ' ' + contact?.name?.lastName : ''),
  };

  let data = [
    {
      field: "Date",
      value: contact?.createdAt ? Moment(contact.createdAt).format('MMMM DD, YYYY') : "<span style = 'color: #818e94; font-size: 14px; font-family: Open Sans,sans-serif'>No data</span>"
    },
    {
      field: "Source",
      value: contact?.timeline?.subscribed?.source ?? "<span style = 'color: #818e94; font-size: 14px; font-family: Open Sans,sans-serif'>No data</span>"
    },

  ];

  const card2 = [
    {
      field: "Email",
      value: contact?.email ? contact.email : "<span style = 'color: #818e94; font-size: 14px; font-family: Open Sans,sans-serif'>No data</span>"
    },
    {
      field: "Phone",
      value: contact?.phone ? contact.phone : "<span style = 'color: #818e94; font-size: 14px; font-family: Open Sans,sans-serif'>No data</span>"
    },
  ]


  // HANDLE FOOTER LINK
  const onClick = () => {
    console.log('edit')
    props.history.push({ pathname: "/organization/contact-us-respond", state: { contact: contact } })
  }

  const onDeleteClick = () => {
    setOpen(true)
  }
  // =====Table Start ==========//
  const handleConfirm = async () => {
    const token = localStorage.getItem("authToken")
    const orgId = localStorage.getItem("orgID")
    const res = await DeleteContact(token, contact._id, orgId)
    console.log('delete', contact._id)
    props.history.push({ pathname: "/organization/contact-us", state: { showMessageAlert: true } });
    setOpen(false)
  };
  const uppercaseWords = str => str?.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());


  const style2 = {
    width: 470,
    marginLeft: 800
  };

  // ============================== Timeline Start ======================================//


  // const details = [
    
  //   {
  //     id: 1, icon: Check, status: 'Subscribed', date: 'June 03, 2020, 12:00 PM', details: [
  //       { title: 'Source', message: 'Website' },
  //       { title: 'IP Address', IP: '23.125.122.121', flag: unitedStatesFlag, },
  //       { title: 'Location', message: 'New York, NY' },

  //     ]
  //   },

  //   {
  //     id: 2, icon: Cancel, status: 'Unsubscribed', date: 'June 03, 2020, 12:00 PM', details: [
  //       { title: 'Source', message: 'Website' },
  //       { title: 'IP Address', IP: '23.125.122.121', flag: unitedStatesFlag, },
  //       { title: 'Location', message: 'New York, NY' },

  //     ]
  //   },

  // ];

  // ============================== Timeline End ======================================//


  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle

      header={
        <>
          <div
            onClick={() =>
              props.history.push('/manage-subscriptions')
            }
            className='backBtn'>
            <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
            <p style={{ alignSelf: 'center' }}>Back</p>{' '}
          </div>

        </>
      }

      content={
        <div className="detailsTableContainer">
          {loading ?
            <div style={{ position: 'absolute', top: "50%", left: "50%" }}>
              <CircularProgress color="primary" />
            </div> :
            <>

          <Grid container spacing={3} >

            <Grid item md={12} sm={12} xs={12}>
              <CardDetailsTable
                data={data}
                data2={card2}
                twoColumns
                header={header}

                Status={true}
                StatusLabel="Status"
                StatusText={contact?.status}
                statuscolor={contact?.status?.toLowerCase() === 'subscribed' ? "#3CC480" : "#B00020"}
              />
            </Grid>

            <Grid item xs={12}>
              <div className="FullTable">
                <EventLogs 
                  data={details}
                  headTitle="TIMELINE"
                  schedule={true}
                  editSchedule={(e) => console.log('editSchedule', e)}
                  deleteSchedule={(e) => console.log('deleteSchedule', e)}
                />
              </div>
            </Grid>

          </Grid>

          <AlertDialogue
            title="Are you sure?"
            description="Are you sure you want to delete this contact?"
            cancelText="Cancel"
            confirmText="Confirm"
            open={open}
            handleCancel={() => setOpen(false)}
            handleClose={() => setOpen(false)}
            handleConfirm={() => handleConfirm()}
            styles={style2}
          />
        </>
        }
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  );
}
export default ManageSubscriptionsDetails;