const INITIAL_STATE = {
    
    email: '',
    orgName:''
    
  }
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      default:
        return state
    }
  }
  