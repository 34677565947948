import React, { Component } from "react";
import {
  BaseTextField,
  BaseButton,
  AlertAction,
  MasterLayoutMiddle,
  FooterInner,
  FormSwitch,
} from "@dclab/dc-react-ui-kit";
import CircularProgress from "@material-ui/core/CircularProgress";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Grid from '@material-ui/core/Grid';
import { getCategories, addCategories } from "./apis";
import "../../App.css";
import "./style.css";
import "../style.css";
import messages_content from '../../utils/messages.json';
const {
  token_expired,
  success_messgae_add_category
} = messages_content;
export default class AddCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catName: "",
      catNameCount: 0,
      status: false,
      error: false,
      showAlert: false,
      success: false,
      loading: false,
      catLoading: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.addCategory = this.addCategory.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }
  async componentDidMount() {
    const id = localStorage.getItem("orgID");
    const res = await getCategories(id);
    this.setState({
      categories: res.data.body.data,
      catLoading: false,
    });
  }
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  handleChange = (e) => {
    const { catNameCount } = this.state;
    if (catNameCount < 30) {
      this.setState({ catName: e, catNameCount: e.length });
    } else {
      this.setState({ catNameCount: e.length });
    }
  };
  handleSwitchChange = (e) => {
    this.setState({
      status: !this.state.status,
    });
  };
  addCategory = async () => {
    const token = localStorage.getItem("authToken");
    const id = localStorage.getItem("orgID");
    const { catName, status, categories } = this.state;
    this.setState({ loading: true });
    if (!catName) {
      this.setState({ error: true, loading: false });
    } else {
      try {
        const obj = {
          name: catName,
          orgId: localStorage.getItem("orgID") || "5ea0023dc21fb5000714bed8",
          order:
            categories.length > 0 && !categories[0].status
              ? 1
              : categories.length + 1,
          status: status ? "Active" : "Inactive",
        };
        await addCategories(obj, token);
        const res = await getCategories(id);
        this.setState({
          categories: res.data.body.data,
          catName: "",
          showAlert: true,
          success: true,
          loading: false,
        });
        setTimeout(() => {
          this.props.history.replace("/deenfund/categories");
        }, 2000);
      } catch (error) {
        this.setState({
          error: error && error.response && error.response.data.message,
          showAlert: true,
          loading: false,
        });
        this.hideAlert();
      }
    }
  };
  render() {
    const {
      catName,
      status,
      error,
      success,
      loading,
      showAlert,
    } = this.state;
    const style = {
      width: '100%',
    };
    return (
      <React.Fragment>
        <MasterLayoutMiddle
          header={
            <div
              onClick={() => this.props.history.replace("/deenfund/categories")}
              className="backBtn"
            >
              <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
              <p style={{ alignSelf: "center" }}>Back</p>{" "}
            </div>
          }
          isForm={true}
          formData={
            <div>
              {showAlert && error && (
                <div
                  style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                >
                  <AlertAction
                    alert={
                      error === "Invalid token"
                        ? token_expired
                        : error
                    }
                    alertType={"error"}
                    onClick={() => this.setState({ showAlert: false })}
                  />
                </div>
              )}
              {showAlert && success && (
                <div
                  style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, right: 220 }}
                >
                  <AlertAction
                    alert={success_messgae_add_category}
                    alertType={"success"}
                    onClick={() => this.setState({ showAlert: false })}
                  />
                </div>
              )}
              <div>
                <h1 className="title_top">Add Donation Categories</h1>
                <div className="dasheddBorder"></div>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseTextField
                      value={catName}
                      onChange={(e) => this.handleChange(e.target.value)}
                      InputProps={false}
                      phone={false}
                      search={false}
                      style={{ width: 570 }}
                      label="Category Name"
                      error={error && !catName}
                      helperText={error && !catName && "Please enter category name"}
                      placeholder=""
                      count={30}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="border_top_bottm">
                    <FormSwitch
                      headline="Category Status"
                      body="Display this category to potential donors"
                      values={status}
                      handleSwitchChange={() => this.handleSwitchChange("salahStatus")}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  {loading ? (
                    <div
                      style={{
                        marginTop: 30,
                        display: "flex",
                        justifyContent: "center",
                        width: 570,
                      }}
                    >
                      <CircularProgress
                        color="primary"
                        style={{ margin: "0 auto" }}
                      />
                    </div>
                  ) : (
                      <div className="form_button">
                        <p
                          className="cancel"
                          onClick={() =>
                            this.props.history.replace("/deenfund/categories")
                          }
                        >
                          CANCEL
                      </p>
                        <BaseButton text="SAVE" onClick={() => this.addCategory()} />
                      </div>
                    )}
                </Grid>
              </Grid>
            </div>
          }
          isFooter={true}
          footer={
            <FooterInner 
              style={style}
              termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
              privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
            />
          }
          isWhiteBackground={true}
        />
      </React.Fragment>
    );
  }
}
