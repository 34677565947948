import React from "react";
import { PageHeaderWithButton, MasterLayoutMiddle, FooterInner, TextInputFixedLabel, BaseButton } from "@dclab/dc-react-ui-kit";
import checkCircle from '../../assets/img/checkCircle.svg'
import Tooltip from '@material-ui/core/Tooltip';
import './style.css'


function BuildProject() {

  const style = {
    width: '100%',
  };


  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(event, newValue);
  };


  const onClick = (x, y) => {
    console.log(x);
    console.log(y);
  };

  const saveChanges = () => {
    console.log("save");
  };

  const publish = () => {
    console.log("publish");
  };
  const back = () => {
    console.log("back");
  };

  const saveStyles = {
    backgroundColor: "#2A82F6",
  };

  const publishStyles = {
    backgroundColor: "#2A82F6",
    opacity: 0.6,
  };
  const livePauseStyles = {
    display: "flex",
    alignItems: "center",
    marginLeft: "8px",
    marginTop: "1px",
    height: "36px",
    minWidth: "114px",
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: "30px",
    borderBottomLeftRadius: "30px",
    borderTopRightRadius: "30px",
    borderBottomRightRadius: "30px",
    cursor: "pointer",
    border: "1px solid #D3D8DA",
    textTransform: "uppercase",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "114px",
    display: "none",
  };
  const livePauseTextStyle = {
    margin: "0px",
    fontSize: "14px",
    color: "#4D4D4D",
    fontWeight: 'bold',
    fontFamily: '"Roboto", "Helvetica"'
  }



  const paperStyles = {
    backgroundColor: '#fff'
  };


  const click = () => {
    console.log("button clicked");
  };

  function copyToClipboard(e) {
    let Text = document.getElementById('text')
    navigator.clipboard.writeText(Text.textContent);
    setcopyTooltip(true)
    setTimeout(() => {
      setcopyTooltip(false)
    }, 5000);
  };

  const [copyTooltip, setcopyTooltip] = React.useState(false);

  return (
    <MasterLayoutMiddle
      header={
        <div>
          <PageHeaderWithButton

            title="Build Project Page"
            indicatorColor="primary"
            style={{ color: "#fff", border: "1px solid " }}
            handleChange={(x, y) => handleChange(x, y)}
            headerColor={'#4D4D4D'}
            saveStyles={saveStyles}
            publishStyles={publishStyles}
            paperStyles={paperStyles}
            saveChanges={() => saveChanges()}
            publish={() => publish()}
            disabled={false}
            back={back}
            showBackBtn={false}
            livePauseBtnText={"Preview"}
            livePauseStyles={livePauseStyles}
            livePauseOnclick={(e) => console.log('!props.livePauseBtnText')}
            livePauseTextStyle={livePauseTextStyle}
            hiddenDraft={false}
            hiddenPublish={false}
            btnText="Back"
            saveBtn="VISIT PAGE"
          />;
        </div>
      }
      content={
        <div style={{ textAlign: "center", padding: "63px", background: "#fff", boxShadow: "0px 1px 1px #00000024", borderRadius: "4px" }}>
          <img src={checkCircle} style={{ maxWidth: "33px", }} />
          <div style={{ fontSize: "28px", color: "#4D4D4D", maxWidth: "840px", margin: "0 auto", paddingTop: "30px", marginBottom: "16px", }}>Your organization build project page is live</div>
          <div style={{ fontSize: "16px", color: "#818E94", maxWidth: "316px", margin: "0 auto", lineHeight: "28px", }}>Your organization build project page is live. Please <a style={{ fontSize: "16px", color: "#2490F9", textDecorationColor:"underline", }} href="https://shapla.deenconnect.io/about#contact-us" target="blank"> contact us</a> to update anything.</div>

          <div className="BuildProjectFieldContainer">
            <div className="BuildProjectField">
              <TextInputFixedLabel
                placeholder="https://build.masjidalaqsa.com"
                Errormessage="Error message"
                errortextStatus={false}
              //onChange={(e) => setvalue(e.target.value)}
              //value={value}
              />
            </div>
            <div className="BuildProjectButton">
              <Tooltip title="Copied!" arrow placement="top"
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => setcopyTooltip(false)}
                open={copyTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <BaseButton onClick={() => copyToClipboard()} text="COPY LINK" />
              </Tooltip>
            </div>
          </div>

        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  )
}
export default BuildProject
