import React from "react";
import { connect } from 'react-redux'
import {
  SimpleAppBar,
  BasePaper,
  NavDrawer,
  BaseIcon,
  MasterLayout,
} from "@dclab/dc-react-ui-kit";
import { Switch, Route } from "react-router-dom";

import { ReactComponent as MoreApps } from "../../assets/nav_icons/moreapps.svg";
import { ReactComponent as Support } from "../../assets/nav_icons/support.svg";
import { ReactComponent as Launch } from '../../assets/nav_icons/launch-24px.svg'
import MainLogo from '../../assets/img/DC_Logo.png'
import { ChevronRight } from '@material-ui/icons'

import ComuContactsListing from './ComuContactsListing'
import AddContact from './AddContact'
import EditContact from './EditContact'
import ContactListDetails from './ContactListDetails'
import ComunicationMessages from './Messages' 
import NewMessage from './NewMessage'
import MessagesDetails from './MessagesDetails'

import Templates from './Templates'
import CreateNewGroup from './CreateNewGroup'
import AddTemplate from './AddTemplate'
import GroupsDetails from './GroupsDetails'
import PrivateRoute from "../../components/PrivateRoute";
import "./style.css";

import '../../App.css'
const headerItems = [
  {
    title: "Messages",
    path: "/communication/messages",
  },
  {
    title: "Contacts",
    path: "/communication/contact-list",
  },
  {
    title: "Templates",
    path: "/communication/templates",
  },
];

const footerItems = [
  {
    title: "More Apps",
    icon: <BaseIcon icon={MoreApps} />,
    path: "/more-apps",
    child: [
    ],
    secondaryIcon: <BaseIcon icon={ChevronRight} />,
  },
  {
    title: "Support",
    icon: <BaseIcon icon={Support} />,
    path: "http://shapla.deenconnect.io/support",
    child: [],
    secondaryIcon: <BaseIcon icon={Launch} />
  },
];
const Communication = (props) => {
  function onClick() {
    props.history.push('/dashboard')
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname])
  const logout = () => {
    localStorage.setItem('user', false)
    localStorage.setItem('authToken', '')
    props.history.replace('/signin')
    localStorage.clear();
  }
  const userProfile = () => {
    props.history.push("/admin-profile")
  };
  const navigate = (x, y) => {
    if (x === 'Support') {
      window.location.href = (y)
    } else {
      props.history.push(y)
    }
  }
  const getInitials = () => {
    let nameArr = localStorage.getItem("name") || (props?.signup?.fullName ?? '') ;
    nameArr = nameArr.length > 0 ? nameArr?.trim()?.split(" ") : [];
    let initials;
    if (nameArr?.length > 1) {
      initials = nameArr[0][0] + nameArr[1][0];
    } else {
      initials = nameArr?.[0]?.[0] + nameArr[0]?.[1] ?? '';
    } 
    return initials;
  }
  let image = localStorage.getItem("image");
  console.log("userImage", image)
  const content = (
    <React.Fragment>
      <MasterLayout
        header={
          <SimpleAppBar
            src={MainLogo}
            name={localStorage.getItem("name") || props.signup.fullName}
            role={localStorage.getItem("role")}
            showImg={image && image !== "undefined" ? true : false}
            img={image && image !== "undefined" ? image : ""}
            onClickLogout={() => logout()}
            onClickProfile={() => userProfile()}
            initials = {getInitials()}
            style={{ marginTop: 80, marginLeft: -10 }}
          />
        }
        sideBar={
          <NavDrawer
            headerNavItems={headerItems}
            footerNavItems={footerItems}
            onBackBtnClick={() => onClick()}
            onClick={() => console.log('')}
            buttonShow={true}
            showTitle={true}
            showBodyItem={true}
            navigate={(x, y) => navigate(x, y)}
            selectedTitle={localStorage.getItem('pageTitle')}
            footerLower="footerLower"
          />
        }
        headerFixed={true}
        middleContent={
          <Switch>
            <PrivateRoute exact path="/communication/contact-list" component={ComuContactsListing} /> 
            <PrivateRoute exact path="/communication/contact-list/:status" component={ComuContactsListing} />  
            <PrivateRoute exact path="/communication/add-contact" component={AddContact} />
            <PrivateRoute exact path="/communication/add-contact/:id" component={AddContact} />
            <PrivateRoute exact path="/communication/edit-contact" component={EditContact} />
            <PrivateRoute exact path="/communication/contact-details/:id" component={ContactListDetails} />
            <PrivateRoute exact path="/communication/contact-details/:id/:status" component={ContactListDetails} />
            <PrivateRoute exact path="/communication/messages" component={ComunicationMessages} /> 
            <PrivateRoute exact path="/communication/messages/:messageType" component={ComunicationMessages} /> 
            <PrivateRoute exact path="/communication/new-message" component={NewMessage} /> 
            <PrivateRoute exact path="/communication/new-message/:id" component={NewMessage} /> 
            <PrivateRoute exact path="/communication/templates" component={Templates} />
            <PrivateRoute exact path="/communication/templates/:status" component={Templates} />
            <PrivateRoute exact path="/communication/create-new-group" component={CreateNewGroup} /> 
            <PrivateRoute exact path="/communication/create-new-group/:id" component={CreateNewGroup} /> 
            <PrivateRoute exact path="/communication/add-template" component={AddTemplate} />
            <PrivateRoute exact path="/communication/groups-details/:id" component={GroupsDetails} />
            <PrivateRoute exact path="/communication/groups-details/:id/:status" component={GroupsDetails} />
            <PrivateRoute exact path="/communication/add-template/:id" component={AddTemplate} />
            <PrivateRoute exact path="/communication/messages-details" component={MessagesDetails} />
            <PrivateRoute exact path="/communication/messages-details/:id" component={MessagesDetails} />     
          </Switch>
        }
      />
    </React.Fragment>
  );
  return (
    <>
      <BasePaper elevation={0} context={content} />
    </>
  );
};
const mapStateToProps = state => {
  return {
    signup: state.signup
  }
}
export default connect(mapStateToProps, {})(Communication);
