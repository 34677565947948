import React from "react";
import { BaseButton } from "@dclab/dc-react-ui-kit";
import Background from "../../assets/images/dark_pattern_bg.png";

const env = {
  prod: process.env.REACT_APP_STRIPE_PROD_KEY,
  dev: process.env.REACT_APP_STRIPE_DEV_KEY
};
let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")
) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}
const envStripeKey = env[currentEnv];

function ConnectionPage() {
  const routeChange = () => {
    window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${envStripeKey}&scope=read_write&redirect_uri=https://golap.deenfund.com/individual_organization/withdrawals`;
  };
  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100vh",
      backgroundImage: `url(${Background})`,
    },
    pTag: {
      color: "#fafafa",
    },
  };
  return (
    <div style={styles.container}>
      <p style={styles.pTag}>
        Click on the
        <span>
          <b> Button </b>
        </span>
        to proceed with you stripe payment
      </p>
      <BaseButton
        size="large"
        color="primary"
        type="button"
        text="CONNECT TO STRIPE"
        variant="contained"
        onClick={() => routeChange()}
        style={{ color: "#ffffff" }}
      />
    </div>
  );
}
export default ConnectionPage;