import React, { Component } from "react";
import { getVerifiedDeviceDetails } from "./Apis"
import { deleteSchedulefromDevice } from "./Apis"
import moment from "moment";
import {
  GridContainer,
  GridItem,
  PageHeaderWithTabs,
  SimpleTable,
  CardDetailsTable,
  AlertAction,
  MasterLayoutMiddle,
  FooterInner,
  Accordian, SimpleAccordian, TextInputFixedLabel
} from "@dclab/dc-react-ui-kit";
import "./style.css";
import Grid from '@material-ui/core/Grid';

import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import LiveIcon from "../../assets/nav_icons/streaming.svg";
import LiveIconWhite from "../../assets/nav_icons/streaming-white.svg";
import PauseIcon from "../../assets/nav_icons/pause.svg";
import { updateDeviceStatus } from './Apis'
import icon from '../../assets/img/playlist.png'
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import messages_content from '../../utils/messages.json';
import Moment from "moment";
import { updateDeviceById } from './Apis/index'

const {
  token_expired
} = messages_content;
class ManageDevice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      error: false,
      showAlert: false,
      success: false,
      value: 0,
      devices: [],
      deviceDetails: [],
      schedule: [],
      openDeleteModal: false,
      modalLoading: false,
      deviceName: "",
      selectedZone: 0,
      changes: false,
    };
  }
  async componentDidMount() {
    const deviceId = localStorage.getItem("deviceId")
    const token = localStorage.getItem("authToken")
    this.setState({ isloading: true });
    try {
      const res = await getVerifiedDeviceDetails(deviceId, token);
      localStorage.setItem('deviceName', res?.data?.body?.data?.device?.name ?? "")
      this.setState({
        deviceDetails: res?.data?.body?.data?.device,
        schedule: res?.data?.body?.data?.device?.schedules ?? [],
        isloading: false,
        deviceName: res?.data?.body?.data?.device?.name ?? ""
      })
      console.log("deviceDetails", res?.data?.body?.data?.device)
    } catch (error) {
      this.setState({ error: error?.response?.data?.message, isloading: false });
    }
  }

  saveChanges = () => {
    console.log("save");
  };
  publish = async () => {
    console.log("publish");
    const { deviceName } = this.state;
    const deviceId = this.state?.deviceDetails?._id
    const token = localStorage.getItem('authToken')
    if (deviceId) {
      const updatedResponse = await updateDeviceById({ name: deviceName }, deviceId, token)
      if (updatedResponse) {
        this.setState({ changes: false });
        this.props.history.push({ pathname: "/deenkiosk/devices", state: { showAlert: true } })
      }
    }
  };
  handleChange = (event, newValue) => {
    this.setState({ value: newValue })
  };
  goLive = async () => {
    const token = localStorage.getItem('authToken')
    const deviceId = localStorage.getItem('deviceId')
    const status = 'Live'
    const changestatus = await updateDeviceStatus(status, deviceId, token)
    if (changestatus?.data?.body?.data) {
      const changestatusnew = this.state.deviceDetails
      changestatusnew.status = 'Live'
      this.setState({ deviceDetails: changestatusnew })
    }
  }
  pause = async () => {
    const token = localStorage.getItem('authToken')
    const deviceId = localStorage.getItem('deviceId')
    const status = 'Pause'
    const changestatus = await updateDeviceStatus(status, deviceId, token)
    if (changestatus?.data?.body?.data) {
      const changestatusnew = this.state.deviceDetails
      changestatusnew.status = 'Pause'
      this.setState({ deviceDetails: changestatusnew })
    }
  }
  // onClick = () => {
  //   console.log('edit')
  // }
  editAction = (e) => {
    this.props.history.push({ pathname: `/deenkiosk/edit-schedule`, state: { schedule: e[0].data, schedules: this.state.schedule } })
  }
  deleteAction = async (e) => {
    this.setState({
      openDeleteModal: true,
      scheduleId: e[0].scheduleId,
    });
  }
  handleClose = () => {
    this.setState({ openDeleteModal: false });
  };
  handleAnnoucementConfirm = async () => {
    const { schedule, scheduleId } = this.state;
    this.setState({ modalLoading: true });
    const deviceId = localStorage.getItem('deviceScheduleId')
    const token = localStorage.getItem('authToken')
    try {
      console.log("delete", deviceId, scheduleId)
      await deleteSchedulefromDevice(deviceId, scheduleId, token)
      const new_schedules = schedule.filter(item => item?._id !== scheduleId);
      this.setState({
        schedule: new_schedules,
        success: true,
        openDeleteModal: false,
        modalLoading: false,
      });
    } catch (error) {
      this.setState({
        error: error && error.response && error.response.data.message,
        showAlert: true,
        openDeleteModal: false,
        modalLoading: false,
      });
    }
  };

  onLayoutZoneClick = (zone) => {
    console.log("onLayoutZoneClick", zone);
    if (zone === this.state.selectedZone) {
      this.setState({ selectedZone: 0 });
    } else {
      this.setState({ selectedZone: zone });
    }
  };

  addDeviceName = async () => {
    const { deviceName } = this.state;
    if (!deviceName) {
      this.setState({ showDeviceError: true })
    }
    else {
      this.setState({ selectedZone: 0, changes: true });
    }
  }

  render() {
    const { error, showAlert, success, isloading, devices, value, schedule, deviceDetails, openDeleteModal, modalLoading } = this.state;
    const object = [
      {
        label: "Schedule",
        item: "Schedule",
      },
      {
        label: "Device details",
        item: "Device details",
      },

      {
        label: "Settings",
        item: "Settings",
      },
    ];
    const saveStyles = {
      backgroundColor: "#2A82F6",
    };
    const publishStyles = {
      backgroundColor: "#2A82F6",
      opacity: 0.6,
    };
    const livePauseStyles = {
      display: "flex",
      alignItems: "center",
      marginTop: "1px",
      width: "116px",
      backgroundColor: "#FFFFFF",
      borderTopLeftRadius: "30px",
      borderBottomLeftRadius: "30px",
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
      cursor: "pointer",
      border: "1px solid #D3D8DA",
      justifyContent: 'center',
      backgroundColor: '#2A82F6'
    };
    const livePauseDisabledStyles = {
      display: "flex",
      alignItems: "center",
      marginTop: "1px",
      width: "116px",
      backgroundColor: "#FFFFFF",
      borderTopLeftRadius: "30px",
      borderBottomLeftRadius: "30px",
      borderTopRightRadius: "30px",
      borderBottomRightRadius: "30px",
      cursor: "pointer",
      border: "1px solid #D3D8DA",
      justifyContent: 'center',
      color: '#000'
    };
    const livePauseTextStyle = {
      marginLeft: "6px",
      fontSize: "14px",
      color: "#000",
      fontWeight: 'bold',
      fontFamily: '"Roboto", "Helvetica"',
    }
    const livePauseTextDisabledStyle = {
      marginLeft: "6px",
      fontSize: "14px",
      color: "#fff",
      fontWeight: 'bold',
      fontFamily: '"Roboto", "Helvetica"',
    }
    const livePauseIconStyle = {
      fontSize: "14px",
      color: "white",
      width: "20px",
      height: "14px"
    };
    const classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };

    const col = [
      { type: 'string', name: 'name', label: 'NAME', width: "15%" },
      { type: 'string', name: 'name', label: 'HOMESCREEN', width: "20%" },
      { type: 'string', name: 'name', label: 'PUBLISH DATE', width: "20%" },
      { type: 'string', name: 'name', label: 'UNPUBLISH DATE', width: "20%" },
      { type: 'string', name: 'name', label: 'WEEKDAYS', width: "25%" },
      { type: 'status', name: 'status', label: 'STATUS', width: "10%" },
      { type: "action", name: "action", label: "ACTION", width: "10%" },
    ];

    const rows = schedule?.length ? schedule.map((item, i) => {
      const dateStart = [Moment(item.publishFrom).format("MMM DD, YYYY"), Moment(item.publishFrom).format("LT") + ' (EST)'];
      const dateEnd = [Moment(item.publishTo).format("MMM DD, YYYY"), Moment(item.publishTo).format("LT") + ' (EST)'];
      const UnpublishDate = new Date(dateEnd).getFullYear();
      const statusChangeStart = new Date(dateStart) <= new Date();
      const statusChangeEnd = new Date(dateEnd) <= new Date();
      const isVisible = item.visibility === "Public" ? true : false;
      console.log("sss", statusChangeStart, statusChangeEnd)

      return [
        { type: "string", name: "date", value: "Schedule " + (i + 1), scheduleId: item._id, data: { ...item } },
        { type: "string", name: "name", value: item.homeScreen?.name ?? "" },
        {
          type: 'string',
          name: "vertical",
          value: dateStart
        },
        {
          type: "string",
          name: UnpublishDate === 2099 ? "frequency" : "vertical",
          value: UnpublishDate === 2099 ? "Never" : dateEnd
        },
        { type: "string", name: "name", value: item.weekDays?.everyDay ? "Everyday" : item.weekDays?.days?.length ? item.weekDays.days.map((day) => day).join(", ") : "" },
        {
          type: "status",
          status: item?.visibility === 'Hidden' ? 'pending' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'success' : item?.visibility === 'Public' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule'
            : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',

          value: item?.visibility === 'Hidden' ? 'Unpublished' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'Published' : item?.visibility === 'Public' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled'
            : statusChangeEnd ? 'Unpublished4' : statusChangeStart ? 'Published' : 'Scheduled',
        },
      ]
    }) : [];
    const detailHeader = {
      avatar: '',
      tableName: `Device Details`,
      icon: ''
    };
    const deviceDetail = [
      {
        field: "Device Name",
        value: deviceDetails?.name ?? ""
      },

      {
        field: "Status",
        value: deviceDetails?.status ?? ""
      },
      {
        field: "IP",
        value: deviceDetails?.ipAddress ?? ""
      },
      {
        field: "Device ID",
        value: deviceDetails?.deviceId ?? ""
      },
      {
        field: "Connected on",
        value: deviceDetails?.connectedOn ? moment(deviceDetails?.connectedOn).format("LL") : ""
      },
      {
        field: "MAC",
        value: deviceDetails?.macAddress ?? ""
      },
      {
        field: "System Uptime",
        value: deviceDetails?.systemUptime ? moment(deviceDetails?.systemUptime).format("LLL") : '<span className="ondisableText">No Data</span>'
      },
      {
        field: "Last Active",
        value: deviceDetails?.lastActive ? moment(deviceDetails?.lastActive).format("LLL") : '<span className="ondisableText">No Data</span>'
      }
      ,
      {
        field: "Network Type",
        value: "WiFi"
      }
    ];

    const onClick = () => {
      console.log('edit')
  }
  
  const onDeleteClick = () => {
      console.log('delete')
  }

  let footerLinkName = "Edit Device";
  let footerDeleteButton = "Delete Device";




    const style = {
      width: '100%',
    };
    let deleteModal = (
      <Dialog
        open={openDeleteModal}
        onClose={() => this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete this schedule? This can't be undone.
          </DialogContentText>
        </DialogContent>
        {modalLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 50,
            }}
          >
            <CircularProgress
              color="primary"
              style={{ width: 24, height: 24 }}
            />
          </div>
        ) : (
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => this.handleAnnoucementConfirm()}
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );

    return (
      <MasterLayoutMiddle
        header={isloading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80vh",
            }}
          >
            <CircularProgress
              color="primary"
              style={{ width: 24, height: 24 }}
            />
          </div>
        ) : (
          <PageHeaderWithTabs
            value={value}
            title={deviceDetails?.name}
            object={object}
            indicatorColor="primary"
            style={{ color: "#2A82F6", border: "1px solid black" }}
            handleChange={(x, y) => this.handleChange(x, y)}
            indicatorColor="#2A82F6"
            saveStyles={saveStyles}
            publishStyles={publishStyles}
            // saveChanges={() => this.saveChanges()}
            publish={() => this.publish()}
            disabled={this.state.changes ? false : true}
            back={() => { this.props.history.push("/deenkiosk/devices") }}
            showBackBtn={true}
            btnText={'Back'}
            livePauseBtnText={deviceDetails?.status !== 'Live' ? "Go Live" : "Pause"}
            livePauseStyles={deviceDetails?.status !== 'Live' ? livePauseStyles : livePauseDisabledStyles}
            livePauseIcon={deviceDetails?.status !== 'Live' ? LiveIconWhite : PauseIcon}
            livePauseIconStyle={livePauseIconStyle}
            livePauseOnclick={(e) => deviceDetails?.status !== 'Live' ? this.goLive() : this.pause()}
            livePauseTextStyle={deviceDetails?.status !== 'Live' ? livePauseTextDisabledStyle : livePauseTextStyle}
            leftIconText={deviceDetails?.status === 'Live' ? "Live" : ""}
            liveIcon={LiveIcon}
            saveBtn={'SAVE CHANGES'}
          />
        )
        }
        content={
          <>
            {error && error === "Invalid token" && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => this.setState({ error: false })}
                />
              </div>
            )}
            {showAlert && error && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={error}
                  alertType={"error"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {showAlert && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={"Changes saved successfully!"}
                  alertType={"success"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {isloading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                <CircularProgress
                  color="primary"
                  style={{ width: 24, height: 24 }}
                />
              </div>
            ) : (

              <div>
                {value === 0 ?
                  <GridContainer spacing={0}>
                    <GridItem>
                      <SimpleTable
                        showHeader={true}
                        title="Schedules"
                        rows={rows}
                        columns={col}
                        colSpan={16}
                        actionSendButton={true}
                        classProps={changeClassProps()}
                        donationsCount={rows && rows.length > 0 ? 1 : 0}
                        noTitle="No schedule added"
                        noPara="Added schedule will be displayed and managed here"
                        noBtnText="Add New Schedule"
                        onInviteClick={() => this.props.history.push({ pathname: "/deenkiosk/add-schedule", state: { schedules: schedule } })}
                        onAddClick={() => this.props.history.push({ pathname: "/deenkiosk/add-schedule", state: { schedules: schedule } })}
                        addText="ADD SCHEDULE"
                        showFooter={true}
                        showAdd={true}
                        iconTextSize={16}
                        editIcon={Edit}
                        deleteIcon={Delete}
                        showIconCol={true}
                        editAction={(edit) => this.editAction(edit)}
                        deleteAction={(deleteItem) => this.deleteAction(deleteItem)}
                      />
                    </GridItem>
                  </GridContainer> : null}
                <div>
                  {value === 1 ? <GridContainer spacing={0} containerClass="grid-main">
                    <GridItem>
                      <div className='detailsTable'>
                        <CardDetailsTable
                          data={deviceDetail}
                          header={detailHeader}
                          // footerLinkName={footerLinkName}
                          // footerDeleteButton={footerDeleteButton}
                          onClick={() => onClick()}
                          onDeleteClick={() => onDeleteClick()}
                        />
                      </div>
                    </GridItem>
                  </GridContainer> : null}

                  {value === 2 ? <GridContainer spacing={0} containerClass="grid-main">
                    <GridItem className="accourdionContainer">
                      <SimpleAccordian
                        Title="Device Name"
                        text = {this.state.deviceName}
                        AccordionDetails={{ top: "-46px" }}
                        panel={1}
                        open={this.state.selectedZone === 1 ? true : false}
                        onAccordianClick={this.onLayoutZoneClick}
                        accordianName="headerCard"
                        saveChanges={() => {
                          this.addDeviceName()

                        }}
                        content={
                          <Grid container spacing={3}>
                            <Grid item md={4} sm={4} xs={4}> </Grid>

                            <Grid item md={8} sm={8} xs={8}>
                              <TextInputFixedLabel
                                label="Device Name"
                                labelStatus={false}
                                helpertext="The name is only visible to you. Name examples - Main Lobby, Main Prayer Space, Sister’s Floor"
                                HelpertextStatus={true}
                                placeholder="Placeholder"
                                Errormessage="Error message"
                                errortextStatus={false}
                                count={20}
                                countStatus={true}
                                tooltipStatus={false}
                                onChange={(e) => this.setState({ deviceName: e.target.value })}
                                value={this.state.deviceName}
                              />
                            </Grid></Grid>
                        }
                      />
                    </GridItem>
                  </GridContainer> : null}
                </div>

              </div>
            )}
            {openDeleteModal && deleteModal}
          </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={false}
      />
    )
  }
}
export default ManageDevice;
