import * as constants from "../constants";

function editReminder(data) {
    return {
        type: constants.EDIT_REMINDER,
        payload: data
    };
}

function emptyReminder() {
    return {
        type: constants.EMPTY_REMINDER
    };
}

export { editReminder, emptyReminder };