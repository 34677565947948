import * as constants from "../constants";

function signupHandler(email, fullName) {
  return {
    type: constants.SIGN_UP,
    payload: {
      email,
      fullName,
    },
  };
}

function verifyCodeHandler(verifyCode) {
  return {
    type: constants.VERIFY_CODE,
    payload: {
     verifyCode
    },
  };
}

export { signupHandler,verifyCodeHandler };
