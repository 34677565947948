import React, {useState, useEffect} from "react";
import Grid from '@material-ui/core/Grid';
import { SimpleTable,  MasterLayoutMiddle, FooterInner, AlertAction  } from "@dclab/dc-react-ui-kit";
import { GetNewsLetter } from "../Messages/api";
import '../style.css';
import Moment from 'moment'
import { CircularProgress } from "@material-ui/core";

function ManageSubscriptions(props) {
  const [rows, setRows] = React.useState([])
  const [loader, setLoader] = React.useState(true)
  const [error, seterror] = React.useState(false);
  const [showAlert, setshowAlert] = useState(false)

  const checkStatus = (status) => {
    status = status ? status.toLowerCase() : "";
    if (status === 'subscribed') {
      return "success"
    } else if (status === 'in-progress'){
      return "discontinued"
    } else if (status === 'closed'){
      return "failed"
    } else if (status === 'done'){
      return "success"
    } else {
      return "failed"
    }
 }
  
  useEffect(() => {
    getContacts();
    if(props?.location?.state?.showMessageAlert) {
      setshowAlert(true);
      setTimeout(() => {
        setshowAlert(false)
      }, 8000);
    }
  }, [])


  
  const getContacts = async () => {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID')
    const LocalName = localStorage.getItem('name')
    try {
      const response = await GetNewsLetter(token, orgId);
      let contacts = response.data;
      let array = [];
      contacts =  contacts.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt)
      });
      array = contacts.map((text, index) => {
        return ([
          { type: 'id', value: index + 1, _id: text._id },

          {
            type: 'link',
            name: 'invoice_number',
            value: text?.email ?? "",
            url: `/subscriber-details/${text._id}`,
            linkType: "external",
            // stripe:"Stripe",
            display:"inline-block",
          },
          { type: "string", name: "frequency", value: (text?.name?.firstName ?? "") + (text?.name?.lastName ? ' ' + text.name.lastName : ''), _id: text._id },
          { type: 'string', name: 'date', value:  Moment(text.createdAt).format('MMM DD, YYYY') },
          { type: "string", name: "chip", value: text?.timeline?.subscribed?.source },
          { type: 'status', status: text?.status?.toLowerCase() === 'subscribed' ? 'success' : 'failed'  , value: text?.status },
          { type: "action", status: "success", value: "SEE DETAILS" },
        ])
      })
      setLoader(false)
      setRows(array);
    } catch (error) {
      console.log("Error Occured: ", error);
      setLoader(false)
      seterror(error.response && error.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  
  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { id: 'NO', label: 'NO.', width: 'auto' },
    { type: "string", name: "name", label: "EMAIL ADDRESS", width: "22%" },
    { type: "string", name: "name", label: "NAME", width: "20%" }, 
    { type: "string", name: "name", label: "DATE ADDED", width: "20%" },
    { type: "string", name: "name", label: "SOURCE", width: "20%" },
    { type: "string", name: "status", label: "STATUS", width: '20%' },
    { type: "action", name: "action", label: "ACTION", width: "10%" },
  ];

  // ============================== Table End ======================================//
  // ============================== Style Start ===============================//
  
  // ============================== Style END ===============================//
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      content={
        loader ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <>
        <div>
            {showAlert && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={"Contact deleted successfully!"}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            )}
            <Grid container spacing={3} >
            <Grid item md={12} sm={12} xs={12} >
              
                <div className="table_box">
                  <SimpleTable
                    showHeader={true}
                    title="Subscribers"
                    width="100%"
                    rows={rows}
                    columns={col}
                    colSpan={16}
                    actionSendButton={true}
                    classProps={changeClassProps()}
                    donationsCount={rows && rows.length > 0 ? 1 : 0}
                    noTitle="No subscribers yet"
                    noPara="Newsletter subscribers will be displayed and managed here"
                    noBtnText=""
                    // onInviteClick={() =>
                    //   props.history.push(
                    //     "/subscriber-details"
                    //   )
                    // }
                    actionLink={(name) => props.history.push({pathname: `/subscriber-details/${name[0]?._id}`})}
                    showIconCol={false}
                    actionSendButton={true}
                    deleteAction={(e) => console.log(e)}
                    showFooter={true}
                    showAdd={false}
                    // addText="ADD NEW"
                    // onAddClick={() =>
                    //   props.history.push(
                    //     "/subscriber-details"
                    //   )
                    // }
                    iconTextSize={16}
                  /> 
                </div>
              </Grid>
            </Grid>
        
         
            
     
        </div>
        </>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
export default ManageSubscriptions;