export const countries = [
    {
        "id": 1,
        "name": "Afghanistan",
        "iso3": "AFG",
        "iso2": "AF",
        "phone_code": "93",
        "capital": "Kabul",
        "currency": "AFN",
        "native": "افغانستان",
        "emoji": "🇦🇫",
        "emojiU": "U+1F1E6 U+1F1EB"
    },
    {
        "id": 2,
        "name": "Aland Islands",
        "iso3": "ALA",
        "iso2": "AX",
        "phone_code": "+358-18",
        "capital": "Mariehamn",
        "currency": "EUR",
        "native": "Åland",
        "emoji": "🇦🇽",
        "emojiU": "U+1F1E6 U+1F1FD"
    },
    {
        "id": 3,
        "name": "Albania",
        "iso3": "ALB",
        "iso2": "AL",
        "phone_code": "355",
        "capital": "Tirana",
        "currency": "ALL",
        "native": "Shqipëria",
        "emoji": "🇦🇱",
        "emojiU": "U+1F1E6 U+1F1F1"
    },
    {
        "id": 4,
        "name": "Algeria",
        "iso3": "DZA",
        "iso2": "DZ",
        "phone_code": "213",
        "capital": "Algiers",
        "currency": "DZD",
        "native": "الجزائر",
        "emoji": "🇩🇿",
        "emojiU": "U+1F1E9 U+1F1FF"
    },
    {
        "id": 5,
        "name": "American Samoa",
        "iso3": "ASM",
        "iso2": "AS",
        "phone_code": "+1-684",
        "capital": "Pago Pago",
        "currency": "USD",
        "native": "American Samoa",
        "emoji": "🇦🇸",
        "emojiU": "U+1F1E6 U+1F1F8"
    },
    {
        "id": 6,
        "name": "Andorra",
        "iso3": "AND",
        "iso2": "AD",
        "phone_code": "376",
        "capital": "Andorra la Vella",
        "currency": "EUR",
        "native": "Andorra",
        "emoji": "🇦🇩",
        "emojiU": "U+1F1E6 U+1F1E9"
    },
    {
        "id": 7,
        "name": "Angola",
        "iso3": "AGO",
        "iso2": "AO",
        "phone_code": "244",
        "capital": "Luanda",
        "currency": "AOA",
        "native": "Angola",
        "emoji": "🇦🇴",
        "emojiU": "U+1F1E6 U+1F1F4"
    },
    {
        "id": 8,
        "name": "Anguilla",
        "iso3": "AIA",
        "iso2": "AI",
        "phone_code": "+1-264",
        "capital": "The Valley",
        "currency": "XCD",
        "native": "Anguilla",
        "emoji": "🇦🇮",
        "emojiU": "U+1F1E6 U+1F1EE"
    },
    {
        "id": 9,
        "name": "Antarctica",
        "iso3": "ATA",
        "iso2": "AQ",
        "phone_code": "",
        "capital": "",
        "currency": "",
        "native": "Antarctica",
        "emoji": "🇦🇶",
        "emojiU": "U+1F1E6 U+1F1F6"
    },
    {
        "id": 10,
        "name": "Antigua And Barbuda",
        "iso3": "ATG",
        "iso2": "AG",
        "phone_code": "+1-268",
        "capital": "St. John's",
        "currency": "XCD",
        "native": "Antigua and Barbuda",
        "emoji": "🇦🇬",
        "emojiU": "U+1F1E6 U+1F1EC"
    },
    {
        "id": 11,
        "name": "Argentina",
        "iso3": "ARG",
        "iso2": "AR",
        "phone_code": "54",
        "capital": "Buenos Aires",
        "currency": "ARS",
        "native": "Argentina",
        "emoji": "🇦🇷",
        "emojiU": "U+1F1E6 U+1F1F7"
    },
    {
        "id": 12,
        "name": "Armenia",
        "iso3": "ARM",
        "iso2": "AM",
        "phone_code": "374",
        "capital": "Yerevan",
        "currency": "AMD",
        "native": "Հայաստան",
        "emoji": "🇦🇲",
        "emojiU": "U+1F1E6 U+1F1F2"
    },
    {
        "id": 13,
        "name": "Aruba",
        "iso3": "ABW",
        "iso2": "AW",
        "phone_code": "297",
        "capital": "Oranjestad",
        "currency": "AWG",
        "native": "Aruba",
        "emoji": "🇦🇼",
        "emojiU": "U+1F1E6 U+1F1FC"
    },
    {
        "id": 14,
        "name": "Australia",
        "iso3": "AUS",
        "iso2": "AU",
        "phone_code": "61",
        "capital": "Canberra",
        "currency": "AUD",
        "native": "Australia",
        "emoji": "🇦🇺",
        "emojiU": "U+1F1E6 U+1F1FA"
    },
    {
        "id": 15,
        "name": "Austria",
        "iso3": "AUT",
        "iso2": "AT",
        "phone_code": "43",
        "capital": "Vienna",
        "currency": "EUR",
        "native": "Österreich",
        "emoji": "🇦🇹",
        "emojiU": "U+1F1E6 U+1F1F9"
    },
    {
        "id": 16,
        "name": "Azerbaijan",
        "iso3": "AZE",
        "iso2": "AZ",
        "phone_code": "994",
        "capital": "Baku",
        "currency": "AZN",
        "native": "Azərbaycan",
        "emoji": "🇦🇿",
        "emojiU": "U+1F1E6 U+1F1FF"
    },
    {
        "id": 17,
        "name": "Bahamas The",
        "iso3": "BHS",
        "iso2": "BS",
        "phone_code": "+1-242",
        "capital": "Nassau",
        "currency": "BSD",
        "native": "Bahamas",
        "emoji": "🇧🇸",
        "emojiU": "U+1F1E7 U+1F1F8"
    },
    {
        "id": 18,
        "name": "Bahrain",
        "iso3": "BHR",
        "iso2": "BH",
        "phone_code": "973",
        "capital": "Manama",
        "currency": "BHD",
        "native": "‏البحرين",
        "emoji": "🇧🇭",
        "emojiU": "U+1F1E7 U+1F1ED"
    },
    {
        "id": 19,
        "name": "Bangladesh",
        "iso3": "BGD",
        "iso2": "BD",
        "phone_code": "880",
        "capital": "Dhaka",
        "currency": "BDT",
        "native": "Bangladesh",
        "emoji": "🇧🇩",
        "emojiU": "U+1F1E7 U+1F1E9"
    },
    {
        "id": 20,
        "name": "Barbados",
        "iso3": "BRB",
        "iso2": "BB",
        "phone_code": "+1-246",
        "capital": "Bridgetown",
        "currency": "BBD",
        "native": "Barbados",
        "emoji": "🇧🇧",
        "emojiU": "U+1F1E7 U+1F1E7"
    },
    {
        "id": 21,
        "name": "Belarus",
        "iso3": "BLR",
        "iso2": "BY",
        "phone_code": "375",
        "capital": "Minsk",
        "currency": "BYN",
        "native": "Белару́сь",
        "emoji": "🇧🇾",
        "emojiU": "U+1F1E7 U+1F1FE"
    },
    {
        "id": 22,
        "name": "Belgium",
        "iso3": "BEL",
        "iso2": "BE",
        "phone_code": "32",
        "capital": "Brussels",
        "currency": "EUR",
        "native": "België",
        "emoji": "🇧🇪",
        "emojiU": "U+1F1E7 U+1F1EA"
    },
    {
        "id": 23,
        "name": "Belize",
        "iso3": "BLZ",
        "iso2": "BZ",
        "phone_code": "501",
        "capital": "Belmopan",
        "currency": "BZD",
        "native": "Belize",
        "emoji": "🇧🇿",
        "emojiU": "U+1F1E7 U+1F1FF"
    },
    {
        "id": 24,
        "name": "Benin",
        "iso3": "BEN",
        "iso2": "BJ",
        "phone_code": "229",
        "capital": "Porto-Novo",
        "currency": "XOF",
        "native": "Bénin",
        "emoji": "🇧🇯",
        "emojiU": "U+1F1E7 U+1F1EF"
    },
    {
        "id": 25,
        "name": "Bermuda",
        "iso3": "BMU",
        "iso2": "BM",
        "phone_code": "+1-441",
        "capital": "Hamilton",
        "currency": "BMD",
        "native": "Bermuda",
        "emoji": "🇧🇲",
        "emojiU": "U+1F1E7 U+1F1F2"
    },
    {
        "id": 26,
        "name": "Bhutan",
        "iso3": "BTN",
        "iso2": "BT",
        "phone_code": "975",
        "capital": "Thimphu",
        "currency": "BTN",
        "native": "ʼbrug-yul",
        "emoji": "🇧🇹",
        "emojiU": "U+1F1E7 U+1F1F9"
    },
    {
        "id": 27,
        "name": "Bolivia",
        "iso3": "BOL",
        "iso2": "BO",
        "phone_code": "591",
        "capital": "Sucre",
        "currency": "BOB",
        "native": "Bolivia",
        "emoji": "🇧🇴",
        "emojiU": "U+1F1E7 U+1F1F4"
    },
    {
        "id": 28,
        "name": "Bosnia and Herzegovina",
        "iso3": "BIH",
        "iso2": "BA",
        "phone_code": "387",
        "capital": "Sarajevo",
        "currency": "BAM",
        "native": "Bosna i Hercegovina",
        "emoji": "🇧🇦",
        "emojiU": "U+1F1E7 U+1F1E6"
    },
    {
        "id": 29,
        "name": "Botswana",
        "iso3": "BWA",
        "iso2": "BW",
        "phone_code": "267",
        "capital": "Gaborone",
        "currency": "BWP",
        "native": "Botswana",
        "emoji": "🇧🇼",
        "emojiU": "U+1F1E7 U+1F1FC"
    },
    {
        "id": 30,
        "name": "Bouvet Island",
        "iso3": "BVT",
        "iso2": "BV",
        "phone_code": "",
        "capital": "",
        "currency": "NOK",
        "native": "Bouvetøya",
        "emoji": "🇧🇻",
        "emojiU": "U+1F1E7 U+1F1FB"
    },
    {
        "id": 31,
        "name": "Brazil",
        "iso3": "BRA",
        "iso2": "BR",
        "phone_code": "55",
        "capital": "Brasilia",
        "currency": "BRL",
        "native": "Brasil",
        "emoji": "🇧🇷",
        "emojiU": "U+1F1E7 U+1F1F7"
    },
    {
        "id": 32,
        "name": "British Indian Ocean Territory",
        "iso3": "IOT",
        "iso2": "IO",
        "phone_code": "246",
        "capital": "Diego Garcia",
        "currency": "USD",
        "native": "British Indian Ocean Territory",
        "emoji": "🇮🇴",
        "emojiU": "U+1F1EE U+1F1F4"
    },
    {
        "id": 33,
        "name": "Brunei",
        "iso3": "BRN",
        "iso2": "BN",
        "phone_code": "673",
        "capital": "Bandar Seri Begawan",
        "currency": "BND",
        "native": "Negara Brunei Darussalam",
        "emoji": "🇧🇳",
        "emojiU": "U+1F1E7 U+1F1F3"
    },
    {
        "id": 34,
        "name": "Bulgaria",
        "iso3": "BGR",
        "iso2": "BG",
        "phone_code": "359",
        "capital": "Sofia",
        "currency": "BGN",
        "native": "България",
        "emoji": "🇧🇬",
        "emojiU": "U+1F1E7 U+1F1EC"
    },
    {
        "id": 35,
        "name": "Burkina Faso",
        "iso3": "BFA",
        "iso2": "BF",
        "phone_code": "226",
        "capital": "Ouagadougou",
        "currency": "XOF",
        "native": "Burkina Faso",
        "emoji": "🇧🇫",
        "emojiU": "U+1F1E7 U+1F1EB"
    },
    {
        "id": 36,
        "name": "Burundi",
        "iso3": "BDI",
        "iso2": "BI",
        "phone_code": "257",
        "capital": "Bujumbura",
        "currency": "BIF",
        "native": "Burundi",
        "emoji": "🇧🇮",
        "emojiU": "U+1F1E7 U+1F1EE"
    },
    {
        "id": 37,
        "name": "Cambodia",
        "iso3": "KHM",
        "iso2": "KH",
        "phone_code": "855",
        "capital": "Phnom Penh",
        "currency": "KHR",
        "native": "Kâmpŭchéa",
        "emoji": "🇰🇭",
        "emojiU": "U+1F1F0 U+1F1ED"
    },
    {
        "id": 38,
        "name": "Cameroon",
        "iso3": "CMR",
        "iso2": "CM",
        "phone_code": "237",
        "capital": "Yaounde",
        "currency": "XAF",
        "native": "Cameroon",
        "emoji": "🇨🇲",
        "emojiU": "U+1F1E8 U+1F1F2"
    },
    {
        "id": 39,
        "name": "Canada",
        "iso3": "CAN",
        "iso2": "CA",
        "phone_code": "1",
        "capital": "Ottawa",
        "currency": "CAD",
        "native": "Canada",
        "emoji": "🇨🇦",
        "emojiU": "U+1F1E8 U+1F1E6"
    },
    {
        "id": 40,
        "name": "Cape Verde",
        "iso3": "CPV",
        "iso2": "CV",
        "phone_code": "238",
        "capital": "Praia",
        "currency": "CVE",
        "native": "Cabo Verde",
        "emoji": "🇨🇻",
        "emojiU": "U+1F1E8 U+1F1FB"
    },
    {
        "id": 41,
        "name": "Cayman Islands",
        "iso3": "CYM",
        "iso2": "KY",
        "phone_code": "+1-345",
        "capital": "George Town",
        "currency": "KYD",
        "native": "Cayman Islands",
        "emoji": "🇰🇾",
        "emojiU": "U+1F1F0 U+1F1FE"
    },
    {
        "id": 42,
        "name": "Central African Republic",
        "iso3": "CAF",
        "iso2": "CF",
        "phone_code": "236",
        "capital": "Bangui",
        "currency": "XAF",
        "native": "Ködörösêse tî Bêafrîka",
        "emoji": "🇨🇫",
        "emojiU": "U+1F1E8 U+1F1EB"
    },
    {
        "id": 43,
        "name": "Chad",
        "iso3": "TCD",
        "iso2": "TD",
        "phone_code": "235",
        "capital": "N'Djamena",
        "currency": "XAF",
        "native": "Tchad",
        "emoji": "🇹🇩",
        "emojiU": "U+1F1F9 U+1F1E9"
    },
    {
        "id": 44,
        "name": "Chile",
        "iso3": "CHL",
        "iso2": "CL",
        "phone_code": "56",
        "capital": "Santiago",
        "currency": "CLP",
        "native": "Chile",
        "emoji": "🇨🇱",
        "emojiU": "U+1F1E8 U+1F1F1"
    },
    {
        "id": 45,
        "name": "China",
        "iso3": "CHN",
        "iso2": "CN",
        "phone_code": "86",
        "capital": "Beijing",
        "currency": "CNY",
        "native": "中国",
        "emoji": "🇨🇳",
        "emojiU": "U+1F1E8 U+1F1F3"
    },
    {
        "id": 46,
        "name": "Christmas Island",
        "iso3": "CXR",
        "iso2": "CX",
        "phone_code": "61",
        "capital": "Flying Fish Cove",
        "currency": "AUD",
        "native": "Christmas Island",
        "emoji": "🇨🇽",
        "emojiU": "U+1F1E8 U+1F1FD"
    },
    {
        "id": 47,
        "name": "Cocos (Keeling) Islands",
        "iso3": "CCK",
        "iso2": "CC",
        "phone_code": "61",
        "capital": "West Island",
        "currency": "AUD",
        "native": "Cocos (Keeling) Islands",
        "emoji": "🇨🇨",
        "emojiU": "U+1F1E8 U+1F1E8"
    },
    {
        "id": 48,
        "name": "Colombia",
        "iso3": "COL",
        "iso2": "CO",
        "phone_code": "57",
        "capital": "Bogota",
        "currency": "COP",
        "native": "Colombia",
        "emoji": "🇨🇴",
        "emojiU": "U+1F1E8 U+1F1F4"
    },
    {
        "id": 49,
        "name": "Comoros",
        "iso3": "COM",
        "iso2": "KM",
        "phone_code": "269",
        "capital": "Moroni",
        "currency": "KMF",
        "native": "Komori",
        "emoji": "🇰🇲",
        "emojiU": "U+1F1F0 U+1F1F2"
    },
    {
        "id": 50,
        "name": "Congo",
        "iso3": "COG",
        "iso2": "CG",
        "phone_code": "242",
        "capital": "Brazzaville",
        "currency": "XAF",
        "native": "République du Congo",
        "emoji": "🇨🇬",
        "emojiU": "U+1F1E8 U+1F1EC"
    },
    {
        "id": 51,
        "name": "Congo The Democratic Republic Of The",
        "iso3": "COD",
        "iso2": "CD",
        "phone_code": "243",
        "capital": "Kinshasa",
        "currency": "CDF",
        "native": "République démocratique du Congo",
        "emoji": "🇨🇩",
        "emojiU": "U+1F1E8 U+1F1E9"
    },
    {
        "id": 52,
        "name": "Cook Islands",
        "iso3": "COK",
        "iso2": "CK",
        "phone_code": "682",
        "capital": "Avarua",
        "currency": "NZD",
        "native": "Cook Islands",
        "emoji": "🇨🇰",
        "emojiU": "U+1F1E8 U+1F1F0"
    },
    {
        "id": 53,
        "name": "Costa Rica",
        "iso3": "CRI",
        "iso2": "CR",
        "phone_code": "506",
        "capital": "San Jose",
        "currency": "CRC",
        "native": "Costa Rica",
        "emoji": "🇨🇷",
        "emojiU": "U+1F1E8 U+1F1F7"
    },
    {
        "id": 54,
        "name": "Cote D'Ivoire (Ivory Coast)",
        "iso3": "CIV",
        "iso2": "CI",
        "phone_code": "225",
        "capital": "Yamoussoukro",
        "currency": "XOF",
        "native": null,
        "emoji": "🇨🇮",
        "emojiU": "U+1F1E8 U+1F1EE"
    },
    {
        "id": 55,
        "name": "Croatia (Hrvatska)",
        "iso3": "HRV",
        "iso2": "HR",
        "phone_code": "385",
        "capital": "Zagreb",
        "currency": "HRK",
        "native": "Hrvatska",
        "emoji": "🇭🇷",
        "emojiU": "U+1F1ED U+1F1F7"
    },
    {
        "id": 56,
        "name": "Cuba",
        "iso3": "CUB",
        "iso2": "CU",
        "phone_code": "53",
        "capital": "Havana",
        "currency": "CUP",
        "native": "Cuba",
        "emoji": "🇨🇺",
        "emojiU": "U+1F1E8 U+1F1FA"
    },
    {
        "id": 57,
        "name": "Cyprus",
        "iso3": "CYP",
        "iso2": "CY",
        "phone_code": "357",
        "capital": "Nicosia",
        "currency": "EUR",
        "native": "Κύπρος",
        "emoji": "🇨🇾",
        "emojiU": "U+1F1E8 U+1F1FE"
    },
    {
        "id": 58,
        "name": "Czech Republic",
        "iso3": "CZE",
        "iso2": "CZ",
        "phone_code": "420",
        "capital": "Prague",
        "currency": "CZK",
        "native": "Česká republika",
        "emoji": "🇨🇿",
        "emojiU": "U+1F1E8 U+1F1FF"
    },
    {
        "id": 59,
        "name": "Denmark",
        "iso3": "DNK",
        "iso2": "DK",
        "phone_code": "45",
        "capital": "Copenhagen",
        "currency": "DKK",
        "native": "Danmark",
        "emoji": "🇩🇰",
        "emojiU": "U+1F1E9 U+1F1F0"
    },
    {
        "id": 60,
        "name": "Djibouti",
        "iso3": "DJI",
        "iso2": "DJ",
        "phone_code": "253",
        "capital": "Djibouti",
        "currency": "DJF",
        "native": "Djibouti",
        "emoji": "🇩🇯",
        "emojiU": "U+1F1E9 U+1F1EF"
    },
    {
        "id": 61,
        "name": "Dominica",
        "iso3": "DMA",
        "iso2": "DM",
        "phone_code": "+1-767",
        "capital": "Roseau",
        "currency": "XCD",
        "native": "Dominica",
        "emoji": "🇩🇲",
        "emojiU": "U+1F1E9 U+1F1F2"
    },
    {
        "id": 62,
        "name": "Dominican Republic",
        "iso3": "DOM",
        "iso2": "DO",
        "phone_code": "+1-809 and 1-829",
        "capital": "Santo Domingo",
        "currency": "DOP",
        "native": "República Dominicana",
        "emoji": "🇩🇴",
        "emojiU": "U+1F1E9 U+1F1F4"
    },
    {
        "id": 63,
        "name": "East Timor",
        "iso3": "TLS",
        "iso2": "TL",
        "phone_code": "670",
        "capital": "Dili",
        "currency": "USD",
        "native": "Timor-Leste",
        "emoji": "🇹🇱",
        "emojiU": "U+1F1F9 U+1F1F1"
    },
    {
        "id": 64,
        "name": "Ecuador",
        "iso3": "ECU",
        "iso2": "EC",
        "phone_code": "593",
        "capital": "Quito",
        "currency": "USD",
        "native": "Ecuador",
        "emoji": "🇪🇨",
        "emojiU": "U+1F1EA U+1F1E8"
    },
    {
        "id": 65,
        "name": "Egypt",
        "iso3": "EGY",
        "iso2": "EG",
        "phone_code": "20",
        "capital": "Cairo",
        "currency": "EGP",
        "native": "مصر‎",
        "emoji": "🇪🇬",
        "emojiU": "U+1F1EA U+1F1EC"
    },
    {
        "id": 66,
        "name": "El Salvador",
        "iso3": "SLV",
        "iso2": "SV",
        "phone_code": "503",
        "capital": "San Salvador",
        "currency": "USD",
        "native": "El Salvador",
        "emoji": "🇸🇻",
        "emojiU": "U+1F1F8 U+1F1FB"
    },
    {
        "id": 67,
        "name": "Equatorial Guinea",
        "iso3": "GNQ",
        "iso2": "GQ",
        "phone_code": "240",
        "capital": "Malabo",
        "currency": "XAF",
        "native": "Guinea Ecuatorial",
        "emoji": "🇬🇶",
        "emojiU": "U+1F1EC U+1F1F6"
    },
    {
        "id": 68,
        "name": "Eritrea",
        "iso3": "ERI",
        "iso2": "ER",
        "phone_code": "291",
        "capital": "Asmara",
        "currency": "ERN",
        "native": "ኤርትራ",
        "emoji": "🇪🇷",
        "emojiU": "U+1F1EA U+1F1F7"
    },
    {
        "id": 69,
        "name": "Estonia",
        "iso3": "EST",
        "iso2": "EE",
        "phone_code": "372",
        "capital": "Tallinn",
        "currency": "EUR",
        "native": "Eesti",
        "emoji": "🇪🇪",
        "emojiU": "U+1F1EA U+1F1EA"
    },
    {
        "id": 70,
        "name": "Ethiopia",
        "iso3": "ETH",
        "iso2": "ET",
        "phone_code": "251",
        "capital": "Addis Ababa",
        "currency": "ETB",
        "native": "ኢትዮጵያ",
        "emoji": "🇪🇹",
        "emojiU": "U+1F1EA U+1F1F9"
    },
    {
        "id": 71,
        "name": "Falkland Islands",
        "iso3": "FLK",
        "iso2": "FK",
        "phone_code": "500",
        "capital": "Stanley",
        "currency": "FKP",
        "native": "Falkland Islands",
        "emoji": "🇫🇰",
        "emojiU": "U+1F1EB U+1F1F0"
    },
    {
        "id": 72,
        "name": "Faroe Islands",
        "iso3": "FRO",
        "iso2": "FO",
        "phone_code": "298",
        "capital": "Torshavn",
        "currency": "DKK",
        "native": "Føroyar",
        "emoji": "🇫🇴",
        "emojiU": "U+1F1EB U+1F1F4"
    },
    {
        "id": 73,
        "name": "Fiji Islands",
        "iso3": "FJI",
        "iso2": "FJ",
        "phone_code": "679",
        "capital": "Suva",
        "currency": "FJD",
        "native": "Fiji",
        "emoji": "🇫🇯",
        "emojiU": "U+1F1EB U+1F1EF"
    },
    {
        "id": 74,
        "name": "Finland",
        "iso3": "FIN",
        "iso2": "FI",
        "phone_code": "358",
        "capital": "Helsinki",
        "currency": "EUR",
        "native": "Suomi",
        "emoji": "🇫🇮",
        "emojiU": "U+1F1EB U+1F1EE"
    },
    {
        "id": 75,
        "name": "France",
        "iso3": "FRA",
        "iso2": "FR",
        "phone_code": "33",
        "capital": "Paris",
        "currency": "EUR",
        "native": "France",
        "emoji": "🇫🇷",
        "emojiU": "U+1F1EB U+1F1F7"
    },
    {
        "id": 76,
        "name": "French Guiana",
        "iso3": "GUF",
        "iso2": "GF",
        "phone_code": "594",
        "capital": "Cayenne",
        "currency": "EUR",
        "native": "Guyane française",
        "emoji": "🇬🇫",
        "emojiU": "U+1F1EC U+1F1EB"
    },
    {
        "id": 77,
        "name": "French Polynesia",
        "iso3": "PYF",
        "iso2": "PF",
        "phone_code": "689",
        "capital": "Papeete",
        "currency": "XPF",
        "native": "Polynésie française",
        "emoji": "🇵🇫",
        "emojiU": "U+1F1F5 U+1F1EB"
    },
    {
        "id": 78,
        "name": "French Southern Territories",
        "iso3": "ATF",
        "iso2": "TF",
        "phone_code": "",
        "capital": "Port-aux-Francais",
        "currency": "EUR",
        "native": "Territoire des Terres australes et antarctiques fr",
        "emoji": "🇹🇫",
        "emojiU": "U+1F1F9 U+1F1EB"
    },
    {
        "id": 79,
        "name": "Gabon",
        "iso3": "GAB",
        "iso2": "GA",
        "phone_code": "241",
        "capital": "Libreville",
        "currency": "XAF",
        "native": "Gabon",
        "emoji": "🇬🇦",
        "emojiU": "U+1F1EC U+1F1E6"
    },
    {
        "id": 80,
        "name": "Gambia The",
        "iso3": "GMB",
        "iso2": "GM",
        "phone_code": "220",
        "capital": "Banjul",
        "currency": "GMD",
        "native": "Gambia",
        "emoji": "🇬🇲",
        "emojiU": "U+1F1EC U+1F1F2"
    },
    {
        "id": 81,
        "name": "Georgia",
        "iso3": "GEO",
        "iso2": "GE",
        "phone_code": "995",
        "capital": "Tbilisi",
        "currency": "GEL",
        "native": "საქართველო",
        "emoji": "🇬🇪",
        "emojiU": "U+1F1EC U+1F1EA"
    },
    {
        "id": 82,
        "name": "Germany",
        "iso3": "DEU",
        "iso2": "DE",
        "phone_code": "49",
        "capital": "Berlin",
        "currency": "EUR",
        "native": "Deutschland",
        "emoji": "🇩🇪",
        "emojiU": "U+1F1E9 U+1F1EA"
    },
    {
        "id": 83,
        "name": "Ghana",
        "iso3": "GHA",
        "iso2": "GH",
        "phone_code": "233",
        "capital": "Accra",
        "currency": "GHS",
        "native": "Ghana",
        "emoji": "🇬🇭",
        "emojiU": "U+1F1EC U+1F1ED"
    },
    {
        "id": 84,
        "name": "Gibraltar",
        "iso3": "GIB",
        "iso2": "GI",
        "phone_code": "350",
        "capital": "Gibraltar",
        "currency": "GIP",
        "native": "Gibraltar",
        "emoji": "🇬🇮",
        "emojiU": "U+1F1EC U+1F1EE"
    },
    {
        "id": 85,
        "name": "Greece",
        "iso3": "GRC",
        "iso2": "GR",
        "phone_code": "30",
        "capital": "Athens",
        "currency": "EUR",
        "native": "Ελλάδα",
        "emoji": "🇬🇷",
        "emojiU": "U+1F1EC U+1F1F7"
    },
    {
        "id": 86,
        "name": "Greenland",
        "iso3": "GRL",
        "iso2": "GL",
        "phone_code": "299",
        "capital": "Nuuk",
        "currency": "DKK",
        "native": "Kalaallit Nunaat",
        "emoji": "🇬🇱",
        "emojiU": "U+1F1EC U+1F1F1"
    },
    {
        "id": 87,
        "name": "Grenada",
        "iso3": "GRD",
        "iso2": "GD",
        "phone_code": "+1-473",
        "capital": "St. George's",
        "currency": "XCD",
        "native": "Grenada",
        "emoji": "🇬🇩",
        "emojiU": "U+1F1EC U+1F1E9"
    },
    {
        "id": 88,
        "name": "Guadeloupe",
        "iso3": "GLP",
        "iso2": "GP",
        "phone_code": "590",
        "capital": "Basse-Terre",
        "currency": "EUR",
        "native": "Guadeloupe",
        "emoji": "🇬🇵",
        "emojiU": "U+1F1EC U+1F1F5"
    },
    {
        "id": 89,
        "name": "Guam",
        "iso3": "GUM",
        "iso2": "GU",
        "phone_code": "+1-671",
        "capital": "Hagatna",
        "currency": "USD",
        "native": "Guam",
        "emoji": "🇬🇺",
        "emojiU": "U+1F1EC U+1F1FA"
    },
    {
        "id": 90,
        "name": "Guatemala",
        "iso3": "GTM",
        "iso2": "GT",
        "phone_code": "502",
        "capital": "Guatemala City",
        "currency": "GTQ",
        "native": "Guatemala",
        "emoji": "🇬🇹",
        "emojiU": "U+1F1EC U+1F1F9"
    },
    {
        "id": 91,
        "name": "Guernsey and Alderney",
        "iso3": "GGY",
        "iso2": "GG",
        "phone_code": "+44-1481",
        "capital": "St Peter Port",
        "currency": "GBP",
        "native": "Guernsey",
        "emoji": "🇬🇬",
        "emojiU": "U+1F1EC U+1F1EC"
    },
    {
        "id": 92,
        "name": "Guinea",
        "iso3": "GIN",
        "iso2": "GN",
        "phone_code": "224",
        "capital": "Conakry",
        "currency": "GNF",
        "native": "Guinée",
        "emoji": "🇬🇳",
        "emojiU": "U+1F1EC U+1F1F3"
    },
    {
        "id": 93,
        "name": "Guinea-Bissau",
        "iso3": "GNB",
        "iso2": "GW",
        "phone_code": "245",
        "capital": "Bissau",
        "currency": "XOF",
        "native": "Guiné-Bissau",
        "emoji": "🇬🇼",
        "emojiU": "U+1F1EC U+1F1FC"
    },
    {
        "id": 94,
        "name": "Guyana",
        "iso3": "GUY",
        "iso2": "GY",
        "phone_code": "592",
        "capital": "Georgetown",
        "currency": "GYD",
        "native": "Guyana",
        "emoji": "🇬🇾",
        "emojiU": "U+1F1EC U+1F1FE"
    },
    {
        "id": 95,
        "name": "Haiti",
        "iso3": "HTI",
        "iso2": "HT",
        "phone_code": "509",
        "capital": "Port-au-Prince",
        "currency": "HTG",
        "native": "Haïti",
        "emoji": "🇭🇹",
        "emojiU": "U+1F1ED U+1F1F9"
    },
    {
        "id": 96,
        "name": "Heard and McDonald Islands",
        "iso3": "HMD",
        "iso2": "HM",
        "phone_code": " ",
        "capital": "",
        "currency": "AUD",
        "native": "Heard Island and McDonald Islands",
        "emoji": "🇭🇲",
        "emojiU": "U+1F1ED U+1F1F2"
    },
    {
        "id": 97,
        "name": "Honduras",
        "iso3": "HND",
        "iso2": "HN",
        "phone_code": "504",
        "capital": "Tegucigalpa",
        "currency": "HNL",
        "native": "Honduras",
        "emoji": "🇭🇳",
        "emojiU": "U+1F1ED U+1F1F3"
    },
    {
        "id": 98,
        "name": "Hong Kong S.A.R.",
        "iso3": "HKG",
        "iso2": "HK",
        "phone_code": "852",
        "capital": "Hong Kong",
        "currency": "HKD",
        "native": "香港",
        "emoji": "🇭🇰",
        "emojiU": "U+1F1ED U+1F1F0"
    },
    {
        "id": 99,
        "name": "Hungary",
        "iso3": "HUN",
        "iso2": "HU",
        "phone_code": "36",
        "capital": "Budapest",
        "currency": "HUF",
        "native": "Magyarország",
        "emoji": "🇭🇺",
        "emojiU": "U+1F1ED U+1F1FA"
    },
    {
        "id": 100,
        "name": "Iceland",
        "iso3": "ISL",
        "iso2": "IS",
        "phone_code": "354",
        "capital": "Reykjavik",
        "currency": "ISK",
        "native": "Ísland",
        "emoji": "🇮🇸",
        "emojiU": "U+1F1EE U+1F1F8"
    },
    {
        "id": 101,
        "name": "India",
        "iso3": "IND",
        "iso2": "IN",
        "phone_code": "91",
        "capital": "New Delhi",
        "currency": "INR",
        "native": "भारत",
        "emoji": "🇮🇳",
        "emojiU": "U+1F1EE U+1F1F3"
    },
    {
        "id": 102,
        "name": "Indonesia",
        "iso3": "IDN",
        "iso2": "ID",
        "phone_code": "62",
        "capital": "Jakarta",
        "currency": "IDR",
        "native": "Indonesia",
        "emoji": "🇮🇩",
        "emojiU": "U+1F1EE U+1F1E9"
    },
    {
        "id": 103,
        "name": "Iran",
        "iso3": "IRN",
        "iso2": "IR",
        "phone_code": "98",
        "capital": "Tehran",
        "currency": "IRR",
        "native": "ایران",
        "emoji": "🇮🇷",
        "emojiU": "U+1F1EE U+1F1F7"
    },
    {
        "id": 104,
        "name": "Iraq",
        "iso3": "IRQ",
        "iso2": "IQ",
        "phone_code": "964",
        "capital": "Baghdad",
        "currency": "IQD",
        "native": "العراق",
        "emoji": "🇮🇶",
        "emojiU": "U+1F1EE U+1F1F6"
    },
    {
        "id": 105,
        "name": "Ireland",
        "iso3": "IRL",
        "iso2": "IE",
        "phone_code": "353",
        "capital": "Dublin",
        "currency": "EUR",
        "native": "Éire",
        "emoji": "🇮🇪",
        "emojiU": "U+1F1EE U+1F1EA"
    },
    {
        "id": 106,
        "name": "Israel",
        "iso3": "ISR",
        "iso2": "IL",
        "phone_code": "972",
        "capital": "Jerusalem",
        "currency": "ILS",
        "native": "יִשְׂרָאֵל",
        "emoji": "🇮🇱",
        "emojiU": "U+1F1EE U+1F1F1"
    },
    {
        "id": 107,
        "name": "Italy",
        "iso3": "ITA",
        "iso2": "IT",
        "phone_code": "39",
        "capital": "Rome",
        "currency": "EUR",
        "native": "Italia",
        "emoji": "🇮🇹",
        "emojiU": "U+1F1EE U+1F1F9"
    },
    {
        "id": 108,
        "name": "Jamaica",
        "iso3": "JAM",
        "iso2": "JM",
        "phone_code": "+1-876",
        "capital": "Kingston",
        "currency": "JMD",
        "native": "Jamaica",
        "emoji": "🇯🇲",
        "emojiU": "U+1F1EF U+1F1F2"
    },
    {
        "id": 109,
        "name": "Japan",
        "iso3": "JPN",
        "iso2": "JP",
        "phone_code": "81",
        "capital": "Tokyo",
        "currency": "JPY",
        "native": "日本",
        "emoji": "🇯🇵",
        "emojiU": "U+1F1EF U+1F1F5"
    },
    {
        "id": 110,
        "name": "Jersey",
        "iso3": "JEY",
        "iso2": "JE",
        "phone_code": "+44-1534",
        "capital": "Saint Helier",
        "currency": "GBP",
        "native": "Jersey",
        "emoji": "🇯🇪",
        "emojiU": "U+1F1EF U+1F1EA"
    },
    {
        "id": 111,
        "name": "Jordan",
        "iso3": "JOR",
        "iso2": "JO",
        "phone_code": "962",
        "capital": "Amman",
        "currency": "JOD",
        "native": "الأردن",
        "emoji": "🇯🇴",
        "emojiU": "U+1F1EF U+1F1F4"
    },
    {
        "id": 112,
        "name": "Kazakhstan",
        "iso3": "KAZ",
        "iso2": "KZ",
        "phone_code": "7",
        "capital": "Astana",
        "currency": "KZT",
        "native": "Қазақстан",
        "emoji": "🇰🇿",
        "emojiU": "U+1F1F0 U+1F1FF"
    },
    {
        "id": 113,
        "name": "Kenya",
        "iso3": "KEN",
        "iso2": "KE",
        "phone_code": "254",
        "capital": "Nairobi",
        "currency": "KES",
        "native": "Kenya",
        "emoji": "🇰🇪",
        "emojiU": "U+1F1F0 U+1F1EA"
    },
    {
        "id": 114,
        "name": "Kiribati",
        "iso3": "KIR",
        "iso2": "KI",
        "phone_code": "686",
        "capital": "Tarawa",
        "currency": "AUD",
        "native": "Kiribati",
        "emoji": "🇰🇮",
        "emojiU": "U+1F1F0 U+1F1EE"
    },
    {
        "id": 115,
        "name": "Korea North",
        "iso3": "PRK",
        "iso2": "KP",
        "phone_code": "850",
        "capital": "Pyongyang",
        "currency": "KPW",
        "native": "북한",
        "emoji": "🇰🇵",
        "emojiU": "U+1F1F0 U+1F1F5"
    },
    {
        "id": 116,
        "name": "Korea South",
        "iso3": "KOR",
        "iso2": "KR",
        "phone_code": "82",
        "capital": "Seoul",
        "currency": "KRW",
        "native": "대한민국",
        "emoji": "🇰🇷",
        "emojiU": "U+1F1F0 U+1F1F7"
    },
    {
        "id": 248,
        "name": "Kosovo",
        "iso3": "XKX",
        "iso2": "XK",
        "phone_code": "383",
        "capital": "Pristina",
        "currency": "EUR",
        "native": "Republika e Kosovës",
        "emoji": "🇽🇰",
        "emojiU": "U+1F1FD U+1F1F0"
    },
    {
        "id": 117,
        "name": "Kuwait",
        "iso3": "KWT",
        "iso2": "KW",
        "phone_code": "965",
        "capital": "Kuwait City",
        "currency": "KWD",
        "native": "الكويت",
        "emoji": "🇰🇼",
        "emojiU": "U+1F1F0 U+1F1FC"
    },
    {
        "id": 118,
        "name": "Kyrgyzstan",
        "iso3": "KGZ",
        "iso2": "KG",
        "phone_code": "996",
        "capital": "Bishkek",
        "currency": "KGS",
        "native": "Кыргызстан",
        "emoji": "🇰🇬",
        "emojiU": "U+1F1F0 U+1F1EC"
    },
    {
        "id": 119,
        "name": "Laos",
        "iso3": "LAO",
        "iso2": "LA",
        "phone_code": "856",
        "capital": "Vientiane",
        "currency": "LAK",
        "native": "ສປປລາວ",
        "emoji": "🇱🇦",
        "emojiU": "U+1F1F1 U+1F1E6"
    },
    {
        "id": 120,
        "name": "Latvia",
        "iso3": "LVA",
        "iso2": "LV",
        "phone_code": "371",
        "capital": "Riga",
        "currency": "EUR",
        "native": "Latvija",
        "emoji": "🇱🇻",
        "emojiU": "U+1F1F1 U+1F1FB"
    },
    {
        "id": 121,
        "name": "Lebanon",
        "iso3": "LBN",
        "iso2": "LB",
        "phone_code": "961",
        "capital": "Beirut",
        "currency": "LBP",
        "native": "لبنان",
        "emoji": "🇱🇧",
        "emojiU": "U+1F1F1 U+1F1E7"
    },
    {
        "id": 122,
        "name": "Lesotho",
        "iso3": "LSO",
        "iso2": "LS",
        "phone_code": "266",
        "capital": "Maseru",
        "currency": "LSL",
        "native": "Lesotho",
        "emoji": "🇱🇸",
        "emojiU": "U+1F1F1 U+1F1F8"
    },
    {
        "id": 123,
        "name": "Liberia",
        "iso3": "LBR",
        "iso2": "LR",
        "phone_code": "231",
        "capital": "Monrovia",
        "currency": "LRD",
        "native": "Liberia",
        "emoji": "🇱🇷",
        "emojiU": "U+1F1F1 U+1F1F7"
    },
    {
        "id": 124,
        "name": "Libya",
        "iso3": "LBY",
        "iso2": "LY",
        "phone_code": "218",
        "capital": "Tripolis",
        "currency": "LYD",
        "native": "‏ليبيا",
        "emoji": "🇱🇾",
        "emojiU": "U+1F1F1 U+1F1FE"
    },
    {
        "id": 125,
        "name": "Liechtenstein",
        "iso3": "LIE",
        "iso2": "LI",
        "phone_code": "423",
        "capital": "Vaduz",
        "currency": "CHF",
        "native": "Liechtenstein",
        "emoji": "🇱🇮",
        "emojiU": "U+1F1F1 U+1F1EE"
    },
    {
        "id": 126,
        "name": "Lithuania",
        "iso3": "LTU",
        "iso2": "LT",
        "phone_code": "370",
        "capital": "Vilnius",
        "currency": "EUR",
        "native": "Lietuva",
        "emoji": "🇱🇹",
        "emojiU": "U+1F1F1 U+1F1F9"
    },
    {
        "id": 127,
        "name": "Luxembourg",
        "iso3": "LUX",
        "iso2": "LU",
        "phone_code": "352",
        "capital": "Luxembourg",
        "currency": "EUR",
        "native": "Luxembourg",
        "emoji": "🇱🇺",
        "emojiU": "U+1F1F1 U+1F1FA"
    },
    {
        "id": 128,
        "name": "Macau S.A.R.",
        "iso3": "MAC",
        "iso2": "MO",
        "phone_code": "853",
        "capital": "Macao",
        "currency": "MOP",
        "native": "澳門",
        "emoji": "🇲🇴",
        "emojiU": "U+1F1F2 U+1F1F4"
    },
    {
        "id": 129,
        "name": "Macedonia",
        "iso3": "MKD",
        "iso2": "MK",
        "phone_code": "389",
        "capital": "Skopje",
        "currency": "MKD",
        "native": "Северна Македонија",
        "emoji": "🇲🇰",
        "emojiU": "U+1F1F2 U+1F1F0"
    },
    {
        "id": 130,
        "name": "Madagascar",
        "iso3": "MDG",
        "iso2": "MG",
        "phone_code": "261",
        "capital": "Antananarivo",
        "currency": "MGA",
        "native": "Madagasikara",
        "emoji": "🇲🇬",
        "emojiU": "U+1F1F2 U+1F1EC"
    },
    {
        "id": 131,
        "name": "Malawi",
        "iso3": "MWI",
        "iso2": "MW",
        "phone_code": "265",
        "capital": "Lilongwe",
        "currency": "MWK",
        "native": "Malawi",
        "emoji": "🇲🇼",
        "emojiU": "U+1F1F2 U+1F1FC"
    },
    {
        "id": 132,
        "name": "Malaysia",
        "iso3": "MYS",
        "iso2": "MY",
        "phone_code": "60",
        "capital": "Kuala Lumpur",
        "currency": "MYR",
        "native": "Malaysia",
        "emoji": "🇲🇾",
        "emojiU": "U+1F1F2 U+1F1FE"
    },
    {
        "id": 133,
        "name": "Maldives",
        "iso3": "MDV",
        "iso2": "MV",
        "phone_code": "960",
        "capital": "Male",
        "currency": "MVR",
        "native": "Maldives",
        "emoji": "🇲🇻",
        "emojiU": "U+1F1F2 U+1F1FB"
    },
    {
        "id": 134,
        "name": "Mali",
        "iso3": "MLI",
        "iso2": "ML",
        "phone_code": "223",
        "capital": "Bamako",
        "currency": "XOF",
        "native": "Mali",
        "emoji": "🇲🇱",
        "emojiU": "U+1F1F2 U+1F1F1"
    },
    {
        "id": 135,
        "name": "Malta",
        "iso3": "MLT",
        "iso2": "MT",
        "phone_code": "356",
        "capital": "Valletta",
        "currency": "EUR",
        "native": "Malta",
        "emoji": "🇲🇹",
        "emojiU": "U+1F1F2 U+1F1F9"
    },
    {
        "id": 136,
        "name": "Man (Isle of)",
        "iso3": "IMN",
        "iso2": "IM",
        "phone_code": "+44-1624",
        "capital": "Douglas, Isle of Man",
        "currency": "GBP",
        "native": "Isle of Man",
        "emoji": "🇮🇲",
        "emojiU": "U+1F1EE U+1F1F2"
    },
    {
        "id": 137,
        "name": "Marshall Islands",
        "iso3": "MHL",
        "iso2": "MH",
        "phone_code": "692",
        "capital": "Majuro",
        "currency": "USD",
        "native": "M̧ajeļ",
        "emoji": "🇲🇭",
        "emojiU": "U+1F1F2 U+1F1ED"
    },
    {
        "id": 138,
        "name": "Martinique",
        "iso3": "MTQ",
        "iso2": "MQ",
        "phone_code": "596",
        "capital": "Fort-de-France",
        "currency": "EUR",
        "native": "Martinique",
        "emoji": "🇲🇶",
        "emojiU": "U+1F1F2 U+1F1F6"
    },
    {
        "id": 139,
        "name": "Mauritania",
        "iso3": "MRT",
        "iso2": "MR",
        "phone_code": "222",
        "capital": "Nouakchott",
        "currency": "MRO",
        "native": "موريتانيا",
        "emoji": "🇲🇷",
        "emojiU": "U+1F1F2 U+1F1F7"
    },
    {
        "id": 140,
        "name": "Mauritius",
        "iso3": "MUS",
        "iso2": "MU",
        "phone_code": "230",
        "capital": "Port Louis",
        "currency": "MUR",
        "native": "Maurice",
        "emoji": "🇲🇺",
        "emojiU": "U+1F1F2 U+1F1FA"
    },
    {
        "id": 141,
        "name": "Mayotte",
        "iso3": "MYT",
        "iso2": "YT",
        "phone_code": "262",
        "capital": "Mamoudzou",
        "currency": "EUR",
        "native": "Mayotte",
        "emoji": "🇾🇹",
        "emojiU": "U+1F1FE U+1F1F9"
    },
    {
        "id": 142,
        "name": "Mexico",
        "iso3": "MEX",
        "iso2": "MX",
        "phone_code": "52",
        "capital": "Mexico City",
        "currency": "MXN",
        "native": "México",
        "emoji": "🇲🇽",
        "emojiU": "U+1F1F2 U+1F1FD"
    },
    {
        "id": 143,
        "name": "Micronesia",
        "iso3": "FSM",
        "iso2": "FM",
        "phone_code": "691",
        "capital": "Palikir",
        "currency": "USD",
        "native": "Micronesia",
        "emoji": "🇫🇲",
        "emojiU": "U+1F1EB U+1F1F2"
    },
    {
        "id": 144,
        "name": "Moldova",
        "iso3": "MDA",
        "iso2": "MD",
        "phone_code": "373",
        "capital": "Chisinau",
        "currency": "MDL",
        "native": "Moldova",
        "emoji": "🇲🇩",
        "emojiU": "U+1F1F2 U+1F1E9"
    },
    {
        "id": 145,
        "name": "Monaco",
        "iso3": "MCO",
        "iso2": "MC",
        "phone_code": "377",
        "capital": "Monaco",
        "currency": "EUR",
        "native": "Monaco",
        "emoji": "🇲🇨",
        "emojiU": "U+1F1F2 U+1F1E8"
    },
    {
        "id": 146,
        "name": "Mongolia",
        "iso3": "MNG",
        "iso2": "MN",
        "phone_code": "976",
        "capital": "Ulan Bator",
        "currency": "MNT",
        "native": "Монгол улс",
        "emoji": "🇲🇳",
        "emojiU": "U+1F1F2 U+1F1F3"
    },
    {
        "id": 147,
        "name": "Montenegro",
        "iso3": "MNE",
        "iso2": "ME",
        "phone_code": "382",
        "capital": "Podgorica",
        "currency": "EUR",
        "native": "Црна Гора",
        "emoji": "🇲🇪",
        "emojiU": "U+1F1F2 U+1F1EA"
    },
    {
        "id": 148,
        "name": "Montserrat",
        "iso3": "MSR",
        "iso2": "MS",
        "phone_code": "+1-664",
        "capital": "Plymouth",
        "currency": "XCD",
        "native": "Montserrat",
        "emoji": "🇲🇸",
        "emojiU": "U+1F1F2 U+1F1F8"
    },
    {
        "id": 149,
        "name": "Morocco",
        "iso3": "MAR",
        "iso2": "MA",
        "phone_code": "212",
        "capital": "Rabat",
        "currency": "MAD",
        "native": "المغرب",
        "emoji": "🇲🇦",
        "emojiU": "U+1F1F2 U+1F1E6"
    },
    {
        "id": 150,
        "name": "Mozambique",
        "iso3": "MOZ",
        "iso2": "MZ",
        "phone_code": "258",
        "capital": "Maputo",
        "currency": "MZN",
        "native": "Moçambique",
        "emoji": "🇲🇿",
        "emojiU": "U+1F1F2 U+1F1FF"
    },
    {
        "id": 151,
        "name": "Myanmar",
        "iso3": "MMR",
        "iso2": "MM",
        "phone_code": "95",
        "capital": "Nay Pyi Taw",
        "currency": "MMK",
        "native": "မြန်မာ",
        "emoji": "🇲🇲",
        "emojiU": "U+1F1F2 U+1F1F2"
    },
    {
        "id": 152,
        "name": "Namibia",
        "iso3": "NAM",
        "iso2": "NA",
        "phone_code": "264",
        "capital": "Windhoek",
        "currency": "NAD",
        "native": "Namibia",
        "emoji": "🇳🇦",
        "emojiU": "U+1F1F3 U+1F1E6"
    },
    {
        "id": 153,
        "name": "Nauru",
        "iso3": "NRU",
        "iso2": "NR",
        "phone_code": "674",
        "capital": "Yaren",
        "currency": "AUD",
        "native": "Nauru",
        "emoji": "🇳🇷",
        "emojiU": "U+1F1F3 U+1F1F7"
    },
    {
        "id": 154,
        "name": "Nepal",
        "iso3": "NPL",
        "iso2": "NP",
        "phone_code": "977",
        "capital": "Kathmandu",
        "currency": "NPR",
        "native": "नपल",
        "emoji": "🇳🇵",
        "emojiU": "U+1F1F3 U+1F1F5"
    },
    {
        "id": 155,
        "name": "Netherlands Antilles",
        "iso3": "ANT",
        "iso2": "AN",
        "phone_code": "",
        "capital": "",
        "currency": "",
        "native": null,
        "emoji": null,
        "emojiU": null
    },
    {
        "id": 156,
        "name": "Netherlands The",
        "iso3": "NLD",
        "iso2": "NL",
        "phone_code": "31",
        "capital": "Amsterdam",
        "currency": "EUR",
        "native": "Nederland",
        "emoji": "🇳🇱",
        "emojiU": "U+1F1F3 U+1F1F1"
    },
    {
        "id": 157,
        "name": "New Caledonia",
        "iso3": "NCL",
        "iso2": "NC",
        "phone_code": "687",
        "capital": "Noumea",
        "currency": "XPF",
        "native": "Nouvelle-Calédonie",
        "emoji": "🇳🇨",
        "emojiU": "U+1F1F3 U+1F1E8"
    },
    {
        "id": 158,
        "name": "New Zealand",
        "iso3": "NZL",
        "iso2": "NZ",
        "phone_code": "64",
        "capital": "Wellington",
        "currency": "NZD",
        "native": "New Zealand",
        "emoji": "🇳🇿",
        "emojiU": "U+1F1F3 U+1F1FF"
    },
    {
        "id": 159,
        "name": "Nicaragua",
        "iso3": "NIC",
        "iso2": "NI",
        "phone_code": "505",
        "capital": "Managua",
        "currency": "NIO",
        "native": "Nicaragua",
        "emoji": "🇳🇮",
        "emojiU": "U+1F1F3 U+1F1EE"
    },
    {
        "id": 160,
        "name": "Niger",
        "iso3": "NER",
        "iso2": "NE",
        "phone_code": "227",
        "capital": "Niamey",
        "currency": "XOF",
        "native": "Niger",
        "emoji": "🇳🇪",
        "emojiU": "U+1F1F3 U+1F1EA"
    },
    {
        "id": 161,
        "name": "Nigeria",
        "iso3": "NGA",
        "iso2": "NG",
        "phone_code": "234",
        "capital": "Abuja",
        "currency": "NGN",
        "native": "Nigeria",
        "emoji": "🇳🇬",
        "emojiU": "U+1F1F3 U+1F1EC"
    },
    {
        "id": 162,
        "name": "Niue",
        "iso3": "NIU",
        "iso2": "NU",
        "phone_code": "683",
        "capital": "Alofi",
        "currency": "NZD",
        "native": "Niuē",
        "emoji": "🇳🇺",
        "emojiU": "U+1F1F3 U+1F1FA"
    },
    {
        "id": 163,
        "name": "Norfolk Island",
        "iso3": "NFK",
        "iso2": "NF",
        "phone_code": "672",
        "capital": "Kingston",
        "currency": "AUD",
        "native": "Norfolk Island",
        "emoji": "🇳🇫",
        "emojiU": "U+1F1F3 U+1F1EB"
    },
    {
        "id": 164,
        "name": "Northern Mariana Islands",
        "iso3": "MNP",
        "iso2": "MP",
        "phone_code": "+1-670",
        "capital": "Saipan",
        "currency": "USD",
        "native": "Northern Mariana Islands",
        "emoji": "🇲🇵",
        "emojiU": "U+1F1F2 U+1F1F5"
    },
    {
        "id": 165,
        "name": "Norway",
        "iso3": "NOR",
        "iso2": "NO",
        "phone_code": "47",
        "capital": "Oslo",
        "currency": "NOK",
        "native": "Norge",
        "emoji": "🇳🇴",
        "emojiU": "U+1F1F3 U+1F1F4"
    },
    {
        "id": 166,
        "name": "Oman",
        "iso3": "OMN",
        "iso2": "OM",
        "phone_code": "968",
        "capital": "Muscat",
        "currency": "OMR",
        "native": "عمان",
        "emoji": "🇴🇲",
        "emojiU": "U+1F1F4 U+1F1F2"
    },
    {
        "id": 167,
        "name": "Pakistan",
        "iso3": "PAK",
        "iso2": "PK",
        "phone_code": "92",
        "capital": "Islamabad",
        "currency": "PKR",
        "native": "Pakistan",
        "emoji": "🇵🇰",
        "emojiU": "U+1F1F5 U+1F1F0"
    },
    {
        "id": 168,
        "name": "Palau",
        "iso3": "PLW",
        "iso2": "PW",
        "phone_code": "680",
        "capital": "Melekeok",
        "currency": "USD",
        "native": "Palau",
        "emoji": "🇵🇼",
        "emojiU": "U+1F1F5 U+1F1FC"
    },
    {
        "id": 169,
        "name": "Palestinian Territory Occupied",
        "iso3": "PSE",
        "iso2": "PS",
        "phone_code": "970",
        "capital": "East Jerusalem",
        "currency": "ILS",
        "native": "فلسطين",
        "emoji": "🇵🇸",
        "emojiU": "U+1F1F5 U+1F1F8"
    },
    {
        "id": 170,
        "name": "Panama",
        "iso3": "PAN",
        "iso2": "PA",
        "phone_code": "507",
        "capital": "Panama City",
        "currency": "PAB",
        "native": "Panamá",
        "emoji": "🇵🇦",
        "emojiU": "U+1F1F5 U+1F1E6"
    },
    {
        "id": 171,
        "name": "Papua new Guinea",
        "iso3": "PNG",
        "iso2": "PG",
        "phone_code": "675",
        "capital": "Port Moresby",
        "currency": "PGK",
        "native": "Papua Niugini",
        "emoji": "🇵🇬",
        "emojiU": "U+1F1F5 U+1F1EC"
    },
    {
        "id": 172,
        "name": "Paraguay",
        "iso3": "PRY",
        "iso2": "PY",
        "phone_code": "595",
        "capital": "Asuncion",
        "currency": "PYG",
        "native": "Paraguay",
        "emoji": "🇵🇾",
        "emojiU": "U+1F1F5 U+1F1FE"
    },
    {
        "id": 173,
        "name": "Peru",
        "iso3": "PER",
        "iso2": "PE",
        "phone_code": "51",
        "capital": "Lima",
        "currency": "PEN",
        "native": "Perú",
        "emoji": "🇵🇪",
        "emojiU": "U+1F1F5 U+1F1EA"
    },
    {
        "id": 174,
        "name": "Philippines",
        "iso3": "PHL",
        "iso2": "PH",
        "phone_code": "63",
        "capital": "Manila",
        "currency": "PHP",
        "native": "Pilipinas",
        "emoji": "🇵🇭",
        "emojiU": "U+1F1F5 U+1F1ED"
    },
    {
        "id": 175,
        "name": "Pitcairn Island",
        "iso3": "PCN",
        "iso2": "PN",
        "phone_code": "870",
        "capital": "Adamstown",
        "currency": "NZD",
        "native": "Pitcairn Islands",
        "emoji": "🇵🇳",
        "emojiU": "U+1F1F5 U+1F1F3"
    },
    {
        "id": 176,
        "name": "Poland",
        "iso3": "POL",
        "iso2": "PL",
        "phone_code": "48",
        "capital": "Warsaw",
        "currency": "PLN",
        "native": "Polska",
        "emoji": "🇵🇱",
        "emojiU": "U+1F1F5 U+1F1F1"
    },
    {
        "id": 177,
        "name": "Portugal",
        "iso3": "PRT",
        "iso2": "PT",
        "phone_code": "351",
        "capital": "Lisbon",
        "currency": "EUR",
        "native": "Portugal",
        "emoji": "🇵🇹",
        "emojiU": "U+1F1F5 U+1F1F9"
    },
    {
        "id": 178,
        "name": "Puerto Rico",
        "iso3": "PRI",
        "iso2": "PR",
        "phone_code": "+1-787 and 1-939",
        "capital": "San Juan",
        "currency": "USD",
        "native": "Puerto Rico",
        "emoji": "🇵🇷",
        "emojiU": "U+1F1F5 U+1F1F7"
    },
    {
        "id": 179,
        "name": "Qatar",
        "iso3": "QAT",
        "iso2": "QA",
        "phone_code": "974",
        "capital": "Doha",
        "currency": "QAR",
        "native": "قطر",
        "emoji": "🇶🇦",
        "emojiU": "U+1F1F6 U+1F1E6"
    },
    {
        "id": 180,
        "name": "Reunion",
        "iso3": "REU",
        "iso2": "RE",
        "phone_code": "262",
        "capital": "Saint-Denis",
        "currency": "EUR",
        "native": "La Réunion",
        "emoji": "🇷🇪",
        "emojiU": "U+1F1F7 U+1F1EA"
    },
    {
        "id": 181,
        "name": "Romania",
        "iso3": "ROU",
        "iso2": "RO",
        "phone_code": "40",
        "capital": "Bucharest",
        "currency": "RON",
        "native": "România",
        "emoji": "🇷🇴",
        "emojiU": "U+1F1F7 U+1F1F4"
    },
    {
        "id": 182,
        "name": "Russia",
        "iso3": "RUS",
        "iso2": "RU",
        "phone_code": "7",
        "capital": "Moscow",
        "currency": "RUB",
        "native": "Россия",
        "emoji": "🇷🇺",
        "emojiU": "U+1F1F7 U+1F1FA"
    },
    {
        "id": 183,
        "name": "Rwanda",
        "iso3": "RWA",
        "iso2": "RW",
        "phone_code": "250",
        "capital": "Kigali",
        "currency": "RWF",
        "native": "Rwanda",
        "emoji": "🇷🇼",
        "emojiU": "U+1F1F7 U+1F1FC"
    },
    {
        "id": 184,
        "name": "Saint Helena",
        "iso3": "SHN",
        "iso2": "SH",
        "phone_code": "290",
        "capital": "Jamestown",
        "currency": "SHP",
        "native": "Saint Helena",
        "emoji": "🇸🇭",
        "emojiU": "U+1F1F8 U+1F1ED"
    },
    {
        "id": 185,
        "name": "Saint Kitts And Nevis",
        "iso3": "KNA",
        "iso2": "KN",
        "phone_code": "+1-869",
        "capital": "Basseterre",
        "currency": "XCD",
        "native": "Saint Kitts and Nevis",
        "emoji": "🇰🇳",
        "emojiU": "U+1F1F0 U+1F1F3"
    },
    {
        "id": 186,
        "name": "Saint Lucia",
        "iso3": "LCA",
        "iso2": "LC",
        "phone_code": "+1-758",
        "capital": "Castries",
        "currency": "XCD",
        "native": "Saint Lucia",
        "emoji": "🇱🇨",
        "emojiU": "U+1F1F1 U+1F1E8"
    },
    {
        "id": 187,
        "name": "Saint Pierre and Miquelon",
        "iso3": "SPM",
        "iso2": "PM",
        "phone_code": "508",
        "capital": "Saint-Pierre",
        "currency": "EUR",
        "native": "Saint-Pierre-et-Miquelon",
        "emoji": "🇵🇲",
        "emojiU": "U+1F1F5 U+1F1F2"
    },
    {
        "id": 188,
        "name": "Saint Vincent And The Grenadines",
        "iso3": "VCT",
        "iso2": "VC",
        "phone_code": "+1-784",
        "capital": "Kingstown",
        "currency": "XCD",
        "native": "Saint Vincent and the Grenadines",
        "emoji": "🇻🇨",
        "emojiU": "U+1F1FB U+1F1E8"
    },
    {
        "id": 189,
        "name": "Saint-Barthelemy",
        "iso3": "BLM",
        "iso2": "BL",
        "phone_code": "590",
        "capital": "Gustavia",
        "currency": "EUR",
        "native": "Saint-Barthélemy",
        "emoji": "🇧🇱",
        "emojiU": "U+1F1E7 U+1F1F1"
    },
    {
        "id": 190,
        "name": "Saint-Martin (French part)",
        "iso3": "MAF",
        "iso2": "MF",
        "phone_code": "590",
        "capital": "Marigot",
        "currency": "EUR",
        "native": "Saint-Martin",
        "emoji": "🇲🇫",
        "emojiU": "U+1F1F2 U+1F1EB"
    },
    {
        "id": 191,
        "name": "Samoa",
        "iso3": "WSM",
        "iso2": "WS",
        "phone_code": "685",
        "capital": "Apia",
        "currency": "WST",
        "native": "Samoa",
        "emoji": "🇼🇸",
        "emojiU": "U+1F1FC U+1F1F8"
    },
    {
        "id": 192,
        "name": "San Marino",
        "iso3": "SMR",
        "iso2": "SM",
        "phone_code": "378",
        "capital": "San Marino",
        "currency": "EUR",
        "native": "San Marino",
        "emoji": "🇸🇲",
        "emojiU": "U+1F1F8 U+1F1F2"
    },
    {
        "id": 193,
        "name": "Sao Tome and Principe",
        "iso3": "STP",
        "iso2": "ST",
        "phone_code": "239",
        "capital": "Sao Tome",
        "currency": "STD",
        "native": "São Tomé e Príncipe",
        "emoji": "🇸🇹",
        "emojiU": "U+1F1F8 U+1F1F9"
    },
    {
        "id": 194,
        "name": "Saudi Arabia",
        "iso3": "SAU",
        "iso2": "SA",
        "phone_code": "966",
        "capital": "Riyadh",
        "currency": "SAR",
        "native": "العربية السعودية",
        "emoji": "🇸🇦",
        "emojiU": "U+1F1F8 U+1F1E6"
    },
    {
        "id": 195,
        "name": "Senegal",
        "iso3": "SEN",
        "iso2": "SN",
        "phone_code": "221",
        "capital": "Dakar",
        "currency": "XOF",
        "native": "Sénégal",
        "emoji": "🇸🇳",
        "emojiU": "U+1F1F8 U+1F1F3"
    },
    {
        "id": 196,
        "name": "Serbia",
        "iso3": "SRB",
        "iso2": "RS",
        "phone_code": "381",
        "capital": "Belgrade",
        "currency": "RSD",
        "native": "Србија",
        "emoji": "🇷🇸",
        "emojiU": "U+1F1F7 U+1F1F8"
    },
    {
        "id": 197,
        "name": "Seychelles",
        "iso3": "SYC",
        "iso2": "SC",
        "phone_code": "248",
        "capital": "Victoria",
        "currency": "SCR",
        "native": "Seychelles",
        "emoji": "🇸🇨",
        "emojiU": "U+1F1F8 U+1F1E8"
    },
    {
        "id": 198,
        "name": "Sierra Leone",
        "iso3": "SLE",
        "iso2": "SL",
        "phone_code": "232",
        "capital": "Freetown",
        "currency": "SLL",
        "native": "Sierra Leone",
        "emoji": "🇸🇱",
        "emojiU": "U+1F1F8 U+1F1F1"
    },
    {
        "id": 199,
        "name": "Singapore",
        "iso3": "SGP",
        "iso2": "SG",
        "phone_code": "65",
        "capital": "Singapur",
        "currency": "SGD",
        "native": "Singapore",
        "emoji": "🇸🇬",
        "emojiU": "U+1F1F8 U+1F1EC"
    },
    {
        "id": 200,
        "name": "Slovakia",
        "iso3": "SVK",
        "iso2": "SK",
        "phone_code": "421",
        "capital": "Bratislava",
        "currency": "EUR",
        "native": "Slovensko",
        "emoji": "🇸🇰",
        "emojiU": "U+1F1F8 U+1F1F0"
    },
    {
        "id": 201,
        "name": "Slovenia",
        "iso3": "SVN",
        "iso2": "SI",
        "phone_code": "386",
        "capital": "Ljubljana",
        "currency": "EUR",
        "native": "Slovenija",
        "emoji": "🇸🇮",
        "emojiU": "U+1F1F8 U+1F1EE"
    },
    {
        "id": 202,
        "name": "Solomon Islands",
        "iso3": "SLB",
        "iso2": "SB",
        "phone_code": "677",
        "capital": "Honiara",
        "currency": "SBD",
        "native": "Solomon Islands",
        "emoji": "🇸🇧",
        "emojiU": "U+1F1F8 U+1F1E7"
    },
    {
        "id": 203,
        "name": "Somalia",
        "iso3": "SOM",
        "iso2": "SO",
        "phone_code": "252",
        "capital": "Mogadishu",
        "currency": "SOS",
        "native": "Soomaaliya",
        "emoji": "🇸🇴",
        "emojiU": "U+1F1F8 U+1F1F4"
    },
    {
        "id": 204,
        "name": "South Africa",
        "iso3": "ZAF",
        "iso2": "ZA",
        "phone_code": "27",
        "capital": "Pretoria",
        "currency": "ZAR",
        "native": "South Africa",
        "emoji": "🇿🇦",
        "emojiU": "U+1F1FF U+1F1E6"
    },
    {
        "id": 205,
        "name": "South Georgia",
        "iso3": "SGS",
        "iso2": "GS",
        "phone_code": "",
        "capital": "Grytviken",
        "currency": "GBP",
        "native": "South Georgia",
        "emoji": "🇬🇸",
        "emojiU": "U+1F1EC U+1F1F8"
    },
    {
        "id": 206,
        "name": "South Sudan",
        "iso3": "SSD",
        "iso2": "SS",
        "phone_code": "211",
        "capital": "Juba",
        "currency": "SSP",
        "native": "South Sudan",
        "emoji": "🇸🇸",
        "emojiU": "U+1F1F8 U+1F1F8"
    },
    {
        "id": 207,
        "name": "Spain",
        "iso3": "ESP",
        "iso2": "ES",
        "phone_code": "34",
        "capital": "Madrid",
        "currency": "EUR",
        "native": "España",
        "emoji": "🇪🇸",
        "emojiU": "U+1F1EA U+1F1F8"
    },
    {
        "id": 208,
        "name": "Sri Lanka",
        "iso3": "LKA",
        "iso2": "LK",
        "phone_code": "94",
        "capital": "Colombo",
        "currency": "LKR",
        "native": "śrī laṃkāva",
        "emoji": "🇱🇰",
        "emojiU": "U+1F1F1 U+1F1F0"
    },
    {
        "id": 209,
        "name": "Sudan",
        "iso3": "SDN",
        "iso2": "SD",
        "phone_code": "249",
        "capital": "Khartoum",
        "currency": "SDG",
        "native": "السودان",
        "emoji": "🇸🇩",
        "emojiU": "U+1F1F8 U+1F1E9"
    },
    {
        "id": 210,
        "name": "Suriname",
        "iso3": "SUR",
        "iso2": "SR",
        "phone_code": "597",
        "capital": "Paramaribo",
        "currency": "SRD",
        "native": "Suriname",
        "emoji": "🇸🇷",
        "emojiU": "U+1F1F8 U+1F1F7"
    },
    {
        "id": 211,
        "name": "Svalbard And Jan Mayen Islands",
        "iso3": "SJM",
        "iso2": "SJ",
        "phone_code": "47",
        "capital": "Longyearbyen",
        "currency": "NOK",
        "native": "Svalbard og Jan Mayen",
        "emoji": "🇸🇯",
        "emojiU": "U+1F1F8 U+1F1EF"
    },
    {
        "id": 212,
        "name": "Swaziland",
        "iso3": "SWZ",
        "iso2": "SZ",
        "phone_code": "268",
        "capital": "Mbabane",
        "currency": "SZL",
        "native": "Swaziland",
        "emoji": "🇸🇿",
        "emojiU": "U+1F1F8 U+1F1FF"
    },
    {
        "id": 213,
        "name": "Sweden",
        "iso3": "SWE",
        "iso2": "SE",
        "phone_code": "46",
        "capital": "Stockholm",
        "currency": "SEK",
        "native": "Sverige",
        "emoji": "🇸🇪",
        "emojiU": "U+1F1F8 U+1F1EA"
    },
    {
        "id": 214,
        "name": "Switzerland",
        "iso3": "CHE",
        "iso2": "CH",
        "phone_code": "41",
        "capital": "Berne",
        "currency": "CHF",
        "native": "Schweiz",
        "emoji": "🇨🇭",
        "emojiU": "U+1F1E8 U+1F1ED"
    },
    {
        "id": 215,
        "name": "Syria",
        "iso3": "SYR",
        "iso2": "SY",
        "phone_code": "963",
        "capital": "Damascus",
        "currency": "SYP",
        "native": "سوريا",
        "emoji": "🇸🇾",
        "emojiU": "U+1F1F8 U+1F1FE"
    },
    {
        "id": 216,
        "name": "Taiwan",
        "iso3": "TWN",
        "iso2": "TW",
        "phone_code": "886",
        "capital": "Taipei",
        "currency": "TWD",
        "native": "臺灣",
        "emoji": "🇹🇼",
        "emojiU": "U+1F1F9 U+1F1FC"
    },
    {
        "id": 217,
        "name": "Tajikistan",
        "iso3": "TJK",
        "iso2": "TJ",
        "phone_code": "992",
        "capital": "Dushanbe",
        "currency": "TJS",
        "native": "Тоҷикистон",
        "emoji": "🇹🇯",
        "emojiU": "U+1F1F9 U+1F1EF"
    },
    {
        "id": 218,
        "name": "Tanzania",
        "iso3": "TZA",
        "iso2": "TZ",
        "phone_code": "255",
        "capital": "Dodoma",
        "currency": "TZS",
        "native": "Tanzania",
        "emoji": "🇹🇿",
        "emojiU": "U+1F1F9 U+1F1FF"
    },
    {
        "id": 219,
        "name": "Thailand",
        "iso3": "THA",
        "iso2": "TH",
        "phone_code": "66",
        "capital": "Bangkok",
        "currency": "THB",
        "native": "ประเทศไทย",
        "emoji": "🇹🇭",
        "emojiU": "U+1F1F9 U+1F1ED"
    },
    {
        "id": 220,
        "name": "Togo",
        "iso3": "TGO",
        "iso2": "TG",
        "phone_code": "228",
        "capital": "Lome",
        "currency": "XOF",
        "native": "Togo",
        "emoji": "🇹🇬",
        "emojiU": "U+1F1F9 U+1F1EC"
    },
    {
        "id": 221,
        "name": "Tokelau",
        "iso3": "TKL",
        "iso2": "TK",
        "phone_code": "690",
        "capital": "",
        "currency": "NZD",
        "native": "Tokelau",
        "emoji": "🇹🇰",
        "emojiU": "U+1F1F9 U+1F1F0"
    },
    {
        "id": 222,
        "name": "Tonga",
        "iso3": "TON",
        "iso2": "TO",
        "phone_code": "676",
        "capital": "Nuku'alofa",
        "currency": "TOP",
        "native": "Tonga",
        "emoji": "🇹🇴",
        "emojiU": "U+1F1F9 U+1F1F4"
    },
    {
        "id": 223,
        "name": "Trinidad And Tobago",
        "iso3": "TTO",
        "iso2": "TT",
        "phone_code": "+1-868",
        "capital": "Port of Spain",
        "currency": "TTD",
        "native": "Trinidad and Tobago",
        "emoji": "🇹🇹",
        "emojiU": "U+1F1F9 U+1F1F9"
    },
    {
        "id": 224,
        "name": "Tunisia",
        "iso3": "TUN",
        "iso2": "TN",
        "phone_code": "216",
        "capital": "Tunis",
        "currency": "TND",
        "native": "تونس",
        "emoji": "🇹🇳",
        "emojiU": "U+1F1F9 U+1F1F3"
    },
    {
        "id": 225,
        "name": "Turkey",
        "iso3": "TUR",
        "iso2": "TR",
        "phone_code": "90",
        "capital": "Ankara",
        "currency": "TRY",
        "native": "Türkiye",
        "emoji": "🇹🇷",
        "emojiU": "U+1F1F9 U+1F1F7"
    },
    {
        "id": 226,
        "name": "Turkmenistan",
        "iso3": "TKM",
        "iso2": "TM",
        "phone_code": "993",
        "capital": "Ashgabat",
        "currency": "TMT",
        "native": "Türkmenistan",
        "emoji": "🇹🇲",
        "emojiU": "U+1F1F9 U+1F1F2"
    },
    {
        "id": 227,
        "name": "Turks And Caicos Islands",
        "iso3": "TCA",
        "iso2": "TC",
        "phone_code": "+1-649",
        "capital": "Cockburn Town",
        "currency": "USD",
        "native": "Turks and Caicos Islands",
        "emoji": "🇹🇨",
        "emojiU": "U+1F1F9 U+1F1E8"
    },
    {
        "id": 228,
        "name": "Tuvalu",
        "iso3": "TUV",
        "iso2": "TV",
        "phone_code": "688",
        "capital": "Funafuti",
        "currency": "AUD",
        "native": "Tuvalu",
        "emoji": "🇹🇻",
        "emojiU": "U+1F1F9 U+1F1FB"
    },
    {
        "id": 229,
        "name": "Uganda",
        "iso3": "UGA",
        "iso2": "UG",
        "phone_code": "256",
        "capital": "Kampala",
        "currency": "UGX",
        "native": "Uganda",
        "emoji": "🇺🇬",
        "emojiU": "U+1F1FA U+1F1EC"
    },
    {
        "id": 230,
        "name": "Ukraine",
        "iso3": "UKR",
        "iso2": "UA",
        "phone_code": "380",
        "capital": "Kiev",
        "currency": "UAH",
        "native": "Україна",
        "emoji": "🇺🇦",
        "emojiU": "U+1F1FA U+1F1E6"
    },
    {
        "id": 231,
        "name": "United Arab Emirates",
        "iso3": "ARE",
        "iso2": "AE",
        "phone_code": "971",
        "capital": "Abu Dhabi",
        "currency": "AED",
        "native": "دولة الإمارات العربية المتحدة",
        "emoji": "🇦🇪",
        "emojiU": "U+1F1E6 U+1F1EA"
    },
    {
        "id": 232,
        "name": "United Kingdom",
        "iso3": "GBR",
        "iso2": "GB",
        "phone_code": "44",
        "capital": "London",
        "currency": "GBP",
        "native": "United Kingdom",
        "emoji": "🇬🇧",
        "emojiU": "U+1F1EC U+1F1E7"
    },
    {
        "id": 233,
        "name": "United States",
        "iso3": "USA",
        "iso2": "US",
        "phone_code": "1",
        "capital": "Washington",
        "currency": "USD",
        "native": "United States",
        "emoji": "🇺🇸",
        "emojiU": "U+1F1FA U+1F1F8"
    },
    {
        "id": 234,
        "name": "United States Minor Outlying Islands",
        "iso3": "UMI",
        "iso2": "UM",
        "phone_code": "1",
        "capital": "",
        "currency": "USD",
        "native": "United States Minor Outlying Islands",
        "emoji": "🇺🇲",
        "emojiU": "U+1F1FA U+1F1F2"
    },
    {
        "id": 235,
        "name": "Uruguay",
        "iso3": "URY",
        "iso2": "UY",
        "phone_code": "598",
        "capital": "Montevideo",
        "currency": "UYU",
        "native": "Uruguay",
        "emoji": "🇺🇾",
        "emojiU": "U+1F1FA U+1F1FE"
    },
    {
        "id": 236,
        "name": "Uzbekistan",
        "iso3": "UZB",
        "iso2": "UZ",
        "phone_code": "998",
        "capital": "Tashkent",
        "currency": "UZS",
        "native": "O‘zbekiston",
        "emoji": "🇺🇿",
        "emojiU": "U+1F1FA U+1F1FF"
    },
    {
        "id": 237,
        "name": "Vanuatu",
        "iso3": "VUT",
        "iso2": "VU",
        "phone_code": "678",
        "capital": "Port Vila",
        "currency": "VUV",
        "native": "Vanuatu",
        "emoji": "🇻🇺",
        "emojiU": "U+1F1FB U+1F1FA"
    },
    {
        "id": 238,
        "name": "Vatican City State (Holy See)",
        "iso3": "VAT",
        "iso2": "VA",
        "phone_code": "379",
        "capital": "Vatican City",
        "currency": "EUR",
        "native": "Vaticano",
        "emoji": "🇻🇦",
        "emojiU": "U+1F1FB U+1F1E6"
    },
    {
        "id": 239,
        "name": "Venezuela",
        "iso3": "VEN",
        "iso2": "VE",
        "phone_code": "58",
        "capital": "Caracas",
        "currency": "VEF",
        "native": "Venezuela",
        "emoji": "🇻🇪",
        "emojiU": "U+1F1FB U+1F1EA"
    },
    {
        "id": 240,
        "name": "Vietnam",
        "iso3": "VNM",
        "iso2": "VN",
        "phone_code": "84",
        "capital": "Hanoi",
        "currency": "VND",
        "native": "Việt Nam",
        "emoji": "🇻🇳",
        "emojiU": "U+1F1FB U+1F1F3"
    },
    {
        "id": 241,
        "name": "Virgin Islands (British)",
        "iso3": "VGB",
        "iso2": "VG",
        "phone_code": "+1-284",
        "capital": "Road Town",
        "currency": "USD",
        "native": "British Virgin Islands",
        "emoji": "🇻🇬",
        "emojiU": "U+1F1FB U+1F1EC"
    },
    {
        "id": 242,
        "name": "Virgin Islands (US)",
        "iso3": "VIR",
        "iso2": "VI",
        "phone_code": "+1-340",
        "capital": "Charlotte Amalie",
        "currency": "USD",
        "native": "United States Virgin Islands",
        "emoji": "🇻🇮",
        "emojiU": "U+1F1FB U+1F1EE"
    },
    {
        "id": 243,
        "name": "Wallis And Futuna Islands",
        "iso3": "WLF",
        "iso2": "WF",
        "phone_code": "681",
        "capital": "Mata Utu",
        "currency": "XPF",
        "native": "Wallis et Futuna",
        "emoji": "🇼🇫",
        "emojiU": "U+1F1FC U+1F1EB"
    },
    {
        "id": 244,
        "name": "Western Sahara",
        "iso3": "ESH",
        "iso2": "EH",
        "phone_code": "212",
        "capital": "El-Aaiun",
        "currency": "MAD",
        "native": "الصحراء الغربية",
        "emoji": "🇪🇭",
        "emojiU": "U+1F1EA U+1F1ED"
    },
    {
        "id": 245,
        "name": "Yemen",
        "iso3": "YEM",
        "iso2": "YE",
        "phone_code": "967",
        "capital": "Sanaa",
        "currency": "YER",
        "native": "اليَمَن",
        "emoji": "🇾🇪",
        "emojiU": "U+1F1FE U+1F1EA"
    },
    {
        "id": 246,
        "name": "Zambia",
        "iso3": "ZMB",
        "iso2": "ZM",
        "phone_code": "260",
        "capital": "Lusaka",
        "currency": "ZMW",
        "native": "Zambia",
        "emoji": "🇿🇲",
        "emojiU": "U+1F1FF U+1F1F2"
    },
    {
        "id": 247,
        "name": "Zimbabwe",
        "iso3": "ZWE",
        "iso2": "ZW",
        "phone_code": "263",
        "capital": "Harare",
        "currency": "ZWL",
        "native": "Zimbabwe",
        "emoji": "🇿🇼",
        "emojiU": "U+1F1FF U+1F1FC"
    }
]
