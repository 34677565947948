import React, { useEffect, useState } from 'react';
import {
  AlertAction,
  MasterLayoutMiddle,
  FooterInner,
  AlertDialogue,
} from '@dclab/dc-react-ui-kit';
import { CheckoutPayment } from "master-react-lib";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux';
import { showAlert } from '../../redux/actions/alertActions';
import MasterCardImg from '../../assets/images/masterCardIcon.png'
import VisaCardImg from '../../assets/images/visa-icon.png'
import DiscoverCardImg from '../../assets/images/discovercard.png';
import AmericanExpressImg from '../../assets/images/american-express.png';
import DinersCardImg from '../../assets/images/dinerscard.png';
import HelpImg from '../../assets/images/help-24px.svg';
import NewCardImg from '../../assets/images/new-card.png'
import { updateCustomerPaymentMethods, deleteCustomerPaymentMethod } from './api';
import "./billingInfo.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import messages_content from '../../utils/messages.json';
const { payment_method_delete_success_message, payment_method_updated_success_message,  } = messages_content;


function EditBillingInfo(props) {

  const [cardDetails, setcardDetails] = React.useState({
    cardNumber: "", expiration: "", code: ""
  });
  const [openAlert, setOpenAlert] = useState(false);

  const [addressDetails, setaddressDetails] = React.useState({
    firstName: "", lastName: "", line1: "", line2: "", city: "", state: props.location?.state?.method?.billing_details?.address?.state ?? "", postalCode: "", country: "",
  });

  const [saveCardDetails, setsaveCardDetails] = React.useState(props.location?.state?.default ?? false);
  const [error, seterror] = useState(false)
  const [loading, setloading] = useState(false)

  let selectedCardDetails = {
    firstName: "Sheikh", lastName: "Rehman", line1: "Street Address", line2: "", city: "City", state: "State", postalCode: "Zip Code", country: "United States",
  };

  const [method, setmethod] = useState({})

  useEffect(() => {
    if (props.location?.state?.method) {
      const name = props.location?.state?.method?.billing_details?.name?.split(" ");
      setaddressDetails({
        ... props.location?.state?.method?.billing_details?.address, 
        state:  props.location?.state?.method?.billing_details?.address?.state,
        firstName: name[0], 
        lastName: name[1] ? name[1] : '', 
        postalCode: props.location?.state?.method?.billing_details?.address?.postal_code,
      });
      setmethod(props.location.state.method);
      setcardDetails({
        cardNumber: `•••• •••• 	•••• ${props.location?.state?.method?.card?.last4}`,
        expiration: `${props.location?.state?.method?.card?.exp_month}/${parseInt(props.location?.state?.method?.card?.exp_year) - 2000}`,
        code: '123'
      });

    }
  }, [])

  // console.log("method", method);
  // console.log("addressDetails: ", addressDetails);

  const brandImage = (name) =>  {
    switch(name) {
      case 'diners' :
        return DinersCardImg;

      case 'discover' :
        return DiscoverCardImg;

      case 'mastercard':
        return MasterCardImg;

      case 'visa':
        return VisaCardImg;
      
      default: 
        return ''
    }
  };

  const styleAlert = {
    width: 470,
    marginLeft: 800
  };

  const handlePaymentSelectChange = (selectedPaymentCard) => {
    console.log("selectedPaymentCard: ", selectedPaymentCard);
  };

  const onEditBillingDetailClick = () => {
    console.log("onEditBillingDetailClick");
  };

  const creditCardsList = [];

  const onPlaceOrderSubmit = async (details) => {
    setloading(true)
    const { firstName, lastName, line1, line2, city, state, postalCode, country } = details?.addressDetails;

    let billing_details = {
      address: {
        line1, line2, city, state, postal_code: postalCode, country
      },
      name: firstName + " " + lastName,
    };
    let orgId = localStorage.getItem("orgID");
    let paymentMethodId = method?.id;

    try {
      let res = await updateCustomerPaymentMethods({ billing_details, orgId, paymentMethodId, saveCardDetails });
      if (res?.data?.error) {
        console.log("error", res?.data?.message)
        seterror(res?.data?.message || "Invalid Payment Credentials");
        setloading(false)
      } else {
        seterror(false)
        setloading(false)
        props.showAlert(payment_method_updated_success_message);
        props.history.goBack();
      }
    }
    catch (error) {
      console.log(error)
      setloading(false)
      seterror(error?.message || "Invalid Payment Credentials");
    }
  }

  const style = {
    width: '100%',
  };

  const removePaymentMethod = async ()  => {
    setOpenAlert(false);
    setloading(true);
    let orgId = localStorage.getItem("orgID");
    let paymentMethodId = method?.id;

    try {
      let res = await deleteCustomerPaymentMethod( paymentMethodId, orgId);
      if (res?.data?.error) {
        console.log("error", res?.data?.message)
        seterror(res?.data?.message || "Something went wrong");
        setloading(false);
      } else {
        seterror(false);
        setloading(false);
        props.showAlert(payment_method_delete_success_message);
        props.history.goBack();
      }
    }
    catch (error) {
      console.log(error)
      setloading(false)
      seterror(error?.message || "Something went wrong");
    }
  };

  const onclickremove = () => {
    setOpenAlert(true);
  };
  
   const onSubmit = () => {
    console.log("button clicked");
  };

  return (
    <div className="billing-info-wrapper">
      <MasterLayoutMiddle
        header={
          <>
            <div
              onClick={() =>
                props.history.goBack()
              }
              className='backBtn'>
              <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
              <p style={{ alignSelf: 'center' }}>Back</p>{' '}
            </div>

            {(error && (
              <div
                style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={error}
                  alertType={'error'}
                  onClick={() => seterror(false)}
                />
              </div>
            ))}
          </>
        }
        isForm={true}
        formData={
          <>
            <div>
              <h1 className='title_top'>Edit Card Details</h1>
              <div className="dasheddBorder"></div>
            </div>
            {loading && <div
              style={{
                position: 'absolute',
                top: "50%",
                left: "50%",
                zIndex: 1000
              }}
            >
              <CircularProgress color='primary' />
            </div>}

            {/* <div className="mainTextHeading">Card Information</div> */}
            <CheckoutPayment
              creditCardsList={creditCardsList}
              editMode = {true}
              cardImage = {brandImage(method?.card?.brand)}
              handlePaymentSelectChange={handlePaymentSelectChange}
              onPlaceOrderSubmit={onPlaceOrderSubmit}
              cardDetails={cardDetails}
              setcardDetails={setcardDetails}
              addressDetails={addressDetails}
              setaddressDetails={setaddressDetails}
              saveCardDetails={saveCardDetails}
              setsaveCardDetails={(event) => setsaveCardDetails(event)}
              //selectedCardDetails={selectedCardDetails}
              privacyPolicyLink={"/privacy-policy"}
              termsOfUseLink={"/terms-of-use"}
              onEditBillingDetailClick={onEditBillingDetailClick}
              visaCardImage={VisaCardImg}
              masterCardImage={MasterCardImg}
              newCardImage={NewCardImg}
              discoverCardImage={DiscoverCardImg}
              americanExpressImage={AmericanExpressImg}
              dinersCardImage={DinersCardImg}
              helpImage={HelpImg}
              ButtonLabel="SAVE"
              RemoveButtonLabel="REMOVE CARD"
              ScuredLabel="Secured"
              checkboxLabel="Make this card my default payment method"

              onclickremove={onclickremove}
              onSubmit={onSubmit}
            />

            <div style={{ margin: "0 auto" }}>
              <AlertDialogue
                title="Delete Payment Method?"
                description="Are you sure you want to delete this Payment Method?"
                // description2 = "Deleting will permanently remove from Website."
                cancelText="Cancel"
                confirmText="Confirm"
                open={openAlert}
                handleCancel={() => setOpenAlert(false)}
                handleClose={() => setOpenAlert(false)}
                handleConfirm={removePaymentMethod}
                styles={styleAlert}
                />
            </div>

          </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={true}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    showAlert: (res) => dispatch(showAlert(res)),
  };
};
export default connect(0, mapDispatchToProps)(EditBillingInfo);