import React, { useRef } from "react";
import {
  BaseTextField,
  PageHeaderWithTabs,
  FormSwitch,
  BaseCheckBox,
  EmptyTable,
  MasterLayoutMiddle, FooterInner, AlertAction, AlertDialogueAdvance
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Moment from "moment";
import { useEffect, useState } from "react";
import MainBanner from "../../components/HomePage/MainBanner/MainBanner";
import NewsArticles from "../../components/HomePage/NewsArticles/NewsArticles";
import Events from "../../components/HomePage/Events/Events";
import Videos from "../../components/HomePage/Videos/Videos";
import Services from "../../components/HomePage/Services/Services";
import "./style.css";
import { GetSliderWebsite } from "./api";
import { getOrganizationData } from "../Organization/api";
import { saveAsDraft } from "../../components/DonationForm/Apis";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { PagesAction } from "../../redux/actions/pages";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
const { token_expired, quick_access_limit_axeeded, Your_changes_has_been_saved_as_draft, Your_changes_has_been_published } = messages_content;
function Home_Page(props) {
  const history = props.history;
  const [Title, setTitle] = React.useState('')
  const [value, setValue] = React.useState(0);
  const [dataRow, setDataRow] = React.useState([]);
  const [Loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [showAlert, setshowAlert] = useState(false);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState(false);
  const [getNewData, setNewData] = useState(false);
  const [checked, setChecked] = useState(true);
  const [ErrorFound, setErrorFound] = useState(false)
  const unblockHandle = useRef();
  const [changes, setCheckChanges] = useState(false);
  const [saved_route, setRoute] = useState('');
  const [QuickLimit, setQuickLimit] = useState(false);
  const [open, setAlertModal] = useState(false);
  const [organizationData, setOrganizationData] = useState([])
  const [homepageButton, setHomePageButton] = useState(false);
  const [integrateData, setIntegrateData] = useState({});
  const [pagesAll, setPagesAll] = useState({})
  const [dischard_changes, setdischard_changes] = useState(0);
  useEffect(() => {
    unblockHandle.current = history.block((route) => {
      setRoute(route.pathname)
      // changes && visibleModal()
      setAlertModal(false)
      setCheckChanges(false)
      return changes === true ? false : true
    });
    return () => {
      unblockHandle.current = false
      !window.location.pathname.includes('home-page') || !window.location.pathname.includes('edit-slider') && localStorage.removeItem('home-page')
    }
  }, [changes])
  function visibleModal() {
    setAlertModal(false)
  }
  useEffect(() => {
    // console.log(window.location.pathname)
    const page_no = localStorage.getItem('home-page')
    setValue(page_no ? +page_no : 0);
    async function getorganization() {
      try {
        const token = localStorage.getItem('authToken')
        const orgId = localStorage.getItem('orgID')
        const getOraganization = await getOrganizationData(orgId, token);
        const organizationData = getOraganization?.data?.body?.data?.enabledIntegrations;
        const data = organizationData.deenweb?.pages;
        const oraganization_data = data?.homePage
        setPagesAll(data);
        setIntegrateData(organizationData);
        setOrganizationData(oraganization_data);
        setTitle(oraganization_data.title);
        setHomePageButton(oraganization_data.enablePage)
        setChecked(oraganization_data.quickAccess)
        setNewData(false)
      }
      catch (error) {
        seterror(error.response && error.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    }
    getorganization()
  }, [getNewData])
  const handleChange = (event, newValue) => {
    changes && (Title === '' ? setErrorFound(true) : setAlertModal(true))
    !changes && setValue(newValue);
    !changes && localStorage.setItem('home-page', newValue)
    setdischard_changes(newValue)
  };
  useEffect(() => {
    setTimeout(() => {
      props.alertHide()
    }, 8000)
  }, [props.alert.alertValue])
  useEffect(() => {
    props.history.location.state && setValue(parseInt(props.history.location.state))
    history.replace({ pathname: '/website/home-page', state: '' })
    getSliders();
  }, [])
  async function getSliders() {
    const token = localStorage.getItem("authToken");
    try {
      let response = await GetSliderWebsite(token);
      let res = response.data.body.data
      setData(res);
      let sort = res.sort((a, b) => {
        return a.order - b.order
      })
      let data = [];
      sort.map((text, index) => {
        let dateStart = [Moment(text.website.publishFrom).format("MMM DD, YYYY"), Moment(text.website.publishFrom).format("h:mm A")];
        let dateEnd = [Moment(text.website.publishTo).format("MMM DD, YYYY"), Moment(text.website.publishTo).format("h:mm A")];
        let UnpublishDate = new Date(dateEnd).getFullYear()
        let status = text.website.status
        let statusChangeStart = new Date(dateStart) <= new Date();
        let statusChangeEnd = new Date(dateEnd) <= new Date();
        let array = [];
        array = [
          { type: "id", value: `${index <= 8 ? '0' + (index + 1) : index + 1}`, _id: text._id },
          {
            type: "url",
            name: "single_pic",
            src: text.imageMetaData.imageUrl,
          },
          {
            type: "string",
            name: "name",
            value: text.title.length > 55 ? text.title.slice(0, 55) + "..." : text.title,
          },
          { type: "string", name: "frequency", value: text.category },
          {
            type: "string",
            name: "vertical",
            value: dateStart,
          },
          {
            type: "string",
            name: UnpublishDate === 2099 ? "frequency" : "vertical",
            value: UnpublishDate === 2099 ? "Never" : dateEnd
          },

          {
            type: "status", status: status === 'Hidden' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'pending' : 'schedule'
              : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',
            value: status === 'Hidden' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Unpublished' : 'Scheduled'
              : statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled'
          },
        ]
        data.push(array);
      })
      setDataRow(data);
      setLoading(false)
    } catch (err) {
      setLoading(false)
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const object = [
    {
      label: "Main banner",
      item: "Main banner",
    },
    {
      label: "Reads/Views",
      item: "Reads/Views",
    },
    {
      label: "Events",
      item: "Events",
    },
    {
      label: "Videos",
      item: "Videos",
    },
    {
      label: "Services",
      item: "Services",
    },

    {
      label: "Page setup",
      item: "Page setup",
    },
  ];
  const saveChanges = async () => {
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    if (Title === '') {
      setErrorFound(true)
    } else {
      const data = {
        enabledIntegrations: {
          ...integrateData,
          deenweb: {
            ...integrateData.deenweb,
            pages: {
              ...pagesAll,
              homePage: {
                title: Title,
                status: homepageButton ? 'active' : 'inactive',
                enablePage: homepageButton,
                quickAccess: checked,
                lastEdited: new Date(),
                pageName: 'About Us',
              }
            }
          }
        }
      }
      // const data = {
      //   "addSelectedArrayData": {
      //       "enabledIntegrations.deenweb.pages.homePage": {
      //         title: Title,
      //         status: homepageButton ? 'active' : 'inactive',
      //         enablePage: homepageButton,
      //         quickAccess: checked,
      //         lastEdited: new Date(),
      //       }
      //     },
      //   }
      try {
        await saveAsDraft(orgId, data, token);
        setshowAlert(true);
        setsuccess(true)
        setValue(dischard_changes);
        setAlertModal(false)
        if (saved_route) {
          props.history.push(saved_route)
        }
      } catch (err) {
        setAlertModal(false)
        seterror(err.response && err.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    }
  };
  const publish = async () => {
    let pages = props?.pages?.pages
    let lengthOfPages = pages.filter(text => text.quickAccess === true);
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    if (Title === '') {
      setErrorFound(true)
    } else {
      const data = {
        enabledIntegrations: {
          ...integrateData,
          deenweb: {
            ...integrateData.deenweb,
            pages: {
              ...pagesAll,
              homePage: {
                title: Title,
                status: homepageButton ? 'active' : 'inactive',
                enablePage: homepageButton,
                quickAccess: checked,
                lastEdited: new Date(),
                pageName: 'About Us',
              }
            }
          }
        }
      }
      if (!checked || organizationData.quickAccess || lengthOfPages.length < 6) {
        try {
          await saveAsDraft(orgId, data, token);
          setshowAlert(true);
          setsuccess('Publish')
          setTimeout(() => {
            setsuccess(false)
          }, 8000);
          setCheckChanges(false)
          let Quick = pages.filter(text => text.name[0] !== "homePage")
          Quick.push({
            name: ['homePage'],
            quickAccess: checked
          })
          props.dispatchPagesAction(Quick)
        } catch (err) {
          seterror(err.response && err.response.data.message)
          setTimeout(() => {
            seterror(false)
          }, 8000);
        }
      } else {
        setQuickLimit(true)
        setTimeout(() => {
          setQuickLimit(false)
        }, 8000)
      }
    }
  };
  const handleCloseDraft = () => {
    setAlertModal(false)
  };
  const discardChanges = () => {
    setCheckChanges(false)
    setAlertModal(false)
    setValue(dischard_changes)
    setChecked(organizationData.quickAccess);
    setHomePageButton(organizationData.enablePage)
    setTitle(organizationData.title)
    if (unblockHandle) {
      unblockHandle.current()
    }
    if (saved_route) {
      props.history.push(saved_route)
    }
  };
  const Alertstyle = {
    width: 470,
    marginLeft: '35%'
  };
  const saveStyles = {
    backgroundColor: "#2A82F6",
  };
  const publishStyles = {
    backgroundColor: "#2A82F6",
    opacity: 0.6,
  };
  const paperStyles = {};
  const styles = {
    BaseTextField: {
      width: "100%",
    },
    contents: {
      paddingBottom: 0,
      paddingTop: 20,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "100%",
      marginTop: 70,
      marginLeft: "280px",
    },
  };
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const checkChanges = (boolean) => {
    setCheckChanges(boolean)
  }
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { id: "drag", label: " ", width: 'auto' },
    { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
    { type: "link", name: "invoice_number", label: "Image", width: '10%' },
    { type: "string", name: "name", label: "Slider Title", width: '30%' },
    { type: "string", name: "date", label: "Category", width: '15%' },
    { type: "string", name: "date/time", label: "Publish Date", width: '15%' },
    {
      type: "string",
      name: "date/time",
      label: "UnPublish Date",
      width: '15%'
    },
    { type: "string", name: "date", label: "STATUS", width: '10%' },
    { type: "action", name: "action", label: "ACTION", width: '10%' },
  ];
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          <PageHeaderWithTabs
            back={() => props.history.push("/website/pages")}
            value={value}
            title="Homepage"
            object={object}
            disabled={changes ? false : true}
            style={{ color: "#2A82F6", border: "1px solid black" }}
            handleChange={(x, y) => handleChange(x, y)}
            indicatorColor="#2A82F6"
            saveStyles={saveStyles}
            publishStyles={publishStyles}
            paperStyles={paperStyles}
            hiddenDraft={true}
            saveChanges={() => saveChanges()}
            publish={() => publish()}
          />
          {
            showAlert && success && success === 'Publish' ? (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={Your_changes_has_been_published}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            ) : showAlert && success && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={Your_changes_has_been_saved_as_draft}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            )
          }
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            props.alert.alertValue &&
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
              <AlertAction
                alert={props.alert.alertValue}
                alertType={"success"}
                onClick={() => props.alertHide()}
              />
            </div>
          }
          {
            QuickLimit && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={quick_access_limit_axeeded}
                  alertType={"error"}
                  onClick={() => setQuickLimit(false)}
                />
              </div>
            )
          }
        </>
      }
      content={
        Loading ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <>
            <div>
              <div>
                <AlertDialogueAdvance
                  title="You have unsaved changes!"
                  description='There are unsaved changes. If you would like to save changes, press the "SAVE AS DRAFT" button.'
                  cancelText="Discard Changes"
                  confirmText="SAVE AS DRAFT"
                  open={open}
                  handleCancel={() => saveChanges()}
                  handleClose={() => handleCloseDraft()}
                  handleConfirm={() => discardChanges()}
                  styles={Alertstyle}
                />
              </div>

              {value === 0 && (
                <Grid className="form_box" style={{ border: 'none' }}>
                  <Grid item>
                    {
                      Loading ?
                        <div
                          style={{
                            marginTop: 30,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress color="primary" />
                        </div>
                        :
                        dataRow.length > 0 ?
                          <MainBanner
                            rows={dataRow}
                            data={data}
                            col={col}
                            changeClassProps={changeClassProps}
                            history={props.history}
                            DataRow={(e) => setDataRow(e)}
                            changes={changes} setCheckChanges={setCheckChanges}
                          />
                          :
                          <EmptyTable
                            title='Main Banner'
                            onAddClick={() => props.history.push("/website/add-slider")}
                            addLabel="ADD NEW"
                            noBtnText="ADD NEW"
                          />
                    }
                  </Grid>
                </Grid>
              )}
              {value === 1 && (
                <Grid className="form_box" style={{ border: "none" }}>
                  {" "}
                  <Grid item>
                    <NewsArticles history={props.history} changes={changes} setCheckChanges={setCheckChanges} />
                  </Grid>
                </Grid>
              )}
              {value === 2 && (
                <Grid className="form_box" style={{ border: "none" }}>
                  {" "}
                  <Grid item>
                    <Events history={props.history} changes={changes} setCheckChanges={setCheckChanges} />
                  </Grid>
                </Grid>
              )}
              {value === 3 && (
                <Grid className="form_box" style={{ border: "none" }}>
                  {" "}
                  <Grid item>
                    <Videos history={props.history} changes={changes} setCheckChanges={setCheckChanges} />
                  </Grid>
                </Grid>
              )}
              {value === 4 && (
                <Grid className="form_box" style={{ border: "none" }}>
                  {" "}
                  <Grid item>
                    <Services history={props.history} changes={changes} setCheckChanges={setCheckChanges} />
                  </Grid>
                </Grid>
              )}

              {value === 5 && (
                <div className="page_body">
                  <Typography className="page_form_title">Page Settings</Typography>
                  <Grid className="form_body">
                    <Grid container item xs={6} spacing={3} >
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={Title}
                            onChange={(e) => {
                              setTitle(e.target.value); checkChanges(true); setErrorFound(false)
                            }}
                            InputProps={false}
                            style={styles.BaseTextField}
                            error={ErrorFound}
                            color="primary"
                            disabled={false}
                            helperText="The page title appears in the top of the browser window."
                            label="Page Title"
                            count={20}
                          />
                        </div>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <div className="page_switch">
                          <FormSwitch
                            style={styles.BaseTextField}
                            headline="Enable Page"
                            body="Disabled pages can’t be accessed by your site visitors."
                            values={homepageButton}
                            handleSwitchChange={() => { setHomePageButton(!homepageButton); checkChanges(true) }}
                          />
                        </div>
                      </Grid> */}
                      <Grid item xs={12}>
                        <div className="SolidBorder"></div>
                        <div className="checkBox">
                          <BaseCheckBox
                            label="Quick Access"
                            checked={checked}
                            color="secondary"
                            showHelperText={true}
                            helperText="Add to left navigation under QUICK ACCESS for quickly accessing the page"
                            onChange={(e) => { setChecked(e.target.checked); checkChanges(true) }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          </>
      }
      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
    />
  );
}
const mapStateToProps = (state) => {
  return {
    pages: state.pages,
    alert: state.alert,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatchPagesAction: (data) => dispatch(PagesAction(data)),
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Home_Page);
