import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import {
  AddButton, UploadList, AlertDialogue 
} from "@dclab/dc-react-ui-kit";
import Modalintegration from '../../integrationModal/Modalintegration'
//import MediaLibraryModal from '../../MediaLibrary/MediaLibraryModal';
import MediaUpload from "../../../components/MediaUpload/MediaUpload";


export default function MiddleCard(props) {
    const {card, setcard, classes, integrationsData} = props;

    const [open, setOpen] = useState(false);
    const [openIntegrationModal, setopenIntegrationModal] = useState(false)

    const UploadData = card?.imageMetaData?.imageUrl ? [{
        Img: card?.imageMetaData?.imageUrl,
        name: card?.imageMetaData?.imageName,
        filename: card?.cardType === "image" ? "Image" : "Integration",
    }] : [];

    const addMediaItem = (obj) => {
      console.log("Card Object: ", obj);
       setcard({...card, 
          cardType: "image", 
          imageMetaData: { imageUrl: obj.src, imageName: obj.title }
      }); 
     
      
    };

    const style={
        width:470,
        marginLeft:800
    };

    return (
        <Grid container spacing={3}>
          
          <Grid item xs={12}>
              {integrationsData?.length ? <Modalintegration 
              labelName="Integration Modal"
              setopenIntegrationModal={setopenIntegrationModal}
              openIntegrationModal={openIntegrationModal}
              card={card} setcard={setcard}
              integrationsData={integrationsData}
            /> : null}
        </Grid>

        <Grid item xs={12}>
          <p className={classes.text}>Add integration or media to publish in this zone. Added content will be visible and managed from here.</p>
        </Grid>

        {UploadData?.length ? <Grid item xs={12}>
          <UploadList
            UploadData={UploadData}
            deleteItem={(item, index) => {
                setOpen(true)
            }}
          />
        </Grid> : null }

        <Grid item xs={6}>
          <AddButton
            onClick={() => setopenIntegrationModal(true)}
            text="Add integration"
            disabled={card?.imageMetaData?.imageUrl ? "disabled" : ""}
          />
        </Grid>

        <Grid item xs={6}>
            <MediaUpload
              addMediaItem = {addMediaItem}
              buttonType="addMedia"
            />
           {/*<MediaLibraryModal typeOfMedia='/images' label="Upload Image" buttonType="addMedia" onChange={(obj) => setcard({...card, cardType: "image", imageMetaData: { imageUrl: obj.src, imageName: obj.title }})} disabled={card?.imageMetaData?.imageUrl ? true : false}
        />*/}
        </Grid>
           

        {/* <Grid item xs={12}> */}
          <AlertDialogue
          title="Are you sure?"
          description="Are you sure you want to delete this media?"
          cancelText="Cancel"
          confirmText="Confirm"  
          open={open}
          handleCancel={() => setOpen(false)}
          handleClose={() => setOpen(false)}
          handleConfirm={() => {
              setcard({...card, cardType: "", imageMetaData: { imageUrl: "", imageName: "" }})
              setOpen(false)
          }}
          styles={style}
          />
        {/* </Grid> */}


      </Grid>
  
    )
}
