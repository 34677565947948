import * as constants from '../constants'

const INITIAL_STATE = {
    editAnnoucements: [],
    edit: false
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case constants.EDIT_ANNOUCEMENT:
            return {
                ...state,
                editAnnoucements: action.payload,
                edit: true
            };
        case constants.EMPTY_ANNOUCEMENT:
            return {
                ...state,
                editAnnoucements: [],
                edit: false
            };
        default:
            return state;
    }
};
