import React, { useState, useEffect } from 'react';
import {
  AlertAction,
  EditableTable,
  MasterLayoutMiddle,
  FooterInner, CardDetailsTable,
} from "@dclab/dc-react-ui-kit";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from '@material-ui/core/Grid';


export default function CommitteesDetailsAddMember(props) {
  console.log(props?.location?.state?.order)
  const [order, setorder] = useState({})
  const [showSuccessAlert, setshowSuccessAlert] = useState(true)
  const [loading, setloading] = useState(false)



  let header = { avatar: "", tableName: `Social Services`, icon: "", status: [] };

  let data = [
    {
      field: "Name",
      value: "Social Services"
    },

    {
      field: "Chair/Secretary",
      value: "Hassan Ahmed"
    },

  ];

  let data2 = [
    {
      field: "# of members",
      value: "12"
    },

  ];

  let footerLinkName = "Edit";
  let footerDeleteButton = "Delete";

  const onClick = () => {
    console.log('EDIT')
    props.history.push({ pathname: `/organization/edit-committee`, state: { order: order } });
  }

  const onCancelClick = () => {
    console.log('DELETE');
  };

  const style = {
    width: '100%',
  };


  // =========== Editable Table Start ===========//

  const [columns, setColumns] = React.useState([
    { title: 'NAME', field: 'text', width:"30%"},
    { title: 'PHONE', field: 'text', width:"20%" },
    { title: 'MAIL', field: 'text', width:"20%" },
    { title: 'DESIGNATION', field: 'text', width:"20%" },
    { title: 'ACTION', field: 'text', width:"20%" },
    
  ]);

  const [setData] = React.useState([
    { number: '01', text: 'Architectural Plans', dropdown: 34 },
    { number: '02', text: 'Buildout', dropdown: 35 },
    { number: '03', text: 'Expansion', dropdown: 36 },
  ]);

  const onRowAdd = (newData) => {
    setData([...data, newData]);
    console.log(newData)
  };

  const onRowDelete = (oldData) => {
    const dataDelete = [...data];
    const index = oldData.tableData.id;
    dataDelete.splice(index, 1);
    setData([...dataDelete]);
    console.log(oldData)
  };

  const onRowUpdate = (newData, oldData) => {
    const dataUpdate = [...data];
    const index = oldData.tableData.id;
    dataUpdate[index] = newData;
    setData([...dataUpdate]);
    console.log(newData, oldData)
  };

  // =========== Editable Table End ===========//


  return (
    <MasterLayoutMiddle
      header={
        <>
          <div
            onClick={() =>
              props.history.goBack()
            }
            className='backBtn'>
            <ChevronLeft style={{ alignSelf: 'center', marginLeft: 0 }} />
            <p style={{ alignSelf: 'center' }}>Back</p>{' '}
          </div>

          {(showSuccessAlert && (
            <div
              style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
            >
              <AlertAction
                alert={"Committee added successfully!"}
                alertType={"success"}
                onClick={() => setshowSuccessAlert(false)}
              />
            </div>
          ))}
        </>
      }
      content={
        <>
          <div style={{ marginTop: '60px' }}>
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <CardDetailsTable
                  data={data}
                  data2={data2}
                  twoColumns
                  header={header}
                  footerLinkName={footerLinkName}
                  footerDeleteButton={footerDeleteButton}
                  onClick={() => onClick()}
                  onDeleteClick={() => onCancelClick()}
                />
              </Grid>

              <Grid item xs={12}>
                <div className="speaker-simple-table">
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 30,
                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  ) : (


                    <div className="speaker-simple-table2">

                      <EditableTable
                        title={"Members"}
                        columns={columns}
                        setColumns={setColumns}
                        data={data}
                        setData={setData}
                        search={false}
                        onRowAdd={onRowAdd}
                        onRowUpdate={onRowUpdate}
                        onRowDelete={onRowDelete}
                        pageSize={5}
                        pageSizeOptions={[5, 10, 20]}
                      />
                    </div>
                  )}
                </div>
              </Grid>

            </Grid>
          </div>

        </>
      }
      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  )
}
