import React, { useEffect } from 'react'
import { ChangePasswordComponent, AlertAction } from "@dclab/dc-react-ui-kit";
import Lock from "../../assets/img/lock-24px.svg";
import Right from '../../assets/img/done-14px.svg'
import Close from '../../assets/img/clear-24px.svg'
import Logo from '../../assets/img/DC_Logo.png';
import classes from './signin.module.css';
import backgroundImage from '../../assets/images/dark_pattern_bg.png';
import { getCurrentUserDetail, changePassword } from "./Apis/index";
import { connect } from "react-redux";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
const {Password_changed_success_message} = messages_content;


function ChangePassword(props) {
  const [userDetails, setuserDetails] = React.useState({})
  const [showStrength, setshowStrength] = React.useState(true);
  const [confirmPasswordError, setconfirmPasswordError] = React.useState(false);
  const [showErrorAlert, setshowErrorAlert] = React.useState(false)
  const [values, setValues] = React.useState({
    currentpassword: "",
    newpassword: "",
    confirmpassword: "",
    userName: ""
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if(prop === "confirmpassword" && values.confirmpassword !== values.newpassword) {
      setconfirmPasswordError(true)
    }
  };

  useEffect(() => {
    const getDetails = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const res = await getCurrentUserDetail(token);
        setuserDetails(res)
      }
      catch (error) {
        console.log(error)
      }
    }
    getDetails()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { currentpassword, newpassword } = values;
    try {
      // const prevPass = userDetails?.previousPassword?.length ? userDetails?.previousPassword : [];
      // console.log(prevPass, "prevPass", prevPass?.slice(prevPass?.length - 3, prevPass?.length))
      // const prevThreePass = prevPass?.length > 3 ? prevPass?.slice(prevPass?.length - 3, prevPass?.length) : prevPass;

      if(currentpassword !== newpassword) {
        let res = await changePassword(userDetails.userName, currentpassword, newpassword, props.history);
        console.log(res, "res")
        if(res) {
          localStorage.setItem('user', false)
          localStorage.setItem('authToken', '')
          props.alertShow(Password_changed_success_message)
          props.history.replace('/signin')
          localStorage.clear();
        }
      } else {
          setshowErrorAlert(true)
          setTimeout(() => {
            setshowErrorAlert(false)
          }, 8000);
      }

    }
    catch (error) {
      console.log(error.message)
    }
  };
  const onCancelLink = (e) => {
    props.history.push("/edit-profile")
  };

  const checkPassword = (password) => {
    return password.length < 8 ||
      !/[a-z]/.test(password) ||
      !/[A-Z]/.test(password) ||
      !/[0-9]/.test(password)
      ? false
      : true;
  }

  const { currentpassword, newpassword, confirmpassword } = values
  let disableBtn = checkPassword(currentpassword) && checkPassword(newpassword) && checkPassword(confirmpassword) && newpassword === confirmpassword ? false : true;

  return (
    <div className={classes.mainClass}>
      {(showErrorAlert && (
          <div
            style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
          >
            <AlertAction
              alert={"You cannot use previous password!"}
              alertType={"error"}
              onClick={() => setshowErrorAlert(false)}
            />
          </div>
      ))}
      <div className={classes.signInClass} style={{ backgroundImage: `url(${backgroundImage}) !important` }}>
        <div style={{ width: '55%', margin: 'auto', fontFamily: 'Roboto', fontWeight: '300' }}>
          <ChangePasswordComponent
            title="Change Password"
            name="Change Password"
            src={Logo}
            LogoLink="https://shapla.deenconnect.io/"
            right={Right}
            close={Close}
            icon={Lock}

            currentpasswordvalue={values.currentpassword}
            currentpasswordvalue_onChange={handleChange("currentpassword")}

            newpasswordvalue={values.newpassword}
            newpasswordvalue_onChange={handleChange("newpassword")}
            newPasswordOnFocus={() => setshowStrength(true)}
            showStrength={showStrength}

            confirmpasswordvalue={values.confirmpassword}
            confirmpasswordvalue_onChange={handleChange("confirmpassword")}
            confirmPasswordOnFocus={() => {
              setshowStrength(false)
            }}
            confirmPasswordOnBlur={() => {
              setconfirmPasswordError(true)
            }}

            text={"Change Password"}
            onClick={(e) => handleSubmit(e)}
            onSubmit={(e) => handleSubmit(e)}
            isLoading={false}
            onCancelLink={onCancelLink}
            disableBtn={disableBtn}
            confirmPasswordError={confirmPasswordError}
          />
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    alert: state.alert,
  };
};
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

