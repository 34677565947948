import React from "react";
import { useEffect, useState } from 'react';
import { BaseTextField, MasterLayoutMiddle, FooterInner, CardDetailsTable, EventLogs, Initials } from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Check from "../../assets/img/check_circle-24px.svg";
import axios from "axios";
import { AlertAction, AlertDialogue } from "@dclab/dc-react-ui-kit";
import Button from '@material-ui/core/Button';
import moment from 'moment';
import unitedStatesFlag from '../../assets/img/united-states.svg'
import messages_content from '../../utils/messages.json';
import { getContactGroups } from './Functions';

import SendIcon from '../../assets/img/add-24px.svg'
const {
    add_com_contact_success,
    edit_com_contact_success

} = messages_content;

const orgEnv = {
    prod: process.env.REACT_APP_BASEURL_PROD_API,
    dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
    window.location.host.includes("amazon") ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("shapla")
) {
    currentEnv = "dev";
} else {
    currentEnv = "prod";
}

const orgBaseUrl = orgEnv[currentEnv];

function AddContact(props) {

    // const [checked, setChecked] = useState(true);
    const [itemDetails, setItemDetails] = useState({
        firstName: '',
        lastName: '',
        createdAt: '',
        updatedAt: '',
        email: '',
        source: '',
        phoneNumber: '',
        createdBy: '',
        ipAddress: '',
        createdByLocation: '',

    });

    const modalStyle = {
        width: 470,
        marginLeft: 800
    };

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [success, setSuccess] = useState(false);
    const [editSuccess, setEditSuccess] = useState(false);
    const [contactGroups, setContactGroups] = useState([]);
    const [groupDeleteMessage, setgroupDeleteMessage] = useState('');

    useEffect(async () => {
        let checked = true;
        const token = localStorage.getItem("authToken");

        const itemId = (props.match.params.id.toString());

        const status = props.match.params.status;

        if (status && status.length) {
            if (status.toString() == 'editSuccess') {
                setEditSuccess(true);
            } else {
                setSuccess(true);
            }

        }
        getItemDetails(itemId, token, 'contacts').then(response => {
            if (checked) {

                if (response && response.data.body.data) {

                    let item = response.data.body.data;


                    setItemDetails(response.data.body.data);
                }

            }
        });

        await getContactGroups(itemId).then(response => {

            if (checked) {

                if (response && response.data.body.data) {

                    let items = response.data.body.data;
                    let groups = [];

                    items.map(singleItem => {
                        groups.push(singleItem.groupName);
                    });

                    console.log(groups);

                    if (groups.length) {
                        let gps = groups.join(', ');
                        let message =   gps  ;

                        setgroupDeleteMessage(message);
                    }
                }

            }

        });

        return () => checked = false;

    }, []);

    async function getItemDetails(itemId, token, type = 'contacts',) {
        return new Promise((resolve, reject) => {
            axios
                .get(`${orgBaseUrl}/communications/singleItem/${type}/${itemId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        token
                    }
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }



    let data = [
        {
            field: "Date Added",
            value: moment(itemDetails.createdAt).format('MMM DD, YYYY'),
        },
        {
            field: "First Name",
            value: itemDetails.firstName.toString(),
        },
        {
            field: "Email",
            value: itemDetails.email.toString()
        },
        {
            field: "Last Updated",
            value: moment(itemDetails.updatedAt).format('MMM DD, YYYY')
        },
        {
            field: "Last Name",
            value: itemDetails.lastName.toString()
        },
        {
            field: "Phone",
            value: "+" + itemDetails.phoneNumber.toString()
        },
    ];


    const style = {
        width: '100%',
    };

    let header = {
        tableName: itemDetails.firstName.toString() + ' ' + itemDetails.lastName.toString(),
    };




    let footerLinkName = "EDIT INFORMATION";
    let footerDeleteButton = "DELETE CONTACT";

    // HANDLE FOOTER LINK
    const onClick = () => {

        const contactId = (props.match.params.id.toString());
        props.history.push('/communication/add-contact/' + contactId);
    }

    const onDeleteClick = () => {
        setDeleteModalOpen(true);
    }

    const handleCloseModal = () => {
        setDeleteModalOpen(false);
    }

    const send = () => {
        if (props.match.params.id)
            props.history.push('/communication/add-contact/' + props.match.params.id.toString());
    };

    async function handleConfirm() {
        setDeleteModalOpen(false);

        const itemId = props.match.params.id.toString();
        const token = localStorage.getItem("authToken");

        const response = await deleteItem(itemId, 'contacts', token);

        // props.history.push('/communication/contact-list/deleteSuccesContact');
        window.location.href = '/communication/contact-list/deleteSuccesContact';
    }

    async function sendMessage() {

        props.history.push('/communication/new-message/cfromcontact@' + props.match.params.id);
    }

    async function deleteItem(itemId, type, token) {

        return new Promise((resolve, reject) => {
            axios
                .delete(`${orgBaseUrl}/communications/${type}/${itemId}`, {
                    headers: {
                        token
                    }
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    };



    const details = [
        {
            id: 1, icon: Check, status: 'Added manually', date: moment(itemDetails.createdAt).format('MMM DD, YYYY, hh:mm A'), details: [
                { title: 'Source', message: (itemDetails.source ? itemDetails.source : '') },
                { title: 'Admin Name', message: (itemDetails.createdBy ? itemDetails.createdBy : '') },
                // { title: 'IP Address', IP: (itemDetails.ipAddress ? itemDetails.ipAddress : ''), flag: unitedStatesFlag, },
                // { title: 'Location', message: (itemDetails.createdByLocation ? itemDetails.createdByLocation : '') },

            ]
        },

    ];
    return (
        <MasterLayoutMiddle
            header={
                <div
                    onClick={() => props.history.push("/communication/contact-list")}
                    className="backBtn"
                >
                    <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                    <p style={{ alignSelf: "center" }}>Back</p>{" "}
                </div>
            }

            content={

                <div>
                    {success && (
                        <div
                            style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                        >
                            <AlertAction
                                alert={add_com_contact_success}
                                alertType={"success"}
                                onClick={() => setSuccess(false)}
                            />
                        </div>
                    )}

                    {editSuccess && (
                        <div
                            style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                        >
                            <AlertAction
                                alert={edit_com_contact_success}
                                alertType={"success"}
                                onClick={() => setEditSuccess(false)}
                            />
                        </div>
                    )}

                    <div style={{ marginTop: "60px", }}>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <CardDetailsTable
                                    data={data}
                                    header={header}
                                    footerLinkName={footerLinkName}
                                    footerDeleteButton={footerDeleteButton}
                                    onClick={onClick}
                                    onDeleteClick={onDeleteClick}

                                    sendbutton={true}
                                    onClick={send}
                                    sendlabel="SEND MESSAGE"
                                    sendIcon={SendIcon}
                                    sendbutton={SendIcon}
                                    onClickSend={sendMessage}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <div className="FullTable">
                                    <EventLogs data={details}
                                        headTitle="TIMELINE"
                                        schedule={true}
                                        editSchedule={(e) => console.log('editSchedule', e)}
                                        deleteSchedule={(e) => console.log('deleteSchedule', e)}
                                    />
                                </div>
                            </Grid>

                        </Grid>

                        <div className="DeleteImgButton">

                            <AlertDialogue
                                title="Delete Contact?"
                                description={<div>
                                    This contact is member of the following group(s):
                                    <p>
                                        <b>{groupDeleteMessage}</b>
                                    </p>
                                    Are you sure you want to delete this contact? Deleting will permanently remove from everywhere.
                                </div>}
                                cancelText="Cancel"
                                confirmText="Confirm"
                                open={deleteModalOpen}
                                handleCancel={handleCloseModal}
                                handleClose={handleCloseModal}
                                handleConfirm={handleConfirm}
                                styles={modalStyle}
                            />
                        </div>


                    </div>

                </div>

            }
            isFooter={true}
            footer={<FooterInner
                style={style}
                termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
                privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
            />}
            isWhiteBackground={false}
        />
    );
}

export default AddContact;