import axios from "axios";

const baserUrl =
    "https://cors-anywhere.herokuapp.com/http://18.211.207.245:3000";
const env = {
    prod: process.env.REACT_APP_PAYMENT_PROD_API,
    dev: process.env.REACT_APP_PAYMENT_DEV_API
};
const orgEnv = {
    prod: process.env.REACT_APP_BASEURL_PROD_API,
    dev: process.env.REACT_APP_BASEURL_DEV_API
};
let currentEnv;
if (
    window.location.host.includes("amazon") ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("shapla")
) {
    currentEnv = "dev";
} else {
    currentEnv = "prod";
}
const orgBaseUrl = orgEnv[currentEnv];
const token = localStorage.getItem("authToken");

export function getCurrentUserDetail(token) {
    return new Promise((resolve, reject) => {
        axios
            .get(orgBaseUrl + `/auth/getCurrentUser`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
                if (data) {
                    resolve(data);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function editProfile (id, user, history, update = true) {
    return new Promise((resolve, reject) => {
        axios.put(`${orgBaseUrl}/auth/updateProfile/${id}`, user, {
            headers: {
                token
            },
        })
        .then(res => {
            if(res?.data?.body?.data) {
                let data = res.data.body.data;
                if(update) {
                const name = user?.name?.firstName + (user?.name?.lastName ? ' ' + user?.name?.lastName : '')
                const userImage = user?.imageDetails?.image ?? "";
                localStorage.setItem('name', name);
                localStorage.setItem('image', userImage);
                }
            }
            history.push({pathname: "/admin-profile", state: { successAlert: true } })
            resolve(res);
        })
        .catch(err => {
            reject(err);
        })
    });
};

export function changePassword (userName, oldPassword, newPassword, history) {
    return new Promise((resolve, reject) => {
    axios
        .post(
            orgBaseUrl + '/auth/changePassword',
            { userName, oldPassword, newPassword }
        )
        .then(res => {
            const data = res && res.data && res.data.body && res.data.body.data;
            resolve(true)
            history.push(`/edit-profile`)
        })
        .catch(err => {
            console.log(err, "err")
            reject(err);
        })
    });
};

export function getOrganizationPlan(id, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(orgBaseUrl + `/planSubscription/getOrganizationPlan/${id}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
                if (data) {
                    resolve(data);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function getPlanSubscriptionsByOrgId(orgId, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(orgBaseUrl + `/planSubscription/getPlanSubscriptionsByOrgId/${orgId}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
                if (data) {
                    resolve(data);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function refreshCognitoToken(data) {
    return new Promise((resolve, reject) => {
    axios
        .post(
            orgBaseUrl + '/auth/refreshCognitoToken', { ...data }
        )
        .then(res => {
            // const data = res?.data?.body?.data;
            // console.log("refreshCognitoToken", res)
            resolve(res?.data ?? {})
        })
        .catch(err => {
            console.log(err, "err")
            reject(err);
        })
    });
};
