import React, { useEffect } from "react";
import {
  BaseButton,
  BaseSelect,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import "../../../pages/Website/style.css";
import { GetEventsWebsiteFeaturedNot, getEventsWithAppId, updateEventsWithAppId } from "../../../pages/Website/api";
import { updateEvent } from "../../../pages/Donations/deen-kioks-website/Apis";
import { CircularProgress } from "@material-ui/core";
import { hideAlert, showAlert } from "../../../redux/actions/alertActions";
import { connect } from "react-redux";
import messages_content from '../../../utils/messages.json';
import { editOrganization } from "../../../pages/Organization/api";
const { 
    token_expired,
    maximum_limit_exeed_of_featured,
    Event_featured_successfully
  } = messages_content;

function AddEvents(props) {
  const [selection, setSelection] = React.useState('');
  const [Events, setEvents] = React.useState([]);
  const [Objecy, setObject] = React.useState([])
  const [Loading, setLoading] = React.useState(true)
  const [error, seterror] = React.useState(false)
  const [limitError, setLimitError] = React.useState(false);
  const [featuredEvents, setFeaturedEvents] = React.useState([])
  useEffect(() => {
    getEventsFeatured()
  }, [])
  async function getEventsFeatured() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');

    try {
      const resEventsFeatured = await getEventsWithAppId(token, orgId, 'deenweb')
      // let response = await GetEventsWebsiteFeaturedNot(token);
      const data = resEventsFeatured?.data?.body?.data ?? [];
      const featuredEvents = data.filter(text => text.featured === true)
      setFeaturedEvents(featuredEvents);
      const addEventToWebsite = data.filter(text => text.featured !== true)
      setEvents(data);
      console.log(addEventToWebsite, data, "addEventToWebsite")
      const array = addEventToWebsite.map((text) => {
        return ({
          value: text.event.title,
          label: text.event.title,
        })
      })
      setObject(array)
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const OnFeatured = async () => {
    const data = Events.filter(text => text.event.title === selection);
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    let Obj = {
      website: { featured: true }
    }
    const updateOnOrg = {
          event: data[0].event._id,
          featured: true,
          publishFrom: data[0].publishFrom,  // DATE OBJECT
          publishTo: data[0].publishTo,
          status: data[0].status,
      }
    if (featuredEvents.length < 3) {
      try {
        await updateEventsWithAppId(token, orgId, 'deenweb',data[0].event._id,updateOnOrg )
        // await editOrganization(updateOnOrg, orgId, token);
        // let response = await updateEvent(Obj, token, data[0]._id);
        props.alertShow(Event_featured_successfully)
        
        // if (response.status === 200) {
          props.history.push({ pathname: '/website/home-page', state: '2' })
        // }
      } catch (err) {
        seterror(err.response && err.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    } else {
      setLimitError(true)
      setTimeout(() => {
        setLimitError(false)
      }, 8000);
    }
  }
  const styles = {
    formBody: {
      background: "#fff",
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A",
    },

    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
    },

    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },

    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
    select: {
      margin: "14px 0",
      minWidth: 120,
      width: 120,
      border: "#D3D8DA",
      width: "100%",
    },
  };
  const style = {
    width: '100%',
  };
  const handleChange = (e) => {
    setSelection(e.target.value);
  }
  return (
    <MasterLayoutMiddle
      header={
        <>
          <div
            onClick={() => props.history.push({ pathname: '/website/home-page', state: '2' })}
            className="backBtn"
            style={{ marginTop: 0 }}
          >
            <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
            <p style={{ alignSelf: "center" }}>Back</p>{" "}
          </div>
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            limitError && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={maximum_limit_exeed_of_featured}
                  alertType={"error"}
                  onClick={() => setLimitError(false)}
                />
              </div>
            )
          }
        </>
      }
      isForm={true}
      formData={
        Loading ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <div>
            <div>
              <h1 className="title_top">Add Featured Events</h1>
              <div className="dasheddBorder"></div>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h2 className="sub_title">Select Event to Feature</h2>
                <Typography className="sub_decrition">Selected content will be featured on the website homepage</Typography>
              </Grid>
              <Grid item md={12} xl={12}>
                <div className="field_box">
                  <BaseSelect
                    value={selection}
                    onChange={(e) => handleChange(e)}
                    object={Objecy}
                    style={styles.select}
                    helperText={Objecy.length ? "" : <p>Evets not found. For create new click here:
                      <span onClick={() => props.history.push('/addevent')} className='addLinkTag'> Add Events </span></p>}
                    label="Select Event"
                    error={Objecy.length ? false : true}
                  />
                </div>
              </Grid>
              <Grid item md={12}>
                <div className="form_button">
                  <span className="btnCencel">
                    <BaseButton text="CANCEL" onClick={() => props.history.push({ pathname: '/website/home-page', state: '2' })} />
                  </span>
                  <BaseButton text="ADD NOW" onClick={() => OnFeatured()} />
                </div>
              </Grid>
            </Grid>
          </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
const mapStateToProps = (state) => ({
  alert: state.addOrg.alert,
});

const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
  alertHide: (res) => dispatch(hideAlert(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEvents);
