import React, { Component } from 'react';
import { connect } from 'react-redux';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  BaseTextField,
  BaseButton,
  AlertAction,
  BaseFileSelect,
  DrawerMain,
  BaseImgChip,
  MasterLayoutMiddle,
  FooterInner,
  FormSwitch,
  TextEditor,
   
} from '@dclab/dc-react-ui-kit';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactCrop from 'react-image-crop';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Avatar from '@material-ui/core/Avatar';
import 'react-image-crop/dist/ReactCrop.css';
import Grid from '@material-ui/core/Grid';
import { addService, getServices } from './api';
import {
  image64toCanvasRef,
  extractImageFileExtensionFromBase64,
  base64StringtoFile,
} from '../../utils/crop';
import {
  addServiceAction,
  emptyServiceAction,
} from '../../redux/actions/servicesAction';
import './style.css';
import '../../App.css';
import '../style.css';
import { showAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';

import MediaUpload from '../../components/MediaUpload/MediaUpload';





const {
  token_expired,
  success_message_services,
  service_title_already_exist
} = messages_content;
class AddService extends Component {
  constructor(props) {
    super(props);
    this.imagePreviewCanvasRef = React.createRef();
    this.state = {
      serviceName: '',
      serviceNameCount: 0,
      serviceDescription: [{ type: 'paragraph', children: [{ text: '' }] }],
      featured: false,
      descriptionCount: 0,
      showAlert: false,
      success: false,
      error: false,
      openDrawer: false,
      serviceImage: [],
      image: [],
      loading: false,
      allServices: [],
      seError: false,
      showDrag: true,
      crop: {
        aspect: 1 / 1,
      },
      
    };

    
  
  



    this.onDescriptionStateChange = this.onDescriptionStateChange.bind(this);
    this.addService = this.addService.bind(this);
    this.upload = this.upload.bind(this);
    this.addLogo = this.addLogo.bind(this);
    this.handleDeleteChip = this.handleDeleteChip.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleOnCrop = this.handleOnCrop.bind(this);
    this.handleOnCropCompleted = this.handleOnCropCompleted.bind(this);
    this.handleRemove = this.handleRemove.bind(this);

    
    this.selectMediaItem = this.selectMediaItem.bind(this);


  }

  async selectMediaItem( object ){
    this.setState({      
      Image: [
        {          
          name: object.title,
          src: object.src,
          size: 55,
        },
      ],
      
    });
  }


  async componentDidMount() {
    const token = localStorage.getItem('authToken');
    const orgId = localStorage.getItem('orgID');
    try {
      const res = await getServices(orgId, token);
      this.setState({
        allServices: res.data.body.data,
      });
    } catch (error) {
      this.setState({
        seError: error.response && error.response.data.message,
      });
      setTimeout(() => {
        this.setState({ seError: false })
      }, 8000);
    }
  }
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  changeHandler = (name, val) => {
    const { serviceNameCount } = this.state;
    if (name === 'name') {
      if (serviceNameCount < 30) {
        this.setState({ serviceName: val, serviceNameCount: val.length });
      } else {
        this.setState({ serviceNameCount: val.length });
      }
    }
  };
  handleDeleteChip = () => {
    this.setState({
      serviceImage: [],
      image: [],
    });
  };
  upload = async (files) => {
    this.setState({
      image: files,
      showDrag: files.length === 0 ? true : false,
      serviceImage: [],
    });
  };
  addLogo = async (object) => {
    /* const { image } = this.state; */
    if (object?.src.length > 0) {
      /* const canvasRef = this.imagePreviewCanvasRef.current;
      const imgSrc = image[0].src;
      const fileExtension = extractImageFileExtensionFromBase64(imgSrc);
      const imageDatato64 = canvasRef.toDataURL('image/' + fileExtension);
      const myFileName = image[0].name + fileExtension;
      if (imageDatato64.split(',')[1] !== '') {
        const newCroppedFile = base64StringtoFile(imageDatato64, myFileName);
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            this.setState({
              serviceImage: [
                {
                  preview: URL.createObjectURL(newCroppedFile),
                  src: reader.result,
                  source: reader.result,
                  name: image[0].name.slice(0, 20),
                  label: image[0].name.slice(0, 20),
                  size: image[0].size,
                },
              ],
              image: [
                {
                  preview: URL.createObjectURL(newCroppedFile),
                  src: reader.result,
                  name: image[0].name.slice(0, 20),
                  label: image[0].name.slice(0, 20),
                  size: image[0].size,
                },
              ],
              openDrawer: false,
              showDrag: image.length > 0 && false,
            });
          },
          false
        );
        reader.readAsDataURL(newCroppedFile);
      } else { */
        this.setState({
          serviceImage: [
            {
              label: object.title,
              name: object.title,
              src: object.src,
              source: object.src,
              size: 0,
            },
          ],
          image: [
            {
              label: object.title,
              name: object.title,
              src: object.src,
              size: 0,
            },
          ],
          openDrawer: false,
          /* showDrag: image.length > 0 && false, */
        });
      /* } */
    } else {
      this.setState({ openDrawer: false });
    }
  };
  handleOnCrop = (crop) => {
    this.setState({ crop });
    this.setState({ cropped: true });
  };
  handleOnCropCompleted = (crop, pixelCrop) => {
    const { image } = this.state;
    const canvasRef = this.imagePreviewCanvasRef.current;
    const imgSrc = image[0].src;
    image64toCanvasRef(canvasRef, imgSrc, crop);
  };
  onDescriptionStateChange = (editorState) => {
    let sum = 1;
    editorState.map((st) => {
      if (st.type === 'bulleted-list' || st.type === 'numbered-list') {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    this.setState({
      serviceDescription: editorState,
      descriptionCount: sum,
    });
  };
  handleRemove = () => {
    this.setState({ image: [], showDrag: true, serviceImage: [] });
  };
  addService = async () => {
    const token = localStorage.getItem('authToken');
    const orgId = localStorage.getItem('orgID');
    const {
      serviceName,
      serviceDescription,
      featured,
      serviceImage,
      descriptionCount,
      allServices,
    } = this.state;
    this.setState({ loading: true });
    const obj = {
      name: serviceName,
      description: JSON.stringify(serviceDescription),
      imageMetaData: {
        imageUrl: serviceImage.length > 0 && serviceImage[0].source,
        imageName: serviceImage.length > 0 && serviceImage[0].name,
        imageThumbnail: serviceImage.length > 0 && serviceImage[0].source,
        imageSize: serviceImage.length > 0 && serviceImage[0].size,
      },
      featured,
      orgId,
    };
    if (
      !serviceName ||
      serviceImage.length === 0 ||
      descriptionCount === 1 ||
      descriptionCount === 0
    ) {
      this.setState({ error: true, loading: false });
    } else {
      const sp = allServices.filter((s) => s.name === serviceName);
      if (sp.length > 0) {
        this.setState({
          error: service_title_already_exist,
          loading: false,
          showAlert: true,
        });
      } else {
        try {
          const res = await addService(obj, token);
          this.props.addServiceHandler(res.data.body.data);
          this.setState({
            success: true,
            showAlert: true,
            serviceName: '',
            featured: false,
            loading: false,
          });
          this.props.showAlert(success_message_services)
          this.props.history.push('/organization/services');
        } catch (error) {
          this.setState({
            error: error && error.response && error.response.data.message,
            showAlert: true,
            loading: false,
          });
          setTimeout(() => {
            this.setState({ error: false })
          }, 8000);
          this.hideAlert();
        }
      }
    }
  };
  render() {
    const {
      serviceName,
      serviceDescription,
      featured,
      descriptionCount,
      showAlert,
      success,
      error,
      openDrawer,
      image,
      serviceImage,
      loading,
      showDrag,
      crop,
    } = this.state;
    function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    const style = {
      width: '100%',
    };
    return (
      <MasterLayoutMiddle
        header={
          <>
            <div
              onClick={() => this.props.history.push('/organization/services')}
              className='backBtn'>
              <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
              <p style={{ alignSelf: 'center' }}>Back</p>{' '}
            </div>
            {error && error === 'Invalid token' && (
              <div
                style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={token_expired}
                  alertType={'error'}
                  onClick={() => this.setState({ error: false })}
                />
              </div>
            )}
            {showAlert && error && (
              <div
                style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={
                    error === 'Invalid token'
                      ? token_expired
                      : error
                  }
                  alertType={'error'}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {showAlert && success && (
              <div
                style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={success_message_services}
                  alertType={'success'}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
          </>
        }
        isForm={true}
        formData={
          <>
            <div>
              <h1 className='title_top'>Add Service</h1>
              <div className="dasheddBorder"></div>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h2 className="sub_title">Service Details</h2>
              </Grid>
              <Grid item xs={12}>
                <div className="field_box services_helpertext">
                  <BaseTextField
                    label='Service Name'
                    value={serviceName}
                    onChange={(e) => this.changeHandler('name', e.target.value)}
                    error={!success && error && !serviceName}
                    helperText={
                      (!success &&
                        error &&
                        !serviceName &&
                        'Please enter service name') ||
                      ' '
                    }
                    placeholder=' '
                    count={30}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
              <div className="field_box services_helpertext">
                <TextEditor
                  onChange={(e) => this.onDescriptionStateChange(e)}
                  disabled={false}
                  value={serviceDescription}
                  label={"Description"}
                  helperText={
                    error &&
                    !descriptionCount &&
                    "Please add service description"
                  }
                  error={error && !descriptionCount}
                  maxCharCount={1000}
                />
                </div>
              </Grid>
              <Grid item xs={12}>
                  <div className="field_box">
                    <div className="media-library-image" >
                      <MediaUpload 
                        typeOfMedia='/images' 
                        label="Service Image" 
                        addMediaItem={this.addLogo}
                        buttonName = "ADD IMAGE" />
                    </div>
                    <div>
                    </div>
                  </div>
                </Grid> 
              {/* <Grid item xs={12}>
                <div className="field_box"
                  onClick={() =>
                    this.setState({
                      openDrawer: true,
                      showDrag: image.length > 0 ? false : true,
                    })
                  }>
                  <div className='img-chip'>
                    <BaseImgChip
                      chipData={serviceImage}
                      deleteChip={(chip, index) => this.handleDeleteChip(chip, index)}
                      onInputClick={() =>
                        this.setState({
                          openDrawer: true,
                          showDrag: image.length > 0 ? false : true,
                        })
                      }
                      onChipInputClick={() =>
                        this.setState({
                          openDrawer: true,
                          showDrag: image.length > 0 ? false : true,
                        })
                      }
                      label='Service Image'
                    />
                    <p className='MuiFormHelperText-root Mui-error'>
                      <span>
                        {error &&
                          serviceImage.length === 0 &&
                          'Please provide service image'}
                      </span>
                    </p>
                  </div>
                </div>
              </Grid> */}
              <Grid item xs={12}>
                 <div className="field_box border_top_bottm">
                  <FormSwitch
                    headline='Service Status'
                    body="Enabling this would make the service available for publishing from application"
                    values={featured}
                    handleSwitchChange={(e) => this.setState({ featured: !featured })}
                  />
                </div> 

                {/* <Grid item xs={12}>
                  <div className="field_box" >
                    <div className="media-library-image" >
                      <MediaUploader typeOfMedia='/images' label="Upload Images" onChange={this.selectMediaItem} />
                    </div>
                    <div>
                    </div>
                  </div>
                </Grid> */}

              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <div
                    style={{
                      marginTop: 30,
                      display: 'flex',
                      justifyContent: 'center',
                      width: 570,
                    }}
                  >
                    <CircularProgress color='primary' />
                  </div>
                ) : (
                  <div className="form_button">
                    <p className='cancel' onClick={() => this.props.history.push('/organization/services')} >CANCEL</p>
                    <BaseButton text='ADD NOW' onClick={() => this.addService()} />
                  </div>
                )}
              </Grid>
            </Grid>
            <div className='orgForm4'></div>
            <DrawerMain
              open={openDrawer}
              openHandler={() => this.setState({ openDrawer: false })}
              title='Select Image'
              onCancel={() => this.setState({ openDrawer: false })}
              onSave={() => this.addLogo()}
            >
              <List className='img-details' style={{ paddingLeft: 0 }}>
                {image.map((file, index) => (
                  <ListItem key={file.name} style={{ paddingLeft: 0 }}>
                    <ListItemAvatar>
                      <Avatar
                        variant='square'
                        alt={'preview'}
                        src={
                          serviceImage.length > 0
                            ? serviceImage[0].source
                            : file.preview
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={file.name.slice(0, 20)}
                      secondary={formatBytes(file.size)}
                    />
                    <ListItemSecondaryAction style={{ paddingLeft: 20 }}>
                      <IconButton
                        aria-label='delete'
                        size='medium'
                        style={{ padding: '0px' }}
                        onClick={() => this.handleRemove(index)}
                      >
                        <DeleteForeverIcon fontSize='inherit' />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              {showDrag && (
                <>
                  <div style={{ marginBottom: 20 }} className='img-uploader'>
                    <BaseFileSelect selectedFiles={(file) => this.upload(file)} showMediaText={true} getFromMedia={() => console.log('clicked')} />
                  </div>
                </>
              )}
              <ReactCrop
                src={image.length > 0 && image[0].src}
                crop={crop}
                onChange={this.handleOnCrop}
                onComplete={this.handleOnCropCompleted}
              />
              {(image.length > 0 || serviceImage.length > 0) && (
                <p style={{ color: '#818E94', fontSize: 12 }}>
                  You can also crop the uploaded images
                </p>
              )}
              <br />
              <div style={{ display: 'none' }}>
                <canvas ref={this.imagePreviewCanvasRef}></canvas>
              </div>
            </DrawerMain>
            <div className='add-service-separator'></div>
          </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={true}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    services: state.services.editableService,
    edit: state.services.edit,
  };
};
const mapDispatchToProps = (dispatch) => ({
  addServiceHandler: (res) => dispatch(addServiceAction(res)),
  emptyServiceHandler: () => dispatch(emptyServiceAction()),
  showAlert: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddService);
