import React from 'react';
import { CardDetailsTable, Initials, CardStatus } from "@dclab/dc-react-ui-kit";

function EmergencyContact (props) {
   let header = {
        tableName: `Emergency Contact`,
    };

    let data = [
        {
            field: "First name",
            value: "Shayan"
        },
        
        {
            field: "Last Name",
            value: "Ahmed"
        },
        
        {
            field: "Relationship",
            value: "Sibling"
        },
        
        
    ];

    let data2 = [
        {
            field: "Phone",
            value: "+1 (123) 123-1234"
        },
        {
            field: "Email",
            value: `hassanahmed@email.com`
        },
        {
            field: "Address",
            value: "889 Lords Way Bolivar, TN 38008 United States"
        },

    ];

    

    let footerLinkName = "Edit";

    // HANDLE FOOTER LINK
    const onClick = () => {
        console.log('edit')
    }

    const onDeleteClick = () => {
        console.log('delete')
    }

    const onClickSend = () => {
        console.log('Send')
    }


    const handleChange = (event, value) => {
        console.log(event.target.value, value);
    };


    return (

        <CardDetailsTable
            data={data}
            data2={data2} 
            twoColumns 
            header={header}
            footerLinkName={footerLinkName}
            onClick={() => onClick()}
            onDeleteClick={() => onDeleteClick()}

            Status={false}
            StatusLabel="Status"
            StatusText="Active"
            statuscolor="#3CC480"

            OrgName="Deenfund"
            OrgSubText="Application"
            HeaderStatus={false}

        />

    );
}


export default EmergencyContact;