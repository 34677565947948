import React, {useEffect, useState} from "react";
import Chip from '@material-ui/core/Chip';
import { SelectAppsCard, AlertAction } from "@dclab/dc-react-ui-kit";
import './style.css';
import { withRouter } from "react-router-dom";
import { updatePlanSubscriptions } from "./api";
import CircularProgress from "@material-ui/core/CircularProgress";


function Browse(props) {
  const { plans, planSubscription, loading } = props;
  // console.log("planSubscription", plans)

  const [applications, setapplications] = useState([])
  const [integrations, setintegrations] = useState([])
  const [planSubcriptionsData, setplanSubcriptionsData] = useState([])
  const [showSuccessAlert, setshowSuccessAlert] = useState(false)
  const [filterProduct, setfilterProduct] = useState("all")

  useEffect(() => {
    props.applications && setapplications(props.applications?.sort((a, b) => a?.order - b?.order));
    props.integrations && setintegrations(props.integrations?.sort((a, b) => a?.order - b?.order))
  }, [props.applications, props.integrations])

  useEffect(() => {
    if(planSubscription?._id) {
      const arr = Object.keys(planSubscription)
      .filter(v => {
        return planSubscription[v] != null && typeof planSubscription[v] === 'object'
      }).map(key => ({ ...planSubscription[key], planName: [key][0] }))
      setplanSubcriptionsData(arr)
      console.log("arrs", arr)
    }
  }, [planSubscription, showSuccessAlert])

  const Check = () => {
    // console.log("hello");
  };


  const onActionButtonClick = async (type, details) => {
    if(type === "SEE DETAILS") {

        props.history.push({pathname: `/app-details`, state: { details: details, plans: plans, planSubscription: planSubscription }});
    } else if (type === "ADD NOW") {
      const token = localStorage.getItem("authToken");
      let selectedIntegrations = planSubscription?.extraIntegrations?.selectedIntegrations?.length ? planSubscription.extraIntegrations.selectedIntegrations : [];
      selectedIntegrations.push({
        name: details?.name ?? "",
        price: 0,
        paymentFrequency: "",
        compatible: details?.compatibleWith?.length ? details?.compatibleWith?.map((el) => el.applicationName).join(", ") : "",
        integrationApi: details?.integrationApi ?? "",
        createdAt: new Date()
      });

      const formData = {
        extraIntegrations : {
          selectedIntegrations
        }
      };

      try {
  
        let res = await updatePlanSubscriptions(planSubscription._id, formData, token);
        if(!res?.data?.error) {
          setshowSuccessAlert(true)
          props.history.push(`/more-app`);
          props.history.push({pathname: `/more-apps`, state: { showSuccessAlert: true }});
          // setTimeout(() => {
          //   setshowSuccessAlert(false)
          // }, 8000);
        } else {
          console.log("type", planSubscription._id, formData, res)
        }

      } catch (error) {
        console.log(error)
      }


    } else if (type?.toLowerCase() === "manage") {
      let plan = plans?.stripeData?.length ? plans.stripeData.find((plan, i) => plan?.subscription_plan?.toLowerCase() === details?.name?.toLowerCase()) : null;
      // let id = plan?._id;
      plan = { ...plan, orgId: plans?.orgId, planSubscriptionId: plans?.planSubscriptionId };

      if(details?.appType === "Application") {
        if(plan) {
          props.history.push({pathname: `/organization/plan-details`, state: { details: details, plan: plan } });
        } else {

        } 
      } else if (details?.appType === "Integration") {
         if(plan) {
            props.history.push({pathname: `/organization/plan-details`, state: { details: details, plan: plan } });
         } else {
            props.history.push({pathname: `/organization/plan-details`, state: { details: details, plan: plan } });
         }
      }
                   
    }
    console.log("onActionButtonClick", type);
  };

  const filterProducts = (type) => {
    switch (type) {
      case "free": {
        let integrations = props.integrations.filter((el) => el?.pricing?.pricingType?.toLowerCase() === "free");
        setintegrations(integrations)
        let filteredApps = [];
        let a = props.applications?.forEach((app) => {
          if(app.plans.find((plan) => plan.name?.toLowerCase() === "free" && plan.status?.toLowerCase() === "available")) {
             filteredApps.push(app)
          }
        })
        setapplications(filteredApps)
     } 
     break;

      case "paid": {
        let integrations = props.integrations.filter((el) => el?.pricing?.pricingType?.toLowerCase() === "paid");
        setintegrations(integrations)
        let filteredApps = [];
        let a = props.applications?.forEach((app) => {
          if(!app.plans.find((plan) => plan.name?.toLowerCase() === "free" && plan.status?.toLowerCase() === "available")) {
             filteredApps.push(app)
          }
        })
        setapplications(filteredApps)
      }
      break;

      case "deenfund": {
        let integration = [];
        let app = props.applications.filter((el) => el?.name?.toLowerCase() === "deenfund");
        let arr = props?.integrations?.forEach((int) => {
          let compatibleIntegration = int?.compatibleWith?.filter(compatible => compatible?.applicationName?.toLowerCase() === 'deenfund');
          if(compatibleIntegration.length > 0) {
            integration.push(int);
          }
        });
        
        setapplications(app);
        setintegrations(integration);
      }
      break;

      case "deenkiosk": {
        let integration = [];
        let app = props.applications.filter((el) => el?.name?.toLowerCase() === "deenkiosk");
        let arr = props?.integrations?.forEach((int) => {
          let compatibleIntegration = int?.compatibleWith?.filter(compatible => compatible?.applicationName?.toLowerCase() === 'deenkiosk');
          if(compatibleIntegration.length > 0) {
            integration.push(int);
          }
        });
        setapplications(app);
        setintegrations(integration);
      }
      break;

      case "deenweb": {
        let integration = [];
        let app = props.applications.filter((el) => el?.name?.toLowerCase() === "deenweb");
        let arr = props?.integrations?.forEach((int) => {
          let compatibleIntegration = int?.compatibleWith?.filter(compatible => compatible?.applicationName?.toLowerCase() === 'deenweb');
          if(compatibleIntegration.length > 0) {
            integration.push(int);
          }
        });
        setapplications(app);
        setintegrations(integration);
      }
      break;

      case "deenteam": {
        let integration = [];
        let app = props.applications.filter((el) => el?.name?.toLowerCase() === "deenteam");
        let arr = props?.integrations?.forEach((int) => {
          let compatibleIntegration = int?.compatibleWith?.filter(compatible => compatible?.applicationName?.toLowerCase() === 'deenteam');
          if(compatibleIntegration.length > 0) {
            integration.push(int);
          }
        });
        setapplications(app);
        setintegrations(integration);
      }
      break;

      case "deenscreen": {
        let integration = [];
        let app = props.applications.filter((el) => el?.name?.toLowerCase() === "deenscreen");
        let arr = props?.integrations?.forEach((int) => {
          let compatibleIntegration = int?.compatibleWith?.filter(compatible => compatible?.applicationName?.toLowerCase() === 'deenscreen');
          if(compatibleIntegration.length > 0) {
            integration.push(int);
          }
        });
        setapplications(app)
        setintegrations(integration)
      }
      break;
    
      default:
        setapplications(props.applications);
        setintegrations(props.integrations)
        break;
    }
    setfilterProduct(type);
  }

  
  useEffect(() => {
    if(props?.location?.state?.showSuccessAlert) {
      setshowSuccessAlert(true)
      setTimeout(() => {
        setshowSuccessAlert(false)
      }, 8000);
    }
}, [props?.location?.state?.showSuccessAlert])

  const capitalizeFLetter = (val) => {  
    return val ? `${val[0].toUpperCase() + val.slice(1)}` : ""; 
  } 

  return (
    <div>
         {(showSuccessAlert && (
          <div
            style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
          >
            <AlertAction
              alert={"Integration added successfully!"}
              alertType={"success"}
              onClick={() => setshowSuccessAlert(false)}
            />
          </div>
        ))}
        <div className="chip_tab_container">
          <Chip label="All categories" variant="outlined" onClick={() => filterProducts("all")} className={filterProduct === "all" ? "active" : ""} />
          <Chip label="Paid" variant="outlined" onClick={() => filterProducts("paid") } className={filterProduct === "paid" ? "active" : ""} />
          <Chip label="Free" variant="outlined" onClick={() => filterProducts("free") } className={filterProduct === "free" ? "active" : ""} />
          <Chip label="DEENSCREEN" variant="outlined" onClick={() => filterProducts("deenscreen") } className={filterProduct === "deenscreen" ? "active" : ""} />
          <Chip label="DEENKiosk" variant="outlined" onClick={() => filterProducts("deenkiosk") } className={filterProduct === "deenkiosk" ? "active" : ""} />
          <Chip label="DEENfund" variant="outlined" onClick={() => filterProducts("deenfund") } className={filterProduct === "deenfund" ? "active" : ""} />
          <Chip label="DEENweb" variant="outlined" onClick={() => filterProducts("deenweb") } className={filterProduct === "deenweb" ? "active" : ""} />
          <Chip label="DEENTeam" variant="outlined" onClick={() => filterProducts("deenteam") } className={filterProduct === "deenteam" ? "active" : ""} />
        </div>
        
        {loading ? <div
                  style={{
                    position: 'absolute',
                    top: "50%",
                    left: "50%",
                    zIndex: 1000
                  }}
                >
                  <CircularProgress color='primary' />
                </div> : <div className="app_card_container">
            {applications?.length ? applications.map((app, i) => {
                let found = planSubcriptionsData?.find((el) => el.plan && el?.planName?.toLowerCase() === app?.name?.toLowerCase())
                  return <div key={i} className="apps_card"> 
                 <SelectAppsCard
                  showStatus={false}
                  iname="DeenIcon"
                  imageUrl={app?.iconMetaData?.iconUrl ?? ""}
                  status={false}
                  btndisabled={true}
                  cardTitle={capitalizeFLetter(app?.displayName ?? app?.name) ?? ""}
                  tagline={"Application"}
                  discription={app.shortDescription ?? ""}
                  anchorhelpertext={"LEARN MORE"}
                  cardAction={() => Check()}
                  btnTwoText={app?.status !== 'Coming Soon'? found ? "MANAGE" : "" : ""}
                  btnOneText={app?.status === 'Coming Soon'? 'Coming Soon' : "SEE DETAILS"}
                  showAddedIcon={found ? true : false}
                  onActionButtonClick={onActionButtonClick}
                  appDetails={{...app, appType: "Application", addedPlan: found}}
                />
              </div>
            }) : null}

              {integrations?.length ? integrations.map((ints, i) => { 
                 let found = planSubcriptionsData?.find((el) => el?.planName?.toLowerCase() === "extraintegrations");
                 let intFound = found?.selectedIntegrations?.find((el) => el?.integrationApi?.toLowerCase() === ints?.integrationApi?.toLowerCase());
                 
                 return <div key={i} className="apps_card"> 
                  <SelectAppsCard
                    showStatus={ints?.pricing?.pricingType?.toLowerCase() === "free" ? true : false}
                    iname="DeenIcon"
                    imageUrl={ints?.imageMetaData?.imageUrl ?? ""}
                    status={ints?.pricing?.pricingType?.toLowerCase() === "free" ? "FREE" : false}
                    btndisabled={true}
                    cardTitle={capitalizeFLetter(ints?.name) ?? ""}
                    tagline={"Integration"}
                    discription={ints?.shortDescription ?? ""}
                    anchorhelpertext={"LEARN MORE"}
                    cardAction={() => Check()}
                    btnTwoText={ints?.status !== 'Coming Soon'? intFound ? "MANAGE" : "" : ""}
                    btnOneText={ints?.status === 'Coming Soon' ? 'Coming Soon' :intFound ? "SEE DETAILS" : ints?.pricing?.pricingType?.toLowerCase() === "free" ? "ADD NOW" : "SEE DETAILS"}
                    showAddedIcon={intFound ? true : false}
                    onActionButtonClick={onActionButtonClick}
                    appDetails={{...ints, appType: "Integration", addedPlan: intFound }}
                  />
              </div>
            }) : null}

          <div className="clearfix"></div>
        </div>
        }
    </div>
  )
}
export default withRouter(Browse)
