import React, { useEffect, useState } from "react";
import { PageHeaderWithTabs, MasterLayoutMiddle, FooterInner, AlertAction } from "@dclab/dc-react-ui-kit";
import Chip from '@material-ui/core/Chip';
// import { SelectAppsCard } from "@dclab/dc-react-ui-kit";
import './style.css';

import Browse from "./Browse"
import Manage from "./Manage"
import { getApps, getIntegrations, getPlans,getCurrentUserDetail } from "./api";
import {getPlanSubscriptionsByOrgId} from "../../pages/UserProfile/Apis";
import { hideAlert } from "../../redux/actions/alertActions";
import { connect } from "react-redux";


function ManageApps(props) {
  const [value, setValue] = React.useState(0);
  const [apps, setapps] = useState([])
  const [integrations, setintegrations] = useState([])
  const [planSubscription, setplanSubscription] = useState()
  const [plans, setplans] = useState({})
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState("")
  const [cartItems, setcartItems] = useState([])

  useEffect(() => {
    let cartData = JSON.parse(localStorage.getItem("appCartItem")) ? JSON.parse(localStorage.getItem("appCartItem")) : [];
    setcartItems(cartData)
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if(props?.location?.state?.tab) {
      setValue(1)
    }
  }, [])

  useEffect(() => {
    const getDetails = async () => {
      const token = localStorage.getItem("authToken");
      const orgId = localStorage.getItem("orgID");
      try {
        setloading(true)
          const appsRes = await getApps(token);
          const integrationsRes = await getIntegrations(token);

          const plansRes = await getPlans(orgId, token);
          // console.log("plansRes: ", plansRes)
          const res = await getPlanSubscriptionsByOrgId(orgId, token);
          if(res?._id) {
            setplanSubscription(res)
          }

          setplans(plansRes?.data?.body?.data)
          // console.log("appsRes", appsRes)
          // console.log("integrationsRes", integrationsRes)
          setapps(appsRes?.data?.body?.data)
          setintegrations(integrationsRes?.data?.body?.data)
          setloading(false)
        } catch (error) {
            seterror(error.response && error.response.data.message)
            setloading(false)
        }
    }
    getDetails();
    setTimeout(() => {
      props.hideAlert();
    }, 8000);
}, [])

  const object = [
    {
      label: "Browse",
      item: "Tab 01",
    },
    {
      label: "Manage",
      item: "Tab 02",
    },
  ];

  const capitalizeFLetter = (val) => {  
    return val ? `${val[0].toUpperCase() + val.slice(1)}` : ""; 
  } 

  let sum = 0;
  const CartItems = cartItems?.length ? cartItems.map((item) => {
    let appName = Object.keys(item)[0];
    let planName = item[appName].plan;
    let displayName = item[appName]?.displayName ?? false;
    sum = sum + parseFloat(item[appName].total)
    return {
      name: `${displayName ? displayName.toUpperCase() : appName.toUpperCase() ?? ""} - ${planName?.toUpperCase() ?? ""}`,
      desc: `$${item[appName].total}/${capitalizeFLetter(item[appName].paymentFrequency)}`,
      id: appName
    }
  }) : [];

  const cartCheckOut =() => {
    console.log('checkOut')
  }
  const removeCartItem =(data, index) => {
    console.log(data, index, 'lopppp')
      
    let items = cartItems;
    items = items.length ? items.filter((item) => data.id !== Object.keys(item)[0]) : [];
    
    console.log("items", items);
    localStorage.setItem("appCartItem", JSON.stringify(items));
    setcartItems(items)  
  };

  const saveChanges = () => {
    console.log("save");
  };
  const publish = () => {
    console.log("publish");
  };
  const back = () => {
    console.log("back");
  };
  const saveStyles = {
    backgroundColor: "#2A82F6",
  };
  const publishStyles = {
    backgroundColor: "#2A82F6",
    opacity: 0.6,
  };
  const Check = () => {
    console.log("hello");
  };

  const style = {
    width: '100%',
  };

  const paperStyles = {
    backgroundColor: '#4482f6'
  };


  return (
    <MasterLayoutMiddle
      header={
        <div>
          <PageHeaderWithTabs
            value={value}
            title="Empower your organization"
            object={object}
            indicatorColor="primary"
            style={{ color: "#2A82F6", border: "1px solid " }}
            handleChange={(x, y) => handleChange(x, y)}
            indicatorColor="#2A82F6"
            headerColor={'#fff'}
            subHeader={'We provide powerful tools, integration, features and more.'}
            saveStyles={saveStyles}
            publishStyles={publishStyles}
            paperStyles={paperStyles}
            saveChanges={() => saveChanges()}
            publish={() => publish()}
            disabled={false}
            back={back}
            showBackBtn={false}
            //livePauseBtnText={"Pause"}
            //livePauseStyles={livePauseStyles}
            //livePauseIcon={LiveIcon}
            //livePauseIconStyle={livePauseIconStyle}
            //livePauseOnclick={(e)=> console.log('!props.livePauseBtnText')}
            //livePauseTextStyle={livePauseTextStyle}
            // leftIconText={"Live"}
            addToCart={true}
            CartItems={CartItems}
            cartCheckOut={cartItems?.length ? () => props.history.push("/cart-details") : null}
            removeCartItem={removeCartItem}
            cartSubTotal={sum ? sum.toFixed(2) : 0}
            cartQuantity={cartItems?.length ?? null}
            //liveIcon={LiveIcon}
            hiddenDraft={true}
            hiddenPublish={true}
          />

          {props?.alertValue && (props?.alertValue === "Free Application added successfully!" || this.props?.alertValue === "Invitation resent successfully") && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                }}
              >
                <AlertAction
                  alert={props?.alertValue}
                  alertType={"success"}
                  onClick={() => props.hideAlert()}
                />
              </div>
            )}

        </div>
      }
      content={
        <div>
          {value === 0 && (
            <div>
              <Browse
                applications={apps}
                integrations={integrations}
                plans={plans}
                planSubscription={planSubscription}
                loading={loading}
              />
            </div>
          )}
          {value === 1 && (
            <div>
              <Manage
                applications={apps}
                integrations={integrations}
                plans={plans}
                planSubscription={planSubscription}
                loading={loading}
              />
            </div>
          )}
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  )
}
const mapStateToProps = (state) => ({
  alertValue: state.alert.alertValue,
});
const mapDispatchToProps = (dispatch) => ({
  hideAlert: (res) => dispatch(hideAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ManageApps);
