import React, { Component } from 'react';
import './style.css'
class Page_title extends Component {
    render() {
        return (
            <div className="page_title">
                <h1>{this.props.name}</h1>
            </div>
        );
    }
}
export default Page_title;