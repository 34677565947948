import * as constants from '../constants'

const INITIAL_STATE = {
    email: "johnhassankhan@gmail.com",
    fullName: "Deen Connect",
    verifyCode:''
  };
  
  export default (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
      case constants.SIGN_UP:
        return {
          ...state,
          email: action.payload.email,
          fullName: action.payload.fullName,
        };

      case constants.VERIFY_CODE:
        return {
          ...state,
          verifyCode:action.payload.verifyCode
        };

      default:
        return state;
    }
  };
  