import React from 'react';
import {
    MasterLayoutMiddle, FooterInner, BasePhoneInput,
    BaseSelect, BaseTextField, DateRange, BaseButton,
}
    from "@dclab/dc-react-ui-kit";

import Grid from '@material-ui/core/Grid';
import ChevronLeft from '@material-ui/icons/ChevronLeft';


function EditEmergency(props) {

    const style = {
        width: '100%',
    };

    const [values, setValues] = React.useState({
        age: "",
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const object = [
        {
            value: "10",
            label: "Ten",
        },
        {
            value: "20",
            label: "Twenty",
        },
        {
            value: "30",
            label: "Thirty",
        },
    ];

    const [isOpen, setOpen] = React.useState(false);
    const [states, setstates] = React.useState(false);
    const [temp, settemp] = React.useState('')
    const [value, setvalue] = React.useState('No specific date')

    const onSelect = (value, states) => {
        settemp(value);
        setstates(states);
    };

    const onToggle = () => {
        setOpen(!isOpen);
    };

    const clear = () => {
        setOpen(!isOpen);
        setvalue('No Specific Date')
        settemp('')

    };

    const cancel = () => {
        settemp('')
        setOpen(!isOpen);

    }
    const save = () => {
        setvalue(temp)
        setOpen(!isOpen);
    }


    const [phone, setPhone] = React.useState({
        country: "",
        number: "",
    });

    return (

        <MasterLayoutMiddle

            header={
                <div style={{ width: '26%', alignSelf: 'center' }}>
                    <div
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                        className='backBtn'
                    >
                        <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
                        <p style={{ alignSelf: 'center' }}>Back</p>{' '}
                    </div>
                </div>
            }


            isForm={true}
            formData={
                <>
                    <div>
                        <h1 className='title_top'>Edit Emergency Contact</h1>
                        <div className="dasheddBorder"></div>
                    </div>


                    <Grid container spacing={3}>

                        <Grid item xs={6}>
                            <div className="field_box">
                                <BaseTextField
                                    label="First Name"
                                    placeholder=""
                                    helperText=""
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Last Name"
                                    placeholder=""
                                    helperText=""
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="field_box">
                                <BasePhoneInput
                                    phoneInputVariant={"underlineWithFlag"} // default, materialui, underlineInput, underlineWithFlag
                                    country={'us'}
                                    value={phone.number}
                                    countryCodeEditable={false}
                                    onChange={(phone, country) => console.log("onChange", phone, country)}
                                    onBlur={() => console.log("onBlur")}
                                    onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                                    variant="outlined"
                                    onlyCountries={["us", "ca", "gb", "my"]}
                                    containerStyle={{}}
                                    buttonStyle={{}}
                                    inputStyle={{
                                        marginLeft: "0px",
                                        height: "35px",
                                        width: "100%",
                                        fontFamily: "Roboto-Regular",
                                        fontSize: "16px",
                                    }}
                                    containerClass={{}}
                                    errorText={"Phone number is required"}
                                    error={false}
                                    errorStyles={{ marginBottom: "5px" }}
                                    autoFormat={true}
                                    disableDropdown={false}
                                    disabled={false}
                                    disableCountryCode={false}
                                    enableSearch={false}
                                    disableSearchIcon={false}
                                    placeholder={""}
                                    inputProps={{}}
                                    specialLabel={'Phone Number'}
                                // preferredCountries={[]}
                                // excludeCountries={[]}

                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Email"
                                    placeholder=""
                                    helperText=""
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseSelect
                                    label="Relationship"
                                    value={values.age}
                                    onChange={handleChange("age")}
                                    object={object}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={8}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Resident Street Address"
                                    placeholder=""
                                    helperText=""
                                />
                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Apt/Floor"
                                    placeholder=""
                                    helperText=""
                                />
                            </div>
                        </Grid>


                        <Grid item xs={4}>
                            <div className="field_box">
                                <BaseTextField
                                    label="City"
                                    placeholder=""
                                    helperText=""
                                />
                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <div className="field_box">
                                <BaseSelect
                                    label="State"
                                    value={values.age}
                                    onChange={handleChange("age")}
                                    object={object}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={4}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Zip/Postal Code"
                                    placeholder=""
                                    helperText=""
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseSelect
                                    label="Country"
                                    value={values.age}
                                    onChange={handleChange("age")}
                                    object={object}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="form_button">
                                <p
                                    className='cancel'
                                    onClick={() => this.onCancelOrgDetail()
                                    }
                                >
                                    GO BACK
                                </p>
                                <BaseButton text='SAVE' onClick={() => this.updateOrg()} />
                            </div>

                        </Grid>

                    </Grid>

                </>
            }

            isFooter={true}
            footer={
                <FooterInner style={style} />
            }

            isWhiteBackground={true}

        />

    );
}


export default EditEmergency;