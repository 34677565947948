import React, { useState, useEffect } from 'react';
import VerifiedIcon from '../../../assets/img/VerifiedIcon.svg';
import { AlertAction, MasterLayoutMiddle, FooterInner, CardDetailsTable,} from "@dclab/dc-react-ui-kit";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Grid from '@material-ui/core/Grid';
import { AlertDialogue } from "@dclab/dc-react-ui-kit";








export default function MemberDetails(props) {
  console.log(props?.location?.state?.order)
  const [order, setorder] = useState({})
  const [showSuccessAlert, setshowSuccessAlert] = useState(true)
  const [loading, setloading] = useState(false)

  const [open, setOpen] = useState(false);
  


  useEffect(() => {
    if (props?.location?.state?.order) {
      props?.location?.state?.order && setorder(props.location.state.order)
    }
  }, [props?.location?.state?.order])


  let header = { avatar: "", tableName: `Ahmad Noor`, icon: "", status: [] };

  let data = [
    {
      field: "Email",
      value: `<span style='display: flex'>ahmadnoor@email.com <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style='margin-left: 5px' viewBox="0 0 24 24"><defs><style>.a{fill:none;}.b{fill:#3cc480;}</style></defs><path class="a" d="M0,0H24V24H0Z"/><path class="b" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM10,17,5,12l1.41-1.41L10,14.17l7.59-7.59L19,8Z"/></svg></span>` 
    },
    {
      field: "Phone",
      value: "+1 (876) 786-8764"
    },
  ];

  let data2 = [
    {
      field: "Committees",
      value:   `<a style={{ color: "#2490F9", textDecoration: "none" }} href={} target="_blank"> Social Services, Youth </a>`
    },

  ];

  let footerLinkName = "EDIT INFORMATION";
  let footerDeleteButton = "DELETE MEMBER";

  const onClick = () => {
    console.log('EDIT')
    props.history.push({ pathname: `/organization/edit-committee`, state: { order: order } });
  }

  const onCancelClick = () => {
    setOpen(true)
  }

  const style = {
    width: '100%',
  };

  const style2 = {
    width: 500,
    marginLeft: 800
  };
  
  return (
    <MasterLayoutMiddle
      header={
        <>
          <div
            onClick={() =>
              props.history.goBack()
            }
            className='backBtn'>
            <ChevronLeft style={{ alignSelf: 'center', marginLeft: 0 }} />
            <p style={{ alignSelf: 'center' }}>Back</p>{' '}
          </div>

          {(showSuccessAlert && (
            <div
              style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
            >
              <AlertAction
                alert={"Member updated successfully!"}
                alertType={"success"}
                onClick={() => setshowSuccessAlert(false)}
              />
            </div>
          ))}
        </>
      }
      content={
        <>
          <div style={{ marginTop: '60px' }}>
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <CardDetailsTable
                  data={data}
                  data2={data2}
                  twoColumns
                  header={header}
                  footerLinkName={footerLinkName}
                  footerDeleteButton={footerDeleteButton}
                  onClick={() => onClick()}
                  onDeleteClick={() => onCancelClick()}
                />

                
              </Grid>

            </Grid>

            <AlertDialogue
            title="Delete Member?"
            description="Are you sure you want to delete this member? Deleting 
            will permanently remove from everywhere."
            cancelText="Cancel"
            confirmText="Confirm"
            open={open}
            handleCancel={() => setOpen(false)}
            handleClose={() => setOpen(false)}
           // handleConfirm={() => handleConfirm()}
             styles={style2}
          />



          </div>

        </>
      }
      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  )
}
