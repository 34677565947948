import React, { Component } from 'react'
import { SimpleTable, EmptyTable, MasterLayoutMiddle, FooterInner, AlertAction, AlertDialogue } from "@dclab/dc-react-ui-kit";
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import Handle from "../../assets/img/more_vert-24px.svg";
import Moment from "moment";
import '../../App.css'
import './style.css'
import { deleteReminderWebsite, getReminders } from '../Donations/deen-kioks-website/Apis';
import { editReminder } from '../../redux/actions/reminderAction';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { deleteRemindersWithAppId, getRemindersWithAppId, sortRemindersWebsite } from './api';
import { hideAlert, showAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
const { token_expired, remove_website_reminder_success_message } = messages_content;
class Reminders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      remindersList: [],
      error: false,
      openAlert: false,
      specificRow: [],
      data: [],
      status: ''
    }
  }
  deleteServiceAction = async (event) => {
    const { remindersList } = this.state
    this.setState({ Loading: true, openAlert: false })
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      await deleteRemindersWithAppId(token, orgId, 'deenweb', event[0].remindId)
      const stateNews = remindersList
      const NewsIndex = remindersList.indexOf(event)
      stateNews.splice(NewsIndex, 1)
      this.setState({ Loading: false })
      this.props.alertShow(remove_website_reminder_success_message)
    } catch (err) {
      this.setState({ Loading: false });
      this.setState({ error: err.response && err.response.data.message })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  }
  async componentDidMount() {
    this.setState({ Loading: true })
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const getremind = await getRemindersWithAppId(token, orgId, 'deenweb')
      const data = getremind.data.body.data;
      const sort = data.sort((a, b) => {
        return a.reminder.order - b.reminder.order
      })
      this.props.editReminderDisp(sort);
      this.setState({ data: sort })
      const dataAll = sort.map((text, index) => {
        const description = JSON.parse(text.reminder.description);
        const dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("h:mm A") + ' (EST)'];
        const dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("h:mm A") + ' (EST)'];
        const UnpublishDate = new Date(dateEnd).getFullYear()
        const status = text.status
        this.setState({ status: status })
        const statusChangeStart = new Date(dateStart) <= new Date();
        const statusChangeEnd = new Date(dateEnd) <= new Date();
        return (
          [
            { type: "id", value: `${index <= 8 ? '0' + (index + 1) : index + 1}`, _id: text._id, remindId: text.reminder._id },
            { type: "string", name: "name", value: text.reminder.title, },
            { type: "string", name: "frequency", value: description[0].children[0].text.slice(0, 55) + "...", },
            {
              type: "string",
              name: "vertical",
              value: dateStart,
            },
            {
              type: "string",
              name: UnpublishDate === 2099 ? "frequency" : "vertical",
              value: UnpublishDate === 2099 ? "Never" : dateEnd
            },
            {
              type: "status",
              status: status === 'Hidden' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'pending' : 'schedule'
                : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',
              value: status === 'Hidden' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Unpublished' : 'Scheduled'
                : statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled'
            },
          ]
        )
      })
      this.setState({ remindersList: dataAll, Loading: false });
    } catch (err) {
      this.setState({ Loading: false });
      this.setState({ error: err.response && err.response.data.message })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  }
  handleCancel = () => {
    this.setState({ openAlert: false });
  }
  handleClose = () => {
    this.setState({ openAlert: false });
  };
  render() {
    const { remindersList, Loading, error, openAlert, specificRow } = this.state;
    if (this.props.alert.alertValue) {
      setTimeout(() => {
        this.props.alertHide();
      }, 8000);
    }
    let classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    let col = [
      { id: "drag", label: " ", width: 'auto' },
      { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
      { type: "link", name: "invoice_number", label: "Title", width: '25%' },
      { type: "string", name: "name", label: "Description", width: '25%' },
      { type: "string", name: "date", label: "Publish Date", width: '20%' },
      { type: "string", name: "date/time", label: "UnPublish Date", width: '15%' },
      { type: "string", name: "status", label: "STATUS", width: '10%' },
      { type: "action", name: "action", label: "ACTION", width: '10%' },
    ];
    const style = {
      width: '100%',
    };
    const styleAlert = {
      width: 470,
      marginLeft: 800
    };
    const sortData = async (data) => {
      const token = localStorage.getItem("authToken");
      let OBJ = {
        sortedList: data.map(text => {
          if (text.order)
            return (
              { _id: text?.reminder?._id, order: text.order }
            )
        })
      }
      try {
        await sortRemindersWebsite(token, OBJ)
      } catch (err) {
        this.setState({ error: err.response && err.response.data.message })
        setTimeout(() => {
          this.setState({ error: false })
        }, 8000);
      }
      const sortReminders = data.sort((a, b) => a.order - b.order)
      const dataAll = sortReminders.map((text, index) => {
        const description = JSON.parse(text.reminder.description);
        const dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("h:mm A")];
        const dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("h:mm A")];
        const UnpublishDate = new Date(dateEnd).getFullYear()
        const status = text.status
        this.setState({ status: status })
        const statusChangeStart = new Date(dateStart) <= new Date();
        const statusChangeEnd = new Date(dateEnd) <= new Date();
        return (
          [
            { type: "id", value: `${index <= 8 ? '0' + (index + 1) : index + 1}`, _id: text._id, remindId: text.reminder._id },
            { type: "string", name: "name", value: text.reminder.title, },
            { type: "string", name: "frequency", value: description[0].children[0].text.slice(0, 55) + "...", },
            {
              type: "string",
              name: "vertical",
              value: dateStart,
            },
            {
              type: "string",
              name: UnpublishDate === 2099 ? "frequency" : "vertical",
              value: UnpublishDate === 2099 ? "Never" : dateEnd
            },
            {
              type: "status",
              status: status === 'Hidden' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'pending' : 'schedule'
                : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',
              value: status === 'Hidden' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Unpublished' : 'Scheduled'
                : statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled'
            },
          ]
        )
      })
      this.setState({ remindersList: dataAll })
    }
    return (
      <MasterLayoutMiddle
        content={
          Loading ?
            <div
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </div>
            :
            <>
              {
                this.props.alert.alertValue &&
                <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                  <AlertAction
                    alert={this.props.alert.alertValue}
                    alertType={"success"}
                    onClick={() => this.props.alertHide()}
                  />
                </div>
              }
              {
                error && error === "Invalid token" && (
                  <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                    <AlertAction
                      alert={token_expired}
                      alertType={"error"}
                      onClick={() => this.setState({ error: false })}
                    />
                  </div>
                )
              }
              {
                remindersList.length > 0 ?
                  <>
                    <SimpleTable
                      showHeader={true}
                      title="Reminders"
                      parentTableDrag={true}
                      DragIcon={Handle}
                      rows={remindersList.length ? remindersList : []}
                      columns={col}
                      colSpan={16}
                      actionSendButton={true}
                      classProps={changeClassProps()}
                      donationsCount={remindersList?.length > 0 ? 1 : 0}
                      noTitle="No Reminders added"
                      noPara="Added Reminders will be displayed and managed here"
                      noBtnText="Add New Reminders"
                      onInviteClick={() =>
                        this.props.history.push(
                          "/website/add-reminder"
                        )
                      }
                      showIconCol={true}
                      editIcon={Edit}
                      deleteIcon={Delete}
                      editAction={(e) =>
                        this.props.history.push(`/website/edit-reminder/${e[0]._id}`)
                      }
                      deleteAction={(e) => this.setState({ openAlert: true, specificRow: e })}
                      showFooter={true}
                      showAdd={true}
                      addText="ADD"
                      onAddClick={() =>
                        this.props.history.push(
                          "/website/add-reminder"
                        )
                      }
                      iconTextSize={16}
                      rowsPerPage={5}
                      sortData={(e) => sortData(e)}
                      data={this.state.data}
                      sortColumnIndex={[4]}
                    />
                    <div style={{ display: "flex", marginTop: 0 }}>
                      <p className="cat-lower" style={{ marginTop: 0 }}>
                        Change the order of rows to display to users. Click on{" "}
                      </p>
                      <img src={Handle} alt="" style={{ display: "flex", marginTop: -10 }} />
                      <p className="cat-lower" style={{ marginTop: 0 }}>to drag and drop within the table.</p>
                    </div>
                  </>
                  :
                  <EmptyTable
                    title="Reminders"
                    noTitle="No reminders added"
                    noPara="Added reminders will be displayed and managed here"
                    onAddClick={() => this.props.history.push("/website/add-reminder")}
                    addLabel="ADD"
                    noBtnText="ADD"
                  />
              }
              <div>
                <AlertDialogue
                  title="Delete Reminder?"
                  description="Are you sure you want to delete this Reminder?"
                  description2="Deleting will permanently remove from Website."
                  cancelText="Cancel"
                  confirmText="Confirm"
                  open={openAlert}
                  handleCancel={() => this.handleCancel()}
                  handleClose={() => this.handleClose()}
                  handleConfirm={() => this.deleteServiceAction(specificRow)}
                  styles={styleAlert}
                />
              </div>
            </>
        }
        isFooter={true}
        footer={
          <FooterInner
            style={style}
            termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
            privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
      />
    )
  }
}
const mapStateToProps = (state) => ({
  alert: state.alert,
});
const mapDispatchToProps = (dispatch) => ({
  editReminderDisp: (res) => dispatch(editReminder(res)),
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Reminders);