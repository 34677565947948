import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import {
  MasterLayoutMiddle, FooterInner, BaseTextField,
  BaseButton,
  DateRange 
} from "@dclab/dc-react-ui-kit";

import "../style.css";



export default function EditDonation(props) {
  const style = {
    width: '100%',
  };

  const [isOpen, setOpen] = React.useState(false);
const [states, setstates] = React.useState(false);
const [temp, settemp] = React.useState('')
const [value, setvalue] = React.useState('No specific date')

  const onSelect = (value, states) => {
    settemp(value);
    setstates(states);
  };
  
  const onToggle = () => {
    setOpen(!isOpen);
  };
  
  const clear = () => {
     setOpen(!isOpen);
     setvalue('No Specific Date')
     settemp('')
  
  };
  
  const cancel = () => {
    settemp('')
    setOpen(!isOpen);
  
  }
  const save = () => {
    setvalue(temp)
    setOpen(!isOpen);
  }
  


  return (
    <MasterLayoutMiddle
      header={
        <>


          <div
            onClick={() => this.props.history.replace("/deenfund/categories")}
            className="backBtn"
          >
            <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
            <p style={{ alignSelf: "center" }}>Back</p>{" "}
          </div>
        </>
      }
      isForm={true}
      formData={
        <div>
          <div>
            <h1 className="title_top">Edit Recurring Donation</h1>
            <div className="dasheddBorder"></div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <div className="field_box amountField">
                <span className="amountIcon">$</span>
                <BaseTextField
                  label="Amount"
                  value="50"
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="field_box">
                <DateRange
                  format="MMMM DD, yyyy"
                  title="Charge Date"
                  onToggle={onToggle}
                  onSelect={onSelect}
                  setOpen={setOpen}
                  value={temp ? temp : value}
                  isOpen={isOpen}
                  clear={clear}
                  cancel={cancel}
                  save={save}

                />
              </div>
            </Grid>



            <Grid item xs={12}>
              <div className="form_button">
                <p
                  className="cancel"
                  onClick={() =>
                    this.props.history.replace("/deenfund/categories")
                  }
                >
                  Discontinue Donation
                </p>
                <BaseButton
                  text="SAVE"
                  onClick={() => this.editCategoryHandler()}
                />
              </div>
            </Grid>

          </Grid>
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />

  )
}
