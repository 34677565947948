import React, { useEffect } from "react";
import Moment from "moment";
import MomentTime from "moment-timezone";
import {
  BaseTextField,
  BaseButton,
  BaseImgChip,
  BaseStepperExtended,
  BaseSelect,
  TextEditor,
  FormPublishUnpublish,
  BaseStepper,
  MasterLayoutMiddle,
  FooterInner,
  DrawerMain,
  BaseFileSelect,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import MediaUpload from '../../components/MediaUpload/MediaUpload';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Grid from "@material-ui/core/Grid";
import { useState } from "react";
import "./style.css";
import AddCircle from "../../assets/nav_icons/add_circle.svg";
import { Avatar, CircularProgress, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import { EditNewsAndArticlesWebsite, GetNewsAndArticlesWebsite, getNewsWithAppId, updateNewsWithAppId } from "./api";
import { connect } from "react-redux";
import { showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
const { token_expired, success_message_edit_news } = messages_content;


function EditNewsAndArticles(props) {
  const [activeStep, setActiveStep] = React.useState(1);
  const [openLogoDrawer, setOpenLogoDrawer] = React.useState(false);
  const [logo, setLogo] = React.useState([]);
  const [openLogoDrawer2, setOpenLogoDrawer2] = React.useState(false);
  const [logo2, setLogo2] = React.useState([]);
  const [Loader, setLoader] = React.useState(true)
  const [Title, setTitle] = React.useState('');
  const [Auther, setAuther] = React.useState('');
  const [Category, setCategory] = React.useState('');
  const [visibility, setVisibility] = React.useState("Public");
  const [publish, setPublish] = React.useState("Immediately");
  const [unPublish, setUnPublish] = React.useState("Never");
  const [publishDate, setPublishDate] = React.useState("Immediately");
  const [publishTime, setPublishTime] = React.useState("");
  const [unPublishDate, setUnPublishDate] = React.useState("Never");
  const [unPublishTime, setUnPublishTime] = React.useState("");
  const [unPublishTimestamp, setUnPublishTimestamp] = React.useState(0);
  const [UTCDate, setUTCDate] = React.useState('');
  const [UTCTime, setUTCTime] = React.useState('');
  const [UTCDateUnPublish, setUTCDateUnPublish] = React.useState('');
  const [UTCTimeUnPublish, setUTCTimeUnPublish] = React.useState('');
  const [publishedDateAndTime, setPublishedDateAndTime] = React.useState('');
  const [unPublishedDateAndTime, setUnPublishedDateAndTime] = React.useState('');
  const [validUnPublish, setValidUnPublish] = React.useState(true);
  const [validPublish, setValidPublish] = React.useState(true);
  const [errTitle, setErrTitle] = useState(false);
  const [errAuthor, setErrAuthor] = useState(false);
  const [errCategory, setErrCategory] = useState(false);
  const [featured, setFeatured] = React.useState(false);
  const [error, seterror] = useState(false);
  const [selectNews,setSelectNews] = useState([])
  const [errLogo, setErrLogo] = useState(false);
  // const [errLogo2, setErrLogo2] = useState(false);
  // const [addParagraph, setaddParagraph] = useState(false)
  // const [additionalImage, setadditionalImage] = useState(false)
  // const [additionalVideo, setadditionalVideo] = useState("")
  // const [addVideo, setAddVideo] = useState(false)

    // ######### ADDITIONAL CONTENT SECTION ########
    const [additionalContent, setadditionalContent] = useState([])

    const addText = () => {
      const data = {
        type: "text",
        value : JSON.stringify([{ type: "paragraph", children: [{ text: "" }] }]),
      }
      const tempArray = [...additionalContent];
      tempArray.push(data);
      setadditionalContent(tempArray);
    }
  
    const addImage = () => {
      const data = {
        type: "image",
        value : {},
      }
      const tempArray = [...additionalContent];
      tempArray.push(data);
      setadditionalContent(tempArray);
    }
  
    const addVideo = () => {
      const data = {
        type: "video",
        value : '',
      }
      const tempArray = [...additionalContent];
      tempArray.push(data);
      setadditionalContent(tempArray);
    }
    
    const deleteAdditionalContent = (type, index) => {
      let tempArray = [...additionalContent];
      tempArray.splice(index, 1);
      setadditionalContent(tempArray);
    }
  
    const contentChangeHandler = (index, value) => {
      //   console.log("text",index, type, value)
        setadditionalContent((prevState) => ([
              ...prevState.slice(0, index),
              {
                  ...prevState[index],
                  value: value
              },
              ...prevState.slice(index + 1)
          ]
        ));
    }
    
    // console.log("additionalContent", additionalContent)
    // ######### ADDITIONAL CONTENT SECTION END ########
  
  
  useEffect(() => {
    setLoader(true);
    getSpecificNews()
  }, []);


  async function getSpecificNews() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const resNews = await getNewsWithAppId(token, orgId, 'deenweb')
      const data = resNews.data.body.data;
      //console.log("Image data: ", data);
      const filterData = data.filter(text => text._id === props.match.params.id)
      setSelectNews(filterData)
      setPublishDate(
        `${Moment(filterData[0].publishFrom).format("ddd")}, ${Moment(filterData[0].publishFrom).format("MMM D, YYYY")}`
      );
      setUTCDate(Moment(filterData[0].publishFrom).format('YYYY-MM-DD'))
      setUTCTime(Moment(filterData[0].publishFrom).format("HH:mm:ss"));
      setPublishTime(Moment(filterData[0].publishFrom).format("h:mm A"));
      setUnPublishTimestamp(filterData[0].publishTo);
      const year = new Date(filterData[0].publishTo).getFullYear()
      setUnPublishDate(year === 2099 ? "Never" :
        `${Moment(filterData[0].publishTo).format("ddd")}, ${Moment(filterData[0].publishTo).format("MMM D, YYYY")}`
      );
      setUTCDateUnPublish(Moment(filterData[0].publishTo).format('YYYY-MM-DD'))
      setUnPublishTime(Moment(filterData[0].publishTo).format("h:mm A"));
      setUTCTimeUnPublish(Moment(filterData[0].publishTo).format("HH:mm:ss"));
      let visibility = Moment(filterData[0].publishTo) < Moment() ? 'Hidden' : filterData[0].status;
      setVisibility(visibility);
      setTitle(filterData[0].article.title);
      setAuther(filterData[0].article.author);
      setCategory(filterData[0].article.category);
      setFeatured(filterData[0].featured)
      setLogo([
        {
          label: filterData[0].article.imageMetaData ? filterData[0].article.imageMetaData.imageName : '',
          name: filterData[0].article.imageMetaData ? filterData[0].article.imageMetaData.imageName : '',
          src: filterData[0].article.imageMetaData ? filterData[0].article.imageMetaData.imageUrl : '',
          size: filterData[0].article.imageMetaData ? filterData[0].article.imageMetaData.imageSize : '',
        },
      ])
      // if(filterData[0]?.article?.additionalImageMetaData?.imageUrl) {
      //   setLogo2([
      //     {
      //       label: filterData[0].article.additionalImageMetaData.imageName,
      //       name: filterData[0].article.additionalImageMetaData.imageName,
      //       src: filterData[0].article.additionalImageMetaData.imageUrl,
      //       size: filterData[0].article.additionalImageMetaData.imageSize,
      //     },
      //   ])
      //   setadditionalImage(true)
      // }
      setValue(JSON.parse(filterData[0].article.description))
      filterData[0]?.article?.additionalDescription && setValue2(JSON.parse(filterData[0].article.additionalDescription))
      // filterData[0]?.article?.additionalDescription && setaddParagraph(true)
      // if(filterData[0]?.article?.additionalVideo) {
      //   setadditionalVideo(filterData[0].article.additionalVideo)
      //   setAddVideo(true)
      // }
      if(filterData[0]?.article?.additionalContent?.length) {
        setadditionalContent(filterData[0].article.additionalContent)
      }
      setLoader(false)
    } catch (err) {
      setLoader(false)
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  function addLogo(obj) {
      setLogo([
        {
          label: obj.title,
          name: obj.title,
          src: obj.src,
          size: 0,
        },
      ]);
  };
  function addLogo2(obj) {
      setLogo2([
        {
          label: obj.title,
          name: obj.title,
          src: obj.src,
          size: 0,
        },
      ])
      
  };
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  function handleLogoRemove2(sentIndex) {
    setLogo2([]);
  };
  function logoUpload2(files) {
    if (files.length > 0) {
      setLogo2(files);
    }
  };
  function handleLogoRemove() {
    setLogo([]);
  };
  function logoUpload(files) {
    if (files.length > 0) {
      setLogo(files);
    }
  };
  const getPublishDate = (value) => {
    if(value === "Immediately") {
      setUTCDate('');
      setPublishedDateAndTime('');
      setPublish("Immediately");
      setPublishDate("Immediately")
    } else {
      setValidPublish(false);
      setPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDate(Moment(value).format('YYYY-MM-DD'));
    }
  };
  const getPublishTime = (value) => {
    setPublishTime(Moment(value).format("h:mm A"));
    setUTCTime(Moment(value).format("HH:mm:ss"));
  };
  const getUnPublishDate = (value) => {
    console.log("adsad: ", value)
    if(value == "Never") {
      setUTCDateUnPublish('');
      setUnPublishedDateAndTime('')
      setUnPublish('Never');
      setUnPublishDate('Never');
    } else {
      setValidUnPublish(false);
      setUnPublishTimestamp(value);
      setUnPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDateUnPublish(Moment(value).format('YYYY-MM-DD'));
    }
  };
  const getUnPublishTime = (value) => {
    setUnPublishTime(Moment(value).format("h:mm A"));
    setUTCTimeUnPublish(Moment(value).format("HH:mm:ss"));
  };
  const onVisibilitySave = () => {
    console.log("visibility saved");
  };
  const onVisibilityCancel = () => {
    console.log("visibility cancelled");
  };
  const onPublishSave = () => {
    let Time = publishTime.split(' ');
    let dated = MomentTime(new Date(publishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = `${dated + 'T' + Hours}:00.00Z`
    setPublishedDateAndTime(date);
  };
  const onPublishCancel = () => {
    console.log("publish date cancel");
  };
  const onUnPublishSave = () => {
    let Time = unPublishTime.split(' ');
    let dated = MomentTime(new Date(unPublishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = `${dated + 'T' + Hours}:00.00Z`
    setUnPublishedDateAndTime(date);
  };
  const onUnPublishCancel = () => {
    console.log("unpublish date cancel");
  };
  const convertUTC = (date) => {
    return date.toISOString()
  }
  const onSave = async () => {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    const data = UTCDate === '' ? new Date() : new Date(`${UTCDate + ' ' + UTCTime}`)
    const dataU = convertUTC(data);
    const dataE = UTCDateUnPublish === '' ? new Date() : new Date(`${UTCDateUnPublish + ' ' + UTCTimeUnPublish}`)
    const UnpublishTime = convertUTC(dataE);
    let obj;
    // console.log("Updated Logo is: ", logo);
    // console.log("Upodated Logo2: ", logo2);
    if(logo.length > 0 /* && logo[0].src?.slice(0, 5) !== 'https' */){
        obj = {
          title: Title,
          imageMetaData: {
            imageUrl: logo[0].src,
            imageThumbnail: logo[0].src,
            imageName: logo[0].name,
            imageSize: logo[0].size
          },
          // additionalImageMetaData: logo2?.length ? {
          //   imageUrl: logo2[0].src,
          //   imageThumbnail: logo2[0].src,
          //   imageName: logo2[0].name,
          //   imageSize: logo2[0].size
          // } : {},
          category: Category,
          author: Auther,
          description: JSON.stringify(value),
          // additionalDescription: JSON.stringify(value2),
          // additionalVideo: additionalVideo,
          additionalContent: additionalContent,
        }
      }else{
        obj = {
          title: Title,
          category: Category,
          author: Auther,
          description: JSON.stringify(value),
          // additionalImageMetaData: logo2?.length ? {
          //   imageUrl: logo2[0].src,
          //   imageThumbnail: logo2[0].src,
          //   imageName: logo2[0].name,
          //   imageSize: logo2[0].size
          // } : {},
          // additionalDescription: JSON.stringify(value2),
          additionalContent: additionalContent,
        }
      }
    const objData = {
      article: selectNews[0].article._id,
      publishFrom: publishDate === 'Immediately' ? publish : dataU,  // DATE OBJECT
      publishTo: UTCDateUnPublish === "" ? unPublish : UnpublishTime,
      status: visibility,
      featured: featured
    }
    // if(!addParagraph) {
    //   delete obj.additionalDescription;
    // }
    // if(!additionalImage || !logo2?.length) {
    //   delete obj.additionalImageMetaData;
    // }
    console.log(obj, "obj")
    try {
      await EditNewsAndArticlesWebsite(obj, token, selectNews[0].article._id);
      await updateNewsWithAppId(token, orgId, 'deenweb', selectNews[0].article._id, objData )
      props.alertShow(success_message_edit_news)
      props.history.push("/website/news-articles");
    } catch (err) {
      console.log("Error is: ", err);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  };
  const onBack = () => {
    setActiveStep(0);
  };
  const getSteps = () => {
    return [
      { number: 1, label: "News Details" },
      { number: 2, label: "Publish" },
    ];
  }
  const handleNext = () => {
    if (Title === '' && Auther === '' && Category === '' && logo.length === 0) {
      setErrTitle(true)
      setErrAuthor(true)
      setErrCategory(true)
      setErrLogo(true)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleChange = (prop) => (event) => {
    if (prop === 'title') {
      setTitle(event.target.value);
    } else if (prop === 'auther') {
      setAuther(event.target.value);
    } else if (prop === 'category') {
      setCategory(event.target.value);
    }
  };
  const object = [
    {
      value: "News",
      label: "News",
    },
    {
      value: "Article",
      label: "Article",
    },
  ];
  const [value, setValue] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);
  const [value2, setValue2] = useState([ { type: "paragraph", children: [{ text: "" }] }]);

  const onChange = (e) => {
    setValue(e);
  };
  const onInputClick = () => {
    console.log("input clicked");
  };
  const onChipInputClick = () => {
    console.log("input chip clicked");
  };
  const deleteChip = () => {
    setLogo([])
  };
  const deleteChip2 = () => {
    setLogo2([])
  };
  const styles = {
    stepper: {
      marginTop: 0,
      padding: "0px 0px",
      borderRadius: "30px",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A",
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    select: {
      margin: "0px 0",
      minWidth: 120,
      height: 66,
      width: 120,
      border: "#D3D8DA",
      width: "100%",
    },
    BaseTextField: {
      width: "100%",
    },
    formBody: {
      background: "#fff",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A",
    },
    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
    },
    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
  };
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
        </>
      }
      isForm={true}
      formData={
        Loader ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <>
            <div>
              <BaseStepperExtended
                title="Edit Reads/Views"
                getSteps={getSteps}
                alternativeLabel={true}
                handleBack={() => handleBack()}
                handleNext={() => handleNext()}
                handleBackPreviousPage={() => props.history.push('/website/news-articles')}
                nonLinear={true}
                orientation={"horizontal"}
                btnStyles={styles.stepper}
                onStepClick = {(obj) => setActiveStep(obj - 1)}
                activeStep={activeStep}
              />
              {activeStep === 0 &&
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Basic Information</h2>
                  </Grid>
                  <Grid item md={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={Title}
                        onChange={handleChange("title")}
                        InputProps={true}
                        count={100}
                        color="primary"
                        disabled={false}
                        label="Main Title"
                        className="arrow_hide"
                        error={errTitle}
                      />
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={Auther}
                        onChange={handleChange("auther")}
                        InputProps={true}
                        phone={false}
                        disabled={false}
                        search={false}
                        color="primary"
                        helperText="Add an author name to display on the website"
                        label="Author"
                        className="arrow_hide"
                        error={errAuthor}
                      />
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <div className="field_box">
                      <BaseSelect
                        value={Category}
                        onChange={handleChange("category")}
                        object={object}
                        style={styles.select}
                        label="Category"
                        error={errCategory}
                      />
                    </div>
                  </Grid>
                  <Grid item md={12}>
                    <MediaUpload
                      addMediaItem = {addLogo}
                      label = "Add Image"
                      buttonName = "ADD IMAGE"
                      typeOfMedia='/images'
                      selectedMedia={{ logoUrl: logo[0]?.src ?? "", logoName: logo[0]?.name ?? "" }}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <TextEditor
                      onChange={(e) => onChange(e)}
                      value={value}
                      style={styles.editor}
                      label={"Description"}
                      maxCharCount={1000}
                    />
                  </Grid>

                  {additionalContent.map((content, i) => {
                return (<>
                      {content.type === "text" && <Grid item md={12}>
                        <div style={{  position: "relative", }}>
                  <TextEditor
                    onChange={(e) => contentChangeHandler(i, JSON.stringify(e))}
                    value={JSON.parse(additionalContent[i].value)}
                    style={{}}
                    error={true}
                    label={"Description"}
                    maxCharCount={1000}
                  />
                  <DeleteForeverIcon
                    fontSize="24px"
                    htmlColor="#818E94"
                    onClick={() => deleteAdditionalContent(content.type, i)}
                    style={{ position: "absolute", right: "-31px", top: "90px", cursor: "pointer", }}

                  />
                </div>
              </Grid>}

              {content.type === "image" && <Grid item md={12}>
                <div className="field_box">
                  <MediaUpload
                    addMediaItem={(obj) => contentChangeHandler(i, { imageName: obj?.title, imageUrl: obj?.src, imageSize: 0 })}
                    label="Add Image"
                    buttonName="ADD IMAGE"
                    typeOfMedia='/images'
                    selectedMedia={{ logoUrl: content?.value?.imageUrl ?? "", logoName: content?.value?.imageName ?? "" }}
                  />
                  <DeleteForeverIcon
                    fontSize="24px"
                    htmlColor="#818E94"
                    onClick={() => deleteAdditionalContent(content.type, i)}
                    style={{ position: "absolute", right: "-31px", top: "39px", cursor: "pointer", }}
                  />
                </div>
              </Grid>}

              {content.type === "video" && <Grid item md={12}>
                {console.log("content: ", content)}
                <div style={{ width: "100%", position: "relative", }}>
                  <div className="field_box">
                    <MediaUpload
                      addMediaItem={(obj) => contentChangeHandler(i, obj.src)}
                      label="Add Video"
                      buttonName="ADD VIDEO"
                      typeOfMedia='/videos'
                      showMediaTab={"video"}
                      selectedMedia={{ logoUrl: (content?.value?.split("/embed/").length === 2) ? `https://img.youtube.com/vi/${content?.value?.split("/embed/")?.[1]}/sddefault.jpg` : "", logoName: (content?.value?.split("/embed/").length === 2) ? content.value : ""}}
                    />

                    <DeleteForeverIcon
                      fontSize="24px" htmlColor="#818E94"
                      onClick={() => deleteAdditionalContent(content.type, i)}
                      style={{ position: "absolute", right: "-31px", top: "39px", cursor: "pointer", }}
                    />
                  </div>
                </div>

              </Grid>}
                  </>)
              })}


              {/* {addParagraph && <Grid item md={12}>
                  <div style={{display: "flex", alignItems: "center", width: "105%"}}>
                    <div style={{width: "100%"}}>
                    <TextEditor
                    onChange={(e) => setValue2(e)}
                    value={value2}
                    style={{}}
                    error={true}
                    label={"Description"}
                    maxCharCount={1000}
                  /></div>
                  <DeleteForeverIcon fontSize="24px" htmlColor="#4D4D4D" onClick={() => setaddParagraph(false)} />
                  </div>
              </Grid>}

              {additionalImage && <Grid item md={12}>
                    <div style={{display: "flex", alignItems: "center", width: "105%"}}>
                    <div style={{width: "100%"}}>
                    <div className="field_box">
                      <MediaUpload
                        addMediaItem = {addLogo2}
                        label = "Add Image"
                        buttonName = "ADD IMAGE"
                        typeOfMedia='/images'
                        selectedMedia={{ logoUrl: logo2[0]?.src ?? "", logoName: logo2[0]?.name ?? "" }}
                      />
                        </div>
                      </div>
                      </div>
                   <DeleteForeverIcon fontSize="24px" htmlColor="#4D4D4D" onClick={() => setadditionalImage(false)} />
                 </Grid>}

              {addVideo && <Grid item md={12}>
                <div style={{display: "flex", alignItems: "center", width: "105%"}}>
                <div style={{width: "100%"}}>
                <div className="field_box">
                  <MediaUpload
                    videoData = { (obj) => setadditionalVideo(obj.title)}
                    label = "Add Video"
                    buttonName = "ADD VIDEO"
                    typeOfMedia='/videos'
                    showMediaTab={"video"}
                  />

                  </div>
                  </div>
                  </div>
                  <DeleteForeverIcon fontSize="24px" htmlColor="#4D4D4D" onClick={() => setAddVideo(false)} />
              </Grid>} */}

                <Grid item md={12} style={{ marginTop: 12 }}>
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", marginRight: 40, cursor:"pointer" }} onClick={() => addText()}>
                      <img src={AddCircle} alt="" style={{ marginRight: 8 }} />{" "}
                      <p style={{ color: "#2A82F6", fontSize: 14 }}>ADD TEXT</p>
                    </div>
                    <div style={{ display: "flex", marginRight: 40, cursor:"pointer" }} onClick={() => addImage()}>
                      <img src={AddCircle} alt="" style={{ marginRight: 8 }} />{" "}
                      <p style={{ color: "#2A82F6", fontSize: 14 }}>ADD IMAGE</p>
                    </div>
                    <div style={{ display: "flex", marginRight: 40, cursor:"pointer" }} onClick={() => addVideo()}>
                      <img src={AddCircle} alt="" style={{ marginRight: 8 }} />{" "}
                      <p style={{ color: "#2A82F6", fontSize: 14 }}>ADD VIDEO</p>
                    </div>
                  </div>
                </Grid>
                  <Grid item md={12}>
                    <div className="form_button">
                      <span className="btnCencel">
                        <BaseButton text="CANCEL" onClick={() => {
                          props.history.push('/website/news-articles')
                        }} />
                      </span>
                      <BaseButton text="CONTINUE" onClick={() => handleNext()} />
                    </div>
                  </Grid>
                </Grid>
              }
              {activeStep === 1 && (
                <Grid item md={12} xl={12}>
                  <div className="field_box">
                    <FormPublishUnpublish
                      visibility={visibility}
                      setVisibility={setVisibility}
                      publish={publish}
                      unPublish={unPublish}
                      publishDate={publishDate}
                      publishTime={publishTime}
                      setPublishDate={setPublishDate}
                      setPublishTime={setPublishTime}
                      getPublishDate={getPublishDate}
                      getPublishTime={getPublishTime}
                      unPublishDate={unPublishDate}
                      unPublishTime={unPublishTime}
                      setUnPublishDate={setUnPublishDate}
                      setUnPublishTime={setUnPublishTime}
                      getUnPublishDate={getUnPublishDate}
                      getUnPublishTime={getUnPublishTime}
                      onVisibilitySave={onVisibilitySave}
                      onVisibilityCancel={onVisibilityCancel}
                      onPublishSave={onPublishSave}
                      onPublishCancel={onPublishCancel}
                      onUnPublishSave={onUnPublishSave}
                      onUnPublishCancel={onUnPublishCancel}
                      onSave={onSave}
                      onBack={onBack}
                      unPublishTimestamp={unPublishTimestamp}
                      minDateUnpublish={publishDate}
                      disabledSave={validUnPublish}
                      disabledSaveP={validPublish}
                      showFeatured={featured ? featured : props?.location?.state?.canBeFeatured}
                      featuredText="Feature this on homepage"
                      checked={featured}
                      onSetChecked={() => setFeatured(!featured)}
                    />
                  </div>
                </Grid>
              )}
              <DrawerMain
                open={openLogoDrawer}
                openHandler={() => setOpenLogoDrawer(false)
                }
                title="Select Image"
                onCancel={() => setOpenLogoDrawer(false)
                }
                onSave={() => addLogo()}
              >
                <List className="img-details" style={{ paddingLeft: 0 }}>
                  {logo.map((file, index) => (
                    <ListItem key={file.name} style={{ paddingLeft: 0 }}>
                      <ListItemAvatar>
                        <Avatar
                          variant="square"
                          alt={"preview"}
                          src={
                            logo.length > 0
                              ? logo[0].preview || logo[0].src
                              : file.preview
                          }
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          (file.name && file.name.slice(0, 20)) ||
                          file.src.slice(0, 20)
                        }
                        secondary={file.size && formatBytes(file.size)}
                      />
                      <ListItemSecondaryAction style={{ paddingLeft: 20 }}>
                        <IconButton
                          aria-label="delete"
                          size="medium"
                          style={{ padding: "0px" }}
                          onClick={() => handleLogoRemove(index)}
                        >
                          <DeleteForeverIcon fontSize="inherit" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
                {logo.length === 0 && (
                  <>
                    <div style={{ marginBottom: 20 }} className="img-uploader">
                      <BaseFileSelect
                        selectedFiles={(file) => logoUpload(file)}
                      />
                    </div>
                  </>
                )}
                {logo.length > 0 && logo[0].src.slice(0, 5) !== "https" && (
                  <img src={logo.length > 0 && logo[0].src} width='100%'   alt="logo" />
                )}
                <br />
              </DrawerMain>
              <DrawerMain
            open={openLogoDrawer2}
            openHandler={() => setOpenLogoDrawer2(false)
            }
            title="Select Image"
            onCancel={() => setOpenLogoDrawer2(false)
            }
            onSave={() => addLogo2()}
          >
            <List className="img-details" style={{ paddingLeft: 0 }}>
              {logo2.map((file, index) => (
                <ListItem key={file.name} style={{ paddingLeft: 0 }}>
                  <ListItemAvatar>
                    <Avatar
                      variant="square"
                      alt={"preview"}
                      src={
                        logo2.length > 0
                          ? logo2[0].preview || logo2[0].src
                          : file.preview
                      }
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      (file.name && file.name.slice(0, 20)) ||
                      file.src.slice(0, 20)
                    }
                    secondary={file.size && formatBytes(file.size)}
                  />
                  <ListItemSecondaryAction style={{ paddingLeft: 20 }}>
                    <IconButton
                      aria-label="delete"
                      size="medium"
                      style={{ padding: "0px" }}
                      onClick={() => handleLogoRemove2(index)}
                    >
                      <DeleteForeverIcon fontSize="inherit" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            {logo2.length === 0 && (
              <>
                <div style={{ marginBottom: 20 }} className="img-uploader">
                  <BaseFileSelect
                    selectedFiles={(file) => logoUpload2(file)}
                  />
                </div>
              </>
            )}
            {logo2.length > 0 && logo2[0].src.slice(0, 5) !== "https" && (
              <img src={logo2.length > 0 && logo2[0].src} width='100%' />
            )}
            <br />
          </DrawerMain>
            </div>
          </>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(0, mapDispatchToProps)(EditNewsAndArticles);
