import React, { useRef } from "react";
import {
  BaseTextField,
  PageHeaderWithTabs,
  FormSwitch,
  BaseCheckBox,
  SimpleTable,
  GridItem,
  MasterLayoutMiddle, FooterInner, EmptyTable, AlertDialogue, AlertAction, AlertDialogueAdvance
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Moment from "moment";
import { useEffect, useState } from "react";
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import { deleteEventsWithAppId, getEventsWithAppId } from './api';
import "./style.css";
import { getEvents } from "../Donations/deen-kioks-website/Apis";
import { getOrganizationData } from "../Organization/api";
import { saveAsDraft } from "../../components/DonationForm/Apis";
import { connect } from "react-redux";
import { PagesAction } from "../../redux/actions/pages";
import { CircularProgress } from "@material-ui/core";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
const {
  token_expired,
  quick_access_limit_axeeded,
  Your_changes_has_been_published,
  Your_changes_has_been_saved_as_draft,
  remove_event_message
} = messages_content;
function Events(props) {
  const history = props.history;
  const [Title, setTitle] = React.useState('')
  const [Loader, setLoader] = React.useState(true);
  const [value, setValue] = React.useState(0);
  const [EventsList, setEventsList] = React.useState([]);
  const [specificRow, setSpecificRow] = React.useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState(false);
  const [getNewData, setNewData] = useState(false);
  const [ErrorFound, setErrorFound] = useState(false)
  const unblockHandle = useRef();
  const [changes, setCheckChanges] = useState(false);
  const [saved_route, setRoute] = useState('');
  const [open, setAlertModal] = useState(false);
  const [organizationData, setOrganizationData] = useState([])
  const [QuickLimit, setQuickLimit] = useState(false);
  const [homepageButton, setHomePageButton] = useState(false);
  const [pagesAll, setPagesAll] = useState({})
  const [integrateData, setIntegrateData] = useState({});
  const [dischard_changes, setdischard_changes] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      props.alertHide()
    }, 8000)
  }, [props.alert.alertValue])
  useEffect(() => {
    unblockHandle.current = history.block((route) => {
      setRoute(route.pathname)
      changes && visibleModal()
      return changes === true ? false : true
    });
    return () => {
      unblockHandle.current = false
      !window.location.pathname.includes('events') && localStorage.removeItem('events')
    }
  }, [changes])
  function visibleModal() {
    setAlertModal(true)
  }
  useEffect(() => {
    const page_no = localStorage.getItem('events')
    setValue(page_no ? +page_no : 0);
    async function getorganization() {
      setLoader(true)
      try {
        const token = localStorage.getItem('authToken')
        const orgId = localStorage.getItem('orgID')
        const getOraganization = await getOrganizationData(orgId, token);
        const organizationData = getOraganization?.data?.body?.data?.enabledIntegrations;
        const data = organizationData?.deenweb?.pages;
        const oraganization_data = data?.events;
        setIntegrateData(organizationData);
        setPagesAll(data);
        setOrganizationData(oraganization_data);
        setTitle(oraganization_data.title);
        setHomePageButton(oraganization_data.enablePage)
        setChecked(oraganization_data.quickAccess)
        setNewData(false)
        setLoader(false);
      }
      catch (error) {
        setLoader(false);
        seterror(error.response && error.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    }
    getorganization()
  }, [getNewData])
  const handleChange = (event, newValue) => {
    changes && (Title === '' ? setErrorFound(true) : setAlertModal(true))
    !changes && setValue(newValue);
    !changes && localStorage.setItem('events', newValue)
    setdischard_changes(newValue)
  };
  useEffect(() => {
    GetEvents()
  }, [])
  async function GetEvents() {
    const token = localStorage.getItem('authToken')
    const orgId = localStorage.getItem('orgID')
    try {
      const resEvents = await getEventsWithAppId(token, orgId, 'deenweb')
      const resData = resEvents.data.body.data
      const sort = resData.sort((a, b) => {
        return new Date(b.event.updatedAt) - new Date(a.event.updatedAt)
      })
      let data = [];
      sort.map((text, i) => {
        let dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("h:mm A")];
        let dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("h:mm A")];
        let status = text.status
        let UnpublishDate = new Date(dateEnd).getFullYear()
        let statusChangeStart = new Date(dateStart) <= new Date();
        let statusChangeEnd = new Date(dateEnd) <= new Date();
        let array = [];
        array = [
          { type: "id", value: `${i <= 8 ? '0' + (i + 1) : i + 1}`, _id: text.event._id, objId: text._id },
          {
            type: "url",
            name: "single_pic",
            src: text.event.imageDetails ? text.event.imageDetails.imageUrl : '',
          },
          {
            type: "string",
            name: "name",
            value: text.event.title,
          },
          {
            type: "array",
            name: "multi_images",
            value: text.event.speakers.map(tex => (
              {
                name: "img1",
                type: "url",
                src: tex.imageMetaData ? tex.imageMetaData.imageUrl : '',
                listStyle: {
                  position: "relative",
                  left: -24,
                  zIndex: 100,
                },
              }
            ))
          },
          {
            type: "string",
            name: "vertical",
            value: dateStart,
          },
          {
            type: "string",
            name: UnpublishDate === 2099 ? "frequency" : "vertical",
            value: UnpublishDate === 2099 ? "Never" : dateEnd,
          },
          {
            type: "status",
            status: status === 'Hidden' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'pending' : 'schedule'
              : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',
            value: status === 'Hidden' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Unpublished' : 'Scheduled'
              : statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled'
          },
        ]
        data.push(array)
      }
      )
      setEventsList(data);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const object = [

    {
      label: "Contents",
      item: "Contents",
    },
    {
      label: "Page setup",
      item: "Page setup",
    },

  ];
  const handleCloseDraft = () => {
    setAlertModal(false)
  };
  const discardChanges = () => {
    setCheckChanges(false)
    setAlertModal(false)
    setValue(dischard_changes)
    setChecked(organizationData.quickAccess);
    setHomePageButton(organizationData.enablePage)
    setTitle(organizationData.title)
    if (unblockHandle) {
      unblockHandle.current()
    }
    if (saved_route) {
      props.history.push(saved_route)
    }
  };
  const DeleteAlertFunc = (e) => {
    setSpecificRow(e);
    setOpenAlert(true);
  }
  const checkChanges = (boolean) => {
    setCheckChanges(boolean)
  }
  const handleCancel = () => {
    setOpenAlert(false);
  }
  const handleClose = () => {
    setOpenAlert(false);
  };
  const saveChanges = async () => {
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    if (Title === '') {
      setErrorFound(true)
    } else {
      // const data = {
      //   "addSelectedArrayData": {
      //       "enabledIntegrations.deenweb.pages.events": {
      //         title: Title,
      //         status: homepageButton ? 'active' : 'inactive',
      //         enablePage: homepageButton,
      //         quickAccess: checked,
      //         lastEdited: new Date(),
      //       }
      //     },
      const data = {
        enabledIntegrations: {
          ...integrateData,
          deenweb: {
            ...integrateData.deenweb,
            pages: {
              ...pagesAll,
              events: {
                title: Title,
                status: homepageButton ? 'active' : 'inactive',
                enablePage: homepageButton,
                quickAccess: checked,
                lastEdited: new Date(),
                pageName: 'About Us',
              }
            }
          }
        }
      }
      // }
      try {
        let response = await saveAsDraft(orgId, data, token);
        setshowAlert(true);
        setsuccess(true)
        setTimeout(() => {
          setsuccess(false)
        }, 8000);
        setValue(dischard_changes);
        setAlertModal(false)
        if (saved_route) {
          props.history.push(saved_route)
        }
      } catch (err) {
        setAlertModal(false)
        seterror(err.response && err.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    }
  };

  const publish = async () => {
    let pages = props?.pages?.pages
    let lengthOfPages = pages.filter(text => text.quickAccess === true);
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    if (Title === '') {
      setErrorFound(true)
    } else {
      const data = {
        enabledIntegrations: {
          ...integrateData,
          deenweb: {
            ...integrateData.deenweb,
            pages: {
              ...pagesAll,
              events: {
                title: Title,
                status: homepageButton ? 'active' : 'inactive',
                enablePage: homepageButton,
                quickAccess: checked,
                lastEdited: new Date(),
                pageName: 'About Us',
              }
            }
          }
        }
      }
      if (!checked || organizationData.quickAccess || lengthOfPages.length < 6) {
        try {
          await saveAsDraft(orgId, data, token);
          setshowAlert(true);
          setsuccess('Publish')
          setCheckChanges(false)
          setAlertModal(false)
          setValue(dischard_changes);
          let Quick = pages.filter(text => text.name[0] !== "events")
          Quick.push({
            name: ['events'],
            quickAccess: checked
          })
          props.dispatchPagesAction(Quick)
        } catch (err) {
          seterror(err.response && err.response.data.message)
          setTimeout(() => {
            seterror(false)
          }, 8000);
        }
      } else {
        setQuickLimit(true)
        setTimeout(() => {
          setQuickLimit(false)
        }, 8000)
      }
    }
  };
  const saveStyles = {
    backgroundColor: "#2A82F6",
  };
  const publishStyles = {
    backgroundColor: "#2A82F6",
    opacity: 0.6,
  };
  const paperStyles = {};
  const styles = {
    BaseTextField: {
      width: "100%",
    },
    contents: {
      paddingTop: 20,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "100%",
      marginTop: 70,
      marginLeft: "280px",
    },
  };
  const [checked, setChecked] = useState(true);
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const deleteServiceAction = async (e) => {
    setOpenAlert(false);
    setLoader(true);
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    try {
      await deleteEventsWithAppId(token, orgId, 'deenweb', e[0]._id)
      props.alertShow(remove_event_message)
      const stateNews = EventsList
      const NewsIndex = EventsList.indexOf(e)
      stateNews.splice(NewsIndex, 1)
      setLoader(false);
    } catch (err) {
      setLoader(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
    { type: "link", name: "invoice_number", label: "Image", width: 'auto' },
    { type: "string", name: "name", label: "Event Title", width: '40%' },
    {
      type: "array",
      name: "multi_images",
      label: "Guests",
      width: '20%',
    },
    { type: "string", name: "date/time", label: "Publish Date", width: '15%' },
    {
      type: "string",
      name: "date/time",
      label: "UnPublish Date",
      width: '15%',
    },
    { type: "string", name: "date", label: "STATUS", width: '15%' },
    { type: "action", name: "action", label: "ACTION", width: '10%' },
  ];
  const style = {
    width: 470,
    marginLeft: 800
  };
  const Alertstyle = {
    width: 470,
    marginLeft: '35%'
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          <PageHeaderWithTabs
            back={() => props.history.push('/website/pages')}
            value={value}
            title="Events"
            object={object}
            disabled={changes ? false : true}
            style={{ color: "#2A82F6", border: "1px solid black" }}
            handleChange={(x, y) => handleChange(x, y)}
            indicatorColor="#2A82F6"
            saveStyles={saveStyles}
            publishStyles={publishStyles}
            hiddenDraft={true}
            paperStyles={paperStyles}
            saveChanges={() => saveChanges()}
            publish={() => publish()}
          />
          {
            showAlert && success && success === 'Publish' ? (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={Your_changes_has_been_published}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            ) : showAlert && success && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={Your_changes_has_been_saved_as_draft}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            )
          }
          {
            props.alert.alertValue &&
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
              <AlertAction
                alert={props.alert.alertValue}
                alertType={"success"}
                onClick={() => props.alertHide()}
              />
            </div>
          }
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            QuickLimit && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={quick_access_limit_axeeded}
                  alertType={"error"}
                  onClick={() => setQuickLimit(false)}
                />
              </div>
            )
          }
        </>
      }
      content={
        Loader ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <>

            <div>
              <div>
                <AlertDialogueAdvance
                  title="You have unsaved changes!"
                  description='There are unsaved changes. If you would like to save changes, press the "SAVE AS DRAFT" button.'
                  cancelText="Discard Changes"
                  confirmText="PUBLISH"
                  open={open}
                  handleCancel={() => publish()}
                  handleClose={() => handleCloseDraft()}
                  handleConfirm={() => discardChanges()}
                  styles={Alertstyle}
                />
              </div>
              {value === 1 &&
                <div className="page_body">
                  <Typography className="page_form_title">Page Settings</Typography>
                  <Grid className="form_body">
                    <Grid container item xs={6} spacing={3} >
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={Title}
                            onChange={(e) => {
                              setTitle(e.target.value); checkChanges(true); setErrorFound(false)
                            }}
                            InputProps={false}
                            phone={false}
                            search={false}
                            count={20}
                            style={styles.BaseTextField}
                            color="primary"
                            error={ErrorFound}
                            disabled={false}
                            // helperText="Helper text"
                            label="Page Title"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="page_switch">
                          <FormSwitch
                            style={styles.BaseTextField}
                            headline="Enable Page"
                            body="Disabled pages can’t be accessed by your site visitors."
                            values={homepageButton}
                            handleSwitchChange={() => { setHomePageButton(!homepageButton); checkChanges(true) }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="SolidBorder"></div>
                        <div className="checkBox">
                          <BaseCheckBox
                            label="Quick Access"
                            checked={checked}
                            color="secondary"
                            showHelperText={true}
                            helperText="Add to left navigation under QUICK ACCESS for quickly accessing the page"
                            onChange={(e) => { setChecked(e.target.checked); checkChanges(true) }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              }
              {value === 0 && (
                <GridItem itemClass="grid-item">
                  {
                    EventsList.length > 0 ?
                      <SimpleTable
                        showHeader={true}
                        title="Events"
                        width="98%"
                        rows={EventsList}
                        columns={col}
                        colSpan={16}
                        actionSendButton={true}
                        classProps={changeClassProps()}
                        donationsCount={EventsList?.length > 0 ? 1 : 0}
                        noTitle="No Events added"
                        noPara="Added Events will be displayed and managed here"
                        noBtnText="Add New Events"
                        onInviteClick={() =>
                          props.history.push("/website/add-events")
                        }
                        actionLink={(name) => this.details(name)}
                        showIconCol={true}
                        editIcon={Edit}
                        deleteIcon={Delete}
                        editAction={(e) =>
                          props.history.push(`/website/edit-event/${e[0].objId}`)
                        }
                        deleteAction={(e) => DeleteAlertFunc(e)}
                        showFooter={true}
                        showAdd={true}
                        addText="ADD NEW"
                        rowsPerPage={5}
                        onAddClick={() => props.history.push("/website/add-events")}
                        iconTextSize={16}
                        sortColumnIndex={[4]}
                      />
                      :
                      <EmptyTable
                        title="Events"
                        onAddClick={() => props.history.push("/website/add-events")}
                        addLabel="ADD NEW"
                        noBtnText="ADD NEW"

                      />
                  }
                </GridItem>
              )}
              <div>
                <AlertDialogue
                  title="Delete Events?"
                  description="Are you sure you want to delete this Events?"
                  description2="Deleting will permanently remove from Website."
                  cancelText="Cancel"
                  confirmText="Confirm"
                  open={openAlert}
                  handleCancel={() => handleCancel()}
                  handleClose={() => handleClose()}
                  handleConfirm={() => deleteServiceAction(specificRow)}
                  styles={style}
                />
              </div>
            </div>
          </>
      }
      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
    />
  );
}
const mapStateToProps = (state) => {
  return {
    pages: state.pages,
    alert: state.alert,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatchPagesAction: (data) => dispatch(PagesAction(data)),
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Events);
