import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  SignInComponent,
  AlertAction,
} from '@dclab/dc-react-ui-kit';
import Lock from '../../assets/img/lock-24px.svg';
import Right from '../../assets/img/done-14px.svg';
import Close from '../../assets/img/clear-24px.svg';
import Logo from '../../assets/img/DC_Logo.png';
import { signinHandler } from './apis';
import { organizations } from '../Donations/deen-kioks-website/Apis';
import { signinAction } from '../../redux/actions/signinAction';
import { getOrgCategories } from '../../redux/actions/categoriesAction';
import '../../App.css';
import { hideAlert, showAlert } from '../../redux/actions/alertActions';
import {enrypt} from '../../utils/crypto';
class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      emailError: false,
      passwordError: false,
      error: false,
      loading: false,
      showAlert: false,
      checked: false,
    };
    this.signin = this.signin.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.rememberMe = this.rememberMe.bind(this);
  }
  componentDidMount() {
    const checkUser = localStorage.getItem('rememberMe');
    const token = localStorage.getItem("authToken");
    if (checkUser === 'true' && token) {
      this.props.history.replace('/dashboard');
    }
  }
  handleChange = (name, event) => {
    if (name === 'email') {
      const emailRegex = /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i;
      if (emailRegex.test(event.target.value)) {
        this.setState({ email: event.target.value, emailError: false });
      } else {
        this.setState({ email: event.target.value, emailError: false });
      }
    } else {
      this.setState({ [name]: event.target.value });
    }
  };
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  rememberMe = (e) => {
    const { checked } = this.state;
    this.setState({
      checked: !checked,
    });
  };
  async signin() {
    const { email, password, emailError, checked } = this.state;
    this.setState({
      showAlert: false,
      error: false,
      loading: true,
      emailError: false,
    });
    if (!email || !password) {
      this.setState({
        emailError: true,
        passwordError: true,
        error: true,
        loading: false,
      });
    } else if (emailError) {
      this.setState({
        error: true,
        loading: false,
      });
    } else {
      const userInfo = {
        userName: email,
        password,
      };
      try {
        this.setState({ loading: true });
        // auth Api
        const res = await signinHandler(userInfo);
        console.log("OrgData: ", res)
        if(res?.data?.body?.data?.userConfirmed == false) {
          localStorage.setItem('name', res?.data?.body?.data.name);
          localStorage.setItem('email', res?.data?.body?.data.email);
          this.props.history.push({
            pathname: '/not-verified',
            state: userInfo
          })
        } else {
          const name = res.data.body.data.name.firstName + (res.data.body.data.name.lastName ? ' ' + res.data.body.data.name.lastName : '')
          const token = res?.data?.body?.data?.token;
          const refreshToken = res?.data?.body?.data?.refreshToken;
          const userName = res?.data?.body?.data?.userName;
          const orgId = res?.data?.body?.data?.defaultOrg;
          const userImage = res?.data?.body?.data?.imageDetails?.image ?? "";
          if(res?.data?.body?.data?.access) {
            const hash = enrypt(res.data?.body?.data?.access?.toLowerCase());
            localStorage.setItem("userAccess", hash);
          }
          localStorage.setItem('authToken', token);
          localStorage.setItem('refreshToken', refreshToken);
          localStorage.setItem('orgID', orgId);
          localStorage.setItem('name', name);
          localStorage.setItem('userName', userName);
          localStorage.setItem('image', userImage);
          localStorage.setItem(
            'role',
            res?.data?.body?.data?.role?.[0].roles?.[0]?.toUpperCase() + 
            res?.data?.body?.data?.role?.[0]?.roles?.slice(1)
          );
          if (token && orgId) {
            const orgres = await organizations(orgId, token);
            let orgAddress = orgres?.data?.body?.data?.[0]?.orgAddress;
            let orgName = orgres?.data?.body?.data?.[0]?.orgName;
            let location = orgres?.data?.body?.data?.[0]?.orgAddress?.coordinatesLatLong;
            let short_name = orgres.data.body.data[0].orgShortName;
            localStorage.setItem('orgaddress', JSON.stringify(orgAddress));
            localStorage.setItem('organame', orgName);
            localStorage.setItem('location', JSON.stringify(location));
            localStorage.setItem('short_name', short_name);

            console.log("Salah Settings: ", orgres.data.body.data[0].salahSetting)
            let daytimeSaving = orgres.data.body.data[0].salahSetting && orgres.data.body.data[0].salahSetting.daytimeSaving;
            let waqtMethod = orgres.data.body.data[0].salahSetting && orgres.data.body.data[0].salahSetting.waqtMethod;
            let timezone = orgres.data.body.data[0].salahSetting && orgres.data.body.data[0].salahSetting.timeZone;
            
            console.log("daytimeSaving: ", daytimeSaving)
            localStorage.setItem('daytimeSaving', daytimeSaving);

            if (localStorage.getItem('timezone') === null) {
              localStorage.setItem('timezone', timezone);
              localStorage.setItem(
                'timezonestring',
                'Eastern Standard Time (EST)'
              );
              localStorage.setItem('waqtmethod', waqtMethod);
              localStorage.setItem(
                'waqtMethodString',
                'Muslim World League (MWL)'
              );
            }
          }
          if (checked) {
            localStorage.setItem('rememberMe', true);
          } else {
            localStorage.setItem('rememberMe', false);
          }
          this.props.signin(email, res.data.body.data.token, res.data.body.data.access);
          localStorage.setItem('user', true);
          this.setState({ loading: false });
          if(localStorage.getItem('oldUrl')) {
            const tempUrl = localStorage.getItem('oldUrl');
            window.localStorage.removeItem('oldUrl');
            window.location.replace(tempUrl)
          } else {
            res.data.body.data.defaultOrg
              ? this.props.history.replace('/dashboard')
              : this.props.history.replace('/onboarding');
          }
        }
      } catch (error) {
        console.log(error)
        if (error.response && error.response.data.statusCode === 401) {
          this.props.history.replace('/sign-locked');
        }
        this.setState({
          error:
            error &&
              error.response &&
              error.response.data.message ===
              "Cannot read property 'role' of null"
              ? 'Invalid Email address or password'
              : error && error.response && error.response.data.message,
          loading: false,
          showAlert: true,
        });
        this.hideAlert();
      }
    }
  }
  render() {
    if (this.props.alert.alertValue) {
      setTimeout(() => {
        this.props.alertHide()
      }, 8000);
    }
    const onBlurFunction = () => {
      const { email } = this.state;
      const emailRegex = /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i;
      if (email) {
        if (emailRegex.test(email)) {
          this.setState({ emailError: false });
        } else {
          this.setState({ emailError: true });
        }
      } else {
        this.setState({ error: true })
      }
    }
    this.handleSubmit = (e) => { };
    const {
      email,
      password,
      emailError,
      error,
      showAlert,
      loading,
      checked,
    } = this.state;
    return (
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', width: '100%', zIndex: -1 }}>
          <div
            className='Background-Image'
            style={{ width: '100%', position: 'fixed', height: '100vh' }}
          ></div>
        </div>
        {
          this.props?.alert?.alertValue &&
          <div style={{ 
              zIndex: 99,
              width: '100%',
              position: 'fixed',
              marginTop: 40,
              marginLeft: 200, 
            }}>
            <AlertAction
              alert={this.props.alert.alertValue}
              alertType={"success"}
              onClick={() => this.props.alertHide()}
            />
          </div>
        }
        {showAlert && error && (
          <div
            style={{
              zIndex: 99,
              width: '100%',
              position: 'fixed',
              marginTop: 40,
              marginLeft: 200,
            }}
          >
            <AlertAction
              alert={error}
              alertType={'error'}
              onClick={() => this.setState({ showAlert: false })}
            />
          </div>
        )}
        <div
          style={{ display: 'flex', justifyContent: 'center', paddingTop: 24 }}
          className='signin-container div_backgrndimg-pattern'
        >
          <SignInComponent
            isLoading={loading}
            title='Sign In'
            name='signin'
            src={Logo}
            LogoLink={process.env.REACT_APP_Environment === 'dev' ? "https://shapla.deenconnect.io/" : "https://deenconnect.io/"}
            right={Right}
            close={Close}
            icon={Lock}
            textfieldvalue={email}
            onChange={(e) => this.handleChange('email', e)}
            emailBlur={() => onBlurFunction()}
            required={true}
            id={'useremail'}
            label={'Email Address'}
            placeholder={' '}
            type={'email'}
            error={(error && !email) || emailError}
            helperText={
              (error && !email ? 'Email is required' : '') ||
              (emailError ? 'Invalid Email' : '')
            }
            passwordvalue={password}
            passwordvalue_onChange={(e) => this.handleChange('password', e)}
            passwordvalue_required={true}
            passwordvalue_id={'userpassword'}
            passwordvalue_label={'Password'}
            passwordvalue_error={error && !password}
            passwordvalue_placeholder={' '}
            passwordvalue_helperText={
              error && !password ? 'Password is required' : ' '
            }
            text={'Sign in'}
            signUpLink={() => this.props.history.replace('/signup')}
            forgotpasswordLink={() =>
              this.props.history.replace('/forgot-password')
            }
            onClick={() => this.signin()}
            checked={checked}
            setChecked={this.rememberMe}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  alert: state.alert,
});
const mapDispatchToProps = (dispatch) => {
  return {
    alertHide: (res) => dispatch(hideAlert(res)),
    alertShow: (res) => dispatch(showAlert(res)),
    signin: (email, token, access) => dispatch(signinAction(email, token, access)),
    getCategories: (data) => dispatch(getOrgCategories(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Signin);
