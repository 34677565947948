import React, { Component } from "react";
import {
  SimpleTable,
  TableCell,
  CardData,
  AlertAction,
  Initials,
  GridContainer,
  GridItem,
  MasterLayoutMiddle,
  FooterInner,
} from "@dclab/dc-react-ui-kit";
import { editEvent } from "../../../redux/actions/eventAction";
import Moment from "moment";
import Edit from "../../../assets/img/create-24px.svg";
import Delete from "../../../assets/img/delete_forever-24px.svg";
import { getEvents, deleteEvent } from "./Apis/index";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import image from "../../../../src/assets/img/insert_photo.png";
import { hideAlert } from "../../../redux/actions/alertActions";
import "../../style.css";
import messages_content from '../../../utils/messages.json';
const {
  token_expired,
  success_message_edit_event,
  success_message_event,
  remove_event_message
} = messages_content;
class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      error: "",
      deleteEventId: "",
      modalLoading: "",
      openAlert: "",
      isloading: false,
      success: false
    };
  }

  async componentDidMount() {
    this.setState({ isloading: true });
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const res = await getEvents(orgId, token);
      this.setState({
        events: res.data.body.data.reverse(),
        isloading: false,
      });
    } catch (error) {
      this.setState({
        error: error && error.response && error.response.data.message,
        isloading: false,
      });
    }
    setTimeout(() => {
      this.props.hideAlert();
    }, 8000);
  }
  editEvent = async (e) => {
    this.props.history.push(`/editevent/${e[0].label}`);
  };

  handleClose = () => {
    this.setState({ openAlert: false });
  };

  handleDeleteConfirm = async () => {
    const { events, deleteEventId } = this.state;
    this.setState({ modalLoading: true });
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const deleteResponse = await deleteEvent(deleteEventId, token, orgId);
      if (deleteResponse.status === 200) {
        const newEvents = events.filter((item) => item._id !== deleteEventId);
        this.setState({
          events: newEvents,
          success: true,
          openAlert: false,
          modalLoading: false,
        });
      }
      setTimeout(() => {
        this.setState({ success: false })
      }, 8000);
    } catch (error) {
      this.setState({
        error: error && error.response && error.response.data.message,
        openAlert: false,
        modalLoading: false,
      });
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  };
  deleteEvent = async (e) => {
    this.setState({
      openAlert: true,
      deleteEventId: e.label,
    });
  };
  render() {
    const {
      events,
      error,
      showAlert,
      success,
      isloading,
      openAlert,
      modalLoading,
    } = this.state;
    const { alertValue } = this.props;
    let classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    const headerdata = [
      {
        title: "Total Donations",
        donation: "$2540.76",
        fromLastMonth: 34,
        increased: true,
      },
      {
        title: "Total Organizations",
        donation: "20",
        fromLastMonth: 34,
        increased: true,
      },
      {
        title: "Average Donations",
        donation: "$640",
        fromLastMonth: 34,
        increased: false,
      },
      {
        title: "Average Donations",
        donation: "$640",
        fromLastMonth: 34,
        increased: false,
      },
    ];
    const col = [
      { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
      { type: "url", name: "single_pic", label: "IMAGE", width: 'auto' },
      { type: "string", name: "name", label: "EVENT TITLE", width: '30%' },
      {
        type: "array",
        name: "multi_images",
        label: "GUESTS",
        width: '15%'
      },
      {
        type: "string",
        name: "date/time",
        label: "START DATE/TIME",
        width: '20%'
      },
      {
        type: "string",
        name: "date/time",
        label: "END DATE/TIME",
        width: '20%'
      },
      { type: "string", name: "repeat", label: "REPEAT", width: '20%' },
      { type: "string", name: "status", label: "STATUS", width: '20%' },
      { type: "action", name: "action", label: "ACTION", width: '20%' },
    ];
    let imgIntials;
    let defaultSrc;
    const rows = events.map((item, i) => {
      var re = /^(http|https|ftp)/
      if (item?.imageDetails?.imageUrl && re.test(item.imageDetails.imageUrl)) {
        defaultSrc = "src";
      } else {
        defaultSrc = "defaultImg";
      }
      //set rows on Events
      return [
        { type: "id", value: (i + 1), label: item._id },
        {
          type: "url",
          name: "userPic",
          [defaultSrc]:
            item?.imageDetails?.imageUrl && re.test(item.imageDetails.imageUrl) ? item.imageDetails.imageUrl : image,
        },
        {
          type: "string",
          name: "name",
          value: item.title.slice(0, 70) + "...",
        },
        {
          type: "array",
          name: "multi_images",
          value: item.speakers.map((speaker_item, index) => {
            // console.log(speaker_item, 'speakers')
            if (speaker_item?.imageMetaData?.imageUrl ? false : true) {
              imgIntials = "initials";
            } else {
              imgIntials = "src";
            }
            return {
              name: speaker_item.name.firstName[0],
              type: "url",
              [imgIntials]:
                !speaker_item?.imageMetaData?.imageUrl
                  ? (speaker_item?.name?.firstName?.[0] ?? "") + (speaker_item?.name?.lastName?.[0] ?? "")
                  : speaker_item && speaker_item.imageMetaData && speaker_item.imageMetaData.imageUrl,
              listStyle: {
                position: "relative",
                left: index === 0 ? -24 : -24 * (index + 1),
                zIndex: 100 - index,
              },
            };
          }),
        },
        {
          type: "string",
          name: "vertical",
          value: [
            Moment(item.eventStart.startAt).format("MMM DD, YYYY"),
            Moment(item.eventStart.startAt).format("h:mm A") + (item.eventZone? ` ${item.eventZone}` : ""),
          ],
        },
        {
          type: "string",
          name: "vertical",
          value: [
            Moment(item.endAt).format("MMM DD, YYYY"),
            Moment(item.endAt).format("h:mm A") + (item.eventZone? ` ${item.eventZone}` : ""),
          ],
        },
        {
          type: "string",
          name: "frequency",
          value: item.repeat.category.split(" ")[0],
        },
        {
          type: "status",
          status: item.published === true ? "success" : "failed",
          value: item.published === true ? "Active" : "Inactive",
        },
      ];
    });
    const alertEventModal = (
      <Dialog
        open={openAlert}
        onClose={() => this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Event?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this event? <br/> Deleting will permanently remove from everywhere.
          </DialogContentText>
        </DialogContent>
        {modalLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 50,
            }}
          >
            <CircularProgress
              color="primary"
              style={{ width: 24, height: 24 }}
            />
          </div>
        ) : (
            <DialogActions>
              <Button onClick={() => this.handleClose()} color="primary">
                Cancel
            </Button>
              <Button onClick={() => this.handleDeleteConfirm()} color="primary">
                Confirm
            </Button>
            </DialogActions>
          )}
      </Dialog>
    );
    const style = {
      width: "100%",
    };
    return (
      <MasterLayoutMiddle
        content={
          <>
            {error && error === "Invalid token" && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                }}
              >
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => this.setState({ error: false })}
                />
              </div>
            )}
            {showAlert && error && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                }}
              >
                <AlertAction
                  alert={error}
                  alertType={"error"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {success && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                }}
              >
                <AlertAction
                  alert={remove_event_message}
                  alertType={"success"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {!error && alertValue && alertValue === "addEvent" && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                }}
              >
                <AlertAction
                  alert={success_message_event}
                  alertType={"success"}
                  onClick={() => this.props.hideAlert()}
                />
              </div>
            )}
            {!error && alertValue && alertValue === "editEvent" && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                }}
              >
                <AlertAction
                  alert={success_message_edit_event}
                  alertType={"success"}
                  onClick={() => this.props.hideAlert()}
                />
              </div>
            )}
            {isloading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                <CircularProgress
                  color="primary"
                  style={{ width: 24, height: 24 }}
                />
              </div>
            ) : (
                <>
                  <GridContainer>
                    {/* <GridItem>
                      <CardData data={headerdata} />
                    </GridItem> */}
                    <GridItem
                      itemStyle={{ marginTop: 0 }}
                      itemClass="grid-item ul-class"
                    >
                      <SimpleTable
                        showHeader={true}
                        title="Events"
                        width="98%"
                        rows={rows}
                        columns={col}
                        colSpan={16}
                        actionSendButton={true}
                        classProps={changeClassProps()}
                        donationsCount={rows && rows.length > 0 ? 1 : 0}
                        noTitle="No events added"
                        noPara="Added events will be displayed and managed here"
                        noBtnText="Add New Events"
                        onInviteClick={() => this.props.history.push("/addevent")}
                        actionLink={(name) => this.details(name)}
                        showIconCol={true}
                        editIcon={Edit}
                        deleteIcon={Delete}
                        editAction={(e) => this.editEvent(e)}
                        deleteAction={(e) => this.deleteEvent(e[0])}
                        showFooter={true}
                        showAdd={true}
                        addText="ADD NEW"
                        onAddClick={() => this.props.history.push("/addevent")}
                        iconTextSize={16}
                        sortColumnIndex={[7]}

                      />
                      <div>
                        <p className="service-helper-text">
                          "Active" events are available with publishing date and
                          time from applications
                      </p>
                      </div>
                    </GridItem>
                  </GridContainer>
                  {openAlert && alertEventModal}
                </>
              )}
          </>
        }
        isFooter={true}
        footer={<FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />}
        isWhiteBackground={false}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alertValue: state.alert.alertValue,
  };
};
const mapDispatchToProps = (dispatch) => ({
  editEvent: (res) => dispatch(editEvent(res)),
  hideAlert: (res) => dispatch(hideAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Events);
