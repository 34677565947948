import React, { useEffect } from "react";
import Moment from "moment";
import MomentTime from "moment-timezone";
import {
  BaseTextField,
  BaseButton,
  BaseStepper,
  BaseStepperExtended,
  FormPublishUnpublish,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import { useState } from "react";
import "./style.css";
import { updateVideosWithAppId } from "./api";
import { connect } from "react-redux";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
import { EditVideoWebsite, getVideosWithAppId } from '../../pages/Website/api';
import MediaUpload from "../../components/MediaUpload/MediaUpload";

const { token_expired, publish_date_before_unpublish, success_message_edit_video, maximum_limit_exeed_of_featured_video } = messages_content;

function EditVideo(props) {
  const [limitError, setLimitError] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(1);
  const [name, setName] = React.useState("");
  const [errorName, setErrorName] = React.useState(false);
  const [errorLink, setErrorLink] = React.useState(false);
  const [error, seterror] = useState(false);
  const [featured, setFeatured] = React.useState(false);
  const [link, setLink] = React.useState('');
  const [visibility, setVisibility] = React.useState("Public");
  const [publish, setPublish] = React.useState("Immediately");
  const [unPublish, setUnPublish] = React.useState("Never");
  const [publishDate, setPublishDate] = React.useState("Immediately");
  const [publishTime, setPublishTime] = React.useState("");
  const [unPublishDate, setUnPublishDate] = React.useState("Never");
  const [unPublishTime, setUnPublishTime] = React.useState("");
  const [unPublishTimestamp, setUnPublishTimestamp] = React.useState(0);
  const [UTCDate, setUTCDate] = React.useState('');
  const [UTCTime, setUTCTime] = React.useState('');
  const [UTCDateUnPublish, setUTCDateUnPublish] = React.useState('');
  const [UTCTimeUnPublish, setUTCTimeUnPublish] = React.useState('');
  const [unPublishedDateAndTime, setUnPublishedDateAndTime] = React.useState('');
  const [publishedDateAndTime, setPublishedDateAndTime] = React.useState('');
  const [dateError, setDateError] = React.useState(false);
  const [validUnPublish, setValidUnPublish] = React.useState(true);
  const [videoData,setVideoData] = React.useState([])
  const [validPublish, setValidPublish] = React.useState(true);
  const [featuredVideos, setFeaturedVideos] = React.useState([])

  useEffect(() => {
    console.log("unPublishDate: ", unPublishDate)
    if (Moment(unPublishDate) < Moment()) {
      setVisibility("Hidden");
    }
  }, [unPublishDate]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    const getData = async () => {
      const resVideo = await getVideosWithAppId(token, orgId, 'deenweb')
      const data = resVideo.data.body.data;
      const featuredVideos = data.filter(text => text.featured === true)
      setFeaturedVideos(featuredVideos)
    }
    getData()
  }, [])

  useEffect(() => {
    const paramData = props.location.state.detail;
    const link = paramData[0].video.link;
    const name = paramData[0].video.title;
    setVideoData(paramData)
    const year = new Date(paramData[0].publishTo).getFullYear()
    setPublishDate(
      `${Moment(paramData[0].publishFrom).format("ddd")}, ${Moment(paramData[0].publishFrom).format("MMM D, YYYY")}`
    );
    setPublishTime(Moment(paramData[0].publishFrom).format("h:mm A"));
    setUnPublishDate(year === 2099 ? 'Never' :
      `${Moment(paramData[0].publishTo).format("ddd")}, ${Moment(paramData[0].publishTo).format("MMM D, YYYY")}`
    );
    setUnPublishTime(Moment(paramData[0].publishTo).format("h:mm A"));
    setUTCDate(Moment(paramData[0].publishFrom).format('YYYY-MM-DD'));
    setUTCTime(Moment(paramData[0].publishFrom).format("HH:mm:ss"));
    setUTCDateUnPublish(Moment(paramData[0].publishTo).format('YYYY-MM-DD'));
    setUTCTimeUnPublish(Moment(paramData[0].publishTo).format("HH:mm:ss"));
    setPublishedDateAndTime(paramData[0].publishFrom);
    setUnPublishedDateAndTime(paramData[0].publishTo);
    setVisibility(paramData[0].status)
    setFeatured(paramData[0].featured);
    setLink(link);
    setName(name);
  }, [])
  const getPublishDate = (value) => {
    if(value === "Immediately") {
      setUTCDate('');
      setPublishedDateAndTime('');
      setPublish("Immediately");
      setPublishDate("Immediately")
    } else {
      setValidPublish(false);
      setPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDate(Moment(value).format('YYYY-MM-DD'));
    }
  };
  const getPublishTime = (value) => {
    setPublishTime(Moment(value).format("h:mm A"));
    setUTCTime(Moment(value).format("HH:mm:ss"));
  };

  const getUnPublishDate = (value) => {
    if(value == "Never") {
      setUTCDateUnPublish('');
      setUnPublishedDateAndTime('')
      setUnPublish('Never');
      setUnPublishDate('Never');
    } else {
      setValidUnPublish(false);
      setUnPublishTimestamp(value);
      setUnPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDateUnPublish(Moment(value).format('YYYY-MM-DD'));
    }
  };
  const getUnPublishTime = (value) => {
    setUnPublishTime(Moment(value).format("h:mm A"));
    setUTCTimeUnPublish(Moment(value).format("HH:mm:ss"));
  };
  const onVisibilitySave = () => {
    console.log("visibility saved");
  };
  const onVisibilityCancel = () => {
    console.log("visibility cancelled");
  };
  const onPublishSave = () => {
    let Time = publishTime.split(' ');
    let dated = MomentTime(new Date(publishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = `${dated + 'T' + Hours}:00.00Z`
    setPublishedDateAndTime(date);
  };
  const onPublishCancel = () => {
    console.log("publish date cancel");
  };
  const onUnPublishSave = () => {
    let Time = unPublishTime.split(' ');
    let dated = MomentTime(new Date(unPublishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = `${dated + 'T' + Hours}:00.00Z`
    setUnPublishedDateAndTime(date);
  };

  const onUnPublishCancel = () => {
    console.log("unpublish date cancel");
  };
  const convertUTC = (date) => {
    return date.toISOString()
  }
  const onSave = async () => {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    const data = UTCDate === '' ? new Date() : new Date(`${UTCDate + ' ' + UTCTime}`)
    const dataU = convertUTC(data);
    const dataE = UTCDateUnPublish === '' ? new Date() : new Date(`${UTCDateUnPublish + ' ' + UTCTimeUnPublish}`)
    const UnpublishTime = convertUTC(dataE);
    // const obj = {
    //   title: name,
    //   link: link,
    //   website: {
    //     status: visibility,
    //     featured: featured,
    //     publishFrom: publishDate === 'Immediately' ? publish : dataU,
    //     publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime
    //   },
    //   organizationId: orgId
    // }
    const objData = {
      video: videoData[0].video._id,
      publishFrom: publishDate === 'Immediately' ? publish : dataU,  // DATE OBJECT
      publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime,
      status: visibility,
      featured: featured
    }
    const videoObj = {
      title: name, link: link, orgId: orgId
    }

    console.log(objData, videoObj,  'videoObj')
    try {
      if (featuredVideos.length >= 3 && featured) {
        setLimitError(true)
        setTimeout(() => {
          setLimitError(false)
        }, 8000);
      } else {
        await updateVideosWithAppId(token, orgId, 'deenweb', videoData[0].video._id, objData)
        await EditVideoWebsite(videoObj, token, videoData[0].video._id)
        props.alertShow(success_message_edit_video)
        props.history.push("/website/videos");
      }
    } catch (error) {
      seterror(error.response && error.response.data.message);
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  };
  const onBack = () => {
    setActiveStep(0)
    // props.history.push("/website/videos");
  };
  const handleChange = (prop) => (event) => {
    if (prop === 'name') {
      setName(event.target.value)
    } else if (prop === 'link') {
      setLink(event.target.value)
    }
  };
  const handleNext = () => {
    if (name === '' || link === '') {
      if (name === '') {
        setErrorName(true);
      }
      if (link === '') {
        setErrorLink(true);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    props.history.push("/website/videos");
  };
  const getSteps = () => {
    return [
      { number: 1, label: "Video Details" },
      { number: 2, label: "Publish" },
    ];
  }
  const styles = {
    stepper: {
      marginTop: 0,
      padding: "0px 0px",
      borderRadius: "30px",
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    select: {
      margin: "14px 0",
      minWidth: 120,
      height: 66,
      width: 120,
      border: "#D3D8DA",
      width: "100%",
    },
    BaseTextField: {
      width: "100%",
    },
    editor: {
      padding: 16,
    },
    formBody: {
      background: "#fff",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A",
      fontWeight: '300',
      marginBottom: "26px",
    },
    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
    },
    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
  };
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          {
            limitError && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={maximum_limit_exeed_of_featured_video}
                  alertType={"error"}
                  onClick={() => setLimitError(false)}
                />
              </div>
            )
          }
          {
            error && error === "Invalid token" && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            dateError &&
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
              <AlertAction
                alert={publish_date_before_unpublish}
                alertType={"error"}
                onClick={() => setDateError(false)}
              />
            </div>
          }
        </>
      }
      isForm={true}
      formData={
        <div>
          <BaseStepperExtended
            title="Edit New Video"
            getSteps={getSteps}
            alternativeLabel={true}
            handleBack={() => handleBack()}
            handleNext={() => handleNext()}
            handleBackPreviousPage={() => props.history.push('/website/videos')}
            nonLinear={true}
            orientation={"horizontal"}
            btnStyles={styles.stepper}
            onStepClick = {(obj) => setActiveStep(obj - 1)}
            activeStep={activeStep}
          />
          {activeStep === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h2 className="sub_title">Basic Information</h2>
              </Grid>
              <Grid item md={12} xl={12}>
                <div className="field_box">
                  <BaseTextField
                    value={name}
                    onChange={handleChange("name")}
                    count={100}
                    color="primary"
                    // disabled={true}
                    error={errorName}
                    helperText={errorName ? "required" : ''}
                    label="Video Title"
                  />
                </div>
              </Grid>
              <Grid item md={12} xl={12}>
                <MediaUpload
                  addMediaItem = {(obj) => setLink(obj.src)}
                  label = "Add Video"
                  buttonName = "ADD VIDEO"
                  typeOfMedia='/videos'
                  showMediaTab={"video"}
                  selectedMedia={{ logoUrl: (link?.split("/embed/").length === 2) ? `https://img.youtube.com/vi/${link?.split("/embed/")?.[1]}/sddefault.jpg` : "", logoName: (link?.split("/embed/").length === 2) ? link : ""}}
                />
                {/* <div className="field_box">
                  <BaseTextField
                    value={link}
                    onChange={handleChange("link")}
                    color="primary"
                    // disabled={true}
                    error={errorLink}
                    helperText={errorLink ? "required" : ''}
                    label="Youtube Link"
                  />
                </div> */}
              </Grid>
              <Grid item md={12} xl={12}>
                <div className="form_button">
                  <span className="btnCencel">
                    <BaseButton text="CANCEL" onClick={() => props.history.push('/website/videos')} />
                  </span>
                  <BaseButton text="CONTINUE" onClick={() => handleNext()} />
                </div>
              </Grid>
            </Grid>
          )}
          {activeStep === 1 && (
            <Grid container spacing={3}>
              <Grid item md={12} xl={12}>
                <div className="field_box">
                  <FormPublishUnpublish
                    visibility={visibility}
                    setVisibility={setVisibility}
                    publish={publish}
                    unPublish={unPublish}
                    publishDate={publishDate}
                    publishTime={publishTime}
                    setPublishDate={setPublishDate}
                    setPublishTime={setPublishTime}
                    getPublishDate={getPublishDate}
                    getPublishTime={getPublishTime}
                    unPublishDate={unPublishDate}
                    unPublishTime={unPublishTime}
                    setUnPublishDate={setUnPublishDate}
                    setUnPublishTime={setUnPublishTime}
                    getUnPublishDate={getUnPublishDate}
                    getUnPublishTime={getUnPublishTime}
                    onVisibilitySave={onVisibilitySave}
                    onVisibilityCancel={onVisibilityCancel}
                    onPublishSave={onPublishSave}
                    onPublishCancel={onPublishCancel}
                    onUnPublishSave={onUnPublishSave}
                    onUnPublishCancel={onUnPublishCancel}
                    onSave={onSave}
                    onBack={onBack}
                    unPublishTimestamp={unPublishTimestamp}
                    minDateUnpublish={publishDate}
                    maxDatepublish={unPublishDate}
                    disabledSave={validUnPublish}
                    disabledSaveP={validPublish}
                    showFeatured={true}
                    featuredText="Feature this on homepage"
                    checked={featured}
                    onSetChecked={() => setFeatured(!featured)}
                  />
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
const mapStateToProps = (state) => {
  return {
    alert: state.alert,
  };
};
const mapDispatchToProps = (dispatch) => ({
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditVideo);
