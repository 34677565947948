import React  from "react";
import Moment from 'react-moment';
import axios from "axios";

const orgEnv = {
    prod: process.env.REACT_APP_BASEURL_PROD_API,
    dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
    window.location.host.includes("amazon") ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("shapla")
) {
    currentEnv = "dev";
} else {
    currentEnv = "prod";
}

const orgBaseUrl = orgEnv[currentEnv];

export async function verifyCommunicationContact( contactId  ){
    return new Promise((resolve, reject) => {
        axios
          .get(`${orgBaseUrl}/contact-activate/${contactId}`,{ })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
}

