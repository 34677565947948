import React from "react";
import { SimpleTable, MasterLayoutMiddle, FooterInner, Accordian } from "@dclab/dc-react-ui-kit";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Grid from '@material-ui/core/Grid';
import './style.css'

function GuestsListing() {

  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { type: "number", name: "serial_number", label: "NO.", width: "auto" },
    { type: "link", name: "invoice_number", label: "IMAGE", width: "auto" },
    { type: 'string', name: 'name', label: 'name', width: '15%' },
    { type: "string", name: "name", label: "Designation", width: "20%" },
    { type: "string", name: "status", label: "Video", width: '50%' },
    { type: "action", name: "action", label: "ACTION", width: "15%" },
  ];

  let rows = [
    [
      { type: "id", value: "01" },
      { type: 'url', name: 'single_pic', initials: 'HK' },
      { type: 'string', name: 'name', value: ['Shayan ', 'Ibn Mahmud'] },
      { type: "string", name: "frequency", value: "Imam, Masjid Bilal Of New York", },
      { type: 'status', status: 'success', value: 'Added' },
      { type: "action", status: "success", value: "see details" },

    ],

    [
      { type: "id", value: "02" },
      { type: 'url', name: 'single_pic', initials: 'HK' },
      { type: 'string', name: 'name', value: ['Shayan ', 'Ibn Mahmud'] },
      { type: "string", name: "frequency", value: "Imam, Masjid Bilal Of New York", },
      { type: 'status', status: 'success', value: 'Added' },
      { type: "action", status: "success", value: "see details" },

    ],

    [
      { type: "id", value: "03" },
      { type: 'url', name: 'single_pic', initials: 'HK' },
      { type: 'string', name: 'name', value: ['Shayan ', 'Ibn Mahmud'] },
      { type: "string", name: "frequency", value: "Imam, Masjid Bilal Of New York", },
      { type: 'status', status: 'success', value: 'Added' },
      { type: "action", status: "success", value: "see details" },

    ],

  ];
  // ============================== Table End ======================================//



  // ============================== 2nd Table Start =================================//
const saveChanges = (accordianNum, accordianData) => {
    console.log("data", accordianNum, accordianData)
  }

  const projectDescriptionLinkClick = () => {
    console.log("projectDescriptionLinkClick")
  }

  const projectHighlightsLinkClick = () => {
    console.log("projectHighlightsLinkClick")
  }

  const projectPhaseLinkClick = () => {
    console.log("projectPhaseLinkClick")
  }

  const projectPageLinkChange = (value) => {
    console.log("projectPageLinkChange", value)
  }

  //ACCORDIAN 1
  let brandingAccordianData = {
    orgName: "Brooklyn Islamic Center", logo: "", themeColor: ""
  };

  //ACCORDIAN 2
  let projectLinkAccordianData = "https://build.deenconnect.io/bicny";

  //ACCORDIAN 3
  let pageTitleAccordianData = "bicny form";

  //ACCORDIAN 4
  let mainBannerAccordianData = "";

  //ACCORDIAN 5
  let projectDescriptionAccordianData = "";

  //ACCORDIAN 6
  let projectHighlightsAccordianData = "";

  //ACCORDIAN 7
  let donationLinkAccordianData = "https://give.deenfund.com/bicny";

  //ACCORDIAN 8
  let donationAppealAccordianData = "Build a house of Allah, and Allah will build one for you in Jannah";

  //ACCORDIAN 9
  let donationApproachAccordianData = {
    donationApproach: "brick sponsor",
    donationApproachData: [
      { amount: 10, description: "test description" }
    ]
  };
  // ============================== 2nd Table End ===================================//


  const style = {
    width: '100%',
  };


  return (
    <div>

      <MasterLayoutMiddle

        header={
          <div>

            <div
              onClick={() =>
                this.props.history.push('')
              }
              className='backBtn'>
              <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
              <p style={{ alignSelf: 'center' }}>Back</p>{' '}
            </div>
          </div>
        }

        content={


          <div className="ListingTableContainer">
          <Grid container spacing={3}>

            <Grid item xs={12}>
              
                <SimpleTable
                  showHeader={true}
                  title="Guests"
                  width="100%"
                  rows={rows}
                  columns={col}
                  colSpan={16}
                  actionSendButton={true}
                  classProps={changeClassProps()}
                  donationsCount={rows?.length ? 1 : 0}
                  noTitle="No Guests added"
                  noPara="Added Guests will be displayed and managed here"
                  noBtnText="Add New Guests"
                  onInviteClick={() =>
                    this.props.history.push(
                      ""
                    )
                  }
                  actionLink={(name) => this.details(name)}
                  showIconCol={false}
                  actionSendButton={true}
                  deleteAction={(e) => this.deleteServiceAction(e)}
                  showFooter={true}
                  showAdd={true}
                  addText="ADD NEW"
                  onAddClick={() => this.props.history.push("")
                  }
                  iconTextSize={16}
                />
              
            </Grid>

            <Grid item xs={12}>
            <Accordian
              saveChanges={saveChanges}
              projectDescriptionLink={projectDescriptionLinkClick}
              projectHighlightsLink={projectHighlightsLinkClick}
              projectPhaseLink={projectPhaseLinkClick}
              projectPageLinkChange={projectPageLinkChange}
              projectPageLinkAvailable={true}
              brandingAccordianData={brandingAccordianData}
              projectLinkAccordianData={projectLinkAccordianData}
              pageTitleAccordianData={pageTitleAccordianData}
              projectDescriptionAccordianData={projectDescriptionAccordianData}
              donationLinkAccordianData={donationLinkAccordianData}
              donationAppealAccordianData={donationAppealAccordianData}
              projectHighlightsAccordianData={projectHighlightsAccordianData}
              donationApproachAccordianData={donationApproachAccordianData}
              // sectionVisibilityAccordianData={sectionVisibilityAccordianData}
              // projectPhaseAccordianData={projectPhaseAccordianData}
              mainBannerAccordianData={mainBannerAccordianData}
           />
            </Grid>

          </Grid>
          </div>


        }



        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }



      />


    </div>
  )
}


export default GuestsListing
