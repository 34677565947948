import React, { useEffect } from 'react'
import { AlertAction, AlertDialogue, EmptyTable, GridContainer, GridItem, SimpleTable } from "@dclab/dc-react-ui-kit";
import Moment from "moment";
import Handle from "../../../assets/img/more_vert-24px.svg";
import { EditVideoWebsite, GetVideosWebsiteFeatured, getVideosWithAppId, sortVideosWebsite, updateVideosWithAppId } from '../../../pages/Website/api';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { hideAlert, showAlert } from '../../../redux/actions/alertActions';
import messages_content from '../../../utils/messages.json';
const {
  token_expired,
  video_unfeatured_successfully
} = messages_content;

function Videos(props) {
  const [dataTable, setDataTable] = React.useState([]);
  const [Loader, setLoader] = React.useState(true);
  const [specificRow, setSpecificRow] = React.useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [error, seterror] = React.useState(false)
  const [data, setData] = React.useState([])
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  function get_youtube_thumbnail(url, quality) {
    if (url) {
      var video_id, thumbnail, result;
      if (result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)) {
        video_id = result.pop();
      }
      else if (result = url.match(/youtu.be\/(.{11})/)) {
        video_id = result.pop();
      }

      if (video_id) {
        if (typeof quality == "undefined") {
          quality = 'high';
        }

        var quality_key = 'maxresdefault'; // Max quality
        if (quality == 'low') {
          quality_key = 'sddefault';
        } else if (quality == 'medium') {
          quality_key = 'mqdefault';
        } else if (quality == 'high') {
          quality_key = 'hqdefault';
        }

        var thumbnail = "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
        return thumbnail;
      }
    }
    return false;
  }
  useEffect(() => {
    getFeaturedVideo()
  }, [])
  async function getFeaturedVideo() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    try {
      const resVideo = await getVideosWithAppId(token, orgId, 'deenweb')
      const data = resVideo.data.body.data;
      const filterFeatured = data.filter(text => text.featured)
      const sort = filterFeatured.sort((a, b) => {
        return a.video.order - b.video.order
      })
      setData(sort)
      let dataTable = [];
      sort.map((text, index) => {
        const dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("h:mm A") + ' (EST)'];
        const dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("h:mm A") + ' (EST)'];
        const UnpublishDate = new Date(dateEnd).getFullYear()
        let array = [
          { type: "id", value: `${index <= 8 ? '0' + (index + 1) : index + 1}`, _id: text._id, videoId: text.video._id },
          {
            name: "textImg",
            type: "string",
            primaryText: text.video.title,
            src: get_youtube_thumbnail(text.video.link, 'max'),
          },
          {
            type: "string",
            name: "vertical",
            value: dateStart,
          },
          {
            type: "string",
            name: UnpublishDate === 2099 ? "frequency" : "vertical",
            value: UnpublishDate === 2099 ? "Never" : dateEnd
          },
          { type: "action", status: "details", value: "Remove" },
        ]
        dataTable.push(array)
      })
      setDataTable(dataTable);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)

      }, 8000);
    }
  }
  const DeleteAlertFunc = (e) => {
    setSpecificRow(e);
    setOpenAlert(true);
  }
  const handleCancel = () => {
    setOpenAlert(false);
  }
  const handleClose = () => {
    setOpenAlert(false);
  };
  const RemoveRow = async (e) => {
    setLoader(true);
    setOpenAlert(false);
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    const updateOnOrg = {
      video: data[0].video._id,
      featured: false,
      publishFrom: data[0].publishFrom,  // DATE OBJECT
      publishTo: data[0].publishTo,
      status: data[0].status,
    }
    try {
      await updateVideosWithAppId(token, orgId, 'deenweb', data[0].video._id, updateOnOrg)
      props.alertShow(video_unfeatured_successfully)
      const stateNews = dataTable
      const NewsIndex = dataTable.indexOf(e)
      stateNews.splice(NewsIndex, 1)
      setLoader(false);
    } catch (err) {
      setLoader(false);
      seterror(err.response && err.response.data.message)
      console.log(err, 'res');
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { id: "drag", label: " ", width: 'auto' },
    { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
    { type: "string", name: "textImg", label: "Video Title", width: '50%' },
    { type: "string", name: "date/time", label: "Publish Date", width: '20%' },
    {
      type: "string",
      name: "date/time",
      label: "UnPublish Date",
      width: '20%',
    },
    { type: "action", name: "action", label: "ACTION", width: '10%' },
  ];
  const style = {
    width: 470,
    marginLeft: 800
  };
  const sortData = async (data) => {
    props.setCheckChanges(true)
    const token = localStorage.getItem("authToken");
    let OBJ = {
      sortedList: data.map(text => {
        return (
          { _id: text.video._id, order: text.order }
        )
      })
    }
    try {
      console.log(data, OBJ)
      await sortVideosWebsite(token, OBJ)
    } catch (err) {
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
    const orderData = data.sort((a, b) => a.order - b.order)
    let arr = []
    orderData.map((text, index) => {
      const dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("h:mm A") + ' (EST)'];
      const dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("h:mm A") + ' (EST)'];
      const UnpublishDate = new Date(dateEnd).getFullYear()
      let array = [
        { type: "id", value: `${index <= 8 ? '0' + (index + 1) : index + 1}`, _id: text._id, videoId: text.video._id },
        {
          name: "textImg",
          type: "string",
          primaryText: text.video.title,
          src: get_youtube_thumbnail(text.video.link, 'max'),
        },
        {
          type: "string",
          name: "vertical",
          value: dateStart,
        },
        {
          type: "string",
          name: UnpublishDate === 2099 ? "frequency" : "vertical",
          value: UnpublishDate === 2099 ? "Never" : dateEnd
        },
        { type: "action", status: "details", value: "Remove" },
      ]
      arr.push(array)
    })
    setDataTable(arr);
  };
  return (
    <GridContainer containerClass="web-announcements ">
      {
        Loader ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          dataTable.length > 0 ?
            <>
              {
                error && error === "Invalid token" && (
                  <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '12px' }}>
                    <AlertAction
                      alert={token_expired}
                      alertType={"error"}
                      onClick={() => seterror(false)}
                    />
                  </div>
                )
              }
              <GridItem xs={12} itemClass="web-salah">
                <SimpleTable
                  showHeader={true}
                  title="Featured Videos"
                  width="98%"
                  parentTableDrag={true}
                  DragIcon={Handle}
                  rows={dataTable}
                  columns={col}
                  colSpan={16}
                  actionSendButton={true}
                  classProps={changeClassProps()}
                  actionLink={(e) => DeleteAlertFunc(e)}
                  donationsCount={dataTable?.length ? 1 : 0}
                  noTitle="No Videos added"
                  noPara="Added Videos will be displayed and managed here"
                  noBtnText="Add New Videos"
                  onInviteClick={() =>
                    props.history.push("/website/homepage/add-new-video")
                  }
                  showIconCol={false}
                  showFooter={false}
                  showAdd={true}
                  addText="ADD"
                  manageDevice={(e) => console.log(e)}
                  onAddClick={() =>
                    props.history.push("/website/homepage/add-new-video")
                  }
                  iconTextSize={16}
                  sortData={(e) => sortData(e)}
                  sortColumnIndex={[3]}
                  data={data}
                />

                <div style={{ display: "flex", marginTop: 20 }}>
                  <p className="cat-lower" style={{ marginTop: 0 }}> Change the order of Videos to display to users. Click on{" "}</p>
                  <img src={Handle} alt="" style={{ display: "flex", marginTop: -10 }} />
                  <p className="cat-lower" style={{ marginTop: 0 }}> to drag and drop within the table.</p>
                </div>
              </GridItem>
            </>
            :
            <EmptyTable
              title="Featured Videos"
              onAddClick={() => props.history.push("/website/homepage/add-new-video")}
              addLabel="Add"
              noBtnText="Add"
            />

      }
      <div>
        <AlertDialogue
          title="Remove Video?"
          description="Are you sure you want to remove this video?"
          description2="It will be removed from homepage only."
          cancelText="Cancel"
          confirmText="Confirm"
          open={openAlert}
          handleCancel={() => handleCancel()}
          handleClose={() => handleClose()}
          handleConfirm={() => RemoveRow(specificRow)}
          styles={style}
        />
      </div>
    </GridContainer>
  );
}
const mapStateToProps = (state) => ({
  alert: state.addOrg.alert,
});

const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
  alertHide: (res) => dispatch(hideAlert(res)),

});
export default connect(mapStateToProps, mapDispatchToProps)(Videos);
