import React from 'react';
import { CardDetailsTable, Initials, CardStatus } from "@dclab/dc-react-ui-kit";

function MemberDetails(props) {
   let header = {
        tableName: `Member Details`,
    };

    let data = [
        {
            field: "First name",
            value: "Shayan"
        },
        {
            field: "Address",
            value: "889 Lords Way Bolivar, TN 38008 United States"
        },

        {
            field: "Level",
            value: "General Membership"
        },
        {
            field: "Middle name",
            value: `None`
        },
        {
            field: "Fee",
            value: "$20"
        },
        {
            field: "Link",
            value: "<a href='#'>1845-9488</a>"
        },
        {
            field: "Last name",
            value: "Mahmud"
        },
        {
            field: "Age group",
            value: "25-30"
        },
        {
            field: "Member since",
            value: `Apr 20, 2021`
            // <CheckCircleIcon style={{color: "#05CE78", fontSize: "14px"}}/>
        },

        {
            field: "Phone",
            value: `+1 (123) 123-1234`
            // <CheckCircleIcon style={{color: "#05CE78", fontSize: "14px"}}/>
        },
        {
            field: "Gender",
            value: "Male"
        },
        {
            field: "Renewal date",
            value: "May 20, 2021"
        },

        {
            field: "Email",
            value: "hassanahmed@email.com"
        },

        {
            field: "Race",
            value: "Asian"
        },

        {
            field: "Renewal cycle",
            value: "Renewal cycle"
        },

        {
            field: "Committee",
            value: "None"
        },

        {
            field: "Profession",
            value: "Dentist"
        },
    
    ];
    

    let footerLinkName = "Edit";
    let footerDeleteButton = "CANCEL MEMBERSHIP";

    // HANDLE FOOTER LINK
    const onClick = () => {
        console.log('/edit-detail')
    }

    const onDeleteClick = () => {
        console.log('cancel-membership')
    }

    const onClickSend = () => {
        console.log('Send')
    }


    const handleChange = (event, value) => {
        console.log(event.target.value, value);
    };


    return (

        <CardDetailsTable
            data={data}
            header={header}
            footerLinkName={footerLinkName}
            footerDeleteButton={footerDeleteButton}
            onClick={() => onClick()}
            onDeleteClick={() => onDeleteClick()}

            Status={true}
            StatusLabel="Status"
            StatusText="Active"
            statuscolor="#3CC480"

            OrgName="Deenfund"
            OrgSubText="Application"
            HeaderStatus={false}

        />

    );
}


export default MemberDetails;