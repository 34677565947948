import React, { useEffect , useState}  from 'react';
import { VerificationSuccess } from "@dclab/dc-react-ui-kit";
import Logo from "../../assets/img/DC_Logo.png";
import Done from '../../assets/img/Done.svg'; 
import {verifyCommunicationContact} from './Functions'; 

function VerifyContact(props){

    const [textmessage , setTextmessage ] = useState( '' );
    const [pageLogo, setPageLogo] = useState( '' ); 

    useEffect( async () => {

        const contactId = props.match.params.id ;
        let checked = true ;

        if( checked ){
            const response = await verifyCommunicationContact( contactId );

            if( response && response.data && response.data.body.data.logoUrl){
                // console.log( response.data.body.data.logoUrl );
                setPageLogo( response.data.body.data.logoUrl ); 
                setTextmessage( 'Your email has been verified.');
                
            }
            
        }

        return () => checked = false  ;

    }, []); 

    const onClick = async () => {

    };

    return (
            <div style={{ paddingBottom: '40px', posiiton: 'relative' }}>
                <div style={{ position: "absolute", width: '100%', zIndex: -1 }} >
                    <div className='Background-Image' style={{ width: '100%', position: 'fixed', height: '100vh' }}>
                    </div>
                </div>
                <div className="signup-container div_backgrndimg-pattern" style={{ paddingTop: 40 }}>
                    <VerificationSuccess
                        src={pageLogo}
                        name="verifySuccess"
                        title="Verification Successful"
                        para={[textmessage]}
                        btnText="GO TO DASHBOARD"
                        btn={false}
                        loader={false}
                        showBox={true}
                        showfooter={true}
                        onLoadingComplete={() => onClick()}
                        img={Done}

                    />
                    <div>
                        <div className="tx_box">
                            <div className="powered_by" style={{ marginLeft: 680 }}> Powered by
                                <a href="https://deenconnect.io/">
                                    <img className='dc_logo_ftr' src="https://deenfund.com/static/media/DC_Logo.454eb4eb.png"  />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                

            </div>
    );

}

export default VerifyContact ; 