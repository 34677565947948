import * as constants from '../constants'

const INITIAL_STATE = {
    df_embedform: [
        {
          form_theme_color: '',
          form_link: 'https://give.deenfund.com/',
          form_header_button: {
            enabled: false,
            link: '',
          },
          form_title: '',
          processing_fee_enabled: false,
          preselect_amounts: [
            {
              allow_to_customize_amount: false,
              sequence: '',
              set_default: false,
            },
          ],
          payment_method: '',
          multiple_donations: {
            paid_feature: '',
            donation_per_category: '',
          },
          setup: {
            right_side: '[{ type: "paragraph", children: [{ text: "" }] }]',
            image_or_video: '',
            content: '',
            faqs: [],
            footer: {
              enabled: false,
              email: '',
              full_org_address: '',
              phone_number: '',
              tax_number: '',
            },
          },
        },
      ],
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case constants.SAVE_EMBED_FORM:
            return {
                ...state,
                embed_form: action.payload,
            };
        case constants.EMPTY_EMBED_FORM:
            return {
                ...state,
                embed_form: INITIAL_STATE,
            };
        default:
            return state;
    }
};
