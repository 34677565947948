import * as constants from "../constants";

function signinAction(email, token, access) {
  return {
    type: constants.SIGN_IN,
    payload: {
      email,
      token,
      access

    },
  };
}


export { signinAction };
