import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Grid from '@material-ui/core/Grid';
import { BasePhoneInput, DropdownMultiSelect, MasterLayoutMiddle, FooterInner, BaseTextField, BaseSelect, BaseButton, arrowClick, DrawerListCheckbox } from "@dclab/dc-react-ui-kit";


function CommitteeEditMember(props) {

  
  const styles = {
    border: '1px solid black',
    color: 'gray'
  };


  const style = {
    width: '100%',
  };

  


  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });

  const [values, setValues] = React.useState({
    name: "hello",
  });

  const handleChange = (prop) => (event) => {
    setValues({

    });
  };

  const viewChange = (newValue) => {
    console.log("Coming from View Change: ", newValue);
  };

  const Listdata = [
    {
      contactId: "6165ac907043bb0008c942d3",
      groupContacts: [],
      title: "Marketing team"
    },
    {
      contactId: "615aec4ea1dd53000874cda0",
      title: "Faisal Mosque",
    },
    {
      contactId: "6170389b9da5450009e719ad",
      title: "atiqur rahman",
    },
    {
      contactId: "61436db18c6dde0009b2091f",
      title: "Ahsan Uddin2",
    },
    {
      contactId: "61433422521c5d00092fe7e0",
      title: "Rizwan khan",
    },
    {
      contactId: "6138ce625cc6650008ecd6f7",
      title: "ahsan Uddin",
    }

  ];



  const selectedValues = [
    // { title: 'The Shawshank Redemption', year: 1994 },
    // { title: 'The Godfather', year: 1972 },
    // { title: 'The Godfather: Part II', year: 1974 },
  ];

  const group = {
    'The Shawshank Redemption': 1994,
    'The Godfather': 1972,
    'Shaft\'s Big Score!': 1972,
    'The Godfather: Part II': 1974,
  }

  const groupByCallBack = (option) => {
    return group[option.title]
  }

  return (

    <MasterLayoutMiddle
      header={
        <div className="backBtn">
          <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
          <Link
            to={{ pathname: "/organization/committees", state: 1, }}
            style={{ alignSelf: "center", color: "black", textDecoration: "none", }} > Back </Link>{" "}
        </div>
      }

      isForm={true}
      formData={
        <div>
          <Grid container spacing={3}>

            <Grid item md={12}>
              <h1 className='title_top'>Edit Member</h1>
              <div className="dasheddBorder"></div>
            </Grid>


            <Grid item xs={6}>
              <div className="field_box">
                <BaseTextField
                  label="First Name"
                  value="Sports & Recreation"
                  style={styles.btn}
                  color="primary"
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="field_box">
                <BaseTextField
                  label="Last Name"
                  value="Sports & Recreation"
                  style={styles.btn}
                  color="primary"
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="field_box">
                <BasePhoneInput
                  phoneInputVariant={"underlineWithFlag"} // default, materialui, underlineInput, underlineWithFlag
                  country={'us'}
                  value={phone.number}
                  countryCodeEditable={false}
                  onChange={(phone, country) => console.log("onChange", phone, country)}
                  onBlur={() => console.log("onBlur")}
                  onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                  variant="outlined"
                  onlyCountries={["us", "ca", "gb", "my"]}
                  containerStyle={{}}
                  buttonStyle={{}}
                  inputStyle={{
                    marginLeft: "0px",
                    height: "35px",
                    width: "100%",
                    fontFamily: "Roboto-Regular",
                    fontSize: "16px",
                  }}
                  containerClass={{}}
                  errorText={"Phone number is required"}
                  error={true}
                  errorStyles={{ marginBottom: "5px" }}
                  autoFormat={true}
                  disableDropdown={false}
                  disabled={false}
                  disableCountryCode={false}
                  enableSearch={false}
                  disableSearchIcon={false}
                  placeholder={""}
                  inputProps={{}}
                  specialLabel={'Phone Number'}
                // preferredCountries={[]}
                // excludeCountries={[]}
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className="field_box">
                <BaseTextField
                  label="Email"
                  value="shayanmahmud@gmail.com"
                  style={styles.btn}
                  color="primary"
                />
              </div>
            </Grid>


            <Grid item xs={12}>
              <div className="field_box">
                <DropdownMultiSelect
                  label="Select Committee"
                  Listdata={Listdata.sort((a, b) => a.year - b.year)}
                  variant="standard"
                 // placeholder="Search contacts/groups"
                  selected={selectedValues}
                  groupByCallBack={groupByCallBack}
                  // helpertext="Helpertext"
                  // error="Field is required"
                  fieldToSelect='Description'
                  // variant="outlined"
                  onChange={handleChange("name")}
                  viewChange={viewChange}
                  value={values.name}
                />

              </div>
            </Grid>

            <Grid item md={12} className="buttons">
              <span className="btnCencel" onClick={() => props.history.goBack()}><BaseButton text="CANCEL" /></span>
              <BaseButton text="ADD Now"  />
            </Grid>

          </Grid>


        </div>
      }

      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}

    />
  );
}
export default CommitteeEditMember;