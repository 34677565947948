import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  AlertAction, FormSwitch, MasterLayoutMiddle, CardDetailsTable, Initials, FooterInner, BaseButton, SimpleAccordian, DropDown
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import './style.css'
import { getCurrentUserDetail, editProfile } from "./Apis/index";
import { ReactComponent as Crown } from '../../assets/img/crown.svg'



function AdminProfile(props) {
  // ============================== Base Tab Start ===============================//
  const [value, setValue] = React.useState(0);
  const [userDetails, setuserDetails] = React.useState({})
  const [successAlert, setsuccessAlert] = useState(false)
  const [timezone, setTimezone] = useState('');
  const [loading, setloading] = useState(true);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  useEffect(() => {
    if (props?.location?.state?.successAlert) {
      setsuccessAlert(true)
      setTimeout(() => {
        setsuccessAlert(false)
      }, 8000);
    }
    const getDetails = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const res = await getCurrentUserDetail(token);
        console.log("Res of user Profile: ", res);
        if(res?.timeZone?.length > 0) {
          setTimezone(res.timeZone);
        }
        setuserDetails(res)
        setloading(false);
      }
      catch (error) {
        console.log(error)
      }
    }
    getDetails()
  }, [])
  const object = [
    {
      label: "Profile",
      item: "",
    },
    {
      label: "preferences",
      item: "",
    },
  ];
  const onClick = (x, y) => {
    console.log(x);
    console.log(y);
  };
  // ============================== Base Tab End ===============================//
  // ============================== Details Table Start ===============================//

  const firstName = userDetails?.name && userDetails.name.firstName ? userDetails.name.firstName : "";
  const lastName = userDetails?.name && userDetails.name.lastName ? userDetails.name.lastName : "";
  const userImage = userDetails?.imageDetails && userDetails.imageDetails.image ? userDetails.imageDetails.image : "";

  let header = {
    avatar: userImage ? <img src={userImage} alt="avatar" /> : <Initials name={firstName || lastName ? firstName + " " + lastName : ""} />,
    tableName: firstName || lastName ? firstName + " " + lastName : "",
    icon: userDetails && userDetails.role && userDetails.role.length && userDetails.role[0].roles === "owner" ? <Crown /> : "",
    status: []
  };


  let data = [
    {
      field: "Phone",
      value: userDetails && userDetails.phoneNo ? userDetails.phoneNo : '<span className="ondisableText">No Phone</span>'
    },
    {
      field: "Address",
      value: userDetails?.address?.line1 ? `<div><div>${userDetails?.address?.line1}</div><div>${userDetails.address.city + ", " + userDetails.address.state + ", " + userDetails.address.postalCode}</div></div>` : '<span className="ondisableText">No Address</span>'
    },
    {
      field: "",
      value: "<span></span>"
    },
    {
      field: "Email",
      value: userDetails && userDetails.userName ? userDetails.userName : ""
    },
    {
      field: "",
      value: "<span></span>"
    }, {
      field: "",
      value: "<span></span>"
    },
    // {
    //   field: "Password",
    //   value: "12345678"
    // },
  ];
  let footerLinkName = "EDIT PROFILE";
  // ============================== Details Table End =================================//
  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { type: "number", name: "serial_number", label: "NO.", width: "auto" },
    { type: "link", name: "invoice_number", label: "Org name", width: "30%" },
    { type: "string", name: "name", label: "Role", width: "15%" },
    { type: "string", name: "name", label: "ORG id", width: "44%" },
    { type: "action", name: "action", label: "ACTION", width: "20%" },
  ];
  let rows = [
    [
      { type: "id", value: "01" },
      {
        name: "textImg",
        type: "string",
        primaryText: "Islamic Institute of Brooklyn",
        initials: "HK",
      },
      { type: "string", name: "frequency", value: "Admin", },
      { type: "string", name: "date/time", value: "acct_1Ah5arCVVsjUQDTv" },
      { type: "action", status: "success", value: "see details" },
    ],
    [
      { type: "id", value: "02" },
      {
        name: "textImg",
        type: "string",
        primaryText: "Islamic Institute of Brooklyn",
        initials: "HK",
      },
      { type: "string", name: "frequency", value: "Admin", },
      { type: "string", name: "date/time", value: "acct_1Ah5arCVVsjUQDTv" },
      { type: "action", status: "success", value: "see details" },
    ],
    [
      { type: "id", value: "03" },
      {
        name: "textImg",
        type: "string",
        primaryText: "Islamic Institute of Brooklyn",
        initials: "HK",
      },
      { type: "string", name: "frequency", value: "Admin", },
      { type: "string", name: "date/time", value: "acct_1Ah5arCVVsjUQDTv" },
      { type: "action", status: "success", value: "see details" },
    ],
  ];
  // ============================== Table End ======================================//
  // ============================== Style Start ===============================//
  const styles = {
    first: {
      marginBottom: 31,
    },
    second: {
      marginBottom: 111,
    },
    third: {
      marginBottom: 1,
    },
  };
  // ============================== Style END ===============================//
  const style = {
    width: '100%',
  };

  // ====== Accordion Start =======//
  const [selectedZone, setselectedZone] = React.useState(0);

  const saveChanges = async (accordian) => {
    setselectedZone(0)

    const formData = {
      "timeZone": timezone
    }
    try {
      await editProfile(userDetails._id, formData, props.history, false);
      setsuccessAlert(true)
      setTimeout(() => {
        setsuccessAlert(false)
      }, 8000);
    }
    catch (error) {
      console.log(error)
    }


  }
  const onAccordianClick = (panel) => {
    console.log("onAccordianClick", panel)
    if (panel === selectedZone) {
      setselectedZone(0)
    } else {
      setselectedZone(panel)
    }
  }

  const AccordionDetails = {
    top: "0px"
    //top:"-46px"
  };


  const selectdata = [
    { value: "AST", select: "Atlantic Standard Time (AST)" },
    { value: "EST", select: "Eastern Standard Time (EST)" },
    { value: "CST", select: "Central Standard Time (CST)" },
    { value: "MST", select: "Mountain Standard Time (MST)" },
    { value: "AKST", select: "Alaskan Standard Time (AKST)" },
    { value: "HST", select: "Hawaii Standard Time (HST)" },
    { value: "GMT", select: "Greenwich Mean Time (GMT)" },
    { value: "PST", select: "Pacific Standard Time (PST)" },
  ];

  const handleChange = (event, value) => {
    console.log(event.target.value, value);
  };

  const timezoneChanged = (event, value) => {
    setTimezone(event.target.value)
  }

  const getFullTimeZone = (abbr) => {
    switch(abbr){
      case 'AST':
        return 'Atlantic Standard Time (AST)';
      
      case 'EST':
        return "Eastern Standard Time (EST)";

      case "CST":
        return "Central Standard Time (CST)";
      
      case "MST":
        return "Mountain Standard Time (MST)";

      case "AKST":
        return "Alaskan Standard Time (AKST)";

      case "HST":
        return "Hawaii Standard Time (HST)";

      case "GMT":
        return "Greenwich Mean Time (GMT)";

      case "PST":
        return "Pacific Standard Time (PST)";

      default:
        return null

    }
  }




  // ====== Accordion End =======//


  return (
    <MasterLayoutMiddle
      content={
        <div>
          {(successAlert && (
            <div
              style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
            >
              <AlertAction
                alert={"Profile updated successfully!"}
                alertType={"success"}
                onClick={() => setsuccessAlert(false)}
              />
            </div>
          ))}
          {/* <BaseTabs
            title=""
            object={object}
            indicatorColor="primary"
            onClick={(x, y) => onClick(x, y)}
            onChange={(event, newValue) => handleChange(event, newValue)}
            value={value}
            indicatorColor="#7266FB"
          /> */}
          {value === 0 && (
            <Grid container spacing={3} >
              <Grid item xs={12} >
                <div className="table_box">
                  <CardDetailsTable data={data} header={header} footerLinkName={footerLinkName} onClick={() => props.history.push("/edit-profile")} avatarStatus={true} />
                </div>
              </Grid>

              <Grid item xs={12} >
                <SimpleAccordian
                  Title="PASSWORD"
                  text={<div><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span></div>}
                  //tooltip="Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups."
                  color="#2D2D2D"
                  fontSize="14px"
                  fontWeight="400"
                  TootltipStatus={false}
                  TitleButtonLabel="CHANGE NOW"
                  AccordionDetails={AccordionDetails}
                  panel={1}
                  id="1"
                  areacontrol="1"
                  open={false}
                  onAccordianClick={() => props.history.push('/change-password')}
                  // onAccordianClick={onAccordianClick}
                  accordianName="headerCard"
                  saveChanges={saveChanges}
                  Cencel="CENCEL"
                  Save="SAVE CHANGES "
                />

                <SimpleAccordian
                  Title="TIME ZONE"
                  text={getFullTimeZone(timezone) ?? "Select time zone"}
                  color={timezone.length > 0 ? "#4D4D4D" : "#818E94"}
                  fontSize="14px"
                  fontWeight="400"
                  //tooltip="Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups."
                  TootltipStatus={false}
                  TitleButtonLabel="EDIT"
                  AccordionDetails={AccordionDetails}
                  panel={2}
                  id="2"
                  areacontrol="2"
                  open={selectedZone === 2 ? true : false}
                  onAccordianClick={onAccordianClick}
                  accordianName="headerCard"
                  saveChanges={saveChanges}
                  Cencel="CANCEL"
                  Save="SAVE CHANGES "

                  content={
                    
                        <div className="TimeZoneBody">
                        {!loading && <DropDown
                          label="Select Time Zone"
                          labelStatus={true}
                          selectdata={selectdata}
                          defaultValue={timezone}
                          // value = {timezone}
                          handleChange={timezoneChanged}
                        /> }
                        <div className="HelperText">Changes to your time zone will reflected across all added DC apps and integrations.</div>
                        </div>
                  }

                />

                {/* <SimpleAccordian
                  Title="Delete your account"
                  text="Permanently delete your account"
                  tooltip="Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups."
                  TootltipStatus={true}
                  TitleButtonLabel="DELETE ACCOUNT"
                  AccordionDetails={AccordionDetails}
                  panel={0}
                  id="0"
                  areacontrol="0"
                  open={selectedZone === 0 ? false : false}
                  onAccordianClick={onAccordianClick}
                  accordianName="headerCard"
                  saveChanges={saveChanges}
                  Cencel="CENCEL"
                  Save="SAVE CHANGES "
                /> */}
              </Grid>


              <Grid item xs={12} >
                <div className="table_box">
                  {/* <SimpleTable
                    showHeader={true}
                    title="Accounts"
                    width="100%"
                    rows={rows}
                    columns={col}
                    colSpan={16}
                    actionSendButton={true}
                    classProps={changeClassProps()}
                    donationsCount={1}
                    noTitle="No Service found"
                    noPara="Add new AnnouncementsAnnouncements."
                    noBtnText="Add Announcements"
                    onInviteClick={() =>
                      this.props.history.push(
                        "/organization/services/add-new-service"
                      )
                    }
                    actionLink={(name) => this.details(name)}
                    showIconCol={false}
                    actionSendButton={true}
                    deleteAction={(e) => this.deleteServiceAction(e)}
                    showFooter={true}
                    showAdd={false}
                    addText="ADD NEW"
                    onAddClick={() =>
                      this.props.history.push(
                        "/Website/Add-Announcements"
                      )
                    }
                    iconTextSize={16}
                  /> */}
                </div>
              </Grid>
            </Grid>
          )}
          {value === 1 && (
            <>
              <div className="page_body">
                <Typography className="page_form_title">Email Preferences</Typography>
                <Grid className="form_body">
                  <Grid container item xs={6} spacing={1} >
                    <Grid item xs={12}>
                      <FormSwitch style={styles.BaseTextField} headline="Login from a new device" body="Get an email notification everytime you login from a new device" />
                    </Grid>
                    <Grid item xs={12}><div className="border_line"></div></Grid>
                    <Grid item xs={12}>
                      <FormSwitch style={styles.BaseTextField} headline="Payments reviews" body="Get an email notification everytime you login from a new device" />
                    </Grid>
                    <Grid item xs={12}><div className="border_line"></div></Grid>
                    <Grid item xs={12}>
                      <FormSwitch style={styles.BaseTextField} headline="Successful payments" body="Get an email notification everytime you login from a new device" />
                    </Grid>
                    <Grid item xs={12}><div className="border_line"></div></Grid>
                    <Grid item xs={12}>
                      <FormSwitch style={styles.BaseTextField} headline="Login from a new device" body="Get an email notification everytime you login from a new device" />
                    </Grid>
                    <Grid item xs={12}><div className="border_line"></div></Grid>
                    <Grid item xs={12}>
                      <FormSwitch style={styles.BaseTextField} headline="Payments reviews" body="Get an email notification everytime you login from a new device" />
                    </Grid>
                  </Grid>
                </Grid>
                <div className="page_footer footer_btn">
                  <span className="btnCencel">
                    <BaseButton text="CANCEL" />
                  </span>
                  <BaseButton text="Save changes" />
                </div>
              </div>
            </>
          )}

        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  );
}
export default AdminProfile;