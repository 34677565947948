import React, { useRef } from "react";
import {
  BaseTextField,
  BasePaper,
  PageHeaderWithTabs,
  FormSwitch,
  BaseCheckBox,
  TextEditor,
  MasterLayoutMiddle,
  FooterInner,
  AlertDialogueAdvance,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import "./style.css";
import { saveAsDraft } from "../../components/DonationForm/Apis";
import { editOrganization, getOrganizationData } from "../Organization/api";
import { AddPrivacyPolicyWebsite, GetPrivacyPolicyWebsite, updatePrivacyPolicyWebsite } from "./api";
import { PagesAction } from "../../redux/actions/pages";
import { connect } from "react-redux";
import messages_content from '../../utils/messages.json';
const {
  token_expired,
  Your_changes_has_been_published,
  Your_changes_has_been_saved_as_draft,
  quick_access_limit_axeeded
} = messages_content;
function PrivacyPolicy(props) {
  const history = props.history;
  const [Title, setTitle] = React.useState('')
  const [value, setValue] = React.useState(0);
  const [showAlert, setshowAlert] = useState(false);
  const [success, setsuccess] = useState(false);
  const [organizationData, setOrganizationData] = useState([])
  const [open, setAlertModal] = useState(false);
  const [dischard_changes, setdischard_changes] = useState(0);
  const [changes, setCheckChanges] = useState(false);
  const [homepageButton, setHomePageButton] = useState(false);
  const [Loader, setLoader] = React.useState(true);
  const [error, seterror] = useState(false);
  const [ErrorFound, setErrorFound] = useState(false)
  const unblockHandle = useRef();
  const [EmptyData, setEmptyData] = useState({})
  const [QuickLimit, setQuickLimit] = useState(false);
  const [checked, setChecked] = useState(true);
  const [pagesAll, setPagesAll] = useState({})
  const [saved_route, setRoute] = useState('');
  const [orgIntegrationData,setOrgIntegrationData] = useState({})
  const [textValue, setTextValue] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);

  useEffect(() => {
    unblockHandle.current = history.block((route) => {
      setRoute(route.pathname)
      // console.log('changes', changes)
      changes && visibleModal()
      return changes === true ? false : true
    });
    return () => {
      unblockHandle.current = false
      !window.location.pathname.includes('privacy-policy') && localStorage.removeItem('privacy-policy')
    }
  }, [changes])

  useEffect(() => {
    const page_no = localStorage.getItem('privacy-policy')
    setValue(page_no ? +page_no : 0);
    async function getorganization() {
      try {
        const token = localStorage.getItem('authToken')
        const orgId = localStorage.getItem('orgID')
        const getOraganization = await getOrganizationData(orgId, token);
        // console.log('org', getOraganization)
        // try {
        //   const getTermsAndCondition = await GetPrivacyPolicyWebsite(token);
        //   let descApi = getTermsAndCondition.data.body.data;
        //   let desc = JSON.parse(descApi.description);
        //   setTextValue(desc);
        //   setEmptyData(descApi);
        // } catch (err) {
        //   console.log(err)
        // }
        const orgIntegration = getOraganization?.data?.body?.data?.enabledIntegrations
        const data = orgIntegration?.deenweb?.pages;
        const oraganization_data = data?.privacyPolicy;

        const ppDescription = orgIntegration?.deenweb?.settings?.privacyPolicy?.description;
        ppDescription && setTextValue(JSON.parse(ppDescription));

        setOrgIntegrationData(orgIntegration);
        setPagesAll(data);
        setOrganizationData(oraganization_data);
        setTitle(oraganization_data.title);
        setHomePageButton(oraganization_data.enablePage)
        setChecked(oraganization_data.quickAccess)
        setLoader(false);
      }
      catch (error) {
        setLoader(false);
        seterror(error.response && error.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    }
    getorganization()
  }, [])
  const handleChange = (event, newValue) => {
    changes && (Title === '' ? setErrorFound(true) : setAlertModal(true))
    !changes && setValue(newValue);
    !changes && localStorage.setItem('privacy-policy', newValue)
    setdischard_changes(newValue)
  };
  function visibleModal() {
    setAlertModal(true)
  }
  const object = [
    {
      label: "Contents",
      item: "Contents",
    },
    {
      label: "Page setup",
      item: "Page setup",
    },
    
  ];
  const saveChanges = async () => {
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    if (Title === '') {
      setErrorFound(true)
    } else {
      let data = {
        enabledIntegrations: {
          ...orgIntegrationData,
          deenweb: {
            ...orgIntegrationData?.deenweb,
              settings: {
                ...orgIntegrationData?.deenweb?.settings,
                privacyPolicy: {
                  description: JSON.stringify(textValue)
                }
              },
            pages: {
              ...pagesAll,
              privacyPolicy: {
                title: Title,
                status: homepageButton ? 'active' : 'inactive',
                enablePage: homepageButton,
                quickAccess: checked,
                lastEdited: new Date(),
                pageName: 'Privacy Policy',
              }
            }
          }
        }
      }
      // const Privacy = {
      //   description: JSON.stringify(textValue),
      //   organizationId: orgId
      // }
      try {
        await saveAsDraft(orgId, data, token);
        // const result = await AddPrivacyPolicyWebsite(Privacy, token)
        // const addedPrivacy = result.data.body.data;
        // const selectedPrivacy = organizationData.selectedPrivacyPolicy
        // selectedPrivacy.push(addedPrivacy);

        setshowAlert(true);
        setsuccess(true)
        setValue(dischard_changes);
        setAlertModal(false)
        // setCheckChanges(false)
        setValue(dischard_changes);
      } catch (err) {
        seterror(err.response && err.response.data.message)
        setAlertModal(false)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    }
  };
  const publish = async () => {
    let pages = props?.pages?.pages
    let lengthOfPages = pages.filter(text => text.quickAccess === true);
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    if (!value) {
      if (Title === '') {
        setErrorFound(true)
      } else {
        let data = {
          enabledIntegrations: {
            ...orgIntegrationData,
            deenweb: {
              ...orgIntegrationData?.deenweb,
                settings: {
                  ...orgIntegrationData?.deenweb?.settings,
                  privacyPolicy: {
                    description: JSON.stringify(textValue)
                  }
                },
              pages: {
                ...pagesAll,
                privacyPolicy: {
                  title: Title,
                  status: homepageButton ? 'active' : 'inactive',
                  enablePage: homepageButton,
                  quickAccess: checked,
                  lastEdited: new Date(),
                  pageName: 'Privacy Policy',
                }
              }
            }
          }
        }

        if (!checked || organizationData.quickAccess || lengthOfPages.length < 6) {
          try {
            await saveAsDraft(orgId, data, token);

            setshowAlert(true);
            setAlertModal(false)
            setsuccess('Publish');
            setTimeout(() => {
              setsuccess(false)
            }, 8000);
            setCheckChanges(false)
            setValue(dischard_changes);
            let Quick = pages.filter(text => text.name[0] !== "privacyPolicy")
            Quick.push({
              name: ['privacyPolicy'],
              quickAccess: checked
            })
            props.dispatchPagesAction(Quick)
            if (saved_route) {
              props.history.push(saved_route);
            }
          } catch (err) {
            seterror(err.response && err.response.data.message)
          }
        } else {
          setQuickLimit(true)
          setTimeout(() => {
            setQuickLimit(false)
          }, 8000)
        }
      }
    } else {
      let data = {
        enabledIntegrations: {
          ...orgIntegrationData,
          deenweb: {
            ...orgIntegrationData?.deenweb,
              settings: {
                ...orgIntegrationData?.deenweb?.settings,
                privacyPolicy: {
                  description: JSON.stringify(textValue)
                }
              },
            pages: {
              ...pagesAll,
              privacyPolicy: {
                title: Title,
                status: homepageButton ? 'active' : 'inactive',
                enablePage: homepageButton,
                quickAccess: checked,
                lastEdited: new Date(),
                pageName: 'Privacy Policy',
              }
            }
          }
        }
      }

      if (!checked || organizationData.quickAccess || lengthOfPages.length < 6) {
        try {
          await saveAsDraft(orgId, data, token);

          setshowAlert(true);
          setsuccess('Publish');
          setAlertModal(false)
          setTimeout(() => {
            setsuccess(false);
          }, 8000);
          setCheckChanges(false)
          setValue(dischard_changes);
          let Quick = pages.filter(text => text.name[0] !== "privacyPolicy")
          Quick.push({
            name: ['privacyPolicy'],
            quickAccess: checked
          })
          props.dispatchPagesAction(Quick)
          if (saved_route) {
            props.history.push(saved_route);
          }
        } catch (err) {
          seterror(err.response && err.response.data.message)
        }
      } else {
        setQuickLimit(true)
        setTimeout(() => {
          setQuickLimit(false)
        }, 8000)
      }
    }

  };
  const saveStyles = {
    backgroundColor: "#2A82F6",
  };
  const publishStyles = {
    backgroundColor: "#2A82F6",
    opacity: 0.6,
  };
  const paperStyles = {};
  const styles = {
    BaseTextField: {
      width: "100%",
    },
    contents: {
      paddingTop: 20,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "100%",
      marginTop: 70,
      marginLeft: "14%",
    },
  };
  const handleClose = () => {
    setAlertModal(false)
  };

  const info = (
    <>
      <div style={{ borderBottom: "1px solid #E8E9E9" }}>
        <p className="orgTitled">Privacy Policy</p>
      </div>
      <div className="about-us" style={{ paddingBottom: 48, marginTop: 30 }}>
        {/* <p className="desc">Description</p> */}
        <div>
          <TextEditor
            onChange={(e) => { setTextValue(e); checkChanges(e !== textValue) }}
            value={textValue}
            maxCharCount={10000}
          //  helperText={"Helper Text"}
            label={"Description"}
          />
        </div>
        {/* <p className="MuiFormHelperText-root" style={{ marginTop: 3, marginBottom: 0 }}>
          <span
            style={{
              color: "rgb(211, 216, 218)",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span style={{ fontSize: 12, marginTop: "4px", }}>Helper text</span>
            <span
              style={{
                color: "rgb(182, 185, 185)",
                alignSelf: "flex-end",
                fontSize: 12,
                marginTop: "4px",
              }}
            >
              CHAR 1246/10000
            </span>
          </span>
        </p> */}
      </div>
    </>
  );
  const checkChanges = (boolean) => {
    setCheckChanges(boolean)
  }
  const style = {
    width: '100%',
  };
  const discardChanges = () => {
    setCheckChanges(false)
    setAlertModal(false)
    setValue(dischard_changes)
    setChecked(Object.keys(organizationData).length ? organizationData.quickAccess : false);
    setHomePageButton(Object.keys(organizationData).length ? organizationData.enablePage : false)
    setTitle(Object.keys(organizationData).length ? organizationData.title : '')
    if (unblockHandle) {
      unblockHandle.current()
    }
    if (saved_route) {
      props.history.push(saved_route)
    }
  };
  const Alertstyle = {
    width: 470,
    marginLeft: '35%'
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          <PageHeaderWithTabs
            back={() => props.history.push('/website/pages')}
            value={value}
            title="Privacy Policy"
            object={object}
            disabled={changes ? false : true}
            indicatorColor="primary"
            style={{ color: "#2A82F6", border: "1px solid black" }}
            handleChange={(x, y) => handleChange(x, y)}
            indicatorColor="#2A82F6"
            saveStyles={saveStyles}
            publishStyles={publishStyles}
            hiddenDraft={true}
            paperStyles={paperStyles}
            saveChanges={() => saveChanges()}
            publish={() => publish()}
          />
          {
            showAlert && success && success === 'Publish' ? (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={Your_changes_has_been_published}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            ) : showAlert && success && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={Your_changes_has_been_saved_as_draft}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            )
          }
          {
            error && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            QuickLimit && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={quick_access_limit_axeeded}
                  alertType={"error"}
                  onClick={() => setQuickLimit(false)}
                />
              </div>
            )
          }
        </>
      }
      content={
        Loader ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <>
            <div className="formBody">
              <div>
                <AlertDialogueAdvance
                  title="You have unsaved changes!"
                  description='There are unsaved changes. If you would like to save changes, press the "SAVE AS DRAFT" button.'
                  cancelText="Discard Changes"
                  confirmText="PUBLISH"
                  open={open}
                  handleCancel={() => publish()}
                  handleClose={() => handleClose()}
                  handleConfirm={() => discardChanges()}
                  styles={Alertstyle}
                />
              </div>
              {value === 1 && (
                <div className="page_body">
                  <Typography className="page_form_title">Page Settings</Typography>
                  <Grid className="form_body">
                    <Grid container item xs={6} spacing={3} >
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={Title}
                            onChange={(e) => {
                              setTitle(e.target.value); checkChanges(true); setErrorFound(false)
                            }}
                            InputProps={false}
                            phone={false}
                            error={ErrorFound}
                            search={false}
                            count={20}
                            style={styles.BaseTextField}
                            color="primary"
                            disabled={false}
                           // helperText="Helper text"
                            label="Page Title"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="page_switch">
                          <FormSwitch
                            style={styles.BaseTextField}
                            headline="Enable Page"
                            body="Disabled pages can’t be accessed by your site visitors."
                            values={homepageButton}
                            handleSwitchChange={() => { setHomePageButton(!homepageButton); checkChanges(true) }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="SolidBorder"></div>
                        <div className="checkBox">
                          <BaseCheckBox
                            label="Quick Access"
                            checked={checked}
                            color="secondary"
                            showHelperText={true}
                            helperText="Add to left navigation under QUICK ACCESS for quickly accessing the page"
                            onChange={(e) => { setChecked(e.target.checked); checkChanges(true) }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              )}
              {value === 0 && <div>
                <div style={{ border: "1px solid #E8E9E9", marginTop: 24 }}>
                  <BasePaper
                    context={info}
                    square={false}
                    paperStyle={styles.paperStyleNext}
                  />
                </div>
              </div>}
            </div>
          </>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  );
}
const mapStateToProps = (state) => {
  return {
    pages: state.pages,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatchPagesAction: (data) => dispatch(PagesAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
