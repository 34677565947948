import * as constants from "../constants";

function addOrgHandler(
  name,
    type,
    email,
    primary_phone,
    taxId_ein,
    tax_exemption_status,
  
    line1,
    city,
    state,
    postal_code,
    orgId
) {
  return {
    type: constants.ADD_ORG,

    payload: {
      name,
      type,
      email,
      primary_phone,
      taxId_ein,
      tax_exemption_status,

      line1,
      city,
      state,
      postal_code,
      orgId,
    },
  };
}

function connectOrgHandler(payload){
  return{
    type:constants.ADD_ORG_STRIPE,
    payload
  }
}

function getOrgData(payload){
  return{
    type:constants.GET_ORG_DATA,
    payload
  }
}

export { addOrgHandler,connectOrgHandler,getOrgData };
