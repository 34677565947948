  import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import { BaseSelect, AlertDialogue } from "@dclab/dc-react-ui-kit";

import ImageUploader from './Uploader.js';

import { AlertSystem , AlertAction } from "@dclab/dc-react-ui-kit";

import messages_content from '../../utils/messages.json';

import ToggleButton from '@material-ui/lab/ToggleButton';
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Button from '@material-ui/core/Button';


import { MediaGroup, RenderLoadingIcon, TabPanel, MediaDetails, DisplayRightDrawerMedia, MediaStoreTotal } from "./Functions";

import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import axios from "axios";

import BaseSelect2 from './Baseselect2';
import './style.css';

const orgEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")
) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}

const orgBaseUrl = orgEnv[currentEnv];
const totalCapacity = 100 ;

const {delete_media_message} = messages_content; 

class MediaLibraryClass extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mediaItems: [],
      mediaDates: [],
      baseSelect: {
        type: '/all-type',
        date: '/'
      },
      index: '',
      deleteModalOpen: false,
      deleteMediaItemId: '', 
      setValues: '',
      value: 0,
      openRightSideDrawer: false,
      setValue: 0,
      sideBar: 0,
      loadingIcon: true,
      progress: 60, setProgress: 60,
      alignment: 'left',
      setAlignment: 'left',
      cancelClicked: false,
      deleteSuccess: false , 
      count: {
        totalItems: 0,
        maxLimit: 2,
        percentage: 9.00, 
        totalSize: 0.00 
      },
      detailsMedia: {
        imageMetaData: {},
        metaData: {}        
      },
      bootstrapValues: {
        md: 12,
        sm: 12,
        xs: 12,
      },
      objNumber: null
    };

    this.mediaClickEvent = this.mediaClickEvent.bind(this);
    this.deleteMediaItem = this.deleteMediaItem.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.changeMediaType = this.changeMediaType.bind(this);
    this.updateFromChild = this.updateFromChild.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.clickListener = this.clickListener.bind(this);
    this.changeDateSelect = this.changeDateSelect.bind(this);
    this.startShowingLoadingIcon = this.startShowingLoadingIcon.bind(this);

    this.mediaItemRef = React.createRef();
    this.rightDrawerRef = React.createRef();
  }

  /*showing progress bar when uploading 
  a new image 
  */
  async startShowingLoadingIcon() {
    const newImage = {
      "imageMetaData": { "imageUrl": "", "imageName": "Thumbnail", "imageThumbnail": "", "fileType": "Video", "imageSize": 0 },
      "metaData": { "videoUrl": "", "duration": "" }, "_id": "-1",
      "title": "", "isVideo": false,
      "mediaURL": "", "createdAt": "", "orgId": "5ea0023dc21fb5000714bed8",
      "updatedAt": ""
    };

    this.setState({      
      mediaItems: [newImage, ...this.state.mediaItems]
    })
  }

  async stopShowingLoadingIcon() {
    this.setState({
      loadingIcon: false,
    })
  }

  async changeMediaType(e) {
      
    const path = e.target.value;

    const dateFilter = document.getElementById('media-item-date-filtering').value;

    const token = localStorage.getItem("authToken");

    const response = await this.getMediaItems(token, path + dateFilter);

    this.setState({
      mediaItems: response.data.body.data,
      baseSelect: {
        type: path,
        date: dateFilter
      }
    });

  }

  async changeDateSelect(e) {
      
    const path = document.getElementById('demo-simple-select').value;

    const dateFilter = e.target.value;

    const token = localStorage.getItem("authToken");

    const response = await this.getMediaItems(token, path + dateFilter);

    this.setState({
      mediaItems: response.data.body.data,
      baseSelect: {
        type: path,
        date: dateFilter
      }
    });

  }

  render() {
    
      
    const allMediaItems = [
      {
        value: "/all-type",
        label: "All media items"
      },
      {
        value: "/images",
        label: "Image"
      },
      {
        value: "/videos",
        label: "Video"
      },
    ];
  
    const styles = {
      select: {
        margin: 8,
        minWidth: 120,
        height: 47,
        width: 120,
        margin: 8,
        border: "#D3D8DA",
        width: "100%",
      }
    };

       
    
    const UploadEndPoint = `${orgBaseUrl}/medias`;

    const modalStyle = {
      width: 470,
      marginLeft: 800
    };
    
    return (     
          
      <div className="MediaLibraryContainerMain">
          
                 
        <div className="MediaLibContainerinner" >

          <div className="MediaLibLeftColm">

            {this.state.count.percentage > 75 ? (
              <AlertSystem
                error={true}
                btnText="Upgrade"
                className="upgradeAlertBox"
                alertTitle={"Your storage is almost full"}
                alertMessage={
                  "Your storage is almost full. Please upgrade or delete some images."
                }
              />
            ) : (
              ''
            )}
  
            <div className="MediaHeader">

              <div className="PageHeadingTopleft">Media Library</div>
              <ImageUploader
                loadingIcon={this.startShowingLoadingIcon}
                className="MediaAddButton pic-btn"
                buttonStyles={{ margin: "10px" }}
                endPoint={UploadEndPoint}
                onChange={this.updateFromChild}
                maxLimit={totalCapacity - this.state.count.totalSize}
              />

              {this.state.deleteSuccess && (
                  <div
                      style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                  >
                      <AlertAction
                          alert={delete_media_message}
                          alertType={"success"}
                          onClick={() => this.setState({ deleteSuccess: false })}
                      />
                  </div>
              )}     
            </div>


            <div className="MediaButtonHeader">

              <div className="innercolleft">
                <Grid container spacing={3} >

                  <Grid item md={3} sm={3} xs={12} >
                    <BaseSelect
                      value={this.state.baseSelect.type}
                      onChange={this.changeMediaType}
                      object={allMediaItems}
                      label=""
                    />
                  </Grid>

                  <Grid item md={3} sm={3} xs={12} >
                    <BaseSelect2
                      value={this.state.baseSelect.date}
                      onChange={this.changeDateSelect}
                      object={this.state.mediaDates}
                      style={styles.select}
                      label=""
                      uniqueid="media-item-date-filtering"
                    />
                  </Grid>
                </Grid>
              </div>
              <div className="clearfix"></div>
            </div>
            {this.state.loadingIcon ? (
              <RenderLoadingIcon showloading={this.state.loadingIcon} />
            ) : (
              <TabPanel value={this.state.value} index={0}>
                <MediaGroup refitem={this.mediaItemRef} mediaList={this.state.mediaItems} handler={this.mediaClickEvent} />
              </TabPanel>
            )
            }
  
          </div>
          
          {this.state.openRightSideDrawer ? (
            <div className="MediaLibRightDrawerContainer">
              <div ref={this.rightDrawerRef} className="mdLibSideBar">
  
                <DisplayRightDrawerMedia isVideo={this.state.detailsMedia.isVideo} detailsMedia={this.state.detailsMedia} />

                <div className="FetureImgDetails">

                  <MediaDetails object={this.state.detailsMedia} />

                  <div className="DeleteImgButton">
                    <Button color="secondary" mediaSize={ this.state.detailsMedia?.imageMetaData?.imageSize ? this.state.detailsMedia.imageMetaData.imageSize: 0 } onClick={this.deleteMediaItem} mediaid={this.state.detailsMedia._id} ><DeleteForeverRoundedIcon /> Delete permanently</Button>
                    <AlertDialogue
                      title="Are you sure?"
                      description="Are you sure you want to delete this media item?
                                      It will be removed permanently."
                      cancelText="Cancel"
                      confirmText="Confirm"
                      open={this.state.deleteModalOpen}
                      handleCancel={this.handleCloseModal}
                      handleClose={this.handleCloseModal}
                      handleConfirm={this.handleConfirm}
                      styles={modalStyle}
                    />
                  </div>

                  <div className="BootmInfodetailsContainer">

                    <MediaStoreTotal objects={this.state.count} />

                  </div>
                </div>

              </div>
            </div>
          ) : (
            <div />
          )}

        </div>

      </div>


    );
  }

  async mediaClickEvent(e) {
    const objNumber = (e.target.getAttribute('objnr'));
    console.log("objNumber", objNumber, this.state.objNumber)

    if (this.state.objNumber === objNumber) {
      this.setState({
        openRightSideDrawer: false,
        objNumber: null,
        bootstrapValues: {
          md: 12,
          sm: 12,
          xs: 12
        },
      });
    } else {
      this.setState({
        detailsMedia: this.state.mediaItems[objNumber], 
        openRightSideDrawer: true,
        objNumber: objNumber,
        bootstrapValues: {
          md: 9,
          sm: 9,
          xs: 12
        },
      });
    }
  }

  async deleteMediaItem(e) {
    const mediaId = (e.currentTarget.getAttribute('mediaid'));
    const mediaSize = e.currentTarget.getAttribute('mediaSize'); 
    this.setState({
      deleteModalOpen: true, 
      deleteMediaItemId: mediaId,
      mediaSize: mediaSize,
    });   
        
  }
  async handleConfirm(e) {
    const mediaId = this.state.deleteMediaItemId;
    const token = localStorage.getItem("authToken");
    const mediaSize = this.state.mediaSize;

    // this.setState({
    //   loadingIcon: false,
    // });
    
    this.setState({
      loadingIcon: true,
      deleteModalOpen: false,
      openRightSideDrawer: false,      
    });

    // this.setState({  })
    
    const deleteStatus = await this.deleteItem(mediaId, token);

    if (deleteStatus) {
      const path = document.getElementById('demo-simple-select').value;

      const dateFilter = document.getElementById('media-item-date-filtering').value;
      const response = await this.getMediaItems(token, path + dateFilter);

      let count = this.state.count;

      count.totalItems = count.totalItems - 1;
      count.totalSize = count.totalSize - (mediaSize / 1024);
      count.percentage = count.totalSize * 100 / totalCapacity;

      this.setState({
        mediaItems: response.data.body.data,
        bootstrapValues: {
          md: 12,
          sm: 12,
          xs: 12
        },
        count: count,
        deleteSuccess: true, 
      });
    }
    this.setState({      
      mediaId: '',
      loadingIcon: false,
    });
  }

  async handleCloseModal(e) {
    this.setState({
      deleteModalOpen: false,
      cancelClicked: true,
      mediaId: '',
    });
  }

  async deleteItem(mediaId, token) {
    
    return new Promise((resolve, reject) => {
      axios
        .delete(`${orgBaseUrl}/medias/${mediaId}/delete`, {
          headers: {
            token
          }
        })
        .then((res) => {          
          resolve(res);
        })
        .catch((error) => {          
          reject(error);
        });
    });
  }

  async clickListener(e) {
    // console.log( this.mediaItemRef.current );
    if (this.mediaItemRef && this.mediaItemRef.current && !this.mediaItemRef.current.contains(e.target)) {
      if (this.rightDrawerRef && this.rightDrawerRef.current && this.rightDrawerRef.current.contains(e.target)) {

      } else {
        if (this.state.cancelClicked) {
          this.setState({
            cancelClicked: false,
          })
        } else {
          this.setState({
            openRightSideDrawer: false,
            bootstrapValues: {
              md: 12,
              sm: 12,
              xs: 12
            },
          })
        }
        
      }
      
    }
  }

  async componentDidMount() {
    this.setState({
      loadingIcon: true
    });

    // Attach the listeners on component mount.
    document.addEventListener('click', this.clickListener);
    // document.addEventListener('keyup', escapeListener);

    const token = localStorage.getItem("authToken");
    
    const response = await this.getMediaItems(token, '/all-type');

    let totalSize = 0;
    
    let totalMediaItem = 0;
    let mediaCreationDate = [
      {
        value: '/',
        label: 'All dates'
      }
    ]; 
    let tempArray = [];
    for (const element in response.data.body.data) {
      
      const time = new Date(response.data.body.data[element].createdAt);
      const year = time.getFullYear(); 
      const month = time.getMonth() + 1;
      const monthName = time.toLocaleString('default', { month: 'short' });
      const dateObject = {
        label: monthName + ' ' + year,
        value: '/' + year + '-' + month
      };
      
      if (tempArray.indexOf(dateObject.value) < 0) {
        mediaCreationDate.push(dateObject);
        tempArray.push(dateObject.value);
      }    

      if (!response.data.body.data[element].isVideo) {
        if( response.data.body.data[element] && response.data.body.data[element].imageMetaData){
          totalSize = totalSize + response.data.body.data[element].imageMetaData.imageSize;
        }
        
        
      }
      totalMediaItem++;
    }
    totalSize = totalSize / 1024;
    const percentage = totalSize * 100 / totalCapacity;

    const count = {
      totalItems: totalMediaItem,
      maxLimit: totalCapacity,
      percentage: percentage,
      totalSize: totalSize 
    }

    this.setState({
      mediaItems: response.data.body.data,
      count: count,
      loadingIcon: false,
      mediaDates: mediaCreationDate,
    });
  }

  async updateFromChild(size) {
    const token = localStorage.getItem("authToken");

    const path = document.getElementById('demo-simple-select').value;

    const dateFilter = document.getElementById('media-item-date-filtering').value;
    
    const response = await this.getMediaItems(token, path + dateFilter);
    
    

    let count = this.state.count;

    count.totalItems += 1;
    
    count.totalSize += (size / 1024);

    count.percentage = (count.totalSize * 100 / totalCapacity);

    this.setState({
      mediaItems: response.data.body.data,
      count: count,
    });
  }

  async getMediaItems(token, path = '') {
    
    if (path.substr(-1) === '/') {
      path = path.substr(0, path.length - 1);
    }

    return new Promise((resolve, reject) => {
      axios
        .get(`${orgBaseUrl}/medias${path}`, {
          headers: {
            "Content-Type": "application/json",
            token
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

}

export default MediaLibraryClass;

