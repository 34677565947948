import React, {useState, useEffect} from "react";
import Chip from '@material-ui/core/Chip';
import { SimpleTable, } from "@dclab/dc-react-ui-kit";
import './style.css';
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from 'moment';
import { withRouter } from "react-router-dom";

function Manage(props) {
  const [plans, setplans] = useState([])
  const [apps, setapps] = useState([])
  const [planSubscription, setplanSubscription] = useState([])
  const [integrations, setintegrations] = useState([])

  useEffect(() => {
    setplans(props.plans)
    props.applications?.length && setapps(props.applications)
    props.integrations?.length && setintegrations(props.integrations)
}, [props.plans, props.applications, props.integrations])

useEffect(() => {
  if(props.planSubscription?._id) {
    const arr = Object.keys(props.planSubscription)
    .filter(v => {
      return props.planSubscription[v] != null && typeof props.planSubscription[v] === 'object'
    }).map(key => ({ ...props.planSubscription[key], planName: [key][0] }))
    setplanSubscription(arr)
    console.log("arrs", arr)
  }
}, [props.planSubscription])

   //console.log("plans: ", plans)
  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  let col = [
    // { id: "NO", label: "NO.", width: "auto" },
    { type: "url", name: "single_pic", label: "", width: '22%' },
    { type: "string", name: "name", label: "PLAN", width: '15%' },
    { type: "string", name: "category", label: "PRICE", width: '15%' },
    { type: "string", name: "category", label: "BILLING CYCLE", width: '20%' },
    { type: "string", name: "category", label: "STARTED", width: '25%' },
    { id: "STATUS", label: "STATUS", width: '15%' },
    { type: "action", name: "action", label: "", width:"10%" },
];

  const capitalizeFLetter = (val) => {  
    return val ? `${val[0].toUpperCase() + val.slice(1)}` : ""; 
  } 

  let rows = [];

  console.log("planSubscription: ", planSubscription)
  planSubscription?.length && planSubscription.forEach((plan, i) => {
    console.log("plan: ", plan)
    if(plan.planName?.toLowerCase() === "extraintegrations") {
      plan.selectedIntegrations?.length && plan.selectedIntegrations.forEach(int => {
        
        let planPayment = plans?.stripeData?.length ? plans?.stripeData?.find((el) => el?.subscription_plan?.toLowerCase() === int?.name?.toLowerCase()) : {};
        let integration = integrations?.length ? integrations?.find((el) => el?.integrationApi?.toLowerCase() === int?.integrationApi?.toLowerCase()) : {};
        rows.push([
          { type: "string", name: "textImg", src: integration?.imageMetaData?.imageUrl ? integration.imageMetaData.imageUrl : "https://i.ibb.co/mhTNNDX/DEENCONNECT-Integration-Icon.png", primaryText: integration?.name, initials: "", plan: {...planPayment, orgId: plans?.orgId, planSubscriptionId: plans?.planSubscriptionId, planName: integration?.name, planType: "integration"} },
          { type: "string", name: "name", value: capitalizeFLetter(integration?.pricing?.pricingType) },
          { type: "string", name: "category", value: int.paymentFrequency === "monthly" ? `$${int.price}/mo` : int.price ? `$${(parseFloat(int.price) / 12).toFixed(2)}/mo` : "$0/mo"  },
          { type: "string", name: "category", value: capitalizeFLetter(int.paymentFrequency) ?? "N/A"},
          { type: "string", name: "category", value: int?.createdAt ? moment(int?.createdAt).format('MMM DD, YYYY, hh:mm A') : planPayment?.created ? moment(planPayment.created * 1000).format('MMM DD, YYYY, hh:mm A') : "N/A" },
          {
            type: "status",
            status: "success",
            value: "Active",
          },
          { type: "action", status: "details", value: "Manage Plan" }
        ])
      });
    } else if(plan.plan) {
      let planPayment = plans?.stripeData?.length ? plans?.stripeData?.find((el) => el?.subscription_plan?.toLowerCase() === plan?.planName?.toLowerCase()) : {};

      let app = apps?.length ? apps?.find((el) => el?.name?.toLowerCase() === plan?.planName?.toLowerCase()) : {};
      
      

      rows.push([
        { type: "string", name: "textImg", src:  app?.iconMetaData?.iconUrl ? app.iconMetaData.iconUrl : "https://i.ibb.co/mhTNNDX/DEENCONNECT-Integration-Icon.png", primaryText: app?.displayName ?? app?.name, initials: "", plan: {...planPayment, orgId: plans?.orgId, planSubscriptionId: plans?.planSubscriptionId, planName: plan?.planName, planType: "application"} },
        { type: "string", name: "name", value: plan.plan ? capitalizeFLetter(plan.plan) : "" },
        { type: "string", name: "category", value: plan.paymentFrequency === "monthly" ? `$${plan.total}/mo` : plan.total ? `$${(parseFloat(plan.total) / 12).toFixed(2)}/mo` : "$0/mo" },
        { type: "string", name: "category", value: capitalizeFLetter(plan.paymentFrequency) ?? "N/A" },
        { type: "string", name: "category", value: plan?.createdAt ? moment(plan?.createdAt).format('MMM DD, YYYY, hh:mm A') : planPayment?.created ? moment(planPayment.created * 1000).format('MMM DD, YYYY, hh:mm A') : "N/A" },
        {
          type: "status",
          status: "success",
          value: "Active",
        },
        { type: "action", status: "details", value: "Manage Plan" }
      ])
    }
  });
  // ============================== Table End ======================================//

  const actionLinkHandler = (data) => {
    console.log("data[0]?.plan: ", data[0]?.plan)
    let details;
      if(data[0]?.plan?.planType === "application") {
        details = apps?.find((el) => el?.name?.toLowerCase() === data[0]?.plan?.planName)
        details = {...details, appType: "application"}
      } else {
        details = integrations?.find((el) => el?.name?.toLowerCase() === data[0]?.plan?.planName?.toLowerCase());
        details = {...details, appType: "integration"}
      }

      console.log("id is: ", data[0]?.plan, details);
      props.history.push({pathname: `/organization/plan-details`, state: { plan: data[0].plan, details: details }});
}


  return (
    <div>
        { props.loading ? (
        <div
        style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 30,
        }}
        >
        <CircularProgress color="primary" />
        </div>
        ) : (
        <SimpleTable
        showHeader={true}
        showCol={false}
        title="Manage Plans"
        rows={rows}
        columns={col}
        colSpan={16}
        actionSendButton={true}
        classProps={changeClassProps()}
        donationsCount={rows && rows.length > 0 ? 1 : 0}
        noTitle="No Plans added"
        noPara="Added Plans will be displayed and managed here"
        noBtnText="Add New Plans"
        actionLink={(data) => actionLinkHandler(data)}
        showIconCol={false}
        showFooter={true}
        showAdd={false}
        iconTextSize={16}
        />
        )}
    </div>
  )
}


export default withRouter(Manage);
