import React, { Component } from 'react'
import { SimpleTable } from "@dclab/dc-react-ui-kit";
import Pic1 from "../../assets/img/Ellipse_1.png";
import Pic2 from "../../assets/img/Ellipse_2.png";
import Pic3 from "../../assets/img/Ellipse_3.png";
export default class Site_Menus extends Component {
  render() {
    let classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    let col = [
      { type: "number", name: "serial_number", label: "NO.", minWidth: 30 },
      { type: "link", name: "invoice_number", label: "COL W/LINK", minWidth: 120 },
      { type: "string", name: "name", label: "COL W/BOLD CELL", minWidth: 170 },
      { type: "string", name: "date", label: "DATE", minWidth: 120 },
      { type: "string", name: "date/time", label: "DATE/TIME", minWidth: 120 },
      { type: "string", name: "time", label: "TIME", minWidth: 90 },
      { type: "string", name: "amount", label: "AMOUNT", minWidth: 90 },
      { type: "string", name: "category", label: "COL W/TEXT", minWidth: 290 },
      { type: "string", name: "frequency", label: "COL W/TEXT", minWidth: 120 },
      { type: "url", name: "single_pic", label: "COL W/IMG", minWidth: 90 },
      {
        type: "array",
        name: "multi_images",
        label: "COL W/MULTIPLE IMG",
        minWidth: 170,
      },
      { type: "string", name: "textImg", label: "COL W/IMG & TEXT", minWidth: 280 },
      { type: "status", name: "status", label: "STATUS", minWidth: 110 },
      { type: "action", name: "action", label: "ACTION", minWidth: 120 },
      { type: "action", name: "actionIcons", label: "ACTION", minWidth: 90 },
    ];
    let rows = [
      [
        { type: "id", value: "01" },
        { type: "link", name: "invoice_number", value: "1848-5138" ,url:'https://www.google.com' },
        { type: "string", name: "name", value: "Hassan Khan" },
        { type: "string", name: "date", value: "Jan 20, 2020" },
        { type: "string", name: "date/time", value: "Jan 23, 2020 8:00 AM (EST)" },
        { type: "string", name: "time", value: "4:50 AM" },
        { type: "string", name: "amount", value: "$30" },
        { type: "string", name: "category", value: "Zakat" },
         {type:"string",name:"frequency",value:"One-Time"},
        { type: "url", name: "single_pic", src: Pic1 },
        {
          type: "array",
          name: "multi_images",
          value: [
            { name: "img1", type: "url", src: Pic1 },
            { name: "img2", type: "url", src: Pic2 },
            { name: "img3", type: "url", src: Pic3 },
          ],
        },
        {
          name: "textImg",
          type: "string",
          primaryText: "Bilal Masjid of New York",
          secondaryText: "Brooklyn, NY",
          src:
            "https://media.gettyimages.com/photos/jama-masjid-mosque-in-delhi-picture-id505757382?s=612x612",
        },
        { type: "status", status: "success", value: "Success" },
        { type: "action", status: "details", value: "see details" },
      ],
      [
        { type: "id", value: "01" },
        { type: "link", name: "invoice_number", value: "1848-5138",url:'https://www.google.com' },
        { type: "string", name: "name", value: "Hassan Khan",  },
        { type: "string", name: "date", value: "Jan 20, 2020" },
        { type: "string", name: "date/time", value: "Jan 23, 2020 8:00 AM (EST)" },
        { type: "string", name: "time", value: "4:50 AM" },
        { type: "string", name: "amount", value: "$30" },
        { type: "string", name: "category", value: "Zakat" },
           {type:"string",name:"frequency",value:"One-Time"},
        { type: "url", name: "single_pic", src: Pic1 },
        {
          type: "array",
          name: "multi_images",
          value: [
            { name: "img1", type: "url", src: Pic1 },
            { name: "img2", type: "url", src: Pic2 },
            { name: "img3", type: "url", src: Pic3 },
          ],
        },
        {
          name: "textImg",
          type: "string",
          primaryText: "Bilal Masjid of New York",
          secondaryText: "Brooklyn, NY",
          src:
            "https://media.gettyimages.com/photos/jama-masjid-mosque-in-delhi-picture-id505757382?s=612x612",
        },
        { type: "status", status: "success", value: "Success" },
        { type: "action", status: "details", value: "submit" },
      ],
    ];
    return (
      <section style={styles.contents}>
        <SimpleTable
          showHeader={true}
          title="Site Menus"
          width="98%"
          rows={rows}
          columns={col}
          colSpan={16}
          actionSendButton={true}
          classProps={changeClassProps()}
          donationsCount={rows?.length > 0 ? 1 : 0}
          noTitle="No Menus added"
          noPara="Added Menus will be displayed and managed here"
          noBtnText="Add New Menus"
          onInviteClick={() =>
            this.props.history.push(
              "/organization/services/add-new-service"
            )
          }
          actionLink={(name) => this.details(name)}
          showIconCol={true}
          editAction = {(e) => this.editService(e)}
          deleteAction = {(e) => this.deleteServiceAction(e)}
          showFooter={true}
          showAdd={false}
          onAddClick={() =>
            this.props.history.push(
              "/organization/services/add-new-service"
            )
          }
          iconTextSize={16}
        />
      </section>
    )
  }
}
const styles = {
  contents: {
    padding: 24,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: "76%",
    marginTop: 70,
    marginLeft: "20%",
  },
}