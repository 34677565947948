import axios from "axios";
import { websocketOnMessage } from "../../../../utils/socketservice";
const baserUrl =
    "https://cors-anywhere.herokuapp.com/http://18.211.207.245:3000";
const env = {
    prod: process.env.REACT_APP_PAYMENT_PROD_API,
    dev: process.env.REACT_APP_PAYMENT_DEV_API
};
const orgEnv = {
    prod: process.env.REACT_APP_BASEURL_PROD_API,
    dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
    window.location.host.includes("amazon") ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("shapla")
) {
    currentEnv = "dev";
} else {
    currentEnv = "prod";
}
const orgBaseUrl = orgEnv[currentEnv];
export function getAllSalah(orgId, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/salah?orgId=${orgId}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function DeletesalahWebsite(id, token) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${orgBaseUrl}/salah/deleteWebsiteFromSalah/${id}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function Deletesalah(id, token, orgId, app) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${orgBaseUrl}/salah/${id}?orgId=${orgId}&application=${app}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                if (res.data && res.data.body && res.data.body.data) {
                    websocketOnMessage({
                        "notify": "delete salah",
                        "orgId": orgId,
                        "data": res.data.body.data
                    })
                }
                resolve(res);
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function deleteReminderWebsite(id, token) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${orgBaseUrl}/reminders/deleteWebsiteFromReminder/${id}`, {
                headers: {
                    "token": token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function deleteReminder(id, token, orgId) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${orgBaseUrl}/reminders/${id}`, {
                headers: {
                    "token": token
                },
            })
            .then((res) => {
                if (res.data && res.data.body && res.data.body.data) {
                    websocketOnMessage({
                        "notify": "delete reminder",
                        "orgId": orgId,
                        "data": res.data.body.data
                    })
                }
                resolve(res);
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function getReminder(id, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/reminders/${id}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function getSalah(id, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/salah/${id}`, {
                headers: {
                    token
                }
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function getEvent(id, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/events/${id}`, {
                headers: {
                    token
                }
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function deleteEvent(id, token, orgId) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${orgBaseUrl}/events/${id}`, {
                headers: {
                    "token": token
                },
            })
            .then((res) => {
                if (res.data && res.data.body && res.data.body.data) {
                    websocketOnMessage({
                        "notify": "delete event",
                        "orgId": orgId, // instead of announcement obj send orgId
                        "data": res.data.body.data
                    })
                }
                resolve(res);
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function getReminders(orgId, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/reminders?orgId=${orgId}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function updateReminder(data, token, id) {
    return new Promise((resolve, reject) => {
        axios
            .put(`${orgBaseUrl}/reminders/${id}`, data, {
                headers: {
                    token
                },
            })
            .then((res) => {
                if (res.data && res.data.body && res.data.body.data) {
                    websocketOnMessage({
                        "notify": "update reminder",
                        "orgId": data.orgId, // instead of announcement obj send orgId
                        "data": res.data.body.data
                    })
                }
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function addReminder(orgId, token) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${orgBaseUrl}/reminders`, orgId, {
                headers: {
                    token
                },
            })
            .then((res) => {
                if (res.data && res.data.body && res.data.body.data) {
                    websocketOnMessage({
                        "notify": "add reminder",
                        "orgId": orgId.orgId, // instead of announcement obj send orgId
                        "data": res.data.body.data
                    })
                }
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function addAnnoucement(orgId, token) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${orgBaseUrl}/announcements`, orgId, {
                headers: {
                    token
                },
            })
            .then((res) => {
                if (res.data && res.data.body && res.data.body.data) {
                    websocketOnMessage({
                        "notify": "add annoucement",
                        "orgId": orgId.orgId, // instead of announcement obj send orgId
                        "data": res.data.body.data
                    })
                }
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function updateAnnoucement(data, token, id) {
    return new Promise((resolve, reject) => {
        axios
            .put(`${orgBaseUrl}/announcements/${id}`, data, {
                headers: {
                    token
                },
            })
            .then((res) => {
                if (res.data && res.data.body && res.data.body.data) {
                    websocketOnMessage({
                        "notify": "update annoucement",
                        "orgId": data.orgId, // instead of announcement obj send orgId
                        "data": res.data.body.data
                    })
                }
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function UpdateSalah(data, token, id) {
    return new Promise((resolve, reject) => {
        axios
            .put(`${orgBaseUrl}/salah/${id}`, data, {
                headers: {
                    token
                },
            })
            .then((res) => {
                if (res.data && res.data.body && res.data.body.data) {
                    websocketOnMessage({
                        "notify": "update salah",
                        "orgId": data.orgId, // instead of announcement obj send orgId
                        "data": res.data.body.data
                    })
                }
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function addSalah(data, token) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${orgBaseUrl}/salah`, data, {
                headers: {
                    token
                },
            })
            .then((res) => {
                if (res.data && res.data.body && res.data.body.data) {
                    websocketOnMessage({
                        "notify": "add salah",
                        "orgId": data.orgId, // instead of announcement obj send orgId
                        "data": res.data.body.data
                    })
                }
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function addNewEvent(data, token) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${orgBaseUrl}/events`, data, {
                headers: {
                    token
                },
            })
            .then((res) => {
                if (res.data && res.data.body && res.data.body.data) {
                    websocketOnMessage({
                        "notify": "add event",
                        "orgId": data.orgId, // instead of announcement obj send orgId
                        "data": res.data.body.data
                    })
                }
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function updateEvent(data, token, id) {
    return new Promise((resolve, reject) => {
        axios
            .put(`${orgBaseUrl}/events/${id}`, data, {
                headers: {
                    token
                },
            })
            .then((res) => {
                if (res.data && res.data.body && res.data.body.data) {
                    websocketOnMessage({
                        "notify": "update event",
                        "orgId": data.orgId, // instead of announcement obj send orgId
                        "data": res.data.body.data
                    })
                }
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function getAnnoucements(data, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/announcements`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function getAnnoucementsWebsite(data, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/announcements?website=5ee9daf01e75ea1f2cfdc872`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function getAnnoucement(id, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/announcements/${id}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function deleteAnnoucementWebsite(id, token) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${orgBaseUrl}/announcements/deleteWebsiteFromAnnouncement/${id}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function deleteAnnoucement(id, token, orgId, app) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${orgBaseUrl}/announcements/${id}?orgId=${orgId}&application=${app}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                if (res.data && res.data.body && res.data.body.data) {
                    websocketOnMessage({
                        "notify": "delete annoucement",
                        "orgId": orgId,
                        "data": res.data.body.data
                    })
                }
                resolve(res);
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function organizations(orgId, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/organizations/${orgId}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function getWakt(lat, long, method, timezone,daytimeSaving, juristic='Standard') {

    console.log("Lat: ", lat);
    console.log("long: ", long);
    console.log("method: ", method);
    console.log("Timezone: ", timezone);
    return new Promise((resolve, reject) => {
        axios.get(`${orgBaseUrl}/wakt?duration=1&w_method=${method}&latitude=${lat}&longitude=${long}&timezone=${timezone}&juristic=${juristic}&daytimeSaving=${daytimeSaving}`)
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function getSpeakers(id, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/speakers?orgId=${id}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}
export function getEvents(orgId, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/events`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function getSingleEvent(id, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/events/${id}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function getaddressFromPostalCode(postalCode) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/shared/addressFromPostalCode?postal_code=${postalCode}`)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}