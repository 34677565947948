import * as constants from '../constants'

const INITIAL_STATE = {
    email: "abc@gmail.com",
    code: "123",
   
  };
  
  export default (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
      case constants.FORGOT_PASSWORD:
        return {
          ...state,
          email: action.payload.email,
          code: action.payload.code,
        };

      default:
        return state;
    }
  };
  