import React, { useEffect, useState } from 'react';
import {
  AlertAction,
  MasterLayoutMiddle,
  FooterInner,
} from '@dclab/dc-react-ui-kit';
import { CheckoutPayment } from "master-react-lib";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux';
import { showAlert } from '../../redux/actions/alertActions';

import MasterCardImg from '../../assets/images/masterCardIcon.png'
import VisaCardImg from '../../assets/images/visa-icon.png'
import DiscoverCardImg from '../../assets/images/discovercard.png';
import AmericanExpressImg from '../../assets/images/american-express.png';
import DinersCardImg from '../../assets/images/dinerscard.png';
import HelpImg from '../../assets/images/help-24px.svg';
import NewCardImg from '../../assets/images/new-card.png'
import { addCustomerPaymentMethods } from './api';
import "./billingInfo.css";
import messages_content from '../../utils/messages.json';
import CircularProgress from '@material-ui/core/CircularProgress';
const { payment_method_added_success_message  } = messages_content;


function AddBillingInfo(props) {

    const [cardDetails, setcardDetails] = React.useState({
        cardNumber: "", expiration: "", code: ""
    });
    
    const [addressDetails, setaddressDetails] = React.useState({
        firstName: "", lastName: "", line1: "", line2: "", city: "", state: "", postalCode: "", country: "",
    });
    
    const [saveCardDetails, setsaveCardDetails] = React.useState(false);
    const [error, seterror] = useState(false)
    const [loading, setloading] = useState(false)

    let selectedCardDetails = {
        firstName: "Sheikh", lastName: "Rehman", line1: "Street Address", line2: "", city: "City", state: "State", postalCode: "Zip Code", country: "United States",
    };
    
    const handlePaymentSelectChange = (selectedPaymentCard) => {
        console.log("selectedPaymentCard: ", selectedPaymentCard);
    };

    const onEditBillingDetailClick = () => {
        console.log("onEditBillingDetailClick");
    };

    const creditCardsList = [];
    
    const onPlaceOrderSubmit = async (details) => {
        setloading(true)
        const { cardNumber, expiration, code } = details?.cardData;
        const { firstName, lastName, line1, line2, city, state, postalCode, country } = details?.addressDetails;

        let cardData = {
            cvc: code,
            exp_month: parseInt(expiration.split("/")[0]),
            exp_year: +('20' + expiration.split("/")[1]),
            number: cardNumber
        };
        let billingData = {
          address: {
            line1, line2, city, state, postal_code: postalCode, country
          },
          name: firstName + " " + lastName,
        };
        let orgId = localStorage.getItem("orgID");

        try {
            console.log("formData", cardData, billingData, orgId);
          
            let res = await addCustomerPaymentMethods({cardData, billingData, orgId, saveCardDetails});
            if(res?.data?.error) {
              console.log("error", res?.data?.message)
              seterror(res?.data?.message || "Invalid Payment Credentials");
              setloading(false)
            } else {
              seterror(false)
              setloading(false)
              props.showAlert(payment_method_added_success_message);
              props.history.push('/organization/payment-methods');
            }
        }
            catch (error) {
            console.log(error)
            setloading(false)
            seterror(error?.message || "Invalid Payment Credentials");
        }
    }

    const style = {
        width: '100%',
    };

    return (
        <div className="billing-info-wrapper">
        <MasterLayoutMiddle
          header={
            <>
              <div
                onClick={() =>
                  props.history.goBack()
                }
                className='backBtn'>
                <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
                <p style={{ alignSelf: 'center' }}>Back</p>{' '}
              </div>

              {(error && (
                <div
                  style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                >
                  <AlertAction
                    alert={error}
                    alertType={'error'}
                    onClick={() => seterror(false)}
                  />
                </div>
              ))}
            </>
          }
          isForm={true}
          formData={
            <>
                <div>
                  <h1 className='title_top'>Add New Payment Method</h1>
                  <div className="dasheddBorder"></div>
                </div>    
                {loading && <div
                  style={{
                    position: 'absolute',
                    top: "50%",
                    left: "50%",
                    zIndex: 1000
                  }}
                >
                  <CircularProgress color='primary' />
                </div>}

                {/* <div className="mainTextHeading">Card Information</div> */}
                <CheckoutPayment 
                    creditCardsList={creditCardsList}
                    handlePaymentSelectChange={handlePaymentSelectChange}
                    onPlaceOrderSubmit={onPlaceOrderSubmit}
                    cardDetails={cardDetails}
                    setcardDetails={setcardDetails}
                    addressDetails={addressDetails}
                    setaddressDetails={setaddressDetails}
                    saveCardDetails={saveCardDetails}
                    setsaveCardDetails={(event) => setsaveCardDetails(event)}
                    selectedCardDetails={selectedCardDetails}
                    privacyPolicyLink={"/privacy-policy"}
                    termsOfUseLink={"/terms-of-use"}
                    onEditBillingDetailClick={onEditBillingDetailClick}
                    visaCardImage={VisaCardImg}
                    masterCardImage={MasterCardImg}
                    newCardImage={NewCardImg}
                    discoverCardImage={DiscoverCardImg}
                    americanExpressImage={AmericanExpressImg}
                    dinersCardImage={DinersCardImg}
                    helpImage={HelpImg}
                    ButtonLabel="ADD NOW"
                    ScuredLabel="Secured"
                    checkboxLabel="Make this card my default payment method"
                    BottomHelperText="We don't save your credit card details, your credit card information is safe with our trusted partner Stripe"
                />

            </>
          }
          isFooter={true}
          footer={
            <FooterInner 
              style={style}
              termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
              privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"} 
            />
          }
          isWhiteBackground={true}
        />
        </div>
      );
}

const mapDispatchToProps = (dispatch) => {
  return {
    showAlert: (res) => dispatch(showAlert(res)),
  };
};
export default connect(0, mapDispatchToProps)(AddBillingInfo);