import React, {useEffect, useState} from 'react'
import {SignUpVerification} from '@dclab/dc-react-ui-kit'
import Logo from "../../assets/img/DC_Logo.png";
import './style.css';
import { ResendCode } from '../Signup/api';
import { signupHandler } from '../../redux/actions/signupAction';
import { connect } from 'react-redux';

function SignUpLock(props) {
    const [userDetail, setUserDetail] = useState();
    const [loading, setloading] = useState(false)
    useEffect(() => {
        if(props?.location?.state){
            setUserDetail(props.location.state);
        }
    }, []);

    const click = async (e) => {
        e.preventDefault();
        setloading(true);
        if(userDetail.userName) {
            const obj = {
                userName: userDetail.userName,
            };
            try {
                const res = await ResendCode(obj);
                if(!res.data.body.data.error) {
                    const name = localStorage.getItem('name') ?? '';
                    props.signUp(userDetail.userName, name);
                    props.history.push({
                        pathname: '/verify',
                        state: { 
                            email: userDetail.userName, 
                            password: userDetail.password
                        },
                    });
                }
            } catch (error) {
                console.log(error)
            }
        
        }
        setloading(false);
    }
    return (
        <div className="container-success" style={{position: "relative"}}>
            <div style={{position: "absolute", width: '100%', zIndex: -1}} >
                <div className='Background-Image' style={{ width: '100%', position: 'fixed', height: '100vh' }}>

                </div>
            </div>
            <div style={{paddingTop: 100}}>
                <SignUpVerification 
                    src={Logo}
                    LogoLink="https://shapla.deenconnect.io/"
                    name="verifyCode"
                    title="Your email isn't verified yet"
                    metaTitle="The email associated with this account needs to be confirmed. We can send"
                    email={localStorage.getItem('email')}
                    metaTitleEnd="a 6 digit verification code"
                    onClickVerify={(e) => click(e)}
                    btnText="SEND VERIFICATION CODE & CONTINUE"
                    buttomText = "Not you?"
                    buttomTextLink = {process.env.REACT_APP_Environment === 'dev' ? 'https://shapla.admin.deenconnect.io/' : 'https://admin.deenconnect.io/' }
                    buttomTextEnd = "Change account"
                    isLoading = {loading}
                />
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
      orgToken: state.orgToken.code,
    };
  };

const mapDispatchToProps = (dispatch) => {
    return {
      signUp: (email, fullName) => dispatch(signupHandler(email, fullName)),
    };
  };
export default connect(mapStateToProps, mapDispatchToProps)(SignUpLock);
