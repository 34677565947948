import * as constants from "../constants";
const INITIAL_STATE = {
    orgData: {}
  }
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case constants.GET_ORG_DATA_WEB:
        return {
            orgData: action.payload,
        };
      default:
        return state
    }
  }