import React from "react";
import "./App.css";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Content from "./Pages/Content";
import axios from "axios";
export default function RediectedPage(props) {
  const [uriCode, setURICODE] = React.useState("");
  const [uriError, setURIError] = React.useState("");
  const [loader, setLoader] = React.useState(false);
  React.useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const scope = params.get("scope");
    const code = params.get("code");
    const error = params.get("error");
    if (code) {
      setURICODE(code);
    }
    if (error) {
      setURIError(error);
    }
    setLoader(true);
    axios
      .post(
        "https://1v2bdiahji.execute-api.us-east-1.amazonaws.com/dev/organizations/connectStripe",
        { code, scope }
      )
      .then((res) => {
        setLoader(false);
        console.log(res);
      })
      .catch((err) => {
        setLoader(false);
        setURIError(err);
      });
  }, []);
  return (
    <>
      <Header />
      {uriCode ? (
        <Content loader={loader} message={uriCode} />
      ) : uriError ? (
        <Content loader={loader} message={uriError} />
      ) : null}
      <Footer />
    </>
  );
}
