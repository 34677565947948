import React from "react";
import { useEffect, useState } from 'react';
import { SimpleTable, MasterLayoutMiddle, FooterInner, CardDetailsTable, AlertAction, AlertDialogue } from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Verifiedicon from '../../assets/img/VerifiedIcon.svg'
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import { getItemDetails, deleteTemplate, getGroupContacts, updateGroup , updateGroupCount} from './Functions';
import { ListItemSecondaryAction } from "@material-ui/core";
import moment from 'moment';
import messages_content from '../../utils/messages.json';
import SendIcon from '../../assets/img/add-24px.svg'

const {
    add_com_group_success,
    contact_added_to_group,
} = messages_content;


function GroupsDetails(props) {


    const [contactAddSuccess , setContactAddSuccess ] = useState( false );
    const style = {
        width: '100%',
    };

    let header = {
        tableName: `Social Services`,
    };

    const [itemDetails, setItemDetails] = useState({
        createdAt: '',
        groupName: '',
        groupContacts: '',
        updatedAt: '',
        numberOfContacts: '0',

    });

    const [success, setSuccess] = useState(false);

    let data = [
        {
            field: "Date Added",
            value: moment(itemDetails.createdAt).format('MMM DD, YYYY')
        },

        {
            field: "Last Updated",
            value: moment(itemDetails.updatedAt).format('MMM DD, YYYY')
        },



    ];

    let data2 = [
        {
            field: "Name",
            value: itemDetails.groupName.toString()
        },

        {
            field: "# Of Contacts",
            value: itemDetails.numberOfContacts ? itemDetails.numberOfContacts.toString() : '0'
        },

    ];

    const modalStyle = {
        width: 470,
        marginLeft: 800
    };

    useEffect(() => {
        let checked = true;
        let item;

        async function fetchData() {

            const itemId = (props.match.params.id.toString());
            // console.log( itemId );

            const status = props.match.params.status;

            if (status && status.length) {
                if( status =='contactAddedSuccess' ){
                    setContactAddSuccess( true );
                }else{
                    setSuccess(true);
                }
                
            }

            await getItemDetails(itemId, 'groups').then(response => {
                if (checked) {

                    if (response && response.data.body.data) {

                        item = response.data.body.data;                        

                        setItemDetails(item);
                        // console.log( item.groupContacts );
                    }
                }
            });

            if (item && item.groupContacts && item.groupContacts.length) {
                // console.log( item.groupContacts);
                const newArr = item.groupContacts.filter((a) => a);
                let items ; 
                try{

                    items = await getGroupContacts(newArr.join(','));

                    // console.log( items.data.body.data );
                    let oldDetails = item ; 

                    const oldNumberOfContacts = oldDetails.numberOfContacts;

                    oldDetails.numberOfContacts = items.data.body.data.length ; 

                    setItemDetails( oldDetails );

                    setGroupcontacts(items.data.body.data);

                    if( oldNumberOfContacts != oldDetails.numberOfContacts ){
                        //update group count
                        updateGroupCount( oldDetails.numberOfContacts , props.match.params.id.toString());
                    }

                }catch( err ){

                    console.log( err );

                }
                
                
            }else{
                if( item.numberOfContacts > 0 ){
                    //update group count
                    updateGroupCount( 0 , props.match.params.id.toString());
                }
            }

        }

        fetchData();

        return () => checked = false;

    }, []);

    const [groupContacts, setGroupcontacts] = useState([]);


    let footerDeleteButton = "DELETE GROUP";
    let footerLinkName = " EDIT INFORMATION";

    // HANDLE FOOTER LINK
    const onClick = () => {
        console.log('edit')
    }
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteContactModalOpen, setDeleteContactModalOpen] = useState(false);

    const handleCloseModal = () => {
        setDeleteModalOpen(false);
        setDeleteContactModalOpen(false);
    }

    async function sendMessage(){
        props.history.push('/communication/new-message/cfromgroup@'+ props.match.params.id );
    }

    async function confirmDeleteContact(){
        setDeleteContactModalOpen(false);

        const itemId = props.match.params.id.toString();

        let newGroupContacts = [] ;
        let contactIds = [];
        let olditemDetails = itemDetails ;    

        await groupContacts.map( singleItem =>{
            if( singleItem._id == selectedContactId ){

            }else{
                newGroupContacts.push( singleItem);
                contactIds.push( singleItem._id );
            }
        });       


        const response = await updateGroup( {
            groupName: itemDetails.groupName ,
            numberOfContacts: contactIds.length , 
            groupContacts: contactIds , 
        } ,  itemId);

        if( response ){            

            setGroupcontacts( newGroupContacts );             

            olditemDetails.numberOfContacts = contactIds.length ;
            
            console.log( olditemDetails );

            setItemDetails( olditemDetails);

        }
    }

    async function handleConfirm() {
        setDeleteModalOpen(false);

        const itemId = props.match.params.id.toString();

        const response = await deleteTemplate('groups', itemId);

        props.history.push('/communication/contact-list/deleteSuccesGroup');
    }

    const [selectedContactId , setSelectedContactId] = React.useState('');

    async function deleteContactFromGroup( contactId ){
        setSelectedContactId( contactId ); 
        setDeleteContactModalOpen( true );
    }


    const onDeleteClick = () => {
        setDeleteModalOpen(true);
    }

    const send = (e) => {
        
        if( props.match.params.id )
            props.history.push('/communication/create-new-group/' + props.match.params.id.toString() );
    };

    // ============================== Table Start ======================================//


    let col = [
        { type: 'link', name: 'type', label: 'FIRST NAME', width: '20%' },
        { type: 'link', name: 'type', label: 'LAST NAME', width: '20%' },
        { type: 'link', name: 'type', label: 'PHONE', width: '20%' },
        { type: 'link', name: 'type', label: 'EMAIL ADDRESS', width: '20%', icon: Verifiedicon, },                  
        { type: 'string', name: 'date', label: 'SOURCE', width: '10%' },
        { type: 'action', label: '' },
        { type: 'action', label: '' },

    ];

    
    let rows = [];

    if (groupContacts.length) {
        // console.log( groupContacts );

        rows = groupContacts.map((item, index) => {
            console.log(item);
            return ([
                { type: 'string', name: 'time', value: item.firstName },
                { type: 'string', name: 'time', value: item.lastName },
                { type: 'string', name: 'time', value: '+' + item.phoneNumber },
                { type: 'string', name: 'name', icon: Verifiedicon, value: item.email },
                { type: 'string', name: 'chip', value: 'Admin' },
                { type: 'action', status: 'success', value: 'see details', id: item._id },
            ]);
        });
    }



    // ============================== Table End ======================================//

    return (
        <MasterLayoutMiddle
            header={
                <div
                    onClick={() => props.history.push("/communication/contact-list/groupListView")}
                    className="backBtn"
                >
                    <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                    <p style={{ alignSelf: "center" }}>Back</p>{" "}
                </div>
            }

            content={
                <div>
                    {success && (
                        <div
                            style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                        >
                            <AlertAction
                                alert={add_com_group_success}
                                alertType={"success"}
                                onClick={() => setSuccess(false)}
                            />
                        </div>
                    )}
                    {contactAddSuccess && (
                        <div
                            style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                        >
                            <AlertAction
                                alert={contact_added_to_group}
                                alertType={"success"}
                                onClick={() => setContactAddSuccess(false)}
                            />
                        </div>
                    )}
                    <div style={{ marginTop: "60px", }}>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <CardDetailsTable
                                    data={data}
                                    data2={data2}
                                    twoColumns
                                    header={header}
                                    footerLinkName={footerLinkName}
                                    footerDeleteButton={footerDeleteButton}
                                    onClick={(e) => console.log('hi') }
                                    onDeleteClick={() => onDeleteClick()}

                                    sendbutton={true}
                                    onClick={send}
                                    sendlabel="SEND MESSAGE"
                                    sendIcon={SendIcon}
                                    sendbutton={SendIcon}
                                    onClickSend={sendMessage}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <div className="table_box">
                                    <SimpleTable
                                        showHeader={true}
                                        title="Contact List"
                                        width="100%"
                                        rows={rows}
                                        columns={col}
                                        colSpan={16}
                                        actionSendButton={true}
                                        donationsCount={rows && rows.length > 0 ? 1 : 0}
                                        noTitle="No inquiry submitted yet"
                                        noPara="Inquiries submitted by visitors will be visible and managed here."
                                        noBtnText=""
                                        onInviteClick={() => console.log('invite')}
                                        actionLink={(event, name) => props.history.push('/communication/contact-details/' + event[5].id )}
                                        showIconCol={true}
                                        actionSendButton={true}
                                        deleteAction={(e) =>deleteContactFromGroup(e[5].id)}
                                        showFooter={true}
                                        showAdd={true}
                                        addText="ADD NEW"
                                        onAddClick={() =>props.history.push('/communication/add-contact/addToGroup-' + props.match.params.id.toString())}
                                        // showIconCol={true}
                                        iconTextSize={16}
                                        deleteIcon={Delete}

                                        verifyIcon={false}
                                    />
                                </div>
                            </Grid>

                        </Grid>
                        <div className="DeleteImgButton">

                            <AlertDialogue
                                title="Delete Group?"
                                description="Are you sure you want to delete this Group?
                            Deleting will permanently remove from everywhere."
                                cancelText="Cancel"
                                confirmText="Confirm"
                                open={deleteModalOpen}
                                handleCancel={handleCloseModal}
                                handleClose={handleCloseModal}
                                handleConfirm={handleConfirm}
                                styles={modalStyle}
                            />

                            <AlertDialogue
                                title="Delete Contact?"
                                description="Are you sure you want to delete this Contact?"
                                cancelText="Cancel"
                                confirmText="Confirm"
                                open={deleteContactModalOpen}
                                handleCancel={handleCloseModal}
                                handleClose={handleCloseModal}
                                handleConfirm={confirmDeleteContact}
                                styles={modalStyle}
                            />
                        </div>
                    </div>
                </div>
            }









            isFooter={true}
            footer={<FooterInner 
                    style={style}
                    termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
                    privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                    />}
            isWhiteBackground={false}
        />
    );
}

export default GroupsDetails;
