import React from 'react';
import { PageHeaderWithTabs, MasterLayoutMiddle, FooterInner, } from "@dclab/dc-react-ui-kit";

import BasicDetails from './BasicDetails'


function NikahCustomizedForm(props) {

  const style = {
    width: '100%',
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(event, newValue);
  };
  const object = [
    {
      label: "BASIC DETAILS",
    },
    {
      label: "VENUE DETAILS",
    },
    {
      label: "FOOTER",
    },
  ];






  return (

    <MasterLayoutMiddle
      header={
        <PageHeaderWithTabs
          back={() => props.history.push("/website/pages")}
          value={value}
          title="Nikah Request Form"
          object={object}
          style={{ color: "#2A82F6", border: "1px solid black" }}
          handleChange={(x, y) => handleChange(x, y)}
          indicatorColor="#2A82F6"
          hiddenDraft={true}
          hiddenPublish={true}
        />
      }
      content={
        <div>
          {value === 0 && (
           <BasicDetails />
          )}

          {value === 1 && (
            <></>
          )}

          {value === 2 && (
            <></>
          )}
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />

  );
}


export default NikahCustomizedForm;