import React, { useState, useEffect } from 'react';
import { PricingModal } from "master-react-lib";
import CheckCircle from '../../../assets/img/check-circle.png';
import info from '../../../assets/img/info.png';
import HardwareSlide1 from '../../../assets/img/hard-slide1.png';
import Player from '../../../assets/img/player.png';
import Grid from '@material-ui/core/Grid';
import { updatePlanSubscriptions } from "../../ManageApps/api";
import { cancelPlan } from "../api";
import { connect } from "react-redux";
import { showAlert } from "../../../redux/actions/alertActions";
import { CircularProgress } from "@material-ui/core";

import {
  AlertAction,
  GridContainer,
  GridItem,
  MasterLayoutMiddle,
  FooterInner, CardDetailsTable, SimpleTable
} from "@dclab/dc-react-ui-kit";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import "../style.css";
import "../../../App.css";
import "../../style.css";
import moment from 'moment';


function PlanDetails(props) {
  console.log("props?.location?.state?.plan: ", props?.location?.state?.details)
  const [plan, setplan] = useState({})
  const [details, setdetails] = useState({})
  const [cartItems, setcartItems] = useState([])
  const [showSuccessAlert, setshowSuccessAlert] = useState(false)

  useEffect(() => {
    let cartData = JSON.parse(localStorage.getItem("appCartItem")) ? JSON.parse(localStorage.getItem("appCartItem")) : [];
    console.log("cartData: ", cartData)
    setcartItems(cartData)
  }, [])

  const [selectedPlan, setselectedPlan] = React.useState("monthly");
  const [openModal, setModal] = React.useState(false);

  const handleSelectedPlan = (e) => {
    console.log(e.target.checked)
    setselectedPlan(e.target.checked ? "annually" : "monthly");
  };

  const handleSelectedPlanData = async (planData) => {
    if (planData[Object.keys(planData)[0]].plan.toLowerCase() === 'free') {
      const token = localStorage.getItem("authToken");

      //let currentPlan = plan?.stripeData?.length ? plans.stripeData.find((plan, i) => plan?.subscription_plan?.toLowerCase() === details?.name?.toLowerCase()) : null;
      let currentPlan = { ...plan, orgId: plan?.orgId, planSubscriptionId: plan?.planSubscriptionId };

      if (currentPlan) {
        let price = currentPlan && currentPlan?.monthlyBilling?.price ? currentPlan.monthlyBilling.price : 0;
        const formData = {
          planId: currentPlan?.planId,
          subscriptionId: currentPlan?.subscriptionId,
          orgId: currentPlan?.orgId,
          customerId: currentPlan?.customerId,
          productId: currentPlan?.productId,
          planSubscriptionId: currentPlan?.planSubscriptionId,
          subscriptionPlan: details?.name,
          subscriptionPlanType: details?.appType,
          subscriptionPaymentFrequency: currentPlan?.frequency,
          subscriptionMonthlyPrice: price,
          removeSubscription: true,
        };

        await cancelPlan(formData, token);
      }

      planData[Object.keys(planData)[0]]['displayName'] = details?.displayName ?? '';
      await updatePlanSubscriptions(plan.planSubscriptionId, planData, token);
      props.alertShow("Free Application added successfully!");
      props.history.push('/more-apps');


    } else {
      let appName = Object.keys(planData)[0];
      let paymentFrequency = selectedPlan;

      let items = cartItems;
      items = items.length ? items.filter((item) => Object.keys(item)[0] !== appName) : [];
      items.push({
        [appName]: {
          ...planData[appName],
          updatedPlan: plan?.subscription_plan_type?.toLowerCase() === "free" || plan?.subscription_plan_type === undefined ? false : true,
          paymentFrequency,
          subscriptionId: plan?.subscriptionId,
          customerId: plan?.customerId,
          createdAt: new Date(),
          productImage: details?.imageMetaData?.imageUrl ?? ''

        }
      });

      console.log("items", items);
      localStorage.setItem("appCartItem", JSON.stringify(items));
      setcartItems(items)
      setshowSuccessAlert(true)
      setTimeout(() => {
        setshowSuccessAlert(false)
      }, 8000);
      // props.history.push("/more-apps")
    }
  };

  useEffect(() => {
    if (props?.location?.state?.plan) {
      props?.location?.state?.plan && setplan(props.location.state.plan)
      props?.location?.state?.details && setdetails(props.location.state.details)
    }
  }, [props?.location?.state?.plan, props?.location?.state?.details])

  const capitalizeFLetter = (val) => {
    return val ? `${val[0].toUpperCase() + val.slice(1)}` : "";
  }

  let header = { avatar: "", tableName: `Details`, icon: "", status: [] };
  let cardData1 = [
    {
      field: "Current Plan",
      value: details?.appType?.toLowerCase() === "integration"
        ? plan?.amount ? 'Paid' : 'Free'
        : plan?.subscription_plan ? `${plan?.subscription_plan ? plan.subscription_plan.toUpperCase() : ""} - ${plan?.subscription_plan_type?.toUpperCase() ?? ""}` : `${details?.name?.toUpperCase()}`
      // - ${details?.appType?.toLowerCase() === "integration" ? "Integration" : "Free"}
    },
    {
      field: "Plan Price",
      value: plan?.amount ? `$${plan.amount / 100}/${plan?.frequency?.toLowerCase() === 'monthly' ? 'mo' : 'yr'}` : "$0"
    },
    {
      field: "Start Date",
      value: plan?.created ? moment(plan?.created * 1000).format("MMM DD, yyyy") : '<span className="ondisableText">None</span>'
    },
    {
      field: "Billing Cycle",
      value: plan?.frequency ? capitalizeFLetter(plan?.frequency) : '<span className="ondisableText">None</span>'
    },
  ];
  let createdAt = moment(plan?.created * 1000);
  let currentDate = moment();

  let cardData2 = [
    {
      field: "Next Payment on",
      value: plan.amount
        ? plan?.frequency?.toLowerCase() === 'monthly'
          ? (createdAt.get('date') <= currentDate.get('date'))
            ? createdAt.get('date') === currentDate.get('date') && createdAt.format('HH:mm') < currentDate.format('HH:mm')
              ? moment({ year: currentDate.get('month') + 1 > 11 ? currentDate.get('year') + 1 : currentDate.get('year'), month: currentDate.get('month') + 1 > 11 ? 0 : currentDate.get('month') + 1, day: createdAt.get('date'), hour: createdAt.get('hour'), minute: createdAt.get('minute') }).format("MMM DD, yyyy")
              : moment({ year: currentDate.get('month') + 1 > 11 ? currentDate.get('year') + 1 : currentDate.get('year'), month: currentDate.get('month') + 1 > 11 ? 0 : currentDate.get('month') + 1, day: createdAt.get('date'), hour: createdAt.get('hour'), minute: createdAt.get('minute') }).format("MMM DD, yyyy")
            : moment({ year: currentDate.get('year'), month: currentDate.get('month'), day: createdAt.get('date'), hour: createdAt.get('hour'), minute: createdAt.get('minute') }).format("MMM DD, yyyy")
          : (createdAt.get('year') <= currentDate.get('year') && createdAt.get('month') <= currentDate.get('month') && createdAt.get('date') <= currentDate.get('date'))
            ? createdAt.get('date') === currentDate.get('date') && createdAt.format('HH:mm') < currentDate.format('HH:mm')
              ? moment({ year: currentDate.get('year') + 1, month: createdAt.get('month'), day: createdAt.get('date'), hour: createdAt.get('hour'), minute: createdAt.get('minute') }).format("MMM DD, yyyy")
              : moment({ year: currentDate.get('year') + 1, month: createdAt.get('month'), day: createdAt.get('date'), hour: createdAt.get('hour'), minute: createdAt.get('minute') }).format("MMM DD, yyyy")
            : moment({ year: currentDate.get('year'), month: createdAt.get('month'), day: createdAt.get('date'), hour: createdAt.get('hour'), minute: createdAt.get('minute') }).format("MMM DD, yyyy")
        : '<span className="ondisableText">None</span>'
    },
    {
      field: "Next Payment Amount",
      value: plan?.amount ? `$${plan.amount / 100}` : '<span className="ondisableText">None</span>'
    },
    {
      field: "Payment Method",
      value: plan?.payment_method_details?.card?.brand?.toUpperCase() ?? '<span className="ondisableText">None</span>'
    },
    {
      field: "Total Billed",
      value: plan?.amount ? `$${plan.amount / 100}` : "$0"
    },
  ];

  let footerLinkName = details?.appType?.toLowerCase() === "integration" ? " " : "CHANGE PLAN";
  let footerDeleteButton = "CANCEL PLAN";

  const onClick = () => {
    console.log('edit');
    setModal(true);
    // props.history.push({pathname: `/organization/plan-details/${id}`, state: { plan: data[0].plan }});
  }

  const onCancelClick = () => {
    console.log('Cancel')
    if (plan?._id || details?._id) {
      props.history.push({ pathname: `/organization/cancel-plan`, state: { plan: plan, details: details } });
    }
  };

  const style = {
    width: '100%',
  };

  // HARDWARE MODAL
  const hardwareSlideImages = [
    HardwareSlide1, HardwareSlide1, HardwareSlide1, HardwareSlide1, HardwareSlide1
  ];

  const includeInPackage = [
    { title: "DEENKIOSK Player", image: Player },
    { title: "Touch Display", image: Player },
  ];

  const hardwareFeatures = ["24 inch LED", "VESA Mount", "Enable/disable specific Salah", "Search and follow Masajid", "Add your own Salah", "Enable/disable specific Salah"];








  // ============================== Table Start ======================================//
  let col = [
    { type: 'string', name: 'name', label: 'INVOICE NUMBER', width: '20%' },
    { type: 'string', name: 'name', label: 'INVOICE DATE', width: '20%' },
    { type: 'string', name: 'name', label: 'AMOUNT', width: '20%' },
    { type: 'string', name: 'name', label: 'PAYMENT METHOD', width: '20%' },
    { type: 'string', name: 'date', label: 'PAYMENT', width: '20%' },
    { type: 'action', label: 'ACTION' }

  ];

  const selected_categories = col.sort((a, b) => a.order - b.order)
  let rows = selected_categories.map((text, index) => {
    let i = index + 1
    return (
      [
        {
          type: 'link', name: 'invoice_number', value: '1848-5131', url: 'https://www.google.com',
          linkType: "external"
        },
        { type: 'string', name: 'time', value: 'Aug 1, 2021' },
        { type: 'string', name: 'name', value: '$30' },
        { type: 'string', name: 'category', value: 'Check' },
        { type: 'string', name: 'frequency', value: 'One-Time' },
        { type: 'action', status: 'success', value: 'VIEW INVOICE' },

      ]
    )
  })


  // ============================== Table End ======================================//

  return (
    <MasterLayoutMiddle
      header={
        <>
          <div
            onClick={() =>
              props.history.goBack()
            }
            className='backBtn'>
            <ChevronLeft style={{ alignSelf: 'center', marginLeft: 0 }} />
            <p style={{ alignSelf: 'center' }}>Back</p>{' '}
          </div>

          {(showSuccessAlert && (
            <div
              style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
            >
              <AlertAction
                alert={"Added to cart successfully!"}
                alertType={"success"}
                onClick={() => setshowSuccessAlert(false)}
              />
            </div>
          ))}
        </>
      }
      content={
        <>
          <Grid container spacing={3} style={{ marginTop: "60px", }}>
            <Grid item xs={12}>
              <CardDetailsTable
                data={cardData1}
                data2={cardData2}
                twoColumns
                header={header}
                footerLinkName={footerLinkName}
                footerDeleteButton={footerDeleteButton}
                onClick={() => onClick()}
                onDeleteClick={() => onCancelClick()}

                Status={true}
                StatusLabel="Status"
                StatusText={"Active"}
                statuscolor={'#3CC480'}
                // StatusText={details?.status}
                // statuscolor={details?.status === "Active" ? '#3CC480' : '#C44058'}

                OrgName={details?.displayName ?? details?.name}
                OrgSubText={details?.appType}
                OrgLogo={details?.appType?.toLowerCase() === "integration" ? details?.imageMetaData?.imageUrl : details?.iconMetaData?.iconUrl}
                HeaderStatus={true}

              />

            </Grid>

            {/* <Grid item xs={12}>
              <div className="table_box">
                <SimpleTable
                  showHeader={true}
                  title="Payment History"
                  width="100%"
                  rows={rows}
                  columns={col}
                  colSpan={16}
                  actionSendButton={true}
                  donationsCount={rows && rows.length > 0 ? 1 : 0}
                  noTitle="No inquiry submitted yet"
                  noPara="Inquiries submitted by visitors will be visible and managed here."
                  noBtnText=""
                  onInviteClick={() =>
                    props.history.push(
                      "/organization/payment-receipt"
                    )
                  }
                  actionLink={(name) => props.history.push({ pathname: "/organization/payment-receipt", state: { contact: name[0].data } })}
                  showIconCol={false}
                  actionSendButton={true}
                  deleteAction={(e) => console.log(e)}
                  showFooter={true}
                  showAdd={false}
                  addText="ADD NEW"
                  onAddClick={() =>
                    props.history.push(
                      "/organization/payment-receipt"
                    )
                  }
                  iconTextSize={16}
                />
              </div>
            </Grid> */}

          </Grid>

          <PricingModal
            openModal={openModal}
            setModal={setModal}
            selectedPlan={selectedPlan}
            handleSelectedPlan={handleSelectedPlan}
            handleSelectedPlanData={handleSelectedPlanData}
            applicationInfo={details}
            checkCircleIcon={CheckCircle}
            infoIcon={info}
            buttonStyle={{
              display: "none"
            }}
            currentPlanName={plan?.subscription_plan_type?.toLowerCase() ?? "free"}
            planDiscount={"20"}
            // HARDWARE MODAL
            deviceTitle={"DEENKIOSK Player"}
            devicePrice={10}
            hardwareSlideImages={hardwareSlideImages}
            includeInPackage={includeInPackage}
            hardwareFeatures={hardwareFeatures}
            modalButtonText={"SEE DETAILS"}
            modalButtonStyles={{ color: "white" }}
          />
        </>
      }
      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  )
}


const mapStateToProps = (state) => {
  return {
    alertValue: state.alert.alertValue

  };
};

const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanDetails);