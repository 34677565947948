import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import {
   ZoneLayout
} from "@dclab/dc-react-ui-kit";

export default function Layout(props) {
    const {onLayoutZoneClick, layout, selectedZone } = props;

    return (
        <Grid container spacing={0.5}>

                {layout.map((el, i) => {
                  let id = i + 1;
                  return <Grid item xs={el.grid} onClick={() => {
                    onLayoutZoneClick(id)
                  }}>
                  <ZoneLayout
                    Label={id}
                    height={el.height}
                    selected={selectedZone === id ? true : false}
                  />
                </Grid>
                })}
               
              </Grid>
           
    )
}
