import * as constants from "../constants";

const INITIAL_STATE = {
    pages:[
        {
            name: ['homePage'],
            quickAccess: true,
        },
        {
            name: ['events'],
            quickAccess: true,
        },
        {
          name: ['videos'],
          quickAccess: true,
        },
        {
          name: ['articles'],
          quickAccess: true,
        },
        
    ]
  };
  
  export default (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
      case constants.PAGES_QUICKACCESS:
        return {
            pages: action.payload,
        };
      default:
        return state;
    }
  };
  