import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  SignUpComponent,
  AlertAction,
} from '@dclab/dc-react-ui-kit';
import Lock from '../../assets/img/lock-24px.svg';
import Right from '../../assets/img/done-14px.svg';
import Close from '../../assets/img/clear-24px.svg';
import Logo from '../../assets/img/DC_Logo.png';
import { signupHandler } from '../../redux/actions/signupAction';
import { signup, getName } from './api';
import { nameSplit } from '../../utils/nameSplit'
import './style.css';
import queryString from 'query-string';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      nameError: false,
      emailError: false,
      passwordError: false,
      emailDisbled: false,
      error: false,
      loading: false,
      showAlert: false,
      orgId: "",
      teamMemberId: ""
    };
    this.signup = this.signup.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

   
  componentDidMount() {
    const getData = async () => {
      let Email = localStorage.getItem('orgEmail');
      let Admin = localStorage.getItem('orgAdmin') ? localStorage.getItem('orgAdmin') : "";
  
      if(this?.props?.location?.search) {
        let userMail = this?.props?.location?.search;
        const parsed = queryString.parse(userMail);
  
        if(parsed?.email) {
          let name = await getName(parsed.email);
          name = name?.data?.body?.data ?? null;
          this.setState({ 
            email: parsed.email, 
            emailDisbled: parsed.email ? true : false, 
            orgId: parsed.orgId ? parsed.orgId : "",
            teamMemberId: parsed.teamMemberId ? parsed.teamMemberId : "",
            name: name ? name?.firstName + " " + name?.lastName : ""
          });
        }
      } else  {
        this.setState({ email: Email, name: Admin, emailDisbled: Email ? true : false });
      }
    } 

    getData();
  }
  handleChange = (name, event) => {
    if (name === 'email') {
      const emailRegex = /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i;
      if (emailRegex.test(event.target.value)) {
        this.setState({ email: event.target.value, emailError: false });
      } else {
        this.setState({ email: event.target.value, emailError: true });
      }
    } else {
      this.setState({ [name]: event.target.value });
    }
  };
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  async signup() {
    this.setState({
      showAlert: false,
      error: false,
      loading: true,
      emailError: false,
    });
    const { name, email, password, emailError } = this.state;
    const Name = nameSplit(name)
    if (!name || !email || !password) {
      this.setState({
        nameError: true,
        emailError: true,
        passwordError: true,
        error: true,
        loading: false,
      });
    } else if (emailError) {
      this.setState({
        error: true,
        loading: false,
      });
    } else {
      let searchId;
      if(this?.props?.location?.search) {
        let search = this?.props?.location?.search;
        let searchParam = search?.split("=")[0];
        let searchValue = search?.split("=")[1];
        if(searchParam === "?pricingDetailId") {
          searchId = searchValue;
          console.log("serach", searchParam, searchValue, this?.props?.location?.search)
        }
      }
      console.log("searchId", searchId)
      const userInfo = {
        userName: email,
        password,
        name: {
          firstName: Name[0],
          lastName: Name[1] ? Name[1] : '',
        },
        planSubscriptionId: searchId ? searchId : !this.props.orgToken ? "true" : "",
        teamMemberOrgId: this.state.orgId ? this.state.orgId : "",
        teamMemberId: this.state.teamMemberId ? this.state.teamMemberId : ""
      };

      console.log("userInfo", userInfo)

      try {
        this.props.signUp(email, name);
        const res = await signup(userInfo, this.props.orgToken);
        localStorage.setItem('name', name);
        localStorage.setItem('email', email);
        this.setState({ showAlert: true, loading: false });
        setTimeout(() => {
          this.props.history.replace({
            pathname: '/verify',
            state: { email, password, teamMember: this.state.teamMemberId ? this.state.teamMemberId : "" },
          });
        }, 1000);
      } catch (error) {
        this.setState({
          error: error && error.response && error.response.data.message,
          loading: false,
          showAlert: true,
        });
        this.hideAlert();
      }
    }
  }

  disableSignUpButton = (name, email, password) => {
    const emailRegex = /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/;
    const passwordRegEx = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    
    const emailCleared = emailRegex.test(email);
    const nameCleared = name.length > 0 ? true : false;
    const passwordCleared = passwordRegEx.test(password);

    return emailCleared && nameCleared && passwordCleared ? false : true;
  }
  render() {
    this.handleSubmit = (e) => { };
    const {
      name,
      email,
      password,
      nameError,
      emailError,
      passwordError,
      error,
      showAlert,
      loading,
      emailDisbled,
    } = this.state;
    return (
      <div className='' style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', width: '100%', zIndex: -1 }}>
          <div
            className='Background-Image'
            style={{ width: '100%', position: 'fixed', height: '100vh' }}
          ></div>
        </div>
        {showAlert && error && (
          <div
            style={{
              zIndex: 99,
              width: '100%',
              position: 'fixed',
              marginTop: 40,
              marginLeft: 200,
            }}
          >
            <AlertAction
              alert={error}
              alertType={'error'}
              onClick={() => this.setState({ showAlert: false })}
            />
          </div>
        )}
        <div
          style={{ width: '60%', margin: '0 auto', paddingTop: 24 }}
          className='signup-container div_backgrndimg-pattern'
        >
          <SignUpComponent
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? 'https://shapla.deenconnect.io/terms-policies' : 'https://deenconnect.io/terms-policies' }
            policyLink = {process.env.REACT_APP_Environment === 'dev' ? 'https://shapla.deenconnect.io/terms-policies#privacy-policy' : 'https://deenconnect.io/terms-policies#privacy-policy'}
            isLoading={loading}
            src={Logo}
            LogoLink={process.env.REACT_APP_Environment === 'dev' ? "https://shapla.deenconnect.io/" : "https://deenconnect.io/"}
            right={Right}
            close={Close}
            icon={Lock}
            namevalue={name}
            nameonChange={(e) => this.handleChange('name', e)}
            namerequired={true}
            nameid={'userfullname'}
            namelabel={'Full Name'}
            nameplaceholder={'Full Name'}
            namemultiline={false}
            nametype={'text'}
            nameerror={nameError}
            namehelperText={error && !name ? 'First name is required' : ''}
            textfieldvalue={email}
            onChange={(e) => this.handleChange('email', e)}
            disabledEmail={emailDisbled}
            required={true}
            id={'useremail'}
            label={'Email Address'}
            //placeholder={'email'}
            type={'email'}
            error={emailError}
            helperText={
              (error && !email ? 'Email is required' : '') ||
              (emailError ? 'Email address is required' : '')
            }
            passwordvalue={password}
            passwordvalue_onChange={(e) => this.handleChange('password', e)}
            passwordvalue_required={true}
            passwordvalue_id={'userpassword'}
            passwordvalue_label={'Password'}
            passwordvalue_error={passwordError}
            passwordvalue_placeholder={' '}
            passwordvalue_helperText={
              error && !password ? 'Password is required' : ''
            }
            signInLink={() => this.props.history.replace('/signin')}
            text={'Sign Up'}
            disabletlTextButton = {this.disableSignUpButton(name, email, password)}
            onClick={() => this.signup()}
            onSubmit={(e) => this.handleSubmit(e)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orgToken: state.orgToken.code,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signUp: (email, fullName) => dispatch(signupHandler(email, fullName)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Signup);
