import React, { Component } from "react";
import {
  BasePaper,
  SimpleTable,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction,
  CardDetailsTable,
  CardStatus, 
} from "@dclab/dc-react-ui-kit";
import Moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getOrganizationData, getaddressFromPostalCode } from "./apis";
import { stateAbbreviations } from "../../utils/statesAbbreviations";
import "./style.css";
import Send from "../../assets/img/add-24px.svg";
import { ChevronLeft } from "@material-ui/icons";
import Done from "../../assets/img/ok_check_done-256.png";
import "../style.css";
import Grid from '@material-ui/core/Grid';
import messages_content from '../../utils/messages.json';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
const {
  thank_email_already_sent,
  message_sent_successfully
} = messages_content;

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orgData: [],
      sucess: false,
      error: false,
      loading: true,
      address: {},
      showAlert: false
    };
  }
  async componentDidMount() {
    const obj = JSON.parse(localStorage.getItem("Donation Details"));
    const orgId = localStorage.getItem("orgID");
    const token = localStorage.getItem("authToken");

    if (this.props?.location?.state?.showMessageAlert) {
      console.log("showMessageAlert", this.props.location.state.showMessageAlert)
      this.setState({ showAlert: true });
      setTimeout(() => {
        this.setState({ showAlert: false });

      }, 8000);
    }

    try {
      const res = await getOrganizationData(orgId, token);
      // if (obj?.address?.postal_code) {
      //   console.log("postal_code", obj.address);
      //   const post_code = await getaddressFromPostalCode(
      //     obj.address.postal_code,
      //     obj?.address?.country ? obj.address.country : "",
      //     obj?.address?.ip_country ? obj.address.ip_country : "",
      //   );
      //   console.log("address", post_code?.data?.body?.data?.address);
      //   this.setState({
      //     address: post_code?.data?.body?.data?.address,
      //   });
      // }
      this.setState({
        orgData: res?.data?.body?.data[0],
        loading: false,
      });
    } catch (error) {
      this.setState({ error: error, loading: false });
    }
  }

  capitalizeFLetter = (val) => {  
    return val ? `${val[0].toUpperCase() + val.slice(1)}` : ""; 
  }

  render() {
    const { orgData, loading, address } = this.state;
    const obj = JSON.parse(localStorage.getItem("Donation Details"));
    var currentDate = Moment(new Date(obj.donation_date));
    var futureMonth = Moment(currentDate).add(1, "M");
    var futureMonthEnd = Moment(futureMonth).endOf("month");
    if (
      currentDate.date() !== futureMonth.date() &&
      futureMonth.isSame(futureMonthEnd.format("YYYY-MM-DD"))
    ) {
      futureMonth = futureMonth.add(1, "d");
    }
    const nextDonationDate = futureMonth.format("MMM DD, YYYY");
    const details = (
      <div>
        <div style={{ borderBottom: "1px solid #E8E9E9", display: "flex" }}>
          <h3 className="orgTitle">Donation Details</h3>
        </div>
        <div
          style={{
            display: "flex",
            marginLeft: 30,
            marginRight: 30,
            padding: "30px 0",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <div style={{ height: 50 }}>
              <div style={{ display: "flex" }}>
                <div className="prop">
                  <p>Receipt #</p>
                </div>
                <div className="name2">
                  <p className="bold">
                    <a
                      style={{ color: "#2A82F6", textDecoration: "none" }}
                      href={obj.receiptUrl}
                      target="_blank"
                    >
                      {obj?.receiptNumber ? obj.receiptNumber.replace("#", "") : ""}
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div style={{ height: 50 }}>
              <div style={{ display: "flex" }}>
                <div className="prop">
                  <p>Status</p>
                </div>
                <div className="name2">
                  <div style={{ display: "flex" }}>
                    <p className="bold">{obj.status}</p>
                    {obj.status === "Success" && (
                      <img
                        src={Done}
                        height={20}
                        width={20}
                        style={{ marginTop: 13, marginLeft: 5 }}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: 50 }}>
              <div style={{ display: "flex" }}>
                <div className="prop">
                  <p>Name</p>
                </div>
                <div className="name2">
                  <p className="bold">{obj.name}</p>
                </div>
              </div>
            </div>
            <div style={{ height: 50 }}>
              <div style={{ display: "flex" }}>
                <div className="prop">
                  <p>Email</p>
                </div>
                <div className="name2">

                  <p className="bold">{obj.email ? obj.email : <span style={{ color: "#818E94" }}>No email</span>}</p>
                </div>
              </div>
            </div>
            {address && address.state && (
              <div style={{ height: 50 }}>
                <div style={{ display: "flex" }}>
                  <div className="prop">
                    <p>Location</p>
                  </div>
                  <div className="name2">
                    <p className="bold">
                      {address.city ? address.city + ", " : ""}
                      {address.state ? address.state + ", " : ""}{address.postalCode}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <div style={{ height: 50, flex: 1 }}>
              <div style={{ display: "flex" }}>
                <div className="prop">
                  <p>Amount</p>
                </div>
                <div className="name2">
                  <p className="bold">{obj.amount}</p>
                </div>
              </div>
            </div>
            <div style={{ height: 50, flex: 1 }}>
              <div style={{ display: "flex" }}>
                <div className="prop">
                  <p>Donation date</p>
                </div>
                <div className="name2">
                  <p className="bold">{obj.donation_date}</p>
                </div>
              </div>
            </div>
            <div style={{ height: 50, flex: 1 }}>
              <div style={{ display: "flex" }}>
                <div className="prop">
                  <p>Frequency</p>
                </div>
                <div className="name2">
                  <p className="bold">{obj.frequency}</p>
                </div>
              </div>
            </div>
            <div style={{ height: 50, flex: 1 }}>
              <div style={{ display: "flex" }}>
                <div className="prop">
                  <p>Category</p>
                </div>
                <div className="name2">
                  <p className="bold">{obj.category}</p>
                </div>
              </div>
            </div>
            <div style={{ height: 50, flex: 1 }}>
              <div style={{ display: "flex" }}>
                <div className="prop">
                  <p>Fees</p>
                </div>
                <div className="name2">
                  <p className="bold">
                    {obj.processingFee ? "Covered" : <span style={{ color: "#818E94" }}>Not Covered</span>}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            {obj.frequency === "Monthly" && (
              <div style={{ height: 50 }}>
                <div style={{ display: "flex" }}>
                  <div className="prop">
                    <p>Next Donation</p>
                  </div>
                  <div className="name2">
                    <p className="bold">{nextDonationDate ? nextDonationDate : <span style={{ color: "#818E94" }}>No Date</span>}</p>
                  </div>
                </div>
              </div>
            )}
            <div style={{ height: 50 }}>
              <div style={{ display: "flex" }}>
                <div className="prop">
                  <p>Origin</p>
                </div>
                <div className="name2">
                  <p className="bold">{obj.stream}</p>
                </div>
              </div>
            </div>
            {obj.note !== " " && (
              <div style={{ height: 50 }}>
                <div style={{ display: "flex" }}>
                  <div className="prop">
                    <p>Note</p>
                  </div>
                  <div className="name2">
                    <p
                      className="bold"
                      style={{ width: 190, lineHeight: "1.5rem" }}
                    >
                      {obj.note}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );

    // ============================== Table Start ======================================//
    let header = {
      tableName: "Donation Details",
      status: [<CardStatus styleClassName="primary" text={<div><VisibilityOffRoundedIcon /> ANONYMOUS</div>} style={{paddingTop: "0px",}} />,]

    };
    
    let data = [
      {
        field: "Receipt #",
        value: obj?.receiptNumber ? `<span style = 'color: rgb(42 130 247)'>${obj.receiptNumber.replace("#", "")} </span>`: ""
      },
      {
        field: "Amount",
        value: obj.amount
      },
      {
        field: "Next Donation",
        value: obj.donation_date
      },

      {
        field: "Name",
        value: obj.name
      },
    
      {
        field: "Donation date",
        value: obj.donation_date
      },

      {
        field: "Origin",
        value: obj.stream ?? "<span style = 'color: gray'>No Data</span>"
      },
      {
        field: "Email",
        value: obj.email ? obj.email :  "<span style = 'color: gray'>No Data</span>"
      },

      {
        field: "Frequency",
        value: obj.frequency
      },
   
      {
        field: "Note",
        value: (obj.note && obj.note?.length > 1) ? obj.note : "<span style = 'color: gray'>No Data</span>"
      },

      {
        field: "Location",
        value: `${obj?.address?.city ? this.capitalizeFLetter(obj?.address?.city) + "," : ""} ${obj?.address?.state
          ? stateAbbreviations[obj.address?.state.replace(/\b\w/g, c => c.toUpperCase())] ? stateAbbreviations[obj.address?.state.replace(/\b\w/g, c => c.toUpperCase())] : this.capitalizeFLetter(obj?.address?.state) ?? '' : this.capitalizeFLetter(obj?.address?.state) ?? ''} ${ obj?.address?.postal_code ?? ""}`
                
      },

      {
        field: "Category",
        value: obj.category
      },
      
 
      //<p className="bold">
        //{address.city ? address.city + ", " : ""}
        //{address.state ? address.state + ", " : ""}{address.postalCode}
      //</p>

      // {
      //   field: "Fees",
      //   value: obj.processingFee ? obj.processingFee: "<span style = 'color: gray'>No Data</span>"
      // },
    ];

    let footerLinkName = "Edit Donation";


    // ============================== Table Start ======================================//


    const columns = [
      { id: "DONOR NAME", label: "EMAIL", width: "20%" },
      { id: "DONOR", label: "SENT BY", width: "60%" },
      { id: "DATE", label: "DATE", width: "50%" },
      { id: "", label: "", width: "50%" },
    ];
    const donorsLogData = orgData?.enabledIntegrations?.deenfund?.donorsLog;
    const filteredRows = donorsLogData?.length && donorsLogData.filter(
      (donor) =>
        donor.receiptNumber ===
        JSON.parse(localStorage.getItem("Donation Details")).receiptNumber && donor.emailTo === JSON.parse(localStorage.getItem("Donation Details")).email
    );

    const sortedRows = filteredRows
      ? filteredRows.sort((a, b) => b.sentDate - a.sentDate)
      : [];
    localStorage.setItem("Donors Logs", JSON.stringify(sortedRows));
    const rows = sortedRows && sortedRows.length
      ? sortedRows.map((log, i) => {
        const nameArr = log.sentBy.split(" ");
        let initials;
        if (nameArr.length > 1) {
          initials = nameArr[0][0] + nameArr[1][0];
        } else {
          initials = nameArr[0][0];
        }
        return [
          {
            type: "string",
            name: "name",
            value: log.emailTo,
          },
          {
            name: "textImg",
            type: "string",
            value: log.sentBy,
            primaryText: log.sentBy,
            initials: initials?.toUpperCase(),
          },
          {
            type: "string",
            name: "name",
            value: Moment(log.sentDate).format("MMM DD, YYYY, h:mm A"),
          },
        ];
      })
      : [];

    console.log("rowsData", rows)
    const classProps = () => {
      const tempArray = [];
      columns.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    const style = {
      width: '100%',
    };
    return (
      <MasterLayoutMiddle
        header={
          <div className='contact-us-backbutton'>
            <div
              onClick={() => this.props.history.push("/deenfund/donations")}
              className="backBtn"
              style={{ marginTop: 10 }}
            >
              <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
              <p style={{ alignSelf: "center" }}>Back</p>{" "}
            </div>
          </div>
        }
        content={
          <div style={{
            marginTop: 68,
          }}>
            {this.state.showAlert && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={message_sent_successfully}
                  alertType={"success"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}


            <Grid container spacing={3}>
              {/* <Grid item xs={12}>
                <div style={{ boxShadow: "0px 2px 4px #0000001A" }}>
                  <BasePaper
                    context={details}
                    square={false}
                    paperStyle={styles.paperStyleNext}
                  />
                </div>
              </Grid> */}

              <Grid item xs={12}>
                <CardDetailsTable
                  data={data}
                  header={header}
                  //footerLinkName={footerLinkName}

                  Status={true}
                  StatusLabel="Status"
                  StatusText="Active"
                  statuscolor="#3CC480"
                />
              </Grid>


              {loading ? (
                <div
                  style={{
                    marginTop: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress color="primary" />
                </div>
              ) : (
                JSON.parse(localStorage.getItem("Donation Details")).email && (
                  <Grid item xs={12}>
                    <div className="log-div HeaderDevider">
                      <SimpleTable
                        showHeader={true}
                        showCol={false}
                        title="Send Thanks"
                        rows={rows}
                        columns={columns}
                        colSpan={16}
                        actionSendButton={true}
                        classProps={changeClassProps()}
                        donationsCount={rows.length > 0 ? 1 : 0}
                        noTitle="Thanking your donors is a good gesture."
                        noPara=" "
                        noBtnText=""
                        showFooter={false}
                        showSend={true}
                        sendText="Send Now"
                        sendIcon={Send}
                        iconTextSize={16}
                        onSendClick={() =>
                          this.props.history.replace(
                            "/deenfund/donations/thank-donors"
                          )
                        }
                      />
                    </div>
                  </Grid>
                )
              )}
            </Grid>
          </div>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style}
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={false}
      />
    );
  }
}
const styles = {
  paperStyle: {},
  paperStyleNext: {
  },
  contents: {
    padding: '24 0',
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: '76%',
    marginTop: 70,
    marginLeft: '20%'
  },
};