import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import {
   ZoneLayout, DropDown, BaseButton
} from "@dclab/dc-react-ui-kit";
import Layout from "./Layouts/Layout.js"
import ChooseTemplate from '../ChooseTemplate'
import './../style.css'
 

export default function LayoutTab(props) {
    const {editHomeScreen, seteditHomeScreen, onLayoutZoneClick, selectedZone} = props;
    const [templateModalOpen, settemplateModalOpen] = useState(false)
    const editBanner = editHomeScreen?.layout?.bannerCard ?? {}

    const layout1 = [
        { grid: 12, height: "" },
        { grid: 4, height: "102px" }, { grid: 4, height: "102px" }, { grid: 4, height: "102px" },
        { grid: 4, height: "" }, { grid: 4, height: "" }, { grid: 4, height: "" },
    ];

    const layout2 = [
        { grid: 12, height: "" },
        { grid: 4, height: "102px" }, { grid: 8, height: "102px" },
        { grid: 4, height: "" }, { grid: 4, height: "" }, { grid: 4, height: "" },
    ];

    const layout3 = [
        { grid: 12, height: "" },
        { grid: 8, height: "102px" }, { grid: 4, height: "102px" },
        { grid: 4, height: "" }, { grid: 4, height: "" }, { grid: 4, height: "" },
    ];

    const layout4 = [
        { grid: 12, height: "" },
        { grid: 12, height: "102px" },
        { grid: 4, height: "" }, { grid: 4, height: "" }, { grid: 4, height: "" },
    ];
    
    const layout5 = [
        { grid: 12, height: "" },
        { grid: 12, height: "140px" },
    ];
  

    const selectedLayout = () => {
        let templateName = editHomeScreen?.template?.name ? editHomeScreen.template.name.toLowerCase() : ""
        switch(templateName) {
            case "template one":
                return <Layout onLayoutZoneClick={onLayoutZoneClick} layout={layout1} selectedZone={selectedZone} />
            case "template two":
                return <Layout onLayoutZoneClick={onLayoutZoneClick} layout={layout2} selectedZone={selectedZone} />
            case "template three":
                return <Layout onLayoutZoneClick={onLayoutZoneClick} layout={layout3} selectedZone={selectedZone} />
            case "template four":
                return <Layout onLayoutZoneClick={onLayoutZoneClick} layout={layout4} selectedZone={selectedZone} />
            case "template five":
                return <Layout onLayoutZoneClick={onLayoutZoneClick} layout={layout5} selectedZone={selectedZone} />
                             
            default:
                return <Layout onLayoutZoneClick={onLayoutZoneClick} layout={layout1} selectedZone={selectedZone} />
        }
    }

    const Transition = [
        { value: "fade", select: "Fade" },
        { value: "dissolve", select: "Dissolve" },
        { value: "flip horizontal", select: "Flip Horizontal" },
        { value: "flip vertical", select: "Flip Vertical" },
        { value: "slide right", select: "Slide Right" },
        { value: "slide left", select: "Slide Left" },
        { value: "slide up", select: "Slide Up" },
        { value: "slide down", select: "Slide Down" },
    ];


    const handleChange = (event, value) => {
        let layout = editHomeScreen?.layout ?? {}
        let bannerCard = editHomeScreen?.layout?.bannerCard ?? {}
        seteditHomeScreen({...editHomeScreen, layout: {...layout, bannerCard: {...bannerCard, transitions: event.target.value}}})
        props.setchanges(true)
        console.log(event.target.value, value);
    };


    return (
        <Grid container spacing={3}>
            <Grid item xs={12} >

                {selectedLayout()}
                <div className="ChangeButton" >
                    <BaseButton  text="Change Template" onClick={() => settemplateModalOpen(true)}/>
                </div>
            </Grid>
            
            <Grid item xs={12}>
                <ChooseTemplate 
                    templateModalOpen={templateModalOpen}
                    settemplateModalOpen={settemplateModalOpen}
                    addHomeScreen={editHomeScreen}
                    setaddHomeScreen={seteditHomeScreen}
                    edit={true}
                    setchanges={props.setchanges}
                />
            </Grid>

            {editBanner?.slider?.length ? <Grid item xs={12}>
              <DropDown
                label="Transition Style"
                labelStatus={true}
                selectdata={Transition}
                defaultValue={editBanner?.transitions ?? "fade"}
                handleChange={handleChange}
              />
            </Grid> : null}
          </Grid>
    )
}
