import axios from "axios";

import * as constants from "./constants";

import { logoutUser } from "./actions/authActionCreators";

export const apiMiddleware = ({ dispatch, getState, subscribe }) => (next) => (
  action
) => {
  if (action.type !== constants.API) return next(action);

  //dispatching before api calls
  dispatch({ type: constants.TOGGLE_LOADER });

  const Base_URL = "https://1v2bdiahji.execute-api.us-east-1.amazonaws.com/dev";
  // const AUTH_TOKEN = getState().user.token;
  const AUTH_TOKEN=localStorage.getItem('authToken')
  const REG_TOKEN = "deenconnect-elobyte";
  if (AUTH_TOKEN) axios.defaults.headers.common["token"] = `${AUTH_TOKEN}`;
  else axios.defaults.headers.common["token"] = `${REG_TOKEN}`;
  const {
    url,
    method,
    success,
    data,
    postProcessSuccess,
    postProcessError,
  } = action.payload;

  axios({
    method,
    url: Base_URL + url,
    data: data ? data : null,
  })
    .then((response) => {
      dispatch({ type: constants.TOGGLE_LOADER });
      if (success) dispatch(success(response.data));
      if (postProcessSuccess) postProcessSuccess(response.data);
    })
    .catch((err) => {
      dispatch({ type: constants.TOGGLE_LOADER });
      if (!err.response) console.warn(err);
      else {
        if (err.response && err.response.status === 403) dispatch(logoutUser());
        if (err.response.data.error.message) {
          if (postProcessError)
            postProcessError(err.response.data.error.message);
        }
      }
    });
};
