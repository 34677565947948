import React, { Component } from 'react';
import { connect } from 'react-redux';
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import Send from '../../assets/img/add-24px.svg';
import {
    BaseTabs,
    SimpleTable,
    AlertDialogue,
    GridContainer,
    GridItem,
    MasterLayoutMiddle,
    AlertAction,
    FooterInner
} from '@dclab/dc-react-ui-kit';
import {
    getServices,
    deleteService,
    editOrganization,
    getService,
    getOrganizationData,
} from '../Organization/api';
import { editServiceAction } from '../../redux/actions/servicesAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../App.css';
import '../style.css';
import { getCommunicationsData, deleteTemplate } from './Functions';
import moment from 'moment';

import { hideAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
const {
    add_com_message_success,
    remove_com_message_message,
    add_com_message_scheduled,
} = messages_content;
class ComunicationMessages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabInd: 0,
            activeTab: 0,
            items: [],
            error: '',
            showAlert: false,
            success: false,
            addSuccess: false ,
            deleteSuccess: false, 
            messageScheduled: false ,
            facilitiesArr: [],
            orgFacilities: [],
            openAlert: false,
            deleteServiceId: '',
            loading: true,
            modalLoading: false,
            facilitiesLoading: false,
            removeFacility: false,
        };
        this.onClick = this.onClick.bind(this);
        this.editService = this.editService.bind(this);
        this.deleteServiceAction = this.deleteServiceAction.bind(this);
        this.addFacilities = this.addFacilities.bind(this);
        this.saveFacilities = this.saveFacilities.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleServiceConfirm = this.handleServiceConfirm.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.AskdeleteMessage = this.AskdeleteMessage.bind(this);
        this.messageDetails = this.messageDetails.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
    }

    async componentDidMount() {
        const token = localStorage.getItem('authToken');
        let orgId = localStorage.getItem('orgID');
        const messageType = this.props.match.params.messageType ; 
        if( messageType ){
            if( messageType.toString() == 'messageAdd' ){
                this.setState({
                    addSuccess: true 
                });
            }else if (messageType.toString() == 'messageDelete'){
                this.setState({
                    deleteSuccess: true 
                });
            }else if(messageType.toString() == 'messageScheduled'){
                this.setState({
                    messageScheduled: true 
                });
            }
        }
        try {
            const res = await getCommunicationsData('messages', orgId, token);
            this.setState({
                items: res.data.body.data,
                loading: false,
            });
        } catch (error) {
            this.setState({
                error: error.response && error.response.data.message,
                loading: false,
            });
        }
    }

    hideAlert = () => {
        setTimeout(() => {
            this.setState({ showAlert: false });
        }, 8000);
    };
    addFacilities = (e, item) => {

    };
    saveFacilities = async () => {

    };
    editService = async (e) => {

    };
    handleClose = () => {
        this.setState({ openAlert: false });
    };
    handleServiceConfirm = async () => {
        this.setState({ modalLoading: true });
        const { services, deleteServiceId } = this.state;
        const token = localStorage.getItem('authToken');
        try {
            const res = await deleteService(deleteServiceId, token);
            const newServiceArr = services.filter(
                (ser) => ser._id !== deleteServiceId
            );
            this.setState({
                services: newServiceArr,
                success: true,
                openAlert: false,
                modalLoading: false,
            });
        } catch (error) {
            this.setState({
                error: error.response && error.response.data.message,
                showAlert: true,
                openAlert: false,
                modalLoading: false,
            });
        }
    };
    deleteServiceAction = async (e) => {
        this.setState({
            openAlert: true,
            deleteServiceId: e[0].label,
        });
    };
    onClick = (x, y) => {
        this.setState({
            tabInd: y,
        });
    };
    handleTabChange = (event, newValue) => {
        this.setState({ activeTab: newValue });
    };
    handleCloseModal = () => {
        this.setState({ deleteModalOpen: false });
    }

    async AskdeleteMessage(e) {
        const templateId = e[4].value;
        console.log(templateId);
        this.setState({ selectedTemplateId: templateId, deleteModalOpen: true });
    };

    async messageDetails( e ){
        let mid =( e[4].name );
        this.props.history.push('/communication/messages-details/'+mid);
    }

    handleConfirm = async () => {
        // console.log( this.state.selectedTemplateId );

        await deleteTemplate('messages', this.state.selectedTemplateId);

        const updateList = this.state.items.filter(
            (template) => template._id !== this.state.selectedTemplateId
        );

        this.setState({ deleteModalOpen: false, success: true, items: updateList });

    }

    render() {
        const modalStyle = {
            width: 470,
            marginLeft: 800
        };
        const {
            items,
            success,
            addSuccess,
            loading,
            deleteModalOpen,
            deleteSuccess,
            messageScheduled, 
        } = this.state;
        if (this.props.alert.alertValue) {
            setTimeout(() => {
                this.props.alertHide()
            }, 8000);
        }


        let col = [
            { type: 'string', name: 'single_pic', label: 'MESSAGE', width: '40%' },
            { type: 'string', name: 'single_pic', label: 'TYPE', width: '21%' },
            { type: 'string', name: 'single_pic', label: 'DATE', width: '21%' },
            { type: "string", name: "status", label: "STATUS", width: '20%' },            
            { type: "string", name: "single_pic", label: "ACTION", width: '20%' },
        ];

        let rows = items.map((ser, i) => {
            let statusType = 'schedule' ; 
            let displayMessage = ser.message ; 

            if( ser.type == 'EMAIL'){
                displayMessage = ser.emailSubject ; 
            }

            if( ser.status.toLowerCase() == 'sent' ){
                statusType = 'success';
            }
            if(displayMessage.length > 50){
                displayMessage = displayMessage.substring(0,50) + "...";
            } 
            return [
                { type: 'string', name: 'name', value: displayMessage },
                { type: "string", name: "chipBlue", value: ser.type },
                { type: 'string', name: 'date', value: moment(ser.date).format('MMM DD, YYYY, hh:mm A') },
                { type: 'status', status: statusType , name: 'status', value: ser.status },
                { type: 'action', name: ser._id ,  status: 'success', value: 'SEE DETAILS' },
            ];
        });


        const style = {
            width: '100%',
        };
        return (
            <MasterLayoutMiddle
                header={
                    <>

                    </>
                }
                content={
                    <>
                        {deleteSuccess && (
                            <div
                                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                            >
                                <AlertAction
                                    alert={remove_com_message_message}
                                    alertType={"success"}
                                    onClick={() => this.setState({ success: false })}
                                />
                            </div>
                        )}

                        {addSuccess && (
                            <div
                                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                            >
                                <AlertAction
                                    alert={add_com_message_success}
                                    alertType={"success"}
                                    onClick={() => this.setState({ addSuccess: false })}
                                />
                            </div>
                        )}

                        {messageScheduled && (
                            <div
                                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                            >
                                <AlertAction
                                    alert={add_com_message_scheduled}
                                    alertType={"success"}
                                    onClick={() => this.setState({ messageScheduled: false })}
                                />
                            </div>
                        )}

                        {loading ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: 30,
                                }}
                            >
                                <CircularProgress color='primary' />
                            </div>
                        ) : ( 

                            <GridContainer spacing="0">
                                <>
                                    <GridItem itemStyle={{ marginTop: 0 }} itemClass='grid-item'>
                                        <div className='services-table'>
                                            <SimpleTable
                                                showHeader={true}
                                                title='Messages'
                                                width='98%'
                                                rows={rows}
                                                columns={col}
                                                colSpan={16}
                                                actionSendButton={true}
                                                donationsCount={rows && rows.length > 0 ? 1 : 0}
                                                noTitle="No Messages added"
                                                noPara="Added Messages will be displayed and managed here"
                                                noBtnText="Add New Message"
                                                onInviteClick={() => {
                                                    
                                                    this.props.history.push(
                                                        '/communication/new-message'
                                                    );
                                                }}
                                                onSendClick={() => this.props.history.push(
                                                    '/communication/new-message'
                                                )}
                                                showIconCol={false}
                                                editIcon={false}
                                                deleteIcon={Delete}
                                                editAction={(e) => this.editSalah(e)}
                                                deleteAction={(e) => this.AskdeleteMessage(e)}
                                                actionLink={(name) => this.messageDetails(name)}
                                                showFooter={true}
                                                onAddClick={() => {                                                
                                                    this.props.history.push(
                                                        '/communication/new-message'
                                                    );
                                                }}
                                                iconTextSize={16}
                                                showAdd={false}
                                                showSend={true}
                                                sendText={'NEW MESSAGE'}
                                                sendIcon={Send}
                                                rowsPerPage={10}
                                            />
                                        </div>

                                    </GridItem>

                                    <div className="DeleteImgButton">

                                        <AlertDialogue
                                            title="Delete Message?"
                                            description="Are you sure you want to delete this Message?
                                            Deleting will permanently remove from everywhere."
                                            cancelText="Cancel"
                                            confirmText="Confirm"
                                            open={deleteModalOpen}
                                            handleCancel={this.handleCloseModal}
                                            handleClose={this.handleCloseModal}
                                            handleConfirm={this.handleConfirm}
                                            styles={modalStyle}
                                        />
                                    </div>
                                </>


                            </GridContainer>
                        )}

                    </>
                }
                isFooter={true}
                footer={
                    <FooterInner 
                        style={style}
                        termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
                        privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                    />
                }
                isWhiteBackground={false}
            />
        );
    }
}
const styles = {
    paperStyle: {
    },
    paperStyleNext: {
    },
};
const mapStateToProps = (state) => {
    return {
        services: state.services.services,
        alert: state.alert,
    };
};
const mapDispatchToProps = (dispatch) => ({
    editServiceHandler: (res) => dispatch(editServiceAction(res)),
    alertHide: (res) => dispatch(hideAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ComunicationMessages);
