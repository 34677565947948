import React, {useEffect, useState} from "react";
import Grid from '@material-ui/core/Grid';
import { MasterLayoutMiddle, FooterInner, ConnectedAccounts    } from "@dclab/dc-react-ui-kit";
import '../style.css'

import StripIcon from '../../assets/img/ConnectedIcons/Stripe.svg'
import Paypal from '../../assets/img/ConnectedIcons/PayPal.svg'
import Facebook from '../../assets/img/ConnectedIcons/Facebook.svg'
import YouTube from '../../assets/img/ConnectedIcons/YouTube.svg'
import Twitter from '../../assets/img/ConnectedIcons/Twitter.svg'
import {editOrganization, getOrganizationData} from './api'

function ConnectedAccountsComp(props) {
  const [socials, setSocials] = useState({
    youtube: '',
    facebook: ''
  });
  
  const [originalSocials, setOriginalSocials] = useState({});

  const [connected, setConnected] = useState({
    youtube: false,
    facebook: false
  })
  const [stripeRequired, setstripeRequired] = useState(true);

  const isConnected = (field) => {
      return connected[field];
  }
  useEffect(() => {
    const getData = async() => {
      const orgId = localStorage.getItem("orgID");
      try {
        const orgData = await getOrganizationData(orgId);
        if(orgData?.data?.body?.data) {
          const {orgSocials, enabledIntegrations} = orgData.data.body.data;
          console.log("enabledIntegrations?.deenfund: ", enabledIntegrations?.deenfund);
          if(enabledIntegrations?.deenfund?.stripeAccount) {
            setstripeRequired(false);
          }

          if(orgSocials) {
            let tempObj = {
              ...connected
            };
            
            if(orgSocials?.facebook?.length) {
              tempObj = {
                ...tempObj,
                facebook: true
              };
            
            }
            if(orgSocials?.youtube?.length) {
              tempObj = {
                ...tempObj,
                youtube: true
              };

            }
            setConnected(tempObj);
            setOriginalSocials(orgSocials);
            setSocials(orgSocials);
          }
        } 
      } catch(error) {
        console.log(error)
      }
    };
    getData();
  }, [])


  const submitForm = async (field) => {
    
    const token = localStorage.getItem("authToken");
      const orgId = localStorage.getItem("orgID");
      const obj = {
        orgSocials : {
          ...originalSocials,
          [field]: socials[field]
        }
      }
      
      try {
        console.log("socials: ", obj)
        await editOrganization(obj, orgId, token);
        //console.log(socials[field])
          setConnected({
            ...connected,
            [field]: socials[field].length > 0
          });
        
      } catch(err) {
        console.log("Error Occured: ",err);
      }
  }

  const onchange = (e, key) => {
    setSocials({
      ...socials,
      [key]: e.target.value
    })
  }
  // ======== Table Start =======//
  // const data = [
  //   {
  //     src: StripIcon,
  //     headline: "Stripe",
  //     body: "Stripe combines payments platform. ",
  //     StatusTitle: stripeRequired ? "Not connected" : "Connected" ,
  //     //StatusText: "Aug 12, 2020",
  //     linkText: "Learn More >",
  //     endIcon: true,
  //     callback: (e) => {console.log(`${e} is clicked`)},


  //     src: StripIcon,
  //     title: "Stripe",
  //     SubTitle: "Stripe combines payments platform. ",
  
  //     StatusTitle: "masjidbilalnyc@gmail.com",
  //     StatusText: "Aug 12, 2020",
  
  //     LearnMoreText:"Learn more",
  //     LearnMoreTextStatus:true,
  //     LearnMoreLink:"https://www.google.com/",
  
  //     callback: (e) => {console.log(`${e} is clicked`)},
  
  //     ConnectedButtonText:"Connect",
  //     ButtonIconStatus: true,
  //     ConnectedButtonStatus: true,
  //     buttonLink:"https://stripe.com/",
  
  //     DisConnectedText:"DISCONNECT",
  //     DisConnectedButtonStatus: false,
  //   },
   
  //   {
    
  //     src: Facebook,
  //     title: "Facebook",
  //     SubTitle: "Connect your social accounts with us.  ",
  //     StatusTitle: socials.facebook.length > 0 ? socials.facebook  : "Not connected",
  //     StatusText: "Aug 12, 2020",
  //     value: 'facebook.com',
     
  //     callback: (e) => {console.log(`${e} is clicked`)},
  //     ConnectedButtonText:"Link",
  //     ButtonIconStatus: false,
  //     ConnectedButtonStatus: true,
  //     buttonLink:"https://stripe.com/",

  //     //StatusText: "Aug 12, 2020",
  //     endIcon: false,
  //     callback: (e) => submitForm(),
  //     value: socials.facebook,
  //     onchange: (e) => onchange(e, 'facebook'),

      
  //   },
  
  //   {
  //     src: YouTube,
  //     headline: "YouTube",
  //     body: "Connect your social accounts with us.  ",
  //     StatusTitle: socials.youtube.length > 0 ? socials.youtube : "Not connected",
  //     //StatusText: "Aug 12, 2020",
  //     linkText: "Learn More >",
  //     endIcon: true,
  //      callback: (e) => submitForm(),
  //     value: socials.youtube,
  //     onchange: (e) => onchange(e, 'youtube')
  //   },
    
  // ];

  const onChange = (e) => {
    console.log("Field event is: ", e.target.value);
  }

  const checkDisable = (social) => {
    switch(social) {
      case 'facebook':
        return socials?.facebook?.length > 0 && socials.facebook.indexOf("https://www.facebook.com/") === -1;
      case 'youtube':
        return socials?.youtube?.length > 0 && socials.youtube.indexOf("https://www.youtube.com/") === -1;
      default: 
        return true
    }
  }

  const getAccordianData = () => {
    //console.log("Connected: ", connected);
    let data = [];
    if(!stripeRequired) {
      data.push({
        src: StripIcon,
        headline: "Stripe",
        body: "Stripe combines payments platform. ",
        StatusTitle: stripeRequired ? "Not connected" : "Connected" ,
        //StatusText: "Aug 12, 2020",
        linkText: "Learn More >",
        endIcon: true,
        callback: (e) => {console.log(`${e} is clicked`)},


        src: StripIcon,
        title: "Stripe",
        SubTitle: "Stripe combines payments platform. ",
    
        StatusTitle: "Connected",
        StatusText: "",
    
        LearnMoreText:"Learn more",
        LearnMoreTextStatus:false,
        LearnMoreLink:"https://www.google.com/",
    
        callback: (e) => {console.log(`${e} is clicked`)},
  
        ConnectedButtonText:"Connect",
        ButtonIconStatus: false,
        ConnectedButtonStatus: false,
        buttonLink:"https://stripe.com/",
        hideDetils: true,
        DisConnectedText:"DISCONNECT",
        DisConnectedButtonStatus: false,
      })
    }


    data.push({
        src: Facebook,
        title: "Facebook",
        StatusTitle: connected.facebook ? "Connected" : "Not connected",
        callback: (e) => submitForm('facebook'),
        onchange: (e) => onchange(e, 'facebook'),
        value: socials.facebook,
        ConnectedButtonText:"Link",
        ButtonIconStatus: false,
        ConnectedButtonStatus: true,
        //buttonLink:"https://stripe.com/",
        disabled: checkDisable('facebook'),
        Fieldlebel:" Enter Your Facebook Page Url",
        placeholder:"https://www.facebook.com/username",
    
    });
    
    data.push({
        src: YouTube,
        title: "YouTube",
        SubTitle: "Connect your social accounts with us.  ",
        StatusTitle: connected.youtube ? "Connected" :  "Not connected",
        callback: (e) => submitForm('youtube'),
        onchange: (e) => onchange(e, 'youtube'),
        value: socials.youtube,
        ConnectedButtonText: "Link",
        ButtonIconStatus: false,
        ConnectedButtonStatus: true,
        disabled: checkDisable('youtube'),
       // buttonLink:"https://stripe.com/",
  
        Fieldlebel:"Enter Your YouTube Channel URL",
        placeholder:"https://www.youtube.com/channel/username",
    });

    return data;
  }
  // const data = [
  //   {
  //     src: StripIcon,
  //     title: "Stripe",
  //     SubTitle: "Stripe combines payments platform. ",
  
  //     StatusTitle: "masjidbilalnyc@gmail.com",
  //     StatusText: "Aug 12, 2020",
  
  //     LearnMoreText:"Learn more",
  //     LearnMoreTextStatus:true,
  //     LearnMoreLink:"https://www.google.com/",
  
  //     callback: (e) => {console.log(`${e} is clicked`)},
  
  //     ConnectedButtonText:"Connect",
  //     ButtonIconStatus: true,
  //     ConnectedButtonStatus: true,
  //     buttonLink:"https://stripe.com/",
  
  //     DisConnectedText:"DISCONNECT",
  //     DisConnectedButtonStatus: false,

  //     Fieldlebel:"Enter Your Stripe Email ID",
  //     placeholder:"Enter Your Stripe Email ID",
      
  
    // },
    // {
    //   src: Paypal,
    //   title: "Paypal",
    //   StatusTitle: "Not connected",
    //   callback: (e) => {console.log(`${e} is clicked`)},
    //   ConnectedButtonText:"Connect",
    //   ButtonIconStatus: true,
    //   ConnectedButtonStatus: false,
    //   DisConnectedText:"DISCONNECT",
    //   DisConnectedButtonStatus: true,
    // },
  
    // {
    //   src: Facebook,
    //   title: "Facebook",
    //   StatusTitle: socials?.facebook?.length > 0 ? "Connected" : "Not connected",
    //   value: socials.facebook,
    //   onchange: (e) => onchange(e, 'facebook'),
     
    //   callback: (e) => submitForm('facebook'),
    //   ConnectedButtonText:"Link",
    //   ButtonIconStatus: false,
    //   ConnectedButtonStatus: true,
    //   //buttonLink:"https://stripe.com/",

    //   Fieldlebel:" Enter Your Facebook Page Url",
    //   placeholder:"https://www.facebook.com/username",
  
    // },
  
    // {
    //   src: YouTube,
    //   title: "YouTube",
    //   SubTitle: "Connect your social accounts with us.  ",
    //   StatusTitle: socials?.facebook?.length > 0 ? "Connected" :  "Not connected",
    //   callback: (e) => submitForm('youtube'),
    //   onchange: (e) => onchange(e, 'youtube'),
    //   value: socials.youtube,
    //   ConnectedButtonText: "Link",
    //   ButtonIconStatus: false,
    //   ConnectedButtonStatus: true,
    //  // buttonLink:"https://stripe.com/",

    //   Fieldlebel:"Enter Your YouTube Channel URL",
    //   placeholder:"https://www.youtube.com/channel/username",
    // },
    // {
    //   src: Twitter,
    //   title: "Twitter",
    //   StatusTitle: "Not connected",

    //   callback: (e) => {console.log(`${e} is clicked`)},
    //   ConnectedButtonText:"Link",
    //   ButtonIconStatus: false,
    //   ConnectedButtonStatus: true,
    //   buttonLink:"https://stripe.com/",

    //   Fieldlebel:"Enter Your Twitter Page Url",
    //   placeholder:"https://twitter.com/orgusername",
    // },
  // ];
  
  // ======= Table End ==========//

  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      content={
        <div>
          
          
            <Grid container spacing={3} >

            
              
            <Grid item md={12} sm={12} xs={12} >
              
                <div className="table_box">
                <ConnectedAccounts
                  data={getAccordianData()}
                  Title="Connected Accounts" 
                  SubTitle="Connect payment processor and social accounts"
                />
                </div>
              </Grid>
            </Grid>
        
         
            
     
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
export default ConnectedAccountsComp;