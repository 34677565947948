import React, { Component } from 'react';
import { connect } from 'react-redux';
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import {
    AlertDialogue,
    SimpleTable,
    GridContainer,
    GridItem,
    MasterLayoutMiddle,
    AlertAction,
    FooterInner
} from '@dclab/dc-react-ui-kit';
import {
    getServices,
    deleteService,
    editOrganization,
    getService,
    getOrganizationData,
} from '../Organization/api';
import { editServiceAction } from '../../redux/actions/servicesAction';
import { getCommunicationsData , deleteTemplate } from './Functions';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../App.css';
import '../style.css';
import { hideAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
const {
    remove_template_message,
    add_com_template_success,
} = messages_content;

const orgEnv = {
    prod: process.env.REACT_APP_BASEURL_PROD_API,
    dev: process.env.REACT_APP_BASEURL_DEV_API
  };
  
  let currentEnv;
  if (
    window.location.host.includes("amazon") ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("shapla")
  ) {
    currentEnv = "dev";
  } else {
    currentEnv = "prod";
  }
  
  const orgBaseUrl = orgEnv[currentEnv];


class Templates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabInd: 0,
            activeTab: 0,
            items: [],
            error: '',
            showAlert: false,
            success: false,
            deleteSuccess: false , 
            facilitiesArr: [],
            orgFacilities: [],
            openAlert: false,
            deleteServiceId: '',
            loading: true,
            modalLoading: false,
            deleteModalOpen: false ,
            facilitiesLoading: false,
            removeFacility: false,
            selectedTemplateId: null ,
        };
        this.onClick = this.onClick.bind(this);
        this.editService = this.editService.bind(this);
        // this.deleteServiceAction = this.deleteServiceAction.bind(this);
        this.addFacilities = this.addFacilities.bind(this);
        this.saveFacilities = this.saveFacilities.bind(this);
        // this.getCommunicationsData = this.getCommunicationsData.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleServiceConfirm = this.handleServiceConfirm.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.hideAlert = this.hideAlert.bind(this);
        this.editTemplate = this.editTemplate.bind(this);
        this.AskdeleteTemplate = this.AskdeleteTemplate.bind(this);
    }
    async editTemplate(e){
        // console.log( e[3].value.toString() );
        this.props.history.push( '/communication/add-template/'+e[3].value.toString());
        
    }

    async AskdeleteTemplate (e) {
        const templateId = e[3].value ; 
        console.log( templateId );
        this.setState({selectedTemplateId : templateId , deleteModalOpen: true  });
    };

    
    
    async componentDidMount() {

        const token = localStorage.getItem('authToken');
        let orgId = localStorage.getItem('orgID');
        const status = this.props.match.params.status ; 

        if( status && status.length ){
            this.setState({
                success: true
            });
        }

        try {
            
            const items = await getCommunicationsData('templates' , orgId, token);
            this.setState({
                items: items.data.body.data,                
                loading: false,
            });

        } catch (error) {
            this.setState({
                error: error.response && error.response.data.message,
                loading: false,
            });
        }
    }

    hideAlert = () => {
        setTimeout(() => {
            this.setState({ showAlert: false });
        }, 8000);
    };
    addFacilities = (e, item) => {
        
    };
    saveFacilities = async () => {
        
    };
    editService = async (e) => {
       
    };
    handleClose = () => {
        this.setState({ openAlert: false });
    };
    handleServiceConfirm = async () => {
        
    };
    
    onClick = (x, y) => {
        this.setState({
            tabInd: y,
        });
    };
    handleTabChange = (event, newValue) => {
        this.setState({ activeTab: newValue });
    };

    handleConfirm = async () =>{
        // console.log( this.state.selectedTemplateId );

        await deleteTemplate( 'templates', this.state.selectedTemplateId );

        const updateList = this.state.items.filter(
        (template) => template._id !== this.state.selectedTemplateId
        );

        this.setState({ deleteModalOpen: false , deleteSuccess: true , items: updateList });        

    }

    handleCloseModal = () =>{
        this.setState({ deleteModalOpen: false });
    }
    render() {
        const {
            items,
            deleteModalOpen, 
            deleteSuccess,
            loading,
            success,
        } = this.state;
        if (this.props.alert.alertValue) {
            setTimeout(() => {
                this.props.alertHide()
            }, 8000);
        }

       
        let col = [
            { type: 'string', name: 'string', label: 'NAME', width: '20%' },
            { type: 'string', name: 'string', label: 'BODY', width: '60%' },
            { type: 'string', name: 'single_pic', label: 'TYPE', width: '20%' }, 
            { id: "ACTION",  label: "", width: '10%' },           
            { id: "ACTION",  label: "ACTION", width: '10%' },
        ];

        let rows = items.map((ser, i) => {

            return [
                { type: 'string', name: 'name', value: ser.templateName },
                { type: 'string', name: 'date', value: ser.templateBody  },
                { type: "string", name: 'chipBlue', value: ser.templateType },
                { type: "string", name: "_id",   value:  ser._id }
            ];
        });


        const style = {
            width: '100%',
        };
        const modalStyle = {
            width: 470,
            marginLeft: 800
          };

        return (
            <MasterLayoutMiddle
                header={
                    <>

                    </>
                }
                content={
                    <>
                        {success && (
                        <div
                            style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                        >
                            <AlertAction
                            alert={add_com_template_success}
                            alertType={"success"}
                            onClick={() => this.setState({ success: false })}
                            />
                        </div>
                        )}

                        {deleteSuccess && (
                        <div
                            style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                        >
                            <AlertAction
                            alert={remove_template_message}
                            alertType={"success"}
                            onClick={() => this.setState({ deleteSuccess: false })}
                            />
                        </div>
                        )}

                        {loading ? (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: 30,
                                }}
                            >
                                <CircularProgress color='primary' />
                            </div>
                        ) : ( 
                        <GridContainer spacing="0">
                                <>
                                    <GridItem itemStyle={{ marginTop: 0 }} itemClass='grid-item'>
                                        <div className='services-table'>
                                            <SimpleTable
                                                showHeader={true}
                                                title='Templates'
                                                width='98%'
                                                rows={rows}
                                                columns={col}
                                                colSpan={16}
                                                actionSendButton={true}
                                                donationsCount={rows && rows.length > 0 ? 1 : 0}
                                                noTitle="No Templates added"
                                                noPara="Added Templates will be displayed and managed here"
                                                noBtnText="Add New Template"
                                                onInviteClick={() => {
                                                    this.props.editServiceHandler();
                                                    this.props.history.push(
                                                        '/communication/add-template'
                                                    );
                                                }}

                                                showIconCol={true}
                                                editIcon={Edit}
                                                deleteIcon={Delete}
                                                editAction={(e) => this.editTemplate(e)}
                                                deleteAction={(e) => this.AskdeleteTemplate( e ) }
                                                actionLink={(name) => this.details(name)}
                                                showFooter={true}
                                                showAdd={true}
                                                onAddClick={() => {
                                                    this.props.editServiceHandler();
                                                    this.props.history.push(
                                                        '/communication/add-template'
                                                    );
                                                }}
                                                iconTextSize={16}
                                                addText='ADD NEW'
                                                rowsPerPage={10}
                                            />
                                        </div>

                                        

                                    </GridItem>

                                        <div className="DeleteImgButton">

                                            <AlertDialogue
                                                title="Delete Template?"
                                                description="Are you sure you want to delete this Template?
                                                Deleting will permanently remove from everywhere."
                                                cancelText="Cancel"
                                                confirmText="Confirm"
                                                open={deleteModalOpen}
                                                handleCancel={this.handleCloseModal}
                                                handleClose={this.handleCloseModal}
                                                handleConfirm={this.handleConfirm}
                                                styles={modalStyle}
                                            />
                                        </div>

                                </>


                            </GridContainer>
                    )}

                        

                    </>
                }
                isFooter={true}
                footer={
                    <FooterInner 
                        style={style}
                        termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
                        privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                    />
                }
                isWhiteBackground={false}
            />
        );
    }
}
const styles = {
    paperStyle: {
    },
    paperStyleNext: {
    },
};
const mapStateToProps = (state) => {
    return {
        services: state.services.services,
        alert: state.alert,
    };
};
const mapDispatchToProps = (dispatch) => ({
    editServiceHandler: (res) => dispatch(editServiceAction(res)),
    alertHide: (res) => dispatch(hideAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Templates);
