import React, { useState, useEffect } from "react";
import { CheckOrderSummary, AlertAction } from "@dclab/dc-react-ui-kit";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Grid from '@material-ui/core/Grid';
import './style.css'

import MasterCardImg from '../../assets/img/masterCardIcon.png'
import VisaCardImg from '../../assets/img/visa-icon.png'
import NewCardImg from '../../assets/img/new-card.png'
import DiscoverCardImg from '../../assets/img/discovercard.png';
import AmericanExpressImg from '../../assets/img/american-express.png';
import DinersCardImg from '../../assets/img/dinerscard.png';
import HelpImg from '../../assets/img/help-24px.svg'
import { CheckoutPayment } from "master-react-lib";
import JcbCardImg from '../../assets/img/jcb-card.png';
import UnionPayImg from '../../assets/img/unionpay.png';

import { updateUserSubscriptions, stripeTokenGet, addCustomerPaymentMethods, getCustomerPaymentMethods } from '../Organization/api';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getPlans, getCurrentUserDetail, getOrganizationData, getValidatePromoCode } from "./api";
import {getPlanSubscriptionsByOrgId} from "../../pages/UserProfile/Apis";



function AppCheckout(props) {
  // ============================== Oder Sumary start ======================================//
  const [discountCode, setDiscountCode] = React.useState("");
  const [discount, setDiscount] = React.useState(0);
  const [cartItems, setcartItems] = useState([])
  const [plans, setplans] = useState({})
  const [paymentMethods, setpaymentMethods] = useState([])
  const [userPlanDetails, setuserPlanDetails] = useState([])
  const [selectedCardDetail, setSelectedCardDetail] = useState({});
  const [discountError, setdiscountError] = useState({error: false, msg: ''});
  const [discountDetails, setdiscountDetails] = useState({});
  const [promoCodeApplied, setpromoCodeApplied] = useState(false);
  const [subTotal, setsubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [discountAmount, setdiscountAmount] = useState(0);


  useEffect(() => {
    if(props?.location?.state?.userPlanDetails?.planSubscriptionData) {
        let planSubscriptionData = props?.location?.state?.userPlanDetails?.planSubscriptionData;
        const cartData = Object.keys(planSubscriptionData)
        .filter(v => {
          return planSubscriptionData[v] != null && typeof planSubscriptionData[v] === 'object'
        }).map(key => ({ [[key][0]]: { ...planSubscriptionData[key] }}))

        let data = [];
        let a = cartData?.forEach((el, i) => {
          let name = Object.keys(el)[0];
          // console.log("name", name, el[name])
          if(name === "extraIntegrations") {            
            let b = el[name].selectedIntegrations.forEach((int) => {
              // data = { ...data, [int.name]: {...int, total: int.price}}
              data.push({[int?.name?.toLowerCase()]: {...int, total: int.price, type: "integration" }})
            })
          } else {
            // data = { ...data, [name]: {...el[name], name: name}}
            data.push({ [name]: {...el[name], name: name} })
          }
        })

        // console.log("arrs1", data)
        setcartItems(data)
        localStorage.setItem("appCartItem", JSON.stringify(data))

    } else {
      let cartData = JSON.parse(localStorage.getItem("appCartItem")) ? JSON.parse(localStorage.getItem("appCartItem")) : [];
      setcartItems(cartData)
      // console.log("arrs2", cartData)
    }
  }, []);

  const validateCode = async () => {
    if(promoCodeApplied) {
      setDiscountCode('');
      setpromoCodeApplied(false);
      return;
    }
    const token = localStorage.getItem("authToken");
    try {
      if(discountCode.length > 0) {
        const promoCodeResponse = await getValidatePromoCode(token, discountCode);
        
        if(promoCodeResponse.error) {
          setpromoCodeApplied(false);
          setdiscountError({
            error: true,
            msg: 'the promotion code you entered is invalid'
          });
        } else {
          const promoObject = promoCodeResponse?.body?.data ?? {};
          if(Object.keys(promoObject).length > 0) {
            let tempSubtotal = 0;
            let tempDiscount = 0;
            // setpromoCodeDetails(promoObject.code);
            if(promoObject?.coupon.amount_off) {
              tempDiscount = promoObject.coupon.amount_off/100;
              tempSubtotal = subTotal - tempDiscount;

              setdiscountAmount(tempDiscount);
              setTotal(tempSubtotal);
            } else if(promoObject?.coupon.percent_off) {
              tempDiscount = (promoObject.coupon.percent_off / 100) * subTotal;
              tempSubtotal = subTotal - tempDiscount;

              setdiscountAmount(tempDiscount);
              setTotal(tempSubtotal);
            }
          }
          setpromoCodeApplied(true);
          setdiscountError({
            error: false,
            msg: ''
          });
        }
      } else {
        setpromoCodeApplied(false);
        setdiscountError({
          error: true,
          msg: 'Please enter promo code'
        });
      }

    } catch(err) {
      console.log(err);
    }
}


  // let sum = 0;
  // const x = cartItems?.length ? cartItems.forEach((item) => {
  //   let appName = Object.keys(item)[0];
  //   if(appName?.toLowerCase() === "extraintegrations") {
  //       let add = item[appName]?.selectedIntegrations?.length && item[appName]?.selectedIntegrations?.forEach((el) => {
  //         sum = sum + parseFloat(el.price ?? 0)
  //       })
  //   } else {
  //     sum = sum + parseFloat(item[appName].total)
  //   }
  // }) : []; 

  React.useEffect(() => {
    if (discount < 100 && discountCode !== "") {
      setDiscount(discount + 1);
    } //Set Your Discount Logic Here.
    if (discountCode === "") {
      setDiscount(0);
    }
  }, [discountCode]);

  const callback = (code, total) => {
    setDiscountCode(code);
  };

  const dataset = [
    {
      title: "Sub Total",
      value: `$${subTotal ? (subTotal).toFixed(2) : 0.00}`,
    },
    // {
    //   title: <p style={{margin: 0}}> Estimated tax for: <span style={{color: '#4b91fa'}}> 11218 </span> </p>,
    //   value: "$0.00",
    //   info: true,
    //   help_text: 'Lorem Ipsum'
    // },
    {
      title: "Discounts",
      value: `${discountAmount > 0 ? '- $' +  discountAmount.toFixed(2) : '$0.00'}`,
    },
  ];
  // ============================== Oder Sumary End ======================================//

  // ============================== Checkout Start ======================================//
    const [planDetails, setplanDetails] = useState({
         email: "", planSubscriptionsData: [], planSubscriptionId: "", 
    });

    const [cardDetails, setcardDetails] = React.useState({
        cardNumber: "", expiration: "", code: ""
    });

    const [addressDetails, setaddressDetails] = React.useState({
        firstName: "", lastName: "", line1: "", line2: "", city: "", state: "", postalCode: "", country: "",
    });

    const [saveCardDetails, setsaveCardDetails] = React.useState(false);
    const [error, seterror] = useState(false)
    const [loading, setloading] = useState(false)
    const [selectedPaymentCard, setselectedPaymentCard] = useState({
      cardId: "", customerId: ""
    })

    

    const handlePaymentSelectChange = (card) => {
      console.log("card: ", card)
      if(card === 'newCard') {
        setSelectedCardDetail({});
      } else {
        const paymentMethodSelected = paymentMethods.filter(method => method.id == card.split("|")[0]);
        console.log("paymentMethodSelected: ", paymentMethodSelected)
        if(paymentMethodSelected) {
          let selectedCardDetails = paymentMethodSelected?.[0].billing_details?.address?.line1 ? {
            firstName: paymentMethodSelected?.[0].billing_details?.name?.split(' ')?.[0] ?? "", lastName: paymentMethodSelected?.[0].billing_details?.name?.split(' ')?.[1] ?? "", ...paymentMethodSelected?.[0].billing_details.address,
          } : {};

          setSelectedCardDetail(selectedCardDetails);
        }
        setselectedPaymentCard({customerId: card.split("|")[1], cardId: card.split("|")[0]})
      }
      
    };

    const onEditBillingDetailClick = () => {
        console.log("onEditBillingDetailClick");
    };

    const cardBrandImage = (brand) => {
      brand = brand?.toLowerCase();
      switch (brand) {
        case "visa":
          return VisaCardImg
      
        case "amex":
          return AmericanExpressImg
      
        case "discover":
          return DiscoverCardImg
      
        case "diners_club":
          return DinersCardImg
      
        case "jcb":
          return JcbCardImg
      
        case "mastercard":
          return MasterCardImg
      
        case "unionpay":
          return UnionPayImg
      
        default:
          return ""
      }
    }

    const creditCardsList = paymentMethods?.length ? paymentMethods.map((el) => {
      return {
        cardName: `${el?.card?.brand?.toUpperCase()} - ${el.card.last4}`, 
        cardNumber: `${el?.id}|${el?.customer}`, 
        cardImage: cardBrandImage(el?.card?.brand)
      }
    }) : [];

    const onPlaceOrderSubmit = async (details) => {
      console.log("details", details)
      if(details?.paymentCheque) {
          setloading(true);
          try {
            let res;
              console.log("selectedPaymentCard: ", selectedPaymentCard)
              res = await updateUserSubscriptions(selectedPaymentCard, {
              
                  orgId: localStorage.getItem("orgID"),
                  paymentCheque: true,
                  ...planDetails,
              });
            
            if(res?.data?.error) {
              console.log("error", res?.data?.message)
              seterror(res?.data?.message || "Invalid Payment Credentials");
              setloading(false)
            } else {
              seterror(false)
              setloading(false)
              localStorage.removeItem("appCartItem")
              props.history.push("/payment-successful");
            }
        }
            catch (error) {
            console.log(error)
            setloading(false)
            seterror(error?.message || "Invalid Payment Credentials");
        }
      } else if(details?.selectedCardDetails ) {
        setloading(true)
        const { firstName, lastName, line1, line2, city, state, postal_code: postalCode, country } = details?.selectedCardDetails;

        let addressInfo = {
            line1, line2, city, state, postalCode, country
        };
        let name = {
            firstName, lastName,
        };

        try {
            console.log("formData", selectedPaymentCard, {
              name, 
              address: addressInfo, 
              orgId: localStorage.getItem("orgID"),
              newCard: false,
              ...planDetails,
          });
            let res;
            if(discountCode.length > 0) {
              res = await updateUserSubscriptions(selectedPaymentCard, {
                name, 
                address: addressInfo, 
                orgId: localStorage.getItem("orgID"),
                promoCode: discountCode,
                newCard: false,
                ...planDetails,
            });
            } else {
              res = await updateUserSubscriptions(selectedPaymentCard, {
                  name, 
                  address: addressInfo, 
                  orgId: localStorage.getItem("orgID"),
                  newCard: false,
                  ...planDetails,
              });
            }
            if(res?.data?.error) {
              console.log("error", res?.data?.message)
              seterror(res?.data?.message || "Invalid Payment Credentials");
              setloading(false)
            } else {
              seterror(false)
              setloading(false)
              localStorage.removeItem("appCartItem")
              props.history.push("/payment-successful");
            }
        }
            catch (error) {
            console.log(error)
            setloading(false)
            seterror(error?.message || "Invalid Payment Credentials");
        }

      } else {
        setloading(true)
        const { cardNumber, expiration, code } = details?.cardData;
        const { firstName, lastName, line1, line2, city, state, postalCode, country } = details?.addressDetails;

        let cardData = {
            cvc: code,
            exp_month: parseInt(expiration.split("/")[0]),
            exp_year: +('20' + expiration.split("/")[1]),
            number: cardNumber
        };
        let addressInfo = {
            line1, line2, city, state, postal_code: postalCode, country
        };
        let name = {
            firstName, lastName,
        };

        console.log("formDatanew", cardData, {
          name, 
          address: addressInfo, 
          saveCard: saveCardDetails,
          orgId: localStorage.getItem("orgID"),
          newCard: true,
          ...planDetails,
      });

        try {
          let res;
          if(props?.location?.state?.userPlanDetails?.planSubscriptionData) {

            res = await stripeTokenGet(cardData, {
              name, 
              address: addressInfo, 
              saveCard: saveCardDetails,
              orgId: localStorage.getItem("orgID"),
              ...planDetails,
            });
          } else {
            if(discountCode.length > 0) {
              res = await updateUserSubscriptions(cardData, {
                  name, 
                  address: addressInfo, 
                  saveCard: saveCardDetails,
                  orgId: localStorage.getItem("orgID"),
                  promoCode: discountCode,
                  newCard: true,
                  ...planDetails,
              });
            } else {
              res = await updateUserSubscriptions(cardData, {
                name, 
                address: addressInfo, 
                saveCard: saveCardDetails,
                orgId: localStorage.getItem("orgID"),
                newCard: true,
                ...planDetails,
              });
            }
          } 
            if(res?.data?.error) {
              console.log("error", res?.data?.message)
              seterror(res?.data?.message || "Invalid Payment Credentials");
              setloading(false)
            } else {
              seterror(false)
              setloading(false)
              localStorage.removeItem("appCartItem")
              props.history.push({pathname: `/payment-successful`, state: { showSuccessAlert: true} });
            }
        }
            catch (error) {
            console.log(error)
            setloading(false)
            seterror(error?.message || "Invalid Payment Credentials");
        }

        try {
          if(saveCardDetails) {
            let billingData = {
              address: { ...addressInfo },
              name: firstName + " " + lastName,
            };
            let orgId = localStorage.getItem("orgID");
                      
            let res = await addCustomerPaymentMethods({cardData, billingData, orgId});
            if(res?.data?.error) {
              console.log("error", res?.data?.message)
              seterror(res?.data?.message || "Invalid Payment Credentials");
            }
          }
        } catch (error) {
          console.log(error)
        }
      }
    }

    useEffect(() => {
        const getDetails = async () => {
        try {
          const token = localStorage.getItem("authToken");
          const orgId = localStorage.getItem("orgID");
          const res = await getCurrentUserDetail(token);
          console.log("Customer: ", res);
          const planSubscriptionRes = await getPlanSubscriptionsByOrgId(orgId, token);
          console.log("planSubscriptionRes: ", planSubscriptionRes);
          const resOrg = await getOrganizationData(orgId);
          const { orgPaymentMethods } = resOrg?.data?.body?.data;
          if(orgPaymentMethods?.length) {
            
            const methodsRes = await getCustomerPaymentMethods(orgPaymentMethods);
            console.log("paymentMethods called: ", methodsRes?.data?.body?.data);
            methodsRes?.data?.body?.data && setpaymentMethods(methodsRes?.data?.body?.data)
          }
          
          //console.log("paymentMethods: ", paymentMethods)
          const plansRes = await getPlans(orgId, token);
          if (plansRes?.data?.body?.data?.stripeData?.length) {
            let customerId = plansRes?.data?.body?.data?.stripeData?.map((el) => el.customerId);
            // console.log("customerId", [...new Set(customerId)])
            const paymentMethodRes = await getCustomerPaymentMethods([...new Set(customerId)], token);
            if(paymentMethodRes?.data?.body?.data?.length) {
              setpaymentMethods(paymentMethodRes.data.body.data);
            }
          }

          if(plansRes?.data?.body?.data) {
            setplans(plansRes?.data?.body?.data)
          }

          if(planSubscriptionRes?._id) {
            // planSubscriptionsData: arr,
            setplanDetails((prevState) => {
              return {
                ...prevState,
                planSubscriptionId: planSubscriptionRes?._id,
                email: res?.userName ?? "",
                orgName: localStorage.getItem("organame") !== "undefined" ? localStorage.getItem("organame") : localStorage.getItem("short_name")
              }
            });
          }
        }
        catch (error) {
          console.log(error)
        }
      }
      getDetails()
    }, [])
    
    useEffect(() => {
      let selectedIntegrations = [];
      let arr = cartItems?.length ? cartItems?.map((el, i) => {
        let name = Object.keys(el)[0];
        if(el[name]?.type === "integration") {
          selectedIntegrations.push({ ...el[name], name: name, createdAt: new Date() })
        } else {
          return { ...el[name], planName: name, createdAt: new Date()}
        }
      }).filter((el) => el !== undefined) : null;

      if(arr) {
        arr = [ ...arr, {planName: "extraIntegrations", selectedIntegrations}];
        console.log("arrs", arr)

        setplanDetails((prevState) => {
          return {
            ...prevState,
            planSubscriptionsData: arr,
          }
      });
      }

      let sum = 0;
      const x = cartItems?.length ? cartItems.forEach((item) => {
        let appName = Object.keys(item)[0];
        if(appName?.toLowerCase() === "extraintegrations") {
            let add = item[appName]?.selectedIntegrations?.length && item[appName]?.selectedIntegrations?.forEach((el) => {
              sum = sum + parseFloat(el.price ?? 0)
            })
        } else {
          sum = sum + parseFloat(item[appName].total)
        }
      }) : []; 
      setTotal(sum);
      setsubTotal(sum);

    }, [cartItems])
    
  return (
    <div>
        {error && (
          <div
            style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
          >
            <AlertAction
              alert={error}
              alertType={'error'}
              onClick={() => seterror(false)}
            />
          </div>
        )}
          <>
            <div className="greyHeader">
              <div className="midContainer">
                <div
                  onClick={() => props.history.goBack()}
                  className="backBtn">
                  <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                  <p style={{ alignSelf: "center" }}>Back</p>{" "}
                </div>
                <h1 className="checkTitle">Checkout</h1>
              </div>
            </div>
          </>

          <div className="CheckOutFormContainer midContainer">
            <Grid container spacing={3}>

              <Grid item xs={8}>
              {loading && <div
                  style={{
                    position: 'relative',
                    top: "50%",
                    left: "50%",
                    zIndex: 1000
                  }}
                >
                  <CircularProgress color='primary' />
                </div>}
              <CheckoutPayment 
                    paywithdropdown={true}
                    creditCardsList={creditCardsList}
                    handlePaymentSelectChange={handlePaymentSelectChange}
                    onPlaceOrderSubmit={onPlaceOrderSubmit}
                    cardDetails={cardDetails}
                    setcardDetails={setcardDetails}
                    addressDetails={addressDetails}
                    setaddressDetails={setaddressDetails}
                    saveCardDetails={saveCardDetails}
                    setsaveCardDetails={setsaveCardDetails}
                    selectedCardDetails={selectedCardDetail}
                    privacyPolicyLink={"/privacy-policy"}
                    termsOfUseLink={"/terms-of-use"}
                    onEditBillingDetailClick={onEditBillingDetailClick}
                    visaCardImage={VisaCardImg}
                    masterCardImage={MasterCardImg}
                    newCardImage={NewCardImg}
                    discoverCardImage={DiscoverCardImg}
                    americanExpressImage={AmericanExpressImg}
                    dinersCardImage={DinersCardImg}
                    jcbCardImg={JcbCardImg}
                    unionPayImg={UnionPayImg}
                    helpImage={HelpImg}
                    ButtonLabel="PLACE ORDER"
                    ScuredLabel="Secured checkout"
                    checkboxLabel="Save my card details for future transaction"
                    Tooltipcheckboxtext="We don't save your credit card details, your credit card information is safe with our trusted and secured partner Stripe"
                    BottomHelperText={`By placing your order you agree to our <a target = 'blank' href=${process.env.REACT_APP_Environment === 'dev' ? 'https://shapla.deenconnect.io/terms-policies#privacy-policy' : 'https://deenconnect.io/terms-policies#privacy-policy' }>Privacy Policy</a> and <a target = 'blank' href=${process.env.REACT_APP_Environment === 'dev' ? 'https://shapla.deenconnect.io/terms-policies' : 'https://deenconnect.io/terms-policies'}>Terms of Use</a>`}
                    TooltipQuestionIcontext="CVV is the last 3 digits on the back. In American Express, it is a 4 digit code in the front of the credit card"

                    buttoncolor="#0053A5"
                    buttonwidth="100%"
                    buttonheight="50px"
                    buttonborder="#ccc 0px solid"
                    buttonRadiusr="100px"
                    buttonTextcolor="#fff"
                    buttonfontWeight="600"

                    chequePayableTo = 'AFNAN INNOVATION LAB, INC'
                    mailToName = 'Abdullah Al Razi'
                    mailToStreet = '280 East 7 St'
                    mailToCity = 'Brooklyn'
                    mailToState = 'NY'
                    mailToPostalCode = '11218'



                />
              </Grid>

              <Grid item xs={4}>
                <CheckOrderSummary
                  dataset={dataset}
                  callback={callback}
                  discount={discount}
                  summary="Order Summary"
                  dropDownSummary="Promo code"
                  dropDownDetails="Enter Code Here"
                  bottomText="Total"
                  buttomSubText="$0"
                  text="Continue to payment"
                  apply={promoCodeApplied ? "Remove" : "APPLY"}
                  error = {discountError.error ? discountError.msg  :''}
                  //errorTop={'Invalid code'}
                  total={total ? total.toFixed(2) : 0}
                  editSection={true}
                  cartItemCount={cartItems?.length ?? 0}
                  editCartSection={true}
                  onEditCartClick={() => props.history.push("/cart-details")}
                  showCheckoutButton={false}
                  applyCode = {validateCode}
                  codeApplied = {promoCodeApplied}
                  CodeField = {true}
                />
              </Grid>

            </Grid>

          </div>
      


        

      

    </div>
  )
}


export default AppCheckout
