import axios from "axios";

const baserUrl =
  "https://cors-anywhere.herokuapp.com/http://18.211.207.245:3000";
const env = {
  prod: process.env.REACT_APP_PAYMENT_PROD_API,
  dev: process.env.REACT_APP_PAYMENT_DEV_API
};
const orgEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")
) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}
const orgBaseUrl = orgEnv[currentEnv];

export function getCategories(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/donation-category?orgId=${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sortCategories(obj, token) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/donation-category/sortCategoriesList`, obj, {
        headers: {
          "Content-Type": "application/json",
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCategory(id) {

  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/donation-category/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function addCategories(data, token) {

  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/donation-category`, data, {
        headers: {
          "Content-Type": "application/json",
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);

      });
  });
}


export function editCategory(data, token, id) {

  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/donation-category/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);

      });
  });
}
export function editEmbedFormCategories(data, token, id) {

  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/organizations/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function deleteCategory(id, token) {

  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/donation-category/${id}`, {
        headers: {
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);

      });
  });
}
export function editOrganization(data, id, token) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/organizations/${id}`, data, {
        headers: {
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getOrganizationData(id, token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/organizations/${id}`, {
        headers: {
          token: token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getaddressFromPostalCode(postalCode, country, ipCountry) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/shared/addressFromPostalCode?postal_code=${postalCode}&country=${country}&ipCountry=${ipCountry}`)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function testAddressFromPostalCode(postalCode, country) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/shared/testAddressFromPostalCode?postal_code=${postalCode}&country=${country}`)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function addFaq(id, faqs, token) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/organizations/${id}`, faqs, {
        headers: {
          "Content-Type": "application/json",
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getEmbedForm(id, token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/organizations/${id}`, {
        headers: {
          "Content-Type": "application/json",
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function publishEmbedForm(data, id, token) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/organizations/${id}`, data, {
        headers: {
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTeamMember(id, token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/team-members/${id}`, {
        headers: {
          token: token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function inviteDonor(id, token, data){
  
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/organizations/${id}/createInviteDonor`, data, {
        headers: {
          "Content-Type": "application/json",
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);

      });
  });
}

export function editInviteDonor(id, token, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/organizations/${id}/updateInviteDonor`, data, {
        headers: {
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getInviteDonor(id, token){

  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/organizations/${id}/getInviteDonor`, {
        headers: {
          "Content-Type": "application/json",
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);

      });
  });
}

export function getInviteDonors(id, token){

  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/organizations/${id}/getInviteDonors`, {
        headers: {
          "Content-Type": "application/json",
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);

      });
  });
}

export function deleteInviteDonor(id, token){

  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/organizations/${id}/deleteInviteDonor`, {
        headers: {
          "Content-Type": "application/json",
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);

      });
  });
}