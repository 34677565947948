import React, { Component } from 'react'
import { SimpleTable, EmptyTable, MasterLayoutMiddle, FooterInner, AlertAction, AlertDialogue } from "@dclab/dc-react-ui-kit";
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import Handle from "../../assets/img/more_vert-24px.svg";
import Moment from "moment";
import '../../App.css'
import "./style.css";
import { getAllSalah, DeletesalahWebsite } from '../Donations/deen-kioks-website/Apis';
import { CircularProgress } from '@material-ui/core';
import { sortSalahsWebsite, getSalahsWithAppId, deleteSalahsWithAppId, getPhotoGalleryWithOrgId, deletePhotoFromGallery, sortPhotosWebsite } from './api';
import { connect } from 'react-redux';
import { hideAlert, showAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
import { editOrganization, getOrganizationData } from '../Organization/api';
import image from "../../assets/img/insert_photo.png"
const { token_expired, remove_photo_gallery_message } = messages_content;

class PhotoGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Salah: [],
      PhotoGallery: [],
      error: false,
      openAlert: false,
      specificRow: [],
      data: [],
      status: '',
      PhotoGalleryList: [],
      orgIntegrateData: {}
    }
  }
  async componentDidMount() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const resPhotGet = await getPhotoGalleryWithOrgId(token, orgId)
      const GalleryData = resPhotGet.data.body.data;

      let sort = GalleryData.sort((a, b) => {
        return a.order - b.order
      })
      let data = [];
      this.setState({ data: GalleryData, PhotoGalleryList: GalleryData })
      let defaultSrc;
      sort.map((text, i) => {
        // const timeZoneArray = text.salah.settings.salahMethod.timezone.split(' ');
        // const timeZone = timeZoneArray[timeZoneArray.length - 1]
        // const dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("h:mm A")];
        // const dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("h:mm A")];
        // const UnpublishDate = new Date(dateEnd).getFullYear()
        // const status = text.status
        // this.setState({ status: status })
        // const statusChangeStart = new Date(dateStart) <= new Date();
        // const statusChangeEnd = new Date(dateEnd) <= new Date();
        let array = [];
        let re = /^(http|https|ftp)/
        if (text.imageMetaData?.imageUrl && re.test(text?.imageMetaData?.imageUrl)) {
          defaultSrc = "src";
        } else {
          defaultSrc = "defaultImg";
        }
        array = [
          {
            type: 'id',
            _id: text._id,
            //photoId: text._id
          },
          {
            type: "string",
            name: "textImg",
            primaryText: text.imageMetaData?.imageName ?? '',
            [defaultSrc]:
              text.imageMetaData?.imageUrl && re.test(text?.imageMetaData.imageUrl) ? text?.imageMetaData.imageUrl : image,
          },

          // {
          //   name: 'textImg',
          //   type: 'string',
          //   primaryText: 'Speaker image.png',
          //   initials: 'HK',
          // },

          {
            type: 'string',
            name: "date",
            value: text?.title ?? '',
          },

        ]
        data.push(array)

      }
      )
      this.setState({ PhotoGallery: data, Loading: false });
    } catch (err) {
      this.setState({ Loading: false });
      this.setState({ error: err.response && err.response.data.message })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  }
  deleteServiceAction = async (e) => {
    if (e?.[0]?._id) {
      this.setState({
        openAlert: false,
        Loading: true
      });
      const token = localStorage.getItem("authToken");
      const orgId = localStorage.getItem('orgID');
      try {
        const res = await deletePhotoFromGallery(token, e[0]._id, orgId)
        const stateNews = this.state.PhotoGallery;
        const NewsIndex = stateNews.indexOf(e)
        stateNews.splice(NewsIndex, 1)
        this.setState({
          PhotoGallery: stateNews,
          Loading: false
        });
        this.props.alertShow(remove_photo_gallery_message)
      } catch (error) {
        console.log("Error Occured: ", error);
        this.setState({
          Loading: false,
          error: error.response && error.response.data.message
        });
        setTimeout(() => {
          this.setState({
            error: false
          });
        }, 8000);
      }
    }
  }

  handleCancel = () => {
    this.setState({ openAlert: false });
  }
  handleClose = () => {
    this.setState({ openAlert: false });
  };
  render() {
    const { PhotoGallery, Loading, error, openAlert, specificRow } = this.state;
    if (this.props.alert.alertValue) {
      setTimeout(() => {
        this.props.alertHide()
      }, 8000);
    }
    let classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    let col = [
      { id: "drag", label: " ", width: 'auto' },
      { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
      { type: "link", name: "salahName", label: "NAME", width: '57%' },
      { type: "string", name: "date/time", label: "Caption title", width: '33%' },
      { type: "action", name: "action", label: "", width: '20%' },
    ];
    const style = {
      width: '100%',
    };
    const styleAlert = {
      width: 470,
      marginLeft: 800
    };
    const sortData = async (newData) => {
      const token = localStorage.getItem("authToken");
      let OBJ = {
        sortedList: newData.map(text => {
          return (
            { _id: text?._id, order: text.order }
          )
        })
      }
      try {
        const response = await sortPhotosWebsite(token, OBJ)
        console.log("Api response: ", response);
      } catch (err) {
        console.log("Error Occured: ", err);
        this.setState({ Loading: false });
        this.setState({ error: err.response && err.response.data.message })
        setTimeout(() => {
          this.setState({ error: false })
        }, 8000);
      }


      let arr = [];
      try {
        const sortData = newData.sort((a, b) => a.order - b.order)
        const token = localStorage.getItem("authToken");
        // let sort = GalleryData.sort((a, b) => {
        //   return a.order - b.order
        // })
        let data = [];
        //this.setState({PhotoGalleryList: sortData })
        let defaultSrc;
        sortData.map((text, i) => {
          let array = [];
          let re = /^(http|https|ftp)/
          if (text.imageMetaData?.imageUrl && re.test(text?.imageMetaData?.imageUrl)) {
            defaultSrc = "src";
          } else {
            defaultSrc = "defaultImg";
          }
          array = [
            {
              type: 'id',
              _id: text._id,
              //photoId: text._id
            },
            {
              type: "string",
              name: "textImg",
              primaryText: text.imageMetaData?.imageName ?? '',
              [defaultSrc]:
                text.imageMetaData?.imageUrl && re.test(text?.imageMetaData.imageUrl) ? text?.imageMetaData.imageUrl : image,
            },
            {
              type: 'string',
              name: "date",
              value: text?.title ?? '',
            },

          ]
          data.push(array)

        }
        )
        console.log("Data to be displayed: ", data);
        this.setState({ PhotoGallery: data, Loading: false });
      } catch (err) {
        console.log("Error is: ", err);
        this.setState({ Loading: false });
        this.setState({ error: err.response && err.response.data.message })
        setTimeout(() => {
          this.setState({ error: false })
        }, 8000);
      }
    }
    return (
      <MasterLayoutMiddle
        content={
          Loading ?
            <div
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </div>
            :
            <>
              {
                this.props.alert.alertValue &&
                <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                  <AlertAction
                    alert={this.props.alert.alertValue}
                    alertType={"success"}
                    onClick={() => this.props.alertHide()}
                  />
                </div>
              }
              {
                error && error === "Invalid token" && (
                  <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                    <AlertAction
                      alert={token_expired}
                      alertType={"error"}
                      onClick={() => this.setState({ error: false })}
                    />
                  </div>
                )
              }
              {
                PhotoGallery.length > 0 ?
                  <div>
                    <SimpleTable
                      showHeader={true}
                      title="Photo Gallery"
                      parentTableDrag={true}
                      DragIcon={Handle}
                      width="98%"
                      rows={PhotoGallery}
                      columns={col}
                      colSpan={16}
                      actionSendButton={true}
                      classProps={changeClassProps()}
                      donationsCount={PhotoGallery?.length > 0 ? 1 : 0}
                      noTitle="No Photo added"
                      noPara="Added Photos will be displayed and managed here"
                      noBtnText="Add New Photo"
                      onInviteClick={() =>
                        this.props.history.push(
                          "/website/add-photo-gallery"
                        )
                      }
                      showIconCol={true}
                      editIcon={Edit}
                      deleteIcon={Delete}
                      editAction={(e) =>
                        this.props.history.push({
                          pathname: `/website/edit-photo-gallery/${e[0]._id}`,
                          state: { detail: this.state.PhotoGalleryList.filter(text => text._id === e[0]._id) }
                        })
                      }
                      deleteAction={(e) => this.setState({ openAlert: true, specificRow: e })}
                      showFooter={true}
                      showAdd={true}
                      addText="ADD PHOTO"
                      onAddClick={() =>
                        this.props.history.push(
                          "/website/add-photo-gallery"
                        )
                      }
                      iconTextSize={16}
                      rowsPerPage={5}
                      sortData={(e) => sortData(e)}
                      data={this.state.data}
                    //sortColumnIndex={[3]}

                    />
                    <div style={{ display: "flex", marginTop: 13 }}>
                      <p className="cat-lower" style={{ marginTop: 0 }}>
                        Change the order of Slider to display on the website. Click on{" "}
                      </p>
                      <img src={Handle} alt="" style={{ display: "flex", marginTop: -10 }} />
                      <p className="cat-lower" style={{ marginTop: 0 }}>to drag and drop within the table.</p>
                    </div>
                  </div>
                  :
                  <EmptyTable
                    title="Photo Gallery"
                    noTitle="No photo added"
                    noPara="Added photos will be displayed and managed here"
                    onAddClick={() => this.props.history.push("/website/add-photo-gallery")}
                    addLabel="ADD PHOTO"
                    noBtnText="ADD PHOTO"
                  />
              }
              <div>
                <AlertDialogue
                  title="Delete Photo?"
                  description="Are you sure you want to delete this Photo?"
                  description2="Deleting will permanently remove from Website."
                  cancelText="Cancel"
                  confirmText="Confirm"
                  open={openAlert}
                  handleCancel={() => this.handleCancel()}
                  handleClose={() => this.handleClose()}
                  handleConfirm={() => this.deleteServiceAction(specificRow)}
                  styles={styleAlert}
                />
              </div>
            </>
        }
        isFooter={true}
        footer={
          <FooterInner
            style={style}
            termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
            privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
      />
    )
  }
}
const mapStateToProps = (state) => ({
  alert: state.alert,
});
const mapDispatchToProps = (dispatch) => ({
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PhotoGallery);
const styles = {
  contents: {
    padding: 24,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: "76%",
    marginTop: 70,
    marginLeft: "20%",
  },
}