import React from "react";
import {useEffect, useState} from 'react';
import { BaseTextField, MasterLayoutMiddle, FooterInner, DropdownMultiSelect, BaseButton, BaseCheckBox  } from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import axios from "axios";
import {getCommunicationsData, getItemDetails, updateGroup} from './Functions';

const orgEnv = {
    prod: process.env.REACT_APP_BASEURL_PROD_API,
    dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
    window.location.host.includes("amazon") ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("shapla")
) {
    currentEnv = "dev";
} else {
    currentEnv = "prod";
}

const orgBaseUrl = orgEnv[currentEnv];

function CreateNewGroup(props) {

    const [buttonDisabled , setButtonDisabled] = React.useState( 'disabled' );

    const style = {
        width: '100%',
    };

    const styles = {
        btn: {
            height: 66,
            width: "100%",
            margin: 8,
            border: "#D3D8DA",
        },
    };

    const [values, setValues] = React.useState({
        groupName: "",
        groupContacts: [],
        numberOfContacts: "",
    });

    const handleChange = (prop) => (event) => {
        setValues({
            ...values,
            [prop]: event.target.value,
        });

        checkVerification();
    };

    
    function checkVerification(){

        if( values.groupName.length > 0  && values.numberOfContacts>0 ){            
            setButtonDisabled( ''); 
            
        }else{
            setButtonDisabled( 'disabled');
        }
    }

    const viewChange = (newvalue) =>{
        if( newvalue.length ){
            let changedData = values ; 
            changedData.numberOfContacts = newvalue.length ; 
            for(let i=0 ; i < changedData.numberOfContacts ; i++ ){
                changedData.groupContacts.push( newvalue[i].contactId);
            }
            setValues( changedData );
        }

        checkVerification();
    }

    const group = {
    'The Shawshank Redemption': 1994,
    
    }
    const [addnowButton, setaddnowButton] = useState('ADD NOW');

    const groupByCallBack = (option) => {
        return group[option.title]
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const token = localStorage.getItem("authToken");
        const orgId = localStorage.getItem('orgID');

        const postData = {
            groupName: values.groupName ,
            numberOfContacts: values.numberOfContacts , 
            groupContacts: values.groupContacts , 
            orgId: orgId           

        };

        if( isEdit ){
            const updatedItem = await updateGroup( values, gid);
            if( updatedItem){
                console.log( updatedItem );
                props.history.push('/communication/groups-details/'+updatedItem.data._id + '/success' ) ;
            }
        }else{
            return new Promise((resolve, reject) => {
                axios
                    .post(`${orgBaseUrl}/communications/groups`, postData, {
                        headers: {
                            "Content-Type": "application/json",
                            token
                        }
                    })
                    .then((res) => {
                        resolve(res);
                        console.log( res );
                        props.history.push('/communication/groups-details/'+res.data.body.data._id + '/success' ) ;
    
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        }
        

    };

    const [gid, setGroupid] = useState( false );
   

    useEffect(() => {
        let mounted = true;

        async function fetchData(){
            const token = localStorage.getItem("authToken");
            const orgId = localStorage.getItem('orgID');
            
            const groupId = props.match.params.id ; 
            let groupDetails ={} ; 
            let sv = [] ; 

            if( groupId ){
                setGroupid( groupId );
                setaddnowButton( 'EDIT NOW');
                setPagetitle('Edit');
                setButtonDisabled('');
                
                await getItemDetails( groupId , 'groups').then( response =>{
                    if( mounted ){
                        groupDetails = ( response.data.body.data );
                        
                        setValues( groupDetails );                        
                        
                    }
                });
            }

            await getCommunicationsData( 'contacts' , orgId ,  token).then( response =>{
                if( mounted ){
                    let contacts = [] ; 
                    let items = response.data.body.data;

                    for(let i=0; i < items.length ; i++ ){
                        let obj = {
                            title: items[i].firstName + ' ' + items[i].lastName ,
                            Description: items[i].phoneNumber , 
                            contactId: items[i]._id
                        }
                        contacts.push( obj );
                        // console.log( groupDetails.groupContacts );
                        if( groupDetails  && groupDetails.groupContacts?.includes( obj.contactId) ){
                            sv.push( obj );
                        }

                    }
                    // console.log( sv );
                    setListdata( contacts  );

                    // console.log( sv );

                    if(sv && sv.length){
                        setSelectedValues( sv );
                        // console.log( sv);
                    }
                        
                        
                    if( groupId) 
                        setIsedit( true );
                }
            });

            
            
            
        } 
        fetchData(); 

        return () => mounted = false ; 
        
    }, []);

    const [listData, setListdata ] = React.useState([{
        title: "Please Select" , Description: ""
    }]);

    const [selectedValues, setSelectedValues] = useState(false);

    const [isEdit, setIsedit] = useState( false );

    const [pageTitle , setPagetitle] = useState('Create New')

    return (
        <MasterLayoutMiddle
            header={
                <div
                    onClick={() => props.history.replace("/communication/contact-list/groupListView")}
                    className="backBtn"
                >
                    <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                    <p style={{ alignSelf: "center" }}>Back</p>{" "}
                </div>
            }
            isForm={true}
            formData={
                <div>

                    <div>
                        <h1 className="title_top">{pageTitle} Group</h1>
                        <div className="dasheddBorder"></div>
                    </div>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Group Name"
                                    value={values.groupName}
                                    onChange={handleChange("groupName")}
                                    style={styles.btn}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            {isEdit  && selectedValues && (
                                
                                    <DropdownMultiSelect
                                        Listdata={listData}
                                        variant="standard"
                                        placeholder="Search contacts/groups"
                                        onChange={handleChange("name")}
                                        groupByCallBack = {groupByCallBack}
                                        viewChange={viewChange}
                                        selected = {selectedValues}  
                                        value={values.groupName}  
                                        label="To"
                                        fieldToSelect="contactId"                       
                                    />
                                
                            )}

                            {! isEdit && (
                                <DropdownMultiSelect
                                    Listdata={listData}
                                    variant="standard"
                                    placeholder="Search contacts"
                                    onChange={handleChange("name")}
                                    groupByCallBack = {groupByCallBack}
                                    viewChange={viewChange}
                                    value={values.groupName} 
                                    label="Select Contacts" 
                                    fieldToSelect="contactId"  
                                    label="To"
                                    //helpertext="Search contacts"
                                    //error="Field is required"                     
                                />
                            )}
                            
                            
                        </Grid>

                        

                        <Grid item xs={12}>

                            <div className="form_button">
                                <p
                                    className='cancel'
                                    onClick={() => props.history.push('/communication/contact-list')  }
                                >
                                    CANCEL
                                </p>
                                <BaseButton disabled={buttonDisabled} text={addnowButton} onClick={handleSubmit} />
                            </div>

                        </Grid>

                    </Grid>

                </div>
            }
            isFooter={true}
            footer={<FooterInner 
                    style={style}
                    termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
                    privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                    />}
            isWhiteBackground={true}
        />
    );
}

export default CreateNewGroup;