import axios from "axios";

const baserUrl =
  "https://cors-anywhere.herokuapp.com/http://18.211.207.245:3000";

const paymentEnv = {
  prod: process.env.REACT_APP_PAYMENT_PROD_API,
  dev: process.env.REACT_APP_PAYMENT_DEV_API
};
const orgEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")
) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}
const orgBaseUrl = orgEnv[currentEnv];
const paymentBaseUrl = paymentEnv[currentEnv];

export function addOrganization(credentials,token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/organizations`, credentials,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function connectOrganization(data,token){
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/organizations/connectStripe`, data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getOrganizationData(id){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/organizations/${id}`,{
        headers:{
          token:'deenconnect-elobyte'
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getNewMessageCount(id, token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/contact/messagescount/${id}`,{
        headers:{
          token: token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function editOrganizationForSetupForm(data,token){
  console.log("Data to be send: ", data);
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/organizations`, data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function editOrganization(data,id,token){
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/organizations/${id}`, data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function saveSalah(data, id, token ){
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/organizations/saveSalahSettings/${id}`, data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function addMember(data,token){
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/team-members`, data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function editMember(data,id,token){
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/team-members/${id}`, data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getTeamMember(id,token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/team-members/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTeamMembers(token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/team-members`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteTeamMember(id,token){
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/team-members/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getSpeakers(id,token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/speakers?orgId=${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSpeaker(id,token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/speakers/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function addSpeakers(data,token){
  console.log( data ); 
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/speakers`,data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function UpdateSpeakers(data,token,id){
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/speakers/${id}`,data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function deleteSpeaker(token,id){
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/speakers/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getServices(id,token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/services?orgId=${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getAllServices(token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/services`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getService(id,token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/services/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function addService(data,token){
  console.log("Uploaded data is: ", data)
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/services`,data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function UpdateService(data,token,id){
  console.log("Data to be saved is: ", data);
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/services/${id}`,data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function deleteService(id,token){
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/services/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getaddressFromPostalCode(orgData) {

  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/shared/addressFromPostalCode?postal_code=${orgData.postal_code}&country=${orgData.country}`)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getaddressFromZip(postalCode) {

  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/shared/addressFromPostalCode?postal_code=${postalCode}`)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function stripeTokenGet(cardInfo, chargeInfo) {
  const token = localStorage.getItem("authToken");
  // console.log("chargeInfo", cardInfo, chargeInfo)
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/stripe/token`, cardInfo, {
        headers:{
          token
        }
      })
      .then((res) => {
        console.log("res/stripe/token", res)

        if (res?.data?.body?.data) {
          const { amount } = chargeInfo
          console.log("body", {
                ...chargeInfo,
                amount: amount * 100,
                currency: 'USD',
                source: res.data.body.data,
              })
          axios
            .post(`${orgBaseUrl}/stripe/planBillingCharges`, {
              ...chargeInfo,
              amount: amount * 100,
              currency: 'USD',
              source: res.data.body.data.id,
            }, {
              headers:{
                token
              }
            })
            .then((res) => {
              console.log("res/stripe/charges", res)
              resolve(res)
            })
            .catch((error) => {
              reject(error)
            })
        } else {
          resolve(res)
        }
      })
      .catch((error) => {
        console.log(error, "error")
        reject(error)
      })
  })
}

export function getPlans(id, token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/planSubscription/getOrganizationPlan/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function cancelPlan(data, token){
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/planSubscription/cancelPlan`, data, {
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateUserSubscriptions(cardInfo, chargeInfo) {
  const token = localStorage.getItem("authToken");
  console.log("chargeInfo", chargeInfo)
  console.log("cardInfo", cardInfo)
  return new Promise((resolve, reject) => {
      const { amount } = chargeInfo
      console.log("body", {
            ...chargeInfo,
            ...cardInfo,
            amount: amount * 100,
            currency: 'USD',
          })
      axios
        .post(`${orgBaseUrl}/stripe/updateUserSubscriptions`, {
          ...chargeInfo,
          ...cardInfo,
          amount: amount * 100,
          currency: 'USD',
        }, {
          headers:{
            token
          }
        })
        .then((res) => {
          console.log("res/stripe/charges", res)
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
  })
}

export function getOrders(id, token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/orgOrder/getOrgOrders/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

//get order details from orderId 
export function getOrderDetails(id, token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/orgOrder/getOrgOrder/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateOrgOrder(id, data, token){
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/orgOrder/updateOrgOrder/${id}`, { ...data }, {
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAllCustomerInvoices(data){
  const token = localStorage.getItem("authToken");
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/stripe/getAllCustomerInvoices`, data, {
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCustomerPaymentMethods(data){
  const token = localStorage.getItem("authToken");
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/stripe/getCustomerPaymentMethods`, data, {
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addCustomerPaymentMethods(data){
  const token = localStorage.getItem("authToken");
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/stripe/addCustomerPaymentMethods`, data, {
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateCustomerPaymentMethods(data){
  const token = localStorage.getItem("authToken");
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/stripe/updateCustomerPaymentMethods`, data, {
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export function deleteCustomerPaymentMethod(id, orgId){
  const token = localStorage.getItem("authToken");
  return new Promise((resolve, reject) => {
    axios
    .delete(`${orgBaseUrl}/stripe/deleteCustomerPaymentMethod/${id}?orgId=${orgId}`,{
      headers:{
        token
      }
    })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function resetInvite(data,token){
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/team-members/resetInvite`,data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}