import React, { useEffect } from "react";
import { GridContainer, BaseTabs, SimpleTable, GridItem, MasterLayoutMiddle, FooterInner, AlertAction } from "@dclab/dc-react-ui-kit";
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import Handle from "../../assets/img/more_vert-24px.svg";
import './style.css'
import { GetContacts } from "./api";
import Moment from "moment";
import { CircularProgress } from "@material-ui/core";
import messages_content from '../../utils/messages.json';
import Grid from '@material-ui/core/Grid';
const {
  token_expired
} = messages_content;

function Messages(props) {
  // ============================== Base Tab Start ===============================//
  const [value, setValue] = React.useState(0);
  const [rows, setRows] = React.useState([])
  const [loader, setLoader] = React.useState(true)
  const [error, seterror] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    getContacts();
  }, []);

  const checkStatus = (status) => {
    status = status ? status.toLowerCase() : "";
    if (status === 'new') {
      return "invite"
    } else if (status === 'in-progress'){
      return "discontinued"
    } else if (status === 'closed'){
      return "failed"
    } else if (status === 'done'){
      return "success"
    } else {
      return "discontinued"
    }
 };

  const getContacts = async () => {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID')
    const LocalName = localStorage.getItem('name')
    try {
      const response = await GetContacts(token, orgId);
      const contacts = response.data.body.data;
      let array = []
      array = contacts.map((text, index) => {
        console.log(index === 0, 'ooo')
        return ([
          { type: "string", name: "name", value: (text?.name?.firstName ?? "") + (text?.name?.lastName ? ' ' + text.name.lastName : ''), _id: text._id, data: { ...text } },
          { type: "string", name: "chip", value: "Contact Us" },
          { type: "string", name: "category", value: text.stream },
          {
            type: "array",
            name: "multi_images",
            value: [{
              name: 'img1',
              type: 'url',
              initials: LocalName[0].toUpperCase(),
              listStyle: {
                position: 'relative',
                left: '-1.6rem',
                border: '3px solid white',
                borderRadius: '50px',
                zIndex: 100 - index,
              },
            }
            ],
          },
          { type: "status", status: checkStatus(text?.status ?? "new"), value: text?.status ?? "New"},
          { type: "string", name: "date", value: Moment(text.updatedAt).format('lll') },
          { type: "action", status: "success", value: "VIEW DETAILS" },
        ]
        )
      })
      setLoader(false)
      setRows(array);
    } catch (error) {
      setLoader(false)
      seterror(error.response && error.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const object = [
    {
      label: "Messages",
      item: "",
    },
    {
      label: "Notifications",
      item: "",
      count: 3
    },

  ];
  const onClick = (x, y) => {
    console.log(x);
    console.log(y);
  };
  // ============================== Base Tab End ===============================//
  // ============================== Table Start ======================================//
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const col = [
    { type: "number", name: "serial_number", label: "FROM", width: '20%' },
    { type: "string", name: "chip", label: "CAtegory", width: '20%' },
    { type: "string", name: "name", label: "App stream", width: '20%' },
    { type: "string", name: "date", label: "ASSIGNEE", width: '0%' },
    { type: "string", name: "status", label: "STATUS", width: '10%' },
    { type: "string", name: "date", label: "Date", width: '28%' },
    { type: "action", name: "action", label: "", width: '30%' },
  ];
  const colNotification = [
    { type: "number", name: "serial_number", label: "EVENT", width: '80%' },
    { type: "string", name: "name", label: "App stream", width: '20%' },
    { type: "string", name: "date", label: "Date", width: '20%' },
  ];

  const rowsNotification = [
    [
      { type: "string", name: "name", value: "New one-time/monthly donation processed successfully" },
      { type: "string", name: "category", value: "DEENWEB" },
      { type: "string", name: "date", value: "6/30/20, 8:00 PM" },
    ],
  ];
  const details = (name) => {
    console.log(name)
    props.history.push({ pathname: '/organization/contact-us-details', state: { contact: name[0].data } })
  }
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      header={
        error && error === "Invalid token" && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
            <AlertAction
              alert={token_expired}
              alertType={"error"}
              onClick={() => seterror(false)}
            />
          </div>
        )
      }
      content={
        loader ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <SimpleTable
                  showHeader={true}
                  title="Messages"
                  parentTableDrag={false}
                  DragIcon={Handle}
                  rows={rows}
                  columns={col}
                  colSpan={16}
                  actionSendButton={true}
                  classProps={changeClassProps()}
                  donationsCount={rows.length ? 1 : 0}
                  noTitle="No messages yet."
                  noPara=""
                  noBtnText=""
                  actionLink={(name) => details(name)}
                  showIconCol={false}
                  editIcon={Edit}
                  deleteIcon={Delete}
                  showFooter={true}
                  showAdd={false}
                  addText="ADD"
                  onAddClick={() =>
                    console.log('add click')
                  }
                  iconTextSize={16}
                />
              </Grid>
            </Grid>








            {/* <GridContainer>
              <BaseTabs
                title=""
                object={object}
                indicatorColor="primary"
                onClick={(x, y) => onClick(x, y)}
                onChange={(event, newValue) => handleChange(event, newValue)}
                value={value}
                indicatorColor="#7266FB"
              />
              {value === 0 ?
                <div>
                  
                </div>
                :
                <div>
                  <GridItem itemStyle={{ marginTop: 0, paddingBottom: 0 }} xs={12}>
                    <SimpleTable
                      showHeader={true}
                      title="Messages"
                      parentTableDrag={false}
                      DragIcon={Handle}
                      width="98%"
                      rows={rowsNotification}
                      columns={colNotification}
                      colSpan={16}
                      actionSendButton={true}
                      classProps={changeClassProps()}
                      donationsCount={1}
                      noTitle=""
                      noPara=""
                      noBtnText=""
                      onInviteClick={() =>
                        console.log('invite click')
                      }
                      actionLink={(name) => console.log('details')}
                      showIconCol={false}
                      editIcon={Edit}
                      deleteIcon={Delete}
                      editAction={(e) =>
                        console.log('edit click')
                      }
                      deleteAction={(e) => this.deleteServiceAction(e)}
                      showFooter={true}
                      showAdd={false}
                      addText="ADD"
                      onAddClick={() =>
                        console.log('add click')
                      }
                      iconTextSize={16}
                    />
                  </GridItem>
                </div>
              }
            </GridContainer> */}
          </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  );
}
export default Messages;