import React, { Component } from "react";
import "./style.css";
import Page_title from "./Page_title";
import Loader from "../Loader/Loader";
class Tos extends Component {
  render() {
    return (
      <div className="">
        {this.props.loader && (
          <>
            <Loader />
            <Page_title name="DEENFUND is a global platform to connect awesome donors with amazing organizations with great causes" />
          </>
        )}
        <h1>{this.props.message}</h1>
      </div>
    );
  }
}
export default Tos;