import React, { Component } from "react";
import {
  BaseTextField,
  BaseButton,
  AlertAction,
  FormSwitch,
  MasterLayoutMiddle, FooterInner
} from "@dclab/dc-react-ui-kit";
import CircularProgress from "@material-ui/core/CircularProgress";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Grid from '@material-ui/core/Grid';
import { getCategory, editCategory } from "./apis";
import "../../App.css";
import "./style.css";
import "../style.css";
import messages_content from '../../utils/messages.json';
const { 
    token_expired,
    success_message_edit_category
  } = messages_content;
export default class AddCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catName: "",
      catNameCount: 0,
      status: false,
      error: false,
      showAlert: false,
      success: false,
      loading: true,
      editLoading: false,
      categories: [],
      id: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.editCategoryHandler = this.editCategoryHandler.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }
  async componentDidMount() {
    let pathName = this.props.history.location.pathname;
    const id = pathName.slice(pathName.lastIndexOf("/") + 1);
    const token = localStorage.getItem("authToken");
    try {
      const res = await getCategory(id, token);
      const { _id, name, status } = res.data.body.data;
      this.setState({
        catName: name,
        status: status === "Active" ? true : false,
        id: _id,
        loading: false,
      });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  handleChange = (e) => {
    const { catNameCount } = this.state;
    if (catNameCount < 30) {
      this.setState({ catName: e, catNameCount: e.length });
    } else {
      this.setState({ catNameCount: e.length });
    }
  };
  handleSwitchChange = (e) => {
    this.setState({
      status: !this.state.status,
    });
  };
  editCategoryHandler = async () => {
    const token = localStorage.getItem("authToken");
    const { catName, status, id } = this.state;
    this.setState({ editLoading: true });
    if (!catName) {
      this.setState({ error: true, editLoading: false });
    } else {
      try {
        const obj = {
          name: catName,
          status: status ? "Active" : "Inactive",
        };
        const res2 = await editCategory(obj, token, id);
        this.setState({
          catName: "",
          showAlert: true,
          success: true,
          edit: false,
          editLoading: false,
        });
        setTimeout(() => {
          this.props.history.replace("/deenfund/categories");
        }, 2000);
      } catch (error) {
        this.setState({
          error: error && error.response && error.response.data.message,
          showAlert: true,
          editLoading: false,
        });
        this.hideAlert();
      }
    }
  };
  render() {
    const {
      catName,
      status,
      error,
      success,
      loading,
      showAlert,
      editLoading,
    } = this.state;
    const style = {
      width: '100%',
    };
    return (
      <React.Fragment>
        <MasterLayoutMiddle
          header={
            <>
              {showAlert && error && (
                <div
                  style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                >
                  <AlertAction
                    alert={
                      error === "Invalid token"
                        ? token_expired
                        : error
                    }
                    alertType={"error"}
                    onClick={() => this.setState({ showAlert: false })}
                  />
                </div>
              )}
              {showAlert && success && (
                <div
                  style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                >
                  <AlertAction
                    alert={success_message_edit_category}
                    alertType={"success"}
                    onClick={() => this.setState({ showAlert: false })}
                  />
                </div>
              )}
              <div
                onClick={() => this.props.history.replace("/deenfund/categories")}
                className="backBtn"
              >
                <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                <p style={{ alignSelf: "center" }}>Back</p>{" "}
              </div>
            </>
          }
          isForm={true}
          formData={
            <div>
              <div>
                <h1 className="title_top">Edit Donation Category</h1>
                <div className="dasheddBorder"></div>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseTextField
                      value={catName}
                      onChange={(e) => this.handleChange(e.target.value)}
                      InputProps={false}
                      phone={false}
                      search={false}
                      style={{ width: 570 }}
                      label="Category Name"
                      error={error && !catName}
                      helperText={
                        error && !catName && "Please enter category name"
                      }
                      placeholder=""
                      count={30}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="border_top_bottm">
                    <FormSwitch
                      headline="Category Status"
                      body="Display this category to potential donors"
                      values={status}
                      handleSwitchChange={() => this.handleSwitchChange("salahStatus")}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 350,
                        }}
                      >
                        <CircularProgress color="primary" />
                      </div>
                    ) : (
                        <div>
                          {editLoading ? (
                            <div
                              style={{
                                marginTop: 30,
                                display: "flex",
                                justifyContent: "center",
                                width: 570,
                              }}
                            >
                              <CircularProgress
                                color="primary"
                                style={{ margin: "0 auto" }}
                              />
                            </div>
                          ) : (
                              <div className="form_button">
                                <p
                                  className="cancel"
                                  onClick={() =>
                                    this.props.history.replace("/deenfund/categories")
                                  }
                                >
                                  CANCEL
                                </p>
                                <BaseButton
                                  text="SAVE"
                                  onClick={() => this.editCategoryHandler()}
                                />
                              </div>
                            )}
                        </div>
                      )}
                  </div>
                </Grid>
              </Grid>
            </div>
          }
          isFooter={true}
          footer={
            <FooterInner 
              style={style}
              termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
              privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
            />
          }
          isWhiteBackground={true}
        />
        <section style={styles.contents}>
        </section>
      </React.Fragment>
    );
  }
}
const styles = {
  paperStyle: {},
  paperStyleNext: {},
  contents: {
    padding: '24 0',
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: '76%',
    marginTop: 70,
    marginLeft: '20%'
  },
};