import React, { useEffect, useState } from "react";
import {
    CardDetailsTable, MasterLayoutMiddle,
    AlertAction, FooterInner
} from "@dclab/dc-react-ui-kit";
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import { AlertDialogue } from "@dclab/dc-react-ui-kit";



function JumahDetails(props) {

    const style = {
        width: '100%',
    };


    let header = {
        tableName: `Jumu'ah Details`,
    };

    let data = [
        {
            field: "Date",
            value: "Aug 6, 2021"
        },

        {
            field: "Khutbah time",
            value: "1:45 PM"
        },

        {
            field: "Khateeb",
            value: "Dr. Omar Suleiman"
        },

        {
            field: "Preferred Contact",
            value: "+1 (123) 123-1234"
        },

        {
            field: "Khutbah Topic",
            value: "The Miracle of the Qur’an"
        },

    ];

    let data2 = [
        {
            field: "Reminder 1",
            value: "2 days before"
        },
        {
            field: "Reminder 2",
            value: "1 day before"
        },

        {
            field: "Reminder 3",
            value: "2 hours before"
        },

        {
            field: "Description/Note",
            value: `<div>All praise be to Allah, and may His finest See More... peace and blessings be upon His final prophet, Muhammad ﷺ, who said, “I have not been sent except to perfect noble character.” <a href="#">See More...</a></div>`
        },

    ];


    let footerLinkName = "EDIT INFO";
    let footerDeleteButton = "DELETE";

    // HANDLE FOOTER LINK
    const onClick = () => {
        console.log('/edit-detail')
    }

    const onDeleteClick = () => {
        setOpen(true)
      }

    const onClickSend = () => {
        console.log('Send')
    }


    const handleChange = (event, value) => {
        console.log(event.target.value, value);
    };

    const [open, setOpen] = useState(false);

    const style2 = {
        width: 470,
        marginLeft: 800
      };


    return (

        <MasterLayoutMiddle
            header={
                <div style={{ width: '26%', alignSelf: 'center' }}>
                    <div
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                        className='backBtn'
                    >
                        <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
                        <p style={{ alignSelf: 'center' }}>Back</p>{' '}
                    </div>
                </div>
            }
            content={

                <div style={{ marginTop: "50px", }}>
                    <CardDetailsTable
                        data={data}
                        data2={data2}
                        twoColumns
                        header={header}
                        footerLinkName={footerLinkName}
                        footerDeleteButton={footerDeleteButton}
                        onClick={() => onClick()}
                        onDeleteClick={() => onDeleteClick()}

                        Status={true}
                        StatusLabel="Status"
                        StatusText="Active"
                        statuscolor="#3CC480"

                        OrgName="Deenfund"
                        OrgSubText="Application"
                        HeaderStatus={false}

                    />

                    <AlertDialogue
                        title="Delete Jumu'ah?"
                        description="Are you sure you want to delete this Jumu'ah? Deleting will permanently remove from everywhere."
                        cancelText="Cancel"
                        confirmText="Confirm"
                        open={open}
                        handleCancel={() => setOpen(false)}
                        handleClose={() => setOpen(false)}
                        //handleConfirm={() => handleConfirm()}
                        styles={style2}
                    />





                </div>
            }
            isFooter={true}
            footer={
                <FooterInner
                    style={style}
                    termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
                    privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                />
            }
            isWhiteBackground={false}
        />



    );
}


export default JumahDetails;