import React from 'react';
import { ColorPickerButton, MediaUploader } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';

import replyIcon from '../../assets/img/replyIcon.svg'
import FileIcon from '../../assets/img/upload_file_black_24dp.svg'

function Branding(props) {

    const getColor = (color) => {
        console.log("get color", color)
    }

    const palette = {
        red: '#ff0000',
        blue: '#0000ff',
        green: '#00ff00',
        yellow: 'yellow',
        cyan: 'cyan',
        lime: 'lime',
        gray: 'gray',
        orange: 'orange',
        purple: 'purple',
        black: 'black',
        white: 'white',
        pink: 'pink',
        darkblue: 'darkblue',
    };


    const getMediaItems = () => {

        setMediaList(mediaObject);

    }

    const selectMediaItem = (obj) => {
        console.log("Obj is: ", obj);

    }

    const addMediaItem = (obj) => {
        console.log("Media data is: ", obj);


    }

    const imageData = (obj) => {
        const tempMediaArray = mediaList;
        tempMediaArray.push(obj);
        setMediaList(tempMediaArray);

    }

    const videoData = (obj) => {
        console.log("Video data is: ", obj);
    }

    const onDelete = () => {
        console.log("Ondelete Called")
    }


    const [mediaList, setMediaList] = React.useState([]);

    const mediaObject = [{
        imageMetaData: {
            imageUrl: "https://i.ytimg.com/vi/6WE3nJiECRw/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAckAfnE7HtfnjxhAH-8sEdXdMgLQ",
            imageName: "Thumbnail",
            imageThumbnail: "https://i.ytimg.com/vi/6WE3nJiECRw/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAckAfnE7HtfnjxhAH-8sEdXdMgLQ",
            fileType: "Video",
            imageSize: 0
        },
        metaData: {
            videoUrl: "https://www.youtube.com/watch?v=6WE3nJiECRw",
            duration: "7m 16s "
        },
        _id: "610d393141e4dd5be3026723",
        isVideo: true,
        title: "Kun Faya Kun - A.R. Rahman, Mohit Chauhan [Drifting Lights x WORMONO Lofi Remake] | Bollywood Lofi",
        mediaURL: "https://i.ytimg.com/vi/6WE3nJiECRw/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAckAfnE7HtfnjxhAH-8sEdXdMgLQ",
        createdAt: "2021-08-06T13:29:21.978Z",
        orgId: "600aa7cd4350100e18c7370e",
        updatedAt: "2021-08-06T13:29:21.978Z"
    }]

    return (

        <div>
            <Grid container spacing={3}>

                <Grid item xs={12} md={6}>
                    <MediaUploader
                        onDelete={onDelete}
                        mediaList={mediaList}
                        getMediaItems={getMediaItems}
                        showMediaTab={"video"}
                        typeOfMedia='/videos'
                        label="Logo"
                        onChange={selectMediaItem}
                        addButtonHandler={addMediaItem}
                        imageData={imageData}
                        maxImageLimit={100}
                        videoData={videoData}
                        replyIcon={replyIcon}
                        FileIcon={FileIcon}
                        buttonName="UPLOAD LOGO"
                        currentImageSize={1500}
                        totalFileUploadLimit={100}
                        selectedMedia={{ logoUrl: 'https://d2imgltiwla1iy.cloudfront.net/6659a2c4f2102d698fff5e934851a98c-test shieyt/1626175177704fd3e1073-3d62-46a3-bba9-37e877b7c900.png', logoName: 'add product' }}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <ColorPickerButton
                        label="Theme Color"
                        getColor={getColor}
                        defaultColor={"#222222"}
                        style={{ width: '100%' }}
                        palette={palette}
                    />
                </Grid>

            </Grid>

        </div>

    );
}


export default Branding;