import React, {useState, useEffect} from 'react';
import { AlertDialogueAdvance } from "@dclab/dc-react-ui-kit";
import jwtDecode from "jwt-decode";

import { refreshCognitoToken } from "../pages/UserProfile/Apis/index"
import { withRouter } from 'react-router-dom';

function SessionExpiredModal(props) {

    const [open, setOpen] = useState(false);

    const handleClose = async () => {
        setOpen(false)
    };
    const handleStay = async () => {
        const data = {
          refreshToken: localStorage.getItem("refreshToken"),
          userName: localStorage.getItem("userName")
        }
        console.log("stay", data)
        const res = await refreshCognitoToken(data);
        if(res?.accessToken) {
          localStorage.setItem("refreshToken", res.refreshToken)
          localStorage.setItem("authToken", res.accessToken)
          props.history.goBack();
        }
        setOpen(false)
    };

    const handleLogout = () => {
        console.log('logout')
        localStorage.setItem('user', false)
        localStorage.setItem('authToken', '')
        props.history.replace('/signin')
        localStorage.clear();
        setOpen(false)
    };  

    const style2={
      width:470,
      marginLeft:'35%'
    };

    useEffect(() => {
      if (localStorage.getItem("authToken")) {
        try {
          const tokenPayload = jwtDecode(localStorage.getItem("authToken"));
          // console.log("tokenPayload", tokenPayload);
          // console.log(Math.floor(Date.now() / 1000));

          if (Math.floor(Date.now() / 1000) > tokenPayload.exp) {
            // console.log("here", Math.floor(Date.now() / 1000) < tokenPayload.exp)
            setOpen(true)
          }
        } catch (err) {
          console.log(err, "err")
        }
      }
    }, [])

    return (
        <div>
            <AlertDialogueAdvance
            title="Are you still there?"
            description="You've been inactive and you're about to automatically be logged out in approximately 1 minute for security reasons."
            cancelText="LOGOUT NOW"
            confirmText="STAY CONNECTED"  
            open={open}
            handleCancel={() => handleStay()}
            handleClose={() => handleClose()}
            handleConfirm={() => handleLogout()}
            styles={style2}
            />
        </div>
    )
}

export default withRouter(SessionExpiredModal);