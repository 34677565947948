import React, {useEffect, useState} from "react";
import { MasterLayoutMiddle, FooterInner,CheckOrderSummary, SimpleTable} from "@dclab/dc-react-ui-kit";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Grid from '@material-ui/core/Grid';
import './style.css'
import {getValidatePromoCode} from './api';
function MoreAppsCartDetails(props) {

  // ============================== Oder Sumary start ======================================//
  const [discountCode, setDiscountCode] = React.useState("");
  const [discount, setDiscount] = React.useState(0);
  const [cartItems, setcartItems] = useState([]);
  const [discountError, setdiscountError] = useState({error: false, msg: ''});
  const [discountDetails, setdiscountDetails] = useState({});
  const [promoCodeApplied, setpromoCodeApplied] = useState(false);
  const [promoCodeDetails, setpromoCodeDetails] = useState({});
  const [discountAmount, setdiscountAmount] = useState(0);
  const [rows, setRows] = useState([]);
  const [subTotal, setsubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    let cartData = JSON.parse(localStorage.getItem("appCartItem")) ? JSON.parse(localStorage.getItem("appCartItem")) : [];
    setcartItems(cartData)
  }, [])
  
  // React.useEffect(() => {
  //   if (discount < 100 && discountCode !== "") {
  //     setDiscount(discount + 1);
  //   } //Set Your Discount Logic Here.
  //   if (discountCode === "") {
  //     setDiscount(0);
  //   }
  // }, [discountCode]);
  
  const callback = (code, total) => {
    console.log(total);
    setDiscountCode(code);
  };

  const onClick = () => {
    console.log("clicker")
      props.history.push({pathname: "/checkout", state: promoCodeDetails});
  
  }
  
// ============================== Oder Sumary End ======================================//

  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { type: "", name: "", label: "", width: "auto" },
    { type: "link", name: "invoice_number", label: "Items", width: "20%" },
    { type: "string", name: "name", label: "Price", width: "15%" },
    { type: "string", name: "name", label: "Cycle", width: "20%" },
    { type: 'string', name: 'itemQuantity', label: 'Quantity', width: '20%' },
    { type: "string", name: "name", label: "Total", width: "15%" },
    { type: 'deleteItem', label: '', width: "10%"},
  ];

  useEffect(() => {
    let sum = 0;
    const rows = cartItems?.length ? cartItems.map((item) => {
      let appName = Object.keys(item)[0];
      let planName = item[appName].plan;
      let displayName = item[appName]?.displayName ?? false;
      let paymentFrequency = item[appName].paymentFrequency;
      let monthTotal = (item[appName].total / 12).toFixed(2) ?? 0; 
      sum = sum + parseFloat(item[appName].total)
      return [     
        { name: "textImg", type: "string", 
          primaryText: `${displayName ? displayName?.toUpperCase() : appName?.toUpperCase() ?? ""} ${planName ? "- " + planName.toUpperCase() : ""}`, 
          initials: !item[appName].productImage ? appName[0] : "",
          src:  item[appName].productImage ?? '',
          appName: appName,
  
         },
        { type: "string", name: "frequency", value: `$${paymentFrequency === "monthly" ? item[appName].total : monthTotal}/mo` },
        { type: "string", name: "date/time", value: `${item[appName].paymentFrequency ?? ""}` },
        { type: '', name: 'itemQuantity', value: "deleteIcon" }, //itemQuantityInput
        { type: "string", name: "date/time", value: `$${item[appName].total ?? 0}` },
        { type: 'deleteItem', name: 'deleteIcon', disabled: false },
      ]
    }) : []; 
    setRows(rows);
    setsubTotal(sum);
    setTotal(sum);
  }, [cartItems])

  const dataset = [
    {
      title: "Sub Total",
      value: `$${subTotal ? (subTotal).toFixed(2) : 0.00}`,
    },
    // {
    //   title: "Estimated tax for 11218",
    //   value: "$0.00",
    // },
    {
      title: "Discounts",
      value: `${discountAmount > 0 ? '- $' +  discountAmount.toFixed(2) : '$0.00'}`,
    },
  ];

  const validateCode = async () => {
      if(promoCodeApplied) {
        setDiscountCode('');
        setpromoCodeApplied(false);
        return;
      }
      const token = localStorage.getItem("authToken");
      try {
        if(discountCode.length > 0) {
          const promoCodeResponse = await getValidatePromoCode(token, discountCode);
          
          if(promoCodeResponse.error) {
            setpromoCodeApplied(false);
            setdiscountError({
              error: true,
              msg: 'the promotion code you entered is invalid'
            });
          } else {
            const promoObject = promoCodeResponse?.body?.data ?? {};
            if(Object.keys(promoObject).length > 0) {
              let tempSubtotal = 0;
              let tempDiscount = 0;
              setpromoCodeDetails(promoObject.code);
              if(promoObject?.coupon.amount_off) {
                tempDiscount = promoObject.coupon.amount_off/100;
                tempSubtotal = subTotal - tempDiscount;

                setdiscountAmount(tempDiscount);
                setTotal(tempSubtotal);
              } else if(promoObject?.coupon.percent_off) {
                tempDiscount = (promoObject.coupon.percent_off / 100) * subTotal;
                tempSubtotal = subTotal - tempDiscount;

                setdiscountAmount(tempDiscount);
                setTotal(tempSubtotal);
              }
            }
            setpromoCodeApplied(true);
            setdiscountError({
              error: false,
              msg: ''
            });
          }
        } else {
          setpromoCodeApplied(false);
          setdiscountError({
            error: true,
            msg: 'Please enter promo code'
          });
        }

      } catch(err) {
        console.log(err);
      }
  }
  const deleteAction = (deleteData) => {
    console.log(deleteData);
  };
  const deleteItemAction = (deleteData) => {
    console.log("deleteItemAction", deleteData);
    
    let items = cartItems;
    items = items.length ? items.filter((item) => deleteData[0].appName !== Object.keys(item)[0]) : [];
    
    console.log("items", items);
    localStorage.setItem("appCartItem", JSON.stringify(items));
    setcartItems(items)

  };
  const deleteActionChild = (deleteData) => {
    console.log(deleteData,'c');
  };

  const changeQuantityAction = (value, item) => {
    console.log("changeQuantityAction", value, item);
  };

  // ============================== Table End ======================================//

  const style = {
    width:'100%',
  };


  return (
    <div>
        <MasterLayoutMiddle
            header={  
            <>
              <div
                onClick={() => props.history.goBack()}
                className="backBtn">
                <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                <p style={{ alignSelf: "center" }}>Back</p>{" "}
              </div>

            </>
            }

        content={			
          <div className="AppPageContainer cart-table-data">    

            <Grid container spacing={3} className="">	      

              <Grid item xs={8}>
                  <div className="your_cart_title">Your cart</div> 
                  <SimpleTable
                    parentTableDrag={true}
                    showHeader={false}
                    title=""
                    width="100%"
                    rows={rows}
                    columns={col}
                    colSpan={16}
                    actionSendButton={true}
                    classProps={changeClassProps()}
                    deleteActionChild={(deleteItem) => deleteActionChild(deleteItem)}
                    deleteItemAction={(deleteItem) => deleteItemAction(deleteItem)}
                    changeQuantityAction={(value, item) => changeQuantityAction(value, item)}
                    donationsCount={rows && rows.length > 0 ? 1 : 0}
                    noTitle="No Items added"
                    noPara="Added Items will be displayed and managed here"
                    noBtnText="Add New Items"
                    onInviteClick={() => props.history.push( "/more-apps" )}
                    actionLink={(name) => this.details(name)}
                    showIconCol={false}
                    actionSendButton={true}
                    deleteAction={(e) => this.deleteServiceAction(e)}
                    showFooter={false}
                    showAdd={false}
                    addText="ADD NEW"
                    onAddClick={() => props.history.push( "/more-apps" )}
                    iconTextSize={16}
                  />
              </Grid>

              <Grid item xs={4}> 
                <CheckOrderSummary
                  dataset={dataset}
                  callback={callback}
                  //discount={discount}
                  summary="Order Summary"
                  dropDownSummary="Promo code"
                  dropDownDetails="Enter Code Here"
                  placeholder="Enter Code Here"
                  bottomText="Total"
                  buttomSubText="$0"
                  text="Continue to payment"
                  apply={promoCodeApplied ? "Remove" : "APPLY"}
                  onClick={onClick}
                  total={total?.toFixed(2)}
                  error = {discountError.error ? discountError.msg  :''}
                  editCartSection={false}
                  showCheckoutButton={true}
                  applyCode = {validateCode}
                  codeApplied = {promoCodeApplied}
                />
              </Grid>

            </Grid>
                                  
          </div>
        }


        isFooter={true}
        footer={
        <FooterInner 
          style={style}
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
        }

        isWhiteBackground={true}

        />
        
    </div>
  )
}


export default MoreAppsCartDetails
