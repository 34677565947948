import axios from "axios";

const paymentEnv = {
  prod: process.env.REACT_APP_PAYMENT_PROD_API,
  dev: process.env.REACT_APP_PAYMENT_DEV_API
};
const orgEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")
) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}
const orgBaseUrl = orgEnv[currentEnv];
const paymentBaseUrl = paymentEnv[currentEnv];

export function getApps(token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/planSubscription/getAllDcApplications`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getIntegrations(token, compatibleApp){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/planSubscription/getAllDcIntegrations?compatibleApp=${compatibleApp ? compatibleApp : ""}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getOrganizationData(id){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/organizations/${id}`,{
        headers:{
          token:'deenconnect-elobyte'
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPlans(id, token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/planSubscription/getOrganizationPlan/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getCurrentUserDetail(token) {
  return new Promise((resolve, reject) => {
      axios
          .get(orgBaseUrl + `/auth/getCurrentUser`, {
              headers: {
                  token
              },
          })
          .then((res) => {
              const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
              if (data) {
                  resolve(data);
              }
          })
          .catch((error) => {
              reject(error);
          });
  });
}


export function getCustomerPaymentMethods(ids, token){
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/stripe/getCustomerPaymentMethods`, [...ids], {
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updatePlanSubscriptions(id, data, token){
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/planSubscription/updatePlanSubscriptions/${id}`, data, {
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getValidatePromoCode(token, promoCode) {
  return new Promise((resolve, reject) => {
      axios
          .get(orgBaseUrl + `/stripe/validatecoupon/${promoCode}`, {
              headers: {
                  token
              },
          })
          .then((res) => {
            console.log("Response is: ", res);
              const data = res && res.data ;
              if (data) {
                  resolve(data);
              }
          })
          .catch((error) => {
              reject(error);
          });
  });
}
