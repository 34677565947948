import * as constants from '../constants'

const INITIAL_STATE = {
    services: [],
    editableSalah: [],
    edit: false,
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case constants.ADD_SERVICE:
            return {
                ...state,
                services: [...state.services, action.payload]
            };
        case constants.EDIT_SALAH:
            return {
                ...state,
                editableSalah: action.payload,
                edit: true
            };
        case constants.EMPTY_SERVICE:
            return {
                ...state,
                editableSalah: [],
                edit: false
            };
        default:
            return state;
    }
};
