import React, { useImperativeHandle, forwardRef, useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
  BaseSwitch,
  DonationCardMidAvatar,
  BaseDivider,
  BaseTextField,
  DrawerMain,
  BaseFileSelect,
  BaseImgChip,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import InfoLogo from '../../assets/nav_icons/info-24px.svg';
import Insert from '../../assets/img/insert_photo-24px.svg'
import ReplyIcon from '../../assets/nav_icons/reply-24px.svg';
import Code from '../../assets/img/code-24px.svg'
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField
} from "@material-ui/core";
import { TwitterPicker } from "react-color";
import { saveAsDraft } from './Apis'
import { getOrganizationData } from './Apis/index'
import messages_content from '../../utils/messages.json';
import MediaUpload from "../MediaUpload/MediaUpload";
const { 
    token_expired,
    Your_changes_has_been_saved_as_draft,
    Your_changes_has_been_published,
  } = messages_content;
const Setup = forwardRef((props, ref) => {
  const [card1, setCard1] = React.useState(false);
  const [card2, setCard2] = React.useState(false);
  const [videoName, setVideoName] = React.useState('');
  const [tempVideoName, setTempVideoName] = React.useState('');
  const [logo, setLogo] = React.useState([]);
  const [formImage, setFormImage] = useState({});
  const [openLogoDrawer, setOpenLogoDrawer] = React.useState(false);
  const [openVideoDrawer, setOpenVideoDrawer] = React.useState('')
  const [textExemption, setTaxExemption] = React.useState(false);
  const [showOrganization, setShowOrganization] = React.useState(false);
  const [showEmail, setShowEmail] = React.useState(false);
  const [showNumber, setShowNumber] = React.useState(false);
  const [themeColor, setThemeColor] = React.useState('#05CE78')
  const [error, seterror] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [success, setsuccess] = useState(false);
  const [image_video, setImageOrVideo] = useState('');
  const [embedform_draft, setembedform_draft] = useState("");
  const [logoError ,setLogoError] = useState(false)
  const [videoError, setVideoError] = useState(false)
  const [organization_data, setorganization_data] = useState("");
  const [content, setcontent] = useState("");
  const [loading, setLoading] = useState(true);
  const [categories,setCategories] = useState([])
  const [Temp, setTemp] = useState('')
  const setTwitterColorArrow = (color) => {
    const title = document.getElementsByClassName('twitter-picker')[0]?.getElementsByTagName('span')
    if (!title) return
    const length = title?.length
    for (let i = 0; i < length; i++) {
      if (title?.[i]?.children?.[0]?.title?.toLowerCase() === color?.toLowerCase()) {
        title[i].children[0].id = 'checkbox';
        title[i].children[0].innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><defs><style>.a{fill:none;}.b{fill:#fff;}</style></defs><path class="a" d="M0,0H18V18H0Z"/><path class="b" d="M7.7,13.652,5.077,11.027a.742.742,0,1,0-1.05,1.05l3.142,3.142a.747.747,0,0,0,1.057,0l7.95-7.943a.742.742,0,0,0-1.05-1.05Z" transform="translate(-0.952 -1.502)"/></svg>'
      }
      else {
        title[i].children[0].id = '';
        title[i].children[0].innerHTML = ''
      }
    }
  }
  console.log(embedform_draft,Temp, 'draft')
  useEffect(() => {
    async function getEmbedForm() {
      try {
        setLoading(true)
        const orgId = localStorage.getItem('orgID')
        const token = localStorage.getItem('authToken')
        const getOraganization = await getOrganizationData(orgId, token)
        console.log(getOraganization, 'data')
        const embedFormCat = getOraganization?.data?.body?.data?.[0]?.enabledIntegrations?.deenfund?.selectedDonationCategories
        const embedform_draft = getOraganization?.data?.body?.data?.[0]?.embed_form_draft?.[0]
        const embedformDraft = getOraganization?.data?.body?.data?.[0]?.enabledIntegrations?.deenfund
        const organization_data = getOraganization?.data?.body?.data?.[0]
        const showemail = embedformDraft?.embedFormDraft?.[0]?.setup?.footer?.email
        const showaddress = embedformDraft?.embedFormDraft?.[0]?.setup?.footer?.fullOrgAddress
        const phonenumber = embedformDraft?.embedFormDraft?.[0]?.setup?.footer?.phoneNumber
        const showtextexemption = embedformDraft?.embedFormDraft?.[0]?.setup?.footer?.taxNumber
        const themecolor = embedformDraft?.embedFormDraft?.[0]?.formThemeColor
        const content_setup = embedformDraft?.embedFormDraft?.[0]?.setup?.content
        const image_or_video = embedformDraft?.embedFormDraft?.[0]?.setup?.imageOrVideo
        const formimage = getOraganization.data.body.data[0]?.enabledIntegrations?.deenfund?.embedForm?.[0]?.formImage
        console.log( "formimage", formimage )
        setcontent(content_setup)
        setCategories(embedFormCat);
        setembedform_draft(embedformDraft)
        setTemp(embedform_draft)
        setorganization_data(organization_data)
        setFormImage(formimage)
        showemail && setShowEmail(true)
        phonenumber && setShowNumber(true)
        showaddress && setShowOrganization(true)
        showtextexemption && setTaxExemption(true)
        themecolor && setThemeColor(themecolor)
        image_or_video && setImageOrVideo(image_or_video)
        content_setup && image_or_video === 'image' && setImage(content_setup)
        content_setup && image_or_video === 'video' && setVideo(content_setup)
        setLoading(false)
      }
      catch {
        seterror(error && error.response && error.response.data.message)
        setLoading(false)
        setTimeout(() => {
          seterror(false)
        }, 8000)
      }
    }
    getEmbedForm()
  }, [])
  useEffect(() => {
    setTwitterColorArrow(themeColor)
  }, [loading])
  const handleChangeVideo = (prop) => (event) => {
    setTempVideoName(event.target.value)
  };
  const setImage = (data) => {
    if(data) {
      let length = data.split("-")?.length;
      let imageName = length ? data.split("-")[length - 1] : "";
      setLogo([{ src: data, name: imageName ?? "image.png", label: imageName ?? "image.png" }])
    }
    setCard1(true)
    setCard2(false)
  }
  const setVideo = (data) => {
    setVideoName(data)
    setCard2(true)
    setCard1(false)
  }
  useImperativeHandle(ref, () => ({
    async SetupDraft(param) {
      const orgId = localStorage.getItem('orgID')
      const token = localStorage.getItem('authToken')
      console.log("here", card1,"here", logo.length,"here", card2,"here", videoName)
      const formdata = {
        enabledIntegrations: formData(param),
      }
      console.log( "Formdata: ", formdata );

      if(card1 && !logo.length){
        setErrorsOnSave('Logo');
      }else if(card2 && videoName === ''){
        console.log("here")
        setErrorsOnSave('Video')
      } else{
        console.log(param, "param")
        param === 'publish' ? publish(orgId, formdata, token) : draftChanges(orgId, formdata, token)
      }
    }
  }));
  function formData(param) {
    if (param === 'publish') {
      return [
              {
                formThemeColor: themeColor,
                formTitle: embedform_draft?.embedFormDraft?.[0]?.formTitle,
                formLink: embedform_draft?.embedFormDraft?.[0]?.formLink,
                formImage: formImage,
                formHeaderButton: embedform_draft?.embedFormDraft?.[0]?.formHeaderButton,
                processingFeeEnabled: embedform_draft?.embedFormDraft?.[0]?.processingFeeEnabled,
                preselectAmounts: embedform_draft?.embedFormDraft?.[0]?.preselectAmounts,
                setup: {
                  footer: {
                      enabled: true,
                      email: showEmail ? organization_data?.orgEmail : '',
                      fullOrgAddress: organization_data?.orgAddress !== JSON.stringify('{}') && showOrganization ? `${organization_data?.orgAddress?.line1},${organization_data?.orgAddress?.line2 && " " + organization_data?.orgAddress?.line2} ${organization_data?.orgAddress?.city}, ${organization_data?.orgAddress?.state} ${organization_data?.orgAddress?.postalCode}` : '',
                      phoneNumber: showNumber ? organization_data?.phoneFax?.primaryPhone : '',
                      taxNumber: textExemption ? 'true' : ''
                    },
                    rightSide: embedform_draft?.embedFormDraft?.[0]?.setup?.rightSide,
                    imageOrVideo: image_video ? image_video : '',
                    content: image_video ? image_video === 'image' ? logo?.[0]?.src ? logo?.[0]?.src : '' : videoName : '',
                    faqs: embedform_draft?.embedFormDraft?.[0].setup?.faqs
                },
                donationFormType: embedform_draft?.embedFormDraft?.[0]?.donationFormType,
                paymentMethod: embedform_draft?.embedFormDraft?.[0]?.paymentMethod,//release 2 will have two donations values
                websiteName: embedform_draft?.embedFormDraft?.[0].websiteName,
                donationMinimumAmount: embedform_draft?.embedFormDraft?.[0].donationMinimumAmount,
                donationMinimumAmountEnabled: embedform_draft?.embedFormDraft?.[0]?.donationMinimumAmountEnabled,
                embedButton: embedform_draft?.embedFormDraft?.[0].embedButton,
                changes: false,
              }
            ]
    } else {
      return [
        {
          formThemeColor: themeColor,
          formTitle: embedform_draft?.embedFormDraft?.[0]?.formTitle,
          formLink: embedform_draft?.embedFormDraft?.[0]?.formLink,
          formImage: formImage,
          formHeaderButton: embedform_draft?.embedFormDraft?.[0]?.formHeaderButton,
          processingFeeEnabled: embedform_draft?.embedFormDraft?.[0]?.processingFeeEnabled,
          preselectAmounts: embedform_draft?.embedFormDraft?.[0]?.preselectAmounts,
          setup: {
            footer: {
                enabled: true,
                email: showEmail ? organization_data?.orgEmail : '',
                fullOrgAddress: organization_data?.orgAddress !== JSON.stringify('{}') && showOrganization ? `${organization_data?.orgAddress?.line1},${organization_data?.orgAddress?.line2 && " " + organization_data?.orgAddress?.line2} ${organization_data?.orgAddress?.city}, ${organization_data?.orgAddress?.state} ${organization_data?.orgAddress?.postalCode}` : '',
                phoneNumber: showNumber ? organization_data?.phoneFax?.primaryPhone : '',
                taxNumber: textExemption ? 'true' : ''
              },
              rightSide: embedform_draft?.embedFormDraft?.[0]?.setup?.rightSide,
              imageOrVideo: image_video ? image_video : '',
              content: image_video ? image_video === 'image' ? logo?.[0]?.src ? logo?.[0]?.src : '' : videoName : '',
              faqs: embedform_draft?.embedFormDraft?.[0].setup?.faqs
          },
          donationFormType: embedform_draft?.embedFormDraft?.[0]?.donationFormType,
          paymentMethod: embedform_draft?.embedFormDraft?.[0]?.paymentMethod,//release 2 will have two donations values
          websiteName: embedform_draft?.embedFormDraft?.[0].websiteName,
          donationMinimumAmount: embedform_draft?.embedFormDraft?.[0].donationMinimumAmount,
          donationMinimumAmountEnabled: embedform_draft?.embedFormDraft?.[0]?.donationMinimumAmountEnabled,
          embedButton: embedform_draft?.embedFormDraft?.[0].embedButton,
          changes: true,
        }
      ]
    }
  }
  function setErrorsOnSave(params){
    if(params === 'Logo'){
      setLogoError(true)
    }else if(params === 'Video'){
      setVideoError(true)
    }
    props.hideAlert()
  }
  async function publish(orgId, formdata, token) {
    console.log("formdata", formdata)
    let formData2 = {
      'enabledIntegrations.deenfund.embedForm': formdata.enabledIntegrations,
      'enabledIntegrations.deenfund.embedFormDraft': formdata.enabledIntegrations
    }

    console.log(formData2, 'formData2')

    props.publishChanges(false)
    try {
      await saveAsDraft(orgId, formData2, token)
      setshowAlert(true)
      setsuccess('Publish')
      props.hideAlert()
      props.checkChanges(false)
      props.getnewdata(true)
      setTimeout(() => {
        setshowAlert(false)
        setsuccess(false)
      }, 8000)
    }
    catch (error) {
      seterror(error.response && error.response.data.message)
      props.hideAlert()
      setTimeout(() => {
        seterror(false)
      }, 8000)
    }
  }
  async function draftChanges(orgId, formdata, token) {

    console.log("formdata", formdata)
    let formData2 = {
      'enabledIntegrations.deenfund.embedForm': formdata.enabledIntegrations,
      'enabledIntegrations.deenfund.embedFormDraft': formdata.enabledIntegrations
    }

    console.log(formData2, 'formData2')
    
    try {
      await saveAsDraft(orgId, formData2, token)
      setshowAlert(true)
      setsuccess(true)
      props.hideAlert()
      props.checkChanges(false)
      setTimeout(() => {
        setshowAlert(false)
        setsuccess(false)
      }, 8000)
    }
    catch (error) {
      seterror(error.response && error.response.data.message)
      props.hideAlert()
      setTimeout(() => {
        seterror(false)
      }, 8000)
    }
  }
  function addVideo(obj) {
    if(obj?.src?.length > 0) {
      props.checkChanges(true)
      setVideoName(obj.src)
      setImageOrVideo('video')

    }
    
    //setOpenVideoDrawer(false);
  }
  function addLogo(obj) {
    if (obj?.src?.length > 0) {
      setLogo([
        {
          label: obj.title,
          name: obj.title,
          src: obj.src,
          size: '',
        },
      ])
      props.checkChanges(true)
      //setOpenLogoDrawer(false);
      setImageOrVideo('image')
    } else {
      //setOpenLogoDrawer(false);
      props.checkChanges(false)
    }
  };
  const cardFunction = (prop) => {
    console.log("prop", prop)
    if ((prop === "setCard1" && card1) || (prop === "setCard2" && card2)) {
      setCard1(false);
      setCard2(false);
      props.checkChanges(false)
      setImageOrVideo('')
    } else if (prop === "setCard1") {
      setCard1(true);
      setCard2(false);
      props.checkChanges(true)
      setImageOrVideo('image')
    } else if (prop === "setCard2") {
      setCard2(true);
      props.checkChanges(true)
      setCard1(false);
      setImageOrVideo('video')
    }
  };
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  function handleLogoRemove(sentIndex) {
    setLogo([]);
  };
  function logoUpload(files) {
    if (files.length > 0) {
      setLogo(files);
    }
  };
  function colorChangeHandler(event) {
    console.log(event)
    setThemeColor(event.hex)
    props.checkChanges(true)
    setTwitterColorArrow(event.hex)
  }
  const styles = {
    btn: {
      height: 66,
      width: "100%",
      margin: 8,
      border: "#D3D8DA",
      color: '#D3D8DA'
    },
  };
  const deleteChip = () => {
    setLogo([]);
    setImageOrVideo("")
    setCard1(false)
    props.checkChanges(true)
  }
  return (
    <>
      {
        error && error === "Invalid token" && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={token_expired}
              alertType={"error"}
              onClick={() => seterror(false)}
            />
          </div>
        )
      } 
      {
        showAlert && error && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={
                error === "Invalid token"
                  ? token_expired
                  : error
              }
              alertType={"error"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        )
      }
      {
        showAlert && success && success === 'Publish' ? (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={Your_changes_has_been_published}
              alertType={"success"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        ) : showAlert && success && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={Your_changes_has_been_saved_as_draft}
              alertType={"success"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        )
      }
      <div className="main-form-container">
        {loading && <div className='skeletons'>
          <SkeletonTheme color="#f7f8fa">
            <Skeleton height={300} width={570} />
            <Skeleton height={300} width={570} />
          </SkeletonTheme>
        </div>}
        {!loading && <section>
          <div className="emb_card">
            <div className="card_title">
              <h1 className="df-form-title">Theme Color</h1>
            </div>
            <TwitterPicker
              width='500px'
              className='df-setup-colorpicker'
              colors={['#2A82F6', '#05CE78', '#7266FB', '#00ADBE', '#E57D33', '#F9A602']}
              color={themeColor}
              styles={{ width: '100%', border: 'none' }}
              onChange={(event) => colorChangeHandler(event)}
            />
          </div>
          <div className="emb_card">
            <div className="card_title">
              <h1 className="df-form-title">Add Media</h1>
            </div>
            <div className="df-donation-form-cards step_card_fild">
              <span className={card1 ? "selected-card" : ""}>
              <DonationCardMidAvatar
                cardCheck={card1}
                cardFunction={() => cardFunction("setCard1")}
                header={"Upload Image"}
                showStartImg={true}
                startImg={Insert}
              />
              </span>

              <span className={card2 ? "selected-card" : ""}>
              <DonationCardMidAvatar
                cardCheck={card2}
                cardFunction={() => cardFunction("setCard2")}
                header={"Embed Video"}
                showStartImg={true}
                startImg={Code}
              />
              </span>
            </div>
            <div style={{ marginBottom: 75 }}></div>
            {card1 &&
              <div className="img-chip df-img-text-field" style={{ display: 'flex', alignItems: 'center' }} /* onClick={() => setOpenLogoDrawer(true)} */>
                <div style={{ width: '100%', }}>
                  <MediaUpload
                    addMediaItem={obj => addLogo(obj)}
                    label="Add Image"
                    buttonName="ADD IMAGE"
                    typeOfMedia='/images'
                    selectedMedia={
                      { logoUrl: (image_video === 'image' && content?.indexOf("youtube") === -1) ? 
                          (logo?.[0]?.src?.length > 0) ?
                            logo[0].src :
                             content  : "", 
                        logoName: (image_video === 'image' && content?.indexOf("youtube") === -1) ? 
                        (logo?.[0]?.name?.length > 0) ?
                          logo[0].name :
                           ""  : "",
                      }}
                  />
                  {/* <BaseImgChip
                    chipData={logo}
                    deleteChip={deleteChip}
                    helperText={logoError ? 'Image is required' : ''}
                    error={logoError}
                    label='Select image'
                  /> */}
                </div>
              </div>}
            {card2 &&
              <div className="img-chip df-img-text-field" /* onClick={() => setOpenVideoDrawer(true)} */>
                <div style={{ width: '100%' }}>
                  <MediaUpload
                      addMediaItem={(obj) => addVideo(obj)}
                      label="Add Video"
                      buttonName="ADD VIDEO"
                      typeOfMedia='/videos'
                      showMediaTab={"video"}
                      selectedMedia={{ 
                        logoUrl: (image_video === 'video' && content?.split("/embed/").length === 2) ? 
                        `https://img.youtube.com/vi/${content?.split("/embed/")?.[1]}/sddefault.jpg` : "",
                        logoName: (image_video === 'video' && content?.split("/embed/").length === 2) ? content : ""}}

                    />

                  {/* <BaseTextField
                    value={videoName}
                    onChange={handleChangeVideo("name")}
                    InputProps={true}
                    phone={false}
                    search={false}
                    helperText={videoError ? 'Video is required' : ''}
                    error={videoError}
                    label={'Select Video'}
                    style={styles.btn}
                    color="primary"
                    disabled={true}
                    helperText=" "
                  /> */}
                </div>
              </div>}
          </div>
          <div className="emb_card">
            <div className="card_title">
              <h1 className="df-form-title">Footer</h1>
            </div>
            <div className="df-form-link-text-field">
              <div className="df-homepage-btn">
                <h1 className="df-form-footer-link">Show Tax-exemption status & Tax ID</h1>
                <div className="df-switch">
                  <BaseSwitch
                    checked={textExemption}
                    onChange={() => {
                      setTaxExemption(!textExemption);
                      props.checkChanges(true)
                    }
                    }
                  />
                </div>
              </div>
              <div className="df-divider">
                <BaseDivider width={525} />
              </div>
              <div className="df-homepage-btn">
                <h1 className="df-form-footer-link">Show organization full address</h1>
                <div className="df-switch">
                  <BaseSwitch
                    checked={showOrganization}
                    onChange={() => {
                      props.checkChanges(true)
                      setShowOrganization(!showOrganization)
                    }}
                  />
                </div>
              </div>
              <div className="df-divider">
                <BaseDivider width={525} />
              </div>
              <div className="df-homepage-btn">
                <h1 className="df-form-footer-link">Show email address</h1>
                <div className="df-switch">
                  <BaseSwitch
                    checked={showEmail}
                    onChange={() => {
                      props.checkChanges(true)
                      setShowEmail(!showEmail)
                    }}
                  />
                </div>
              </div>
              <div className="df-divider">
                <BaseDivider width={525} />
              </div>
              <div className="df-homepage-btn">
                <h1 className="df-form-footer-link">Show phone number</h1>
                <div className="df-switch">
                  <BaseSwitch
                    checked={showNumber}
                    onChange={() => {
                      setShowNumber(!showNumber)
                      props.checkChanges(true)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <DrawerMain
            open={openLogoDrawer}
            openHandler={() => setOpenLogoDrawer(false)
            }
            title="Select Image"
            onCancel={() => setOpenLogoDrawer(false)
            }
            onSave={() => addLogo()}
          >
            <List className="img-details" style={{ paddingLeft: 0 }}>
              {logo.map((file, index) => (
                <ListItem key={file.name} style={{ paddingLeft: 0 }}>
                  <ListItemAvatar>
                    <Avatar
                      variant="square"
                      alt={"preview"}
                      src={
                        logo.length > 0
                          ? logo[0].preview || logo[0].src
                          : file.preview
                      }
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      (file.name && file.name.slice(0, 20)) ||
                      file.src.slice(0, 20)
                    }
                    secondary={file.size && formatBytes(file.size)}
                  />
                  <ListItemSecondaryAction style={{ paddingLeft: 20 }}>
                    <IconButton
                      aria-label="delete"
                      size="medium"
                      style={{ padding: "0px" }}
                      onClick={() => handleLogoRemove(index)}
                    >
                      <DeleteForeverIcon fontSize="inherit" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            {logo.length === 0 && (
              <>
                <div style={{ marginBottom: 20 }} className="img-uploader">
                  <BaseFileSelect
                    selectedFiles={(file) => logoUpload(file)}
                  />
                </div>
              </>
            )}
            {logo.length > 0 && logo[0].src.slice(0, 5) !== "https" && (
              <img src={logo.length > 0 && logo[0].src} width='100%' />
            )}
            <br />
          </DrawerMain>
          <DrawerMain
            open={openVideoDrawer}
            openHandler={() => setOpenVideoDrawer(false)
            }
            title={`Embed Video `}
            img={InfoLogo}
            onCancel={() => setOpenVideoDrawer(false)
            }
            onSave={() => addVideo()}
          >
            <div className="img-chip" onClick={() => setOpenVideoDrawer(true)}>
              <TextField
                id="standard-textarea"
                label="Embed Video"
                placeholder=""
                multiline
                style={{ width: '100%' }}
                value={tempVideoName}
                onChange={handleChangeVideo("name")}
              />
              <div style={{ marginTop: 50 }}>
                <div style={{ display: 'flex' }}>
                  <img src={InfoLogo} width='19px' height='19px' />
                  <label style={{ marginLeft: 10 }}>
                    Embed a video
                  </label>
                </div>
                <div style={{ display: 'flex' }} className='df-dr-vd-dexc'>
                  <label style={{ marginTop: 10 }}>
                    1. On a computer, go to the YouTube video you want to embed.
                  </label>
                </div>
                <div style={{ display: 'flex', marginTop: 10 }} className='df-dr-vd-dexc'>
                  <label style={{ marginRight: 10 }}>
                    2. Under the video, click
                    <label style={{ fontWeight: "bold", }} >{' '}
                      SHARE
                    </label>
                  </label>
                  <img src={ReplyIcon} width='17px' height='17px' />
                </div>
                <div style={{ display: 'flex', marginTop: 10 }} className='df-dr-vd-dexc'>
                  <label style={{ marginRight: 10 }}>
                    3. Click
                      <label style={{ fontWeight: "bold", }} >{' '}
                      Embed
                      </label>
                      .
                  </label>
                </div>
                <div style={{ display: 'flex', marginTop: 10 }} className='df-dr-vd-dexc'>
                  <label style={{ marginRight: 10 }}>
                    4. From the box that appears, copy the HTML code.
                  </label>
                </div>
                <div style={{ display: 'flex', marginTop: 10 }} className='df-dr-vd-dexc'>
                  <label style={{ marginRight: 10 }}>
                    5. Paste the code into the field above and save.
                  </label>
                </div>
              </div>
            </div>
          </DrawerMain>
        </section>}
      </div>
    </>
  );
})

export default Setup;
