import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import FileIcon from '../../assets/img/upload_file_black_24dp.svg'
// import AddRoundedIcon from '@material-ui/icons/AddRounded';

import salah from './NextSalah.png';
import events from './EventsLayout.png';
import DEENFUND from './DonationLayout.png';

function Modalintegration(props) {
  const { openIntegrationModal, setopenIntegrationModal, card, setcard, integrationsData} = props;
  const classes = useStyles();

  // const intData = props.integrationsData;
  // console.log("intData", intData)

  const [selectedIntegration, setselectedIntegration] = useState({})
  const [integrations, setintegrations] = useState([])
  // const [intData, setintData] = useState([])

  const [value, setValue] = useState(parseInt(0));
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => setopenIntegrationModal(false);

  const addIntegration = () => {
    handleClose()
    setcard({...card, cardType: "integration", integrationType: selectedIntegration.integrationApi, imageMetaData: { imageUrl: selectedIntegration.img, imageName: selectedIntegration.name }})
  }

  const onIntegrationClick = (el) => {
    setselectedIntegration(el)
  }

  useEffect(() => {
    let subscriptions = localStorage.getItem("subsData") ? JSON.parse(localStorage.getItem("subsData")) : [];
    if(integrationsData?.length && subscriptions?.length) {
      const arr = integrationsData?.filter(el => subscriptions.includes(el.integrationApi)).map((int) => {
        return {
          name: int.name, img: int.imageMetaData?.imageUrl, integrationApi: int.integrationApi,
        }
      });
      if(subscriptions.includes("deenfund")) {
        arr.push({
          name: "DEENFUND", img: "https://i.ibb.co/mhTNNDX/DEENCONNECT-Integration-Icon.png", integrationApi: "deenfund",
        })
      }
      console.log(arr, subscriptions, "filtererd")
      setintegrations(arr)
    }
  }, [integrationsData])

  return (
    <div className="ModalContainer">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openIntegrationModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openIntegrationModal}>
          <div className={classes.Container}>

            <div className={classes.Title}>Applications/Integrations</div>

            <div className={classes.InnerContainer}>
              <div className={classes.ModalLefCtontainer}>
                <Tabs value={value} onChange={handleChange}>
                  {integrations?.length ? integrations.map((el, i) => {
                    return <Tab key={i} label={el.name} icon={<img className="fileIcon" src={el.img} alt={el.name} width={"32px"} />} onClick={() => onIntegrationClick({...el})}></Tab>
                  }) : null}
                </Tabs>
              </div>

              <div className={classes.ModalRightCtontainer}>
                  {integrations?.length ? integrations.map((el, i) => {
                    return <TabPanel key={i} value={value} index={i}>
                    <div className={classes.PanelContainer}><img className={classes.img} src={el.integrationApi === "events" ? events : el.integrationApi === "salah" ? salah : el.integrationApi === "deenfund" ? DEENFUND : "" } alt="layout" /></div>
                  </TabPanel>

                  }) : null}
              </div>

            </div>

            <div className={classes.ModalFooter}>
              <button className="ButtonCancel" type="button" onClick={handleClose}> BACK</button>
              <button className={`ButtonContinue `} type="button" onClick={addIntegration}>{"ADD"} </button>
            </div>
          </div>
        </Fade>
      </Modal>


    </div>


  );
}


function TabPanel(props) {
  const { children, value, index } = props;

  return (<div>
    {
      value === index && (
        <h1>{children}</h1>
      )
    }

  </div>)

}

export default React.memo(Modalintegration);



const useStyles = makeStyles((theme) => ({

  Container: {
    background: "#fff",
    maxWidth: "1030px",
    width: "100%",
    height: "750px",
    position: "relative",
    left: "0",
    top: "11%",
    borderRadius: "4px",
    margin: "0 auto",
  },

  InnerContainer:{
    display: "flex",
    height:" 92%",
    overflow:"hidden",
  },

  Title: {
    fontSize: "24px",
    color: "#5A5A5A",
    fontWeight: "300",
    background: "#F7F8FA",
    height: "60px",
    display: "flex",
    padding: "0 30px",
    alignItems: "center",
    justifyContent: "space-between",
  },

  ModalLefCtontainer: {
    background: "#dfe6e9",
    maxWidth: "200px",
    width: "100%",
    borderRadius: "4px 0 0 4px",
    minWidth: "322px",

    '& .MuiTabs-flexContainer': {
      display: "block !important",
    },

    '& .MuiTabs-flexContainer .MuiTab-root': {
      width: "100%",
      margin: "0px !important",
      height: "auto",
      minHeight: "auto",
      padding: "10px !important",
      paddingBottom: "7px !important",
      height: "50px",
    },

    '& .Mui-selected': {
      background: "#fff",
      borderRight: " #2A82F6 2px solid !important",
    },

    '& .MuiTabs-scroller': {
      whiteSpace: "initial !important",
    },

    '& .MuiTab-wrapper': {
      width: "100% !important",
      display: "flex !important",
      flexDirection: "initial",
      margin: "0 !important",
      alignItems: "center !important",
    },

    '& .MuiTabs-indicator': {
      display: "none !important",
    }


  },

  ModalRightCtontainer: {
    padding: "32px 60px",
    width: "100%",
  },

  PanelContainer: {
    
  },

  img: {
    width: "65%",
    margin:"0 auto",
    display:"inherit",
  },

  ModalFooter: {
    background: "#fff",
    boxShadow: "0px -1px 4px #0000000F",
    padding: "17px 0px",
    position: "absolute",
    bottom: "0",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderRadius:"0 0 4px 4px",

    "& .ButtonCancel": {
      color: "#818E94",
      backgroundColor: "transparent",
      borderRadius: "100px",
      margin: " 0 30px",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "400",
      height: "40px",
      outline: "none",
      border: "none",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "uppercase",
      letterSpacing: "1.25px",
    },

    "& .ButtonContinue": {
      color: "#fff",
      backgroundColor: "#2a82f6",
      borderRadius: "100px",
      padding: " 0 30px",
      margin: " 0 30px",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "400",
      height: "40px",
      outline: "none",
      border: "none",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "uppercase",
      letterSpacing: "1.25px",
    },

    "& .disableContinue": {
      color: "#fff",
      backgroundColor: "darkGray",
      cursor: "default",
    },
  },


}));