import React, { useEffect } from "react";
import Moment from "moment";
import MomentTime from "moment-timezone";
import {
  BaseTextField,
  BaseButton,
  BaseStepper,
  BaseStepperExtended,
  FormPublishUnpublish,
  MasterLayoutMiddle,
  FooterInner,
  DrawerMain,
  BaseFileSelect,
  BaseImgChip,
  AlertAction,
  FormSwitch, TextEditor, BaseSelect
} from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { useState } from "react";
import "./style.css";
import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import {AddSliderWebsite, getAnnoucementsWithAppId, getEventsWithAppId, getNewsWithAppId, GetSliderWebsite,  UpdateSliderWebsite } from "./api";
import { connect } from "react-redux";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import MediaUpload from "../../components/MediaUpload/MediaUpload";
import messages_content from '../../utils/messages.json';

const { token_expired, publish_date_before_unpublish, success_message_edit_slider } = messages_content;


function EditSlider(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [visibility, setVisibility] = React.useState("Public");
  const [spinner, setSpinner] = React.useState(false);
  const [Label, setLabel] = React.useState('');
  const [Link, setLink] = React.useState('https://');
  const [openLogoDrawer, setOpenLogoDrawer] = React.useState(false);
  const [Action, setAction] = React.useState(false);
  const [logo, setLogo] = React.useState([]);
  const [publish, setPublish] = React.useState("Immediately");
  const [unPublish, setUnPublish] = React.useState("Never");
  const [publishDate, setPublishDate] = React.useState("Immediately");
  const [publishTime, setPublishTime] = React.useState("");
  const [unPublishDate, setUnPublishDate] = React.useState("Never");
  const [unPublishTime, setUnPublishTime] = React.useState("");
  const [unPublishTimestamp, setUnPublishTimestamp] = React.useState(0);
  const [UTCDate, setUTCDate] = React.useState('');
  const [UTCTime, setUTCTime] = React.useState('');
  const [UTCDateUnPublish, setUTCDateUnPublish] = React.useState('');
  const [disabled, setDisabled] = React.useState(false)
  const [UTCTimeUnPublish, setUTCTimeUnPublish] = React.useState('');
  const [publishedDateAndTime, setPublishedDateAndTime] = React.useState('');
  const [unPublishedDateAndTime, setUnPublishedDateAndTime] = React.useState('');
  const [category, setCategory] = React.useState('');
  const [Title, setTitle] = React.useState('')
  const [titleObj, setTitleObj] = React.useState([{}])
  const [desc, setDesc] = React.useState([{type:'paragraph',children:[{text:""}]}]);
  const [validUnPublish, setValidUnPublish] = React.useState(true);
  const [validPublish, setValidPublish] = React.useState(true);
  const [errorCategory, setErrorCategory] = React.useState(false);
  const [errorTitle, setErrorTitle] = React.useState(false);
  const [dateError, setDateError] = React.useState(false);
  const [errorDesc, setErrorDesc] = React.useState(false);
  const [errorlogo, setErrorlogo] = React.useState(false);
  const [allAnnouncement,setAllAnnouncement] = React.useState([]);
  const [allEvents,setAllEvents] = React.useState([])
  const [allNews,setAllNews] = React.useState([])
  const [allSliders, setAllSliders] = React.useState([]);
  const [sliderTitles, setsliderTitles] = useState([])
  const [error, seterror] = useState(false);
  useEffect(() => {
    if (Moment(unPublishDate) < Moment()) {
      setVisibility("Hidden");
    }
  }, [unPublishedDateAndTime]);

  // useEffect(() => {
  //   getAllSlidersFunction()
  // }, [])

  async function getAllSlidersFunction() {
    const token = localStorage.getItem('authToken');
    const orgId = localStorage.getItem('orgID');
    setSpinner(true)
    try {
      const response = await GetSliderWebsite(token);
      const slidersArray = response?.data?.body?.data;
      response?.data?.body?.data && setAllSliders(slidersArray);
      slidersArray?.length && setsliderTitles(slidersArray?.map((el) => el.title))
      setSpinner(false)
    }
    catch (error) {
      setSpinner(false)
      seterror(error.response && error.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
    try {
    setSpinner(true)
      const responseA = await getAnnoucementsWithAppId(token, orgId, 'deenweb');
      const annArray = responseA.data.body.data;
      responseA?.data?.body?.data && setAllAnnouncement(annArray);
      setSpinner(false)
    }
    catch (error) {
      setSpinner(false)
      seterror(error.response && error.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
    try {
    setSpinner(true)

      const responseE = await getEventsWithAppId(token, orgId, 'deenweb');
      const annEvents = responseE.data.body.data;
      responseE?.data?.body?.data && setAllEvents(annEvents);
      setSpinner(false)
    }
    catch (error) {
      setSpinner(false)
      seterror(error.response && error.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
    try {
    setSpinner(true)

      const responseN = await getNewsWithAppId(token, orgId, 'deenweb');
      const GetNews = responseN.data.body.data;
      responseN?.data?.body?.data && setAllNews(GetNews);
      setSpinner(false)
    }
    catch (error) {
      setSpinner(false)
      seterror(error.response && error.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }

  useEffect(() => {
    switch (category) {
      case 'Events':
        const selectEvents = allEvents;
        if(!selectEvents?.length) {
          setTitleObj([])
          setEmptyFields()
          return
        }        
        const titleEvents = selectEvents?.map(text => {
          return { value: text.event.title, label: text.event.title }
        })
        setTitleObj(titleEvents)
        setSelectedEvent(selectEvents)
        break;
      
      case 'Announcement':
        const selectAnnouncement = allAnnouncement;
        if(!selectAnnouncement?.length) {
          setTitleObj([])
          setEmptyFields()
            return
        }
        const titleAnnouncement  = selectAnnouncement?.map(text => {
          return { value: text.announcement.title, label: text.announcement.title }
        })
        setTitleObj(titleAnnouncement)
        setSelectedAnnouncement(selectAnnouncement)
        break
      
      case 'News':
        const selectNews = allNews;
        const select = selectNews.filter(text => text.article.category === 'News')
        if(!select?.length) {
          setTitleObj([])
          setEmptyFields()
           return
         }
        const titleNews = select?.map(text => {
          return { value: text.article.title, label: text.article.title }
        })
        setTitleObj(titleNews)
        setSelectedNews(select)
        break
      
      case 'Articles':
        const selectNew = allNews;
        const selectArticle = selectNew.filter(text => text.article.category === 'Article')
        if(!selectArticle?.length) {
          setTitleObj([])
          setEmptyFields()
          return
        }
        const titleArticle = selectArticle?.map(text => {
          return { value: text.article.title, label: text.article.title }
        })
        setTitleObj(titleArticle)
        setSelectedArticle(selectArticle)

    }
      
  }, [allEvents, allAnnouncement, allNews])

  useEffect(() => {
    getAllSlidersFunction();

    let paramData = props.location.state.detail;
    console.log('param', paramData)
    paramData[0].description && setDesc(JSON.parse(paramData[0].description));
    setCategory(paramData[0].category);
    setTitle(paramData[0].title);
    // setAction(paramData[0].actionButton)
    setPublishDate(
      `${Moment(paramData[0].website.publishFrom).format("ddd")}, ${Moment(paramData[0].website.publishFrom).format("MMM D, YYYY")}`
    );
    setPublishTime(Moment(paramData[0].website.publishFrom).format("h:mm A"));

    if(Moment(paramData[0].website.publishTo).format("YYYY") === "2099") {
      setUnPublishDate('Never');
      setUnPublishTime('');
      console.log("here")
     } else {
      setUnPublishDate(
      `${Moment(paramData[0].website.publishTo).format("ddd")}, ${Moment(paramData[0].website.publishTo).format("MMM D, YYYY")}`
      );
      setUnPublishTime(Moment(paramData[0].website.publishTo).format("h:mm A"));
     }

    setUTCDate(Moment(paramData[0].website.publishFrom).format('YYYY-MM-DD'));
    setUTCTime(Moment(paramData[0].website.publishFrom).format("HH:mm:ss"));
    setUTCDateUnPublish(Moment(paramData[0].website.publishTo).format('YYYY-MM-DD'));
    setUTCTimeUnPublish(Moment(paramData[0].website.publishTo).format("HH:mm:ss"));
    setPublishedDateAndTime(paramData[0].website.publishFrom);
    setUnPublishedDateAndTime(paramData[0].website.publishTo);
    setVisibility(paramData[0].website.status)
    setLogo([
      {
        label: paramData[0].imageMetaData.imageName.slice(0, 20),
        name: paramData[0].imageMetaData.imageName.slice(0, 20),
        src: paramData[0].imageMetaData.imageUrl,
        size: paramData[0].imageMetaData.imageSize,
      },
    ])
    setLabel(paramData[0].button.buttonLabel);
    setLink(paramData[0].button.buttonLink);
    setAction(paramData[0].button.actionButton);
    // if (paramData[0].button.buttonLabel !== " " && paramData[0].button.buttonLink !== "https://") {
    //   setAction(true)
    // }



  }, [])

  

  function addLogo(obj) {
    if (obj?.src) {
      setLogo([
        {
          label: obj.title,
          name: obj.title,
          src: obj.src,
          size: '',
        },
      ])
      setErrorlogo(false)
      setOpenLogoDrawer(false);
    } else {
      setOpenLogoDrawer(false);
    }
  };
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  function handleLogoRemove() {
    setLogo([]);
  };
  function logoUpload(files) {
    if (files.length > 0) {
      setLogo(files);
    }
  };
  const getPublishDate = (value) => {
    if(value === "Immediately") {
      setUTCDate('');
      setPublishedDateAndTime('');
      setPublish("Immediately");
      setPublishDate("Immediately")
    } else {
      setValidPublish(false);
      setPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDate(Moment(value).format('YYYY-MM-DD'));
    }
  };
  const getPublishTime = (value) => {
    setPublishTime(Moment(value).format("h:mm A"));
    setUTCTime(Moment(value).format("HH:mm:ss"));
  };
  const getUnPublishDate = (value) => {
    if(value == "Never") {
      setUTCDateUnPublish('');
      setUnPublishedDateAndTime('')
      setUnPublish('Never');
      setUnPublishDate('Never');
    } else {
      setValidUnPublish(false);
      setUnPublishTimestamp(value);
      setUnPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDateUnPublish(Moment(value).format('YYYY-MM-DD'));
    }
  };
  const getUnPublishTime = (value) => {
    setUnPublishTime(Moment(value).format("h:mm A"));
    setUTCTimeUnPublish(Moment(value).format("HH:mm:ss"));
  };
  const onVisibilitySave = () => {
    console.log("visibility saved");
  };
  const onVisibilityCancel = () => {
    console.log("visibility cancelled");
  };
  const onPublishSave = () => {
    let Time = publishTime.split(' ');
    let dated = MomentTime(new Date(publishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = `${dated + 'T' + Hours}:00.00Z`
    setPublishedDateAndTime(date);
  };

  const onPublishCancel = () => {
    console.log("publish date cancel");
  };

  const onUnPublishSave = () => {
    let Time = unPublishTime.split(' ');
    let dated = MomentTime(new Date(unPublishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = `${dated + 'T' + Hours}:00.00Z`
    setUnPublishedDateAndTime(date);
  };
  const onUnPublishCancel = () => {
    console.log("unpublish date cancel");
  };
  const convertUTC = (date) => {
    return date.toISOString()
  }
  const onSave = async () => {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    let data = UTCDate === '' ? new Date() : new Date(`${UTCDate + ' ' + UTCTime}`)
    let dataU = convertUTC(data);
    let dataE = UTCDateUnPublish === '' ? new Date() : new Date(`${UTCDateUnPublish + ' ' + UTCTimeUnPublish}`)
    let UnpublishTime = convertUTC(dataE);
    // console.log(publishDate, publish)
    let obj = {
      title: Title,
      imageMetaData: {
        imageUrl: logo[0].src,
        imageName: logo[0].name,
        imageThumbnail: logo[0].src,
        imageSize: logo[0].size
      },
      category: category,
      author: 'Auther',
      description: JSON.stringify(desc),
      website: {
        status: visibility,
        publishFrom: publishDate === 'Immediately' ? publish : dataU,
        publishTo: UTCDateUnPublish === "" ? unPublish : UnpublishTime,
      },
      button: {
        actionButton: Action,
        buttonLink: Link,
        buttonLabel: Label ? Label : ' ',
      },
      orgId: orgId
    }
    console.log("obdiaj", obj)

    let previousUnpublish = obj.website.publishFrom !== 'Immediately' ? obj.website.publishTo > obj.website.publishFrom : true
    if (obj.website.publishTo === 'Never' || previousUnpublish) {
      try {
        console.log("obj", obj)
        let response = await UpdateSliderWebsite(obj, token, props.match.params.id);
        props.alertShow(success_message_edit_slider)
        if (response.status === 200) {
          props.history.push("/website/home-page");
        }
      } catch (err) {
        seterror(err.response && err.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    } else {
      setDateError(true)
      setTimeout(() => {
        setDateError(false)
      }, 8000)
    }
  };
  const onBack = () => {
    setActiveStep(0);
  };
  const handleNext = () => {
    console.log(category, Title, desc, logo)
    if (category === '' || Title === '' || desc === '' || logo.length === 0) {
      if (category === '') {
        setErrorCategory(true);
      }
      if (Title === '') {
        setErrorTitle(true);
      }
      if (desc === '') {
        setErrorDesc(true);
      }
      if (logo.length) {
        setErrorlogo(true);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const getSteps = () => {
    return [
      { number: 1, label: "Basic Details" },
      { number: 2, label: "Publish" },
    ];
  }
  const [values, setValues] = React.useState({
    age: "",
  });

  const setSelectedAnnouncement = (select) => {
    console.log("Select is: ", select)
    const desct = select.length ? select[0].announcement.description : ""
    const ImageChips = []
    const actionButtont = select.length ? select[0].announcement.button.actionButton : false
    const buttonLinkt = select.length ? select[0].announcement.button.url : ""
    const buttonLabelt = select.length ? select[0].announcement.button.buttonLabel : ""
    console.log('working')
    // setonSelectSlider(select);
    setTitle(select.length ? select[0].announcement.title : "")
    desct ? setDesc(JSON.parse(desct)) : setDesc([{type:'paragraph',children:[{text:""}]}]);
    //setLogo(ImageChips)
    // setAction(actionButtont)
    // setLink(buttonLinkt)
    // setLabel(buttonLabelt)
    setDisabled(select.length ? true : false)

    if(Moment(select[0].publishTo).format("YYYY") === "2099") {
     setUnPublishDate('Never');
     setUnPublishTime('');
     console.log("here")
    } else {
     setUnPublishDate(select.length ?
       `${Moment(select[0].publishTo).format("ddd")}, ${Moment(select[0].publishTo).format("MMM D, YYYY")}`
       : unPublishDate);
     setUnPublishTime(select.length ? Moment(select[0].publishTo).format("h:mm A") : unPublishTime);
    }

    setUTCDate(select.length ? Moment(select[0].publishFrom).format('YYYY-MM-DD') : UTCDate);
    setUTCTime(select.length ? Moment(select[0].publishFrom).format("HH:mm:ss") : UTCTime);
    setUTCDateUnPublish(select.length ? Moment(select[0].publishTo).format('YYYY-MM-DD') : UTCDateUnPublish);
    setUTCTimeUnPublish(select.length ? Moment(select[0].publishTo).format("HH:mm:ss") : UTCTimeUnPublish);
    setPublishedDateAndTime(select.length ? select[0].publishFrom : publishedDateAndTime);
    setUnPublishedDateAndTime(select.length ? select[0].publishTo : unPublishedDateAndTime);
    setVisibility(select.length ? select[0].status : visibility)
  }

  const setSelectedEvent = (select) => {
    const desct = select.length ? select[0].event.description : ""
    const ImageChips = select.length ? [
      {
        key: 0,
        label: select?.[0]?.event?.imageDetails?.imageName,
        src: select?.[0]?.event?.imageDetails?.imageUrl,
        name: select?.[0]?.event?.imageDetails?.imageName,
        size: select?.[0]?.event?.imageDetails?.imageSize,
        alt: "",
      }
    ]
      :
      []
    // const actionButtont = select.length ? select[0].event.button.actionButton : false
    // const buttonLinkt = select.length ? select[0].event.button.url : ""
    // const buttonLabelt = select.length ? select[0].event.button.buttonLabel : ""
    console.log('working')
    // setonSelectSlider(select);
    setTitle(select.length ? select[0].event.title : "")
    desct ? setDesc(JSON.parse(desct)) : setDesc([{type:'paragraph',children:[{text:""}]}]);
    setLogo(ImageChips)
    // setAction(false)
    // setLink('')
    // setLabel('')
    setDisabled(select.length ? true : false)
    if(Moment(select[0].publishTo).format("YYYY") === "2099") {
      setUnPublishDate('Never');
      setUnPublishTime('');
      console.log("here")
     } else {
      setUnPublishDate(select.length ?
        `${Moment(select[0].publishTo).format("ddd")}, ${Moment(select[0].publishTo).format("MMM D, YYYY")}`
        : unPublishDate);
      setUnPublishTime(select.length ? Moment(select[0].publishTo).format("h:mm A") : unPublishTime);
     }

    setPublishDate(select.length ?
      `${Moment(select[0].publishFrom).format("ddd")}, ${Moment(select[0].publishFrom).format("MMM D, YYYY")}`
      : publishDate);
    setPublishTime(select.length ? Moment(select[0].publishFrom).format("h:mm A") : publishTime);

    setUTCDate(select.length ? Moment(select[0].publishFrom).format('YYYY-MM-DD') : UTCDate);
    setUTCTime(select.length ? Moment(select[0].publishFrom).format("HH:mm:ss") : UTCTime);
    setUTCDateUnPublish(select.length ? Moment(select[0].publishTo).format('YYYY-MM-DD') : UTCDateUnPublish);
    setUTCTimeUnPublish(select.length ? Moment(select[0].publishTo).format("HH:mm:ss") : UTCTimeUnPublish);
    setPublishedDateAndTime(select.length ? select[0].publishFrom : publishedDateAndTime);
    setUnPublishedDateAndTime(select.length ? select[0].publishTo : unPublishedDateAndTime);
    setVisibility(select.length ? select[0].status : visibility)
  }

  const setSelectedNews = (select) => {
    const desct = select.length ? select[0].article.description : ""
    const ImageChips = select.length ? [
      {
        key: 0,
        label: select[0].article.imageMetaData.imageName,
        src: select[0].article.imageMetaData.imageUrl,
        name: select[0].article.imageMetaData.imageName,
        size: select[0].article.imageMetaData.imageSize,
        alt: "",
      }
    ]
      :
      []
    // const actionButtont = select.length ? select[0].event.button.actionButton : false
    // const buttonLinkt = select.length ? select[0].event.button.url : ""
    // const buttonLabelt = select.length ? select[0].event.button.buttonLabel : ""
    console.log('working')
    // setonSelectSlider(select);
    setTitle(select.length ? select[0].article.title : "")
    desct ? setDesc(JSON.parse(desct)) : setDesc([{type:'paragraph',children:[{text:""}]}]);
    setLogo(ImageChips)
    // setAction(false)
    // setLink('')
    // setLabel('')
    setDisabled(select.length ? true : false)
    if(Moment(select[0].publishTo).format("YYYY") === "2099") {
      setUnPublishDate('Never');
      setUnPublishTime('');
      console.log("here")
     } else {
      setUnPublishDate(select.length ?
        `${Moment(select[0].publishTo).format("ddd")}, ${Moment(select[0].publishTo).format("MMM D, YYYY")}`
        : unPublishDate);
      setUnPublishTime(select.length ? Moment(select[0].publishTo).format("h:mm A") : unPublishTime);
     }
    setPublishDate(select.length ?
      `${Moment(select[0].publishFrom).format("ddd")}, ${Moment(select[0].publishFrom).format("MMM D, YYYY")}`
      : publishDate);
    setPublishTime(select.length ? Moment(select[0].publishFrom).format("h:mm A") : publishTime);
    setUTCDate(select.length ? Moment(select[0].publishFrom).format('YYYY-MM-DD') : UTCDate);
    setUTCTime(select.length ? Moment(select[0].publishFrom).format("HH:mm:ss") : UTCTime);
    setUTCDateUnPublish(select.length ? Moment(select[0].publishTo).format('YYYY-MM-DD') : UTCDateUnPublish);
    setUTCTimeUnPublish(select.length ? Moment(select[0].publishTo).format("HH:mm:ss") : UTCTimeUnPublish);
    setPublishedDateAndTime(select.length ? select[0].publishFrom : publishedDateAndTime);
    setUnPublishedDateAndTime(select.length ? select[0].publishTo : unPublishedDateAndTime);
    setVisibility(select.length ? select[0].status : visibility)
  }

  const setSelectedArticle = (select) => {
    const desct = select.length ? select[0].article.description : ""
    const ImageChips = select.length ? [
      {
        key: 0,
        label: select[0].article.imageMetaData.imageName,
        src: select[0].article.imageMetaData.imageUrl,
        name: select[0].article.imageMetaData.imageName,
        size: select[0].article.imageMetaData.imageSize,
        alt: "",
      }
    ]
      :
      []
    // const actionButtont = select.length ? select[0].event.button.actionButton : false
    // const buttonLinkt = select.length ? select[0].event.button.url : ""
    // const buttonLabelt = select.length ? select[0].event.button.buttonLabel : ""
    console.log('working')
    // setonSelectSlider(select);
    setTitle(select.length ? select[0].article.title : "")
    desct ? setDesc(JSON.parse(desct)) : setDesc([{type:'paragraph',children:[{text:""}]}]);
    setLogo(ImageChips)
    // setAction(false)
    // setLink('')
    // setLabel('')
    setDisabled(select.length ? true : false)
    if(Moment(select?.[0]?.publishTo).format("YYYY") === "2099") {
      setUnPublishDate('Never');
      setUnPublishTime('');
      console.log("here")
     } else {
      setUnPublishDate(select.length ?
        `${Moment(select[0].publishTo).format("ddd")}, ${Moment(select[0].publishTo).format("MMM D, YYYY")}`
        : unPublishDate);
      setUnPublishTime(select.length ? Moment(select[0].publishTo).format("h:mm A") : unPublishTime);
     }
    setPublishDate(select.length ?
      `${Moment(select[0].publishFrom).format("ddd")}, ${Moment(select[0].publishFrom).format("MMM D, YYYY")}`
      : publishDate);
    setPublishTime(select.length ? Moment(select[0].publishFrom).format("h:mm A") : publishTime);
    setUTCDate(select.length ? Moment(select[0].publishFrom).format('YYYY-MM-DD') : UTCDate);
    setUTCTime(select.length ? Moment(select[0].publishFrom).format("HH:mm:ss") : UTCTime);
    setUTCDateUnPublish(select.length ? Moment(select[0].publishTo).format('YYYY-MM-DD') : UTCDateUnPublish);
    setUTCTimeUnPublish(select.length ? Moment(select[0].publishTo).format("HH:mm:ss") : UTCTimeUnPublish);
    setPublishedDateAndTime(select.length ? select[0].publishFrom : publishedDateAndTime);
    setUnPublishedDateAndTime(select.length ? select[0].publishTo : unPublishedDateAndTime);
    setVisibility(select.length ? select[0].status : visibility)
  }

  const setEmptyFields = () => {
    setTitle("")
    setTitleObj([])
    setDesc([{type:'paragraph',children:[{text:""}]}])
    setLogo([])
    // setLink('')
    // setLabel('')
    setDisabled(false)
    setPublishDate('Immediately');
    setPublishTime('');
    setUnPublishDate('Never');
    setUnPublishTime('');
    setUTCDate('');
    setUTCTime('');
    setUTCDateUnPublish('');
    setUTCTimeUnPublish('');
    setPublishedDateAndTime('');
    setUnPublishedDateAndTime('');
    setVisibility("Public")
  }

  const handleChange = (prop) => (event) => {
    if (prop === 'category') {
      console.log(event.target.value, 'selection')
      setCategory(event.target.value); 
      if(event.target.value ===  'Custom'){
        setEmptyFields()
      }
      if(event.target.value ===  'Announcement'){
       const select = allAnnouncement;
       if(!select?.length) {
        setTitleObj([])
        setEmptyFields()
         return
       }
       const title = select?.filter((el) => !sliderTitles.includes(el.announcement.title)).map(text => {
         return { value: text.announcement.title, label: text.announcement.title }
       })
       setTitleObj(title)
       setSelectedAnnouncement(select)
      }
      if(event.target.value ===  'Events'){
        const select = allEvents;
        if(!select?.length) {
          setTitleObj([])
          setEmptyFields()
           return
         }        
        const title = select?.filter((el) => !sliderTitles.includes(el.event.title)).map(text => {
          return { value: text.event.title, label: text.event.title }
        })
        setTitleObj(title)
        setSelectedEvent(select)
       }
       if(event.target.value ===  'News'){
        const selectNews = allNews;
        const select = selectNews.filter(text => text.article.category === 'News')
        if(!select?.length) {
          setTitleObj([])
          setEmptyFields()
           return
         }
        const title = select?.filter((el) => !sliderTitles.includes(el.article.title)).map(text => {
          return { value: text.article.title, label: text.article.title }
        })
        setTitleObj(title)
        setSelectedNews(select)
       }
       if(event.target.value ===  'Articles'){
        const selectNews = allNews;
        const select = selectNews.filter(text => text.article.category === 'Article')
        if(!select?.length) {
          setTitleObj([])
          setEmptyFields()
           return
         }
        const title = select?.filter((el) => !sliderTitles.includes(el.article.title)).map(text => {
          return { value: text.article.title, label: text.article.title }
        })
        setTitleObj(title)
        setSelectedArticle(select)
       }
    } else if (prop === 'title') {
      console.log("t", event.target.value)
      setTitle(event.target.value);
    } else if (prop === 'Desc') {
      setDesc(event.target.value);
    } else if (prop === 'Link') {
      setLink(event.target.value);
    } else if (prop === 'Label') {
      setLabel(event.target.value);
    } else if (prop === 'selectTitle') {
      console.log("selectTitle", event.target.value, category)

      if(category ===  'Announcement'){
        const select = allAnnouncement;
        const ann = select?.filter((el) => !sliderTitles.includes(el.announcement.title) && el.announcement.title === event.target.value).map(text => {
          return { ...text }
        })
        setSelectedAnnouncement(ann)
       }
       if(category ===  'Events'){
         const select = allEvents;      
         const title = select?.filter((el) => !sliderTitles.includes(el.event.title) && el.event.title === event.target.value).map(text => {
           return { ...text }
         })
         setSelectedEvent(title)
        }
        if(category ===  'News'){
         const selectNews = allNews;
         const select = selectNews.filter(text => text.article.category === 'News')
         const title = select?.filter((el) => !sliderTitles.includes(el.article.title)&& el.article.title === event.target.value).map(text => {
           return { ...text }
         })
         setSelectedNews(title)
        }
        if(category ===  'Articles'){
         const selectNews = allNews;
         const select = selectNews.filter(text => text.article.category === 'Article')
         const title = select?.filter((el) => !sliderTitles.includes(el.article.title) && el.article.title === event.target.value).map(text => {
           return { ...text }
         })
         setSelectedArticle(title)
        }
    }
  };
  // const handleChange = (prop) => (event) => {
  //   if (prop === 'category') {
  //     setCategory(event.target.value);
  //   } else if (prop === 'title') {
  //     setTitle(event.target.value);
  //   } else if (prop === 'Desc') {
  //     setDesc(event.target.value);
  //   } else if (prop === 'Link') {
  //     setLink(event.target.value);
  //   } else if (prop === 'Label') {
  //     setLabel(event.target.value);
  //   }
  // };
  const object = [
    {
      value: "News",
      label: "News",
    },
    {
      value: "Articles",
      label: "Articles",
    },
    {
      value: "Events",
      label: "Events",
    },
    {
      value: "Announcement",
      label: "Announcement",
    },
    {
      value: "Custom",
      label: "Custom",
    },
  ];
  const [value, setValue] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);
  const onChange = (e) => {
    setValue(e);
  };
  const onInputClick = () => {
    console.log("input clicked");
  };
  const onChipInputClick = () => {
    console.log("input chip clicked");
    setOpenLogoDrawer(true)

  };
  const deleteChip = () => {
    console.log("chip deleted");
  };
  const styles = {
    stepper: {
      marginTop: 0,
      padding: "0px 0px",
      borderRadius: "30px",
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    select: {
      margin: "14px 0",
      minWidth: 120,
      height: 66,
      width: 120,
      border: "#D3D8DA",
      width: "100%",
    },
    formBody: {
      background: "#fff",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A",
    },
    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
    },
    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
    BaseTextField: {
      width: "100%",
    },
    editor: {
      padding: 16,
    },
  };
  const style = {
    width: '100%',
  };

  function onDescriptionStateChange(editorState) {
    let sum = 0;
    editorState.map((st) => {
      if (st.type === "bulleted-list" || st.type === "numbered-list") {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    setDesc(editorState)
    // setTextValuelength(sum)
    // if (textValueLength > 0 && (sum > textValueLength || sum < textValueLength)) {
    //   props.checkChanges(true)
    //   console.log("textValueLength", textValueLength, sum)
    // }
  };

  return (
    
    <MasterLayoutMiddle
      isForm={true}
      formData={
        <>
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            dateError &&
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
              <AlertAction
                alert={publish_date_before_unpublish}
                alertType={"error"}
                onClick={() => setDateError(false)}
              />
            </div>
          }
          <div>
            <BaseStepperExtended
              title="Edit Slider"
              getSteps={getSteps}
              alternativeLabel={true}
              handleBackPreviousPage={() => props.history.push({ pathname: '/website/home-page', state: '0' })}
              handleBack={() => handleBack()}
              handleNext={() => handleNext()}
              nonLinear={true}
              orientation={"horizontal"}
              btnStyles={styles.stepper}
              onStepClick = {(obj) => setActiveStep(obj - 1)}
              activeStep={activeStep}
            />
            {activeStep === 0 && (
              <Grid container spacing={3}>
                <Grid item xs={12}><h2 className="sub_title">Basic Information</h2></Grid>
                <Grid item xs={12}>
                  <div className="field_box">
                  <BaseSelect
                    value={category}
                    onChange={handleChange("category")}
                    object={object}
                    style={styles.select}
                    error={errorCategory}
                    label="Category"
                  />

                    {/* <BaseTextField
                      value={category}
                      InputProps={false}
                      phone={false}
                      search={false}
                      style={styles.select}
                      color="primary"
                      disabled={false}
                      helperText="Announcements are managed at home screen"
                      label="Select Announcement"
                    /> */}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="field_box">
                  {(category === "News" || category === "Articles" || category === "Events" || category === "Announcement")
                    ?
                    <BaseSelect
                      value={Title}
                      onChange={handleChange("selectTitle")}
                      object={titleObj}
                      style={styles.select}
                      error={errorCategory || titleObj.length ? false : true}
                      helperText={titleObj.length ? "Enter a title for the slider" : <p>{category} not found. For create new {category} click here: <span onClick={() => props.history.push('')} className='addLinkTag'> Add {category} </span></p>}
                      label="Slider Title"
                      // error={errorService || titleObj.length ? false : true}
                    />
                    :
                    <BaseTextField
                      value={Title}
                      onChange={handleChange("title")}
                      InputProps={false}
                      count={60}
                      style={styles.BaseTextField}
                      color="primary"
                      error={errorTitle}
                      helperText="Enter a title for the slider"
                      label="Slider Title"
                    />
                  }

                    {/* <BaseTextField
                      value={Title}
                      onChange={handleChange("title")}
                      InputProps={false}
                      count={60}
                      disabled={false}
                      style={styles.BaseTextField}
                      color="primary"
                      error={errorTitle}
                      helperText="Enter a title for the slider"
                      label="Slider Title"
                    /> */}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="field_box">
                    {/* <BaseTextField
                      value={desc}
                      onChange={handleChange("Desc")}
                      InputProps={false}
                      count={100}
                      disabled={false}
                      style={styles.BaseTextField}
                      color="primary"
                      error={errorDesc}
                      helperText="Description to show on the banner"
                      label="Short Description"
                    /> */}
                    <TextEditor
                      label={"Short Description"}
                      value={desc}
                      maxCharCount={100}
                      helperText="Description to show on the banner"
                      onChange={(e) => onDescriptionStateChange(e)}
                      error={errorDesc}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <MediaUpload
                    addMediaItem = {addLogo}
                    label = "Banner Image"
                    buttonName = "ADD IMAGE"
                    typeOfMedia='/images'
                    selectedMedia = {{ logoUrl: logo?.[0]?.src ?? "", logoName: logo?.[0]?.name ?? ""}} 

                  />
                  {/* <div className="field_box">
                    <BaseImgChip
                      chipData={logo}
                      onInputClick={onInputClick}
                      onChipInputClick={onChipInputClick}
                      deleteChip={deleteChip}
                      label="Banner Image"
                      error={errorlogo}
                      helperText="One main image required to display"
                    />
                  </div> */}
                </Grid>
                <Grid item xs={12}>
                  <div className="border_top_bottm">
                    <FormSwitch
                      headline='Add Action Button'
                      body="Add a button for user to click and navigate to a specific page/website"
                      values={Action}
                      handleSwitchChange={() => setAction(!Action)}
                    />
                  </div>
                </Grid>
                {
                  Action ?
                    <>
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={Label}
                            onChange={handleChange("Label")}
                            InputProps={false}
                            count={100}
                            disabled={false}
                            style={styles.BaseTextField}
                            color="primary"
                           // helperText="Helper text"
                            label="Button Label"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={Link}
                            onChange={handleChange("Link")}
                            InputProps={false}
                            style={styles.BaseTextField}
                            disabled={false}
                            color="primary"
                           // helperText="Helper text"
                            label="Add Link"
                          />
                        </div>
                      </Grid>
                    </>
                    :
                    null
                }
                <Grid item xs={12}>
                  <div className="form_button">
                    <span className="btnCencel">
                      <BaseButton
                        text="CANCEL"
                        onClick={() => props.history.push({ pathname: '/website/home-page', state: '0' })}
                      />
                    </span>
                    <BaseButton text="CONTINUE" onClick={() => handleNext()} />
                  </div>
                </Grid>
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <div className="field_box">
                    <FormPublishUnpublish
                      visibility={visibility}
                      setVisibility={setVisibility}
                      publish={publish}
                      unPublish={unPublish}
                      publishDate={publishDate}
                      publishTime={publishTime}
                      setPublishDate={setPublishDate}
                      setPublishTime={setPublishTime}
                      getPublishDate={getPublishDate}
                      getPublishTime={getPublishTime}
                      unPublishDate={unPublishDate}
                      unPublishTime={unPublishTime}
                      setUnPublishDate={setUnPublishDate}
                      setUnPublishTime={setUnPublishTime}
                      getUnPublishDate={getUnPublishDate}
                      getUnPublishTime={getUnPublishTime}
                      onVisibilitySave={onVisibilitySave}
                      onVisibilityCancel={onVisibilityCancel}
                      onPublishSave={onPublishSave}
                      onPublishCancel={onPublishCancel}
                      onUnPublishSave={onUnPublishSave}
                      onUnPublishCancel={onUnPublishCancel}
                      onSave={onSave}
                      onBack={onBack}
                      unPublishTimestamp={unPublishTimestamp}
                      minDateUnpublish={publishDate}
                      disabledSave={validUnPublish}
                      disabledSaveP={validPublish}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
            <DrawerMain
              open={openLogoDrawer}
              openHandler={() => setOpenLogoDrawer(false)
              }
              title="Select Image"
              onCancel={() => setOpenLogoDrawer(false)
              }
              onSave={() => addLogo()}
            >
              <List className="img-details" style={{ paddingLeft: 0 }}>
                {logo.map((file, index) => (
                  <ListItem key={file.name} style={{ paddingLeft: 0 }}>
                    <ListItemAvatar>
                      <Avatar
                        variant="square"
                        alt={"preview"}
                        src={
                          logo.length > 0
                            ? logo[0].preview || logo[0].src
                            : file.preview
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        (file?.name && file?.name?.slice(0, 20)) ||
                        file?.src?.slice(0, 20)
                      }
                      secondary={file.size && formatBytes(file.size)}
                    />
                    <ListItemSecondaryAction style={{ paddingLeft: 20 }}>
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        style={{ padding: "0px" }}
                        onClick={() => handleLogoRemove(index)}
                      >
                        <DeleteForeverIcon fontSize="inherit" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              {logo.length === 0 && (
                <>
                  <div style={{ marginBottom: 20 }} className="img-uploader">
                    <BaseFileSelect
                      selectedFiles={(file) => logoUpload(file)}
                    />
                  </div>
                </>
              )}
              {logo.length > 0 && logo[0]?.src?.slice(0, 5) !== "https" && (
                <img src={logo.length > 0 && logo[0].src} width='100%' />
              )}
              <br />
            </DrawerMain>
          </div>
        </>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
const mapStateToProps = (state) => ({
  alert: state.alert,
});
const mapDispatchToProps = (dispatch) => ({
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditSlider);
