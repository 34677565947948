import React, { useImperativeHandle, forwardRef, useState, useEffect } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
  BaseSwitch,
  DonationCardMidAvatar,
  BaseDivider,
  BaseRadio,
  AlertAction,
  SimpleAccordian,
  FormSwitch,
  BaseSelect,
} from "@dclab/dc-react-ui-kit";
import { makeStyles, Popover, TextField, Typography } from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import { saveAsDraft } from './Apis'
import './style.css';
import messages_content from '../../utils/messages.json';
import Grid from '@material-ui/core/Grid';
const {
  token_expired,
  Your_changes_has_been_saved_as_draft,
  Your_changes_has_been_published
} = messages_content;
const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));
function useOutsideAlerter(ref, setToolTip) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setToolTip(0)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
const Donations = forwardRef((props, ref) => {
  const orgIntegration = props?.embed_data?.enabledIntegrations
  const embedformDraft = orgIntegration?.deenfund
  const embedform_draft = props?.embed_data?.enabledIntegrations?.deenfund?.embedFormDraft?.[0]
  const classes = useStyles();
  const [card1, setCard1] = React.useState(true);
  const [card2, setCard2] = React.useState(false);
  const [showLock, setShowLock] = React.useState(true);
  const [transactionFee, settransactionFee] = React.useState(false);
  const [famount, setFAmount] = React.useState(500);
  const [samount, setSAmount] = React.useState(300);
  const [tamount, setTAmount] = React.useState(100);
  const [FourthAmount, setFourthAmount] = React.useState(50);
  const [customAmount, setCustomAmount] = React.useState('1');
  const [minimumDonation, setMinimumDonation] = React.useState(false);
  const [donationType, setDonationType] = React.useState('');
  const [showToolTop, setToolTip] = React.useState(0);
  const [defaultAmount, setDefaultAmount] = React.useState(3);
  const [Temp, setTemp] = useState('')
  const [error, seterror] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [success, setsuccess] = useState(false);
  const wrapperRef = React.useRef(null);
  const [minAmountSwitch, setminAmountSwitch] = useState(false)
  useOutsideAlerter(wrapperRef, setToolTip);

  useEffect(() => {
    const embedform_draft = props?.embed_data?.enabledIntegrations?.deenfund?.embedFormDraft?.[0]
    const donation_form_type = embedform_draft?.donationFormType
    const preselect_amounts = embedform_draft?.preselectAmounts
    console.log("preselect_amounts", preselect_amounts);
    const donation_minimum_amount = embedform_draft?.donationMinimumAmount
    const donation_minimum_amount_enabled = embedform_draft?.donationMinimumAmountEnabled ? true : false
    const processing_fee_enabled = embedform_draft?.processingFeeEnabled === true ? true : false;
    setTemp(embedform_draft)
    donation_form_type && setDonationType(donation_form_type);
    donation_form_type === 'Single' && setCard1(true)
    preselect_amounts?.[0]?.amount && setFAmount(preselect_amounts[0].amount)
    preselect_amounts?.[1]?.amount && setSAmount(preselect_amounts[1].amount)
    preselect_amounts?.[2]?.amount && setTAmount(preselect_amounts[2].amount)
    preselect_amounts?.[3]?.amount && setFourthAmount(preselect_amounts[3].amount)
    preselect_amounts?.[0]?.setDefault && setDefaultAmount(1)
    preselect_amounts?.[1]?.setDefault && setDefaultAmount(2)
    preselect_amounts?.[2]?.setDefault && setDefaultAmount(3)
    preselect_amounts?.[3]?.setDefault && setDefaultAmount(4)
    setminAmountSwitch(donation_minimum_amount_enabled)
    // donation_minimum_amount && setMinimumDonation(true)
    // if(donation_minimum_amount_enabled || donation_minimum_amount > 1) {
    //   setMinimumDonation(false)
    // }
    console.log("donation_minimum_amount", donation_minimum_amount)

    donation_minimum_amount && donation_minimum_amount === 'default' ? setCustomAmount(1) : setCustomAmount(donation_minimum_amount)
    settransactionFee(processing_fee_enabled)
  }, [props.embed_data])

  const handleChange = (prop) => (event) => {
    console.log("processing_fee_enabled", prop)

    if (prop === 'Enabled') {
      settransactionFee(true);
      props.checkChanges(true)
    } else {
      settransactionFee(false)
      props.checkChanges(true)
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true)
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpen(false)
  };
  const cardFunction = (props) => {
    if (props === "setCard1") {
      setCard1(true);
      setCard2(false);
      setShowLock(true);
      setDonationType('Single')
    }
  };
  useImperativeHandle(ref, () => ({
    async DonationsDraft(param) {
      const orgId = localStorage.getItem('orgID')
      const token = localStorage.getItem('authToken')
      const formdata = {
        enabledIntegrations: formData(param),
      }
      param === 'publish' ? publish(orgId, formdata, token) : draftChanges(orgId, formdata, token)
    }
  }));
  function formData(param) {
    if (param === 'publish') {
      return (
        {
          ...orgIntegration,
          deenfund: {
            ...embedformDraft,
            embedFormDraft: [
              {
                ...embedformDraft.embedFormDraft[0],
                processingFeeEnabled: transactionFee,
                preselectAmounts: [
                  {
                    allowToCustomizeAmount: famount !== 500 ? true : false,
                    amount: famount,
                    setDefault: defaultAmount === 1 ? true : false,
                  },
                  {
                    allowToCustomizeAmount: samount !== 300 ? true : false,
                    amount: samount,
                    setDefault: defaultAmount === 2 ? true : false,
                  },
                  {
                    allowToCustomizeAmount: tamount !== 100 ? true : false,
                    amount: tamount,
                    setDefault: defaultAmount === 3 ? true : false,
                  },
                  {
                    allowToCustomizeAmount: famount !== 50 ? true : false,
                    amount: FourthAmount,
                    setDefault: defaultAmount === 4 ? true : false,
                  },
                ],
                donationFormType: donationType,
                paymentMethod: ['Credit card'],//release 2 will have two donations values
                donationMinimumAmount: minAmountSwitch ? customAmount : '1',
                donationMinimumAmountEnabled: minAmountSwitch,
                changes: true,
              }
            ],
            embedForm: [
              {
                ...embedformDraft.embedForm[0],
                processingFeeEnabled: transactionFee,
                preselectAmounts: [
                  {
                    allowToCustomizeAmount: famount !== 500 ? true : false,
                    amount: famount,
                    setDefault: defaultAmount === 1 ? true : false,
                  },
                  {
                    allowToCustomizeAmount: samount !== 300 ? true : false,
                    amount: samount,
                    setDefault: defaultAmount === 2 ? true : false,
                  },
                  {
                    allowToCustomizeAmount: tamount !== 100 ? true : false,
                    amount: tamount,
                    setDefault: defaultAmount === 3 ? true : false,
                  },
                  {
                    allowToCustomizeAmount: famount !== 50 ? true : false,
                    amount: FourthAmount,
                    setDefault: defaultAmount === 4 ? true : false,
                  },
                ],
                donationFormType: donationType,
                paymentMethod: ['Credit card'],//release 2 will have two donations values
                donationMinimumAmount: minAmountSwitch ? customAmount : '1',
                donationMinimumAmountEnabled: minAmountSwitch,
              }
            ],
          }
        }
      )
    } else {
      return (
        {
          ...orgIntegration,
          deenfund: {
            ...embedformDraft,
            embedFormDraft: [
              {
                ...embedformDraft.embedFormDraft[0],
                processingFeeEnabled: transactionFee,
                preselectAmounts: [
                  {
                    allowToCustomizeAmount: famount !== 500 ? true : false,
                    amount: famount,
                    setDefault: defaultAmount === 1 ? true : false,
                  },
                  {
                    allowToCustomizeAmount: samount !== 300 ? true : false,
                    amount: samount,
                    setDefault: defaultAmount === 2 ? true : false,
                  },
                  {
                    allowToCustomizeAmount: tamount !== 100 ? true : false,
                    amount: tamount,
                    setDefault: defaultAmount === 3 ? true : false,
                  },
                  {
                    allowToCustomizeAmount: famount !== 50 ? true : false,
                    amount: FourthAmount,
                    setDefault: defaultAmount === 4 ? true : false,
                  },
                ],
                donationFormType: donationType,
                paymentMethod: ['Credit card'],//release 2 will have two donations values
                donationMinimumAmount: minAmountSwitch ? customAmount : '1',
                donationMinimumAmountEnabled: minAmountSwitch,
                changes: true,
              }
            ],
            embedForm: [
              {
                ...embedformDraft.embedForm[0],
                processingFeeEnabled: transactionFee,
                preselectAmounts: [
                  {
                    allowToCustomizeAmount: famount !== 500 ? true : false,
                    amount: famount,
                    setDefault: defaultAmount === 1 ? true : false,
                  },
                  {
                    allowToCustomizeAmount: samount !== 300 ? true : false,
                    amount: samount,
                    setDefault: defaultAmount === 2 ? true : false,
                  },
                  {
                    allowToCustomizeAmount: tamount !== 100 ? true : false,
                    amount: tamount,
                    setDefault: defaultAmount === 3 ? true : false,
                  },
                  {
                    allowToCustomizeAmount: famount !== 50 ? true : false,
                    amount: FourthAmount,
                    setDefault: defaultAmount === 4 ? true : false,
                  },
                ],
                donationFormType: donationType,
                paymentMethod: ['Credit card'],//release 2 will have two donations values
                donationMinimumAmount: minAmountSwitch ? customAmount : '1',
                donationMinimumAmountEnabled: minAmountSwitch,
              }
            ]
          }
        }
      )
      // return [
      //   {
      //     donationFormType: donationType,
      //     preselectAmounts: [
      //       {
      //         allowToCustomizeAmount: famount !== 500 ? true : false,
      //         amount: famount,
      //         setDefault: defaultAmount === 1 ? true : false,
      //       },
      //       {
      //         allowToCustomizeAmount: samount !== 300 ? true : false,
      //         amount: samount,
      //         setDefault: defaultAmount === 2 ? true : false,
      //       },
      //       {
      //         allowToCustomizeAmount: tamount !== 100 ? true : false,
      //         amount: tamount,
      //         setDefault: defaultAmount === 3 ? true : false,
      //       },
      //       {
      //         allowToCustomizeAmount: famount !== 50 ? true : false,
      //         amount: FourthAmount,
      //         setDefault: defaultAmount === 4 ? true : false,
      //       },
      //     ],
      //     donationMinimumAmount: minimumDonation ? customAmount : 'default',
      //     processingFeeEnabled: transactionFee,
      //     paymentMethod: ['Credit card'],//release 2 will have two donations values
      //     donationCategories: embedform_draft?.donation_categories,
      //     formTitle: embedform_draft?.form_title,
      //     formThemeColor: embedform_draft?.form_theme_color,
      //     formLink: embedform_draft?.form_link,
      //     formHeaderButton: embedform_draft?.form_header_button,
      //     setup: {
      //       footer: embedform_draft?.setup?.footer,
      //       rightSide: embedform_draft?.setup?.right_side,
      //       imageOrVideo: embedform_draft?.setup?.image_or_video,
      //       content: embedform_draft?.setup?.content,
      //       faqs: embedform_draft?.setup?.faqs
      //     },
      //     embedButton: embedform_draft?.embed_button,
      //     websiteName: embedform_draft?.website_name,
      //     changes: true
      //   }
      // ]
    }
  }
  async function publish(orgId, formdata, token) {
    // formdata.deenfund.embedForm = formData()
    // formdata.deenfund.embedFormDraft[0].changes = false
    props.publishChanges(false)
    try {
      await saveAsDraft(orgId, formdata, token)
      setshowAlert(true)
      setsuccess('Publish')
      props.hideAlert()
      props.checkChanges(false)
      props.getnewdata(true)
      setTimeout(() => {
        setshowAlert(false)
        setsuccess(false)
      }, 8000)
    }
    catch (error) {
      seterror(error.response && error.response.data.message)
      props.hideAlert()
      setTimeout(() => {
        seterror(false)
      }, 8000)
    }
  }
  async function draftChanges(orgId, formdata, token) {
    try {
      await saveAsDraft(orgId, formdata, token)
      setshowAlert(true)
      setsuccess(true)
      props.hideAlert()
      props.checkChanges(false)
      props.getnewdata(true)
      setTimeout(() => {
        setshowAlert(false)
        setsuccess(false)
      }, 8000)
    }
    catch (error) {
      seterror(error.response && error.response.data.message)
      props.hideAlert()
      setTimeout(() => {
        seterror(false)
      }, 8000)
    }
  }
  function onselect(value, checker) {
    console.log("onselect", value, checker)
    const regex = /^[0-9]*$/
    if (checker === 'first' && regex.test(value)) {
      setFAmount(value)
      props.checkChanges(true)
    } else if (checker === 'second' && regex.test(value)) {
      setSAmount(value)
      props.checkChanges(true)
    } else if (checker === 'third' && regex.test(value)) {
      setTAmount(value)
      props.checkChanges(true)
    } else if (checker === 'fourth' && regex.test(value)) {
      setFourthAmount(value)
      props.checkChanges(true)
    }
  }
  function onChangeHandler(e) {
    setCustomAmount(e.target.value)
    props.checkChanges(true)
  }




  const [selectedZone, setselectedZone] = React.useState(0);

  const saveChanges = (accordian) => {
    console.log("data", accordian)
    setselectedZone(0)
  }
  const onAccordianClick = (panel) => {
    console.log("onAccordianClick", panel)
    if (panel === selectedZone) {
      setselectedZone(0)
    } else {
      setselectedZone(panel)
    }
  }

  const AccordionDetails = {
    //top: "0px"
    top: "0",
    left: "360px",
  };


  const [values, setValues] = React.useState(false);

  const handleSwitchChange = (event) => {
    setValues(event)
    console.log(event, 'swuitch')
    // setValues();
  };


  const handleChangeSelect = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const object = [
    {
      value: "Monthly",
      label: "Monthly",
    },
    {
      value: "One-time",
      label: "One-time",
    },
  ];
  const styles = {
    select: {
      margin: 8,
      minWidth: 120,
      height: 66,
      width: 120,
      margin: 8,
      border: "#D3D8DA",
      width: "100%",
    }
  };



  return (
    <>
      {
        error && error === "Invalid token" && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={token_expired}
              alertType={"error"}
              onClick={() => seterror(false)}
            />
          </div>
        )
      }
      {
        showAlert && error && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={
                error === "Invalid token"
                  ? token_expired
                  : error
              }
              alertType={"error"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        )
      }
      {
        showAlert && success && success === 'Publish' ? (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={Your_changes_has_been_published}
              alertType={"success"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        ) : showAlert && success && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={Your_changes_has_been_saved_as_draft}
              alertType={"success"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        )
      }
      <div className="main-form-container">
        <>
          {!props?.embed_data && <div className='skeletons'>
            <SkeletonTheme color="#f7f8fa">
              <Skeleton height={140} width={570} />
              <Skeleton height={300} width={570} />
              <Skeleton height={90} width={570} />
            </SkeletonTheme>
          </div>}
          {props?.embed_data && <section>
            <div className="emb_card">
              <div className="card_title">
                <h1 className="df-form-title">Form Type</h1>
              </div>
              <div className="df-donation-form-cards">
                <DonationCardMidAvatar
                  cardCheck={card1}
                  cardFunction={() => cardFunction("setCard1")}
                  header={"Single donation"}
                />
                <div
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  style={{ display: 'flex' }}
                >

                  <DonationCardMidAvatar
                    cardCheck={card2}
                    cardFunction={() => cardFunction("setCard2")}
                    header={"Multiple Donations"}
                    showLockIcon={showLock}
                    disabled={open}
                  />

                  <Popover
                    id="mouse-over-popover"
                    className={classes.popover}
                    classes={{
                      paper: classes.paper,
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    style={{ left: -120 }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Typography style={{ width: '330px', padding: '10px' }}>
                      Upgrade your plan to receive multiple donation at once from a single donor. This can help to reduce transaction fees.
                      <div className="df-donation-pop-btn">
                        <p className="df-copy-text" style={{ marginBottom: 0, cursor: "pointer" }} >COMING SOON</p>
                      </div>
                    </Typography>
                  </Popover>
                </div>
              </div>
            </div>
            <div className="emb_card">
              <div className="card_title">
                <h1 className="df-form-title">Donation Amounts</h1>
              </div>
              <p className="df-form-para-amounts">Preselect amounts</p>
              <div className="df-form-amount-box" ref={wrapperRef}>
                <div className={`preselect-amount ${defaultAmount === 1 && 'active'}`}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    onClick={() => setToolTip(1)}
                    value={famount}
                    onChange={(e) => onselect(e.target.value, 'first')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                  {showToolTop === 1 && <span onClick={() => { setDefaultAmount(1); setToolTip(0); props.checkChanges(true) }} className="tooltiptext">SET DEFAULT</span>}
                </div>
                <div className={`preselect-amount ${defaultAmount === 2 && 'active'}`}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    onClick={() => setToolTip(2)}
                    value={samount}
                    onChange={(e) => onselect(e.target.value, 'second')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                  {showToolTop === 2 && <span onClick={() => { setDefaultAmount(2); setToolTip(0); props.checkChanges(true) }} className="tooltiptext">SET DEFAULT</span>}
                </div>
                <div className={`preselect-amount ${defaultAmount === 3 && 'active'}`}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    value={tamount}
                    onClick={() => setToolTip(3)}
                    onChange={(e) => onselect(e.target.value, 'third')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                  {showToolTop === 3 && <span onClick={() => { setDefaultAmount(3); setToolTip(0); props.checkChanges(true) }} className="tooltiptext">SET DEFAULT</span>}
                </div>
                <div className={`preselect-amount ${defaultAmount === 4 && 'active'}`}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    onClick={() => setToolTip(4)}
                    value={FourthAmount}
                    onChange={(e) => onselect(e.target.value, 'fourth')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          $
                        </InputAdornment>
                      ),
                    }}
                  />
                  {showToolTop === 4 && <span onClick={() => { setDefaultAmount(4); setToolTip(0); props.checkChanges(true) }} className="tooltiptext">SET DEFAULT</span>}
                </div>
              </div>
              <div className="df-divider">
                <BaseDivider width={525} />
              </div>
              <div className="df-homepage-btn">
                <h1 className="df-form-link">Minimum donation</h1>
                <div className="df-switch">
                  <BaseSwitch
                    checked={minAmountSwitch}
                    onChange={() => {
                      setMinimumDonation(!minimumDonation);
                      setminAmountSwitch(!minAmountSwitch)
                      props.checkChanges(true)
                    }}
                  />
                </div>
              </div>
              <p className="df-form-subtitle-para">Set a min donation amount for your donors</p>
              {minAmountSwitch && <div className="min_donaton" style={{ position: "relative" }}>
                <input type='text' className='df-price input-number' style={{ fontSize: '16px', border: '1px solid #F7F8FA', paddingLeft: 30, borderRadius: "4px" }} value={customAmount}
                  onChange={(e) => { if (e.target.value.length <= 10 && /^[0-9]*$/.test(e.target.value)) { onChangeHandler(e) } }}
                  onBlur={() => !customAmount && setCustomAmount('1')}
                />
                <p style={{ position: 'absolute', left: '15px', top: '0px', }}>$</p>
              </div>}
            </div>
            <div className="emb_card">
              <div className="card_title">
                <h1 className="df-form-title">Transaction Fees</h1>
                <p className="df-form-para">
                  Give donors the option to cover transaction fees.
                </p>
              </div>
              <div className="df-form-link-radio">
                <div>
                  <BaseRadio
                    label={"Enabled"}
                    checked={transactionFee === true ? true : false}
                    onChange={handleChange("Enabled")}
                  />
                </div>
                <div style={{ marginLeft: 60 }}>
                  <BaseRadio
                    label={"Disabled"}
                    checked={transactionFee === false ? true : false}
                    onChange={handleChange("Disabled")}
                  />
                </div>
              </div>
            </div>

            {/* <div style={{ marginTop: "10px", }}>
              <SimpleAccordian
                Title="DONATION FREQUENCY"
                text="One-time, Monthly"
                color="#818E94"
                fontSize="12px"
                fontWeight="400"
                tooltip="Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups."
                TootltipStatus={false}
                TitleButtonLabel="EDIT"
                AccordionDetails={AccordionDetails}
                panel={1}
                open={selectedZone === 1 ? true : false}
                onAccordianClick={onAccordianClick}
                id="1"
                areacontrol="1"
                accordianName="middlerCard"
                saveChanges={saveChanges}
                Cencel="CENCEL"
                Save="SAVE NOW"
                content={
                  <div className="AccordionDetailConainer">

                    <Grid container spacing={3}>

                      <Grid item xs={12}>
                        <div className="FormSwitchManContainer">
                          <FormSwitch
                            headline='One-time'
                            //body="Speaker is available for future events"
                            values={values}
                            handleSwitchChange={(e) => handleSwitchChange(e)}
                          />
                        </div>
                        <div className="FormSwitchManContainer">
                          <FormSwitch
                            headline='Weekly'
                            //body="Speaker is available for future events"
                            values={values}
                            handleSwitchChange={(e) => handleSwitchChange(e)}
                          />
                        </div>
                        <div className="FormSwitchManContainer">
                          <FormSwitch
                            headline='Monthly'
                            //body="Speaker is available for future events"
                            values={values}
                            handleSwitchChange={(e) => handleSwitchChange(e)}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <BaseSelect
                          label="Select Default Frequency"
                          value={values.age}
                          onChange={handleChangeSelect("age")}
                          object={object}
                          style={styles.select}
                          helperText="The default frequency will be pre-selected"
                        />

                      </Grid>




                    </Grid>
                  </div>
                }

              />

              <SimpleAccordian
                Title="ANONYMOUS DONATION"
                text="Enabled"
                color="#818E94"
                fontSize="12px"
                fontWeight="400"
                tooltip="Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups."
                TootltipStatus={false}
                TitleButtonLabel="EDIT"
                AccordionDetails={AccordionDetails}
                panel={2}
                open={selectedZone === 2 ? true : false}
                onAccordianClick={onAccordianClick}
                id="2"
                areacontrol="2"
                accordianName="middlerCard"
                saveChanges={saveChanges}
                Cencel="CENCEL"
                Save="SAVE NOW"
                content={
                  <div className="DonationMethodFieldContainerTop">

                    <Grid container spacing={3}>

                      <Grid item xs={12}>
                        
                          <div className="card_title">
                            <p className="df-form-para">
                            Give the donor the option to hide their name from public
                            </p>
                          </div>
                          <div className="df-form-link-radio">
                            <div>
                              <BaseRadio
                                label={"Enabled"}
                                checked={transactionFee === true ? true : false}
                                onChange={handleChange("Enabled")}
                              />
                            </div>
                            <div style={{ marginLeft: 60 }}>
                              <BaseRadio
                                label={"Disabled"}
                                checked={transactionFee === false ? true : false}
                                onChange={handleChange("Disabled")}
                              />
                            </div>
                          </div>
                        
                      </Grid>

                    </Grid>
                  </div>
                }

              />
            </div> */}



          </section>}
        </>
      </div>
    </>
  );
})

export default Donations;
