import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  SimpleAppBar,
  BasePaper,
} from "@dclab/dc-react-ui-kit";
import { Switch, Route } from "react-router-dom";
import MainLogo from "../../assets/img/DC_Logo.png";

import AppCheckout from "./AppCheckout";
import PrivateRoute from '../../components/PrivateRoute';


const MoreAppCheckOut = (props) => {

  const logout = () => {
    localStorage.setItem("user", false);
    localStorage.setItem("authToken", "");
    props.history.replace("/signin");
    localStorage.clear();
  };


  const content = (
    <React.Fragment>
      <SimpleAppBar
        src={MainLogo}
        name={localStorage.getItem("name") || (props?.signup?.fullName ?? '')}
        role={localStorage.getItem("role")}
        onClickLogout={() => logout()}
        style={{ marginTop: 80, }}
        showImg={localStorage.getItem("image") ? true : false}
        img = {localStorage.getItem("image") ?? ''}
      />

      <Switch>
        <PrivateRoute exact path="/checkout" component={AppCheckout} />
      </Switch>
    </React.Fragment>
  );
  
  return (
    <>
      <BasePaper
        elevation={0}
        context={content}
        paperStyle={styles.paperStyle}
      />
    </>
  );
};

const styles = {

};
const mapStateToProps = (state) => {
  return {
    pages: state.pages,
  };
};

export default connect(mapStateToProps, 0)(MoreAppCheckOut);
