import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { SimpleTable, MasterLayoutMiddle, FooterInner } from "@dclab/dc-react-ui-kit";
import '../style.css';
import { getOrganizationPlan } from "../../UserProfile/Apis/index"
import moment from 'moment'
import { getAllCustomerInvoices } from "../api/index.js"
import CircularProgress from '@material-ui/core/CircularProgress';

function PaymenthistoryTable(props) {
  const [payments, setpayments] = useState([])

  const token = localStorage.getItem("authToken")
  const orgId = localStorage.getItem("orgID")
  const [loading, setloading] = useState(true)

  useEffect(() => {
    const getData = async () => {
      const res = await getOrganizationPlan(orgId, token);

      if (res?.stripeData?.length) {
        const customerIds = res.stripeData.map((el) => el.customerId);
        if (customerIds?.length) {
          const invoices = await getAllCustomerInvoices([...new Set(customerIds)]);

          console.log(customerIds, invoices, "invoices")
          setpayments(invoices?.data?.body?.data ?? [])
        }
      }
      setloading(false)
    }
    getData()
  }, [])

  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const capitalizeFLetter = (val) => {
    return val ? `${val[0].toUpperCase() + val.slice(1)}` : "";
  }

  const checkFrequency = (status) => {
    status = status ? status.toLowerCase() : "";
    if (status === 'month') {
      return "Monthly"
    } else if (status === 'year') {
      return "Annually"
    } else {
      return capitalizeFLetter(status)
    }
  }

  const checkStatusName = (status) => {
    status = status ? status.toLowerCase() : "";
    if (status === 'open') {
      return "Due"
    } else if (status === 'paid') {
      return "Paid"
    } else if (status === 'void') {
      return "Failed"
    } else {
      return capitalizeFLetter(status)
    }
  }

  const checkStatus = (status) => {
    status = status ? status.toLowerCase() : "";
    if (status === 'paid') {
      return "success"
    } else if (status === 'open') {
      return "discontinued"
    } else if (status === 'void') {
      return "failed"
    } else {
      return "discontinued"
    }
  }

  let col = [
    { id: 'NO', label: 'NO.', width: 'auto' },
    { id: "RECEIPT", label: "Invoice Number", width: "20%" },
    { type: "string", name: "name", label: "INVOICE Date", width: "20%" },
    { type: "string", name: "name", label: "Billing Cycle", width: "20%" },
    { type: "string", name: "name", label: "Amount paid", width: "20%" },
    { type: "string", name: "status", label: "Status", width: '15%' },
    { type: "action", name: "action", label: "ACTION", width: "10%" },
  ];

  let rows = payments?.length ? payments?.map((payment, i) => {
    return [
      { type: 'id', value: i + 1, payment: payment },
      {
        type: "link",
        name: "invoice_number",
        value: payment?.receipt_number ? payment?.receipt_number.replace("#", "") : "" || "N/A",
        url: payment?.hosted_invoice_url ? payment?.hosted_invoice_url : ""
      },
      { type: 'string', name: 'date', value: payment?.created ? moment(payment.created * 1000).format("MMM DD, yyyy") : "" },
      { type: "string", name: "frequency", value: payment?.lines?.data?.[0]?.plan?.interval ? checkFrequency(payment?.lines?.data?.[0]?.plan?.interval) : "" },
      { type: "string", name: "name", value: payment?.total ? "$" + (payment.total / 100).toFixed(2) : "N/A" },
      { type: 'status', status: checkStatus(payment?.status), value: payment?.status ? checkStatusName(payment.status) : "" },
      { type: "action", status: "active", value: payment?.status === "paid" ? "VIEW RECEIPT" : "VIEW INVOICE", showGreen: payment?.status === "paid"},
    ]
  }) : [];

  // ============================== Table End ======================================//

  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      content={
        <div>
          <Grid container spacing={3} >
            <Grid item md={12} sm={12} xs={12} >

              <div className="table_box">
                {loading ?
                  <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 250,
                  }}
                >
                  <CircularProgress color='primary' />
                </div>
                : 
                <SimpleTable
                  showHeader={true}
                  title="Payment History"
                  width="100%"
                  rows={rows}
                  columns={col}
                  colSpan={16}
                  actionSendButton={true}
                  classProps={changeClassProps()}
                  donationsCount={rows?.length ? 1 : 0}
                  noTitle="No Payment History Found"
                  actionLink={(name) => props.history.push({ pathname: "/organization/payment-receipt", state: { invoice: name[0].payment } })}
                  showIconCol={false}
                  actionSendButton={true}
                  deleteAction={(e) => console.log(e)}
                  showFooter={rows?.length > 5 ? true : false}
                  showAdd={false}
                  addText="ADD NEW"
                  iconTextSize={16}
                  rowsPerPage={5}

                  iconTextSize={16}
                  sortColumnIndex={[5]}


                />
              }
              </div>
            </Grid>
          </Grid>




        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}

export default PaymenthistoryTable;