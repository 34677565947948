import React, {useState, useEffect} from 'react';
import CheckCircle from '../../../assets/img/check-circle.png';

import {
    AlertAction,
    GridContainer,
    GridItem,
    MasterLayoutMiddle,
    FooterInner, CardDetailsTable,
    SimpleTable,
  } from "@dclab/dc-react-ui-kit";
  import ChevronLeft from '@material-ui/icons/ChevronLeft';
import "../style.css";
import "../../../App.css";
import "../../style.css";
import moment from 'moment';
  import { getOrderDetails } from "../api";
  import CircularProgress from "@material-ui/core/CircularProgress";


export default function OrderDetails(props) {
  
    const [order, setorder] = useState({});
    const [orderTotal, setOrdertotal] = useState(0);
    // const [planSubscription, setplanSubscription] = useState([])
    const [showSuccessAlert, setshowSuccessAlert] = useState(false)
    const [loading, setloading] = useState(false)
    
    useEffect(() => {
        const getDetails = async () => {
            setloading(true)
            const token = localStorage.getItem("authToken");
            // let orgId = localStorage.getItem("orgID");
            //get the orderId from the url params
            const orderId = props?.match?.params?.orderId;
            // console.log( orderId );

            try {
                //get order details from the api using orderId from the url params
                const orderRes = await getOrderDetails( orderId, token);   
                setorder(orderRes?.data?.body?.data); 
                //find the order subtotal from the order items
                let orderSubtotal = 0 ; 
                console.log( orderRes?.data?.body?.data?.orderItems)
                //loop through the order items and sum the price
               if( orderRes?.data?.body?.data?.orderItems ){
                    for( let i=0 ; i<orderRes.data.body.data.orderItems.length ; i++ ){
                        orderSubtotal += orderRes.data.body.data.orderItems[i].orderSubtotal;
                    }
               }
                  // console.log( item?.orderSubtotal )
                  //   orderSubtotal += item?.orderSubtotal;
                    //set the order total
              setOrdertotal(orderSubtotal);             
                
                

                setloading(false)
            } catch (error) {               
                setloading(false)
            }            
        }
        getDetails();
    }, []);  

    
    let header = { avatar: "", tableName: `Order Details`, icon: "", status: [] };

    const paymentMethods={
      'card': 'Card',
      'check': 'Check',
      'payLater': 'Pay Later',
    }

    let data = [
        {
            field: "Order Status",
            value: order?.status ?? "PENDING"
        },
        {
          field: "Requester",
          value:  order?.requester?.firstName ? order.requester.firstName + " " + order.requester.lastName : '<span className="ondisableText">No name</span>'
        },
        {
          field: "Subtotal",
          value: "$" + orderTotal
      },
      {
        field: "Order #",
        value: order?.orderNumber ?? order._id
      },
    {
      field: "Email",
      value: order?.requester?.email ? order.requester.email : '<span className="ondisableText">No email</span>'
    },
    {
      field: "Discount",
      value: "0%"
    },
    {
      field: "Date",
      value: order?.createdAt ? moment(order.createdAt).format('lll') : '<span className="ondisableText">none</span>'
    },
    {
      field: "Phone",
      value: order?.requester?.phone ? order?.requester?.phone : '<span className="ondisableText">No phone</span>'
    },
    {
      field: "Total",
      value : "$" + orderTotal
    },
    {
      field: "Ordered For",
      value: order?.orderFor ?? '<span className="ondisableText">None</span>'
    },
    {
      field: "Address",
      value : order?.requester?.billingStreetAddress ? order?.requester?.billingStreetAddress + "<br>" +  order?.requester?.billingCity + ", " + order?.requester?.billingState + " " + order?.requester?.billingPostalCode : '<span className="ondisableText">No address</span>'
    },
    {
      field: "Payment",
      value: order.payment?.method === 'payLater' ? 'Pending' : `Paid <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style='margin-left: 5px' viewBox="0 0 24 24"><defs><style>.a{fill:none;}.b{fill:#3cc480;}</style></defs><path class="a" d="M0,0H24V24H0Z"/><path class="b" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM10,17,5,12l1.41-1.41L10,14.17l7.59-7.59L19,8Z"/></svg>`
    },
    {
      field: "Org Name",
      value:  order?.shipping?.companyName ?  order.shipping.companyName : '<span className="ondisableText">No name</span>'
    },
    {
      field: "Shipping",
      value : order?.requester?.shippingStreetAddress ? order?.requester?.shippingStreetAddress + "<br>" +   order?.requester?.shippingCity + ", " +  order?.requester?.shippingState + " " +  order?.requester?.shippingPostalCode : '<span className="ondisableText">No address</span>'
    },
    {field: "Payment method",
    value:  order?.payment?.method ? paymentMethods[ order.payment?.method ] : '<span className="ondisableText">None</span>'},
    {
      field: "Org Type",
      value:  order?.shipping?.companyState ? order.shipping.companyState : '<span className="ondisableText">None</span>'
    },   
            
  ];

    let footerLinkName = "MANAGE ORDER";
    let viewInvoice = "VIEW INVOICE";

    const onClick = () => {
      console.log('MANAGE')
      props.history.push({pathname: `/orders`, state: { order: order }});
    }

    const onCancelClick = () => {
      console.log('INVOICE');
      //if in the order receiptUrl available then redirect to the receiptUrl
      if( order?.payment?.receiptUrl ){
        window.open(order?.payment?.receiptUrl, "_blank");
      }else{
        //show the error message no invoice available for this order
        alert('No invoice available for this order');
      }

    };

    const style = {
        width: '100%',
    };
    const language={
      '1': 'English',
      '2': 'Bangla',
      '3': 'Chinese',
      '4': 'Spanish',

    };
    

    // TABLE
    let col = [
        { id: "NO", label: "", width: "auto" },
        { type: "string", name: "name", label: "NAME", width: '20%' },
        { type: "string", name: "name", label: "LANGUAGE", width: '20%' },
        { type: "string", name: "category", label: "QTY", width: '30%' },
        { type: "string", name: "category", label: "SUBTOTAL", width: '30%' },
    ];
 
    let rows = order?.orderItems?.length ? order?.orderItems.map((el, i) => {
              
        return [
          { id: "NO.", label: "No", width: "auto" },
          { type: "string", name: "name", value: el?.itemName },
          { type: "string", name: "name", value: language[el?.language] },
          { type: "string", name: "category", value: el?.quantity },
          { type: "string", name: "category", value: "$" + el?.orderSubtotal },
        ]
    }) : [];
      

      let classProps = () => {
        const tempArray = [];
        col.forEach((column, index) => {
          tempArray.push(`class${index}`);
        });
        return tempArray;
      };

      const changeClassProps = () => {
        const tempArray = classProps();
        tempArray[0] = "class15";
        return tempArray;
      };

      const actionLinkHandler = (data) => {
        // console.log("id is: ", data[0]?.plan, details);
        // props.history.push({pathname: `/organization/plan-details`, state: { plan: data[0].plan, details: details }});
      }


    return (
        <MasterLayoutMiddle
        header={
            <>
              <div
                onClick={() =>
                  // props.history.goBack()
                  //go back to /orders url 
                  props.history.push({pathname: `/orders`})
                }
                className='backBtn'>
                <ChevronLeft style={{ alignSelf: 'center', marginLeft: 0 }} />
                <p style={{ alignSelf: 'center' }}>Back</p>{' '}
              </div>

              {(showSuccessAlert && (
                    <div
                      style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                    >
                      <AlertAction
                        alert={"Added to cart successfully!"}
                        alertType={"success"}
                        onClick={() => setshowSuccessAlert(false)}
                      />
                    </div>
              ))}
            </>
        }
        content={
          <>
            <GridContainer spacing={0} containerClass="grid-main">
                <GridItem itemStyle={{ marginTop: '60px' }} itemClass="grid-item">
                    <CardDetailsTable 
                    data={data} 
                    header={header} 
                    footerLinkName={footerLinkName} 
                    footerDeleteButton={viewInvoice}
                    onClick={() => onClick()}
                    onDeleteClick={() => onCancelClick()}
                    />
                </GridItem>

                <GridItem itemStyle={{ marginTop: 25 }} itemClass="grid-item">
                  <div className="speaker-simple-table">
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: 30,
                        }}
                      >
                        <CircularProgress color="primary" />
                      </div>
                    ) : (
                        <div className="speaker-simple-table2">
                          <SimpleTable
                            showHeader={true}
                            showCol={false}
                            title="Items"
                            rows={rows}
                            columns={col}
                            colSpan={16}
                            actionSendButton={true}
                            classProps={changeClassProps()}
                            donationsCount={rows && rows.length > 0 ? 1 : 0}
                            noTitle="No Items added"
                            noPara="Added Items will be displayed and managed here"
                            noBtnText=""
                            actionLink={(data) => actionLinkHandler(data)}
                            showIconCol={false}
                            showFooter={true}
                            showAdd={false}
                            iconTextSize={16}
                            rowsPerPage={5}
                            
                          />
                        </div>
                      )}
                  </div>
                </GridItem>
            </GridContainer>


          </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={false}
      />
    )
}
