import { combineReducers } from "redux";
 
import user from "./userReducer";
import loading from "./loadingReducer";
import donors from "./donorsReducer";
import signup from "./signupReducer";
import org from "./orgReducer";
import orgToken from "./orgTokenReducer";
import forgotPassword from "./forgotPasswordReducer";
import signin from "./signinReducer";
import addOrg from "./newOrgReducer";
import teamMember from "./teamMemberReducer";
import inquiryTypes from './inquiryTypesReducer'
import services from './servicesReducer'
import categories from './categoriesReducer'
import salahs from './salahReducer'
import reminders from './reminderReducer'
import annoucements from './annoucementReducer'
import events from './eventReducer'
import embedForm from './embedForm'
import pages from './pages';
import alert from './alertReducer';
import orgData from './orgDataWebReduce';
const rootReducer = combineReducers({
  user,
  donors,
  loading,
  signup,
  org,
  orgToken,
  orgData,
  forgotPassword,
  signin,
  addOrg,
  teamMember,
  inquiryTypes,
  services,
  categories,
  salahs,
  reminders,
  annoucements,
  events,
  embedForm,
  pages,
  alert
});

export default rootReducer;