import React, { Component } from 'react';
import './Footer.css'
class Footer extends Component {
    render() {
        return (
            <div className="">
                <div className="ftr_btm">
                    <div className="container">
                        <div className="btm_ftr_left">
                            <p>
                                <a href="http://shapla.deenconnect.io/terms-policies">TERMS OF SERVICES</a>
                                <span>|</span>
                                <a href="http://shapla.deenconnect.io/terms-policies#privacy-policy">PRIVACY POLICY</a>
                            </p>
                            <p>COPYRIGHT © 2020  ∎  ALL RIGHT RESERVED</p>
                        </div>
                        <div className="copyright_links">POWERED BY <a href="#"><img className="" src={require('../img/deenconnect_footer_logo.png')} /></a></div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Footer;