import React from "react";
import {
  BaseTextField,
  BaseButton,
  TextEditor,
  AlertAction,
  MasterLayoutMiddle,
  FooterInner
} from "@dclab/dc-react-ui-kit";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { useState } from "react";
import "../../Website/style.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { addFaq, getEmbedForm } from '../apis/index'
import { markdowntoreact } from "../../../utils/markdowntoreact";
import Grid from "@material-ui/core/Grid";
import { useDispatch } from 'react-redux'
import { showAlert } from '../../../redux/actions/alertActions';
import messages_content from '../../../utils/messages.json';
const {
  token_expired
} = messages_content;
function AddFaq(props) {
  const dispatch = useDispatch()
  const [value, setValue] = useState([{ type: "paragraph", children: [{ text: "" }] }]);
  const [title, setTitle] = useState('')
  const [descriptionCount, setdescriptionCount] = useState('')
  const [error, setError] = useState(false)
  const [showalert, setshowalert] = useState(false)
  const [loading, setloading] = useState(false)
  function onDescriptionStateChange(editorState) {
    let sum = 0;
    editorState.map((st) => {
      if (st.type === "bulleted-list" || st.type === "numbered-list") {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    setValue(editorState)
    setdescriptionCount(sum)
  };
  const add = async () => {
    setloading(true)
    if (!title || !descriptionCount) {
      setError(true)
      setloading(false)
    }
    else {
      const orgId = localStorage.getItem('orgID')
      const token = localStorage.getItem('authToken')
      const faqObject = {
        question: title,
        answer: markdowntoreact(value)[0].props.children[0]
      }
      try {
        const getfaqs = await getEmbedForm(orgId, token)
        let embedform_draft = getfaqs?.data?.body?.data[0]?.enabledIntegrations?.deenfund?.embedFormDraft?.[0]
        let embedform = getfaqs?.data?.body?.data[0]?.enabledIntegrations?.deenfund?.embedForm?.[0]
        let faqs = getfaqs?.data?.body?.data[0]?.enabledIntegrations?.deenfund?.embedFormDraft?.[0]?.setup?.faqs
        faqs = faqs && faqs.length ? faqs : [];
        faqs.push(faqObject)
        console.log("faqsData", faqs)
        const formdata = {
          embedFormDraft: [
            {
              ...embedform_draft,
              setup: {
                footer: embedform_draft?.setup?.footer,
                rightSide: embedform_draft?.setup?.rightSide,
                imageOrVideo: embedform_draft?.setup?.imageOrVideo,
                content: embedform_draft?.setup?.content,
                faqs: faqs
              },
            },
          ],
          embedForm: [
            {
              ...embedform,
              setup: {
                footer: embedform?.setup?.footer,
                rightSide: embedform?.setup?.rightSide,
                imageOrVideo: embedform?.setup?.imageOrVideo,
                content: embedform?.setup?.content,
                faqs: faqs
              },
            },
          ]
        }

        let formData2 = {
          'enabledIntegrations.deenfund.embedFormDraft': formdata.embedFormDraft,
          'enabledIntegrations.deenfund.embedForm': formdata.embedForm
        };
        
        console.log("formData2", formData2)
        await addFaq(orgId, formData2, token)
        setshowalert(true)
        setloading(false)
        dispatch(showAlert('addfaq'))
        props.history.goBack()
      }
      catch (error) {
        console.log(error)
        setloading(false)
        setError(error && error.response && error.response.data.message)
      }
    }
  }
  const style = {
    width: '100%',
  };
  const textEditorStyle = {
    border: "1px solid black",
    color: "gray",
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          <div
            onClick={() => props.history.goBack()}
            className="backBtn"
            style={{ marginTop: 0 }}
          >
            <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
            <p style={{ alignSelf: "center" }}>Back</p>{" "}
          </div>
          {error && error === "Token invalid or expired" && (
            <div
              style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 70 }}
            >
              <AlertAction
                alert={token_expired}
                alertType={"error"}
                onClick={() => setError(false)}
              />
            </div>
          )}
          {showalert && error && (
            <div
              style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 70 }}
            >
              <AlertAction
                alert={
                  error === "Token invalid or expired"
                    ? token_expired
                    : error
                }
                alertType={"error"}
                onClick={() => setshowalert(false)}
              />
            </div>
          )}
        </>
      }
      isForm={true}
      formData={
        < div >
          <div>
            <h1 className="title_top">Add Frequently Asked Questions</h1>
            <div className="dasheddBorder"></div>
          </div>
          <div>
            <Grid container spacing={3}>
              <Grid item md={12} xl={12}>
                <div className="field_box">
                  <BaseTextField
                    value={title}
                    InputProps={false}
                    count={100}
                    color="primary"
                    onChange={(e) => setTitle(e.target.value)}
                    helperText={error && !title && "Enter a title for the slider"}
                    error={error && !title && "Enter a title for the slider"}
                    label="Faq Title"
                  />
                </div>
              </Grid>
              <Grid item md={12} xl={12}>
                <TextEditor
                  onChange={(e) => onDescriptionStateChange(e)}
                  disabled={false}
                  value={value}
                  style={textEditorStyle}
                  label={"Description"}
                  maxCharCount={1000}
                  helperText={
                    error &&
                    !descriptionCount &&
                    "description is required"
                  }
                  error={error && !descriptionCount}
                />
              </Grid>
              <Grid item md={12} xl={12}>
                {loading ? (
                  <div
                    style={{
                      marginTop: 30,
                      display: "flex",
                      justifyContent: "center",
                      width: 500,
                    }}
                  >
                    <CircularProgress color="primary" />
                  </div>
                ) :
                  (<div className="form_button">
                    <span className="btnCencel">
                      <BaseButton text="CANCEL" onClick={() => props.history.push("/deenfund/donation-form")} />
                    </span>
                    <BaseButton text="ADD NOW" onClick={() => add()} />
                  </div>)}
              </Grid>
            </Grid>
          </div>
        </ div >
      }
      isFooter={true}
      footer={
        < FooterInner 
          style={style}
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
export default AddFaq;
