import React, { Component } from "react";
import {
  SimpleTable,
  BasePaper,
  CardData,
  BaseTabs,
  AlertAction,
  GridContainer,
  GridItem,
  MasterLayoutMiddle,
  FooterInner
} from "@dclab/dc-react-ui-kit";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import { setAllDonors } from "./../../redux/actions/donorsActionCreators";
import { getDonors, getDonorsCardData } from "../Donations/api";
import { getaddressFromPostalCode } from "./apis";
import { stateAbbreviations } from "../../utils/statesAbbreviations";
import "../../App.css";
import '../style.css';
import moment from 'moment';
import messages_content from '../../utils/messages.json';
import Anonymouscon from '../../assets/img/Anonymous-icon.svg'
const {
  token_expired
} = messages_content;
class Collection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      activeTab: 0,
      donors: props.donors,
      donorsCardData: {},
      error: "",
      loading: true,
    };
    this.handleTabChange = this.handleTabChange.bind(this);
    this.actionLink = this.actionLink.bind(this);
  }
  async componentDidMount() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const res = await getDonors(orgId, token);
      const res2 = await getDonorsCardData(orgId, "", token);
      this.props.setDonors(res.data.body.data);
      this.setState({
        donors: res.data.body.data,
        donorsCardData: res2.data.body.data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error: error && error.response && error.response.data.message,
        loading: false,
      });
    }
  }
  actionLink = (data) => {
    const obj = {
      receiptNumber: data[1]?.value,
      receiptUrl: data[1]?.url,
      name: data[2]?.value,
      amount: data[4]?.value,
      frequency: data[6]?.value,
      category: data[5]?.value,
      status: data[7]?.value,
      stream: data[2]?.stream,
      email: data[3]?.email,
      donation_date: data[4]?.donation_date,
      note: data[5]?.note,
      processingFee: data[5]?.processingFee,
      address: data[5]?.address,
    };
    localStorage.setItem("Donation Details", JSON.stringify(obj));
    this.props.history.replace("/deenfund/donations/details");
  };
  actionLink2 = (data) => {
    let donorData = this.state.donors.find((el) => el._id === data[0]?.docId)
    const obj = {
      receiptNumber: donorData?.receipt_number,
      receiptUrl: donorData?.receipt_url,
      name: donorData?.fullName,
      amount: donorData?.amount ? "$" + (donorData.amount / 100).toFixed(2) : "",
      frequency: donorData?.frequency,
      category: donorData?.category,
      status: donorData?.status,
      stream: donorData?.stream,
      email: donorData?.email,
      donation_date: donorData?.createdAt,
      note: donorData?.memoNote,
      address: donorData?.billing_details?.address,
      processingFee: donorData?.processingFee
    };
    localStorage.setItem("Donation Details", JSON.stringify(obj));
    this.props.history.replace("/deenfund/donations/details");
  };
  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };

  uppercaseWords = str => str?.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
  sortData = (obj) => {
    console.log("Sorted function Data: ", obj);
  }

  render() {
    let rows = [];
    let freqRows = [];
    let monthlyRows = [];
    let byCategoryRows = [];
    this.onClick = (x, y) => {
      this.setState({
        tabIndex: y,
      });
    };
    const { donors, tabIndex } = this.state;
    if (Array.isArray(donors)) {
      rows = donors.map((donor, i) => {
        return [
          { type: "id", value: i + 1 },
          {
            type: "link",
            name: "invoice_number",
            value: donor.receipt_number ? donor.receipt_number.replace("#", "") : "" || "N/A",
            url: donor.receipt_number ? donor.receipt_url : null,
          },
          {
            type: "string",
            name: "email",
            value: donor.fullName || "N/A",
            stream: donor.stream,
            icon: Anonymouscon,
            verifyText: "Anonymous",

          },
          {
            type: "string",
            name: "date",
            value: donor.createdAt ? donor.createdAt.replace(",", ", ") : "" || "N/A",
            email: donor.email,
          },
          {
            type: "string",
            name: "name",
            value: donor.amount ? "$" + (donor.amount / 100).toFixed(2) : "" || "N/A",
            donation_date: donor.createdAt ? donor.createdAt.replace(",", ", ") : "",
          },
          {
            type: "string",
            name: "category",
            value: donor.category || "N/A",
            note: donor.memoNote,
            processingFee: donor.processingFee,
            address: donor.billing_details.address,
          },
          {
            type: "string",
            name: "category",
            value:
              donor.frequency[0].toUpperCase() + donor.frequency.slice(1) ||
              "N/A",
          },
          {
            type: "status",
            status:
              donor.status.toLowerCase() === "succeeded" ? "success" : "failed",
            value:
              donor.status.toLowerCase() === "succeeded"
                ? "Success"
                : donor.status[0].toUpperCase() + donor.status.slice(1),
          },
          { type: "action", status: "details", value: "see details" },
        ];
      });
      byCategoryRows = donors.map((freqmonthly, i) => {
        const nameArr = freqmonthly?.fullName?.split(" ");
        let initials;
        if (nameArr?.length > 1) {
          initials = nameArr[0][0] + nameArr[1][0];
        } else if (nameArr?.length) {
          initials = nameArr[0][0];
        } else {
          initials = "";
        }
        return [
          { type: "id", value: i + 1, docId: freqmonthly._id },

          {
            type: 'string',
            name: 'textImg',
            primaryText: freqmonthly.fullName,
            initials: initials?.toUpperCase(),
          },

          {
            type: "string",
            name: "category",
            value: `${freqmonthly?.billing_details?.address?.city ? this.uppercaseWords(freqmonthly?.billing_details?.address?.city) + "," : ""} ${freqmonthly?.billing_details?.address?.state
              ? stateAbbreviations[this.uppercaseWords(freqmonthly?.billing_details?.address?.state.toLowerCase())] ? stateAbbreviations[this.uppercaseWords(freqmonthly?.billing_details?.address?.state.toLowerCase())] : this.uppercaseWords(freqmonthly?.billing_details?.address?.state) ?? '' : this.uppercaseWords(freqmonthly?.billing_details?.address?.state) ?? ''} ${freqmonthly?.billing_details?.address?.postal_code ?? ""}`
          },
          {
            type: "string",
            name: "name",
            value: freqmonthly.amount ? "$" + (freqmonthly.amount / 100).toFixed(2) : "" || "N/A",
          },
          { type: "action", status: "details", value: "View details" },
        ];
      });
    }
    if (Array.isArray(donors)) {
      const oneTime = donors.filter((freq) => freq.frequency === "one-time");
      const recurring = donors.filter(
        (freq) => freq.frequency.toLowerCase() === "monthly"
      );
      oneTime.map((freq, i) => {
        return freqRows.push([
          { type: "id", value: i + 1 },
          {
            type: "link",
            name: "invoice_number",
            value: freq.receipt_number ? freq.receipt_number.replace("#", "") : "" || "N/A",
            url: freq.receipt_number ? freq.receipt_url : null,
          },
          {
            type: "string",
            name: "name",
            value: freq.fullName || "N/A",
            stream: freq.stream,
          },
          {
            type: "string",
            name: "date",
            value: freq.createdAt ? freq.createdAt.replace(",", ", ") : "" || "N/A",
            email: freq.email,
          },
          {
            type: "string",
            name: "name",
            value: freq.amount ? "$" + (freq.amount / 100).toFixed(2) : "" || "N/A",
            donation_date: freq.createdAt ? freq.createdAt.replace(",", ", ") : "",
          },
          {
            type: "string",
            name: "category",
            value: freq.category || "N/A",
            note: freq.memoNote,
            address: freq.billing_details.address,
          },
          {
            type: "string",
            name: "category",
            value:
              freq.frequency[0].toUpperCase() + freq.frequency.slice(1) ||
              "N/A",
          },
          {
            type: "status",
            status:
              freq.status.toLowerCase() === "succeeded" ? "success" : "failed",
            value:
              freq.status.toLowerCase() === "succeeded"
                ? "Success"
                : freq.status[0].toUpperCase() + freq.status.slice(1),
          },
          { type: "action", status: "details", value: "see details" },
        ]);
      });
      recurring.map((freq, i) => {
        return monthlyRows.push([
          { type: "id", value: i + 1 },
          {
            type: "link",
            name: "invoice_number",
            value: freq.receipt_number ? freq.receipt_number.replace("#", "") : "" || "N/A",
            url: freq.receipt_number ? freq.receipt_url : null,
          },
          {
            type: "string",
            name: "name",
            value: freq.fullName || "N/A",
            stream: freq.stream,
          },
          {
            type: "string",
            name: "date",
            value: freq.createdAt ? freq.createdAt.replace(",", ", ") : "" || "N/A",
            email: freq.email,
          },
          {
            type: "string",
            name: "name",
            value: freq.amount ? "$" + (freq.amount / 100).toFixed(2) : "" || "N/A",
            donation_date: freq.createdAt ? freq.createdAt.replace(",", ", ") : "",
          },
          {
            type: "string",
            name: "category",
            value: freq.category || "N/A",
            note: freq.memoNote,
            address: freq.billing_details.address,
          },
          {
            type: "string",
            name: "category",
            value:
              freq.frequency[0].toUpperCase() + freq.frequency.slice(1) ||
              "N/A",
          },
          {
            type: "status",
            status:
              freq.status.toLowerCase() === "succeeded" ? "success" : "failed",
            value:
              freq.status.toLowerCase() === "succeeded"
                ? "Success"
                : freq.status[0].toUpperCase() + freq.status.slice(1),
          },
          { type: "action", status: "details", value: "see details" },
        ]);
      });
    }
    const classProps = () => {
      const tempArray = [];
      columns.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    const obj = [
      {
        label: "All",
        item: "",
      },
      {
        label: "One-Time",
        item: "",
      },
      {
        label: "Recurring",
        item: "",
      },
      {
        label: "New Donors",
        item: "",
      },
    ];
    const columns = [
      { id: "NO.", label: "No.", width: "auto" },
      { id: "RECEIPT", label: "RECEIPT", width: "18%" },
      { id: "DONOR NAME", label: "DONOR NAME", width: "20%" },
      { id: "DATE", label: "DATE", width: "20%" },
      { id: "AMOUNT", label: "AMOUNT", width: "17%" },
      { id: "CATEGORY", label: "CATEGORY", width: "13%" },
      { id: "FREQUENCY", label: "FREQUENCY", width: "17%" },
      { id: "STATUS", label: "STATUS", width: "15%" },
      { id: "ACTION", label: "ACTION", width: "15%" },
    ];
    const byCategoryColumns = [
      { id: "NO.", label: "No.", width: "auto", },
      { id: "CATEGORY", label: "DONOR NAME", width: "25%" },
      { id: "CATEGORY", label: "Location", width: "25%" },
      { id: "CATEGORY", label: "Amount", width: "50%" },
      { id: "STATUS", label: "", width: "15%" },
    ];
    const contentNext = (
      <>
        <SimpleTable
          showHeader={true}
          showCol={false}
          title="Donations"
          rows={rows}
          columns={columns}
          colSpan={16}
          actionSendButton={true}
          classProps={changeClassProps()}
          donationsCount={rows.length < 0 ? 0 : rows.length}
          noTitle="No recent donations"
          noPara="No donation to show yet. Start inviting donors to increase donations."
          noBtnText="INVITE DONOR"
          showFooter={true}
          actionLink={(data) => this.actionLink(data)}

          iconTextSize={16}
          rowsPerPage={5}
          // sortData={(e) => sortData(e)}
          data={this.state.data}
          sortColumnIndex={[7]}
          verifyIcon={false}

        />
        {rows.length < 0 && <div className="footer-separator-deenfund-2"></div>}
      </>
    );
    const contentNextOneTime = (
      <>
        <SimpleTable
          showHeader={true}
          title="Donations"
          rows={freqRows}
          columns={columns}
          colSpan={16}
          actionSendButton={true}
          classProps={changeClassProps()}
          donationsCount={freqRows.length < 0 ? 0 : freqRows.length}
          noTitle="No recent donations"
          noPara="No donation to show yet. Start inviting donors to increase donations."
          noBtnText="INVITE DONOR"
          showFooter={true}
          actionLink={(data) => this.actionLink(data)}

          iconTextSize={16}
          rowsPerPage={5}
          // sortData={(e) => sortData(e)}
          data={this.state.data}
          sortColumnIndex={[7]}
        />
        <div className="footer-saprator-3"></div>
      </>
    );
    const contentNextMonthly = (
      <SimpleTable
        showHeader={true}
        title="Donations"
        rows={monthlyRows}
        columns={columns}
        colSpan={16}
        actionSendButton={true}
        classProps={changeClassProps()}
        donationsCount={monthlyRows.length < 0 ? 0 : monthlyRows.length}
        noTitle="No recent donations"
        noPara="No donation to show yet. Start inviting donors to increase donations."
        noBtnText="INVITE DONOR"
        showFooter={true}
        actionLink={(data) => this.actionLink(data)}

        iconTextSize={16}
        rowsPerPage={5}
        // sortData={(e) => sortData(e)}
        data={this.state.data}
        sortColumnIndex={[7]}
      />
    );
    const contentNextByCategory = (
      <>
        <SimpleTable
          showHeader={true}
          title="Donors"
          rows={byCategoryRows}
          columns={byCategoryColumns}
          colSpan={16}
          actionSendButton={true}
          classProps={changeClassProps()}
          donationsCount={byCategoryRows.length < 0 ? 0 : byCategoryRows.length}
          noTitle="No recent donations"
          noPara="No donation to show yet. Start inviting donors to increase donations."
          noBtnText="INVITE DONOR"
          showFooter={true}
          actionLink={(data) => this.actionLink2(data)}
        />
        <div className="footer-saprator-4"></div>
      </>
    );
    const style = {
      width: '100%',
    };
    console.log("this.state.donors", this.state.donors)

    const { error, activeTab, loading, donorsCardData } = this.state;
    return (
      <React.Fragment>
        <MasterLayoutMiddle
          header={
            <div>
              {error && error === "Token is invalid or expired" && (
                <div
                  style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                >
                  <AlertAction
                    alert={token_expired}
                    alertType={"error"}
                    onClick={() => this.setState({ error: false })}
                  />
                </div>
              )}
            </div>
          }
          content={
            <div>
              <div className="card_section">
                <CardData
                  data={[
                    {
                      title: `Donations - ${moment().format("MMM YYYY")}`,
                      donation: donorsCardData && donorsCardData.card1 && donorsCardData.card1.data ? "$" + (donorsCardData.card1.data / 100).toFixed(2) : 0,
                      fromLastMonth: donorsCardData && donorsCardData.card1 && donorsCardData.card1.percentage ? Math.round(donorsCardData.card1.percentage) : 0,
                      increased: donorsCardData && donorsCardData.card1 && donorsCardData.card1.percentage > 0 ? true : false,
                    },
                    {
                      title: `New Donors - ${moment().format("MMM YYYY")}`,
                      donation: donorsCardData && donorsCardData.card2 && donorsCardData.card2.data ? donorsCardData.card2.data : 0,
                      fromLastMonth: donorsCardData && donorsCardData.card2 && donorsCardData.card2.percentage ? Math.round(donorsCardData.card2.percentage) : 0,
                      increased: donorsCardData && donorsCardData.card2 && donorsCardData.card2.percentage > 0 ? true : false,
                    },
                    {
                      title: "Total Recurring Donors",
                      donation: donorsCardData && donorsCardData.card3 && donorsCardData.card3.data ? donorsCardData.card3.data : 0,
                      fromLastMonth: donorsCardData && donorsCardData.card3 && donorsCardData.card3.percentage ? Math.round(donorsCardData.card3.percentage) : 0,
                      increased: donorsCardData && donorsCardData.card3 && donorsCardData.card3.percentage > 0 ? true : false,
                    },
                    {
                      title: `Total Donations - ${moment().format("YYYY")}`,
                      donation: donorsCardData && donorsCardData.card4 && donorsCardData.card4.data ? "$" + (donorsCardData.card4.data / 100).toFixed(2) : 0,
                      fromLastMonth: donorsCardData && donorsCardData.card4 && donorsCardData.card4.percentage ? Math.round(donorsCardData.card4.percentage) : 0,
                      increased: donorsCardData && donorsCardData.card4 && donorsCardData.card4.percentage > 0 ? true : false,
                    },
                  ]}
                />
                {/* <CardData
                  data={[
                    {
                      title: "Total Donations",
                      donation: "$2540.76",
                      fromLastMonth: 34,
                      increased: true,
                    },
                    {
                      title: "Total Organizations",
                      donation: "20",
                      fromLastMonth: 34,
                      increased: true,
                    },
                    {
                      title: "Average Donations",
                      donation: "$640",
                      fromLastMonth: 34,
                      increased: false,
                    },
                    {
                      title: "Average Donations",
                      donation: "$640",
                      fromLastMonth: 34,
                      increased: false,
                    },
                  ]}
                /> */}
              </div>
              <GridContainer spacing="0">
                <GridItem>
                  <BaseTabs
                    title=""
                    object={obj}
                    onClick={(x, y) => this.onClick(x, y)}
                    value={activeTab}
                    onChange={(event, newValue) =>
                      this.handleTabChange(event, newValue)
                    }
                  />
                </GridItem>
                {tabIndex === 0 &&
                  (loading ? (
                    <div
                      style={{
                        marginTop: 60,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                    <GridItem itemClass="grid-item">
                      <BasePaper
                        context={contentNext}
                        square={false}
                        paperStyle={styles.paperStyleNext}
                      />
                    </GridItem>
                  ))}
                {tabIndex === 1 &&
                  (loading ? (
                    <div
                      style={{
                        marginTop: 60,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                    <GridItem itemClass="grid-item">
                      <BasePaper
                        context={contentNextOneTime}
                        square={false}
                        paperStyle={styles.paperStyleNext}
                      />
                    </GridItem>
                  ))}
                {tabIndex === 2 &&
                  (loading ? (
                    <div
                      style={{
                        marginTop: 60,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                    <GridItem itemClass="grid-item">
                      <BasePaper
                        context={contentNextMonthly}
                        square={false}
                        paperStyle={styles.paperStyleNext}
                      />
                    </GridItem>
                  ))}
                {tabIndex === 3 &&
                  (loading ? (
                    <div
                      style={{
                        marginTop: 60,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                    <GridItem itemClass="grid-item">
                      <BasePaper
                        context={contentNextByCategory}
                        square={false}
                        paperStyle={styles.paperStyleNext}
                      />
                    </GridItem>
                  ))}
              </GridContainer>
            </div>
          }
          isFooter={true}
          footer={
            <FooterInner
              style={style}
              termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
              privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
            />
          }
          isWhiteBackground={false}
        />
      </React.Fragment>
    );
  }
}
const styles = {
  paperStyle: {},
  paperStyleNext: {
    border: "none",
  },
  contents: {
    padding: '24 0',
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: '76%',
    marginTop: 70,
    marginLeft: '20%'
  },
};
const mapStateToProps = (state) => ({
  donors: state.donors,
});
const mapDispatchToProps = (dispatch) => ({
  setDonors: (res) => dispatch(setAllDonors(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Collection);
