import * as constants from "../constants";

const INITIAL_STATE = {
  name: "",
  type: "",
  email: "",
  taxId_ein: "",
  primary_phone: "",
  suit_number: "",

  city: "",
  state: "",
  postal_code: "",
  line1: "",
  tax_exemption_status: false,
  orgId: "5ea0023dc21fb5000714bed8",

  orgData: [
    // {
    //   taxId_status: { taxId_ein: "1223" },
    //   phone_fax: { primary_number: "0991222" },
    //   address: {
    //     line1: "test",
    //     city: "test city",
    //     state: "test state",
    //     postal_code: "122",
    //     country: " ",
    //   },
    //   team_members: [],
    //   _id: "5ee8835b9c56cd0007890c2b",
    //   name: "ABC",
    //   type: "Masjid/Islamic Center",
    //   createdBy: "5ed4a6c956a6640007445fea",
    //   discount: 0,
    //   contact_info: [],
    //   logs: [],
    //   embed_form: [],
    //   promo: [],
    //   createdAt: "Jun 16,2020",
    //   updatedAt: "Jun 16,2020",
    //   stripe_account: "acct_1Gua4nIlkjYcXCkz",
    // },
  ],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.ADD_ORG:
      return {
        ...state,
        name: action.payload.name,
        type: action.payload.type,
        email: action.payload.email,
        taxId_ein: action.payload.taxId_ein,
        primary_phone: action.payload.primary_phone,
        suit_number: action.payload.suit_number,

        city: action.payload.city,
        state: action.payload.state,
        postal_code: action.payload.postal_code,
        line1: action.payload.line1,
        tax_exemption_status: action.payload.tax_exemption_status,
        orgId: action.payload.orgId,
      };

    case constants.ADD_ORG_STRIPE:
      return {
        ...state,
        orgData: action.payload,
      };

    case constants.GET_ORG_DATA:
      return {
        ...state,
        orgData: action.payload,
      };

    default:
      return state;
  }
};
