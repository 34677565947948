import React, { useEffect } from "react";
import {
  BaseButton,
  BaseSelect,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import "../../../pages/Website/style.css";
import { EditServicesWebsite, GetServicesWebsite, getServicesWithAppId, updateServicesWithAppId } from "../../../pages/Website/api";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { hideAlert, showAlert } from "../../../redux/actions/alertActions";
import messages_content from '../../../utils/messages.json';
import { editOrganization } from "../../../pages/Organization/api";
const { 
    token_expired,
    maximum_limit_exeed_of_featured_services,
    service_featured_successfully
  } = messages_content;
function AddServices(props) {
  const [selection, setSelection] = React.useState('');
  const [Error, setError] = React.useState(false);
  const [object, setObject] = React.useState([])
  const [error, seterror] = React.useState(false)
  const [limitError, setLimitError] = React.useState(false);
  const [featuredServices, setFeaturedServices] = React.useState([])
  const [services, setServices] = React.useState([])
  const [Loader, setLoader] = React.useState(true);
  useEffect(() => {
    getFeaturedServices();
  }, [])
  async function getFeaturedServices() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    try {
      const resService = await getServicesWithAppId(token, orgId, 'deenweb')
      const data = resService.data.body.data;
      const featuredServices = data.filter(text => text.featured === true);
      setFeaturedServices(featuredServices);
      const unfeatured = data.filter(text => text.featured !== true);
      setServices(data);
      const array = unfeatured.map((text, index) => {
        return ({
          value: text.service.name,
          label: text.service.name,
          _id: text.service._id
        })
      })
      setObject(array);
      setLoader(false)
    } catch (err) {
      setLoader(false)
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const handleChange = (e) => {
    setError(false)
    setSelection(e.target.value);
  }
  const OnFeatured = async () => {
    if (featuredServices.length < 4) {
      setLoader(true);
      if (selection === '') {
        setLoader(false);
        setError(true);
      } else {
        const data = services.filter(text => text.service.name === selection);
        const token = localStorage.getItem("authToken");
        const orgId = localStorage.getItem('orgID');
        const updateOnOrg = {
          service: data[0].service._id,
          featured: true,
          publishFrom: data[0].publishFrom,  // DATE OBJECT
          publishTo: data[0].publishTo,
          status: data[0].status,
      }
        try {
          await updateServicesWithAppId(token, orgId, 'deenweb',data[0].service._id, updateOnOrg)
          props.alertShow(service_featured_successfully)
          props.history.push({ pathname: '/website/home-page', state: '4' });
          setLoader(false)
        } catch (err) {
          setLoader(false)
          seterror(err.response && err.response.data.message)
          setTimeout(() => {
            seterror(false)
          }, 8000);
        }
      }
    } else {
      setLimitError(true)
      setTimeout(() => {
        setLimitError(false)
      }, 8000);
    }
  }
  const styles = {
    formBody: {
      background: "#fff",
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A",
    },
    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
    },
    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
    select: {
      margin: "14px 0",
      minWidth: 120,
      width: 120,
      border: "#D3D8DA",
      width: "100%",
    },
  };
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          <div
            onClick={() => props.history.push({ pathname: '/website/home-page', state: '4' })}
            className="backBtn"
            style={{ marginTop: 0 }}
          >
            <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
            <p style={{ alignSelf: "center" }}>Back</p>{" "}
          </div>
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            limitError && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={maximum_limit_exeed_of_featured_services}
                  alertType={"error"}
                  onClick={() => setLimitError(false)}
                />
              </div>
            )
          }
        </>
      }
      isForm={true}
      formData={
        <div>
          {
            Loader ?
              <div
                style={{
                  marginTop: 30,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
              :
              <div>
                <div>
                  <h1 className="title_top">Add Featured Services</h1>
                  <div className="dasheddBorder"></div>
                </div>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Select Services to Feature</h2>
                    <Typography className="sub_decrition">Selected content will be featured on the website homepage</Typography>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseSelect
                        value={selection}
                        onChange={(e) => handleChange(e)}
                        object={object}
                        error={Error || object.length ? false : true}
                        style={styles.select}
                        helperText={object.length ? "" : <p>Services not found. For create new click here:
                            <span onClick={() => props.history.push('/website/add-services')} className='addLinkTag'> Add Services </span></p>}
                        label="Select Service"
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="form_button">
                      <span className="btnCencel">
                        <BaseButton text="CANCEL" onClick={() => props.history.push({ pathname: '/website/home-page', state: '4' })} />
                      </span>
                      <BaseButton text="ADD NOW" onClick={() => OnFeatured()} />
                    </div>
                  </Grid>
                </Grid>
              </div>
          }
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
const mapStateToProps = (state) => ({
  alert: state.addOrg.alert,
});

const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
  alertHide: (res) => dispatch(hideAlert(res)),

});
export default connect(mapStateToProps, mapDispatchToProps)(AddServices);
