import axios from "axios";

const baserUrl =
  "https://cors-anywhere.herokuapp.com/http://18.211.207.245:3000";
const env = {
  prod: process.env.REACT_APP_PAYMENT_PROD_API,
  dev: process.env.REACT_APP_PAYMENT_DEV_API
};
const orgEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")

) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}
const orgBaseUrl = orgEnv[currentEnv];

export function getOrganization() {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/organizations/invite`, {
        headers: {
          token:
            "eyJraWQiOiI1alBpcUoyNkVSQlBta0hNRjVWSFU3ZTF3MW03RnVwcmdtSEhUdjVvbGFrPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJmYThhZTEwNy1kMTQ1LTQ2MjAtOTU3OC02ODFmZjE0OGFhZjkiLCJldmVudF9pZCI6IjU5M2I1ODY0LTkyMzgtNGQzYy1iZmQzLWFiYjRkODNhNDQ4NyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE1OTA2NTQ2NjYsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xX1k5WmNBZjZ0bCIsImV4cCI6MTU5MDY1ODI2NiwiaWF0IjoxNTkwNjU0NjY2LCJqdGkiOiIxOWQ0MWVlZi05NjI5LTQ0ZDItODliNC0zODA1MjlhYzFkNTYiLCJjbGllbnRfaWQiOiI0ZDA2MTM3a3ZkMzMxYnNtYmozZGltNnFkMCIsInVzZXJuYW1lIjoiZmE4YWUxMDctZDE0NS00NjIwLTk1NzgtNjgxZmYxNDhhYWY5In0.cF5x-4ioaDbcNmP3kcRYaIhLkAKjQebPTdd_d4cByNQ7n4fvp_uEHcpZ9yKzMRtInmAaiTTYdDeHTei6nlpGhu6ziySlJJAghaqkEJDYF5ktnNF-qHRyfN05GXBnen7bnd9leT8ZwvHoIKt1BK2fdi7CyOOw9aHFnZKJubojqsY8ZIr-LIBYu5RksxiMRwsgHzRW3-3a0PXfdceb3u6PY1XsVdqOaX2jDAla8wTwGnfSXjxw0m8HuMfesDzMHCXh_k2iYWR05cJ4A5AEYY26hSHN-X3N1e8LFdsfaxirhEx67sDxMGmDxGks2jdIphxmPq06uAezLzXoYYgRGz12Tg",
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function checkCode(code) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/auth/verifyToken`, {
        returnEmail: true
        }, {
        headers: {
          token: code,
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
