import React, { Component } from "react";
import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { markdowntoPlainHtml, serialize } from '../../utils/markdowntoreact';

import {
  BaseTextField,
  BaseButton,
  AlertAction,
  GridContainer,
  GridItem,
  MasterLayoutMiddle,
  FooterInner
} from "@dclab/dc-react-ui-kit";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextEditor from "../../components/TextEditor/TextEditor";
import { editOrganization, getOrganizationData,getTeamMember } from "./apis";
import "./style.css";
import "../style.css";
import messages_content from '../../utils/messages.json';
import Grid from '@material-ui/core/Grid';
const { 
  thank_email_already_sent,
  message_sent_successfully
} = messages_content;
const defaultTemplate = [
  { type: "paragraph", children: [{ text: "{donor_name}," }] },
  {
    type: "paragraph",
    children: [
      {
        text:
          "We just want to send you a quick thank you note for your support. Your generosity will help us to improve our services in serving the community with better and creative ways. ",
      },
    ],
  },
  {
    type: "paragraph",
    children: [{ text: "Greatly appreciate your donations! " }],
  },
  { type: "paragraph", children: [{ text: "" }] },
  { type: "paragraph", children: [{ text: "Thanks - " }] },
  { type: "paragraph", children: [{ text: "{{Org Owner}}" }] },
  {
    type: "paragraph",
    children: [{ text: "{{Org Name}}" }],
  },
];
class ThankDonorsNonEditable extends Component {
  constructor(props) {
    const details = JSON.parse(localStorage.getItem("Donation Details"));
    super(props);
    this.state = {
      templateDescription: [{ type: "paragraph", children: [{ text: " " }] }],
      subject: "",
      loading: false,
      error: false,
      showAlert: false,
      recurring: false,
      success: false,
      email: details?.email,
      emailError: false,
      orgData: {},
      contentLoading: true,
    };
    this.sendEmail = this.sendEmail.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  async componentDidMount() {
    const details = JSON.parse(localStorage.getItem("Donation Details"));
    const name = details?.name;
    const arr = name?.split(" ");
    const orgId = localStorage.getItem("orgID");
    const token = localStorage.getItem("authToken");
    try {
      const res = await getOrganizationData(orgId, token);
      const thankYouEmail = res?.data?.body?.data[0]?.enabledIntegrations?.deenfund?.settings?.emailTemplates?.thankYouEmail;
      const orgName = res?.data?.body?.data[0]?.orgName;
      const orgMembers = res?.data?.body?.data[0]?.orgTeamMembers;   
      let orgOwner = ""; 
      if (orgMembers && orgMembers.length) {
        let owner = orgMembers.find((el) => el.role === "owner");
        if(owner){
          const res2 = await getTeamMember(owner.userId, token)
          let resData = res2?.data?.body?.data
          orgOwner = resData?.name?.firstName ? resData.name.firstName + " " + resData.name.lastName : ""
        }
      }  
      console.log("thankYouEmail", thankYouEmail)

      this.setState({
        orgData: res?.data?.body?.data[0]?.enabledIntegrations?.deenfund,
        // templateDescription: thankYouEmail?.content
        //   ? JSON.parse(thankYouEmail?.content)
          // : defaultTemplate,
        subject: thankYouEmail?.subject
          ? thankYouEmail?.subject
          : "Thank you for your generous donation",
        contentLoading: false,
      });

      let temp = thankYouEmail?.content ? JSON.parse(thankYouEmail?.content) : defaultTemplate;

      if (temp) {
        console.log("temp", temp)
        if(temp && temp.length) {
          temp[0].children[0].text = arr[0] + ",";
          temp[temp.length - 2].children[0].text = orgOwner;
          temp[temp.length - 1].children[0].text = orgName;
          this.setState({ templateDescription: temp });
        }
      }
    } catch (error) {
      this.setState({ error: error });
    }
  }

  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  changeHandler = (val) => {
    const emailRegex = /(^[0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*)@([0-9a-zA-Z]+(?:[._-][0-9a-zA-Z]+)*\.[0-9a-zA-Z]{2,3})$/i;
    if (!emailRegex.test(val)) {
      this.setState({
        email: val,
        emailError: true,
      });
    } else {
      this.setState({
        email: val,
        emailError: false,
      });
    }
  };
  sendEmail = async () => {
    const { email, templateDescription, orgData, subject } = this.state;
    const editor = {
      children: [
        ...templateDescription
      ]
    }
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    const sentDate = new Date().getTime();
    let prevDonorLog = orgData?.donorsLog?.length ? orgData.donorsLog : [] 
    prevDonorLog.push({
      emailTo: email,
      sentBy: localStorage.getItem("name"),
      sentDate: sentDate,
      receiptNumber: JSON.parse(localStorage.getItem("Donation Details"))?.receiptNumber,
      templateDescription: serialize(editor),
      subject: subject
    })
    console.log("emailtest", prevDonorLog[prevDonorLog.length - 1])
    const obj = {
      'enabledIntegrations.deenfund.donorsLog': prevDonorLog
    };

    try {
      await editOrganization(obj, orgId, token);
      this.setState({
        showAlert: true,
        success: true,
      });
      this.hideAlert();
      this.props.history.push({
        pathname: "/deenfund/donations/details",
        state: { showMessageAlert: true }
      })
      
      
    } catch (error) {
      console.log("error",  error)
      this.setState({ error: error.response.data.message });
    }
  };
  render() {
    const {
      templateDescription,
      subject,
      loading,
      error,
      showAlert,
      success,
      email,
      emailError,
      recurring,
      contentLoading,
    } = this.state;
        
    // console.log("templateDescription", templateDescription);

    const details = JSON.parse(localStorage.getItem("Donation Details"));
    const name = details?.name;
    const style = {
      width: '100%',
    };
    return (
      <MasterLayoutMiddle
        header={
          <div
            onClick={() =>
              this.props.history.push("/deenfund/donations/details")
            }
            className="backBtn"
          >
            <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
            <p style={{ alignSelf: "center" }}>Back</p>{" "}
          </div>
        }
        isForm={true}
        formData={
          <section>
            {showAlert && success && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={message_sent_successfully}
                  alertType={"success"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {showAlert && recurring && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={thank_email_already_sent}
                  alertType={"error"}
                  onClick={() =>
                    this.setState({ showAlert: false, recurring: false })
                  }
                />
              </div>
            )}


            <Grid container spacing={3}>

              <Grid item xs={12}>
                <h1 className="title_top">
                  Send Thanks to <strong>{name}</strong>
                </h1>
                <div class="dasheddBorder"></div>
              </Grid>

            </Grid>



            {contentLoading ? (
              <div
                style={{
                  marginTop: 350,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
                <Grid container spacing={3}>


                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        label="Send to"
                        style={{ width: 570 }}
                        value={email}
                        onChange={(e) => this.changeHandler(e.target.value)}
                        error={(error && !email) || emailError}
                        helperText={
                          (error && !email && "Email is required") ||
                          (emailError && "Invalid format")
                        }
                        placeholder=" "
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        label="Subject"
                        style={{ width: 570 }}
                        helperText=" "
                        value={subject}
                        placeholder=" "
                        onChange={(e) => this.setState({ subject: e.target.value })}
                      // disabled
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <p className="desc">Message</p>
                    <div className="TextEditor">
                      <TextEditor
                        onChange={(state) =>
                          this.setState({ templateDescription: state })
                        }
                        value={templateDescription}
                        longCount={0}
                        disabled={true}
                      />
                    </div>
                    <div className="lowerEditorService">
                      <p className="lowerEditorPara">
                        Edit this message from DEENFUND &gt;{" "}
                        <Link
                          to="/deenfund/thank-donors"
                          style={{ color: "#2A82F6", textDecoration: "none" }}
                        >
                          Thank Donors
                              </Link>
                      </p>
                    </div>
                  </Grid>
                  {loading ? (
                    <div
                      style={{
                        marginTop: 30,
                        display: "flex",
                        justifyContent: "center",

                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                      <Grid item xs={12}>
                        <div className="form_button">
                          <p className="cancel" onClick={() => this.props.history.push("/deenfund/donations/details")} >CANCEL</p>
                          <BaseButton
                            text="Send Now"
                            onClick={() => this.sendEmail()}
                          />
                        </div>
                      </Grid>
                    )}
                </Grid>

              )}
          </section>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={true}
      />
    );
  }
}
export default ThankDonorsNonEditable;
