import React, { useState } from "react";
import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Grid from '@material-ui/core/Grid';
import { TextArea, MasterLayoutMiddle, FooterInner, TextEditor, BaseButton,BaseImgChip } from "@dclab/dc-react-ui-kit";
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import IconButton from '@material-ui/core/IconButton';
import './style.css'



import { } from "@dclab/dc-react-ui-kit";

let chipData = [
  {
    key: 0,
    label: "Image Chip",
    src:
      "https://audioengineusa.com/wp-content/uploads/2019/01/a2.jpg",
    alt: "",
  },

];

const style = {
  width: '100%',
};

const onInputClick = () => {
  console.log("input clicked");
};

const onChipInputClick = () => {
  console.log("input chip clicked");
};

const deleteChip = () => {
  console.log('chip deleted')
}




function EditUpdate(props) {

  const [value, setValue] = useState([{ type: 'paragraph', children: [{ text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." }] }]);

  const onChange = (e) => {
    setValue(e)
  };

  const styles = {
    border: '1px solid black',
    color: 'gray'
  };

  return (

    <MasterLayoutMiddle
      header={
        <div className="backBtn">
          <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
          <Link
            to={{ pathname: "/admin-profile", state: 1, }}
            style={{ alignSelf: "center", color: "black", textDecoration: "none", }} > Back </Link>{" "}
        </div>
      }

      isForm={true}
      formData={
        <div>
          <Grid container spacing={3}>

            <Grid item md={12}>
              <h1 className='title_top'>Project Description</h1>
              <div className="dasheddBorder"></div>
            </Grid>

            <Grid item xs={12}>
              <TextEditor
                onChange={(e) => onChange(e)}
                disabled={false}
                value={value}
                style={styles}
                label={"Description"}
                helperText={"Click on + ADD TEXT below add image"}
                maxCharCount={200}
              />
            </Grid>

            <Grid item xs={12}>
              <div className="field_box">
                <BaseImgChip
                  chipData={chipData}
                  onInputClick={onInputClick}
                  onChipInputClick={onChipInputClick}
                  deleteChip={deleteChip}
                  helperText=''
                  label="Upload Image"
                // textStyle={styles}
                // arrowClick={arrowClick}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="uploadContainer">

                <div className="uploadcol">
                  <input accept="image/*" className="iconUploadField" id="icon-button-file" type="file" />
                  <label htmlFor="icon-button-file">
                    <IconButton className="Uploadbtn" aria-label="upload picture" component="span">
                      <AddCircleRoundedIcon /> 
                  </IconButton> <span className="UploadbtnText">Upload image</span>
                  </label>
                </div>

                <div className="uploadcol">
                  <input accept="image/*" className="iconUploadField" id="icon-button-file" type="file" />
                  <label htmlFor="icon-button-file">
                    <IconButton className="Uploadbtn" aria-label="upload picture" component="span">
                      <AddCircleRoundedIcon /> 
                  </IconButton> <span className="UploadbtnText">Add video</span>
                  </label>
                </div>

              </div>
            </Grid>


            <Grid item md={12} className="buttons">
              <span className="btnCencel" onClick={() => props.history.push("/admin-profile")}><BaseButton text="CANCEL" /></span>
              <BaseButton text="Post now" />
            </Grid>

          </Grid>
        </div>
      }



      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}

    />
  );
}
export default EditUpdate;