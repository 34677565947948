import React, { Component } from 'react';
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import {
  SimpleTable,
  BasePaper,
  BaseTabs,
  AlertAction,
  GridContainer,
  GridItem,
  MasterLayoutMiddle,
  FooterInner,
  CardDetailsTable,
  AlertDialogueAdvance,
  CardStatus
} from '@dclab/dc-react-ui-kit';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import './style.css';
import '../../App.css';
import '../style.css';
import {
  editOrganization,
  getOrganizationData,
  deleteTeamMember,
  getTeamMembers,
} from './api';
import Edit from '../../assets/img/create-24px.svg';
import Delete from '../../assets/img/delete_forever-24px.svg';
import { getOrgData } from '../../redux/actions/newOrgAction';
import { addInquiry } from '../../redux/actions/inquiryTypesAction';
import { editTeamMember } from '../../redux/actions/teamMemberAction';
import ContactInfo from '../../components/orgOverview/contactInfo';
import AboutOrg from '../../components/orgOverview/aboutOrg';
import { stateAbbreviations } from '../../utils/statesAbbreviations';
import { states } from '../../utils/states';
import { hideAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
import { ReactComponent as Crown } from '../../assets/img/crown.svg'
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
const {
  token_expired,
  success_message_add_desc
} = messages_content;
class Overview extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.unblockHandle = React.createRef();
    this.state = {
      orgData: {},
      teamMembers: [],
      error: false,
      success: false,
      tabInd: props.location.state ? props.location.state : 0,
      activeTab: props.location.state ? props.location.state : 0,
      showForm: false,
      statesArr: [],
      showEIN: false,
      showAlert: false,
      alertMsg: '',
      loading: false,
      openAlert: false,
      ownerDelete: false,
      deleteTeamMemberId: '',
      taxData: [],
      teamMembersLoading: true,
      modalLoading: false,
      longDescriptionCount: 0,
      shortDescriptionCount: 0,
      missionDescriptionCount: 0,
      visionDescriptionCount: 0,
      longDescription: [{ type: 'paragraph', children: [{ text: '' }] }],
      longDescriptionTemp: [{ type: 'paragraph', children: [{ text: '' }] }],
      shortDescription: [{ type: 'paragraph', children: [{ text: '' }] }],
      shortDescriptionTemp: [{ type: 'paragraph', children: [{ text: '' }] }],
      mission: [{ type: 'paragraph', children: [{ text: '' }] }],
      missionTemp: [{ type: 'paragraph', children: [{ text: '' }] }],
      vision: [{ type: 'paragraph', children: [{ text: '' }] }],
      visionTemp: [{ type: 'paragraph', children: [{ text: '' }] }],
      detailsLoading: true,
      state: '',
      status: "",
      AlertModal: false,
      route: '',
      stateAbreviation: '',
      changes: false,
      loggedInUserRole: ''
    };
    this.handleTabChange = this.handleTabChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.details = this.details.bind(this);
    this.editAction = this.editAction.bind(this);
    this.deleteMember = this.deleteMember.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.validatePhone = this.validatePhone.bind(this);
    this.onChanges = this.onChanges.bind(this);
  }
  visibleModal = () => {
    this.setState({ AlertModal: true })
  }
  componentWillUnmount() {
    this.unblockHandle.current = false
  }
  componentDidMount = async () => {
    const { longDescription, shortDescription, vision, mission } = this.state;
    const role = localStorage.getItem("role");
    this.setState({ loading: true, loggedInUserRole: role })
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    try {
      const res = await getOrganizationData(orgId);
      const {
        orgTeamMembers,
        taxIdStatus,
        aboutOrg,
        orgAddress,
        orgStatus
      } = res.data.body.data;
      this.setState({ status: orgStatus })
      if (orgTeamMembers?.length > 0) {
        try {
          const res2 = await getTeamMembers(token);
          this.setState({
            teamMembers: res2.data.body.data,
            teamMembersLoading: false,
          });

        } catch (error) {
          console.log(error)
          this.setState({
            error,
          });
        }
      }
      let data =
        taxIdStatus &&
        taxIdStatus.events?.length > 0 &&
        taxIdStatus.events.sort((a, b) => b.updatedAt - a.updatedAt);
      const longDes = aboutOrg && JSON.parse(aboutOrg.aboutUsLong);
      const shortDes = aboutOrg && JSON.parse(aboutOrg.aboutUsShort);
      const misDes = aboutOrg && JSON.parse(aboutOrg.orgMission.content);
      const visDes = aboutOrg && JSON.parse(aboutOrg.orgVision.content);
      this.setState({
        orgData: res.data.body.data,
        state: states.filter((state) => state.name === orgAddress.state),
        stateAbreviation: stateAbbreviations[orgAddress.state]
          ? stateAbbreviations[orgAddress.state]
          : orgAddress.state,
        detailsLoading: false,
        longDescription: longDes ? longDes : longDescription,
        longDescriptionTemp: longDes ? longDes : longDescription,
        shortDescription: shortDes ? shortDes : shortDescription,
        shortDescriptionTemp: shortDes ? shortDes : shortDescription,
        mission: misDes ? misDes : mission,
        missionTemp: misDes ? misDes : mission,
        vision: visDes ? visDes : vision,
        visionTemp: visDes ? visDes : vision,
        taxData: data,
        loading: false
      });
      this.props.getOrganizationData(res.data.body.data);
    } catch (e) {
      this.setState({ error: e, loading: false });
      console.log(e, 'check')
    }
  };
  onLongDescriptionChange = (data) => {
    this.setState({ longDescription: data });
  }
  onShortDescriptionChange = (data) => {
    this.setState({ shortDescription: data });
  }
  onMissionChange = (data) => {
    this.setState({ mission: data });
  }
  onVisionChange = (data) => {
    this.setState({ vision: data });
  }
  validatePhone = (name, e) => {
    if (name === 'primary') {
      const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      if (phoneRegex.test(e)) {
        this.setState({
          primary_phone2: e,
          primaryPhoneError: false,
        });
      } else {
        this.setState({
          primary_phone2: e,
          primaryPhoneError: true,
        });
      }
    } else if (name === 'secondary') {
      const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      if (phoneRegex.test(e)) {
        this.setState({
          secondary_phone2: e,
          secondaryPhoneError: false,
        });
      } else {
        this.setState({
          secondary_phone2: e,
          secondaryPhoneError: true,
        });
      }
    }
  };
  handleClose = () => {
    this.setState({
      openAlert: false,
      ownerDelete: false,
      deleteTeamMemberId: '',
      openInquiryAlert: false,
      deleteInquiryId: '',
    });
  };
  handleConfirm = async () => {
    this.setState({ modalLoading: true });
    const { orgData, deleteTeamMemberId } = this.state;
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    const newArr = orgData.orgTeamMembers.filter(
      (mem) => mem !== deleteTeamMemberId
    );
    const ob = {
      orgTeamMembers: newArr,
    };
    try {
      await deleteTeamMember(deleteTeamMemberId, token);
      await editOrganization(ob, orgId, token);
      const res3 = await getOrganizationData(orgId);
      this.props.getOrganizationData(res3.data.body.data);
      this.setState({
        teamMembers: this.state.teamMembers.filter(
          (member) => member._id !== deleteTeamMemberId
        ),
        orgData: res3.data.body.data,
        openAlert: false,
        modalLoading: false,
      });
    } catch (error) {
      this.setState({
        error: error.response.data.message,
        openAlert: false,
        modalLoading: false,
      });
    }
  };
  deleteMember = (item) => {
    console.log("team member", item[3].owner )
    if(item && item[3].owner) {
      this.setState({ openAlert: true, ownerDelete: true, deleteTeamMemberId: item[2].label });
    } else {
      this.setState({ openAlert: true, ownerDelete: false, deleteTeamMemberId: item[2].label });
    }
  };
  onClick = async (x, y) => {
    const { changes } = this.state;
    this.setState({ tempIndex: y })
    if (!changes) {
      this.setState({
        tabInd: y,
      });
      if (y === 0) {
        let orgId = localStorage.getItem('orgID');
        try {
          const res = await getOrganizationData(orgId);
          this.setState({ orgData: res.data.body.data });
        } catch (err) {
          this.setState({ error: err });
        }
      }
      if (y === 3) {
        const { orgData } = this.state;
        this.setState({
          primary_phone:
            (orgData.phoneFax && orgData.phoneFax.primaryPhone) || '',
          secondary_phone:
            (orgData.phoneFax && orgData.phoneFax.secondaryPhone) || '',
          email: orgData.orgEmail || '',
        });
      }
    } else {
      this.setState({ AlertModal: true })
    }
  };
  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };
  details = (name) => {
    if (name[0].value === 'Tax ID/EIN') {
      this.props.history.push('/organization/overview/taxein');
    } else if (name[0].value === 'Tax Exemption') {
      this.props.history.push('/organization/overview/taxein');
    }
  };
  editAction = () => {
    this.setState({
      showEditDiv: true,
    });
  };
  teamActionClicked = (data) => {
    console.log("Data is: ", data);
    this.props.history.push({
      pathname: "/organization/overview/team-member/details",
      state: {member: data[0]?.member}
    })
  }
  onChanges = (ev) => {
    this.setState({ changes: ev })
  }

  parseTaxStatus = (status) => {
    console.log(status)
    let tempStatus = ''
    if(status==='pending'){
      tempStatus = 'Pending'
    }
    
    else if(status==='approved'){
      tempStatus = 'Approved'
    }

    else if(status==='failed'){
      tempStatus = 'Failed'
    }
    return tempStatus
  }

  render() {
    const {
      tabInd,
      showForm,
      AlertModal,
      primary_phone,
      secondary_phone,
      email,
      openAlert,
      taxData,
      orgData,
      activeTab,
      teamMembersLoading,
      detailsLoading,
      modalLoading,
      loading,
      longDescription,
      shortDescription,
      mission,
      vision,
      longDescriptionCount,
      shortDescriptionCount,
      missionDescriptionCount,
      visionDescriptionCount,
      stateAbreviation,
      state,
      changes,
    } = this.state;
    console.log(orgData)
    if (this.state.changes) {
      const { history } = this.props
      this.unblockHandle.current = history.block((route) => {
        const { changes } = this.state
        this.setState({ route: route.pathname })
        changes && this.visibleModal();
        return !changes
      });
    }
    if (this.props.alert.alertValue) {
      setTimeout(() => {
        this.props.alertHide()
      }, 8000);
    }
    let alertModal = (
      <Dialog
        open={openAlert}
        onClose={() => this.handleClose()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{!this.state.ownerDelete ? 'Are you sure?' : "Owner Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {this.state.ownerDelete ? "You cannot delete Owner of the organization" : "Do you want to delete this team member? This can't be undone."}
          </DialogContentText>
        </DialogContent>
        {modalLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 50,
            }}
          >
            <CircularProgress
              color='primary'
              style={{ width: 24, height: 24 }}
            />
          </div>
        ) : (
            <DialogActions>
              <Button onClick={() => this.handleClose()} color='primary'>
                Cancel
            </Button>
              {!this.state.ownerDelete ? <Button onClick={() => this.handleConfirm()} color='primary'>
                Confirm
            </Button> : "" }
            </DialogActions>
          )}
      </Dialog>
    );
    
    const obj = [
      {
        label: 'Details',
        item: '',
      },
      {
        label: 'Team Members',
        item: '',
      },
      {
        label: 'About Org',
        item: '',
      },
      {
        label: 'Contact Info',
        item: '',
      },
    ];
    const data = [
      {
        field: "Org Type",
        value: orgData && orgData.orgType
      },
      {
        field: "Phone",
        value: orgData && orgData.phoneFax && orgData.phoneFax.primaryPhone + (orgData && orgData.phoneFax && orgData?.phoneFax?.secondaryPhone?.length > 8 ? '</br>' + orgData?.phoneFax?.secondaryPhone : '')
      },
      {
        field: "Address",
        value: orgData && orgData.orgAddress && `${orgData?.orgAddress?.line1} ${orgData?.orgAddress?.line2 ? orgData?.orgAddress?.line2 : ''} </br> ${orgData?.orgAddress?.city}, ${stateAbreviation} ${orgData?.orgAddress?.postalCode}`
      },
      {
        field: "Short Name",
        value: orgData && orgData.orgShortName
      },
      {
        field: "Email",
        value: orgData && orgData.orgEmail
      },
      {
        field: '',
        value: ' '
      },
      {
        field: "Icon",
        value: orgData?.orgIcon?.iconUrl ? `Added <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style='margin-left: 5px' viewBox="0 0 24 24"><defs><style>.a{fill:none;}.b{fill:#3cc480;}</style></defs><path class="a" d="M0,0H24V24H0Z"/><path class="b" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM10,17,5,12l1.41-1.41L10,14.17l7.59-7.59L19,8Z"/></svg>` : '<span className="ondisableText">No icon</span>'
      },
      {
        field: "Tax Exemption",
        value: orgData?.taxIdStatus?.taxExemptDetail?.status ? `<span>${orgData.taxIdStatus.taxExemptDetail.status?.toLowerCase() === 'approved' ?  this.parseTaxStatus(orgData.taxIdStatus.taxExemptDetail.status) +  '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style=\'margin-left: 5px; position: relative; top: 4px;\' viewBox="0 0 24 24"><defs><style>.a{fill:none;}.b{fill:#3cc480;}</style></defs><path class="a" d="M0,0H24V24H0Z"/><path class="b" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM10,17,5,12l1.41-1.41L10,14.17l7.59-7.59L19,8Z"/></svg>' : this.parseTaxStatus(orgData?.taxIdStatus?.taxExemptDetail?.status)} </span>` : '<span className="ondisableText">Not yet submitted</span>'
      },
      {
        field: "Website",
        value: orgData && orgData.orgWebsite ? orgData.orgWebsite : '<span className="ondisableText">No website</span>'
      },
      {
        field: "Logo",
        value: orgData && orgData.orgLogo?.logoUrl ? `Added <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style='margin-left: 5px' viewBox="0 0 24 24"><defs><style>.a{fill:none;}.b{fill:#3cc480;}</style></defs><path class="a" d="M0,0H24V24H0Z"/><path class="b" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM10,17,5,12l1.41-1.41L10,14.17l7.59-7.59L19,8Z"/></svg>` : ''
      },
    ]
    let header = {
      tableName: orgData && orgData.orgName,
      status: orgData && orgData.orgStatus?.toLowerCase() === "approved" && [<CardStatus styleClassName="success" text="Approved" style={{ paddingTop: "0px" }} />] || orgData && orgData.orgStatus?.toLowerCase() === "onboarding" && [<CardStatus styleClassName="warning" text="Onboarding" style={{ paddingTop: "0px" }} />] || [<CardStatus styleClassName="danger" text="Blocked" style={{ paddingTop: "0px" }} />]
    };
    const details = (
      <CardDetailsTable
        data={data}
        header={header}
        footerLinkName={'Edit Organization'}
        onClick={() => this.props.history.push({pathname: '/organization/overview/edit-details', state: { prevPath: 'overview' }})}
      />
    );

    const getVerificationTableStatus = (status) => {
      status = status?.toLowerCase();
      switch (status) {
        case 'rejected':
          return 'failed'
        
        case 'approved':
          return 'success'
        
        case 'pending':
          return 'pending'

        default:
          return 'failed'
      }
    };

    let col = [
      { id: 'NAME', label: 'NAME', width: '20%' },
      { id: 'Submitted', label: 'SUBMITTED', width: '25%' },
      { id: 'REVIEWED', label: 'REVIEWED', width: '30%' },
      { id: 'STATUS', label: 'STATUS', width: '20%' },
      { id: 'ACTION', label: 'ACTION', width: '15%' },
    ];
    let rows = [
      [
        { type: 'string', name: 'date', value: 'Tax ID/EIN' },
        {
          type: 'string',
          name: 'date',
          value:
          orgData?.taxIdStatus?.taxIdEinDetail?.submittedAt
              ? Moment(orgData.taxIdStatus.taxIdEinDetail.submittedAt).format(
                'MMM DD, YYYY, hh:mm A'
              )
              : ' ',
        },
        {
          type: 'string',
          name: 'date',
          value:
          orgData?.taxIdStatus?.taxIdEinDetail?.reviewedAt
          ? Moment(orgData.taxIdStatus.taxIdEinDetail.reviewedAt).format(
            'MMM DD, YYYY, hh:mm A'
          )
          : ' ',
        },
        {
          type: 'status',
          status: 
          orgData?.taxIdStatus?.taxIdEin?.length && 
          orgData?.taxIdStatus?.taxIdEinDetail?.status 
            ? getVerificationTableStatus(orgData.taxIdStatus.taxIdEinDetail.status): 
            'failed',
          value:
          orgData?.taxIdStatus?.taxIdEin?.length && 
          orgData?.taxIdStatus?.taxIdEinDetail?.status 
            ? orgData.taxIdStatus.taxIdEinDetail.status
              : 'Submission Required',
        },
        { type: 'action', status: 'details', value: orgData?.taxIdStatus?.taxIdEin?.length ? 'see details' : 'submit now'},
      ],
      [ 
        { type: 'string', name: 'date', value: 'Tax Exemption' },
        { type: 'string', name: 'date', value: orgData?.taxIdStatus?.taxExempt && orgData?.taxIdStatus?.taxExemptDetail?.submittedAt && Moment(orgData.taxIdStatus.taxExemptDetail.submittedAt).format('MMM DD, YYYY, hh:mm A') },
        { type: 'string', name: 'date', value: orgData?.taxIdStatus?.taxExempt && orgData?.taxIdStatus?.taxExemptDetail?.reviewedAt && Moment(orgData.taxIdStatus.taxExemptDetail.reviewedAt).format('MMM DD, YYYY, hh:mm A') }, 
        { type: 'status', 
          status: 
            orgData?.taxIdStatus?.taxExempt && 
            orgData?.taxIdStatus?.taxExemptDetail?.status 
            ? getVerificationTableStatus(orgData.taxIdStatus.taxExemptDetail.status)
            : '' , 
          value: 
            orgData?.taxIdStatus?.taxExempt && 
            orgData?.taxIdStatus?.taxExemptDetail?.status 
            ? orgData.taxIdStatus.taxExemptDetail.status 
            :  'Not yet submitted' },
        // { type: 'status', status: orgData && orgData.taxIdStatus && orgData.taxIdStatus.taxExempt ? 'pending' : 'failed', value: orgData && orgData.taxIdStatus && orgData.taxIdStatus.taxExempt ? 'Pending' : 'Submission Required' },
        { type: 'action', status: 'details', value: orgData?.taxIdStatus?.taxExempt ? 'see details' : 'submit now' },
      ],
    ];
    let classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = 'class15';
      return tempArray;
    };
    const getStatus = (status) => {
      status = status.toLowerCase();
      switch (status) {
        case 'active':
          return 'success'
        
        case 'invite sent':
          return 'invite'
        default:
          return 'failed'
      }
    }
    const table = (
      <SimpleTable
        showHeader={true}
        title='Verification'
        rows={rows}
        columns={col}
        colSpan={16}
        actionSendButton={true}
        classProps={changeClassProps()}
        donationsCount={rows && rows.length > 0 ? 1 : 0}
        noTitle="No Verification added"
        noPara="Added Verification will be displayed and managed here"
        noBtnText=""
        actionLink={(name) => this.details(name)}
      />
    );
    let teamCol = [
      { id: 'NO', label: 'NO.', width: 'auto' },
      { type: 'url', name: 'single_pic', label: 'IMAGE', width: 'auto' },
      { type: 'string', name: 'name', label: 'NAME', width: '20%' },
      { type: 'string', name: 'category', label: 'ROLE', width: '40%' },
      { type: 'string', name: 'category', label: 'EMAIL', width: '21%' },

      { id: 'STATUS', label: 'ACCESS', width: '20%' },
      { id: 'ACTION', label: 'ACTION', width: '15%' },
    ];
    let teamRows =
      this.state.teamMembers?.length > 0 &&
      this.state.teamMembers.map((member, i) => {
        
        let arr = [];
        if (member.name.firstName) {
          arr.push({
            type: 'string',
            name: 'name',
            value: `${member.name.firstName + (member.name.lastName ? " " + member.name.lastName : '')}`,
            label: member._id,
          });
        }
        if (member.role?.length > 0) {
          let arr2 = [];
          member.role.map((r) => {
            arr2.push(
              r.roles[0].toUpperCase() +
              r.roles.slice(1)
              
            );
          });
          arr.push({
            type: 'string',
            name: 'category',
            value: arr2.map((a) => <p>{a + '  '} {a === "Owner" && <Crown />} </p>),
            owner: member.role.find((el) => el.roles === "owner") ? true : false
          });
        }
        if (member.userName) {
          arr.push({
            type: 'string',
            name: 'category',
            value: member.userName,
          });
        }
        if (member.access) {
          arr.push({
            type: 'status',
            status: getStatus(member.access),
            value: member.access[0].toUpperCase() + member.access.slice(1),
          });
        }
        if (!member.access) {
          arr.push({
            type: 'status',
            status: member.defaultOrganization === localStorage.getItem('orgID') ? 'success' : 'failed',
            value: member.defaultOrganization === localStorage.getItem('orgID') ? 'Active' : 'Inactive',
          });
        }
        arr.push({ type: "action", status: "success", value: "see details" });
        let fullNameMake = member.name.firstName + (member.name.lastName ? " " + member.name.lastName : '');
        let nameArr = fullNameMake.split(' ');
        let initials;
        if (nameArr?.length > 1) {
          initials = nameArr[0][0] + nameArr[1][0];
        } else {
          initials = nameArr[0][0];
        }
        arr.unshift({
          type: 'url',
          name: 'single_pic',
          initials: !member?.imageDetails?.image ? initials.toUpperCase() : '',
          src: member?.imageDetails?.image ?? '',
        });
        arr.unshift({ type: 'id', value: i + 1, member });
        return arr;
      });
    let teamClassProps = () => {
      const tempArray = [];
      teamCol.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const teamChangeClassProps = () => {
      const tempArray = teamClassProps();
      tempArray[0] = 'class15';
      return tempArray;
    };
    const teamTable = (
      <SimpleTable
        showHeader={true}
        title='Team Members'
        rows={teamRows}
        columns={teamCol}
        colSpan={16}
        actionSendButton={true}
        classProps={teamChangeClassProps()}
        donationsCount={
          this.state.teamMembers?.length === 0 ? 0 : this.state.teamMembers?.length
        }
        noTitle='No team members added'
        noPara='Added team members will be displayed and managed here'
        noBtnText='Add Team Member'
        onInviteClick={() => {
          this.props.history.push('/organization/overview/add-team-member');
        }}
        actionLink={(data) => this.teamActionClicked(data)}
        showIconCol={false}
        // editIcon={Edit}
        // deleteIcon={Delete}
        showFooter={true}
        showAdd={true}
        onAddClick={() => {
          this.props.history.push('/organization/overview/add-team-member');
        }}
        iconTextSize={16}
        sortColumnIndex={[5]}
        // editAction={(item) =>
        //   this.props.history.push(
        //     `/organization/overview/edit-team-member/${item[2].label}`
        //   )
        // }
        deleteAction={(item) => this.deleteMember(item)}
        addText='INVITE NEW'
        style={{ overflowX: 'hidden' }}
        disbaleActions = {this.state.loggedInUserRole === 'Owner' ? false :true}
        disbaleActionsTooltip = "You don't have access to this feature"


      />
    );
    const { error, success, showAlert } = this.state;
    const style = {
      width: '100%',
    };
    const saveChanges = async () => {
      const { tempIndex, route } = this.state;
      this.setState({
        tabInd: tempIndex,
        AlertModal: false,
        changes: false,
        longDescriptionTemp: longDescription,
        shortDescriptionTemp: shortDescription,
        missionTemp: mission,
        visionTemp: vision
      });
      this.child.current.save();
      if (this.unblockHandle) {
        this.unblockHandle.current()
      }
      if (route) {
        this.props.history.push(route)
      }
      if (tempIndex === 0) {
        let orgId = localStorage.getItem('orgID');
        try {
          const res = await getOrganizationData(orgId);
          this.setState({ orgData: res.data.body.data });
        } catch (err) {
          this.setState({ error: err });
        }
      }
      if (tempIndex === 3) {
        const { orgData } = this.state;
        this.setState({
          primary_phone:
            (orgData.phoneFax && orgData.phoneFax.primaryPhone) || '',
          secondary_phone:
            (orgData.phoneFax && orgData.phoneFax.secondaryPhone) || '',
          email: orgData.orgEmail || '',
        });
      }
    }
    const handleCloseDraft = async () => {
      this.setState({ AlertModal: false, activeTab: 2 })
    }
    const discardChanges = async () => {
      const { tempIndex, route, longDescriptionTemp, shortDescriptionTemp, missionTemp, visionTemp } = this.state;
      this.setState({
        tabInd: tempIndex,
        AlertModal: false,
        changes: false,
        longDescription: longDescriptionTemp,
        shortDescription: shortDescriptionTemp,
        mission: missionTemp,
        vision: visionTemp
      });
      if (this.unblockHandle) {
        this.unblockHandle.current()
      }
      if (route) {
        this.props.history.push(route)
      }
      if (tempIndex === 0) {
        let orgId = localStorage.getItem('orgID');
        try {
          const res = await getOrganizationData(orgId);
          this.setState({ orgData: res.data.body.data });
        } catch (err) {
          this.setState({ error: err });
        }
      }
      if (tempIndex === 3) {
        const { orgData } = this.state;
        this.setState({
          primary_phone:
            (orgData.phoneFax && orgData.phoneFax.primaryPhone) || '',
          secondary_phone:
            (orgData.phoneFax && orgData.phoneFax.secondaryPhone) || '',
          email: orgData.orgEmail || '',
        });
      }
    }
    return (
      <MasterLayoutMiddle
        header={
          <div>
            {error &&
              ((error.response &&
                error.response.data.message === 'Invalid token') ||
                error === 'Invalid token' ||
                error === 'No token Provided') && (
                <div
                  style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                >
                  <AlertAction
                    alert={token_expired}
                    alertType={'error'}
                    onClick={() => this.setState({ error: false })}
                  />
                </div>
              )}
            {showAlert && error && (
              <div
                style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={
                    error.response.data.message === 'Invalid token'
                      ? token_expired
                      : 'Error'
                  }
                  alertType={'error'}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {
              showAlert && success && (
                <div
                  style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                >
                  <AlertAction
                    alert={success_message_add_desc}
                    alertType={'success'}
                    onClick={() => this.setState({ showAlert: false })}
                  />
                </div>
              )}
            {
              this.props.alert.alertValue &&
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={this.props.alert.alertValue}
                  alertType={"success"}
                  onClick={() => this.props.alertHide()}
                />
              </div>
            }
          </div>
        }
        content={
          <>
            {showForm === false && (
              <GridContainer spacing="0">
                <BaseTabs
                  title=''
                  object={obj}
                  onClick={(x, y) => this.onClick(x, y)}
                  value={activeTab}
                  onChange={(event, newValue) =>
                    this.handleTabChange(event, newValue)
                  }
                />
                {tabInd === 0 &&
                  (detailsLoading ? (
                    <div
                      style={{
                        marginTop: 30,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                      <>
                        <GridItem itemStyle={{ marginBottom: 20 }}>
                          <div>
                            <BasePaper
                              context={details}
                              square={false}
                              paperStyle={styles.paperStyleNext}
                            />
                          </div>
                        </GridItem>
                        <GridItem
                          itemStyle={{ marginTop: 0 }}
                          itemClass='grid-item verify-table'
                        >
                          <div>
                            <BasePaper
                              context={table}
                              square={false}
                              paperStyle={styles.paperStyleNext}
                            />
                          </div>
                        </GridItem>
                      </>
                    ))}
                {tabInd === 1 &&
                  (teamMembersLoading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 250,
                      }}
                    >
                      <CircularProgress color='primary' />
                    </div>
                  ) : (
                      <>
                        <GridItem itemStyle={{ marginTop: 0 }} itemClass='grid-item'>
                          <div
                            style={{ boxShadow: '0px 2px 4px #0000001A' }}
                            className='team-members-table'
                          >
                            <BasePaper
                              context={teamTable}
                              square={false}
                              paperStyle={styles.paperStyleNext}
                            />
                          </div>
                        </GridItem>
                      </>
                    ))}
                {tabInd === 2 && (detailsLoading ? (
                  <div
                    style={{
                      marginTop: 30,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                    <GridItem>
                      <AboutOrg
                        ref={this.child}
                        history={this.props.history}
                        onChanges={(e) => this.onChanges(e)}
                        orgData={orgData.aboutOrg}
                        longDescription={longDescription}
                        shortDescription={shortDescription}
                        mission={mission}
                        vision={vision}
                        onLongDescriptionChange={(e) => this.onLongDescriptionChange(e)}
                        onShortDescriptionChange={(e) => this.onShortDescriptionChange(e)}
                        onMissionChange={(e) => this.onMissionChange(e)}
                        onVisionChange={(e) => this.onVisionChange(e)}
                        longDescriptionCount={longDescriptionCount}
                        shortDescriptionCount={shortDescriptionCount}
                        missionCount={missionDescriptionCount}
                        visionCount={visionDescriptionCount}
                      />
                    </GridItem>
                  ))}
                {tabInd === 3 && (loading ? (
                  <div
                    style={{
                      marginTop: 30,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                    <GridItem itemStyle={{ marginTop: 0 }}>
                      <ContactInfo
                        orgData={orgData}
                        teamMembers={this.state.teamMembers}
                        email={email}
                        primary_phone={primary_phone}
                        secondary_phone={secondary_phone}
                      />
                    </GridItem>
                  ))}
                <div>
                  <AlertDialogueAdvance
                    title="You have unsaved changes!"
                    description='There are unsaved changes. If you would like to save changes, press the "SAVE AS DRAFT" button.'
                    cancelText="Discard Changes"
                    confirmText="SAVE AS DRAFT"
                    open={AlertModal}
                    handleCancel={() => saveChanges()}
                    handleClose={() => handleCloseDraft()}
                    handleConfirm={() => discardChanges()}
                    styles={Alertstyle}
                  />
                </div>
              </GridContainer>
            )}
            {openAlert && alertModal}
          </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={false}
      />
    );
  }
}
const styles = {
  paperStyle: {},
  paperStyleNext: {},
};
const Alertstyle = {
  width: 470,
  marginLeft: '35%'
};
const mapStateToProps = (state) => ({
  addOrg: state.addOrg.orgData,
  inquiryTypes: state.inquiryTypes,
  alert: state.alert,
});
const mapDispatchToProps = (dispatch) => ({
  getOrganizationData: (res) => dispatch(getOrgData(res)),
  addNewInquiry: (name, members) => dispatch(addInquiry(name, members)),
  editTeamMember: (data) => dispatch(editTeamMember(data)),
  alertHide: (res) => dispatch(hideAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Overview);
