import * as constants from "../constants";

function showAlert(data) {
    return {
        type: constants.SHOW_ALERT,
        payload: data
    };
}

function hideAlert() {
    return {
        type: constants.HIDE_ALERT
    };
}

export { showAlert, hideAlert };