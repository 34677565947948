import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  SimpleAppBar,
  BasePaper,
} from "@dclab/dc-react-ui-kit";
import { Switch, Route } from "react-router-dom";
import MainLogo from "../../assets/img/DC_Logo.png";
import DeleteAccount from "./DeleteAccount";
import PrivateRoute from "../../components/PrivateRoute";

const AdminProfilewithoutSidebar = (props) => {

  function onClick() {
    props.history.push("/dashboard");
  }
  const logout = () => {
    localStorage.setItem("user", false);
    localStorage.setItem("authToken", "");
    props.history.replace("/signin");
    localStorage.clear();
  };
  const userProfile = () => {
    props.history.push("/admin-profile")
  };
  const navigate = (x, y) => {
    if (x === 'Support') {
      window.location.href = (y)
    } else {
      props.history.push(y)
    }
  }

  const headerItems = [
    {
      title: "Org Overview",
      path: "/organization/overview",
    },
  ];
  let image = localStorage.getItem("image");


  const content = (
    <React.Fragment>

      <div style={{
        boxShadow: "0px 1px 4px #0000001A",
        borderBottom: "1px solid #0000001A",
        position: "fixed",
        width: "100%",
        zIndex: 99999,
        backgroundColor: "#F7F8FA",
        top:"0px",
      }} >
        <SimpleAppBar
          src={MainLogo}
          name={localStorage.getItem("name") || props.signup.fullName}
          role={localStorage.getItem("role")}
          style={{ marginTop: 80, marginLeft: -10 }}
          showImg={image && image !== "undefined" ? true : false}
          img={image && image !== "undefined" ? image : ""}
          onClickLogout={() => logout()}
          onClickProfile={() => userProfile()}
        />
      </div>

      <div style={{
        paddingTop:"75px",
      }} >
      <Switch>

        <PrivateRoute
          exact path="/user-profile-delete-account" component={DeleteAccount} />
      </Switch>
      </div>


    </React.Fragment>
  );
  return (
    <>
      <BasePaper
        elevation={0}
        context={content}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    signup: state.signup,
  };
};
export default connect(mapStateToProps, {})(AdminProfilewithoutSidebar);
