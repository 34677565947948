import React, { Component } from 'react'
import { SimpleTable, AlertAction, GridContainer, GridItem, MasterLayoutMiddle, FooterInner } from "@dclab/dc-react-ui-kit";
import Edit from "../../../assets/img/create-24px.svg";
import Delete from "../../../assets/img/delete_forever-24px.svg";
import { getReminders, getReminder, deleteReminder, } from './Apis/index'
import '../../../App.css'
import { editReminder } from "../../../redux/actions/reminderAction";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { markdowntoPlainHtml } from "../../../utils/markdowntoreact";
import { hideAlert } from '../../../redux/actions/alertActions';
import '../../style.css'
import messages_content from '../../../utils/messages.json';
const {
    token_expired,
    success_message_reminder,
    success_message_on_update_reminder,
    remove_reminder_message
} = messages_content;
class ManageReminders extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reminders: [],
            error: '',
            deletesalahId: '',
            modalLoading: '',
            openAlert: '',
            isloading: false,
            success: false
        }
    }
    async componentDidMount() {
        this.setState({ isloading: true })
        const token = localStorage.getItem("authToken");
        const orgId = localStorage.getItem("orgID");
        try {
            const res = await getReminders(orgId, token);
            this.setState({
                reminders: res.data.body.data,
                isloading: false
            });
        } catch (error) {
            this.setState({ error: error && error.response && error.response.data.message, isloading: false });
        }
        setTimeout(() => {
            this.props.hideAlert()
        }, 8000)
    }
    editSalah = async (e) => {
        try {
            const token = localStorage.getItem("authToken");
            const res = await getReminder(e.label, token);
            this.props.editReminder(res.data.body.data)
            this.props.history.push("/editreminder");
        } catch (e) {
            this.setState({
                error: e.response && e.response.data.message,
                showAlert: true,
            });
            setTimeout(() => {
                this.setState({ error: false })
            }, 8000);
        }
    }
    handleClose = () => {
        this.setState({ openAlert: false });
    };
    handleServiceConfirm = async () => {
        const { reminders, deleteReminderId } = this.state
        this.setState({ modalLoading: true });
        const token = localStorage.getItem("authToken");
        const orgId = localStorage.getItem("orgID");
        try {
            await deleteReminder(deleteReminderId, token, orgId);
            const newReminders = reminders.filter(
                (item) => item._id !== deleteReminderId
            );
            this.setState({
                reminders: newReminders,
                success: true,
                openAlert: false,
                modalLoading: false,
            });
            setTimeout(() => {
                this.setState({ success: false })
            }, 8000);
        } catch (error) {
            this.setState({
                error: error && error.response && error.response.data.message,
                showAlert: true,
                openAlert: false,
                modalLoading: false,
            });
            setTimeout(() => {
                this.setState({ error: false })
            }, 8000);
        }
    }
    deleteSalah = async (e) => {
        this.setState({
            openAlert: true,
            deleteReminderId: e.label,
        });
    }
    render() {
        const { error, reminders, showAlert, success, openAlert, modalLoading, isloading } = this.state
        const { alertValue } = this.props
        let classProps = () => {
            const tempArray = [];
            col.forEach((column, index) => {
                tempArray.push(`class${index}`);
            });
            return tempArray;
        };
        const changeClassProps = () => {
            const tempArray = classProps();
            tempArray[0] = "class15";
            return tempArray;
        };
        const col = [
            { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
            { type: "link", name: "Title", label: "TITLE", width: '20%' },
            { type: "string", name: "details", label: "DETAILS", width: '80%' },
            { type: "string", name: "status", label: "STATUS", width: '15%' },
            { type: "action", name: "action", label: "ACTION", width: '15%' },
        ];
        const rows = reminders.map((item, i) => {
            return [
                { type: "id", value: `${i <= 8 ? '0' + (i + 1) : i + 1}`, label: item._id },
                { type: "string", name: "name", value: item.title },
                {
                    type: "string", name: "date", value:
                        markdowntoPlainHtml(
                            JSON.parse(item.description)
                        )[0].props.dangerouslySetInnerHTML?.__html.slice(0, 70) + "..."
                },
                {
                    type: "status",
                    status:
                        item.status === 'true' ? "success" : "failed",
                    value: item.status === 'true' ? "Active" : "Inactive",
                },
            ];
        });
        const alertServiceModal = (
            <Dialog
                open={openAlert}
                onClose={() => this.handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Reminder?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this reminder? <br/> Deleting will permanently remove from everywhere.
                    </DialogContentText>
                </DialogContent>
                {modalLoading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 50,
                        }}
                    >
                        <CircularProgress
                            color="primary"
                            style={{ width: 24, height: 24 }}
                        />
                    </div>
                ) : (
                        <DialogActions>
                            <Button onClick={() => this.handleClose()} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={() => this.handleServiceConfirm()} color="primary">
                                Confirm
                            </Button>
                        </DialogActions>
                    )}
            </Dialog>
        );
        const style = {
            width: '100%',
        };
        return (
            <MasterLayoutMiddle
                header={<div></div>}
                content={
                    <>
                        {error && error === "Invalid token" && (
                            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                                <AlertAction
                                    alert={token_expired}
                                    alertType={"error"}
                                    onClick={() => this.setState({ error: false })}
                                />
                            </div>
                        )}
                        {success && (
                            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                                <AlertAction
                                    alert={remove_reminder_message}
                                    alertType={"success"}
                                    onClick={() => this.setState({ error: false })}
                                />
                            </div>
                        )}
                        {showAlert && error && (
                            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                                <AlertAction
                                    alert={error}
                                    alertType={"error"}
                                    onClick={() => this.setState({ showAlert: false })}
                                />
                            </div>
                        )}
                        {!error && alertValue && alertValue === 'addReminder' && (
                            <div
                                style={{
                                    zIndex: 9999,
                                    width: "60%",
                                    position: "fixed",
                                    top: 35,
                                }}
                            >
                                <AlertAction
                                    alert={success_message_reminder}
                                    alertType={"success"}
                                    onClick={() => this.props.hideAlert()}
                                />
                            </div>
                        )}
                        {!error && alertValue && alertValue === 'editReminder' && (
                            <div
                                style={{
                                    zIndex: 9999,
                                    width: "60%",
                                    position: "fixed",
                                    top: 35,
                                }}
                            >
                                <AlertAction
                                    alert={success_message_on_update_reminder}
                                    alertType={"success"}
                                    onClick={() => this.props.hideAlert()}
                                />
                            </div>
                        )}
                        {isloading ? (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: '80vh',
                                }}
                            >
                                <CircularProgress
                                    color="primary"
                                    style={{ width: 24, height: 24 }}
                                />
                            </div>) : (
                                <GridContainer spacing={0}>
                                    <GridItem>
                                        <SimpleTable
                                            showHeader={true}
                                            title="Reminders"
                                            width="98%"
                                            rows={rows}
                                            columns={col}
                                            colSpan={16}
                                            actionSendButton={true}
                                            classProps={changeClassProps()}
                                            donationsCount={rows && rows.length > 0 ? 1 : 0}
                                            noTitle="No Reminders added"
                                            noPara="Added Reminders will be displayed and managed here"
                                            noBtnText="Add New Reminders"
                                            onInviteClick={() =>
                                                this.props.history.push(
                                                    "/addreminder"
                                                )
                                            }
                                            actionLink={(name) => this.details(name)}
                                            showIconCol={true}
                                            editIcon={Edit}
                                            deleteIcon={Delete}
                                            editAction={(e) => this.editSalah(e[0])}
                                            deleteAction={(e) => this.deleteSalah(e[0])}
                                            showFooter={true}
                                            showAdd={true}
                                            addText="ADD NEW"
                                            onAddClick={() =>
                                                this.props.history.push(
                                                    "/addreminder"
                                                )
                                            }
                                            iconTextSize={16}
                                        />
                                        <div>
                                            <p className="service-helper-text">"Active" reminders are available with publishing date and time from applications</p>
                                        </div>
                                    </GridItem>
                                    {openAlert && alertServiceModal}
                                </GridContainer>
                            )}
                    </>
                }
                isFooter={true}
                footer={
                    <FooterInner 
                        style={style} 
                        termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
                        privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                    />
                }
                isWhiteBackground={false}
            />
        )
    }
}
const mapStateToProps = (state) => {
    return {
        alertValue: state.alert.alertValue
    }
}
const mapDispatchToProps = (dispatch) => ({
    hideAlert: (res) => dispatch(hideAlert(res)),
    editReminder: (res) => dispatch(editReminder(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ManageReminders);