import React, { Component } from "react";
import { connect } from "react-redux";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import componentImg from "../../../assets/img/component.png";
import { countries } from "../../../utils/countries";
import { states } from "../../../utils/states";
import { stateAbbreviations } from '../../../utils/statesAbbreviations';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import {getaddressFromPostalCode} from '../../Organization/api/index' 

import {
  BaseTextField,
  AlertAction,
  BaseButton,
  DrawerListRadioButton,
  BaseSwitch,
  DrawerDateTime,
  FormSwitch,
  DrawerMain,
  BaseSelect,
  MasterLayoutMiddle,
  FooterInner,
  Repeat
} from "@dclab/dc-react-ui-kit";
import CircularProgress from "@material-ui/core/CircularProgress";
import Moment from "moment";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  addServiceAction,
  emptyServiceAction,
} from "../../../redux/actions/servicesAction";
import "./style.css";
import { UpdateSalah, getWakt } from "./Apis/index";
import { showAlert } from '../../../redux/actions/alertActions';
import Grid from '@material-ui/core/Grid';
import messages_content from '../../../utils/messages.json';
const {
  token_expired,
  provide_valid_zip_code,
  repeat_date_between_start_end,
  success_message_on_update_salah,
  Organization_postal_code_not_correct
} = messages_content;
const styles = {
  btn: {
    width: "100%",
    border: "#D3D8DA",
  },
};
class EditSalah extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef()
    this.state = {
      openAlert: false,
      originalPublished: false,
      salahPrimary: "",
      salahSecondary: "",
      loading: false,
      openDrawer: false,
      isSalahDrawer: false,
      isLocationDrawer: false,
      isStartDate: false,
      isEndDate: false,
      isRepeatDays: false,
      submitError: false,
      orgLocation: false,
      organizationAddress: "",
      ISOstartDateandTime: "",
      repeatdays: [],
      startDate: "",
      iqamahTime: "",
      startDateandTime: "",
      EndDateandTime: "",
      daysString: "",
      days: {
        Sun: false,
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
      },
      salahStatus: "",
      fullLocationAddress: "",
      fullStartDateandTime: "",
      fullEndDateandTime: "",
      fullRepeatDays: "",
      LocationState: {
        LocationName: "",
        StreetAddress: "",
        Suit_Number: "",
        City: "",
        State: "",
        Zip_Code: "",
      },
      endTime_Timestamp: "",
      endDate_Timestamp: "",
      iqamah_ISOString: "",
      startDate_Timestamp: "",
      isError: "",
      errorText: "",
      method: "",
      timezone: "",
      repeat_object: {},
      iswaktField: false,
      waktSalah: "",
      waktTime: "",
      waktResponse: [],
      waktMethodString: "",
      timezonestring: "",
      IsOrgAddress: "",
      startCheckBox: false,
      endCheckBox: false,
      temp_endDate: "",
      temp_endTime: "",
      temp_startTime: "",
      temp_startDate: "",
      dateError: false,
      timeError: false,
      states: [],
      stateAbbr: "",
      locationError: false,
      endDateError: false,
      endboolcheck: false,
      startboolcheck: false,
      Repeat_beforeError: false,
      data1: [],
      selectedSalah: {},
      DefaultStartDate: '',
      DefaultEndDate: '',


      // dynamicIqamahDetails: {
      //   check: false,
      //   mins: ''
      // },


      //Added by Toseef Ahmed
      //Adding variables that I think are required for Date and Time related information.
      c_salahName: '',
      c_salahWaktName: '',
      c_salahStartDate: '',
      c_salahEndDate: '',
      c_salahStartTime: '',
      c_salahEndTime: '',
      c_organizationAddress: '',
      c_repeatStatus: '',
      c_salahMethod: '',
      c_timeZone: '',
      c_startTime24: '',
      c_endTime24: '',
      c_IqamahTime: '',
      c_dynamicIqamahDetails: {
        check: false,
        mins: ''
      }
    };
  }
  async componentDidMount() {
    const { editableSalah } = this.props;
    const data1 = [
      {
        id: 1,
        primary: "Salat al-Fajr",
        secondary: "Dawn Prayer",
      },
      {
        id: 2,
        primary: "Salat al-Zuhr",
        secondary: "Noon Prayer",
      },
      {
        id: 3,
        primary: "Salat al-‘Asr",
        secondary: "Afternoon Prayer",
      },
      {
        id: 4,
        primary: "Salat al-Maghrib",
        secondary: "Sunset Prayer",
      },
      {
        id: 5,
        primary: "Salat al-‘Isha",
        secondary: "Night Prayer",
      },
      {
        id: 6,
        primary: "Salat al-Jumu‘ah",
        secondary: "Friday Prayer",
      },

      {
        id: 7,
        primary: "Salat at-Tahajjud",
        secondary: "Late Night Prayer",
      },

      {
        id: 8,
        primary: "Salat al-Janazah",
        secondary: "Funeral Prayer",
      },
      {
        id: 9,
        primary: "Salat al-Eid al-Fitr",
        secondary: "",
      },
      {
        id: 10,
        primary: "Salat al-Eid al-Adha",
        secondary: "",
      },
      {
        id: 11,
        primary: "Salat al-Tarawih",
        secondary: "Ramadan Night Prayer",
      },
      {
        id: 12,
        primary: "Salat al-Khawf",
        secondary: "Prayer of Fear",
      },
      {
        id: 13,
        primary: "Salat ad-Duha",
        secondary: "Pre-noon Prayer",
      },
      {
        id: 14,
        primary: "Salat al-Istikharah",
        secondary: "Istikhārah Prayer",
      },
      {
        id: 15,
        primary: "Salat al-'Istisqa",
        secondary: "Prayer for Rain",
      },
    ];
    //console.log("Props are: ", this.props.location.state);
    let location =
      localStorage.getItem("location") != "undefined"
        ? JSON.parse(localStorage.getItem("location"))
        : localStorage.getItem("location");



    
    if (editableSalah && editableSalah.orgId) {
      // const waqtmethod = editableSalah.settings.salahMethod.waqtMethod;
      // const timezone = editableSalah.waqt.timezoneFormat;


      console.log("editableSalah")
      console.log(editableSalah)
      const waqtmethod = this.props.location.state.waqtMethod ? this.props.location.state.waqtMethod : '';
      const timezone = this.props.location.state.timeZone ? this.props.location.state.timeZone : '';
      const juristicMethod = this.props?.location?.state?.juristicMethod && this.props?.location?.state?.juristicMethod != 'Shafi/Maliki/Hanbali' ? 'Hanafi':  'Standard';
      const waktMethodString = this.getFullSalahMethodName(waqtmethod);
      //const waktFormat = editableSalah.waqt.waqtDate;
      const waktFormat = editableSalah.salahStart.waktTime;
      const timezonestring = this.getTimeZoneName(timezone);
      // const timezonestring = editableSalah.settings.salahMethod.timezone;
      // const waktMethodString = editableSalah.waqt.waktMethodString;

      const salahName = editableSalah.salahName.fullName;
      const salahWaktName = editableSalah.salahName.waktName;
      const iqamahTime = editableSalah.salahStart.iqamahTime;
      const startDate = editableSalah.salahStart.waktTime && Moment(editableSalah.salahStart.waktTime).format('ddd, MMM DD, YYYY');
      const endDate = editableSalah.salahEnd && Moment(editableSalah.salahEnd).format('ddd, MMM DD, YYYY');
      const endTime = Moment(editableSalah.salahEnd).format('hh:mm A');
      const waqt = Moment(editableSalah.salahStart.waktTime).format('hh:mm A');
      const status = editableSalah.status === "false" ? false : true;
      

      //Toseef Ahmed
      this.state.c_salahStartDate = ""//Moment(editableSalah.salahStart.waktTime).format('ddd, MMM DD, YYYY');
      this.state.c_salahStartTime = Moment(editableSalah.salahStart.waktTime).utcOffset(0).format('hh:mm A');
      
      console.log("this.state.c_salahStartTime on start: ", this.state.c_salahStartTime)
      console.log("this.state.c_waktTime on start: ", editableSalah.salahStart.waktTime)
      
      
      this.state.c_IqamahTime = Moment(editableSalah.salahStart.iqamahTime).utcOffset(0).format('hh:mm A');
      this.state.c_salahEndDate =  ""//Moment(editableSalah.salahEnd).format('ddd, MMM DD, YYYY');
      this.state.c_salahEndTime =  Moment(editableSalah.salahEnd).utcOffset(0).format('hh:mm A');
      console.log("this.state.c_salahEndDate on start: ", this.state.c_salahEndDate)
      console.log("this.state.c_salahEndTime on start: ", this.state.c_salahEndTime)
      
      
      this.state.c_startTime24 =  this.convertTime12to24(this.state.c_salahStartTime)
      this.state.c_endTime24 =  this.convertTime12to24(this.state.c_salahEndTime)
      
      
      console.log("this.state.c_startTime24: ", this.state.c_startTime24)
      let address;
      let repeat_content = "";
      let locationPlaceholder;
      let LocationAddress = {};
      if (editableSalah.address) {
        LocationAddress = {
          LocationName: editableSalah.address.locationName,
          StreetAddress: editableSalah.address.line1,
          Suit_Number: editableSalah.address.line2,
          City: editableSalah.address.city,
          State: editableSalah.address.state,
          Zip_Code: editableSalah.address.postalCode,
        };
        const line1 = editableSalah.address.line1;
        const line2 = editableSalah.address.line2;
        const state = editableSalah.address.state;
        const city = editableSalah.address.city;
        const postal_code = editableSalah.address.postalCode;
        const state_abbr = states.filter(
          (item) => item.name.toLowerCase() === state.toLowerCase()
        )[0].state_code;
        locationPlaceholder = editableSalah.address.locationName;
        address = `${line1 ? line1 + ", " : ""}${line2 ? line2 + " " : ""}${city ? city + ", " : ""
          }${state_abbr ? state_abbr + " " : ""}${postal_code}`;
      }
      const repeat_end_type =
        editableSalah.repeat.repeatEnds &&
        editableSalah.repeat.repeatEnds.type;
      const repeat_end_value =
        editableSalah.repeat.repeatEnds &&
        editableSalah.repeat.repeatEnds.value;
      const repeat_every = editableSalah.repeat.repeatEvery;
      const newRepeat_every =
        repeat_every.split(" ")[0] == 1
          ? repeat_every.split(" ")[1]
          : repeat_every;
      const category = editableSalah.repeat.category;
      let repeat_on = "";
      console.log("salahName")
      console.log(salahName)
      const waktSalahName = salahName.split(" (");
      //console.log("waktSalahName: ", waktSalahName);

      if (repeat_every === "Weekly") {
        const days = editableSalah.repeat.repeatOn;
        let daystoShow = [];
        Object.keys(days).forEach((item) => {
          if (days[item] === true) {
            daystoShow.push(item);
            repeat_on += item + ", ";
          }
        });
        if (daystoShow.length === 7) {
          repeat_on = "Everyday";
        } else {
          repeat_on = repeat_on.substring(0, repeat_on.lastIndexOf(",") + "");
        }
      } else {
        repeat_on = editableSalah.repeat.repeatOn;
      }
      if (category === "One-time salah") {
        repeat_content = "One-time salah";
      } else if (category === "Custom") {
        if (repeat_end_type === "After Number of Times") {
          repeat_content = `Every ${newRepeat_every} on ${repeat_on}, ${repeat_end_value} Occurrences`;
        } else if (repeat_end_type === "On Date") {
          repeat_content = `Every ${newRepeat_every} on ${repeat_on}, Until ${repeat_end_value}`;
        } else {
          repeat_content = `Every ${newRepeat_every}`;
        }
      } else {
        if (repeat_end_type === "After Number of Times") {
          repeat_content = `${category}, ${repeat_end_value} Occurrences`;
        } else if (repeat_end_type === "On Date") {
          repeat_content = `${category}, Until ${repeat_end_value}`;
        } else {
          repeat_content = `${category}`;
        }
      }
      const dynamicIqamahDetails = editableSalah.dynamicIqamahDetails;

      
      // console.log('dynamicIqamahDetails')
      // console.log(dynamicIqamahDetails)
      const org_address = JSON.parse(localStorage.getItem("orgaddress"));
      const country_name = org_address?.country;
      const country_code = countries.find((item) => item.name === country_name);
      const selectedSalah = data1.filter((el, index) => waktSalahName?.[0]?.trim() == el.primary)?.[0];
      const new_states = states
        .filter((item) => item.country_code === country_code.iso2)
        .map((item) => {
          return {
            label: item.name,
            value: item.name,
            name: item.state_code,
            stateAbbr: item.state_code,
          };
        });
      const default_state = states.filter(
        (item) => item.name.toLowerCase() === org_address?.state.toLowerCase()
      );
      const rept = editableSalah.repeat;
      const repeats = {
        frequency: rept.frequency,
        repeat_category: rept.category,
        repeat_every: rept.repeatEvery,
        repeat_on: rept.repeatOn,
        repeats_Object: rept.repeatEnds
      }
      this.setState({
        states: new_states,
        stateAbbr: default_state[0].state_code,
        Salah_Name: salahName,
        waktFormat: waktFormat,
        fullStartDateandTime:
          (startDate ? startDate + ", " : "") +
          Moment(iqamahTime).utcOffset(0).format("hh:mm A"),
        salahStatus: status,
        originalPublished: status,
        fullEndDateandTime: (endDate ? endDate + ", " : "") + endTime,
        fullLocationAddress: address,
        locationPlaceholder: locationPlaceholder,
        LocationAddress: LocationAddress,
        LocationState: LocationAddress,
        method: waqtmethod,
        timezone: timezone,
        timezonestring: timezonestring,
        waktMethodString: waktMethodString,
        repeat_content: repeat_content,
        //waktTime: waqt,
        iswaktField: true,
        waktSalah: waktSalahName[0].substring(
          waktSalahName[0].lastIndexOf("-") + 1
        ),
        endDateToFormat: endDate,
        endTimeToFormat : Moment(editableSalah.salahEnd).utcOffset(0),
        startDateToFormat: startDate,
        startTimeToFormat: Moment(iqamahTime).utcOffset(0),
        temp_endDate: endDate,
        temp_startDate: startDate,
        temp_endTime: endTime,
        temp_startTime: iqamahTime,
        startDate: startDate,
        endDate: endDate,
        iqamahTime: Moment(iqamahTime).utcOffset(0).format("hh:mm A"),
        endTime: endTime,
        iqamah_ISOString: new Date(`${startDate} ${Moment(iqamahTime).format("hh:mm A")}`),
        startboolcheck: startDate ? true : false,
        endboolcheck: endDate ? true : false,
        startCheckBox: startDate ? true : false,
        endCheckBox: endDate ? true : false,
        repeat_object: repeats,
        data1,
        selectedSalah,
        dynamicIqamahDetails
      });
      if (localStorage.getItem("newMethod") === "true") {
        const waqtmethod = this.props?.location?.state?.waqtMethod ?? localStorage.getItem("waqtmethod");
        const waqtMethodString = localStorage.getItem("waqtMethodString");
        const timezone = localStorage.getItem("timezone");
        const timezonestring = localStorage.getItem("timezonestring");
        this.setState({
          method: waqtmethod,
          timezone: timezone,
          timezonestring: timezonestring,
          waktMethodString: waqtMethodString,
        });
      }
      const orgAddress = JSON.parse(localStorage.getItem("orgaddress"));
      const tempcountry_code = countries.find((item) => item.name === orgAddress.country);
      // console.log(tempcountry_code.iso2)
      // console.log(orgAddress.postalCode)
      const loc = await getaddressFromPostalCode({postal_code: orgAddress.postalCode, country:tempcountry_code.iso2 });

      const LatLong = loc.data.body.data.location
      console.log(LatLong)
      localStorage.setItem("location", JSON.stringify(LatLong));

      location =
      localStorage.getItem("location") != "undefined"
        ? JSON.parse(localStorage.getItem("location"))
        : localStorage.getItem("location");

      if (location !== "undefined" && JSON.stringify(location) !== "{}") {
        let newWaqtMethod = "";
        if (localStorage.getItem("newMethod") === "true") {
          newWaqtMethod = localStorage.getItem("waqtmethod");
        } else {
          newWaqtMethod = editableSalah.settings.salahMethod.waktMethod;
        }
       
        const lat = location.lat;
        const long = location.long;
        let waktSalahValue = "";

        try {
          console.log("this.props?.location?.state: ", this.props?.location?.state);
          const daytimeSaving = this.props?.location?.state?.daytimeSaving ?? localStorage.getItem("daytimeSaving");
          const timezone = this.props?.location?.state?.timeZone ?? localStorage.getItem("timezone");
          const waktApiResponse = await getWakt(lat, long, waqtmethod, timezone, daytimeSaving, juristicMethod);
          const wakt_res = waktApiResponse?.data?.body?.data;
          const value = salahName;
          const newValue = value.split(" (")[0];
          
          Object.keys(wakt_res[0] && wakt_res[0]).forEach((item) => {
            //console.log('item')
            //console.log(item)
            const newItem = item === "dhuhr" ? item.replace(item, "zuhr") : item;
            if (newValue.toLowerCase().includes(newItem)) {
              waktSalahValue = wakt_res[0][item];
            }
          });
        //}

        const salahTime12Hrs = waktSalahValue && this.formatAMPM(waktSalahValue);
       
        //console.log("formatAMPM", salahTime12Hrs, waktSalahValue, this.formatAMPM(waktSalahValue))

       

        this.setState({
          waktResponse: wakt_res,
          waktSalah: newValue.substring(newValue.lastIndexOf("-") + 1),
          waktTime: salahTime12Hrs,
          waktFormat: new Date(`${wakt_res[0].date}T${waktSalahValue}:00`),
        });
        } catch (error) {
          this.setState({
            error: error && error.response && error.response.data.message,
            showAlert: true,
          });
        }
      } else {
        this.setState({
          error: provide_valid_zip_code,
          IsOrgAddress: true,
          locationError: true,
        });
      }
    } else {
      this.props.history.push("/salah");
    }
  }
  locationAddress = () => {
    const { LocationState, orgLocation, stateAbbr } = this.state;
    if (orgLocation) {
      const orgAddress = JSON.parse(localStorage.getItem("orgaddress"));
      const orgName = localStorage.getItem("organame");
      const fullLocationAddress = `${orgAddress.line1 ? orgAddress.line1 + ". " : ""
        }${orgAddress.line2 ? orgAddress.line2 + " " : ""}${orgAddress.city ? orgAddress.city + ", " : ""
        }${stateAbbr} ${orgAddress.postalCode}`;
      this.setState({
        fullLocationAddress,
        openDrawer: false,
        isLocationDrawer: false,
        locationPlaceholder: orgName,
        isError: false,
        LocationAddress: {
          LocationName: orgName,
          StreetAddress: orgAddress.line1,
          Suit_Number: orgAddress.line2 || "",
          City: orgAddress.city,
          State: orgAddress.state,
          Zip_Code: orgAddress.postalCode,
        },
        LocationState: {
          LocationName: "",
          StreetAddress: "",
          Suit_Number: "",
          City: "",
          State: "",
          Zip_Code: "",
        },
      });
      localStorage.setItem(
        "LocationState",
        JSON.stringify({
          LocationName: orgName,
          StreetAddress: orgAddress.line1,
          Suit_Number: orgAddress.line2 || "",
          City: orgAddress.city,
          State: orgAddress.state,
          Zip_Code: orgAddress.postalCode,
          fullLocationAddress,
          orgLocation,
        })
      );
    } else {
      const LocationName = LocationState.LocationName;
      const StreetAddress = LocationState.StreetAddress;
      const Suit_Number = LocationState.Suit_Number;
      const City = LocationState.City;
      const State = LocationState.State;
      const Zip_Code = LocationState.Zip_Code;
      const state_abbr = states.filter(
        (item) => item.name.toLowerCase() === State.toLowerCase()
      );
      if (LocationName && StreetAddress && City && State && Zip_Code) {
        const fullLocationAddress = `${StreetAddress ? StreetAddress + ". " : ""
          }${Suit_Number ? Suit_Number + " " : ""}${City ? City + ", " : ""}${state_abbr[0].state_code
          } ${Zip_Code}`;
        this.setState({
          fullLocationAddress,
          openDrawer: false,
          isLocationDrawer: false,
          orgLocation: false,
          isError: false,
          locationPlaceholder: LocationName,
          LocationAddress: LocationState,
        });
        localStorage.setItem(
          "LocationState",
          JSON.stringify({
            LocationName: LocationState.LocationName,
            StreetAddress: LocationState.StreetAddress,
            Suit_Number: LocationState.Suit_Number,
            City: LocationState.City,
            State: LocationState.State,
            Zip_Code: LocationState.Zip_Code,
            fullLocationAddress,
            orgLocation: false,
          })
        );
      } else {
        this.setState({ isError: true });
      }
    }
  };
  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };
  handleSwitchChange = (val) => (event) => {
    this.setState({ [val]: !this.state[val] });
  };

  getData = (value, secondary) => {
    console.log("value: ", value)
    console.log("secondary: ", secondary)
    const selectedSalah1 = this.state.data1.filter((el, index) => value == el.primary)?.[0];
    selectedSalah1 && this.setState({selectedSalah : selectedSalah1})
    
    console.log("selected Salah: ")
    console.log(selectedSalah1)
   
    const { waktResponse, timezone } = this.state;
    const waktArr = waktResponse && waktResponse[0];

    const salahPrimaryUponValue =
      value + (secondary ? " (" + secondary + ")" : "");
    
    
    let salahID;
    this.state.c_salahName = salahPrimaryUponValue;
    if(selectedSalah1) {
      this.state.c_salahOrder = selectedSalah1.id
    }
    console.log('Selected Salah ID: ' + this.state.c_salahOrder)
    console.log('c_salahName: ' + this.state.c_salahName)
    
    // this.setState({
    //   c_salahName: salahPrimaryUponValue
    // })
    
    
    let isWaktFeildToShow = false;
    let waktSalahValue = "";
    
    
    if (waktArr && "fajr" in waktArr) {
      Object.keys(waktArr && waktArr).forEach((item) => {
        let newItem = item === "dhuhr" ? item.replace(item, "zuhr") : item;
        value = value === 'Salat al-Jumu‘ah' ? 'Salat al-Zuhr' : value;
        
        if (value.toLowerCase().includes(newItem)) {
          isWaktFeildToShow = true;
          waktSalahValue = waktArr[item];
          let waktSalahName =  value.substring(value.lastIndexOf("-") + 1)
          if(waktSalahName.toLowerCase().includes("asr") || waktSalahName.toLowerCase().includes("isha"))
          {
            waktSalahName = waktSalahName.slice(1, waktSalahName.length)
          }
         this.state.c_salahWaktName = waktSalahName;
          this.state.c_startTime24 = waktSalahValue;
         
          //console.log( this.state.c_startTime24)
        }
      });
      const salahTime12Hrs = waktSalahValue && this.formatAMPM(waktSalahValue);
      

      // this.setState({
      //   c_salahStartTime: salahTime12Hrs
      // })

      // console.log('salahTime12Hrs')
      // console.log(salahTime12Hrs)

      this.state.c_salahStartTime = salahTime12Hrs;
      console.log('c_salahStartTime')
      console.log(this.state.c_salahStartTime)

      this.setState({
        ...(value && { salahPrimary: salahPrimaryUponValue }),
        isWaktFeildToShow: isWaktFeildToShow,
        waktSalahName: value.substring(value.lastIndexOf("-") + 1),
        waktSalahValue: salahTime12Hrs,
        waktFormat: new Date(`${waktArr.date}T${waktSalahValue}:00`),
        selectedSalah: selectedSalah1
      });
    }

    this.setState({salahPrimary: salahPrimaryUponValue })

    const salahPrimary = salahPrimaryUponValue;
    localStorage.removeItem("salahObj")
    localStorage.setItem(
      "salahObj",
      JSON.stringify({ salahPrimary, isWaktFeildToShow })
    );
    
  };

  getDateTime = (value, boolean) => {
    

    let startDate = "";
    const { iqamah_ISOString, iqamahTime, startTimeToFormat } = this.state;


    //console.log('Start Date: '+ iqamah_ISOString)
    if (!boolean) {
      startDate = Moment(value).format("ddd, MMM DD, yyyy");
      
      console.log('startDate')
      console.log(startDate)
      
    }

    this.state.c_salahStartDate = startDate;

    //this.convertUTC(this.state.c_salahStartDate, 'start_time');

    // this.setState({
    //   c_salahStartDate: startDate
    // })

    
    this.setState({
      startDateandTime: (startDate ? startDate + ", " : "") + iqamahTime,
      iqamah_ISOString,
      startDate,
      repeat_start_date: startDate.slice(0, 3),
      startDateToFormat: value,
      temp_startDate: value,
      dateError: false,
    });

  }
  getCheck = (value) => {
    if(!value) {
      this.setState({c_salahStartDate : ""});
    }
    this.setState({ startCheckBox: value, startDateToFormat: "" });
  };

  getTimeCheck = (value) => {
    this.setState(prevState => ({ 
      dynamicIqamahDetails: {...prevState.dynamicIqamahDetails, check: value}
  }));
    //console.log(value)
  }

  dynamicIqamahTime = (value) => {
    console.log("Dynamic Iqamah Clicked: ", value)
    const { startDate, startDateToFormat, c_salahStartDate, c_salahStartTime } = this.state;
    let tempDateObj;
    if(c_salahStartDate) { // ||c_salahStartDate==="" || c_salahStartDate===undefined
      tempDateObj = c_salahStartDate
      console.log("c_SalahStart valid")
    }
    else {
      console.log("c_SalahStart Invalid")
      let tempDate = new Date();
      tempDateObj = Moment(tempDate).format("YYYY-MM-DD");
    }

    const { waktTime } = this.state;
    //console.log("waktTime")
    //console.log(waktTime)
    
    let h = c_salahStartTime.split(":")[0];
    let m = c_salahStartTime.split(":")[1];
    let format = c_salahStartTime.split(" ")[1];
    
    let mins = parseInt(m) + parseInt(value)
   
    const startTime24 = this.convertTime12to24(c_salahStartTime)
    // console.log("dynamic hrs" , h)
    // console.log("dynamic mins" , m)
    // console.log("dynamic mins iqamah" , mins)

    var d = new Date(`${tempDateObj} ${startTime24}`);
    
    d.setMinutes(mins);

    //console.log("Start Date: ", d)
    const iqamahTime = Moment(d).format("hh:mm A");
    //console.log("iqamahTime: ", iqamahTime)
    this.state.c_IqamahTime = iqamahTime
    console.log("Dynamic Iqamah Time: ", this.state.c_IqamahTime)
   // this.state.c_IqamahTime = this.state.c_salahStartTime
    this.state.c_dynamicIqamahDetails.mins = value;
    this.state.c_dynamicIqamahDetails.check = true;
    //console.log("iqamahTime Toseef", this.state.c_dynamicIqamahDetails.mins)

    let dateUTC = this.convertUTC(tempDateObj, "start_time")//Moment(this.state.c_salahEndDate).format("YYYY-MM-DD")

    //This code will be removed
    const Iqamah_date_utc = Moment(d).format("YYYY-MM-DD");
    const Iqamah_time_utc = Moment(d).format("hh:mm") + " " +  format;
    const iqamah_ISOString = new Date(`${Iqamah_date_utc} ${Iqamah_time_utc}`);
    this.setState(prevState => ({
      startDateandTime: (startDate ? startDate + ", " : "") + iqamahTime,
      iqamah_ISOString,
      startTimeToFormat: iqamah_ISOString,
      timeError: false,
      temp_startTime: d,
      iqamahTime: iqamahTime,
      c_dynamicIqamahDetails: {...prevState.c_dynamicIqamahDetails, mins: value}
    }));

    // console.log('Iqamah Details')
    // console.log(this.c_dynamicIqamahDetails)
    // const ms = Moment(d).format("hh:mm")
    
  }


  getTimeZoneName = (value) => {
    switch(value){
      case -4:
        return "Atlantic Standard Time (AST)";
      case -5:
        return "Eastern Standard Time (EST)";
      case -6:
        return "Central Standard Time (CST)";
      case -7:
        return "Mountain Standard Time (MST)";
      case -9:
        return "Alaskan Standard Time (AKST)";
      case -10:
        return "Hawaii Standard Time (HST)";
      case 0:
        return "Greenwich Mean Time (GMT)";
      case -8:
        return "Pacific Standard Time (PST)";
      default:
        return "Eastern Standard Time (EST)";

    }
  }
  getFullSalahMethodName = (abbr) => {
   
    switch(abbr) {
      case 'MWL':
        return 'Muslim World League (MWL)'
      case 'ISNA':
        return 'Islamic Society of North America (ISNA)'
      case 'Egypt':
        return 'Egyptian General Authority of Survey'
      case 'Makkah':
        return 'Umm al-Qura University, Makkah'
      case 'Karachi':
        return '	University of Islamic Sciences, Karachi'
      case 'Tehran':
        return 'Institute of Geophysics, University of Tehran'
      case 'Jafari':
        return 'Shia Ithna Ashari (Ja`fari)'
      default:
        return 'Muslim World League (MWL)'
    }
  }

  getEndCheck = (value) => {
    if(value) {
      this.setState({ endCheckBox: value});
    } else {
      this.setState({ endCheckBox: value, endDateToFormat: "", c_salahEndDate : "" });
    }
    
  };

  getDefaultDateObj = (date, time) => {

    console.log("date in default obj: ", date)
    let tempDateObj;
    let dateObj;
    if(!date || date==="undefined" || date==="") {
      if(time==="start_time") {
         dateObj = new Date();
         console.log("default date: ", dateObj)
      }
      else if(time==="end_time") {
        dateObj = new Date('December 31, 2098 00:00:00');
        console.log("default date: ", dateObj)
      }

      tempDateObj = Moment(dateObj).format("YYYY-MM-DD")
    }
    else {
      tempDateObj = date
    }

    return tempDateObj;
  }

  convertUTC = (date, time) => {
    console.log("date value: ", date);
    
    let tempDateObj;
    let dateObj;
    let time24;

    if(!date || date==="undefined" || date==="") {
      if(time==="start_time") {
         dateObj = new Date();
         console.log("default date: ", dateObj)
      }
      else if(time==="end_time") {
        dateObj = new Date('December 31, 2099 00:00:00');
        console.log("default date: ", dateObj)
      }

      tempDateObj = Moment(dateObj).format("YYYY-MM-DD")
    }
    else {
      console.log("date is not invalid: ", dateObj)
      tempDateObj = date
    }

    console.log("tempDateObj: ", tempDateObj)
    if(time === "iqamah_time") {
      time24 = this.convertTime12to24(this.state.c_IqamahTime)
    }
    else if(time==="start_time") {
      time24 = this.state.c_startTime24
    }
    else if(time==="end_time") {
      time24 = this.state.c_endTime24
    }

    let UpdatedDate = new Date(`${tempDateObj} ${time24}`)
    console.log("UpdatedDate: ", UpdatedDate)

    const hrs = UpdatedDate.getHours();

    UpdatedDate.setUTCHours(hrs)
    const timeFinal = Moment(UpdatedDate).format();
    
    const utcDateTime = new Date(timeFinal).toISOString();
    console.log("utcDateTime: ", utcDateTime)

    // const utcOffset = UpdatedDate.getTimezoneOffset();
    // const momentISO = Moment(UpdatedDate).utcOffset(-1*utcOffset).format("YYYY-MM-DDTHH:mm:00:000Z")
    // console.log("momentISO: ", momentISO)

    // let DateUTC = Moment(date).format("YYYY-MM-DDTHH:mm")


    // //console.log("DateUTC: ", DateUTC)
    // let h, m
    // h = time24.split(":")[0];
    // m = time24.split(":")[1];

    // //console.log('time24: ', time24)

    // //console.log('Hours Obtained: ', h)
    // //console.log('Minutes Obtained: ', m)
    // let utcDateTime = Moment(DateUTC).add( h, 'hours').add( m, 'minutes').format("YYYY-MM-DDTHH:mm")
    

    // //console.log("utcDateTime: ", utcDateTime)
    // let newDateISO = new Date(utcDateTime).toISOString()
    // console.log('startDateUTC after adding hours')
    // console.log(newDateISO)

    return utcDateTime;

  };
  
  startTime = (value) => {
    const { startDate, startDateToFormat } = this.state;

    const iqamahTime = Moment(value).format("hh:mm A");
    
    this.state.c_IqamahTime = iqamahTime
    this.state.c_salahStartTime = iqamahTime

    console.log('iqamahTime Testing');
    console.log(iqamahTime);


    //This code will be removed
    const Iqamah_date_utc = Moment(value).format("YYYY-MM-DD");
    const Iqamah_time_utc = Moment(value).format("hh:mm A");
    const iqamah_ISOString = new Date(`${Iqamah_date_utc} ${Iqamah_time_utc}`);
    
    console.log('iqamah_ISOString to test.....')
    console.log(iqamah_ISOString)
    this.setState({
      startDateandTime: (startDate ? startDate + ", " : "") + iqamahTime,
      iqamah_ISOString,
      startTimeToFormat: iqamah_ISOString,
      timeError: false,
      temp_startTime: value,
      iqamahTime: iqamahTime,
    });


    localStorage.setItem(
      "StartDate",
      JSON.stringify({
        startDate: startDate,
        iqamahTime: iqamahTime,
        iqamah_ISOString: iqamah_ISOString,
        repeat_start_date: startDate && startDate.slice(0, 3),
        startDateandTime: (startDate ? startDate + ", " : "") + iqamahTime,
        startDateToFormat: startDateToFormat,
        startTimeToFormat: value,
      })
    );
  };


  getEndDateTime = (value, boolean) => {
    let endDate = "";
    const { endTime, endTimeToFormat } = this.state;

    if (!boolean) {
      endDate = Moment(value).format("ddd, MMM DD, yyyy");
    }

    this.state.c_salahEndDate = Moment(value).format("ddd, MMM DD, yyyy");

    console.log(this.state.c_salahEndDate)

    
    this.setState({
      EndDateandTime: (endDate ? endDate + ", " : "") + endTime,
      endDate,
      endTime,
      temp_endDate: value,
      dateError: false,
      endDateToFormat: value,
    });
    localStorage.setItem(
      "EndDate",
      JSON.stringify({
        endDate: endDate,
        endTime: endTime,
        EndDateandTime: (endDate ? endDate + ", " : "") + endTime,
        endDateToFormat: value,
        endTimeToFormat: endTimeToFormat,

      })
    );
  };

  endTime = (value) => {
    
    let endTime = Moment(value).format("hh:mm A");
    console.log("End Date clicked: ", endTime)

    this.state.c_salahEndTime = endTime;

    this.state.c_endTime24 = this.convertTime12to24(this.state.c_salahEndTime)

    console.log('End Time Added Now')
    console.log(this.state.c_endTime24)
    

   //let dateUTC = this.convertUTC(this.state.c_salahEndDate, "end_time")//Moment(this.state.c_salahEndDate).format("YYYY-MM-DD")

    //This Code will be removed
    const { endDate, endDateToFormat } = this.state;
    this.setState({
      EndDateandTime: (endDate ? endDate + ", " : "") + endTime,
      endDate: endDate,
      endTime: endTime,
      endTimeToFormat: value,
      timeError: false,
      temp_endTime: value,
    });
    localStorage.setItem(
      "EndDate",
      JSON.stringify({
        endDate: endDate,
        endTime: endTime,
        EndDateandTime: (endDate ? endDate + ", " : "") + endTime,
        endDateToFormat: endDateToFormat,
        endTimeToFormat: value,
      })
    );
  };
  CANCELRepeats = () => {
    this.setState({
      repeat_object: "",
      repeat_content: "",
      openDrawer: false,
      isRepeatDays: false,
    });
  };
  getDays = (days) => {
    let count = 0;
    let daystoShow = [];
    let currentString = "";
    let repeatdays = [];
    Object.keys(days).forEach((item) => {
      if (days[item] === true) {
        count++;
        daystoShow.push({ key: count, item: item });
        currentString += item + ", ";
      }
    });

    if (daystoShow.length === 7) {
      currentString = "Everyday";
      repeatdays.push({ key: 1, item: "Everyday" });
    } else {
      currentString = currentString.substring(
        0,
        currentString.lastIndexOf(",") + ""
      );
      repeatdays = daystoShow;
    }
    if (this.state.daysString !== currentString) {
      this.setState({ daysString: currentString, days, repeatdays });
    }
  };

  saveRepeats = (value) => {
    this.setState({
      repeat_object: value,
      repeat_content: value.repeat_content,
      openDrawer: false,
      isRepeatDays: false,
    });
  };
  cancleRepeats = () => {
    this.setState((prevState) => ({
      openDrawer: false,
      isRepeatDays: false,
      repeat_content: prevState.repeat_content,
    }));
  };

  handleRepeatdaysDelete = (data) => {
    const { repeatdays } = this.state;
    const newrepeatsdays = repeatdays.filter((chip) => chip.item != data);
    const days = {
      Sun: false,
      Mon: false,
      Tue: false,
      Wed: false,
      Thu: false,
      Fri: false,
      Sat: false,
    };
    newrepeatsdays.forEach((val) => {
      if (Object.keys(days).includes(val.item)) {
        days[val.item] = true;
      }
    });
    this.setState({ repeatdays: newrepeatsdays, days });
  };
  formatAMPM = (timeString) => {
    //console.log(timeString, "timeString")
    const H = +timeString.substr(0, 2);
    const h = H % 12 || 12;
    const ampm = H < 12 || H === 24 ? "AM" : "PM";
    return (timeString = `${h}${timeString.substr(2, 3)} ${ampm}`);
  };

  // addSalah = async () => {
  //   this.setState({ loading: true });
  //   const {
  //     method,
  //     timezone,
  //     timezonestring,
  //     repeat_object,
  //     waktMethodString,
  //     waktFormat,
  //     Salah_Name,
  //     fullStartDateandTime,
  //     repeatdays,
  //     fullEndDateandTime,
  //     fullLocationAddress,
  //     salahStatus,
  //     repeat_content,
  //     LocationAddress,
  //     endDate,
  //     endTime,
  //     startDate,
  //     iqamah_ISOString,
  //     dynamicIqamahDetails
  //   } = this.state;
  //   if (
  //     !fullStartDateandTime ||
  //     !fullLocationAddress ||
  //     !Salah_Name ||
  //     !fullEndDateandTime ||
  //     !repeatdays
  //   ) {
  //     this.setState({ submitError: true, loading: false });
  //   } else if (
  //     new Date(`${endDate} ${endTime}`).getTime() <=
  //     new Date(
  //       `${startDate} ${Moment(iqamah_ISOString).format("HH:mm:ss")}`
  //     ).getTime()
  //   ) {
  //     this.setState({ endDateError: true, loading: false });
  //   } else if (new Date(repeat_content) <= new Date(
  //     `${startDate} ${Moment(iqamah_ISOString).format("hh:mm A")}`
  //   )) {
  //     this.setState({ Repeat_beforeError: repeat_date_between_start_end, loading: false });
  //   } else {
  //     const token = localStorage.getItem("authToken");
  //     const id = this.props.editableSalah._id;
  //     const orgId = this.props.editableSalah.orgId;
      
  //     // const data = {
  //     //   salahName: Salah_Name,
  //     //   salahStart: {
  //     //     startDate: '',
  //     //     iqamahTime: ''
  //     //   },
  //     //   salahEnd: {
  //     //     endDate: '',
  //     //     endTime: ''
  //     //   },
  //     //   waqt: {
  //     //     waqtDate: waktFormat,
  //     //     waktMethodString: waktMethodString,
  //     //     timezoneFormat: timezone,
  //     //   },
  //     //   settings: {
  //     //     salahMethod: {
  //     //       waqtMethod: method,
  //     //       timezone: timezonestring,
  //     //     }
  //     //   },
  //     //   address: {
  //     //     line1: LocationAddress.StreetAddress,
  //     //     line2: LocationAddress.Suit_Number,
  //     //     city: LocationAddress.City,
  //     //     state: LocationAddress.State,
  //     //     postalCode: LocationAddress.Zip_Code,
  //     //     locationName: LocationAddress.LocationName,
  //     //   },
  //     //   orgId: orgId,
  //     //   status: salahStatus,
  //     //   dynamicIqamahDetails
  //     // };

  //     const data = {
  //       salahName: {
  //         fullName: Salah_Name,
  //         waktName: this.state.c_salahWaktName
  //       },
  //       salahStart: {
  //             waktTime: '',
  //             iqamahTime: ''
  //           },
  //       salahEnd: '',
  //       settings: {
  //         salahMethod: {
  //           waqtMethod: method,
  //           timezone: timezonestring,
  //         },
  //         otherSettings: {}
  //       },
  //       address: {
  //         line1: LocationAddress.StreetAddress,
  //         line2: LocationAddress.Suit_Number,
  //         city: LocationAddress.City,
  //         state: LocationAddress.State,
  //         postalCode: LocationAddress.Zip_Code,
  //         locationName: LocationAddress.LocationName,
  //       },
  //       orgId: orgId,
  //       status: salahStatus,
  //       dynamicIqamahDetails: this.state.c_dynamicIqamahDetails,
  //       order: this.state.c_salahOrder
  //     };

  //     // console.log('data.wakt')
  //     // console.log(data.wakt)
  //     if (Object.keys(repeat_object).length > 0) {
  //       data.repeat = {
  //         category: repeat_object.repeat_category,
  //         repeatEvery: repeat_object.repeat_every,
  //         frequency: repeat_object.frequency,
  //         repeatOn: repeat_object.repeat_on,
  //         repeatEnds: {
  //           type: repeat_object.repeats_Object.type,
  //           value: repeat_object.repeats_Object.value,
  //         },
  //       };
  //     }
  //     if (iqamah_ISOString) {
  //       data.salahStart.iqamahTime = this.state.startTimeToFormat
  //       data.salahStart.startDate = "";
  //     }
  //     if (endTime) {
  //       const today = new Date().toISOString().slice(0, 10)
  //       data.salahEnd.endTime = new Date(`${today} ${endTime}`).toISOString()
  //       data.salahEnd.endDate = "";
  //     }
  //     if (endDate) {
  //       data.salahEnd.endDate = endDate;
  //     }
  //     if (startDate) {
  //       data.salahStart.startDate = startDate;
  //     }
  //     try {
  //       await UpdateSalah(data, token, id);
  //       this.setState({
  //         success: true,
  //         showAlert: true,
  //         loading: false,
  //       });
  //       localStorage.removeItem("newMethod");
  //       this.props.showAlert('editSalah')
  //       this.props.history.push("/salah");
  //     } catch (error) {
  //       this.setState({
  //         error: error && error.response && error.response.data.message,
  //         showAlert: true,
  //         loading: false,
  //       });
  //     }
  //   }
  // };
  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === '12') {
      hours = '00';
    }
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
    return `${hours}:${minutes}`;
  }
  addSalah = async () => {

    console.log('Salah Added...')
    this.setState({ loading: true });
    const {
      method,
      timezone,
      timezonestring,
      waktMethodString,
      waktFormat,
      repeat_object,
      repeat_content,
      Salah_Name,
      fullStartDateandTime,
      fullEndDateandTime,
      fullLocationAddress,
      salahStatus,
      endDate,
      endTime,
      startDate,
      iqamah_ISOString,
      LocationAddress,
      c_dynamicIqamahDetails
    } = this.state;
    if (
      !fullStartDateandTime ||
      !fullLocationAddress ||
      !Salah_Name ||
      !fullEndDateandTime ||
      !repeat_content
    ) {
      this.setState({ submitError: true, loading: false });
    } 
    else if (
      new Date(`${this.state.c_salahEndDate} ${this.state.c_salahEndTime}`).getTime() <=
      new Date(
        `${this.state.c_salahStartDate} ${this.state.c_IqamahTime}`).getTime()
    ) {
      this.state.endDateError = true;
      this.state.loading = false;
      //this.setState({ endDateError: true, loading: false });
    } 
    else if (new Date(repeat_content) <= new Date(
      `${this.state.c_salahStartDate} ${this.state.c_IqamahTime}`
    )) {
      this.setState({ Repeat_beforeError: repeat_date_between_start_end, loading: false });
    } else {
      const token = localStorage.getItem("authToken");
      const orgId = localStorage.getItem("orgID");
      
      // Api object
      // const data = {
      //   salahName: Salah_Name,
      //   salahStart: {
      //     startDate: '',
      //     iqamahTime: ''
      //   },
      //   salahEnd: {
      //     endDate: '',
      //     endTime: ''
      //   },
      //   waqt: {
      //     waqtDate: waktFormat,
      //     waktMethodString: waktMethodString,
      //     timezoneFormat: timezone,
      //   },
      //   settings: {
      //     salahMethod: {
      //       waqtMethod: method,
      //       timezone: timezonestring,
      //     },
      //     otherSettings: {}
      //   },
      //   address: {
      //     line1: LocationAddress.StreetAddress,
      //     line2: LocationAddress.Suit_Number,
      //     city: LocationAddress.City,
      //     state: LocationAddress.State,
      //     postalCode: LocationAddress.Zip_Code,
      //     locationName: LocationAddress.LocationName,
      //   },
      //   orgId: orgId,
      //   status: salahStatus,
      //   c_dynamicIqamahDetails
      // };
      const test = this.convertUTC(this.state.c_salahStartDate, "start_time");
      console.log("test: ", test)
      const id = this.props.editableSalah._id;
      const data = {
        salahName: {
          fullName: Salah_Name,
          waktName: this.state.c_salahWaktName
        },
        salahStart: {
              waktTime: this.convertUTC(this.state.c_salahStartDate, "start_time"),
              iqamahTime: this.convertUTC(this.state.c_salahStartDate, "iqamah_time")
            },
        salahEnd: this.convertUTC(this.state.c_salahEndDate, "end_time"),
        settings: {
          salahMethod: {
            waqtMethod: method,
            timezone: timezonestring,
          },
          otherSettings: {}
        },
        address: {
          line1: LocationAddress.StreetAddress,
          line2: LocationAddress.Suit_Number,
          city: LocationAddress.City,
          state: LocationAddress.State,
          postalCode: LocationAddress.Zip_Code,
          locationName: LocationAddress.LocationName,
        },
        orgId: orgId,
        status: salahStatus,
        dynamicIqamahDetails: this.state.c_dynamicIqamahDetails,
        order: this.state.c_salahOrder
      };

      if (repeat_content) {
        data.repeat = {
          category: repeat_object.repeat_category,
          repeatEvery: repeat_object.repeat_every,
          frequency: repeat_object.frequency,
          repeatOn: repeat_object.repeat_on,
          repeatEnds: {
            type: repeat_object.repeats_Object.type,
            value: repeat_object.repeats_Object.value,
          },
        };
      }

      // if (this.state.c_salahStartDate && this.state.c_IqamahTime) {
      //   data.salahStart.waktTime = this.convertUTC(this.state.c_salahStartDate, "start_time")
      //   data.salahStart.iqamahTime = this.convertUTC(this.state.c_salahStartDate, "iqamah_time")
      // }
      // if (this.state.c_salahEndDate &&this.state.c_salahEndTime) {
      //   data.salahEnd = this.convertUTC(this.state.c_salahEndDate, "end_time")
      // }
     
      try {
        console.log('Salah Added Message')
        console.log(data)
        const res = await UpdateSalah(data, token, id);
        localStorage.removeItem("salahObj");
        localStorage.removeItem("LocationState");
        localStorage.removeItem("StartDate");
        localStorage.removeItem("EndDate");
        localStorage.removeItem("repeats");
        localStorage.removeItem("newMethod");
        this.setState({
          success: true,
          showAlert: true,
          loading: false,
        });
        this.props.showAlert('editSalah')
        this.props.history.push("/salah");
      } catch (error) {
       
        this.setState({
          error: error && error.response && error.response.data.message,
          showAlert: true,
          loading: false,
        });
      }
    }
  };
  change = () => {
    this.props.history.push("salahsettings");
  };

  handleClose = () => {
    this.setState({ openAlert: false });
  };

  handleDeleteConfirm = () => {
    const {salahStatus} = this.state;
    this.setState({ salahStatus: !salahStatus });
    this.setState({openAlert: false});
  }

  handleSwitch = () => {
    const {salahStatus, originalPublished} = this.state;
    if(originalPublished && !salahStatus === false) {
      this.setState({openAlert: true});
    } else {
      this.setState({ salahStatus: !salahStatus });
    }
  };

  render() {
    const {
      endboolcheck,
      startboolcheck,
      showAlert,
      success,
      error,
      openDrawer,
      loading,
      isLocationDrawer,
      isSalahDrawer,
      LocationState,
      isStartDate,
      isEndDate,
      isRepeatDays,
      Salah_Name,
      salahPrimary,
      iqamahTime,
      startDateandTime,
      EndDateandTime,
      fullStartDateandTime,
      fullLocationAddress,
      fullEndDateandTime,
      isError,
      locationPlaceholder,
      submitError,
      timezonestring,
      repeat_content,
      iswaktField,
      waktSalah,
      waktTime,
      isWaktFeildToShow,
      waktSalahName,
      Repeat_beforeError,
      waktSalahValue,
      waktMethodString,
      IsOrgAddress,
      dateError,
      timeError,
      states,
      locationError,
      endDateError,
      endTimeToFormat,
      endDateToFormat,
      startDateToFormat,
      startTimeToFormat,
      repeat_object,
      openAlert,
      data1,
      selectedSalah,
      dynamicIqamahDetails
    } = this.state;

    this.state.iqamahTime = this.state.c_IqamahTime;
    // const data1 = [
    //   {
    //     id: 1,
    //     primary: "Salat al-Fajr",
    //     secondary: "Dawn Prayer",
    //   },
    //   {
    //     id: 2,
    //     primary: "Salat al-Zuhr",
    //     secondary: "Noon Prayer",
    //   },
    //   {
    //     id: 3,
    //     primary: "Salat al-‘Asr",
    //     secondary: "Afternoon Prayer",
    //   },
    //   {
    //     id: 4,
    //     primary: "Salat al-Maghrib",
    //     secondary: "Sunset Prayer",
    //   },
    //   {
    //     id: 5,
    //     primary: "Salat al-‘Isha",
    //     secondary: "Night Prayer",
    //   },
    //   {
    //     id: 6,
    //     primary: "Salat al-Jumu‘ah",
    //     secondary: "Friday Prayer",
    //   },

    //   {
    //     id: 7,
    //     primary: "Salat at-Tahajjud",
    //     secondary: "Late Night Prayer",
    //   },

    //   {
    //     id: 8,
    //     primary: "Salat al-Janazah",
    //     secondary: "Funeral Prayer",
    //   },
    //   {
    //     id: 9,
    //     primary: "Salat al-Eid al-Fitr",
    //     secondary: "",
    //   },
    //   {
    //     id: 10,
    //     primary: "Salat al-Eid al-Adha",
    //     secondary: "",
    //   },
    //   {
    //     id: 11,
    //     primary: "Salat al-Tarawih",
    //     secondary: "Ramadan Night Prayer",
    //   },
    //   {
    //     id: 12,
    //     primary: "Salat al-Khawf",
    //     secondary: "Prayer of Fear",
    //   },
    //   {
    //     id: 13,
    //     primary: "Salat ad-Duha",
    //     secondary: "Pre-noon Prayer",
    //   },
    //   {
    //     id: 14,
    //     primary: "Salat al-Istikharah",
    //     secondary: "Istikhārah Prayer",
    //   },
    //   {
    //     id: 15,
    //     primary: "Salat al-'Istisqa",
    //     secondary: "Prayer for Rain",
    //   },
    // ];
    const LocationArray = [
      "Location Name",
      "Street Address",
      "Suite/FL Number",
      "City",
      "State",
      "Zip code",
    ];
    const LocationArrayNames = [
      "LocationName",
      "StreetAddress",
      "Suit_Number",
      "City",
      "State",
      "Zip_Code",
    ];
    const stringRegex = /^[a-zA-Z\s\.]*$/;
    const flatReg = /^[a-zA-Z0-9\s,.'-]*$/;
    const zipReg = /^[0-9]*$/;
    const orgAddress = JSON.parse(localStorage.getItem("orgaddress"));
    const orgName = localStorage.getItem("organame");
    const style = {
      width: "100%",
    };

    const alertEventModal = (
      <Dialog
        open={openAlert}
        onClose={() => this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Change status to inactive?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          This content is published in other applications/sites. <br></br>
          Changing to “Inactive” will remove from everywhere.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()} color="primary">
          CANCEL
          </Button>
          <Button onClick={() => this.handleDeleteConfirm()} color="primary">
          YES
          </Button>
        </DialogActions>
      </Dialog>
    );


    return (
      <MasterLayoutMiddle
        header={
          <>
            <div
              onClick={() => {
                this.props.history.push("/salah");
                localStorage.removeItem("newMethod");
              }}
              className="backBtn"
            >
              <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
              <p style={{ alignSelf: "center" }}>Back</p>{" "}
            </div>
            {error && error === "Invalid token" && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => this.setState({ error: false })}
                />
              </div>
            )}
            {locationError && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={provide_valid_zip_code}
                  alertType={"error"}
                  onClick={() => this.setState({ locationError: false })}
                />
              </div>
            )}
            {showAlert && error && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={
                    error === "Invalid token"
                      ? token_expired
                      : error
                  }
                  alertType={"error"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {showAlert && success && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={success_message_on_update_salah}
                  alertType={"success"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {showAlert && IsOrgAddress && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={Organization_postal_code_not_correct}
                  alertType={"error"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {openAlert && alertEventModal}
          </>
        }
        isForm={true}
        formData={
          <>
            <div>
              <h1 className="title_top">Edit Salah</h1>
              <div className="dasheddBorder"></div>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12}>  <h2 className="sub_title">Basic Information</h2> </Grid>
              <Grid item xs={12}>
                <div className="field_box">
                  <BaseTextField
                    value={Salah_Name ? Salah_Name : ""}
                    onChange={this.handleChange("salah")}
                    InputProps={false}
                    phone={false}
                    search={false}
                    count={false}
                    label="Salah Name"
                    placeholder=""
                    style={styles.btn}
                    onClick={() =>
                      this.setState({ openDrawer: true, isSalahDrawer: true })
                    }
                    disabled={isSalahDrawer}
                    error={submitError && !Salah_Name}
                    helperText={submitError && "Salah required"}
                    InputProps={true}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    {iswaktField && (
                      <div className="wakt_feild">
                        <div>
                          <img
                            src={componentImg}
                            alt="componentmg"
                            style={{ width: 24, height: 24 }}
                          />
                        </div>
                        <div>
                          <p>{`${waktSalah} waqt starts at ${waktTime}`}</p>
                          <p>
                            <span>{`Method - ${waktMethodString}`}</span>{" "}
                            <span
                              className="change"
                              onClick={() =>
                                this.props.history.push("salahsettings")
                              }
                            >
                              Change
                            </span>
                          </p>
                        </div>
                      </div>
                    )}
                    <div className="time_zone_field">
                      <img
                        src={componentImg}
                        alt="componentmg"
                        style={{ width: 24, height: 24 }}
                      />
                      <p>
                        {`${timezonestring}`}{" "}
                        <span
                          className="change"
                          onClick={() =>
                            this.props.history.push("salahsettings")
                          }
                        >
                          Change
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}> <h2 className="sub_title">Select Location</h2></Grid>
              <Grid item xs={12}>
                <div className="field_box">
                  <BaseTextField
                    value={fullLocationAddress}
                    onChange={this.handleChange("Location")}
                    InputProps={false}
                    phone={false}
                    search={false}
                    count={false}
                    disabled={isLocationDrawer}
                    label={
                      locationPlaceholder ? locationPlaceholder : "Location Name"
                    }
                    placeholder=""
                    style={styles.btn}
                    error={submitError && !fullLocationAddress}
                    helperText={submitError && "Location required"}
                    onClick={() =>
                      this.setState({ openDrawer: true, isLocationDrawer: true })
                    }
                    InputProps={true}
                  />
                </div>
              </Grid>
              <Grid item xs={12}><div className="dasheddBorder_form"></div></Grid>
              <Grid item xs={12}>
                <h2 className="sub_title">Date and Time</h2>{" "}
              </Grid>
              <Grid item xs={12}>
                <div className="field_box">
                  <BaseTextField
                     value={this.state.c_salahStartDate && this.state.c_IqamahTime //If Both Start Date and Start Time are available, show both
                      ? this.state.c_salahStartDate + ' , ' +  this.state.c_IqamahTime 
                      : !this.state.c_salahStartDate && this.state.c_IqamahTime ? this.state.c_IqamahTime  //If only Iqamah Time is available, show only Time
                      : ""}InputProps={false}
                    phone={false}
                    search={false}
                    count={false}
                    label="Start Date and Time"
                    placeholder=""
                    style={styles.btn}
                    error={submitError && !this.state.c_IqamahTime}
                    helperText={submitError && "Start date and time required"}
                    onClick={() =>
                      this.setState({ openDrawer: true, isStartDate: true })
                    }
                    disabled={isStartDate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          onClick={() =>
                            this.setState({
                              openDrawer: true,
                              isRepeatDays: true,
                            })
                          }
                          position="end"
                          style={{
                            marginBottom: 15,
                            cursor: "pointer",
                          }}
                        >
                          <ChevronRight />{" "}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="field_box">
                  <BaseTextField
                    value={(this.state.c_salahEndDate && this.state.c_salahEndTime) //If Both End Date and End Time are available, show both
                      ? this.state.c_salahEndDate + ' , ' +  this.state.c_salahEndTime 
                      : (!this.state.c_salahEndDate && this.state.c_salahEndTime) ? this.state.c_salahEndTime //If only End Time is available, show only Time
                      : ""}
                    InputProps={false}
                    phone={false}
                    search={false}
                    count={false}
                    label="End Date and Time"
                    placeholder=""
                    style={styles.btn}
                    error={(submitError && !(this.state.c_salahEndDate || this.state.c_salahEndTime)) || endDateError}
                    helperText={
                      (submitError &&
                        !(this.state.c_salahEndDate || this.state.c_salahEndTime) &&
                        "End date and time is required") ||
                      (endDateError &&
                        "End date must be greater than start date")
                    }
                    onClick={() =>
                      this.setState({ openDrawer: true, isEndDate: true })
                    }
                    disabled={isEndDate}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          onClick={() =>
                            this.setState({
                              openDrawer: true,
                              isRepeatDays: true,
                            })
                          }
                          position="end"
                          style={{
                            marginBottom: 15,
                            cursor: "pointer",
                          }}
                        >
                          <ChevronRight />{" "}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="field_box">
                  <BaseTextField
                    value={repeat_content || ""}
                    InputProps={false}
                    phone={false}
                    search={false}
                    count={false}
                    label="Repeat"
                    placeholder=""
                    style={styles.btn}
                    onClick={() =>
                      this.setState({ openDrawer: true, isRepeatDays: true })
                    }
                    error={submitError && !repeat_content || Repeat_beforeError}
                    helperText={submitError && !Repeat_beforeError ? "Repeats required" : repeat_date_between_start_end}
                    disabled={isRepeatDays}
                    InputProps={true}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="border_top_bottm">
                  <FormSwitch
                    headline="Salah Status"
                    body="Enabling would make the salah available for publishing"
                    values={this.state.salahStatus}
                    handleSwitchChange={() => this.handleSwitch()}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <div
                    style={{
                      marginTop: 30,
                      display: "flex",
                      justifyContent: "center",
                      width: 500,
                    }}
                  >
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                    <div
                      className="addSalah"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div className="form_button">
                        <p className="cancel" 
                          onClick={() => 
                            {
                              localStorage.removeItem("newMethod"); 
                              this.props.history.push("/salah");  
                            }
                          } >GO BACK </p>
                        <BaseButton text="SAVE" onClick={() => this.addSalah()} />
                      </div>
                    </div>
                  )}
              </Grid>
            </Grid>
            <DrawerMain
              open={openDrawer}
              openHandler={() =>
                this.setState({
                  openDrawer: false,
                  isStartDate: false,
                  isEndDate: false,
                  isLocationDrawer: false,
                  isSalahDrawer: false,
                  isRepeatDays: false,
                })
              }
              title={
                (isSalahDrawer && "Salah Name") ||
                (isLocationDrawer && "Salah Location") ||
                (isStartDate && "Start Date and Time") ||
                (isEndDate && "End Date and Time") ||
                (isRepeatDays && "Repeats")
              }
              onCancel={() => {
                if (isSalahDrawer) {
                  this.setState((prevState) => ({
                    openDrawer: false,
                    isSalahDrawer: false,
                    Salah_Name: this.state.Salah_Name
                      ? this.state.Salah_Name
                      : "",
                  }));
                } else if (isLocationDrawer) {
                  this.setState({
                    openDrawer: false,
                    isLocationDrawer: false,
                    isError: false,
                    orgLocation: false,
                    LocationState: {
                      StreetAddress: "",
                      Suit_Number: "",
                      City: "",
                      State: "",
                      Zip_Code: "",
                      LocationName: "",
                    },
                  });
                } else if (isEndDate) {
                  this.setState({
                    openDrawer: false,
                    isEndDate: false,
                    endCheckBox: this.state.endDateToFormat ? false : true,
                    fullEndDateandTime: fullEndDateandTime
                      ? fullEndDateandTime
                      : "",
                    EndDateandTime: "",
                  });
                  localStorage.removeItem("EndDate");
                } else if (isStartDate) {
                  this.setState({
                    openDrawer: false,
                    isStartDate: false,
                    startCheckBox: this.state.startDateToFormat ? false : true,
                    fullStartDateandTime: fullStartDateandTime
                      ? fullStartDateandTime
                      : "",
                    startDateandTime: "",
                  });
                  localStorage.removeItem("StartDate");
                } else if (isRepeatDays) {
                  this.child.cancle();
                }
              }}
              onSave={() => {
                if (isSalahDrawer) {
                  localStorage.setItem(
                    "salahObj",
                    JSON.stringify({ salahPrimary, isWaktFeildToShow })
                  );
                  this.setState({
                    openDrawer: false,
                    isSalahDrawer: false,
                    Salah_Name: salahPrimary,
                    iswaktField: isWaktFeildToShow,
                    waktSalah: waktSalahName,
                    waktTime: waktSalahValue,
                  });
                } else if (isLocationDrawer) {
                  this.locationAddress();
                } else if (isStartDate) {
                  const {
                    startCheckBox,
                    startDateToFormat,
                    iqamahTime,
                    temp_startTime,
                  } = this.state;
                  if (startCheckBox && !startDateToFormat && !iqamahTime) {
                    this.setState({ dateError: true, timeError: true });
                  } else if (startCheckBox && iqamahTime && !startDateToFormat) {
                    this.setState({ dateError: true, timeError: false });
                  } else if (startCheckBox && startDateToFormat && !iqamahTime) {
                    this.setState({ dateError: false, timeError: true });
                  } else if (!startCheckBox && !iqamahTime) {
                    this.setState({ dateError: false, timeError: true });
                  } else if (!startCheckBox && iqamahTime) {
                    this.setState({
                      openDrawer: false,
                      isStartDate: false,
                      fullStartDateandTime: iqamahTime,
                      timeError: false,
                      dateError: false,
                      // startCheckBox: true,
                      repeat_start_date: "",
                      startDate: "",
                      // startDateToFormat: "",
                      startDateandTime: iqamahTime,
                      startboolcheck: startCheckBox
                    });
                  } else {
                    this.setState({
                      openDrawer: false,
                      isStartDate: false,
                      fullStartDateandTime: startDateandTime
                        ? startDateandTime
                        : fullStartDateandTime,
                      timeError: false,
                      dateError: false,
                      // startCheckBox: true,
                      startboolcheck: startCheckBox
                    });
                  }
                } else if (isEndDate) {
                  const {
                    endCheckBox,
                    temp_endDate,
                    temp_endTime,
                    endTime,
                  } = this.state;
                  
                  if (endCheckBox && !endDateToFormat && !endTime) {
                    this.setState({ dateError: true, timeError: true });
                  } else if (endCheckBox && endTime && !endDateToFormat) {
                    this.setState({ dateError: true, timeError: false });
                  } else if (endCheckBox && endDateToFormat && !endTime) {
                    this.setState({ dateError: false, timeError: true });
                  } else if (!endCheckBox && !endTime) {
                    this.setState({ dateError: false, timeError: true });
                  } else if (!endCheckBox && endTime) {
                    this.setState({
                      openDrawer: false,
                      isEndDate: false,
                      fullEndDateandTime: endTime,
                      timeError: false,
                      dateError: false,
                      // endCheckBox: true,
                      endDate: "",
                      // endDateToFormat: "",
                      EndDateandTime: endTime,
                      endboolcheck: endCheckBox
                    });
                  } else {
                    this.setState({
                      openDrawer: false,
                      isEndDate: false,
                      fullEndDateandTime: EndDateandTime
                        ? EndDateandTime
                        : fullEndDateandTime,
                      timeError: false,
                      dateError: false,
                      // endCheckBox: true,
                      endDateError: false,
                      endboolcheck: endCheckBox
                    });
                  }
                } else if (isRepeatDays) {
                  this.child.save();
                }
              }}
            >
              <div>
                {isSalahDrawer && (
                  <div className="salahDrawer">
                    <DrawerListRadioButton
                      objects={data1}
                      getData={this.getData}
                      selectedData = {selectedSalah}
                    />
                  </div>
                )}
                {isLocationDrawer && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div className="salahStatus">
                        <h2>Use org address</h2>
                      </div>
                      <div>
                        <BaseSwitch
                          checked={this.state.orgLocation}
                          onChange={this.handleSwitchChange("orgLocation")}
                        />
                      </div>
                    </div>
                    {this.state.orgLocation ? (
                      <div
                        className="orgName"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                        }}
                      >
                        {orgName && <h2>{orgName}</h2>}
                        {orgAddress && (<>
                          <p>{`${orgAddress.line1}`} </p>
                          <p> {`${orgAddress.city}, ${stateAbbreviations[orgAddress.state] ? stateAbbreviations[orgAddress.state]
                              : orgAddress.state} ${orgAddress.postalCode
                            }`}</p></>
                        )}
                      </div>
                    ) : (
                        <div className="drawer_form">
                          {LocationArray.map((item, index) => {
                            return (
                              <>
                                {item !== "State" && (
                                  <BaseTextField
                                    key={index}
                                    value={
                                      LocationState[LocationArrayNames[index]] ||
                                      ""
                                    }
                                    onChange={(e) => {
                                      e.persist();
                                      if (
                                        (item === "Location Name" &&
                                          stringRegex.test(e.target.value)) ||
                                        (item === "Suite/FL Number" &&
                                          e.target.value.length <= 10 && flatReg.test(e.target.value)) ||
                                        (item === "State" &&
                                          stringRegex.test(e.target.value)) ||
                                        (item === "Street Address" &&
                                          flatReg.test(e.target.value)) ||
                                        (item === "Zip code" &&
                                          e.target.value.length <= 10 &&
                                          zipReg.test(e.target.value)) ||
                                        (item === "City" &&
                                          stringRegex.test(e.target.value))
                                      ) {
                                        this.setState((prevState) => ({
                                          LocationState: {
                                            ...prevState.LocationState,
                                            [LocationArrayNames[index]]:
                                              e.target.value,
                                          },
                                        }));
                                      }
                                    }}
                                    InputProps={false}
                                    phone={false}
                                    search={false}
                                    count={false}
                                    label={item}
                                    placeholder=""
                                    style={styles.btn}
                                    helperText={
                                      isError &&
                                      item !== "Suite/Fl Number" &&
                                      !LocationState[LocationArrayNames[index]] &&
                                      item + " " + "required"
                                    }
                                    error={
                                      isError &&
                                      item !== "Suite/Fl Number" &&
                                      !LocationState[LocationArrayNames[index]]
                                    }
                                  />
                                )}
                                {item === "State" && (
                                  <BaseSelect
                                    object={states}
                                    label="State"
                                    style={{
                                      width: "100%",
                                      margin: 0,
                                      marginBottom: 26,
                                      minHeight: "48px",
                                    }}
                                    value={
                                      LocationState[LocationArrayNames[index]] ||
                                      ""
                                    }
                                    onChange={(e) => {
                                      e.persist();
                                      this.setState((prevState) => ({
                                        LocationState: {
                                          ...prevState.LocationState,
                                          [LocationArrayNames[index]]:
                                            e.target.value,
                                        },
                                      }));
                                    }}
                                    helperText={
                                      isError &&
                                      item !== "Suite/Fl Number" &&
                                      !LocationState[LocationArrayNames[index]] &&
                                      item + " " + "required"
                                    }
                                    error={
                                      isError &&
                                      item !== "Suite/Fl Number" &&
                                      !LocationState[LocationArrayNames[index]]
                                    }
                                  />
                                )}
                              </>
                            );
                          })}
                        </div>
                      )}
                  </>
                )}
                {isStartDate &&   (
                 
                 <div className="isStartDate">
                    <DrawerDateTime
                      title={""}
                      check={true}
                      checkValue = {true}
                      defaultDate={startDateToFormat}
                      defaultTime={startTimeToFormat}
                      dateLabel={"Start Date"}
                      timeLabel={"Iqamah Time"}
                      switchLabel={"Start Date"}
                      getDateTime={this.getDateTime}
                      getTime={this.startTime}
                      getCheck={this.getCheck}
                      getTimeCheck={this.getTimeCheck}
                      dateError={dateError && !startDateToFormat}
                      useAsPublish={false}
                      dateHelperText={
                        dateError && !startDateToFormat && "Start date required"
                      }
                      timeError={timeError && !iqamahTime}
                      timeHelperText={
                        timeError && !iqamahTime && "Start time required"
                      }
                      
                      dynamicIqamahSelect={this.dynamicIqamahTime}
                      timeSwitchLabel={<>Dynamic Iqamah Time <span style={{position: "relative", top: "4px"}}><Tooltip title="Enabling this option will allow to add time for Iqamah to Waqt." placement="top"><InfoIcon fontSize="small" /></Tooltip></span></>} 
                      showTimeSwitch={true}
                    />
                  </div>
                  
                )}
                
                {isEndDate && (
                  <div className="isEndDate">
                    <DrawerDateTime
                      title={""}
                      check={endboolcheck}
                      checkValue = {true}
                      defaultDate={endDateToFormat}
                      defaultTime={endTimeToFormat}
                      dateLabel={"End Date"}
                      timeLabel={"End Time"}
                      switchLabel={"End Date"}
                      getDateTime={this.getEndDateTime}
                      getTime={this.endTime}
                      getCheck={this.getEndCheck}
                      dateError={dateError && !endDateToFormat}
                      useAsPublish={false}
                      dateHelperText={
                        dateError && !endDateToFormat && "End date required"
                      }
                      timeError={timeError && !this.state.endTime}
                      timeHelperText={
                        timeError && !this.state.endTime && "End time required"
                      }
                    />
                  </div>
                ) }
                {isRepeatDays && (
                  <>
                    <Repeat
                      callbackFromParent={this.saveRepeats}
                      canclecallbackFromParent={this.CANCELRepeats}
                      ref={ref => (this.child = ref)}
                      maxDate={endDateToFormat}
                      initialEndDate={repeat_object?.repeats_Object?.value || startDateToFormat || new Date()}
                      category={repeat_object.repeat_category}
                      repeats_object={repeat_object.repeats_Object}
                      frequency={repeat_object.frequency}
                      repeat_every={repeat_object.repeat_every}
                      repeat_on={repeat_object.repeat_on}
                      oneTimeTitle={'salah'}
                    />
                  </>
                )}
              </div>
            </DrawerMain>
          </>
        }
        isFooter={true}
        footer={<FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />}
        isWhiteBackground={true}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    editableSalah: state.salahs.editableSalah,
    edit: state.services.edit,
  };
};
const mapDispatchToProps = (dispatch) => ({
  addServiceHandler: (res) => dispatch(addServiceAction(res)),
  emptyServiceHandler: () => dispatch(emptyServiceAction()),
  showAlert: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditSalah);
