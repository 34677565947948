import * as constants from '../constants'

const INITIAL_STATE = {
    alertValue: "",
};

export default (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case constants.SHOW_ALERT:
            return {
                ...state,
                alertValue: action.payload,
            };
        case constants.HIDE_ALERT:
            return {
                ...state,
                alertValue: ""
            };
        default:
            return state;
    }
};
