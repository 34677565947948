import React, { Component } from 'react'
import { TabsVertical, BaseSelect, MasterLayoutMiddle, FooterInner, AlertDialogueAdvance, FormSwitch } from "@dclab/dc-react-ui-kit";
import './style.css';
import { ChevronLeft } from '@material-ui/icons';
import {editOrganization} from '../../Organization/api';
import { organizations } from './Apis';
class salahSettings extends Component {
    constructor(props) {
        super(props);
        this.unblockHandle = React.createRef();
        this.state = {
            error: false,
            waqt: '',
            timezone: '',
            value: 0,
            AlertModal: false,
            changes: false,
            route: '',
            juristicMethod: 'Hanafi',
            showAlert: false,
            daytimeSaving: false
        }
    }
    getTimeZoneName = (value) => {
        switch(value){
          case -4:
            return "Atlantic Standard Time (AST)";
          case -5:
            return "Eastern Standard Time (EST)";
          case -6:
            return "Central Standard Time (CST)";
          case -7:
            return "Mountain Standard Time (MST)";
          case -9:
            return "Alaskan Standard Time (AKST)";
          case -10:
            return "Hawaii Standard Time (HST)";
          case 0:
            return "Greenwich Mean Time (GMT)";
          case -8:
            return "Pacific Standard Time (PST)";
          default:
            return "Eastern Standard Time (EST)";
        }
      }
      getFullSalahMethodName = (abbr) => {
       
        switch(abbr) {
          case 'MWL':
            return 'Muslim World League (MWL)'
          case 'ISNA':
            return 'Islamic Society of North America (ISNA)'
          case 'Egypt':
            return 'Egyptian General Authority of Survey'
          case 'Makkah':
            return 'Umm al-Qura University, Makkah'
          case 'Karachi':
            return '	University of Islamic Sciences, Karachi'
          case 'Tehran':
            return 'Institute of Geophysics, University of Tehran'
          case 'Jafari':
            return 'Shia Ithna Ashari (Ja`fari)'
          default:
            return 'Muslim World League (MWL)'
        }
      }
    async GetOrgData() {
        const token = localStorage.getItem('authToken');
        const orgId = localStorage.getItem('orgID');
        try {
            if (token && orgId) {
                const orgres = await organizations(orgId, token);
                
                console.log("Salah Settings: ", orgres.data.body.data[0].salahSetting)
                
                const juristicMethod = orgres.data.body.data[0].salahSetting && orgres.data.body.data[0].salahSetting.juristicMethod//localStorage.getItem('juristicMethod')
               
                let daytimeSaving = orgres.data.body.data[0].salahSetting && orgres.data.body.data[0].salahSetting.daytimeSaving;
                let waqtmethod = orgres.data.body.data[0].salahSetting && orgres.data.body.data[0].salahSetting.waqtMethod;
                let timezoneAbbr = orgres.data.body.data[0].salahSetting && orgres.data.body.data[0].salahSetting.timeZone;
                const timezone = this.getTimeZoneName(timezoneAbbr);
                const waqtMethodString = this.getFullSalahMethodName(waqtmethod)
                const methodString = waqtMethodString

                this.setState({
                    waqt: waqtMethodString,
                    timezone: timezone,
                    timezoneAbbr,
                    waqtmethod,
                    timezone,
                    methodString,
                    juristicMethod,
                    daytimeSaving
                })
               
              }
        }
        catch(e) {
            console.log("error: ", e);
        }
    }
    componentDidMount() {
        this.GetOrgData();
        // const timezone = localStorage.getItem('timezonestring')
        // const waqtMethodString = localStorage.getItem('waqtMethodString')
        // const timezoneAbbr = localStorage.getItem('timezone')
        // const methodString = localStorage.getItem('timezonestring')
        // const waqtmethod = localStorage.getItem('waqtmethod')
        // const juristicMethod = localStorage.getItem('juristicMethod')
        // let daytimeSaving = localStorage.getItem('daytimeSaving');

        // console.log("waqtMethodString: ", waqtMethodString)
        // console.log("daytimeSaving: ", daytimeSaving)
        // if(daytimeSaving === 'false') {
        //     daytimeSaving = false;
        // } else {
        //     daytimeSaving = true;
        // }
        // this.setState({
        //     waqt: waqtMethodString,
        //     timezone: timezone,
        //     timezoneAbbr,
        //     methodString,
        //     waqtmethod,
        //     waqtMethodString,
        //     juristicMethod,
        //     daytimeSaving
        // })
    }
    componentWillUnmount() {
        this.unblockHandle.current = false
    }
    visibleModal = () => {
        this.setState({ AlertModal: true })
    }
    handleChange = (event, newValue) => {
        this.setState({ value: newValue })
    };
    handleWaqtChange = (event) => {
        const waqt = event.target.value
        let waqtmethod = "MWL";
        let waqtMethodString = 'Muslim World League (MWL)'
        switch (waqt) {
            case 'Muslim World League (MWL)':
                waqtmethod = "MWL";
                waqtMethodString = 'Muslim World League (MWL)'
                break;
            case 'Islamic Society of North America (ISNA)':
                waqtmethod = "ISNA";
                waqtMethodString = 'Islamic Society of North America (ISNA)'
                break;
            case 'Egyptian General Authority of Survey':
                waqtmethod = "Egypt";
                waqtMethodString = 'Egyptian General Authority of Survey'
                break;
            case 'Umm al-Qura University, Makkah':
                waqtmethod = "Makkah";
                waqtMethodString = 'Umm al-Qura University, Makkah'
                break;
            case 'University of Islamic Sciences, Karachi':
                waqtmethod = "Karachi";
                waqtMethodString = 'University of Islamic Sciences, Karachi'
                break;
            case 'Institute of Geophysics, University of Tehran':
                waqtmethod = "Tehran";
                waqtMethodString = 'Institute of Geophysics, University of Tehran'
                break;
            case 'Shia Ithna Ashari, Leva Research Institute, Qum':
                waqtmethod = "Jafari";
                waqtMethodString = 'Shia Ithna Ashari, Leva Research Institute, Qum'
                break;
            default:
                waqtmethod = "MWL";
                waqtMethodString = 'Muslim World League (MWL)';
                break;
        }
        this.setState({ waqt: event.target.value, waqtmethod, waqtMethodString, changes: true })
    };
    handleTimeZoneChange = (event) => {
        const timezone = event.target.value
        let timezoneAbbr = "";
        let methodString = ''
        switch (timezone) {
            case 'Atlantic Standard Time (AST)':
                timezoneAbbr = "-4";
                methodString = 'Atlantic Standard Time (AST)';
                break;
            case 'Eastern Standard Time (EST)':
                timezoneAbbr = "-5";
                methodString = 'Eastern Standard Time (EST)'
                break;
            case 'Central Standard Time (CST)':
                timezoneAbbr = "-6";
                methodString = 'Central Standard Time (CST)';

                break;
            case 'Mountain Standard Time (MST)':
                timezoneAbbr = "-7";
                methodString = 'Mountain Standard Time (MST)';

                break;
            case 'Alaskan Standard Time (AKST)':
                timezoneAbbr = "-9";
                methodString = 'Alaskan Standard Time (AKST)';
                break;
            case 'Hawaii Standard Time (HST)':
                timezoneAbbr = "-10";
                methodString = 'Hawaii Standard Time (HST)';

                break;
            case 'Greenwich Mean Time (GMT)':
                timezoneAbbr = "0";
                methodString = 'Greenwich Mean Time (GMT)';
                break;
            case 'Pacific Standard Time (PST)':
                timezoneAbbr = "-8";
                methodString = 'Pacific Standard Time (PST)';
                break;
            default:
                timezoneAbbr = '-5'
                methodString = 'Eastern Standard Time (EST)'
        }
        this.setState({ timezone: event.target.value, timezoneAbbr, methodString, changes: true })
    };
    handleJuristicChange = (event) => {
        this.setState({ juristicMethod: event.target.value, changes: true })
    };
    render() {
        const { waqt, timezone, value, error, AlertModal, changes, daytimeSaving } = this.state
        if (this.state.changes) {
            const { history } = this.props
            this.unblockHandle.current = history.block((route) => {
                this.setState({ route: route.pathname })
                changes && this.visibleModal();
                return changes === true ? false : true
            });
        }
        const styles = {
            select: {
                margin: 8,
                minWidth: 120,
                height: 66,
                width: 120,
                margin: 8,
                border: "#D3D8DA",
                width: 570,
            },
        };
        const object = [
            {
                label: "SALAH METHOD",
                item: "Item One",
            },
            {
                label: "More Settings",
                item: "Item Two",
            },
        ];
        const waqtObject = [
            {
                value: "Muslim World League (MWL)",
                label: "Muslim World League (MWL)",
            },
            {
                value: "Islamic Society of North America (ISNA)",
                label: "Islamic Society of North America (ISNA)",
            },
            {
                value: "Egyptian General Authority of Survey",
                label: "Egyptian General Authority of Survey",
            },
            {
                value: "Umm al-Qura University, Makkah",
                label: "Umm al-Qura University, Makkah",
            },
            {
                value: "University of Islamic Sciences, Karachi",
                label: "University of Islamic Sciences, Karachi",
            },
            {
                value: "Institute of Geophysics, University of Tehran",
                label: "Institute of Geophysics, University of Tehran",
            },
            {
                value: "Shia Ithna Ashari, Leva Research Institute, Qum",
                label: "Shia Ithna Ashari, Leva Research Institute, Qum",
            },
        ];
        const timezoneObject = [
            {
                value: "Atlantic Standard Time (AST)",
                label: "Atlantic Standard Time (AST)",
            },
            {
                value: "Eastern Standard Time (EST)",
                label: "Eastern Standard Time (EST)",
            },
            {
                value: "Central Standard Time (CST)",
                label: "Central Standard Time (CST)",
            },
            {
                value: "Mountain Standard Time (MST)",
                label: "Mountain Standard Time (MST)",
            },
            {
                value: "Alaskan Standard Time (AKST) ",
                label: "Alaskan Standard Time (AKST) ",
            },
            {
                value: "Hawaii Standard Time (HST)",
                label: "Hawaii Standard Time (HST)",
            },
            {
                value: "Greenwich Mean Time (GMT)",
                label: "Greenwich Mean Time (GMT)",
            },
            {
                value: "Pacific Standard Time (PST)",
                label: "Pacific Standard Time (PST)",
            },
        ];
        const salahdiv = (
            <>
                <BaseSelect
                    value={waqt}
                    onChange={this.handleWaqtChange}
                    label="Waqt/Salah Method"
                    object={waqtObject}
                    style={styles.select}
                    helperText={error && !waqt && "Waqt/Salah method is required"}
                    error={error && !waqt}
                />
                <BaseSelect
                value={this.state.juristicMethod}
                onChange={this.handleJuristicChange}
                label="Juristic Method"
                object={[
                    {
                    value: "Hanafi",
                    label: "Hanafi",
                    },
                    {
                    value: "Shafi/Maliki/Hanbali",
                    label: "Shafi/Maliki/Hanbali",
                    },
                ]}
                style={styles.select}
                helperText={error && !waqt && "Juristic method is required"}
                error={error && !waqt}
                />
                <BaseSelect
                    value={timezone}
                    onChange={this.handleTimeZoneChange}
                    label="Time zone"
                    object={timezoneObject}
                    style={styles.select}
                    helperText={error && !timezone && "Time zone is required"}
                    error={error && !timezone}
                />
                <div className="SalahSettingSwitchContainer">
                <FormSwitch
                    headline="Enable daylight-saving time"
                    //body="Enabling would make the salah available for publishing"
                    values={daytimeSaving}
                    handleSwitchChange={(e) => this.setState((prevState, props) => ({daytimeSaving: !prevState.daytimeSaving})) }
                  />
                  </div>
                
            </>
        );
        const onSave = async() => {
            const { route } = this.state
            if (!waqt || !timezone) {
                this.setState({ error: true, changes: false, AlertModal: false, })
            } else {
                this.setState({ changes: false, AlertModal: false, })
                const { waqtmethod, waqtMethodString, timezoneAbbr, methodString, juristicMethod } = this.state;
                localStorage.setItem('waqtmethod', waqtmethod)
                localStorage.setItem('waqtMethodString', waqtMethodString)
                localStorage.setItem('timezone', timezoneAbbr)
                localStorage.setItem('timezonestring', methodString)
                localStorage.setItem('newMethod', true)
                localStorage.setItem('changedWaktMethod', waqtmethod)
                localStorage.setItem('juristicMethod', juristicMethod)
                localStorage.setItem('daytimeSaving', daytimeSaving);

                console.log('waqtmethod', waqtmethod);
                console.log('waqtMethodString', waqtMethodString);
                console.log('timezone', timezoneAbbr);
                console.log('timezonestring', methodString);
                console.log('changedWaktMethod', waqtmethod);
                console.log('juristicMethod', juristicMethod)
                if (this.unblockHandle && this.unblockHandle.current) {
                    this.unblockHandle.current()
                }
                // if (route) {
                //     this.props.history.push(route)
                // }
                
                // 
                const obj = {
                    waqtMethod: waqtmethod,
                    juristicMethod,
                    timeZone: timezoneAbbr,
                    daytimeSaving

                };

                console.log('Changed Time Zone Value')
                console.log(obj)
                try {
                    const token = localStorage.getItem('authToken');
                    const orgId = localStorage.getItem('orgID');
                    await editOrganization({salahSetting: obj}, orgId, token);

                    this.props.history.push({ pathname: '/salah', successAlert: true })
                } catch(err) {
                    console.log(err)
                }


            }
        }
        const onCancle = () => {
            localStorage.setItem('newMethod', false)
            this.props.history.goBack()
        }
        let arr = [salahdiv];
        const style = {
            width: '100%',
        };
        const handleCloseDraft = async () => {
            this.setState({ AlertModal: false })
        }
        const discardChanges = async () => {
            const { route } = this.state
            this.setState({ AlertModal: false, changes: false, })
            if (this.unblockHandle) {
                this.unblockHandle.current()
            }
            if (route) {
                this.props.history.push(route)
            }
        }
        const Alertstyle = {
            width: 470,
            marginLeft: '35%'
        };
        return (
            <MasterLayoutMiddle
                header={
                    <div className='contact-us-backbutton'>
                        <div
                            onClick={() => this.props.history.push("/salah")}
                            className="backBtn"
                            style={{ marginTop: 0 }}
                        >
                            <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                            <p style={{ alignSelf: "center" }}>Back</p>{" "}
                        </div>
                    </div>
                }
                content={
                    <div style={{ marginTop: '65px' }}>
                        <TabsVertical
                            tabs={object}
                            onChange={this.handleChange}
                            value={value}
                            onWaqtChange={this.handleWaqtChange}
                            onTimeZoneChange={this.handleTimeZoneChange}
                            waqt={waqt}
                            timezone={timezone}
                            onSave={onSave}
                            onCancle={onCancle}
                            error={error}
                            title="Salah"
                            primaryPara="Change and save salah related settings from here."
                            children={arr[value]}
                        />
                        <div>
                            <AlertDialogueAdvance
                                title="You have unsaved changes!"
                                description='There are unsaved changes. If you would like to save changes, press the "SAVE AS DRAFT" button.'
                                cancelText="Discard Changes"
                                confirmText="Save chenges"
                                open={AlertModal}
                                handleCancel={() => onSave()}
                                handleClose={() => handleCloseDraft()}
                                handleConfirm={() => discardChanges()}
                                styles={Alertstyle}
                            />
                        </div>
                    </div>
                }
                isFooter={true}
                footer={
                    <FooterInner 
                        style={style}
                        termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
                        privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                    />
                }
                isWhiteBackground={false}
            />
       
        )
        
    }
}
export default salahSettings