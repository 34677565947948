import React, { Component } from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  BaseTextField, BaseButton, AlertAction, MasterLayoutMiddle,
  FooterInner,BaseCheckBox, EventLogs
} from '@dclab/dc-react-ui-kit';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CircularProgress from '@material-ui/core/CircularProgress';
import Check from '../../assets/img/check_circle-24px.svg';
import Add from '../../assets/img/add_circle-24px.svg';
import Cancel from '../../assets/img/cancel-24px.svg';
import { getOrganizationData, editOrganization } from './api';
import Grid from '@material-ui/core/Grid';
import './style.css';
import '../../App.css';
import { showAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
const { 
  submitted_success_message
  } = messages_content;
class Taxein extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      error: false,
      taxNum: '',
      taxExempt: false,
      orgData: {},
      loading: false,
      showAlert: false,
      sortedLogs: [],
      logsLoading: true,
      timeLineData: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  getLogImage = (status) => {
    const temp = status?.toLowerCase();
    
    switch(temp) {
      case 'submitted':
      case 'resubmitted':
        return Add;

      case 'approved':
        return Check;

      case 'rejected':
        return Cancel;

      default: 
        return Add
    }
  };

  getTimeLine = (events) => {
    
    const data = events.length > 0 ? events?.sort((a,b) =>  b.submittedAt - a.submittedAt)?.map((data, index) => {
      return {
        id: index + 1, 
        icon: this.getLogImage(data.status), 
        status: data.status,  
        date: Moment(data.submittedAt).format("MMM DD, YYYY, hh:mm A"), 
        details: [
          { title: 'Type', message: data.eventType },
            ...data.messages
        ]

      }
    }) : [];
  
    return data;
  };


  async componentDidMount() {
    let orgId = localStorage.getItem('orgID');
    try {
      const res = await getOrganizationData(orgId);
      let response = res.data.body.data;

 
      this.setState({
        orgData: response,
        timeLineData:  response?.taxIdStatus?.events ? this.getTimeLine(response.taxIdStatus.events) : [],
        taxNum:
          response && response.taxIdStatus && response.taxIdStatus.taxIdEin,
        taxExempt: response && response.taxIdStatus && response.taxIdStatus.taxExempt,
        sortedLogs:
          response.taxIdStatus &&
          response.taxIdStatus.events &&
          response.taxIdStatus.events.length > 0 &&
          response.taxIdStatus.events.sort(
            (a, b) => b.submittedAt - a.submittedAt
          ),
        logsLoading: false,
      });
    } catch (error) {
      this.setState({ error: error });
    }
  }
  handleChange = (panel) => (event, isExpanded) => {
    this.setState({
      expanded: this.state.expanded ? false : panel,
    });
  };
  submit = async () => {
    const { taxNum, orgData, taxExempt } = this.state;
    this.setState({ loading: true });
    let orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    const EventsArray = orgData?.taxIdStatus?.events ? orgData.taxIdStatus.events : [];
    const oldTaxIdEinDetail = orgData?.taxIdStatus?.taxIdEinDetail ?? {status: '', submittedAt: 0};
    const oldTaxExemptDetail = orgData?.taxIdStatus?.taxExemptDetail ?? {status: '', submittedAt: 0};
    const oldTaxIdEin = orgData?.taxIdStatus?.taxIdEin ?? '';
    const oldTaxExempt = orgData?.taxIdStatus?.taxExempt ?? false;
    if (taxNum.length <= 9) {
      this.setState({ error: true, loading: false });
    } else {
      const time = new Date();
      const timeStamp = time.getTime();
      let obj = {};
      let tempEventType = '';
      if(oldTaxIdEin === taxNum) {
        Object.assign(obj, {taxIdEinDetail: {... oldTaxIdEinDetail}});
      } else {
        tempEventType = 'EIN/Tax ID';
        Object.assign(obj, {taxIdEinDetail: {... oldTaxIdEinDetail, status: 'pending', submittedAt: timeStamp}});
      }

      if(taxExempt == oldTaxExempt) {
        Object.assign(obj, {taxExemptDetail: {... oldTaxExemptDetail}});
      } else {
        if(tempEventType.length > 0) {
          tempEventType = tempEventType + ", " + 'Tax exemptions'
        } else {
          tempEventType = 'Tax exemptions';
        }
        Object.assign(obj, {taxExemptDetail: {... oldTaxExemptDetail, status: 'pending', submittedAt: timeStamp}});
      }

      if(EventsArray.length) {
              const event = {
                events: [...EventsArray,
                {
                  eventType: tempEventType,
                  status: 'Resubmitted',
                  submittedAt: timeStamp,
                },
              ]};

        Object.assign(obj,event )
      } else {
        const event = {
          events: [...EventsArray,
          {
            eventType: tempEventType,
            status: 'Submitted',
            submittedAt: timeStamp,
          },
        ]};
        Object.assign(obj, event )

      }
      const taxIdStatus = {
        ...obj,
        taxIdEin: taxNum,
        taxExempt
      }

      try {
        await editOrganization({taxIdStatus}, orgId, token);
        const res2 = await getOrganizationData(orgId);
        let response = res2.data.body.data;
        this.setState({
          orgData: response,
          taxNum: '',
          sortedLogs:
            response.taxIdStatus &&
            response.taxIdStatus.events &&
            response.taxIdStatus.events.length > 0 &&
            response.taxIdStatus.events.sort(
              (a, b) => b.submittedAt - a.submittedAt
            ),
          loading: false,
        });
        this.props.alertShow(submitted_success_message)
        this.props.history.push({pathname: '/organization/overview'});
        // this.props.history.push('/dashboard')
      } catch (error) {
        this.setState({
          error: error && error.response && error.response.data.message,
          loading: false,
        });
      }
    }
  };
  render() {
    const {
      expanded,
      taxNum,
      orgData,
      loading,
      error,
      showAlert,
      sortedLogs,
      logsLoading,
      taxExempt,
      timeLineData
    } = this.state;
    const numberRegex = /^[0-9-]*$/
    const style = {
      width: '100%',
    };

  
    return (
      <MasterLayoutMiddle
        header={
          <div
            onClick={() =>
              this.props.history.push('/organization/overview')
            }
            className='backBtn'>
            <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
            <p style={{ alignSelf: 'center' }}>Back</p>{' '}
          </div>
        }
        isForm={true}
        formData={
          <>
            {showAlert && error && (
              <div
                style={{
                  zIndex: 9999,
                  width: '60%',
                  position: 'fixed',
                  top: 35,
                  marginTop: 40,
                  marginLeft: 200,
                }}
              >
                <AlertAction
                  alert={error.response.data.message}
                  alertType={'error'}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            <div>
              <div>
                <h1 className='title_top'>EIN/Tax ID</h1>
                <div className="dasheddBorder"></div>
              </div>
              {logsLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 250,
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                  <div>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            label='EIN/Tax ID Number'
                            placeholder=''
                            helperText={
                              error
                                ? 'EIN/Tax ID Number is required'
                                : taxNum.length <= 9 
                                ? '* Required' 
                                : 'EIN/Tax ID Number used to verify organization'
                            }
                            style={{ width: 570, }}
                            value={taxNum === ' ' ? '' : taxNum}
                            onChange={(e) => {
                              if (numberRegex.test(e.target.value) && e.target.value.length < 11) {
                                if (e.target.value !== "" && e.target.value.indexOf('-') === -1 && e.target.value.length === 2) {
                                  this.setState({ taxNum: e.target.value + '-' })
                                }
                                else {
                                  this.setState({ taxNum: e.target.value })
                                }
                              }
                            }}
                            error={error || taxNum.length <= 9}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <div className="field_box checkboxContainerC">
                          <BaseCheckBox
                            checked={taxExempt}
                            label="We are 501(c)(3) tax exempted not-for-profit organization."
                            color="secondary"
                            showHelperText={true}
                            helperText="This will be displayed to potential visitors/donors as VERIFIED - 501(c)(3)"
                            onChange={(e) => this.setState({ taxExempt: e.target.checked })}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        {loading ? (
                          <div
                            style={{
                              marginTop: 30,
                              display: 'flex',
                              justifyContent: 'center',
                              width: 570,
                            }}
                          >
                            <CircularProgress />
                          </div>
                        ) : (
                            <div className="form_button">
                              <p
                                className='cancel'
                                onClick={() =>
                                  this.props.history.push('/organization/overview')
                                }
                              >
                                CANCEL
                                </p>
                              <BaseButton text='SUBMIT' disabled ={taxNum.length <= 9} onClick={() => this.submit()} />
                            </div>
                          )}
                      </Grid>


                      <Grid item xs={12}>
                        {orgData.taxIdStatus &&
                          orgData.taxIdStatus.events &&
                          orgData.taxIdStatus.events.length > 0 && (

                            <EventLogs data={timeLineData}
                              headTitle="Events or Timeline"
                              schedule={true}
                              editSchedule={(e) => console.log('editSchedule', e)}
                              deleteSchedule={(e) => console.log('deleteSchedule', e)}
                            />


                            // <div>
                            //   <div className='head'>
                            //     <p className='headTitle'>EVENTS OR TIMELINE</p>
                            //   </div>
                            //   {sortedLogs.map((e, i) => {
                            //     return (
                            //       <ExpansionPanel
                            //         style={{ width: '100%' }}
                            //         expanded={expanded === i}
                            //         onChange={
                            //           e.messages &&
                            //           e.messages.length > 0 &&
                            //           this.handleChange(i)
                            //         }
                            //       >
                            //         <ExpansionPanelSummary
                            //           expandIcon={
                            //             e.messages &&
                            //             e.messages.length > 0 && <ExpandMoreIcon />
                            //           }
                            //           aria-controls='panel1bh-content'
                            //           id='panel1bh-header'
                            //           style={{ width: '100%' }}
                            //         >
                            //           <div>
                            //             {e.status === 'Submitted' && (
                            //               <img src={Add} alt='' />
                            //             )}
                            //             {e.status === 'Resubmitted' && (
                            //               <img src={Add} alt='' />
                            //             )}
                            //             {e.status === 'Approved' && (
                            //               <img src={Check} alt='' />
                            //             )}
                            //             {e.status === 'Rejected' && (
                            //               <img src={Cancel} alt='' />
                            //             )}
                            //           </div>
                            //           <Typography
                            //             className='accTitle'
                            //             style={{ display: 'flex', alignItems: 'center' }}
                            //           >
                            //             {e.status}
                            //           </Typography>
                            //           <Typography
                            //             className='accDate'
                            //             style={{
                            //               marginLeft: !e.messages.length > 0 && -35,
                            //             }}
                            //           >
                            //             {Moment(e.submittedAt).format(
                            //               'MMMM Do YYYY, h:mm:ss A'
                            //             )}
                            //           </Typography>
                            //         </ExpansionPanelSummary>
                            //         {e.messages.length > 0 &&
                            //           e.messages.map((m) => {
                            //             return (
                            //               <ExpansionPanelDetails>
                            //                 <Typography className='msgTitle accDate'>
                            //                   {m.title}
                            //                 </Typography>
                            //                 <Typography className='msgDesc accTitle'>
                            //                   {m.description}
                            //                 </Typography>
                            //               </ExpansionPanelDetails>
                            //             );
                            //           })}
                            //       </ExpansionPanel>
                            //     );
                            //   })}
                            // </div>
                          )}
                      </Grid>
                      
                    </Grid>
                  </div>
                )}
            </div>
            <div className='tax-separator'></div>
          </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={true}
      />
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
});

export default connect(null, mapDispatchToProps)(Taxein);
