import React, {useEffect, useState} from 'react'
import {MediaUploader} from '@dclab/dc-react-ui-kit'; 
import replyIcon from '../../assets/img/replyIcon.svg'
import FileIcon from '../../assets/img/upload_file_black_24dp.svg'
import {getMediaItems, uploadMedia, uploadVideo} from './Apis/index';
import { getVideosWithAppId } from '../../pages/Website/api';

export default function MediaUpload(props) {    

const [mediaList, setMediaList] = useState([]);
const [currentImageSize, setcurrentImageSize] = useState(0)
// const [selectedMedia, setSelectedMedia] = React.useState({logoUrl: '', logoName: ''});

useEffect( () => {
    getImages();
}, []);

// useEffect( () => {
//   setSelectedMedia(props.selectedMedia);
// }, [props.selectedMedia])


const getImages = () => {
  const token = localStorage.getItem("authToken");
  getMediaItems( token, props.typeOfMedia).then( response => {
  // console.log("res is : ", response);
  let res =  response?.data?.body?.data ?? [];
  setMediaList(res);
  let sum = 0;
  res.forEach(element => {
    sum = sum + (element?.imageMetaData?.imageSize ?? 0) ;      
  });
  setcurrentImageSize(sum)
  }).catch((err) => {
    console.log("err", err)
  });
}

 

/* const getMediaItems = () => {

  setMediaList(mediaObject);

} */

const selectMediaItem = (obj) => {
  console.log("Obj is: ", obj);
 
}



const imageData = async (imageMetaData, postData,) => {
  console.log("Meta data: ", imageMetaData);
  console.log("Post Data: ", postData);
  
  const token = localStorage.getItem("authToken");
  const response = await uploadMedia(imageMetaData, postData, token);
  getImages();
  /*const tempMediaArray = mediaList;
   tempMediaArray.push(response?.data?.body?.data );
  setMediaList(tempMediaArray); */ 
  console.log("Respose is: ", response);
  
}

const videoData = async (obj) => {
  const token = localStorage.getItem("authToken");
  const response = await uploadVideo(obj, token);
  getImages();
  console.log("response: ", response)
}

    return (
        <>
            <MediaUploader 
                mediaList = {mediaList}
                getMediaItems = {getMediaItems}
                showMediaTab={props.showMediaTab ? props.showMediaTab : "image"}
                typeOfMedia={props.typeOfMedia} 
                label={props.label} 
                onChange = {props.onChange? props.onChange: selectMediaItem}
                //onChange={selectMediaItem}
                addButtonHandler = {props.addMediaItem}
                imageData = {imageData}
                maxImageLimit={100}
                videoData = {videoData}
                replyIcon = {replyIcon}
                FileIcon = {FileIcon}
                buttonType={props.buttonType}
                buttonName = {props.buttonName}
                selectedMedia = {{...props.selectedMedia}}
                currentImageSize = {currentImageSize}
                onDelete = {props.onDelete}

            />
        </>
    )
}
