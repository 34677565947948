import React from 'react';
import { CardDetailsTable, MasterLayoutMiddle, FooterInner, PageHeaderWithButton, SimpleAccordian, } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';
import ParentGuardian from './ParentGuardian';
import VolunteerContact from './VolunteerContact';
import EmergencyContact from './EmergencyContact';
import ReferenceOne from './ReferenceOne';
import Agreement from './Agreement';







function VolunteerDetails(props) {


    const [selectedZone, setselectedZone] = React.useState(0);

    const saveChanges = (accordian) => {
        console.log("data", accordian)
        setselectedZone(0)
    }
    const onAccordianClick = (panel) => {
        console.log("onAccordianClick", panel)
        if (panel === selectedZone) {
            setselectedZone(0)
        } else {
            setselectedZone(panel)
        }
    }

    const AccordionDetails = {
        top: "0px"
        //top:"-46px"
    };



    let header = {
        tableName: `Basic Details`,
    };

    let data = [
        {
            field: "First name",
            value: "Hassan"
        },

        {
            field: "Last name",
            value: "Ahmed"
        },

        {
            field: "Location to volunteer",
            value: "New York, USA"
        },

        {
            field: "Become a member?",
            value: "No, not right now"
        },

        {
            field: "18 years or older?",
            value: "No"
        },
    ];

    let data2 = [
        {
            field: "Programs",
            value: "Covid-19, Limbs & Disability Support, Scholarship"
        },
        {
            field: "Description",
            value: "Helping Hand For Chittagonian (HHC) is a US base Not For Profit organization that working to raise awareness and support essential emergency aid to Chittagong, Bangladesh and as well as families in need in USA.",
        },

    ];

    let footerLinkName = "EDIT DETAILS";
    let footerDeleteButton = "DELETE";

    // HANDLE FOOTER LINK
    const onClick = () => {
        console.log('/edit-detail')
    }

    const onDeleteClick = () => {
        console.log('cancel-membership')
    }


    const handleChange = (event, value) => {
        console.log(event.target.value, value);
    };

    const style = {
        width: '100%',
    };



    const back = () => {
        console.log("back");
    }

    const Statustdata = [
        { value: 1, select: "Assigned" },
        { value: 2, select: "Unassigned" },
        { value: 3, select: "Completed" },
        { value: 4, select: "On hold" },
        { value: 5, select: "Canceled" },
    ];

    const Statustdata2 = [
        { value: 1, statuscolor: "#7266FB", select: "New" },
        { value: 2, statuscolor: "#FF9800", select: "In-progress" },
        { value: 3, statuscolor: "#3CC480", select: "Completed" },
        { value: 4, statuscolor: "#818E94", select: "On hold" },
        { value: 5, statuscolor: "#C44058", select: "Canceled" },
    ];

    const eData = [
        {
            id: 1,
            title: "PARENT/GUARDIAN",
            subtext: "Abdullah al-Razi",
            Edit: "EDIT",
            Cancel: "Cancel",
            Submit: "SAVE NOW",
            body: <ParentGuardian />,

        },

        {
            id: 2,
            title: "VOLUNTEER CONTACT",
            subtext: "+1 (123) 123-1234, hassanahmed@gmail.com",
            Edit: "EDIT",
            Cancel: "Cancel",
            Submit: "SAVE NOW",
            body: <VolunteerContact />,
        },

        {
            id: 3,
            title: "EMERGENCY CONTACT",
            subtext: "Aabidah Saba",
            Edit: "EDIT",
            Cancel: "Cancel",
            Submit: "SAVE NOW",
            body: <EmergencyContact />,
        },

        {
            id: 4,
            title: "REFERENCE 1",
            subtext: "Aabidah Saba",
            Edit: "EDIT",
            Cancel: "Cancel",
            Submit: "SAVE NOW",
            body: <ReferenceOne />,
        },

        {
            id: 5,
            title: "AGREEMENT",
            subtext: "Agreement and Signature",
            Edit: "EDIT",
            Cancel: "Close",
            body: <Agreement />,
            color: "#818E94",
            size: "12px",
        },



    ];


    return (

        <>
            <MasterLayoutMiddle

                header={
                    <div>
                        <PageHeaderWithButton
                            title="Hassan Ahmed"
                            indicatorColor="primary"
                            style={{ color: "#fff", border: "1px solid " }}
                            handleChange={(x, y) => handleChange(x, y)}
                            headerColor={'#000'}
                            disabled={true}
                            back={back}
                            showBackBtn={true}
                            hiddenDraft={true}
                            hiddenPublish={true}
                        />
                    </div>
                }

                content={
                    <div>

                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <CardDetailsTable
                                    data={data}
                                    data2={data2}
                                    twoColumns
                                    header={header}
                                    footerLinkName={footerLinkName}
                                    footerDeleteButton={footerDeleteButton}
                                    onClick={() => onClick()}
                                    onDeleteClick={() => onDeleteClick()}

                                    Statustdata={Statustdata}
                                    handleChange="handleChange"
                                    value="2"
                                    Statustdata2={Statustdata2}
                                    StatusDropdown={true}
                                    AssigneeDropdown={true}

                                    HeaderStatus={false}

                                />
                            </Grid>

                            <Grid item xs={12}>
                                {eData.map((item, i) => (
                                    <SimpleAccordian
                                        Title={item.title}
                                        text={item.subtext}
                                        TitleButtonLabel={item.Edit}
                                        AccordionDetails={AccordionDetails}
                                        panel={item.id}
                                        id={item.id}
                                        areacontrol={item.id}
                                        open={selectedZone === item.id ? true : false}
                                        onAccordianClick={onAccordianClick}
                                        accordianName="headerCard"
                                        saveChanges={item.save}
                                        Cencel={item.Cancel}
                                        Save={item.Submit}
                                        color={item.color}
                                        fontSize={item.size}
                                        content={
                                            <div className='AccformBox'>
                                                {item.body}
                                            </div>
                                        }
                                    />
                                ))}
                            </Grid>

                        </Grid>

                    </div>
                }

                isForm={true}
                formData={
                    <div>
                    </div>
                }

                isFooter={true}
                footer={
                    <FooterInner style={style} />
                }

                isWhiteBackground={true}

            />
        </>



    );
}


export default VolunteerDetails;