import React from "react";
import { GettingStarted, MasterLayoutMiddle, FooterInner } from "@dclab/dc-react-ui-kit";
function dk(props) {
  const onClickDonate = () => {
    localStorage.setItem('GetStarted', 'Clicked')
    props.history.replace('/deenkiosk/devices');
  };
  const style = {
    width: '100%',
  };

  return (
    <MasterLayoutMiddle
      content={
        <div style={{ margin: '0 auto', marginTop: 30 }}>
          <GettingStarted
            title="Engage your visitors with easy-to-use Kiosks onsite."
            strings={[
              "Accept one-time or recurring/monthly donations",
              "Display next Salah countdown and all Iqamah times",
              "Increase attendees by publishing upcoming events",
              "Broadcast important announcements",
              "Show current Weather",
              "Allow users to subscribe",
            ]}
            themeColor="#2A82F6"
            onClickDonate={onClickDonate}
            btnText="Get Started"
            width="180"
          />
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style}
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  );
}

export default dk;
