import React, { useEffect,useState } from "react";
import QDCLogo from './QDCLogo.png'
import DCLogo from './dc_logo.png'

import './style.css'

function UnsubscribeFeedback(props) {
    const [orgDetail, setOrgDetail] = useState({})
    useEffect(() => {
        if(props?.location?.state?.orgDetail) {
            setOrgDetail(props.location.state.orgDetail);
        }
    }, [])

    return (

        <div className="SubsFormContainer">

            <div className="QDCLogo"><img src={orgDetail?.orgLogo?.logoUrl} /></div>

            <div className="SubsFormBody">
                <h1 className="SubsFormTitle">Thanks for your feedback!</h1>
                <p>You will be missed</p>
            </div>

            <div className="QDCFooterLogo"><span>POWERED BY</span> <a href={process.env.REACT_APP_Environment === 'dev' ? 'https://shapla.deenconnect.io' : 'https://deenconnect.io' } target="blank"><img src={DCLogo} /></a></div>

        </div>
    );
}

export default UnsubscribeFeedback;

