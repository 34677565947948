import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  SignInComponent,
  AlertAction,
  VerificationSuccess,
} from '@dclab/dc-react-ui-kit';
import Lock from '../../assets/img/lock-24px.svg';
import Right from '../../assets/img/done-14px.svg';
import Close from '../../assets/img/clear-24px.svg';
import Logo from '../../assets/img/DC_Logo.png';
import Done from '../../assets/img/done-14px.svg';
import { resetPasswordHandler } from './apis';
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: '',
      confirmPassword: '',
      emailError: false,
      passwordError: false,
      error: false,
      loading: false,
      showAlert: false,
      resetSuccess: false,
    };
    this.reset = this.reset.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }
  handleChange = (name, event) => {
    this.setState({ [name]: event });
  };
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  async reset() {
    const { newPassword, confirmPassword } = this.state;
    this.setState({
      showAlert: false,
      error: false,
      loading: true,
    });
    if (!newPassword || !confirmPassword) {
      this.setState({
        emailError: true,
        passwordError: true,
        error: true,
        loading: false,
      });
    } else if (newPassword !== confirmPassword) {
      this.setState({
        showAlert: true,
        error: 'Password do not match',
        loading: false,
      });
      this.hideAlert();
    } else {
      try {
        const obj = {
          userName: this.props.forgotPasswordCredentails.email,
          code: this.props.forgotPasswordCredentails.code,
          newPassword,
        };
        this.setState({ loading: true });
        let res = await resetPasswordHandler(obj);
        this.setState({ resetSuccess: true, loading: false });
      } catch (error) {
        this.setState({
          error: error && error.response && error.response.data.message,
          loading: false,
          showAlert: true,
        });
        if(error.response.data.statusCode){
          this.props.history.replace({
            pathname: '/forgot-password',
            state: {
              errorServer: true,
              }}
            )
        }
        this.hideAlert();
      }
    }
  }
  render() {
    this.handleSubmit = (e) => {};
    const {
      resetSuccess,
      newPassword,
      confirmPassword,
      error,
      showAlert,
      loading,
    } = this.state;
    return (
      <div className='Background-Image' style={{ height: 'inherit' }}>
        {showAlert && error && (
          <div
            style={{
              width: "100%",
              position: "fixed",
              marginTop: 20,
              marginLeft: 200,
            }}
          >
            <AlertAction
              alert={error}
              alertType={"error"}
              onClick={() => this.setState({ showAlert: false })}
            />
          </div>
        )}
        {resetSuccess ? (
          <div className='signin-container' style={{ paddingTop: 20 }}>
            <VerificationSuccess
              src={Logo}
              title='Success'
              para='Congratulations, Your password has been reset successfully. Please sign in with your new password.'
              btnText='RETURN TO SIGN IN'
              onClickVerify={() => this.props.history.replace('/signin')}
              img={Done}
              showBox={true}
              showfooter={true}
            />
          </div>
        ) : (
          <div
            style={{ width: '45%', margin: '0 auto', paddingTop: 50 }}
            className='reset signin-container'
          >
            <SignInComponent
              isLoading={loading}
              title='Reset Password'
              name='reset'
              src={Logo}
              right={Right}
              close={Close}
              icon={Lock}
              newpasswordvalue={newPassword}
              newpasswordvalue_onChange={(e) =>
                this.handleChange('newPassword', e.target.value)
              }
              newpasswordvalue_required={true}
              newpasswordvalue_id={'newpassword'}
              newpasswordvalue_label={'New Password'}
              newpasswordvalue_error={error && !newPassword}
              newpasswordvalue_placeholder={''}
              newpasswordvalue_helperText={
                error && !newPassword ? 'New Password is required' : ''
              }
              confirmpasswordvalue={confirmPassword}
              confirmpasswordvalue_onChange={(e) =>
                this.handleChange('confirmPassword', e.target.value)
              }
              confirmpasswordvalue_required={true}
              confirmpasswordvalue_id={'confirmpassword'}
              confirmpasswordvalue_label={'Confirm Password'}
              confirmpasswordvalue_error={error && !confirmPassword}
              confirmpasswordvalue_placeholder={''}
              confirmpasswordvalue_helperText={
                error && !confirmPassword ? 'Confirm Password is required' : ''
              }
              text={'Reset Password'}
              signUpLink='/signup'
              forgotpasswordLink='/forgot-password'
              onClick={() => this.reset()}
            />
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    forgotPasswordCredentails: state.forgotPassword,
  };
};
export default connect(mapStateToProps, {})(ResetPassword);
