import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  SimpleAppBar,
  BasePaper,
  NavDrawer,
  BaseIcon,
  MasterLayout,
} from "@dclab/dc-react-ui-kit";
import { Switch, Route } from "react-router-dom";
import OrgOverview from "./orgOverview";
import EditOrganization from "./editOrganization";
import TaxEin from "./taxein";
import TaxExemption from "./taxExemption";
import AddTeamMember from "./addTeamMember";
import EditTeamMember from "./editTeamMember";
import Speakers from "./speakers";
import AddSpeaker from "./AddSpeaker";
import EditSpeaker from "./EditSpeaker";
import Services from "./services";
import AddService from "./addService";
import EditService from "./EditService";
import { ReactComponent as MoreApps } from "../../assets/nav_icons/moreapps.svg";
import { ReactComponent as Support } from "../../assets/nav_icons/support.svg";
import { ReactComponent as Launch } from "../../assets/nav_icons/launch-24px.svg";
import MainLogo from "../../assets/img/DC_Logo.png";
import { ChevronRight } from "@material-ui/icons";
import { getOrganizationData } from "./api";
import AddBillingInfo from "./AddBillingInfo";
import ManagePlans from "./Plans/ManagePlans";
import PlanDetails from "./Plans/PlanDetails";
import OrgOrders from "../../pages/Order/Order.js";
import OrgOrderDetails from "../../pages/Order/OrderDetails";

import PaymentMethods from "./PaymentMethods";

import ContactUs from "./ContactUs/ContactUs";
import ContactUsDetails from "./ContactUs/ContactUsDetails";
import ContactUsRespond from "./ContactUs/ContactUsRespond";
import EditBillingInfo from "./EditBillingInfo";

import PaymenthistoryTable from "./PaymentHistory/PaymenthistoryTable";
import PaymentReceipt from "./PaymentHistory/PaymentReceipt";


import ConnectedAccountsComp from "./ConnectedAccountsComp";
import SessionExpiredModal from "../../components/SessionExpiredModal";


import CommitteesListing from "./Committees/CommitteesListing";
import CommitteesDetails from "./Committees/CommitteesDetails";
import AddCommittee from "./Committees/AddCommittee";
import EditCommittee from "./Committees/EditCommittee";
import MemberDetails from "./Committees/MemberDetails";
import CommitteesDetailsAddMember from "./Committees/CommitteesDetailsAddMember";
import CommitteeAddMember from "./Committees/CommitteeAddMember";
import CommitteeEditMember from "./Committees/CommitteeEditMember";
import CommitteesMembersListing from "./Committees/CommitteesMembersListing";
import CommitteesDesignations from "./Committees/CommitteesDesignations";
import CommitteesDesignationsAddNew from "./Committees/CommitteesDesignationsAddNew";


import TeamMembersActiveDetails from "./TeamMembersActiveDetails";

import PrivateRoute from "../../components/PrivateRoute";



const HomePage = (props) => {
  const [orgData, setOrgData] = useState({});
  const [error, setError] = useState(false);
  const [detailsRequired, setDetailsRequired] = useState(true);
  const [stripeRequired, setStripeRequired] = useState(true);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname])
  useEffect(() => {
    const fetchData = async () => {
      const orgId = localStorage.getItem("orgID");
      try {
        const res = await getOrganizationData(orgId);
        const response = res.data.body.data;
        const { phoneFax, enabledIntegrations, orgAddress } = response;
        if (
          (phoneFax && phoneFax.primaryPhone.length < 3) ||
          (orgAddress && orgAddress.line1.length < 3) ||
          (orgAddress &&
            ((orgAddress.city && orgAddress.city.length < 3) ||
              (orgAddress.country && orgAddress.country.length < 3))) ||
          (orgAddress && orgAddress.state.length < 3)
        ) {
          setDetailsRequired(true);
          setLoading(false);
        }
        if (
          (phoneFax && phoneFax.primaryPhone.length > 3) ||
          (orgAddress && orgAddress.line1.length > 3) ||
          (orgAddress &&
            ((orgAddress.city && orgAddress.city.length > 3) ||
              orgAddress.country.length > 3)) ||
          (orgAddress && orgAddress.state.length > 3)
        ) {
          setDetailsRequired(false);
          setLoading(false);
        }
        if (!enabledIntegrations.deenfund.stripeAccount) {
          setStripeRequired(true);
          setLoading(false);
        }
        if (!enabledIntegrations.deenfund.stripeAccount) {
          setStripeRequired(false);
          setLoading(false);
        }
        setOrgData(response);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const styles = {
    conatiner: {
      display: "flex",
      background: "#FFF",
    },
    navBar: {
      backgroundImage: "linear-gradient(#2490F9, #7266FB)",
      color: "white",
      height: "105vh",
      marginTop: 75,
      zIndex: 200
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      backgroundColor: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
      paddingTop: 0,
    },
    paperStyle: {
      width: "100%",
      backgroundColor:
        props.match.path === "/organization/overview/edit-details" ||
          props.match.path === "/organization/overview/taxein" ||
          props.match.path === "/organization/overview/tax-exemption" ||
          props.match.path === "/organization/overview/add-team-member" ||
          props.match.path === "/organization/overview/edit-team-member/:id" ||
          props.match.path === "/organization/add-new-guests" ||
          props.match.path === "/organization/edit-speaker/:id" ||
          props.match.path === "/organization/services/add-new-service" ||
          props.match.path === "/organization/services/edit-service/:id"
          ? "#FFF"
          : "#F7F8FA",
    },
    header: {
      boxShadow: "0px 1px 4px #0000001A",
      borderBottom: "1px solid #0000001A",
      position: "fixed",
      width: "100%",
      zIndex: 999,
      backgroundColor: "#F7F8FA",
    },
    footer: {
      backgroundColor:
        props.match.path === "/organization/overview/edit-details" ||
          props.match.path === "/organization/overview/taxein" ||
          props.match.path === "/organization/overview/tax-exemption" ||
          props.match.path === "/organization/overview/add-team-member" ||
          props.match.path === "/organization/overview/edit-team-member/:id" ||
          props.match.path === "/organization/add-new-guests" ||
          props.match.path === "/organization/edit-speaker/:id" ||
          props.match.path === "/organization/services/add-new-service" ||
          props.match.path === "/organization/services/edit-service/:id"
          ? "#FFF"
          : "#F7F8FA",
    },
  };

  const availableSubscriptions = (name) => {
    // console.log("subscriptions", subscriptions, "name", name)
    let subscriptions = localStorage.getItem("subsData") ? localStorage.getItem("subsData") : [];
    if (name?.length && subscriptions.length) {
      const arr = name.filter(value => subscriptions.includes(value));
      return arr?.length ? true : false
    }
  };

  const returnActiveTab = () => {
    switch (props.match.path) {
      case '/organization/overview/taxein':
      case '/organization/overview/edit-details':
      case '/organization/overview/add-team-member':
      case '/organization/overview/edit-team-member/:id':
        return 'Org Overview';

      case '/organization/contact-us-details':
        return 'Contact Us';

      case '/organization/edit-speaker/:id':
      case '/organization/add-new-guests':
        return 'Speakers';

      case '/organization/services/add-new-service':
      case '/organization/services/edit-service/:id':
        return 'Services';

      case '/organization/committees-details':
      case '/organization/edit-committee':
        return 'Committees';

      case '/organization/order-details':
      case '/organization/edit-committee':
        return 'Orders';

      case '/organization/payment-receipt':
        return 'Payment History';

      case '/organization/add-billing-details':
      case '/organization/edit-billing-details':
        return 'Payment Methods';

      default:
        return false;

    }

  }




  const headerItems = [


    {
      title: "Org Overview",

      path: "/organization/overview",
      disabled: detailsRequired,
    },
  ];
  const bodyItems = {
    manage: [
      {
        title: "Contact Us",
        path: "/organization/contact-us",
        child: [],
        disabled: detailsRequired,
      },
      {
        title: "Services",
        path: "/organization/services",
        child: [],
        disabled: detailsRequired,
      },

      // {
      //   title: "Committees",
      //   path: "/organization/committees",
      //   child: [],
      //   disabled: detailsRequired,
      // },
    ],

    billing: [
      true ? {
        title: "Orders",
        path: "/organization/orders",
        child: [],
        disabled: detailsRequired,
      } : undefined,
      {
        title: "Payment History",
        path: "/organization/payment-history",
        child: [],
        disabled: detailsRequired,
      },
      {
        title: "Manage Plans",
        path: "/organization/manage-plans",
        child: [],
        disabled: detailsRequired,
      },
      {
        title: "Payment Methods",
        path: "/organization/payment-methods",
        child: [],
        disabled: detailsRequired,
      },
      // {
      //   title: "Order Support",
      //   path: "#",
      //   child: [],
      //   disabled: detailsRequired,
      // },
    ].filter((el) => el != null),

    Settings: [
      true ? {
        title: "Connected Accounts",
        path: "/organization/connected-accounts",
        child: [],
        disabled: detailsRequired,
      } : undefined,
    ]



    // settings: [
    //   {
    //     title: "Connected Accounts",
    //     path: "/organization/connected-accounts",
    //     child: [],
    //     disabled: detailsRequired,
    //   },
    // ],
  };

  const footerItems = [
    {
      title: "More Apps",
      icon: <BaseIcon icon={MoreApps} />,
      path: "/more-apps",
      child: [
      ],
      disabled: detailsRequired,
      secondaryIcon: <BaseIcon icon={ChevronRight} />,
    },
    {
      title: "Support",
      icon: <BaseIcon icon={Support} />,
      path: process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/support" : "http://deenconnect.io/support",
      child: [],
      secondaryIcon: <BaseIcon icon={Launch} />,
      disabled: detailsRequired,
    },
  ];
  function onClick() {
    props.history.push("/dashboard");
  }
  const logout = () => {
    localStorage.setItem("user", false);
    localStorage.setItem("authToken", "");
    props.history.replace("/signin");
    localStorage.clear();
  };
  const userProfile = () => {
    props.history.push("/admin-profile")
  };
  const navigate = (x, y) => {
    if (x === 'Support') {
      window.location.href = (y)
    } else {
      props.history.push(y)
    }
  }
  const getInitials = () => {
    let nameArr = localStorage.getItem("name") || (props?.signup?.fullName ?? '');
    nameArr = nameArr.length > 0 ? nameArr?.trim()?.split(" ") : [];
    let initials;
    if (nameArr?.length > 1) {
      initials = nameArr[0][0] + nameArr[1][0];
    } else {
      initials = nameArr?.[0]?.[0] + nameArr[0]?.[1] ?? '';
    }
    return initials;
  }
  let image = localStorage.getItem("image");
  const content = (
    <React.Fragment>
      <MasterLayout
        header={
          <SimpleAppBar
            src={MainLogo}
            name={localStorage.getItem("name") || props.signup.fullName}
            showImg={image && image !== "undefined" ? true : false}
            img={image && image !== "undefined" ? image : ""}
            role={localStorage.getItem("role")}
            style={{ marginTop: 80, marginLeft: -10 }}
            onClickLogout={() => logout()}
            initials={getInitials()}
            onClickProfile={() => userProfile()}
          />
        }
        sideBar={
          <div className='org-nav-height'>
            <NavDrawer
              headerNavItems={headerItems}
              bodyNavItems={bodyItems}
              footerNavItems={footerItems}
              onBackBtnClick={() => onClick()}
              onClick={() => { }}
              buttonShow={true}
              showTitle={true}
              showBodyItem={true}
              navigate={(x, y) => navigate(x, y)}
              selectedTitle={localStorage.getItem("pageTitle")}
              distance={1300}
              activeTab={returnActiveTab()}
              footerLower="footerLower"
            />
            <SessionExpiredModal />
          </div>
        }
        headerFixed={true}
        middleContent={
          <Switch>
            <PrivateRoute
              exact
              path="/organization/overview"
              component={OrgOverview}
            />
            <PrivateRoute
              exact
              path="/organization/overview/edit-details"
              component={EditOrganization}
            />
            <PrivateRoute
              exact
              path="/organization/overview/taxein"
              component={TaxEin}
            />
            <PrivateRoute
              exact
              path="/organization/overview/tax-exemption"
              component={TaxExemption}
            />
            <PrivateRoute
              exact
              path="/organization/overview/add-team-member"
              component={AddTeamMember}
            />
            <PrivateRoute
              exact
              path="/organization/overview/edit-team-member/:id"
              component={EditTeamMember}
            />

            <PrivateRoute
              exact
              path="/organization/overview/team-member/details"
              component={TeamMembersActiveDetails}
            />

            <PrivateRoute exact path="/organization/guests" component={Speakers} />
            <PrivateRoute
              exact
              path="/organization/add-new-guests"
              component={AddSpeaker}
            />
            <PrivateRoute
              exact
              path="/organization/edit-speaker/:id"
              component={EditSpeaker}
            />
            <PrivateRoute exact path="/organization/services" component={Services} />
            <PrivateRoute
              exact
              path="/organization/services/add-new-service"
              component={AddService}
            />
            <PrivateRoute
              exact
              path="/organization/services/edit-service/:id"
              component={EditService}
            />
            <PrivateRoute
              exact
              path="/organization/add-billing-details"
              component={AddBillingInfo}
            />
            <PrivateRoute
              exact
              path="/organization/edit-billing-details"
              component={EditBillingInfo}
            />
            <PrivateRoute
              exact
              path="/organization/manage-plans"
              component={ManagePlans}
            />
            {/* <Route
              exact
              path="/organization/plan-details"
              component={PlanDetails}
            /> */}

            <PrivateRoute exact path="/organization/payment-methods" component={PaymentMethods} />

            <PrivateRoute exact path="/organization/contact-us" component={ContactUs} />
            <PrivateRoute exact path="/organization/contact-us-details" component={ContactUsDetails} />
            <PrivateRoute exact path="/organization/contact-us-respond" component={ContactUsRespond} />

            <PrivateRoute exact path="/organization/payment-history" component={PaymenthistoryTable} />
            <PrivateRoute exact path="/organization/payment-receipt" component={PaymentReceipt} />

            <PrivateRoute exact path="/organization/connected-accounts" component={ConnectedAccountsComp} />

            <PrivateRoute exact path="/organization/orders" component={OrgOrders} />
            <PrivateRoute exact path="/organization/order-details" component={OrgOrderDetails} />

            <PrivateRoute exact path="/organization/committees" component={CommitteesListing} />
            <PrivateRoute exact path="/organization/committees-details" component={CommitteesDetails} />
            <PrivateRoute exact path="/organization/add-committee" component={AddCommittee} />
            <PrivateRoute exact path="/organization/edit-committee" component={EditCommittee} />
            <PrivateRoute exact path="/organization/committees/member-details" component={MemberDetails} />
            <PrivateRoute exact path="/organization/committees/details-add-member" component={CommitteesDetailsAddMember} />
            <PrivateRoute exact path="/organization/committees/members" component={CommitteesMembersListing} />
            <PrivateRoute exact path="/organization/committees/edit-member" component={CommitteeEditMember} />
            <PrivateRoute exact path="/organization/committees/add-member" component={CommitteeAddMember} />
            <PrivateRoute exact path="/organization/committees/designations" component={CommitteesDesignations} />
            <PrivateRoute exact path="/organization/committees/add-new-designations" component={CommitteesDesignationsAddNew} />










          </Switch>
        }
      />
    </React.Fragment>
  );
  return (
    <>
      <BasePaper
        elevation={0}
        context={content}
        paperStyle={styles.paperStyle}
      />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    signup: state.signup,
  };
};
export default connect(mapStateToProps, {})(HomePage);
