import React, { Fragment, useEffect, useState } from "react";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { BaseSelect, AddButton } from "@dclab/dc-react-ui-kit";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Grid from '@material-ui/core/Grid';
import SingleMedia from './Functions';
import './style.css';
import './accordion.css';
import YoutubeModal from './YoutubeModal' ;

const useStylesUploader = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: "bold",
    color: "#4D4D4D",
    textTransform: "uppercase",
    fontSize: "14px"
  },
  secondaryHeading: {
    fontWeight: 400,
    color: "#4D4D4D",
    textTransform: "capitalize",
    fontSize: "14px"
  },
}));

const useStyles = makeStyles((theme) => ({

  CardContainer: {
    width: "119px",
    height: "119px",
    display: "inline-block",
    borderRadius: "0px",
    padding: "0",
    overflow: "hidden",
    margin: "2px !important",
    float: "left",
  },


  PackageCardContainer: {
    margin: "0px -5px",

    "& .MuiToggleButtonGroup-root": {
      display: "block",
      borderRadius: "0px",
    },

    "& .MuiToggleButton-root": {
      border: "#E4E8EC 1px solid",
    },

    "& .Packagebadge": {
      backgroundColor: "transparent",
      borderRadius: "100px",
      width: "12px",
      height: "12px",
      position: "absolute",
      right: "10px",
      top: "10px",
      padding: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: "999",
    },

    "& .BadgeIcon": {
      fontSize: "14px",
      color: "#f5f7f9",
      display: "none",
    },

    "& .MedialibImg": {
      width: "100%",
      objectFit: "cover",
      height: "100% !important",
      position: "absolute",
      left: 0,
      top: 0,
    },

    "& .MuiToggleButtonGroup-groupedHorizontal:not(:first-child)": {
      marginLeft: "2px",
    },

    "& .MuiToggleButton-root.Mui-selected + .MuiToggleButton-root.Mui-selected": {
      marginLeft: "2px",
      borderLeft: "#2A82F6 1px solid",
    },

    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: "transparent",
      border: "#2A82F6 1px solid",

      "& .Packagebadge": {
        backgroundColor: "#2A82F6",
      },

      "& .BadgeIcon": {
        color: "#fff",
        display: "block",
      },

      "& .MedialibImg": {
        opacity: 0.6,
      },


    },

  },

}));

const orgEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")
) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}

const orgBaseUrl = orgEnv[currentEnv];

export default function MediaLibraryModal(props) {

  const [preview, setpreview] = useState({
    logoUrl: "", logoName: ""
  })
  const [bannerPreview, setbannerPreview] = useState({
    logoUrl: "", logoName: ""
  })
  
  const [alignment, setAlignment] = React.useState();
  const [mediaType, setMediaType] = React.useState( props.typeOfMedia );
  const [activateDone, setActivateDone] =  React.useState('');
  const [ mediaObject, setMediaObject] = useState(); 
  

  const handleAlignment = (event, newAlignment) => {
    
    
  };  

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setActivateDone('');
    setOpen(false);
    setAlignment( '' );
  };

  // const [show, setShow] = React.useState(false);
  const [mediaList, setMediaList] = React.useState([]);

  const classes = useStyles();

  const fileUploaderClasses = useStylesUploader();

  const handleChange = async (e) => {
    setMediaType( e.target.value )   ;
    const token = localStorage.getItem("authToken");
    

    const response = await getMediaItems( token, e.target.value);

    setMediaList( response.data.body.data );
  };

  let allMediaItems = []; 
  let showImageupload, showVideoupload = false  ; 

  if( props.typeOfMedia == '/images' ){
    //check if the media type is image 
    allMediaItems = [
      {
        value: "/images",
        label: "Image"
      }
    ];   

    showImageupload = true ; 

  }else if( props.typeOfMedia == '/videos'  ){
    allMediaItems = [
      {
        value: "/videos",
        label: "Video"
      }
    ];   
    showVideoupload = true ; 
  }else{
    allMediaItems = [
      {
        value: "/all-type",
        label: "All media items"
      },
      {
        value: "/images",
        label: "Image"
      },
      {
        value: "/videos",
        label: "Video"
      },
    ];

    showImageupload = true ; 
    showVideoupload = true ; 
  }
   
  

  useEffect( () => {
    const token = localStorage.getItem("authToken");
    let mounted = true;

    getMediaItems( token, mediaType).then( response =>{
      if( mounted ){
        setMediaList( response.data.body.data );
      }
    })
    return () => mounted = false ; 

  }, [] );

  const mediaClickEvent = async (e  ) =>{
    const obj = {
      isVideo: parseInt(e.target.getAttribute('isvideo') ),
      src:  (e.target.getAttribute('src') ), 
      title: e.target.getAttribute('mediatitle'),
    };
    let newEvent = parseInt( e.target.getAttribute('ikey') ) + 1;
    newEvent = alignment === newEvent ? null : newEvent;
    setActivateDone('importUrlButton');    

    setAlignment( newEvent );
    props.onChange(obj);
     
    setMediaObject( obj );
  };

  const imageOnchange = async ( e ) =>{
    const token = localStorage.getItem("authToken");

    const ml = await getMediaItems( token, mediaType);

    setMediaList(ml.data.body.data);
    
  }

  const videoOnchange = async (e ) =>{
    setOpen( false );
  }

  const addButtonHandler = async(e) =>{
    const obj = mediaObject ; 
    setpreview({ logoUrl: obj.src , logoName: obj.title });
    setOpen(false );
    console.log("test", obj)
  }

  
  return (

    <div className="ModalContainer">

      {/* <button className="AddVideoModalButton" type="button" onClick={handleOpen}><AddRoundedIcon /> ADD MEDIA LIBRARY</button> */}
      <div className={`org-accordian ${fileUploaderClasses.root}`}>      
        {props.buttonType === "addMedia" ? <AddButton onClick={() => setOpen(true)} text="Add Media" disabled={props.disabled ? "disabled" : ""} /> : <div className="second-row">
          <div className="upload-field">
            <div className="logo-label">{props.label}</div>
            <div className="logo-field">
              {preview?.logoUrl ? <Fragment>
                <span className="uploaded-img-container"><img className="uploaded-img" src={preview.logoUrl} width="36px" alt="media" /> {preview?.logoName}</span>
                <span className="delete-logo-icon"><DeleteForeverIcon onClick={() => setpreview({ logoUrl: "", logoName: "" })} /></span>
              </Fragment> : <span className="upload-logo-wrapper" onClick={handleOpen}>
                <span className="photo-icon"><InsertPhotoIcon /></span>
                <span className="cloud-icon"> {props.buttonName} <CloudUploadIcon /></span>
              </span>}
            </div>
          </div>
        </div>  }      
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="MediaModalContainer">

            <div className="ModalHeader">
              <h1>Media Library</h1>
              <div className="ModalCloseIcon"><CloseRoundedIcon onClick={handleClose} /></div>
            </div>

            <div className="MediaButtonModalHeader">

              <Grid container spacing={0}>	      
              { mediaType=='/all-type' ?(
                <Grid item xs={4}> 
                <BaseSelect
                  value={mediaType}
                  onChange={handleChange}
                  object={allMediaItems}
                  label=""
                />
              </Grid>
              ):(<div />) }               

                </Grid>                
            </div>

            <div className="LibModalBody">
              <div className={classes.PackageCardContainer}>
                <ToggleButtonGroup
                  value={alignment}   
                  exclusive               
                  onChange={handleAlignment} >                  
                  { mediaList.map( (object , index ) =>(                    
                      <ToggleButton aria-label="left aligned" key={index + 1} value={index + 1} className={classes.CardContainer} selected={alignment === index + 1 ? true : false}>                
                          <SingleMedia object={object} handler={mediaClickEvent}  index={index} />
                    </ToggleButton>           
                    
                  ))}
                </ToggleButtonGroup>
              </div>
            </div>
            <div className="ModalFooter">
              <div className="LeftButtonCol">
                { showImageupload? (                 
                  
                  <YoutubeModal onChange={imageOnchange} className={"MediaAddButton " + "importUrlButton"} labelName="Upload Image" initialtab="0"/>
                ): (
                  <div/>
                ) }
                { showVideoupload? (
                    
                    <YoutubeModal onChange={imageOnchange} className={"MediaAddButton " + "importUrlButton"}  labelName="Add Video" initialtab="1" />
                ): (
                  <div/>
                ) } 
                
              </div>

              <div className="rightButtonCol">
                <button onClick={addButtonHandler} className={"MediaAddButton " + activateDone } type="button"> Add <ArrowRightAltIcon className="ImportBtnArrowIcon" /></button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

async function getMediaItems(  token , path=''){
    
  if( path.substr(-1) === '/') {
    path = path.substr(0, path.length -1 );
  }

  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/medias${path}` , {
        headers: {
          "Content-Type": "application/json",
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}





