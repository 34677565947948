import React, { Component } from "react";
import {
  BaseTextField,
  BaseButton,
  BaseStepper,
  BaseStepperExtended,
  TextEditor,
  FormPublishUnpublish,
  BaseImgChip,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import Moment from "moment";
import MomentTime from "moment-timezone";
import Typography from "@material-ui/core/Typography";
import "./style.css";
import { getEvents, getSingleEvent, updateEvent } from "../Donations/deen-kioks-website/Apis";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
import { getOrganizationData } from "../Organization/api";
import { getEventsWithAppId, updateEventsWithAppId } from "./api";
const { token_expired, success_message_edit_event } = messages_content;
const styles = {
  formBody: {
    background: "#fff",
  },
  contents: {
    padding: 24,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: "76%",
    marginTop: 70,
    marginLeft: "20%",
  },
  formTitle: {
    fontSize: 22,
    color: "#5A5A5A",
  },
  formTitleSub: {
    fontSize: 14,
    color: "#818E94",
  },
  dottedBorder: {
    border: "#1px dashed #D3D8DA",
  },
  textEditor: {
    border: '1px solid black'
  },
  pageTitle: {
    color: "#5A5A5A",
    fontSize: "28px",
    paddingBottom: "30px",
    textAlign: "center",
  },
};
class EditEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 1,
      visibility: "Public",
      publish: "Immediately",
      unPublish: "Never",
      publishDate: 'Immediately',
      publishTime: "",
      unPublishDate: "Never",
      unPublishTime: "",
      unPublishTimestamp: 0,
      UTCDate: '',
      UTCTime: '',
      UTCDateUnPublish: '',
      UTCTimeUnPublish: '',
      validUnPublish: true,
      validPublish: true,
      publishedDateAndTime: '',
      unPublishedDateAndTime: '',
      event: "",
      startAt: '',
      endAt: '',
      repeat: '',
      Address: {},
      eventsList: [],
      selectedEvents: [],
      speakers: [],
      textValue: [{ type: "paragraph", children: [{ text: "" }] }],
      Events: [],
      description: [{ type: "paragraph", children: [{ text: "" }] }],
      Loading: true,
      errorEvent: false,
      error: false,
      featured: false,
      image: []
    };
  }
  handleNext = () => {
    console.log(this.state.selectedEvents, 'looppppp')
    if (this.state.selectedEvents.length === 0) {
      this.setState({ errorEvent: true })
    } else {
      this.setState({ activeStep: this.state.activeStep + 1 })
      this.props.history.push(`/website/edit-event/${this.state.selectedEvents[0]._id}`)
    }
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 })
  };
  async componentDidMount() {
    this.setState({ Loading: true })
    const token = localStorage.getItem('authToken')
    const orgId = localStorage.getItem('orgID')
    try {
      const eventId = this.props.match.params.id;
      const getEventAppId = await getEventsWithAppId(token, orgId,'deenweb')
      const dataEve = getEventAppId.data.body.data;
      const EventsWeb = dataEve.filter(text => text._id === eventId);
      console.log("EventsWeb: ", EventsWeb)
      const speakers = EventsWeb[0].event.speakers.map((text, index) => {
        return ({
          key: index + 1,
          label: text.name.firstName + (text.name.lastName ? ' '+text.name.lastName : ''),
          src: text.imageMetaData ? text.imageMetaData.imageUrl : '',
          alt: "",
        })
      })
      console.log(EventsWeb,speakers, 'webbbbb')
      const year = new Date(EventsWeb[0].publishTo).getFullYear();

      this.setState({
        selectedEvents: EventsWeb,
        event: EventsWeb[0].event.title,
        description: JSON.parse(EventsWeb[0].event.description),
        speakers: speakers,
        Address: EventsWeb[0].event.location.address,
        startAt: `${Moment(EventsWeb[0].event.eventStart.startAt).format("ddd")}, ${Moment(EventsWeb[0].event.eventStart.startAt).format("MMM D, YYYY")}, ${Moment(EventsWeb[0].event.eventStart.startAt).format("h:mm A")}`,
        endAt: `${Moment(EventsWeb[0].event.endAt).format("ddd")}, ${Moment(EventsWeb[0].event.endAt).format("MMM D, YYYY")}, ${Moment(EventsWeb[0].event.endAt).format("h:mm A")}`,
        repeat: EventsWeb[0].event.repeat.category,
        image: [
          {
            key: 0,
            label: Object.keys(EventsWeb[0].event).length > 0 ? EventsWeb[0]?.event?.imageDetails?.imageName : '',
            src: Object.keys(EventsWeb[0].event).length ? EventsWeb[0]?.event?.imageDetails?.imageUrl : '',
            alt: "",
          }
        ],
        featured: EventsWeb[0].featured,
        visibility: Moment(EventsWeb[0].publishTo) < Moment() ? 'Hidden' : EventsWeb[0].status,
        unPublishTime: `${Moment(EventsWeb[0].publishTo).format("h:mm A")}`,
        publishTime: `${Moment(EventsWeb[0].publishFrom).format("h:mm A")}`,
        publishDate: `${Moment(EventsWeb[0].publishFrom).format("ddd")}, ${Moment(EventsWeb[0].publishFrom).format("MMM D, YYYY")}`,
        unPublishDate: year === 2099 ? "Never" :  `${Moment(EventsWeb[0].publishTo).format("ddd")}, ${Moment(EventsWeb[0].publishTo).format("MMM D, YYYY")}`,
        UTCDate: Moment(EventsWeb[0].publishFrom).format('YYYY-MM-DD'),
        UTCTime: Moment(EventsWeb[0].publishFrom).format("HH:mm:ss"),
        unPublishTimestamp: EventsWeb[0].publishTo,
        UTCDateUnPublish: year === 2099 ? "" : Moment(EventsWeb[0].publishTo).format('YYYY-MM-DD'),
        UTCTimeUnPublish: Moment(EventsWeb[0].publishTo).format("HH:mm:ss"),
        unPublishedDateAndTime: EventsWeb[0].publishTo,
        publishedDateAndTime: EventsWeb[0].publishFrom,
      })
      const data = EventsWeb.map(text => {
        return (
          {
            ...text,
            value: text.event.title,
            label: text.event.title
          }
        )
      })
      this.setState({ eventsList: EventsWeb })
      this.setState({ Events: data, Loading: false });
    } catch (err) {
      console.log(err)
      this.setState({ Loading: false })
      this.setState({ error: err.response && err.response.data.message })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  }
  InputTextEditor = (e) => {
    this.setState({ textValue: e })
  }
  render() {
    const {
      activeStep,
      image,
      visibility,
      publish,
      unPublish,
      publishDate,
      publishTime,
      UTCDate,
      validUnPublish,
      validPublish,
      unPublishDate,
      UTCTime,
      UTCDateUnPublish,
      UTCTimeUnPublish,
      publishedDateAndTime,
      featured,
      unPublishedDateAndTime,
      selectedEvents,
      speakers,
      Address,
      Loading,
      event,
      startAt,
      repeat,
      endAt,
      description,
      error
    } = this.state;
    const convertUTC = (date) => {
      console.log("convertUTC: ", date)
      return date.toISOString()
    }
    const onSave = async () => {
      const {selectedEvents} = this.state
      console.log(selectedEvents, 'eventsssss')
      const token = localStorage.getItem("authToken");
      const orgId = localStorage.getItem('orgID');
      const data = UTCDate === '' ? new Date() : new Date(`${UTCDate + ' ' + UTCTime}`)
      const dataU = convertUTC(data);
      const dataE = UTCDateUnPublish === '' ? new Date() : new Date(`${UTCDateUnPublish + ' ' + UTCTimeUnPublish}`)
      const UnpublishTime = convertUTC(dataE);
      const dataObj = {
              event: selectedEvents[0].event._id,
              publishFrom: publishedDateAndTime === '' ? publish : dataU,
              publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime,
              status: visibility,
              featured: featured
          }
      try {
        const resEventsUpdate = await updateEventsWithAppId(token, orgId,'deenweb',selectedEvents[0].event._id,dataObj )
        this.props.alertShow(success_message_edit_event)
          this.props.history.push("/website/events");
      } catch (err) {
        this.setState({ error: err.response && err.response.data.message })
        setTimeout(() => {
          this.setState({ error: false })
        }, 8000);
      }
    };
    const onPublishSave = () => {
      let Time = this.state.publishTime.split(' ');
      let dated = MomentTime(new Date(this.state.publishDate)).format('YYYY-MM-DD');
      let split = Time[0].split(':');
      let Hours = ''
      let H;
      if (Time[1] === 'AM') {
        H = parseInt(split[0]);
        Hours = H + ':' + split[1]
      } else {
        H = parseInt(split[0]) + 12;
        Hours = H + ':' + split[1]
      }
      let date = `${dated + 'T' + Hours}:00.00Z`
      this.setState({ publishedDateAndTime: date });
    }
    const onPublishCancel = () => {
      console.log("onPublishCancel")
    };
    const onUnPublishSave = () => {
      let Time = this.state.unPublishTime.split(' ');
      let dated = MomentTime(new Date(this.state.unPublishDate)).format('YYYY-MM-DD');
      let split = Time[0].split(':');
      let Hours = ''
      let H;
      if (Time[1] === 'AM') {
        H = parseInt(split[0]);
        Hours = H + ':' + split[1]
      } else {
        H = parseInt(split[0]) + 12;
        Hours = H + ':' + split[1]
      }
      let date = `${dated + 'T' + Hours}:00.00Z`
      this.setState({ unPublishedDateAndTime: date })
    };
    const onUnPublishCancel = () => {
      console.log("onUnPublishCancel")
    };
    const onBack = () => {
      this.setState({ activeStep: 0 })
    }
    const onVisibilitySave = () => {
      console.log("visibility saved");
    };
    const onVisibilityCancel = () => {
      console.log("visibility cancelled");
    };
    const getPublishDate = (value) => {
      if(value === "Immediately") {
        this.setState({
          publishedDateAndTime: '',
          UTCDate: '',
          publish: 'Immediately',
          publishDate: 'Immediately',

        });
      } else {
        this.setState({
          publishDate: `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`,
          validPublish: false
        })
        this.setState({ postDate: Moment(value).format("YYYY-MM-DD"), UTCDate: Moment(value).format('YYYY-MM-DD') })
      }
    };
    const getPublishTime = (value) => {
      this.setState({ publishTime: Moment(value).format("h:mm A") });
      this.setState({ postTime: `${Moment(value).format("HH:mm:ss")}.000Z`, UTCTime: Moment(value).format("HH:mm:ss") })
    };

    const getUnPublishDate = (value) => {
      if(value == "Never") {
        this.setState({
          unPublishedDateAndTime: '',
          UTCDateUnPublish: '',
          unPublish: 'Never',
          unPublishDate: 'Never'
        });
      } else {
      this.setState({ UnPublishTimestamp: value, validUnPublish: false })
      this.setState({ unPublishDate: `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`, UTCDateUnPublish: Moment(value).format('YYYY-MM-DD') })
      }
    };
    const getUnPublishTime = (value) => {
      this.setState({ unPublishTime: Moment(value).format("h:mm A"), UTCTimeUnPublish: Moment(value).format("HH:mm:ss") });
    };
    const onInputClick = () => {
      console.log("input clicked");
    };

    const onChipInputClick = () => {
      console.log("input chip clicked");
    };

    const deleteChip = () => {
      console.log("chip deleted");
    };
    const getSteps = () => {
      return [
        { number: 1, label: "Event Details" },
        { number: 2, label: "Publish" },
      ];
    }
    const style = {
      width: '100%',
    };
    return (
      <MasterLayoutMiddle
        header={
          <>
            {
              error && error === "Invalid token" && (
                <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                  <AlertAction
                    alert={token_expired}
                    alertType={"error"}
                    onClick={() => this.setState({ error: false })}
                  />
                </div>
              )
            }
          </>
        }
        isForm={true}
        formData={
          Loading ?
            <div
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </div>
            :
            <div>
              <BaseStepperExtended
                title="Edit Event"
                getSteps={getSteps}
                alternativeLabel={true}
                handleBack={() => this.handleBack()}
                handleNext={() => this.handleNext()}
                handleBackPreviousPage={() => this.props.history.push('/website/events')}
                nonLinear={true}
                orientation={"horizontal"}
                btnStyles={styles.stepper}
                onStepClick = {(obj) => this.setState({activeStep: obj - 1})}
                activeStep={activeStep}
              />
              {activeStep === 0 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Select Event</h2>
                    <Typography className="sub_decrition">Add “New Event” from Home screen &gt; Manage &gt; Event</Typography>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={event}
                        InputProps={false}
                        phone={false}
                        search={false}
                        style={styles.select}
                        color="primary"
                        disabled={true}
                       // helperText="Helper Text"
                        label="Select Event"
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <TextEditor
                      label={'Description'}
                      onChange={(e) => this.InputTextEditor(e)}
                      value={description}
                      disabled={true}
                     // helperText={'Helper Text'}
                      style={styles.textEditor}
                    />
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseImgChip
                        chipData={image.length > 0 ? image : []}
                        onInputClick={onInputClick}
                        onChipInputClick={onChipInputClick}
                        deleteChip={deleteChip}
                        label="Image"
                        textStyle={{ width: "100%" }}
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseImgChip
                        chipData={selectedEvents.length > 0 ? speakers : []}
                        onInputClick={onInputClick}
                        onChipInputClick={onChipInputClick}
                        deleteChip={deleteChip}
                        label="Guests"
                        textStyle={{ width: "100%" }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="dasheddBorder_form"></div>
                  </Grid>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Event Location</h2>
                  </Grid>
                  <Grid item md={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={Address && Address.location_name}
                        InputProps={true}
                        disabled={true}
                        label=""
                        placeholder="Location"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="dasheddBorder_form"></div>
                  </Grid>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Date and Time</h2>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={startAt}
                        label="Start Date and Time"
                        placeholder=""
                        disabled={true}
                        onClick={() =>
                          this.setState({ openDrawer: true, isStartDate: true })
                        }
                        InputProps={true}
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={endAt}
                        label="End Date and Time"
                        placeholder=""
                        disabled={true}
                        InputProps={true}
                        onClick={() =>
                          this.setState({ openDrawer: true, isEndDate: true })
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={repeat}
                        label="Repeat"
                        placeholder=""
                        disabled={true}
                        style={{ width: "100%" }}
                        onClick={() =>
                          this.setState({ openDrawer: true, isRepeatDays: true })
                        }
                        InputProps={true}
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="form_button">
                      <p className="cancel" onClick={() => { this.props.history.push("/website/events"); }}>CANCEL</p>
                      <BaseButton text="CONTINUE" onClick={() => this.handleNext()} />
                    </div>
                  </Grid>
                </Grid>
              )}
              {activeStep === 1 && (
                <Grid container spacing={3}>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <FormPublishUnpublish
                        visibility={visibility}
                        setVisibility={(e) => this.setState({ visibility: e })}
                        publish={publish}
                        unPublish={unPublish}
                        publishDate={publishDate}
                        publishTime={publishTime}
                        setPublishDate={(e) => this.setState({ setPublishDate: e })}
                        setPublishTime={(e) => this.setState({ setPublishTime: e })}
                        getPublishDate={getPublishDate}
                        getPublishTime={getPublishTime}
                        unPublishDate={unPublishDate}
                        unPublishTime={this.state.unPublishTime}
                        setUnPublishDate={(e) => this.setState({ setUnPublishDate: e })}
                        setUnPublishTime={(e) => this.setState({ setUnPublishTime: e })}
                        getUnPublishDate={getUnPublishDate}
                        getUnPublishTime={getUnPublishTime}
                        onVisibilitySave={onVisibilitySave}
                        onVisibilityCancel={onVisibilityCancel}
                        onPublishSave={onPublishSave}
                        onPublishCancel={onPublishCancel}
                        onUnPublishSave={onUnPublishSave}
                        onUnPublishCancel={onUnPublishCancel}
                        onSave={onSave}
                        onBack={onBack}
                        unPublishTimestamp={this.state.unPublishTimestamp}
                        showFeatured={false}
                        featuredText="Feature this on homepage"
                        checked={this.state.checked}
                        onSetChecked={() => this.setState({ SetChecked: !this.state.checked })}
                        minDateUnpublish={publishDate}
                        maxDatepublish={unPublishDate}
                        disabledSave={validUnPublish}
                        disabledSaveP={validPublish}
                        showFeatured={true}
                        featuredText="Feature this on homepage"
                        checked={featured}
                        onSetChecked={() => this.setState({ featured: !featured })}
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={true}
      />
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(0, mapDispatchToProps)(EditEvent);