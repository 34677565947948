import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import { SimpleTable,  MasterLayoutMiddle, FooterInner, CardDetailsTable, Initials, CardStatus  } from "@dclab/dc-react-ui-kit";
import './style.css';
import moment from "moment";
import { getPlanSubscriptionsByOrgId} from "../UserProfile/Apis";
import { getApps, getIntegrations } from '../ManageApps/api';


function OrderDetails(props) {
  //console.log(props?.location?.state?.order)
  const [order, setorder] = useState({})
  const [loading, setloading] = useState(false)
  const [error, seterror] = useState("")
  const [planSubscription, setplanSubscription] = useState([])
  const [integrations, setintegrations] = useState([])
  const [apps, setapps] = useState([])

  useEffect(() => {
    if(props?.location?.state?.order) {
      props?.location?.state?.order && setorder(props.location.state.order)
    }
}, [props?.location?.state?.order])

    useEffect(() => {
        const getDetails = async () => {
            setloading(true)
            const token = localStorage.getItem("authToken");
            let orgId = localStorage.getItem("orgID");
            try {
                const userRes = await getPlanSubscriptionsByOrgId(orgId, token);

                const appsRes = await getApps(token);
                setapps(appsRes?.data?.body?.data);
                console.log(apps);

                const integrationsRes = await getIntegrations(token);
                
                setintegrations(integrationsRes?.data?.body?.data)

                //console.log("userRes: ", userRes);
                if(userRes?._id) {
                  let planSubscription = userRes;
                  const arr = Object.keys(planSubscription)
                  .filter(v => {
                    return planSubscription[v] != null && typeof planSubscription[v] === 'object'
                  }).map(key => ({ ...planSubscription[key], planName: [key][0] }))
                  setplanSubscription(arr)
                  //console.log("arrs", arr)
                }
                setloading(false)
            } catch (error) {
                seterror(error?.response && error?.response?.data?.message)
                setloading(false)
            }
        }
        getDetails();
    }, [])

// ============================== Table Start ======================================//
let header = {
  tableName: `Order Details`,
};

const capitalizeFLetter = (val) => {  
  return val ? `${val[0].toUpperCase() + val.slice(1)}` : ""; 
}

let data = [
  {
    field: "Order #",
    value: `<a style={{ color: "#2A82F6", textDecoration: "none" }} href=${order?.receipt_url} target="_blank">
    ${order?.receipt_number}
  </a>`
},
  {
      field: "Payment",
      value: `Paid <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style='margin-left: 5px' viewBox="0 0 24 24"><defs><style>.a{fill:none;}.b{fill:#3cc480;}</style></defs><path class="a" d="M0,0H24V24H0Z"/><path class="b" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM10,17,5,12l1.41-1.41L10,14.17l7.59-7.59L19,8Z"/></svg>`
  },
  {
    field: "Email",
    value: order?.email ? order.email : '<span className="ondisableText">No email</span>'
},
  {
    field: "Date",
    value: order?.created ? moment(order.created * 1000).format('ll') : '<span className="ondisableText">No date</span>'
},

  {
      field: "Subtotal",
      value: order.amount ? "$" + (order.amount / 100)?.toFixed(2) : "$0"
  },
  {
      field: "Phone",
      value: order?.phone ? order.phone : '<span className="ondisableText">No phone</span>'
  },
  {
    field: "Organization",
    value : order?.orgName ? order.orgName : '<span className="ondisableText">No name</span>'
},
  {
      field: "Shipping Cost",
      value: "$0"
  },
  {
      field: "Shipping",
      value : order?.billing_details?.address ? order?.billing_details?.address?.line1 + "<br>" +  order?.billing_details?.address?.city + ", " + order?.billing_details?.address?.state + " " + order?.billing_details?.address?.postal_code : '<span className="ondisableText">No address</span>'
  },
  {
    field: "Address",
    value : order?.billing_details?.address ? order?.billing_details?.address?.state + " " + order?.billing_details?.address?.postal_code : '<span className="ondisableText">No address</span>'
  },
  {
      field: "Total",
      value : order.amount ? "$" + (order.amount / 100)?.toFixed(2) : "$0"
  },
  {field: "", value:  " "},  {field: "", value:  " "},
  {
      field: "Promo Applied",
      value : '<span className="ondisableText">None</span>'
  },
];


let footerLinkName = "View Invoice";
let footerDeleteButton = "";

// HANDLE FOOTER LINK
const onClick = () => {
console.log('edit')
order?.receipt_url && window.open(order?.receipt_url)
}

const onDeleteClick = () => {
console.log('delete')
}
// ============================== Table Start ======================================//
  

  
  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { type: "string", name: "name", label: "APPLICATIONS", width: "40%" }, 
    { type: "string", name: "name", label: "PLAN NAME", width: "18%" },
    { type: "string", name: "name", label: "UNIT PRICE", width: "18%" },
    { type: "string", name: "name", label: "QTY", width: "18%" },
    { type: "string", name: "name", label: "TOTAL", width: "18%" },
    { type: "string", name: "name", label: "", width: "30%" },

  ];

  
  let rows = [];
  planSubscription?.length && planSubscription.forEach((plan, i) => {
    
    if(plan.planName?.toLowerCase() === "extraintegrations") {
      plan.selectedIntegrations?.length && plan.selectedIntegrations.forEach(int => {

        let integration = integrations?.length ? integrations?.find((el) => el?.integrationApi?.toLowerCase() === int?.integrationApi?.toLowerCase()) : {};
       //console.log('Integration: ' + integration?.imageMetaData?.imageUrl)
        rows.push([
          { type: "string", name: "textImg", src: integration?.imageMetaData?.imageUrl ? integration.imageMetaData.imageUrl : "https://i.ibb.co/mhTNNDX/DEENCONNECT-X-Icon.png", primaryText: int?.name?.toUpperCase(), initials: ""},
          { type: "string", name: "name", value: "INTEGRATION" },
          { type: "string", name: "category", value: int.paymentFrequency === "monthly" ? `$${int.price}/mo` : int.price ? `$${(parseFloat(int.price) / 12).toFixed(2)}/mo` : "$0"  },
          { type: "string", name: "category", value: "1" },
          { type: "string", name: "category", value: int.price ? `$${int.price}` : "$0"  },
          { type: "string", name: "category", value: ""  },
        ])
      });
    } else {
      let app = apps?.length ? apps?.find((el) => el?.name?.toLowerCase() === plan?.planName?.toLowerCase()) : {};
      //console.log(app);
      rows.push([
        { type: "string", name: "textImg", src : app?.iconMetaData?.iconUrl ? app.iconMetaData.iconUrl : "https://i.ibb.co/mhTNNDX/DEENCONNECT-Integration-Icon.png", primaryText: plan?.planName?.toUpperCase(), initials: ""},
        { type: "string", name: "name", value: plan.plan ? plan.plan.toUpperCase() : "" },
        { type: "string", name: "category", value: plan.paymentFrequency === "monthly" ? `$${plan.total}/mo` : plan.total ? `$${(parseFloat(plan.total) / 12).toFixed(2)}/mo` : "$0" },
        { type: "string", name: "category", value: "1" },
        { type: "string", name: "category", value: plan.total ? `$${plan.total}` : "$0"  },
        { type: "string", name: "category", value: ""  },
      ])
    }
  });

  // ============================== Table End ======================================//
  
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      content={
        <div>
          
          
            <Grid container spacing={3} >

            <Grid item md={12} sm={12} xs={12} >
            <CardDetailsTable 
              data={data} 
              // data2={data2} 
              // twoColumns 
              header={header} 
              footerLinkName={footerLinkName} 
              footerDeleteButton={footerDeleteButton}
              onClick={() => onClick()}
              onDeleteClick={() => onDeleteClick()}

              Status={true}
              StatusLabel="Status"
              StatusText={order?.status}
              statuscolor="#7266FB"
              />
            </Grid>
              
            <Grid item md={12} sm={12} xs={12} >
              
                <div className="table_box">
                  <SimpleTable
                    showHeader={true}
                    title="Items"
                    width="100%"
                    rows={rows}
                    columns={col}
                    colSpan={16}
                    actionSendButton={true}
                    classProps={changeClassProps()}
                    donationsCount={rows && rows.length > 0 ? 1 : 0}
                    noTitle="No Orders added"
                    noPara="Added Orders will be displayed and managed here"
                    noBtnText=""
                    onInviteClick={() =>
                      this.props.history.push(
                        "/organization/services/add-new-service"
                      )
                    }
                    actionLink={(name) => this.details(name)}
                    showIconCol={false}
                    actionSendButton={true}
                    deleteAction={(e) => this.deleteServiceAction(e)}
                    showFooter={true}
                    showAdd={false}
                    addText="ADD NEW"
                    onAddClick={() =>
                      this.props.history.push(
                        "/Website/Add-Announcements"
                      )
                    }
                    iconTextSize={16}
                  /> 
                </div>
              </Grid>
            </Grid>
        
         
            
     
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
export default OrderDetails;