import React from "react";
import LockIcon from "@material-ui/icons/Lock";
import "./footer.css";
import "../../App.css";

function Footer(props) {
  return (
    <div
      className={`main-footer ${props.customClass}`}
      id="main-footer-border"
      style={props.style}
    >
      <p className="footer-secured">
        <LockIcon style={{ height: 13 }} />
        Secured by aws
      </p>
      <p className="footer-terms">
        <a href={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" } target="_blank">
          {" "}
          Terms of services
        </a>{" "}
        |{" "}
        <a href={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"} target="_blank">
          Privacy Policy
        </a>
      </p>
      <p className="footer-copyright">
        © 2020, DEENFUND, Inc. or its affiliates. All rights reserved.
      </p>
    </div>
  );
}

export default Footer;
