import React, { useEffect, useState } from 'react';
import {
    BaseCheckBox,
    MasterLayoutMiddle,
    FooterInner, TextEditor,BaseButton
} from '@dclab/dc-react-ui-kit';
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Grid from '@material-ui/core/Grid';

function CancelMembership(props) {

    const style = {
        width: '100%',
    };

    const [checked, setChecked] = useState(true);

    useEffect(() => {
        console.log(`Your ${checked} function here`);
    }, [checked]);

    const [value, setValue] = useState([{ type: 'paragraph', children: [{ text: "" }] }]);

    const onChange = (e) => {
        setValue(e)
    };

    return (

        <MasterLayoutMiddle
            header={
                <>
                    <div className='PageheaderCont'>
                        <div className='PageheaderInner'>
                            <div
                                onClick={() => props.history.push({ pathname: '/membership-details', state: '1' })}
                                className="backBtn"
                            >
                                <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                                <p style={{ alignSelf: "center" }}>Back</p>{" "}
                            </div>
                            <h1>Cancel Membership</h1>
                        </div>
                    </div>
                </>
            }
            content={

                <div className='CencelMemberContainer'>
                    <Grid container spacing={3}>

                        <Grid item xs={6}>
                            <div className='TitleA'>WHY DO YOU WANT TO CANCEL</div>
                            <div className='TitleASub'>Please select a reason</div>

                            <div className='CheckBoxContainer'>
                                <BaseCheckBox
                                    label="I'm looking to change my plan."
                                    checked={checked}
                                    color="secondary"
                                    showHelperText={true}
                                    onChange={(e) => setChecked(e.target.checked)}
                                />

                                <BaseCheckBox
                                    label="Technical issue"
                                    checked={checked}
                                    color="secondary"
                                    showHelperText={true}
                                    onChange={(e) => setChecked(e.target.checked)}
                                />

                                <BaseCheckBox
                                    label="Too expensive"
                                    checked={checked}
                                    color="secondary"
                                    showHelperText={true}
                                    onChange={(e) => setChecked(e.target.checked)}
                                />

                                <BaseCheckBox
                                    label="I'm not using the product(s) often enough."
                                    checked={checked}
                                    color="secondary"
                                    showHelperText={true}
                                    onChange={(e) => setChecked(e.target.checked)}
                                />

                                <BaseCheckBox
                                    label="Product(s) and features are too complicated for me."
                                    checked={checked}
                                    color="secondary"
                                    showHelperText={true}
                                    onChange={(e) => setChecked(e.target.checked)}
                                />

                                <BaseCheckBox
                                    label="I'm not using the product(s) often enough."
                                    checked={checked}
                                    color="secondary"
                                    showHelperText={true}
                                    onChange={(e) => setChecked(e.target.checked)}
                                />

                                <BaseCheckBox
                                    label="Other (Please specify)."
                                    checked={checked}
                                    color="secondary"
                                    showHelperText={true}
                                    onChange={(e) => setChecked(e.target.checked)}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className='TextEditorWithoutHeader'>
                                <TextEditor
                                    onChange={(e) => onChange(e)}
                                    disabled={false}
                                    value={value}
                                    label={"COMMENTS (OPTIONAL)"}
                                    maxCharCount={300}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="form_button">
                                <p
                                    className='cancel'
                                    onClick={() => this.onCancelOrgDetail()
                                    }
                                >
                                    CANCEL
                                </p>
                                <BaseButton text='CONFIRM' onClick={() => this.updateOrg()} />
                            </div>

                        </Grid>

                    </Grid>

                </div>
            }
            isFooter={true}
            footer={
                < FooterInner
                    style={style}
                    termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
                    privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                />
            }
            isWhiteBackground={true}
        />

    );
}


export default CancelMembership;