import React, { useState, useRef, useEffect } from "react";
import { PageHeaderWithTabs, AlertDialogueAdvance, AlertAction, MasterLayoutMiddle, FooterInner } from "@dclab/dc-react-ui-kit";
import { withRouter } from "react-router-dom";
import { getOrganizationData } from '../apis/index'
import FormDetails from "../../../components/DonationForm/FormDetails";
// import MetaDetails from "../../../components/DonationForm/MetaDetails";
import Donations from "../../../components/DonationForm/Donations";
import Setup from "../../../components/DonationForm/Setup";
import Categories from "../../../components/DonationForm/Categories";
import Faqs from "../../../components/DonationForm/Faq";
import Embed from '../../../components/DonationForm/Embed'
import "./donationForm.css";
import messages_content from '../../../utils/messages.json';
const { 
    token_expired
  } = messages_content;
function DonationForm(props) {
  const history = props.history;
  const [value, setValue] = useState(0);
  const [open, setAlertModal] = useState(false);
  const [oraganization_data, setOrganizationData] = useState(false);
  const [changes, setCheckChanges] = useState(false);
  const [error, seterror] = useState(false);
  const [getNewData, setNewData] = useState(false);
  const [dischard_changes, setdischard_changes] = useState(0);
  const [saved_route, setRoute] = useState('');
  const [publishchanges, setPublishChanges] = useState(false);
  const unblockHandle = useRef();
  const childRef = useRef();
  useEffect(() => {
    unblockHandle.current = history.block((route) => {
      setRoute(route.pathname)
      changes && visibleModal()
      return changes === true ? false : true
    });
    return () => {
      unblockHandle.current = false
      !window.location.pathname.includes('donation-form') && localStorage.removeItem('embed-form-page-no')
    }
  }, [changes])
  useEffect(() => {
    const page_no = localStorage.getItem('embed-form-page-no')
    setValue(page_no ? +page_no : 0);
    async function getorganization() {
      try {
        const token = localStorage.getItem('authToken')
        const orgId = localStorage.getItem('orgID')
        const getOraganization = await getOrganizationData(orgId, token)
        const oraganization_data = getOraganization?.data?.body?.data?.[0]
        const publishChanges = oraganization_data?.enabledIntegrations?.deenfund?.embedFormDraft?.[0]?.changes;
        setOrganizationData(oraganization_data)
        setNewData(false)
        setPublishChanges(publishChanges)
      }
      catch (error) {
        seterror(error.response && error.response.data.message)
      }
    }
    getorganization()
  }, [getNewData])
  const handleChange = (event, newValue) => {
    changes && setAlertModal(true)
    !changes && setValue(newValue);
    !changes && localStorage.setItem('embed-form-page-no', newValue)
    setdischard_changes(newValue)
  };
  const dataFromChild = (props) => {
    setNewData(props)
  }
  const checkChanges = (boolean) => {
    setCheckChanges(boolean)
  }
  const publicChanges = (boolean) => {
    setPublishChanges(boolean)
  }
  const hideAlert = () => {
    setAlertModal(false)
  }
  const saveChanges = () => {
    value === 0 && childRef.current.formDetailDraft() ||
      value === 1 && childRef.current.DonationsDraft() ||
      value === 2 && childRef.current.SetupDraft() ||
      value === 3 && childRef.current.CategoriesDraft() ||
      value === 4 && childRef.current.FaqsDraft() ||
      value === 5 && childRef.current.EmbedDraft() 
      // || value === 6 && childRef.current.metaDetailDraft()
    if (unblockHandle) {
      unblockHandle.current()
    }
    if (saved_route) {
      props.history.push(saved_route)
    }
  }
  function visibleModal() {
    setAlertModal(true)
  }
  const object = [
    {
      label: "Form Details",
      item: "Form Details",
    },
    {
      label: "Donations",
      item: "Donations",
    },
    {
      label: "Setup",
      item: "Setup",
    },
    {
      label: "Categories",
      item: "Categories",
    },
    {
      label: "FAQs",
      item: "FAQs",
    },
    {
      label: "EMBED",
      item: "EMBED",
    },
    // {
    //   label: "Meta Data",
    //   item: "Meta Data",
    // },
  ];
  const publish = async () => {
    value === 0 && childRef.current.formDetailDraft('publish') ||
      value === 1 && childRef.current.DonationsDraft('publish') ||
      value === 2 && childRef.current.SetupDraft('publish') ||
      value === 3 && childRef.current.CategoriesDraft('publish') ||
      value === 4 && childRef.current.FaqsDraft('publish') ||
      value === 5 && childRef.current.EmbedDraft('publish') 
      // || value === 6 && childRef.current.metaDetailDraft('publish')
  };
  const handleClose = () => {
    setAlertModal(false)
  };
  const discardChanges = () => {
    setCheckChanges(false)
    setAlertModal(false)
    setValue(dischard_changes)
    if (unblockHandle) {
      unblockHandle.current()
    }
    if (saved_route) {
      props.history.push(saved_route)
    }
  };
  const saveStyles = {
    backgroundColor: "#2A82F6",
  };
  const publishStyles = {
    backgroundColor: "#2A82F6",
    opacity: 0.6,
  };
  const Alertstyle = {
    width: 470,
    marginLeft: '35%'
  };
  const paperStyles = {};
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      header={
        <div>
          {
            error && error === "Token invalid or expired" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 70 }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          <PageHeaderWithTabs
            value={value}
            title="Donation Form"
            object={object}
            indicatorColor="primary"
            style={{ color: "#2A82F6", border: "1px solid black" }}
            handleChange={(x, y) => handleChange(x, y)}
            indicatorColor="#2A82F6"
            saveStyles={saveStyles}
            disabled={!publishchanges && !changes}
            publishStyles={publishStyles}
            paperStyles={paperStyles}
            saveChanges={() => saveChanges()}
            publish={() => publish()}
            showBackBtn={false}
          />
        </div>
      }
      content={
        <>
          {value === 0 && <FormDetails hideAlert={hideAlert} publishChanges={publicChanges} checkChanges={checkChanges} ref={childRef} embed_data={oraganization_data} check_bool={changes} getnewdata={(e) => dataFromChild(e)} />}
          {value === 1 && <Donations hideAlert={hideAlert} publishChanges={publicChanges} checkChanges={checkChanges} ref={childRef} embed_data={oraganization_data} check_bool={changes} getnewdata={(e) => dataFromChild(e)} />}
          {value === 2 && <Setup embed_data={oraganization_data} ref={childRef} hideAlert={hideAlert} publishChanges={publicChanges} checkChanges={checkChanges} check_bool={changes} getnewdata={(e) => dataFromChild(e)} />}
          {value === 3 && <Categories embed_data={oraganization_data} ref={childRef} props={props} hideAlert={hideAlert} publishChanges={publicChanges} checkChanges={checkChanges} check_bool={changes} getnewdata={(e) => dataFromChild(e)} />}
          {value === 4 && <Faqs history={props.history} embed_data={oraganization_data} ref={childRef} hideAlert={hideAlert} publishChanges={publicChanges} checkChanges={checkChanges} check_bool={changes} getnewdata={(e) => dataFromChild(e)} />}
          {value === 5 && <Embed ref={childRef} embed_data={oraganization_data} hideAlert={hideAlert} publishChanges={publicChanges} checkChanges={checkChanges} />}
          {/* {value === 6 && <MetaDetails ref={childRef} embed_data={oraganization_data} hideAlert={hideAlert} publishChanges={publicChanges} checkChanges={checkChanges} />} */}
          <div>
            <AlertDialogueAdvance
              title="You have unsaved changes!"
              description='There are unsaved changes. If you would like to save changes, press the "SAVE AS DRAFT" button.'
              cancelText="Discard Changes"
              confirmText="SAVE AS DRAFT"
              open={open}
              handleCancel={() => saveChanges()}
              handleClose={() => handleClose()}
              handleConfirm={() => discardChanges()}
              styles={Alertstyle}
            />
          </div>
        </>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  );
}
export default withRouter(DonationForm);
