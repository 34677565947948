import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "./redux/actions/authActionCreators";
import Load from "./components/StripeConnections/Loader/Loader";
import "./App.css";
import "./pages/DeenFund/style.css";
import "./pages/DeenKiosk/style.css";
import "./pages/Donations/deen-kioks-website/style.css";
import "./pages/Home/style.css";
import "./pages/Organization/style.css";
import "./pages/Signin/style.css";
import "./pages/Signup/style.css";
import Signin from './pages/Signin'
import Signup from './pages/Signup'
import ForgotPassword from "./pages/Signin/forgotPassword"
import ResetPassword from './pages/Signin/resetPassword'
import HomePage from "./pages/Donations/homeComponentPage"
import WebSiteHomePage from './pages/Website'
import MembershipPage from './pages/Membership'
import NikahPage from './pages/Nikah'
import Volunteer from './pages/Volunteer'
import Education from './pages/Education'
import Home from './pages/Home';
import GetStarted from './pages/Home/GetStarted';
import Locked from './pages/Home/Lock';
import Verification from './pages/Signup/Verification'
import VerificationSuccess from "./pages/Signup/VerificationSuccess";
import VerifyContact from "./pages/verify/index";
import OrganizationSetup from "./pages/Organization/setupForm"
import Connection from "./components/StripeConnections/connectionPage"
import Accept from "./components/StripeConnections/rediectedPage"
import DeenFund from './pages/DeenFund'
import DeenKiosk from "./pages/DeenKiosk"
import Organization from "./pages/Organization"
import Website from "./pages/Website"
import ConnectSuccess from "./pages/Organization/stripeConnectSuccess"
import AccordianComponent from "./pages/Organization/accordainTest"
import AddBillingInfo from "./pages/Organization/AddBillingInfo"
import LoadingPage from "./pages/LoadingPage/LoadingPage"
import SignUpLock from './pages/Signin/Lock.js';
import Unverified from './pages/Signin/Unverified';
import ChangePassword from "./pages/UserProfile/ChangePassword";
import PaymentSuccessful from "./pages/ManageApps/PaymentSuccessful";
import MoreAppCheckOut from "./pages/ManageApps";
import ManagePlans from "./pages/Organization/Plans/ManagePlans";
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import Communication from './pages/Communication'

import UnsubscribeEnterEmail from './pages/ManageSubscriptions/UnsubscribeEnterEmail'
import UnsubcribeConfirmatoin from './pages/ManageSubscriptions/UnsubcribeConfirmatoin'
import UnsubscribeSuccess from './pages/ManageSubscriptions/UnsubscribeSuccess'
import UnsubscribeFeedback from './pages/ManageSubscriptions/UnsubscribeFeedback'

import HomePageWithoutSideBar from './pages/Organization/indexWithSideBar'
import AdminProfilewithoutSidebar from './pages/UserProfile/'
import PrivateRoute from "./components/PrivateRoute";
const generateClassName = createGenerateClassName({
  productionPrefix: 'dcjss',
});

const App = ({ user, dispatchLogoutAction }) => {

  return (
    <React.Fragment>
      <StylesProvider generateClassName={generateClassName}>
        <Switch>
          <Route path="/" exact component={Signin} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route path="/invite" exact component={Home} />
          <Route path="/success" exact component={GetStarted} />
          <Route path="/locked" exact component={Locked} />
          <Route path="/sign-locked" exact component={SignUpLock} />
          <Route exact path="/signin" component={Signin} />
          <Route exact path="/signup" component={Signup} />
          <Route path="/verify" exact component={Verification} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route exact path="/not-verified" component={Unverified} />
          <Route
            path="/verify-success"
            exact
            component={VerificationSuccess}
          />
          <Route path="/communication/verify/contact" exact component={VerifyContact} />
          <Route path="/communication/verify/contact/:id" exact component={VerifyContact} />
          <PrivateRoute path="/onboarding" exact component={OrganizationSetup} />
          <Route path="/connect-stripe" exact component={ConnectSuccess} />
          <Route path="/test-accordian" exact component={AccordianComponent} />
          <Route exact path="/donations" component={HomePage} />
          <Route exact path="/dashboard">
            <HomePage
              isLoggedIn={user.isLoggedIn}
              fullName={user.fullName}
              onLogout={dispatchLogoutAction}
            />
          </Route>
          <Route exact path="/dashboard-stats">
            <HomePage
              isLoggedIn={user.isLoggedIn}
              fullName={user.fullName}
              onLogout={dispatchLogoutAction}
            />
          </Route>

          <Route exact path="/deenkiosk" component={HomePage} />
          <Route exact path="/test" component={HomePage} />
          <Route exact path="/salah" component={HomePage} />
          <Route exact path="/reminders" component={HomePage} />
          <Route exact path="/annoucements" component={HomePage} />
          <Route exact path="/addannoucement" component={HomePage} />
          <Route exact path="/editannoucement" component={HomePage} />
          <Route exact path="/addreminder" component={HomePage} />
          <Route exact path="/editreminder" component={HomePage} />
          <Route exact path="/events" component={HomePage} />
          <Route exact path="/addevent" component={HomePage} />
          <Route exact path="/editevent/:id" component={HomePage} />
          <Route exact path="/addsalah" component={HomePage} />
          <Route exact path="/editsalah" component={HomePage} />
          <Route exact path="/salahsettings" component={HomePage} />
          <Route exact path="/more-apps" component={HomePage} />
          <Route exact path="/app-details" component={HomePage} />
          <Route exact path="/cart-details" component={HomePage} />

          <Route exact path="/manage-subscriptions" component={HomePage} />
          <Route exact path="/subscriber-details/:id" component={HomePage} />



          <Route exact path="/checkout" component={MoreAppCheckOut} />
          <PrivateRoute exact path="/payment-successful" component={PaymentSuccessful} />

          <Route exact path="/deenfund/donations" component={DeenFund} />
          <Route exact path="/deenfund/donation-form/add-faq" component={DeenFund} />
          <Route exact path="/deenfund/donation-form/edit-faq/:id" component={DeenFund} />

          <Route
            exact
            path="/deenfund/donations/details"
            component={DeenFund}
          />
          <Route
            exact
            path="/deenfund/donations/thank-donors"
            component={DeenFund}
          />
          <Route exact path="/deenfund/thank-donors" component={DeenFund} />
          <Route exact path="/deenfund/categories" component={DeenFund} />
          <Route
            exact
            path="/deenfund/categories/add-new-category"
            component={DeenFund}
          />
          <Route
            exact
            path="/deenfund/categories/edit-category/:id"
            component={DeenFund}
          />
          <Route exact path="/deenfund/donation-form" component={DeenFund} />

          <Route exact path="/deenfund/manage-donors" component={DeenFund} />

          <Route exact path="/deenfund/donors-details" component={DeenFund} />
          <Route exact path="/deenfund/invite-donor" component={DeenFund} />
          <Route exact path="/deenfund/edit-invite-donor/:id" component={DeenFund} />
          <Route exact path="/deenfund/connect-stripe" component={DeenFund} />

          <Route exact path="/deenfund/thank-donors" component={DeenFund} />
          <Route exact path="/deenfund/edit-donation" component={DeenFund} />
          <Route exact path="/deenfund/edit-discontinue" component={DeenFund} />

          <Route
            exact
            path="/deenkiosk/devices/manage-devices"
            component={DeenKiosk}
          />
          <Route
            exact
            path="/deenkiosk/devices/addplaylist"
            component={DeenKiosk}
          />
          <Route
            exact
            path="/deenkiosk/devices/editplaylist/:id"
            component={DeenKiosk}
          />
          <Route
            exact
            path="/deenkiosk/playlists"
            component={DeenKiosk}
          />
          <Route
            exact
            path="/deenkiosk/newplaylists"
            component={DeenKiosk}
          />
          <Route exact path="/deenkiosk/devices" component={DeenKiosk} />
          <Route
            exact
            path="/deenkiosk/onboarding"
            component={DeenKiosk}
          />

          <Route exact path="/deenkiosk/add-schedule" component={DeenKiosk} />
          <Route exact path="/deenkiosk/edit-schedule" component={DeenKiosk} />

          <Route exact path="/deenkiosk/home-screen" component={DeenKiosk} />

          <Route exact path="/deenkiosk/edit-home-screen" component={DeenKiosk} />

          <Route
            exact
            path="/organization/overview"
            component={Organization}
          />
          <Route
            exact
            path="/organization/add-billing-details"
            component={Organization}
          />
          <Route
            exact
            path="/organization/edit-billing-details"
            component={Organization}
          />
          <Route
            exact
            path="/organization/manage-plans"
            component={Organization}
          />
          <Route
            exact
            path="/organization/plan-details"
            component={HomePage}
          />

          <Route
            exact
            path="/organization/overview/edit-details"
            component={Organization}
          />
          <Route
            exact
            path="/organization/overview/taxein"
            component={Organization}
          />
          <Route
            exact
            path="/organization/overview/tax-exemption"
            component={Organization}
          />
          <Route
            exact
            path="/organization/overview/add-team-member"
            component={Organization}
          />
          <Route
            exact
            path="/organization/overview/edit-team-member/:id"
            component={Organization}
          />
          <Route
            exact
            path="/organization/overview/team-member/details"
            component={Organization}
          />
          <Route
            exact
            path="/organization/guests"
            component={Organization}
          />
          <Route
            exact
            path="/organization/add-new-guests"
            component={Organization}
          />
          <Route
            exact
            path="/organization/edit-speaker/:id"
            component={Organization}
          />
          <Route
            exact
            path="/organization/services"
            component={Organization}
          />
          <Route
            exact
            path="/organization/services/add-new-service"
            component={Organization}
          />
          <Route
            exact
            path="/organization/services/edit-service/:id"
            component={Organization}
          />

          <Route
            exact
            path="/organization/payment-methods"
            component={Organization}
          />

          <Route exact path="/organization/contact-us" component={Organization} />
          <Route exact path="/organization/contact-us-details" component={Organization} />
          <Route exact path="/organization/contact-us-respond" component={Organization} />
          <Route exact path="/organization/payment-history" component={Organization} />
          <Route exact path="/organization/payment-receipt" component={Organization} />
          <Route exact path="/organization/connected-accounts" component={Organization} />
           
          <Route exact path="/organization/orders" component={Organization} />
          <Route exact path="/organization/orders-details/:orderId" component={Organization} />

          <Route exact path="/organization/committees" component={Organization} />
          <Route exact path="/organization/committees-details" component={Organization} />
          <Route exact path="/organization/add-committee" component={Organization} />
          <Route exact path="/organization/edit-committee" component={Organization} />
          <Route exact path="/organization/committees/member-details" component={Organization} />
          <Route exact path="/organization/committees/details-add-member" component={Organization} />
          <Route exact path="/organization/committees/members" component={Organization} />
          <Route exact path="/organization/committees/add-member" component={Organization} />
          <Route exact path="/organization/committees/edit-member" component={Organization} />
          <Route exact path="/organization/committees/designations" component={Organization} />
          <Route exact path="/organization/committees/add-new-designations" component={Organization} />



          <Route exact path="/organization/cancel-plan" component={HomePageWithoutSideBar} />
          <Route exact path="/access-revoked" component={HomePageWithoutSideBar} />


          <PrivateRoute path="/connect_to_stripe" component={Connection} />
          <PrivateRoute path="/stripe_redirect" component={Accept} />
          <PrivateRoute path="/load" component={Load} />
          <Route exact path="/website/announcements" component={Website} />
          <Route
            exact
            path="/website/add-announcements"
            component={WebSiteHomePage}
          />
          <Route
            exact
            path="/website/edit-announcements/:id"
            component={WebSiteHomePage}
          />
          <Route exact path="/website/manage-salah" component={Website} />
          <Route exact path="/website/add-salah" component={Website} />
          <Route exact path="/website/edit-salah/:id" component={Website} />
          <Route
            exact
            path="/website/reminders"
            component={WebSiteHomePage}
          />
          <Route
            exact
            path="/website/add-reminder"
            component={WebSiteHomePage}
          />
          <Route
            exact
            path="/website/edit-reminder/:id"
            component={WebSiteHomePage}
          />
          <Route exact path="/website/pages" component={Website} />
          <Route exact path="/Website/Manage-Page" component={Website} />
          <Route exact path="/Website/AdsPromotion" component={Website} />
          <Route exact path="/website/home-page" component={Website} />
          <Route exact path="/website/add-slider" component={Website} />
          <Route exact path="/website/edit-slider/:id" component={Website} />
          <Route exact path="/website/homepage/add-news-articles" component={Website} />
          <Route exact path="/website/homepage/add-new-event" component={Website} />
          <Route exact path="/website/homepage/add-new-video" component={Website} />
          <Route exact path="/website/homepage/add-new-service" component={Website} />
          <Route exact path="/website/news-articles" component={Website} />
          <Route
            exact
            path="/website/add-news-articles"
            component={Website}
          />
          <Route
            exact
            path="/website/edit-news-articles/:id"
            component={Website}
          />
          <Route exact path="/website/videos" component={Website} />
          <Route exact path="/website/add-video" component={Website} />
          <Route exact path="/website/edit-video/:id" component={Website} />
          <Route exact path="/website/events" component={Website} />
          <Route exact path="/website/add-events" component={Website} />
          <Route exact path="/website/edit-event/:id" component={Website} />
          <Route exact path="/website/services" component={Website} />
          <Route exact path="/website/add-services" component={Website} />
          <Route exact path="/website/edit-service/:id" component={Website} />
          <Route exact path="/website/about-us" component={Website} />

          <Route exact path="/website/promotional-slider" component={Website} />
          <Route exact path="/website/add-promotional-slider" component={Website} />
          <Route exact path="/website/edit-promotional-slider/:id" component={Website} />
          <Route exact path="/website/photo-gallery" component={Website} />
          <Route exact path="/website/add-photo-gallery" component={Website} />
          <Route exact path="/website/edit-photo-gallery/:id" component={Website} />






          <Route exact path="/website/faq" component={WebSiteHomePage} />
          <Route exact path="/website/add-faq" component={WebSiteHomePage} />
          <Route exact path="/website/edit-faq/:id" component={WebSiteHomePage} />
          <Route exact path="/website/privacy-policy" component={WebSiteHomePage} />
          <Route exact path="/website/tos" component={WebSiteHomePage} />
          <Route exact path="/Website/Subscription" component={Website} />
          <Route exact path="/Website/Site_Menus" component={Website} />
          <Route exact path="/website/navigation-menu" component={Website} />
          <Route exact path="/website/add-navigation-menu" component={Website} />
          <Route exact path="/website/edit-navigation-menu/:id" component={Website} />
          <Route exact path="/Edit-profile" component={HomePage} />
          <Route exact path="/admin-profile" component={HomePage} />
          <Route exact path="/user-profile-delete-account" component={AdminProfilewithoutSidebar} />

          <PrivateRoute exact path="/setup" component={LoadingPage} />
          <Route exact path="/messages" component={HomePage} />
          <Route exact path="/messages/contact-us" component={HomePage} />
          <Route
            exact
            path="/orders"
            component={HomePage}
          />
          <Route
            exact
            path="/order-details/:orderId"
            component={HomePage}
          />
          <Route
            exact
            path="/manage-order"
            component={HomePage}
          />


          <Route exact path="/build-project" component={HomePage} />
          <Route exact path="/add-testimonial" component={HomePage} />
          <Route exact path="/add-community-supporter" component={HomePage} />
          <Route exact path="/organizationd-details-form" component={HomePage} />
          <Route exact path="/project-phase" component={HomePage} />
          <Route exact path="/project-description" component={HomePage} />
          <Route exact path="/post-update" component={HomePage} />
          <Route exact path="/edit-update" component={HomePage} />
          <Route exact path="/add-guest" component={HomePage} />
          <Route exact path="/testimonial-listing" component={HomePage} />
          <Route exact path="/community-support-listing" component={HomePage} />
          <Route exact path="/project-highlights" component={HomePage} />
          <Route exact path="/project-highlights" component={HomePage} />
          <Route exact path="/guests-listing" component={HomePage} />

          <Route exact path="/order" component={HomePage} />
          <Route exact path="/order-details/:orderId" component={HomePage} />
          <Route exact path="/manage-order" component={HomePage} />

          <Route exact path="/media-library" component={HomePage} />

          <Route exact path="/build-projects" component={HomePage} />


          <Route exact path="/communication/contact-list" component={Communication} />
          <Route exact path="/communication/contact-list/:status" component={Communication} />
          <Route exact path="/communication/add-contact" component={Communication} />
          <Route exact path="/communication/add-contact/:id" component={Communication} />
          <Route exact path="/communication/edit-contact" component={Communication} />
          <Route exact path="/communication/contact-details/:id" component={Communication} />
          <Route exact path="/communication/groups-details/:id" component={Communication} />
          <Route exact path="/communication/contact-details/:id/:status" component={Communication} />
          <Route exact path="/communication/groups-details/:id/:status" component={Communication} />
          <Route exact path="/communication/messages" component={Communication} />
          <Route exact path="/communication/messages/:messageType" component={Communication} />
          <Route exact path="/communication/new-message" component={Communication} />
          <Route exact path="/communication/new-message/:id" component={Communication} />
          <Route exact path="/communication/templates" component={Communication} />
          <Route exact path="/communication/templates/:status" component={Communication} />
          <Route exact path="/communication/create-new-group" component={Communication} />
          <Route exact path="/communication/create-new-group/:id" component={Communication} />
          <Route exact path="/communication/add-template" component={Communication} />
          <Route exact path="/communication/add-template/:id" component={Communication} />
          <Route exact path="/communication/messages-details" component={Communication} />
          <Route exact path="/communication/messages-details/:id" component={Communication} />

          <Route exact path="/unsubscribe-enter-email/:id" component={UnsubscribeEnterEmail} />
          <Route exact path="/unsubscribe-confirmation" component={UnsubcribeConfirmatoin} />
          <Route exact path="/unsubscribe-success-confirmation" component={UnsubscribeSuccess} />
          <Route exact path="/unsubscribe-confirmation-feedback" component={UnsubscribeFeedback} />


          <Route exact path="/membership/members" component={MembershipPage} />
          <Route exact path="/membership-details" component={MembershipPage} />
          <Route exact path="/edit-detail" component={MembershipPage} />
          <Route exact path="/edit-emergency" component={MembershipPage} />
          <Route exact path="/add-donation" component={MembershipPage} />
          <Route exact path="/cancel-membership" component={MembershipPage} />


          <Route exact path="/salat-al-jumah" component={HomePage} />
          <Route exact path="/add-new-jumah" component={HomePage} />
          <Route exact path="/jumah-details" component={HomePage} />


          <Route exact path="/nikah/nikah-request" component={NikahPage} />
          <Route exact path="/nikah/nikah-details" component={NikahPage} />
          <Route exact path="/nikah/nikah-request-form" component={NikahPage} />


          <Route exact path="/volunteer/volunteer-request" component={Volunteer} />
          <Route exact path="/volunteer/volunteer-details" component={Volunteer} />
          <Route exact path="/volunteer/volunteer-edit-details" component={Volunteer} />


          <Route exact path="/education/courses-listing" component={Education} />
          <Route exact path="/education/add-courses" component={Education} />
          <Route exact path="/education/course-details" component={Education} />
          <Route exact path="/education/add-new-announcement" component={Education} />








        </Switch>
      </StylesProvider>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  dispatchLogoutAction: () => dispatch(logoutUser()),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
