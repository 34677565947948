import React, { useEffect } from "react";
import Moment from "moment";
import MomentTime from "moment-timezone";
import {
  BaseTextField,
  BaseButton,
  BaseStepper,
  FormPublishUnpublish,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import { useState } from "react";
import "./style.css";
import { AddVideoWebsite } from "./api";
import { connect } from "react-redux";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
import { editOrganization } from "../Organization/api";
import { getVideosWithAppId } from "../../pages/Website/api";
import MediaUpload from "../../components/MediaUpload/MediaUpload";

const { token_expired, publish_date_before_unpublish, success_message_add_video, maximum_limit_exeed_of_featured_video } = messages_content;

function AddVideo(props) {
  const [limitError, setLimitError] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [name, setName] = React.useState("");
  const [errorName, setErrorName] = React.useState(false);
  const [errorLink, setErrorLink] = React.useState(false);
  const [error, seterror] = useState(false);
  const [link, setLink] = React.useState("");
  const [visibility, setVisibility] = React.useState("Public");
  const [publish, setPublish] = React.useState("Immediately");
  const [unPublish, setUnPublish] = React.useState("Never");
  const [publishDate, setPublishDate] = React.useState("Immediately");
  const [publishTime, setPublishTime] = React.useState("");
  const [unPublishDate, setUnPublishDate] = React.useState("Never");
  const [unPublishTime, setUnPublishTime] = React.useState("");
  const [unPublishTimestamp, setUnPublishTimestamp] = React.useState(0);
  const [UTCDate, setUTCDate] = React.useState("");
  const [UTCTime, setUTCTime] = React.useState("");
  const [dateError, setDateError] = React.useState(false);
  const [UTCDateUnPublish, setUTCDateUnPublish] = React.useState("");
  const [UTCTimeUnPublish, setUTCTimeUnPublish] = React.useState("");
  const [checkedFeatured, setCheckedFeatured] = React.useState(false);
  const [unPublishedDateAndTime, setUnPublishedDateAndTime] = React.useState(
    ""
  );
  const [publishedDateAndTime, setPublishedDateAndTime] = React.useState("");
  const [validUnPublish, setValidUnPublish] = React.useState(true);
  const [validPublish, setValidPublish] = React.useState(true);
  const [featuredVideos, setFeaturedVideos] = React.useState([])

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    const getData = async () => {
      const resVideo = await getVideosWithAppId(token, orgId, 'deenweb')
      const data = resVideo?.data?.body?.data;
      const featuredVideos = data?.filter(text => text.featured === true)
      setFeaturedVideos(featuredVideos)
    }
    getData()
  }, [])

  // useEffect(() => {
  //   if (unPublishDate < Moment(new Date()).format("MMM D, YYYY")) {
  //     setVisibility("Hidden");
  //   }
  // }, [unPublishDate]);
  const getPublishDate = (value, boolean) => {
    if(value === "Immediately") {
      setUTCDate('');
      setPublishedDateAndTime('');
      setPublish("Immediately");
      setPublishDate("Immediately")
    } else {
      setValidPublish(false);
      setPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDate(Moment(value).format("YYYY-MM-DD"));
    }
  };
  const getPublishTime = (value, boolean) => {
    setPublishTime(Moment(value).format("h:mm A"));
    setUTCTime(Moment(value).format("HH:mm:ss"));
  };
  const getUnPublishDate = (value, boolean) => {
    if(value == "Never") {
      setUTCDateUnPublish('');
      setUnPublishedDateAndTime('')
      setUnPublish('Never');
      setUnPublishDate('Never');
    } else {
      setValidUnPublish(false);
      setUnPublishTimestamp(value);
      setUnPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDateUnPublish(Moment(value).format("YYYY-MM-DD"));
    }
  };
  const getUnPublishTime = (value, boolean) => {
    setUnPublishTime(Moment(value).format("h:mm A"));
    setUTCTimeUnPublish(Moment(value).format("HH:mm:ss"));
  };
  const onVisibilitySave = () => {
    console.log("visibility saved");
  };
  const onVisibilityCancel = () => {
    console.log("visibility cancelled");
  };
  const onPublishSave = () => {
    let Time = publishTime.split(" ");
    let dated = MomentTime(new Date(publishDate)).format("YYYY-MM-DD");
    let split = Time[0].split(":");
    let Hours = "";
    let H;
    if (Time[1] === "AM") {
      H = parseInt(split[0]);
      Hours = H + ":" + split[1];
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ":" + split[1];
    }
    let date = `${dated + "T" + Hours}:00.00Z`;
    setPublishedDateAndTime(date);
  };
  const onPublishCancel = () => {
    console.log("publish date cancel");
  };

  const onUnPublishSave = () => {
    let Time = unPublishTime.split(" ");
    let dated = MomentTime(new Date(unPublishDate)).format("YYYY-MM-DD");
    let split = Time[0].split(":");
    let Hours = "";
    let H;
    if (Time[1] === "AM") {
      H = parseInt(split[0]);
      Hours = H + ":" + split[1];
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ":" + split[1];
    }
    let date = `${dated + "T" + Hours}:00.00Z`;
    setUnPublishedDateAndTime(date);
  };
  const onUnPublishCancel = () => {
    console.log("unpublish date cancel");
  };
  const convertUTC = (date) => {
    return date.toISOString()
  }
  const onSave = async () => {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    const data = UTCDate === '' ? new Date() : new Date(`${UTCDate + ' ' + UTCTime}`)
    const dataU = convertUTC(data);
    const dataE = UTCDateUnPublish === '' ? new Date() : new Date(`${UTCDateUnPublish + ' ' + UTCTimeUnPublish}`)
    const UnpublishTime = convertUTC(dataE);
    const obj = {
      title: name,
      link: link,
      website: {
        status: visibility,
        featured: checkedFeatured,
        publishFrom:
          publishedDateAndTime === ""
            ? publish
            : dataU,
        publishTo:
          unPublishedDateAndTime === ""
            ? unPublish
            : UnpublishTime,
      },
      organizationId: orgId,
    };
    const previousUnpublish = obj.website.publishFrom !== 'Immediately' ? obj.website.publishTo > obj.website.publishFrom : true
/*     if ( obj.website.publishTo === 'Never' ||  previousUnpublish) {
 */      try {
        if (featuredVideos?.length >= 3 && checkedFeatured) {
          setLimitError(true)
          setTimeout(() => {
            setLimitError(false)
          }, 8000);
        } else {
          
          const result = await AddVideoWebsite(obj, token);
          const addedVideo = result.data.body.data._id 
          const updateOnOrg = {
            "addSelectedArrayData": {
              "enabledIntegrations.deenweb.videos.selectedVideos": [{
                video: addedVideo,
                publishFrom: publishedDateAndTime === '' ? publish : dataU,
                publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime, // DATE OBJECT
                status: visibility,
                featured: checkedFeatured
              }]
            },
          }
          await editOrganization(updateOnOrg, orgId, token);
          props.alertShow(success_message_add_video)
          props.history.push("/website/videos"); 
        }

      } catch (error) {
        console.log("Error is: ", error);
        seterror(error.response && error.response.data.message);
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
   /*  } else {
      setDateError(true)
      setTimeout(() => {
        setDateError(false)
      }, 8000)
    } */
  };
  const onBack = () => {
    setActiveStep(0);
  };
  const handleChange = (prop) => (event) => {
    if (prop === "name") {
      setName(event.target.value);
    } else if (prop === "link") {
      setLink(event.target.value);
    }
  };
  const handleNext = () => {
    let validUrl = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.?\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[?6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1?,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00?a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u?00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
    let check = validUrl.test(link);
    if (name === '' || link === '') {
      if (!check) {
        setErrorLink(true);
      }
      if (name === "") {
        setErrorName(true)
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    props.history.push("/website/videos");
  };
  const getSteps = () => {
    return [
      { number: 1, label: "Video Details" },
      { number: 2, label: "Publish" },
    ];
  };
  const styles = {
    stepper: {
      marginTop: 0,
      padding: "0px 0px",
      borderRadius: "30px",
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    select: {
      margin: "14px 0",
      minWidth: 120,
      height: 66,
      width: 120,
      border: "#D3D8DA",
      width: "100%",
    },
    BaseTextField: {
      width: "100%",
    },
    editor: {
      padding: 16,
    },
    formBody: {
      background: "#fff",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A",
      fontWeight: "300",
      marginBottom: "26px",
    },
    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
    },
    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
  };
  const style = {
    width: "100%",
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          {
            limitError && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={maximum_limit_exeed_of_featured_video}
                  alertType={"error"}
                  onClick={() => setLimitError(false)}
                />
              </div>
            )
          }
          {
            error && error === "Invalid token" && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            dateError &&
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
              <AlertAction
                alert={publish_date_before_unpublish}
                alertType={"error"}
                onClick={() => setDateError(false)}
              />
            </div>
          }
        </>
      }
      isForm={true}
      formData={
        <div>
          <BaseStepper
            title="Add New Video"
            getSteps={getSteps}
            alternativeLabel={true}
            handleBack={() => handleBack()}
            handleBackPreviousPage={() => props.history.push('/website/videos')}
            handleNext={() => handleNext()}
            nonLinear={true}
            orientation={"horizontal"}
            btnStyles={styles.stepper}
            activeStep={activeStep}
          />
          {activeStep === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h2 className="sub_title">Basic Information</h2>
              </Grid>
              <Grid item md={12} xl={12}>
                <div className="field_box">
                  <BaseTextField
                    value={name}
                    onChange={handleChange("name")}
                    count={100}
                    color="primary"
                    disabled={false}
                    error={errorName}
                    helperText={errorName ? "Video title is required" : ""}
                    label="Video Title"
                  />
                </div>
              </Grid>
              <Grid item md={12} xl={12}>
                <MediaUpload
                  addMediaItem = {(obj) => setLink(obj.src)}
                  label = "Video"
                  buttonName = "ADD VIDEO"
                  typeOfMedia='/videos'
                  showMediaTab={"video"}
                  selectedMedia={{ 
                    logoUrl: (link.length > 0 && link.indexOf("/embed") !== -1) ?
                    `https://img.youtube.com/vi/${link?.split("/embed/")?.[1]}/sddefault.jpg` : "", 
                    logoName: (link.length > 0 && link.indexOf("/embed") !== -1) ? 
                      link : ""
                  }}

                />
                {/* <div className="field_box">
                  <BaseTextField
                    value={link}
                    onChange={handleChange("link")}
                    color="primary"
                    onClick={() => console.log("onCLick")}
                    onBlur={() => console.log("onBlur")}
                    disabled={false}
                    error={errorLink}
                    helperText={errorLink ? "required" : ""}
                    label="Youtube Link"
                  />
                </div> */}
              </Grid>
              <Grid item md={12} xl={12}>
                <div className="form_button">
                  <span className="btnCencel">
                    <BaseButton
                      text="CANCEL"
                      onClick={() => props.history.push("/website/videos")}
                    />
                  </span>
                  <BaseButton text="CONTINUE" onClick={() => handleNext()} />
                </div>
              </Grid>
            </Grid>
          )}
          {activeStep === 1 && (
            <Grid container spacing={3}>
              <Grid item md={12} xl={12}>
                <div className="field_box">
                  <FormPublishUnpublish
                    visibility={visibility}
                    setVisibility={setVisibility}
                    publish={publish}
                    unPublish={unPublish}
                    publishDate={publishDate}
                    publishTime={publishTime}
                    setPublishDate={setPublishDate}
                    setPublishTime={setPublishTime}
                    getPublishDate={getPublishDate}
                    getPublishTime={getPublishTime}
                    unPublishDate={unPublishDate}
                    unPublishTime={unPublishTime}
                    setUnPublishDate={setUnPublishDate}
                    setUnPublishTime={setUnPublishTime}
                    getUnPublishDate={getUnPublishDate}
                    getUnPublishTime={getUnPublishTime}
                    onVisibilitySave={onVisibilitySave}
                    onVisibilityCancel={onVisibilityCancel}
                    onPublishSave={onPublishSave}
                    onPublishCancel={onPublishCancel}
                    onUnPublishSave={onUnPublishSave}
                    onUnPublishCancel={onUnPublishCancel}
                    onSave={onSave}
                    onBack={onBack}
                    unPublishTimestamp={unPublishTimestamp}
                    minDateUnpublish={publishDate}
                    maxDatepublish={unPublishDate}
                    disabledSave={validUnPublish}
                    disabledSaveP={validPublish}
                    showFeatured={true}
                    featuredText="Feature this on homepage"
                    checked={checkedFeatured}
                    onSetChecked={() => setCheckedFeatured(!checkedFeatured)}
                  />
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      }
      isFooter={true}
      footer={<FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />}
      isWhiteBackground={true}
    />
  );
}
const mapStateToProps = (state) => {
  return {
    alert: state.alert,
  };
};
const mapDispatchToProps = (dispatch) => ({
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddVideo);
