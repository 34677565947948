import React, { Component } from "react";
import {
  CardData,
  GridContainer,
  GridItem,
  AlertAction,
  SimpleTable,
  MasterLayoutMiddle,
  FooterInner
} from "@dclab/dc-react-ui-kit";
import KioskModal from "./KioskModal.js"
import CircularProgress from "@material-ui/core/CircularProgress";
import { getAllOrganizationDevices } from './Apis'
import "./style.css";
import messages_content from '../../utils/messages.json';

const { 
    token_expired
  } = messages_content;
  
class Devices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloading: false,
      error: false,
      showAlert: false,
      success: false,
      devices: [],
      openModal: false,
    };
    this.callback = this.callback.bind(this);
  }
  callback = () => {
    this.setState({ addDevice: true });
  };
  async componentDidMount() {
    this.setState({ isloading: true });
    try {
      const orgId = localStorage.getItem("orgID")
      const token = localStorage.getItem("authToken")
      const res = await getAllOrganizationDevices(orgId, token);
      this.setState({
        devices: res.data.body.data,
        isloading: false,
      });
    } catch (error) {
      this.setState({ error: error && error.response && error.response.data.message, isloading: false });
    }
  }
  connectNow = (event) => {
    localStorage.setItem('deviceId', event.value)
    this.props.history.push({pathname: '/deenkiosk/onboarding', state: { device: { } }})
  }
  manageDevice = (event) => {
    localStorage.setItem('deviceId', event[0].value)
    localStorage.setItem('deviceScheduleId', event[4].deviceId)
    this.props.history.push('/deenkiosk/devices/manage-devices')
  }
  render() {
    const { error, showAlert, success, isloading, devices } = this.state;
    const classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };

    const headerdata = [
      {
        title: "Active Kiosks",
        donation: "0",
        fromLastMonth: 0,
        increased: false,
      },
      {
        title: "Donation This Month",
        donation: "0",
        fromLastMonth: 0,
        increased: false,
      },
      {
        title: "Total Donations 2021",
        donation: "0",
        fromLastMonth: 0,
        increased: false,
      },
      {
        title: "Total Subscribers",
        donation: "0",
        fromLastMonth: 0,
        increased: false,
      },
    ];

    const checkStatus = (status) => {
      status = status ? status.toLowerCase() : "";
      if (status === 'live') {
        return "success"
      } else if (status === 'inactive' || status === 'locked'){
        return "failed"
      } else if (status === 'paused'){
        return "pause"
      } else if (status === 'active'){
        return "active"
      } else {
        return "discontinued"
      }
    }

    const col = [
      { type: "string", name: "serial_number", label: "DEVICE ID", width: "20%" },
      { type: "link", name: "salahName", label: "DEVICE NAME", width: "55%" },
      { type: "string", name: "status", label: "STATUS", width: "8%" },
      { type: "action", name: "action", label: "", width: "16%" },
      // { type: "moreBtn", name: "moreBtn", label: "", width: "15%" },
      { type: "", name: "", label: "", width: "20%" },
    ];
    const rows = devices.map((item, i) => {
      return [
        { name: "amount", value: item.deviceId, label: item.deviceId },
        { type: "string", name: "date", value: item.name ? item.name : "No Name" },
        { type: "status", status: checkStatus(item.status), value: item.status },
        item.status !== "Pending" ? { type: "managedBtn", status: "discontinued", value: "MANAGE DEVICE" } : { type: "connectNowBtn", status: "discontinued", value: "Connect Now" },
        // { type: 'string', name: 'date', value: '...' },
        { deviceId: item._id },
      ];
    });
    const style = {
      width: '100%',
    };
    return (
      <MasterLayoutMiddle
        content={
          <>
            {error && error === "Invalid token" && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => this.setState({ error: false })}
                />
              </div>
            )}
            {showAlert && error && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={error}
                  alertType={"error"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {showAlert && success && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={"Success"}
                  alertType={"success"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {isloading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                }}
              >
                <CircularProgress
                  color="primary"
                  style={{ width: 24, height: 24 }}
                />
              </div>
            ) : (
                <>
                  <GridContainer>
                    {/* <GridItem itemStyle={{ marginBottom: 0, }}>
                      <CardData data={headerdata} />
                    </GridItem> */}
                    <GridItem>
                      <SimpleTable
                        showHeader={true}
                        title="Devices"
                        rows={rows}
                        columns={col}
                        colSpan={16}
                        actionSendButton={true}
                        classProps={changeClassProps()}
                        donationsCount={rows && rows.length > 0 ? 1 : 0}
                        noTitle="No Devices added"
                        noPara="Added Devices will be displayed and managed here"
                        noBtnText="Add New Devices"
                        onInviteClick={() => this.setState({openModal: true})}
                        onAddClick={() => this.setState({openModal: true})}
                        addText="ORDER NEW DEVICE"
                        showIconCol={false}
                        showFooter={true}
                        showAdd={true}
                        iconTextSize={16}
                        manageDevice={(e) => this.manageDevice(e)}
                        connectNow={(e) => this.connectNow(e[0])}
                      />
                    </GridItem>

                    <KioskModal openModal={this.state.openModal} setModal={() => this.setState({openModal: false})} />
                  </GridContainer>
                </>
              )}
          </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
      />
    );
  }
}

export default Devices;
