import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { saveAsDraft, checkWebsiteName } from './Apis'
import {
  BaseSwitch,
  BaseTextField,
  DrawerMain, BaseFileSelect,
  AlertAction,
  TextEditor,
  MasterLayoutMiddle, FooterInner
} from "@dclab/dc-react-ui-kit";
import TextField from '@material-ui/core/TextField';
import ChevronRight from "@material-ui/icons/ChevronRight";
import { Avatar, Button, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import messages_content from '../../utils/messages.json';
import MediaUpload from '../MediaUpload/MediaUpload';
const {
  token_expired,
  Your_changes_has_been_saved_as_draft,
  Your_changes_has_been_published
} = messages_content;

let donationLinkEnv;
if (window.location.host.includes("amazon") || window.location.host.includes("localhost") || window.location.host.includes("shapla")
) {
  donationLinkEnv = "http://dev.give.deenfund.com/";
} else {
  donationLinkEnv = "https://give.deenfund.com/";
}

const FormDetails = forwardRef((props, ref) => {
  const formatFormLink = (str) => {
    if (str) {
      return str.trim().replace(/\s+/g, '-').toLowerCase();
    }
  }

  const messagesEndRef = useRef(null)
  const OrgData = props?.embed_data?.enabledIntegrations
  const embedform_draft = OrgData?.deenfund
  const org_logo = props?.embed_data?.orgLogo?.logoThumbnail
  // props?.embed_data?.logo
  const logoName = props?.embed_data?.orgLogo?.logoName
  // props?.embed_data?.logoName
  const formimage = embedform_draft?.embedFormDraft?.[0]?.formImage
  const description = embedform_draft?.embedFormDraft?.[0]?.setup?.rightSide
  const form_title = embedform_draft?.embedFormDraft?.[0]?.formTitle
  const form_link = embedform_draft?.embedFormDraft?.[0]?.formLink
  const website = embedform_draft?.embedFormDraft?.[0]?.formHeaderButton?.link
  const website_name = embedform_draft?.embedFormDraft?.[0]?.websiteName
  const short_name = props?.embed_data?.orgShortName ? formatFormLink(props.embed_data.orgShortName) : "";
  const [formLink, setFormLink] = useState(donationLinkEnv)
  const [openLogoDrawer, setOpenLogoDrawer] = useState(false)
  const [linkValue, setlinkValue] = useState('')
  const [url, setWebsiteUrl] = useState('');
  const [formImage, setFormImage] = useState({});
  const [orgLogo, setOrgLogo] = useState([]);
  const [logo, setLogo] = useState([]);
  const [showLogoDrag, setShowLogoDrag] = useState(true)
  const [showLogo, setShowLogo] = useState(true);
  const [logoView, setLogoView] = useState(true);
  const [homepageButton, setHomePageButton] = useState(false);
  const [title, setTitile] = useState('');
  const [textValue, setTextValue] = useState([{ type: "paragraph", children: [{ text: "" }] }]);
  const [textValueLength, setTextValuelength] = useState(0);
  const [error, seterror] = useState(false);
  const [logoError, setlogoError] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [success, setsuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [copyTooltip, setcopyTooltip] = React.useState(false);

 
  const headline = React.useRef();

  useEffect(() => {
    
    website_name ? setlinkValue(website_name) : setlinkValue(short_name)
    org_logo && setOrgLogo([{ src: org_logo, name: logoName }])
    setFormImage(formimage)
    org_logo && setLogoView(true)
    website && setWebsiteUrl(website)
    form_title && setTitile(form_title)
    description && setTextValue(JSON.parse(description))
    website && setHomePageButton(true)

    if(headline.current)
    {
      headline.current.focus();
      console.log('Ref CAlled')
    }
  }, [props?.embed_data, headline])

  useImperativeHandle(ref, () => ({
    async formDetailDraft(param) {
      let webRegex = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.?\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[?6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1?,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00?a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u?00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
      if ((homepageButton && !url) || !title) {
        seterror(true)
        scrollToBottom()
      }
      else if (homepageButton && !webRegex.test(url)) {
        seterror('Invalid website url')
        scrollToBottom()
      }
      else {
        const orgId = localStorage.getItem('orgID')
        const token = localStorage.getItem('authToken')
        const formdata = {
          // orgLogo: {
          //   logoThumbnail: orgLogo?.[0]?.src,
          //   logoName: orgLogo?.[0]?.name,
          //   logoSize: orgLogo?.[0]?.size,
          //   logoUrl: orgLogo?.[0]?.src
          // },
          enabledIntegrations: formData(param),
        }
        param === 'publish' ? publish(orgId, formdata, token) : draftChanges(orgId, formdata, token)
      }
    }
  }));
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    
  }

  function formData(param) {
    if (param === 'publish') {
      return [
        {
          donationFormType: embedform_draft?.embedFormDraft?.[0]?.donationFormType,
          preselectAmounts: embedform_draft?.embedFormDraft?.[0]?.preselectAmounts,
          donationMinimumAmount: embedform_draft?.embedFormDraft?.[0]?.donationMinimumAmount,
          donationMinimumAmountEnabled: embedform_draft?.embedFormDraft?.[0]?.donationMinimumAmountEnabled,
          websiteName: error === 'Website Name Already Exist' ? short_name : linkValue,
          processingFeeEnabled: embedform_draft?.embedFormDraft?.[0]?.processingFeeEnabled,
          paymentMethod: embedform_draft?.embedFormDraft?.[0]?.paymentMethod,//release 2 will have two donations values
          formTitle: title,
          formImage: formImage,
          formThemeColor: embedform_draft?.embedFormDraft?.[0]?.formThemeColor,
          formLink: error === 'Website Name Already Exist' ? formLink + formatFormLink(short_name) : formLink + formatFormLink(linkValue),
          formHeaderButton: homepageButton ? {
            enabled: true,
            link: url
          } : {
              enabled: false,
              link: ""
            },
          setup: {
            footer: embedform_draft?.embedFormDraft?.[0]?.setup?.footer,
            rightSide: JSON.stringify(textValue),
            imageOrVideo: embedform_draft?.embedFormDraft?.[0]?.setup?.imageOrVideo,
            content: embedform_draft?.embedFormDraft?.[0]?.setup?.content,
            faqs: embedform_draft?.embedFormDraft?.[0]?.setup?.faqs
          },
          embedButton: embedform_draft?.embedFormDraft?.[0]?.embedButton,
          changes: false
        }
      ]
    } else {
      return [
        {
          donationFormType: embedform_draft?.embedFormDraft?.[0]?.donationFormType,
          preselectAmounts: embedform_draft?.embedFormDraft?.[0]?.preselectAmounts,
          donationMinimumAmount: embedform_draft?.embedFormDraft?.[0]?.donationMinimumAmount,
          donationMinimumAmountEnabled: embedform_draft?.embedFormDraft?.[0]?.donationMinimumAmountEnabled,
          websiteName: error === 'Website Name Already Exist' ? short_name : linkValue,
          processingFeeEnabled: embedform_draft?.embedFormDraft?.[0]?.processingFeeEnabled,
          paymentMethod: embedform_draft?.embedFormDraft?.[0]?.paymentMethod,//release 2 will have two donations values
          formImage: formImage,
          formTitle: title,
          formThemeColor: embedform_draft?.embedFormDraft?.[0]?.formThemeColor,
          formLink: error === 'Website Name Already Exist' ? formLink + formatFormLink(short_name) : formLink + formatFormLink(linkValue),
          formHeaderButton: homepageButton ? {
            enabled: true,
            link: url
          } : {
              enabled: false,
              link: ""
            },
          setup: {
            footer: embedform_draft?.embedFormDraft?.[0]?.setup?.footer,
            rightSide: JSON.stringify(textValue),
            imageOrVideo: embedform_draft?.embedFormDraft?.[0]?.setup?.imageOrVideo,
            content: embedform_draft?.embedFormDraft?.[0]?.setup?.content,
            faqs: embedform_draft?.embedFormDraft?.[0]?.setup?.faqs
          },
          embedButton: embedform_draft?.embedFormDraft?.[0].embedButton,
          changes: true
        }
      ]
    }
  }
  async function publish(orgId, formdata, token) {
    let formData2 = {
      'enabledIntegrations.deenfund.embedForm': formdata.enabledIntegrations,
      'enabledIntegrations.deenfund.embedFormDraft': formdata.enabledIntegrations
      //orgLogo: formdata.orgLogo
    }

    props.publishChanges(false)
    try {
      await saveAsDraft(orgId, formData2, token)
      setshowAlert(true)
      setsuccess('Publish')
      props.hideAlert()
      props.checkChanges(false)
      props.getnewdata(true)
      setTimeout(() => {
        setshowAlert(false)
        setsuccess(false)
      }, 8000)
    }
    catch (error) {
      seterror(error.response && error.response.data.message)
      props.hideAlert()
      setTimeout(() => {
        seterror(false)
      }, 8000)
    }
  }
  async function draftChanges(orgId, formdata, token) {
    let formData2 = {
      'enabledIntegrations.deenfund.embedForm': formdata.enabledIntegrations,
      'enabledIntegrations.deenfund.embedFormDraft': formdata.enabledIntegrations
      //orgLogo: formdata.orgLogo

    }
    try {
      await saveAsDraft(orgId, formData2, token)
      setshowAlert(true)
      setsuccess(true)
      props.hideAlert()
      props.checkChanges(false)
      setTimeout(() => {
        setshowAlert(false)
        setsuccess(false)
      }, 8000)
    }
    catch (error) {
      seterror(error.response && error.response.data.message)
      props.hideAlert()
      setTimeout(() => {
        seterror(false)
      }, 8000)
    }
  }
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  async function addLogo(imageData) {
    console.log("logo[0].size", imageData)
    /* if(logo.length && logo[0]?.size > 3145728) {
      setlogoError(true)
    }
    else  */if (imageData?.src.length > 0) {
      setFormImage(
        {
          src: imageData.src,
          title: imageData.title,
        })
      // setOpenLogoDrawer(false);
      // setShowLogo(false);
      // setLogoView(true)
      // setlogoError(false)
      props.checkChanges(true)
    } else {
      //setOpenLogoDrawer(false);
      setShowLogo(false)
    }
  };
  async function logoUpload(files) {
    if (files.length > 0) {
      setLogo(files);
    }
  };
  function handleLogoRemove() {
    setLogo([]);
    setShowLogoDrag(true);
    setOrgLogo([])
    setLogoView(false);
  };
  async function copyToClipboard(e) {
    let Text = document.getElementById('text')
    await navigator.clipboard.writeText(Text.textContent);
    setcopyTooltip(true)
    // console.log(" navigator.clipboard", await navigator.clipboard.readText())

    setTimeout(async () => {
      // await navigator.clipboard.writeText("");
      // console.log(" navigator.clipboard", await navigator.clipboard.readText())
      setcopyTooltip(false)
    }, 5000);
  };
  function onChangeHandler(e) {
      setTitile(e.target.value)
      props.checkChanges(true)
  }
  function onDescriptionStateChange(editorState) {
    let sum = 0;
    editorState.map((st) => {
      if (st.type === "bulleted-list" || st.type === "numbered-list") {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    setTextValue(editorState)
    setTextValuelength(sum)
    if (textValueLength > 0 && (sum > textValueLength || sum < textValueLength)) {
      props.checkChanges(true)
      console.log("textValueLength", textValueLength, sum)
    }
  };

  async function checkName(e) {
    if (!e.target.value) {
      setlinkValue(short_name)
    } else {
      let value = e.target.value.replace(/\s+/g, '-').toLowerCase();
      setlinkValue(value);
      checkWebSiteName(value)
    }
  }
  async function checkWebSiteName(name) {
    if (!linkValue) {
      setlinkValue(short_name)
    }
    else {
      setLoading(true)
      const token = localStorage.getItem('authToken')
      // const name = linkValue
      try {
        await checkWebsiteName(name, token)
        setLoading(false)
        seterror(false)
        props.checkChanges(true)
        props.setNewData(true)
      }
      catch (error) {
        setLoading(false)
        seterror(error.response && error.response.data.message)
      }
    }
  }
  const textEditorStyle = {
    border: "1px solid black",
    color: "gray",
  };


  const style = {
    width: '100%',
  };
  return (

    <>

      {
        logoError && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={"Logo size should be less than 3MB"}
              alertType={"error"}
              onClick={() => setlogoError(false)}
            />
          </div>
        )
      }
      {
        error && error === "Invalid token" && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={token_expired}
              alertType={"error"}
              onClick={() => seterror(false)}
            />
          </div>
        )
      }
      {
        showAlert && error && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={
                error === "Invalid token"
                  ? token_expired
                  : error
              }
              alertType={"error"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        )
      }
      {
        showAlert && success && success === 'Publish' ? (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={Your_changes_has_been_published}
              alertType={"success"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        ) : showAlert && success && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={Your_changes_has_been_saved_as_draft}
              alertType={"success"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        )
      }
      {console.log("formImage: ", formImage)}
      <div className="main-form-container">
        {!props?.embed_data && <div className='skeletons'>
          <SkeletonTheme color="#f7f8fa">
            <Skeleton height={162} width={570} />
            <Skeleton height={90} width={570} />
            <Skeleton height={90} width={570} />
            <Skeleton height={90} width={570} />
            <Skeleton height={90} width={570} />
          </SkeletonTheme>
        </div>}
        {props?.embed_data && <section>
          {/* {
            logoView && orgLogo.length ?
              orgLogo.map((file, index) => {
                return (
                  <div className="emb_card" key={index}>
                    <div className="card_title">
                      <h1 className="df-form-title">Header Logo</h1>
                    </div>
                    <div className="df-form-btn-img" style={{ background: 'transparent' }} >
                      <img src={file.src} style={{ height: "10px !important" }} />
                    </div>
                    <div className='df-home-logo'>
                      <div className="file_name">
                        {file.name}
                      </div>
                      <span
                        style={{ padding: "0px" }}
                        onClick={() => handleLogoRemove(index)}
                      >
                        <DeleteForeverIcon fontSize="inherit" style={{ fontSize: 22, color: '#818E94' }} />
                      </span>
                    </div>
                  </div>
                )
              })
              :
              <div className="emb_card">
                <div className="card_title">
                  <h1 className="df-form-title">Header Logo</h1>
                </div>
                <div className="df-form-btn" onClick={() => setOpenLogoDrawer(!openLogoDrawer)}>
                  <p className="df-form-btn-text">Upload NEW</p>
                  <ChevronRight style={{ color: "#818E94", position: "relative", left: "26px", top: "1px", }} />
                </div>
              </div>
          } */}
          <div className="emb_card">
              <MediaUpload
                addMediaItem = {(obj) => addLogo(obj)}
                label = "Add Logo"
                buttonName = "ADD LOGO"
                typeOfMedia='/images'
                selectedMedia={{ logoUrl: formImage?.src ?? orgLogo?.[0]?.src, logoName: formImage?.title ?? orgLogo?.[0]?.name}}

              />
          </div>
          <div className="emb_card">
            <div className="card_title">
              <h1 className="df-form-title">Donation form link</h1>
              <p className="df-form-para">Share this link with your awesome donors</p>
            </div>
            {!website_name && <div className="df-form-link-box">
              <p className="df-form-link-text" id='text'>
                {formLink}
              </p>
              <div className='df-form-link-field'>
                <TextField
                  error={false}
                  id="outlined-error"
                  label=""
                  value={linkValue}
                  onChange={(e) => checkName(e)}
                  variant="outlined"
                  error={error && error === 'Website Name Already Exist'}
                />
              </div>
              {loading && <div className='center'><CircularProgress disableShrink size={12} /> </div>}
              {!loading && error !== 'Website Name Already Exist' && <p className='df-form-link-icon'>
                <CheckIcon style={{ color: '#3CC480', fontSize: 16 }} />
                <span>Avaialble</span></p>}
              {!loading && error === 'Website Name Already Exist' && <p className='df-form-link-icon'>
                <ClearIcon style={{ color: '#B00020', fontSize: 16 }} />
                <span>Unavaialble</span></p>}
            </div>}
            {website_name && <div className="df-form-link-box">
              <p className="df-form-link-text" id='text'>
                {form_link}
              </p>
              <Tooltip title="Copied!" arrow placement="top"
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => setcopyTooltip(false)}
                open={copyTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <p className="df-link-copy" onClick={() => copyToClipboard()} >COPY</p>
              </Tooltip>
            </div>}
          </div>
          <div className="emb_card">
            <div className="df-homepage-btn">
              <div className="card_title no_space">
                <h1 className="df-form-title">HOMEPAGE BUTTON</h1>
                {/* <p className="df-form-para">Add a sub-title</p> */}
              </div>
              <div className="df-switch">
                <BaseSwitch
                  checked={homepageButton}
                  onChange={() => {
                    setHomePageButton(!homepageButton);
                    props.checkChanges(true);
                    seterror(false)
                    website && setWebsiteUrl(website)
                  }}
                />
              </div>
            </div>
            {homepageButton && <div className="df-form-link-text-field">
              <div className="df-text-field">
                <BaseTextField
                  style={{ width: "100%" }}
                  label="Website URL"
                  value={url}
                  onChange={(e) => {
                    setWebsiteUrl(e.target.value); props.checkChanges(true); seterror(false)
                  }}
                  error={(error && !url) || (error === 'Invalid website url')}
                  helperText={(error && !url && 'Url is required') || (error === 'Invalid website url' && 'Invalid website url')}
                />
              </div>
            </div>}
          </div>
          <div className="emb_card" ref={messagesEndRef} >
            <div className="card_title no_space">
              <h1 className="df-form-title">Form Headline</h1>
            </div>
            <div className="df-form-link-text-field" >
              <div className="df-text-field" >
                <BaseTextField
                  
                  style={{ width: "100%" }}
                  label="Enter Headline"
                  onChange={(e) => onChangeHandler(e)}
                  value={title}
                  count={50}
                  error={error && error !== 'Website Name Already Exist' && !title}
                  helperText={error && error !== 'Website Name Already Exist' && !title && 'Title is required'}
                />
              </div>
            </div>
          </div>
          <div className="emb_card">
            <div className="card_title">
              <h1 className="df-form-title">Donation Appeal</h1>
            </div>
            <div>
              <TextEditor
                onChange={(e) => onDescriptionStateChange(e)}
                disabled={false}
                value={textValue}
                style={textEditorStyle}
                label={"Description"}
                maxCharCount={300}
                helperText={
                  error && !textValueLength && error !== 'Website Name Already Exist' &&
                  "Description is required"
                }
                error={error && !textValueLength && error !== 'Website Name Already Exist'}
              />
            </div>
          </div>
          <DrawerMain
            open={openLogoDrawer}
            openHandler={() => setOpenLogoDrawer(false)
            }
            title="Select Image"
            onCancel={() => setOpenLogoDrawer(false)
            }
            onSave={() => addLogo()}
          >
            <List className="img-details" style={{ paddingLeft: 0 }}>
              {logo.map((file, index) => (
                <ListItem key={file.name} style={{ paddingLeft: 0 }}>
                  <ListItemAvatar>
                    <Avatar
                      variant="square"
                      alt={"preview"}
                      src={
                        logo.length > 0
                          ? logo[0].preview || logo[0].src
                          : file.preview
                      }
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      (file.name && file.name.slice(0, 20)) ||
                      file.src.slice(0, 20)
                    }
                    secondary={file.size && formatBytes(file.size)}
                  />
                  <ListItemSecondaryAction style={{ paddingLeft: 20 }}>
                    <IconButton
                      aria-label="delete"
                      size="medium"
                      style={{ padding: "0px" }}
                      onClick={() => handleLogoRemove(index)}
                    >
                      <DeleteForeverIcon fontSize="inherit" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
            {logo.length === 0 && (
              <>
                <div style={{ marginBottom: 20 }} className="img-uploader">
                  <BaseFileSelect
                    selectedFiles={(file) => logoUpload(file)}
                  />
                </div>
              </>
            )}
            {logo.length > 0 && logo[0].src.slice(0, 5) !== "https" && (
              <img src={logo.length > 0 && logo[0].src} width='100%' />
            )}
          </DrawerMain>
        </section>}
      </div>
    </>
  );
})

export default FormDetails;
