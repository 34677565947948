import React  from "react";
import Moment from 'react-moment';
import axios from "axios";

const orgEnv = {
    prod: process.env.REACT_APP_BASEURL_PROD_API,
    dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
    window.location.host.includes("amazon") ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("shapla")
) {
    currentEnv = "dev";
} else {
    currentEnv = "prod";
}

const orgBaseUrl = orgEnv[currentEnv];

export async function saveTemplate(inputValues){
    
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');

    
    const postData = {
        templateName: inputValues.templateName,
        templateBody: inputValues.templateBody,
        templateType: inputValues.templateType, 
        emailSubject: inputValues.emailSubject,
        emailHeader: inputValues.emailHeader,       
        orgId: orgId

    };
    return new Promise((resolve, reject) => {
        axios
            .post(`${orgBaseUrl}/communications/templates`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    token
                }
            })
            .then((res) => {
                resolve(res);
                // props.history.push('/communication/templates/'  );

            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function updateTemplate(inputValues , id ){
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');

    
    const postData = {
        templateName: inputValues.templateName,
        templateBody: inputValues.templateBody,
        templateType: inputValues.templateType,        
        orgId: orgId

    };
    return new Promise((resolve, reject) => {
        axios
            .post(`${orgBaseUrl}/communications/templates/${id}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    token
                }
            })
            .then((res) => {
                resolve(res);
                
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function updateGroup(values , itemId){

    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');

    const postData = {
        groupName: values.groupName ,
        numberOfContacts: values.numberOfContacts , 
        groupContacts: values.groupContacts , 
        orgId: orgId           

    };

    return new Promise((resolve, reject) => {
        axios
            .post(`${orgBaseUrl}/communications/groups/${itemId}`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    token
                }
            })
            .then((res) => {
                resolve(res);               

            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function saveMessage(inputValues){
    
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');

    
    const postData = {
        message: inputValues.message,
        fromName: inputValues.fromName,
        type: inputValues.type,
        emailSubject: inputValues.emailSubject,
        emailHeader: inputValues.emailHeader,
        templateName: inputValues.templateName,                
        isSchedules: inputValues.isSchedules,
        contacts: inputValues.contacts, 
        date: inputValues.date,
        orgId: orgId,
    };
    return new Promise((resolve, reject) => {
        axios
            .post(`${orgBaseUrl}/communications/messages`, postData, {
                headers: {
                    "Content-Type": "application/json",
                    token
                }
            })
            .then((res) => {
                resolve(res);                

            })
            .catch((error) => {
                reject(error);
            });
    });
}

export async function getCommunicationsData( type  , orgId, token){
    return new Promise((resolve, reject) => {
        axios
          .get(`${orgBaseUrl}/communications/findAll/${type}/${orgId}`,{
            headers:{
              token
            }
          })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
}

export async function getContactGroups( contactID ){
    const token = localStorage.getItem('authToken');

    return new Promise((resolve, reject) => {
        axios
          .get(`${orgBaseUrl}/communications/getContactGroups/${contactID}`,{
            headers:{
              token
            }
          })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
    });
}

export async function deleteTemplate( type , templateId  ){    

    const token = localStorage.getItem('authToken');
    
    return new Promise((resolve, reject) => {
        axios
        .delete(`${orgBaseUrl}/communications/${type}/${templateId}`, {
            headers: {
            token
            }
        })
        .then((res) => {
            resolve(res);
        })
        .catch((error) => {
            reject(error);
        });
    });
    
}

export async function getGroupContacts( groupContacts ){
    const token = localStorage.getItem('authToken');

    return new Promise((resolve, reject) => {
        axios
          .get(`${orgBaseUrl}/communications/getGroupContacts/${groupContacts}`, {
            headers: {
              "Content-Type": "application/json",
              token
            }
          })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
}

export async function deleteGroupContact( groupId, contactIds ){

    const token = localStorage.getItem('authToken');

    const postData = {
        contactIds: contactIds
    };
    
    return new Promise((resolve, reject) => {
        axios
          .post(`${orgBaseUrl}/communications/deleteGroupContact/${groupId}`, postData , {
            headers: {
              "Content-Type": "application/json",
              token
            }
          })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
}

export async function updateGroupCount( numberOfContacts , groupId){

    const token = localStorage.getItem('authToken');

    const postData = {
        numberOfContacts: numberOfContacts
    };
    
    return new Promise((resolve, reject) => {
        axios
          .post(`${orgBaseUrl}/communications/updateGroupCount/${groupId}`, postData , {
            headers: {
              "Content-Type": "application/json",
              token
            }
          })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
}

export async function getItemDetails(itemId , type='contacts' ){
    const token = localStorage.getItem('authToken');

    return new Promise((resolve, reject) => {
        axios
          .get(`${orgBaseUrl}/communications/singleItem/${type}/${itemId}`, {
            headers: {
              "Content-Type": "application/json",
              token
            }
          })
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
}


