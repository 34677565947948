import * as constants from "../constants";

function addInquiry(
  name,members
    
) {
  return {
    type: constants.ADD_INQUIRY,

    payload: {
      name,
     members
    },
  };
}



export { addInquiry };
