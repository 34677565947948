import React from 'react';
import { Accordian } from "@dclab/dc-react-ui-kit";

export default function AccordainTest() {

    const saveChanges = (accordianNum, accordianData) => {
        console.log("data", accordianNum, accordianData)
      }
      
      const projectDescriptionLinkClick = () => {
        console.log("projectDescriptionLinkClick")
      }
      
      const projectHighlightsLinkClick = () => {
        console.log("projectHighlightsLinkClick")
      }
      
      const projectPhaseLinkClick = () => {
        console.log("projectPhaseLinkClick")
      }
      
      const projectPageLinkChange = (value) => {
        console.log("projectPageLinkChange", value)
      }
      
      //ACCORDIAN 1
      let brandingAccordianData = {
        orgName: "Brooklyn Islamic Center", logo: "", themeColor: ""
      };
      
      //ACCORDIAN 2
      let projectLinkAccordianData = "https://build.deenconnect.io/bicny";
      
      //ACCORDIAN 3
      let pageTitleAccordianData = "bicny form";
      
      //ACCORDIAN 4
      let mainBannerAccordianData = "";
      
      //ACCORDIAN 5
      let projectDescriptionAccordianData = "";
      
      //ACCORDIAN 6
      let projectHighlightsAccordianData = "";
      
      //ACCORDIAN 7
      let donationLinkAccordianData = "https://give.deenfund.com/bicny";
      
      //ACCORDIAN 8
      let donationAppealAccordianData = "Build a house of Allah, and Allah will build one for you in Jannah";
      
      //ACCORDIAN 9
      let donationApproachAccordianData = { 
        donationApproach: "brick sponsor",
        donationApproachData: [
          {amount: 10, description: "test description"}
        ]
      }; 
      
      //ACCORDIAN 10
      let projectPhaseAccordianData = "public"; 
      
      //ACCORDIAN 11
      let sectionVisibilityAccordianData = "hidden";  // hidden or public
      


    return (
        <div style={{margin: "100px"}}>
              <Accordian 
    saveChanges={saveChanges}
    projectDescriptionLink={projectDescriptionLinkClick}
    projectHighlightsLink={projectHighlightsLinkClick}
    projectPhaseLink={projectPhaseLinkClick}
    projectPageLinkChange={projectPageLinkChange}
    projectPageLinkAvailable={true}
    brandingAccordianData={brandingAccordianData}
    projectLinkAccordianData={projectLinkAccordianData}
    pageTitleAccordianData={pageTitleAccordianData}
    projectDescriptionAccordianData={projectDescriptionAccordianData}
    donationLinkAccordianData={donationLinkAccordianData}
    donationAppealAccordianData={donationAppealAccordianData}
    projectHighlightsAccordianData={projectHighlightsAccordianData}
    donationApproachAccordianData={donationApproachAccordianData}
    sectionVisibilityAccordianData={sectionVisibilityAccordianData}
    projectPhaseAccordianData={projectPhaseAccordianData}
    mainBannerAccordianData={mainBannerAccordianData}
  />
        </div>
    )
}
