import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { ListDnD, AlertDialogue } from "@dclab/dc-react-ui-kit";
import MediaLibraryModal from '../../MediaLibrary/MediaLibraryModal';


export default function MiddleCard(props) {
    const {card, setcard, classes} = props;
    const [open, setOpen] = useState(false);
    const [deleteIndex, setdeleteIndex] = useState("")
    const [bannerData, setbannerData] = useState([])

    useEffect(() => {
      card?.slider?.length && setbannerData(card.slider)
    }, [card])

    const onDurationChange = (data, index, duration) => {
      console.log(data, index, duration)
      let sliderArr = [...bannerData];
      sliderArr[index] = { ...data, duration: duration };
      setbannerData(sliderArr)
      setcard({...card, slider: sliderArr})
    }

    const onItemDelete = (index) => {
      // console.log(data, index);
      let arr = [...bannerData];
      arr.splice(index, 1)
      // console.log(index, arr, bannerData)
      setbannerData(arr)
      setcard({...card, slider: arr})
    }

    const style={
      width:470,
      marginLeft:800
    };

    return (
        <Grid container spacing={3}>
        <Grid item xs={12}>
          <p className={classes.text}>Add integration or media to publish in this zone. Added content will be visible and managed from here.</p>
        </Grid>

        <Grid item xs={12} onClick={() => console.log("sss")}>
          <ListDnD 
            BannerData={bannerData}
            buttonIncrement={1.0}
            onDurationChange={onDurationChange}
            deleteItem={(item, index) => {
              setOpen(true)
              setdeleteIndex(index)
            }}
          />
        </Grid>

        <Grid item xs={12}>
           <MediaLibraryModal typeOfMedia='/images' label="Upload Image" buttonType="addMedia" onChange={(obj) => {
             setbannerData([...bannerData, { url: obj.src, name: obj.title, contentType: "image", duration: 10 }])
             setcard({...card, cardType: "image", slider: [...bannerData, { url: obj.src, name: obj.title, contentType: "image", duration: 10 }]})
           }}  />
        </Grid>

        
        <AlertDialogue
          title="Are you sure?"
          description="Are you sure you want to delete this media?"
          cancelText="Cancel"
          confirmText="Confirm"  
          open={open}
          handleCancel={() => setOpen(false)}
          handleClose={() => setOpen(false)}
          handleConfirm={() => {
            // console.log(deleteIndex)
              onItemDelete(deleteIndex)
              setOpen(false)
          }}
          styles={style}
        />

      </Grid>
  
    )
}
