import React, { Fragment, useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  SimpleAccordian, MasterLayoutMiddle, FooterInner, TextInputFixedLabel, FormSwitch
} from "@dclab/dc-react-ui-kit";

import SideDrawer from './SideDrawer';
import PageHeader from './PageHeader';
import ButtonCard from './ButtonCard';
import MiddleCard from './MiddleCard';
import BannerCard from './BannerCard';
import MediaLibraryModal from '../../MediaLibrary/MediaLibraryModal';
import MediaUpload from "../../../components/MediaUpload/MediaUpload";
import { getIntegrations } from "../../ManageApps/api/index.js";
import '././style.css'

function EditHomeScreen(props) {
  const [editHomeScreen, seteditHomeScreen] = useState({})
  const [selectedZone, setselectedZone] = useState(0)
  const [changes, setchanges] = useState(false)

  const [headerCard, setheaderCard] = useState({
    orgName: "",
    americanDate: false,
    islamicDate: false,
    taxExemptStatus: false,
    orgIcon: { iconUrl: "", iconName: "", iconSize: 0 }
  })

  const [leftButtonCard, setleftButtonCard] = useState(false)
  const [middleButtonCard, setmiddleButtonCard] = useState(false)
  const [rightButtonCard, setrightButtonCard] = useState(false)
  const [leftCard, setleftCard] = useState(false)
  const [middleCard, setmiddleCard] = useState(false)
  const [rightCard, setrightCard] = useState(false)
  const [bannerCard, setbannerCard] = useState(false)
  const [integrationsData, setintegrationsData] = useState([])

  useEffect(() => {
    if (props.location?.state?.homeScreen) {
      console.log("props are: ", props)
      seteditHomeScreen(props.location.state.homeScreen)
    }
    const getData = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const integrationsRes = await getIntegrations(token, "DEENKIOSK");
        integrationsRes?.data?.body?.data?.length && setintegrationsData(integrationsRes.data.body.data)
      } catch (error) {
        console.log(error)
      }
    }
    getData()

  }, [])


  useEffect(() => {
    if (editHomeScreen?.name) {
      console.log("editHomeScreen is: ", editHomeScreen);
      // console.log("changed here", editHomeScreen)
      // setchanges(true)
      let editHeader = editHomeScreen?.layout?.headerCard ? editHomeScreen?.layout?.headerCard : {};

      let org = props.location?.state?.org ?? {};
      console.log("Organization data: ", org);
      setheaderCard({
        orgName: editHeader.orgName ?? org.orgName,
        americanDate: editHeader.americanDate ?? false,
        islamicDate: editHeader.islamicDate ?? false,
        taxExemptStatus: editHeader.taxExemptStatus ?? false,
        orgIcon: editHeader.orgIcon?.iconUrl ? editHeader.orgIcon : org.orgIcon
      })
      setleftButtonCard(editHomeScreen?.layout?.leftButtonCard ?? {})
      setmiddleButtonCard(editHomeScreen?.layout?.middleButtonCard ?? {})
      setrightButtonCard(editHomeScreen?.layout?.rightButtonCard ?? {})
      setleftCard(editHomeScreen?.layout?.leftCard ?? {})
      setrightCard(editHomeScreen?.layout?.rightCard ?? {})
      setmiddleCard(editHomeScreen?.layout?.middleCard ?? {})
      setbannerCard(editHomeScreen?.layout?.bannerCard ?? {})
    }

  }, [editHomeScreen])

  const style = {
    width: '100%',
  };

  const AccordionDetails = {
    top: "-46px"
  };

  const onLayoutZoneClick = (zone) => {
    console.log("onLayoutZoneClick", zone);
    if (zone === selectedZone) {
      setselectedZone(0)
    } else {
      setselectedZone(zone)
    }
  };

  const selectMediaItem = (object) => {
    setheaderCard({
      ...headerCard, orgIcon: {
        iconUrl: object.src, iconName: object.title, iconSize: 0
      }
    })
    console.log(object);
  }

  const classes = useStyles();

  let layout = editHomeScreen?.layout ?? {}

  const headerCardSave = (acc) => {
    // console.log(acc, headerCard)
    seteditHomeScreen({ ...editHomeScreen, layout: { ...layout, headerCard: headerCard } })
    setchanges(true)
    setselectedZone(0)
    console.log("header icon: ", headerCard)
  }

  const leftButtonCardSave = (acc) => {
    seteditHomeScreen({ ...editHomeScreen, layout: { ...layout, leftButtonCard: leftButtonCard } })
    setchanges(true)
    setselectedZone(0)
  }

  const middleButtonCardSave = (acc) => {
    seteditHomeScreen({ ...editHomeScreen, layout: { ...layout, middleButtonCard: middleButtonCard } })
    setchanges(true)
    setselectedZone(0)
  }

  const rightButtonCardSave = (acc) => {
    seteditHomeScreen({ ...editHomeScreen, layout: { ...layout, rightButtonCard: rightButtonCard } })
    setchanges(true)
    setselectedZone(0)
  }

  const cardSave = (acc) => {
    let cardName = acc === "leftCard" ? leftCard : acc === "middleCard" ? middleCard : acc === "rightCard" ? rightCard : "";
    if (cardName) {
      seteditHomeScreen({ ...editHomeScreen, layout: { ...layout, [acc]: cardName } })
      setchanges(true)
      setselectedZone(0)
    }
  }


  const bannerCardSave = (acc) => {
    seteditHomeScreen({ ...editHomeScreen, layout: { ...layout, bannerCard: bannerCard } })
    setchanges(true)
    setselectedZone(0)
  }


  const templateName = editHomeScreen?.template?.name?.toLowerCase() ?? "template one";

  let rightCardZoneNum, bannerCardZoneBtn, leftBtnCardZoneNum, middleBtnCardZoneNum, rightBtnCardZoneNum;
  if (templateName === "template one") {
    rightCardZoneNum = 4;
    leftBtnCardZoneNum = 5;
    middleBtnCardZoneNum = 6;
    rightBtnCardZoneNum = 7;

  } else if (templateName === "template two") {
    leftBtnCardZoneNum = 4;
    middleBtnCardZoneNum = 5;
    rightBtnCardZoneNum = 6;
    bannerCardZoneBtn = 3;

  } else if (templateName === "template three") {
    rightCardZoneNum = 3;
    leftBtnCardZoneNum = 4;
    middleBtnCardZoneNum = 5;
    rightBtnCardZoneNum = 6;
    bannerCardZoneBtn = 2;

  } else if (templateName === "template four") {
    leftBtnCardZoneNum = 3;
    middleBtnCardZoneNum = 4;
    rightBtnCardZoneNum = 5;
    bannerCardZoneBtn = 2;

  } else if (templateName === "template five") {
    bannerCardZoneBtn = 2;
  }

  return (
    <div>

      <PageHeader
        editHomeScreen={editHomeScreen}
        seteditHomeScreen={seteditHomeScreen}
        changes={changes}
        setchanges={setchanges}
      />

      <MasterLayoutMiddle
        content={
          <div>


            <div className="ZoneContainer">

              <SimpleAccordian
                Title="zone 1 / header"
                AccordionDetails={AccordionDetails}
                panel={1}
                open={selectedZone === 1 ? true : false}
                onAccordianClick={onLayoutZoneClick}
                accordianName="headerCard"
                saveChanges={headerCardSave}
                content={

                  <Grid container spacing={3}>

                    <Grid item md={4} sm={4} xs={4}> </Grid>

                    <Grid item md={8} sm={8} xs={8}>

                      <Grid container spacing={3}>

                        <Grid item xs={12}>
                          <TextInputFixedLabel
                            label="Org Name"
                            labelStatus={true}
                            helpertext="Helper text"
                            HelpertextStatus={false}
                            placeholder="Placeholder"
                            Errormessage="Error message"
                            errortextStatus={false}
                            count={100}
                            countStatus={false}
                            tooltipStatus={false}
                            onChange={(e) => setheaderCard({ ...headerCard, orgName: e.target.value })}
                            value={headerCard?.orgName ?? ""}
                          />
                        </Grid>
                        {console.log("Header Card: ", headerCard.orgIcon?.iconUrl)}

                        <Grid item xs={12}>
                          {headerCard.orgIcon?.iconUrl &&
                            <MediaUpload
                              addMediaItem={selectMediaItem}
                              label=" Logo Icon"
                              buttonName="ADD LOGO ICON"
                              typeOfMedia='/images'
                              selectedMedia={{ logoUrl: headerCard.orgIcon?.iconUrl ? headerCard.orgIcon?.iconUrl : "", logoName: headerCard.orgIcon?.iconName ?? "" }}
                            />}

                          {!headerCard.orgIcon?.iconUrl &&
                            <MediaUpload
                              addMediaItem={selectMediaItem}
                              label=" Logo Icon"
                              buttonName="ADD LOGO ICON"
                              typeOfMedia='/images'
                            />

                          }
                          {/*                             <MediaLibraryModal typeOfMedia='/images' label="Org Icon" onChange={selectMediaItem}  />
 */}                            {/* <MediaUploader
                              label="Org Icon"
                              buttonText={"UPLOAD ORG ICON"}
                              getImageData={(data) => setheaderCard({...headerCard, orgIcon: {
                                        iconUrl: data.image, iconName: data.name, iconSize: data.size
                              }})}
                              imageRef={"orgIcon"}
                              onImageDeleteClick={() => console.log("onImageDeleteClick")}
                            /> */}
                        </Grid>

                        <Grid item xs={12}>

                          <div className={classes.BorderLine}>
                          <FormSwitch
                            headline='Show organization tax exemption status'
                            values={headerCard?.taxExemptStatus ?? false}
                            handleSwitchChange={(e) => setheaderCard({ ...headerCard, taxExemptStatus: e })}
                          />
                          </div>
                            
                          <div className={classes.BorderLine}>
                          <FormSwitch
                            headline='Show American English date'
                            values={headerCard?.americanDate ?? false}
                            handleSwitchChange={(e) => setheaderCard({ ...headerCard, americanDate: e })}
                          />
                          </div>

                          <FormSwitch
                            headline='Show Islamic date'
                            values={headerCard?.islamicDate ?? false}
                            handleSwitchChange={(e) => setheaderCard({ ...headerCard, islamicDate: e })}
                          />
                          
                        </Grid>

                      

                      </Grid>

                    </Grid>

                  </Grid>


                }
              />

              {(templateName === "template one" || templateName === "template two") && <SimpleAccordian
                Title="Zone 2 / Left card"
                AccordionDetails={AccordionDetails}
                panel={2}
                open={selectedZone === 2 ? true : false}
                onAccordianClick={onLayoutZoneClick}
                accordianName="leftCard"
                saveChanges={cardSave}
                content={
                  <Grid container spacing={3}>
                    <Grid item md={4} sm={4} xs={4}> </Grid>
                    <Grid item md={8} sm={8} xs={8}>
                      <MiddleCard
                        card={leftCard}
                        setcard={setleftCard}
                        classes={classes}
                        integrationsData={integrationsData}
                      />
                    </Grid>
                  </Grid>
                }
              />}


              {templateName === "template one" && <SimpleAccordian
                Title="Zone 3 / Middle card"
                AccordionDetails={AccordionDetails}
                panel={3}
                open={selectedZone === 3 ? true : false}
                onAccordianClick={onLayoutZoneClick}
                accordianName="middleCard"
                saveChanges={cardSave}
                content={
                  <Grid container spacing={3}>
                    <Grid item md={4} sm={4} xs={4}> </Grid>
                    <Grid item md={8} sm={8} xs={8}>
                      <MiddleCard
                        card={middleCard}
                        setcard={setmiddleCard}
                        classes={classes}
                        integrationsData={integrationsData}
                      />
                    </Grid>
                  </Grid>
                }
              />}

              {(templateName !== "template one") && <SimpleAccordian
                Title={`Zone ${bannerCardZoneBtn} / Banner Card`}
                AccordionDetails={AccordionDetails}
                panel={bannerCardZoneBtn}
                open={selectedZone === bannerCardZoneBtn ? true : false}
                onAccordianClick={onLayoutZoneClick}
                accordianName="bannerCard"
                saveChanges={bannerCardSave}
                content={
                  <Grid container spacing={3}>
                    <Grid item md={4} sm={4} xs={4}> </Grid>
                    <Grid item md={8} sm={8} xs={8}>

                      <BannerCard
                        card={bannerCard}
                        setcard={setbannerCard}
                        classes={classes}
                        integrationsData={integrationsData}
                      />

                    </Grid>
                  </Grid>
                }
              />}

              {(templateName === "template one" || templateName === "template three") && <SimpleAccordian
                Title={`Zone ${rightCardZoneNum} / Right card`}
                AccordionDetails={AccordionDetails}
                panel={rightCardZoneNum}
                open={selectedZone === rightCardZoneNum ? true : false}
                onAccordianClick={onLayoutZoneClick}
                accordianName="rightCard"
                saveChanges={cardSave}
                content={
                  <Grid container spacing={3}>
                    <Grid item md={4} sm={4} xs={4}> </Grid>
                    <Grid item md={8} sm={8} xs={8}>
                      <MiddleCard
                        card={rightCard}
                        setcard={setrightCard}
                        classes={classes}
                        integrationsData={integrationsData}
                      />
                    </Grid>
                  </Grid>
                }
              />}

              {(templateName !== "template five") && <Fragment>
                <SimpleAccordian
                  Title={`Zone ${leftBtnCardZoneNum} / Button 1`}
                  AccordionDetails={AccordionDetails}
                  panel={leftBtnCardZoneNum}
                  open={selectedZone === leftBtnCardZoneNum ? true : false}
                  onAccordianClick={onLayoutZoneClick}
                  accordianName="leftButtonCard"
                  saveChanges={leftButtonCardSave}
                  content={
                    <Grid container spacing={3}>

                      <Grid item md={4} sm={4} xs={4}> </Grid>

                      <Grid item md={8} sm={8} xs={8}>

                        {leftButtonCard && <ButtonCard
                          buttonCard={leftButtonCard}
                          setbuttonCard={setleftButtonCard}
                          classes={classes}
                        />}

                      </Grid>
                    </Grid>

                  }
                />

                <SimpleAccordian
                  Title={`Zone ${middleBtnCardZoneNum} / Button 2`}
                  AccordionDetails={AccordionDetails}
                  panel={middleBtnCardZoneNum}
                  open={selectedZone === middleBtnCardZoneNum ? true : false}
                  onAccordianClick={onLayoutZoneClick}
                  accordianName="middleButtonCard"
                  saveChanges={middleButtonCardSave}
                  content={

                    <Grid container spacing={3}>

                      <Grid item md={4} sm={4} xs={4}> </Grid>

                      <Grid item md={8} sm={8} xs={8}>

                        {middleButtonCard && <ButtonCard
                          buttonCard={middleButtonCard}
                          setbuttonCard={setmiddleButtonCard}
                          classes={classes}
                        />}
                      </Grid>

                    </Grid>


                  }
                />

                <SimpleAccordian
                  Title={`Zone ${rightBtnCardZoneNum} / Button 3`}
                  AccordionDetails={AccordionDetails}
                  panel={rightBtnCardZoneNum}
                  open={selectedZone === rightBtnCardZoneNum ? true : false}
                  onAccordianClick={onLayoutZoneClick}
                  accordianName="rightButtonCard"
                  saveChanges={rightButtonCardSave}
                  content={
                    <Grid container spacing={3}>
                      <Grid item md={4} sm={4} xs={4}> </Grid>
                      <Grid item md={8} sm={8} xs={8}>

                        {rightButtonCard && <ButtonCard
                          buttonCard={rightButtonCard}
                          setbuttonCard={setrightButtonCard}
                          classes={classes}
                        />}

                      </Grid>
                    </Grid>
                  }
                />
              </Fragment>}


            </div>
          </div>
        }

        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={false}
      />

      <div className={classes.SideDrawer} >
        <SideDrawer
          editHomeScreen={editHomeScreen}
          seteditHomeScreen={seteditHomeScreen}
          onLayoutZoneClick={onLayoutZoneClick}
          setchanges={setchanges}
          selectedZone={selectedZone}
        />
      </div>
    </div>
  );
}
export default EditHomeScreen;


const useStyles = makeStyles((theme) => ({

  DashLine: {
    border: " 1px dashed #D3D8DA",
  },

  text: {
    letterSpacing: " 0.25px",
    color: '#818E94',
    fontSize: "14px",
    lineHeight: "19px",
  },

  BorderLine: {
    borderBottom: " 1px solid #E4E8EC",
  },



  Preview: {
    width: "100%",
    height: "56px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    letterSpacing: " 1.26px",
    fontWeight: "500",
  },

  PreviewLebel: {
    color: "#818E94",
    fontSize: "12px",
    marginBottom: "6px",
  }



}), { index: 1 });