import React from "react";
import "./style.css";
import Tos from "./Tos";
function Content(props) {
  return (
    <div className="">
      <Tos loader={props.loader} message={props.message} />
    </div>
  );
}
export default Content;