import React, { Component } from "react";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { makeStyles } from '@material-ui/core/styles';
import ScreenIcon from '../../../assets/img/slide-show.svg'
import TextField from '@material-ui/core/TextField';


export default function CreateNewHomeScreenModal(props) {
  const {nameModalOpen, setnameModalOpen, addHomeScreen, setaddHomeScreen, settemplateModalOpen} = props;
  const classes = useStyles();

  return (

    <div>

      {/* <button className="AddVideoModalButton" type="button" onClick={() => setnameModalOpen(true)}><AddRoundedIcon /> ADD New</button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={nameModalOpen}
        onClose={() => setnameModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={nameModalOpen}>
          <div className={classes.MediaModalContainer}>

            <div className={classes.Header}>
              <h1 className={classes.Title}>Create new home screen</h1>
              <div className="ModalCloseIcon"><CloseRoundedIcon onClick={() => setnameModalOpen(false)} /></div>
            </div>


              <div className={classes.BodyFormContaier}>
                <img className={classes.ModalIcon} src={ScreenIcon} />
                <div className={classes.FieldDescription}>Create home screen to add image, video and integrations to play on your kiosk. Give your new home screen a name.</div>

                <div className={classes.FormContaier}>
                  <TextField 
                    id="standard-basic" 
                    placeholder="New Home Screen"
                    value={addHomeScreen?.name ?? ""}
                    // error={addHomeScreen?.name?.length ? false : true}
                    onChange={(e) => {
                      console.log(e.target.value)
                      if(e.target.value?.length <= 20) {
                        setaddHomeScreen({ ...addHomeScreen, name: e.target.value })
                      }
                    }}
                  />
                  <div className={classes.CharCount}>CHAR {addHomeScreen?.name?.length ?? 0}/20</div>
                </div>
              </div>

            
            <div className={classes.ModalFooter}>
              <button className="ButtonCancel" type="button" onClick={() => setnameModalOpen(false)}> Cancel</button>
              <button disabled={addHomeScreen?.name?.length ? false : true} className={`ButtonContinue ${addHomeScreen?.name?.length ? "" : "disableContinue"}`} type="button" onClick={() => {
                setnameModalOpen(false)
                settemplateModalOpen(true)
              }}>Continue</button>
            </div>


          </div>
        </Fade>
      </Modal>

    </div>

  );
}



const useStyles = makeStyles((theme) => ({

  MediaModalContainer: {
    background: "#fff",
    maxWidth: "1030px",
    width: "100%",
    height: "750px",
    position: "relative",
    top: "80px",
    borderRadius: "4px",
    margin:" 0 auto",
  },

  CardContainer: {
    width: "119px",
    height: "119px",
    display: "inline-block",
    borderRadius: "0px",
    padding: "0",
    overflow: "hidden",
    margin: "2px !important",
    float: "left",
  },

  Header: {
    display: "flex",
    justifyContent: "space-between",
    padding: "17px 30px",
    background: "#F7F8FA",
    borderRadius: "4px 4px 0 0",
  },

  Title: {
    color: "#5a5a5a",
    fontSize: "24px",
    fontFamily: "Poppins",
    fontWeight: "300",
    margin: "0",
  },

  ModalFooter: {
    background: "#fff",
    boxShadow: "0px -1px 4px #0000000F",
    padding: "17px 0px",
    position: "absolute",
    bottom: "0",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderRadius:"0 0 4px 4px",

    "& .ButtonCancel": {
      color: "#818E94",
      backgroundColor: "transparent",
      borderRadius: "100px",
      margin: " 0 30px",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "400",
      height: "40px",
      outline: "none",
      border: "none",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "uppercase",
      letterSpacing: "1.25px",
    },

    "& .ButtonContinue": {
      color: "#fff",
      backgroundColor: "#2a82f6",
      borderRadius: "100px",
      padding: " 0 30px",
      margin: " 0 30px",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "400",
      height: "40px",
      outline: "none",
      border: "none",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textTransform: "uppercase",
      letterSpacing: "1.25px",
    },

    "& .disableContinue": {
      color: "#fff",
      backgroundColor: "darkGray",
      cursor: "default",
    },
  },

  BodyFormContaier:{
    maxWidth: "670px",
    margin: "0 auto",
    paddingTop:"60px",
  },

  FieldDescription: {
    color: "#818E94",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: "400",
    margin: "0",
    letterSpacing: " 0.49px",
    textAlign: "center",
    paddingTop: "16px"
  },

  ModalIcon:{
    width:"170px",
    margin: "0 auto",
    display:"inherit",

  },

  FormContaier:{
    paddingTop: "30px",

    "& .MuiInputBase-input" :{
      textAlign:"center",
      fontSize:"40px",
    },

    "& ::placeholder" :{
      color: "#D3D8DA"
    },

  },

  CharCount:{
    color: "#D3D8DA",
    fontSize: "12px",
    fontFamily: "Poppins",
    fontWeight: "400",
    marginTop: "4px",
    textAlign:"center",
  }

}), {index: 1});