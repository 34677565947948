import React from "react";
import { useEffect, useState } from 'react';
import { BaseTextField, MasterLayoutMiddle, FooterInner, BasePhoneInput, BaseButton, BaseCheckBox } from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import axios from "axios";
import { getCurrentUserDetail } from "../UserProfile/Apis/index";
import {getItemDetails} from './Functions';
import CircularProgress from '@material-ui/core/CircularProgress';


const orgEnv = {
    prod: process.env.REACT_APP_BASEURL_PROD_API,
    dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
    window.location.host.includes("amazon") ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("shapla")
) {
    currentEnv = "dev";
} else {
    currentEnv = "prod";
}

const orgBaseUrl = orgEnv[currentEnv];

function AddContact(props) {

    const emailRegex = /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/;

    const [inputValues, setValues] = React.useState({
        firstName: '' , 
        lastName: '', 
        email: '' , 
        phoneNumber: '' , 
        phonePrefix: '',
        emailVerified: false, 

    });

    const [buttonDisabled , setButtonDisabled] = React.useState( 'disabled' );

    const [ emailInvalid , setemailInvalid ] = React.useState('');

    async function editSave(e){
        e.preventDefault();
        setLoading( true );
        
        const token = localStorage.getItem("authToken");
        const orgId = localStorage.getItem('orgID');

        const postData = {
            firstName: inputValues.firstName,
            lastName: inputValues.lastName,
            email: inputValues.email,
            phoneNumber: inputValues.phoneNumber,
            phonePrefix: inputValues.phonePrefix,
            orgId: orgId,
            source: 'admin',            
            communicationType: 'contacts'

        };
        const contactId = props.match.params.id.toString() ; 
        return new Promise((resolve, reject) => {
            axios
                .post(`${orgBaseUrl}/communications/contacts/${contactId}`, postData, {
                    headers: {
                        "Content-Type": "application/json",
                        token
                    }
                })
                .then((res) => {
                    resolve(res);
                    // console.log( res.data._id );
                    props.history.push('/communication/contact-details/' + res.data._id +'/editSuccess');

                })
                .catch((error) => {
                    reject(error);
                });
        });

    }

    async function handleSubmit(e) {
        e.preventDefault();
        setLoading( true );
        const token = localStorage.getItem("authToken");
        const orgId = localStorage.getItem('orgID');

        const user = await getCurrentUserDetail(token);
        // console.log( );
        // return ;
        const postData = {
            firstName: inputValues.firstName,
            lastName: inputValues.lastName,
            email: inputValues.email,
            phoneNumber: inputValues.phoneNumber,
            phonePrefix: inputValues.phonePrefix,
            orgId: orgId,
            source: 'admin',
            createdBy: user.name.firstName + ' ' + user.name.lastName,
            communicationType: 'contacts', 
            addToGroup: addToGroup

        };
        
        return new Promise((resolve, reject) => {
            axios
                .post(`${orgBaseUrl}/communications/contacts`, postData, {
                    headers: {
                        "Content-Type": "application/json",
                        token
                    }
                })
                .then((res) => {
                    resolve(res);
                    if( addToGroup ){
                        props.history.push('/communication/groups-details/'+addToGroup + '/contactAddedSuccess');
                    }else{
                        props.history.push('/communication/contact-list/contactAddSuccess');
                    }
                    
                    setLoading( false );

                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    function cancelSubmit(e) {
        e.preventDefault();

        props.history.replace('/communication/contact-list');
    }




    const style = {
        width: '100%',
    };

    const styles = {
        btn: {
            height: 66,
            width: "100%",
            margin: 8,
            border: "#D3D8DA",
        },
    };



    const handleChange = (prop) => (event) => {
        setValues({
            ...inputValues,
            [prop]: event.target.value,
        });

        if( prop =='email' ){
            if(emailRegex.test( event.target.value) ){                
                setemailInvalid( '' );
            }else{
                setemailInvalid( 'Email Invalid' );
            }    
        }
        checkVerification();
    };

    function checkVerification(){
        if( inputValues.firstName.length> 0 && inputValues.email.length > 0   ){
            if(emailRegex.test( inputValues.email ) ){
                setButtonDisabled( '');
                setemailInvalid( '' );
            }else{
                setemailInvalid( 'Email Invalid' );
            }
            
        }else{
            setButtonDisabled( 'disabled');
        }
    }

    function phoneNumberChanged(phonenumber, dialcode) {
        setValues({
            ...inputValues,
            ['phoneNumber']: phonenumber,
            ['phonePrefix']: dialcode,
        });

        // console.log( inputValues );
        checkVerification(); 
    }

    const [phone, setPhone] = React.useState({
        country: "",
        number: "",
    });

    const [checked, setChecked] = React.useState(true);
    const [emailUnique, setEmailUnique] = React.useState(true);
    const [editItem, setEditItem] = useState( false );
    const [pageTitle , setpageTitle] = useState( 'Add Contact');
    const [addToGroup , setAddtoGroup ] = useState( 0 );
    const [loading , setLoading ] = useState( false );
    

    useEffect(() => {
        const contactId = props.match.params.id ; 
        let checked = true ;

        if( contactId && contactId.includes('addToGroup') ){
            const groupIdInURL = ( contactId.split('-'));
            if( Array.isArray( groupIdInURL ) && groupIdInURL[1] ){
                setAddtoGroup(groupIdInURL[1]);
            }
        }else if( contactId ){
            setEditItem( true );
            setpageTitle( 'EDIT CONTACT');
            getItemDetails( contactId.toString() , 'contacts').then(response =>{
                if( checked ){
                    if( response && response.data.body.data ){                          
    
                        setValues( response.data.body.data );
                        
                    }
    
                }
            });
        }
        return () => checked = false  ;


    }, []);

    return (
        <MasterLayoutMiddle
            header={
                <div
                    onClick={() => props.history.replace("/communication/contact-list")}
                    className="backBtn"
                >
                    <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                    <p style={{ alignSelf: "center" }}>Back</p>{" "}
                </div>
            }
            isForm={true}
            formData={
                <>
                    {loading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 30,
                            }}
                        >
                            <CircularProgress color='primary' />
                        </div>
                    ) : ( 
                    <div>

                        <div>
                            <h1 className="title_top">{pageTitle}</h1>
                            <div className="dasheddBorder"></div>
                        </div>
                        <Grid container spacing={3}>

                            <Grid item xs={6}>
                                <div className="field_box">
                                    <BaseTextField
                                        label="First Name"
                                        name="firstname"
                                        value={inputValues.firstName}
                                        onChange={handleChange("firstName")}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div className="field_box">
                                    <BaseTextField
                                        label="Last Name"
                                        name="lastName"
                                        value={inputValues.lastName}
                                        onChange={handleChange("lastName")}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div className="field_box">
                                    <BaseTextField
                                        label="Email"
                                        name="email"
                                        value={inputValues.email}
                                        onChange={handleChange("email")}
                                        helperText={ emailInvalid }
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div className="field_box">
                                    <BasePhoneInput
                                        phoneInputVariant={"underlineWithFlag"} // default, materialui, underlineInput, underlineWithFlag
                                        country={'us'}
                                        value={inputValues.phoneNumber}
                                        countryCodeEditable={false}
                                        onChange={(phone, country) => phoneNumberChanged(phone, country.dialCode)}
                                        onBlur={() => console.log("onBlur")}
                                        onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                                        variant="outlined"
                                        onlyCountries={["us", "ca", "gb", "my", "pk", "bd"]}
                                        containerStyle={{}}
                                        buttonStyle={{}}
                                        inputStyle={{
                                            marginLeft: "0px",
                                            height: "35px",
                                            width: "100%",
                                            fontFamily: "Roboto-Regular",
                                            fontSize: "16px",
                                        }}
                                        containerClass={{}}
                                        //errorText={"Phone number is required"}
                                        error={true}
                                        errorStyles={{ marginBottom: "5px" }}
                                        autoFormat={true}
                                        disableDropdown={false}
                                        disabled={false}
                                        disableCountryCode={false}
                                        enableSearch={false}
                                        disableSearchIcon={false}
                                        placeholder={""}
                                        inputProps={{}}
                                        specialLabel={'Phone'}
                                    // preferredCountries={[]}
                                    // excludeCountries={[]}
                                    />
                                </div>
                            </Grid>
                            {/* <div className="field_box border_top_bottm"> */}
                                {! editItem && <Grid item xs={12}>
                                    
                                        <BaseCheckBox
                                            label="If this email already exist in my contacts, update their profile"
                                            checked={emailUnique}
                                            color="secondary"
                                            onChange={(e) => setEmailUnique( e.target.checked  ) }
                                        />
                                    
                                </Grid>
                                }

                                {inputValues.emailVerified == false && (
                                    <Grid item xs={12}>
                                        {/* <div className="field_box border_top_bottm"> */}
                                            <BaseCheckBox
                                                label="Send verification email to contact"
                                                checked={checked}
                                                color="secondary"
                                                onChange={ (e) => setChecked( e.target.checked  ) }
                                            />
                                        {/* </div> */}
                                    </Grid>
                                )}
                            {/* </div> */}

                            <Grid item xs={12}>

                                <div className="form_button">
                                    <p
                                        className='cancel'
                                        onClick={cancelSubmit}
                                    >
                                        CANCEL
                                    </p>
                                    {editItem && (
                                        <BaseButton disabled={buttonDisabled} text='SAVE' onClick={editSave} />
                                    )}
                                    {!editItem && (
                                        <BaseButton disabled={buttonDisabled} text='ADD NOW' onClick={handleSubmit} />
                                    )}
                                    
                                </div>

                            </Grid>

                        </Grid>

                    </div>
                    )}
                </>
            }
            isFooter={true}
            footer={<FooterInner 
                style={style} 
                termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
                privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                />}
            isWhiteBackground={true}
        />
    );
}

export default AddContact;
