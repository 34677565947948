import axios from "axios";

const baserUrl =
  "https://cors-anywhere.herokuapp.com/http://18.211.207.245:3000";
const env = {
  prod: process.env.REACT_APP_PAYMENT_PROD_API,
  dev: process.env.REACT_APP_PAYMENT_DEV_API
};
const orgEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")
) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}
const orgBaseUrl = orgEnv[currentEnv];
export function addOrganization(credentials,token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/organizations`, credentials,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function connectOrganization(data,token){
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/organizations/connectStripe`, data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getOrganizationData(id){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/organizations/${id}`,{
        headers:{
          token:'deenconnect-elobyte'
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function editOrganizationForSetupForm(data,token){
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/organizations`, data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function editOrganization(data,id,token){
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/organizations/${id}`, data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function addMember(data,token){
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/team-members`, data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function editMember(data,id,token){
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/team-members/${id}`, data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getTeamMember(id,token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/team-members/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getTeamMembers(token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/team-members`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteTeamMember(id,token){
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/team-members/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getSpeakers(id,token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/speakers?orgId=${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getSpeaker(id,token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/speakers/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function addSpeakers(data,token){
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/speakers`,data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function UpdateSpeakers(data,token,id){
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/speakers/${id}`,data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function deleteSpeaker(token,id){
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/speakers/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getServices(id,token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/services?orgId=${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getAllServices(token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/services`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getService(id,token){
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/services/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function addService(data,token){
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/services`,data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function UpdateService(data,token,id){
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/services/${id}`,data,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function deleteService(id,token){
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/services/${id}`,{
        headers:{
          token
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getaddressFromPostalCode(postalCode) {

  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/shared/addressFromPostalCode?postal_code=${postalCode}`)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}