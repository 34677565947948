import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Verify, AlertAction } from '@dclab/dc-react-ui-kit';
import Logo from '../../assets/img/DC_Logo.png';
import { verifyCodeHandler } from '../../redux/actions/signupAction';
import { ResendCode, verifyHandler } from './api';
import './style.css';
import messages_content from '../../utils/messages.json';
const { 
  email_verfy_message
  } = messages_content;
class Verification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      error: false,
      showAlert: false,
      resendShowAlert: false,
      isLoading: false,
      success: false,
    };
    this.click = this.click.bind(this);
    this.resend = this.resend.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false, resendShowAlert: false });
    }, 8000);
  };
  click = async () => {
    const { code } = this.state;
    this.setState({ error: false, showAlert: false, isLoading: true });
    if (!code) {
      this.setState({
        error: true,
        isLoading: false,
      });
    } else {
      const obj = {
        userName: this.props.signup.email,
        code,
      };
      try {
        await verifyHandler(obj);
        this.props.verifyCodeHandler(code);
        this.setState({ showAlert: true, isLoading: false, success: true });
        setTimeout(() => {
          this.props.history.replace({
            pathname: '/verify-success',
            state: {
              email: this.props.location.state.email,
              password: this.props.location.state.password,
              teamMember: this.props.location.state.teamMember,
            },
          });
        }, 2000);
      } catch (error) {
        if (error.response && error.response.data.statusCode === 401) {
          this.props.history.replace('/sign-locked');
        }
        this.setState({
          error: true,
          isLoading: false,
          showAlert: true,
        });
        this.hideAlert();
      }
    }
  };
  resend = async () => {
    let email = localStorage.getItem('email');
    const obj = {
      userName: email,
    };
    try {
      const res = await ResendCode(obj);
      this.setState({
        resendShowAlert: true,
        error: false,
        code: ''
      });
      this.hideAlert();
    } catch (error) {
      this.setState({
        error: error && error.response && error.response.data.message,
        isLoading: false,
      });
    }
  };
  render() {
    this.setOtp = (code) => {
      this.setState({
        code,
      });
    };
    const {
      code,
      error,
      isLoading,
      resendShowAlert,
    } = this.state;
    return (
      <div style={{ position: 'relative', paddingBottom: '40px' }}>
        <div style={{ position: 'absolute', width: '100%', zIndex: -1 }}>
          <div
            className='Background-Image'
            style={{ width: '100%', position: 'fixed', height: '100vh' }}
          ></div>
        </div>
        {resendShowAlert && (
          <div
            style={{
              zIndex: 99,
              width: '100%',
              position: 'fixed',
              marginTop: 22,
              marginLeft: 200,
            }}
          >
            <AlertAction
              alert={email_verfy_message}
              alertType={'success'}
              onClick={() => this.setState({ resendShowAlert: false })}
            />
          </div>
        )}
        <div
          style={{ opacity: 1, paddingTop: 30 }}
          className='signup-container div_backgrndimg-pattern'
        >
          <Verify
            isLoading={isLoading}
            name='verifyCode'
            disabled={code.length !== 6 && true}
            title='Verification'
            metaTitle='Please enter a verification code send to your email address'
            email={localStorage.getItem('email')}
            btnText='Verify'
            forVerify={true}
            src={Logo}
            LogoLink="https://shapla.deenconnect.io/"
            value={code}
            onChangeCode={(char) => this.setOtp(char)}
            onClickVerify={this.click}
            onClickResend={this.resend}
            hasErrored={error}
            errorStyle={{
              border: '1px solid #B00020',
            }}
            error={false}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    signup: state.signup,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    verifyCodeHandler: (code) => dispatch(verifyCodeHandler(code)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Verification);
