import * as constants from "../constants";

function editEvent(data) {
    return {
        type: constants.EDIT_EVENT,
        payload: data
    };
}
function emptyEvent(data) {
    return {
        type: constants.EMPTY_EVENT,

    };
}

export { editEvent , emptyEvent };
