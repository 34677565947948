import React, { useEffect } from "react";
import {
  BaseTextField,
  BaseButton,
  BaseSelect,
  BaseRadio,
  FormSwitch,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { useState } from "react";
import "./style.css";
import { AddNavMenusWebsite, getNavMenusWebsite, UpdateNavMenusWebsite } from "./api";
import { getOrganizationData } from "../DeenFund/apis";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
const { token_expired, success_nav_created, menu_added_successfully } = messages_content;

function AddNavigationMenu(props) {
  const [Menu, setMenu] = React.useState(true);
  const [Link, setLink] = React.useState(true);
  const [error, seterror] = useState(false);
  const [Switch, setSwitch] = React.useState(false);
  const [errorWeb, setErrorWeb] = React.useState(false);
  const [mainError, setMainError] = React.useState(false);
  const [subError, setSubError] = React.useState(false);
  const [selectPage, setSelectPage] = React.useState(false);
  const [specificData, setSpecificData] = React.useState([]);
  const [showAlert, setshowAlert] = useState(false);
  const [Loading, setLoading] = React.useState(true);
  const [errorName, setErrorName] = React.useState(false)
  const [values, setValues] = useState({
    name: '',
    Sub: '',
    page: '',
    Link: ''
  });
  const [MenusList, setMenusList] = React.useState([]);
  const [Pages, setPages] = React.useState([]);
  useEffect(() => {
    getMenus();
  }, [])
  async function getMenus() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID')
    try {
      const getOraganization = await getOrganizationData(orgId, token);
      const oraganization_data = getOraganization?.data?.body?.data[0]?.enabledIntegrations?.deenweb?.pages
      let results = Object.entries(oraganization_data).map((e) => ({ [e[0]]: e[1] }));
      let data = []
      results.filter((text) => {
        var resultObj = Object.keys(text).map((key) => text[key]);
        let name = Object.keys(text)
        let arr = resultObj.map((tex, i) => {
          return (
            {
              value: name[0],
              label: name[0],
            }
          )
        })
        data.push(arr[0])
      })
      setPages(data);
      let result = await getNavMenusWebsite(token);
      let Nav = result.data.body.data;
      let array = Nav.map((text, index) => {
        return (
          {
            ...text,
            index: index,
            value: text._id,
            label: text.label,
          }
        )
      })
      setMenusList(array);
      setLoading(false);
    } catch (err) {
      seterror(err.response && err.response.data.message)
      setLoading(false);
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }

  async function onSave() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID')
    let webRegex = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
    let condition = Link ? values.page : values.Link
    if (values.name === '' || condition === '') {
      if (!Link && !webRegex.test(values.Link)) {
        setErrorWeb(true)
      }
      if (Menu && values.name === "") {
        setMainError(true)
        setErrorName(true)
      }
      if (condition === '') {
        setSelectPage(true);
      }
      if (!Menu && values.Sub === '') {
        setSubError(true)
      }
    } else {
      setErrorWeb(false);
      setMainError(false);
      setSelectPage(false);
      setSubError(false);
      if (Menu) {
        let Obj = {
          label: values.name,
          linkDetails: {
            linkType: Link ? "Internal" : 'External',
            linkUrl: Link ? values.page : values.Link,
          },
          enable: Switch,
          subMenu: [],
          orgId: orgId
        }
        try {
          await AddNavMenusWebsite(token, Obj);
          props.alertShow(menu_added_successfully)
          setshowAlert(true)
          setshowAlert(false)
          props.history.push("/website/navigation-menu");
        } catch (err) {
          seterror(err.response && err.response.data.message)
          setTimeout(() => {
            seterror(false)
          }, 8000);
        }
      } else {
        const prevSubMenus = specificData?.[0]?.subMenu ? specificData[0].subMenu : [];
        let Obj = {
          subMenu: [
            ...prevSubMenus,
            {
              enable: Switch,
              label: values.name,
              linkDetails: {
                linkType: Link ? "Internal" : 'External',
                linkUrl: Link ? values.page : values.Link,
              },
            }
          ],
        }
        try {
          await UpdateNavMenusWebsite(token, Obj, specificData[0]._id);
          setshowAlert(true)
          props.alertShow(menu_added_successfully)
          setshowAlert(false)
          props.history.push("/website/navigation-menu");
        } catch (err) {
          seterror(err.response && err.response.data.message)
          setTimeout(() => {
            seterror(false)
          }, 8000);
        }
      }
    }
  }
  const styles = {
    formBody: {
      background: "#fff",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A"
    },
    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
      marginTop: 7,
    },
    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
    stepper: {
      marginTop: 120,
      padding: "8px 20px",
      borderRadius: "30px",
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    select: {
      margin: "14px 0px 0px 0px",
      minWidth: 120,
      height: 66,
      width: 120,
      border: "#D3D8DA",
      width: "100%",
    },
    btn: {
      height: 66,
      width: "100%",
      margin: '0 0 8px 0',
      border: "#D3D8DA",
    },
    BaseTextField: {
      width: "100%",
    },
    editor: {
      padding: 16,
    },
  };
  const handleChange = (prop) => (event) => {
    if (prop === 'Sub') {
      let data = MenusList.filter(text => text._id === event.target.value)
      setSpecificData(data);
      setValues({
        ...values,
        [prop]: event.target.value,
      });
    } else {
      setValues({
        ...values,
        [prop]: event.target.value,
      });
    }
  };
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      header={
        <div
          onClick={() => props.history.push("/website/navigation-menu")}
          className="backBtn"
          style={{ marginTop: 0 }}
        >
          <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
          <p style={{ alignSelf: "center" }}>Back</p>{" "}
        </div>
      }
      isForm={true}
      formData={
        Loading ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <>
            {
              showAlert &&
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                <AlertAction
                  alert={success_nav_created}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            }
            {
              error && error === "Invalid token" && (
                <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                  <AlertAction
                    alert={token_expired}
                    alertType={"error"}
                    onClick={() => seterror(false)}
                  />
                </div>
              )
            }
            <div>
              <div>
                <h1 className="title_top">Add Menu</h1>
                <div className="dasheddBorder"></div>
              </div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h2 className="sub_title">Details</h2>
                  <Typography style={styles.formTitleSub}>Add this menu as a</Typography>
                </Grid>
                <Grid item xs={3}>
                  <BaseRadio
                    label={"Main Menu"}
                    color={"secondary"}
                    checked={Menu}
                    onChange={() => setMenu(true)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <BaseRadio
                    label={"Submenu"}
                    color={"secondary"}
                    checked={!Menu}
                    onChange={() => setMenu(false)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseTextField
                      value={values.name}
                      label='Menu Label'
                      onChange={handleChange("name")}
                      InputProps={false}
                      phone={false}
                      search={false}
                      count={20}
                      style={styles.btn}
                      color="primary"
                      disabled={false}
                      error={mainError}
                     // helperText="Helper text"
                      error={errorName}
                    />
                  </div>
                </Grid>
                {
                  !Menu ?
                    <Grid item xs={12}>
                      <div className="field_box">
                        <BaseSelect
                          value={values.Sub}
                          label='Add Under'
                          onChange={handleChange("Sub")}
                          object={MenusList}
                          error={subError}
                          style={styles.select}
                         // helperText="Helper text"
                        />
                      </div>
                    </Grid>
                    :
                    null
                }
                <Grid item xs={12}> <div className="dasheddBorder_form"></div></Grid>
                <Grid item xs={12}>
                  <h2 className="sub_title">Link to</h2>
                  <Typography style={styles.formTitleSub}>Select a destination for the menu, you can choose an internal page or external link</Typography>
                </Grid>
                <Grid item xs={4}>
                  <BaseRadio
                    label={"Internal page"}
                    color={"secondary"}
                    checked={Link}
                    onChange={() => setLink(true)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <BaseRadio
                    label={"External link"}
                    color={"secondary"}
                    checked={!Link}
                    onChange={() => setLink(false)}
                  />
                </Grid>
                {
                  Link ?
                    <Grid item xs={12}>
                      <div className="field_box">
                        <BaseSelect
                          value={values.page}
                          label='Select Page'
                          onChange={handleChange("page")}
                          object={Pages}
                          error={selectPage}
                          style={styles.select}
                          //helperText="Helper text"
                        />
                      </div>
                    </Grid>
                    :
                    <Grid item xs={12}>
                      <div className="field_box">
                        <BaseTextField
                          value={values.Link}
                          label='External Link'
                          onChange={handleChange("Link")}
                          InputProps={false}
                          phone={false}
                          search={false}
                          style={styles.select}
                          color="primary"
                          disabled={false}
                          error={errorWeb}
                          //helperText="Helper text"
                        />
                      </div>
                    </Grid>
                }
                <Grid item xs={12}>
                  <div className="field_box border_top_bottm">
                    <FormSwitch
                      body='Disabled menus can’t be accessed by your site visitors.'
                      headline='Enable Menu'
                      values={Switch}
                      handleSwitchChange={(e) => setSwitch(e)}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="form_button">
                    <span className="btnCencel">
                      <BaseButton text="CANCEL" onClick={() => props.history.push('/website/navigation-menu')} />
                    </span>
                    <BaseButton text="ADD NOW" onClick={() => onSave()} />
                  </div>
                </Grid>
              </Grid>
            </div>
          </>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(0, mapDispatchToProps)(AddNavigationMenu);
