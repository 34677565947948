import React from "react";
import { useEffect, useState } from 'react';
import { SimpleTable, MasterLayoutMiddle, FooterInner, CardDetailsTable, AlertAction, AlertDialogue } from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { getItemDetails, deleteTemplate, getGroupContacts } from './Functions';
import moment from 'moment';
import messages_content from '../../utils/messages.json';

const {
    add_com_group_success,
} = messages_content;


function MessagesDetails(props) {


    const style = {
        width: '100%',
    };

    let header = {
        tableName: `Message Details`,
    };

    const [itemDetails, setItemDetails] = useState({
        createdAt: '',
        message: '',
        type: '',
        emailSubject: '',
        emailHeader: '',
        date: '' , 
        status: '',
        contacts: [], 
        isSchedules: '',
        updatedAt: '',
        fromName: ''

    });

    const [success, setSuccess] = useState(false);

    let data = [

        {
            field: "Message Type",
            value: itemDetails.type
        },

        {
            field: "Date",
            value: moment(itemDetails.date).format('MMM DD, YYYY')
        },

        

        {
            field: "To",
            value: itemDetails.contacts.length + " contacts"
        },



    ];

    const[ data2 , setData2] = useState( [
        {
            field: "Message",
            value: itemDetails.message 
        }
    ]);

    const modalStyle = {
        width: 470,
        marginLeft: 800
    };

    useEffect(() => {
        let checked = true;
        let item;

        async function fetchData() {

            const itemId = (props.match.params.id.toString());
            // console.log( itemId );

            const status = props.match.params.status;

            if (status && status.length) {
                setSuccess(true);
            }

            await getItemDetails(itemId, 'messages').then(response => {
                if (checked) {

                    if (response && response.data.body.data) {

                        item = response.data.body.data;                        

                        setItemDetails(item);  
                        
                        if( item.type.toLowerCase() =='email'){
                            setMessageType('EMAIL');

                            let obj = [{
                                value: item.emailSubject, 
                                field: 'Subject'
                            },{
                                value: item.message, 
                                field: "Message"
                            }];
                            setData2(  obj );
                            

                        }else{
                            let obj = [{
                                value: item.message, 
                                field: "Message"
                            }];
                            setData2(  obj );
                        }                         
                        
                    }
                }
            });         
            
            if( item && item.contacts && item.contacts.length){

                const contactItems = await getGroupContacts( item.contacts.join(',') );

                console.log( contactItems )

                setMessageContacts( contactItems.data.body.data );
            }

        }

        fetchData();

        return () => checked = false;

    }, []);

    const [messageContacts, setMessageContacts] = useState([]);


    let footerDeleteButton = "DELETE MESSAGE";
    let footerLinkName = "COPY MESSAGE";

    // HANDLE FOOTER LINK
    const onClick = () => {
        console.log('edit')
    }
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const handleCloseModal = () => {
        setDeleteModalOpen(false);
    }

    async function handleConfirm() {
        setDeleteModalOpen(false);

        const itemId = props.match.params.id.toString();

        const response = await deleteTemplate('messages', itemId);

        props.history.push( '/communication/messages/messageDelete');
    }

    async function copyItem(){

        const itemId = props.match.params.id.toString();        

        props.history.push( '/communication/new-message/'+itemId );
    }


    const onDeleteClick = () => {
        setDeleteModalOpen(true);
    }

    const [messageType , setMessageType] = React.useState('PHONE');

    // ============================== Table Start ======================================//


    let col = [
        { type: 'link', name: 'type', label: 'FIRST NAME', width: '15%' },
        { type: 'link', name: 'type', label: 'LAST NAME', width: '15%' },
        { type: 'link', name: 'type', label: messageType, width: '15%' },
        { type: 'link', name: 'type', label: 'GROUP', width: '60%', },
        { type: 'link', name: 'type', label: '', width: '40%', },

    ];


    // const selected_categories = data.sort((a, b) => a.order - b.order)
    let rows = messageContacts.map((item, index) => {
       
        return (
            [
                { type: 'string', name: 'time', value: item.firstName },
                { type: 'string', name: 'time', value: item.lastName  },
                { type: 'string', name: 'name', value: messageType =='PHONE' ?  "+"+item.phoneNumber : item.email },
                { type: 'string', name: 'time', value: "" },

            ]
        )
    })



    // ============================== Table End ======================================//

    return (
        <MasterLayoutMiddle
            header={
                <div
                    onClick={() =>props.history.push("/communication/messages")}
                    className="backBtn"
                >
                    <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                    <p style={{ alignSelf: "center" }}>Back</p>{" "}
                </div>
            }

            content={
                <div>
                    {success && (
                        <div
                            style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                        >
                            <AlertAction
                                alert={add_com_group_success}
                                alertType={"success"}
                                onClick={() => setSuccess(false)}
                            />
                        </div>
                    )}
                    <div style={{ marginTop: "60px", }}>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <CardDetailsTable
                                    data={data}
                                    data2={data2}
                                    twoColumns
                                    header={header}
                                    footerLinkName={footerLinkName}
                                    footerDeleteButton={footerDeleteButton}
                                    onClick={copyItem}
                                    onDeleteClick={() => onDeleteClick()}
                                    Status={true}
                                    StatusLabel="Status"
                                    StatusText={itemDetails.status}
                                    statuscolor={itemDetails.status.toLocaleLowerCase() =='sent' ? '#3CC480': '#818E94'}
                                    // "#3CC480" // Sent //
                                // statuscolor="#818E94" //Scheduled//
                                // statuscolor="#B00020" //Failed //

                                />
                            </Grid>

                            <Grid item xs={12}>
                                <div className="table_box">
                                    <SimpleTable
                                        showHeader={true}
                                        title="Recipients"
                                        width="100%"
                                        rows={rows}
                                        columns={col}
                                        colSpan={16}
                                        actionSendButton={true}
                                        donationsCount={rows && rows.length > 0 ? 1 : 0}
                                        noTitle="No inquiry submitted yet"
                                        noPara="Inquiries submitted by visitors will be visible and managed here."
                                        noBtnText=""
                                        onInviteClick={() =>
                                            props.history.push(
                                                "/organization/contact-us-details"
                                            )
                                        }
                                        actionLink={(name) => props.history.push({ pathname: "/organization/contact-us-details", state: { contact: name[0].data } })}
                                        showIconCol={false}
                                        actionSendButton={true}
                                        deleteAction={(e) => console.log(e)}
                                        showFooter={true}
                                        showAdd={false}
                                        addText="ADD NEW"
                                        onAddClick={() =>
                                            props.history.push(
                                                "/organization/contact-us-details"
                                            )
                                        }
                                        iconTextSize={16}
                                    />
                                </div>
                            </Grid>

                        </Grid>
                        <div className="DeleteImgButton">

                            <AlertDialogue
                                title="Delete Message?"
                                description="Are you sure you want to delete this message? Deleting will permanently remove from everywhere."
                                cancelText="Cancel"
                                confirmText="Confirm"
                                open={deleteModalOpen}
                                handleCancel={handleCloseModal}
                                handleClose={handleCloseModal}
                                handleConfirm={handleConfirm}
                                styles={modalStyle}
                            />
                        </div>
                    </div>
                </div>
            }


            isFooter={true}
            footer={<FooterInner 
                    style={style}
                    termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
                    privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                    />}
            isWhiteBackground={false}
        />
    );
}

export default MessagesDetails;
