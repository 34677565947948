import React, { useRef } from "react";
import {
  BaseTextField,
  PageHeaderWithTabs,
  FormSwitch,
  BaseCheckBox,
  SimpleTable,
  MasterLayoutMiddle,
  FooterInner,
  AlertDialogue,
  AlertAction,
  AlertDialogueAdvance,
  EmptyTable
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import Handle from "../../assets/img/more_vert-24px.svg";
import "./style.css";
import { getFaqsWithAppId, DeleteFAQWebsite, deleteFaqsWithAppId, sortFaqWebsite } from "./api";
import { getOrganizationData } from "../Organization/api";
import { saveAsDraft } from "../../components/DonationForm/Apis";
import { connect } from "react-redux";
import { PagesAction } from "../../redux/actions/pages";
import { CircularProgress } from "@material-ui/core";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
const {
  token_expired,
  remove_faq_message,
  quick_access_limit_axeeded,
  Your_changes_has_been_published,
  Your_changes_has_been_saved_as_draft
} = messages_content;
function Faq(props) {
  const history = props.history;
  const [Title, setTitle] = React.useState('')
  const [value, setValue] = React.useState(0);
  const [faqs, setFaqs] = React.useState([]);
  const [faqsRow, setFaqsRow] = React.useState([]);
  const [specificRow, setSpecificRow] = React.useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [Loader, setLoader] = React.useState(true);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState(false);
  const [getNewData, setNewData] = useState(false);
  const [errorFound, setErrorFound] = useState(false)
  const unblockHandle = useRef();
  const [changes, setCheckChanges] = useState(false);
  const [saved_route, setRoute] = useState('');
  const [open, setAlertModal] = useState(false);
  const [organizationData, setOrganizationData] = useState([])
  const [QuickLimit, setQuickLimit] = useState(false);
  const [homepageButton, setHomePageButton] = useState(false);
  const [pagesAll, setPagesAll] = useState({})
  const [integrateData, setIntegrateData] = useState({});
  const [dischard_changes, setdischard_changes] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      props.alertHide()
    }, 8000)
  }, [props.alert.alertValue])
  useEffect(() => {
    unblockHandle.current = history.block((route) => {
      setRoute(route.pathname)
      changes && visibleModal()
      return changes === true ? false : true
    });
    return () => {
      unblockHandle.current = false
      !window.location.pathname.includes('faq') && localStorage.removeItem('faq')
    }
  }, [changes])
  function visibleModal() {
    setAlertModal(true)
  }
  useEffect(() => {
    const page_no = localStorage.getItem('faq')
    setValue(page_no ? +page_no : 0);
    async function getorganization() {
      try {
        const token = localStorage.getItem('authToken')
        const orgId = localStorage.getItem('orgID')
        const getOraganization = await getOrganizationData(orgId, token);
        const organizationData = getOraganization?.data?.body?.data?.enabledIntegrations;
        const data = organizationData.deenweb?.pages;
        const oraganization_data = data?.faqs
        setIntegrateData(organizationData);
        setPagesAll(data);
        setOrganizationData(oraganization_data);
        setTitle(oraganization_data.title);
        setHomePageButton(oraganization_data.enablePage)
        setChecked(oraganization_data.quickAccess)
        setNewData(false)
      }
      catch (error) {
        seterror(error.response && error.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    }
    getorganization()
  }, [getNewData])
  const handleChange = (event, newValue) => {
    changes && (Title === '' ? setErrorFound(true) : setAlertModal(true))
    !changes && setValue(newValue);
    !changes && localStorage.setItem('faq', newValue)
    setdischard_changes(newValue)
  };
  useEffect(() => {
    getFaQsAll()
  }, [])
  async function getFaQsAll() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID')
    try {
      const responseFaqs = await getFaqsWithAppId(token, orgId, 'deenweb')
      const data = responseFaqs.data.body.data;
      // const sort = data.sort((a, b) => {
      //   return new Date(b.updatedAt) - new Date(a.updatedAt)
      // })
      const sort = data.sort((a, b) => a?.order - b?.order);
      setFaqs(sort);
      let dataArray = []
      sort.map((text, index) => {
        const Answere = JSON.parse(text.answer);
        let array = [];
        array = [
          { type: "id", value: `${index <= 8 ? '0' + (index + 1) : index + 1}`, _id: text._id },
          {
            type: "string",
            name: "name",
            value: text.question,
          },
          {
            type: "string",
            name: "category",
            value: Answere[0].children[0].text.length > 29 ? Answere[0].children[0].text.slice(0, 30) + " ..." : Answere[0].children[0].text,
          },
        ]
        dataArray.push(array);
      })
      setFaqsRow(dataArray);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const object = [
    {
      label: "Page setup",
      item: "Page setup",
    },
    {
      label: "Contents",
      item: "Contents",
    },


  ];
  const handleCloseDraft = () => {
    setAlertModal(false)
  };
  const discardChanges = () => {
    setCheckChanges(false)
    setAlertModal(false)
    setValue(dischard_changes)
    setChecked(organizationData.quickAccess);
    setHomePageButton(organizationData.enablePage)
    setTitle(organizationData.title)
    if (unblockHandle) {
      unblockHandle.current()
    }
    if (saved_route) {
      props.history.push(saved_route)
    }
  };
  const checkChanges = (boolean) => {
    setCheckChanges(boolean)
  }
  const saveChanges = async () => {
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    if (Title === '') {
      setErrorFound(true)
    } else {
      const data = {
        enabledIntegrations: {
          ...integrateData,
          deenweb: {
            ...integrateData.deenweb,
            pages: {
              ...pagesAll,
              faqs: {
                title: Title,
                status: homepageButton ? 'active' : 'inactive',
                enablePage: homepageButton,
                quickAccess: checked,
                lastEdited: new Date(),
                pageName: 'About Us',
              }
            }
          }
        }
      }
      // const data = {
      //   "addSelectedArrayData": {
      //       "enabledIntegrations.deenweb.pages.faqs": {
      //         title: Title,
      //         status: homepageButton ? 'active' : 'inactive',
      //         enablePage: homepageButton,
      //         quickAccess: checked,
      //         lastEdited: new Date(),
      //       }
      //     },
      //   }
      try {
        let response = await saveAsDraft(orgId, data, token);
        setshowAlert(true);
        setsuccess(true)
        setValue(dischard_changes);
        setAlertModal(false)
        if (saved_route) {
          props.history.push(saved_route)
        }
      } catch (err) {
        setAlertModal(false)
        seterror(err.response && err.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    }
  };

  const publish = async () => {
    let pages = props?.pages?.pages
    let lengthOfPages = pages.filter(text => text.quickAccess === true);
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    if (Title === '') {
      setErrorFound(true)
    } else {
      const data = {
        enabledIntegrations: {
          ...integrateData,
          deenweb: {
            ...integrateData.deenweb,
            pages: {
              ...pagesAll,
              faqs: {
                title: Title,
                status: homepageButton ? 'active' : 'inactive',
                enablePage: homepageButton,
                quickAccess: checked,
                lastEdited: new Date(),
                pageName: 'About Us',
              }
            }
          }
        }
      }
      if (!checked || organizationData.quickAccess || lengthOfPages.length < 6) {
        try {
          await saveAsDraft(orgId, data, token);
          setshowAlert(true);
          setsuccess('Publish');
          setCheckChanges(false)
          setAlertModal(false)
          setValue(dischard_changes);
          let Quick = pages.filter(text => text.name[0] !== "faqs")
          Quick.push({
            name: ['faqs'],
            quickAccess: checked
          })
          props.dispatchPagesAction(Quick)
        } catch (err) {
          seterror(err.response && err.response.data.message)
          setTimeout(() => {
            seterror(false)
          }, 8000);
        }
      } else {
        setQuickLimit(true)
        setTimeout(() => {
          setQuickLimit(false)
        }, 8000)
      }
    }
  };
  const saveStyles = {
    backgroundColor: "#2A82F6",
  };
  const publishStyles = {
    backgroundColor: "#2A82F6",
    opacity: 0.6,
  };
  const paperStyles = {};
  const styles = {
    BaseTextField: {
      width: "100%",
    },
    contents: {
      paddingTop: 20,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "100%",
      marginTop: 70,
      marginLeft: "14%",
    },
  };
  const [checked, setChecked] = useState(true);
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  const deleteServiceAction = async (e) => {
    setOpenAlert(false);
    setLoader(true);
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    try {
      await deleteFaqsWithAppId(token, orgId, 'deenweb', e[0]._id)
      await DeleteFAQWebsite(token, e[0]._id)
      props.alertShow(remove_faq_message)
      const stateNews = faqsRow
      const NewsIndex = faqsRow.indexOf(e)
      stateNews.splice(NewsIndex, 1);
      setLoader(false);
    } catch (err) {
      setLoader(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const DeleteAlertFunc = (e) => {
    setSpecificRow(e);
    setOpenAlert(true);
  }
  const handleCancel = () => {
    setOpenAlert(false);
  }
  const handleClose = () => {
    setOpenAlert(false);
  };
  let col = [
    { id: "drag", label: " ", width: 'auto' },
    { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
    { type: "string", name: "name", label: "Title", width: '45%' },
    { type: "string", name: "category", label: "Details", width: '45%' },
    { type: "action", name: "action", label: "ACTION", width: '10%' },
  ];
  const style = {
    width: 470,
    marginLeft: 800
  };
  const Alertstyle = {
    width: 470,
    marginLeft: '35%'
  };
  const sortData = async (data) => {

    const token = localStorage.getItem("authToken");
    let OBJ = {
      sortedList: data.map(text => {
        return (
          { _id: text._id, order: text.order }
        )
      })
    }
    try {
      console.log(data, OBJ)
      await sortFaqWebsite(token, OBJ)
    } catch (err) {
      console.log("Error occured: ", err);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }


    data.sort((a, b) => a.order - b.order)
    let arr = []
    data.map((text, index) => {
      let Answere = JSON.parse(text.answer);
      let array = [];
      array = [
        { type: "id", value: `${index <= 8 ? '0' + (index + 1) : index + 1}`, _id: text._id },
        {
          type: "string",
          name: "name",
          value: text.question,
        },
        {
          type: "string",
          name: "category",
          value: Answere[0].children[0].text.slice(0, 30) + "...",
        },
        // { type: "action", value: "" },
      ]
      arr.push(array);
    })
    setFaqsRow(arr)
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          <PageHeaderWithTabs
            back={() => props.history.push('/website/pages')}
            value={value}
            title="FAQs"
            object={object}
            disabled={changes ? false : true}
            indicatorColor="primary"
            style={{ color: "#2A82F6", border: "1px solid black" }}
            handleChange={(x, y) => handleChange(x, y)}
            indicatorColor="#2A82F6"
            saveStyles={saveStyles}
            publishStyles={publishStyles}
            hiddenDraft={true}
            paperStyles={paperStyles}
            saveChanges={() => saveChanges()}
            publish={() => publish()}
          />
          {
            showAlert && success && success === 'Publish' ? (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={Your_changes_has_been_published}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            ) : showAlert && success && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={Your_changes_has_been_saved_as_draft}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            )
          }
          {
            props.alert.alertValue &&
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
              <AlertAction
                alert={props.alert.alertValue}
                alertType={"success"}
                onClick={() => props.alertHide()}
              />
            </div>
          }
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            QuickLimit && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={quick_access_limit_axeeded}
                  alertType={"error"}
                  onClick={() => setQuickLimit(false)}
                />
              </div>
            )
          }
        </>
      }
      content={
        Loader ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <>
            <div>
              <div>
                <AlertDialogueAdvance
                  title="You have unsaved changes!"
                  description='There are unsaved changes. If you would like to save changes, press the "SAVE AS DRAFT" button.'
                  cancelText="Discard Changes"
                  confirmText="PUBLISH"
                  open={open}
                  // childRef.current.saveasDraft()
                  handleCancel={() => publish()}
                  handleClose={() => handleCloseDraft()}
                  handleConfirm={() => discardChanges()}
                  styles={Alertstyle}
                />
              </div>

              {value === 1 && (
                <div>
                  {
                    faqsRow.length ?
                      <SimpleTable
                        showHeader={true}
                        title="Faq"
                        parentTableDrag={true}
                        DragIcon={Handle}
                        rows={faqsRow}
                        columns={col}
                        colSpan={16}
                        actionSendButton={true}
                        classProps={changeClassProps()}
                        donationsCount={faqsRow?.length > 0 ? 1 : 0}
                        noTitle="No Faqs added"
                        noPara="Added Faqs will be displayed and managed here"
                        noBtnText="Add New Faqs"
                        onInviteClick={() =>
                          props.history.push("/website/add-faq")
                        }
                        // actionLink={(name) => this.details(name)}
                        showIconCol={true}
                        editIcon={Edit}
                        deleteIcon={Delete}
                        editAction={(e) =>
                          props.history.push({
                            pathname: `/website/edit-faq/${e[0]._id}`,
                            state: { detail: faqs.filter(text => text._id === e[0]._id) }
                          })
                        }
                        deleteAction={(e) => DeleteAlertFunc(e)}
                        showFooter={true}
                        showAdd={true}
                        addText="ADD NEW"
                        onAddClick={() => props.history.push("/website/add-faq")}
                        iconTextSize={16}
                        sortData={(e) => sortData(e)}
                        data={faqs}
                      />
                      :
                      <EmptyTable
                        title="Faq"
                        onAddClick={() => props.history.push("/website/add-faq")}
                        addLabel="ADD NEW"
                        noBtnText="ADD NEW"
                      />
                  }
                </div>
              )}
              {value === 0 &&
                <div className="page_body">
                  <Typography className="page_form_title">Page Settings</Typography>
                  <Grid className="form_body">
                    <Grid container item xs={6} spacing={3} >
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={Title}
                            onChange={(e) => {
                              setTitle(e.target.value); checkChanges(true); setErrorFound(false)
                            }}
                            InputProps={false}
                            phone={false}
                            error={errorFound}
                            search={false}
                            count={20}
                            style={styles.BaseTextField}
                            color="primary"
                            disabled={false}
                            // helperText="Helper text"
                            label="Page Title"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="page_switch">
                          <FormSwitch
                            style={styles.BaseTextField}
                            headline="Enable Page"
                            body="Disabled pages can’t be accessed by your site visitors."
                            values={homepageButton}
                            handleSwitchChange={() => { setHomePageButton(!homepageButton); checkChanges(true) }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="SolidBorder no_space"></div>
                        <div className="checkBox">
                          <BaseCheckBox
                            label="Quick Access"
                            checked={checked}
                            color="secondary"
                            showHelperText={true}
                            helperText="Add to left navigation under QUICK ACCESS for quickly accessing the page"
                            onChange={(e) => { setChecked(e.target.checked); checkChanges(true) }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              }

              <div>
                <AlertDialogue
                  title="Delete Faq?"
                  description="Are you sure you want to delete this Faq?"
                  description2="Deleting will permanently remove from Website."
                  cancelText="Cancel"
                  confirmText="Confirm"
                  open={openAlert}
                  handleCancel={() => handleCancel()}
                  handleClose={() => handleClose()}
                  handleConfirm={() => deleteServiceAction(specificRow)}
                  styles={style}
                />
              </div>
            </div>
          </>
      }
      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  );
}
const mapStateToProps = (state) => {
  return {
    pages: state.pages,
    alert: state.alert,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatchPagesAction: (data) => dispatch(PagesAction(data)),
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Faq);
