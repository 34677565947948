import React, { Component } from "react";
import {
  SimpleTable,
  BasePaper,
  CardData,
  BaseTabs,
  AlertAction,
  GridContainer,
  GridItem,
  MasterLayoutMiddle,
  FooterInner
} from "@dclab/dc-react-ui-kit";
import { connect } from "react-redux";
import { setAllDonors } from "../../redux/actions/donorsActionCreators";
import { getDonors, getDonorsCardData, getDonationsByCategory } from "./api";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../App.css";
import '../style.css'
import moment from 'moment';
import messages_content from '../../utils/messages.json';
const { 
    token_expired
  } = messages_content;
class DonorCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      activeTab: 0,
      donors: props.donors,
      donorsCardData: {},
      donationByCategory: [],
      error: "",
      loading: true,
    };
    this.handleTabChange = this.handleTabChange.bind(this);
    this.actionLink = this.actionLink.bind(this);
  }
  async componentDidMount() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const res = await getDonors(orgId, token);
      const res2 = await getDonorsCardData(orgId, "", token);
      const res3 = await getDonationsByCategory(orgId, token);
      this.props.setDonors(res.data.body.data);
      this.setState({
        donors: res.data.body.data,
        donorsCardData: res2.data.body.data,
        donationByCategory: res3.data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error: error && error.response && error.response.data.message,
        loading: false,
      });
      setTimeout(() => {
        this.setState({error: false})
      }, 8000);
    }
  }
  actionLink = (data) => {
    const obj = {
      receiptNumber: data[1].value,
      receiptUrl: data[1].url,
      name: data[2].value,
      amount: data[4].value,
      frequency: data[6].value,
      category: data[5].value,
      status: data[7].value,
      stream: data[2].stream,
      email: data[3].email,
      donation_date: data[4].donation_date,
      note: data[5].note,
      address: data[5].address,
    };
    localStorage.setItem("Donation Details", JSON.stringify(obj));
    this.props.history.replace("/deenfund/donations/details");
  };
  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };
  render() {
    let rows = [];
    let freqRows = [];
    let monthlyRows = [];
    let byCategoryRows = [];
    this.onClick = (x, y) => {
      this.setState({
        tabIndex: y,
      });
    };
    const { donors, tabIndex, donationByCategory } = this.state;
    if (Array.isArray(donors)) {
      rows = donors.map((donor, i) => {
        return [
          { type: "id", value: i + 1 },
          {
            type: "link",
            name: "invoice_number",
            value: donor.receipt_number ?  donor.receipt_number.replace("#", "") : "" || "N/A",
            url: donor.receipt_number ? donor.receipt_url : null,
          },
          {
            type: "string",
            name: "name",
            value: donor.fullName || "N/A",
            stream: donor.stream,
          },
          {
            type: "string",
            name: "date",
            value: donor.createdAt || "N/A",
            email: donor.email,
          },
          {
            type: "string",
            name: "name",
            value: "$" + donor.amount / 100 || "N/A",
            donation_date: donor.createdAt,
          },
          {
            type: "string",
            name: "category",
            value: donor.category || "N/A",
            note: donor.memoNote,
            address: donor.billing_details.address,
          },
          {
            type: "string",
            name: "category",
            value:
              donor.frequency[0].toUpperCase() + donor.frequency.slice(1) ||
              "N/A",
          },
          {
            type: "status",
            status:
              donor?.status?.toLowerCase() === "succeeded" ? "success" : "failed",
            value:
              donor?.status?.toLowerCase() === "succeeded"
                ? "Success"
                : donor.status?.[0]?.toUpperCase() + donor.status?.slice(1),
          },
          { type: "action", status: "details", value: "see details" },
        ];
      });
      byCategoryRows = donationByCategory.map((freqmonthly, i) => {
        return [
          { type: "id", value: i + 1 },
          {
            type: "string",
            name: "category",
            value: freqmonthly.categoryName || "N/A",
          },
          {
            type: "string",
            name: "name",
            value: "$" + freqmonthly.amount / 100 || "N/A",
          },
          // {
          //   type: "status",
          //   status:
          //     freqmonthly?.status?.toLowerCase() === "active"
          //       ? "success"
          //       : "failed",
          //   value: freqmonthly?.status?.[0]?.toUpperCase() + freqmonthly?.status?.slice(1),
          //     freqmonthly.status.slice(1),
          //     freqmonthly.status.toLowerCase() === "Active"
          //       ? "Success"
          //       : freqmonthly.status[0].toUpperCase() +
          //       freqmonthly.status.slice(1),
          // },
        ];
      });
    }
    if (Array.isArray(donors)) {
      const oneTime = donors.filter((freq) => freq.frequency === "one-time");
      const recurring = donors.filter(
        (freq) => freq?.frequency?.toLowerCase() === "monthly"
      );
      oneTime.map((freq, i) => {
        return freqRows.push([
          { type: "id", value: i + 1 },
          {
            type: "link",
            name: "invoice_number",
            value: freq.receipt_number || "N/A",
            url: freq.receipt_number ? freq.receipt_url : null,
          },
          {
            type: "string",
            name: "name",
            value: freq.fullName || "N/A",

            stream: freq.stream,
          },
          {
            type: "string",
            name: "date",
            value: freq.createdAt || "N/A",

            email: freq.email,
          },
          {
            type: "string",
            name: "name",
            value: "$" + freq.amount / 100 || "N/A",
            donation_date: freq.createdAt,
          },
          {
            type: "string",
            name: "category",
            value: freq.category || "N/A",
            note: freq.memoNote,
            address: freq.billing_details.address,
          },
          {
            type: "string",
            name: "category",
            value:
              freq.frequency[0].toUpperCase() + freq.frequency.slice(1) ||
              "N/A",
          },
          {
            type: "status",
            status:
              freq?.status?.toLowerCase() === "succeeded" ? "success" : "failed",
            value:
              freq?.status?.toLowerCase() === "succeeded"
                ? "Success"
                : freq.status[0].toUpperCase() + freq.status.slice(1),
          },
          { type: "action", status: "details", value: "see details" },
        ]);
      });
      recurring.map((freq, i) => {
        return monthlyRows.push([
          { type: "id", value: i + 1 },
          {
            type: "link",
            name: "invoice_number",
            value: freq.receipt_number || "N/A",
            url: freq.receipt_number ? freq.receipt_url : null,
          },
          {
            type: "string",
            name: "name",
            value: freq.fullName || "N/A",
            stream: freq.stream,
          },
          {
            type: "string",
            name: "date",
            value: freq.createdAt || "N/A",
            email: freq.email,
          },
          {
            type: "string",
            name: "name",
            value: "$" + freq.amount / 100 || "N/A",
            donation_date: freq.createdAt,
          },
          {
            type: "string",
            name: "category",
            value: freq.category || "N/A",
            note: freq.memoNote,
            address: freq.billing_details.address,
          },
          {
            type: "string",
            name: "category",
            value:
              freq.frequency[0].toUpperCase() + freq.frequency.slice(1) ||
              "N/A",
          },
          {
            type: "status",
            status:
              freq?.status?.toLowerCase() === "succeeded" ? "success" : "failed",
            value:
              freq?.status?.toLowerCase() === "succeeded"
                ? "Success"
                : freq.status[0].toUpperCase() + freq.status.slice(1),
          },
          { type: "action", status: "details", value: "see details" },
        ]);
      });
    }
    let classProps = () => {
      const tempArray = [];
      columns.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    const obj = [
      {
        label: "All",
        item: "",
      },
      {
        label: "One-Time",
        item: "",
      },
      {
        label: "Recurring",
        item: "",
      },
      {
        label: "By Categories",
        item: "",
      },
    ];
    const columns = [
      { id: "NO.", label: "No.", width: "auto" },
      { id: "RECEIPT", label: "RECEIPT", width: "20%" },
      { id: "DONOR NAME", label: "DONOR NAME", width: "24%" },
      { id: "DATE", label: "DATE", width: "16%" },
      { id: "AMOUNT", label: "AMOUNT", width: "16%" },
      { id: "CATEGORY", label: "CATEGORY", width: "16%" },
      { id: "FREQUENCY", label: "FREQUENCY", width: "16%" },
      { id: "STATUS", label: "STATUS", width: "15%" },
      { id: "ACTION", label: "ACTION", width: "15%" },
    ];
    const byCategoryColumns = [
      { id: "NO.", label: "No.", width: "auto", },
      { id: "CATEGORY", label: "CATEGORY", width: "80%" },
      { id: "AMOUNT", label: "TOTAL COLLECTED", width: "20%" },
      // { id: "STATUS", label: "STATUS", width: "20%" },
      // { id: "STATUS", label: "", width: "5%" },
    ];
    const contentNext = (
      <>
        <SimpleTable
          showHeader={true}
          showCol={false}
          title="Donations"
          rows={rows}
          columns={columns}
          colSpan={16}
          actionSendButton={true}
          classProps={changeClassProps()}
          donationsCount={rows.length < 0 ? 0 : rows.length}
          noTitle="No recent donations"
          noPara="Add donation categories so that people can donate for a specific cause."
          noBtnText="Invite Donors"
          showFooter={true}
          actionLink={(data) => this.actionLink(data)}

          iconTextSize={16}
          rowsPerPage={5}
          // sortData={(e) => sortData(e)}
          data={this.state.data}
          sortColumnIndex={[7]}
        />
      </>
    );
    const contentNextOneTime = (
      <>
        <SimpleTable
          showHeader={true}
          title="Donations"
          rows={freqRows}
          columns={columns}
          colSpan={16}
          actionSendButton={true}
          classProps={changeClassProps()}
          donationsCount={freqRows.length < 0 ? 0 : freqRows.length}
          noTitle="No recent donations"
          noPara="Add donation categories so that people can donate for a specific cause."
          noBtnText="Invite Donors"
          showFooter={true}
          actionLink={(data) => this.actionLink(data)}
          iconTextSize={16}
          rowsPerPage={5}
          // sortData={(e) => sortData(e)}
          data={this.state.data}
          sortColumnIndex={[7]}
        />
      </>
    );
    const contentNextMonthly = (
      <SimpleTable
        showHeader={true}
        title="Donations"
        rows={monthlyRows}
        columns={columns}
        colSpan={16}
        actionSendButton={true}
        classProps={changeClassProps()}
        donationsCount={monthlyRows.length < 0 ? 0 : monthlyRows.length}
        noTitle="No recent donations"
        noPara="Add donation categories so that people can donate for a specific cause."
        noBtnText="Invite Donors"
        showFooter={true}
        actionLink={(data) => this.actionLink(data)}
        iconTextSize={16}
          rowsPerPage={5}
          // sortData={(e) => sortData(e)}
          data={this.state.data}
          sortColumnIndex={[7]}
      />
    );
    const contentNextByCategory = (
      <>
        <SimpleTable
          showHeader={true}
          title="Donations"
          rows={byCategoryRows}
          columns={byCategoryColumns}
          colSpan={16}
          actionSendButton={true}
          classProps={changeClassProps()}
          donationsCount={byCategoryRows.length < 0 ? 0 : byCategoryRows.length}
          noTitle="No recent donations"
          noPara="Add donation categories so that people can donate for a specific cause."
          noBtnText="Invite Donors"
          showFooter={true}
          iconTextSize={16}
          rowsPerPage={5}
          // sortData={(e) => sortData(e)}
          data={this.state.data}
          sortColumnIndex={[3]}
        />
      </>
    );
    const { error, activeTab, loading, donorsCardData } = this.state;
    const style = {
      width: '100%',
    };

    return (
      <React.Fragment>
        <MasterLayoutMiddle
          content={
            <div>
              {error && error === "Token is invalid or expired" && (
                <div
                  style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
                >
                  <AlertAction
                    alert={token_expired}
                    alertType={"error"}
                    onClick={() => this.setState({ error: false })}
                  />
                </div>
              )}
              <GridContainer>
                <GridItem>
                  <div style={{ boxShadow: "0px 2px 4px #0000001A" }}>
                    <CardData
                      data={[
                        {
                          title: `Donations - ${moment().format("MMM YYYY")}`,
                          donation: donorsCardData && donorsCardData.card1 && donorsCardData.card1.data ? "$" + (donorsCardData.card1.data / 100).toFixed(2) : 0,
                          fromLastMonth: donorsCardData && donorsCardData.card1 && donorsCardData.card1.percentage ? donorsCardData.card1.percentage: 0,
                          increased: donorsCardData && donorsCardData.card1 && donorsCardData.card1.percentage > 0 ? true : false,
                        },
                        {
                          title: `New Donors - ${moment().format("MMM YYYY")}`,
                          donation: donorsCardData && donorsCardData.card2 && donorsCardData.card2.data ? donorsCardData.card2.data : 0,
                          fromLastMonth: donorsCardData && donorsCardData.card2 && donorsCardData.card2.percentage ? donorsCardData.card2.percentage : 0,
                          increased: donorsCardData && donorsCardData.card2 && donorsCardData.card2.percentage > 0 ? true : false,
                        },
                        {
                          title: "Total Recurring Donors",
                          donation: donorsCardData && donorsCardData.card3 && donorsCardData.card3.data ? donorsCardData.card3.data : 0,
                          fromLastMonth: donorsCardData && donorsCardData.card3 && donorsCardData.card3.percentage ? donorsCardData.card3.percentage : 0,
                          increased: donorsCardData && donorsCardData.card3 && donorsCardData.card3.percentage > 0 ? true : false,
                        },
                        {
                          title: `Total Donations - ${moment().format("YYYY")}`,
                          donation: donorsCardData && donorsCardData.card4 && donorsCardData.card4.data ? "$" + (donorsCardData.card4.data / 100).toFixed(2)  : 0,
                          fromLastMonth: donorsCardData && donorsCardData.card4 && donorsCardData.card4.percentage ? donorsCardData.card4.percentage : 0,
                          increased: donorsCardData && donorsCardData.card4 && donorsCardData.card4.percentage > 0 ? true : false,
                          fromLastYear: true,
                        },
                      ]}
                    />
                  </div>
                </GridItem>
                <GridItem>
                  <BaseTabs
                    title=""
                    object={obj}
                    onClick={(x, y) => this.onClick(x, y)}
                    value={activeTab}
                    onChange={(event, newValue) =>
                      this.handleTabChange(event, newValue)
                    }
                  />
                </GridItem>
                {tabIndex === 0 &&
                  (loading ? (
                    <div
                      style={{
                        marginTop: 60,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                      <GridItem itemClass="grid-item">
                        <BasePaper
                          context={contentNext}
                          square={false}
                          paperStyle={styles.paperStyleNext}
                        />
                      </GridItem>
                    ))}
                {tabIndex === 1 && (
                  <GridItem itemClass="grid-item">
                    <BasePaper
                      context={contentNextOneTime}
                      square={false}
                      paperStyle={styles.paperStyleNext}
                    />
                  </GridItem>
                )}
                {tabIndex === 2 && (
                  <GridItem itemClass="grid-item">
                    <BasePaper
                      context={contentNextMonthly}
                      square={false}
                      paperStyle={styles.paperStyleNext}
                    />
                  </GridItem>
                )}
                {tabIndex === 3 && (
                  <GridItem itemClass="grid-item">
                    <BasePaper
                      context={contentNextByCategory}
                      square={false}
                      paperStyle={styles.paperStyleNext}
                    />
                  </GridItem>
                )}
              </GridContainer>
            </div>
          }
          isFooter={true}
          footer={
            <FooterInner 
              style={style} 
              termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
              privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
            />
          }
          isWhiteBackground={false}
        />
      </React.Fragment>
    );
  }
}
const styles = {
  paperStyle: {
    border: "none",
  },
  paperStyleNext: {
    border: "none",
  },
};
const mapStateToProps = (state) => ({
  donors: state.donors,
});
const mapDispatchToProps = (dispatch) => ({
  setDonors: (res) => dispatch(setAllDonors(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DonorCollection);
