import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import { GridContainer, GridItem, AlertAction, SimpleTable, EmptyTable } from "@dclab/dc-react-ui-kit";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from 'react-redux'
import Handle from "../../assets/img/more_vert-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import Edit from '../../assets/img/create-24px.svg'
import { getEmbedForm } from './Apis'
import CircularProgress from "@material-ui/core/CircularProgress";
import { saveAsDraft } from './Apis'
import { hideAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
import Button from '@material-ui/core/Button';

const {
  token_expired,
  Your_changes_has_been_saved_as_draft,
  remove_faq_message,
  Your_changes_has_been_published,
  success_message_edit_faq,
  success_message_faq
} = messages_content;
const Faqs = forwardRef((props, ref) => {
  const dispatch = useDispatch()
  const history = props.history
  const embedform_draft = props?.embed_data?.enabledIntegrations?.deenfund

  // const embedform_draft = props?.embed_data?.embed_form_draft?.[0]
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  const [error, setError] = useState(false)
  const [showAlert, setshowAlert] = useState(false)
  const [faqs, setFaqs] = useState([])
  const [loading, setLoading] = useState(true)
  const [success, setsuccess] = useState(false);
  const [faq, setTempFaq] = useState(false);
  const [modalLoading, setmodalLoading] = useState(false)
const [openAlert, setopenAlert] = useState(false)
const [deleteFaqId, setdeleteFaqId] = useState("")
  useEffect(() => {
    const orgId = localStorage.getItem('orgID')
    const token = localStorage.getItem('authToken')
    async function getfaqs() {
      try {
        const faqs = await getEmbedForm(orgId, token)
        console.log("faqs", faqs)
        const faqs_data = faqs?.data?.body?.data[0]?.enabledIntegrations?.deenfund?.embedFormDraft[0]?.setup?.faqs
        setTempFaq(faqs_data)
        const rows = faqs_data.map((item, index) => {
          return [
            { type: "id", value: `${index <= 8 ? '0' + (index + 1) : index + 1}`, name: item._id },
            { type: "string", name: "name", value: item.question },
            { type: "string", name: "name", value: item?.answer?.length > 40 ? item.answer.substring(0, 40) + "..." : item.answer },
          ]
        })
        setFaqs(rows)
        setLoading(false)
      }
      catch (error) {
        setError(error && error.response && error.response.data.message)
        setLoading(false)
        setTimeout(() => {
          setError(false)
        }, 8000)
      }
    }
    getfaqs()
    setTimeout(() => {
      dispatch(hideAlert())
    }, 8000)
  }, [])
  useImperativeHandle(ref, () => ({
    async FaqsDraft(param) {
      const orgId = localStorage.getItem('orgID')
      const token = localStorage.getItem('authToken')
      const formdata = {
        enabledIntegrations: formData(param),
      }
      param === 'publish' ? publish(orgId, formdata, token) : draftChanges(orgId, formdata, token)
    }
  }));
  function formData(param) {
    if(param === 'publish'){
      return [{
        donationFormType: embedform_draft?.embedFormDraft?.[0]?.donationFormType,
        preselectAmounts: embedform_draft?.embedFormDraft?.[0]?.preselectAmounts,
        donationMinimumAmount: embedform_draft?.embedFormDraft?.[0]?.donationMinimumAmount,
        donationMinimumAmountEnabled: embedform_draft?.embedFormDraft?.[0]?.donationMinimumAmountEnabled,
        processingFeeEnabled: embedform_draft?.embedFormDraft?.[0]?.processingFeeEnabled,
        paymentMethod: embedform_draft?.embedFormDraft?.[0]?.paymentMethod,//release 2 will have two donations values

        donation_categories: embedform_draft?.embedFormDraft?.[0]?.donation_categories,
        formTitle: embedform_draft?.embedFormDraft?.[0]?.formTitle,
        formThemeColor: embedform_draft?.embedFormDraft?.[0]?.formThemeColor,
        formLink: embedform_draft?.embedFormDraft?.[0]?.formLink,
        formHeaderButton: embedform_draft?.embedFormDraft?.[0]?.formHeaderButton,
        setup: {
          footer: embedform_draft?.embedFormDraft?.[0]?.setup?.footer,
          rightSide: embedform_draft?.embedFormDraft?.[0]?.setup?.rightSide,
          imageOrVideo: embedform_draft?.embedFormDraft?.[0]?.setup?.imageOrVideo,
          content: embedform_draft?.embedFormDraft?.[0]?.setup?.content,
          faqs: faq
        },
        embedButton: embedform_draft?.embedFormDraft?.[0]?.embedButton,
        websiteName: embedform_draft?.embedFormDraft?.[0]?.websiteName,
}]
    } else {
      return [{
        donationFormType: embedform_draft?.embedFormDraft?.[0]?.donationFormType,
        preselectAmounts: embedform_draft?.embedFormDraft?.[0]?.preselectAmounts,
        donationMinimumAmount: embedform_draft?.embedFormDraft?.[0]?.donationMinimumAmount,
        donationMinimumAmountEnabled: embedform_draft?.embedFormDraft?.[0]?.donationMinimumAmountEnabled,
        processingFeeEnabled: embedform_draft?.embedFormDraft?.[0]?.processingFeeEnabled,
        paymentMethod: embedform_draft?.embedFormDraft?.[0]?.paymentMethod,//release 2 will have two donations values

        donation_categories: embedform_draft?.embedFormDraft?.[0]?.donation_categories,
        formTitle: embedform_draft?.embedFormDraft?.[0]?.formTitle,
        formThemeColor: embedform_draft?.embedFormDraft?.[0]?.formThemeColor,
        formLink: embedform_draft?.embedFormDraft?.[0]?.formLink,
        formHeaderButton: embedform_draft?.embedFormDraft?.[0]?.formHeaderButton,
        setup: {
          footer: embedform_draft?.embedFormDraft?.[0]?.setup?.footer,
          rightSide: embedform_draft?.embedFormDraft?.[0]?.setup?.rightSide,
          imageOrVideo: embedform_draft?.embedFormDraft?.[0]?.setup?.imageOrVideo,
          content: embedform_draft?.embedFormDraft?.[0]?.setup?.content,
          faqs: faq
        },
        embedButton: embedform_draft?.embedFormDraft?.[0]?.embedButton,
        websiteName: embedform_draft?.embedFormDraft?.[0]?.websiteName,
        changes: true
}]
    }
  }
  async function publish(orgId, formdata, token) {
    let formData2 = {
      'enabledIntegrations.deenfund.embedForm': formdata.enabledIntegrations,
      'enabledIntegrations.deenfund.embedFormDraft': formdata.enabledIntegrations
    }
    props.publishChanges(false)
    try {
      await saveAsDraft(orgId, formData2, token)
      setshowAlert(true)
      setsuccess('Publish')
      props.hideAlert()
      props.checkChanges(false)
      setTimeout(() => {
        setshowAlert(false)
        setsuccess(false)
      }, 8000)
    }
    catch (error) {
      setError(error.response && error.response.data.message)
      props.hideAlert()
      setTimeout(() => {
        setError(false)
      }, 8000)
    }
  }
  async function draftChanges(orgId, formdata, token) {
    console.log("formdata", formdata)

    let formData2 = {
      'enabledIntegrations.deenfund.embedForm': formdata.enabledIntegrations,
      'enabledIntegrations.deenfund.embedFormDraft': formdata.enabledIntegrations
    }

    console.log("formData2", formData2)

    try {
      await saveAsDraft(orgId, formData2, token)
      setshowAlert(true)
      setsuccess(true)
      props.hideAlert()
      props.checkChanges(false)
      setTimeout(() => {
        setshowAlert(false)
        setsuccess(false)
      }, 8000)
    }
    catch (error) {
      setError(error.response && error.response.data.message)
      props.hideAlert()
      setTimeout(() => {
        setError(false)
      }, 8000)
    }
  }
  const updateCategories = (data) => {
    const sort_faqs = data.sort((a, b) => a.order - b.order).map((item, index) => {
      return [
        { type: "id", value: index + 1 },
        { type: "string", name: "name", value: item.question },
        { type: "string", name: "name", value: item?.answer?.length > 40 ? item.answer.substring(0, 40) + "..." : item.answer },
      ]
    })
    const set_faqs = data.sort((a, b) => a.order - b.order)
    setFaqs(sort_faqs)
    setTempFaq(set_faqs)
    props.checkChanges(true)
  }
  const deleteFaq = (e) => {
    console.log("deleteFaq", e)
    const _id = e[0]?.name
    if(_id) {
      setopenAlert(true)
      setdeleteFaqId(_id)
    }
  }
  const edit = (e) => {
    const _id = e[0]?.name
    history.push(`/deenfund/donation-form/edit-faq/${_id}`)
  }
  let col = [
    { id: "drag", label: " ", width: "50px" },
    { type: "number", name: "serial_number", label: "NO.", width: "auto" },
    { type: "link", name: "invoice_number", label: "Title", width: "45%" },
    { type: "link", name: "details", label: "Details", width: "48%", },
    { type: "action", name: "action", label: "ACTION", width: "15%" },
  ];
  const alertmessage = useSelector(state => state.alert.alertValue)
  const handleConfirm = async () => {
    setmodalLoading(true)
    let _id = deleteFaqId;
    const filtered_faqs = faq.filter(item => item._id !== _id)
    const new_rows = filtered_faqs.map((item, index) => {
      return [
        { type: "id", value: index + 1, name: item._id },
        { type: "string", name: "name", value: item.question },
        {
          type: "string", name: "name", value: item?.answer?.length > 40 ? item.answer.substring(0, 40) + "..." : item.answer
        }
      ]
    })
    setFaqs(new_rows)
    setTempFaq(filtered_faqs)
    props.checkChanges(true)
    setError('Delete Successfully.');
    setmodalLoading(false)
    setopenAlert(false)
    setTimeout(() => {
      setError(false);
    }, 8000);
  }
  const handleClose = () => {
    setopenAlert(false)
    setdeleteFaqId("")
  };
  let alertModal = (
    <Dialog
      open={openAlert}
      onClose={() => handleClose()}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          Do you want to delete this faq? This can't be undone.
        </DialogContentText>
      </DialogContent>
      {modalLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 50,
          }}
        >
          <CircularProgress
            color='primary'
            style={{ width: 24, height: 24 }}
          />
        </div>
      ) : (
          <DialogActions>
            <Button onClick={() => handleClose()} color='primary'>Cancel</Button>
            <Button onClick={() => handleConfirm()} color='primary'>Confirm</Button> 
          </DialogActions>
        )}
    </Dialog>
  );
  return (
    <>
      {error && error === "Delete Successfully." && (
        <div
          style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 17 }}
        >
          <AlertAction
            alert={remove_faq_message}
            alertType={"error"}
            onClick={() => setError(false)}
          />
        </div>
      )}
      {error && error === "Token invalid or expired" && (
        <div
          style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 17 }}
        >
          <AlertAction
            alert={token_expired}
            alertType={"error"}
            onClick={() => setError(false)}
          />
        </div>
      )}
      {showAlert && error && (
        <div
          style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 17 }}
        >
          <AlertAction
            alert={
              error === "Token invalid or expired"
                ? token_expired
                : error
            }
            alertType={"error"}
            onClick={() => setshowAlert(false)}
          />
        </div>
      )}
      {
        showAlert && success && success === 'Publish' ? (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 17 }}>
            <AlertAction
              alert={Your_changes_has_been_published}
              alertType={"success"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        ) : showAlert && success && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 17 }}>
            <AlertAction
              alert={Your_changes_has_been_saved_as_draft}
              alertType={"success"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        )
      }
      {!error && alertmessage && alertmessage === 'addfaq' && (
        <div
          style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 17 }}
        >
          <AlertAction
            alert={success_message_faq}
            alertType={"success"}
            onClick={() => dispatch(hideAlert())}
          />
        </div>
      )}
      {!error && alertmessage && alertmessage === 'editfaq' && (
        <div
          style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 17 }}
        >
          <AlertAction
            alert={success_message_edit_faq}
            alertType={"success"}
            onClick={() => dispatch(hideAlert())}
          />
        </div>
      )}
      <GridContainer>
        {loading && <GridItem itemStyle={{ marginTop: 40, paddingBottom: 0 }} xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: 'center',
              height: '50vh'
            }}
          >
            <CircularProgress color="primary" />
          </div>
        </GridItem>}
        {!loading && <GridItem>
          {/* {
            faqs.length ? */}
              <SimpleTable
                showHeader={true}
                title="FAQs"
                rows={faqs}
                columns={col}
                DragIcon={Handle}
                parentTableDrag={true}
                colSpan={16}
                actionSendButton={true}
                classProps={changeClassProps()}
                donationsCount={faqs && faqs.length > 0 ? 1 : 0}
                noTitle="No faq added yet"
                noPara="Add frequently asked questions"
                noBtnText="Add FAQ"
                onInviteClick={() =>
                  history.push("/deenfund/donation-form/add-faq")
                }
                showIconCol={true}
                editIcon={false}
                deleteIcon={Delete}
                editIcon={Edit}
                editAction={(e) => edit(e)}
                deleteAction={(e) => deleteFaq(e)}
                data={faq}
                showFooter={faqs?.length > 10 ? true : false}
                showAdd={true}
                addText="ADD NOW"
                onAddClick={() =>
                  history.push("/deenfund/donation-form/add-faq")
                }
                iconTextSize={16}
                iconTextSize={16}
                updateCategoriesLocal={true}
                sortData={(e) => updateCategories(e)}
                showEditColIcon={false}
                hideEditIcon={false}
                rowsPerPage={10}
              />
              {/* :
              <EmptyTable title={'FAQs'} onAddClick={() => history.push("/deenfund/donation-form/add-faq")} />
          } */}
        </GridItem>}
        {!loading && <GridItem xs={12} itemStyle={{ paddingTop: 13 }}>
          <div style={{ display: "flex", marginTop: 0 }}>
            <p className="cat-lower" style={{ marginTop: 0 }}>
              Change the order of Faqs to display to users. Click on{" "}
            </p>
            <img
              src={Handle}
              alt=""
              style={{ display: "flex", marginTop: -10 }}
            />
            <p className="cat-lower" style={{ marginTop: 0 }}>
              to drag and drop within the table.
          </p>
          </div>
        </GridItem>}
      </GridContainer>
      {openAlert && alertModal}
    </>
  );
})

export default Faqs;
