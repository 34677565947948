import React, { useEffect } from 'react';
import { AlertDialogue, GridContainer, GridItem, SimpleTable, MasterLayoutMiddle, FooterInner, AlertAction, EmptyTable } from '@dclab/dc-react-ui-kit';
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import Handle from "../../assets/images/more_vert-24px.png";
import { getNavMenusWebsite, deleteNavMenusWebsite, deleteSubNavMenusWebsite } from './api';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { hideAlert, showAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
const { token_expired, remove_menu_message } = messages_content;
let tempArray = [];
const columns = [
    { id: 'drag', label: ' ', width: 'auto' },
    { type: 'link', name: 'invoice_number', label: 'Menu', width: '20%' },
    { type: 'string', name: 'date', label: 'SUBMENU', width: '15%' },
    { type: 'string', name: 'date', label: 'LINKS TO', width: '50%' },
    { type: 'string', name: 'date', label: 'TYPE', width: '20%' },
    { type: 'status', name: 'status', label: 'STATUS', width: '22%' },
    { type: 'action', name: 'actionIcons', label: 'ACTION', width: '22%' },
];
function NavigationMane(props) {
    const [openAlert, setOpenAlert] = React.useState(false);
    const [getData, setGetData] = React.useState([])
    const [error, seterror] = React.useState(false);
    const [Loading, setLoading] = React.useState(true);
    const [rows, setRows] = React.useState([])
    const [removeRow, setRemoveRow] = React.useState([]);
    const [removeRowChild, setRemoveRowChild] = React.useState([])
    const [openAlertChild, setOpenAlertChild] = React.useState(false)
    useEffect(() => {
        getNavData();
    }, [])
    useEffect(() => {
        setTimeout(() => {
            props.alertHide()
        }, 8000)
    }, [props.alert.alertValue])
    async function getNavData() {
        const token = localStorage.getItem("authToken");
        try {
            let result = await getNavMenusWebsite(token);
            let data = result.data.body.data
            let sort = data.sort((a, b) => {
                return new Date(b.updatedAt) - new Date(a.updatedAt)
            })
            setGetData(data);
            let structured = []
            sort.map((text) => {
                let array = []
                array = [
                    { type: 'string', name: 'name', value: text.label, _id: text._id },
                    { type: 'string', name: 'collapse', value: text.subMenu.length ? 'Show' : 'Hide' },
                    { type: 'string', name: 'date', value: text.linkDetails.linkUrl },
                    { type: 'string', name: 'date', value: text.linkDetails.linkType ? capitalizeFLetter(text.linkDetails.linkType) : '' },
                    { type: 'status', status: text.enable === 'true' ? 'success' : 'failed', value: text.enable === 'true' ? 'Active' : 'Inactive' },
                    {
                        type: 'additionalData',
                        columns: subMenuColumns,
                        value: text.subMenu.map((t, i) => {
                            return (
                                [
                                    { type: 'string', name: 'menu', value: t.label, _id: t._id, pid: text._id },
                                    { type: 'string', name: 'link', value: t.linkDetails.linkUrl },
                                    { type: 'string', name: 'type', value: capitalizeFLetter(t.linkDetails.linkType) },
                                    { type: 'status', status: t.enable === 'true' ? 'success' : 'failed', value: t.enable === 'true' ? 'Active' : 'Inactive' },
                                    { type: 'action' },
                                ]
                            )
                        })
                    },

                ]
                structured.push(array)
            })
            setRows(structured)
            setLoading(false)
        } catch (err) {
            seterror(err.response && err.response.data.message)
            setLoading(false)
            setTimeout(() => {
                seterror(false)
            }, 4000);
        }
    }
    let classProps = () => {
        columns.forEach((column, index) => {
            tempArray.push(`class${index}`);
        });
        return tempArray;
    };
    function changeClassProps() {
        tempArray = classProps();
        tempArray[0] = 'class15';
        return tempArray;
    };
    const columns1 = (columns) =>
        columns.map((obj) => {
            return obj;
        });
    const subMenuColumns = [
        { id: 'drag', label: ' ', width: 'auto' },
        { type: 'string', name: 'amount', label: 'MENU', width: '20%' },
        { type: 'string', name: 'category', label: 'LINKS TO', width: '20%' },
        { type: 'string', name: 'frequency', label: 'TYPE', width: '50%' },
        { type: 'url', name: 'single_pic', label: 'STATUS', width: '15%' },
        { type: 'action', name: 'actionIcons', label: 'ACTION', width: '15%' },
    ];
    const click = (msg) => {
        console.log(msg, 'click');
    };
    const btnClick = () => {
        console.log('invite');
    };
    const editAction = (edit) => {
        let RowEdit = getData.filter(text => text._id === edit[0]._id);
        props.history.push({
            pathname: `/website/edit-navigation-menu/${edit[0]._id}`,
            state: RowEdit
        }
        )
    };
    const editActionChild = (editChild) => {
        let RowEdit = getData.filter(text => text._id === editChild[0].pid);
        let child = RowEdit[0].subMenu.filter(text => text._id === editChild[0]._id)
        props.history.push({
            pathname: `/website/edit-navigation-menu/${child[0]._id}`,
            state: RowEdit
        }
        )
    }
    const deleteAction = (deleteData) => {
        setOpenAlert(true);
        setRemoveRow(deleteData);
    };
    const deleteActionChild = (deleteData) => {
        setOpenAlertChild(true);
        setRemoveRowChild(deleteData);
    };
    const actionLink = (name) => {
        console.log(name, 'nameaction');
    };
    const handleCancel = () => {
        setOpenAlert(false);
    }
    const handleClose = () => {
        setOpenAlert(false)
    };
    const handleCancelChild = () => {
        setOpenAlertChild(false);
    }
    const handleCloseChild = () => {
        setOpenAlertChild(false)
    };
    const handleConfirmChild = async () => {
        setOpenAlertChild(false)
        setLoading(true)
        const token = localStorage.getItem("authToken");
        try {
            let res = await deleteSubNavMenusWebsite(token, removeRowChild[0].pid, removeRowChild[0]._id);
            if (res.status === 200) {
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            }
        } catch (err) {
            seterror(err.response && err.response.data.message)
            setLoading(false)
            setTimeout(() => {
                seterror(false)
            }, 4000);
        }
    };
    const handleConfirm = async () => {
        setOpenAlert(false)
        setLoading(true)
        const token = localStorage.getItem("authToken");
        try {
            let res = await deleteNavMenusWebsite(token, removeRow[0]._id);
            props.alertShow(remove_menu_message)
            if (res.status === 200) {
                window.location.reload();
            }
        } catch (err) {
            seterror(err.response && err.response.data.message)
            setLoading(false)
            setTimeout(() => {
                seterror(false)
            }, 4000);
        }
    };
    const style = {
        width: 470,
        marginLeft: 800
    };
    const capitalizeFLetter = (val) => {
        return val ? `${val[0].toUpperCase() + val.slice(1)}` : "";
    }
    const sortData = (data) => {
        let sortD = data.sort((a, b) => a.order - b.order)
        let structured = []
        sortD.map((text) => {
            let array = []
            array = [
                { type: 'string', name: 'name', value: text.label, _id: text._id },
                { type: 'string', name: 'collapse', value: text.subMenu.length ? 'Show' : 'Hide' },
                { type: 'string', name: 'date', value: text.linkDetails.linkUrl },
                { type: 'string', name: 'date', value: text.linkDetails.linkType ? capitalizeFLetter(text.linkDetails.linkType) : '' },
                { type: 'status', status: text.enable === 'true' ? 'success' : 'failed', value: text.enable === 'true' ? 'Active' : 'Inactive' },
                {
                    type: 'additionalData',
                    columns: subMenuColumns,
                    value: text.subMenu.map((t, i) => {
                        return (
                            [
                                { type: 'string', name: 'menu', value: t.label, _id: t._id, pid: text._id },
                                { type: 'string', name: 'link', value: t.link },
                                { type: 'string', name: 'type', value: capitalizeFLetter(t.linkType) },
                                { type: 'string', status: t.enable === 'true' ? 'success' : 'failed', value: t.enable === 'true' ? 'Active' : 'Inactive' },
                                { type: 'action' },
                            ]
                        )
                    })
                },
            ]
            structured.push(array)
        })
        setRows(structured)
    }
    return (
        <MasterLayoutMiddle
            content={
                Loading ?
                    <div
                        style={{
                            marginTop: 30,
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress color="primary" />
                    </div>
                    :
                    <>
                        {
                            props.alert.alertValue &&
                            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, left: '30%' }}>
                                <AlertAction
                                    alert={props.alert.alertValue}
                                    alertType={"success"}
                                    onClick={() => props.alertHide()}
                                />
                            </div>
                        }
                        {
                            error && error === "Invalid token" && (
                                <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, left: '30%' }}>
                                    <AlertAction
                                        alert={token_expired}
                                        alertType={"error"}
                                        onClick={() => seterror(false)}
                                    />
                                </div>
                            )
                        }
                        <div>
                            <GridContainer>
                                {
                                    rows.length ?
                                        <>
                                            <GridItem
                                                xs={12}
                                                xl={12}
                                            >
                                                <SimpleTable
                                                    showHeader={true}
                                                    showCol={true}
                                                    parentTableDrag={true}
                                                    title='Navigation Menu'
                                                    rows={rows.length > 0 ? rows : []}
                                                    columns={columns1(columns)}
                                                    colSpan={6}
                                                    actionSendButton={true}
                                                    classProps={changeClassProps()}
                                                    onClick={(message, index) => click(message)}
                                                    onAddClick={() => props.history.push("/website/add-navigation-menu")}
                                                    showFilter={false}
                                                    DragIcon={Handle}
                                                    filterText='Filter'
                                                    showAdd={true}
                                                    showSend={false}
                                                    sendText={'ADD Now'}
                                                    addText='Add'
                                                    donationsCount={rows?.length > 0 ? 1 : 0}
                                                    noTitle="No Menu added"
                                                    noPara="Added Menu will be displayed and managed here"
                                                    noBtnText="Add New Menu"
                                                    onInviteClick={() => btnClick()}
                                                    editIcon={Edit}
                                                    deleteIcon={Delete}
                                                    editIconChild={Edit}
                                                    deleteIconChild={Delete}
                                                    showIconCol={true}
                                                    editAction={(edit) => editAction(edit)}
                                                    editActionChild={(edit) => editActionChild(edit)}
                                                    deleteAction={(deleteItem) => deleteAction(deleteItem)}
                                                    deleteActionChild={(deleteItem) => deleteActionChild(deleteItem)}
                                                    showFooter={true}
                                                    actionLink={(name) => actionLink(name)}
                                                    iconTextSize={14}
                                                    rowsPerPage={7}
                                                    manageDevice={(e) => console.log(e)}
                                                    connectNow={(e) => console.log(e)}
                                                    sortData={(e) => sortData(e)}
                                                    data={getData}
                                                />

                                                <div style={{ display: "flex", marginTop: 10, color: '#818E94' }}>
                                                    <p className="cat-lower" style={{ marginTop: 0 }}>
                                                        Change the order of Header Menus to display to users. Click on{" "}
                                                    </p>
                                                    <img src={Handle} alt="" className="des_handle_icon" />
                                                    <p className="cat-lower" style={{ marginTop: 0 }}>to drag and drop within the table.</p>
                                                </div>
                                            </GridItem>

                                        </>
                                        :
                                        <EmptyTable
                                            title='Navigation Menu'
                                            onAddClick={() => props.history.push("/website/add-navigation-menu")}
                                            addLabel="ADD"
                                            noBtnText="ADD"
                                        />
                                }
                            </GridContainer>
                            <div>
                                <AlertDialogue
                                    title="Are you sure?"
                                    description="Are you sure you want delete this page? Website may loose some features."
                                    cancelText="Cancel"
                                    confirmText="Confirm"
                                    open={openAlert}
                                    handleCancel={() => handleCancel()}
                                    handleClose={() => handleClose()}
                                    handleConfirm={() => handleConfirm()}
                                    styles={style}
                                />
                            </div>
                            <div>
                                <AlertDialogue
                                    title="Are you sure?"
                                    description="Are you sure you want delete this page? Website may loose some features."
                                    cancelText="Cancel"
                                    confirmText="Confirm"
                                    open={openAlertChild}
                                    handleCancel={() => handleCancelChild()}
                                    handleClose={() => handleCloseChild()}
                                    handleConfirm={() => handleConfirmChild()}
                                    styles={style}
                                />
                            </div>
                        </div>
                    </>
            }
            isFooter={true}
            footer={
                <FooterInner
                    style={style}
                    termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
                    privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                />
            }
            isWhiteBackground={false}
        />
    )
}

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
    };
};
const mapDispatchToProps = (dispatch) => ({
    alertHide: (res) => dispatch(hideAlert(res)),
    alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NavigationMane);
const styles = {
    contents: {
        padding: 24,
        paddingBottom: 0,
        display: "flex",
        flexDirection: "column",
        background: "#FFF",
        width: "76%",
        marginTop: 70,
        marginLeft: "20%",
    },
}