import axios from "axios";

const baserUrl =
  "https://cors-anywhere.herokuapp.com/http://18.211.207.245:3000";
const env = {
  prod: process.env.REACT_APP_PAYMENT_PROD_API,
  dev: process.env.REACT_APP_PAYMENT_DEV_API
};
const orgEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")
) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}
// console.log( orgEnv );

const orgBaseUrl = orgEnv[currentEnv];
console.log(orgBaseUrl);
export function signinHandler(credentials,code) {
  
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/auth/authenticate`, credentials, {
        headers: {
          "Content-Type": "application/json",
          "token":code

        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
        console.log(error);
      });
  });
}
export function forgotPasswordHandler(userName) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${orgBaseUrl}/auth/forgotPassword`, userName, {
          headers: {
            "Content-Type": "application/json",
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

export function verifyHandler(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/auth/verify`, credentials, {
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function resetPasswordHandler(credentials) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${orgBaseUrl}/auth/resetPassword`, credentials, {
          headers: {
            "Content-Type": "application/json",
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }