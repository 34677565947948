import React, { Component } from "react";
import { connect } from "react-redux";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Moment from "moment-timezone";
import PublicIcon from "@material-ui/icons/Public";
import FastFoodIcon from "@material-ui/icons/FastfoodOutlined";
import NoFoodIcon from "@material-ui/icons/CancelOutlined";
import PotluckIcon from "@material-ui/icons/AccessibilityNewOutlined";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import { ReactComponent as Invoice } from "../../../assets/nav_icons/in_voice.svg";
import { ReactComponent as Invoice_blue } from "../../../assets/nav_icons/in_voice_blue.svg";
import { ReactComponent as Private_blue } from "../../../assets/nav_icons/private-blue.svg";
import { ReactComponent as Private_grey } from "../../../assets/nav_icons/private-grey.svg";
import { countries } from "../../../utils/countries";
import { states } from "../../../utils/states";
import { stateAbbreviations } from '../../../utils/statesAbbreviations';
import addCircle from "../../../assets/nav_icons/add_circle.svg";
import Delete from "../../../assets/img/delete_forever-24px.svg";

import Zoom from "../../../assets/img/Socialicon/zoom-icon.svg";
import Facebook from "../../../assets/img/Socialicon/facebook-icon.svg";
import Youtube from "../../../assets/img/Socialicon/youtube-icon.svg";
import Teleconference from "../../../assets/img/Socialicon/Teleconference-icon.svg";



import {
  BaseTextField,
  BaseButton,
  BaseSwitch,
  AlertAction,
  BaseFileSelect,
  DrawerListCheckbox,
  DrawerDateTime,
  DrawerCardMidAvatar,
  DrawerCardSmall,
  DrawerMain,
  BaseImgChip,
  BaseCheckBox,
  BaseSelect,
  MasterLayoutMiddle,
  FooterInner,
  FormSwitch,
  TextEditor,
  BaseStepper,
  Repeat
  // MediaUploader
} from "@dclab/dc-react-ui-kit";
// import MediaUpload from '../../../components/MediaUpload/MediaUpload.js';
import CircularProgress from "@material-ui/core/CircularProgress";
import "./style.css";
import "../../Organization/style.css";
import InputAdornment from "@material-ui/core/InputAdornment";
import ChevronRight from "@material-ui/icons/ChevronRight";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { showAlert } from "../../../redux/actions/alertActions";
import { getSpeakers, addNewEvent } from "./Apis/index";
import messages_content from '../../../utils/messages.json';
import { TextField } from "@material-ui/core";
import MediaUpload from "../../../components/MediaUpload/MediaUpload";
import { Link } from "react-router-dom";

const {
  token_expired,
  repeat_date_between_start_end,
  event_title_already_exist
} = messages_content;
const refreshmentFood = [
  {
    id: 1,
    primary: "Light Refreshment",
  },
  {
    id: 2,
    primary: "Breakfast",
  },
  {
    id: 3,
    primary: "Brunch",
  },
  {
    id: 4,
    primary: "Lunch",
  },
  {
    id: 5,
    primary: "Dinner",
  },
];
const styles = {
  btn: {
    height: 70,
    width: "100%",
    marginBottom: 26,
    border: "#D3D8DA",
  },
  stepper: {
    marginTop: 10,
  },
};
class AddEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventTitle: "",
      eventTitleCount: 0,
      eventDescription: [{ type: "paragraph", children: [{ text: "" }] }],
      featured: true,
      descriptionCount: 0,
      showAlert: false,
      success: false,
      error: false,
      openDrawer: false,
      Image: [],
      attendees: [],
      image: "",
      loading: false,
      events: [],
      seError: false,
      isActionBtn: false,
      btnLabel: "",
      btnLabelCount: 0,
      link: "",
      selectImage: false,
      fullLocationAddress: "",
      isLocationDrawer: "",
      locationPlaceholder: "",
      submitError: false,
      activeStep: 0,
      isLocationDrawer: false,
      isStartDate: false,
      isEndDate: false,
      isRepeatDays: false,
      orgLocation: true,
      organizationAddress: "",
      ISOstartDateandTime: "",
      repeatdays: [],
      startDate: "",
      iqamahTime: "",
      dateError: false,
      startDateandTime: "",
      EndDateandTime: "",
      daysString: "",
      days: {
        Sun: false,
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
      },
      fullLocationAddress: "",
      fullStartDateandTime: Moment().format("ddd, MMM DD, yyyy hh:mm A"),
      fullEndDateandTime: Moment().add(1, 'hours').format("ddd, MMM DD, yyyy hh:mm A"),
      fullRepeatDays: "",
      LocationAddress: {
        LocationName: "",
        StreetAddress: "",
        Suit_Number: "",
        City: "",
        State: "",
        Zip_Code: "",
      },
      LocationState: {
        LocationName: "",
        StreetAddress: "",
        Suit_Number: "",
        City: "",
        State: "",
        Zip_Code: "",
      },
      stateAbbr: "",
      isEntryType: false,
      entryType: "",
      card4: false,
      card3: false,
      card2: false,
      card1: false,
      refreshment_card1: false,
      refreshment_card2: false,
      refreshment_card3: false,
      attendee: [],
      isAttendee: false,
      isRefreshment: false,
      registrationFee: "",
      entryTypeContent: "",
      feeOption: "",
      isSalahDrawer: false,
      isWaktFeildToShow: false,
      waktSalahName: "",
      waktSalahValue: "",
      entryError: false,
      all_attendees: [],
      foodList: [],
      tempFoodList: [],
      isFoodList: true,
      potluckContact: "",
      potluckString: "",
      foodlistItem: false,
      isSpeakers: false,
      speakersToShow: 4,
      speakerEmail: false,
      reminderEmail: false,
      speakersChipData: [],
      tempSpeakers: "",
      continueError: false,
      emailOnPublished: false,
      attendeestoShow: [],
      all_attendees_show: [],
      startDateToFormat: Moment(),
      startTimeToFormat: Moment(),
      endTimeToFormat: Moment().add(1, 'hours'),
      enddateToFormat: Moment(),
      repeat_object: {
        frequency: "",
        repeat_category: "One-time event",
        repeat_content: "One-time event",
        repeat_every: "",
        repeat_on: "",
        repeats_Object: {
          type: "Never",
          value: ''
        }
      },
      repeat_content: "One-time event",
      entry_type: "",
      timeError: false,
      temp_startDate: Moment(),
      temp_endDate: Moment(),
      temp_startTime: Moment(),
      temp_endTime: Moment(),
      endDateError: false,
      states: [],
      hideImage: false,
      speakers: [],
      tempImage: [],
      deleteTempImage: false,
      addProfession: false,
      professionAttendees: [],
      professionAttTemp: [],
      customAttendees: [],
      prevattendees: [],
      prevattendeestoShow: [],
      prevCustomAttendees: [],
      Repeat_beforeError: false,
      time_zone: '',
      timezoneAbbr: '',
      methodString: '',
      zoneAbbriviation: "",
    };
    this.onDescriptionStateChange = this.onDescriptionStateChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.selectMediaItem = this.selectMediaItem.bind(this);
  }

  async selectMediaItem(object) {
    this.setState({
      Image: [
        {
          name: object.title,
          src: object.src,
          size: 55,
        },
      ],

    });
  }

  async componentDidMount() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const speakers = await getSpeakers(orgId, token);
      const new_Speakers = speakers?.data?.body?.data?.filter(speaker => speaker.status === 'Available').map((item) => {
        return {
          id: item._id,
          primary: item.name.firstName + (item.name.lastName ? ' ' + item.name.lastName : ''),
          secondary: item.designation.title,
          src: item.imageMetaData.imageUrl,
          phone: item.phone,
          status: item.status,
          thumbnailImage: item.imageMetaData.imageThumbnail,
          emailAddress: item.emailAddress
        };
      });
      const orgName = localStorage.getItem("organame");
      const orgaddress = JSON.parse(localStorage.getItem('orgaddress'));
      const country_name = orgaddress?.country ? orgaddress.country : 'United States';
      const country_code = countries.find((item) => item.name === country_name);
      const new_states = states
        .filter((item) => item.country_code === country_code.iso2)
        .map((item) => {
          return {
            label: item.name,
            value: item.name,
            name: item.state_code,
            stateAbbr: item.state_code,
          };
        });
      const default_state = states.filter(
        (item) => item.name.toLowerCase() === orgaddress.state.toLowerCase()
      );
      const fullLocationAddress = `${orgaddress?.line1 ? orgaddress?.line1 + (orgaddress?.line2 ? ", " : ". ") : ""
        }${orgaddress?.line2 ? orgaddress?.line2 + ". " : ""}${orgaddress?.city ? orgaddress?.city + ", " : ""
        }${default_state?.[0]?.state_code ?? ''} ${orgaddress?.postalCode ?? ''}`;
      const LocationAddress = {
        LocationName: orgName,
        StreetAddress: orgaddress?.line1,
        Suit_Number: orgaddress?.line2 || "",
        City: orgaddress?.city,
        State: orgaddress?.state,
        Zip_Code: orgaddress?.postalCode,
      };
      this.setState({
        events: [],
        speakers: new_Speakers,
        states: new_states,
        stateAbbr: default_state?.[0]?.state_code,
        LocationAddress,
        fullLocationAddress,
      });

    } catch (error) {
      this.setState({
        error: error && error.response && error.response.data.message,
      });
    }
  }
  handleSwitchChange = (val) => (event) => {
    this.setState({ [val]: !this.state[val] });
  };
  locationAddress = () => {
    const { LocationState, orgLocation, stateAbbr } = this.state;
    if (orgLocation) {
      const orgAddress = JSON.parse(localStorage.getItem("orgaddress"));
      const orgName = localStorage.getItem("organame");
      const fullLocationAddress = `${orgAddress.line1 ? orgAddress.line1 + (orgAddress.line2 ? ", " : ". ") : ""
        }${orgAddress.line2 ? orgAddress.line2 + ". " : ""}${orgAddress.city ? orgAddress.city + ", " : ""
        }${stateAbbr ?? ''} ${orgAddress.postalCode ?? ''}`;
      this.setState({
        fullLocationAddress,
        openDrawer: false,
        isLocationDrawer: false,
        locationPlaceholder: orgName,
        isError: false,
        LocationAddress: {
          LocationName: orgName,
          StreetAddress: orgAddress.line1,
          Suit_Number: orgAddress.line2 || "",
          City: orgAddress.city,
          State: orgAddress.state,
          Zip_Code: orgAddress.postalCode,
        },
        LocationState: {
          LocationName: "",
          StreetAddress: "",
          Suit_Number: "",
          City: "",
          State: "",
          Zip_Code: "",
        },
      });
    } else {
      const LocationName = LocationState.LocationName;
      const StreetAddress = LocationState.StreetAddress;
      const Suit_Number = LocationState.Suit_Number;
      const City = LocationState.City;
      const State = LocationState.State;
      const Zip_Code = LocationState.Zip_Code;
      const state_abbr = states.filter(
        (item) => item.name.toLowerCase() === State.toLowerCase()
      );
      if (LocationName && StreetAddress && City && State && Zip_Code) {
        let fullLocationAddress = `${StreetAddress ? StreetAddress + ". " : ""
          }${Suit_Number ? Suit_Number : ""} ${City ? City + ", " : ""}${state_abbr[0].state_code
          } ${Zip_Code}`;

        this.setState({
          fullLocationAddress,
          openDrawer: false,
          isLocationDrawer: false,
          orgLocation: false,
          isError: false,
          locationPlaceholder: LocationName,
          LocationAddress: LocationState,
          LocationState: {
            LocationName: "",
            StreetAddress: "",
            Suit_Number: "",
            City: "",
            State: "",
            Zip_Code: "",
          },
        });
      } else {
        this.setState({ isError: true });
      }
    }
  };
  saveRepeats = (value) => {
    this.setState({
      repeat_object: value,
      repeat_content: value.repeat_content,
      openDrawer: false,
      isRepeatDays: false,
    });
  };
  cancleRepeats = () => {
    this.setState({
      repeat_object: "",
      repeat_content: "",
      openDrawer: false,
      isRepeatDays: false,
    });
  };
  getDateTime = (value, boolean) => {
    let startDate = "";
    const { iqamah_ISOString, iqamahTime } = this.state;
    if (!boolean) {
      startDate = Moment(value).format("ddd, MMM DD, yyyy");
    }
    this.setState({
      startDateandTime: (startDate ? startDate + ", " : "") + iqamahTime,
      iqamah_ISOString,
      startDate,
      repeat_start_date: startDate.slice(0, 3),
      startDateToFormat: value,
      temp_startDate: value,
      dateError: false,
    });
  };

  startTime = (value) => {
    const iqamahTime = Moment(value).format("hh:mm A");
    const iqamah_ISOString = new Date(value).toISOString();
    const { startDate } = this.state;
    this.setState({
      startDateandTime: (startDate ? startDate + ", " : "") + iqamahTime,
      iqamah_ISOString,
      startTimeToFormat: value,
      timeError: false,
      temp_startTime: value,
    });
  };
  handleRemove = () => {
    this.setState({ tempImage: [], deleteTempImage: true });
  };
  getSpeakers = (values) => {
    console.log("values", values)
    // if(values?.length) {
    // values = this.state.tempSpeakers ? this.state.tempSpeakers : []
    const tempSpeakers = values.map((item) => {
      return {
        id: item.id,
        key: item.id,
        label: item.primary || item.label,
        src: item.src,
        secondary: item.secondary,
        phone: item.phone,
        status: item.status,
        thumbnailImage: item.thumbnailImage,
        emailAddress: item.emailAddress
      };
    });
    console.log(tempSpeakers, 'checking')
    this.setState({ tempSpeakers: tempSpeakers });
    // } 

  };
  getEndDateTime = (value, boolean) => {
    const { endTime } = this.state;
    let endDate = "";
    if (!boolean) {
      endDate = Moment(value).format("ddd, MMM DD, yyyy");
    }
    this.setState({
      EndDateandTime: (endDate ? endDate + ", " : "") + endTime,
      endDate,
      endTime,
      enddateToFormat: value,
      dateError: false,
      temp_endDate: value,
    });
  };
  endTime = (value) => {
    let endTime = Moment(value).format("hh:mm A");
    const { endDate } = this.state;
    this.setState({
      EndDateandTime: (endDate ? endDate + ", " : "") + endTime,
      endDate,
      endTime,
      endTimeToFormat: value,
      timeError: false,
      temp_endTime: value,
    });
  };
  changeHandler = (name, val) => {
    const { eventTitleCount, btnLabelCount } = this.state;
    if (name === "name") {
      if (eventTitleCount < 59) {
        // if (/^(?![\s.]+$)[a-zA-Z\s.]*$/.test(val)) {
        this.setState({
          eventTitle: val,
          eventTitleCount: val.length,
        });
        // } else {
        //   return false;
        // }
      } else {
        this.setState({ eventTitleCount: val.length });
      }
    }
    if (name === "btnLabel") {
      if (btnLabelCount < 14) {
        if (/^(?![\s.]+$)[a-zA-Z\s.]*$/.test(val)) {
          this.setState({ btnLabel: val, btnLabelCount: val.length });
        } else {
          return false;
        }
      } else {
        this.setState({ btnLabelCount: val.length });
      }
    }
  };
  onDescriptionStateChange = (editorState) => {
    let sum = 0;
    editorState.map((st) => {
      if (st.type === "bulleted-list" || st.type === "numbered-list") {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    this.setState({
      eventDescription: editorState,
      descriptionCount: sum,
    });
  };
  upload = async (files) => {
    this.setState({
      image: files,
      hideImage: files && files.length > 0 ? true : false,
      deleteTempImage: false,
    });
  };
  addLogo = async () => {
    const { image, deleteTempImage } = this.state;
    const setImage =
      image.length > 0
        ? [{ name: image[0].name, src: image[0].src, size: image[0].size }]
        : [];
    this.setState({
      Image: deleteTempImage ? [] : setImage,
      openDrawer: false,
      selectImage: image.length > 0 && false,
      tempImage: deleteTempImage ? [] : setImage,
    });
  };
  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };
  handleText = (index, e) => {
    const stringRegex = /^[a-zA-Z\s\.]*$/;
    if (e.target.value.length <= 20 && stringRegex.test(e.target.value)) {
      const customAttendees = [...this.state.customAttendees]
      customAttendees[index] = e.target.value
      this.setState({
        customAttendees
      })
    }
  }
  handleDelete = (i, e) => {
    e.preventDefault()
    const customAttendees = [
      ...this.state.customAttendees.slice(0, i),
      ...this.state.customAttendees.slice(i + 1)
    ]
    this.setState({
      customAttendees
    })
  }
  addCustomAttendee = () => {
    const { addProfession } = this.state;
    if (addProfession) {
      const customAttendees = this.state.customAttendees.concat([''])
      this.setState({
        customAttendees
      })
    }
    else {
      this.setState({ addProfession: true })
    }
  }
  saveEntryTyp = () => {
    const { feeOption, entryType, registrationFee } = this.state;
    if (
      entryType === "Public" &&
      feeOption === "required" &&
      !registrationFee
    ) {
      this.setState({ entryError: true });
    } else {
      let entry_content = "";
      if (entryType === "Public" && feeOption === "free") {
        entry_content = `${entryType} Event with ${feeOption} entry`;
      } else if (entryType === "Public" && feeOption === "required") {
        entry_content = entry_content = `${entryType} Event with registration fee ${feeOption}`;
      } else if (entryType === "Public" && feeOption === "optional") {
        entry_content = entry_content = `${entryType} Event with registration fee ${feeOption}`;
      } else if (entryType === "Private" && feeOption === "restricted") {
        entry_content = entry_content = `${entryType} Event ${feeOption} to personal`;
      }
      this.setState({
        openDrawer: false,
        isEntryType: false,
        entryTypeContent: entry_content,
        fee: registrationFee,
        entry_type: entryType,
        entryType: "",
        registrationFee: "",
        card1: false,
        card2: false,
        card3: false,
        card4: false,
        entryError: false,
      });
    }
  };
  addEvent = async () => {
    this.setState({ loading: true });
    const {
      Image,
      speakersChipData,
      speakerEmail,
      eventDescription,
      entry_type,
      feeOption,
      eventTitle,
      featured,
      fullLocationAddress,
      descriptionCount,
      fullEndDateandTime,
      repeat_content,
      fullStartDateandTime,
      entryTypeContent,
      all_attendees,
      foodList,
      events,
      registrationFee,
      fee,
      refreshment_card2,
      refreshment_card3,
      potluckContact,
      startDateToFormat,
      zoneAbbriviation,
      startTimeToFormat,
      LocationAddress,
      enddateToFormat,
      endTimeToFormat,
      repeat_object,
      customAttendees
    } = this.state;
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    const contructStartDate = Moment(startDateToFormat).format("YYYY-MM-DD");
    const contructEndtDate = Moment(enddateToFormat).format("YYYY-MM-DD");
    const constructStartTime = Moment(startTimeToFormat).format("HH:mm:ss");
    const constructEndTime = Moment(endTimeToFormat).format("HH:mm:ss");

    const speakersData = speakersChipData.map((item) => {
      console.log("speakersDataitem", item)
      return {
        name: item.label,
        id: item.key,
        image: item.src,
        emailOnPublished: speakerEmail,
        phone: item.phone,
        status: item.status,
        thumbnailImage: item.thumbnailImage,
        emailAddress: item.emailAddress
      };
    });
    let temp_att = {
      ageGroups: {
        seniors: false,
        adult: false,
        youth: false,
        children: false,
      },
      gender: {
        male: false,
        female: false,
      },
      otherItemList: {
        all: false,
        muslims: false,
        faithLeaders: false,
        nonMuslims: false,
        socialActivists: false,
        politicalLeaders: false,
      },
    };
    all_attendees.map((item) => {
      if (item in temp_att.ageGroups) {
        temp_att.ageGroups[item] = true;
      } else if (item in temp_att.gender) {
        temp_att.gender[item] = true;
      } else if (item in temp_att.otherItemList) {
        temp_att.otherItemList[item] = true;
      }
    });
    temp_att.customProfession = customAttendees
    const newFoodList = foodList.map(function (item) {
      return item["primary"];
    });
    const refreshments = {
      providedList: newFoodList,
      provided: refreshment_card2,
      potluck: refreshment_card3,
      potluckContactNumber: potluckContact,
      potluckList: ["undefined"],
    };
    const obj = {
      title: eventTitle,
      orgName: localStorage.getItem("organame"),
      description: JSON.stringify(eventDescription),
      imageDetails: {
        thumbnailImage: Image?.[0]?.src,
        imageUrl: Image?.[0]?.src,
        imageName: Image?.[0]?.name,
        imageSize: Image?.[0]?.size,
      },
      speakers: speakersData,
      orgId: orgId,
      repeat: {
        category: repeat_object.repeat_category,
        repeatEvery: repeat_object.repeat_every,
        frequency: repeat_object.frequency,
        repeatOn: repeat_object.repeat_on,
        repeatEnds: {
          type: repeat_object.repeats_Object.type,
          value: repeat_object.repeats_Object.value,
        },
      },
      location: {
        address: {
          line1: LocationAddress.StreetAddress,
          line2: LocationAddress.Suit_Number,
          city: LocationAddress.City,
          state: LocationAddress.State,
          postalCode: LocationAddress.Zip_Code,
          locationName: LocationAddress.LocationName,
        },
        lat: 0,
        long: 0,
      },
      eventStart: {
        startAt: new Date(
          `${contructStartDate} ${constructStartTime}`
        ).toISOString(),
        startTime: new Date(
          `${contructStartDate} ${constructStartTime}`
        ).toISOString(),
      },
      eventZone: zoneAbbriviation,
      endAt: new Date(`${contructEndtDate} ${constructEndTime}`).toISOString(),
      published: featured,
      entry: {
        fee: fee ? fee : 0,
        feeRequired: feeOption,
        entryType: entry_type,
        amount: "",
        position: "",
      },
      attendee: temp_att,
      refreshment: refreshments,
      appPublishedAt: {
        website: true,
        deenScreen: true,
        deenFund: true,
      },
      deleteAtDone: true,
      userGroups: {
        lifetimeMembers: true,
        generalMembers: true,
        executiveBoard: false,
        youthDepartments: false,
      },
    };
    if (
      !eventTitle ||
      descriptionCount === 0 ||
      // speakersChipData.length === 0 ||
      !fullLocationAddress ||
      !fullStartDateandTime ||
      !fullEndDateandTime ||
      !repeat_content ||
      !entryTypeContent ||
      all_attendees.length === 0 ||
      foodList.length === 0
    ) {
      this.setState({ error: true, loading: false });
    } else {
      const allEvents = events.filter((item) => item.title === eventTitle);
      if (allEvents.length > 0) {
        this.setState({
          error: event_title_already_exist,
          loading: false,
          showAlert: true,
        });
      } else {
        try {
          await addNewEvent(obj, token);
          this.setState({
            success: true,
            showAlert: true,
            loading: false,
          });
          this.props.showAlert("addEvent");
          this.props.history.push("/events");
        } catch (error) {
          this.setState({
            error: error && error.response && error.response.data.message,
            showAlert: true,
            loading: false,
          });
        }
      }
    }
  };

  attendeeFunc = (value, valuetoShow) => {
    const { attendees, attendeestoShow } = this.state;
    if (value === "all") {
      if (attendees.includes("all")) {
        attendees.splice(attendees.indexOf("all"), 6);
        attendeestoShow.splice(attendeestoShow.indexOf("Muslims"), 5);
        this.setState({ attendees, attendeestoShow });
      } else {
        const arr = [
          "muslims",
          "faithLeaders",
          "nonMuslims",
          "socialActivists",
          "politicalLeaders",
        ];
        const arr2 = [
          "Muslims",
          "Faith Leaders",
          "Non-Muslims",
          "Social Activists",
          "Political Leaders",
        ];
        const filtered = attendees.filter((item) => !arr.includes(item));
        const filteredtoShow = attendeestoShow.filter(
          (item) => !arr2.includes(item)
        );
        filtered.push("all");
        filteredtoShow.push("Open to all");
        this.setState({ attendees: filtered, attendeestoShow: filteredtoShow });
      }
    } else {
      if (attendees.includes("all")) {
        const arr = [
          "muslims",
          "faithLeaders",
          "nonMuslims",
          "socialActivists",
          "politicalLeaders",
        ];
        if (arr.includes(value)) {
          attendees.splice(attendees.indexOf("all"), 1);
          attendeestoShow.splice(attendeestoShow.indexOf("Open to all"), 1);
        }
      }
      if (attendees.includes(value)) {
        const newattendees = attendees.filter((item) => item !== value);
        const newAttendeestoShow = attendeestoShow.filter(
          (item) => item !== valuetoShow
        );
        this.setState({
          attendees: newattendees,
          attendeestoShow: newAttendeestoShow,
        });
      } else {
        if (attendees.includes("all")) {
          attendees.unshift(value);
          attendeestoShow.unshift(valuetoShow);
          this.setState({ attendees, attendeestoShow });
        } else {
          attendees.push(value);
          attendeestoShow.push(valuetoShow);
          this.setState({ attendees, attendeestoShow });
        }
      }
    }
  };
  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };
  continue = () => {
    const {
      Image,
      enddateToFormat,
      startDateToFormat,
      speakersChipData,
      eventTitle,
      fullLocationAddress,
      descriptionCount,
      fullEndDateandTime,
      repeat_content,
      fullStartDateandTime,
      startTimeToFormat,
      endTimeToFormat,
    } = this.state;
    const contructStartDate = Moment(startDateToFormat).format("YYYY-MM-DD");
    const contructEndtDate = Moment(enddateToFormat).format("YYYY-MM-DD");
    const constructStartTime = Moment(startTimeToFormat).format("HH:mm:ss");
    const constructEndTime = Moment(endTimeToFormat).format("HH:mm:ss");
    if (
      !eventTitle ||
      descriptionCount === 0 ||
      // speakersChipData.length === 0 ||
      !fullLocationAddress ||
      !fullStartDateandTime ||
      !fullEndDateandTime ||
      !repeat_content
    ) {
      this.setState({ continueError: true, loading: false });
    } else if (
      new Date(`${contructEndtDate} ${constructEndTime}`).getTime() <
      new Date(`${contructStartDate} ${constructStartTime}`).getTime()
    ) {
      this.setState({ endDateError: true, loading: false });
    } else if (
      Moment(new Date(repeat_content)).format('YYYY-MM-DD') < Moment(new Date(
        `${contructStartDate} ${constructStartTime}`
      )).format('YYYY-MM-DD')
    ) {
      this.setState({ Repeat_beforeError: repeat_date_between_start_end, loading: false });

    } else {
      this.setState({ activeStep: this.state.activeStep + 1 });
    }
  };
  cardFunction = (props, values) => {
    this.setState({
      card1: false,
      card2: false,
      card3: false,
      card4: false,
      [props]: !this.state[props],
      entryType: values.entryType,
      feeOption: values.feeOption,
    });
  };
  refreshment = (props) => {
    if (props === "refreshment_card1") {
      this.setState({
        refreshment_card1: false,
        refreshment_card2: false,
        refreshment_card3: false,
        [props]: !this.state[props],
        tempFoodList: [{ primary: "No Food" }],
      });
    } else {
      this.setState({
        refreshment_card1: false,
        [props]: !this.state[props],
      });
    }
  };

  handleAttendeeDelete = (chip) => {
    const { all_attendees, all_attendees_show, attendees, customAttendees } = this.state;
    const ChipLabel = chip.label === 'Brother' ? 'male' : chip.label === 'Sisters' ? 'female' : chip.label.split(' ').join('').toLowerCase()
    const deleteAttendee = all_attendees.filter(
      (item) => item.split(' ').join('').toLowerCase() !== ChipLabel
    );
    const deleteAttendeetoShow = all_attendees_show.filter(
      (item) => item.split(' ').join('').toLowerCase() !== chip.label.split(' ').join('').toLowerCase()
    );
    const deleteCustom = customAttendees.filter((item) => item.split(' ').join('').toLowerCase() !== ChipLabel)
    this.setState({
      all_attendees: deleteAttendee,
      all_attendees_show: deleteAttendeetoShow,
      attendees: deleteAttendee,
      attendeestoShow: deleteAttendeetoShow,
      customAttendees: deleteCustom,

      prevattendees: deleteAttendee,
      prevattendeestoShow: deleteAttendeetoShow,
      prevattendees: deleteAttendee,
      prevattendeestoShow: deleteAttendeetoShow,
      prevCustomAttendees: deleteCustom
    });
  };
  handleRefreshmentsDelete = (chip) => {
    const { foodList } = this.state;
    let deleteRefreshment = foodList.filter(
      (item) => item.primary !== chip.label
    );
    this.setState({
      foodList: deleteRefreshment,
      tempFoodList: deleteRefreshment.length > 0 ? deleteRefreshment : [],
    });
  };
  handleDeleteChip = () => {
    this.setState({
      Image: [],
      tempImage: [],
      deleteTempImage: false,
    });
  };
  deleteChip = (e) => {
    const { speakersChipData } = this.state;
    const new_speakersChipData = speakersChipData.filter(
      (item) => item.key !== e.key
    );
    this.setState({ speakersChipData: new_speakersChipData, tempSpeakers: new_speakersChipData, });
  };
  renderRequirementInput() {
    const { entryError, card2, registrationFee } = this.state;
    return (
      <div className="requirementCont">
        <h1>Select Requirements</h1>
        <BaseTextField
          value={registrationFee || ""}
          InputProps={false}
          phone={false}
          search={false}
          count={false}
          label="Registration Fee"
          onChange={(e) => {
            if (
              e.target.value === "" ||
              e.target.value.match(/^\d{1,4}(\.\d{0,2})?$/)
            ) {
              this.setState({ registrationFee: e.target.value });
            }
          }}
          placeholder=""
          style={styles.btn}
          error={entryError && card2 && !registrationFee}
          helperText={entryError && "Registration fee required"}
        />
        <span className="dollarIcon">$</span>
      </div>
    );
  }
  handleTimeZoneChange = (event) => {
    const timezone = event.target.value
    let timezoneAbbr = "";
    let methodString = '';
    let zoneAbbriviation = ''
    switch (timezone) {
      case 'Atlantic Standard Time (AST)':
        timezoneAbbr = "-4";
        methodString = 'Atlantic Standard Time (AST)';
        zoneAbbriviation = '(AST)';
        break;
      case 'Eastern Standard Time (EST)':
        timezoneAbbr = "-5";
        methodString = 'Eastern Standard Time (EST)';
        zoneAbbriviation = '(EST)';
        break;
      case 'Central Standard Time (CST)':
        timezoneAbbr = "-6";
        methodString = 'Central Standard Time (CST)';
        zoneAbbriviation = '(CST)';
        break;
      case 'Mountain Standard Time (MST)':
        timezoneAbbr = "-7";
        methodString = 'Mountain Standard Time (MST)';
        zoneAbbriviation = '(MST)';
        break;
      case 'Alaskan Standard Time (AKST)':
        timezoneAbbr = "-9";
        methodString = 'Alaskan Standard Time (AKST)';
        zoneAbbriviation = '(AKST)';
        break;
      case 'Hawaii Standard Time (HST)':
        timezoneAbbr = "-10";
        methodString = 'Hawaii Standard Time (HST)';
        zoneAbbriviation = '(HST)';
        break;
      case 'Greenwich Mean Time (GMT)':
        timezoneAbbr = "0";
        methodString = 'Greenwich Mean Time (GMT)';
        zoneAbbriviation = '(GMT)';
        break;
      case 'Pacific Standard Time (PST)':
        timezoneAbbr = "-8";
        methodString = 'Pacific Standard Time (PST)';
        zoneAbbriviation = '(PST)';
        break;
      default:
        timezoneAbbr = '-5'
        methodString = 'Eastern Standard Time (EST)'
        zoneAbbriviation = '(EST)';
    }
    this.setState({ time_zone: event.target.value, timezoneAbbr, methodString, zoneAbbriviation, changes: true })
  };

  render() {
    const {
      eventTitle,
      eventDescription,
      descriptionCount,
      showAlert,
      eventTitleCount,
      timezoneAbbr,
      time_zone,
      zoneAbbriviation,
      Image,
      selectImage,
      Repeat_beforeError,
      repeat_content,
      activeStep,
      success,
      error,
      openDrawer,
      loading,
      isLocationDrawer,
      LocationState,
      isStartDate,
      isEndDate,
      isRepeatDays,
      startDateandTime,
      EndDateandTime,
      fullStartDateandTime,
      fullLocationAddress,
      fullEndDateandTime,
      isError,
      locationPlaceholder,
      timezone,
      repeat_start_date,
      isEntryType,
      card4,
      card3,
      card2,
      card1,
      attendee,
      isAttendee,
      featured,
      isRefreshment,
      entryTypeContent,
      isSpeakers,
      attendees,
      all_attendees,
      refreshment_card1,
      refreshment_card2,
      refreshment_card3,
      foodList,
      foodlistItem,
      speakersToShow,
      speakerEmail,
      reminderEmail,
      speakersChipData,
      speakers,
      continueError,
      attendeestoShow,
      all_attendees_show,
      potluckContact,
      dateError,
      timeError,
      endDateError,
      states,
      hideImage,
      tempImage,
      isSalahDrawer,
      endTimeToFormat,
      enddateToFormat,
      startDateToFormat,
      startTimeToFormat,
      repeat_object,
      addProfession,
      professionAttendees,
      professionAttTemp,
      customAttendees
    } = this.state;
    console.log("speakersstate", speakers)
    console.log("tempSpeakers", this.state.tempSpeakers)

    const LocationArray = [
      "Location Name",
      "Street Address",
      "Suite/FL Number",
      "City",
      "State",
      "Zip code",
    ];
    const LocationArrayNames = [
      "LocationName",
      "StreetAddress",
      "Suit_Number",
      "City",
      "State",
      "Zip_Code",
    ];
    const timezoneObject = [
      {
        value: "Atlantic Standard Time (AST)",
        label: "Atlantic Standard Time (AST)",
      },
      {
        value: "Eastern Standard Time (EST)",
        label: "Eastern Standard Time (EST)",
      },
      {
        value: "Central Standard Time (CST)",
        label: "Central Standard Time (CST)",
      },
      {
        value: "Mountain Standard Time (MST)",
        label: "Mountain Standard Time (MST)",
      },
      {
        value: "Alaskan Standard Time (AKST) ",
        label: "Alaskan Standard Time (AKST) ",
      },
      {
        value: "Hawaii Standard Time (HST)",
        label: "Hawaii Standard Time (HST)",
      },
      {
        value: "Greenwich Mean Time (GMT)",
        label: "Greenwich Mean Time (GMT)",
      },
      {
        value: "Pacific Standard Time (PST)",
        label: "Pacific Standard Time (PST)",
      },
    ];
    const textEditorStyle = {
      border: "1px solid black",
      color: "gray",
    };
    const stringRegex = /^[a-zA-Z\s\.]*$/;
    const flatReg = /^[a-zA-Z0-9\s,.'-]*$/;
    const state = /[A-Z][a-z]+/g;
    const zipReg = /^[0-9]*$/;
    const orgAddress = JSON.parse(localStorage.getItem("orgaddress"));
    const orgName = localStorage.getItem("organame");
    const fee = /^[0-9]+(\.[0-9]{1,2})?$/;
    const re = /\d{1,2}\.{1}?\d{0,2}/;
    function formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
    const imagechip =
      Image.length > 0
        ? Image.map((url, i) => {
          return {
            key: i,
            label: url.name.slice(0, 50),
          };
        })
        : "";
    const all_attendees_data =
      all_attendees_show.length > 0
        ? all_attendees_show.map((item, i) => {
          return {
            key: i,
            label: item,
          };
        })
        : [];
    const foodlist =
      foodList.length > 0
        ? foodList.map((item, i) => {
          return {
            key: i,
            label: item.primary,
          };
        })
        : [];
    const style = {
      width: "100%",
    };
    const styles = {
      stepper: {
        marginTop: 120,
        padding: "8px 20px",
        borderRadius: "30px",
      },
    };
    const getSteps = () => {
      return [
        { number: 1, label: "Basic Info" },
        { number: 2, label: "Event Details" },
      ];

    };
    return (
      <MasterLayoutMiddle
        header={
          <>
            <div
              onClick={() => this.props.history.push("/events")}
              className="backBtn"
              style={{ left: "78px", position: "absolute" }}
            >
              <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
              <p style={{ alignSelf: "center" }}>Back</p>{" "}
            </div>
            {error && error === "Invalid token" && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => this.setState({ error: false })}
                />
              </div>
            )}
            {showAlert && error && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: 70,
                }}
              >
                <AlertAction
                  alert={
                    error === "Invalid token"
                      ? token_expired
                      : error
                  }
                  alertType={"error"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
          </>
        }
        isForm={true}
        formData={
          <>
            <div>
              <h1 className="title_top">Add New Event</h1>
            </div>
            <BaseStepper
              title=""
              subTitle=""
              getSteps={getSteps}
              alternativeLabel={true}
              nonLinear={true}
              backButton={false}
              orientation={"horizontal"}
              btnStyles={styles.stepper}
              activeStep={activeStep}
            />
            <section>
              <div>
                {activeStep === 0 ? (
                  <section>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h2 className="sub_title">Basic Information</h2>
                      </Grid>

                      <Grid item xs={12}>
                        <div className="field_box">
                          <TextField
                            label="Event Title"
                            value={eventTitle}
                            onChange={(e) =>
                              this.changeHandler("name", e.target.value)
                            }
                            error={continueError && !eventTitle}
                            helperText={
                              continueError &&
                              !eventTitle &&
                              "Event Title is Required"
                            }
                            placeholder=" "
                            inputProps={{
                              maxLength: 60
                            }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <span className="char-count">
                              CHAR {eventTitleCount}/60
                            </span>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <TextEditor
                          onChange={(e) => this.onDescriptionStateChange(e)}
                          disabled={false}
                          value={eventDescription}
                          style={textEditorStyle}
                          label={"Description"}
                          helperText={
                            continueError &&
                            !descriptionCount &&
                            "Please add event description"
                          }
                          error={continueError && !descriptionCount}
                          maxCharCount={1000}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div className="field_box" >
                          <div className="media-library-image" >
                            <MediaUpload
                              addMediaItem={this.selectMediaItem}
                              label="Event Image"
                              buttonName="ADD IMAGE"
                              typeOfMedia='/images'
                            />
                            {/*                             <MediaUploader typeOfMedia='/images' label="Upload Images" onChange={this.selectMediaItem}  />
 */}                          </div>
                          <div>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="field_box image_boxes" onClick={() => {
                          this.setState({
                            openDrawer: true,
                            isSpeakers: true,
                          })
                        }}>
                          <BaseImgChip
                            chipData={
                              speakersChipData ? speakersChipData : null
                            }
                            onInputClick={() =>
                              this.setState({
                                isSpeakers: true,
                                openDrawer: true,
                              })
                            }

                            onChipInputClick={() =>
                              this.setState({
                                isSpeakers: true,
                                openDrawer: true,
                              })
                            }
                            deleteChip={this.deleteChip}
                            // error={
                            //   continueError && speakersChipData.length === 0
                            // }
                            helperText={" "
                              // continueError &&
                              // speakersChipData.length === 0 &&
                              // "Please select an item in this list"
                            }
                            label={"Guests"}
                            disabled={isSpeakers}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        {" "}
                        <div className="dasheddBorder_form"></div>
                      </Grid>
                      <Grid item xs={12}>
                        <h2 className="sub_title">Event Location</h2>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={fullLocationAddress}
                            onChange={this.handleChange("Location")}
                            // InputProps={false}
                            phone={false}
                            search={false}
                            count={false}
                            disabled={isLocationDrawer}
                            label={
                              locationPlaceholder
                                ? locationPlaceholder
                                : "Add Location"
                            }
                            placeholder=""
                            style={styles.btn}
                            error={continueError && !fullLocationAddress}
                            helperText={
                              continueError &&
                              !fullLocationAddress &&
                              "location is Required"
                            }
                            onClick={() =>
                              this.setState({
                                openDrawer: true,
                                isLocationDrawer: true,
                              })
                            }
                            InputProps={true}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}></Grid>
                      <Grid item xs={12}>
                        {" "}
                        <div className="dasheddBorder_form"></div>
                      </Grid>
                      <Grid item xs={12}>
                        <h2 className="sub_title">Date and Time</h2>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={
                              fullStartDateandTime ? fullStartDateandTime + (zoneAbbriviation ? " " + zoneAbbriviation : '') : ""
                            }
                            // InputProps={false}
                            phone={false}
                            search={false}
                            count={false}
                            label="Start Date and Time"
                            placeholder=""
                            style={styles.btn}
                            error={continueError && !fullStartDateandTime}
                            helperText={
                              continueError &&
                              !fullStartDateandTime &&
                              "Start date and time is required"
                            }
                            onClick={() =>
                              this.setState({
                                openDrawer: true,
                                isStartDate: true,
                              })
                            }
                            disabled={isStartDate}
                            InputProps={true}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={fullEndDateandTime ? fullEndDateandTime + (zoneAbbriviation ? " " + zoneAbbriviation : '') : ""}
                            // InputProps={false}
                            phone={false}
                            search={false}
                            count={false}
                            label="End Date and Time"
                            placeholder=""
                            style={styles.btn}
                            error={
                              (continueError && !fullEndDateandTime) ||
                              endDateError
                            }
                            helperText={
                              (continueError &&
                                !fullEndDateandTime &&
                                "End date and time required") ||
                              (endDateError && !isEndDate &&
                                "Event end date should be greater than event starting date")
                            }
                            onClick={() =>
                              this.setState({
                                openDrawer: true,
                                isEndDate: true,
                              })
                            }
                            disabled={isEndDate}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  onClick={() =>
                                    this.setState({
                                      openDrawer: true,
                                      isRepeatDays: true,
                                    })
                                  }
                                  position="end"
                                  style={{
                                    marginBottom: 15,
                                    cursor: "pointer",
                                  }}
                                >
                                  <ChevronRight />{" "}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={repeat_content || ""}
                            // InputProps={false}
                            phone={false}
                            search={false}
                            count={false}
                            label="Repeat"
                            placeholder=""
                            style={styles.btn}
                            onClick={() =>
                              this.setState({
                                openDrawer: true,
                                isRepeatDays: true,
                              })
                            }
                            disabled={isRepeatDays}
                            error={continueError && !repeat_content || Repeat_beforeError}
                            helperText={continueError && !Repeat_beforeError ? "Please select an item in this list" : repeat_date_between_start_end}
                            InputProps={true}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {activeStep === 0 && (
                        <div className="form_button">
                          <p
                            className="cancel"
                            onClick={() => this.props.history.push("/events")}
                          >
                            CANCEL
                          </p>
                          <BaseButton
                            text="CONTINUE"
                            onClick={() => this.continue()}
                          />
                        </div>
                      )}
                    </Grid>
                  </section>
                ) : (
                  <section>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h2 className="sub_title">Event Entry</h2>
                      </Grid>

                      <Grid item xs={12}>
                        <div className="field_box activeColor">
                          <BaseTextField
                            value={entryTypeContent || ""}
                            phone={false}
                            search={false}
                            count={false}
                            label="Select Entry Type"
                            placeholder=""
                            style={styles.btn}
                            onClick={() =>
                              this.setState({
                                openDrawer: true,
                                isEntryType: true,
                              })
                            }
                            disabled={isEntryType}
                            error={error && !entryTypeContent}
                            helperText={
                              error &&
                              !entryTypeContent &&
                              " Please select an item in this list"
                            }
                            InputProps={true}
                          // disabled
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        {" "}
                        <div className="dasheddBorder_form"></div>
                      </Grid>
                      <Grid item xs={12}>
                        <h2 className="sub_title">Attendee(s)</h2>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="field_box" onClick={() =>
                          this.setState({
                            openDrawer: true,
                            isAttendee: true,
                          })
                        }>
                          <BaseImgChip
                            chipData={all_attendees_data}
                            onInputClick={() =>
                              this.setState({
                                openDrawer: true,
                                isAttendee: true,
                              })
                            }
                            onChipInputClick={() =>
                              this.setState({
                                openDrawer: true,
                                isAttendee: true,
                              })
                            }
                            deleteChip={this.handleAttendeeDelete}
                            label={"Select Attendee(s)"}
                            helperText={
                              error &&
                              all_attendees_show?.length === 0 &&
                              "Please select an item in this list"
                            }
                            error={error && all_attendees_show?.length === 0}
                            disabled={selectImage}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        {" "}
                        <div className="dasheddBorder_form"></div>
                      </Grid>
                      <Grid item xs={12}>
                        <h2 className="sub_title">Refreshments</h2>
                      </Grid>

                      <Grid item xs={12}>
                        <div className="field_box" onClick={() =>
                          this.setState({
                            openDrawer: true,
                            isRefreshment: true,
                          })
                        }>
                          <BaseImgChip
                            chipData={foodlist}
                            onInputClick={() =>
                              this.setState({
                                openDrawer: true,
                                isRefreshment: true,
                              })
                            }
                            onChipInputClick={() =>
                              this.setState({
                                openDrawer: true,
                                isRefreshment: true,
                              })
                            }
                            deleteChip={this.handleRefreshmentsDelete}
                            label={"Provided Refreshments"}
                            helperText={
                              error &&
                              foodList.length === 0 &&
                              "Please select an item in this list"
                            }
                            error={error && foodList.length === 0}
                            disabled={isRefreshment}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        <div className="border_top_bottm">
                          <FormSwitch
                            headline="Event Status"
                            body="Enabling would make the event available for publishing"
                            values={featured}
                            handleSwitchChange={() =>
                              this.setState({ featured: !featured })
                            }
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12}>
                        {loading ? (
                          <div
                            style={{
                              marginTop: 30,
                              display: "flex",
                              justifyContent: "center",
                              width: 500,
                            }}
                          >
                            <CircularProgress color="primary" />
                          </div>
                        ) : (
                          <div>
                            {activeStep === 1 && (
                              <div className="form_button">
                                <p
                                  className="cancel"
                                  onClick={() =>
                                    this.setState({
                                      activeStep: this.state.activeStep - 1,
                                    })
                                  }
                                >
                                  GO BACK
                                </p>
                                <BaseButton
                                  text="ADD NOW"
                                  onClick={() => this.addEvent()}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </section>
                )}
              </div>
            </section>
            <DrawerMain
              open={openDrawer}
              className="custom_drawer"
              openHandler={() => {
                if (selectImage) {
                  this.setState({
                    openDrawer: false,
                    selectImage: false,
                    tempImage: Image,
                  });
                }
                else if (isAttendee) {
                  this.setState({
                    openDrawer: false,
                    isAttendee: false,
                    all_attendees: this.state.prevattendees,
                    all_attendees_show:
                      all_attendees_show.length > 0 ? all_attendees_show : [],
                    attendees: this.state.prevattendees,
                    attendeestoShow: this.state.prevattendeestoShow,
                    customAttendees: this.state.prevCustomAttendees
                  });
                }
                else {
                  this.setState({
                    openDrawer: false,
                    isSalahDrawer: false,
                    isLocationDrawer: false,
                    isStartDate: false,
                    isEndDate: false,
                    isRepeatDays: false,
                    isAttendee: false,
                    isEntryType: false,
                    isRefreshment: false,
                    isSpeakers: false,
                  });
                }
              }}
              title={
                (isSalahDrawer && "Salah Name") ||
                (isLocationDrawer && "Event Location") ||
                (isStartDate && "Start Date and Time") ||
                (isEndDate && "End Date and Time") ||
                (isRepeatDays && "Repeats") ||
                (isAttendee && "Select Attendee(s)") ||
                (isEntryType && "Entry Type") ||
                (isRefreshment && "Refreshments") ||
                (isSpeakers && "Recent Guests") ||
                (selectImage && "Select image")
              }
              onCancel={() => {
                if (selectImage) {
                  this.setState({
                    openDrawer: false,
                    selectImage: false,
                    tempImage: Image,
                  });
                }
                if (isSpeakers) {
                  this.setState({
                    openDrawer: false,
                    isSpeakers: false,
                  });
                } else if (isLocationDrawer) {
                  this.setState({
                    openDrawer: false,
                    isLocationDrawer: false,
                    isError: false,
                    orgLocation: false,
                    LocationState: {
                      StreetAddress: "",
                      Suit_Number: "",
                      City: "",
                      State: "",
                      Zip_Code: "",
                      LocationName: "",
                    },
                  });
                } else if (isEndDate) {
                  this.setState({
                    openDrawer: false,
                    isEndDate: false,
                    dateError: false,
                    timeError: false,
                    fullEndDateandTime: fullEndDateandTime
                      ? fullEndDateandTime
                      : "",
                    EndDateandTime: "",
                  });
                  localStorage.removeItem("EndDate");
                } else if (isStartDate) {
                  this.setState({
                    openDrawer: false,
                    isStartDate: false,
                    dateError: false,
                    timeError: false,
                    fullStartDateandTime: fullStartDateandTime
                      ? fullStartDateandTime
                      : "",
                    startDateandTime: "",
                  });
                  localStorage.removeItem("StartDate");
                } else if (isRepeatDays) {
                  this.child.cancle();
                } else if (isEntryType) {
                  this.setState({
                    openDrawer: false,
                    isEntryType: false,
                  });
                } else if (isAttendee) {
                  this.setState({
                    openDrawer: false,
                    isAttendee: false,
                    all_attendees: this.state.prevattendees,
                    all_attendees_show:
                      all_attendees_show.length > 0 ? all_attendees_show : [],
                    attendees: this.state.prevattendees,
                    attendeestoShow: this.state.prevattendeestoShow,
                    customAttendees: this.state.prevCustomAttendees
                  });
                } else if (isRefreshment) {
                  this.setState({
                    openDrawer: false,
                    isRefreshment: false,
                  });
                }
              }}
              onSave={() => {
                if (selectImage) {
                  this.addLogo();
                } else if (isSpeakers) {
                  const tempSpeakers = this.state.tempSpeakers;
                  console.log("tempSpeakers", tempSpeakers)
                  this.setState({
                    openDrawer: false,
                    isSpeakers: false,
                    speakersChipData:
                      tempSpeakers && tempSpeakers.length > 0
                        ? tempSpeakers
                        : [],
                  });
                } else if (isLocationDrawer) {
                  this.locationAddress();
                } else if (isStartDate) {
                  const { temp_startDate, temp_startTime } = this.state;
                  if (!temp_startDate || !temp_startTime) {
                    this.setState({ dateError: true, timeError: true });
                  } else {
                    this.setState({
                      openDrawer: false,
                      isStartDate: false,
                      fullStartDateandTime: startDateandTime
                        ? startDateandTime
                        : fullStartDateandTime,
                      dateError: false,
                      timeError: false,
                    });
                  }
                } else if (isEndDate) {
                  const { temp_endDate, temp_endTime } = this.state;
                  if (!temp_endDate || !temp_endTime) {
                    this.setState({ dateError: true, timeError: true });
                  } else {
                    const endDateTime = EndDateandTime
                      ? EndDateandTime
                      : fullEndDateandTime;
                    this.setState({
                      openDrawer: fullStartDateandTime <= endDateTime ? false : true,
                      isEndDate: fullStartDateandTime <= endDateTime ? false : true,
                      fullEndDateandTime: endDateTime,
                      dateError: fullStartDateandTime <= endDateTime ? false : true,
                      timeError: fullStartDateandTime <= endDateTime ? false : true,
                      endDateError: fullStartDateandTime < endDateTime ? false : true,
                    });
                  }
                } else if (isRepeatDays) {
                  this.child.save();
                } else if (isEntryType) {
                  this.saveEntryTyp();
                } else if (isAttendee) {
                  let newAtt = customAttendees[0] ? customAttendees : ""
                  const filt = customAttendees.filter(tex => tex !== '')
                  const filterAtt = attendees.filter(item => !customAttendees.includes(item))
                  const filterAtt2 = attendeestoShow.filter(item => !customAttendees.includes(item))
                  this.setState({
                    openDrawer: false,
                    isAttendee: false,
                    all_attendees: filt.length ? [...filterAtt, ...filt] : [...attendees],
                    all_attendees_show: filt.length ? [...filterAtt2, ...filt] : [...attendeestoShow],
                    prevattendees: [...filterAtt],
                    prevattendeestoShow: [...filterAtt2],
                    prevCustomAttendees: [...customAttendees]
                  });
                } else if (isRefreshment) {
                  let removePotluck = false;
                  const { refreshment_card3, tempFoodList } = this.state;
                  let foodlist = tempFoodList;
                  if (refreshment_card3) {
                    if (foodlist.length === 0) {
                      foodlist.push({ primary: "Potluck" });

                    } else if (foodlist.map((e) => e.primary !== "Potluck")) {
                      foodlist.push({ primary: "Potluck" });
                    }
                  }
                  if (!refreshment_card3) {
                    if (foodlist.some((e) => e.primary === "Potluck")) {
                      foodlist = foodlist.filter(
                        (item) => item.primary !== "Potluck"
                      );
                    }
                  }
                  if (refreshment_card2 && tempFoodList.length === 0) {
                    this.setState({ foodlistItem: true });
                  } else {
                    this.setState({
                      openDrawer: false,
                      isRefreshment: false,
                      foodList: foodlist,
                      tempFoodList: [],
                      refreshment_card1: false,
                      refreshment_card2: false,
                      refreshment_card3: false,
                    });
                  }
                }
              }}
            >
              <div
                className="sidebar"
                style={{ height: "100%", position: "relative" }}
              >
                {selectImage && (
                  <>
                    <div className="event_Image">
                      {tempImage && tempImage.length > 0 && !hideImage && (
                        <List
                          className="img-details"
                          style={{ paddingLeft: 0 }}
                        >
                          {tempImage.map((file, index) => (
                            <ListItem
                              key={file.name}
                              style={{ paddingLeft: 0 }}
                            >
                              <ListItemAvatar>
                                <Avatar
                                  variant="square"
                                  alt={"preview"}
                                  src={file.src}
                                />
                              </ListItemAvatar>

                              <ListItemText
                                primary={file.name.slice(0, 20)}
                                secondary={formatBytes(file.size)}
                              />
                              <ListItemSecondaryAction
                                style={{ paddingLeft: 20 }}
                              >
                                <IconButton
                                  aria-label="delete"
                                  size="medium"
                                  style={{ padding: "0px" }}
                                  onClick={() => this.handleRemove(index)}
                                >
                                  <DeleteForeverIcon fontSize="inherit" />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          ))}
                        </List>
                      )}
                      <div>
                        <div style={{ marginBottom: 20 }}>
                          <BaseFileSelect
                            selectedFiles={(file) => this.upload(file)}
                            showMediaText={true}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {isLocationDrawer && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="salahStatus">
                        <h2>Venue</h2>
                      </div>
                      <div>
                        <BaseSwitch
                          checked={this.state.orgLocation}
                          onChange={this.handleSwitchChange("orgLocation")}
                        />
                      </div>

                    </div>
                    {this.state.orgLocation ? (
                      <div
                        className="orgName"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          marginTop: "10px",
                        }}
                      >
                        {orgName && <h2>{orgName}</h2>}
                        {orgAddress && (<>
                          <p>{`${orgAddress.line1}. `} </p>
                          <p> {`${orgAddress.city}, ${stateAbbreviations[orgAddress.state] ? stateAbbreviations[orgAddress.state]
                            : orgAddress.state} ${orgAddress.postalCode
                            }`}</p></>
                        )}
                      </div>
                    ) : (

                      <div className="drawer_form">
                        {LocationArray.map((item, index) => {
                          return (
                            <>
                              {item !== "State" && (
                                <BaseTextField
                                  key={index}
                                  value={
                                    LocationState[LocationArrayNames[index]] ||
                                    ""
                                  }
                                  onChange={(e) => {

                                    e.persist();
                                    if (
                                      (item === "Location Name" &&
                                        stringRegex.test(e.target.value)) ||
                                      (item === "Suite/FL Number" &&
                                        e.target.value.length <= 10 &&
                                        flatReg.test(e.target.value)) ||
                                      (item === "State" &&
                                        stringRegex.test(e.target.value)) ||
                                      (item === "Street Address" &&
                                        flatReg.test(e.target.value)) ||
                                      (item === "Zip code" &&
                                        e.target.value.length <= 10 &&
                                        zipReg.test(e.target.value)) ||
                                      (item === "City" &&
                                        e.target.value.length <= 15 &&
                                        stringRegex.test(e.target.value))
                                    ) {
                                      this.setState((prevState) => ({
                                        LocationState: {
                                          ...prevState.LocationState,
                                          [LocationArrayNames[index]]:
                                            e.target.value,
                                        },
                                      }));
                                    }
                                  }}
                                  InputProps={false}
                                  phone={false}
                                  search={false}
                                  count={false}
                                  label={item}
                                  placeholder=""
                                  style={styles.btn}
                                  helperText={
                                    isError &&
                                    item !== "Suite/Fl Number" &&
                                    !LocationState[LocationArrayNames[index]] &&
                                    item + " " + "required"
                                  }
                                  error={
                                    isError &&
                                    item !== "Suite/Fl Number" &&
                                    !LocationState[LocationArrayNames[index]]
                                  }
                                />
                              )}
                              {item === "State" && (
                                <BaseSelect
                                  object={states}
                                  label="State"
                                  style={{
                                    width: "100%",
                                    margin: 0,
                                    marginBottom: 26,
                                    minHeight: "48px",
                                  }}
                                  value={
                                    LocationState[LocationArrayNames[index]] ||
                                    ""
                                  }
                                  onChange={(e) => {
                                    e.persist();
                                    this.setState((prevState) => ({
                                      LocationState: {
                                        ...prevState.LocationState,
                                        [LocationArrayNames[index]]:
                                          e.target.value,
                                      },
                                    }));
                                  }}
                                  helperText={
                                    isError &&
                                    item !== "Suite/Fl Number" &&
                                    !LocationState[LocationArrayNames[index]] &&
                                    item + " " + "required"
                                  }
                                  error={
                                    isError &&
                                    item !== "Suite/Fl Number" &&
                                    !LocationState[LocationArrayNames[index]]
                                  }
                                />
                              )}
                            </>
                          );
                        })}
                      </div>
                    )}

                    <div className="DrawerSocial">

                      <div className="DrawerSocialDetails">
                        <div className="DrSwitchBox"><img src={Zoom} /> Zoom</div>
                        <BaseSwitch
                          checked={this.state.zoom}
                          onChange={this.handleSwitchChange("zoom")}
                        />
                      </div>

                      <div>
                        {this.state.zoom ? (
                          <div>
                            <div className="drw_field">
                              <label>Zoom Link</label>
                              <textarea className="drw_fieldArea"></textarea>
                            </div>

                            <div className="drw_field">
                              <label>Description (Optional)</label>
                              <textarea className="drw_fieldArea"></textarea>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>


                      <div className="DrawerSocialDetails">
                        <div className="DrSwitchBox"><img src={Facebook} />Facebook Live</div>
                        <BaseSwitch
                          checked={this.state.Facebook}
                          onChange={this.handleSwitchChange("Facebook")}
                        />
                      </div>

                      <div>
                        {this.state.Facebook ? (
                          <div>
                            <div className="drw_field">
                              <label>Facbook Live Link</label>
                              <textarea className="drw_fieldArea"></textarea>
                            </div>

                            <div className="drw_field">
                              <label>Description (Optional)</label>
                              <textarea className="drw_fieldArea"></textarea>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>


                      <div className="DrawerSocialDetails">
                        <div className="DrSwitchBox"><img src={Youtube} />Youtube</div>
                        <BaseSwitch
                          checked={this.state.Youtube}
                          onChange={this.handleSwitchChange("Youtube")}
                        />
                      </div>

                      <div>
                        {this.state.Youtube ? (
                          <div>
                            <div className="drw_field">
                              <label>Youtube Live Link</label>
                              <textarea className="drw_fieldArea"></textarea>
                            </div>

                            <div className="drw_field">
                              <label>Description (Optional)</label>
                              <textarea className="drw_fieldArea"></textarea>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>


                      <div className="DrawerSocialDetails">
                        <div className="DrSwitchBox"><img src={Teleconference} />Teleconference</div>
                        <BaseSwitch
                          checked={this.state.Teleconference}
                          onChange={this.handleSwitchChange("Teleconference")}
                        />
                      </div>

                      <div>
                        {this.state.Teleconference ? (
                          <div>
                            <div className="drw_field">
                              <label>Dial In Number</label>
                              <div className="field_box activeColor">
                                <BaseTextField
                                  label=""
                                />
                              </div>
                            </div>

                            <div className="drw_field">
                              <label>Additional Details</label>
                              <textarea className="drw_fieldArea"></textarea>
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>




                    </div>
                  </>

                )}
                {isStartDate && (
                  <div className="isStartDate">
                    <DrawerDateTime
                      title={""}
                      hidecheck={true}
                      dateLabel={"Event Starts"}
                      timeLabel={"Start Time"}
                      switchLabel={"No start date"}
                      getDateTime={this.getDateTime}
                      defaultDate={startDateToFormat}
                      defaultTime={startTimeToFormat}
                      getTime={this.startTime}
                      dateError={dateError}
                      dateHelperText={
                        dateError &&
                        !this.state.temp_startDate &&
                        "Event start date is required"
                      }
                      timeError={timeError}
                      timeHelperText={
                        timeError &&
                        !this.state.temp_startTime &&
                        "Start time is required"
                      }
                      useAsPublish={false}
                    />
                    <BaseSelect
                      value={time_zone}
                      onChange={this.handleTimeZoneChange}
                      label="Time zone"
                      object={timezoneObject}
                      style={styles.select}
                      helperText={error && !time_zone && "Time zone is required"}
                      error={error && !time_zone}
                    />
                  </div>
                )}
                {isEndDate && (
                  <div className="isEndDate">
                    <DrawerDateTime
                      title={""}
                      hidecheck={true}
                      defaultDate={enddateToFormat}
                      defaultTime={endTimeToFormat}
                      dateLabel={"Event Ends"}
                      timeLabel={"End Time"}
                      switchLabel={"No end date"}
                      getDateTime={this.getEndDateTime}
                      getTime={this.endTime}
                      dateError={dateError}
                      dateHelperText={
                        dateError &&
                        !this.state.temp_endDate &&
                        "Event end date is required" ||
                        (endDateError &&
                          "Event end date should be greater than event starting date")
                      }
                      timeError={timeError}
                      timeHelperText={
                        timeError &&
                        !this.state.temp_endTime &&
                        "End time is required"
                      }
                      useAsPublish={false}
                    />
                  </div>
                )}
                {isRepeatDays && (
                  <>
                    <Repeat
                      callbackFromParent={this.saveRepeats}
                      canclecallbackFromParent={this.cancleRepeats}
                      ref={(ref) => (this.child = ref)}
                      maxDate={enddateToFormat}
                      initialEndDate={startDateToFormat || new Date()}
                      category={repeat_object.repeat_category}
                      repeats_object={repeat_object.repeats_Object}
                      frequency={repeat_object.frequency}
                      repeat_every={repeat_object.repeat_every}
                      repeat_on={repeat_object.repeat_on}
                      oneTimeTitle={"event"}
                    />
                  </>
                )}
                {isEntryType && (
                  <div style={{ height: "inherit" }}>
                    <div className="DrawerCard_conainer">
                      <DrawerCardMidAvatar
                        customIcon={
                          <PublicIcon
                            style={
                              card1
                                ? { color: "#2490F9" }
                                : { color: "#D3D8DA" }
                            }
                          />
                        }
                        cardCheck={card1}
                        avatarAlt={"media"}
                        cardFunction={(props) =>
                          this.cardFunction("card1", {
                            entryType: "Public",
                            feeOption: "free",
                          })
                        }
                        header={"Public"}
                        body={"Free Entry"}
                      />
                      <DrawerCardMidAvatar
                        customIcon={card2 ? <Invoice_blue /> : <Invoice />}
                        avatarAlt={"media"}
                        cardCheck={card2}
                        cardFunction={() =>
                          this.cardFunction("card2", {
                            entryType: "Public",
                            feeOption: "required",
                          })
                        }
                        header={"Public"}
                        body={"Registration Fee Required"}
                      />
                      <DrawerCardMidAvatar
                        customIcon={
                          <ReceiptIcon
                            style={
                              card3
                                ? { color: "#2490F9" }
                                : { color: "#D3D8DA" }
                            }
                          />
                        }
                        cardCheck={card3}
                        avatarAlt={"media"}
                        cardFunction={() =>
                          this.cardFunction("card3", {
                            entryType: "Public",
                            feeOption: "optional",
                          })
                        }
                        header={"Public"}
                        body={"Registration Fee Optional"}
                      />
                      <DrawerCardMidAvatar
                        customIcon={card4 ? <Private_blue /> : <Private_grey />}
                        cardCheck={card4}
                        avatarAlt={"media"}
                        cardFunction={() =>
                          this.cardFunction("card4", {
                            entryType: "Private",
                            feeOption: "restricted",
                          })
                        }
                        header={"Private"}
                        body={"Entry Restricted to Personnel"}
                      />
                    </div>
                    <div>
                      {(card2 || card3) && this.renderRequirementInput()}
                    </div>
                  </div>
                )}
                {isAttendee && (
                  <div>
                    <div className="sidebar_parent">
                      <div className="attendees">
                        <div className="gender">
                          <h1>Select Gender</h1>
                          <div className="drawer_btn">
                            <DrawerCardSmall
                              header={"Brothers"}
                              tick={all_attendees.includes("male")}
                              cardFunction={(e) =>
                                this.attendeeFunc("male", "Brothers")
                              }
                            />
                            <DrawerCardSmall
                              header={"Sisters"}
                              tick={all_attendees.includes("female")}
                              cardFunction={(e) =>
                                this.attendeeFunc("female", "Sisters")
                              }
                            />
                          </div>
                        </div>
                        <div className="gender">
                          <h1>Select Age Group</h1>
                          <div className="drawer_btn">
                            <DrawerCardSmall
                              header={"Seniors"}
                              tick={all_attendees.includes("seniors")}
                              body={"65+"}
                              cardFunction={() =>
                                this.attendeeFunc("seniors", "Seniors")
                              }
                            />
                            <DrawerCardSmall
                              header={"Adult"}
                              tick={all_attendees.includes("adult")}
                              body={"25-64"}
                              cardFunction={() =>
                                this.attendeeFunc("adult", "Adults")
                              }
                            />
                          </div>

                          <div className="drawer_btn">
                            <DrawerCardSmall
                              header={"Youth"}
                              tick={all_attendees.includes("youth")}
                              body={"14-25"}
                              cardFunction={() =>
                                this.attendeeFunc("youth", "Youth")
                              }
                            />
                            <DrawerCardSmall
                              header={"Children"}
                              tick={all_attendees.includes("children")}
                              body={"7-14"}
                              cardFunction={() =>
                                this.attendeeFunc("children", "Children")
                              }
                            />
                          </div>
                          <h1>Religion (Optional)</h1>
                          <div className="drawer_btn">
                            <DrawerCardSmall
                              header={"Muslims"}
                              tick={all_attendees.includes("muslims")}
                              cardFunction={() =>
                                this.attendeeFunc("muslims", "Muslims")
                              }
                            />
                            <DrawerCardSmall
                              header={"Non-Muslims"}
                              tick={all_attendees.includes("nonMuslims")}
                              cardFunction={() =>
                                this.attendeeFunc("nonMuslims", "Non-Muslims")
                              }
                            />
                          </div>
                        </div>
                        <div className="gender">
                          <h1>Profession (Optional)</h1>
                          <div className="full_width_button">
                            <DrawerCardSmall
                              header={"Faith Leaders"}
                              tick={all_attendees.includes("faithLeaders")}
                              cardFunction={() =>
                                this.attendeeFunc(
                                  "faithLeaders",
                                  "Faith Leaders"
                                )
                              }
                            />
                            <DrawerCardSmall
                              header={"Social Activists"}
                              tick={all_attendees.includes("socialActivists")}
                              cardFunction={() =>
                                this.attendeeFunc(
                                  "socialActivists",
                                  "Social Activists"
                                )
                              }
                            />
                            <DrawerCardSmall
                              header={"Political Leaders"}
                              tick={all_attendees.includes("politicalLeaders")}
                              cardFunction={() =>
                                this.attendeeFunc(
                                  "politicalLeaders",
                                  "Political Leaders"
                                )
                              }
                            />
                          </div>
                          {addProfession && customAttendees && customAttendees.map((item, index) => {
                            return (
                              <div className="add-attendee-input" key={index}>
                                <input
                                  type="text"
                                  placeholder="Add new profession"
                                  value={item}
                                  onChange={(e) => this.handleText(index, e)}
                                />
                                <img
                                  src={Delete}
                                  onClick={(e) => this.handleDelete(index, e)}
                                />
                              </div>
                            )
                          })}
                          <div className="add_another_btn">
                            <img src={addCircle} />
                            <span
                              onClick={(e) => this.addCustomAttendee(e)}
                            >
                              add another
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRefreshment && (
                  <div style={{ height: "inherit" }}>
                    <div className="sidebar_parent">
                      <div>
                        <h1>Select one or multiple</h1>
                        <DrawerCardMidAvatar
                          customIcon={
                            <NoFoodIcon
                              style={
                                refreshment_card1
                                  ? { color: "#2490F9" }
                                  : { color: "#D3D8DA" }
                              }
                            />
                          }
                          cardCheck={refreshment_card1}
                          avatarAlt={"media"}
                          cardFunction={() =>
                            this.refreshment("refreshment_card1")
                          }
                          header={"No Food"}
                          body={"No food provided at venue"}
                        />
                        <DrawerCardMidAvatar
                          customIcon={
                            <FastFoodIcon
                              style={
                                refreshment_card2
                                  ? { color: "#2490F9" }
                                  : { color: "#D3D8DA" }
                              }
                            />
                          }
                          avatarAlt={"media"}
                          cardCheck={refreshment_card2}
                          cardFunction={() =>
                            this.refreshment("refreshment_card2")
                          }
                          header={"Provided"}
                          body={"Food available at venue"}
                        />
                        <DrawerCardMidAvatar
                          customIcon={
                            <PotluckIcon
                              style={
                                refreshment_card3
                                  ? { color: "#2490F9" }
                                  : { color: "#D3D8DA" }
                              }
                            />
                          }
                          cardCheck={refreshment_card3}
                          avatarAlt={"media"}
                          cardFunction={() =>
                            this.refreshment("refreshment_card3")
                          }
                          header={"Potluck"}
                          body={"Attenders can contribute food"}
                        />
                      </div>
                      <div>
                        {refreshment_card3 && (
                          <div className="potluck">
                            <h1>Potluck Details</h1>
                            <BaseTextField
                              value={potluckContact || ""}
                              onChange={(e) =>
                                this.setState({
                                  potluckContact: e.target.value,
                                })
                              }
                              InputProps={false}
                              phone={false}
                              search={false}
                              count={false}
                              label="Contact No"
                              placeholder=""
                              style={styles.btn}
                            />
                            <p>
                              Please provide the phone number of the
                              admin/manager attendees can contact for details
                            </p>
                          </div>
                        )}
                        {refreshment_card2 && (
                          <div className="requirementCont">
                            <h1>Select Provided Food</h1>
                            {foodlistItem && (
                              <span className="foodlistitem">
                                Food list item required
                              </span>
                            )}
                            <div className="cardDrawer">
                              <DrawerListCheckbox
                                objects={refreshmentFood}
                                getData={(e) => {
                                  console.log("refreshmentFood", e)
                                  this.setState((prevState) => {
                                    return {
                                      ...prevState,
                                      tempFoodList:
                                        e.length > 0
                                          ? e
                                          : prevState.tempFoodList,
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {isSpeakers && speakers && (
                  <div className="speakers">
                    {speakers && speakers.length > 0 && (
                      <div className="first_child">
                        <DrawerListCheckbox
                          objects={speakers.slice(0, speakersToShow)}
                          subheader={""}
                          getData={this.getSpeakers}
                          selectedItems={this.state.tempSpeakers}
                        />
                        {speakers.length > 3 && (
                          <div className="showMore">
                            {speakersToShow === 4 && (
                              <span
                                onClick={() =>
                                  this.setState({
                                    speakersToShow: speakers.length,
                                    // speakersToShow: speakersToShow + 1,
                                  })
                                }
                              >
                                Show More
                              </span>
                            )}
                            {speakersToShow > 4 && (
                              <span
                                onClick={() =>
                                  this.setState({ speakersToShow: 4 })
                                }
                              >
                                Show Less
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    <div className="DrawerInfo">
                      <div className="DrawerInfoTitle">Don't see the guests?</div>
                      Add new guests from <Link to="/organization/guests" >Organization &gt; Guests.</Link>
                    </div>
                    <div className="second_child">



                      {/* <div className="email_div">
                        <BaseCheckBox
                          label="Send email to speaker(s) once this event is published"
                          checked={speakerEmail}
                          color="secondary"
                          showHelperText={false}
                          onChange={(e) =>
                            this.setState({
                              speakerEmail: !this.state.speakerEmail,
                            })
                          }
                        />
                        <BaseCheckBox
                          label="Send reminder email to speaker(s) 1 day before event starts (coming soon)"
                          checked={reminderEmail}
                          color="secondary"
                          showHelperText={false}
                          onChange={(e) =>
                            this.setState({
                              reminderEmail: !this.state.reminderEmail,
                            })
                          }
                        />
                      </div> */}
                      <div></div>
                    </div>
                  </div>
                )}
              </div>
            </DrawerMain>
          </>
        }
        isFooter={true}
        footer={<FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />}
        isWhiteBackground={true}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alertValue: state.alert.alertValue,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showAlert: (res) => dispatch(showAlert(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);
