import React from 'react'
import {ComponentOtpSuccessCard} from '@dclab/dc-react-ui-kit'
import Logo from "../../assets/img/DC_Logo.png";
import './style.css'
function GetStarted(props) {
    const onClick = () => {
        props.history.replace('/signup')
    }
    return (
        <div className="container-success" style={{position: "relative"}} >
            <div style={{position: "absolute", width: '100%',zIndex: -1}} >
                <div className='Background-Image' style={{ width: '100%', position: 'fixed', height: '100vh' }}>
                </div>
            </div>
            <div className='div_backgrndimg-pattern' style={{paddingTop: 100}}>
                <ComponentOtpSuccessCard src={Logo} onClick={onClick}/>
            </div>
        </div>
    )
}
export default GetStarted
