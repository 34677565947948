import React, { Component } from "react";
import {
  VerificationSuccess,
  SimpleAppBar,
  AlertAction,
} from "@dclab/dc-react-ui-kit";
import { connect } from "react-redux";
import Logo from "../../assets/img/DC_Logo.png";
import ErrorIcon from "../../assets/img/error_outline-24px.svg";
import Done from '../../assets/img/Done.svg'
import { connectOrganization } from "./api";
import { connectOrgHandler } from "../../redux/actions/newOrgAction";
import Footer from "../../components/Footer/Footer";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./onboarding.css";
import "./style.css";
import "../style.css";
class StripeConnectSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      success: false,
      loading: true,
      showAlert: true
    };
    this.onClick = this.onClick.bind(this);
  }
  
  async componentDidMount() {
    var urlParams = new URLSearchParams(window.location.search);
    let scope = urlParams.get("scope");
    let code = urlParams.get("code");
    let orgId = localStorage.getItem("orgID");
    const obj = {
      scope,
      code,
      orgId,
    };
    try {
      const res = await connectOrganization(
        obj,
        localStorage.getItem("authToken")
      );
      this.setState({ success: true, loading: false });
      this.props.connectOrg(res.data.body.data);
    if(window.location.href.indexOf("?dashboard=true") !== -1) {
      this.props.history.replace("/dashboard");
    } else {
      this.props.history.replace("/setup");
    }
    } catch (e) {
      this.setState({
        error: e.response.data.message,
        loading: false,
        showAlert: false
      });
    }
  }
  onClick = async () => {
    const { error, success } = this.state;
    if (error) {
      this.props.history.replace("/setup");
    } else {
      var urlParams = new URLSearchParams(window.location.search);
      let scope = urlParams.get("scope");
      let code = urlParams.get("code");
      let orgId = localStorage.getItem("orgID");
    }
  };
  logout = () => {
    localStorage.setItem("user", false);
    localStorage.setItem("authToken", "");
    this.props.history.replace("/signin");
  };
  render() {
    const { error, loading, success } = this.state;
    return (
      <div>
        {error && (
          <div
            style={{
              zIndex: 9999,
              width: "60%",
              position: "fixed",
              top: 35,
              marginLeft: 400,
            }}
          >
            <AlertAction
              alert={"Authorization code expired"}
              alertType={"error"}
              onClick={() => this.setState({ error: false })}
            />
          </div>
        )}
        <header style={styles.header} className="app-bar">
          <SimpleAppBar
            src={Logo}
            name={localStorage.getItem("name")}
            role={localStorage.getItem("role")}
            onClickLogout={() => this.logout()}
            style={{ marginTop: 80, marginLeft: -10 }}
          />
        </header>
        <section style={styles.container}>
          <div style={{ height: "100vh", marginBottom: 50 }}>
            {loading ? (
              <div
                style={{
                  marginTop: 30,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 450,
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : success ? (
              <div className="stripe-success-card">
                {this.state.showAlert && (
                  <div
                    style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                  >
                    <AlertAction
                      alert={"Stripe connected successfully!"}
                      alertType={'success'}
                      onClick={() => this.setState({showAlert : false})}
                    />
                  </div>
                )}
                <VerificationSuccess
                  name="verifySuccess"
                  src={Logo}
                  title="Connected Successfully!"
                  para="Awesome! You've successfully registered your awesome organization with us. Enjoy our services."
                  btnText="Go to dashboard"
                  onClickVerify={() => this.onClick()}
                  img={Done}
                />
              </div>
            ) : (
                  <div className="stripe-success-card">
                    <VerificationSuccess
                      img={ErrorIcon}
                      name="verifySuccess"
                      src={Logo}
                      title="Couldn't connect to Stripe"
                      para={[
                        "Oops! We couldn't connect with your stripe account. Please contact us at ",
                        <a
                          style={{
                            color: "#2490F9",
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                          href="mailto:support@deen360.com"
                        >
                          support@deen360.com
                    </a>,
                        " if you face more issues.",
                      ]}
                      btnText="Go to dashboard"
                      onClickVerify={() => this.onClick()}
                    />
                  </div>
                )}
          </div>
          <div style={{ height: "62%" }}>
            <Footer customClass="setup-footer-stripe" style={{ left: "11%" }} />
          </div>
        </section>
      </div>
    );
  }
}
const styles = {
  header: {
    boxShadow: "0px 1px 4px #0000001A",
    borderBottom: "1px solid #0000001A",
    position: "fixed",
    width: "100%",
    zIndex: 999,
    backgroundColor: "#F7F8FA",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
};
const mapStateToProps = (state) => {
  return {
    org: state.addOrg.orgId,
    signin: state.signin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    connectOrg: (data) => dispatch(connectOrgHandler(data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StripeConnectSuccess);
