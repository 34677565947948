import React, { useEffect, useState } from "react";
import { Uppload, fetchUploader, Camera, Facebook, URL, Screenshot, Local, xhrUploader, en, Crop,  Blur, Contrast, Rotate, Flip, Grayscale, HueRotate, Sepia } from "uppload";
import "uppload/dist/uppload.css";
import "uppload/dist/themes/light.css";

import AddRoundedIcon from '@material-ui/icons/AddRounded';

import YoutubeModal from './YoutubeModal' ;
import MediaLibraryModal from './MediaLibraryModal' ;

export default function ImageUploader(props) {

  const token = localStorage.getItem("authToken");
  useEffect(() => {
    const uploader = new Uppload({
      value: "https://via.placeholder.com/150",
      lang: en,
      uploader: fetchUploader({
        endpoint: props.endPoint,
        settingsFunction: file => {
          // You get the file blob
          // For example, add a custom header for auth and send a PUT request
          const formData = new FormData();
          formData.append("image", file);
          return {
            method: "POST",
            body: formData,
            headers: {
              "token": token
            }
          };
        },
        responseFunction: json => {
          props.onChange();
          return json.results[0];
        }
      }),
      bind: document.querySelector("img.uppload-pic"),
      call: document.querySelector(".pic-btn")
    });
    uploader.use([new Local(), new Camera(),  new URL(), new Screenshot(), new Facebook() ])
  }, [])

  const handleClick = (size) => {
    // console.log('I am in uploader ');
    props.onChange(size);
  };  

  return (
    <div>      
      <div className="mediaLibActionButton">        
        <YoutubeModal loadingIcon={props.loadingIcon} onChange={handleClick} maxLimit={props.maxLimit} className="AddVideoModalButton" labelName="ADD VIDEO" initialtab="1" />
        <YoutubeModal loadingIcon={props.loadingIcon} onChange={handleClick} maxLimit={props.maxLimit} className="AddVideoModalButton" labelName="UPLOAD PHOTO" initialtab="0"/>
      </div>
    </div>
  );
}
