import React from "react";
import {useEffect, useState} from 'react';
import { BaseTextField, MasterLayoutMiddle, FooterInner, BasePhoneInput, BaseButton, BaseCheckBox  } from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import ChevronLeft from "@material-ui/icons/ChevronLeft";



function AddContact(props) {

    const style = {
        width: '100%',
    };

    const styles = {
        btn: {
            height: 66,
            width: "100%",
            margin: 8,
            border: "#D3D8DA",
        },
    };

    const [values, setValues] = React.useState({
        name: "hello",
        number: "",
    });

    const handleChange = (prop) => (event) => {
        setValues({
            ...values,
            [prop]: event.target.value,
        });
    };

    const [phone, setPhone] = React.useState({
        country: "",
        number: "",
    });

    const [checked, setChecked] = useState(true);

    useEffect(() => {
        console.log(`Your ${checked} function here`);
    }, [checked]);

    return (
        <MasterLayoutMiddle
            header={
                <div
                    onClick={() => this.props.history.replace("/deenfund/categories")}
                    className="backBtn"
                >
                    <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                    <p style={{ alignSelf: "center" }}>Back</p>{" "}
                </div>
            }
            isForm={true}
            formData={
                <div>

                    <div>
                        <h1 className="title_top">Edit Contact</h1>
                        <div className="dasheddBorder"></div>
                    </div>
                    <Grid container spacing={3}>

                        <Grid item xs={6}>
                            <div className="field_box">
                                <BaseTextField
                                    label="First Name"
                                    //value={values.name}
                                    onChange={handleChange("name")}
                                    style={styles.btn}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Last Name"
                                    //value={values.name}
                                    onChange={handleChange("name")}
                                    style={styles.btn}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Email"
                                    //value={values.name}
                                    onChange={handleChange("name")}
                                    style={styles.btn}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="field_box telInputcontainer">
                                <BasePhoneInput
                                    phoneInputVariant={"underlineWithFlag"} // default, materialui, underlineInput, underlineWithFlag
                                    country={'us'}
                                    value={phone.number}
                                    countryCodeEditable={false}
                                    onChange={(phone, country) => console.log("onChange", phone, country)}
                                    onBlur={() => console.log("onBlur")}
                                    onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                                    variant="outlined"
                                    onlyCountries={["us", "ca", "gb", "my"]}
                                    containerStyle={{}}
                                    buttonStyle={{}}
                                    inputStyle={{
                                        marginLeft: "0px",
                                        height: "35px",
                                        width: "100%",
                                        fontFamily: "Roboto-Regular",
                                        fontSize: "16px",
                                    }}
                                    containerClass={{}}
                                    //errorText={"Phone number is required"}
                                    error={true}
                                    errorStyles={{ marginBottom: "5px" }}
                                    autoFormat={true}
                                    disableDropdown={false}
                                    disabled={false}
                                    disableCountryCode={false}
                                    enableSearch={false}
                                    disableSearchIcon={false}
                                    placeholder={""}
                                    inputProps={{}}
                                    specialLabel={'Primary Phone'}
                                // preferredCountries={[]}
                                // excludeCountries={[]}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box border_top_bottm">
                                <BaseCheckBox
                                    label="Send verification email to contact"
                                    checked={checked}
                                    color="secondary"
                                    onChange={(e) => setChecked(e.target.checked)}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>

                            <div className="form_button">
                                <p
                                    className='cancel'
                                    onClick={() => this.onCancelOrgDetail()
                                    }
                                >
                                    CANCEL
                                </p>
                                <BaseButton text='ADD NOW' onClick={() => this.updateOrg()} />
                            </div>

                        </Grid>

                    </Grid>

                </div>
            }
            isFooter={true}
            footer={<FooterInner 
                    style={style}
                    termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
                    privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                    />}
            isWhiteBackground={true}
        />
    );
}

export default AddContact;
