import React from 'react';
import { SelectFieldOne, TextInputFixedLabel, PhoneInputField, } from "master-react-lib";
import Grid from '@material-ui/core/Grid';

function VolunteerContact(props) {
    const [phone, setPhone] = React.useState({
        country: "",
        number: "",
    });

    const selectProfession = [
        { value: 1, select: "NY" },
        { value: 2, select: "ABC" },
    ];

    const handleChangeGender = (event, value) => {
        console.log(event.target.value, value);
    };


    return (

        <div>
            <Grid container spacing={3}>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <PhoneInputField
                            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                            country={'us'}
                            value={phone.number}
                            countryCodeEditable={false}
                            onChange={(phone, country) => console.log("onChange", phone, country)}
                            onBlur={() => console.log("onBlur")}
                            onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                            variant="outlined"
                            // onlyCountries={["us", "ca", "gb", "my"]}
                            containerStyle={{}}
                            buttonStyle={{}}
                            inputStyle={{
                                marginLeft: "0px",
                                height: "40px",
                                width: "100%",
                                fontFamily: "Roboto-Regular",
                                fontSize: "16px",
                            }}
                            containerClass={{}}
                            errorText={"Phone number is required"}
                            error={false}
                            errorStyles={{ marginBottom: "5px" }}
                            autoFormat={true}
                            disableDropdown={false}
                            disabled={false}
                            disableCountryCode={false}
                            enableSearch={false}
                            disableSearchIcon={false}
                            placeholder={""}
                            inputProps={{}}
                            specialLabel={'Primary Phone'}
                            // preferredCountries={[]}
                            // excludeCountries={[]}
                            labelFixed="Phone Number"
                            LabelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="Email Address"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div className="field_box">
                        <TextInputFixedLabel
                            label="City/Town"
                            labelStatus={true}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={4}>
                    <div className="field_box">
                        <SelectFieldOne
                            label="State/Province"
                            selectdata={selectProfession}
                            defaultValue={1}
                            handleChange={handleChangeGender}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={12}>
                    <div className="field_box">
                        <PhoneInputField
                            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                            country={'us'}
                            value={phone.number}
                            countryCodeEditable={false}
                            onChange={(phone, country) => console.log("onChange", phone, country)}
                            onBlur={() => console.log("onBlur")}
                            onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
                            variant="outlined"
                            // onlyCountries={["us", "ca", "gb", "my"]}
                            containerStyle={{}}
                            buttonStyle={{}}
                            inputStyle={{
                                marginLeft: "0px",
                                height: "40px",
                                width: "100%",
                                fontFamily: "Roboto-Regular",
                                fontSize: "16px",
                            }}
                            containerClass={{}}
                            errorText={"Phone number is required"}
                            error={false}
                            errorStyles={{ marginBottom: "5px" }}
                            autoFormat={true}
                            disableDropdown={false}
                            disabled={false}
                            disableCountryCode={false}
                            enableSearch={false}
                            disableSearchIcon={false}
                            placeholder={""}
                            inputProps={{}}
                            specialLabel={'Phone Number'}
                            // preferredCountries={[]}
                            // excludeCountries={[]}
                            labelFixed="Phone Number"
                            LabelStatus={true}
                        />
                    </div>
                </Grid>



            </Grid>

        </div>

    );
}


export default VolunteerContact;