import * as constants from "../constants";

function editAnnoucement(data) {
    return {
        type: constants.EDIT_ANNOUCEMENT,
        payload: data
    };
}

function emptyAnnoucement() {
    return {
        type: constants.EMPTY_ANNOUCEMENT
    };
}

export { editAnnoucement, emptyAnnoucement };