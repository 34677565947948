import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import scheduleIcon from '../../assets/nav_icons/schedule.svg'
import {
  BaseTextField,
  BaseButton,
  AlertAction,
  DrawerMain,
  EventLogs,
  DrawerListRadioButton,
  DrawerSchedule,
  MasterLayoutMiddle,
  FooterInner
} from "@dclab/dc-react-ui-kit";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./style.css";
import editIcon from "../../assets/nav_icons/edit.svg";
import deleteIcon from "../../assets/nav_icons/delete.svg";
import addCircle from "../../assets/nav_icons/add_circle.svg";
import { updatePlaylist, getAllPlaylists } from "./Apis"
import Grid from '@material-ui/core/Grid';
import messages_content from '../../utils/messages.json';
const { 
    token_expired,
    success_message_add_playlist
  } = messages_content;
const AddPlaylist = (props) => {
  const history = useHistory();
  const [error, seterror] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [success, setsuccess] = useState(false);
  const [loading, setloading] = useState(false);
  const [playlist, setplaylist] = useState("Home Screen");
  const [openDrawer, setopenDrawer] = useState(false)
  const [isPlaylist, setPlaylist] = useState(false)
  const [playlistId, setPlaylistId] = useState('')
  const [isSchedule, setIsSchedule] = useState(false)
  const [deviceName, setDeviceName] = useState('')
  const [editBoolean, setEditBoolean] = useState(false);
  const [title, setTitle] = useState("");
  const [index, setIndex] = useState("");
  const [days, setDays] = useState("")
  let weekdays = []
  const [open, setOpen] = useState(false);
  const [timeRanges, settimeRanges] = useState([
    { startTime: "", endTime: "" }
  ]);
  const [day, setDay] = useState([]);
  const [dateRange, setdateRange] = React.useState("No specific date");
  const [activePlaylists, setActivePlayList] = useState()
  const [schedule, setSchedule] = useState([
    {
      id: 1, icon: scheduleIcon, status: 'Schedule 1', editIcon: editIcon, deleteIcon: deleteIcon, details: [
        { title: 'Date Range', message: 'No specific date' },
        { title: 'Weekdays', message: 'Everyday' },
        {
          title: 'Time Range', message: '10:00 AM - 6:00 PM'
        }]
    },
  ])
  const setDefaultTime = (value) => {
    const today = new Date();
    const myToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), value, 0, 0);
    return myToday.toISOString()
  }
  const [scheduleToPost, setScheduleToPost] = useState([
    {
      "name": `Schedule 1`,
      "dateRange": `No specific date`,
      "weekDays": 'Everyday',
      "timeRange": timeRanges.map((item) => {
        return {
          "startTime": setDefaultTime(10),
          "endTime": setDefaultTime(18)
        }
      }),
    }
  ])
  useEffect(() => {
    const deviceName = localStorage.getItem('deviceName')
    setDeviceName(deviceName)
    async function getplaylists() {
      try {
        const token = localStorage.getItem("authToken")
        const orgId = '786' // needs to changes orgId in R2.
        const playlists = await getAllPlaylists(token, orgId)
        const playlistsdata = playlists?.data?.body?.data?.map((item) => {
          return {
            id: item._id,
            primary: item.name,
            secondary: `${item.slides.length} Slide, ${item.duration}`
          }
        })
        setActivePlayList(playlistsdata)
        setPlaylistId(playlistsdata?.[0]?.id)
        setplaylist(playlistsdata?.[0]?.primary)
      }
      catch (e) {
        setshowAlert(true)
        seterror(error && error.response && error.response.data.message)
      }
    }
    getplaylists()
  }, [])
  const convertUTC = (value) => {
    let date = new Date(value)
    return date.toISOString()
  }
  const editSchedule = (index) => {
    const days = {
      Sun: true,
      Mon: true,
      Tue: true,
      Wed: true,
      Thu: true,
      Fri: true,
      Sat: true,
    }
    setIsSchedule(true)
    setOpen(true)
    const schedule = scheduleToPost[index - 1]
    const daysToSet = schedule.weekDays
    if (daysToSet === 'Everyday') {
      setDays(days)
    }
    else {
      const setdays = daysToSet.split(", ")
      setdays.forEach((item) => {
        if (item in days) {
          days[item] = true
        }
        else {
          days[item] = false
        }
      })
    }
    setdateRange(schedule.dateRange)
    settimeRanges(schedule.timeRange)
    setDays(days)
    setTitle(schedule.name)
    setEditBoolean(true)
    setIndex(index)
  }
  const deleteSchedule = (index) => {
    const newarr = [...scheduleToPost]
    newarr.splice(index - 1, 1)
    const new_schedule = schedule.filter(item => item.id !== index)
      .map((tasks, index) => {
        tasks.status = `Schedule ${index + 1}`
        return tasks
      })
    setSchedule(new_schedule)
    setScheduleToPost(newarr.map((tasks, index) => {
      tasks.name = `Schedule ${index + 1}`
      return tasks
    }))
  }
  const onEdit = () => {
    schedule.splice(index - 1, 1, {
      id: index, icon: scheduleIcon, status: `Schedule ${index}`, editIcon: editIcon, deleteIcon: deleteIcon, details: [
        { title: 'Date Range', message: dateRange?.start ? `${moment(dateRange.start).format("MM/DD/YYYY")} - ${moment(dateRange.end).format("MM/DD/YYYY")}` : 'No specific date' },
        { title: 'Weekdays', message: weekdays.join(", ") },
        {
          title: 'Time Range',
          message: timeRanges.map((item) => {
            return `${moment(item.startTime).format("h:mm a")}
        - ${moment(item.endTime).format("h:mm a")}\n`
          })
        }]
    })
    scheduleToPost.splice(index - 1, 1, {
      "name": `Schedule ${index}`,
      "dateRange": dateRange?.start ? convertUTC(dateRange.start) + ' - ' + convertUTC(dateRange.end) : 'No specific date',
      "weekDays": weekdays.join(", "),
      "timeRange": timeRanges.map((item) => {
        return {
          "startTime": convertUTC(item.startTime),
          "endTime": convertUTC(item.endTime)
        }
      }),
    }
    )
    onCancel()
    setIsSchedule(false)
    setEditBoolean(false)
    setIndex("")
    setTitle("")
    setdateRange("No specific date")
    settimeRanges([
      { startTime: "", endTime: "" }
    ])
  }
  const push = () => {
    setSchedule([
      ...schedule,
      {
        id: schedule.length + 1, icon: scheduleIcon, status: `Schedule ${schedule.length + 1}`, editIcon: editIcon, deleteIcon: deleteIcon, details: [
          { title: 'Date Range', message: dateRange?.start ? `${moment(dateRange.start).format("MM/DD/YYYY")} - ${moment(dateRange.end).format("MM/DD/YYYY")}` : 'No specific date' },
          { title: 'Weekdays', message: weekdays.join(", ") },
          {
            title: 'Time Range',
            message: timeRanges.map((item) => {
              return `${moment(item.startTime).format("h:mm a")}
                - ${moment(item.endTime).format("h:mm a")}\n`
            })
          }]
      }
    ]);
    setScheduleToPost([
      ...scheduleToPost,
      {
        "name": `Schedule ${schedule.length + 1}`,
        "dateRange": dateRange?.start ? convertUTC(dateRange.start) + ' - ' + convertUTC(dateRange.end) : 'No specific date',
        "weekDays": weekdays.join(", "),
        "timeRange": timeRanges.map((item) => {
          return {
            "startTime": convertUTC(item.startTime),
            "endTime": convertUTC(item.endTime)
          }
        }),
      }
    ]);
  }
  const _getData = (elements, value, id) => {
    setplaylist(elements)
    setPlaylistId(id)
  }
  const getDays = (days) => {
    setDay(days)
    Object.keys(day).forEach((item) => {
      if (day[item]) {
        weekdays.push(item)
      }
    })
    if (weekdays.length === 7) {
      weekdays = ['Everyday']
    }
  }
  const closeDrawer = () => {
    setOpen(!open);
  };
  const onCancel = () => {
    setOpen(false);
  };
  const playlistOnsave = () => {
    setplaylist(playlist)
    setopenDrawer(false)
  }
  const playlistOncancel = () => {
    setplaylist("")
    setopenDrawer(false)
  }
  const onSave = () => {
    push()
    onCancel()
  }
  const post = async () => {
    const deviceId = localStorage.getItem('deviceScheduleId')
    setloading(true)
    try {
      const postSchedule = {
        "deviceId": deviceId,
        "playlistId": playlistId,
        "schedules": scheduleToPost
      }
      postSchedule.name = playlist
      const token = localStorage.getItem("authToken")
      const postScheduleResponse = await updatePlaylist(postSchedule, token)
      setshowAlert(true)
      setsuccess(true)
      setloading(false)
      const timer = setTimeout(() => {
        history.push("/deenkiosk/devices/manage-devices")
      }, 3000);
      return () => clearTimeout(timer);
    }
    catch (error) {
      setshowAlert(true)
      seterror(error && error.response && error.response.data.message)
      setloading(false)
    }
  }
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          <div
            onClick={() => history.push("/deenkiosk/devices/manage-devices")}
            className="backBtn"
            style={{ marginTop: 0 }}
          >
            <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
            <p style={{ alignSelf: "center" }}>Back</p>{" "}
          </div>
          {error && error === "Token invalid or expired" && (
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
              <AlertAction
                alert={token_expired}
                alertType={"error"}
                onClick={() => seterror(false)}
              />
            </div>
          )}
          {showAlert && error && (
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
              <AlertAction
                alert={
                  error === "Invalid token"
                    ? token_expired
                    : error
                }
                alertType={"error"}
                onClick={() => setshowAlert(false)}
              />
            </div>
          )}
          {showAlert && success && (
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
              <AlertAction
                alert={success_message_add_playlist}
                alertType={"success"}
                onClick={() => setshowAlert(false)}
              />
            </div>
          )}
        </>
      }
      isForm={true}
      formData={
        <div>
          <>
            <div>
              <h1 className='title_top'>Add Playlist to{" "}
                <span className='roboto-medium' style={{ fontWeight: 500, color: "#5A5A5A" }}>
                  {deviceName}
                </span></h1>
              <div className="dasheddBorder"></div>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h2 className="sub_title">Playlists</h2>
                <p className='roboto-regular' style={{ color: "#818E94", fontSize: 14, marginTop: 7, padding: 0 }} >
                  Create “New Playlists” from{" "} <span style={{ fontSize: 14, color: "#2A82F6" }} onClick={() => console.log()} > Playlists </span></p>
              </Grid>
              <Grid item xs={12}>
                <BaseTextField
                  label="Select Playlist"
                  value={playlist}
                  error={!success && error && !playlist}
                  disabled={true}
                  helperText={
                    (!success && error && !playlist && "Please select playlist") ||
                    " "
                  }
                  placeholder=" "
                  InputProps={true}
                />
                <p className='roboto-regular' style={{ color: '#D3D8DA', fontSize: 12 }}>Playlists are managed at Manage {'>'} Playlists</p>
              </Grid>
              <Grid item xs={12}>
                <div className='schedule'>
                  <EventLogs data={schedule} headTitle="Schedules"
                    schedule={true}
                    editSchedule={(e) => editSchedule(e)}
                    deleteSchedule={(e) => deleteSchedule(e)}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className='add_another_btn' >
                  <img src={addCircle} />
                  <span onClick={() => {
                    setIsSchedule(true)
                    setOpen(true)
                  }
                  }>add another</span>
                </div>
              </Grid>
              {loading ? (
                <div
                  style={{
                    marginTop: 30,
                    display: "flex",
                    justifyContent: "center",
                    width: 570,
                  }}
                >
                  <CircularProgress color="primary" />
                </div>
              ) : (
                  <Grid item xs={12}>
                    <div className="form_button">
                      <p className="cancel" onClick={() => history.push("/deenkiosk/devices/")} >CANCEL</p>
                      <BaseButton text="ADD NOW" onClick={post} />
                    </div>
                  </Grid>
                )}
            </Grid>
            {isSchedule && <DrawerSchedule
              open={open}
              openHandler={closeDrawer}
              title={editBoolean ? title : `Schedule ${schedule.length + 1}`}
              onCancel={onCancel}
              onSave={editBoolean ? onEdit : onSave}
              loading={false}
              timeRanges={timeRanges}
              settimeRanges={settimeRanges}
              getDays={getDays}
              dateRange={dateRange}
              setdateRange={setdateRange}
              clear={onSave}
              days={days}
            >
            </DrawerSchedule>}
            <DrawerMain
              open={openDrawer}
              openHandler={() => setopenDrawer(false)}
              title={isPlaylist && 'Select Playlist' || isSchedule && `Schedule`}
              onCancel={playlistOncancel}
              onSave={playlistOnsave}
            >
              {isPlaylist && <DrawerListRadioButton objects={activePlaylists} getData={_getData} />}
            </DrawerMain>
          </>
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    services: state.services.editableService,
    edit: state.services.edit,
  };
};
export default connect(mapStateToProps, 0)(AddPlaylist);
