import React, { useEffect } from "react";
import Moment from "moment";
import MomentTime from "moment-timezone";
import {
  BaseTextField,
  BaseButton,
  BaseStepper,
  BaseSelect,
  TextEditor,
  GridContainer,
  GridItem,
  FormPublishUnpublish,
  BasePaper,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useState } from "react";
import "./style.css";
import { getReminders, updateReminder } from "../Donations/deen-kioks-website/Apis";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
import { editOrganization } from "../Organization/api";
import { getRemindersWithAppId } from "./api";
const { token_expired, success_message_reminder, publish_date_before_unpublish } = messages_content;
function AddReminders(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [Loading, setLoading] = React.useState(true);
  const [DisabledSaved, setDisabledSaved] = React.useState(false);
  const [visibility, setVisibility] = React.useState("Public");
  const [publish, setPublish] = React.useState("Immediately");
  const [unPublish, setUnPublish] = React.useState("Never");
  const [publishDate, setPublishDate] = React.useState("Immediately");
  const [publishTime, setPublishTime] = React.useState("");
  const [unPublishDate, setUnPublishDate] = React.useState("Never");
  const [unPublishTime, setUnPublishTime] = React.useState("");
  const [unPublishTimestamp, setUnPublishTimestamp] = React.useState(0);
  const [Reminders, setReminders] = React.useState([]);
  const [SelectReminder, setSelectReminders] = React.useState('');
  const [publishedDateAndTime, setPublishedDateAndTime] = React.useState('');
  const [onSelectReminder, setOnSelectReminder] = React.useState([]);
  const [UTCDate, setUTCDate] = React.useState('');
  const [UTCTime, setUTCTime] = React.useState('');
  const [UTCDateUnPublish, setUTCDateUnPublish] = React.useState('');
  const [UTCTimeUnPublish, setUTCTimeUnPublish] = React.useState('');
  const [unPublishedDateAndTime, setUnPublishedDateAndTime] = React.useState('');
  const [validUnPublish, setValidUnPublish] = React.useState(true);
  const [validPublish, setValidPublish] = React.useState(true);
  const [dateError, setDateError] = React.useState(false);
  const [errorReminder, setErrorReminder] = React.useState(false);
  const [error, seterror] = useState(false);
  const [disablePublish, setdisablePublish] = useState(false);
  
  const [Desc, setDesc] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);
  useEffect(() => {
    GetAllReminders()
  }, [])
  async function GetAllReminders() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    let RemindData = [];
    try{
      const addRemind = await getRemindersWithAppId(token, orgId, 'deenweb')
      RemindData = addRemind ? addRemind.data.body.data : []

    }catch(err){
      console.log(err)
      setLoading(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
    try {
      setLoading(true);
      const result = await getReminders(orgId, token);
      const res = result.data.body.data
      const getActiveSalah = res.filter(text => text.status === 'true')
      const Ids = RemindData.length ? RemindData.map(t => t.reminder._id) : [];
      const filter = getActiveSalah.filter(text => !Ids.includes(text._id))
      const array = filter.map((text, index) => {
        return (
          {
            ...text,
            value: text.title,
            label: text.title,
          }
        )
      })
      setReminders(array);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const handleNextReminder = () => {
    if (onSelectReminder.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setErrorReminder(true)
    }
  };
  const handleBackReminder = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const getPublishDate = (value, boolean) => {
    if(value === "Immediately") {
      setUTCDate('');
      setPublishedDateAndTime('');
      setPublish("Immediately");
      setPublishDate("Immediately")
    } else {
      setValidPublish(false);
      setPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDate(Moment(value).format('YYYY-MM-DD'));
    }
  };
  const getPublishTime = (value, boolean) => {
    setPublishTime(Moment(value).format("h:mm A"));
    setUTCTime(Moment(value).format("HH:mm:ss"));
  };
  const getUnPublishDate = (value, boolean) => {
    if(value == "Never") {
      setUTCDateUnPublish('');
      setUnPublishedDateAndTime('')
      setUnPublish('Never');
      setUnPublishDate('Never');
    } else {
      setValidUnPublish(false);
      setUnPublishTimestamp(value);
      setUnPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDateUnPublish(Moment(value).format('YYYY-MM-DD'));
    }
  };
  const getUnPublishTime = (value, boolean) => {
    setUnPublishTime(Moment(value).format("h:mm A"));
    setUTCTimeUnPublish(Moment(value).format("HH:mm:ss"));
  };
  const onVisibilitySave = () => {
    console.log("visibility saved");
  };

  const onVisibilityCancel = () => {
    console.log("visibility cancelled");
  };
  const onPublishSave = () => {
    let Time = publishTime.split(' ');
    let dated = MomentTime(new Date(publishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = `${dated + 'T' + Hours}:00.00Z`
    setPublishedDateAndTime(date);
  };
  const onPublishCancel = () => {
    console.log("onPublishCancel")
  };
  const onUnPublishSave = () => {
    let Time = unPublishTime.split(' ');
    let dated = MomentTime(new Date(unPublishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = `${dated + 'T' + Hours}:00.00Z`
    setUnPublishedDateAndTime(date);
  };
  const onUnPublishCancel = () => {
    console.log("onUnPublishCancel")
  };
  const convertUTC = (date) => {
    return date.toISOString()
  }
  const onSave = async () => {
    if(disablePublish) {
      return
    }
    setdisablePublish(true)

    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    const data = UTCDate === '' ? new Date() : new Date(`${UTCDate + ' ' + UTCTime}`)
    const dataU = convertUTC(data);
    const dataE = UTCDateUnPublish === '' ? new Date() : new Date(`${UTCDateUnPublish + ' ' + UTCTimeUnPublish}`)
    const UnpublishTime = convertUTC(dataE);
    const obj = {
      website: {
        status: visibility,
        publishFrom: publishedDateAndTime === '' ? publish : dataU,
        publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime
      }
    }
    const objData = {
      "addSelectedArrayData": {
          "enabledIntegrations.deenweb.reminders.selectedReminders": [{
              reminder: onSelectReminder[0]._id,
              publishFrom: publishedDateAndTime === '' ? publish : dataU,
              publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime, // DATE OBJECT
              status: visibility,
              featured: false
          }]
      },
      "enabledIntegrations.deenweb.reminders.subscriptionType": "free"
  }
    const previousUnpublish = obj.website.publishFrom !== 'Immediately' ? obj.website.publishTo > obj.website.publishFrom : true
    if (obj.website.publishTo === 'Never' || previousUnpublish) {
      try {
        await editOrganization(objData, orgId, token);
        props.alertShow(success_message_reminder)
        props.history.push("/website/reminders");
        // const ret = await updateReminder(obj, token, onSelectReminder[0]._id)
        // if (ret.status === 200) {
        // }
      } catch (err) {
        seterror(err.response && err.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    } else {
      setDateError(true)
      setTimeout(() => {
        setDateError(false)
      }, 8000)
    }
  };
  const onBack = () => {
    setActiveStep(0);
  };
  const handleChange = (prop) => (event) => {
    if (prop === 'reminders') {
      setSelectReminders(event.target.value)
      let RemindersList = Reminders;
      let select = RemindersList.filter(tex => { return tex.title === event.target.value })
      let desc = JSON.parse(select[0].description)
      setDesc(desc);
      setOnSelectReminder(select);
    }
  };
  const styles = {
    formBody: {
      background: "#fff",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A",
    },
    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
    },
    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
  };
  const style = {
    width: '100%',
  };
  const getStepsReminder = () => {
    return [
      { number: 1, label: "Select Reminder" },
      { number: 2, label: "Publish" },
    ];
  }
  return (
    <MasterLayoutMiddle
      header={
        <>
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            dateError &&
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
              <AlertAction
                alert={publish_date_before_unpublish}
                alertType={"error"}
                onClick={() => setDateError(false)}
              />
            </div>
          }
        </>
      }
      isForm={true}
      formData={
        Loading ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <div>
            <BaseStepper
              title="Add Reminder"
              getSteps={getStepsReminder}
              alternativeLabel={true}
              handleBackPreviousPage={() => props.history.push('/website/reminders')}
              handleBack={() => handleBackReminder()}
              handleNext={() => handleNextReminder()}
              nonLinear={true}
              orientation={"horizontal"}
              btnStyles={styles.stepper}
              activeStep={activeStep}
            />
            {activeStep === 0 && (
              <Grid>
                <Grid item md={12} xl={12} style={{ margin: "auto" }}>
                  <Typography className="form_secton_title">Reminders</Typography>
                  <Typography style={styles.formTitleSub}>
                    Add “New Reminders from Home screen &gt; Manage &gt; Reminders.
                  </Typography>
                </Grid>
                <Grid item md={12} xl={12} style={{ margin: "auto", marginTop: 15 }} className='selectRemindermargin0'>
                  <BaseSelect
                    value={SelectReminder}
                    onChange={handleChange("reminders")}
                    object={Reminders}
                    style={{ width: '100%' }}
                    helperText={Reminders.length ? "Reminders are managed at home screen" : <p>Reminders not found. For create new reminders click here: <span onClick={() => props.history.push('/reminders')} className='addLinkTag'> Add Reminders </span></p>}
                    label="Select Reminder"
                    error={errorReminder || Reminders.length ? false : true}
                  />
                </Grid>
                <Grid item md={12} xl={12} style={{ margin: "auto" }}>
                  <TextEditor
                    label={"Description"}
                    onChange={(e) => console.log(e)}
                    disabled={true}
                    value={Desc}
                    style={styles.editor}
                    maxCharCount={200}
                    helperText={"Edit this description from Home screen `&gt;` Manage `&gt;` Reminders"}
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  xl={12}
                  className="buttons"
                >
                  <span className="btnCencel">
                    <BaseButton
                      text="CANCEL"
                      onClick={() => props.history.push("/website/reminders")}
                    />
                  </span>
                  <BaseButton text="CONTINUE" onClick={() => handleNextReminder()} />
                </Grid>
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid>
                <Grid item md={12} xl={12} style={{ margin: "auto" }}>
                  <div className="dasheddBorder"></div>
                </Grid>
                <Grid
                  item
                  md={12}
                  xl={12}
                  style={{ margin: "auto" }}
                  className="grid-add-announc-publish forSwitchWidth FeildsWidth100"
                >
                  <FormPublishUnpublish
                    visibility={visibility}
                    setVisibility={setVisibility}
                    publish={publish}
                    unPublish={unPublish}
                    publishDate={publishDate}
                    publishTime={publishTime}
                    setPublishDate={setPublishDate}
                    setPublishTime={setPublishTime}
                    getPublishDate={getPublishDate}
                    getPublishTime={getPublishTime}
                    unPublishDate={unPublishDate}
                    unPublishTime={unPublishTime}
                    setUnPublishDate={setUnPublishDate}
                    setUnPublishTime={setUnPublishTime}
                    getUnPublishDate={getUnPublishDate}
                    getUnPublishTime={getUnPublishTime}
                    onVisibilitySave={onVisibilitySave}
                    onVisibilityCancel={onVisibilityCancel}
                    onPublishSave={onPublishSave}
                    onPublishCancel={onPublishCancel}
                    onUnPublishSave={onUnPublishSave}
                    onUnPublishCancel={onUnPublishCancel}
                    onSave={onSave}
                    onBack={onBack}
                    unPublishTimestamp={unPublishTimestamp}
                    minDateUnpublish={publishDate}
                    maxDatepublish={unPublishDate}
                    disabledSave={validUnPublish}
                    disabledSaveP={validPublish}
                  />
                </Grid>
              </Grid>
            )}
          </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
const mapStateToProps = (state) => {
  return {
    reminders: state.reminders,
  };
};
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),

});
export default connect(mapStateToProps, mapDispatchToProps)(AddReminders);
