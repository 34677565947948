import React, { useEffect } from "react";
import { BaseButton } from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import './style.css'

function DeleteAccount(props) {

    const style = {
        width: '100%',
    };

    return (
        <div>
            <div className="PageTitleContainer">
            <div
              onClick={() => this.props.history.push('/organization/services')}
              className='backBtn'>
              <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
              <p style={{ alignSelf: 'center' }}>Back</p>{' '}
            </div>
                <div className="title_top">Delete your account</div>
            </div>

            <div className="PageContainer">
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <div className="DeleteAccountContainer">
                            <div className="DeleteAccountSubTitle">You're about to delete your account:</div>

                            <div className="UserIconContainer">
                                <div className="UserIcon">
                                    <p>MA</p>
                                </div>
                                <div className="UserDtlInfoCol">
                                    <div className="UserDtlInfoColTitle">Shayan Mahmud</div>
                                    <div className="UserDtlInfoColSubTitle">shayanmahmud@gmail.com</div>
                                </div>
                            </div>


                            <div className="DeleteAccountInfoList">
                                <div className="DeleteAccountSubTitle">When you delete your account:</div>
                                <ul>
                                    <li>You'll <span>immediately lose access</span> to your DEENCONNECT account services.</li>

                                    <li>We'll <span>delete your personal data,</span>  such as your full name and email address,
                                        from DEENCONNECT account services within 30 days, except in a few cases where required for legitimate
                                        business or legal purposes. <InfoRoundedIcon className="infoIconS" /></li>

                                    <li>We’ll email you a list of apps that may have stored your personal data. <InfoRoundedIcon className="infoIconS" /></li>

                                    <li>The content you've created will remain in DEENCONNECT account services. <InfoRoundedIcon className="infoIconS" /></li>
                                </ul>
                            </div>


                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className="PageBottomContainer">
                            
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: 570,
                                    }}
                                >
                                    
                                </div>
                           
                                <div className="form_button buttonRed">
                                    <p className='cancel' onClick={() => this.props.history.push('/organization/services')} >CANCEL</p>
                                    <BaseButton text='DELETE ACCOUNT' onClick={() => this.addService()} />
                                </div>
                           
                        </div>
                    </Grid>

                </Grid>
            </div>
        </div>

    );
}

export default DeleteAccount;
