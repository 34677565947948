import React, { Component } from 'react'
import { SimpleTable, EmptyTable, MasterLayoutMiddle, FooterInner, AlertAction, AlertDialogue } from "@dclab/dc-react-ui-kit";
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import Handle from "../../assets/img/more_vert-24px.svg";
import Moment from "moment";
import '../../App.css'
import "./style.css";
import { getAllSalah, DeletesalahWebsite } from '../Donations/deen-kioks-website/Apis';
import { CircularProgress } from '@material-ui/core';
import { sortSalahsWebsite, getSalahsWithAppId, deleteSalahsWithAppId } from './api';
import { connect } from 'react-redux';
import { hideAlert, showAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
import { editOrganization, getOrganizationData } from '../Organization/api';
const { token_expired, remove_website_salah_success_message } = messages_content;
class ManageSalah extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      Salah: [],
      error: false,
      openAlert: false,
      specificRow: [],
      data: [],
      status: '',
      SalahList: [],
      orgIntegrateData: {},
      c_salahInfo: [],
      c_publishTo: '',
      c_publishFrom: '',
      c_selectedSalahs: [],

    }
  }
  async componentDidMount() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      
      //Part 1: Select All Salahs by Org
      const salahRes = await getAllSalah(orgId, token);
      const SalahData = salahRes.data.body.data;
      const ActiveSalah = SalahData.filter(text => text.status === 'true')
      console.log("ActiveSalah: ", ActiveSalah)
      //const resSalahGet = await getSalahsWithAppId(token, orgId, 'deenweb')

      //const SalahData = resSalahGet?.data?.body?.data

      

      //Part 2: Get All Salahs Selected to Display by Org
      const resOrg = await getOrganizationData(orgId);
      const orgIntegate = resOrg.data?.body?.data?.enabledIntegrations;

      const selSalahs = orgIntegate?.deenweb?.salahs?.selectedSalahs.length ? orgIntegate?.deenweb?.salahs?.selectedSalahs : []
      console.log("selSalahs: ", selSalahs)
      this.state.c_selectedSalahs = selSalahs
      //const resData = salah.filter(({status, publishTo, publishFrom}) => status === "Public"); // && (new Date(publishTo) > new Date()) && (new Date(publishFrom) < new Date())
    
      const Ids = selSalahs.length ? selSalahs.map(t => t.salah) : [];
      console.log("Active Salah: ", Ids)
     // console.log(Ids)
      
     let updatedSalahObj = []

      const SalahToDisplay = ActiveSalah.filter(text => Ids.includes(text._id))
      
      console.log("SalahToDisplay: ", SalahToDisplay)

    let sortedSalah = SalahToDisplay.length > 1 ? 
    SalahToDisplay?.filter((el) => el?.order).sort((a, b) => {
        return a.order - b.order
      }) : SalahToDisplay

      console.log("sorted Salah: ", sortedSalah)
      // let sort = updatedSalahObj.filter((el) => el?.salah?._id).sort((a, b) => {
      //   console.log("A: ", a)
      //   return a.salah.order - b.salah.order })

      // console.log("updatedSalahObj: ", sort)
      // let sortedSalah = SalahToDisplay?.filter((el) => el?.order).sort((a, b) => {
      //   return a.order - b.order
      // })

      
     // console.log("sort: ", sort)

      // const filter = getActiveSalah.filter(text => Ids.includes(text._id))
      // console.log("Active Salah")
      // console.log(filter)

      // const lu = resData?.length ? resData.sort((a, b) => {
      //   return getTimeStamp(b.salah?.updatedAt) - getTimeStamp(a.salah?.updatedAt)
      //   }) : []
      // console.log("getSalahs: ", lu)
      // setlastUpdated(lu?.[0]?.salah?.updatedAt)
      // const orderedData = resData.filter((el) => el?.salah?._id).sort((a, b) => {
      //     return a.salah.order - b.salah.order
      // })
      // //const data = response.data.body.data;

      
      let data = [];
      let tempObj = [];
      this.setState({ data: SalahToDisplay, SalahList: SalahData, orgIntegrateData: orgIntegate })
      
      const temp = sortedSalah?.filter((el) => el?._id)?.map((text, i) => {

        //console.log("text sorted : ", text)
        let status;
        let publishTo;
        let publishFrom;

        selSalahs.map(setting => {
          console.log("setting: ", setting)
          let arr = []
          if(text._id === setting.salah) {
            status = setting.status
            publishFrom = setting.publishFrom
            publishTo = setting.publishTo

          }
        })
        console.log("status: ", status)
        console.log("From: ", publishFrom)
        console.log("To: ", publishTo)

        this.state.c_publishFrom = publishFrom;
        this.state.c_publishTo = publishTo;
        console.log("this.state.publishFrom: ", this.state.publishFrom)
        const timeZoneArray = text.settings.salahMethod.timezone.split(' ');
        const timeZone = timeZoneArray[timeZoneArray.length - 1]
        let dateStart = [Moment(publishFrom).format("MMM DD, YYYY"), Moment(publishFrom).format("h:mm A") + ' ' + timeZone];
        let dateEnd = [Moment(publishTo).format("MMM DD, YYYY"), Moment(publishTo).format("h:mm A") + ' ' + timeZone];
        //console.log("dateEnd: ", dateEnd);
        const UnpublishDate = new Date(dateEnd).getFullYear()
        //status = text.status
        this.setState({ status: status })
        const statusChangeStart = new Date(dateStart) <= new Date();
        const statusChangeEnd = new Date(dateEnd) <= new Date();
        let array = [];
        const getSalah = text.salahName.fullName.split(" (")[0];
        const getSalahDesc = text.salahName.fullName.replace(getSalah + " ", "");
        //let name = text.salah.salahName.split("(");

        dateEnd = 'Never'
        dateStart = 'Immediately'
        array = [
          {
            type: 'id',
            _id: text._id,
            salahId: text._id
          },
          {
            type: 'string',
            name: "siblings",
            value: [getSalah, getSalahDesc]
          },
          // {
          //   type: 'string',
          //   name: "vertical",
          //   value: dateStart
          // },
          // {
          //   type: 'string',
          //   name: UnpublishDate === 2099 ? "frequency" : "vertical",
          //   value: UnpublishDate === 2099 ? "Never" : dateEnd
          // },
          {
            type: "status",
            status: status === 'Hidden' ? 'pending' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'success' : status === 'Publish' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'pending' : 'schedule'
              : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',
            // value: status === 'Hidden' ? 'Unpublished' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'Published' : status === 'Publish' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Unpublished' : 'Scheduled'
            //   : statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled',
            value: status === 'Hidden' ? 'Unpublished' : 'Published',
          },
        ]
        data.push(array)
      }
      )
      console.log("data: ", data)
      this.setState({ Salah: data, Loading: false });
    } catch (err) {
      console.log(err)
      this.setState({ Loading: false });
      this.setState({ error: err.response && err.response.data.message })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  }
  deleteServiceAction = async (e) => {
    const { Salah } = this.state
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    this.setState({ Loading: true, openAlert: false })
    try {
      await deleteSalahsWithAppId(token, orgId, 'deenweb', e[0].salahId)
      const stateNews = Salah
      const NewsIndex = Salah.indexOf(e)
      stateNews.splice(NewsIndex, 1)
      this.setState({ Loading: false })
      this.props.alertShow(remove_website_salah_success_message)
    } catch (err) {
      this.setState({ Loading: false });
      this.setState({ error: err.response && err.response.data.message })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  }
  handleCancel = () => {
    this.setState({ openAlert: false });
  }
  handleClose = () => {
    this.setState({ openAlert: false });
  };
  render() {
    const { Salah, Loading, error, openAlert, specificRow } = this.state;
    if (this.props.alert.alertValue) {
      setTimeout(() => {
        this.props.alertHide()
      }, 8000);
    }
    let classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    let col = [
      { id: "drag", label: " ", width: 'auto' },
      { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
      { type: "link", name: "salahName", label: "Salah Name", width: '80%' },
      // { type: "string", name: "date", label: "Publish Date", width: '20%' },
      // { type: "string", name: "date/time", label: "UnPublish Date", width: '20%' },
      { type: "string", name: "status", label: "STATUS", width: '20%' },
      { type: "action", name: "action", label: "ACTION", width: '20%' },
    ];
    const style = {
      width: '100%',
    };
    const styleAlert = {
      width: 470,
      marginLeft: 800
    };
    const sortData = async (data) => {
      console.log("data is: ", data);
      const token = localStorage.getItem("authToken");
      let OBJ = {
        sortedList: data.map(text => {
          return (
            { _id: text?._id, order: text.order }
          )
        })
      }
      try {
        await sortSalahsWebsite(token, OBJ)
      } catch (err) {
        this.setState({ Loading: false });
        this.setState({ error: err.response && err.response.data.message })
        setTimeout(() => {
          this.setState({ error: false })
        }, 8000);
      }

      console.log("data: ", data)
      const sortData = data.sort((a, b) => a.order - b.order);
      console.log("sortData: ", sortData);
      
      
      let arr = [];
      this.state.c_salahInfo = sortData;
      console.log(" this.state.c_salahInfo: ", this.state.c_salahInfo)
      this.state.c_salahInfo.map((item) => {

        let status;
        let publishTo;
        let publishFrom;

        this.state.c_selectedSalahs.map(setting => {
          console.log("setting: ", setting)
          let arr = []
          if(item._id === setting.salah) {
            status = setting.status
            publishFrom = setting.publishFrom
            publishTo = setting.publishTo

          }
        })
        
        console.log('Salah Item')
        console.log(item)

        console.log("this.state.c_publishFrom: ", this.state.c_publishFrom);
        console.log("this.state.c_publishFrom: ", this.state.c_publishFrom);
        
        const timeZoneArray = item.settings.salahMethod.timezone.split(' ');
        const timeZone = timeZoneArray[timeZoneArray.length - 1]
        const getSalah = item.salahName.fullName.split(" (")[0];
        const getSalahDesc = item.salahName.fullName.replace(getSalah + " ", "");
        const dateStart = [Moment('2022-01-21T15:09:02.061Z').format("MMM DD, YYYY"), Moment(item.salahStart.waktTime).format("h:mm A") + ' ' + timeZone];
        const dateEnd = [Moment('2099-01-21T15:09:02.061Z').format("MMM DD, YYYY"), Moment(item.salahEnd).format("h:mm A") + ' ' + timeZone];
        

        const statusChangeStart = new Date(dateStart) <= new Date();
        const statusChangeEnd = new Date(dateEnd) <= new Date();
        const UnpublishDate = new Date(dateEnd).getFullYear()
        //const status = item.status
        
        
        this.state.status = status; //item.status

        let array = [];
          array = [
            {
              type: 'id',
              _id: item._id,
              salahId: item._id//text.salah._id
            },
            {
              type: 'string',
              name: "siblings",
              value: [getSalah, getSalahDesc]//name
            },
            // {
            //   type: 'string',
            //   name: "vertical",
            //   value: dateStart
            // },
            // {
            //   type: 'string',
            //   name: UnpublishDate === 2099 ? "frequency" : "vertical",
            //   value: UnpublishDate === 2099 ? "Never" : dateEnd
            // },
            {
              type: "status",
              status: status === 'Hidden' ? 'pending' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'success' : status === 'Publish' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'pending' : 'schedule'
                : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',
                value: status === 'Hidden' ? 'Unpublished' : 'Published',
              // value: status === 'Hidden' ? 'Unpublished' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'Published' : status === 'Publish' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Unpublished' : 'Scheduled'
              //   : statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled',
            },
          ]
          arr.push(array)
        })
        this.setState({ Salah: arr })
      

        console.log("Salah Object ADDED:")
        console.log(Salah)

      // sortData.filter((el) => el?.salah?._id).map((text, i) => {
      //   const timeZoneArray = text.salah.settings.salahMethod.timezone.split(' ');
      //   const timeZone = timeZoneArray[timeZoneArray.length - 1]
      //   const dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("h:mm A") + ' ' + timeZone];
      //   const dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("h:mm A") + ' ' + timeZone];
      //   const UnpublishDate = new Date(dateEnd).getFullYear()
      //   const status = text.status
      //   this.setState({ status: status })
      //   const statusChangeStart = new Date(dateStart) <= new Date();
      //   const statusChangeEnd = new Date(dateEnd) <= new Date();
      //   const getSalah = text.salah.salahName.split(" (")[0];
      //   const getSalahDesc = text.salah.salahName.replace(getSalah + " ", "");
      //   //let name = text.salah.salahName.split("(");
      //   let array = [];
        
        
      //   array = [
      //     {
      //       type: 'id',
      //       _id: text._id,
      //       salahId: text.salah._id
      //     },
      //     {
      //       type: 'string',
      //       name: "siblings",
      //       value: [getSalah, getSalahDesc]//name
      //     },
      //     {
      //       type: 'string',
      //       name: "vertical",
      //       value: dateStart
      //     },
      //     {
      //       type: 'string',
      //       name: UnpublishDate === 2099 ? "frequency" : "vertical",
      //       value: UnpublishDate === 2099 ? "Never" : dateEnd
      //     },
      //     {
      //       type: "status",
      //       status: status === 'Hidden' ? 'pending' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'success' : status === 'Publish' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'pending' : 'schedule'
      //         : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',
      //       value: status === 'Hidden' ? 'Unpublished' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'Published' : status === 'Publish' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Unpublished' : 'Scheduled'
      //         : statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled',
      //     },
      //   ]
      //   arr.push(array)
      // }
      // )
      // this.setState({ Salah: arr })

    }
    return (
      <MasterLayoutMiddle
        content={
          Loading ?
            <div
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </div>
            :
            <>
              {
                this.props.alert.alertValue &&
                <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                  <AlertAction
                    alert={this.props.alert.alertValue}
                    alertType={"success"}
                    onClick={() => this.props.alertHide()}
                  />
                </div>
              }
              {
                error && error === "Invalid token" && (
                  <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                    <AlertAction
                      alert={token_expired}
                      alertType={"error"}
                      onClick={() => this.setState({ error: false })}
                    />
                  </div>
                )
              }
              {
                Salah.length > 0 ?
                  <div>
                    <SimpleTable
                      showHeader={true}
                      title="Manage Salah"
                      parentTableDrag={true}
                      DragIcon={Handle}
                      width="98%"
                      rows={Salah}
                      columns={col}
                      colSpan={16}
                      actionSendButton={true}
                      classProps={changeClassProps()}
                      donationsCount={Salah?.length > 0 ? 1 : 0}
                      noTitle="No Salah added"
                      noPara="Added Salah will be displayed and managed here"
                      noBtnText="Add New Salah"
                      onInviteClick={() =>
                        this.props.history.push(
                          "/website/add-salah"
                        )
                      }
                      showIconCol={true}
                      editIcon={Edit}
                      deleteIcon={Delete}
                      editAction={(e) => this.props.history.push(`/website/edit-salah/${e[0]._id}`)}
                      deleteAction={(e) => this.setState({ openAlert: true, specificRow: e })}
                      showFooter={true}
                      showAdd={true}
                      addText="ADD"
                      onAddClick={() =>
                        this.props.history.push(
                          "/website/add-salah"
                        )
                      }
                      iconTextSize={16}
                      rowsPerPage={5}
                      sortData={(e) => sortData(e)}
                      data={this.state.data}
                      sortColumnIndex={[3]}

                    />
                    <div style={{ display: "flex", marginTop: 13 }}>
                      <p className="cat-lower" style={{ marginTop: 0 }}>
                        Change the order of rows to display to users. Click on{" "}
                      </p>
                      <img src={Handle} alt="" style={{ display: "flex", marginTop: -10 }} />
                      <p className="cat-lower" style={{ marginTop: 0 }}>to drag and drop within the table.</p>
                    </div>
                  </div>
                  :
                  <EmptyTable
                    title="Manage Salah"
                    noTitle="No salahs added"
                    noPara="Added salah will be displayed and managed here"
                    onAddClick={() => this.props.history.push("/website/add-salah")}
                    addLabel="ADD"
                    noBtnText="ADD"
                  />
              }
              <div>
                <AlertDialogue
                  title="Delete Salah?"
                  description="Are you sure you want to delete this Salah?"
                  description2="Deleting will permanently remove from Website."
                  cancelText="Cancel"
                  confirmText="Confirm"
                  open={openAlert}
                  handleCancel={() => this.handleCancel()}
                  handleClose={() => this.handleClose()}
                  handleConfirm={() => this.deleteServiceAction(specificRow)}
                  styles={styleAlert}
                />
              </div>
            </>
        }
        isFooter={true}
        footer={
          <FooterInner
            style={style}
            termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
            privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
      />
    )
  }
}
const mapStateToProps = (state) => ({
  alert: state.alert,
});
const mapDispatchToProps = (dispatch) => ({
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ManageSalah);
const styles = {
  contents: {
    padding: 24,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: "76%",
    marginTop: 70,
    marginLeft: "20%",
  },
}