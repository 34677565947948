import React, { Component } from "react";
import {
  SimpleTable,
  EmptyTable,
  MasterLayoutMiddle,
  FooterInner,
  AlertDialogue,
  AlertAction,
} from "@dclab/dc-react-ui-kit";
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import Handle from "../../assets/img/more_vert-24px.svg";
import Moment from "moment";
import "../../App.css";
import "./style.css";
import { getAnnoucements, deleteAnnoucementWebsite } from "../Donations/deen-kioks-website/Apis";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { editAnnoucement } from "../../redux/actions/annoucementAction";
import { deleteAnnoucementsWithAppId, getAnnoucementsWithAppId, sortAnnouncementWebsite } from "./api";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import { markdowntoPlainHtml } from "../../utils/markdowntoreact";
import messages_content from '../../utils/messages.json';
import { editOrganization, getOrganizationData } from "../Organization/api";
const { token_expired, remove_website_announcement_success_message } = messages_content;
class Announcements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Annoucements: [],
      loading: true,
      openAlert: false,
      showAlert: false,
      specificRow: [],
      error: false,
      data: [],
      width: 0,
      status: '',
      check: [
        { _id: '1', title: '', description: '[{"type":"paragraph","children":[{"text":"On saturday, a group or members come to spread knowledge."}]}]', website: { publishTo: "2021-01-05T13:41:13.837Z", publishFrom: "2021-01-05T13:41:13.837Z", status: '' } },
        { _id: '1', title: '', description: '[{"type":"paragraph","children":[{"text":"On saturday, a group or members come to spread knowledge."}]}]', website: { publishTo: "2021-01-05T13:41:13.837Z", publishFrom: "2021-01-05T13:41:13.837Z", status: '' } },
        { _id: '1', title: '', description: '[{"type":"paragraph","children":[{"text":"On saturday, a group or members come to spread knowledge."}]}]', website: { publishTo: "2021-01-05T13:41:13.837Z", publishFrom: "2021-01-05T13:41:13.837Z", status: '' } },
        { _id: '1', title: '', description: '[{"type":"paragraph","children":[{"text":"On saturday, a group or members come to spread knowledge."}]}]', website: { publishTo: "2021-01-05T13:41:13.837Z", publishFrom: "2021-01-05T13:41:13.837Z", status: '' } },
        { _id: '1', title: '', description: '[{"type":"paragraph","children":[{"text":"On saturday, a group or members come to spread knowledge."}]}]', website: { publishTo: "2021-01-05T13:41:13.837Z", publishFrom: "2021-01-05T13:41:13.837Z", status: '' } },
        { _id: '1', title: '', description: '[{"type":"paragraph","children":[{"text":"On saturday, a group or members come to spread knowledge."}]}]', website: { publishTo: "2021-01-05T13:41:13.837Z", publishFrom: "2021-01-05T13:41:13.837Z", status: '' } },
        { _id: '1', title: '', description: '[{"type":"paragraph","children":[{"text":"On saturday, a group or members come to spread knowledge."}]}]', website: { publishTo: "2021-01-05T13:41:13.837Z", publishFrom: "2021-01-05T13:41:13.837Z", status: '' } },
        { _id: '1', title: '', description: '[{"type":"paragraph","children":[{"text":"On saturday, a group or members come to spread knowledge."}]}]', website: { publishTo: "2021-01-05T13:41:13.837Z", publishFrom: "2021-01-05T13:41:13.837Z", status: '' } },
        { _id: '1', title: '', description: '[{"type":"paragraph","children":[{"text":"On saturday, a group or members come to spread knowledge."}]}]', website: { publishTo: "2021-01-05T13:41:13.837Z", publishFrom: "2021-01-05T13:41:13.837Z", status: '' } },
        { _id: '1', title: '', description: '[{"type":"paragraph","children":[{"text":"On saturday, a group or members come to spread knowledge."}]}]', website: { publishTo: "2021-01-05T13:41:13.837Z", publishFrom: "2021-01-05T13:41:13.837Z", status: '' } },
        { _id: '1', title: '', description: '[{"type":"paragraph","children":[{"text":"On saturday, a group or members come to spread knowledge."}]}]', website: { publishTo: "2021-01-05T13:41:13.837Z", publishFrom: "2021-01-05T13:41:13.837Z", status: '' } },
        { _id: '1', title: '', description: '[{"type":"paragraph","children":[{"text":"On saturday, a group or members come to spread knowledge."}]}]', website: { publishTo: "2021-01-05T13:41:13.837Z", publishFrom: "2021-01-05T13:41:13.837Z", status: '' } },
        { _id: '1', title: '', description: '[{"type":"paragraph","children":[{"text":"On saturday, a group or members come to spread knowledge."}]}]', website: { publishTo: "2021-01-05T13:41:13.837Z", publishFrom: "2021-01-05T13:41:13.837Z", status: '' } },
      ],
      orgIntegrateData: {},
      announceList: {}
    }
  }
  deleteServiceAction = async (event) => {
    this.setState({ loading: true, openAlert: false })
    const { Annoucements } = this.state
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    try {
      await deleteAnnoucementsWithAppId(token, orgId, 'deenweb', event[0].annId)
      const stateNews = Annoucements
      const NewsIndex = Annoucements.indexOf(event)
      stateNews.splice(NewsIndex, 1)
      this.setState({ loading: false })
      this.props.alertShow(remove_website_announcement_success_message)
    } catch (err) {
      this.setState({ loading: false })
      this.setState({ error: err.response && err.response.data.message })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  }
  async componentDidMount() {
    this.setState({ width: window.innerWidth })
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const resAnn = await getAnnoucementsWithAppId(token, orgId, 'deenweb')
      const resData = resAnn.data.body.data

      console.log("resData: ", resData)
      const sort = resData.sort((a, b) => {
        return a.announcement.orderNo - b.announcement.orderNo
      })
      let orderKey = sort.map(text => {
        return (
          {
            ...text,
            order: text.announcement.orderNo
          }
        )
      })
      let dataArr = [];
      this.setState({ data: orderKey, announceList: resData })
      sort.map((text, i) => {
        console.log("text: ", text)
        const dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("LT") + ' (EST)'];
        const dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("LT") + ' (EST)'];
        const desc = JSON.parse(text.announcement.description)
        const status = text.status
        const statusChangeStart = new Date(dateStart) <= new Date();
        const statusChangeEnd = new Date(dateEnd) <= new Date();
        this.setState({ status: status, dateStart: statusChangeStart, dateEnd: statusChangeEnd })
        const UnpublishDate = new Date(dateEnd).getFullYear()
        let array = [];
        array = [
          {
            type: 'id',
            _id: text._id,
            annId: text.announcement._id
          },
          {
            type: 'string',
            name: "name",
            value: text.announcement.title
          },
          {
            type: 'string',
            name: "frequency",
            value: desc[0].children[0].text.slice(0, 55) + "..."
          },
          {
            type: 'string',
            name: "vertical",
            value: dateStart
          },
          {
            type: "string",
            name: UnpublishDate === 2099 ? "frequency" : "vertical",
            value: UnpublishDate === 2099 ? "Never" : dateEnd
          },
          {
            type: "status",
            status: status === 'Hidden' ? 'pending' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'success' : status === 'Publish' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'pending' : 'schedule'
              : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',
            value: status === 'Hidden' ? 'Unpublished' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'Published' : status === 'Publish' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Unpublished' : 'Scheduled'
              : statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled',
          },
        ]
        dataArr.push(array)
      })
      this.props.editAnnoucementDis(dataArr);
      this.setState({ Annoucements: dataArr, loading: false });
    } catch (err) {
      console.log(err)
      this.setState({ loading: false });
      this.setState({ error: err.response && err.response.data.message })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  }
  handleCancel = () => {
    this.setState({ openAlert: false });
  }
  handleClose = () => {
    this.setState({ openAlert: false });
  };
  render() {
    const { Annoucements, loading, openAlert, specificRow, error } = this.state;
    if (this.props.alert.alertValue) {
      setTimeout(() => {
        this.props.alertHide()
      }, 8000);
    }
    let classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    let col = [
      { id: "drag", label: " ", width: '1px' },
      { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
      { type: "link", name: "invoice_number", label: "Title", width: '20%' },
      { type: "string", name: "name", label: "Description", width: '30%' },
      { type: "string", name: "date", label: "Publish Date", width: '16%' },
      {
        type: "string",
        name: "date/time",
        label: "UnPublish Date",
        width: '16%',
      },
      { type: "string", name: "status", label: "STATUS", width: '10%' },
      { type: "action", name: "action", label: "ACTION", width: '15%' },
    ];
    const style = {
      width: '100%',
    };
    const styleAlert = {
      width: 470,
      marginLeft: 800
    };
    const sortData = async (data) => {
      console.log(data, this.state.data, 'data announcement')
      const token = localStorage.getItem("authToken");
      const OBJ = {
        sortedList: data.map(text => {
          return (
            { _id: text._id, order: text.order }
          )
        })
      }
      try {
        await sortAnnouncementWebsite(token, OBJ)
        // await editOrganization(dataObj, orgId, token);
      } catch (err) {
        this.setState({ error: err.response && err.response.data.message })
        setTimeout(() => {
          this.setState({ error: false })
        }, 8000);
      }
      const sortedData = data.sort((a, b) => a.order - b.order)
      let arr = []
      sortedData.map((text, i) => {
        const dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("LT")];
        const dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("LT")];
        const desc = JSON.parse(text.announcement.description)
        const status = text.status
        const statusChangeStart = new Date(dateStart) <= new Date();
        const statusChangeEnd = new Date(dateEnd) <= new Date();
        this.setState({ status: status, dateStart: statusChangeStart, dateEnd: statusChangeEnd })
        const UnpublishDate = new Date(dateEnd).getFullYear()
        let array = [];
        array = [
          {
            type: 'id',
            _id: text._id,
            annId: text.announcement._id
          },
          {
            type: 'string',
            name: "name",
            value: text.announcement.title
          },
          {
            type: 'string',
            name: "frequency",
            value: desc[0].children[0].text.slice(0, 55) + "..."
          },
          {
            type: 'string',
            name: dateStart === "Immediately" ? 'address' : "vertical",
            value: dateStart
          },
          {
            type: "string",
            name: UnpublishDate === 2099 ? "frequency" : "vertical",
            value: UnpublishDate === 2099 ? "Never" : dateEnd
          },
          {
            type: "status",
            status: status === 'Hidden' ? 'pending' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'success' : status === 'Publish' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'pending' : 'schedule'
              : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',
            value: status === 'Hidden' ? 'Unpublished' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'Published' : status === 'Publish' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Unpublished' : 'Scheduled'
              : statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled',
          },
        ]
        arr.push(array)
      })
      this.setState({ Annoucements: arr })
    };
    return (
      <MasterLayoutMiddle
        content={
          loading ?
            <div
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </div>
            :
            <>
              {
                this.props.alert.alertValue &&
                <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                  <AlertAction
                    alert={this.props.alert.alertValue}
                    alertType={"success"}
                    onClick={() => this.props.alertHide()}
                  />
                </div>
              }
              {
                error && error === "Invalid token" && (
                  <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                    <AlertAction
                      alert={token_expired}
                      alertType={"error"}
                      onClick={() => this.setState({ error: false })}
                    />
                  </div>
                )
              }
              {
                Annoucements.length > 0 ?
                  <>
                    <SimpleTable
                      showHeader={true}
                      title="Announcements"
                      parentTableDrag={true}
                      DragIcon={Handle}
                      rows={Annoucements}
                      columns={col}
                      colSpan={16}
                      actionSendButton={true}
                      classProps={changeClassProps()}
                      donationsCount={Annoucements?.length > 0 ? 1 : 0}
                      noTitle="No Annoucements added"
                      noPara="Added Annoucements will be displayed and managed here"
                      noBtnText="Add New Annoucements"
                      onInviteClick={() =>
                        this.props.history.push("/website/add-announcements")
                      }
                      actionLink={(name) => this.details(name)}
                      showIconCol={true}
                      editIcon={Edit}
                      deleteIcon={Delete}
                      editAction={(e) =>
                        this.props.history.push(`/website/edit-announcements/${e[0]._id}`)
                      }
                      deleteAction={(e) => this.setState({ openAlert: true, specificRow: e })}
                      showFooter={true}
                      showAdd={true}
                      addText="ADD"
                      onAddClick={() =>
                        this.props.history.push("/website/add-announcements")
                      }
                      iconTextSize={16}
                      rowsPerPage={5}
                      sortData={(e) => sortData(e)}
                      data={this.state.data}
                      sortColumnIndex={[4]}
                    />
                    <div style={{ display: "flex", marginTop: 0 }}>
                      <p className="cat-lower" style={{ marginTop: 0 }}>
                        Change the order of rows to display to users. Click on{" "}
                      </p>
                      <img src={Handle} alt="" style={{ display: "flex", marginTop: -10 }} />
                      <p className="cat-lower" style={{ marginTop: 0 }}>to drag and drop within the table.</p>
                    </div>
                    <div className="DeleteAnnouncementContainer">
                      <AlertDialogue
                        title="Delete Announcement?"
                        description="Are you sure you want to delete this Announcement?"
                        description2="Deleting will permanently remove from Website."
                        cancelText="Cancel"
                        confirmText="Confirm"
                        open={openAlert}
                        handleCancel={() => this.handleCancel()}
                        handleClose={() => this.handleClose()}
                        handleConfirm={() => this.deleteServiceAction(specificRow)}
                        styles={styleAlert}
                      />
                    </div>
                  </>
                  :
                  <EmptyTable
                    title="Announcements"
                    noTitle="No announcements added"
                    noPara="Added announcements will be displayed and managed here"
                    onAddClick={() => this.props.history.push("/website/add-announcements")}
                    addLabel="ADD"
                    noBtnText="ADD"
                  />
              }
            </>
        }
        isFooter={true}
        footer={
          <FooterInner
            style={style}
            termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
            privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  editAnnoucements: state.annoucements,
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  editAnnoucementDis: (res) => dispatch(editAnnoucement(res)),
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),

});
export default connect(mapStateToProps, mapDispatchToProps)(Announcements);