import React from "react";
import { connect } from 'react-redux'
import {
  SimpleAppBar,
  BasePaper,
  NavDrawer,
  BaseIcon,
  MasterLayout,
} from "@dclab/dc-react-ui-kit";
import { Switch, Route } from "react-router-dom";
import Collection from './collections'
import Categories from './categories'
import AddCategories from './addCategories'
import EditCategories from './editCategories'
import DonationDetails from './details'
import ThankDonorsNonEditable from './ThankDonorsNonEditable'
import ThankDonors from './ThankDonors'
import DonationForm from './DonationForm/DonationForm'
import AddFaqs from './DonationForm/AddFaq'
import EditFaqs from './DonationForm/EditFaq'
import { ReactComponent as MoreApps } from "../../assets/nav_icons/moreapps.svg";
import { ReactComponent as Support } from "../../assets/nav_icons/support.svg";
import { ReactComponent as Launch } from '../../assets/nav_icons/launch-24px.svg'
import MainLogo from '../../assets/img/DC_Logo.png'
import { ChevronRight } from '@material-ui/icons'
import ManageDonors from './ManageDonors/ManageDonors'
import DonorDetails from './ManageDonors/DonorDetails'
import InviteDonor from './ManageDonors/InviteDonor'
import EditInviteDonor from './ManageDonors/editInviteDonor'
import dfconnectStripe from './dfconnectStripe'
import EditDonation from './EditDonation'
import EditDiscontinue from './EditDiscontinue'
import PrivateRoute from "../../components/PrivateRoute";


import '../../App.css'
const headerItems = [
  {
    title: "Donations",
    path: "/deenfund/donations",
  },
  {
    title: "Manage Donors",
    path: "/deenfund/manage-donors",
  },
  {
    title: "Donation Categories",
    path: "/deenfund/categories",
  },
];
const bodyItems = {
  FUNDRAISING: [
    {
      title: "Donation Form",
      path: "/deenfund/donation-form",
      child: [
      ],
    },

    {
      title: 'Settings',
      path: "/deenfund/thank-donors",
      child: [],
      // badgeText: 'Coming Soon'
    },
    // {
    //   title: "Campaign",
    //   path: "/deenfund/campaign",
    //   child: [],
    //   badgeText: 'Coming Soon'
    // },
  ],
  // embed: [
  //   {
  //     title: "Embed Button",
  //     path: "/deenfund/embed",
  //     child: [],
  //     badgeText: 'Coming Soon'
  //   },
  // ],
  //settings: [
   
    // {
    //   title: 'Email Notifications',
    //   path: "/deenfund/notifications",
    //   child: [],
    //   badgeText: 'Coming Soon'
    // },
    // {
    //   title: 'Donation Receipt',
    //   path: "/deenfund/receipt",
    //   icon: '',
    //   child: [],
    //   badgeText: 'Coming Soon'
    // },
    //  {
    // title: 'Thank Donors',
    // path: "/deenfund/thank-donors",
    // child: []
    //  },
  // ]
};
const footerItems = [
  {
    title: "More Apps",
    icon: <BaseIcon icon={MoreApps} />,
    path: "/more-apps",
    child: [
    ],
    secondaryIcon: <BaseIcon icon={ChevronRight} />,
  },
  {
    title: "Support",
    icon: <BaseIcon icon={Support} />,
    path: "http://shapla.deenconnect.io/support",
    child: [],
    secondaryIcon: <BaseIcon icon={Launch} />
  },
];
const HomePage = (props) => {
  function onClick() {
    props.history.push('/dashboard')
  }

  const returnActiveTab = () => {
    switch (props.match.path) {
      case '/deenfund/donations/details':
          return "Donations";
      default:
        return false;
    }
  };
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname])
  const logout = () => {
    localStorage.setItem('user', false)
    localStorage.setItem('authToken', '')
    props.history.replace('/signin')
    localStorage.clear();
  }
  const userProfile = () => {
    props.history.push("/admin-profile")
  };
  const navigate = (x, y) => {
    if (x === 'Support') {
      window.location.href = (y)
    } else {
      props.history.push(y)
    }
  }
  const getInitials = () => {
    let nameArr = localStorage.getItem("name") || (props?.signup?.fullName ?? '') ;
    nameArr = nameArr.length > 0 ? nameArr?.trim()?.split(" ") : [];
    let initials;
    if (nameArr?.length > 1) {
      initials = nameArr[0][0] + nameArr[1][0];
    } else {
      initials = nameArr?.[0]?.[0] + nameArr[0]?.[1] ?? '';
    } 
    return initials;
  }
  let image = localStorage.getItem("image");
  console.log("userImage", image)
  const content = (
    <React.Fragment>
      <MasterLayout
        header={
          <SimpleAppBar
            src={MainLogo}
            name={localStorage.getItem("name") || props.signup.fullName}
            role={localStorage.getItem("role")}
            showImg={image && image !== "undefined" ? true : false}
            img={image && image !== "undefined" ? image : ""}
            onClickLogout={() => logout()}
            onClickProfile={() => userProfile()}
            initials = {getInitials()}
            style={{ marginTop: 80, marginLeft: -10 }}
          />
        }
        sideBar={
          <NavDrawer
            headerNavItems={headerItems}
            bodyNavItems={bodyItems}
            footerNavItems={footerItems}
            onBackBtnClick={() => onClick()}
            onClick={() => console.log('')}
            buttonShow={true}
            showTitle={true}
            showBodyItem={true}
            activeTab = {returnActiveTab()}
            navigate={(x, y) => navigate(x, y)}
            selectedTitle={'DeenFund'}
            footerLower="footerLower"
          />
        }
        headerFixed={true}
        middleContent={
          <Switch>
            <PrivateRoute exact path="/deenfund/donations" component={Collection} />
            <PrivateRoute exact path="/deenfund/donations/details" component={DonationDetails} />
            <PrivateRoute exact path="/deenfund/donations/thank-donors" component={ThankDonorsNonEditable} />
            <PrivateRoute exact path="/deenfund/thank-donors" component={ThankDonors} />
            <PrivateRoute exact path="/deenfund/categories" component={Categories} />
            <PrivateRoute exact path="/deenfund/categories/add-new-category" component={AddCategories} />
            <PrivateRoute exact path="/deenfund/categories/edit-category/:id" component={EditCategories} />
            <PrivateRoute exact path="/deenfund/donation-form" component={DonationForm} />
            <PrivateRoute exact path="/deenfund/donation-form/add-faq" component={AddFaqs} />
            <PrivateRoute exact path="/deenfund/donation-form/edit-faq/:id" component={EditFaqs} />
            <PrivateRoute exact path="/deenfund/manage-donors" component={ManageDonors} />
            <PrivateRoute exact path="/deenfund/donors-details" component={DonorDetails} />
            <PrivateRoute exact path="/deenfund/invite-donor" component={InviteDonor} />
            <PrivateRoute exact path="/deenfund/edit-invite-donor/:id" component={EditInviteDonor} />
            <PrivateRoute exact path="/deenfund/connect-stripe" component={dfconnectStripe} />
            <PrivateRoute exact path="/deenfund/edit-donation" component={EditDonation} />
            <PrivateRoute exact path="/deenfund/edit-discontinue" component={EditDiscontinue} />   
          </Switch>
        }
      />
    </React.Fragment>
  );
  return (
    <>
      <BasePaper elevation={0} context={content} />
    </>
  );
};
const mapStateToProps = state => {
  return {
    signup: state.signup
  }
}
export default connect(mapStateToProps, {})(HomePage);
