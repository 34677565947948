import React, { useState } from "react";
import { BaseTextField, PageHeaderWithTabs, FormSwitch, BaseCheckBox } from "@dclab/dc-react-ui-kit";
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import './style.css'
function ManagPage() {
  const [checked, setChecked] = useState(true);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const object = [
    {
      label: "Page setup",
      item: "Page setup",
    },
    {
      label: "Main banner",
      item: "Main banner",
    },
    {
      label: "Reads/Views",
      item: "Reads/Views",
    },
    {
      label: "Events",
      item: "Events",
    },
    {
      label: "Videos",
      item: "Videos",
    },
    {
      label: "Services",
      item: "Services",
    },
  ];
  const saveChanges = () => {
    console.log('save')
  };
  const publish = () => {
    console.log('publish')
  };
  const saveStyles = {
    backgroundColor: '#2A82F6'
  };
  const publishStyles = {
    backgroundColor: '#2A82F6',
    opacity: 0.6
  };
  const paperStyles = {
  };
  const styles = {
    BaseTextField: {
      width: '100%',
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
  };
  return (
    <section style={styles.contents}>
      <div className="formBody">
        <div className="backBtn btnWithText">
          <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
          <Link
            to={{
              pathname: "/Website/Reminders",
              state: 1,
            }}
            style={{
              alignSelf: "center",
              color: "black",
              textDecoration: "none",
            }}
          >
            ALL PAGES
        </Link>{" "}
        </div>
        <PageHeaderWithTabs value={value} title="Homepage" object={object} indicatorColor="primary" style={{ color: "#2A82F6", border: '1px solid black' }}
          handleChange={(x, y) => handleChange(x, y)} indicatorColor="#2A82F6"
          saveStyles={saveStyles}
          publishStyles={publishStyles}
          paperStyles={paperStyles}
          saveChanges={() => saveChanges()}
          publish={() => publish()}
        />
        <Grid className="form_box">
          <Grid item md={12}>
            <Typography className="page_form_title">Page Settings</Typography>
          </Grid>
          <Grid item md={6} className="gridspacing">
            <BaseTextField
              value={"Homepage"}
              InputProps={true}
              phone={false}
              search={false}
              style={styles.BaseTextField}
              color="primary"
              disabled={false}
             // helperText="Helper text"
            />
            <FormSwitch style={styles.BaseTextField} headline="Enable Page" body="Disabled pages can’t be accessed by your site visitors." />
            <div className="SolidBorder no_space"></div>
            <div className="checkBox">
              <BaseCheckBox
                label="Quick Access"
                checked={checked}
                color="secondary"
                showHelperText={true}
                helperText="Add to left navigation under QUICK ACCESS for quickly accessing the page"
                onChange={(e) => setChecked(e.target.checked)}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
}

export default ManagPage;
