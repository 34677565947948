import React, { useState, useEffect } from "react";
import { TextInput } from "master-react-lib";
import { BaseButton, TextEditor } from "@dclab/dc-react-ui-kit";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import QDCLogo from './QDCLogo.png'
import DCLogo from './dc_logo.png'
import {unSubscribeFromNewsLetterReason, reSubscribeFromNewsLetterReason} from './api';

import './style.css'

function UnsubcribeConfirmatoin(props) {
    const [value, setValue] = React.useState(null);
    const [showOther, setshowOther] = useState(false);
    const [TextAreavalue, setValueTextArea] = useState([{type:'paragraph',children:[{text:""}]}]);
    const [id, setId] = useState();
    const [orgDetail, setOrgDetail] = useState({});
    const [reasons, setReasons] = useState([
        "I receive too many emails.",
        "I am having difficulties receiving or viewing emails.",
        "I don't remember subscribing to receive emails.",
        "I don't think the content is relevant.",
        "Other (Please explain below)"
    ]);

    useEffect(() => {
        if(props?.location?.state?.id) {
            setId(props.location.state.id);
            setOrgDetail(props.location.state.orgDetail);
        }
    }, []);

    const click = () => {
        let reason_temp = '';
        if(value === 5) {
            reason_temp = TextAreavalue[0].children[0].text;
        } else {
            reason_temp = reasons[value - 1];
        }

        unSubscribeFromNewsLetterReason(id, reason_temp)
        .then(res => {
            props.history.push({pathname: "/unsubscribe-confirmation-feedback", state: {orgDetail}});
        })
        .catch(err => {

        })
    };

    const handleChange = (event) => {
        setValue(parseInt(event.target.value));
        if(event.target.value == 5) {
            setshowOther(true);
            
        } else {
            setshowOther(false);
        }
    };

    const subscribeAgain = () => {
        reSubscribeFromNewsLetterReason(id)
        .then(res => {
            props.history.push({pathname:"/unsubscribe-success-confirmation", state: {orgDetail}})
        })
    }

    const onChange = (e) => {
        setValueTextArea(e)
    };
    
    const styles = {
        border:'1px solid black',
        color: 'gray'
    };

    return (

        <div className="SubsFormContainer">
            <div className="QDCLogo"><img src={orgDetail?.orgLogo?.logoUrl} /></div>

            <div className="SubsFormBody">
                <h1 className="SubsFormTitle">Your request was received</h1>
                <p>Please allow time for our system to process your request. Thank you for your patience.
                    <br /> <br /> Unsubscribed by mistake?</p>

                <div className="SubsFormButton outlineButton">
                    <BaseButton onClick={subscribeAgain} text="SUBSCRIBE AGAIN" />
                </div>

                <div className="SubsFormField">
                    <FormControl component="fieldset">
                        <FormLabel component="legend"><p>Please take a moment to tell us why you no longer wish to hear from us:</p></FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                            {reasons.map((reason, index) => {
                                return <FormControlLabel value= {index + 1} control={<Radio />} label= {reason} /> 
                            })}
                            {/* <FormControlLabel value="1" control={<Radio />} label="I receive too many emails." />
                            <FormControlLabel value="2" control={<Radio />} label="I am having difficulties receiving or viewing emails." />
                            <FormControlLabel value="3" control={<Radio />} label="I don't remember subscribing to receive emails." />
                            <FormControlLabel value="4" control={<Radio />} label="I don't think the content is relevant." />
                            <FormControlLabel value="5" control={<Radio />} label="Other (Please explain below)" /> */}
                        </RadioGroup>
                    </FormControl>
                </div>

                {showOther && 
                    <div className="TextArea">
                        <TextEditor
                            onChange={(e) => onChange(e)}
                            disabled={false}
                            value={TextAreavalue}
                            style={styles}
                            maxCharCount={200}
                            placeholder=""
                        />
                    </div> 
                // </div>
                //     </div> 
                }

                <div className="SubsFormButton">
                    <BaseButton onClick={click} disabled = {!value} text="SUBMIT" />
                </div>

            </div>
            <div className="QDCFooterLogo"><span>POWERED BY</span> <a href={process.env.REACT_APP_Environment === 'dev' ? 'https://shapla.deenconnect.io' : 'https://deenconnect.io' } target="blank"><img src={DCLogo} /></a></div>
        </div>
    );
}

export default UnsubcribeConfirmatoin;

