import React, { useEffect } from "react";
import Moment from "moment";
import MomentTime from "moment-timezone";
import {
  BaseTextField,
  BaseButton,
  BaseStepper,
  TextEditor,
  FormPublishUnpublish,
  AlertAction,
  MasterLayoutMiddle,
  FooterInner,
  BaseStepperExtended
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useState } from "react";
import "./style.css";
import { getAnnoucement, getAnnoucements, updateAnnoucement } from "../Donations/deen-kioks-website/Apis";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
import { editOrganization, getOrganizationData } from "../Organization/api";
import { getAnnoucementsWithAppId, getRemindersWithAppId, updateAnnoucementsWithAppId } from "./api";
const { token_expired, success_message_on_update_announcement, publish_date_before_unpublish } = messages_content;
function EditAnnouncements(props) {
  const [activeStep, setActiveStep] = React.useState(1);
  const [announcementName, setannouncementName] = React.useState('')
  const [urlLink, setUrlLink] = React.useState('');
  const [buttonLabel, setButtonLabel] = React.useState('')
  const [visibility, setVisibility] = React.useState("Public");
  const [publish, setPublish] = React.useState("Immediately");
  const [unPublish, setUnPublish] = React.useState("Never");
  const [publishDate, setPublishDate] = React.useState("Immediately");
  const [publishTime, setPublishTime] = React.useState("");
  const [UTCDate, setUTCDate] = React.useState('');
  const [UTCTime, setUTCTime] = React.useState('');
  const [error, seterror] = useState(false);
  const [UTCDateUnPublish, setUTCDateUnPublish] = React.useState('');
  const [UTCTimeUnPublish, setUTCTimeUnPublish] = React.useState('');
  const [unPublishDate, setUnPublishDate] = React.useState("Never");
  const [unPublishTime, setUnPublishTime] = React.useState("");
  const [unPublishTimestamp, setUnPublishTimestamp] = React.useState(0);
  const [Loading, setLoading] = React.useState(true);
  const [validUnPublish, setValidUnPublish] = React.useState(true);
  const [validPublish, setValidPublish] = React.useState(true);
  const [publishedDateAndTime, setPublishedDateAndTime] = React.useState('');
  const [unPublishedDateAndTime, setUnPublishedDateAndTime] = React.useState('');
  const [errorAnnouncement, setErrorAnnouncement] = React.useState(false);
  const [existingAnnouncement, setExistingAnnouncement] = React.useState({});
  const [onSelectAnnouncement,setOnSelectAnnouncement] = React.useState([])
  const [integration, setIntegration] = React.useState({});
  const [dateError, setDateError] = React.useState(false);
  const [Desc, setDesc] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);
  useEffect(() => {
    getAnnoucementsFunc();
  }, [])
  async function getAnnoucementsFunc(params) {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const Announcement = props.match.params.id;
      const getResAnnouncement = await getAnnoucementsWithAppId(token, orgId, 'deenweb');
      console.log(getResAnnouncement, 'announcement result')
      const AnnData = getResAnnouncement.data.body.data
      // const getOraganization = await getOrganizationData(orgId, token);
      // const orgIntegateData = getOraganization?.data?.body?.data?.enabledIntegrations;
      // const AnnouncementList = orgIntegateData.deenweb?.announcements?.selectedAnnouncements     
      // setExistingAnnouncement(AnnouncementList);
      // setIntegration(orgIntegateData);
      const result = AnnData.filter(text => text._id === Announcement)
      // const Specific = await getAnnoucement(Announcement, token)
      // const result = Specific.data.body.data;
      console.log(result, 'result')
      setOnSelectAnnouncement(result)
      result[0].publishFrom === "Immediately" ? setPublishDate(result[0].publishFrom) : setPublishDate(
        `${Moment(result[0].publishFrom).format("ddd")}, ${Moment(result[0].publishFrom).format("MMM D, YYYY")}`
      );
      result[0].publishFrom === "Immediately" ? setUTCDate(result[0].publishFrom) : setUTCDate(Moment(result[0].publishFrom).format('YYYY-MM-DD'))
      result[0].publishFrom === "Immediately" ? setUTCTime('') :setUTCTime(Moment(result[0].publishFrom).format("HH:mm:ss"));
      result[0].publishFrom === "Immediately" ? setPublishTime('') : setPublishTime(Moment(result[0].publishFrom).format("h:mm A"));
      result[0].publishTo === "Never" ? setUnPublishTimestamp('') : setUnPublishTimestamp(result[0].publishTo);

      result[0].publishTo === "Never" ? setUTCDateUnPublish('') : setUTCDateUnPublish(Moment(result[0].publishTo).format('YYYY-MM-DD'))



      
    if(Moment(result[0].publishTo).format("YYYY") === "2099") {
      setUnPublishDate('Never');
      setUnPublishTime('');
      console.log("here")
     } else {
      result[0].publishTo === "Never" ? setUnPublishDate(result[0].publishTo) :  setUnPublishDate(`${Moment(result[0].publishTo).format("ddd")}, ${Moment(result[0].publishTo).format("MMM D, YYYY")}`
      );
      result[0].publishTo === "Never" ? setUnPublishTime('') : setUnPublishTime(Moment(result[0].publishTo).format("h:mm A"));
     }

      // result[0].publishTo === "Never" ? setUnPublishTime('') : setUTCTimeUnPublish(Moment(result[0].publishTo).format("HH:mm:ss"));
      result[0].publishFrom === "Immediately" ? setPublishedDateAndTime('') : setPublishedDateAndTime(result[0].publishFrom);
      result[0].publishTo === "Never" ? setUnPublishedDateAndTime('') : setUnPublishedDateAndTime(result[0].publishTo)
      let visibility = Moment(result[0].publishTo) < Moment() ? 'Hidden' : result[0].status;
      setVisibility(visibility);
      setannouncementName(result[0].announcement.title);
      setDesc(JSON.parse(result[0].announcement.description));
      setButtonLabel(result[0].announcement.button.buttonLabel);
      setUrlLink(result[0].announcement.button.url);
      const response = await getAnnoucements(orgId, token);
      const res = response.data.body.data
      const data = res.filter(text => text.website && text.active === true)
      let arr = []
      res.map(tex => {
        arr.push({ value: tex.title, label: tex.title })
      })
      setLoading(false);
    } catch (err) {
      setLoading(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const getPublishDate = (value) => {
    if(value === "Immediately") {
      setUTCDate('');
      setPublishedDateAndTime('');
      setPublish("Immediately");
      setPublishDate("Immediately")
    } else {
      setValidPublish(false);
      setPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDate(Moment(value).format('YYYY-MM-DD'));
    }
  };
  const getPublishTime = (value) => {
    setPublishTime(Moment(value).format("h:mm A"));
    setUTCTime(Moment(value).format("HH:mm:ss"));
  };
  const getUnPublishDate = (value) => {
    if(value == "Never") {
      setUTCDateUnPublish('');
      setUnPublishedDateAndTime('')
      setUnPublish('Never');
      setUnPublishDate('Never');
    } else {
      setValidUnPublish(false);
      setUnPublishTimestamp(value);
      setUnPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDateUnPublish(Moment(value).format('YYYY-MM-DD'))
    }
  };
  const getUnPublishTime = (value) => {
    setUnPublishTime(Moment(value).format("h:mm A"));
    setUTCTimeUnPublish(Moment(value).format("HH:mm:ss"));
  };
  const onVisibilitySave = () => {
    console.log("visibility saved");
  };
  const onVisibilityCancel = () => {
    console.log("visibility cancelled");
  };
  const onPublishSave = () => {
    let Time = publishTime.split(' ');
    let dated = MomentTime(new Date(publishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = `${dated + 'T' + Hours}:00.00Z`
    setPublishedDateAndTime(date);
  };
  const onPublishCancel = () => {
    console.log("publish date cancel");
  };
  const onUnPublishSave = () => {
    let Time = unPublishTime.split(' ');
    let dated = MomentTime(new Date(unPublishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = `${dated + 'T' + Hours}:00.00Z`
    setUnPublishedDateAndTime(date);
  };
  const onUnPublishCancel = () => {
    console.log("unpublish date cancel");
  };
  const convertUTC = (date) => {
    return date.toISOString()
  }
  const onSave = async () => {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    const data = UTCDate === 'Immediately' ? UTCDate : UTCDate === '' ? new Date() : new Date(`${UTCDate + ' ' + UTCTime}`)
    const dataU = data === 'Immediately' ? data : convertUTC(data);
    const dataE =  UTCDateUnPublish === '' ? new Date() : new Date(`${UTCDateUnPublish + ' ' + UTCTimeUnPublish}`)
    const UnpublishTime = UTCDateUnPublish === 'Never' ? UTCDateUnPublish : convertUTC(dataE);
    const Check = {
      website: {
        status: visibility,
        publishFrom: publishDate === 'Immediately' ? publish : dataU,
        publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime
      }
    }
    const objData = {
      announcement: onSelectAnnouncement[0].announcement._id,
      publishFrom: publishDate === 'Immediately' ? publish : dataU,  // DATE OBJECT
      publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime,
      status: visibility,
      featured: false
    }
    const previousUnpublish = Check.website.publishFrom !== 'Immediately' ? Check.website.publishTo > Check.website.publishFrom : true
    if (Check.website.publishTo === 'Never' || previousUnpublish) {
      try {
        await updateAnnoucementsWithAppId(token, orgId, 'deenweb',onSelectAnnouncement[0].announcement._id, objData )
        props.alertShow(success_message_on_update_announcement);
        props.history.push("/website/announcements");
      } catch (err) {
        seterror(err.response && err.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    } else {
      setDateError(true)
      setTimeout(() => {
        setDateError(false)
      }, 8000)
    }
  };
  const onBack = () => {
    setActiveStep(0)
  };
  const handleNext = () => {
    if (announcementName === '') {
      setErrorAnnouncement(true)
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const styles = {
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    select: {
      margin: "14px 0",
      minWidth: 120,
      height: 70,
      width: 120,
      border: "#D3D8DA",
      width: "100%",
    },
    BaseTextField: {
      width: "100%",
    },
    editor: {
      padding: 16,
    },
    formBody: {
      background: "#fff",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A",
    },
    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
    },
    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
  };
  const getSteps = () => {
    return [
      { number: 1, label: "Select Announcement" },
      { number: 2, label: "Publish" },
    ];
  }
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          {
            dateError &&
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
              <AlertAction
                alert={publish_date_before_unpublish}
                alertType={"error"}
                onClick={() => setDateError(false)}
              />
            </div>

          }
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
        </>
      }
      isForm={true}
      formData={
        <div>
          {
            Loading ?
              <div
                style={{
                  marginTop: 30,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
              :
              <>
                <div>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div>
                        <BaseStepperExtended
                          title="Edit Announcement"
                          getSteps={getSteps}
                          alternativeLabel={true}
                          handleBackPreviousPage={() => props.history.push('/website/announcements')}
                          handleBack={() => handleBack()}
                          handleNext={() => handleNext()}
                          nonLinear={true}
                          orientation={"horizontal"}
                          btnStyles={styles.stepper}
                          onStepClick = {(obj) => setActiveStep(obj - 1)}
                          activeStep={activeStep}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {activeStep === 0 && (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <h2 className="sub_title">Announcement</h2>
                        <Typography style={styles.formTitleSub}>
                          Add “New Announcement” from Home screen &gt; Manage &gt; Announcement.
                        </Typography>
                      </Grid>
                      <Grid item md={12} xl={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={announcementName}
                            InputProps={false}
                            phone={false}
                            search={false}
                            style={styles.select}
                            color="primary"
                            disabled={true}
                            error={errorAnnouncement}
                            helperText="Announcements are managed at home screen"
                            label="Select Announcement"
                          />
                        </div>
                      </Grid>
                      <Grid item md={12} xl={12}>
                        <div>
                          <TextEditor
                            label={"Description"}
                            onChange={(e) => console.log(e)}
                            disabled={true}
                           // helperText={"Helper Text"}
                            value={Desc}
                            style={styles.editor}
                          />
                        </div>
                      </Grid>
                      <Grid item md={12} xl={12}>
                        <div className="SolidBorder"></div>
                      </Grid>
                      <Grid item md={12} xl={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={buttonLabel}
                            InputProps={false}
                            phone={false}
                            search={false}
                            style={styles.BaseTextField}
                            color="primary"
                            disabled={true}
                           // helperText="Helper text"
                            label="Button Label"
                          />
                        </div>
                      </Grid>
                      <Grid item md={12} xl={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={urlLink}
                            InputProps={true}
                            phone={false}
                            search={false}
                            style={styles.BaseTextField}
                            color="primary"
                            disabled={true}
                           // helperText="Helper text"
                            label="Add Link"
                          />
                        </div>
                      </Grid>
                      <Grid
                        item md={12} xl={12}>
                        <div className="form_button">
                          <span className="btnCencel">
                            <BaseButton text="CANCEL" onClick={() => props.history.push('/website/announcements')} />
                          </span>
                          <BaseButton text="CONTINUE" onClick={() => handleNext()} />
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 1 && (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div className="field_box">
                          <FormPublishUnpublish
                            visibility={visibility}
                            setVisibility={setVisibility}
                            publish={publish}
                            unPublish={unPublish}
                            publishDate={publishDate}
                            publishTime={publishTime}
                            setPublishDate={setPublishDate}
                            setPublishTime={setPublishTime}
                            getPublishDate={getPublishDate}
                            getPublishTime={getPublishTime}
                            unPublishDate={unPublishDate}
                            unPublishTime={unPublishTime}
                            setUnPublishDate={setUnPublishDate}
                            setUnPublishTime={setUnPublishTime}
                            getUnPublishDate={getUnPublishDate}
                            getUnPublishTime={getUnPublishTime}
                            onVisibilitySave={onVisibilitySave}
                            onVisibilityCancel={onVisibilityCancel}
                            onPublishSave={onPublishSave}
                            onPublishCancel={onPublishCancel}
                            onUnPublishSave={onUnPublishSave}
                            onUnPublishCancel={onUnPublishCancel}
                            onSave={onSave}
                            onBack={onBack}
                            unPublishTimestamp={unPublishTimestamp}
                            minDateUnpublish={publishDate}
                            maxDatepublish={unPublishDate}
                            disabledSave={validUnPublish}
                            disabledSaveP={validPublish}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </>
          }
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
const mapStateToProps = (state) => ({
  alert: state.addOrg.alert,
});
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
  alertHide: (res) => dispatch(hideAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(EditAnnouncements);
