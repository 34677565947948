import * as constants from "../constants";

const INITIAL_STATE = {
    inquiry_name:'',
  inquiry_names: [],
  inquiries:[]

};


export default (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
      case constants.ADD_INQUIRY:
        return {
          ...state,
          inquiry_name:action.payload.name,
          inquiries:[...state.inquiries,action.payload.name],
          inquiry_names:[...state.inquiry_names,{name: action.payload.name,members:action.payload.members}],
        };

      default:
        return state;
    }
  };
  