import React from 'react'
import { AlertAction, AlertDialogue, GridContainer, GridItem, SimpleTable } from '@dclab/dc-react-ui-kit'
import Edit from "../../../assets/img/create-24px.svg";
import Delete from "../../../assets/img/delete_forever-24px.svg";
import Handle from '../../../assets/img/more_vert-24px.svg'
import { DeleteSliderWebsite, sortSliderWebsite } from '../../../pages/Website/api';
import Moment from "moment";
import { connect } from 'react-redux';
import { hideAlert, showAlert } from '../../../redux/actions/alertActions';
import messages_content from '../../../utils/messages.json';
const { 
    token_expired,
    remove_slider_message,
  } = messages_content;

function MainBanner(props) {
  const [specificRow, setSpecificRow] = React.useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [error, seterror] = React.useState(false)
  const deleteServiceAction = async (e) => {
    setOpenAlert(false);
    const token = localStorage.getItem("authToken");
    try {
      await DeleteSliderWebsite(token, e[0]._id)
      props.alertShow(remove_slider_message)
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (err) {
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const DeleteAlertFunc = (e) => {
    setSpecificRow(e);
    setOpenAlert(true);
  }
  const handleCancel = () => {
    setOpenAlert(false);
  }
  const handleClose = () => {
    setOpenAlert(false);
  };
  const style = {
    width: 470,
    marginLeft: 800
  };
  let sort = props.data.sort((a, b) => {
    return a.order - b.order
  })
  const sortData = async (data) => {
    props.setCheckChanges(true)
    const token = localStorage.getItem("authToken");
    let OBJ = {
      sortedList: data.map(text => {
        return (
          { _id: text._id, order: text.order }
        )
      })
    }
    try {
      await sortSliderWebsite(token, OBJ)
    } catch (err) {
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
    const sortData = data.sort((a, b) => a.order - b.order)
    let arr = [];
    sortData.map((text, index) => {
      let dateStart = [Moment(text.website.publishFrom).format("MMM DD, YYYY"), Moment(text.website.publishFrom).format("h:mm A")];
      let dateEnd = [Moment(text.website.publishTo).format("MMM DD, YYYY"), Moment(text.website.publishTo).format("h:mm A")];
      let UnpublishDate = new Date(dateEnd).getFullYear();
      let status = text.website.status
      let statusChangeStart = new Date(dateStart) <= new Date();
      let statusChangeEnd = new Date(dateEnd) <= new Date();
      let array = [];
      array = [
        { type: "id", value: `${index<=8 ? '0'+(index+1) : index + 1}`, _id: text._id },
        {
          type: "url",
          name: "single_pic",
          src: text.imageMetaData.imageUrl,
        },
        {
          type: "string",
          name: "name",
          value: text.title,
        },
        { type: "string", name: "frequency", value: text.category },
        {
          type: "string",
          name: "vertical",
          value: dateStart,
        },
        {
          type: "string",
          name: UnpublishDate === 2099 ? "frequency" : "vertical",
          value: UnpublishDate === 2099 ? "Never" : dateEnd
        },

        {
          type: "status",
          status: status === 'Hidden' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'pending' : 'schedule'
            : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',
          value: status === 'Hidden' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Unpublished' : 'Scheduled'
            : statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled'
        },
      ]
      arr.push(array);
    })
    props.DataRow(arr)
  }
  return (
    <GridContainer containerClass="web-announcements ">
      {
        error && error === "Invalid token" && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '12px' }}>
            <AlertAction
              alert={token_expired}
              alertType={"error"}
              onClick={() => seterror(false)}
            />
          </div>
        )
      }
      <GridItem xs={12} itemClass="web-salah">
        <SimpleTable
          showHeader={true}
          title="Main Slider"
          parentTableDrag={true}
          DragIcon={Handle}
          rows={props.rows}
          columns={props.col}
          colSpan={16}
          actionSendButton={true}
          classProps={() => props.changeClassProps()}
          donationsCount={props.rows?.length ? 1 : 0}
          noTitle="No Slider added"
          noPara="Added Slider will be displayed and managed here"
          noBtnText="Add New Slider"
          onInviteClick={() =>
            props.history.push(
              "/website/add-slider"
            )
          }
          showIconCol={true}
          editIcon={Edit}
          deleteIcon={Delete}
          editAction={(e) => props.history.push(
            {
              pathname: `/website/edit-slider/${e[0]._id}`,
              state: { detail: props.data.filter(text => text._id === e[0]._id) }
            }
          )}
          deleteAction={(e) => DeleteAlertFunc(e)}
          showFooter={true}
          showAdd={true}
          addText="ADD NEW"
          onAddClick={() =>
            props.history.push(
              "/website/add-slider"
            )
          }
          iconTextSize={16}
          rowsPerPage={5}
          sortData={(e) => sortData(e)}
          data={sort}
          sortColumnIndex={[5]}
        />
        <div style={{ display: "flex", marginTop: 20 }}>
          <p className="cat-lower" style={{ marginTop: 0 }}>
            Change the order of Banner to display to users. Click on{" "}
          </p>
          <img src={Handle} alt="" style={{ display: "flex", marginTop: -10 }} />
          <p className="cat-lower" style={{ marginTop: 0 }}>to drag and drop within the table.</p>
        </div>
      </GridItem>
      <div>
        <AlertDialogue
          title="Delete Banner?"
          description="Are you sure you want to delete this Banner?"
          description2 = "Deleting will permanently remove from Website."
          cancelText="Cancel"
          confirmText="Confirm"
          open={openAlert}
          handleCancel={() => handleCancel()}
          handleClose={() => handleClose()}
          handleConfirm={() => deleteServiceAction(specificRow)}
          styles={style}
        />
      </div>
    </GridContainer>
  )
}
const mapStateToProps = (state) => ({
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),

});
export default connect(mapStateToProps, mapDispatchToProps)(MainBanner);
