import axios from "axios";
const baserUrl =
  "https://cors-anywhere.herokuapp.com/http://18.211.207.245:3000";
const env = {
  prod: process.env.REACT_APP_PAYMENT_PROD_API,
  dev: process.env.REACT_APP_PAYMENT_DEV_API
};
const orgEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
};
let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")

) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}
const orgBaseUrl = orgEnv[currentEnv];
export function AddNewsAndArticlesWebsite(data, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/news`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetNewsAndArticlesWebsite(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/news`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetNewsAndArticlesWebsiteFeatured(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/news?featured=true`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetNewsAndArticlesWebsiteFeaturedNot(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/news?featured=false`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetEventsWebsiteFeaturedNot(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/events?featured=false`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetEventsWebsiteFeatured(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/events?featured=true`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function DeleteNewsAndArticlesWebsite(token, Id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/news/${Id}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function EditNewsAndArticlesWebsite(data, token, Id) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/news/${Id}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function DeleteEventsWebsite(token, Id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/events/deleteWebsiteFromEvent/${Id}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function DeleteServicesWebsite(token, Id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/services/deleteWebsiteFromService/${Id}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function AddVideoWebsite(data, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/videos`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addPromotionalSlider(data, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/promotional-slider`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function addPhotoGallery(data, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/photo-gallery`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function editPhotoGallery(data, token, id, OrgId) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/photo-gallery/${id}?orgId=${OrgId}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function DeletePromotionalSliderWebsite(token, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/promotional-slider/${id}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function EditPromotionalSliderWebsite(data, token, id, OrgId) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/promotional-slider/${id}?orgId=${OrgId}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function EditVideoWebsite(data, token, id) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/videos/${id}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetVideosWebsite(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/videos`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetVideosWebsiteFeatured(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/videos?featured=true`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function DeleteVideosWebsite(token, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/videos/${id}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function AddFAQWebsite(data, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/faq`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetFAQWebsite(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/faq`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function EditFAQWebsite(data, token, id) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/faq/${id}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function DeleteFAQWebsite(token, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/faq/${id}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function AddSliderWebsite(data, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/sliders `, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetSliderWebsite(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/sliders`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateSliderWebsite(data, token, id) {
  console.log("token", token)
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/sliders/${id}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function DeleteSliderWebsite(token, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/sliders/${id} `, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetServicesWebsite(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/services`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function EditServicesWebsite(data, token, id) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/services/${id}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetServicesWebsiteFeatured(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/services?featured=true`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function EditOrganizationWebsitePages(data, token, id) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/organizations/${id}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function AddTermOfServicesWebsite(data, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/termsOfService`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updateTermOfServicesWebsite(data, token, id) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/termsOfService/${id}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetTermOfServicesWebsite(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/termsOfService`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function AddPrivacyPolicyWebsite(data, token) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/privacyPolicy`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updatePrivacyPolicyWebsite(data, token, id) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/privacyPolicy/${id}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function GetPrivacyPolicyWebsite(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/privacyPolicy`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getNavMenusWebsite(token) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/navmenu `, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function AddNavMenusWebsite(token, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`${orgBaseUrl}/navmenu`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function UpdateNavMenusWebsite(token, data, id) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/navmenu/${id}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteNavMenusWebsite(token, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/navmenu/${id}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateSubNavMenusWebsite(data, token, menu, subMenu) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/navmenu/updateSubMenu/${menu}?subMenuId=${subMenu}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteSubNavMenusWebsite(token, menu, subMenu) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/navmenu/${menu}?subMenuId=${subMenu}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


// Sorting Api's
export function sortRemindersWebsite(token, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/reminders/sortRemindersList`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function sortSalahsWebsite(token, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/salah/sortSalahList`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sortPhotosWebsite(token, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/photo-gallery/sort-list`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sortPromotionalSiderWebsite(token, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/promotional-slider/sortPromotionalSliderList`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sortAnnouncementWebsite(token, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/announcements/sortAnnouncementList`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function sortNewsArticlesWebsite(token, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/news/sortNewsList`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function sortEventsWebsite(token, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/events/sortEventList`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function sortVideosWebsite(token, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/videos/sortVideosList`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function sortServicesWebsite(token, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/services/sortServiceList`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function sortSliderWebsite(token, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/sliders/sortSlidersList`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getAnnoucementsWithAppId(token, id, app) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/announcements?orgId=${id}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function deleteAnnoucementsWithAppId(token, OrgId, app, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/announcements/${id}?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updateAnnoucementsWithAppId(token, OrgId, app, id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/announcements/${id}?orgId=${OrgId}&application=${app}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getEventsWithAppId(token, OrgId, app) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/events?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteEventsWithAppId(token, OrgId, app, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/events/${id}?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateEventsWithAppId(token, OrgId, app, id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/events/${id}?orgId=${OrgId}&application=${app}`, data,{
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getRemindersWithAppId(token, OrgId, app) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/reminders?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteRemindersWithAppId(token, OrgId, app, id,) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/reminders/${id}?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateRemindersWithAppId(token, OrgId, app, id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/reminders/${id}?orgId=${OrgId}&application=${app}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getSalahsWithAppId(token, OrgId, app) {
  console.log("OrgId: ", OrgId)
  console.log("app: ", app)
  
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/salah?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updateSalahsWithAppId(token, OrgId, app, id , data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/salah/${id}?orgId=${OrgId}&application=${app}`,data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function deleteSalahsWithAppId(token, OrgId, app, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/salah/${id}?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getServicesWithAppId(token, OrgId, app) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/services?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updateServicesWithAppId(token, OrgId, app, id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/services/${id}?orgId=${OrgId}&application=${app}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function deleteServicesWithAppId(token, OrgId, app, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/services/${id}?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getVideosWithAppId(token, OrgId, app) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/videos?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPromotionalSliderWithAppId(token, OrgId, app) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/promotional-slider?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getPhotoGalleryWithOrgId(token, OrgId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/photo-gallery?orgId=${OrgId}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deletePhotoFromGallery(token, id, OrgId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/photo-gallery/${id}?orgId=${OrgId}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deletePromotionalSliderWithAppId(token, OrgId, app, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/promotional-slider/${id}?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateVideosWithAppId(token, OrgId, app, id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/videos/${id}?orgId=${OrgId}&application=${app}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updatePromotionalSliderWithAppId(token, OrgId, app, id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/promotional-slider/${id}?orgId=${OrgId}&application=${app}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteVideosWithAppId(token, OrgId, app, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/videos/${id}?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteFaqsWithAppId(token, OrgId, app, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/faq/${id}?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getFaqsWithAppId(token, OrgId, app) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/faq?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function getNewsWithAppId(token, OrgId, app) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/news?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function updateNewsWithAppId(token, OrgId, app, id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/news/${id}?orgId=${OrgId}&application=${app}`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export function deleteNewsWithAppId(token, OrgId, app, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/news/${id}?orgId=${OrgId}&application=${app}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function sortFaqWebsite(token, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/faq/sortFaqList`, data, {
        headers: {
          token
        },
      })
      .then((res) => {
        console.log("Faq Resolve: ", res);
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}