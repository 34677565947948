import React, { useEffect, useState } from "react";
import { BaseTextField, BaseButton, BaseProfileInfo, BaseSelect, MasterLayoutMiddle, FooterInner, AvannceInfoChange } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';
import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { GridContainer } from "@dclab/dc-react-ui-kit";
import './style.css';
import { getCurrentUserDetail, editProfile } from "./Apis/index";
import { BasePhoneInput } from "@dclab/dc-react-ui-kit";
import { usStates } from '../../utils/usStates.js';
import Button from '@material-ui/core/Button';
import MediaUpload from "../../components/MediaUpload/MediaUpload";

function EditPofile(props) {
  const [userDetails, setuserDetails] = React.useState({})
  const [currentUser, setcurrentUser] = useState({
    userName: "", name: { firstName: "", lastName: "" }, phoneNo: "", imageDetails: { image: "" }, line1: "", city: "", state: "", postalCode: ""
  });

  const [preview, setPreview] = useState()
  useEffect(() => {
    const getDetails = async () => {
      const token = localStorage.getItem("authToken");
      try {
        const res = await getCurrentUserDetail(token);
        setuserDetails(res)
      }
      catch (error) {
        console.log(error)
      }
    }
    getDetails()
  }, [])
  useEffect(() => {
    setcurrentUser({
      userName: userDetails && userDetails.userName ? userDetails.userName : "",
      name: {
        firstName: userDetails.name?.firstName ? userDetails.name.firstName : "",
        lastName: userDetails.name?.lastName ? userDetails.name.lastName : "",
      },
      phoneNo: userDetails && userDetails.phoneNo ? userDetails.phoneNo : "",
      imageDetails: {
        image: userDetails && userDetails.imageDetails?.image ? userDetails.imageDetails?.image : "",
        imageName: userDetails && userDetails.imageDetails?.imageName ? userDetails.imageDetails?.imageName : "",
      },
      line1: userDetails && userDetails.address && userDetails.address.line1 ? userDetails.address.line1 : "",
      city: userDetails && userDetails.address && userDetails.address.city ? userDetails.address.city : "",
      state: userDetails && userDetails.address && userDetails.address.state ? userDetails.address.state : "",
      postalCode: userDetails && userDetails.address && userDetails.address.postalCode ? userDetails.address.postalCode : "",
    })
  }, [userDetails])

  const addLogo = (obj) => {
    if(obj.src) {
      setcurrentUser({... currentUser, imageDetails : {image: obj.src, imageName: obj.title}})
    }
  }

  let onSubmit = async (e) => {
    e.preventDefault();
    const { userName, line1, city, state, postalCode } = currentUser;
    let formData = {
      // ...currentUser,
      imageDetails: {
        image: currentUser.imageDetails.image,
        imageName: currentUser.imageDetails.imageName

      },
      name: {
        firstName: currentUser.name.firstName,
        lastName: currentUser.name.lastName
      },
      phoneNo: currentUser.phoneNo,
      // userName, 
      address: { line1, line2: "", city, state, postalCode, country: "" }
    };
    console.log(formData, 'checking')
    try {
      await editProfile(userDetails._id, formData, props.history);
    }
    catch (error) {
      console.log(error)
    }
  }

  const handleChange = (prop) => (event) => {
    console.log(prop, 'props checking')
    if (prop === 'firstName') {
      if (event.target.value?.length <= 50) {
        setcurrentUser({ ...currentUser, name: { ...name, [prop]: event.target.value } });
      } else {
        return;
      }
    } else if (prop === 'lastName') {
      if (event.target.value?.length <= 50) {
        setcurrentUser({ ...currentUser, name: { ...name, [prop]: event.target.value } });
      }
    } else if (prop === "postalCode") {
      const numRegx = /^\d+$/
      if (event.target.value.length <= 5 && numRegx.test(event.target.value)) {
        setcurrentUser({ ...currentUser, [prop]: event.target.value });
      } else if (event.target.value.length < 1) {
        setcurrentUser({ ...currentUser, [prop]: event.target.value });
      }
    } else {
      setcurrentUser({ ...currentUser, [prop]: event.target.value });
    }
  };
  const object = usStates?.map((el) => { return { value: el.name, label: el.name } }) ?? [];
  const styles = {
    stepper: {
      marginTop: 0,
      padding: "0px 0px",
      borderRadius: "30px",
    },
    select: {
      minWidth: 120,
      height: 66,
      width: 120,
      border: "#D3D8DA",
      width: "100%",
    },
    BaseTextField: {
      width: '100%',
    }
  };
  const style = {
    width: '100%',
  };
  let fileUploadButton = () => {
    document.getElementById('fileButton').click();
    document.getElementById('fileButton').onchange = (e) => {
      onFileChange(e)
    }
  }
  function onFileChange(event) {
    var file = event.target.files[0];
    const objectUrl = URL.createObjectURL(event.target.files[0])
    setPreview(objectUrl)

    var reader = new FileReader();
    let encodeImage = reader.readAsDataURL(file)

    reader.onload = function (event) {
      setcurrentUser({ ...currentUser, imageDetails: { image: reader.result, thumbnailImage: reader.result } });
    };
  }

  const removeImage = () => {
    setcurrentUser({ ...currentUser, imageDetails: { image: "", thumbnailImage: "" } });
    setPreview("")
  }
  const { userName, name, phoneNo, line1, city, state, postalCode, imageDetails } = currentUser;

  return (
    <MasterLayoutMiddle
      header={
        <div className="backBtn">
          <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
          <Link
            to={{ pathname: "/admin-profile", state: 1, }}
            style={{ alignSelf: "center", color: "black", textDecoration: "none", }} > Back </Link>{" "}
        </div>
      }
      content={
        <div className="adminProfile_container">
          <GridContainer spacing={3} direction="row">
            {/* <Grid item md={3} xs={12}>
              <div className="edit_profile_photo">
                <BaseProfileInfo alt="" membership="" src={imageDetails?.image ? imageDetails.image : preview} />
                <div>
                  <input id="fileButton" type="file" onChange={(event) => onFileChange(event)} hidden />
                  <BaseButton text="Upload photo" onClick={fileUploadButton} />
                </div>
                <Button className="remove_photo" onClick={removeImage}>Remove Photo</Button>
              </div>
            </Grid> */}

            <Grid item md={3} xs={12}></Grid>
            <Grid item md={6} xs={6}>
              <Grid item md={12}>
                <h1 className='title_top'>Edit Profile Information</h1>
                <div className="dasheddBorder"></div>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h2 className="sub_title">Basic Information</h2>
                </Grid>
                <Grid item md={6}>
                  <div className="field_box">
                    <BaseTextField
                      label={"First Name"}
                      value={name?.firstName}
                      onChange={handleChange("firstName")}
                      InputProps={true}
                      phone={false}
                      search={false}
                      color="primary"
                      disabled={false}
                      helperText={`Char ${name?.firstName.length}/50`}
                    />
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div className="field_box">
                    <BaseTextField
                      label={"Last Name"}
                      value={name?.lastName}
                      onChange={handleChange("lastName")}
                      InputProps={true}
                      phone={false}
                      search={false}
                      color="primary"
                      disabled={false}
                      helperText={`Char ${name?.lastName.length}/50`}
                    />
                  </div>
                </Grid>
                <Grid item md={12}>
                  <div className="field_box">
                    <BasePhoneInput
                      label="Phone Number"
                      phoneInputVariant={"underlineWithFlag"}
                      countryCodeEditable={false}
                      specialLabel={'Phone Number'}
                      placeholder='Phone Number'
                      country={'us'}
                      inputStyle={{
                        marginLeft: "0px",
                        height: "35px",
                        width: "100%",
                        fontFamily: "Roboto-Regular",
                        fontSize: "16px",
                      }}
                      value={phoneNo}
                      onChange={(e, f, g, h) => setcurrentUser({ ...currentUser, phoneNo: h })}
                    />
                    {/* <BaseTextField
                      label={"Mobile Number"}
                      value={phoneNo}
                      onChange={handleChange("phoneNo")}
                      InputProps={true}
                      phone={false}
                      search={false}
                      color="primary"
                      disabled={false}
                    /> */}
                  </div>
                </Grid>
                <Grid item md={12}>
                  <div className="field_box">
                    <BaseTextField
                      label={"Email Address"}
                      value={userName}
                      onChange={handleChange("userName")}
                      InputProps={true}
                      phone={false}
                      search={false}
                      color="primary"
                      disabled={true}
                    // helperText="Helper text"
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="field_box" >
                    <div className="media-library-image" >
                      <MediaUpload
                        addMediaItem={addLogo}
                        label="Profile Picture"
                        buttonName="ADD PICTURE"
                        typeOfMedia='/images'
                        selectedMedia = {{logoUrl: currentUser?.imageDetails?.image ?? '', logoName: currentUser?.imageDetails?.imageName ?? ''}} 

                      />
                    </div>
                    <div>
                    </div>
                  </div>
                </Grid>


                <Grid item md={12}>
                  <div className="field_box">
                    <BaseTextField
                      label={"Street Address"}
                      value={line1}
                      onChange={handleChange("line1")}
                      InputProps={true}
                      phone={false}
                      search={false}
                      color="primary"
                      disabled={false}
                    //helperText="Helper text"
                    />
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className="field_box">
                    <BaseTextField
                      label={"City"}
                      value={city}
                      onChange={handleChange("city")}
                      InputProps={true}
                      phone={false}
                      search={false}
                      color="primary"
                      disabled={false}
                    // helperText="Helper text"
                    />
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className="field_box">
                    <BaseSelect
                      label={"State"}
                      value={state}
                      onChange={handleChange("state")}
                      object={object}
                    />
                  </div>
                </Grid>
                <Grid item md={4}>
                  <div className="field_box">
                    <BaseTextField
                      label={"Zip/Postal Code"}
                      value={postalCode}
                      onChange={handleChange("postalCode")}
                      InputProps={true}
                      phone={true}
                      search={false}
                      color="primary"
                      disabled={false}
                    //helperText="Helper text"
                    />
                  </div>
                </Grid>
                <Grid item md={12} className="buttons">
                  <span className="btnCencel" onClick={() => props.history.push("/admin-profile")}><BaseButton text="CANCEL" /></span>
                  <BaseButton text="UPDATE" onClick={onSubmit} />
                </Grid>
                {/* <Grid item md={12}>
                  <div className="dasheddBorder"></div>
                  <AvannceInfoChange
                    showMainTitle={true}
                    mainTitle="Advance"
                    Title="Change email"
                    description="You have to confirm your password to change email."
                  />
                  <div className="dasheddBorder"></div>
                  <AvannceInfoChange
                    showMainTitle={false}
                    mainTitle="Advance"
                    Title="Change password"
                    description="Changing password is easy click Change now and follow the steps."
                    changeNow={() => props.history.push("/change-password")}
                  />
                </Grid> */}
              </Grid>
            </Grid>
          </GridContainer>
        </div>
      }

      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
export default EditPofile;