import React, { Component } from 'react';
import '../../pages/Organization/style.css';
import '../../App.css';
import { editOrganization } from '../../pages/Organization/api';
import {
  BasePaper,
  BaseSwitch,
  AlertAction,
  TextEditor,
} from '@dclab/dc-react-ui-kit';
import CircularProgress from '@material-ui/core/CircularProgress';
import messages_content from '../../utils/messages.json';
const {
  token_expired,
  long_short_desc_empty
} = messages_content;
export default class AboutOrg extends Component {
  constructor(props) {
    super(props);
    this.unblockHandle = React.createRef();
    this.state = {
      showMission: props.orgData && props.orgData.orgMission.enabled,
      showVision: props.orgData && props.orgData.orgVision.enabled,
      description: [{ type: 'paragraph', children: [{ text: '' }] }],
      longDescription: props.longDescription,
      longDescriptionCopy: props.longDescription,
      longDescriptionCount: 0,
      shortDescription: props.shortDescription,
      shortDescriptionCopy: props.shortDescription,
      shortDescriptionCount: 0,
      mission: props.mission,
      missionCopy: props.mission,
      missionCount: props.missionCount,
      vision: props.vision,
      visionCopy: props.vision,
      visionCount: props.visionCount,
      error: false,
      loading: false,
      showAlert: false,
      success: false,
      desError: false,
      missionText: '',
      visionText: '',
      descriptionText: '',
      orgData: props.orgData,
      renderCount: 0,
    };
    this.onLongDescriptionStateChange = this.onLongDescriptionStateChange.bind(this);
    this.onShortDescriptionStateChange = this.onShortDescriptionStateChange.bind(this);
    this.onMissionStateChange = this.onMissionStateChange.bind(this);
    this.onVisionStateChange = this.onVisionStateChange.bind(this);
    this.handleMissionChange = this.handleMissionChange.bind(this);
    this.handleVisionChange = this.handleVisionChange.bind(this);
    this.save = this.save.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }
  componentDidMount() {
    const { longDescription, shortDescription, mission, vision } = this.state;
    let longSum = 1;
    longDescription.map((st) => {
      if (st.type === 'bulleted-list' || st.type === 'numbered-list') {
        st.children.map((e) => {
          e.children.map((ch) => {
            longSum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          longSum += e.text.length;
        });
      }
    });
    let shortSum = 1;
    shortDescription.map((st) => {
      if (st.type === 'bulleted-list' || st.type === 'numbered-list') {
        st.children.map((e) => {
          e.children.map((ch) => {
            shortSum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          shortSum += e.text.length;
        });
      }
    });
    let misSum = 1;
    mission.map((st) => {
      if (st.type === 'bulleted-list' || st.type === 'numbered-list') {
        st.children.map((e) => {
          e.children.map((ch) => {
            misSum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          misSum += e.text.length;
        });
      }
    });
    let visSum = 1;
    vision.map((st) => {
      if (st.type === 'bulleted-list' || st.type === 'numbered-list') {
        st.children.map((e) => {
          e.children.map((ch) => {
            visSum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          visSum += e.text.length;
        });
      }
    });
    this.setState({
      longDescriptionCount: longSum,
      shortDescriptionCount: shortSum,
      visionCount: visSum,
      missionCount: misSum,
    });
  };
  scrollToTop = () => {
    if(this.state.renderCount < 2) {
      const temp = this.state.renderCount;
      window.scrollTo(0, 0);
      this.setState({
        renderCount: temp + 1
      });
    }
  }
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  save = async () => {
    const {
      longDescription,
      shortDescription,
      vision,
      mission,
      longDescriptionCount,
      shortDescriptionCount,
      longDescriptionCopy,
      shortDescriptionCopy,
      missionCopy,
      visionCopy,
      showMission,
      showVision,
    } = this.state;
    let orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    this.setState({
      loading: true,
      descriptionText: '',
      missionText: '',
      visionText: '',
    });
    if (longDescription !== longDescriptionCopy) {
      this.setState({
        descriptionText: 'Description',
      });
    }
    if (shortDescription !== shortDescriptionCopy) {
      this.setState({
        descriptionText: 'Description',
      });
    }
    if (mission !== missionCopy) {
      this.setState({
        missionText: 'Mission',
      });
    }
    if (vision !== visionCopy) {
      this.setState({
        visionText: 'Vision',
      });
    }
    const obj = {
      aboutOrg: {
        aboutUsLong: JSON.stringify(longDescription),
        aboutUsShort: JSON.stringify(shortDescription),
        orgMission: {
          enabled: showMission,
          content: JSON.stringify(mission),
        },
        orgVision: {
          enabled: showVision,
          content: JSON.stringify(vision),
        },
      },
    };
    if (longDescriptionCount <= 1 || shortDescriptionCount <= 1) {
      this.setState({
        desError: true,
        loading: false,
      });
    } else {
      try {
        await editOrganization(obj, orgId, token);
        this.setState({
          showAlert: true,
          longDescriptionCopy: longDescription,
          shortDescriptionCopy: shortDescription,
          missionCopy: mission,
          visionCopy: vision,
          success: true,
          loading: false,
          changes: false
        });
        this.hideAlert();
        this.props.onChanges(false)
      } catch (error) {
        this.setState({ error, showAlert: true, loading: false });
        this.hideAlert();
        setTimeout(() => {
          this.setState({ error: false })
        }, 8000);
      }
    }
  };
  onLongDescriptionStateChange = (editorState) => {
    const { longDescription } = this.state
    let sum = 1;
    editorState.map((st) => {
      if (st.type === 'bulleted-list' || st.type === 'numbered-list') {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    this.setState({
      longDescription: editorState,
      longDescriptionCount: sum,
    });
    this.props.onLongDescriptionChange(editorState)
    this.props.onChanges(editorState !== longDescription)
  };
  onShortDescriptionStateChange = (editorState) => {
    const { shortDescription } = this.state
    let sum = 1;
    editorState.map((st) => {
      if (st.type === 'bulleted-list' || st.type === 'numbered-list') {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    this.setState({
      shortDescription: editorState,
      shortDescriptionCount: sum,
      changes: editorState !== shortDescription
    });
    this.props.onShortDescriptionChange(editorState)
    this.props.onChanges(editorState !== shortDescription)
  };
  onMissionStateChange = (editorState) => {
    const { mission } = this.state
    let sum = 1;
    editorState.map((st) => {
      if (st.type === 'bulleted-list' || st.type === 'numbered-list') {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    this.setState({
      mission: editorState,
      missionCount: sum,
      changes: editorState !== mission
    });
    this.props.onMissionChange(editorState);
    this.props.onChanges(editorState !== mission);
  };
  onVisionStateChange = (editorState) => {
    const { vision } = this.state
    let sum = 1;
    editorState.map((st) => {
      if (st.type === 'bulleted-list' || st.type === 'numbered-list') {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    this.setState({
      vision: editorState,
      visionCount: sum,
      changes: editorState !== vision
    });
    this.props.onVisionChange(editorState);
    this.props.onChanges(editorState !== vision);
  };
  handleVisionChange = (e) => {
    const { showVision } = this.state
    this.setState({
      showVision: !this.state.showVision,
    });
    this.props.onChanges(e !== showVision)
  };
  handleMissionChange = (e) => {
    const { showMission } = this.state
    this.setState({
      showMission: !this.state.showMission,
    });
    this.props.onChanges(e !== showMission)
  };
  render() {
    const {
      longDescription,
      descriptionText,
      missionText,
      visionText,
      longDescriptionCount,
      shortDescription,
      shortDescriptionCount,
      mission,
      missionCount,
      vision,
      visionCount,
      showMission,
      showVision,
      success,
      error,
      showAlert,
      desError,
    } = this.state;
    const info = (
      <>
        <div style={{ borderBottom: '1px solid #E8E9E9' }}>
          <p className='orgTitled about_title'>About organization</p>
        </div>
        <div className='about-us' style={{ paddingBottom: 48 }}>
          <h5 className='about'>About us</h5>


          <p className='desc'>Short Description</p>
          <div>
            <TextEditor
              onChange={(e) => this.onShortDescriptionStateChange(e)}
              value={shortDescription}
              shortCount={shortDescriptionCount}
              maxCharCount={300}
              helperText={"Brief description of your organization."}
            />
          </div>
          <br />
          <p className='desc'>Long Description</p>
          <div>
            <TextEditor
              onChange={(e) => this.onLongDescriptionStateChange(e)}
              value={longDescription}
              longCount={longDescriptionCount}
              maxCharCount={1000}
              helperText={"Description of your organization. It'll be displayed on the website, on-site kiosk system and other services."}
            />
          </div>
        </div>
        <div className='about-us' style={{ paddingBottom: 48 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: 940,
            }}
          >
            <h5 className='about'>Org Mission</h5>
            <div style={{ alignSelf: 'center' }}>
              <BaseSwitch
                checked={showMission}
                onChange={(e) => this.handleMissionChange(e)}
                size='small'
              />
            </div>
          </div>
          <p className='descPara'>
          Add your organization’s mission statement (Optional).
          </p>
          {showMission && (
            <>
              <p className='desc'>Description</p>
              <div>
                <TextEditor
                  onChange={(e) => this.onMissionStateChange(e)}
                  value={mission}
                  missionCount={missionCount}
                  maxCharCount={300}
                  //helperText={"Brief description of your organization."}
                />
              </div>
            </>
          )}
          <br />
        </div>
        <div
          className='about-us'
          style={{ paddingBottom: 48, borderBottom: 'none' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: 940,
            }}
          >
            <h5 className='about'>Org Vision</h5>
            <div style={{ alignSelf: 'center' }}>
              <BaseSwitch
                checked={showVision}
                onChange={(e) => this.handleVisionChange(e)}
                size='small'
              />
            </div>
          </div>
          <p className='descPara'>
            Add your organization’s vision statement (Optional)
          </p>
          {showVision && (
            <>
              <p className='desc'>Description</p>
              <div>
                <TextEditor
                  onChange={(e) => this.onVisionStateChange(e)}
                  value={vision}
                  visionCount={visionCount}
                  maxCharCount={300}
                  //helperText={"Brief description of your organization."}
                />
              </div>
              <br />{' '}
            </>
          )}
        </div>
        {this.state.loading ? (
          <div
            style={{
              border: '1px solid #E8E9E9',
              display: 'flex',
              justifyContent: 'center',
              height: 30,
              paddingTop: 10,
              width: '100%',
            }}
          >
            <CircularProgress color='primary' size={20} />
          </div>
        ) : (
            <div
              style={{
                borderTop: '1px solid #E8E9E9',
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <p className='orgSave' onClick={() => this.save()}>
                Save Changes
            </p>
            </div>
          )}
      </>
    );
    return (
      <>
      {this.scrollToTop()}
    
        {error &&
          ((error.response &&
            error.response.data.message === 'Invalid token') ||
            error === 'Invalid Token') && (
            <div
              style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35 }}
            >
              <AlertAction
                alert={token_expired}
                alertType={'error'}
                onClick={() => this.setState({ error: false })}
              />
            </div>
          )}
        {desError && (
          <div
            style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35 }}
          >
            <AlertAction
              alert={long_short_desc_empty}
              alertType={'error'}
              onClick={() => this.setState({ desError: false })}
            />
          </div>
        )}
        {showAlert && error && (
          <div
            style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35 }}
          >
            <AlertAction
              alert={
                error.response.data.message === 'Invalid token'
                  ? token_expired
                  : 'Error'
              }
              alertType={'error'}
              onClick={() => this.setState({ showAlert: false })}
            />
          </div>
        )}
        {showAlert && success && (
          <div
            style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35 }}
          >
            <AlertAction
              alert={"Description, Mission, Vision successfully added"}
              alertType={'success'}
              onClick={() => this.setState({ showAlert: false })}
            />
          </div>
        )}
        <div
          style={{ border: '1px solid rgb(232, 233, 233)' }}
        >
          <BasePaper
            context={info}
            square={false}
            paperStyle={styles.paperStyleNext}
          />
        </div>
        <div className='org-scroll'></div>
      </>
    );
  }
}
const styles = {
  paperStyle: {
  },
  paperStyleNext: {},
};
