import * as constants from "../constants";

function forgotPasswordAction(email, code) {
  return {
    type: constants.FORGOT_PASSWORD,
    payload: {
      email,
      code,
    },
  };
}


export { forgotPasswordAction };
