import React, { useRef } from "react";
import {
  BaseTextField,
  BasePaper,
  PageHeaderWithTabs,
  FormSwitch,
  BaseCheckBox,
  SimpleTable,
  TextEditor,
  MasterLayoutMiddle,
  FooterInner,
  AlertDialogueAdvance,
  AlertAction,
  DrawerMain, BaseSelect,
  DrawerListCheckbox
} from "@dclab/dc-react-ui-kit";
import {
  getTeamMembers,
} from '../Organization/api/index.js';

import { BasePhoneInput } from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import "./style.css";
import { getOrganizationData } from "../Organization/api";
import { saveAsDraft } from "../../components/DonationForm/Apis";
import { connect } from "react-redux";
import { PagesAction } from "../../redux/actions/pages";
import messages_content from '../../utils/messages.json';
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
const {
  token_expired,
  Your_changes_has_been_published,
  Your_changes_has_been_saved_as_draft,
  quick_access_limit_axeeded
} = messages_content;

function AboutUs(props) {
  const history = props.history;
  const [Title, setTitle] = React.useState('')
  const [showAlert, setshowAlert] = useState(false);
  const [success, setsuccess] = useState(false);
  const [value, setValue] = React.useState(0);
  const [open, setAlertModal] = useState(false);
  const [dischard_changes, setdischard_changes] = useState(0);
  const [changes, setCheckChanges] = useState(false);
  const [homepageButton, setHomePageButton] = useState(false);
  const [error, seterror] = useState(true);
  const [Loader, setLoader] = React.useState(true);
  const [ErrorFound, setErrorFound] = useState(false)
  const unblockHandle = useRef();
  const [saved_route, setRoute] = useState('');
  const [inquiryName, setInquiryName] = useState('')
  const [addInquiryError, setAddInquiryError] = useState(false)
  const [inquiryMembers, setInquiryMembers] = useState([])
  const [openDrawerInquiry, setOpenDrawerInquiry] = useState(false);
  const [QuickLimit, setQuickLimit] = useState(false);
  const [org, setOrg] = useState(false);
  const [integrationOrg, setIntegrationOrg] = useState({})
  const [pagesAll, setPagesAll] = useState({})
  const [longDesc, setLongDesc] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);
  const [shortDesc, setShortDesc] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);
  const [ourMission, setOurMission] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);
  const [phoneFax, setPhoneFax] = useState({})
  const [email, setEmail] = useState('');
  const [Inquiry, setInquiry] = React.useState([])
  const [teamMembers, setteamMembers] = useState([])
  const [inquiryTypesData, setinquiryTypesData] = useState([])
  const [webContactDetails, setwebContactDetails] = useState([])
  const [selectedInquiries, setselectedInquiries] = useState([])
  const [saved, setsaved] = useState(true)
  //const [saved, setsaved] = useState(false)
  const handleChange = (event, newValue) => {
    changes && setAlertModal(true)
    !changes && setValue(newValue);
    !changes && localStorage.setItem('about-us', newValue)
    setdischard_changes(newValue)
  };
  const addInquiryMember = (e) => {
    if (!inquiryMembers.find((element) => element._id === e._id)) {
      const evcIdArray = inquiryMembers.concat(e);
      setInquiryMembers(evcIdArray)
    } else {
      let Inquiry = inquiryMembers.filter(
        (element) => element._id !== e._id
      )
      setInquiryMembers(Inquiry)
    }
  };
  useEffect(() => {
    unblockHandle.current = history.block((route) => {
      setRoute(route.pathname)
      changes && visibleModal()
      return changes === true ? false : true
    });

    // getOrganizationDataFunc();
    
    return () => {
      unblockHandle.current = false
      !window.location.pathname.includes('about-us') && localStorage.removeItem('about-us')
    }
  }, [changes])

  useEffect(() => {
    getOrganizationDataFunc()
    
  }, [history, saved])

  async function getOrganizationDataFunc() {
    console.log('Org Data Called')
    const orgId = localStorage.getItem("orgID");
    const token = localStorage.getItem("authToken");
    try {
      const response = await getOrganizationData(orgId);
      const data = response.data.body.data;
      console.log("response: ", response)
      const pagesAll = data?.enabledIntegrations?.deenweb?.pages;
      const webContactInfo = data?.enabledIntegrations?.deenweb?.settings?.contactDetails ?? {};
      const oraganization_data = pagesAll.aboutUs
      data?.aboutOrg && setLongDesc(JSON.parse(data.aboutOrg.aboutUsLong));
      data?.aboutOrg && setShortDesc(JSON.parse(data.aboutOrg.aboutUsShort));
      setPagesAll(pagesAll);
      setIntegrationOrg(data?.enabledIntegrations)
      setTitle(oraganization_data.title);
      setOrg(oraganization_data)
      setHomePageButton(oraganization_data.enablePage)
      setChecked(oraganization_data.quickAccess)
      data?.aboutOrg && setOurMission(JSON.parse(data.aboutOrg?.orgMission.content))

      let inquiryTypesData = [];
      let a = data?.contactInfo?.forEach(contact => {
        let b = contact?.inquiryTypes?.forEach(inq => {
          inquiryTypesData.push(inq)
        });
      });

      setinquiryTypesData(inquiryTypesData)
      setwebContactDetails(webContactInfo)
      console.log("webContactInfo: ", webContactInfo)

      
      
      webContactInfo.primaryPhone || webContactInfo.secondaryPhone ? setPhoneFax({ primaryPhone: webContactInfo.primaryPhone, secondaryPhone: webContactInfo.secondaryPhone }) : setPhoneFax(data.phoneFax);
      webContactInfo.email ? setEmail(webContactInfo.email) : setEmail(data.orgEmail);
      
      const selectedData = webContactInfo?.inquiryTypes?.length ? webContactInfo.inquiryTypes.map((el) => { return { id: el._id, primary: el.inquiryName, data: { ...el } } }) : [];
      //console.log("selectedData: ", selectedData)

      const res2 = await getTeamMembers(token);
      setteamMembers(res2.data.body.data)

      
      



      const data2 = inquiryTypesData?.length ? inquiryTypesData?.map((el) => { return { id: el._id, primary: el.inquiryName, data: { ...el } } }) : [];
      //console.log("data2: ", data2)

      const Ids = webContactInfo?.inquiryTypes.length ? webContactInfo?.inquiryTypes.map(t => t._id) : [];
      //console.log("Ids: ", Ids)

     const selectedInq = data2.filter(inq => Ids.includes(inq.id))
      console.log("selectedInq: ", selectedInq)
      console.log("webContactInfo?.inquiryTypes: ", webContactInfo?.inquiryTypes)
      inquiryTableData(selectedInq?.map((el) => { return { ...el.data } }))
      setselectedInquiries(selectedInq?.map((el) => { return { ...el.data } }))

      setLoader(false);
    } catch (err) {
      setLoader(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }

  const inquiryTableData = (inquiryTypes) => {
    let Inq = inquiryTypes?.length ? inquiryTypes.map((text, index) => {
      return (
        [
          { type: "id", value: `${index <= 8 ? '0' + (index + 1) : index + 1}`, id: text._id },
          {
            type: "string",
            name: "name",
            value: text.inquiryName,
          },
          {
            type: "array",
            name: "multi_images",
            value: text.members.map((tex, d) => {
              return (
                {
                  name: tex.name.firstName + (tex.name.lastName ? ' ' + tex.name.lastName : ''),
                  type: "url",
                  src: tex?.imageDetails?.image ? tex.imageDetails.image : null,
                  initials: tex?.imageDetails?.image ? null : tex.name.firstName.slice(0, 1).toUpperCase() + (tex.name.lastName && tex.name.lastName.slice(0, 1).toUpperCase()),
                  listStyle: {
                    position: 'relative',
                    left: `${d === 0 ? '-23px' : 1 * (-d + 0.25)}rem`,
                    border: '3px solid white',
                    borderRadius: '50px',
                    zIndex: 100 - d,
                  },
                }
              )
            })
          },
        ]
      )
    }) : []
    setInquiry(Inq)
  }

  function visibleModal() {
    setAlertModal(true)
  }
  const object = [
    {
      label: "About Org",
      item: "About Org",
    },
    {
      label: "Contact Info",
      item: "Contact Info",
    },
    {
      label: "Page setup",
      item: "Page setup",
    },
  ];
  const publish = async () => {
    let pages = props?.pages?.pages
    let lengthOfPages = pages.filter(text => text.quickAccess === true);
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    if (Title === '') {
      setErrorFound(true)
    } else {
      const data = {
        enabledIntegrations: {
          ...integrationOrg,
          deenweb: {
            ...integrationOrg.deenweb,
            settings: {
              ...integrationOrg?.deenweb?.settings,
              contactDetails: {
                ...integrationOrg?.deenweb?.settings?.contactDetails,
                email: email,
                primaryPhone: phoneFax.primaryPhone,
                secondaryPhone: phoneFax.secondaryPhone,
              },
            },
            pages: {
              ...pagesAll,
              aboutUs: {
                title: Title,
                status: homepageButton ? 'active' : 'inactive',
                enablePage: homepageButton,
                quickAccess: checked,
                lastEdited: new Date(),
                pageName: 'About Us',
              }
            }
          }
        }
      }

      if (!checked || org.quickAccess || lengthOfPages.length < 6) {
        try {
          //console.log("Data", data)
          await saveAsDraft(orgId, data, token);
          setshowAlert(true);
          setsuccess('Publish')
          setValue(dischard_changes);
          setAlertModal(false)
          setCheckChanges(false)
          let Quick = pages.filter(text => text.name[0] !== "aboutUs")
          Quick.push({
            name: ['aboutUs'],
            quickAccess: checked
          })
          props.dispatchPagesAction(Quick)
        } catch (err) {
          seterror(err.response && err.response.data.message)
          setTimeout(() => {
            seterror(false)
          }, 8000);
        }
      } else {
        setQuickLimit(true)
        setTimeout(() => {
          setQuickLimit(false)
        }, 8000)
      }
    }
  };
  const saveStyles = {
    backgroundColor: "#2A82F6",
  };
  const publishStyles = {
    backgroundColor: "#2A82F6",
    opacity: 0.6,
  };
  const paperStyles = {};
  const styles = {
    BaseTextField: {
      width: "100%",
    },
    contents: {
      paddingTop: 20,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "100%",
      marginTop: 70,
      marginLeft: 280,
    },
  };
  const [checked, setChecked] = useState(true);
  let inquiryCol = [
    { id: "NO.", label: "NO.", width: "auto" },
    { id: "INQUIRY_NAME", label: "INQUIRY NAME", width: "50%" },
    { type: "array", name: "multi_images", label: "EMAIL TO", width: "40%" },

    { id: "ACTION", label: "ACTION", width: '15%' },
  ];
  let InquiryclassProps = () => {
    const tempArray = [];
    inquiryCol.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const handleClose = () => {
    setAlertModal(false)
  };

  const inquiryHandler = async (inquiryData) => {
    const contactDetails = integrationOrg?.deenweb?.settings?.contactDetails ?? {}
    const inquiryTypes = integrationOrg?.deenweb?.settings?.contactDetails?.inquiryTypes ?? []
    const settings = integrationOrg?.deenweb?.settings ?? {}

    const data = {
      enabledIntegrations: {
        ...integrationOrg,
        deenweb: {
          ...integrationOrg.deenweb,
          settings: {
            ...settings,
            contactDetails: {
              ...contactDetails,
              inquiryTypes: inquiryData,
            },
          }
        }
      }
    };

    try {
      const orgId = localStorage.getItem('orgID');
      const token = localStorage.getItem('authToken');

      //console.log("Data", data)
      await saveAsDraft(orgId, data, token);
      setshowAlert(true);
      setsaved(!saved)
    } catch (err) {
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }

  const deleteInquiry = (data) => {
    //console.log("data", data, data[0].id)
    const inquiryTypes = integrationOrg?.deenweb?.settings?.contactDetails?.inquiryTypes ?? []
    let arr = inquiryTypes.filter((inqT) => inqT._id !== data[0].id);
    inquiryHandler(arr)
    inquiryTableData(arr)
  }

  const onSaveDrawer = async () => {
    console.log('save', selectedInquiries)
    setOpenDrawerInquiry(false)
    const inquiryTypes = integrationOrg?.deenweb?.settings?.contactDetails?.inquiryTypes ?? []
    let data = selectedInquiries;
    inquiryHandler(data)
    inquiryTableData(data)
  }

  const saveChanges = async () => {
   console.log("Changes Saved...")
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    if (Title === '') {
      setErrorFound(true)
    } else {
      const data = {
        enabledIntegrations: {
          ...integrationOrg,
          deenweb: {
            ...integrationOrg.deenweb,
            pages: {
              ...pagesAll,
              aboutUs: {
                title: Title,
                status: homepageButton ? 'active' : 'inactive',
                enablePage: homepageButton,
                quickAccess: checked,
                lastEdited: new Date(),
                pageName: 'About Us',
              }
            }
          }
        }
      }
      try {
        await saveAsDraft(orgId, data, token);
        setshowAlert(true);
        setsuccess(true)
        setValue(dischard_changes);
        setAlertModal(false)
      } catch (err) {
        seterror(err.response && err.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    }
  };
  const checkChanges = (boolean) => {
    setCheckChanges(boolean)
  }
  const discardChanges = () => {
    setCheckChanges(false)
    setAlertModal(false)
    setChecked(org.quickAccess);
    setHomePageButton(org.enablePage)
    setTitle(org.title)
    setValue(dischard_changes)
    if (unblockHandle) {
      unblockHandle.current()
    }
    if (saved_route) {
      props.history.push(saved_route)
    }
  };
  const InquirychangeClassProps = () => {
    const tempArray = InquiryclassProps();
    tempArray[0] = "class15";
    return tempArray;
  }

  const info = (
    <>
      <Typography className="page_form_title">About organization</Typography>
      <div className="about-us" style={{}}>
        <h5 className="about">About us</h5>
        <TextEditor
          onChange={(e) => console.log("Changed")}
          disabled={true}
          value={shortDesc}
          label={"Short Description"}
          helperText={<p>
            Please Add/Edit this section from Organization &gt; Overview &gt;{" "}
            <span style={{ color: "#2A82F6" }}>About Organization</span>
          </p>}
        />
        <br />
        <TextEditor
          onChange={(e) => console.log("Changed")}
          disabled={true}
          value={longDesc}
          label={"Long Description"}
          helperText={<p>Please Add/Edit this section from Organization &gt; Overview &gt;{" "}
            <span style={{ color: "#2A82F6" }}>About Organization</span></p>}
        />
      </div>
      <div className="about-us" style={{ paddingBottom: 48 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: 940,
          }}
        >
          <h5 className="about">Org Mission</h5>
        </div>
        <p className="descPara">
          Brief description of your organization mission (Optional)
        </p>
        <>
          <TextEditor
            onChange={(e) => console.log("Changed")}
            disabled={true}
            value={ourMission}
            label={"Description"}
            helperText={<p>
              Please Add/Edit this section from Organization &gt; Overview &gt;{" "}
              <span style={{ color: "#2A82F6" }}>About Organization</span>
            </p>}
          />
        </>
        <br />
      </div>
    </>
  );
  const contact = (
    <>
      <Typography className="page_form_title">Contact Info</Typography>
      <div className="contact-us about-us-contact">
        <h5 className="contact">Contact Us</h5>
        <p className="contact-desc">
          This contact details will be visible to user in “Contact Us”
        </p>



        <Grid container spacing={3}>
          <Grid item md={4} sm={4} xs={12}>
            <BasePhoneInput
              phoneInputVariant={"underlineWithFlag"}
              countryCodeEditable={false}
              specialLabel={'Primary Phone Number'}
              placeholder="Phone Number"
              country={"us"}
              value={phoneFax.primaryPhone}
              inputStyle={{
                marginLeft: "0px",
                height: "34px",
                width: "100%",
                fontFamily: "Roboto-Regular",
                fontSize: "16px",
              }}
              onChange={(e, f, g, h) => {
                setPhoneFax({ ...phoneFax, primaryPhone: h })
                setCheckChanges(true)
              }}
            />
          </Grid>

          <Grid item md={4} sm={4} xs={12}>
            <BasePhoneInput
              phoneInputVariant={"underlineWithFlag"}
              countryCodeEditable={false}
              specialLabel={'Secondary phone number'}
              placeholder="Phone Number"
              country={"us"}
              value={phoneFax.secondaryPhone}
              inputStyle={{
                marginLeft: "0px",
                height: "34px",
                width: "100%",
                fontFamily: "Roboto-Regular",
                fontSize: "16px",
              }}
              onChange={(e, f, g, h) => {
                setPhoneFax({ ...phoneFax, secondaryPhone: h })
                setCheckChanges(true)
              }}
            />
          </Grid>

          <Grid item xs={8}>
            <BaseTextField
              label="Info Email"
              // helperText={"Helper Text"}
              style={{ width: "100%", marginLeft: 0 }}
              value={email}
              placeholder=" "
              onChange={(e) => {
                setCheckChanges(true)
                setEmail(e.target.value)
              }}
            />
          </Grid>
        </Grid>
        <br /><br />
      </div>

    </>
  );

  const data2 = inquiryTypesData?.length ? inquiryTypesData?.map((el) => { return { id: el._id, primary: el.inquiryName, data: { ...el } } }) : [];
 
  const selectedData = webContactDetails?.inquiryTypes?.length ? webContactDetails.inquiryTypes.map((el) => { return { id: el._id, primary: el.inquiryName, data: { ...el } } }) : [];

  const getData = (values) => {
    console.log("getData", values);
    if (!values?.length) return
    setselectedInquiries(values?.map((el) => { return { ...el.data } }))
  }
  //console.log(inquiryCol)
  //console.log(Inquiry)
  const inquiryTable = (
    <>
      <SimpleTable
        showHeader={true}
        title="Inquiry Types"
        rows={Inquiry}
        columns={inquiryCol}
        colSpan={16}
        actionSendButton={true}
        classProps={InquirychangeClassProps()}
        donationsCount={Inquiry?.length > 0 ? 1 : 0}
        noTitle="No Inquiry added"
        noPara="Added Inquiry will be displayed and managed here"
        noBtnText="ADD INQUIRY TYPE"
        onInviteClick={() =>
          setOpenDrawerInquiry(true)
        }
        showIconCol={true}
        editIcon={Edit}
        deleteIcon={Delete}
        showFooter={true}
        showAdd={true}
        iconTextSize={16}
        addText="ADD"
        onAddClick={(e) => setOpenDrawerInquiry(true)}
        editAction={(e) => setOpenDrawerInquiry(true)}
        deleteAction={(e) => deleteInquiry(e)}
      />
      <DrawerMain
        open={openDrawerInquiry}
        openHandler={() => setOpenDrawerInquiry(false)}
        title="Add Inquiry Type"
        onCancel={() => setOpenDrawerInquiry(false)}
        onSave={onSaveDrawer}
        loading={false}
      >
        <div className="inquiry-list-aboutus" style={{ height: 75 }}>
          {/* <BaseSelect
          value={inquiryName}
          onChange={(event) => setInquiryName(event.target.value)}
          object={inquiryTypesData?.map((el) => { return { value: el, label: el }})}
          label='Inquiry Name'
        /> */}

          <DrawerListCheckbox
            objects={data2}
            subheader={"Select inquiries to show in website"}
            getData={getData}
            selectedItems={selectedData}
          />

          <div className="DrawerInfo">
            <div className="DrawerInfoTitle">Add new Inquiries from</div>
            <Link to={{
              pathname: "/organization/overview",
              state: 3
            }} >Organization > Contact info</Link>
          </div>

        </div>
        {/* <div style={{ width: 305 }} className='event_Image'>
          <p>Email to</p>
          <List>
            {teamMembers?.length ? teamMembers.map((mem) => {
                let nameArr = [mem.name.firstName, mem.name.lastName];
                let initials;
                if (nameArr.length > 0) {
                  initials = nameArr[0][0] + (nameArr[1] === '' ? '' : nameArr[1][0]);
                } else {
                  initials = nameArr[0][0];
                }
                return (
                  <>
                    <ListItem
                      alignItems='flex-start'
                      // style={{ marginLeft: -15 }}
                    >
                      <div
                        style={{
                          width: 40,
                          height: 40,
                          background: '#7266FB 0% 0% no-repeat padding-box',
                          borderRadius: 50,
                          marginTop: 5,
                          marginRight: 10,
                        }}
                      >
                        <p
                          style={{
                            textAlign: 'center',
                            fontSize: 16,
                            letterSpacing: 0.51,
                            color: '#FFFFFF',
                            verticalAlign: 'middle',
                            marginTop: 10,
                          }}
                        >
                          {initials.toUpperCase()}
                        </p>
                      </div>
                      <ListItemText
                        primary={nameArr.join(' ')}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component='span'
                              variant='body2'
                              color='textPrimary'
                            ></Typography>
                            {mem.role[0].roles[0].toUpperCase() +
                              mem.role[0].roles.slice(1)}
                          </React.Fragment>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge='end'
                          onChange={() => addInquiryMember(mem)}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider component='li' className='divider-inq' />
                  </>
                );
              }) : null}
          </List>
          {addInquiryError && inquiryMembers.length === 0 && (
            <p style={{ color: '#f44336 ', fontSize: '0.75rem' }}>
              Select atleast one member
            </p>
          )}
        </div> */}

      </DrawerMain>
    </>
  );
  const Alertstyle = {
    width: 470,
    marginLeft: '35%'
  };
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          <PageHeaderWithTabs
            back={() => props.history.push('/website/pages')}
            value={value}
            title="About Us"
            object={object}
            disabled={changes ? false : true}
            indicatorColor="primary"
            style={{ color: "#2A82F6", border: "1px solid black" }}
            handleChange={(x, y) => handleChange(x, y)}
            indicatorColor="#2A82F6"
            saveStyles={saveStyles}
            publishStyles={publishStyles}
            hiddenDraft={true}
            paperStyles={paperStyles}
            saveChanges={() => saveChanges()}
            publish={() => publish()}
          />
          {
            showAlert && success && success === 'Publish' ? (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={Your_changes_has_been_published}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            ) : showAlert && success && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={Your_changes_has_been_saved_as_draft}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            )
          }
          {
            error && error === "Token invalid or expired" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            QuickLimit && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={quick_access_limit_axeeded}
                  alertType={"error"}
                  onClick={() => setQuickLimit(false)}
                />
              </div>
            )
          }
        </>
      }
      content={
        Loader ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <>
            <div>
              <div>
                <AlertDialogueAdvance
                  title="You have unsaved changes!"
                  description='There are unsaved changes. If you would like to save changes, press the "SAVE AS DRAFT" button.'
                  cancelText="Discard Changes"
                  confirmText="Save As Draft"
                  open={open}
                  handleCancel={() => publish()}
                  handleClose={() => handleClose()}
                  handleConfirm={() => discardChanges()}
                  styles={Alertstyle}
                />
              </div>

              {value === 0 && (
                <div style={{ border: "1px solid #E8E9E9", marginTop: 24 }}>
                  <BasePaper
                    context={info}
                    square={false}
                    paperStyle={styles.paperStyleNext}
                  />
                </div>
              )}
              {value === 1 && (
                <div style={{ marginTop: 24, border: 'none' }}>
                  <div
                    style={{
                      boxShadow: "0px 2px 4px #0000001A",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <BasePaper
                      context={contact}
                      square={false}
                      paperStyle={styles.paperStyleNext}
                    />
                  </div>
                  <div
                    style={{ boxShadow: "0px 2px 4px #0000001A", marginTop: 29 }}
                    className="inquiry-table"
                  >
                    <BasePaper
                      context={inquiryTable}
                      square={false}
                      paperStyle={styles.paperStyleNext}
                    />
                  </div>
                </div>
              )}

              {value === 2 && (
                <div className="page_body">
                  <Typography className="page_form_title">Page Settings</Typography>
                  <Grid className="form_body">
                    <Grid container item xs={6} spacing={3} >
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={Title}
                            onChange={(e) => {
                              setTitle(e.target.value); checkChanges(true); setErrorFound(false)
                            }}
                            InputProps={false}
                            phone={false}
                            error={ErrorFound}
                            search={false}
                            count={20}
                            style={styles.BaseTextField}
                            color="primary"
                            disabled={false}
                            // helperText="Helper text"
                            label="Page Title"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="page_switch">
                          <FormSwitch
                            style={styles.BaseTextField}
                            headline="Enable Page"
                            body="Disabled pages can’t be accessed by your site visitors."
                            values={homepageButton}
                            handleSwitchChange={() => { setHomePageButton(!homepageButton); checkChanges(true) }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="SolidBorder"></div>
                        <div className="checkBox">
                          <BaseCheckBox
                            label="Quick Access"
                            checked={checked}
                            color="secondary"
                            showHelperText={true}
                            helperText="Add to left navigation under QUICK ACCESS for quickly accessing the page"
                            onChange={(e) => { setChecked(e.target.checked); checkChanges(true) }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          </>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  );
}
const mapStateToProps = (state) => {
  return {
    pages: state.pages,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatchPagesAction: (data) => dispatch(PagesAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
