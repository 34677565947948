import axios from "axios";

const baserUrl =
  "https://cors-anywhere.herokuapp.com/http://18.211.207.245:3000";
const env = {
  prod: process.env.REACT_APP_PAYMENT_PROD_API,
  dev: process.env.REACT_APP_PAYMENT_DEV_API
};
const orgEnv = {
  prod: process.env.REACT_APP_BASEURL_PROD_API,
  dev: process.env.REACT_APP_BASEURL_DEV_API
};

let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")

) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}
const orgBaseUrl = orgEnv[currentEnv];

export function GetContacts(token, id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/contact?orgId=${id}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetNewsLetter(token, id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/newsLetter/${id}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetNewsLetterById(token, id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${orgBaseUrl}/newsletter/getById/${id}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function DeleteContact(token, id, orgId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${orgBaseUrl}/contact/${id}?orgId=${orgId}`, {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function UpdateContact(token, id, data) {
  return new Promise((resolve, reject) => {
    axios
      .put(`${orgBaseUrl}/contact/${id}`, data , {
        headers: {
          token
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
}