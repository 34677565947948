import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {PageHeaderWithButton } from "@dclab/dc-react-ui-kit";
import { updateHomeScreen, updateHomeScreenPreview } from "../Apis/index";
import { withRouter } from 'react-router-dom';


const env = {
  prod: "https://kiosk.deenkiosk.com",
  dev: "https://shapla.kiosk.deenkiosk.com",  
  // dev: " http://localhost:3001",  
};

let currentEnv;
if (
  window.location.host.includes("amazon") ||
  window.location.host.includes("localhost") ||
  window.location.host.includes("shapla")
) {
  currentEnv = "dev";
} else {
  currentEnv = "prod";
}
const dkBaseUrl = env[currentEnv];


function PageHeader(props) {
  const {editHomeScreen} = props;
  // ======== Setting Data Start ========//
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(event, newValue);
  };
  
  const saveChanges = () => {
    console.log("save");
  };
  
  const publish = async () => {
    // console.log("publish", editHomeScreen);
    const token = localStorage.getItem("authToken")
    const res = await updateHomeScreen(editHomeScreen?._id, {...editHomeScreen}, token)
    props.setchanges(false)
    props.history.push({pathname: "/deenkiosk/home-screen", state: { showAlert: true }})
    console.log("res", res) 
  };
  const back = () => {
    props.history.push("/deenkiosk/home-screen")
  };

  const onPreviewClick = async () => {
    const orgId = localStorage.getItem("orgID")
    const token = localStorage.getItem("authToken")

    try {
      let res = await updateHomeScreenPreview(editHomeScreen?._id, orgId, {...editHomeScreen}, token);
      if(res?.data?.body?.data) {
        const url = `${dkBaseUrl}/home-preview?orgId=${orgId}&homeScreenId=${editHomeScreen?._id ?? ""}`;
        window.open(url, '_blank');
      }
    } catch (error) {
      console.log(error)
    }

  }
  
  const saveStyles = {
    backgroundColor: "#2A82F6",
  };
  
  const publishStyles = {
    backgroundColor: "#2A82F6",
    opacity: 0.6,
  };
  const livePauseStyles = {
    display: "flex",
    alignItems: "center",
    marginLeft: "8px",
    marginTop: "1px",
    height: "36px",
    minWidth: "114px",
    backgroundColor: "#FFFFFF",
    borderTopLeftRadius: "30px",
    borderBottomLeftRadius: "30px",
    borderTopRightRadius: "30px",
    borderBottomRightRadius: "30px",
    cursor : "pointer",
    border: "1px solid #D3D8DA",
    textTransform:"uppercase",
    justifyContent:"center",
    alignItems:"center",
    minWidth:"114px",
  };
  const livePauseTextStyle = { 
    margin: "0px", 
    fontSize: "14px", 
    color: "#4D4D4D", 
    fontWeight: 'bold', 
    fontFamily: '"Roboto", "Helvetica"' }
  
  const paperStyles = {
    backgroundColor: '#fff',
    top:"80px",
  };

  return (
    <div>
      <PageHeaderWithButton

        title={editHomeScreen?.name ?? ""}
        indicatorColor="primary"
        style={{ color: "#fff", border: "1px solid " }}
        handleChange={(x, y) => handleChange(x, y)}
        headerColor={'#000'}
        //subHeader={'We provide powerful tools, integration, features and more.'}
        saveStyles={saveStyles}
        publishStyles={publishStyles}
        paperStyles={paperStyles}
        saveChanges={() => saveChanges()}
        publish={() => publish()}
        disabled={props.changes ? false : true}
        back={back}
        showBackBtn={true}
        livePauseBtnText={"Preview"}
        livePauseStyles={livePauseStyles}
        livePauseOnclick={() => onPreviewClick()}
        livePauseTextStyle={livePauseTextStyle}
        hiddenDraft={false}
        hiddenPublish={false}
        btnText="Back"
        saveBtn="save changes"
      />

    </div>


  );
}
export default withRouter(PageHeader);


const useStyles = makeStyles((theme) => ({
  DashLine: {
    border: " 1px dashed #D3D8DA",
  },

  text: {
    letterSpacing: " 0.25px",
    color: '#818E94',
    fontSize: "14px",
    lineHeight: "19px",
  },

  BorderLine: {
    borderBottom: " 1px solid #E4E8EC",
  }

}));