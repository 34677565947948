import React from 'react';
import { SelectFieldOne, TextInputFixedLabel, PhoneInputField, } from "master-react-lib";
import { FormSwitch } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';


function VenueDetails(props) {

    const handleChangeGender = (event, value) => {
        console.log(event.target.value, value);
    };


    const Gender = [
        { value: 1, select: "Male" },
        { value: 2, select: "Female" },
    ];

    const Estimated = [
        { value: 1, select: "10 - 20" },
        { value: 2, select: "10 - 25" },
    ];

    const Food = [
        { value: 1, select: "Light refreshment" },
        { value: 2, select: "Dinner" },
    ];

    const Served = [
        { value: 1, select: "Dine-in/Eat in" },
        { value: 2, select: "Dine-in/Eat in" },
    ];

    


    




    const [values, setValues] = React.useState(false);

    const handleSwitchChange = (event) => {
        setValues(event)
        console.log(event, 'swuitch')
        // setValues();
    };

    var count = 1;
    const addMore = () => {
        count++;
    }

    return (

        <div>

            <div className='switchTop'>
                <FormSwitch
                    headline='Guests attending'
                    body=""
                    values={values}
                    handleSwitchChange={(e) => handleSwitchChange(e)}
                />
            </div>


            <Grid container spacing={3}>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <SelectFieldOne
                            label="Guest Type"
                            selectdata={Gender}
                            defaultValue={1}
                            handleChange={handleChangeGender}
                        />
                    </div>
                </Grid>


                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <SelectFieldOne
                            label="Served In"
                            selectdata={Estimated}
                            defaultValue={1}
                            handleChange={handleChangeGender}
                        />
                    </div>
                </Grid>

            </Grid>

            <Grid container spacing={3} >

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <SelectFieldOne
                            selectdata={Gender}
                            defaultValue={1}
                            handleChange={handleChangeGender}
                        />
                    </div>
                </Grid>


                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <SelectFieldOne
                            selectdata={Estimated}
                            defaultValue={1}
                            handleChange={handleChangeGender}
                        />
                    </div>
                </Grid>

            </Grid>

            <div className="addButtonContainerTwo" onClick={() => addMore()}><AddCircleRoundedIcon /> ADD MORE GUESTS </div>

            <div className="SpBorder"></div>
            
            <div className='switchBox'>
                <FormSwitch
                    headline='Serving food/refreshment'
                    body=""
                    values={values}
                    handleSwitchChange={(e) => handleSwitchChange(e)}
                />
            </div>

            <Grid container spacing={3} >

                <Grid item xs={12} md={12}>

                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <SelectFieldOne
                            label="Food Type"

                            selectdata={Food}
                            defaultValue={1}
                            handleChange={handleChangeGender}
                        />
                    </div>
                </Grid>


                <Grid item xs={12} md={6}>
                    <div className="field_box">
                        <SelectFieldOne
                            label="Served In"
                            selectdata={Served}
                            defaultValue={1}
                            handleChange={handleChangeGender}
                        />
                    </div>
                </Grid>

            </Grid>

            <div className="addButtonContainerTwo" onClick={() => addMore()}><AddCircleRoundedIcon /> ADD MORE GUESTS </div>

        </div>

    );
}


export default VenueDetails;