import React, { Component } from "react";
// import "react-phone-input-2/lib/style.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import {
  SimpleTable,
  AlertAction,
  GridContainer,
  GridItem,
  MasterLayoutMiddle,
  FooterInner
} from "@dclab/dc-react-ui-kit";
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import { getSpeakers, deleteSpeaker } from "./api";
import CircularProgress from "@material-ui/core/CircularProgress";
import "./style.css";
import "../../App.css";
import "../style.css";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import { connect } from "react-redux";
import messages_content from '../../utils/messages.json';
const { 
    token_expired,
    guest_remove_success_message
  } = messages_content;
class Speakers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editableSpeaker: [],
      speakerName: "",
      designation: "",
      email: "",
      phoneNo: "",
      phoneNo2: "",
      available: false,
      addSpeaker: false,
      error: false,
      speakers: [],
      success: false,
      editSuccess: false,
      showAlert: false,
      edit: false,
      editSpeakerId: "",
      deleteSpeakerId: "",
      openDrawer: false,
      speakerImage: [],
      image: [],
      loading: true,
      openAlert: false,
      modalLoading: false,
    };
    this.delete = this.delete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSpeakerConfirm = this.handleSpeakerConfirm.bind(this);
  }
  async componentDidMount() {
    const token = localStorage.getItem("authToken");
    let orgId = localStorage.getItem("orgID");
    try {
      const res = await getSpeakers(orgId, token);
      this.setState({
        speakers: res.data.body.data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error: error.response && error.response.data.message,
        loading: false,
      });
    }
  }
  handleClose = () => {
    this.setState({ openAlert: false, deleteSpeakerId: "" });
  };
  handleSpeakerConfirm = async () => {
    this.setState({ modalLoading: true });
    const token = localStorage.getItem("authToken");
    const { speakers, deleteSpeakerId } = this.state;
    try {
      const res = await deleteSpeaker(token, deleteSpeakerId);
      let newSpeakersArr = speakers.filter((sp) => sp._id !== deleteSpeakerId);
      this.setState({
        speakers: newSpeakersArr,
        openAlert: false,
        deleteSpeakerId: "",
        modalLoading: false,
      });
      this.props.alertShow(guest_remove_success_message);

    } catch (error) {
      this.setState({
        error: error.response.data.message,
        openAlert: false,
        deleteSpeakerId: "",
        modalLoading: false,
      });
    }
  };
  delete = (e) => {
    this.setState({
      openAlert: true,
      deleteSpeakerId: e[0].label,
    });
  };
  render() {
    const {
      error,
      speakers,
      success,
      showAlert,
      loading,
      openAlert,
      modalLoading,
    } = this.state;
    if (this.props.alert.alertValue) {
      setTimeout(() => {
        this.props.alertHide()
      }, 8000);
    }
    let alertSpeakerModal = (
      <Dialog
        open={openAlert}
        onClose={() => this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Guest?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this guest? <br/>Deleting will permanently remove from everywhere.
          </DialogContentText>
        </DialogContent>
        {modalLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 50,
            }}
          >
            <CircularProgress
              color="primary"
              style={{ width: 24, height: 24 }}
            />
          </div>
        ) : (
            <DialogActions>
              <Button onClick={() => this.handleClose()} color="primary">
                Cancel
            </Button>
              <Button onClick={() => this.handleSpeakerConfirm()} color="primary">
                Confirm
            </Button>
            </DialogActions>
          )}
      </Dialog>
    );
    let col = [
      { id: "NO", label: "NO.", width: "auto" },
      { type: "url", name: "single_pic", label: "IMAGE", width: 'auto' },
      { type: "string", name: "name", label: "GUESTS NAME", width: '30%' },
      { type: "string", name: "category", label: "DESIGNATION", width: '25%' },
      { type: "string", name: "category", label: "PHONE", width: '20%' },
      { id: "STATUS", label: "STATUS", width: '15%' },
      { id: "ACTION", label: "ACTION", width: '15%' },
    ];

    let rows = speakers.map((sp, i) => {
      console.log("speaker: ", sp)
      let nameArr = [sp.name.firstName, sp.name.lastName]
      let initials;
      if (nameArr.length > 1) {
        initials = nameArr[0][0] + nameArr[1][0];
      } else {
        initials = nameArr[0][0];
      }
      return [
        { type: "id", value: i + 1, label: sp._id },
        { type: "url", name: "single_pic", src: sp.imageMetaData.imageUrl },
        { type: "string", name: "name", value: sp.name.firstName + (sp.name.lastName ? " " + sp.name.lastName : '') },
        { type: "string", name: "category", value: sp.designation.title },
        { type: "string", name: "category", value: sp.phone },
        {
          type: "status",
          status:
            sp.status.toLowerCase() === "available" ? "success" : "failed",
          value: sp.status,
        },
      ];
    });
    let classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    const style = {
      width: '100%',
    };
    return (
      <MasterLayoutMiddle
        header={
          <>
            {(error && error === "Invalid token") ||
              (error && error === "No token Provided" && (
                <div
                  style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}
                >
                  <AlertAction
                    alert={token_expired}
                    alertType={"error"}
                    onClick={() => this.setState({ error: false })}
                  />
                </div>
              ))}
            {showAlert && error && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={
                    error === "Invalid token"
                      ? token_expired
                      : error
                  }
                  alertType={"error"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {showAlert && success && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={"Success"}
                  alertType={"success"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {
              this.props.alert.alertValue &&
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={this.props.alert.alertValue}
                  alertType={"success"}
                  onClick={() => this.props.alertHide()}
                />
              </div>
            }
          </>
        }
        content={
          <>
            <GridContainer spacing={0} containerClass="grid-main">
              <GridItem itemStyle={{ marginTop: 0 }} itemClass="grid-item">
                <div className="speaker-simple-table">
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 30,
                      }}
                    >
                      <CircularProgress color="primary" />
                    </div>
                  ) : (
                      <div className="speaker-simple-table2">
                        <SimpleTable
                          showHeader={true}
                          showCol={false}
                          title="Guests"
                          rows={rows}
                          columns={col}
                          colSpan={16}
                          actionSendButton={true}
                          classProps={changeClassProps()}
                          donationsCount={rows && rows.length > 0 ? 1 : 0}
                          noTitle="No Guests added"
                          noPara="Added Guests will be displayed and managed here"
                          noBtnText="Add New Guests"
                          onInviteClick={() =>
                            this.props.history.push({
                              pathname: "/organization/add-new-guests",
                              speakers,
                            })
                          }
                          actionLink={(name) => this.details(name)}
                          showIconCol={true}
                          editIcon={Edit}
                          deleteIcon={Delete}
                          editAction={(e) =>
                            this.props.history.push(
                              `/organization/edit-speaker/${e[0].label}`
                            )
                          }
                          deleteAction={(e) => this.delete(e)}
                          showFooter={true}
                          showAdd={true}
                          onAddClick={() =>
                            this.props.history.push({
                              pathname: "/organization/add-new-guests",
                              speakers,
                            })
                          }
                          iconTextSize={16}
                          addText="ADD NEW"
                        />
                      </div>
                    )}
                </div>
              </GridItem>
            </GridContainer>
            {openAlert && alertSpeakerModal}
          </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={false}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    alert: state.alert,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    alertHide: (res) => dispatch(hideAlert(res)),
    alertShow: (res) => dispatch(showAlert(res)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Speakers);
