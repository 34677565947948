// import WebSocket from 'isomorphic-ws';
function connect() {
  let ws;
  const orgId = localStorage.getItem("orgID");
  if (orgId) {
    const sharedEnv = {
      prod: process.env.REACT_APP_PROD_SOCKET_URL,
      dev: process.env.REACT_APP_DEV_SOCKET_URL
    }
    let currentEnv
    if (window.location.host.includes('shapla') || window.location.host.includes('localhost') || window.location.host.includes('dev')) {
      currentEnv = 'dev'
    } else {
      currentEnv = 'prod'
    }
    const socketURL = sharedEnv[currentEnv]
    const WS_URL = `${socketURL}?orgId=${orgId}`;
    ws = new WebSocket(WS_URL);
  }
  ws.onclose = function (e) {
    console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
    setTimeout(function () {
      connect();
    }, 1000);
  };
  ws.onerror = function (err) {
    console.error('Socket encountered error: ', err.message, 'Closing socket');
    ws.close();
  };
  return ws
}
export const websocketOnMessage = (data) => {
  const websocket = connect();
  if (websocket) {
    websocket.onopen = function (event) {
      websocket.send(JSON.stringify({
        action: "onMessage",
        data: data
      }));
    };
  };
}
