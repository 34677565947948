import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { MasterLayoutMiddle, FooterInner, } from "@dclab/dc-react-ui-kit";
import './style.css'
import { BankCard, AlertAction} from "@dclab/dc-react-ui-kit";

import { getOrganizationData, getCustomerPaymentMethods } from './api';
import MasterCardImg from '../../assets/img/masterCardIcon.png'
import VisaCardImg from '../../assets/img/visa-icon.png'
import NewCardImg from '../../assets/img/new-card.png'
import DiscoverCardImg from '../../assets/img/discovercard.png';
import AmericanExpressImg from '../../assets/img/american-express.png';
import DinersCardImg from '../../assets/img/dinerscard.png';
import HelpImg from '../../assets/img/help-24px.svg'
import { CheckoutPayment } from "master-react-lib";
import JcbCardImg from '../../assets/img/jcb-card.png';
import UnionPayImg from '../../assets/img/unionpay.png';
import { connect } from "react-redux";
import { hideAlert } from "../../redux/actions/alertActions";

function PaymentMethods(props) {
  const [paymentMethods, setpaymentMethods] = useState([])
  const [defaultPayment, setdefaultPayment] = useState()

  useEffect(() => {
    const getData = async () => {
      const orgId = localStorage.getItem('orgID');
      try {
        const res = await getOrganizationData(orgId);
        const { orgPaymentMethods, defaultPaymentMethod } = res?.data?.body?.data;
        if (orgPaymentMethods?.length) {
          const methodsRes = await getCustomerPaymentMethods(orgPaymentMethods);
          methodsRes?.data?.body?.data && setpaymentMethods(methodsRes?.data?.body?.data)
          setdefaultPayment(defaultPaymentMethod)
        }
      } catch (error) {
        console.log(error)
      }
    }
    getData()
  }, [])

  console.log(paymentMethods, "paymentMethods")


  // ============================== Card Start ===============================//
  const onClickEditDetails = (data) => {
    // console.log("edit details", data);
    props.history.push({ pathname: "/organization/edit-billing-details", state: { method: data, default: data?.id ===  defaultPayment} })
  };

  const addNewPaymentMethod = () => {
    console.log("Add new method");
    props.history.push("/organization/add-billing-details")
  };
  // ============================== Card END ===============================//
  const style = {
    width: '100%',
  };

  const cardBrandImage = (brand) => {
    brand = brand?.toLowerCase();
    switch (brand) {
      case "visa":
        return VisaCardImg

      case "amex":
        return AmericanExpressImg

      case "discover":
        return DiscoverCardImg

      case "diners_club":
        return DinersCardImg

      case "jcb":
        return JcbCardImg

      case "mastercard":
        return MasterCardImg

      case "unionpay":
        return UnionPayImg

      default:
        return ""
    }
  }

  if (props.alert.alertValue) {
    setTimeout(() => {
      props.alertHide()
    }, 8000);
  }

  return (
    <MasterLayoutMiddle
      content={
        <div>
          {
            props.alert.alertValue &&
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
              <AlertAction
                alert={props.alert.alertValue}
                alertType={"success"}
                onClick={() => props.alertHide()}
              />
            </div>
          }

          <Grid container spacing={3} >
            <Grid item md={12} sm={12} xs={12} >
              <div className="PageHeadingTopleft">Payment Methods</div>
            </Grid>

            <Grid item md={12} sm={12} xs={12} >
              <div className="BankCardsContainer">


                {paymentMethods?.length ? paymentMethods.map((method, i) => {
                  return <BankCard
                    addNew={false}
                    cardImage={cardBrandImage(method?.card?.brand)}
                    defaultMethod={method?.id === defaultPayment}
                    cardName={method?.card?.brand?.toUpperCase() ?? ""}
                    endingIn={method?.card?.last4 ?? ""}
                    expiryDate={method?.card?.exp_month + "/" + method?.card?.exp_year}
                    onClickEditDetails={() => onClickEditDetails(method)}
                  />
                }) : null}

                <div onClick={addNewPaymentMethod} style={{ cursor: "pointer" }}>
                  <BankCard addNew={true} addNewPaymentMethod={addNewPaymentMethod} />
                </div>

              </div>
            </Grid>



          </Grid>

        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  );
}
const mapStateToProps = (state) => ({
  alert: state.alert,
});

const mapDispatchToProps = (dispatch) => ({
  alertHide: (res) => dispatch(hideAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);