import React, { Component } from 'react';
import './LLoader.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
class FullPageLoader extends Component {
    render() {
        return (
            <div className="">
                <div className="loader_bg"></div>
                <div className="loader">
                    <Loader type="Oval" color="#fff" height={70} width={70} />
                    <p>We are now setting up everything so that funds directly goes to the bank account you provided.</p>
                </div>
            </div>
        );
    }
}
export default FullPageLoader;