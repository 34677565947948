import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { MasterLayoutMiddle, FooterInner, CardDetailsTable, EventLogs, AlertAction, AlertDialogue, GridItem , Initials} from "@dclab/dc-react-ui-kit";
import '../style.css';
import Moment from "moment";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {editMember} from '../BuildProject/api';
import {deleteTeamMember, resetInvite} from './api';
import CircularProgress from "@material-ui/core/CircularProgress";
import { showAlert } from "../../redux/actions/alertActions";
import { connect } from "react-redux";
import messages_content from '../../utils/messages.json';
const { 
    token_expired,
    invite_Resent_success_message,
    team_member_removed_success_message
} = messages_content;

function TeamMembersActiveDetails(props) {
    const [memberData, setMemberData] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [currentUserRole, setCurrentUserRole] = useState('');
    const [loading, setloading] = useState(false);

    useEffect(() => {
        if(props?.location?.state?.member) {
            const role = localStorage.getItem("role");
            setCurrentUserRole(role);
            setMemberData(props.location.state.member);
        }
    }, []);

    const getStatus = (status) => {
    
        status = status?.toLowerCase();
        switch (status) {
          case 'active':
            return '#3CC480'
          
          case 'invite sent':
            return '#7266FB'

          default:
            return '#C44058'
        }
    }


    // ======= Table Start ========//
    let header = {

        avatar: memberData?.imageDetails?.image ?  <img src={memberData?.imageDetails?.image} /> : <Initials name= {memberData?.name?.firstName + " " + memberData?.name?.lastName} />, //<Initials name="Islamic Brooklyn Institue" />,
        tableName: `${memberData?.name?.firstName + " " + memberData?.name?.lastName}`,
    }

    let data = [
        {
            field: "Email",
            value: memberData?.userName
        },
        {
            field: "Role",
            value: memberData?.role?.[0]?.roles?.toLowerCase() === 'admin' ? 'Admin' : memberData?.role?.[0]?.roles
        },

    ];

    const data2 = [


    ]

    let footerLinkName = "RESEND INVITE";
    let footerDeleteButton = "REMOVE";
    let ownerFooterButton = "TRANSFER OWNERSHIP"

    const resetInviteApi = async () => {
        try {
            setloading(true);
            const token = localStorage.getItem("authToken");
            const resetInviteResponse = await resetInvite({_id: memberData._id}, token);
            if(!resetInviteResponse?.data?.error) {
                props.showAlert(invite_Resent_success_message)
                props.history.push({
                    pathname: "/organization/overview",
                    state: 1,
                });
            }
            setloading(false);
        } catch (error) {
            console.log("Error While sending resent invite", error);
            setloading(false)
        }
    }
    // HANDLE FOOTER LINK
    const onClick = async () => {
        if(memberData.access?.toLowerCase() === "invite sent") {
            resetInviteApi();
        }
    }

    const onDeleteClick = () => {
        if(memberData?.role?.[0]?.roles?.toLowerCase() !== 'owner')
            setOpenAlert(true);
        else    
            console.log("Transfer OwnerShip is not implemented yet")
    }

    const send = () => {
        console.log("");
    };



    const Statustdata2 = [
        { value: 1, statuscolor: "#3CC480", select: "Active" },
        { value: 2, statuscolor: "#B00020", select: "Revoked" },
    ];

    const updateStatus = async (status) => {
        const token = localStorage.getItem("authToken");
       
        try {
            await editMember({access: status}, memberData._id, token);

        } catch (err){
            console.error(err)
        }
    }

    const handleChange = (event) => {
        if(event?.target?.value) {
            const newStatus = event?.target?.value === 1 ? "Active" : "Revoked";
            console.log("New Status is: ", newStatus);
            updateStatus(newStatus)

        }
    };

    const confirmDelete = async() => {
        setOpenAlert(false);
        setloading(true);
        const token = localStorage.getItem("authToken");
        try {
            const response = await deleteTeamMember(memberData._id, token);
            if(!response?.data.error) {
                setloading(false)
                props.showAlert(team_member_removed_success_message)
                props.history.push({
                    pathname: "/organization/overview",
                    state: 1,
                });
            } else {
                setloading(false);
            }
        } catch (err) {
            setloading(false)
            console.log("Error Occured: ", err);
        }
    };

    const Alertstyle = {
        width: 470,
        marginLeft: '35%'
    };

    const style = {
        width: '100%',
    };
    return (
        <MasterLayoutMiddle

            header={
                <>
                    <div
                        onClick={() =>
                            props.history.push({
                                pathname: "/organization/overview",
                                state: 1,
                              })
                        }
                        className='backBtn'>
                        <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
                        <p style={{ alignSelf: 'center' }}>Back</p>{' '}
                    </div>
                </>
            }

            content={
                

                <div className="detailsTableContainer">
                    <div>
                        <AlertDialogue
                        title= "Remove Team Member?"
                        description='Are you sure you want to delete this team member?'
                        description2 = "Deleting will permanently remove from everywhere."
                        cancelText="CANCEL"
                        confirmText="CONFIRM"
                        open={openAlert}
                        handleCancel={() => setOpenAlert(false)}
                        handleClose={() => setOpenAlert(false)}
                        handleConfirm={() => confirmDelete()}
                        styles={Alertstyle}
                        />
                    </div>

                    <Grid container spacing={3} >
                    {loading && <GridItem itemStyle={{ marginTop: 40, paddingBottom: 0 }} xs={12}>
                        <div
                            style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: 'center',
                            height: '50vh'
                            }}
                        >
                            <CircularProgress color="primary" />
                        </div>
                        </GridItem>}
                        {!loading &&<Grid item md={12} sm={12} xs={12}>
                            <CardDetailsTable
                                data={data}
                                data2={data2}
                                twoColumns
                                header={header}
                                footerLinkName={memberData.access?.toLowerCase() === "invite sent" ? footerLinkName : ''}
                                footerDeleteButton={memberData?.role?.[0]?.roles?.toLowerCase() === 'owner' ? ownerFooterButton : footerDeleteButton}
                                onClick={() => onClick()}
                                onDeleteClick={() => onDeleteClick()}
                                avatarStatus={true}
                                handleChange= {(value) => handleChange(value)}
                                value= {memberData?.access?.toLowerCase() === 'active' ? '1' : '2'}
                                Statustdata2={Statustdata2}
                                StatusDropdown={memberData.access && memberData.access?.toLowerCase() !== "invite sent" && memberData?.role?.[0]?.roles?.toLowerCase() !== 'owner' ? true : false }

                                Status={memberData.access && memberData.access?.toLowerCase() !== "invite sent" && memberData?.role?.[0]?.roles?.toLowerCase() !== 'owner'  ? false : true }
                                StatusLabel="Status"
                                StatusText= {memberData.access}
                                statuscolor= {getStatus(memberData.access)}
                            />
                        </Grid>}


                    </Grid>


                </div>
            }
            isFooter={true}
            footer={
                <FooterInner 
                    style={style} 
                    termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
                    privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                />
            }
            isWhiteBackground={false}
        />
    );
}
const mapStateToProps = (state) => {
    return {
      roles: state.teamMember.roles,
      teamMember: state.teamMember.editTeamMember,
    };
  };

const mapDispatchToProps = (dispatch) => {
    return {
        showAlert: (res) => dispatch(showAlert(res)),
    };
};
export default connect( mapStateToProps, mapDispatchToProps)(TeamMembersActiveDetails);