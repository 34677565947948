import React from "react";
import { connect } from 'react-redux'
import {
  SimpleAppBar,
  BasePaper,
  NavDrawer,
  BaseIcon,
  MasterLayout,
} from "@dclab/dc-react-ui-kit";
import { Switch, Route } from "react-router-dom";
import { ReactComponent as MoreApps } from "../../assets/nav_icons/moreapps.svg";
import { ReactComponent as Support } from "../../assets/nav_icons/support.svg";
import { ReactComponent as Launch } from '../../assets/nav_icons/launch-24px.svg'
import MainLogo from '../../assets/img/DC_Logo.png';
import { ChevronRight } from '@material-ui/icons';
import PrivateRoute from "../../components/PrivateRoute";


import VolunteerRequestListing from './VolunteerRequestListing';
import VolunteerDetails from './VolunteerDetails';
import EditDetails from './EditDetails';



const headerItems = [
  {
    title: "Volunteer Requestes",
    path: "/volunteer/volunteer-request",
  },
  {
    title: "Customize Form",
    path: "/nikah/nikah-request-form",
  },
];

const footerItems = [
  {
    title: "More Apps",
    icon: <BaseIcon icon={MoreApps} />,
    path: "/more-apps",
    child: [
    ],
    secondaryIcon: <BaseIcon icon={ChevronRight} />,
  },
  {
    title: "Support",
    icon: <BaseIcon icon={Support} />,
    path: "http://shapla.deenconnect.io/support",
    child: [],
    secondaryIcon: <BaseIcon icon={Launch} />
  },
];
const Volunteer = (props) => {
  function onClick() {
    props.history.push('/dashboard')
  }

  const returnActiveTab = () => {
    switch (props.match.path) {
      case '/deenfund/donations/details':
        return "Donations";
      default:
        return false;
    }
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname])
  const logout = () => {
    localStorage.setItem('user', false)
    localStorage.setItem('authToken', '')
    props.history.replace('/signin')
    localStorage.clear();
  }
  const userProfile = () => {
    props.history.push("/admin-profile")
  };
  const navigate = (x, y) => {
    if (x === 'Support') {
      window.location.href = (y)
    } else {
      props.history.push(y)
    }
  }
  const getInitials = () => {
    let nameArr = localStorage.getItem("name") || (props?.signup?.fullName ?? '');
    nameArr = nameArr.length > 0 ? nameArr?.trim()?.split(" ") : [];
    let initials;
    if (nameArr?.length > 1) {
      initials = nameArr[0][0] + nameArr[1][0];
    } else {
      initials = nameArr?.[0]?.[0] + nameArr[0]?.[1] ?? '';
    }
    return initials;
  }
  let image = localStorage.getItem("image");
  console.log("userImage", image)
  const content = (
    <React.Fragment>
      <MasterLayout
        header={
          <SimpleAppBar
            src={MainLogo}
            name={localStorage.getItem("name") || props.signup.fullName}
            role={localStorage.getItem("role")}
            showImg={image && image !== "undefined" ? true : false}
            img={image && image !== "undefined" ? image : ""}
            onClickLogout={() => logout()}
            onClickProfile={() => userProfile()}
            initials={getInitials()}
            style={{ marginTop: 80, marginLeft: -10 }}
          />
        }
        sideBar={
          <NavDrawer
            headerNavItems={headerItems}
            footerNavItems={footerItems}
            onBackBtnClick={() => onClick()}
            onClick={() => console.log('')}
            buttonShow={true}
            showTitle={true}
            showBodyItem={true}
            activeTab={returnActiveTab()}
            navigate={(x, y) => navigate(x, y)}
            selectedTitle={'Volunteer'}
            footerLower="footerLower"
          />
        }
        headerFixed={true}
        middleContent={
          <Switch>
            <PrivateRoute exact path="/volunteer/volunteer-request" component={VolunteerRequestListing} />
            <PrivateRoute exact path="/volunteer/volunteer-details" component={VolunteerDetails} />
            <PrivateRoute exact path="/volunteer/volunteer-edit-details" component={EditDetails} />

          </Switch>
        }
      />
    </React.Fragment>
  );
  return (
    <>
      <BasePaper elevation={0} context={content} />
    </>
  );
};
const mapStateToProps = state => {
  return {
    signup: state.signup
  }
}
export default connect(mapStateToProps, {})(Volunteer);
