import React, { useEffect } from "react";
import Moment from "moment";
import MomentTime from "moment-timezone";
import {
  BaseButton,
  BaseStepper,
  BaseStepperExtended,
  TextEditor,
  FormPublishUnpublish,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction,
  BaseTextField
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useState } from "react";
import "./style.css";
import { getReminder, getReminders, updateReminder } from "../Donations/deen-kioks-website/Apis";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
import { getRemindersWithAppId, updateRemindersWithAppId } from "./api";
const { token_expired, success_message_on_update_reminder, publish_date_before_unpublish } = messages_content;
function EditReminders(props) {
  const [activeStep, setActiveStep] = React.useState(1);
  const [Loading, setLoading] = React.useState(true);
  const [visibility, setVisibility] = React.useState("Public");
  const [publish, setPublish] = React.useState("Immediately");
  const [unPublish, setUnPublish] = React.useState("Never");
  const [publishDate, setPublishDate] = React.useState("Immediately");
  const [publishTime, setPublishTime] = React.useState("");
  const [unPublishDate, setUnPublishDate] = React.useState("Never");
  const [unPublishTime, setUnPublishTime] = React.useState("");
  const [unPublishTimestamp, setUnPublishTimestamp] = React.useState(0);
  const [Reminders, setReminders] = React.useState([]);
  const [SelectReminder, setSelectReminders] = React.useState('');
  const [publishedDateAndTime, setPublishedDateAndTime] = React.useState('');
  const [onSelectReminder, setOnSelectReminder] = React.useState([]);
  const [unPublishedDateAndTime, setUnPublishedDateAndTime] = React.useState('');
  const [validUnPublish, setValidUnPublish] = React.useState(true);
  const [validPublish, setValidPublish] = React.useState(true);
  const [UTCDate, setUTCDate] = React.useState('');
  const [UTCTime, setUTCTime] = React.useState('');
  const [UTCDateUnPublish, setUTCDateUnPublish] = React.useState('');
  const [UTCTimeUnPublish, setUTCTimeUnPublish] = React.useState('');
  const [dateError, setDateError] = React.useState(false);
  const [error, seterror] = useState(false);
  const [Desc, setDesc] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);
  
  useEffect(() => {
    GetAllReminders()
  }, [])
  async function GetAllReminders() {
    setLoading(true);
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const ReminderId = props.match.params.id;
      const resRemind = await getRemindersWithAppId(token, orgId, 'deenweb')
      // let getReminderId = await getReminder(ReminderId, token);
      const result = resRemind.data.body.data;
      const filterReminder = result.filter(text => text._id === ReminderId)
      const resultArray = filterReminder;
      setOnSelectReminder(resultArray);
      setDesc(JSON.parse(resultArray[0].reminder.description))
      setSelectReminders(resultArray[0].reminder.title)
      setPublishDate(
        `${Moment(resultArray[0].publishFrom).format("ddd")}, ${Moment(resultArray[0].publishFrom).format("MMM D, YYYY")}`
      );
      setUTCDate(Moment(resultArray[0].publishFrom).format('YYYY-MM-DD'))
      setUTCTime(Moment(resultArray[0].publishFrom).format("HH:mm:ss"));
      setPublishTime(Moment(resultArray[0].publishFrom).format("h:mm A"));
      setUnPublishTimestamp(resultArray[0].publishTo);
      const year = new Date(resultArray[0].publishTo).getFullYear()
      setUnPublishDate(year === 2099 ? "Never" :
        `${Moment(resultArray[0].publishTo).format("ddd")}, ${Moment(resultArray[0].publishTo).format("MMM D, YYYY")}`
      );
      setPublishedDateAndTime(resultArray[0].publishFrom);
      setUnPublishedDateAndTime(resultArray[0].publishTo)
      setUTCDateUnPublish(Moment(resultArray[0].publishTo).format('YYYY-MM-DD'))
      setUnPublishTime(Moment(resultArray[0].publishTo).format("h:mm A"));
      setUTCTimeUnPublish(Moment(resultArray[0].publishTo).format("HH:mm:ss"));
      let visibility = Moment(resultArray[0].publishTo) < Moment() ? 'Hidden' : resultArray[0].status;
      setVisibility(visibility);
      const response = await getReminders(orgId, token);
      const data = response.data.body.data;
      const array = data.map((text, index) => {
        return (
          {
            ...text,
            value: text.title,
            label: text.title,
          }
        )
      })
      setReminders(array);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const handleNextReminder = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    props.history.push(`/website/edit-reminder/${onSelectReminder[0]._id}`)
  };
  const handleBackReminder = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const getPublishDate = (value, boolean) => {
    if(value === "Immediately") {
      setUTCDate('');
      setPublishedDateAndTime('');
      setPublish("Immediately");
      setPublishDate("Immediately")
    } else {
      setValidPublish(false);
      setPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDate(Moment(value).format('YYYY-MM-DD'));
    }
  };
  const getPublishTime = (value, boolean) => {
    setPublishTime(Moment(value).format("h:mm A"));
    setUTCTime(Moment(value).format("HH:mm:ss"));
  };
  const getUnPublishDate = (value, boolean) => {
    if(value == "Never") {
      setUTCDateUnPublish('');
      setUnPublishedDateAndTime('')
      setUnPublish('Never');
      setUnPublishDate('Never');
    } else {
      setValidUnPublish(false);
      setUnPublishTimestamp(value);
      setUnPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDateUnPublish(Moment(value).format('YYYY-MM-DD'));
    }
  };
  const getUnPublishTime = (value, boolean) => {
    setUnPublishTime(Moment(value).format("h:mm A"));
    setUTCTimeUnPublish(Moment(value).format("HH:mm:ss"));
  };
  const onVisibilitySave = () => {
    console.log("visibility saved");
  };
  const onVisibilityCancel = () => {
    console.log("visibility cancelled");
  };
  const onPublishSave = () => {
    let Time = publishTime.split(' ');
    let dated = MomentTime(new Date(publishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = MomentTime(new Date(`${dated + 'T' + Hours}:00.00Z`)).toISOString();
    setPublishedDateAndTime(date);
  };
  const onPublishCancel = () => {
    console.log("publish date cancel");
  };
  const onUnPublishSave = () => {
    let Time = unPublishTime.split(' ');
    let dated = MomentTime(new Date(unPublishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = MomentTime(new Date(`${dated + 'T' + Hours}:00.00Z`)).toISOString();
    setUnPublishedDateAndTime(date);
  };
  const onUnPublishCancel = () => {
    console.log("unpublish date cancel");
  };
  const convertUTC = (date) => {
    return date.toISOString()
  }
  const onSave = async () => {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    const data = UTCDate === 'Immediately' ? UTCDate : UTCDate === '' ? new Date() : new Date(`${UTCDate + ' ' + UTCTime}`)
    const dataU = data === 'Immediately' ? data : convertUTC(data);
    const dataE =  UTCDateUnPublish === '' ? new Date() : new Date(`${UTCDateUnPublish + ' ' + UTCTimeUnPublish}`)
    const UnpublishTime = UTCDateUnPublish === 'Never' ? UTCDateUnPublish : convertUTC(dataE);
    const obj = {
      website: {
        status: visibility,
        publishFrom: publishDate === 'Immediately' ? publish : dataU,
        publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime
      }
    }
    const objData = {
        reminder: onSelectReminder[0].reminder._id,
        publishFrom: publishDate === 'Immediately' ? publish : dataU,  // DATE OBJECT
        publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime,
        status: visibility,
        featured: false
    }
    console.log("objData: ", objData);
    const previousUnpublish = obj.website.publishFrom !== 'Immediately' ? obj.website.publishTo > obj.website.publishFrom : true
    if (obj.website.publishTo === 'Never' || previousUnpublish) {
      try {
        await updateRemindersWithAppId(token, orgId, 'deenweb', onSelectReminder[0].reminder._id,objData )
        props.alertShow(success_message_on_update_reminder)
        props.history.push("/website/reminders");
        // const ret = await updateReminder(obj, token, props.match.params.id)
        // if (ret.status === 200) {
        // }
      } catch (err) {
        seterror(err.response && err.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    } else {
      setDateError(true)
      setTimeout(() => {
        setDateError(false)
      }, 8000)
    }
  };
  const onBack = () => {
    setActiveStep(0);
  };
  const styles = {
    formBody: {
      background: "#fff",
    },
    select: {
      margin: "14px 0",
      minWidth: 120,
      height: 66,
      width: 120,
      border: "#D3D8DA",
      width: "100%",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A",
    },
    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
    },
    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
  };
  const getStepsReminder = () => {
    return [
      { number: 1, label: "Select Reminder" },
      { number: 2, label: "Publish" },
    ];
  }
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          {
            dateError &&
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
              <AlertAction
                alert={publish_date_before_unpublish}
                alertType={"error"}
                onClick={() => setDateError(false)}
              />
            </div>

          }
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
        </>
      }
      isForm={true}
      formData={
        <div>
          {
            Loading ?
              <div
                style={{
                  marginTop: 30,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
              :
              <>
                {
                  error && error === "Invalid token" && (
                    <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                      <AlertAction
                        alert={token_expired}
                        alertType={"error"}
                        onClick={() => seterror(false)}
                      />
                    </div>
                  )
                }
                
                <div>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <BaseStepperExtended
                        title="Edit Reminder"
                        getSteps={getStepsReminder}
                        alternativeLabel={true}
                        handleBackPreviousPage={() => props.history.push('/website/reminders')}
                        handleBack={() => handleBackReminder()}
                        handleNext={() => handleNextReminder()}
                        nonLinear={true}
                        orientation={"horizontal"}
                        btnStyles={styles.stepper}
                        onStepClick = {(obj) => setActiveStep(obj - 1)}
                        activeStep={activeStep}
                      />
                    </Grid>
                  </Grid>
                  {activeStep === 0 && (
                    <Grid container spacing={3}>

                      <Grid item xs={12}>
                        <h2 className="sub_title">Reminders</h2>
                        <Typography style={styles.formTitleSub}>
                          Add “New Reminders from Home screen &gt; Manage &gt; Reminders.
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={SelectReminder}
                            InputProps={false}
                            phone={false}
                            search={false}
                            style={styles.select}
                            color="primary"
                            disabled={true}
                            helperText="Reminders are managed at home screen"
                            label="Select Reminder"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <p className="desc">Description</p>
                        <div>
                          <TextEditor
                            onChange={(e) => console.log(e)}
                            disabled={true}
                            value={Desc}
                            style={styles.editor}
                          />
                        </div>
                        <p className="desc" style={{ color: "#D3D8DA", marginTop: 4 }}>
                          Edit this description from Home screen &gt; Manage &gt; Reminders
                        </p>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="form_button">
                          <span className="btnCencel">
                            <BaseButton
                              text="CANCEL"
                              onClick={() => props.history.push("/website/reminders")}
                            />
                          </span>
                          <BaseButton text="CONTINUE" onClick={() => handleNextReminder()} />
                        </div>
                      </Grid>
                    </Grid>
                  )}
                  {activeStep === 1 && (
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <div className="field_box">
                          <FormPublishUnpublish
                            visibility={visibility}
                            setVisibility={setVisibility}
                            publish={publish}
                            unPublish={unPublish}
                            publishDate={publishDate}
                            publishTime={publishTime}
                            setPublishDate={setPublishDate}
                            setPublishTime={setPublishTime}
                            getPublishDate={getPublishDate}
                            getPublishTime={getPublishTime}
                            unPublishDate={unPublishDate}
                            unPublishTime={unPublishTime}
                            setUnPublishDate={setUnPublishDate}
                            setUnPublishTime={setUnPublishTime}
                            getUnPublishDate={getUnPublishDate}
                            getUnPublishTime={getUnPublishTime}
                            onVisibilitySave={onVisibilitySave}
                            onVisibilityCancel={onVisibilityCancel}
                            onPublishSave={onPublishSave}
                            onPublishCancel={onPublishCancel}
                            onUnPublishSave={onUnPublishSave}
                            onUnPublishCancel={onUnPublishCancel}
                            onSave={onSave}
                            onBack={onBack}
                            unPublishTimestamp={unPublishTimestamp}
                            minDateUnpublish={publishDate}
                            disabledSave={validUnPublish}
                            disabledSaveP={validPublish}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </>
          }
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
const mapStateToProps = (state) => {
  return {
    reminders: state.reminders,
  };
};
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),

});
export default connect(mapStateToProps, mapDispatchToProps)(EditReminders);
