import * as constants from "../constants";

function editSalahAction(data) {
    return {
        type: constants.EDIT_SALAH,
        payload: data
    };
}

function addSalah(data, type) {
    return {
        type: type,
        payload: data
    };
}


export { editSalahAction };
