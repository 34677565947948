import React, { Component } from 'react'
import { SimpleTable, EmptyTable, MasterLayoutMiddle, FooterInner, AlertAction, AlertDialogue } from "@dclab/dc-react-ui-kit";
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import Handle from "../../assets/img/more_vert-24px.svg";
import Moment from "moment";
import '../../App.css'
import "./style.css";
import { getAllSalah, DeletesalahWebsite } from '../Donations/deen-kioks-website/Apis';
import { CircularProgress } from '@material-ui/core';
import { sortPromotionalSiderWebsite } from './api';
import { connect } from 'react-redux';
import { hideAlert, showAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
import { editOrganization, getOrganizationData } from '../Organization/api';
import {getPromotionalSliderWithAppId, deletePromotionalSliderWithAppId, DeletePromotionalSliderWebsite} from './api';
//import image from "../../../../src/assets/img/insert_photo.png";
import image from "../../assets/img/insert_photo.png"

const { token_expired, remove_promotional_slider_message } = messages_content;
class PromotionalSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Loading: true,
      error: false,
      openAlert: false,
      specificRow: [],
      data: [],
      status: '',
      promotionalSlider: [],
      promotionalSliderData:[],
      orgIntegrateData: {}
    }
  }
  async componentDidMount() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const res = await getPromotionalSliderWithAppId(token, orgId, 'deenweb');
      const SliderData = res.data.body.data
      const resOrg = await getOrganizationData(orgId);
      const orgIntegate = resOrg.data?.body?.data?.enabledIntegrations;
      let sort = SliderData.filter((el) => el?.promotionalSlider?._id).sort((a, b) => {
        return a.promotionalSlider.order - b.promotionalSlider.order
      })
      
      let data = [];
      this.setState({promotionalSliderData: sort, data: SliderData, promotionalSlider: SliderData, orgIntegrateData: orgIntegate })
      let defaultSrc;
      sort.filter((el) => el?.promotionalSlider?._id).map((text, i) => {
        // const timeZoneArray = text.salah.settings.salahMethod.timezone.split(' ');
        // const timeZone = timeZoneArray[timeZoneArray.length - 1]
        let re = /^(http|https|ftp)/
        if (text?.promotionalSlider?.imageMetaData?.imageUrl && re.test(text?.promotionalSlider?.imageMetaData?.imageUrl)) {
          defaultSrc = "src";
        } else {
          defaultSrc = "defaultImg";
        }
        const dateStart = [Moment(text?.publishFrom).format("MMM DD, YYYY"), Moment(text?.publishFrom).format("h:mm A") ];
        const dateEnd = [Moment(text?.publishTo).format("MMM DD, YYYY"), Moment(text?.publishTo).format("h:mm A")  ];
        const UnpublishDate = new Date(dateEnd).getFullYear()
        const status = text?.status
        this.setState({ status: status })
        const statusChangeStart = new Date(dateStart) <= new Date();
        const statusChangeEnd = new Date(dateEnd) <= new Date();
        let array = [];

        array = [
          {
            type: 'id',
            _id: text?._id,
            sliderId: text?.promotionalSlider?._id ?? null,
          },
          {
            type: "url",
            name: "userPic",
            [defaultSrc]:
              text?.promotionalSlider?.imageMetaData?.imageUrl && re.test(text?.promotionalSlider?.imageMetaData.imageUrl) ? text?.promotionalSlider?.imageMetaData.imageUrl : image,
          },

          //{ type: 'url', name: 'single_pic', initials: 'HK', value: imageMetaData },
          
          {
            type: 'string',
            name: "name",
            value: text?.promotionalSlider?.title ?? ''
          },
          {
            type: 'string',
            name: dateStart === "Immediately" ? 'address' : "vertical",
            value: dateStart
          },
          {
            type: 'string',
            name: UnpublishDate === 2099 ? "frequency" : "vertical",
            value: UnpublishDate === 2099 ? "Never" : dateEnd
          },
          {
            type: "status",
            status: status === 'Hidden' ? 'pending' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'success' : status === 'Publish' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'pending' : 'schedule'
              : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',
            value: status === 'Hidden' ? 'Unpublished' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'Published' : status === 'Publish' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Unpublished' : 'Scheduled'
              : statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled',
          },
        ]
        data.push(array)
      }
      )
      this.setState({ promotionalSlider: data, Loading: false });
    } catch (err) {
      this.setState({ Loading: false });
      this.setState({ error: err.response && err.response.data.message })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  }

  editPromotionalSlider = (e) => {
    this.props.history.push(`/website/edit-promotional-slider/${e[0]._id}`)
  }
  deleteServiceAction = async (e) => {
    if(e?.[0]?.sliderId) {
      this.setState({
        openAlert: false,
        Loading: true
      });
      const token = localStorage.getItem("authToken");
      const orgId = localStorage.getItem('orgID');
      try {
        const res = await deletePromotionalSliderWithAppId(token, orgId, 'deenweb', e[0].sliderId)
        await DeletePromotionalSliderWebsite(token, e[0]._id)
        this.props.alertShow(remove_promotional_slider_message)
        const stateNews = this.state.promotionalSlider;
        const NewsIndex =  stateNews.indexOf(e)
        stateNews.splice(NewsIndex, 1)
        this.setState({
          promotionalSlider: stateNews,
          Loading: false
        });
      } catch (error) {
        this.setState({
          Loading: false,
          error: error.response && error.response.data.message
        });
        setTimeout(() => {
          this.setState({
            error: false
          });
        }, 8000);
      }
    }
  }
  handleCancel = () => {
    this.setState({ openAlert: false });
  }
  handleClose = () => {
    this.setState({ openAlert: false });
  };
  render() {
    const { promotionalSlider, Loading, error, openAlert, specificRow } = this.state;
    if (this.props.alert.alertValue) {
      setTimeout(() => {
        this.props.alertHide()
      }, 8000);
    }
    let classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    let col = [
      { id: "drag", label: " ", width: 'auto' },
      { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
      { type: "url", name: "single_pic", label: "IMAGE", width: 'auto' },
      //{ type: "link", name: "salahName", label: "Image", width: 'auto' },
      { type: "string", name: "date", label: "Title", width: '60%' },

      { type: "string", name: "date", label: "Publish Date", width: '20%' },
      { type: "string", name: "date/time", label: "UnPublish Date", width: '20%' },
      { type: "string", name: "status", label: "STATUS", width: '20%' },
      { type: "action", name: "action", label: "ACTION", width: '20%' },
    ];
    const style = {
      width: '100%',
    };
    const styleAlert = {
      width: 470,
      marginLeft: 800
    };
    const sortData = async (data) => {
      const sortData = data.sort((a, b) => a.order - b.order);
      const token = localStorage.getItem("authToken");
      let OBJ = {
        sortedList: sortData.map(text => {
          return (
            { _id: text?.promotionalSlider?._id, order: text.order }
          )
        })
      };
      try {
        await sortPromotionalSiderWebsite(token, OBJ)
      } catch (err) {
        this.setState({ Loading: false });
        this.setState({ error: err.response && err.response.data.message })
        setTimeout(() => {
          this.setState({ error: false })
        }, 8000);
      }
      try {
        let data = [];
        let defaultSrc;
        sortData.filter((el) => el?.promotionalSlider?._id).map((text, i) => {
          // const timeZoneArray = text.salah.settings.salahMethod.timezone.split(' ');
          // const timeZone = timeZoneArray[timeZoneArray.length - 1]
          let re = /^(http|https|ftp)/
          if (text?.promotionalSlider?.imageMetaData?.imageUrl && re.test(text?.promotionalSlider?.imageMetaData?.imageUrl)) {
            defaultSrc = "src";
          } else {
            defaultSrc = "defaultImg";
          }
          const dateStart = [Moment(text?.publishFrom).format("MMM DD, YYYY"), Moment(text?.publishFrom).format("h:mm A") ];
          const dateEnd = [Moment(text?.publishTo).format("MMM DD, YYYY"), Moment(text?.publishTo).format("h:mm A")  ];
          const UnpublishDate = new Date(dateEnd).getFullYear()
          const status = text?.status
          this.setState({ status: status })
          const statusChangeStart = new Date(dateStart) <= new Date();
          const statusChangeEnd = new Date(dateEnd) <= new Date();
          let array = [];

          array = [
            {
              type: 'id',
              _id: text?._id,
              sliderId: text?.promotionalSlider?._id ?? null,
            },
            {
              type: "url",
              name: "userPic",
              [defaultSrc]:
                text?.promotionalSlider?.imageMetaData?.imageUrl && re.test(text?.promotionalSlider?.imageMetaData.imageUrl) ? text?.promotionalSlider?.imageMetaData.imageUrl : image,
            },

          //{ type: 'url', name: 'single_pic', initials: 'HK', value: imageMetaData },
          
            {
              type: 'string',
              name: "name",
              value: text?.promotionalSlider?.title ?? ''
            },
            {
              type: 'string',
              name: dateStart === "Immediately" ? 'address' : "vertical",
              value: dateStart
            },
            {
              type: 'string',
              name: UnpublishDate === 2099 ? "frequency" : "vertical",
              value: UnpublishDate === 2099 ? "Never" : dateEnd
            },
            {
              type: "status",
              status: status === 'Hidden' ? 'pending' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'success' : status === 'Publish' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'pending' : 'schedule'
                : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',
              value: status === 'Hidden' ? 'Unpublished' : dateStart === 'Immediately' && dateEnd === 'Never' ? 'Published' : status === 'Publish' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Unpublished' : 'Scheduled'
                : statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled',
            },
          ]
          data.push(array)
        }
        )
        this.setState({ promotionalSlider: data, Loading: false });
      } catch (err) {
        this.setState({ Loading: false });
        this.setState({ error: err.response && err.response.data.message })
        setTimeout(() => {
          this.setState({ error: false })
        }, 8000);
    }
    }
    return (
      <MasterLayoutMiddle
        content={
          Loading ?
            <div
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </div>
            :
            <>
              {
                this.props.alert.alertValue &&
                <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                  <AlertAction
                    alert={this.props.alert.alertValue}
                    alertType={"success"}
                    onClick={() => this.props.alertHide()}
                  />
                </div>
              }
              {
                error && error === "Invalid token" && (
                  <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                    <AlertAction
                      alert={token_expired}
                      alertType={"error"}
                      onClick={() => this.setState({ error: false })}
                    />
                  </div>
                )
              }
              {console.log("promotionalSliderData: ", this.state.promotionalSliderData)}
              {
                promotionalSlider.length > 0 ?
                  <div>
                    <SimpleTable
                      showHeader={true}
                      title="Promotional Slider"
                      parentTableDrag={true}
                      DragIcon={Handle}
                      width="98%"
                      rows={promotionalSlider}
                      columns={col}
                      colSpan={16}
                      actionSendButton={true}
                      classProps={changeClassProps()}
                      donationsCount={promotionalSlider?.length > 0 ? 1 : 0}
                      noTitle="No Promotional Slider added"
                      noPara="Added Promotional Slider will be displayed and managed here"
                      noBtnText="Add New Promotional Slider"
                      onInviteClick={() =>
                        this.props.history.push(
                          "/website/add-promotional-slider"
                        )
                      }
                      showIconCol={true}
                      editIcon={Edit}
                      deleteIcon={Delete}
                      editAction={(e) =>
                        this.props.history.push({
                          pathname: `/website/edit-promotional-slider/${e[0]._id}`,
                          state: { detail: this.state.promotionalSliderData.filter(text => text._id === e[0]._id) }
                        })
                      }
                      deleteAction={(e) => this.setState({ openAlert: true, specificRow: e })}
                      showFooter={true}
                      showAdd={true}
                      addText="add new"
                      onAddClick={() =>
                        this.props.history.push(
                          "/website/add-promotional-slider"
                        )
                      }

                      iconTextSize={16}
                      rowsPerPage={5}
                      sortData={(e) => sortData(e)}
                      data={this.state.data}
                      sortColumnIndex={[4]}
                      

                    />
                    <div style={{ display: "flex", marginTop: 13 }}>
                      <p className="cat-lower" style={{ marginTop: 0 }}>
                      Change the order of Slider to display on the website. Click on{" "}
                      </p>
                      <img src={Handle} alt="" style={{ display: "flex", marginTop: -10 }} />
                      <p className="cat-lower" style={{ marginTop: 0 }}>to drag and drop within the table.</p>
                    </div>
                  </div>
                  :
                  <EmptyTable 
                  title="Promotional Slider"
                  noTitle="No promotional sliders added"
                  noPara="Added promotional sliders will be displayed and managed here"   
                  onAddClick={() => this.props.history.push("/website/add-promotional-slider")}
                  addLabel="ADD NEW"
                  noBtnText="ADD NEW"
                  />
              }
              <div>
                <AlertDialogue
                  title="Delete Slider?"
                  description="Are you sure you want to delete this Slider?"
                  description2 = "Deleting will permanently remove from Website."
                  cancelText="Cancel"
                  confirmText="Confirm"
                  open={openAlert}
                  handleCancel={() => this.handleCancel()}
                  handleClose={() => this.handleClose()}
                  handleConfirm={() => this.deleteServiceAction(this.state.specificRow)}
                  styles={styleAlert}
                />
              </div>
            </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
      />
    )
  }
}
const mapStateToProps = (state) => ({
  alert: state.alert,
});
const mapDispatchToProps = (dispatch) => ({
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PromotionalSlider);
const styles = {
  contents: {
    padding: 24,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: "76%",
    marginTop: 70,
    marginLeft: "20%",
  },
}