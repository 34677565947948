import React, {useState} from "react";
import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Grid from '@material-ui/core/Grid';
import { BaseTextField, MasterLayoutMiddle, FooterInner, BaseImgChip,TextEditor,BaseButton } from "@dclab/dc-react-ui-kit";
import './style.css'



import { } from "@dclab/dc-react-ui-kit";

let chipData = [
  {
    key: 0,
    label: "Image Chip",
    src:
      "https://audioengineusa.com/wp-content/uploads/2019/01/a2.jpg",
    alt: "",
  },

];

const style = {
  width: '100%',
};

const onInputClick = () => {
  console.log("input clicked");
};

const onChipInputClick = () => {
  console.log("input chip clicked");
};

const deleteChip = () => {
  console.log('chip deleted')
}




function AddTestimonial(props) {

  const [value, setValue] = useState([{type:'paragraph',children:[{text:"Lorem Ipsum is simply dummy text of the printing and typesetting industry."}]}]);

  const onChange = (e) => {
      setValue(e)
  };
  
  const styles = {
      border:'1px solid black',
      color: 'gray'
  };

  return (

    <MasterLayoutMiddle
      header={
        <div className="backBtn">
          <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
          <Link
            to={{ pathname: "/admin-profile", state: 1, }}
            style={{ alignSelf: "center", color: "black", textDecoration: "none", }} > Back </Link>{" "}
        </div>
      }

      isForm={true}
      formData={
        <div>
          <Grid container spacing={3}>

            <Grid item md={12}>
              <h1 className='title_top'>Add Testimonial</h1>
              <div className="dasheddBorder"></div>
            </Grid>

            <Grid item xs={12}>
              <div className="field_box">
                <BaseTextField
                  label={"Name"}
                  color="primary"
                  disabled={false}
                 // helperText="Helper text"
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="field_box">
                <BaseTextField
                  label={"Designation"}
                  color="primary"
                  disabled={false}
                  //helperText="Helper text"
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="field_box">
                <BaseImgChip
                  chipData={chipData}
                  onInputClick={onInputClick}
                  onChipInputClick={onChipInputClick}
                  deleteChip={deleteChip}
                  helperText=''
                  label="Image"
                // textStyle={styles}
                // arrowClick={arrowClick}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <TextEditor
                onChange={(e) => onChange(e)}
                disabled={false}
                value={value}
                style={styles}
                label={"Description"}
               // helperText={"Helper Text"}
                maxCharCount={200}
              />
            </Grid>

            <Grid item xs={12}>
              <div className="field_box">
                <BaseTextField
                  label={"Add Video (Optional)"}
                  color="primary"
                  disabled={false}
                 // helperText="Helper text"
                  InputProps={true}
                />
              </div>
            </Grid>

            <Grid item md={12} className="buttons">
                  <span className="btnCencel" onClick={() => props.history.push("/admin-profile")}><BaseButton text="CANCEL" /></span>
                  <BaseButton text="Add now"  />
                </Grid>

          </Grid>
        </div>
      }



      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}

    />
  );
}
export default AddTestimonial;