import React, { useRef } from "react";
import {
  BaseTextField,
  PageHeaderWithTabs,
  FormSwitch,
  BaseCheckBox,
  SimpleTable,
  MasterLayoutMiddle,
  FooterInner,
  AlertDialogue,
  AlertAction,
  EmptyTable,
  AlertDialogueAdvance
} from "@dclab/dc-react-ui-kit";
import Moment from "moment";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useEffect, useState } from "react";
import { DeleteVideosWebsite, deleteVideosWithAppId, GetVideosWebsite, getVideosWithAppId } from './api';
import Edit from "../../assets/img/create-24px.svg";
import Delete from "../../assets/img/delete_forever-24px.svg";
import "./style.css";
import { getOrganizationData } from "../Organization/api";
import { saveAsDraft } from "../../components/DonationForm/Apis";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { PagesAction } from "../../redux/actions/pages";
import { hideAlert, showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
const {
  token_expired,
  Your_changes_has_been_published,
  Your_changes_has_been_saved_as_draft,
  quick_access_limit_axeeded,
  remove_video_message
} = messages_content;
function Videos(props) {
  const history = props.history;
  const [Title, setTitle] = React.useState('')
  const [value, setValue] = React.useState(0);
  const [videoData, setVodeoData] = React.useState([]);
  const [videosList, setVideosList] = React.useState([]);
  const [specificRow, setSpecificRow] = React.useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [success, setsuccess] = useState(false);
  const [error, seterror] = useState(false);
  const [getNewData, setNewData] = useState(false);
  const [ErrorFound, setErrorFound] = useState(false)
  const unblockHandle = useRef();
  const [changes, setCheckChanges] = useState(false);
  const [saved_route, setRoute] = useState('');
  const [checked, setChecked] = useState(true);
  const [open, setAlertModal] = useState(false);
  const [organizationData, setOrganizationData] = useState([])
  const [pagesAll, setPagesAll] = useState({})
  const [QuickLimit, setQuickLimit] = useState(false);
  const [homepageButton, setHomePageButton] = useState(false);
  const [integrateData, setIntegrateData] = useState({})
  const [dischard_changes, setdischard_changes] = useState(0);
  const [Loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      props.alertHide()
    }, 8000)
  }, [props.alert.alertValue])
  useEffect(() => {
    unblockHandle.current = history.block((route) => {
      setRoute(route.pathname)
      changes && visibleModal()
      setCheckChanges(false)
      return changes === true ? false : true
    });
    return () => {
      unblockHandle.current = false
      !window.location.pathname.includes('video') && localStorage.removeItem('videos')
    }
  }, [changes])
  function visibleModal() {
    setAlertModal(true)
  }
  useEffect(() => {
    const page_no = localStorage.getItem('videos')
    const token = localStorage.getItem('authToken')
    const orgId = localStorage.getItem('orgID')
    setValue(page_no ? +page_no : 0);
    async function getorganization() {
      try {
        const getOraganization = await getOrganizationData(orgId, token);
        const orgIntegrateData = getOraganization?.data?.body?.data?.enabledIntegrations;
        const data = orgIntegrateData?.deenweb?.pages;
        const oraganization_data = data?.videos
        setIntegrateData(orgIntegrateData);
        setOrganizationData(oraganization_data);
        setPagesAll(data);
        setTitle(oraganization_data.title);
        setHomePageButton(oraganization_data.enablePage)
        setChecked(oraganization_data.quickAccess)
        setNewData(false)
      }
      catch (error) {
        seterror(error.response && error.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    }
    getorganization()
  }, [getNewData])
  const handleChange = (event, newValue) => {
    changes && (Title === '' ? setErrorFound(true) : setAlertModal(true))
    !changes && setValue(newValue);
    !changes && localStorage.setItem('videos', newValue)
    setdischard_changes(newValue)
  };

  function get_youtube_thumbnail(url, quality) {
    if (url) {
      var video_id, thumbnail, result;
      if (result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)) {
        video_id = result.pop();
      }
      else if (result = url.match(/youtu.be\/(.{11})/)) {
        video_id = result.pop();
      }

      if (video_id) {
        if (typeof quality == "undefined") {
          quality = 'high';
        }

        var quality_key = 'maxresdefault'; // Max quality
        if (quality == 'low') {
          quality_key = 'sddefault';
        } else if (quality == 'medium') {
          quality_key = 'mqdefault';
        } else if (quality == 'high') {
          quality_key = 'hqdefault';
        }

        var thumbnail = "http://img.youtube.com/vi/" + video_id + "/" + quality_key + ".jpg";
        return thumbnail;
      }
    }
    return false;
  }
  useEffect(() => {
    getAllVideosWebsite();
  }, []);
  async function getAllVideosWebsite() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID')

    try {
      const ResVideos = await getVideosWithAppId(token, orgId, 'deenweb');
      // const response = await GetVideosWebsite(token);
      const data = ResVideos.data.body.data
      const sort = data.sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt)
      })
      setVodeoData(sort);
      let dataArray = [];
      sort.map((text, index) => {
        const dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("h:mm A") + ' (EST)'];
        const dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("h:mm A") + ' (EST)'];
        const UnpublishDate = new Date(dateEnd).getFullYear();
        const status = text.status
        const statusChangeStart = new Date(dateStart) <= new Date();
        const statusChangeEnd = new Date(dateEnd) <= new Date();
        let array = []
        array = [
          { type: "id", value: `${index <= 8 ? '0' + (index + 1) : index + 1}`, _id: text._id, videoId: text.video._id },
          {
            name: "textImg",
            type: "string",
            primaryText: text.video.title,
            src: get_youtube_thumbnail(text.video.link, 'max'),
          },
          {
            type: "string",
            name: "vertical",
            value: dateStart,
          },
          {
            type: "string",
            name: UnpublishDate === 2099 ? "frequency" : "vertical",
            value: UnpublishDate === 2099 ? "Never" : dateEnd
          },
          {
            type: "status",
            status: status === 'Hidden' ? statusChangeEnd ? 'pending' : statusChangeStart ? 'pending' : 'schedule'
              : statusChangeEnd ? 'pending' : statusChangeStart ? 'success' : 'schedule',
            value: status === 'Hidden' ? statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Unpublished' : 'Scheduled'
              : statusChangeEnd ? 'Unpublished' : statusChangeStart ? 'Published' : 'Scheduled'
          },
        ]
        dataArray.push(array);
      })
      setVideosList(dataArray);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      seterror(error.response && error.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const checkChanges = (boolean) => {
    setCheckChanges(boolean)
  }
  const saveChanges = async () => {
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    if (Title === '') {
      setErrorFound(true)
    } else {
      // const data = {
      //   "addSelectedArrayData": {
      //       "enabledIntegrations.deenweb.pages.videos": {
      //         title: Title,
      //         status: homepageButton ? 'active' : 'inactive',
      //         enablePage: homepageButton,
      //         quickAccess: checked,
      //         lastEdited: new Date(),
      //       }
      //     },
      //   }
      const data = {
        enabledIntegrations: {
          ...integrateData,
          deenweb: {
            ...integrateData.deenweb,
            pages: {
              ...pagesAll,
              videos: {
                title: Title,
                status: homepageButton ? 'active' : 'inactive',
                enablePage: homepageButton,
                quickAccess: checked,
                lastEdited: new Date(),
                pageName: 'About Us',
              }
            }
          }
        }
      }
      try {
        await saveAsDraft(orgId, data, token);
        setCheckChanges(false)
        setshowAlert(true);
        setsuccess(true)
        setValue(dischard_changes);
        setAlertModal(false)
        if (saved_route) {
          props.history.push(saved_route)
        }
      } catch (err) {
        setAlertModal(false)
        seterror(err.response && err.response.data.message)
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    }
  };
  const publish = async () => {
    const pages = props?.pages?.pages
    const lengthOfPages = pages.filter(text => text.quickAccess === true);
    const orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    if (Title === '') {
      setErrorFound(true)
    } else {
      const data = {
        enabledIntegrations: {
          ...integrateData,
          deenweb: {
            ...integrateData.deenweb,
            pages: {
              ...pagesAll,
              videos: {
                title: Title,
                status: homepageButton ? 'active' : 'inactive',
                enablePage: homepageButton,
                quickAccess: checked,
                lastEdited: new Date(),
                pageName: 'About Us',
              }
            }
          }
        }
      }
      if (!checked || organizationData.quickAccess || lengthOfPages.length < 6) {
        try {
          await saveAsDraft(orgId, data, token);
          setshowAlert(true);
          setsuccess('Publish');
          setCheckChanges(false)
          let Quick = pages.filter(text => text.name[0] !== "videos")
          Quick.push({
            name: ['videos'],
            quickAccess: checked
          })
          props.dispatchPagesAction(Quick)
        } catch (err) {
          seterror(err.response && err.response.data.message)
          setTimeout(() => {
            seterror(false)
          }, 8000);
        }
      } else {
        setQuickLimit(true)
        setTimeout(() => {
          setQuickLimit(false)
        }, 8000)
      }
    }
  };
  const object = [
    {
      label: "Contents",
      item: "Contents",
    },
    {
      label: "Page setup",
      item: "Page setup",
    },
  ];
  const handleCloseDraft = () => {
    setAlertModal(false)
  };
  const discardChanges = () => {
    setCheckChanges(false)
    setAlertModal(false)
    setValue(dischard_changes)
    setChecked(organizationData.quickAccess);
    setHomePageButton(organizationData.enablePage)
    setTitle(organizationData.title)
    if (unblockHandle) {
      unblockHandle.current()
    }
    if (saved_route) {
      props.history.push(saved_route)
    }
  };
  const saveStyles = {
    backgroundColor: "#2A82F6",
  };
  const publishStyles = {
    backgroundColor: "#2A82F6",
    opacity: 0.6,
  };
  const paperStyles = {};
  const styles = {
    BaseTextField: {
      width: "100%",
    },
    contents: {
      paddingTop: 20,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "100%",
      marginTop: 70,
      marginLeft: "14%",
    },
  };
  const DeleteAlertFunc = (e) => {
    setSpecificRow(e);
    setOpenAlert(true);
  }
  const handleCancel = () => {
    setOpenAlert(false);
  }
  const handleClose = () => {
    setOpenAlert(false);
  };
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  const deleteServiceAction = async (e) => {
    setOpenAlert(false);
    setLoading(true)
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    try {
      await deleteVideosWithAppId(token, orgId, 'deenweb', e[0].videoId)
      await DeleteVideosWebsite(token, e[0].videoId)
      props.alertShow(remove_video_message)
      const stateNews = videosList
      const NewsIndex = videosList.indexOf(e)
      stateNews.splice(NewsIndex, 1)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      seterror(error.response && error.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  let col = [
    { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
    { type: "string", name: "name", label: "Video title ", width: '60%' },
    { type: "string", name: "date/time", label: "Publish Date", width: '15%' },
    {
      type: "string",
      name: "date/time",
      label: "UnPublish Date",
      width: '15%',
    },
    { type: "string", name: "date", label: "STATUS", width: '15%' },
    { type: "action", name: "action", label: "ACTION", width: '10%' },
  ];
  const style = {
    width: 470,
    marginLeft: 800
  };
  const Alertstyle = {
    width: 470,
    marginLeft: '35%'
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          <PageHeaderWithTabs
            back={() => props.history.push('/website/pages')}
            value={value}
            title="Videos"
            object={object}
            disabled={changes ? false : true}
            indicatorColor="primary"
            style={{ color: "#2A82F6", border: "1px solid black" }}
            handleChange={(x, y) => handleChange(x, y)}
            indicatorColor="#2A82F6"
            saveStyles={saveStyles}
            publishStyles={publishStyles}
            paperStyles={paperStyles}
            hiddenDraft={true}
            saveChanges={() => saveChanges()}
            publish={() => publish()}
          />
          {
            showAlert && success && success === 'Publish' ? (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={Your_changes_has_been_published}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            ) : showAlert && success && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={Your_changes_has_been_saved_as_draft}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            )
          }
          {
            props.alert.alertValue &&
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
              <AlertAction
                alert={props.alert.alertValue}
                alertType={"success"}
                onClick={() => props.alertHide()}
              />
            </div>
          }
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            QuickLimit && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={quick_access_limit_axeeded}
                  alertType={"error"}
                  onClick={() => setQuickLimit(false)}
                />
              </div>
            )
          }
        </>
      }
      content={
        Loading ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <>
            <div>
              <div>
                <AlertDialogueAdvance
                  title="You have unsaved changes!"
                  description='There are unsaved changes. If you would like to save changes, press the "SAVE AS DRAFT" button.'
                  cancelText="Discard Changes"
                  confirmText="SAVE AS DRAFT"
                  open={open}
                  handleCancel={() => saveChanges()}
                  handleClose={() => handleCloseDraft()}
                  handleConfirm={() => discardChanges()}
                  styles={Alertstyle}
                />
              </div>
              {value === 1 && (
                <div className="page_body">
                  <Typography className="page_form_title">Page Settings</Typography>
                  <Grid className="form_body">
                    <Grid container item xs={6} spacing={3} >
                      <Grid item xs={12}>
                        <div className="field_box">
                          <BaseTextField
                            value={Title}
                            onChange={(e) => {
                              setTitle(e.target.value); checkChanges(true); setErrorFound(false)
                            }}
                            InputProps={false}
                            phone={false}
                            search={false}
                            error={ErrorFound}
                            count={20}
                            style={styles.BaseTextField}
                            color="primary"
                            disabled={false}
                            // helperText="Helper text"
                            label="Page Title"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="page_switch">
                          <FormSwitch
                            style={styles.BaseTextField}
                            headline="Enable Page"
                            body="Disabled pages can’t be accessed by your site visitors."
                            values={homepageButton}
                            handleSwitchChange={() => { setHomePageButton(!homepageButton); checkChanges(true) }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div className="SolidBorder"></div>
                        <div className="checkBox">
                          <BaseCheckBox
                            label="Quick Access"
                            checked={checked}
                            color="secondary"
                            showHelperText={true}
                            helperText="Add to left navigation under QUICK ACCESS for quickly accessing the page"
                            onChange={(e) => { setChecked(e.target.checked); checkChanges(true) }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              )}
              {value === 0 && (
                <div>
                  {videosList.length > 0 ?
                    <SimpleTable
                      showHeader={true}
                      title="Videos"
                      rows={videosList}
                      columns={col}
                      colSpan={16}
                      actionSendButton={true}
                      classProps={changeClassProps()}
                      donationsCount={videosList?.length > 0 ? 1 : 0}
                      noTitle="No Videos added"
                      noPara="Added Videos will be displayed and managed here"
                      noBtnText="Add New Videos"
                      onInviteClick={() =>
                        props.history.push("/website/add-video")
                      }
                      actionLink={(name) => this.details(name)}
                      showIconCol={true}
                      editIcon={Edit}
                      deleteIcon={Delete}
                      editAction={(e) =>
                        props.history.push({
                          pathname: `/website/edit-video/${e[0]._id}`,
                          state: { detail: videoData.filter(text => text._id === e[0]._id) }
                        })
                      }
                      deleteAction={(e) => DeleteAlertFunc(e)}
                      showFooter={true}
                      showAdd={true}
                      addText="ADD NEW"
                      onAddClick={() => props.history.push("/website/add-video")}
                      iconTextSize={16}
                      rowsPerPage={5}
                      sortColumnIndex={[2]}
                    />
                    :
                    <EmptyTable
                      title="Videos"
                      onAddClick={() => props.history.push("/website/add-video")}
                      addLabel="ADD NEW"
                      noBtnText="ADD NEW"
                    />
                  }
                </div>
              )}
              <div>
                <AlertDialogue
                  title="Delete Video?"
                  description="Are you sure you want to delete this Video?"
                  description2="Deleting will permanently remove from Website."
                  cancelText="Cancel"
                  confirmText="Confirm"
                  open={openAlert}
                  handleCancel={() => handleCancel()}
                  handleClose={() => handleClose()}
                  handleConfirm={() => deleteServiceAction(specificRow)}
                  styles={style}
                />
              </div>
            </div>
          </>
      }
      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
    />
  );
}
const mapStateToProps = (state) => {
  return {
    pages: state.pages,
    alert: state.alert,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatchPagesAction: (data) => dispatch(PagesAction(data)),
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Videos);
