import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  TabDrawer,
} from "@dclab/dc-react-ui-kit";
import LayoutTab from './SideDrawersTabs/LayoutTab'
import SettingsTab from './SideDrawersTabs/SettingsTab'

function SideDrawer(props) {
  const {editHomeScreen, seteditHomeScreen, onLayoutZoneClick} = props;


  const TabDrawerStyle = {
    minHeight: "100%",
    top: "193px",
    maxWidth:"320px",
  };

  return (
    <div>
      <TabDrawer
        labelOne="layOut"
        labelTwo="SETTINGS"
        TabDrawerStyle={TabDrawerStyle}
        TabOneContent={
          <LayoutTab 
             editHomeScreen={editHomeScreen}
             seteditHomeScreen={seteditHomeScreen}
             onLayoutZoneClick={onLayoutZoneClick}
             setchanges={props.setchanges}
             selectedZone={props.selectedZone}
          />}
        TabTwoContent={
          <SettingsTab 
             editHomeScreen={editHomeScreen}
             seteditHomeScreen={seteditHomeScreen}
             setchanges={props.setchanges}
        />}
      />
    </div>
  );
}
export default SideDrawer;


const useStyles = makeStyles((theme) => ({

  DashLine: {
    border: " 1px dashed #D3D8DA",
  },

  text: {
    letterSpacing: " 0.25px",
    color: '#818E94',
    fontSize: "14px",
    lineHeight: "19px",
  },

  BorderLine: {
    borderBottom: " 1px solid #E4E8EC",
  }

}));