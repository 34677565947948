import React, { useState, useEffect } from 'react';
import {
    DrawerMain, MediaUploader, BaseTextField,
    BaseImgChip, DateRange, BaseSelect, DrawerListCheckbox,
} from "@dclab/dc-react-ui-kit";

import Grid from '@material-ui/core/Grid';
import replyIcon from '../../assets/img/replyIcon.svg';
import FileIcon from '../../assets/img/filterIcon.svg';





function CourseOverview(props) {

    const style = {
        width: '100%',
    };

    const [open, setOpen] = React.useState(false);
    const [values, setValues] = React.useState({ age: "", });
    const closeDrawer = () => { setOpen(!open); };
    const onCancel = () => { setOpen(false); };

    const onSave = () => {
        console.log("save");
    };
    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    let chipData = [
        {
            key: 0,
            label: "Image Chip",
            src:
                "https://audioengineusa.com/wp-content/uploads/2019/01/a2.jpg",
            alt: "",
        },

    ];

    const onInputClick = () => {
        console.log("input clicked");
    };

    const onChipInputClick = () => {
        console.log("input chip clicked");
    };

    const deleteChip = () => {
        console.log('chip deleted')
    }


    const [value, setValue] = useState([{ type: 'paragraph', children: [{ text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." }] }]);

    const onChange = (e) => {
        setValue(e)
    };

    const styles = {
        border: '1px solid black',
        color: 'gray'
    };

    const [checked, setChecked] = useState(true);

    useEffect(() => {
        console.log(`Your ${checked} function here`);
    }, [checked]);


    const [valuesCategory, setValuesCategory] = React.useState({
        age: "",
    });

    const handleChangeCategory = (prop) => (event) => {
        setValuesCategory({ ...values, [prop]: event.target.value });
    };

    const category = [
        {
            value: "10",
            label: "Ten",
        },
        {
            value: "20",
            label: "Twenty",
        },
        {
            value: "30",
            label: "Thirty",
        },
    ];



    const [mediaList, setMediaList] = React.useState([]);

    const mediaObject = [{
        imageMetaData: {
            imageUrl: "https://i.ytimg.com/vi/6WE3nJiECRw/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAckAfnE7HtfnjxhAH-8sEdXdMgLQ",
            imageName: "Thumbnail",
            imageThumbnail: "https://i.ytimg.com/vi/6WE3nJiECRw/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAckAfnE7HtfnjxhAH-8sEdXdMgLQ",
            fileType: "Video",
            imageSize: 0
        },
        metaData: {
            videoUrl: "https://www.youtube.com/watch?v=6WE3nJiECRw",
            duration: "7m 16s "
        },
        _id: "610d393141e4dd5be3026723",
        isVideo: true,
        title: "Kun Faya Kun - A.R. Rahman, Mohit Chauhan [Drifting Lights x WORMONO Lofi Remake] | Bollywood Lofi",
        mediaURL: "https://i.ytimg.com/vi/6WE3nJiECRw/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAckAfnE7HtfnjxhAH-8sEdXdMgLQ",
        createdAt: "2021-08-06T13:29:21.978Z",
        orgId: "600aa7cd4350100e18c7370e",
        updatedAt: "2021-08-06T13:29:21.978Z"
    }]

    const getMediaItems = () => {

        setMediaList(mediaObject);

    }

    const selectMediaItem = (obj) => {
        console.log("Obj is: ", obj);

    }

    const addMediaItem = (obj) => {
        console.log("Media data is: ", obj);


    }

    const imageData = (obj) => {
        const tempMediaArray = mediaList;
        tempMediaArray.push(obj);
        setMediaList(tempMediaArray);

    }

    const videoData = (obj) => {
        console.log("Video data is: ", obj);
    }

    const onDelete = () => {
        console.log("Ondelete Called")
    }








    const [isOpen, setOpenDate] = React.useState(false);
    const [states, setstates] = React.useState(false);
    const [temp, settemp] = React.useState('')
    const [valueDate, setvalue] = React.useState('No specific date')

    const onSelect = (valueDate, states) => {
        settemp(valueDate);
        setstates(states);
    };

    const onToggle = () => {
        setOpenDate(!isOpen);
    };

    const clear = () => {
        setOpenDate(!isOpen);
        setvalue('No Specific Date')
        settemp('')

    };

    const cancel = () => {
        settemp('')
        setOpenDate(!isOpen);

    }
    const save = () => {
        setvalue(temp)
        setOpenDate(!isOpen);
    }




    const data3 = [
        {
            id: 1,
            primary: "Abdul Al Razi",
            secondary: "Khatib",
            src:
                "https://media.gettyimages.com/photos/jama-masjid-mosque-in-delhi-picture-id505757382?s=612x612",
        },
        {
            id: 2,
            primary: "Abdul Al Razi",
            secondary: "Khatib",
            src:
                "",
            initials: "MA",
        },
    ];




    const selectedData = [
        {
            id: 2,
            primary: "Abdul Al Razi",
            secondary: "Khatib",
            src:
                "https://media.gettyimages.com/photos/jama-masjid-mosque-in-delhi-picture-id505757382?s=612x612",
        },
    ];

    const getData = (values) => {
        console.log(values);
    }

    const stylesSelect = {
        select: {
            margin: 8,
            minWidth: "100%",
            height: 66,
            width: "100%",
            margin: 8,
            border: "#D3D8DA",
            width: "100%",
        }
    };


    return (

        <div className='StepperFormCtn'>

            <Grid container spacing={3}>

                <Grid item xs={12}>
                    <h2 className="sub_title">Basic Info</h2>
                </Grid>

                <Grid item xs={12}>
                    <div className="field_box" >
                        <BaseTextField
                            label="Course Title"
                        />
                    </div>
                </Grid>


                <Grid item xs={12} md={12}>
                    <div className="field_box">
                        <BaseSelect
                            label="Category"
                            value={values.age}
                            onChange={handleChangeCategory("age")}
                            object={category}
                            style={stylesSelect.select}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={12}>
                    <div className="field_box">
                        <BaseSelect
                            label="Level"
                            value={values.age}
                            onChange={handleChangeCategory("age")}
                            object={category}
                            style={stylesSelect.select}
                        />
                    </div>
                </Grid>


                <Grid item xs={12}>
                    <div className="field_box" onClick={() => setOpen(true)}>
                        <BaseImgChip
                            chipData={chipData}
                            onInputClick={onInputClick}
                            onChipInputClick={onChipInputClick}
                            deleteChip={deleteChip}
                            helperText=''
                            label="Select Instructor"

                        // textStyle={styles}
                        // arrowClick={arrowClick}
                        />
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <h2 className="sub_title">Duration</h2>
                </Grid>

                <Grid item xs={6}>
                    <div className="field_box" >

                        <DateRange
                            format="MMMM DD, yyyy"
                            label="Start Date"
                            title="Date Range"
                            onToggle={onToggle}
                            onSelect={onSelect}
                            setOpen={setOpenDate}
                            value={temp ? temp : valueDate}
                            isOpen={isOpen}
                            clear={clear}
                            cancel={cancel}
                            save={save}

                        />
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <div className="field_box" >

                        <DateRange
                            format="MMMM DD, yyyy"
                            label="End Date"
                            title="Date Range"
                            onToggle={onToggle}
                            onSelect={onSelect}
                            setOpen={setOpenDate}
                            value={temp ? temp : valueDate}
                            isOpen={isOpen}
                            clear={clear}
                            cancel={cancel}
                            save={save}

                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={12}>
                    <div className="field_box">
                        <BaseSelect
                            label="Months"
                            value={values.age}
                            onChange={handleChangeCategory("age")}
                            object={category}
                            style={stylesSelect.select}
                        />
                    </div>
                </Grid>


                <Grid item xs={12}>
                    <h2 className="sub_title">Course Image</h2>
                </Grid>

                <Grid item xs={12}>
                    <div className="field_box" >
                        <MediaUploader
                            onDelete={onDelete}
                            mediaList={mediaList}
                            getMediaItems={getMediaItems}
                            showMediaTab={"video"}
                            typeOfMedia='/videos'
                            label="Upload Image"
                            onChange={selectMediaItem}
                            addButtonHandler={addMediaItem}
                            imageData={imageData}
                            maxImageLimit={100}
                            videoData={videoData}
                            replyIcon={replyIcon}
                            FileIcon={FileIcon}
                            buttonName="Download"
                            currentImageSize={1500}
                            totalFileUploadLimit={100}
                            selectedMedia={{ logoUrl: 'https://d2imgltiwla1iy.cloudfront.net/6659a2c4f2102d698fff5e934851a98c-test shieyt/1626175177704fd3e1073-3d62-46a3-bba9-37e877b7c900.png', logoName: 'add product' }}
                        />
                    </div>
                </Grid>


            </Grid>

            <DrawerMain
                open={open}
                openHandler={closeDrawer}
                title="Select Assignee"
                onCancel={onCancel}
                loading={false}
                saveDisabled={false}
                onSave={onSave}
            >
                <div className="cardDrawer">
                    <DrawerListCheckbox objects={data3} getData={getData} selectedItems={selectedData} />

                    <div className='DrwrBottominfo'>
                        <p>Don't see the instructor?</p>
                        <h2>Add new instructor(s) from <a href="#">Academy Instructors</a></h2>
                    </div>

                </div>
            </DrawerMain>
        </div>

    );
}


export default CourseOverview;