import React, { Component } from "react";
import {
  BaseTextField,
  BaseButton,
  BaseStepper,
  BaseSelect,
  TextEditor,
  FormPublishUnpublish,
  BaseImgChip,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import Moment from "moment";
import MomentTime from "moment-timezone";
import Typography from "@material-ui/core/Typography";
import "./style.css";
import { getEvents, updateEvent } from "../Donations/deen-kioks-website/Apis";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
import { editOrganization, getOrganizationData } from "../Organization/api";
import { Filter } from "@material-ui/icons";
import { getEventsWithAppId } from "./api";
const { token_expired, success_message_event } = messages_content;
const styles = {
  formBody: {
    background: "#fff",
  },
  contents: {
    padding: 24,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: "76%",
    marginTop: 70,
    marginLeft: "20%",
  },
  formTitle: {
    fontSize: 22,
    color: "#5A5A5A",
  },
  formTitleSub: {
    fontSize: 14,
    color: "#818E94",
  },
  dottedBorder: {
    border: "#1px dashed #D3D8DA",
  },
  textEditor: {
    border: '1px solid black'
  },
  pageTitle: {
    color: "#5A5A5A",
    fontSize: "28px",
    paddingBottom: "30px",
    textAlign: "center",
  },
};
class AddEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      visibility: "Public",
      publish: "Immediately",
      unPublish: "Never",
      publishDate: 'Immediately',
      publishTime: "",
      unPublishDate: "Never",
      unPublishTime: "",
      unPublishTimestamp: 0,
      UTCDate: '',
      UTCTime: '',
      UTCDateUnPublish: '',
      UTCTimeUnPublish: '',
      validUnPublish: true,
      validPublish: true,
      publishedDateAndTime: '',
      unPublishedDateAndTime: '',
      event: "",
      startAt: '',
      endAt: '',
      repeat: '',
      Address: {},
      eventsList: [],
      selectedEvents: [],
      featured: false,
      speakers: [],
      textValue: [{ type: "paragraph", children: [{ text: "" }] }],
      Events: [],
      description: [{ type: "paragraph", children: [{ text: "" }] }],
      errorEvent: false,
      error: false,
      Loading: false,
      orgDataRed: {},
      orgIntegration: {}
    };
  }
  handleNext = () => {
    if (this.state.event === "") {
      this.setState({ errorEvent: true })
    } else {
      this.setState({ activeStep: this.state.activeStep + 1 })
    }
  };
  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 })
  };
  // shouldComponentUpdate(prevProps, prevState){
  //   if(this.state.orgDataRed !== prevProps.organization.orgData){
  //     this.setState({orgDataRed: prevProps.organization.orgData })
  //   }
  //   if(prevState.eventsList !== this.state.eventsList ){
  //     const dataResponse = prevState.eventsList.filter(text => text.published === true && !text.website)
  //     const data = dataResponse.map(text => {
  //       return (
  //         {
  //           ...text,
  //           value: text.title,
  //           label: text.title
  //         }
  //       )
  //     })
  //     this.setState({Events: data})
  //   }
  // }
  
  async componentDidMount() {
    this.setState({ Loading: true })
    const {orgDataRed} = this.state
    const token = localStorage.getItem('authToken')
    const orgId = localStorage.getItem('orgID')
    let pagesEvents = [];
    try{
      const resEvents = await getEventsWithAppId(token, orgId, 'deenweb')
      pagesEvents = resEvents.data.body.data
    }catch(err) {
      this.setState({ Loading: false })
      this.setState({ error: err.response && err.response.data.message })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
    try {
      this.setState({ Loading: true })
      const response = await getEvents(orgId, token);
      const PublishedEvents = response.data.body.data.filter(text => text.published)
      const Ids = pagesEvents.length ? pagesEvents.map(t => t.event._id) : [];
      const filter = PublishedEvents.filter(text => !Ids.includes(text._id))
      const data = filter.map(text => {
        return (
          {
            ...text,
            value: text.title,
            label: text.title
          }
        )
      })
      this.setState({ eventsList: PublishedEvents })
      this.setState({ Events: data, Loading: false });
    } catch (err) {
      this.setState({ Loading: false })
      this.setState({ error: err.response && err.response.data.message })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  }
  InputTextEditor = (e) => {
    this.setState({ textValue: e })
  }
  render() {
    const {
      activeStep,
      visibility,
      publish,
      unPublish,
      publishDate,
      publishTime,
      UTCDate,
      validUnPublish,
      validPublish,
      featured,
      unPublishDate,
      UTCTime,
      UTCDateUnPublish,
      UTCTimeUnPublish,
      publishedDateAndTime,
      unPublishedDateAndTime,
      selectedEvents,
      speakers,
      orgIntegration,
      Address,
      Loading,
      Events,
      event,
      startAt,
      repeat,
      endAt,
      description,
      error,
    } = this.state;
    const convertUTC = (date) => {
      return date.toISOString()
    }
    const onSave = async () => {
      const token = localStorage.getItem("authToken");
      const orgId = localStorage.getItem('orgID');
      let data = UTCDate === '' ? new Date() : new Date(`${UTCDate + ' ' + UTCTime}`)
      let dataU = convertUTC(data);
      let dataE = UTCDateUnPublish === '' ? new Date() : new Date(`${UTCDateUnPublish + ' ' + UTCTimeUnPublish}`)
      let UnpublishTime = convertUTC(dataE);
      const dataObj = {
          "addSelectedArrayData": {
              "enabledIntegrations.deenweb.events.selectedEvents": [{
                  event: selectedEvents[0]._id,
                  publishFrom: publishedDateAndTime === '' ? publish : dataU,
                  publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime,
                  status: visibility,
                  featured: featured
              }]
          },
          "enabledIntegrations.deenweb.events.subscriptionType": "free"
      }
      console.log(dataObj, 'checking obj')
      try {
        await editOrganization(dataObj, orgId, token);
        this.props.alertShow(success_message_event);
        this.props.history.push("/website/events");
      } catch (err) {
        this.setState({ error: err.response && err.response.data.message })
        setTimeout(() => {
          this.setState({ error: false })
        }, 8000);
      }
    };
    const onPublishSave = () => {
      let Time = this.state.publishTime.split(' ');
      let dated = MomentTime(new Date(this.state.publishDate)).format('YYYY-MM-DD');
      let split = Time[0].split(':');
      let Hours = ''
      let H;
      if (Time[1] === 'AM') {
        H = parseInt(split[0]);
        Hours = H + ':' + split[1]
      } else {
        H = parseInt(split[0]) + 12;
        Hours = H + ':' + split[1]
      }
      let date = `${dated + 'T' + Hours}:00.00Z`
      this.setState({ publishedDateAndTime: date });
    }
    const onPublishCancel = () => {
      console.log('onPublishCancel')
    };
    const onUnPublishSave = () => {
      let Time = this.state.unPublishTime.split(' ');
      let dated = MomentTime(new Date(this.state.unPublishDate)).format('YYYY-MM-DD');
      let split = Time[0].split(':');
      let Hours = ''
      let H;
      if (Time[1] === 'AM') {
        H = parseInt(split[0]);
        Hours = H + ':' + split[1]
      } else {
        H = parseInt(split[0]) + 12;
        Hours = H + ':' + split[1]
      }
      let date = `${dated + 'T' + Hours}:00.00Z`
      this.setState({ unPublishedDateAndTime: date })
    };
    const onUnPublishCancel = () => {
      console.log("onUnPublishCancel")
    };
    const onBack = () => {
      this.handleBack()
    }
    const onVisibilitySave = () => {
      console.log("visibility saved");
    };
    const onVisibilityCancel = () => {
      console.log("visibility cancelled");
    };
    const getPublishDate = (value, boolean) => {
      if(value === "Immediately") {
        this.setState({
          publishedDateAndTime: '',
          UTCDate: '',
          publish: 'Immediately',
          publishDate: 'Immediately',

        });
      } else {
        this.setState({
          publishDate: `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`,
          validPublish: false
        })
        this.setState({ postDate: Moment(value).format("YYYY-MM-DD"), UTCDate: Moment(value).format('YYYY-MM-DD') });
      }
      
    };
    const getPublishTime = (value, boolean) => {
      this.setState({ publishTime: Moment(value).format("h:mm A") });
      this.setState({ postTime: `${Moment(value).format("HH:mm:ss")}.000Z`, UTCTime: Moment(value).format("HH:mm:ss") })
    };
    const getUnPublishDate = (value, boolean) => {
      if(value == "Never") {
        this.setState({
          unPublishedDateAndTime: '',
          UTCDateUnPublish: '',
          unPublish: 'Never',
          unPublishDate: 'Never'
        });
      } else {
        this.setState({ UnPublishTimestamp: value, validUnPublish: false });
        this.setState({ unPublishDate: `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`, UTCDateUnPublish: Moment(value).format('YYYY-MM-DD') });
      }
    };
    const getUnPublishTime = (value, boolean) => {
      this.setState({ unPublishTime: Moment(value).format("h:mm A"), UTCTimeUnPublish: Moment(value).format("HH:mm:ss") });
    };
    const handleChange = (prop) => (event) => {
      if (prop === 'event') {
        let events = this.state.eventsList;
        let select = events.filter(tex => { return tex.title === event.target.value })
        let desc = JSON.parse(select[0].description)
        let speakers = select[0].speakers;
        let SpeakerData = speakers.map((text, index) => {
          return ({
            key: index + 1,
            label: text.name.firstName + (text.name.lastName ? ' '+text.name.lastName : ''),
            src: text.imageMetaData ? text.imageMetaData.imageUrl : '',
            alt: "",
          })
        })
        let Address = select[0].location.address;
        let startDate = select[0].eventStart.startAt;
        let endDate = select[0].endAt;
        let repeat = select[0].repeat.category
        this.setState({
          event: event.target.value,
          selectedEvents: select,
          description: desc,
          speakers: SpeakerData,
          Address: Address,
          startAt: `${Moment(startDate).format("ddd")}, ${Moment(startDate).format("MMM D, YYYY")}, ${Moment(startDate).format("h:mm A")}`,
          endAt: `${Moment(endDate).format("ddd")}, ${Moment(endDate).format("MMM D, YYYY")}, ${Moment(endDate).format("h:mm A")}`,
          repeat: repeat,
        })
      }
    };
    let chipData = [
      {
        key: 0,
        label: selectedEvents.lenght > 0 ? selectedEvents[0]?.imageDetails?.imageName : '',
        src: selectedEvents.length > 0 ? selectedEvents[0]?.imageDetails?.imageUrl : '',
        alt: "",
      },
    ];
    const onInputClick = () => {
      console.log("input clicked");
    };
    const onChipInputClick = () => {
      console.log("input chip clicked");
    };
    const deleteChip = () => {
      console.log("chip deleted");
    };
    const getSteps = () => {
      return [
        { number: 1, label: "Event Details" },
        { number: 2, label: "Publish" },
      ];
    }
    const style = {
      width: '100%',
    };
    return (
      <MasterLayoutMiddle
        header={
          <>
            {
              error && error === "Invalid token" && (
                <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                  <AlertAction
                    alert={token_expired}
                    alertType={"error"}
                    onClick={() => this.setState({ error: false })}
                  />
                </div>
              )
            }
          </>
        }
        isForm={true}
        formData={
          Loading ?
            <div
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </div>
            :
            <div>
              <BaseStepper
                title="Add Event"
                getSteps={getSteps}
                alternativeLabel={true}
                handleBack={() => this.handleBack()}
                handleNext={() => this.handleNext()}
                handleBackPreviousPage={() => this.props.history.push('/website/events')}
                nonLinear={true}
                orientation={"horizontal"}
                btnStyles={styles.stepper}
                activeStep={activeStep}
              />
              {activeStep === 0 && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Select Event</h2>
                    <Typography className="sub_decrition">Add “New Event” from Home screen &gt; Manage &gt; Event</Typography>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseSelect
                        value={event}
                        object={Events}
                        onChange={handleChange("event")}
                        style={{ width: "100%", margin: 0 }}
                        helperText={Events.length ? "" : <p>Events not found. For create new click here: <span onClick={() => this.props.history.push('/addevent')} className='addLinkTag'> Add Events </span></p>}
                        label="Select Event"
                        error={this.state.errorEvent || Events.length ? false : true}
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <TextEditor
                      label={'Description'}
                      onChange={(e) => this.InputTextEditor(e)}
                      value={description}
                     // helperText="Helper Text"
                      style={styles.textEditor}
                      disabled
                    />
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseImgChip
                        chipData={selectedEvents.length > 0 ? chipData : []}
                        onInputClick={onInputClick}
                        onChipInputClick={onChipInputClick}
                        deleteChip={deleteChip}
                        label="Image"
                        textStyle={{ width: "100%" }}
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseImgChip
                        chipData={selectedEvents.length > 0 ? speakers : []}
                        onInputClick={onInputClick}
                        onChipInputClick={onChipInputClick}
                        deleteChip={deleteChip}
                        label="Guests"
                        textStyle={{ width: "100%" }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="dasheddBorder_form"></div>
                  </Grid>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Event Location</h2>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={Address && Address.locationName}
                        InputProps={true}
                        disabled={true}
                        label=""
                        placeholder="Location"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="dasheddBorder_form"></div>
                  </Grid>
                  <Grid item xs={12}>
                    <h2 className="sub_title">Date and Time</h2>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={startAt}
                        label="Start Date and Time"
                        placeholder=""
                        disabled={true}
                        onClick={() =>
                          this.setState({ openDrawer: true, isStartDate: true })
                        }
                        InputProps={true}
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={endAt}
                        label="End Date and Time"
                        placeholder=""
                        disabled={true}
                        InputProps={true}
                        onClick={() =>
                          this.setState({ openDrawer: true, isEndDate: true })
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <BaseTextField
                        value={repeat}
                        label="Repeat"
                        placeholder=""
                        disabled={true}
                        style={{ width: "100%" }}
                        onClick={() =>
                          this.setState({ openDrawer: true, isRepeatDays: true })
                        }
                        InputProps={true}
                      />
                    </div>
                  </Grid>
                  <Grid item md={12} xl={12}>
                    <div className="form_button">
                      <span className="btnCencel">
                        <BaseButton text="CANCEL" onClick={() => this.props.history.push("/website/events")} />
                      </span>
                      <BaseButton text="CONTINUE" onClick={() => this.handleNext()} />
                    </div>
                  </Grid>
                </Grid>
              )}
              {activeStep === 1 && (
                <Grid container spacing={3}>
                  <Grid item md={12} xl={12}>
                    <div className="field_box">
                      <FormPublishUnpublish
                        visibility={visibility}
                        setVisibility={(e) => this.setState({ visibility: e })}
                        publish={publish}
                        unPublish={unPublish}
                        publishDate={publishDate}
                        publishTime={publishTime}
                        setPublishDate={(e) => this.setState({ setPublishDate: e })}
                        setPublishTime={(e) => this.setState({ setPublishTime: e })}
                        getPublishDate={getPublishDate}
                        getPublishTime={getPublishTime}
                        unPublishDate={unPublishDate}
                        unPublishTime={this.state.unPublishTime}
                        setUnPublishDate={(e) => this.setState({ setUnPublishDate: e })}
                        setUnPublishTime={(e) => this.setState({ setUnPublishTime: e })}
                        getUnPublishDate={getUnPublishDate}
                        getUnPublishTime={getUnPublishTime}
                        onVisibilitySave={onVisibilitySave}
                        onVisibilityCancel={onVisibilityCancel}
                        onPublishSave={onPublishSave}
                        onPublishCancel={onPublishCancel}
                        onUnPublishSave={onUnPublishSave}
                        onUnPublishCancel={onUnPublishCancel}
                        onSave={onSave}
                        onBack={onBack}
                        unPublishTimestamp={this.state.unPublishTimestamp}
                        showFeatured={false}
                        featuredText="Feature this on homepage"
                        checked={this.state.checked}
                        onSetChecked={() => this.setState({ SetChecked: !this.state.checked })}
                        minDateUnpublish={publishDate}
                        maxDatepublish={unPublishDate}
                        disabledSave={validUnPublish}
                        disabledSaveP={validPublish}
                        showFeatured={true}
                        featuredText="Feature this on homepage"
                        checked={featured}
                        onSetChecked={() => this.setState({ featured: !featured })}
                      />
                    </div>
                  </Grid>
                </Grid>
              )}
            </div>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={true}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    organization: state.orgData
  };
};
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);