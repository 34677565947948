import React from 'react';
import {
    MasterLayoutMiddle, FooterInner, BasePhoneInput,
    BaseSelect, BaseTextField, DateRange, BaseButton,
}
    from "@dclab/dc-react-ui-kit";

import Grid from '@material-ui/core/Grid';
import ChevronLeft from '@material-ui/icons/ChevronLeft';


function AddDonation(props) {

    const style = {
        width: '100%',
    };

    const [values, setValues] = React.useState({
        age: "",
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const object = [
        {
            value: "10",
            label: "Ten",
        },
        {
            value: "20",
            label: "Twenty",
        },
        {
            value: "30",
            label: "Thirty",
        },
    ];

    const [isOpen, setOpen] = React.useState(false);
    const [states, setstates] = React.useState(false);
    const [temp, settemp] = React.useState('')
    const [value, setvalue] = React.useState('No specific date')

    const onSelect = (value, states) => {
        settemp(value);
        setstates(states);
    };

    const onToggle = () => {
        setOpen(!isOpen);
    };

    const clear = () => {
        setOpen(!isOpen);
        setvalue('No Specific Date')
        settemp('')

    };

    const cancel = () => {
        settemp('')
        setOpen(!isOpen);

    }
    const save = () => {
        setvalue(temp)
        setOpen(!isOpen);
    }


    const [phone, setPhone] = React.useState({
        country: "",
        number: "",
    });


    

    return (

        <MasterLayoutMiddle

            header={
                <div style={{ width: '26%', alignSelf: 'center' }}>
                    <div
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                        className='backBtn'
                    >
                        <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
                        <p style={{ alignSelf: 'center' }}>Back</p>{' '}
                    </div>
                </div>
            }


            isForm={true}
            formData={
                <>
                    <div>
                        <h1 className='title_top'>Add Donation</h1>
                        <div className="dasheddBorder"></div>
                    </div>


                    <Grid container spacing={3}>

                        <Grid item xs={6}>
                            <div className="field_box">
                                <BaseSelect
                                    label="$ Amount"
                                    value={values.age}
                                    onChange={handleChange("age")}
                                    object={object}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="field_box DateRange">
                                <DateRange
                                    format="MMMM DD, yyyy"
                                    label="Date"
                                    onToggle={onToggle}
                                    onSelect={onSelect}
                                    setOpen={setOpen}
                                    value={temp ? temp : value}
                                    isOpen={isOpen}
                                    clear={clear}
                                    cancel={cancel}
                                    save={save}

                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseSelect
                                    label="Payment method"
                                    value={values.age}
                                    onChange={handleChange("age")}
                                    object={object}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Internal notes"
                                    // count={100}
                                />
                            </div>
                        </Grid>


                        <Grid item xs={12}>
                            <div className="form_button">
                                <p
                                    className='cancel'
                                    onClick={() => this.onCancelOrgDetail()
                                    }
                                >
                                    CANCEL
                                </p>
                                <BaseButton text='ADD NOW' onClick={() => this.updateOrg()} />
                            </div>

                        </Grid>



                    </Grid>


                </>
            }

            isFooter={true}
            footer={
                <FooterInner style={style} />
            }

            isWhiteBackground={true}

        />

    );
}


export default AddDonation;