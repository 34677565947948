import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  SimpleAppBar,
  BaseStepper,
  BaseTextField,
  BaseSelect,
  BaseDivider,
  BaseCheckBox,
  BaseButton,
  MasterLayoutMiddle
} from '@dclab/dc-react-ui-kit';
import CircularProgress from '@material-ui/core/CircularProgress';
import { countries } from '../../utils/countries';
import Logo from '../../assets/img/DC_Logo.png';
import Crown from '../../assets/img/crown.svg';
import './style.css';
import './onboarding.css';
import '../style.css';
import { editOrganizationForSetupForm } from './api';
import { addOrgHandler } from '../../redux/actions/newOrgAction';
import Footer from '../../components/Footer/Footer';
import { ChevronLeft } from '@material-ui/icons';
import Grid from '@material-ui/core/Grid';
import { getPlanSubscriptionsByOrgId } from '../UserProfile/Apis';

const env = {
    prod: process.env.REACT_APP_STRIPE_PROD_KEY,
    dev: process.env.REACT_APP_STRIPE_DEV_KEY
};
let currentEnv;
if (
    window.location.host.includes("amazon") ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("shapla")
) {
    currentEnv = "dev";
} else {
    currentEnv = "prod";
}
const envStripeKey = env[currentEnv];


class setupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      activeStep: 0,
      name: '',
      type: '',
      postal_code: '',
      country: '',
      tax_exemption_status: false,
      error: false,
      showAlert: false,
      loading: false,
      zipCodeError: false,
      userData: null,
      subscription: false,
    };
    this.getSteps = this.getSteps.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.checked = this.checked.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.setCountry = this.setCountry.bind(this);
  }
  async componentDidMount() {
    try {
      // const userRes = await fetch(
      //   'https://geolocation-db.com/json/7733a990-ebd4-11ea-b9a6-2955706ddbf3'
      // );
      // const userData = await userRes.json();
      let orgName = localStorage.getItem('orgName') ?? "";
      this.setState({
        name: orgName ? orgName : "",
        // userData,
        // country: userData.country_name,
        country: 'United States'
      });

      const token = localStorage.getItem("authToken");
      let orgId = localStorage.getItem("orgID");
      const res = await getPlanSubscriptionsByOrgId(orgId, token);
      // console.log("getPlanSubscriptionsByOrgId: ", res);
      if(res?._id) {
        let subsData = res;
        const arr = Object.keys(subsData)
        .filter(v => {
          return subsData[v] != null && typeof subsData[v] === 'object'
        }).map(key => ({ ...subsData[key], planName: [key][0] }))
        let deenfundSubscription = arr?.length ? arr.find((el) => el?.planName?.toLowerCase() === "deenfund") : null;
        if(deenfundSubscription?.plan) {
          this.setState({
            subscription: true
          });
        }
        // console.log("arrs", deenfundSubscription)
      }

    } catch (error) {
      console.log(error);
    }
    const user = localStorage.getItem('user');
    if (!user) {
      this.props.history.replace('signin');
    }
  }
  setCountry = (e) => {
    this.setState({ country: e });
  };
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  checked = (e) => {
    this.setState({
      tax_exemption_status: e.target.checked,
    });
  };
  getSteps = () => {
    return this.state.subscription ? [
      {
        number: 1,
        label: 'Organizations details',
      },
      { number: 2, label: 'Connect' },
    ] : [];
  };
  handleNext = async () => {
    const {
      name,
      type,
      postal_code,
      tax_exemption_status,
      zipCodeError,
      country,
    } = this.state;
    this.setState({ loading: true });
    if (!name || !type || !postal_code || !country) {
      this.setState({
        error: true,
        loading: false,
      });
    } else if (zipCodeError) {
      this.setState({ error: true, loading: false });
    } else {
      let obj = {
        orgName: name,
        orgType: type,
        // taxIdStatus: {
        //   taxExempt: tax_exemption_status,
        //   taxIdEin: '',
        // },
        orgAddress: {
          line1: '',
          state: '',
          country,
          postalCode: postal_code,
        },
        // phoneFax: {
        //   primaryPhone: ' ',
        // },
      };
      if(tax_exemption_status) {
        const time = new Date();
        const timeStamp = time.getTime();
        Object.assign(obj, {taxIdStatus: { 
          taxExempt: tax_exemption_status,
          taxIdEin: '',
          taxExemptDetail: {status: 'pending', submittedAt: timeStamp},
          events: [
            {
              eventType: 'Tax exemptions',
              status: 'Submitted',
              submittedAt: timeStamp,
            }],
          }
        });
      } else {
        Object.assign(obj, {taxIdStatus: { 
          taxExempt: tax_exemption_status,
          taxIdEin: '',
          }
        });
      }
      // console.log("Data to be submitted: ", obj);
      try {
        console.log("obj", obj)
        const res = await editOrganizationForSetupForm(
          obj,
          localStorage.getItem('authToken')
        );
        const orgId = res.data._id;
        localStorage.setItem('orgID', orgId);
        this.props.addOrg(name, type, tax_exemption_status, postal_code, orgId);
        if(!this.state.subscription) {
          this.setState({
            loading: false,
          });
          this.props.history.push("/setup")
        } else {
          this.setState({
            loading: false,
            activeStep: this.state.activeStep + 1,
          });
        }
      } catch (error) {
        this.setState({
          showAlert: true,
          error: error && error.response && error.response.data.message,
          loading: false,
        });
        this.hideAlert();
      }
    }
  };
  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1,
    });
  };
  handleChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };
  handleState = (key, value) => {
    this.setState({ [key]: value });
  };
  render() {
    const styles = {
      stepper: {
        borderRadius: 23,
      },
      back: {
        left: -350,
        position: 'relative',
        marginTop: 75,
      },
    };
    let countriesArray = [{
      value: "United States",
      label: "United States",
    }];
    // countries.map((country) => {
      // countriesArray.push({
      //   value: "United States",
      //   label: "United States",
      // });
    // });
    const object = [
      {
        value: 'Academic/Educational Institutions',
        label: 'Academic/Educational Institutions',
      },
      {
        value: 'Charitable Funds',
        label: 'Charitable Funds',
      },
      {
        value: 'Community/Youth Center',
        label: 'Community/Youth Center',
      },
      {
        value: 'Education/Career Development',
        label: 'Education/Career Development',
      },
      {
        value: 'Entrepreneurship & Ideas',
        label: 'Entrepreneurship & Ideas',
      },
      {
        value: 'Funeral/Cemetery Services',
        label: 'Funeral/Cemetery Services',
      },
      {
        value: 'Masjid/Islamic Center',
        label: 'Masjid/Islamic Center',
      },
      {
        value: 'Not-for-Profit Organization',
        label: 'Not-for-Profit Organization',
      },
      {
        value: 'Private Foundation',
        label: 'Private Foundation',
      },
      {
        value: 'Public Affairs/Politics',
        label: 'Public Affairs/Politics',
      },
      {
        value: 'Scientific Research',
        label: 'Scientific Research',
      },
      {
        value: 'Social/Recreational Clubs',
        label: 'Social/Recreational Clubs',
      },
      {
        value: 'Veterans',
        label: 'Veterans',
      },
      {
        value: 'Voluntary Association',
        label: 'Voluntary Association',
      },
      
    ];
    const {
      activeStep,
      name,
      type,
      postal_code,
      tax_exemption_status,
      error,
      showAlert,
      loading,
      zipCodeError,
      country,
    } = this.state;
    const logout = () => {
      localStorage.setItem('user', false);
      localStorage.setItem('authToken', '');
      this.props.history.replace('/signin');
      localStorage.clear();
    };
    let UsZipRegex = /^\d{5}(-\d{4})?(?!-)$/;
    // console.log("envStripeKey", envStripeKey)
    return (
      <div>
        {showAlert && error && (
          <div
            onClick={() => this.handleBack()}
            className="backBtn"
          >
            <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
            <p style={{ alignSelf: 'center' }}>Back</p>{" "}
          </div>
        )}
        <div className="app_bar" style={{ boxShadow: '0px 1px 4px #0000001A', width: '100%', }}>
          <SimpleAppBar
            src={Logo}
            name={localStorage.getItem('name')}
            role={localStorage.getItem('role')}
            showImg={false}
            img={''}
            icon={Crown}
            onClickLogout={() => logout()}
          />
        </div>
        <MasterLayoutMiddle
          header={
            <div className='stepperOn'>
              {
                activeStep !== 0 ?
                  <div
                    onClick={() => this.handleBack()}
                    className="backBtn"
                    style={{ marginLeft: 150, }}
                  >
                    <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
                    <p style={{ alignSelf: 'center' }}>Back</p>{" "}
                  </div>
                  : null
              }
              <BaseStepper
                title={activeStep === 0 ? "Let's Get Your organization set up" : "Connect to Stripe"}
                subTitle='With just some basic details, onboarding is easy and fast'
                getSteps={() => this.getSteps()}
                alternativeLabel={true}
                nonLinear={true}
                backButton={false}
                orientation={'horizontal'}
                btnStyles={styles.stepper}
                bottomLine={false}
                backgroundColor={"#FAFAFA"}
                handleBack={() => this.handleBack()}
                handleNext={() => this.handleNext()}
                activeStep={activeStep}
                backStyle={styles.back}
              />
            </div>
          }
          isForm={true}
          formData={
            <div>
              {(activeStep === 0) && (
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseTextField
                        label='Organization Name'
                        placeholder=''
                        helperText={
                          error && !name
                            ? 'Organization name is required'
                            : 'Organization’s full name as registered and shows in legal documents'
                        }
                        value={name}
                        onChange={(e) => this.handleState('name', e.target.value)}
                        error={error && !name}
                        count={50}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseSelect
                        value={type}
                        onChange={(e) => this.handleState('type', e.target.value)}
                        object={object}
                        label='Organization Type'
                        helperText={
                          error && !type
                            ? 'Organization type is required'
                            : 'Select your organization type'
                        }
                        error={error && !type}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="field_box">
                      <BaseTextField
                        label='Zip/Postal Code'
                        placeholder=' '
                        type='text'
                        helperText={
                          (error && !postal_code && 'Postal code is required') ||
                          (error && zipCodeError && 'Invalid Zip code') || ''
                        }
                        value={postal_code}
                        onChange={(e) => {
                          const numRegx = /^\d+$/
                          if(e.target.value.length <= 5 && numRegx.test(e.target.value)) {
                            this.setState({
                              zipCodeError: false,
                              postal_code: e.target.value,
                            });
                          } else if (e.target.value.length < 1) {
                            this.setState({
                              zipCodeError: true,
                              postal_code: e.target.value,
                            });                          
                          }

                          // if (!UsZipRegex.test(e.target.value)) {
                          //   this.setState({
                          //     zipCodeError: true,
                          //     postal_code: e.target.value,
                          //   });
                          // } else {
                          //   this.setState({
                          //     zipCodeError: false,
                          //     postal_code: e.target.value,
                          //   });
                          // }
                        }}
                        error={(error && !postal_code || error && zipCodeError)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="field_box">
                      <BaseSelect
                        value={country}
                        onChange={(e) => this.setState({ country: e.target.value })}
                        object={countriesArray}
                        label='Country'
                        helperText={
                          error && !type
                            ? 'Country is required'
                            : ''
                        }
                        error={error && !country}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="field_box">
                      <BaseCheckBox
                        checked={tax_exemption_status}
                        label='We are 501(c)(3) tax exempted not-for-profit organization.'
                        color='secondary'
                        showHelperText={true}
                        helperText='This will be displayed to potential visitors/donors as VERIFIED - 501(c)(3)'
                        onChange={(e) =>
                          this.handleState('tax_exemption_status', e.target.checked)
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <BaseDivider />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: 'flex', }}>
                      <img
                        src={require('../../assets/img/info-24px.svg')}
                        style={{ alignSelf: 'flex-start' }}
                        alt=''
                      />
                      <p className="form_info">
                        To add an organization, you must be an employee or authorized
                        member to represent and the organization with proof of
                        business registration and tax information. This information is
                        necessary to prevent fraud, comply with the law, and deliver
                        funds to organization’s bank account.
                      </p>
                    </div>
                  </Grid>
                  
                  {this.state.subscription && <Grid item xs={12}>
                    {loading ? (
                      <CircularProgress color='primary' style={{ marginLeft: 250 }} />
                    ) : (
                        activeStep !== this.getSteps().length && (
                          <div>
                            <div className="form_button">
                              <BaseButton
                                text={'PROCEED NEXT'}
                                color='primary'
                                onClick={() => this.handleNext()}
                                style={styles.stepper}
                                disabled={!name || !type || !postal_code}
                              />
                            </div>
                          </div>
                        )
                      )}
                  </Grid> }

                  {!this.state.subscription && <Grid item xs={12}>
                    {loading ? (
                      <CircularProgress color='primary' style={{ marginLeft: 250 }} />
                    ) : (
                          <div className="form_button">
                            <BaseButton
                              text={'SUBMIT NOW'}
                              color='primary'
                              onClick={() => this.handleNext()}
                              style={styles.stepper}
                              disabled={!name || !type || !postal_code}
                            />
                          </div>
                      )}
                  </Grid>}
                </Grid>
              )}

              {activeStep === 1 && this.state.subscription && (
                <div className="">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <p className='stripe-para'>
                        To start collecting online donations, please connect with
                  secured online payment processing partner{' '}
                        <span style={{ color: '#2A82F6' }}>Stripe</span>; trusted by
                  millions of companies around the world
                </p>
                    </Grid>
                    <Grid item xs={12}>
                      <div className='stripe-account'>
                        <a href={`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${envStripeKey}&scope=read_write`}>
                          <img
                            src={require('../../assets/img/blue-on-dark@2x.png')}
                            style={{
                              cursor: 'pointer',
                            }}
                            alt=''
                          />
                        </a>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <p className='stripe-para'>Do not have a Stripe account?</p>
                      <p className="creatButn">
                        <a className='stripe-skip-link'
                          href={`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${envStripeKey}&scope=read_write`}>
                          Create a new Stripe account
                        </a>
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <BaseDivider />
                    </Grid>
                    <Grid item xs={12}>
                      <p className='stripe-skip'>
                        <Link to='/setup'>
                          Skip for now
                  </Link>
                      </p>
                    </Grid>
                  </Grid>
                </div>
              )}
              <Footer customClass='setup-footer' />
            </div>
          }
          isWhiteBackground={true}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    signin: state.signin,
    signup: state.signup,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addOrg: (
      name,
      type,
      email,
      taxId_ein,
      primary_phone,
      suit_number,
      city,
      state,
      postal_code,
      line1,
      tax_exemption_status
    ) =>
      dispatch(
        addOrgHandler(
          name,
          type,
          email,
          taxId_ein,
          primary_phone,
          suit_number,
          city,
          state,
          postal_code,
          line1,
          tax_exemption_status
        )
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(setupForm);
