import React from "react";
import { MasterLayoutMiddle, FooterInner } from "@dclab/dc-react-ui-kit";
import sadIcon from '../../../assets/img/sadIcon.svg'
const style = {
  width: '100%',
};


function AccessRevoked(props) {

  return (

    <div className="RevokedMainContainer">
    <MasterLayoutMiddle
      
      content={
        <div style={{textAlign:"center", paddingTop:"103px", }}>
            <img src={sadIcon} style={{ maxWidth:"33px", }} />
            <div style={{fontSize:"28px", color:"#4D4D4D", maxWidth:"840px", margin:"0 auto", paddingTop:"30px", marginBottom:"16px", }}>It seems like you don't have access to this organization anymore!</div>
            <div style={{fontSize:"16px", color:"#818E94", maxWidth:"516px", margin:"0 auto", lineHeight:"28px", }}>Ask your organization why you no longer have access to your dashboard. If you think this is a mistake please <a style={{fontSize:"16px", color:"#2490F9", textDecoration:"none", }} href={process.env.REACT_APP_Environment === 'dev' ? "https://shapla.deenconnect.io/about#contact-us" : "https://deenconnect.io/about#contact-us" } target="blank"> contact us</a></div>
        </div>
      }

      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}

    />
    </div>
  );
}
export default AccessRevoked;