import React, { useEffect } from "react";
import { AlertAction, AlertDialogue, EmptyTable, GridContainer, GridItem , SimpleTable} from "@dclab/dc-react-ui-kit";
import Handle from "../../../assets/img/more_vert-24px.svg";
import { GetEventsWebsiteFeatured, getEventsWithAppId, sortEventsWebsite, updateEventsWithAppId } from "../../../pages/Website/api";
import Moment from "moment";
import { CircularProgress } from "@material-ui/core";
import { updateEvent } from "../../../pages/Donations/deen-kioks-website/Apis";
import { connect } from "react-redux";
import { hideAlert, showAlert } from "../../../redux/actions/alertActions";
import messages_content from '../../../utils/messages.json';
const { 
    token_expired,
    event_unfeatured_successfully,
  } = messages_content;
function Events(props) {
  const [dataTable, setDataTable] = React.useState([]);
  const [Loader, setLoader] = React.useState(true);
  const [specificRow, setSpecificRow] = React.useState([]);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [error, seterror] = React.useState(false)
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  useEffect(() => {
    getFeaturedEvents()
  }, []);
  async function getFeaturedEvents() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    try {
      const resEvents = await getEventsWithAppId(token, orgId, 'deenweb')
      const data = resEvents.data.body.data;
      const filteredEvents = data.filter(text => text.featured)
      const sort =  filteredEvents.sort((a,b) => {
        return a.event.order - b.event.order
      })
      console.log("sort: ", sort)
      setData(sort)
      let dataTable = [];
      sort.map((text, index) => {
        const dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("h:mm A")];
        const dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("h:mm A")];
        const UnpublishDate = new Date(dateEnd).getFullYear()
        let array = [
          { type: "id", value: `${index<=8 ? '0'+(index+1) : index + 1}`, _id: text._id, eventId: text.event._id },
          {
            type: "url",
            name: "single_pic",
            src: text.event?.imageDetails?.imageUrl ?? "",
          },
          {
            type: "string",
            name: "name",
            value: text.event.title,
          },
          {
            type: "string",
            name: "vertical",
            value: dateStart,
          },
          {
            type: "string",
            name: UnpublishDate === 2099 ? "frequency" : "vertical",
            value: UnpublishDate === 2099 ? "Never" : dateEnd
          },
          { type: "action", status: "details", value: "Remove" },
        ]
        dataTable.push(array)
      })
      setDataTable(dataTable);
      setLoader(false);
    } catch (err) {
      console.log("ERROR is here: ", err)
      setLoader(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
        }, 8000);
    }
  }
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  const DeleteAlertFunc = (e) => {
    setSpecificRow(e);
    setOpenAlert(true);
  }
  const handleCancel = () => {
    setOpenAlert(false);
  }
  const handleClose = () => {
    setOpenAlert(false);
  };
  const RemoveRow = async (e) => {
    setLoader(true);
    setOpenAlert(false);
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    const updateOnOrg = {
      event: data[0].event._id,
      featured: false,
      publishFrom: data[0].publishFrom,  // DATE OBJECT
      publishTo: data[0].publishTo,
      status: data[0].status,
  }
    try{
      await updateEventsWithAppId(token, orgId, 'deenweb',data[0].event._id,updateOnOrg )
      props.alertShow(event_unfeatured_successfully)
      const stateNews = dataTable
      const NewsIndex =  dataTable.indexOf(e)
      stateNews.splice(NewsIndex, 1)
      setLoader(false);
    }catch(err){
      setLoader(false);
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  let col = [
    { id: "drag", label: " ", width: 'auto' },
    { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
    { type: "link", name: "invoice_number", label: "Image", width: 'auto' },
    { type: "string", name: "name", label: "Event Title", width: '40%' },
    { type: "string", name: "date/time", label: "Start Date/Time", width: '15%' },
    {
      type: "string",
      name: "date/time",
      label: "End Date/Time",
      width: '15%',
    },
    { type: "action", name: "action", label: "ACTION", width: '10%' },
  ];
  const style = {
    width: 470,
    marginLeft: 800
  };
  const sortData = async (data) => {
    props.setCheckChanges(true)
    const token = localStorage.getItem("authToken");
    let OBJ = {
      sortedList: data.map(text => {
        return (
          { _id: text?.event?._id, order: text.order }
        )
      })
    }
    try{
      await sortEventsWebsite(token, OBJ)
    }catch(err){
      seterror(err.response && err.response.data.message)
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
    const sortData = data.sort((a, b) => a.order - b.order)
    let arr =[]
    sortData.map((text, index) => {
      const dateStart = [Moment(text.publishFrom).format("MMM DD, YYYY"), Moment(text.publishFrom).format("h:mm A")];
      const dateEnd = [Moment(text.publishTo).format("MMM DD, YYYY"), Moment(text.publishTo).format("h:mm A")];
      const UnpublishDate = new Date(dateEnd).getFullYear()
      let array = [
        { type: "id", value: `${index<=8 ? '0'+(index+1) : index + 1}`, _id: text._id, eventId: text.event._id },
        {
          type: "url",
          name: "single_pic",
          src: text?.event?.imageDetails?.imageUrl ?? '',
        },
        {
          type: "string",
          name: "name",
          value: text?.event?.title,
        },
        {
          type: "string",
          name: "vertical",
          value: dateStart,
        },
        {
          type: "string",
          name: UnpublishDate === 2099 ? "frequency" : "vertical",
          value: UnpublishDate === 2099 ? "Never" : dateEnd
        },
        { type: "action", status: "details", value: "Remove" },
      ]
      arr.push(array)
    })
    setDataTable(arr);
};
  return (
    <GridContainer containerClass="web-announcements ">
      {
        Loader ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          // dataTable.length>0 ?
          <>
             {
                error && error === "Invalid token" && (
                  <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '17px' }}>
                    <AlertAction
                      alert={token_expired}
                      alertType={"error"}
                      onClick={() => seterror(false)}
                    />
                  </div>
                )
              }
            <GridItem xs={12} itemClass="web-salah">
              <SimpleTable
                showHeader={true}
                title="Featured Events"
                parentTableDrag={true}
                DragIcon={Handle}
                width="98%"
                rows={dataTable}
                columns={col}
                colSpan={16}
                actionSendButton={true}
                actionLink={(e) => DeleteAlertFunc(e)}
                classProps={changeClassProps()}
                donationsCount={dataTable?.length ? 1 : 0}
                noTitle="No events added"
                noPara="Added events will be displayed and managed here"
                noBtnText="Add New Event"
                onInviteClick={() =>
                  props.history.push("/website/homepage/add-new-event")
                }
                showIconCol={false}
                showFooter={false}
                showAdd={true}
                addText="ADD"
                onAddClick={() =>
                  props.history.push("/website/homepage/add-new-event")
                }
                iconTextSize={16}
                sortData={(e) => sortData(e)}
                data={data}
              />
              <div style={{ display: "flex", marginTop: 20 }}>
                <p className="cat-lower" style={{ marginTop: 0 }}> Change the order of Events to display to users. Click on{" "}</p>
                <img  src={Handle} alt="" style={{ display: "flex", marginTop: -10 }} />
                <p className="cat-lower" style={{ marginTop: 0 }}>to drag and drop within the table.</p>
              </div>
            </GridItem>
          </>
      //     :
      //   <EmptyTable onAddClick={() => props.history.push("/website/homepage/add-new-event")}/>
      }
       <div>
          <AlertDialogue
            title="Remove Event?"
            description="Are you sure you want to remove this event?"
            description2 = "It will be removed from homepage only."
            cancelText="Cancel"
            confirmText="Confirm"
            open={openAlert}
            handleCancel={() => handleCancel()}
            handleClose={() => handleClose()}
            handleConfirm={() => RemoveRow(specificRow)}
            styles={style}
          />
        </div>
    </GridContainer>
  );
}
const mapStateToProps = (state) => ({
  alert: state.addOrg.alert,
});
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
  alertHide: (res) => dispatch(hideAlert(res)),

});
export default connect(mapStateToProps, mapDispatchToProps)(Events);
