import React, { useEffect, useState } from "react";
import { addNewHomeScreen, getHomeScreens, deleteHomeScreen } from "../Apis/index";
import { getOrganizationData } from "../../Organization/api/index";
import Grid from '@material-ui/core/Grid';
import {SimpleTable,  MasterLayoutMiddle, FooterInner, AlertDialogue, AlertAction
} from "@dclab/dc-react-ui-kit";

import Edit from "../../../assets/img/create-24px.svg";
import Delete from "../../../assets/img/delete_forever-24px.svg";

import Modal from './CreateNewHomeScreenModal'
import ChooseTemplate from './ChooseTemplate'
import Layout1 from "../../../assets/images/DK_Layout_1.png"
import Layout2 from "../../../assets/images/DK_Layout_2.png"
import Layout3 from "../../../assets/images/DK_Layout_3.png"
import Layout4 from "../../../assets/images/DK_Layout_4.png"
import Layout5 from "../../../assets/images/DK_Layout_5.png"

const orgId = localStorage.getItem("orgID")
const token = localStorage.getItem("authToken")

function HomeScreenListing(props) {
  const [nameModalOpen, setnameModalOpen] = React.useState(false);
  const [forceRender, setforceRender] = useState(false)
  const [templateModalOpen, settemplateModalOpen] = React.useState(false);
  const [addHomeScreen, setaddHomeScreen] = useState({
    name: "", status: "inactive", template: { name: "" , imageUrl: ""}, orgId: orgId
  })
  const [orgData, setorgData] = useState({})
  const [homeScreens, sethomeScreens] = useState([])
  const [homeScreenId, sethomeScreenId] = useState("")
  const [open, setOpen] = useState(false);
const [showAlert, setshowAlert] = useState(false)

  const handleClose = () => {
    console.log('close')
    setOpen(false)
  };
  const handleCancel = () => {
    console.log('cancel')
    setOpen(false)
  };

  const handleConfirm = async () => {
    console.log('confirm', homeScreenId)
    if(homeScreenId) {
      let res = await deleteHomeScreen(homeScreenId, token)
      if(res) {
        sethomeScreens((prevState) => prevState.filter(item => item?._id !== homeScreenId));
        console.log(res)
        setOpen(false)
      }
    }
  };

  const style2 = {
      width:470,
      marginLeft:800
  };

  useEffect(() => {
    if(props?.location?.state?.showAlert) {
      setshowAlert("Homescreen updated successfully!")
      setTimeout(() => {
        setshowAlert(false)
      }, 8000);
    }
  }, [])

  useEffect(() => {
    const getData = async () => {
      const orgId = localStorage.getItem("orgID")
      const token = localStorage.getItem("authToken")
      
      let res = await getHomeScreens(orgId, token);
      console.log("here")
      if(res?.data?.body?.data) {
        sethomeScreens(res?.data?.body?.data)
      }
      
    try {
      const getOraganization = await getOrganizationData(orgId, token)
      const org = getOraganization?.data?.body?.data ?? {} 
      if(org) {
        setorgData({orgName: org.orgName, orgIcon: org.orgIcon ?? {} })
      }
    } catch (error) {
      console.log("error", error)
    }
    }
    getData()
  }, [forceRender])

  const submitForm = async () => {
    const token = localStorage.getItem("authToken")

    console.log({...addHomeScreen}, token)

    let res = await addNewHomeScreen({...addHomeScreen}, token);
    if(res?.data?.body?.data) {
      console.log("ress", res?.data?.body?.data, orgData)
      props.history.push({ pathname: "/deenkiosk/edit-home-screen", state: { homeScreen: res?.data?.body?.data, org: orgData }})
      settemplateModalOpen(false)
      setforceRender(true)
      setaddHomeScreen({
        name: "", status: "inactive", template: { name: "" , imageUrl: ""}
      })
    }
  }

  
  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { type: "link", name: "invoice_number", label: "Name", width: "90%" },
    { type: "string", name: "status", label: "STATUS", width: "15%" },
    { type: "action", name: "action", label: "ACTION", width: "15%" },
  ];

  const getTemplateImage = (name) => {
    name = name?.toLowerCase() ?? ""
    switch (name) {
      case 'template one':
        return Layout1;
      case 'template two':
        return Layout2;
      case 'template three':
        return Layout3;
      case 'template four':
        return Layout4;
      case 'template five':
        return Layout5;
    
      default:
        return Layout1;
    }
  }

  let rows = homeScreens?.length ? homeScreens.map((el, i) => {
    return [
        { name: "textImg", type: "string", primaryText: el.name, src: getTemplateImage(el.template?.name), id: el._id, data: { ...el } },
        { type: "status", status: el.status === "active" ? "success" : "failed", value: el.status, },
      ]
  }) : [];
  // ============================== Table End ======================================//
  // ============================== Style Start ===============================//
  const styles = {
    first: {
      marginBottom: 31,
    },
    second: {
      marginBottom: 111,
    },
    third: {
      marginBottom: 1,
    },
  };
  // ============================== Style END ===============================//
  const style = {
    width: '100%',
  };



  return (
    <MasterLayoutMiddle
      content={
            <Grid container spacing={3} >
                {showAlert && (
                  <div
                    style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                  >
                    <AlertAction
                      alert={showAlert}
                      alertType={'success'}
                      onClick={() => setshowAlert(false)}
                    />
                  </div>
                )}
              <Grid item xs={2} >
                  <Modal 
                     nameModalOpen={nameModalOpen}
                     setnameModalOpen={setnameModalOpen}
                     addHomeScreen={addHomeScreen}
                     setaddHomeScreen={setaddHomeScreen}
                     settemplateModalOpen={settemplateModalOpen}
                  />
              </Grid>

              <Grid item xs={2} >
                  <ChooseTemplate 
                    templateModalOpen={templateModalOpen}
                    settemplateModalOpen={settemplateModalOpen}
                    addHomeScreen={addHomeScreen}
                    setaddHomeScreen={setaddHomeScreen}
                    submitForm={submitForm}
                    setnameModalOpen={setnameModalOpen}
                  />
              </Grid>

              <Grid item xs={12} >
                <AlertDialogue
                  title="Are you sure?"
                  description="Are you sure you want to delete this homescreen?"
                  cancelText="Cancel"
                  confirmText="Confirm"  
                  open={open}
                  handleCancel={() => handleCancel()}
                  handleClose={() => handleClose()}
                  handleConfirm={() => handleConfirm()}
                  styles={style2}
                />
                <div className="table_box">
                    <SimpleTable
                      showHeader={true}
                      title="Home Screen"
                      width="100%"
                      rows={rows}
                      columns={col}
                      colSpan={16}
                      actionSendButton={true}
                      classProps={changeClassProps()}
                      donationsCount={rows && rows.length > 0 ? 1 : 0}
                      noTitle="No homescreen added"
                      noPara="Added homescreen will be displayed and managed here"
                      noBtnText="Add New Homescreen"
                      onInviteClick={() => setnameModalOpen(true)}
                      onAddClick={() => setnameModalOpen(true)}
                      showIconCol={true}
                      editIcon={Edit}
                      editAction={(edit) => props.history.push({ pathname: "/deenkiosk/edit-home-screen", state: { homeScreen: edit[0].data, org: orgData }})}
                      deleteIcon={Delete}
                      deleteAction={(e) => {
                        setOpen(true)
                        sethomeScreenId(e[0].id)
                      }}
                      showFooter={true}
                      showAdd={true}
                      addText="ADD NEW"
                      iconTextSize={16}
                    /> 

                    <div>
                      <p className="service-helper-text">
                        "Active" homepages are available to publish and schedule within device.
                      </p>
                    </div> 
                </div>
              </Grid>
            </Grid>
     
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  );
}
export default HomeScreenListing;