import React, { Component } from "react";
import {
  BaseTextField,
  BaseButton,
  AlertAction,
  SimpleAccordian,
  GridItem,
  MasterLayoutMiddle, FooterInner
} from "@dclab/dc-react-ui-kit";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextEditor from "../../components/TextEditor/TextEditor";
import { getOrganizationData, editOrganization } from "./apis/index";
import "./style.css";
import "../style.css";
import messages_content from '../../utils/messages.json';
import Grid from '@material-ui/core/Grid';
const {
  success_message_add_template
} = messages_content;
class ThankDonorsOLD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateDescription: [{ type: "paragraph", children: [{ text: " " }] }],
      subject: "",
      subjectCount: 0,
      descriptionCount: 0,
      loading: false,
      error: false,
      showAlert: false,
      success: false,
      orgData: {},
      contentLoading: true,
    };
    this.onDescriptionStateChange = this.onDescriptionStateChange.bind(this);
    this.save = this.save.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }
  async componentDidMount() {
    const defaultTemplate = [
      { type: "paragraph", children: [{ text: "{donor_name}," }] },
      {
        type: "paragraph",
        children: [
          {
            text:
              "We just want to send you a quick thank you note for your support. Your generosity will help us to improve our services in serving the community with better and creative ways. ",
          },
        ],
      },
      {
        type: "paragraph",
        children: [{ text: "Greatly appreciate your donations! " }],
      },
      { type: "paragraph", children: [{ text: "" }] },
      { type: "paragraph", children: [{ text: "Thanks - " }] },
      { type: "paragraph", children: [{ text: "{{Org Owner}}" }] },
      {
        type: "paragraph",
        children: [
          { text: "{{Org Name}}" },
        ],
      },
    ];
    const orgId = localStorage.getItem("orgID");
    const token = localStorage.getItem("authToken");

    try {
      const res = await getOrganizationData(orgId, token);
      // const { template } = res.data.body.data;
      const thankYouEmail = res?.data?.body?.data[0]?.enabledIntegrations?.deenfund?.settings?.emailTemplates?.thankYouEmail;
      console.log("thankYouEmail", thankYouEmail)

      let descSum = 1;
      thankYouEmail && thankYouEmail.content
        ? JSON.parse(thankYouEmail.content).map((st) => {
          if (st.type === "bulleted-list" || st.type === "numbered-list") {
            st.children.map((e) => {
              e.children.map((ch) => {
                descSum += ch.text.length;
              });
            });
          } else {
            st.children.map((e) => {
              descSum += e.text.length;
            });
          }
        })
        : defaultTemplate.map((st) => {
          if (st.type === "bulleted-list" || st.type === "numbered-list") {
            st.children.map((e) => {
              e.children.map((ch) => {
                descSum += ch.text.length;
              });
            });
          } else {
            st.children.map((e) => {
              descSum += e.text.length;
            });
          }
        });
      this.setState({
        orgData: res?.data?.body?.data[0]?.enabledIntegrations?.deenfund,
        subject: thankYouEmail?.subject ? thankYouEmail.subject : "Thank you for your generous donation",
        subjectCount: thankYouEmail?.subject ? thankYouEmail.subject.length : "Thank you for your generous donation".length,
        templateDescription: thankYouEmail?.content ? JSON.parse(thankYouEmail?.content) : defaultTemplate,
        descriptionCount: descSum,
        contentLoading: false,
      });

    } catch (error) {
      this.setState({ error: error });
    }
  }
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  save = async () => {
    this.setState({ loading: true });
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    const { templateDescription, subject, descriptionCount } = this.state;
    if (!subject || descriptionCount <= 1) {
      this.setState({ error: true, loading: false });
    } else {
      try {
        const obj = {
          'enabledIntegrations.deenfund.settings.emailTemplates.thankYouEmail': {
            subject: subject,
            content: JSON.stringify(templateDescription),
          }
        };
        // const obj = {
        //   template: {
        //     subject: subject,
        //     content: JSON.stringify(templateDescription),
        //   },
        // };
        await editOrganization(obj, orgId, token);
        localStorage.setItem("subject", subject);
        localStorage.setItem("template", JSON.stringify(templateDescription));
        localStorage.setItem("templateCount", descriptionCount);
        this.setState({ showAlert: true, success: true, loading: false });
        this.hideAlert();
      } catch (error) {
        this.setState({
          error: error && error.response && error.response.data.message,
          showAlert: true,
          loading: false,
        });
        this.hideAlert();
      }
    }
  };
  changeHandler = (name, val) => {
    const { subjectCount } = this.state;
    if (name === "name") {
      if (subjectCount < 39) {
        this.setState({ subject: val, subjectCount: val.length });
      } else {
        this.setState({ subjectCount: val.length });
      }
    }
  };
  onDescriptionStateChange = (editorState) => {
    let sum = 1;
    editorState.map((state) => {
      if (state.type === "bulleted-list" || state.type === "numbered-list") {
        state.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        state.children.map((e) => {
          sum += e.text.length;
        });
      }
    });
    this.setState({
      templateDescription: editorState,
      descriptionCount: sum,
    });
  };
  render() {
    const {
      templateDescription,
      descriptionCount,
      loading,
      error,
      showAlert,
      success,
      subject,
      contentLoading,
    } = this.state;


    const style = {
      width: '100%',
    };



    return (


      <MasterLayoutMiddle

        header={
          <div>

            {showAlert && success && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={success_message_add_template}
                  alertType={"success"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {showAlert && success && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={success_message_add_template}
                  alertType={"success"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {showAlert && error && (
              <div
                style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}
              >
                <AlertAction
                  alert={error}
                  alertType={"error"}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
          </div>
        }



        isForm={true}
        formData={
          <div>
            {contentLoading ? (
              <div
                style={{
                  marginTop: 350,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <Grid container spacing={3}>


                <Grid item xs={12}>
                  <h1 className="title_top">
                    Thank Donors Template
                  </h1>
                  <div class="dasheddBorder"></div>
                </Grid>

                

                <Grid item xs={12}>

                  <p style={{ color: "#4D4D4D", fontSize: 14 }}>
                    Write a thanks message and save it as a template
                  </p>
                </Grid>
                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseTextField
                      label="Subject"
                      value={subject}
                      onChange={(e) => this.changeHandler("name", e.target.value)}
                      error={error && !subject}
                      helperText={error && !subject && "Subject is required"}
                      placeholder=" "
                      count={40}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>

                  <p className="desc">Message</p>
                  <div className="TextEditor">
                    <TextEditor
                      onChange={(e) => this.onDescriptionStateChange(e)}
                      value={templateDescription}
                      shortCount={descriptionCount}
                    />
                  </div>
                  <div className="lowerEditorService">
                    <p className="lowerEditorPara" style={{ color: "red" }}>
                      {error &&
                        (descriptionCount === 1 || descriptionCount === 0) &&
                        "Message is required"}
                    </p>
                    <p className="lowerEditorPara">CHAR {descriptionCount}/300</p>
                  </div>
                </Grid>
                {loading ? (
                  <div
                    style={{
                      marginTop: 30,
                      display: "flex",
                      justifyContent: "center",
                      width: 750,
                    }}
                  >
                    <CircularProgress color="primary" />
                  </div>
                ) : (
                  <Grid item xs={12}>

                    <div className="form_button">
                      <p className="cancel" onClick={() => this.props.history.push("/deenfund/donations/thank-donors")} >CANCEL</p>
                      <BaseButton text="SAVE TEMPLATE" onClick={() => this.save()} />
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
          </div>
        }

        isFooter={true}
        footer={
          <FooterInner
            style={style}
            termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
            privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }

        isWhiteBackground={true}

      />


    );
  }
}
const styles = {
  contents: {
    padding: '24 0',
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: '76%',
    marginTop: 70,
    marginLeft: '20%'
  },
}
export default ThankDonorsOLD;
