import React, { Component } from 'react';
import './Header.css'
class Header extends Component {
    render() {
        return (
            <div className="header">
                <img className="logo_main" alt='Logo' src={require('../img/logo.png')} />
            </div>
        );
    }
}
export default Header;