import React, { useEffect } from "react";
import Moment from "moment";
import MomentTime from "moment-timezone";
import {
  BaseTextField,
  BaseButton,
  BaseStepper,
  BaseSelect,
  TextEditor,
  GridContainer,
  GridItem,
  FormPublishUnpublish,
  BaseImgChip,
  BasePaper,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useState } from "react";
import "./style.css";
import { editOrganization, getAllServices, UpdateService } from "../Organization/api";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
import { getServicesWithAppId } from "./api";
const { token_expired, publish_date_before_unpublish, Your_changes_has_been_saved_as_draft, success_message_services } = messages_content;
function AddService(props) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [visibility, setVisibility] = React.useState("Public");
  const [Loading, setLoading] = React.useState(true);
  const [publish, setPublish] = React.useState("Immediately");
  const [unPublish, setUnPublish] = React.useState("Never");
  const [publishDate, setPublishDate] = React.useState("Immediately");
  const [publishTime, setPublishTime] = React.useState("");
  const [unPublishDate, setUnPublishDate] = React.useState("Never");
  const [unPublishTime, setUnPublishTime] = React.useState("");
  const [unPublishTimestamp, setUnPublishTimestamp] = React.useState(0);
  const [allServices, setAllServices] = React.useState([])
  const [organizationData, setOrganizationData] = React.useState({})
  const [services, setServices] = React.useState([]);
  const [selectService, setSelectService] = React.useState('');
  const [chips, setChips] = React.useState([])
  const [desc, setDesc] = React.useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);
  const [onSelectService, setOnSelectService] = React.useState([]);
  const [UTCDate, setUTCDate] = React.useState('');
  const [UTCTime, setUTCTime] = React.useState('');
  const [UTCDateUnPublish, setUTCDateUnPublish] = React.useState('');
  const [UTCTimeUnPublish, setUTCTimeUnPublish] = React.useState('');
  const [unPublishedDateAndTime, setUnPublishedDateAndTime] = React.useState('');
  const [publishedDateAndTime, setPublishedDateAndTime] = React.useState('');
  const [error, seterror] = useState(false);
  const [validUnPublish, setValidUnPublish] = React.useState(true);
  const [validPublish, setValidPublish] = React.useState(true);
  const [dateError, setDateError] = React.useState(false);
  const [checkedFeatured, setCheckedFeatured] = React.useState(false);
  const [errorService, setErrorService] = React.useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [success, setsuccess] = useState(false);
  // useEffect(() => {
  //   if (unPublishDate < Moment(new Date()).format("MMM D, YYYY")) {
  //     setVisibility("Hidden");
  //   }
  // }, [unPublishDate]);
  useEffect(() => {
    getAllServicesFunction()
  }, [])
  async function getAllServicesFunction() {
    const token = localStorage.getItem('authToken')
    const orgId = localStorage.getItem("orgID");
    let SeriviceList = []

    try{
      const resService = await getServicesWithAppId(token, orgId, 'deenweb')
      SeriviceList = resService.data.body.data
    }catch(err) {
      console.log(err)
      seterror(error.response && error.response.data.message)
      setLoading(false);
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
    try {
      setLoading(true)
      const response = await getAllServices(token);
      const servicesArray = response.data.body.data;
      const Ids = SeriviceList.length ? SeriviceList.map(t => t.service._id) : [];
      const webServices = servicesArray.filter(text => !Ids.includes(text._id))
      setAllServices(servicesArray);
      let name = webServices.map(text => {
        return (
          {
            value: text.name,
            label: text.name,
          }
        )
      })
      setServices(name);
      setLoading(false);
    } catch (error) {
      seterror(error.response && error.response.data.message)
      setLoading(false);
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const getPublishDate = (value) => {
    if(value === "Immediately") {
      setUTCDate('');
      setPublishedDateAndTime('');
      setPublish("Immediately");
      setPublishDate("Immediately")
    } else {
      setValidPublish(false);
      setPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDate(Moment(value).format('YYYY-MM-DD'));
    }
  };
  const getPublishTime = (value) => {
    setPublishTime(Moment(value).format("h:mm A"));
    setUTCTime(Moment(value).format("HH:mm:ss"));
  };
  const getUnPublishDate = (value) => {
    if(value == "Never") {
      setUTCDateUnPublish('');
      setUnPublishedDateAndTime('')
      setUnPublish('Never');
      setUnPublishDate('Never');
    } else {
      setValidUnPublish(false);
      setUnPublishTimestamp(value);
      setUnPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDateUnPublish(Moment(value).format('YYYY-MM-DD'));
    }
  };
  const getUnPublishTime = (value) => {
    setUnPublishTime(Moment(value).format("h:mm A"));
    setUTCTimeUnPublish(Moment(value).format("HH:mm:ss"));
  };
  const onVisibilitySave = () => {
    console.log("visibility saved");
  };
  const onVisibilityCancel = () => {
    console.log("visibility cancelled");
  };
  const onPublishSave = () => {
    let Time = publishTime.split(' ');
    let dated = MomentTime(new Date(publishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = `${dated + 'T' + Hours}:00.00Z`
    setPublishedDateAndTime(date);
  };
  const onPublishCancel = () => {
    console.log("publish date cancel");
  };
  const onUnPublishSave = () => {
    let Time = unPublishTime.split(' ');
    let dated = MomentTime(new Date(unPublishDate)).format('YYYY-MM-DD');
    let split = Time[0].split(':');
    let Hours = ''
    let H;
    if (Time[1] === 'AM') {
      H = parseInt(split[0]);
      Hours = H + ':' + split[1]
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ':' + split[1]
    }
    let date = `${dated + 'T' + Hours}:00.00Z`
    setUnPublishedDateAndTime(date);
  };
  const onUnPublishCancel = () => {
    console.log("unpublish date cancel");
  };
  const convertUTC = (date) => {
    return date.toISOString()
  }
  const onSave = async () => {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem('orgID');
    const data = UTCDate === '' ? new Date() : new Date(`${UTCDate + ' ' + UTCTime}`)
    const dataU = convertUTC(data);
    const dataE = UTCDateUnPublish === '' ? new Date() : new Date(`${UTCDateUnPublish + ' ' + UTCTimeUnPublish}`)
    const UnpublishTime = convertUTC(dataE);
    const obj = {
      website: {
        status: visibility,
        publishFrom: publishedDateAndTime === '' ? publish : dataU,
        publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime,
        featured: checkedFeatured
      }
    }
    const updateOnOrg = {
      "addSelectedArrayData": {
          "enabledIntegrations.deenweb.services.selectedServices": [{
              service: onSelectService[0]._id,
              publishFrom: publishedDateAndTime === '' ? publish : dataU,
              publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime, // DATE OBJECT
              status: visibility,
              featured: checkedFeatured
          }]
      },
    }
    const previousUnpublish = obj.website.publishFrom !== 'Immediately' ? obj.website.publishTo > obj.website.publishFrom : true
    if (obj.website.publishTo === 'Never' || previousUnpublish) {
      try {
        await editOrganization(updateOnOrg, orgId, token);
        const ret = await UpdateService(obj, token, onSelectService[0]._id)
        props.alertShow(success_message_services)
        if (ret.status === 200) {
          props.history.push("/website/services");
        }
        setshowAlert(true);
        setsuccess(true)
      } catch (err) {
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    } else {
      setDateError(true)
      setTimeout(() => {
        setDateError(false)
      }, 8000)
    }
  };
  const onBack = () => {
    setActiveStep(0);
  };
  const handleChange = (prop) => (event) => {
    if (prop === 'services') {
      setSelectService(event.target.value);
      let select = allServices.filter(tex => { return tex.name === event.target.value });
      console.log(select)
      let desc = JSON.parse(select[0].description);
      let ImageChips = [
        {
          key: 0,
          label: select[0].imageMetaData.imageName,
          src: select[0].imageMetaData.imageUrl,
          alt: "",
        },
      ]
      setOnSelectService(select);
      setDesc(desc);
      setChips(ImageChips)
    }
  };
  const onInputClick = () => {
    console.log("input clicked");
  };
  const onChipInputClick = () => {
    console.log("input chip clicked");
  };
  const deleteChip = () => {
    console.log("chip deleted");
  };
  const [value, setValue] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);
  const onChange = (e) => {
    setValue(e);
  };
  const handleNext = () => {
    if (onSelectService.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      setErrorService(true)
    }
  };
  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    props.history.push("/website/services")
  };
  const styles = {
    stepper: {
      marginTop: 0,
      padding: "0px 0px",
      borderRadius: "30px",
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    select: {
      margin: "14px 0",
      minWidth: 120,
      height: 66,
      width: 120,
      border: "#D3D8DA",
      width: "100%",
      marginTop: "31px",
    },
    BaseTextField: {
      width: "100%",
    },
    editor: {
      padding: 16,
    },
    formBody: {
      background: "#fff",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A",
    },
    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
    },
    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
  };

  const getSteps = () => {
    return [
      { number: 1, label: "Select Service" },
      { number: 2, label: "Publish" },
    ];
  }
  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          {
            error && error === "Invalid token" && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            dateError &&
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
              <AlertAction
                alert={publish_date_before_unpublish}
                alertType={"error"}
                onClick={() => setDateError(false)}
              />
            </div>

          }
          {

            showAlert && success && (
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, left: '30%' }}>
                <AlertAction
                  alert={Your_changes_has_been_saved_as_draft}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            )
          }
        </>
      }
      isForm={true}
      formData={
        Loading ?
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
          :
          <div>

            <BaseStepper
              title="Add Service"
              getSteps={getSteps}
              alternativeLabel={true}
              handleBackPreviousPage={() => props.history.push('/website/services')}
              handleBack={() => handleBack()}
              handleNext={() => handleNext()}
              nonLinear={true}
              orientation={"horizontal"}
              btnStyles={styles.stepper}
              activeStep={activeStep}
            />
            {activeStep === 0 && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h2 className="sub_title">Select Service</h2>
                  <Typography className="sub_decrition"> Add “New Service” from Home screen &gt; Manage &gt; Services </Typography>
                </Grid>
                <Grid item md={12} xl={12}>
                  <div className="field_box">
                    <BaseSelect
                      value={selectService}
                      onChange={handleChange('services')}
                      object={services}
                      style={styles.select}
                      helperText={services.length ? "Services are managed at home screen" : <p>Services not found. For create new service click here: <span onClick={() => props.history.push('/organization/services/add-new-service')} className='addLinkTag'> Add Service </span></p>}
                      label="Select Service"
                      error={errorService || services.length ? false : true}
                    />
                  </div>
                </Grid>
                <Grid item md={12}>
                  <TextEditor
                    onChange={(e) => onChange(e)}
                    value={desc}
                    style={styles.editor}
                    disabled={true}
                    label={"Description"}
                    helperText={"Edit this description from Home screen > Organization > Services"}
                  />
                </Grid>
                <Grid item md={12} xl={12}>
                  <div className="field_box">
                    <BaseImgChip
                      chipData={chips}
                      onInputClick={onInputClick}
                      onChipInputClick={onChipInputClick}
                      deleteChip={deleteChip}
                      label="Image"
                      textStyle={{ width: "100%" }}
                    />
                  </div>
                </Grid>
                <Grid item md={12} xl={12}>
                  <div className="form_button">
                    <span className="btnCencel">
                      <BaseButton
                        text="CANCEL"
                        onClick={() => props.history.push("/website/services")}
                      />
                    </span>
                    <BaseButton text="CONTINUE" onClick={() => handleNext()} />
                  </div>
                </Grid>
              </Grid>
            )}
            {activeStep === 1 && (
              <Grid container spacing={3}>
                <Grid item md={12} xl={12}>
                  <div className="field_box">
                    <FormPublishUnpublish
                      visibility={visibility}
                      setVisibility={setVisibility}
                      publish={publish}
                      unPublish={unPublish}
                      publishDate={publishDate}
                      publishTime={publishTime}
                      setPublishDate={setPublishDate}
                      setPublishTime={setPublishTime}
                      getPublishDate={getPublishDate}
                      getPublishTime={getPublishTime}
                      unPublishDate={unPublishDate}
                      unPublishTime={unPublishTime}
                      setUnPublishDate={setUnPublishDate}
                      setUnPublishTime={setUnPublishTime}
                      getUnPublishDate={getUnPublishDate}
                      getUnPublishTime={getUnPublishTime}
                      onVisibilitySave={onVisibilitySave}
                      onVisibilityCancel={onVisibilityCancel}
                      onPublishSave={onPublishSave}
                      onPublishCancel={onPublishCancel}
                      onUnPublishSave={onUnPublishSave}
                      onUnPublishCancel={onUnPublishCancel}
                      onSave={onSave}
                      onBack={onBack}
                      unPublishTimestamp={unPublishTimestamp}
                      minDateUnpublish={publishDate}
                      maxDatepublish={unPublishDate}
                      disabledSave={validUnPublish}
                      disabledSaveP={validPublish}
                      showFeatured={true}
                      featuredText="Feature this on homepage"
                      checked={checkedFeatured}
                      onSetChecked={() => setCheckedFeatured(!checkedFeatured)}
                    />
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
const mapStateToProps = (state) => {
  return {
    organization: state.orgData
  };
};
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(0, mapDispatchToProps)(AddService);
