export const API = "API";
//register
export const SET_REG_USER_INFO = "SET_REG_USER_INFO";
// login
export const SET_USER_INFO = "SET_USER_INFO";
// loaders
export const TOGGLE_LOADER = "TOGGLE_LOADER";
//logout
export const RESET_USER_INFO = "RESET_USER_INFO";

//set all notes
export const SET_ALL_DONORS = "SET_ALL_DONORS";

export const ORG_CODE = "ORG_CODE"

export const SIGN_UP = "SIGN_UP"

export const VERIFY_CODE = "VERIFY_CODE"

export const FORGOT_PASSWORD = "FORGOT_PASSWORD"

export const SIGN_IN = "SIGN_IN"

export const ADD_ORG = "ADD_ORG"

export const ADD_ORG_STRIPE = "ADD_ORG_STRIPE"
export const GET_ORG_DATA = "GET_ORG_DATA"

export const ADD_ROLE = "ADD_ROLE"
export const EDIT_ROLE = "EDIT_ROLE"
export const DELETE_ROLE = "DELETE_ROLE"
export const DELETE_TEAM_MEMBER_ROLE = "DELETE_TEAM_MEMBER_ROLE"
export const EDIT_TEAM_MEMBER_ROLE = "EDIT_TEAM_MEMBER_ROLE"
export const ADD_TEAM_MEMBER_ROLE = "ADD_TEAM_MEMBER_ROLE"
export const EMPTY_TEAM_MEMBER = "EMPTY_TEAM_MEMBER"
export const EDIT_TEAM_MEMBER = "EDIT_TEAM_MEMBER"
export const EMPTY_ROLES = "EMPTY_ROLES"

export const ADD_INQUIRY = "ADD_INQUIRY"

export const ADD_SERVICE = "ADD_SERVICE"
export const EDIT_SERVICE = "EDIT_SERVICE"
export const EMPTY_SERVICE = "EMPTY_SERVICE"

export const EDIT_SALAH = "EDIT_SERVICE"
export const EDIT_REMINDER = "EDIT_REMINDER"
export const EMPTY_REMINDER = "REMINDER"
export const GET_CATEGORIES = "GET_CATEGORIES"

export const EDIT_ANNOUCEMENT = "EDIT_ANNOUCEMENT"
export const EMPTY_ANNOUCEMENT = "EMPTY_ANNOUCEMENT"

export const EDIT_EVENT = "EDIT_EVENT"
export const EMPTY_EVENT = "EDIT_EVENT"
export const SAVE_EMBED_FORM = "SAVE_EMBED_FORM"
export const EMPTY_EMBED_FORM = "EMPTY_EMBED_FORM"

export const SHOW_ALERT = "SHOW_ALERT"
export const HIDE_ALERT = "HIDE_ALERT"


// export const ADD_DONOR = "ADD_DONOR";
// export const REMOVE_DONOR = "REMOVE_DONOR";
// export const UPDATE_DONOR = "UPDATE_DONOR";

export const PAGES_QUICKACCESS = "PAGES_QUICKACCESS"
export const SET_CURRENT_USER_DETAILS = "SET_CURRENT_USER_DETAILS"
export const GET_ORG_DATA_WEB = 'GET_ORG_DATA_WEB'

