import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { SimpleTable, MasterLayoutMiddle, FooterInner, EmptyTable } from "@dclab/dc-react-ui-kit";
import Handle from "../../../assets/img/more_vert-24px.svg";
import { getOrganizationPlan } from "../../UserProfile/Apis/index"
import Edit from '../../../assets/img/create-24px.svg';
import Delete from '../../../assets/img/delete_forever-24px.svg';
import ChevronLeft from '@material-ui/icons/ChevronLeft';


function CommitteesDesignations(props) {
  const [orders, setorders] = useState({})


  useEffect(() => {
    const getDetails = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const orgId = localStorage.getItem("orgID");
        const orgOrders = await getOrganizationPlan(orgId, token);
        console.log(orgOrders?.stripeData, "orgOrders")
        orgOrders && setorders(orgOrders)
      }
      catch (error) {
        console.log(error)
      }
    }
    getDetails()
  }, [])

  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { id: 'drag', label: ' ', width: '15px' }, //its only add when parentTableDrag is true
    { type: "link", name: "invoice_number", label: "Name", width: "50%" },
    { type: "string", name: "name", label: "", width: "10%" },
    { type: "string", name: "name", label: "", width: "70%" },
    { type: "action", name: "action", label: "ACTION", width: "15%" },
  ];
  let rows = orders?.stripeData?.length ? orders.stripeData.map((el, i) => {
    return [
      { type: 'string', name: 'date', value: "Chair" },
      { type: 'string', name: 'date', value: "" },
      { type: 'string', name: 'string', value: "" },
    ]
  }) : [];
  // ===========Table End ===========//

  const style = {
    width: '100%',
  };

  return (
    <MasterLayoutMiddle

      header={
        <>
          <div
            onClick={() =>
              props.history.goBack()
            }
            className='backBtn'>
            <ChevronLeft style={{ alignSelf: 'center', marginLeft: 0 }} />
            <p style={{ alignSelf: 'center' }}>Back</p>{' '}
          </div>


        </>
      }
      content={
        <div style={{ marginTop: '60px' }}>

          <Grid container spacing={3} >

            <Grid item md={12} sm={12} xs={12} >

              <div className="table_box DragTable">
                <SimpleTable
                  showHeader={true}
                  title="Designations"
                  width="100%"
                  rows={rows}
                  columns={col}
                  colSpan={16}
                  actionSendButton={true}
                  parentTableDrag={true}
                  DragIcon={Handle}
                  classProps={changeClassProps()}
                  donationsCount={rows && rows.length > 0 ? 1 : 0}
                  noTitle="No Orders added"
                  noPara="Added Reminders will be displayed and managed here"
                  noBtnText=""
                  onInviteClick={() =>
                    props.history.push("/organization/committees-details")
                  }
                  actionLink={(app) => props.history.push({ pathname: "/organization/committees-details", state: { order: app[0].data } })}
                  showIconCol={true}
                  editIcon={Edit}
                  deleteIcon={Delete}
                  editAction={(e) =>
                    this.props.history.replace(
                      `/organization/services/edit-service/${e[0].label}`
                    )
                  }
                  deleteAction={(e) => this.deleteServiceAction(e)}
                  actionSendButton={false}
                  deleteAction={(e) => console.log(e)}
                  showFooter={true}
                  showAdd={true}
                  addText="ADD NEW"
                  onAddClick={() => this.props.history.push("/organization/add-committee")
                  }
                  iconTextSize={16}
                />

                <EmptyTable
                  title="Designations"
                  noTitle="No Designations added"
                  noPara=""
                  onAddClick={() => this.props.history.push("/organization/add-committee")}
                  addLabel="ADD NEW"
                  noBtnText="ADD NEW"

                />


              </div>

            </Grid>





          </Grid>

        </div>
      }
      isFooter={true}
      footer={
        <FooterInner
          style={style}
          termsLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies"}
          privacyLink={process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={false}
    />
  );
}
export default CommitteesDesignations;