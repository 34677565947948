import React from 'react';
import {
    MasterLayoutMiddle, FooterInner, BasePhoneInput,
    BaseSelect, BaseTextField, BaseButton, FormSelectChip, TextEditor,
}
    from "@dclab/dc-react-ui-kit";

import Grid from '@material-ui/core/Grid';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useState } from 'react';

function EditDetails(props) {

    const style = {
        width: '100%',
    };

    const [values, setValues] = React.useState({
        age: "",
    });

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const object = [
        {
            value: "New York, USA",
            label: "New York, USA",
        },
    ];

    const [isOpen, setOpen] = React.useState(false);
    const [states, setstates] = React.useState(false);
    const [temp, settemp] = React.useState('')
    const [value, setvalue] = React.useState('No specific date')

    const onSelect = (value, states) => {
        settemp(value);
        setstates(states);
    };

    const onToggle = () => {
        setOpen(!isOpen);
    };

    const clear = () => {
        setOpen(!isOpen);
        setvalue('No Specific Date')
        settemp('')

    };

    const cancel = () => {
        settemp('')
        setOpen(!isOpen);

    }
    const save = () => {
        setvalue(temp)
        setOpen(!isOpen);
    }


    const [phone, setPhone] = React.useState({
        country: "",
        number: "",
    });



    const viewChange = (newValue) => {
        console.log(newValue);
    };

    const dataset = [
        { title: "The Shawshank Redemption", year: 1994 },
        { title: "The Godfather", year: 1972 },
        { title: "The Godfather: Part II", year: 1974 },
        { title: "The Dark Knight", year: 2008 },
        { title: "12 Angry Men", year: 1957 },
        { title: "Schindler's List", year: 1993 },
        {
            title: "Pulp Fiction",
            year: 1994,
            src:
                "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcTp_fSHht1Q2lc6pDXJWvMGLnDso0Mz4Yx6o-q-f_eTjb0UltK2&usqp=CAU",
        },
        { title: "The Lord of the Rings: The Return of the King", year: 2003 },
        { title: "The Good, the Bad and the Ugly", year: 1966 },
        { title: "Fight Club", year: 1999 },
        { title: "The Lord of the Rings: The Fellowship of the Ring", year: 2001 },
        { title: "Star Wars: Episode V - The Empire Strikes Back", year: 1980 },
        { title: "Forrest Gump", year: 1994 },
        {
            title: "Inception",
            year: 2010,
            src: "https://img.wallpapersafari.com/desktop/1600/900/80/55/oS4g0R.jpg",
        },
        { title: "The Lord of the Rings: The Two Towers", year: 2002 },
        { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
        { title: "Goodfellas", year: 1990 },
        { title: "The Matrix", year: 1999 },
        { title: "Seven Samurai", year: 1954 },
        { title: "Star Wars: Episode IV - A New Hope", year: 1977 },
        { title: "City of God", year: 2002 },
        { title: "Se7en", year: 1995 },
        { title: "The Silence of the Lambs", year: 1991 },
        { title: "It's a Wonderful Life", year: 1946 },
        { title: "Life Is Beautiful", year: 1997 },
        { title: "The Usual Suspects", year: 1995 },
        { title: "Léon: The Professional", year: 1994 },
        { title: "Spirited Away", year: 2001 },
        { title: "Saving Private Ryan", year: 1998 },
        { title: "Once Upon a Time in the West", year: 1968 },
        { title: "American History X", year: 1998 },
        { title: "Interstellar", year: 2014 },
        { title: "Casablanca", year: 1942 },
        { title: "City Lights", year: 1931 },
        { title: "Psycho", year: 1960 },
        { title: "The Green Mile", year: 1999 },
        { title: "The Intouchables", year: 2011 },
        { title: "Modern Times", year: 1936 },
        { title: "Raiders of the Lost Ark", year: 1981 },
        { title: "Rear Window", year: 1954 },
        { title: "The Pianist", year: 2002 },
        { title: "The Departed", year: 2006 },
        { title: "Terminator 2: Judgment Day", year: 1991 },
        { title: "Back to the Future", year: 1985 },
        { title: "Whiplash", year: 2014 },
        { title: "Gladiator", year: 2000 },
        { title: "Memento", year: 2000 },
        { title: "The Prestige", year: 2006 },
        { title: "The Lion King", year: 1994 },
        { title: "Apocalypse Now", year: 1979 },
        { title: "Alien", year: 1979 },
        { title: "Sunset Boulevard", year: 1950 },
        {
            title:
                "Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb",
            year: 1964,
        },
        { title: "The Great Dictator", year: 1940 },
        { title: "Cinema Paradiso", year: 1988 },
        { title: "The Lives of Others", year: 2006 },
        { title: "Grave of the Fireflies", year: 1988 },
        { title: "Paths of Glory", year: 1957 },
        { title: "Django Unchained", year: 2012 },
        { title: "The Shining", year: 1980 },
        { title: "WALL·E", year: 2008 },
        { title: "American Beauty", year: 1999 },
        { title: "The Dark Knight Rises", year: 2012 },
        { title: "Princess Mononoke", year: 1997 },
        { title: "Aliens", year: 1986 },
        { title: "Oldboy", year: 2003 },
        { title: "Once Upon a Time in America", year: 1984 },
        { title: "Witness for the Prosecution", year: 1957 },
        { title: "Das Boot", year: 1981 },
        { title: "Citizen Kane", year: 1941 },
        { title: "North by Northwest", year: 1959 },
        { title: "Vertigo", year: 1958 },
        { title: "Star Wars: Episode VI - Return of the Jedi", year: 1983 },
        { title: "Reservoir Dogs", year: 1992 },
        { title: "Braveheart", year: 1995 },
        { title: "M", year: 1931 },
        { title: "Requiem for a Dream", year: 2000 },
        { title: "Amélie", year: 2001 },
        { title: "A Clockwork Orange", year: 1971 },
        { title: "Like Stars on Earth", year: 2007 },
        { title: "Taxi Driver", year: 1976 },
        { title: "Lawrence of Arabia", year: 1962 },
        { title: "Double Indemnity", year: 1944 },
        { title: "Eternal Sunshine of the Spotless Mind", year: 2004 },
        { title: "Amadeus", year: 1984 },
        { title: "To Kill a Mockingbird", year: 1962 },
        { title: "Toy Story 3", year: 2010 },
        { title: "Logan", year: 2017 },
        { title: "Full Metal Jacket", year: 1987 },
        { title: "Dangal", year: 2016 },
        { title: "The Sting", year: 1973 },
        { title: "2001: A Space Odyssey", year: 1968 },
        { title: "Singin' in the Rain", year: 1952 },
        { title: "Toy Story", year: 1995 },
        { title: "Bicycle Thieves", year: 1948 },
        { title: "The Kid", year: 1921 },
        { title: "Inglourious Basterds", year: 2009 },
        { title: "Snatch", year: 2000 },
        { title: "3 Idiots", year: 2009 },
        { title: "Monty Python and the Holy Grail", year: 1975 },
    ];


    const [valueEditor, setValueEditor] = useState([{ type: 'paragraph', children: [{ text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry." }] }]);

    const onChange = (e) => {
        setValueEditor(e)
    };

    const styles = {
        border: '1px solid black',
        color: 'gray'
    };


    return (

        <MasterLayoutMiddle

            header={
                <div style={{ width: '26%', alignSelf: 'center' }}>
                    <div
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                        className='backBtn'
                    >
                        <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
                        <p style={{ alignSelf: 'center' }}>Back</p>{' '}
                    </div>
                </div>
            }


            isForm={true}
            formData={
                <>
                    <div>
                        <h1 className='title_top'>Edit Details</h1>
                        <div className="dasheddBorder"></div>
                    </div>


                    <Grid container spacing={3}>

                        <Grid item xs={6}>
                            <div className="field_box">
                                <BaseTextField
                                    label="First Name"
                                // count={100}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={6}>
                            <div className="field_box">
                                <BaseTextField
                                    label="Last Name"
                                // count={100}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseSelect
                                    label="Location to volunteer"
                                    value={values.age}
                                    onChange={handleChange("age")}
                                    object={object}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseSelect
                                    label="Do you also want to become a member?"
                                    value={values.age}
                                    onChange={handleChange("age")}
                                    object={object}
                                />
                            </div>
                        </Grid>


                        <Grid item xs={12}>
                            <div className="field_box chipBox">
                                <FormSelectChip
                                    dataset={dataset}
                                    viewChange={viewChange}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="TextArea">
                                <TextEditor
                                    onChange={(e) => onChange(e)}
                                    disabled={false}
                                    value={valueEditor}
                                    style={styles}
                                    label={"Let us know how else you can help us"}
                                    maxCharCount={300}
                                    placeholder="placeholder"
                                />
                            </div>
                        </Grid>


                        <Grid item xs={12}>
                            <div className="form_button">
                                <p
                                    className='cancel'
                                    onClick={() => this.onCancelOrgDetail()
                                    }
                                >
                                    GO BACK
                                </p>
                                <BaseButton text='SAVE' onClick={() => this.updateOrg()} />
                            </div>

                        </Grid>



                    </Grid>


                </>
            }

            isFooter={true}
            footer={
                <FooterInner style={style} />
            }

            isWhiteBackground={true}

        />

    );
}


export default EditDetails;