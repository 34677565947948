import * as constants from "../constants";

function orgCodeHandler(code) {
    return{
        type:constants.ORG_CODE,
        payload:code
    }

    // return async function (dispatch) {
    //   try {
    //     dispatch({
    //       type: constants.ORG_CODE,
    //       payload: {
    //         code
    //       },
    //     });
    //   } catch (e) {
    //     throw e;
    //   }
    // };
  }
  
  export {
      orgCodeHandler
  }
  