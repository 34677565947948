import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  SimpleAppBar,
  BasePaper,
  NavDrawer,
  BaseIcon,
  MasterLayout,
} from "@dclab/dc-react-ui-kit";
import { Switch, Route } from "react-router-dom";
import { ReactComponent as MoreApps } from "../../assets/nav_icons/moreapps.svg";
import { ReactComponent as Support } from "../../assets/nav_icons/support.svg";
import { ReactComponent as Launch } from "../../assets/nav_icons/launch-24px.svg";
import MainLogo from "../../assets/img/DC_Logo.png";
import { ChevronRight } from "@material-ui/icons";
import Announcements from "./Announcements";
import AddAnnouncement from "./AddAnnouncement";
import EditAnnouncement from './EditAnnouncements'
import ManageSalah from "./ManageSalah";
import AddSalah from './AddSalah'
import EditSalah from './EditSalah'
import Reminders from "./Reminders";
import AddReminders from "./AddReminders";
import EditReminders from "./EditReminders";
import Pages from "./Pages";
import ManagPage from "./ManagPage";
import AdsPromotion from "./AdsPromotion";
import HomePage from "./Home_Page";
import AddHomePageNewsArticles from '../../components/HomePage/NewsArticles/AddNewsArticles'
import AddHomePageEvents from '../../components/HomePage/Events/AddEvents'
import AddHomePageVideo from '../../components/HomePage/Videos/AddVideo'
import AddHomePageServices from '../../components/HomePage/Services/AddService'
import { PagesAction } from "../../redux/actions/pages";
import AddSlider from './AddSlider'
import EditSlider from './EditSlider'
import News_Articles from "./News_Articles";
import AddNewsAndArticles from "./AddNewsAndArticles";
import EditNewsAndArticles from './EditNewsAndArticles'
import Videos from "./Videos";
import AddVideos from './AddVideo'
import EditVideo from './EditVideo'
import Services from './Services'
import AddService from './AddService'
import EditService from './EditService'
import AboutUs from './AboutUs'
import Events from "./Events";
import AddEvents from './AddEvent'
import EditEvent from './EditEvent'
import Faq from './Faq'
import AddFaq from "./AddFaq";
import EditFaq from './EditFaq'
import PrivacyPolicy from './PrivacyPolicy'
import Tos from './ToS'
import Subscription from "./Subscription";
import Site_Menus from "./Site_Menus";
import NavigationMenus from './navigationMenu';
import "../Donations/deen-kioks-website/style.css";
import "../../App.css";
import './style.css'
import AddNavigationMenu from "./addNavigationMenu";
import EditNavigationMenu from "./editNavigationMenu";
import { getOrganizationData } from "../Organization/api";
import { getOrgData } from "../../redux/actions/organizationData";
import SessionExpiredModal from "../../components/SessionExpiredModal";

import PromotionalSlider from "./PromotionalSlider"; 
import AddPromotionalSlider from "./AddPromotionalSlider"; 
import EditPromotionalSlider from "./EditPromotionalSlider"; 
import PhotoGallery from "./PhotoGallery"; 
import AddPhotoGallery from "./AddPhotoGallery"; 
import EditPhotoGallery from "./EditPhotoGallery"; 
import PrivateRoute from "../../components/PrivateRoute";
const headerItems = [
  {
    title: "Announcements",
    path: "/website/announcements",
  },
  {
    title: "Manage Salah",
    path: "/website/manage-salah",
  },
  {
    title: "Reminders",
    path: "/website/reminders",
  },

  {
    title: "Photo Gallery",
    path: "/website/photo-gallery",
  },

  {
    title: "Promotional Slider",
    path: "/website/promotional-slider",
  },

  {
    title: "Pages",
    path: "/website/pages",
  },

  
];
const footerItems = [
  {
    title: "More Apps",
    icon: <BaseIcon icon={MoreApps} />,
    path: "/more-apps",
    child: [],
    secondaryIcon: <BaseIcon icon={ChevronRight} />,
  },
  {
    title: "Support",
    icon: <BaseIcon icon={Support} />,
    path: "http://shapla.deenconnect.io/support",
    child: [],
    secondaryIcon: <BaseIcon icon={Launch} />,
  },
];
const WebSiteHomePage = (props) => {
  const pages = props.pages.pages
  const [bodyItemsArr, setBodyItemsArr] = React.useState([])
  useEffect(() => {
    let pagesList = props?.pages?.pages === undefined ? [] : props?.pages?.pages;
    let data = pagesList.filter(text => text.quickAccess)
    setBodyItemsArr(data);
  }, [pages])
  useEffect(() => {
    const getOrganization = async () => {
      const token = localStorage.getItem('authToken')
      const orgId = localStorage.getItem('orgID')
      const getOraganization = await getOrganizationData(orgId, token);
      const organizationApiData = getOraganization?.data?.body?.data;
      const oraganization_data = organizationApiData.enabledIntegrations?.deenweb?.pages
      let result = Object.entries(oraganization_data).map((e) => ({ [e[0]]: e[1] }));
      let data = []
      result.filter((text) => {
        var resultObj = Object.keys(text).map((key) => text[key]);
        let name = Object.keys(text)
        var arr = resultObj.map((tex, i) => {
          return (
            {
              name: name,
              ...tex
            }
          )
        })
        if (arr[0]) {
          data.push(arr[0])
        }
      })
      setBodyItemsArr(data);
      props.dispatchGetOrgData(organizationApiData);
      props.dispatchPagesAction(data);
    }
    getOrganization()
  }, []);

  const returnActiveTab = () => {
    switch (props?.match?.path) {
      case '/website/edit-announcements/:id':
      case '/website/add-announcements':
          return 'Announcements';
      
      case '/website/add-salah':
      case '/website/edit-salah/:id':
          return 'Manage Salah';

      case '/website/add-reminder':
      case '/website/edit-reminder/:id':
        return 'Reminders';

      case '/website/add-photo-gallery':
      case '/edit-photo-gallery':
        return 'Photo Gallery';

      case '/website/add-promotional-slider':
      case '/website/edit-promotional-slider/:id':
        return 'Promotional Slider';

      case '/website/about-us':
      case '/website/services':
      case '/website/edit-service/:id':
      case '/website/add-services':
      case '/website/videos':
      case '/website/edit-video/:id':
      case '/website/add-video':
      case '/website/news-articles':
      case '/website/edit-news-articles/:id':
      case '/website/add-news-articles':
      case '/website/events':
      case '/website/add-events':
      case '/website/edit-event/:id':
      case '/website/faq':
      case '/website/add-faq':
      case '/website/edit-faq/:id': 
      case '/website/tos':
      case '/website/privacy-policy': 
        return 'Pages';

      case '/website/add-navigation-menu':
      case '/website/edit-navigation-menu/':
          return 'Navigation Menu';
          
      case '/website/edit-slider/': 
      case '/website/add-slider':
      case '/website/homepage/add-news-articles':
      case '/website/homepage/add-new-event':
      case '/website/homepage/add-new-service':
        return 'Home Page';
      
      default:
        return false;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname])
  const bodyItems = {
    QuickAccess: bodyItemsArr.length ? bodyItemsArr.map((text, index) => {
      return (
        {
          title: text.name[0] === 'homePage' ? "Homepage"
            : text.name[0] === "aboutUs" ? 'About Us'
              : text.name[0] === 'services' ? "Services"
                : text.name[0] === 'events' ? "Events"
                  : text.name[0] === 'videos' ? "Videos"
                    : text.name[0] === 'articles' ? "Reads/Views"
                      : text.name[0] === "faqs" ? "FAQs"
                        : text.name[0] === "privacyPolicy" ? 'Privacy Policy'
                          : text.name[0] === "termsOfServices" ? "Terms of Services"
                            : null,
          path: text.quickAccess && text.name[0] === 'homePage' ? "/website/home-page"
            : text.quickAccess && text.name[0] === "aboutUs" ? '/website/about-us'
              : text.quickAccess && text.name[0] === 'services' ? "/website/services"
                : text.quickAccess && text.name[0] === 'events' ? "/website/events"
                  : text.quickAccess && text.name[0] === 'videos' ? "/website/videos"
                    : text.quickAccess && text.name[0] === 'articles' ? "/website/news-articles"
                      : text.quickAccess && text.name[0] === "faqs" ? "/website/faq"
                        : text.quickAccess && text.name[0] === "privacyPolicy" ? '/website/privacy-policy'
                          : text.quickAccess && text.name[0] === "termsOfServices" ? "/website/tos"
                            : null,
          child: []
        }
      )
    })
      : [
        {
          title: 'HomePage',
          path: '/website/home-page',
          child: {}
        }
      ],
    // Setup: [
    //   {
    //     title: "Navigation Menu",
    //     path: "/website/navigation-menu",
    //     child: [],
    //   },
    // ],
  };
  function onClick() {
    props.history.replace("/dashboard");
  }
  const logout = () => {
    localStorage.setItem("user", false);
    localStorage.setItem("authToken", "");
    props.history.replace("/signin");
    localStorage.clear();
  };
  const userProfile = () => {
    props.history.push("/admin-profile")
  };
  const navigate = (x, y) => {
    if (x === 'Support') {
      window.location.href = (y)
    } else {
      props.history.replace(y)
    }
  };
  const getInitials = () => {
    let nameArr = localStorage.getItem("name") || (props?.signup?.fullName ?? '') ;
    nameArr = nameArr.length > 0 ? nameArr?.trim()?.split(" ") : [];
    let initials;
    if (nameArr?.length > 1) {
      initials = nameArr[0][0] + nameArr[1][0];
    } else {
      initials = nameArr?.[0]?.[0] + nameArr[0]?.[1] ?? '';
    } 
    return initials;
  }
  let image = localStorage.getItem("image");
  const content = (
    <React.Fragment>
      <MasterLayout
        header={
          <SimpleAppBar
            src={MainLogo}
            showImg={image && image !== "undefined" ? true : false}
            img={image && image !== "undefined" ? image : ""}
            name={localStorage.getItem("name") || (props?.signup?.fullName ?? '')}
            role={localStorage.getItem("role")}
            onClickLogout={() => logout()}
            initials = {getInitials()}
            style={{ marginTop: 80, }}
            onClickProfile={() => userProfile()}
          />
        }
        sideBar={
          <>
          <NavDrawer
            headerNavItems={headerItems}
            bodyNavItems={bodyItems}
            footerNavItems={footerItems}
            onBackBtnClick={() => onClick()}
            onClick={(e) => console.log("working")}
            buttonShow={true}
            //activeOnTitle={true}
            showBodyItem={true}
            showTitle={true}
            navigate={(x, y) => navigate(x, y)}
            selectedTitle={localStorage.getItem("pageTitle")}
            footerLower="footerLower"
            activeTab = {returnActiveTab()}
          />
          <SessionExpiredModal />
          </>
        }
        headerFixed={true}
        middleContent={
          <Switch>
            <PrivateRoute
              exact
              path="/website/announcements"
              component={Announcements}
            />
            <PrivateRoute
              exact
              path="/website/add-announcements"
              component={AddAnnouncement}
            />
            <PrivateRoute
              exact
              path="/website/edit-announcements/:id"
              component={EditAnnouncement}
            />
            <PrivateRoute exact path="/website/manage-salah" component={ManageSalah} />
            <PrivateRoute exact path="/website/add-salah" component={AddSalah} />
            <PrivateRoute exact path="/website/edit-salah/:id" component={EditSalah} />
            <PrivateRoute exact path="/website/reminders" component={Reminders} />
            <PrivateRoute
              exact
              path="/website/add-reminder"
              component={AddReminders}
            />
            <PrivateRoute
              exact
              path="/website/edit-reminder/:id"
              component={EditReminders}
            />
            <PrivateRoute exact path="/website/pages" component={Pages} />
            <PrivateRoute exact path="/Website/Manage-Page" component={ManagPage} />
            <PrivateRoute
              exact
              path="/Website/AdsPromotion"
              component={AdsPromotion}
            />
            <PrivateRoute exact path="/website/home-page" component={HomePage} />
            <PrivateRoute exact path="/website/add-slider" component={AddSlider} />
            <PrivateRoute exact path="/website/edit-slider/:id" component={EditSlider} />
            <PrivateRoute exact path="/website/homepage/add-news-articles" component={AddHomePageNewsArticles} />
            <PrivateRoute exact path="/website/homepage/add-new-event" component={AddHomePageEvents} />
            <PrivateRoute exact path="/website/homepage/add-new-video" component={AddHomePageVideo} />
            <PrivateRoute exact path="/website/homepage/add-new-service" component={AddHomePageServices} />
            <PrivateRoute
              exact
              path="/website/news-articles"
              component={News_Articles}
            />
            <PrivateRoute
              exact
              path="/website/add-news-articles"
              component={AddNewsAndArticles}
            />
            <PrivateRoute
              exact
              path="/website/edit-news-articles/:id"
              component={EditNewsAndArticles}
            />
            <PrivateRoute exact path="/website/videos" component={Videos} />
            <PrivateRoute exact path="/website/add-video" component={AddVideos} />
            <PrivateRoute exact path="/website/edit-video/:id" component={EditVideo} />
            <PrivateRoute exact path="/website/events" component={Events} />
            <PrivateRoute exact path="/website/add-events" component={AddEvents} />
            <PrivateRoute exact path="/website/edit-event/:id" component={EditEvent} />
            <PrivateRoute exact path="/website/services" component={Services} />
            <PrivateRoute exact path="/website/add-services" component={AddService} />
            <PrivateRoute exact path="/website/edit-service/:id" component={EditService} />
            <PrivateRoute exact path="/website/about-us" component={AboutUs} />
            <PrivateRoute exact path="/website/faq" component={Faq} />
            <PrivateRoute exact path="/website/add-faq" component={AddFaq} />
            <PrivateRoute exact path="/website/edit-faq/:id" component={EditFaq} />
            <PrivateRoute exact path="/website/privacy-policy" component={PrivacyPolicy} />
            <PrivateRoute exact path="/website/tos" component={Tos} />
            <PrivateRoute exact path="/website/promotional-slider" component={PromotionalSlider} />
            <PrivateRoute exact path="/website/add-promotional-slider" component={AddPromotionalSlider} />
            <PrivateRoute exact path="/website/edit-promotional-slider/:id" component={EditPromotionalSlider} />
            <PrivateRoute exact path="/website/photo-gallery" component={PhotoGallery} />
            <PrivateRoute exact path="/website/add-photo-gallery" component={AddPhotoGallery} />
            <PrivateRoute exact path="/website/edit-photo-gallery/:id" component={EditPhotoGallery} />

            <PrivateRoute
              exact
              path="/Website/Subscription"
              component={Subscription}
            />
            <PrivateRoute exact path="/Website/Site_Menus" component={Site_Menus} />
            <PrivateRoute exact path="/website/navigation-menu" component={NavigationMenus} />
            <PrivateRoute exact path="/website/add-navigation-menu" component={AddNavigationMenu} />
            <PrivateRoute exact path="/website/edit-navigation-menu/:id" component={EditNavigationMenu} />
          </Switch>
        }
      />
    </React.Fragment>
  );
  return (
    <>
      <BasePaper
        elevation={0}
        context={content}
        paperStyle={styles.paperStyle}
      />
    </>
  );
};

const styles = {
  conatiner: {
    display: "flex",
    background: "#FFF",
  },
  navBar: {
    backgroundImage: "linear-gradient(#2490F9, #7266FB)",
    color: "white",
    height: "105vh",
    marginTop: 75,
    zIndex: 200
  },
  contents: {
    padding: 24,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    background: "#FFF",
    width: "76%",
    marginTop: 70,
    marginLeft: "280px",
  },
  paperStyle: {
    width: "100%",
  },
  header: {
    boxShadow: "0px 1px 4px #0000001A",
    borderBottom: "1px solid #0000001A",
    position: "fixed",
    width: "100%",
    zIndex: 999,
  },
};
const mapStateToProps = (state) => {
  return {
    pages: state.pages,
    organization: state.orgData
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatchPagesAction: (data) => dispatch(PagesAction(data)),
  dispatchGetOrgData: (data) => dispatch(getOrgData(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(WebSiteHomePage);
