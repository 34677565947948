import React, { useEffect, useState } from 'react'
import { MasterLayoutMiddle, FooterInner, SimpleTable,  CardDetailsTable, Initials, CardStatus } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/core/styles';
import { stateAbbreviations } from '../../../utils/statesAbbreviations';


function DonorDetails(props) {
  const classes = useStyles();
  const [donorDetails, setdonorDetails] = useState({})
  const [donors, setdonors] = useState([])
  console.log(donorDetails, donors)

  useEffect(() => {
      props.location.state?.donorDetails && setdonorDetails(props.location.state.donorDetails)
      props.location.state?.donors?.length && setdonors(props.location.state.donors)
  }, [])

  const capitalizeFLetter = (val) => {  
    return val ? `${val[0].toUpperCase() + val.slice(1)}` : ""; 
  } 

  const style = {
    width: '100%',
  };
 
  let header = {
    avatar: <Initials name={donorDetails?.fullName} />,
    tableName: donorDetails?.fullName ?? ""
  };

  let data = [
    {
      field: "Phone",
      value: donorDetails?.phoneNo ? '+' + donorDetails?.phoneNo  :  ""
    },
    {
      field: "Email",
      value: donorDetails?.email ?? "<span style = 'color: gray'>No Data</span>"
    }, 
  ];


  let address = donorDetails?.billing_details?.address ?? {};
  let data2 = [
    {
      field: "Location",
      value: `${address?.city ? capitalizeFLetter(address?.city) + "," : ""} ${address?.state
        ? stateAbbreviations[address?.state.replace(/\b\w/g, c => c.toUpperCase())] ? stateAbbreviations[address?.state.replace(/\b\w/g, c => c.toUpperCase())] : capitalizeFLetter(address?.state) ?? '' : address?.state ?? ''} ${address?.postal_code ?? ""}`
    },
  ];

// ============================== Table Start ======================================//
let classProps = () => {
  const tempArray = [];
  col.forEach((column, index) => {
    tempArray.push(`class${index}`);
  });
  return tempArray;
};

const changeClassProps = () => {
  const tempArray = classProps();
  tempArray[0] = "class15";
  return tempArray;
};

let col = [
  { type: "number", name: "serial_number", label: "NO.", width: "auto" },
  { type: "string", name: "name", label: "Receipt", width: "15%" },
  { type: "string", name: "name", label: "Date", width: "15%" },
  { type: "string", name: "name", label: "Amount", width: "15%" },
  { type: "string", name: "name", label: "category", width: "15%" },
  { type: "string", name: "name", label: "frequency", width: "15%" },
  { type: "status", name: "status", label: "STATUS", width: "40%" },
  { id: "ACTION", label: "ACTION", width: "15%" },
];

let rows = donors.map((donor, i) => {
  return [
    { 
      type: "id", 
      value: i 
    },
    {
      type: "link",
      name: "invoice_number",
      value: donor.receipt_number ?  donor.receipt_number.replace("#", "") : "" || "N/A",
      url: donor.receipt_number ? donor.receipt_url : null,
    },
    { 
      type: "string",
      name: "date",
      value: donor.createdAt ? donor.createdAt.replace(",", ", ") : "" || "N/A",
      email: donor.email,
    },
    { 
      type: "string",
      name: "name",
      value: donor.amount ? "$" + (donor.amount / 100).toFixed(2) : "" || "N/A",
      donation_date: donor.createdAt ? donor.createdAt.replace(",", ", ") : "",
    },
    {
      type: "string",
      name: "category",
      value: donor.category || "N/A",
      note: donor.memoNote,
      processingFee: donor.processingFee,
      address: donor.billing_details.address,
    },
    // { type: "string", name: "frequency", value:  donor.category ?? "" },
    { 
      type: "string",
      name: "category",
      value:
      donor.frequency[0].toUpperCase() + donor.frequency.slice(1) ||
              "N/A",
    },
    { 
      type: "status",
      status: donor.status.toLowerCase() === "succeeded" ? "success" : "failed",
      value: donor.status.toLowerCase() === "succeeded" ? "Success": donor.status[0].toUpperCase() + donor.status.slice(1), 
    },
    { 
      type: "action", 
      status: "details", 
      value: "see details" 
    },
  ]
});
// ============================== Table End ======================================//
const details = (data) => {
  const obj = {
    receiptNumber: data[1]?.value,
    receiptUrl: data[1]?.url,
    name: donorDetails?.fullName ?? "",
    amount: data[3]?.value,
    frequency: data[5]?.value,
    category: data[4]?.value,
    status: data[6]?.value,
    stream: data[1]?.stream,
    email: donorDetails?.email ?? "",
    donation_date: data[3]?.donation_date,
    note: data[4]?.note,
    processingFee: data[4]?.processingFee,
    address: data[4]?.address,
  };
  localStorage.setItem("Donation Details", JSON.stringify(obj));
  props.history.replace("/deenfund/donations/details");
  
}
  return (
    <div>

      <MasterLayoutMiddle

        header={
          <div
              onClick={() => props.history.goBack()}
              className='backBtn'>
              <ChevronLeft style={{ alignSelf: 'center', marginLeft: 7 }} />
              <p style={{ alignSelf: 'center' }}>Back</p>{' '}
            </div>
        }
        content={

          <div className={classes.Container}>

            <Grid container spacing={4}>

              <Grid item xs={12} className="withoutFooter">
                <CardDetailsTable
                  data={data}
                  data2={data2}
                  twoColumns
                  header={header}
                />
              </Grid>

              <Grid item xs={12} >
                <div className="table_box">
                  <SimpleTable
                    showHeader={true}
                    title="Donation History"
                    width="100%"
                    rows={rows}
                    columns={col}
                    colSpan={16}
                    actionSendButton={true}
                    classProps={changeClassProps()}
                    donationsCount={rows?.length ? 1 : 0}
                    noTitle="No Donation added"
                    noPara="Added Donation will be displayed and managed here"
                    noBtnText="Add New Donation"
                    onInviteClick={() =>
                      this.props.history.push(
                        "/add-new-campaign"
                      )
                    }
                    actionLink={(name) => details(name)}
                    showIconCol={false}
                    actionSendButton={true}
                    deleteAction={(e) => this.deleteServiceAction(e)}
                    showFooter={true}
                    showAdd={false}
                    addText="ADD NEW"
                    onAddClick={() =>
                      this.props.history.push(
                        "/add-new-campaign"
                      )
                    }
                    iconTextSize={16}
                  />
                </div>
              </Grid>

            </Grid>

          </div>
        }


        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }

        isWhiteBackground={false}


      />




    </div>
  )
}




export default DonorDetails;


const useStyles = makeStyles((theme) => ({

  Container: {
    marginTop:"60px",
  },

}));