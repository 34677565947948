import React, { Component } from "react";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Done from '../../assets/nav_icons/Done.svg'
import { verifyDevice, updateDeviceName } from './Apis/index'
import {
    BaseTextField,
    BaseButton,
    VerificationSuccess,
    BaseOtpInput,
    GridContainer,
    GridItem,
    MasterLayoutMiddle, FooterInner
} from "@dclab/dc-react-ui-kit";
import "./style.css";
class Onboarding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otp: "",
            addNewDevice: false,
            focused: true,
            deviceName: '',
            deviceId: '',
            isVerificationError: false,
            showDeviceError: false,
        };
    }
    componentDidMount() {
        const deviceId = localStorage.getItem('deviceId')
        this.setState({ deviceId: deviceId })
    }
    setOtp = (val) => {
        this.setState({ otp: val });
    };
    verifyOtp = async () => {
        const { otp, deviceId } = this.state;
        const token = localStorage.getItem('authToken')
        const verificationResponce = await verifyDevice(otp, deviceId, token)
        if (verificationResponce.data === false) {
            this.setState({ isVerificationError: true })
        }
        else {
            this.setState({ addNewDevice: true })
        }
    };
    addDeviceName = async () => {
        const { deviceName, deviceId } = this.state;
        if (!deviceName) {
            this.setState({ showDeviceError: true })
        }
        else {
            const token = localStorage.getItem('authToken')
            const updatedResponse = await updateDeviceName(deviceName, deviceId, token)
            if (updatedResponse?.data?.message === 'Device Verified And Name Updated') {
                this.props.history.push("/deenkiosk/devices/manage-devices")
            }
        }
    }
    render() {
        const { otp, addNewDevice, focused, deviceName, deviceId, isVerificationError, showDeviceError } = this.state;
        const letters = /^[A-Za-z\s]*$/;
        const style = {
            width: '100%',
        };
        return (
            <div>
                <MasterLayoutMiddle
                    header={
                        <div>
                            <div
                                onClick={() => this.props.history.push("/deenkiosk/devices")}
                                className="backBtn" style={{ marginTop: 0 }} >
                                <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                                <p style={{ alignSelf: "center" }}>Back</p>{" "}
                            </div>
                        </div>
                    }
                    content={
                        <div>
                            {addNewDevice ?
                                <section>
                                    <div className='verificationBox'>
                                        <VerificationSuccess
                                            name="verifySuccess"
                                            img={Done}
                                            title="Verification Successful"
                                            para={[
                                                "Give your new device a name below. The name is only visible to you. Name examples - Main Lobby, Main Prayer Space, Sister’s Floor",
                                            ]}
                                            btn={false}
                                            loader={false}
                                            header={false}
                                            onLoadingComplete={() => console.log()}
                                        />
                                    </div>
                                    <div className='deviceNameTextBox'>
                                        <p className="roboto-light">Give your new device a name</p>
                                        {focused && !deviceName.length && <div className='autoFocusedText'>Enter device name</div>}
                                        <div className='deviceTextbox'>
                                            <BaseTextField
                                                label="Enter device name"
                                                value={deviceName}
                                                style={{ width: 570, height: 50, marginTop: -16 }}
                                                helperText=" "
                                                onFocus={() => this.setState({ focused: false })}
                                                onBlur={() => this.setState({ focused: true })}
                                                onChange={(e) => {
                                                    if (e.target.value.length <= 20 && e.target.value.match(letters)) {
                                                        this.setState({ deviceName: e.target.value, showDeviceError: false })
                                                    }
                                                }}
                                                placeholder=""
                                                InputLabelProps={{ shrink: false }}
                                                error={showDeviceError}
                                            />
                                        </div>
                                    </div>
                                    <div className='char'>
                                        {!showDeviceError && <p className="roboto-light">CHAR {deviceName.length}/20</p>}
                                        {showDeviceError && <p className="roboto-light" style={{ color: '#f44336', fontWeight: 'bold' }}>*Required</p>}
                                    </div>
                                    <div className='verificationBtn' style={{ marginTop: "38px" }}>
                                        <BaseButton text="SAVE" onClick={() => this.addDeviceName()} />
                                    </div>
                                </section>
                                :
                                <section>
                                    <GridContainer>
                                        <div>
                                            <h1 className='title_top'>Device ID - {deviceId}</h1>
                                        </div>
                                    </GridContainer>

                                    <GridContainer spacing={0} containerClass="grid-add-cat">
                                        <GridItem itemStyle={{ marginTop: 30 }} itemClass="grid-item">
                                            <div
                                                style={{
                                                    borderTop: "1px dashed #E4E8EC",
                                                    width: 1030,
                                                    paddingTop: 30,
                                                    margin: "0px auto",
                                                }}
                                            >
                                                <p
                                                    className="roboto-light"
                                                    style={{
                                                        margin: 0,
                                                        color: "#4d4d4d",
                                                        fontSize: 22,
                                                        fontWeight: 300,
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    Enter The Code From Your Device</p>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer spacing={0} containerClass="grid-add-cat">
                                        <GridItem
                                            itemStyle={{ margin: "0px auto", marginTop: 44 }}
                                            itemClass="grid-item"
                                        >
                                            <div className="input-opt-box">
                                                <BaseOtpInput
                                                    forverification={true}
                                                    value={otp}
                                                    onChange={(char) => this.setOtp(char)}
                                                    hasErrored={isVerificationError}
                                                    numInputs={6}
                                                    errorStyle={{
                                                        border: "1px solid #B00020",
                                                    }}
                                                />
                                            </div>
                                        </GridItem>
                                        <GridItem
                                            itemStyle={{ margin: "0px auto", marginTop: 25 }}
                                            itemClass="grid-item"
                                        >
                                            <div className="opt-buttons">
                                                <p
                                                    className="cancel roboto-medium"
                                                    onClick={() => this.props.history.goBack()}
                                                >
                                                    CANCEL</p>
                                                <BaseButton text="CONFIRM" onClick={() => this.verifyOtp()} />
                                            </div>
                                        </GridItem>
                                        <GridItem
                                            itemStyle={{ margin: "0px auto", marginTop: 10 }}
                                            itemClass="grid-item"
                                        > <div className='updradePara'>
                                                <p className="roboto-light">0 out of 3 devices are being used.</p>
                                                <p className="roboto-light">To add more than 3 devices, please upgrade your DEENKIOSK plan.</p>
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                </section>
                            }
                        </div>
                    }
                    isFooter={true}
                    footer={
                        <FooterInner 
                            style={style} 
                            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
                            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                        />
                    }
                    isWhiteBackground={true}
                />
            </div>
        )
    }
}

export default Onboarding;
