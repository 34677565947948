import React , {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {decrypt} from '../utils/crypto';


let routesPrefix = '/';

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
	let verify = false;
	let accountRevoked = false
	const token = localStorage.getItem("authToken");
	let access = localStorage.getItem("userAccess") ;
	if(access) {
		access = decrypt(access);
	}

		
	if(token) {
		verify = true;
	} else {
		localStorage.setItem('oldUrl', window.location.href);
	}
	if(access && access?.toLowerCase() !== "active") {
		accountRevoked = true;
	}

	return( <Route

		{...rest}
		render={
			props => verify && !accountRevoked ? (
				<Component {...props} />
			) : (
				<Redirect to={accountRevoked ? routesPrefix+"access-revoked" : routesPrefix+"signin"} />
			)
		}
	/>
	)
}

PrivateRoute.propTypes = {
	auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	auth: state.signin
});

export default connect(mapStateToProps)(PrivateRoute);