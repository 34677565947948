import axios from "axios";
const baserUrl =
    "https://cors-anywhere.herokuapp.com/http://18.211.207.245:3000";
const env = {
    prod: process.env.REACT_APP_PAYMENT_PROD_API,
  dev: process.env.REACT_APP_PAYMENT_DEV_API
};
const orgEnv = {
    prod: process.env.REACT_APP_BASEURL_PROD_API,
    dev: process.env.REACT_APP_BASEURL_DEV_API
};
let currentEnv;
if (
    window.location.host.includes("amazon") ||
    window.location.host.includes("localhost") ||
    window.location.host.includes("shapla")
) {
    currentEnv = "dev";
} else {
    currentEnv = "prod";
}
const orgBaseUrl = orgEnv[currentEnv];

export function getAllOrganizationDevices(orgId, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/digital-device/getAssignedDevices/${orgId}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function verifyDevice(verificationCode, deviceId, token) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${orgBaseUrl}/digital-device/verifyDevice`, {
                verification_code: verificationCode,
                deviceId: deviceId
            }, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}


export function updateDeviceName(deviceName, deviceId, token) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${orgBaseUrl}/digital-device/updateDeviceName`, {
                name: deviceName,
                deviceId: deviceId
            }, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function updateDeviceById(data, deviceId, token) {
    return new Promise((resolve, reject) => {
        axios
            .put(`${orgBaseUrl}/digital-device/${deviceId}`, {...data}, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function updateDeviceStatus(status, deviceId, token) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${orgBaseUrl}/digital-device/updateDeviceStatus`, {
                status: status,
                deviceId: deviceId
            }, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function updatePlaylist(data, token) {
    return new Promise((resolve, reject) => {
        axios
            .put(`${orgBaseUrl}/digital-device/addPlaylistToDevice`, data, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function getAllPlaylists(token,orgId) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/digital-device/getAllPlaylists?orgId=${orgId}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function getVerifiedDeviceDetails(deviceId, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/digital-device-bridge/isDeviceVerified?deviceId=${deviceId}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export function getAllPlaylist(deviceId, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/digital-device/getAllPlaylists?deviceId=${deviceId}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function deleteSchedulefromDevice(deviceId, scheduleId, token) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${orgBaseUrl}/digital-device/deleteScheduleById/${scheduleId}?deviceId=${deviceId}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}


export function deletePlaylistFromDevice(deviceId, playlistId,token) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${orgBaseUrl}/digital-device/deletePlaylistFromDevice/${deviceId}?playlistId=${playlistId}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function addNewHomeScreen(data, token) {
    return new Promise((resolve, reject) => {
        axios
            .post(`${orgBaseUrl}/digital-device/addHomeScreen`, data, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function updateHomeScreen(id, data, token) {
    return new Promise((resolve, reject) => {
        axios
            .put(`${orgBaseUrl}/digital-device/updateHomeScreen/${id}`, data, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function updateHomeScreenPreview(id, orgId, data, token) {
    return new Promise((resolve, reject) => {
        axios
            .put(`${orgBaseUrl}/digital-device/updateHomeScreenPreview/${id}?orgId=${orgId}`, data, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function getHomeScreens(orgId, token) {
    return new Promise((resolve, reject) => {
        axios
            .get(`${orgBaseUrl}/digital-device/getHomeScreens/${orgId}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function deleteHomeScreen(id, token) {
    return new Promise((resolve, reject) => {
        axios
            .delete(`${orgBaseUrl}/digital-device/deleteHomeScreen/${id}`, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function addScheduleToDevice(data, token) {
    return new Promise((resolve, reject) => {
        axios
            .put(`${orgBaseUrl}/digital-device/addScheduleToDevice`, data, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function updateSchedule(id, deviceId, data, token) {
    return new Promise((resolve, reject) => {
        axios
            .put(`${orgBaseUrl}/digital-device/updateSchedule/${id}?deviceId=${deviceId}`, data, {
                headers: {
                    token
                },
            })
            .then((res) => {
                resolve(res)
            })
            .catch((error) => {
                reject(error)
            })
    })
}