import React from "react";
import escapeHtml from 'escape-html'
import { Node, Text } from 'slate'
// import {Html} from 'slate-html-serializer'


function markdowntoreact(el) {
  return el.map((e) => {
    switch (e.type) {
      case "block-quote":
        return (
          <span>
            {e.children.map((em) => {
              if (e.hasOwnProperty("italic")) {
                return <em>{em.text}</em>;
              }
              if (e.hasOwnProperty("bold")) {
                return <strong>{em.text}</strong>;
              } else {
                return em.text;
              }
            })}
          </span>
        );
      case "paragraph":
        return (
          <span>
            {e.children.map((em) => {
              if (e.hasOwnProperty("italic")) {
                return <em>{em.text}</em>;
              }
              if (e.hasOwnProperty("bold")) {
                return <strong>{em.text}</strong>;
              } else {
                return em.text;
              }
            })}
          </span>
        );
      case "heading-one":
        return (
          <span>
            {e.children.map((em) => {
              if (e.hasOwnProperty("italic")) {
                return <em>{em.text}</em>;
              }
              if (e.hasOwnProperty("bold")) {
                return <strong>{em.text}</strong>;
              } else {
                return em.text;
              }
            })}
          </span>
        );
      case "heading-two":
        return (
          <span>
            {e.children.map((em) => {
              if (e.hasOwnProperty("italic")) {
                return <em>{em.text}</em>;
              }
              if (e.hasOwnProperty("bold")) {
                return <strong>{em.text}</strong>;
              } else {
                return em.text;
              }
            })}
          </span>
        );
      default:
        return null;
    }
  });
}

function markdowntoPlainHtml(el) {
  return el.map((e) => {
    switch (e.type) {
      case "block-quote":
        return (
          <span>
            {e.children.map((em) => {
              if (e.hasOwnProperty("italic")) {
                return em.text;
              }
              if (e.hasOwnProperty("bold")) {
                return em.text;
              } else {
                return em.text;
              }
            })}
          </span>
        );
      case "paragraph":
        let str = "";
        let str2 = e.children.map((em) => {
          if (em.hasOwnProperty("bold")) {
            let strB = em.text;
            str += strB;
            return str;
          }
          if (em.hasOwnProperty('italic')) {
            str += em.text
            return str
          }
          return (str += em.text);
        });
        return (
          <span dangerouslySetInnerHTML={{ __html: str2[str2.length - 1] }} />
        );
      case "heading-one":
        return (
          <span>
            {e.children.map((em) => {
              if (e.hasOwnProperty("italic")) {
                return em.text;
              }
              if (e.hasOwnProperty("bold")) {
                return em.text;
              } else {
                return em.text;
              }
            })}
          </span>
        );
      case "heading-two":
        return (
          <span>
            {e.children.map((em) => {
              if (e.hasOwnProperty("italic")) {
                return em.text;
              }
              if (e.hasOwnProperty("bold")) {
                return em.text;
              } else {
                return em.text;
              }
            })}
          </span>
        );
      default:
        return null;
    }
  });
}

const serialize = node => {
  console.log("serializeHtml", node)

  if (Text.isText(node)) {
    return escapeHtml(node.text)
  }


  const children = node.children.map(n => serialize(n)).join('')

  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    default:
      return children
  }
}

const serialize2 = node => {
  // console.log("serializeHtml", node)
  if (Text.isText(node)) {
    let string = escapeHtml(node.text)

    if (node.bold) {
      string = `<strong>${string}</strong>`
    } else if(node.italic) {
      string = `<em>${string}</em>`
    } else if(node.underline) {
      string = `<u>${string}</u>`
    }

    return string
    // return escapeHtml(node.text)
  }

  const children = node.children.map(n => serialize(n)).join('')

  switch (node.type) {
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'list-item':
      return `<li>${children}</li>`
    case 'heading-one':
      return `<h5>${children}</h5>`
    case 'heading-two':
      return `<h2>${children}</h2>`
    default:
      return children
  }
}


export { markdowntoreact, markdowntoPlainHtml, serialize, serialize2 };