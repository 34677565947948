import React, { useEffect, useState } from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  ColorPickerButton,
  DropDown, TextInputFixedLabel,
} from "@dclab/dc-react-ui-kit";

export default function ButtonCard(props) {
    const {buttonCard , setbuttonCard, classes} = props;
    const palette = {
        red: '#ff0000',
        blue: '#0000ff',
        green: '#00ff00',
        yellow: 'yellow',
        cyan: 'cyan',
        lime: 'lime',
        gray: 'gray',
        orange: 'orange',
        purple: 'purple',
        black: 'black',
        white: 'white',
        pink: 'pink',
        darkblue: 'darkblue',
      };

      const Pages = [
        { value: "/about", select: "About Us Page" },
        { value: "/services", select: "Services Page" },
        { value: "/contact", select: "Contact Us Page" },
        { value: "/announcements", select: "Announcement Page" },
        { value: "/salah", select: "Salah Page" },
        { value: "/events", select: "Events Page" },
        { value: "/donation", select: "Donation Form" },
        { value: "/subscription", select: "Subscription Form" },
      ];

      
    return (
        <Grid container spacing={3}>

        <Grid item xs={6}>
          <TextInputFixedLabel
            label="Button Label"
            labelStatus={true}
            placeholder=""
            Errormessage="Error message"
            errortextStatus={false}
            countStatus={false}
            tooltipStatus={false}
            onChange={(e) => setbuttonCard({...buttonCard, label: e.target.value})}
            value={buttonCard?.label ?? ""}

          />
        </Grid>

        <Grid item xs={6}>
          <DropDown
            label="Navigates To"
            labelStatus={true}
            selectdata={Pages}
            defaultValue={buttonCard?.navigatesTo ? buttonCard?.navigatesTo  : "/about-us"}
            handleChange={(e) => setbuttonCard({...buttonCard, navigatesTo: e.target.value})}
          />
        </Grid>

        <Grid item xs={6}>
          <ColorPickerButton
            label="Button Background Color"
            getColor={(color) => setbuttonCard({...buttonCard, backgroundColor: color})}
            defaultColor={buttonCard?.backgroundColor ? buttonCard?.backgroundColor : "#CCCCCC"}
            style={{ width: '100%' }}
            palette={palette}
          />
        </Grid>

        <Grid item xs={6}>
          <ColorPickerButton
            label="Button Text Color"
            getColor={(color) => setbuttonCard({...buttonCard, color: color})}
            defaultColor={buttonCard?.color ? buttonCard?.color : "#FFFFFF"}
            style={{ width: '100%' }}
            palette={palette}
          />
        </Grid>

        <Grid item xs={6}>
          <div className={classes.PreviewLebel}>Preview</div>
          <div className={classes.Preview} style={{ background: buttonCard?.backgroundColor ?? "#CCCCCC", color: buttonCard?.color ?? "#FFFFFF" }}>About us</div>
        </Grid>
      </Grid>

    )
}
