import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import { MasterLayoutMiddle, FooterInner,} from "@dclab/dc-react-ui-kit";
import { Link } from 'react-router-dom';
import dfLogo from '../../assets/img/DF_Logo_Large.png'
import './style.css';

function dfconnectStripe(props) {


  const style = {
    width: '100%',
  };
  return (
    <MasterLayoutMiddle
      content={
        <div className="DfStripConnectContainer">
          <Grid container spacing={3}>

                    <Grid item xs={12}>
                      <img className="DFLogo" src={dfLogo} alt="DEENFUND" />
                    </Grid>
                    <Grid item xs={12}>
                      <h1 className="connectTitle">Connect to Stripe</h1>
                    </Grid>

                    <Grid item xs={12}>
                      <p className='stripe-para'>
                        To start collecting online donations, please connect with
                        secured online payment processing partner{' '}
                        <span style={{ color: '#2A82F6' }}>Stripe</span>; trusted by
                        millions of companies around the world
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <div className='stripe-account'>
                        <a href={`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=&scope=read_write`}>
                          <img
                            src={require('../../assets/img/blue-on-dark@2x.png')}
                            style={{
                              cursor: 'pointer',
                            }}
                            alt=''
                          />
                        </a>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <p className='stripe-para'>Do not have a Stripe account?</p>
                      <p className="creatButn">
                        <a className='stripe-skip-link'
                          href={`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=&scope=read_write`}>
                          Create a new Stripe account
                        </a>
                      </p>
                    </Grid>
                  </Grid>
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner 
          style={style}
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />
      }
      isWhiteBackground={true}
    />
  );
}
export default dfconnectStripe;