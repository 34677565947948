import React, { useEffect, useImperativeHandle, forwardRef, useState } from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  BaseCheckboxList,
  DrawerMain,
  GridContainer,
  GridItem,
  SimpleTable,
  AlertAction
} from '@dclab/dc-react-ui-kit';
import Handle from '../../assets/img/more_vert-24px.svg';
import Delete from '../../assets/img/delete_forever-24px.svg';
import { saveAsDraft } from './Apis'
import { getCategories, sortCategories } from './Apis';
import { getOrganizationData } from './Apis/index'
import messages_content from '../../utils/messages.json';
const {
  token_expired,
  Your_changes_has_been_saved_as_draft,
  Your_changes_has_been_published
} = messages_content;
const Categories = forwardRef((props, ref) => {
  const [open, setOpen] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const [savecategories, setsavecategories] = React.useState([]);
  const [allCategories, setAllCategories] = React.useState([]);
  const [embedform_draft, setEmbedform_draft] = React.useState([]);
  const [error, seterror] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [success, setsuccess] = useState(false);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    async function getcategories() {
      try {
        const orgId = localStorage.getItem('orgID')
        const token = localStorage.getItem('authToken')
        const getOraganization = await getOrganizationData(orgId, token)
        const categoriesdata = await getCategories(orgId)
        const embedformDraft = getOraganization?.data?.body?.data?.[0]?.enabledIntegrations?.deenfund //
        const embedFormCat = embedformDraft?.donationCategories?.selectedDonationCategories ? embedformDraft.donationCategories.selectedDonationCategories : [];

        const getCategoriesResponse = categoriesdata?.data?.body?.data

        const filterCategories = getCategoriesResponse.filter((item, i) => {
          return embedFormCat.includes(item._id)
        })

        const categories_to_show = getCategoriesResponse.filter(item => item.status === 'Active')
        .map((list, index) => {
          return {
            id: index + 1,
            primary: list.name,
            Checkboxchecked: filterCategories && showDetails(filterCategories, list.name),
            order: list.order,
            status: list.status,
            name: list.name,
            categoryId: list._id
          }
        })
        const cat_to_show = filterCategories && filterCategories.length > 0 ? filterCategories.map((item) => {
          return [
            { type: 'id', docId: item._id },
            { type: 'string', name: 'name', value: item.name },
          ]
        }) : []
        setSelectedCategories(cat_to_show)
        setCategories(filterCategories)
        setEmbedform_draft(embedformDraft)
        setAllCategories(categories_to_show)
        setsavecategories(categories_to_show)
        setLoading(false)
      }
      catch (e) {
        seterror(error && error.response && error.response.data.message)
        setLoading(false)
        setTimeout(() => {
          seterror(false)
        }, 8000)
      }
    }
    getcategories()

  }, [])
  function showDetails(cards, id) {
    var result;
    cards.some(function (card) {
      if (card.name === id) {
        result = true;
        return true;
      }
      else {
        result = false
      }
    });
    return result;
  }
  useImperativeHandle(ref, () => ({
    async CategoriesDraft(param) {
      const orgId = localStorage.getItem('orgID')
      const token = localStorage.getItem('authToken')
      const formdata = {
        enabledIntegrations: formData(param)
      }
      param === 'publish' ? publish(orgId, formdata, token) : draftChanges(orgId, formdata, token)
    }
  }));
  function formData(param) {
    if (param === 'publish') {
      return {
              subscriptionType: embedform_draft?.donationCategories?.subscriptionType ? embedform_draft?.donationCategories?.subscriptionType : '',
              selectedDonationCategories: savecategories.map(t => t.categoryId || t._id),
            }
    } else {
      return {
              subscriptionType: embedform_draft?.donationCategories?.subscriptionType ? embedform_draft?.donationCategories?.subscriptionType : '' ,
              selectedDonationCategories: savecategories.map(t => t.categoryId || t._id),
            }
    }
  }
  async function publish(orgId, formdata, token) {
    let formData2 = {
      'enabledIntegrations.deenfund.donationCategories': formdata.enabledIntegrations,
    };
    props.publishChanges(false)
    try {
      await saveAsDraft(orgId, formData2, token)
      if(savecategories && savecategories.length) {
        let obj = {
          orgId: orgId,
          sortedList: savecategories.map((el) => {
            return { _id: el.categoryId || el._id, order: el.order }
          })
        }
        await sortCategories(obj, token);
      }
      setshowAlert(true)
      setsuccess('Publish')
      props.hideAlert()
      props.checkChanges(false)
      props.getnewdata(true)
      setTimeout(() => {
        setshowAlert(false)
        setsuccess(false)
      }, 8000)
    }
    catch (error) {
      seterror(error.response && error.response.data.message)
      props.hideAlert()
      setTimeout(() => {
        seterror(false)
      }, 8000)
    }
  }
  async function draftChanges(orgId, formdata, token) {
    let formData2 = {
      'enabledIntegrations.deenfund.donationCategories': formdata.enabledIntegrations,
    }
    try {
      await saveAsDraft(orgId, formData2, token)
      if(savecategories && savecategories.length) {
        let obj = {
          orgId: orgId,
          sortedList: savecategories.map((el) => {
            return { _id: el.categoryId, order: el.order }
          })
        }
        const res = await sortCategories(obj, token);
      }
      setshowAlert(true)
      setsuccess(true)
      props.hideAlert()
      props.checkChanges(false)
      props.getnewdata(true)
      setTimeout(() => {
        setshowAlert(false)
        setsuccess(false)
      }, 8000)
    }
    catch (error) {
      seterror(error.response && error.response.data.message)
      props.hideAlert()
      setTimeout(() => {
        seterror(false)
      }, 8000)
    }
  }
  const deleteCategory = (e) => {
    const id = e[0].docId;
    const delCategory = categories.filter(item => item._id !== id)
    setsavecategories(delCategory)
    const cat_to_show = delCategory.map((item, index) => {
      return [
        { type: 'id', value: index + 1, docId: item._id},
        { type: 'string', name: 'name', value: item.name },
      ]
    })
    setSelectedCategories(cat_to_show)
    setAllCategories(allCategories.filter(item => item.categoryId !== id))
    setCategories(delCategory)
    setsavecategories(delCategory)
    props.checkChanges(true)
  }
  const closeDrawer = () => {
    setOpen(!open);
  };
  const onCancel = () => {
    setOpen(false);
  };
  const onSave = () => {
    const selected_categories = allCategories.filter(item => item.Checkboxchecked).map((item, index) => {
      return [
        { type: 'id', value: index + 1, docId: item._id },
        { type: 'string', name: 'name', value: item.primary },
      ]
    })
    setSelectedCategories(selected_categories)
    setOpen(false)
    props.checkChanges(true)
    const saved_categories = allCategories.filter(item => item.Checkboxchecked)
    setsavecategories(saved_categories)
  };
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  function onCheckBoxChange(data) {
    setCategories(data.filter((el) => el.Checkboxchecked === true));
  }
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = 'class15';
    return tempArray;
  };
  const navigate = () => {
    props.props.history.push("/deenfund/categories")
  }
  const updateCategories = (data) => {
    // console.log("sortData", data)
    const selected_categories = data.sort((a, b) => a.order - b.order).map((item, index) => {
      return [
        { type: 'id', value: index + 1, docId: item._id },
        { type: 'string', name: 'name', value: item.name },
      ]
    })
    setSelectedCategories(selected_categories)
    // console.log("setsavecategories", data.sort((a, b) => a.order - b.order))
    setsavecategories(data.sort((a, b) => a.order - b.order))
    props.checkChanges(true)
  };
  let col = [
    { id: 'drag', label: ' ', width: 'auto' },
    { type: 'number', name: 'serial_number', label: 'NO.', width: 'auto' },
    {
      type: 'link',
      name: 'invoice_number',
      label: 'Category Name',
      width: '92%'
    },
    { type: 'action', name: 'action', label: 'ACTION', width: '20%' },
  ];

  return (
    <>
      {
        error && error === "Invalid token" && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={token_expired}
              alertType={"error"}
              onClick={() => seterror(false)}
            />
          </div>
        )
      }
      {
        showAlert && error && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={
                error === "Invalid token"
                  ? token_expired
                  : error
              }
              alertType={"error"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        )
      }
      {
        showAlert && success && success === 'Publish' ? (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={Your_changes_has_been_published}
              alertType={"success"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        ) : showAlert && success && (
          <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: 20 }}>
            <AlertAction
              alert={Your_changes_has_been_saved_as_draft}
              alertType={"success"}
              onClick={() => setshowAlert(false)}
            />
          </div>
        )
      }
      <GridContainer>
        {loading && <GridItem itemStyle={{ marginTop: 40, paddingBottom: 0 }} xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: 'center',
              height: '50vh'
            }}
          >
            <CircularProgress color="primary" />
          </div>
        </GridItem>}
        {!loading && <GridItem>
          <SimpleTable
            showHeader={true}
            title='Donation Categories'
            rows={selectedCategories}
            columns={col}
            parentTableDrag={true}
            DragIcon={Handle}
            colSpan={16}
            actionSendButton={true}
            classProps={changeClassProps()}
            donationsCount={selectedCategories && selectedCategories.length > 0 ? 1 : 0}
            noTitle="No category added yet"
            noPara="Add donation categories so that people can donate for a specific cause."
            noBtnText="Add Category"
            onInviteClick={() =>
              setOpen(true)
            }
            showIconCol={true}
            deleteIcon={Delete}
            editIcon={false}
            deleteAction={(e) => deleteCategory(e)}
            data={categories}
            showFooter={selectedCategories?.length > 10 ? true : false}
            showAdd={true}
            addText='ADD'
            onAddClick={() => setOpen(true)}
            iconTextSize={16}
            updateCategoriesLocal={true}
            sortData={(e) => updateCategories(e)}
            showEditColIcon={false}
            hideEditIcon={true}
            rowsPerPage={10}
          />
        </GridItem>}
        {!loading && <GridItem xs={12} itemStyle={{ paddingTop: 13 }}>
          <div style={{ display: 'flex', marginTop: 0 }}>
            <p className='cat-lower' style={{ marginTop: 0 }}>
              Change the order of Categories to display to users. Click on{' '}
            </p>
            <img
              src={Handle}
              alt=''
              style={{ display: 'flex', marginTop: -10 }}
            />
            <p className='cat-lower' style={{ marginTop: 0 }}>
              to drag and drop within the table.
          </p>
          </div>
        </GridItem>}
        <DrawerMain
          open={open}
          openHandler={closeDrawer}
          title='Donation Categories'
          onCancel={onCancel}
          onSave={onSave}
          loading={false}
        >
          <BaseCheckboxList
            items={allCategories}
            className='df-category-drawer-borderRemove'
            subheader={"Select donation categories"}
            CheckboxonChange={(replyData) => onCheckBoxChange(replyData)}
          />
          <div className='df-categories-dummy-text' style={{ marginTop: '20px', marginBottom: '20px' }}>
            Add new categories from
            <br />
            <label className='df-categories-link-text' onClick={() => navigate()}>
              Donation Categories
              </label>
          </div>
        </DrawerMain>
      </GridContainer>
    </>
  );
})
export default Categories