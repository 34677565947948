import * as constants from '../constants'

const INITIAL_STATE = {
    email: "",
   token:'',
   access: ''
  };
  
  export default (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
      case constants.SIGN_IN:
        return {
          ...state,
          email: action.payload.email,
          token: action.payload.token,
          access: action.payload.access
        };
      default:
        return state;
    }
  };
  