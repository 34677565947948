import React, { Component } from "react";
import {
  SimpleTable,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction
} from "@dclab/dc-react-ui-kit";
import Moment from "moment";
import "../../App.css";
import { getOrganizationData } from "../Organization/api";
import { CircularProgress } from "@material-ui/core";
import messages_content from '../../utils/messages.json';
const { token_expired } = messages_content;
export default class pages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Pages: [],
      error: false,
      Loading: true,
    }
    this.details = this.details.bind(this);
  }
  componentDidMount() {
    this.getPagesData()
  }
  capitalizeFLetter = (val) => {  
    return val ? `${val[0].toUpperCase() + val.slice(1)}` : ""; 
  } 
  async getPagesData() {
    try {
      const token = localStorage.getItem('authToken')
      const orgId = localStorage.getItem('orgID')
      const getOraganization = await getOrganizationData(orgId, token);
      const oraganization_data = getOraganization?.data?.body?.data?.enabledIntegrations?.deenweb?.pages
      let result = Object.entries(oraganization_data).map((e) => ({ [e[0]]: e[1] }));
      function arraymove(arr, fromIndex, toIndex) {
        var element = arr[fromIndex];
        arr.splice(fromIndex, 1);
        arr.splice(toIndex, 0, element);
        return arr
      }
      // arraymove(result, 6, 1)
      // arraymove(result, 4, 2)
      arraymove(result, 8, 7)
      let data = []
      result.filter((text, index) => {
        var resultObj = Object.keys(text).map((key) => text[key]);
        let name = Object.keys(text)
        let arr = resultObj.map((tex, i) => {
          let dateStart = [Moment(tex.lastEdited).format("MMM DD, YYYY")];
          return (
            [
              { type: "id", value: index + 1, name: name[0] },
              { type: "string", name: "name", value: tex.title },
              { type: "string", name: "frequency", value: dateStart },
              { type: "status", status: tex.enablePage ? "success" : 'failed', value: this.capitalizeFLetter(tex.status) },
              { type: "action", status: "details", value: "Manage" },
            ]
          )
        })
        data.push(arr[0])
      })
      this.setState({ Pages: data, Loading: false })
    } catch (error) {
      this.setState({ error: error.response && error.response.data.message, Loading: false })
      setTimeout(() => {
        this.setState({ error: false })
      }, 8000);
    }
  }
  details = (name) => {
    if (name[0].name === 'homePage') {
      this.props.history.push('/website/home-page')
    }
    else if (name[0].name === "aboutUs") {
      this.props.history.push('/website/about-us')
    }
    else if (name[0].name === "services") {
      this.props.history.push('/website/services')
    }
    else if (name[0].name === "videos") {
      this.props.history.push('/website/videos')
    }
    else if (name[0].name === "articles") {
      this.props.history.push('/website/news-articles')
    }
    else if (name[0].name === "events") {
      this.props.history.push('/website/events')
    }
    else if (name[0].name === "faqs") {
      this.props.history.push('/website/faq')
    }
    else if (name[0].name === 'termsOfServices') {
      this.props.history.push('/website/tos')
    }
    else if (name[0].name === "privacyPolicy") {
      this.props.history.push('/website/privacy-policy')
    }
  };
  render() {
    const { error, Pages, Loading } = this.state
    let classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = "class15";
      return tempArray;
    };
    let col = [
      { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
      {
        type: "link",
        name: "invoice_number",
        label: "Page Title",
        width: '55%'
      },
      { type: "string", name: "name", label: "Last Edited", width: '15%' },
      { type: "string", name: "date", label: "STATUS", width: '15%' },
      { type: "string", name: "date", label: "Action", width: '15%' },
    ];
    const style = {
      width: '100%',
    };
    return (
      <MasterLayoutMiddle
        content={
          Loading ?
            <div
              style={{
                marginTop: 30,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </div>
            :
            <>
              {
                error && error === "Invalid token" && (
                  <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35 }}>
                    <AlertAction
                      alert={token_expired}
                      alertType={"error"}
                      onClick={() => this.setState({ error: false })}
                    />
                  </div>
                )
              }
              <div>
                <SimpleTable
                  rowsPerPage={Pages.length}
                  showHeader={true}
                  title="Pages"
                  width="98.4%"
                  rows={Pages}
                  columns={col}
                  colSpan={16}
                  actionSendButton={true}
                  classProps={changeClassProps()}
                  donationsCount={Pages?.length > 0 ? 1 : 0}
                  noTitle="No Pages added"
                  noPara="Added Pages will be displayed and managed here"
                  noBtnText="Add New Pages"
                  onInviteClick={() => this.props.history.push("/website/pages")}
                  actionLink={(name) => this.details(name)}
                  showAdd={true}
                  addText="ADD NEW"
                  onAddClick={() => this.props.history.push("/website/pages")}
                  iconTextSize={16}
                />
              </div>
            </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style}
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"} 
          />
        }
      />
    );
  }
}