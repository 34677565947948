import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ComponentOtpCard } from '@dclab/dc-react-ui-kit';
import { checkCode } from './api';
import Logo from '../../assets/img/DC_Logo.png';
import Icon from '../../assets/img/security-24px.svg';
import Lock from '../../assets/img/lock-24px.svg';
import { orgCodeHandler } from '../../redux/actions/orgTokenAction';
import './style.css';
import '../../App.css';
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      error: false,
      showAlert: false,
      count: 0,
      isLoading: false,
    };
    this.handleCode = this.handleCode.bind(this);
    this.click = this.click.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  handleCode = (e) => {
    this.setState({
      value: e,
    });
    if(e.length === 6) {
      this.click();
    }
    
  };
  click = async () => {
    const { value } = this.state;
    this.setState({ error: false, showAlert: false, isLoading: true });
    if (!value) {
      this.setState({ error: true, isLoading: false });
    } else {
      try {
        const res = await checkCode(value);
        localStorage.setItem('orgEmail', res?.data?.body?.data?.orgEmail);        
        localStorage.setItem('orgAdmin', res?.data?.body?.data?.orgAdmin);        
        localStorage.setItem('orgName', res?.data?.body?.data?.orgName);        
        this.props.orgCode(value);
        localStorage.setItem('code', true);
        this.setState({ isLoading: false });
        this.props.history.replace('/success');
      } catch (error) {
        this.setState({
          error: error && error.response && error.response.data.message,
          showAlert: true,
          count: this.state.count + 1,
          isLoading: false,
        });
        setTimeout(() => {
          this.setState({error: false})
        }, 8000);
        this.hideAlert();
      }
    }
  };
  render() {
    const { value, error, count, isLoading } = this.state;
    if (count === 3) {
      this.props.history.replace('/locked');
    }
    return (
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', width: '100%' }}>
          <div
            className='Background-Image'
            style={{ width: '100%', position: 'fixed', height: '100vh' }}
          ></div>
        </div>
        <div
          className='container div_backgrndimg-pattern'
          style={{ background: 'transparent', paddingTop: 65 }}
        >
          <ComponentOtpCard
            isLoading={isLoading}
            disabled={value.length !== 6 && true}
            macLogo={Lock}
            src={Logo}
            LogoLink="https://shapla.deenconnect.io/"
            icon={Icon}
            value={value}
            onChange={(e) => this.handleCode(e)}
            onClick={() => this.click()}
            hasErrored={error}
            errorStyle={{
              border: '1px solid #B00020',
            }}
            error={false}
          />
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    orgCode: (code) => dispatch(orgCodeHandler(code)),
  };
};
export default connect(null, mapDispatchToProps)(Home);
