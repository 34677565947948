import React from "react";
import { useEffect, useState } from 'react';
import { BaseTextField, MasterLayoutMiddle, FooterInner, BaseSelect, BaseButton, TextEditor, BaseStepper, DrawerDateTime , DrawerMain, DropdownMultiSelect} from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import Button from '@material-ui/core/Button';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

import moment from 'moment';
import { getCommunicationsData , saveMessage , getItemDetails} from './Functions';



function NewMessage(props) {

    const style = {
        width: '100%',
    };

    const [isEdit , setIsEdit] = React.useState(false);
    const [listData, setListdata ] = React.useState([]);

    const [activeStep, setActiveStep] = React.useState(0);
    const getSteps = () => {
        return [
            { number: 1, label: "Basic Info", },
            { number: 2, label: "Message", },
        ];
    };
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    const styles = {
        stepper: {
            marginTop: 120,
            padding: "8px 20px",
            borderRadius: "30px",
        },
        select: {            
            margin: 8,
            minWidth: 120,
            height: 66,
            width: 120,
            margin: 8,
            border: "#D3D8DA",
            width:"100%",               
        }
    };

    const handleChange =  (prop) =>  (event) => {
        setValues({
            ...values,
            [prop]: event.target.value,
        });  
        if( prop == 'templateName'){
            const tid = ( event.target.value );
            setButtonDisabled('');
            if( tid =='SMS9999999' || tid =='EMAIL9999999'){
                setValue(
                    [{ type: 'paragraph', children: [{ text:'' }] }]
                ); 
                    
                // console.log( templates[i].emailSubject);
                const oldValues = values ; 

                if( isTypeEmail ){                        

                    oldValues.emailSubject = '' ; 
                    
                      
                }

                oldValues.templateName = tid ; 
                    
                setValues(oldValues);

            }else{
                for( let i=0 ; i < templates.length ; i++ ){
                    if( tid == templates[i]._id ){
                        setValue(
                            [{ type: 'paragraph', children: [{ text: templates[i].templateBody }] }]
                        ); 
                            
                        // console.log( templates[i].emailSubject);
                        const oldValues = values ; 
    
                        if( isTypeEmail ){                        
    
                            oldValues.emailSubject = templates[i].emailSubject ? templates[i].emailSubject : '' ; 
                            
                              
                        }
    
                        oldValues.templateName = tid ; 
                            
                        setValues(oldValues);
    
                    }
                }
            }
            
        }

        if( prop =='type'){
            if( event.target.value =='SMS' ){
                setIsTypeEmail( false )    ;
                setEditorClass( 'TextArea');
                setMessageSize( '160');
                setTemplate( templateListSMS);
                let oldListData = listData ; 
                let setPhoneInDescription = async function(oldListData){ 
                        await oldListData.map( singleItem =>{
                        singleItem.Description = singleItem.DescriptionWithPhone
                        return singleItem ; 
                    });
                }
                setPhoneInDescription( oldListData );
                setListdata( oldListData );

            }else{
                setIsTypeEmail( true )    ;
                setEditorClass( '');
                setMessageSize( '500');
                setTemplate( templateListEmail);
                let oldListData = listData ; 
                let setEmailInDescription = async function(oldListData){ 
                            await oldListData.map( singleItem =>{
                            singleItem.Description = singleItem.DescriptionWithEmail
                            return singleItem ; 
                        });
                    }
                setEmailInDescription( oldListData );
                setListdata( oldListData );
            }
        }
    };

    const [isTypeEmail , setIsTypeEmail] = React.useState(false );
    const [editorClass , setEditorClass] = React.useState( 'TextArea');
    const [messageSize, setMessageSize] = React.useState('160')

    const [values, setValues] = React.useState({
        fromName: "",
        message: "",
        type: "SMS",
        date: "",        
        templateName: "SMS9999999" ,
        templateBody: "",
        isSchedules: false , 
        emailSubject: '', 
        emailHeader: '',         
        contacts: []        
    });

    const [selectedTime , setSelectedtime ] = React.useState('');

    const [selectedDate , setSelectedDate ] = React.useState(moment().format("ddd, MMM DD, yyyy"));

    const [buttonDisabled , setButtonDisabled] = React.useState( 'disabled' );
    
    const [continueButtonDisabled , setContinueButtonDisabled] = React.useState( 'disabled' );

    const [templates , setTemplates] = React.useState([]);

    const [sendbutton , setSendbutton] = React.useState('SEND NOW');

    const [groups , setGroups] = React.useState([]);

    async function saveSelectedTime( e ){
        // console.log( e );
        setOpen( false );
        setValues({ ...values, 
            ['isSchedules'] : true });
        // console.log('date saved ');
        setSendbutton('SCHEDULE NOW');
    }

    const [Template , setTemplate] = React.useState([]);

    const [settime, setTime] = React.useState( false );

    const[ templateListSMS , settemplateListSMS]  = React.useState([]);

    const[ templateListEmail , settemplateListEmail ]    = React.useState([]);

    const [group, setGroupName ] = React.useState([]);

    useEffect(() => {
        const token = localStorage.getItem("authToken");
        const orgId = localStorage.getItem('orgID');
        let mounted = true;
        let contacts = listData ; 
        let item = {} ; 
        let items = []; 
        let selectedContactObjects = [] ; 
        let selectedContacts = [];  
        let selectedGroups = [] ; 
        const itemId = (props.match.params.id );

        if( itemId && itemId.length ){
            if( true ){

                setContinueButtonDisabled('');
                
                setButtonDisabled('');

            if( itemId.indexOf('@') > -1 ){

                // console.log( 'vhul');

                const urlParts =  itemId.split('@');
                
                
                
                

                if( urlParts[0] =='cfromcontact'){

                    selectedContacts.push( urlParts[1]);                    


                }else if(urlParts[0] =='cfromgroup'){

                    selectedGroups.push( urlParts[1]);

                }                          
                

            }else{

                getItemDetails(itemId, 'messages').then( response =>{

                    // console.log( 'messages');

                    if (mounted) {
        
                        if (response && response.data.body.data) {
        
                            item = response.data.body.data;   

                            // console.log( response );
                            
                            let oldValue = values ; 
        
                            oldValue.fromName = item.fromName ; 
                            oldValue.type = item.type ; 
        
                            if( item.type =='SMS' ){
                                setIsTypeEmail( false )    ;
                                setEditorClass( 'TextArea');
                                setMessageSize( '160');
                                setTemplate( templateListSMS);
                                
                
                            }else{
                                setIsTypeEmail( true )    ;
                                setEditorClass( '');
                                setMessageSize( '500');
                                setTemplate( templateListEmail);
                            }
        
                            
                            if( item.emailSubject ){
                                oldValue.emailSubject = item.emailSubject ; 
                            }
                            if( item.templateName ){
                                oldValue.templateName = item.templateName ; 
                            }
                            oldValue.message = item.message ; 
        
                            setValue(
                                [{ type: 'paragraph', children: [{ text: item.message }] }]
                            ); 
                            
                            // console.log( oldValue );
        
                            setValues( oldValue );

                            if(Array.isArray( item.contacts ) ){
                                // console.log(item.contacts );
                                selectedContacts = ( item.contacts );
                            }else{
                                // console.log('vhul');
                            }
        
                        }
                    }
                });

            }
            }
        }


        getCommunicationsData( 'templates' , orgId ,  token).then( response =>{
        if( mounted ){
            setTemplates( response.data.body.data );
            // console.log( );

            let object = {
                value: 'SMS9999999' ,
                label: 'Custom'
            };  
            templateListSMS.push( object );

            object = {
                value: 'EMAIL9999999' ,
                label: 'Custom'
            };  
            templateListEmail.push( object );

            for( let i=0 ; i < response.data.body.data.length ; i++ ){
                object = {
                    value: response.data.body.data[i]._id ,
                    label: response.data.body.data[i].templateName
                }                
                
                if( response.data.body.data[i].templateType.toLowerCase() == 'sms'){
                    templateListSMS.push( object );
                }else{
                    templateListEmail.push( object );
                }
            }

            setTemplate( templateListSMS);
            
        }
        });

        

        
        getCommunicationsData( 'contacts' , orgId ,  token).then( response =>{
            if( mounted ){

                items = response.data.body.data;

                for(let i=0; i < items.length ; i++ ){
                    let obj = {
                        title: items[i].firstName + ' ' + items[i].lastName ,
                        Description: '+' + items[i].phoneNumber ,
                        DescriptionWithPhone:  '+' + items[i].phoneNumber ,
                        DescriptionWithEmail: items[i].email ,
                        contactId: items[i]._id,
                        isGroup: false 

                    }

                    if( selectedContacts.includes( obj.contactId ) ){
                        selectedContactObjects.push( obj );
                        // console.log( obj );
                        
                    }
                    
                    contacts.push( obj );                                       

                }
                setListdata( contacts  );  

                if( selectedContactObjects && selectedContactObjects.length){
                    setSelectedValues( selectedContactObjects );
                    setIsEdit( true );
                }
                 
                //            

            }
        });  

        
        getCommunicationsData( 'groups' , orgId ,  token).then( response =>{
            if( mounted ){
                items = response.data.body.data;
                let newGroups = [] ; 

                for(let i=0; i < items.length ; i++ ){
                    let obj = {
                        title: items[i].groupName ,
                        Description: '' , 
                        groupContacts: items[i].groupContacts , 
                        contactId: items[i]._id,
                        isGroup: true 
                    }
                    
                    contacts.push( obj );
                    newGroups.push( obj.title );

                    if( selectedGroups.includes(items[i]._id )){
                        selectedContactObjects.push( obj );
                    }

                }
                setGroups( newGroups );

                setListdata( contacts  );
            }
        });
        
            
        
        

        return () => mounted = false ; 
        
    }, []);


    const object = [
        {
            value: "SMS",
            label: "SMS",
        },
        {
            value: "EMAIL",
            label: "Email",
        },

    ];

       


    const [value, setValue] = useState([{ type: 'paragraph', children: [{ text: "" }] }]);

    const onChange = (e) => {
        setValue(e)
    };

    const [open, setOpen] = React.useState(false);
    

    

    const closeDrawer = () => {
        setOpen(!open);
    };
    

    const [stepTwo , setSteptwo] = React.useState(false );
    const [stepOne , setStepone ] = React.useState( true );

    const gotoStepTwo = async () =>{
        
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSteptwo( true );
        setStepone( false );
    }

    const gotoStepOne = async () =>{
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setSteptwo( false );
        setStepone( true );
    }

    const [dateTime, setDateTime] = React.useState();
    const getDateTime = (value , boolean ) => {

        // setDateTime( value.format('MMM DD YYYY hh:mm A') );
        let endDate = "";
        // const { endTime, endTimeToFormat } = this.state;

        if (!boolean) {
            endDate = moment(value).format("ddd, MMM DD, yyyy")  ;
            setSelectedDate( endDate );

            endDate = endDate + ', ' + selectedTime ; 
        }

        

        setValues({ ...values , 
        ['date']: endDate } ); 
        
        
    }

    const getSelectedTime = (value) =>{
        let endTime = moment(value).format("hh:mm A");
        let oldValues = values ; 

        setSelectedtime( endTime );

        if( selectedDate ){
            oldValues.date = selectedDate + ', '+ endTime ; 
            setValues( oldValues );
        }
    }

    const setSchedule = async () =>{
        setTime( true );
    }

    const cancelSchedule = async () =>{
        setTime( false );
        setSendbutton('SEND NOW');
    }

    const sendNowMessage = async () =>{
        let inputValues = values ; 

        

        inputValues.message = value[0].children[0].text ; 
        
        await saveMessage( inputValues );

        if( values.isSchedules ){
            props.history.push('/communication/messages/messageScheduled');
        }else{
            props.history.push('/communication/messages/messageAdd');
        }
        
    }

    
    const viewChange = (newvalue) =>{
        if( newvalue.length ){
            let changedData = values ; 
            
            for(let i=0 ; i < newvalue.length ; i++ ){
                if( newvalue[i].isGroup ){
                    newvalue[i].groupContacts.map( (item, index) =>{
                        if( ! changedData.contacts.includes( item ) ){
                            changedData.contacts.push( item );
                            setContinueButtonDisabled('');
                        }
                    });
                    
                }else{
                    if( !changedData.contacts.includes( newvalue[i].contactId ) ){
                        changedData.contacts.push( newvalue[i].contactId);
                        setContinueButtonDisabled('');  
                    }
                                      
                }
                
            }
            setValues( changedData );

            // console.log( changedData );
        }
    }

    

    const groupByCallBack = (option) => {
        // console.log( groups );
        if( groups.includes( option.title ) ){
            return 'GROUP LIST'  ; 
        }else{
            return 'CONTACT LIST' ; 
        }
       
    }

    const [selectedValues, setSelectedValues] = React.useState([
        
    ]);

    

    return (
        <MasterLayoutMiddle
            header={
                <div
                    onClick={() => props.history.replace("/communication/messages")}
                    className="backBtn"
                >
                    <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
                    <p style={{ alignSelf: "center" }}>Back</p>{" "}
                </div>
            }
            isForm={true}
            formData={
                <div>


                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <BaseStepper
                                title="New Message"
                                
                                getSteps={getSteps}
                                alternativeLabel={true}
                                handleBack={() => handleBack()}
                                handleNext={() => handleNext()}
                                
                                nonLinear={true}
                                bottomLine={false}
                                backButton={false}
                                backgroundColor={'transparent'}
                                orientation={"horizontal"}
                                btnStyles={styles.stepper}
                                activeStep={activeStep}
                            />
                        </Grid>
                    </Grid>
                        {stepOne && (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div className="field_box">
                                        <BaseSelect
                                            label="Message Type"
                                            value={values.type}
                                            onChange={handleChange("type")}
                                            object={object}
                                            style={styles.select}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <div className="field_box">

                                        {isEdit && selectedValues && (
                                            <DropdownMultiSelect
                                            Listdata={listData}
                                            variant="standard"
                                            placeholder="Search contacts/groups"
                                            onChange={handleChange("name")}
                                            groupByCallBack = {groupByCallBack}
                                            viewChange={viewChange}
                                            selected = {selectedValues}  
                                            value={values.groupName} 
                                            label="To"  
                                            fieldToSelect="contactId"                      
                                        />
                                        ) }

                                        {! isEdit  && (
                                            <DropdownMultiSelect
                                            Listdata={listData}
                                            variant="standard"
                                            placeholder="Search contacts/groups"
                                            onChange={handleChange("name")}
                                            groupByCallBack = {groupByCallBack}
                                            viewChange={viewChange}
                                            selected = {[]}  
                                            value={values.groupName} 
                                            label="To"
                                            fieldToSelect="contactId"                         
                                        />
                                        )}
                                        
                                    </div>
                                </Grid>

                                

                                <Grid item xs={12}>

                                    <div className="form_button">
                                        <p
                                            className='cancel'
                                            onClick={ (e) => props.history.push('/communication/messages') }
                                        >
                                            CANCEL
                                        </p>
                                        <BaseButton disabled={continueButtonDisabled} text='CONTINUE' onClick={gotoStepTwo} />
                                    </div>

                                </Grid>
                            </Grid>
                        )}

                        
                        
                    

                {stepTwo && (
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseSelect
                                    label="Template"
                                    value={values.templateName}
                                    onChange={handleChange("templateName")}
                                    object={Template}
                                    style={styles.select}
                                />
                            </div>
                        </Grid>
                        {isTypeEmail && (
                            <Grid item xs={12}>
                                <div className="field_box">
                                    <BaseTextField
                                        label="Email Subject"
                                        value={values.emailSubject}
                                        onChange={handleChange("emailSubject")}
                                        count={50}
                                    />
                                </div>
                            </Grid>
                        )}
                                                


                        <Grid item xs={12}>
                            <div className={editorClass}>
                                <TextEditor
                                    onChange={(e) => onChange(e)}
                                    disabled={false}
                                    value={value}
                                    style={styles}
                                    label={"Message"}
                                    maxCharCount={messageSize}
                                    //placeholder="placeholder"
                                />
                            </div>

                            <Button onClick={setSchedule} className="addSchduleBtn"><CalendarTodayRoundedIcon /> ADD SCHEDULE</Button>
                        </Grid>

                    {settime &&(
                        <Grid item xs={12}>
                            <div className="addedField">
                                <BaseTextField
                                    label="Select Date and Time"
                                    value={values.date ? moment(values.date).format('ddd, MMM DD, yyyy hh:mm A') : '' }
                                    // onChange={handleChange("name")}
                                    InputProps={true}
                                    style={{ width: '60%' }}
                                    onClick={() => setOpen(true)}
                                    disabled={false}
                                    onArrowClick={() => setOpen(true)}

                                />
                                <Button onClick={cancelSchedule} className="deleteField"><DeleteForeverRoundedIcon /></Button>
                            </div>
                        </Grid>
                    )}
                    



                    <Grid item xs={12}>

                        <div className="form_button">
                            <p
                                className='cancel'
                                onClick={gotoStepOne}
                            >
                                GO BACK
                            </p>
                            
                            <BaseButton disabled={buttonDisabled} text={sendbutton} onClick={sendNowMessage} />
                        </div>

                    </Grid>

                </Grid>
                )}
                    




                    {/* <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <div className="field_box">
                                <BaseSelect
                                    label="Template"
                                    value={values.age}
                                    onChange={handleChange("age")}
                                    object={Template}
                                    style={styles.select}
                                />
                            </div>
                        </Grid>

                        

                        <Grid item xs={12}>

                            <TextEditor
                                onChange={(e) => onChange(e)}
                                disabled={false}
                                value={value}
                                style={styles}
                                label={"Message"}
                                maxCharCount={300}
                                placeholder="placeholder"
                            />


                            <Button className="addSchduleBtn"><CalendarTodayRoundedIcon /> ADD SCHEDULE</Button>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="addedField">
                                <BaseTextField
                                    label="Select Date and Time"
                                    value={content}
                                    // onChange={handleChange("name")}
                                    InputProps={true}
                                    style={{ width: '60%' }}
                                    onClick={() => setOpen(true)}
                                    disabled={false}
                                    onArrowClick={() => setOpen(true)}

                                />
                                <Button className="deleteField"><DeleteForeverRoundedIcon /></Button>
                            </div>
                        </Grid>



                        <Grid item xs={12}>

                            <div className="form_button">
                                <p
                                    className='cancel'
                                    onClick={() => this.onCancelOrgDetail()
                                    }
                                >
                                    CANCEL
                                </p>
                                <BaseButton text='SCHEDULE MESSAGE' onClick={() => this.updateOrg()} />
                            </div>

                        </Grid>

                    </Grid> */}

                    <DrawerMain
                        open={open}
                        openHandler={closeDrawer}
                        title="Select Date and Time"
                        onCancel={() => setOpen( false )}
                        onSave={saveSelectedTime}
                        loading={false}
                    >

                        <DrawerDateTime 
                            title={""} 
                            isDateorTime={false} 
                            hidecheck={true}
                            timezone={''} 
                            dateLabel={"Date"} 
                            timeLabel={"Time"}
                            getDateTime={getDateTime} 
                            
                            getTime={getSelectedTime}
                        />

                    </DrawerMain>

                </div>
            }
            isFooter={true}
            footer={<FooterInner 
                    style={style}
                    termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
                    privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
                    />}
            isWhiteBackground={true}
        />
    );
}

export default NewMessage;
