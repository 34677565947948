import * as constants from "../constants";

const INITIAL_STATE = {
    code:''
  };
  
  export default (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
      case constants.ORG_CODE:
        return {
          code: action.payload,
          
        };
      default:
        return state;
    }
  };
  