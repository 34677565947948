import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { markdowntoPlainHtml } from '../../utils/markdowntoreact';
import {
  BaseTabs,
  SimpleTable,
  BasePaper,
  BaseCheckBox,
  AlertAction,
  GridContainer,
  GridItem,
  MasterLayoutMiddle,
  FooterInner
} from '@dclab/dc-react-ui-kit';
import Edit from '../../assets/img/create-24px.svg';
import Delete from '../../assets/img/delete_forever-24px.svg';
import {
  getServices,
  deleteService,
  editOrganization,
  getService,
  getOrganizationData,
} from './api';
import { editServiceAction } from '../../redux/actions/servicesAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../App.css';
import '../style.css';
import { hideAlert, showAlert } from '../../redux/actions/alertActions';
import messages_content from '../../utils/messages.json';
const {
  token_expired,
  success_message_edit_facilities,
  success_message_facilities,
  service_remove_success_message
} = messages_content;
class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabInd: 0,
      activeTab: 0,
      services: [],
      error: '',
      showAlert: false,
      success: false,
      facilitiesArr: [],
      orgFacilities: [],
      openAlert: false,
      deleteServiceId: '',
      loading: true,
      modalLoading: false,
      facilitiesLoading: false,
      removeFacility: false,
    };
    this.onClick = this.onClick.bind(this);
    this.editService = this.editService.bind(this);
    this.deleteServiceAction = this.deleteServiceAction.bind(this);
    this.addFacilities = this.addFacilities.bind(this);
    this.saveFacilities = this.saveFacilities.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleServiceConfirm = this.handleServiceConfirm.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  async componentDidMount() {
    const token = localStorage.getItem('authToken');
    let orgId = localStorage.getItem('orgID');
    try {
      const res2 = await getOrganizationData(orgId);
      const res = await getServices(orgId, token);
      this.setState({
        services: res.data.body.data,
        orgFacilities: res2.data.body.data?.orgDetails?.facilities,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error: error.response && error.response.data.message,
        loading: false,
      });
    }
  }
  hideAlert = () => {
    setTimeout(() => {
      this.setState({ showAlert: false });
    }, 8000);
  };
  addFacilities = (e, item) => {
    const { orgFacilities } = this.state;
    if (
      !orgFacilities.find((element) => element === item) // returns the object otherwise undefined
    ) {
      const evcIdArray = orgFacilities.concat(item);
      this.setState({ orgFacilities: evcIdArray, removeFacility: false });
    } else {
      this.setState({
        orgFacilities: orgFacilities.filter(
          (element) => element !== item // returns an array with all values except current stationId
        ),
        removeFacility: true,
      });
    }
  };
  saveFacilities = async () => {
    this.setState({ facilitiesLoading: true });
    let orgId = localStorage.getItem('orgID');
    const token = localStorage.getItem('authToken');
    const { orgFacilities } = this.state;
    const obj = {
      orgDetails: {
        facilities: orgFacilities,
      }
    };
    try {
      const res = await editOrganization(obj, orgId, token);
      this.setState({
        success: true,
        showAlert: true,
        facilitiesLoading: false,
      });
      this.hideAlert();
    } catch (error) {
      this.setState({
        error: error.response && error.response.data.message,
        showAlert: true,
        facilitiesLoading: false,
      });
      this.hideAlert();
    }
  };
  editService = async (e) => {
    try {
      const token = localStorage.getItem('authToken');
      const res = await getService(e[0].label, token);
      this.props.editServiceHandler(res.data.body.data);
      this.props.history.push('/organization/services/add-new-service');
    } catch (e) {
      this.setState({
        error: e.response && e.response.data.message,
      });
    }
  };
  handleClose = () => {
    this.setState({ openAlert: false });
  };
  handleServiceConfirm = async () => {
    this.setState({ modalLoading: true });
    const { services, deleteServiceId } = this.state;
    const token = localStorage.getItem('authToken');
    try {
      const res = await deleteService(deleteServiceId, token);
      const newServiceArr = services.filter(
        (ser) => ser._id !== deleteServiceId
      );
      this.setState({
        services: newServiceArr,
        success: true,
        openAlert: false,
        modalLoading: false,
      });
      this.props.alertShow(service_remove_success_message)
    } catch (error) {
      this.setState({
        error: error.response && error.response.data.message,
        showAlert: true,
        openAlert: false,
        modalLoading: false,
      });
    }
  };
  deleteServiceAction = async (e) => {
    this.setState({
      openAlert: true,
      deleteServiceId: e[0].label,
    });
  };
  onClick = (x, y) => {
    this.setState({
      tabInd: y,
    });
  };
  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };
  render() {
    const {
      tabInd,
      services,
      error,
      showAlert,
      success,
      openAlert,
      activeTab,
      loading,
      modalLoading,
      facilitiesLoading,
      removeFacility,
    } = this.state;
    if (this.props.alert.alertValue) {
      setTimeout(() => {
        this.props.alertHide()
      }, 8000);
    }
    let alertServiceModal = (
      <Dialog
        open={openAlert}
        onClose={() => this.handleClose()}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Delete Service?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this service? <br/>Deleting will permanently remove from everywhere.
          </DialogContentText>
        </DialogContent>
        {modalLoading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 50,
            }}
          >
            <CircularProgress
              color='primary'
              style={{ width: 24, height: 24 }}
            />
          </div>
        ) : (
          <DialogActions>
            <Button onClick={() => this.handleClose()} color='primary'>
              Cancel
            </Button>
            <Button onClick={() => this.handleServiceConfirm()} color='primary'>
              Confirm
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
    const obj = [
      {
        label: 'Main Services',
        item: '',
      },
      {
        label: 'Facilities',
        item: '',
      },
    ];
    let col = [
      { id: 'NO', label: 'NO.', width: 'auto' },
      { type: 'url', name: 'single_pic', label: 'IMAGE', width: 'auto' },
      { type: 'string', name: 'name', label: 'SERVICE NAME', width: '20%' },
      {
        type: 'string',
        name: 'category',
        label: 'SERVICE DETAILS',
        width: '70%'
      },
      { id: 'STATUS', label: 'STATUS', width: '15%' },
      { id: 'ACTION', label: 'ACTION', width: '15%' },
    ];
    let rows = services.map((ser, i) => {
      let nameArr = ser.name.split('');
      let initials;
      if (nameArr.length > 1) {
        initials = nameArr[0][0] + nameArr[1][0];
      } else {
        initials = nameArr[0][0];
      }
      console.log(
        'received markdown',
        markdowntoPlainHtml(JSON.parse(ser.description))
      );

      return [
        { type: 'id', value: i + 1, label: ser._id },
        { type: 'url', name: 'single_pic', src: ser.imageMetaData.imageUrl ? ser.imageMetaData.imageUrl : initials },
        { type: 'string', name: 'name', value: ser.name },
        {
          type: 'string',
          name: 'category',
          value:
            markdowntoPlainHtml(
              JSON.parse(ser.description)
            )[0].props.dangerouslySetInnerHTML.__html.slice(0, 60) + '...',
        },
        {
          type: 'status',
          status: ser.featured ? 'success' : 'failed',
          value: ser.featured ? 'Active' : 'Inactive',
        },
      ];
    });
    let classProps = () => {
      const tempArray = [];
      col.forEach((column, index) => {
        tempArray.push(`class${index}`);
      });
      return tempArray;
    };
    const changeClassProps = () => {
      const tempArray = classProps();
      tempArray[0] = 'class15';
      return tempArray;
    };
    const arr = [
      'Mass transit accessible ',
      'Wheelchair accessible ',
      'Parking available ',
      'Free WiFi available ',
      'Child-friendly premises ',
      'Bookstore on premises ',
      'Marital services ',
      'Sharia arbitration ',
      'Childcare on premises ',
      'Youth group',
      "Women's programming ",
      'Career counseling ',
      'Arabic classes ',
      "Qur'an/hadith classes",
      'Lecture series ',
      'Boy/Girl Scouts ',
      'Interfaith dialogue ',
      'Voter registration ',
      'Political organizing ',
      'Homeless services ',
      'Vocational training ',
      'Drug counseling ',
      'Domestic abuse services ',
      'Community medical clinic',
      'Family counseling ',
      'Community service ',
      'Legal services ',
      'Immigrant services',
      'Funeral services ',
      'Weekend/Sunday ',
      'Dhikr/spirituality sessions ',
      'Cultural programs ',
      'Social events ',
      'Refugee assistance ',
      'Playground ',
      'Computer lab ',
      'Library ',
      'Mosque on premises',
      'Nurse/infirmary ',
      'Counseling ',
      'Tutoring ',
      'Field trips',
      'College preparation',
      'Debate club ',
      'Book club',
    ];
    const finalArray = [];
    arr.forEach((e) =>
      this.state.orgFacilities.forEach((w) => {
        if (e === w) {
          finalArray.push(e);
        }
      })
    );
    const facilities = (
      <>
        <div style={{ border: '1px solid #E8E9E9' }}>
          <p className='orgTitled' style={{ marginBottom: 20 }}>
            Facilities/Services
          </p>
        </div>
        <div style={{ border: '1px solid #E8E9E9' }}>
          <div style={{ marginBottom: 20 }}>
            {arr.map((item, index) => {
              return (
                <div
                  style={{
                    display: 'inline-block',
                    marginLeft: 30,
                    width: '44%',
                    marginTop: 10,
                  }}
                >
                  <BaseCheckBox
                    label={item}
                    checked={this.state.orgFacilities.some(
                      (fac) => fac === item
                    )}
                    color='secondary'
                    showHelperText={false}
                    onChange={(e) => this.addFacilities(e, item)}
                  />
                </div>
              );
            })}
          </div>
        </div>
        {facilitiesLoading ? (
          <div
            style={{
              border: '1px solid #E8E9E9',
              display: 'flex',
              justifyContent: 'center',
              height: 30,
              paddingTop: 10,
            }}
          >
            <CircularProgress color='primary' size={20} />
          </div>
        ) : (
          <div
            style={{
              borderTop: '1px solid #E8E9E9',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <p className='orgSave' onClick={() => this.saveFacilities()}>
              Save Changes
            </p>
          </div>
        )}
      </>
    );
    const style = {
      width: '100%',
    };
    return (
      <MasterLayoutMiddle
        header={
          <>
            {(error && error === 'No token Provided') ||
              (error && error === 'Invalid token' && (
                <div
                  style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
                >
                  <AlertAction
                    alert={token_expired}
                    alertType={'error'}
                    onClick={() => this.setState({ error: false })}
                  />
                </div>
              ))}
            {showAlert && error && (
              <div
                style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={error}
                  alertType={'error'}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {showAlert && success && (
              <div
                style={{ zIndex: 9999, width: '60%', position: 'fixed', top: 35, marginLeft: '70px' }}
              >
                <AlertAction
                  alert={
                    removeFacility
                      ? success_message_edit_facilities
                      : success_message_facilities
                  }
                  alertType={'success'}
                  onClick={() => this.setState({ showAlert: false })}
                />
              </div>
            )}
            {
              this.props.alert.alertValue &&
              <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
                <AlertAction
                  alert={this.props.alert.alertValue}
                  alertType={"success"}
                  onClick={() => this.props.alertHide()}
                />
              </div>

            }
          </>
        }
        content={
          <>
            <GridContainer spacing="0">
              <GridItem>
                <BaseTabs
                  title=''
                  object={obj}
                  onClick={(x, y) => this.onClick(x, y)}
                  value={activeTab}
                  onChange={(event, newValue) =>
                    this.handleTabChange(event, newValue)
                  }
                />
              </GridItem>
              {tabInd === 0 && (
                <>
                  {loading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 30,
                      }}
                    >
                      <CircularProgress color='primary' />
                    </div>
                  ) : (
                    <GridItem itemStyle={{ marginTop: 0 }} itemClass='grid-item'>
                      <div className='services-table'>
                        <SimpleTable
                          showHeader={true}
                          title='Services'
                          width='98%'
                          rows={rows}
                          columns={col}
                          colSpan={16}
                          actionSendButton={true}
                          classProps={changeClassProps()}
                          donationsCount={rows && rows.length > 0 ? 1 : 0}
                          noTitle="No Services added"
                          noPara="Added Services will be displayed and managed here"
                          noBtnText="Add New Services"
                          onInviteClick={() => {
                            this.props.editServiceHandler();
                            this.props.history.push(
                              '/organization/services/add-new-service'
                            );
                          }}
                          actionLink={(name) => this.details(name)}
                          showIconCol={true}
                          editIcon={Edit}
                          deleteIcon={Delete}
                          editAction={(e) =>
                            this.props.history.replace(
                              `/organization/services/edit-service/${e[0].label}`
                            )
                          }
                          deleteAction={(e) => this.deleteServiceAction(e)}
                          showFooter={true}
                          showAdd={true}
                          onAddClick={() => {
                            this.props.editServiceHandler();
                            this.props.history.push(
                              '/organization/services/add-new-service'
                            );
                          }}
                          iconTextSize={16}
                          addText='ADD NEW'

                          iconTextSize={16}
                          sortColumnIndex={[4]}
                        />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <p className='service-helper-text'>
                          “Active” services are available for other
                          applications/integrations to use
                        </p>
                      </div>
                    </GridItem>
                  )}
                </>
              )}
              {tabInd === 1 && (
                <GridItem itemStyle={{ marginTop: 0 }} itemClass='grid-item'>
                  <BasePaper
                    context={facilities}
                    square={false}
                    paperStyle={styles.paperStyleNext}
                  />
                </GridItem>
              )}
            </GridContainer>
            {openAlert && alertServiceModal}
          </>
        }
        isFooter={true}
        footer={
          <FooterInner 
            style={style} 
            termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
            privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
          />
        }
        isWhiteBackground={false}
      />
    );
  }
}
const styles = {
  paperStyle: {
  },
  paperStyleNext: {
  },
};
const mapStateToProps = (state) => {
  return {
    services: state.services.services,
    alert: state.alert,
  };
};
const mapDispatchToProps = (dispatch) => ({
  editServiceHandler: (res) => dispatch(editServiceAction(res)),
  alertHide: (res) => dispatch(hideAlert(res)),
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Services);
