import React, { useEffect } from "react";
import Moment from "moment";
import MomentTime from "moment-timezone";
import {
  BaseTextField,
  BaseButton,
  BaseStepper,
  BaseStepperExtended,
  TextEditor,
  FormPublishUnpublish,
  BaseImgChip,
  MasterLayoutMiddle,
  FooterInner,
  AlertAction,
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useState } from "react";
import "./style.css";
import { getAllServices, UpdateService } from "../Organization/api";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { showAlert } from "../../redux/actions/alertActions";
import messages_content from '../../utils/messages.json';
import { getServicesWithAppId, updateServicesWithAppId } from "./api";
const { token_expired, publish_date_before_unpublish, success_message_edit_services } = messages_content;
function EditService(props) {
  const [activeStep, setActiveStep] = React.useState(1);
  const [visibility, setVisibility] = React.useState("Public");
  const [Loading, setLoading] = React.useState(true);
  const [publish, setPublish] = React.useState("Immediately");
  const [unPublish, setUnPublish] = React.useState("Never");
  const [publishDate, setPublishDate] = React.useState("Immediately");
  const [publishTime, setPublishTime] = React.useState("");
  const [unPublishDate, setUnPublishDate] = React.useState("Never");
  const [unPublishTime, setUnPublishTime] = React.useState("");
  const [unPublishTimestamp, setUnPublishTimestamp] = React.useState(0);
  const [allServices, setAllServices] = React.useState([]);
  const [services, setServices] = React.useState([]);
  const [selectService, setSelectService] = React.useState("");
  const [chips, setChips] = React.useState([]);
  const [desc, setDesc] = React.useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);
  const [onSelectService, setOnSelectService] = React.useState([]);
  const [UTCDate, setUTCDate] = React.useState("");
  const [UTCTime, setUTCTime] = React.useState("");
  const [UTCDateUnPublish, setUTCDateUnPublish] = React.useState("");
  const [checkedFeatured, setCheckedFeatured] = React.useState(false);
  const [UTCTimeUnPublish, setUTCTimeUnPublish] = React.useState("");
  const [unPublishedDateAndTime, setUnPublishedDateAndTime] = React.useState(
    ""
  );
  const [dateError, setDateError] = React.useState(false);
  const [publishedDateAndTime, setPublishedDateAndTime] = React.useState("");
  const [error, seterror] = useState(false);
  const [errorService, setErrorService] = React.useState(false);
  const [validUnPublish, setValidUnPublish] = React.useState(true);
  const [validPublish, setValidPublish] = React.useState(true);
  useEffect(() => {
    if (Moment(unPublishDate) < Moment()) {
      setVisibility("Hidden");
    }
  }, [unPublishDate]);
  useEffect(() => {
    getAllServicesFunction();
  }, []);
  async function getAllServicesFunction() {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    try {
      const serviceId = props.match.params.id;
      const resService = await getServicesWithAppId(token, orgId, 'deenweb')
      // let response = await getAllServices(token);
      let servicesArray = resService.data.body.data;
      let getService = servicesArray.filter(text => text._id === serviceId)
      setSelectService(getService[0].service.name);
      const year = new Date(getService[0].publishTo).getFullYear()
      setPublishDate(
        `${Moment(getService[0].publishFrom).format("ddd")}, ${Moment(getService[0].publishFrom).format("MMM D, YYYY")}`
      );
      setUTCDate(Moment(getService[0].publishFrom).format('YYYY-MM-DD'));
      setUTCTime(Moment(getService[0].publishFrom).format("HH:mm:ss"));
      setUTCDateUnPublish(Moment(getService[0].publishTo).format('YYYY-MM-DD'));
      setUTCTimeUnPublish(Moment(getService[0].publishTo).format("HH:mm:ss"));
      setPublishedDateAndTime(getService[0].publishFrom);
      setUnPublishedDateAndTime(getService[0].publishTo);
      setUnPublishTimestamp(getService[0].publishTo);
      setPublishTime(Moment(getService[0].publishFrom).format("h:mm A"));
      setUnPublishDate(year === 2099 ? 'Never' :
        `${Moment(getService[0].publishTo).format("ddd")}, ${Moment(getService[0].publishTo).format("MMM D, YYYY")}`
      );
      setUnPublishTime(Moment(getService[0].publishTo).format("h:mm A"));
      let visibility = Moment(getService[0].publishTo) < Moment() ? 'Hidden' : getService[0].status;
      setVisibility(visibility);
      setOnSelectService(getService);
      setCheckedFeatured(getService[0].featured)
      setDesc(JSON.parse(getService[0].service.description));
      const ImageChips = [
        {
          key: 0,
          label: getService.length ? getService[0].service.imageMetaData.imageName : '',
          src: getService.length ? getService[0].service.imageMetaData.imageUrl : '',
          alt: "",
        },
      ];
      setChips(ImageChips);
      setAllServices(servicesArray);
      const name = servicesArray.map((text) => {
        return {
          value: text.service.name,
          label: text.service.name,
        };
      });
      setServices(name);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      seterror(err.response && err.response.data.message);
      setTimeout(() => {
        seterror(false)
      }, 8000);
    }
  }
  const getPublishDate = (value) => {
    if(value === "Immediately") {
      setUTCDate('');
      setPublishedDateAndTime('');
      setPublish("Immediately");
      setPublishDate("Immediately")
    } else {
      setValidPublish(false);
      setPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDate(Moment(value).format("YYYY-MM-DD"));
    }
  };
  const getPublishTime = (value) => {
    setPublishTime(Moment(value).format("h:mm A"));
    setUTCTime(Moment(value).format("HH:mm:ss"));
  };
  const getUnPublishDate = (value) => {
    if(value == "Never") {
      setUTCDateUnPublish('');
      setUnPublishedDateAndTime('')
      setUnPublish('Never');
      setUnPublishDate('Never');
    } else {
      setValidUnPublish(false);
      setUnPublishTimestamp(value);
      setUnPublishDate(
        `${Moment(value).format("ddd")}, ${Moment(value).format("MMM D, YYYY")}`
      );
      setUTCDateUnPublish(Moment(value).format("YYYY-MM-DD"));
    }
  };
  const getUnPublishTime = (value) => {
    setUnPublishTime(Moment(value).format("h:mm A"));
    setUTCTimeUnPublish(Moment(value).format("HH:mm:ss"));
  };
  const onVisibilitySave = () => {
    console.log("visibility saved");
  };
  const onVisibilityCancel = () => {
    console.log("visibility cancelled");
  };
  const onPublishSave = () => {
    let Time = publishTime.split(" ");
    let dated = MomentTime(new Date(publishDate)).format("YYYY-MM-DD");
    let split = Time[0].split(":");
    let Hours = "";
    let H;
    if (Time[1] === "AM") {
      H = parseInt(split[0]);
      Hours = H + ":" + split[1];
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ":" + split[1];
    }
    let date = `${dated + "T" + Hours}:00.00Z`;
    setPublishedDateAndTime(date);
  };

  const onPublishCancel = () => {
    console.log("publish date cancel");
  };
  const onUnPublishSave = () => {
    let Time = unPublishTime.split(" ");
    let dated = MomentTime(new Date(unPublishDate)).format("YYYY-MM-DD");
    let split = Time[0].split(":");
    let Hours = "";
    let H;
    if (Time[1] === "AM") {
      H = parseInt(split[0]);
      Hours = H + ":" + split[1];
    } else {
      H = parseInt(split[0]) + 12;
      Hours = H + ":" + split[1];
    }
    let date = `${dated + "T" + Hours}:00.00Z`;
    setUnPublishedDateAndTime(date);
  };
  const onUnPublishCancel = () => {
    console.log("unpublish date cancel");
  };
  const convertUTC = (date) => {
    return date.toISOString()
  }
  const onSave = async () => {
    const token = localStorage.getItem("authToken");
    const orgId = localStorage.getItem("orgID");
    const data = UTCDate === '' ? new Date() : new Date(`${UTCDate + ' ' + UTCTime}`)
    const dataU = convertUTC(data);
    const dataE = UTCDateUnPublish === '' ? new Date() : new Date(`${UTCDateUnPublish + ' ' + UTCTimeUnPublish}`)
    const UnpublishTime = convertUTC(dataE);
    const obj = {
      website: {
        status: visibility,
        featured: checkedFeatured,
        publishFrom:
          publishDate === "Immediately"
            ? publish
            : dataU,
        publishTo:
          unPublishedDateAndTime === ""
            ? unPublish
            : UnpublishTime,
      },
    };
    const objData = {
      service: onSelectService[0].service._id,
      publishFrom: publishDate === 'Immediately' ? publish : dataU,  // DATE OBJECT
      publishTo: unPublishedDateAndTime === "" ? unPublish : UnpublishTime,
      status: visibility,
      featured: checkedFeatured
  }
    const previousUnpublish = obj.website.publishFrom !== 'Immediately' ? obj.website.publishTo > obj.website.publishFrom : true
    if (obj.website.publishTo === 'Never' || previousUnpublish) {
      try {
        await updateServicesWithAppId(token, orgId, 'deenweb', onSelectService[0].service._id, objData )
        props.alertShow(success_message_edit_services)
        props.history.push("/website/services");
      } catch (err) {
        seterror(err.response && err.response.data.message);
        setTimeout(() => {
          seterror(false)
        }, 8000);
      }
    } else {
      setDateError(true)
      setTimeout(() => {
        setDateError(false)
      }, 8000)
    }
  };
  const onBack = () => {
    setActiveStep(0);
    // props.history.push("/website/services")
  };
  const onInputClick = () => {
    console.log("input clicked");
  };
  const onChipInputClick = () => {
    console.log("input chip clicked");
  };
  const deleteChip = () => {
    console.log("chip deleted");
  };
  const [value, setValue] = useState([
    { type: "paragraph", children: [{ text: "" }] },
  ]);
  const onChange = (e) => {
    setValue(e);
  };
  const handleNext = () => {
    if (onSelectService.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      props.history.push(`/website/edit-service/${onSelectService[0]._id}`);
    } else {
      setErrorService(true)
    }
  };
  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    props.history.push("/website/services")
  };
  const styles = {
    stepper: {
      marginTop: 0,
      padding: "0px 0px",
      borderRadius: "30px",
    },
    contents: {
      padding: 24,
      paddingBottom: 0,
      display: "flex",
      flexDirection: "column",
      background: "#FFF",
      width: "76%",
      marginTop: 70,
      marginLeft: "20%",
    },
    select: {
      margin: "14px 0",
      minWidth: 120,
      height: 66,
      width: 120,
      border: "#D3D8DA",
      width: "100%",
      marginTop: "31px",
    },
    BaseTextField: {
      width: "100%",
    },
    editor: {
      padding: 16,
    },
    formBody: {
      background: "#fff",
    },
    formTitle: {
      fontSize: 22,
      color: "#5A5A5A",
    },
    formTitleSub: {
      fontSize: 14,
      color: "#818E94",
    },
    dottedBorder: {
      border: "#1px dashed #D3D8DA",
    },
    pageTitle: {
      color: "#5A5A5A",
      fontSize: "28px",
      paddingBottom: "30px",
      textAlign: "center",
    },
  };
  const getSteps = () => {
    return [
      { number: 1, label: "Select Service" },
      { number: 2, label: "Publish" },
    ];
  };
  const style = {
    width: "100%",
  };
  return (
    <MasterLayoutMiddle
      header={
        <>
          {
            error && error === "Invalid token" && (
              <div
                style={{
                  zIndex: 9999,
                  width: "60%",
                  position: "fixed",
                  top: 35,
                  marginLeft: '70px'
                }}
              >
                <AlertAction
                  alert={token_expired}
                  alertType={"error"}
                  onClick={() => seterror(false)}
                />
              </div>
            )
          }
          {
            dateError &&
            <div style={{ zIndex: 9999, width: "60%", position: "fixed", top: 35, marginLeft: '70px' }}>
              <AlertAction
                alert={publish_date_before_unpublish}
                alertType={"error"}
                onClick={() => setDateError(false)}
              />
            </div>

          }
        </>
      }
      isForm={true}
      formData={
        Loading ? (
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" />
          </div>
        ) : (
            <div>
              <BaseStepperExtended
                title="Edit Service"
                getSteps={getSteps}
                alternativeLabel={true}
                handleBackPreviousPage={() => props.history.push('/website/services')}
                handleBack={() => handleBack()}
                handleNext={() => handleNext()}
                nonLinear={true}
                orientation={"horizontal"}
                btnStyles={styles.stepper}
                activeStep={activeStep}
                onStepClick = {(obj) => setActiveStep(obj - 1)}
              />
              {activeStep === 0 && (
                <Grid>
                  <Grid item md={12} xl={12} style={{ margin: "auto" }}>
                    <div className="dasheddBorder"></div>
                    <Typography style={styles.formTitle}>Select Service</Typography>
                    <Typography style={styles.formTitleSub}>
                      Add “New Service” from Home screen `&gt;` Manage `&gt;` Services
                  </Typography>
                  </Grid>
                  <Grid item md={12} xl={12} style={{ margin: "auto" }}>
                    <BaseTextField
                      value={selectService}
                      InputProps={false}
                      phone={false}
                      search={false}
                      style={styles.select}
                      color="primary"
                      disabled={true}
                      helperText="Services are managed at home screen"
                      label="Select Service"
                    />
                  </Grid>
                  <Grid item md={12} xl={12} style={{ margin: "auto" }}>
                    <TextEditor
                      label="Description"
                      onChange={(e) => onChange(e)}
                      value={desc}
                      disabled={true}
                      style={styles.editor}
                      helperText={<p>Edit this description from Home screen &gt; Organization &gt; Services</p>}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xl={12}
                    style={{ margin: "auto", marginTop: 14 }}
                    className="dc-web-service"
                  >
                    <BaseImgChip
                      chipData={chips}
                      onInputClick={onInputClick}
                      onChipInputClick={onChipInputClick}
                      deleteChip={deleteChip}
                      label="Image"
                      textStyle={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xl={12}
                    className="buttons"
                    style={{ margin: "auto", marginTop: 50 }}
                  >
                    <span className="btnCencel">
                      <BaseButton
                        text="CANCEL"
                        onClick={() => props.history.push("/website/services")}
                      />
                    </span>
                    <BaseButton text="CONTINUE" onClick={() => handleNext()} />
                  </Grid>
                </Grid>
              )}
              {activeStep === 1 && (
                <Grid>
                  <Grid item md={12} xl={12} style={{ margin: "auto" }}>
                    <div className="dasheddBorder"></div>
                  </Grid>
                  <Grid
                    item
                    md={12}
                    xl={12}
                    style={{ margin: "auto" }}
                    className="grid-add-announc-publish"
                  >
                    <FormPublishUnpublish
                      visibility={visibility}
                      setVisibility={setVisibility}
                      publish={publish}
                      unPublish={unPublish}
                      publishDate={publishDate}
                      publishTime={publishTime}
                      setPublishDate={setPublishDate}
                      setPublishTime={setPublishTime}
                      getPublishDate={getPublishDate}
                      getPublishTime={getPublishTime}
                      unPublishDate={unPublishDate}
                      unPublishTime={unPublishTime}
                      setUnPublishDate={setUnPublishDate}
                      setUnPublishTime={setUnPublishTime}
                      getUnPublishDate={getUnPublishDate}
                      getUnPublishTime={getUnPublishTime}
                      onVisibilitySave={onVisibilitySave}
                      onVisibilityCancel={onVisibilityCancel}
                      onPublishSave={onPublishSave}
                      onPublishCancel={onPublishCancel}
                      onUnPublishSave={onUnPublishSave}
                      onUnPublishCancel={onUnPublishCancel}
                      onSave={onSave}
                      onBack={onBack}
                      unPublishTimestamp={unPublishTimestamp}
                      minDateUnpublish={publishDate}
                      disabledSave={validUnPublish}
                      disabledSaveP={validPublish}
                      showFeatured={true}
                      featuredText="Feature this on homepage"
                      checked={checkedFeatured}
                      onSetChecked={() => setCheckedFeatured(!checkedFeatured)}
                    />
                  </Grid>
                </Grid>
              )}
            </div>
          )
      }
      isFooter={true}
      footer={<FooterInner 
          style={style} 
          termsLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies" : "http://deenconnect.io/terms-policies" }
          privacyLink = {process.env.REACT_APP_Environment === 'dev' ? "http://shapla.deenconnect.io/terms-policies#privacy-policy" : "http://deenconnect.io/terms-policies#privacy-policy"}
        />}
      isWhiteBackground={true}
    />
  );
}
const mapDispatchToProps = (dispatch) => ({
  alertShow: (res) => dispatch(showAlert(res)),
});
export default connect(0, mapDispatchToProps)(EditService);
