import React from 'react';
import { FormSwitch } from "@dclab/dc-react-ui-kit";


function NumberOfSeats(props) {

    const handleChangeGender = (event, value) => {
        console.log(event.target.value, value);
    };


    const Gender = [
        { value: 1, select: "Male" },
        { value: 2, select: "Female" },
    ];

    const Estimated = [
        { value: 1, select: "10 - 20" },
        { value: 2, select: "10 - 25" },
    ];

    const Food = [
        { value: 1, select: "Light refreshment" },
        { value: 2, select: "Dinner" },
    ];

    const Served = [
        { value: 1, select: "Dine-in/Eat in" },
        { value: 2, select: "Dine-in/Eat in" },
    ];



    const [values, setValues] = React.useState(false);

    const handleSwitchChange = (event) => {
        setValues(event)
        console.log(event, 'swuitch')
        // setValues();
    };

    var count = 1;
    const addMore = () => {
        count++;
    }

    return (

        <div className="field_boxAcord">
            <div>
                <FormSwitch
                    headline='Unlimited'
                    body="Limit the number of students that can enroll to this course."
                    values={values}
                    handleSwitchChange={(e) => handleSwitchChange(e)}
                />
            </div>
        </div>

    );
}


export default NumberOfSeats;