import * as constants from "../constants";

function addUserRole(id, application, role) {
  return {
    type: constants.ADD_ROLE,
    payload: {
      id,
      application,
      role,
    },
  };
}
function editUserRole(id, application, role) {
  return {
    type: constants.EDIT_ROLE,
    payload: {
      id,
      application,
      role,
    },
  };
}
function deleteUserRole(id) {
  return {
    type: constants.DELETE_ROLE,
    payload: {
      id,
    },
  };
}

function editTeamMember(data) {
  return {
    type: constants.EDIT_TEAM_MEMBER,
    payload: data,
  };
}


function addTeamMemberUserRole(id, application, role) {
  return {
    type: constants.ADD_TEAM_MEMBER_ROLE,
    payload: {
      id,
      application,
      role,
    },
  };
}

function editTeamMemberUserRole(id, application, role) {
  return {
    type: constants.EDIT_TEAM_MEMBER_ROLE,
    payload: {
      id,
      application,
      role,
    },
  };
}

function deleteTeamMemberUserRole(id) {
  return {
    type: constants.DELETE_TEAM_MEMBER_ROLE,
    payload: {
      id,
    },
  };
}

function emptyTeamMember() {
  return {
    type: constants.EMPTY_TEAM_MEMBER,
  };
}

function emptyRoles() {
  return {
    type: constants.EMPTY_ROLES,
  };
}


export {
  addUserRole,
  deleteUserRole,
  editUserRole,
  editTeamMember,
  deleteTeamMemberUserRole,
  editTeamMemberUserRole,
  addTeamMemberUserRole,
  emptyTeamMember,
  emptyRoles
};
