import * as constants from '../constants'

const INITIAL_STATE = {
    categories:[],
   
  };
  
  export default (state = INITIAL_STATE, action) => {
    
    switch (action.type) {
      case constants.GET_CATEGORIES:
          let arr = action.payload.map(ar => ar)
        return {
          ...state,
         categories:[arr]
        };
        
      default:
        return state;
    }
  };
  